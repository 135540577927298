import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'lh-traveler-shops-ui-best-choice-display',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['traveler-shops-ui-best-choice-display.component.scss'],
  templateUrl: 'traveler-shops-ui-best-choice-display.component.html',
})
export class TravelerShopsUiBestChoiceDisplayComponent {
  @Input() text = 'BEST_CHOICE';
}
