import { ChangeDetectorRef, Component, inject, Input, OnDestroy, OnInit } from '@angular/core';
import { AppConfig } from '@luggagehero/shared/app-settings/data-access';
import {
  BookableStorageLocation,
  IPricing,
  PRICING_SERVICE,
  PricingService,
  StorageCriteria,
} from '@luggagehero/shared/interfaces';
import { SharedIntercomService } from '@luggagehero/shared/services/intercom';
import { SharedShopsService } from '@luggagehero/shared/services/shops';
import { SharedStorageCriteriaService } from '@luggagehero/shared/services/storage-criteria';
import { map, Observable, Subscription } from 'rxjs';

import { BaseModalComponent } from '../../../core';

@Component({ template: '' })
export abstract class PricingBaseComponent extends BaseModalComponent implements OnInit, OnDestroy {
  public showFullExplanation = false;
  private shopsService = inject(SharedShopsService);
  private pricingService = inject<PricingService>(PRICING_SERVICE);
  private criteriaService = inject(SharedStorageCriteriaService);
  private intercom = inject(SharedIntercomService);
  private cd = inject(ChangeDetectorRef);
  private _criteria: StorageCriteria;
  private _shop: BookableStorageLocation;
  private subscriptions: Subscription[] = [];

  public get shop(): BookableStorageLocation {
    return this._shop;
  }
  @Input() public set shop(value: BookableStorageLocation) {
    this._shop = value;
    this.cd.markForCheck();
  }

  public get criteria(): StorageCriteria {
    return this._criteria;
  }
  public set criteria(value: StorageCriteria) {
    this._criteria = value;
    this.cd.markForCheck();
  }

  public get pricing(): IPricing {
    return this.shop?.pricing;
  }

  public get region(): string {
    return this.shopsService.latestPrice?.region;
  }

  public get insuranceCoverage(): number {
    return this.shop?.pricing?.insuranceCoverage || this.shopsService.latestPrice?.pricings[0]?.insuranceCoverage;
  }

  public get hourlyRateHtml(): Observable<string> {
    return this.pricingService.transformPricing(this.pricing, false, false, true, false, false, true).pipe(
      map((html) => html?.replace('>/', '>') ?? ''), // HACK: Remove "/" from "/hour"
    );
  }

  public get dailyRateHtml(): Observable<string> {
    return this.pricingService.transformPricing(this.pricing, false, true, false, false, false, true).pipe(
      map((html) => html?.replace('>/', '>') ?? ''), // HACK: Remove "/" from "/day"
    );
  }

  public ngOnInit() {
    this.criteria = this.criteriaService.currentOrDefault;
    this.subscriptions.push(this.criteriaService.change.subscribe((c) => (this.criteria = c)));
  }

  public ngOnDestroy(): void {
    try {
      if (this.subscriptions) {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
      }
    } catch {
      // Ignore
    }
  }

  public showContact() {
    if (AppConfig.IS_CHAT_ON_CONTACT_LINK_ENABLED) {
      this.intercom.show();
    }
  }
}
