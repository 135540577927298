// libs
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
// app
import { RouterExtensions } from '@luggagehero/core';
import { ProfileBaseComponent } from '@luggagehero/features/user';
import { SharedNotificationService } from '@luggagehero/shared/services/notification';
import { SharedUserService } from '@luggagehero/shared/services/users';
import { SharedWindowService } from '@luggagehero/shared/services/window';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'lh-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfileComponent extends ProfileBaseComponent implements OnInit {
  constructor(
    formBuilder: FormBuilder,
    userService: SharedUserService,
    notify: SharedNotificationService,
    windowService: SharedWindowService,
    router: RouterExtensions,
    cd: ChangeDetectorRef,
    translate: TranslateService,
  ) {
    super(formBuilder, userService, notify, windowService, router, cd, translate);
  }
}
