import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { TranslatePipe } from '@luggagehero/shared/ui-pipes';

import { SharedUiButtonComponent } from '../button/shared-ui-button.component';
import { SharedUiToggleButtonComponent } from '../toggle-button/shared-ui-toggle-button.component';

export interface SelectOptionButtonGroupOption<T> {
  value: T;
  primaryContent: Section;
  secondaryContent?: Section;
}

export interface Section {
  title: string;
  paragraphs?: string[];
}

@Component({
  selector: 'lh-shared-ui-select-option-button-group',
  standalone: true,
  imports: [CommonModule, SharedUiButtonComponent, SharedUiToggleButtonComponent, TranslatePipe],
  templateUrl: './shared-ui-select-option-button-group.component.html',
  styleUrl: './shared-ui-select-option-button-group.component.scss',
})
export class SharedUiSelectOptionButtonGroupComponent<T> {
  @Output() public selectedChange: EventEmitter<T> = new EventEmitter<T>();

  @Input() public prompt: string | undefined;
  @Input() public selected: T | undefined;
  @Input() public options: SelectOptionButtonGroupOption<T>[] = [];

  private readonly cd = inject(ChangeDetectorRef);

  public get advancedMode(): boolean {
    if (!this.options) {
      return false;
    }

    // Check if there are more than 2 options
    if (this.options.length > 2) {
      return true;
    }

    // Check if any of the options have a footer
    if (this.options.some((option) => option.secondaryContent)) {
      return true;
    }

    return false;
  }

  public selectOption(value: T): void {
    this.selected = value;
    this.cd.detectChanges();
    this.selectedChange.emit(value);
  }
}
