export interface ProductList {
  // locationId?: string; // do we need this?
  // region: string; // do we need this?
  currency: string;
  products: Array<ProductInfo>;
  priceContextIds: string[];
  priceContextKey: string;
}

export enum ProductScope {
  'NA' = 'NA',
  'PerBag' = 'PerBag',
  'PerBooking' = 'PerBooking',
}

export interface ProductInfo {
  key: string;
  cost: number;
  value?: number;
  name: string;
  tags?: KeyValuePair<string, string>[];
  scope?: ProductScope;
  optional?: boolean;
  selected?: boolean;
}

export interface KeyValuePair<K, V> {
  key: K;
  value: V;
}
