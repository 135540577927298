import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export class SharedUtilPassword {
  static DEFAULT_PASSWORD_LENGTH = 8; // Adjust as needed
  static DEFAULT_PASSWORD_CHARSET =
    'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789~`!@#$%^&*()-+=|]}[{‘”;:/?.>,<';

  /**
   * Generates a random password adhering to the complexity requirements:
   * - At least one lowercase letter
   * - At least one uppercase letter
   * - At least one digit
   * - At least one special character from the allowed list
   *
   * @param length - The desired length of the password (minimum 4)
   * @param charset - The set of allowed characters for the password
   * @returns A randomly generated password string
   */
  static generate(
    length: number = SharedUtilPassword.DEFAULT_PASSWORD_LENGTH,
    charset: string = SharedUtilPassword.DEFAULT_PASSWORD_CHARSET,
  ): string {
    if (length < 4) {
      throw new Error('Password length must be at least 4 to satisfy complexity requirements.');
    }

    // Define character sets
    const lowercase = 'abcdefghijklmnopqrstuvwxyz';
    const uppercase = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const digits = '0123456789';
    const special = '~`!@#$%^&*()-+=|]}[{‘”;:/?.>,<';

    // Ensure the charset includes all required character sets
    const requiredCharsets = [lowercase, uppercase, digits, special];
    for (const set of requiredCharsets) {
      for (const char of set) {
        if (!charset.includes(char)) {
          throw new Error('The provided charset does not include all required character types.');
        }
      }
    }

    // Function to get a random character from a given set
    const getRandomChar = (set: string): string => {
      const index = Math.floor(Math.random() * set.length);
      return set.charAt(index);
    };

    // Initialize password with one character from each required set
    const passwordChars: string[] = [
      getRandomChar(lowercase),
      getRandomChar(uppercase),
      getRandomChar(digits),
      getRandomChar(special),
    ];

    // Fill the remaining length with random characters from the entire charset
    for (let i = 4; i < length; i++) {
      passwordChars.push(getRandomChar(charset));
    }

    // Shuffle the password characters to ensure randomness
    for (let i = passwordChars.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [passwordChars[i], passwordChars[j]] = [passwordChars[j], passwordChars[i]];
    }

    return passwordChars.join('');
  }

  /**
   *
   * @returns A validator function that checks if the password meets the complexity requirements.
   */
  static passwordComplexity(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value as string;
      if (!value) {
        return null; // Return null if control is empty
      }

      const hasUpperCase = /[A-Z]/.test(value);
      const hasLowerCase = /[a-z]/.test(value);
      const hasNumeric = /[0-9]/.test(value);
      const hasSpecialChar = /[~`!@#$%^&*()\-+=|{}[\]‘”;:/?.>,<]/.test(value);

      const passwordValid = hasUpperCase && hasLowerCase && hasNumeric && hasSpecialChar;

      return !passwordValid ? { passwordComplexity: true } : null;
    };
  }
}
