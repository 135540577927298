import { Injectable } from '@angular/core';
import { Config } from '@luggagehero/shared/environment';
import { IUser } from '@luggagehero/shared/interfaces';
import { SharedWindowService } from '@luggagehero/shared/services/window';

import { ScriptService } from './script.service';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare let wootric: any;

@Injectable()
export class SurveyService {
  private isInitialized = false;

  constructor(
    private window: SharedWindowService,
    private script: ScriptService,
  ) {}

  init() {
    this.script.load('wootric').subscribe((res) => (this.isInitialized = res.isLoaded));
  }

  showSurvey(user: IUser, properties?: unknown) {
    if (!this.isInitialized) {
      // Wootric script not available (likely not running in production)
      return;
    }

    try {
      const userCreatedAt = Math.round(new Date(user.dateCreated).getTime() / 1000);
      this.runWootric(user.email, userCreatedAt, properties);
    } catch {
      // Ignore
    }
  }

  private runWootric(userEmail: string, userCreatedAt: number, properties?: unknown) {
    //
    // Disable Wootric's sampling logic as we always choose explicitly when to show the survey - currently, right after
    // the user leaves a normal review on our platform
    //
    this.window.wootric_survey_immediately = true;

    // When in development mode, allow the survey to be showed multiple times for the same user
    this.window.wootric_no_surveyed_cookie = Config.isDevelopment;

    this.window.wootricSettings = {
      email: userEmail,
      created_at: userCreatedAt,
      properties: properties,
      product_name: 'LuggageHero',
      account_token: Config.environment.WOOTRIC_ACCOUNT_TOKEN,
      modal_footprint: 'compact',
    };
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    wootric('run');
  }
}
