import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'lh-traveler-shops-ui-distance-display',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['traveler-shops-ui-distance-display.component.scss'],
  templateUrl: 'traveler-shops-ui-distance-display.component.html',
})
export class TravelerShopsUiDistanceDisplayComponent {
  @Input() distance!: string;
}
