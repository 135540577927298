import { Injectable } from '@angular/core';

export interface Country {
  code: string;
  name: string;
  visible: boolean;
}

@Injectable()
export class CountryService {
  private countries: Country[] = [
    {
      code: 'DK',
      name: 'Denmark',
      visible: true,
    },
    {
      code: 'GB',
      name: 'United Kingdom',
      visible: true,
    },
  ];
  getCountriesAsArray(): string[] {
    return (
      this.countries
        //.filter(country => country.visible === true)
        .map((country) => country.name)
    );
  }
}
