// any operators needed throughout your application
import './operators';

import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Event, NavigationEnd, Router } from '@angular/router';
import { ModalRef, ModalService } from '@luggagehero/core';
import {
  BookingStrategyFactory,
  DefaultManageBookingStrategy,
  PaymentFirstManageBookingStrategy,
} from '@luggagehero/features/bookings';
import { SharedAppSettingsService } from '@luggagehero/shared/app-settings/data-access';
import { Config } from '@luggagehero/shared/environment';
import { ITheme } from '@luggagehero/shared/interfaces';
import { SharedDocumentService } from '@luggagehero/shared/services/document';
import { SharedIntercomService } from '@luggagehero/shared/services/intercom';
import { SharedLoggingService } from '@luggagehero/shared/services/logging';
import { SharedStorageService } from '@luggagehero/shared/services/storage';
import { SharedStripeService } from '@luggagehero/shared/services/stripe';
import { SharedThemeService } from '@luggagehero/shared/services/theme';
import { IShowLogin, SharedUserService } from '@luggagehero/shared/services/users';
import { SharedWindowService } from '@luggagehero/shared/services/window';
import { FindShopComponent } from '@luggagehero/web/features/shops';
import { UserModalComponent } from '@luggagehero/web/features/user';
import { setTheme } from 'ngx-bootstrap/utils';

/**
 * This class represents the main application component.
 */
@Component({
  selector: 'lh-app',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default, // Everything else uses OnPush
})
export class AppComponent implements OnInit {
  private loginModal: ModalRef;
  private isTouch: boolean;
  private _isIntercomEnabled: boolean;
  private theme: ITheme;

  constructor(
    private userService: SharedUserService,
    private modalService: ModalService,
    private appSettings: SharedAppSettingsService,
    private storageService: SharedStorageService,
    private windowService: SharedWindowService,
    private documentService: SharedDocumentService,
    private themeService: SharedThemeService,
    private intercom: SharedIntercomService,
    private stripe: SharedStripeService,
    private log: SharedLoggingService,
    private router: Router,
  ) {
    setTheme('bs5');
  }

  public get appVersion(): string {
    return this.windowService.appVersion;
  }

  public get isIntercomEnabled(): boolean {
    return this._isIntercomEnabled;
  }

  public get isTabbedNavigationEnabled(): boolean {
    return this.isTouch && this.appSettings.current.IS_TABBED_NAVIGATION_ENABLED;
  }

  public get isThemeHeaderLight(): boolean {
    return this.theme?.headerColor?.isLight ? true : false;
  }

  public ngOnInit() {
    void this.log.debug(`Config env: ${Config.environment.ENV}`);

    this._isIntercomEnabled = this.appSettings.current.IS_INTERCOM_ENABLED;

    try {
      // Register booking strategies (legacy)
      BookingStrategyFactory.registerLegacyStrategy('default', DefaultManageBookingStrategy);
      BookingStrategyFactory.registerLegacyStrategy('payment_first', PaymentFirstManageBookingStrategy);
    } catch (err) {
      void this.log.error(`Error registering booking strategy`, err);
    }

    // Subscribe to app events
    this.router.events.subscribe((e) => this.handleRouterEvent(e));
    this.userService.showLogin.subscribe((e) => void this.handleShowLogin(e));
    this.themeService.theme$.subscribe((t) => (this.theme = t));

    // Register if we're on a touch device
    this.isTouch = this.windowService.isTouch;

    // check if we have a token, but no userinfo, if so, fetch the user
    if (this.storageService.token && !this.userService.user) {
      void this.userService.getUserInfo();
    }

    // Initialize Stripe without waiting for it to be ready
    void this.stripe.init().catch((err) => void this.log.error(`Error initializing Stripe`, err));
  }

  public onAttach(e: Component) {
    // Components that are reused which require a css class on the body should be handled here
    if (e instanceof FindShopComponent) {
      this.documentService.addBodyClass('find-shop');

      if (!e.showList) {
        this.documentService.addBodyClass('fullscreen-map');
      }
    }
  }

  public onDetach(e: Component) {
    // Components that are reused which require a css class on the body should be handled here
    if (e instanceof FindShopComponent) {
      this.documentService.removeBodyClass('find-shop');
      this.documentService.removeBodyClass('fullscreen-map');
    }
  }

  private handleRouterEvent(e: Event): void {
    if (e instanceof NavigationEnd) {
      //
      // Update Intercom whenever the view or URL changes in your app. This will allow
      // people to receive your most recent messages.
      //
      this.intercom.update();

      // Record booking origin to be added to bookings created
      const isInitialView = this.storageService.bookingOriginView ? false : true;
      const bookingOrigin = this.parseBookingOrigin(e.urlAfterRedirects, isInitialView);
      if (bookingOrigin) {
        this.storageService.bookingOriginView = bookingOrigin;
      }
    }
  }

  private async handleShowLogin(e: IShowLogin) {
    if (e.show) {
      const componentState: Partial<UserModalComponent> = {
        isStorageManager: false,
      };
      if (e.userEmail) {
        componentState.userEmail = e.userEmail;
      }
      if (e.register) {
        componentState.isExistingUser = false;
      }
      if (e.goHomeOnAbort) {
        componentState.navigateHomeOnHide = true;
      }

      // Show login view and save modal ref so we can hide on successful login
      this.loginModal = await this.modalService.show({ component: UserModalComponent, componentState });
    } else if (this.loginModal) {
      // Login was completed, hide the modal and remove the ref
      await this.loginModal.hide();
      this.loginModal = null;
    }
  }

  private parseBookingOrigin(url: string, isInitialView: boolean): string {
    if (url.includes('find-shop') || url.includes('home')) {
      return 'browse_locations';
    }
    if (url.includes('find-storage')) {
      return 'find_nearby';
    }
    if (url.includes('book-shop') || url.includes('dropoff')) {
      return 'direct_dropoff';
    }
    if (isInitialView && (url.includes('shop-details') || url.includes('storage-locations'))) {
      return 'specific_location';
    }
    return null;
  }
}
