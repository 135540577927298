import { ChangeDetectorRef, Component, inject, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Config } from '@luggagehero/shared/environment';
import { HowItWorksScenario, ITheme, IUser, LogoStyle } from '@luggagehero/shared/interfaces';
import { SharedIntercomService } from '@luggagehero/shared/services/intercom';
import { SharedParamsService } from '@luggagehero/shared/services/params';
import { SharedThemeService } from '@luggagehero/shared/services/theme';
import { SharedUserService } from '@luggagehero/shared/services/users';
import { SharedWindowService } from '@luggagehero/shared/services/window';
import { filter, Subscription } from 'rxjs';

import { BaseComponent } from '../../../core';

@Component({ template: '' })
export abstract class NavbarBaseComponent extends BaseComponent implements OnInit, OnDestroy {
  public isCollapsed = true;
  public isLoggedIn = false;
  public isDirectDropoff: boolean;
  public isMobileApp: boolean;

  private intercom = inject(SharedIntercomService);

  private _theme: ITheme;
  private subscriptions: Subscription[] = [];
  private _isSmallScreen: boolean;
  private _isSignUpSession = false;
  private _isSignInSession = false;
  private windowWidth: number;

  constructor(
    private paramsService: SharedParamsService,
    private themeService: SharedThemeService,
    protected userService: SharedUserService,
    private windowService: SharedWindowService,
    private router: Router,
    private cd: ChangeDetectorRef,
  ) {
    super();
  }

  get userIdentifier(): string {
    return this.userService.userIdentifier;
  }

  get theme(): ITheme {
    return this._theme;
  }
  set theme(value: ITheme) {
    this._theme = value;
    this.cd.markForCheck();
  }

  get isThemeReady() {
    return this.themeService.isInitialized;
  }

  get isThemeHeaderLight(): boolean {
    return this.theme?.headerColor?.isLight ? true : false;
  }

  get themeLogo(): string {
    return this.theme?.images.logo;
  }

  get themeLink(): string {
    return this.theme?.link;
  }

  get user(): IUser {
    return this.userService.user;
  }

  get isShopOwner(): boolean {
    return this.user && this.user.isShopOwner;
  }

  get notificationCount(): number {
    return this.userService.notificationCount;
  }

  public onNavbarBrandClick(event: Event) {
    if (this.isDirectDropoff) {
      event.preventDefault();
    }
  }

  get homeUrl(): string {
    if (this.paramsService.landingPageUrl) {
      return this.paramsService.landingPageUrl;
    }
    switch (Config.environment.ENV) {
      case 'DEV':
        return '/home';

      case 'STAGING':
        return 'https://app.devheroo.com';

      case 'PRODUCTION':
        return 'https://luggagehero.com';

      default:
        return 'https://luggagehero.com';
    }
  }

  get isPlatformLogoCollapsed(): boolean {
    // if (this.isSmallScreen) {
    //   return true;
    // }
    if (this.themeLogo && (this.isSmallScreen || this.theme.logoStyle === LogoStyle.Primary)) {
      return true;
    }
    return false;
  }

  get isUserAvailable(): boolean {
    if (!this.userService.user) {
      return false;
    }
    return true;
  }

  get isSignUpSession(): boolean {
    return this._isSignUpSession;
  }
  set isSignUpSession(value: boolean) {
    this._isSignUpSession = value;
    this.cd.markForCheck();
  }

  get isSignInSession(): boolean {
    return this._isSignInSession;
  }
  set isSignInSession(value: boolean) {
    this._isSignInSession = value;
    this.cd.markForCheck();
  }

  get isSmallScreen(): boolean {
    return this._isSmallScreen;
  }
  set isSmallScreen(value: boolean) {
    this._isSmallScreen = value;
    this.cd.markForCheck();
  }

  get howItWorksScenario(): HowItWorksScenario {
    if (this.isDirectDropoff) {
      return HowItWorksScenario.walkIn;
    }
    if (this.isMobileApp) {
      return HowItWorksScenario.mobileApp;
    }
    return HowItWorksScenario.default;
  }

  get showPricingItem() {
    if (this.windowWidth < 375) {
      return false;
    }

    return true;
  }

  async ngOnInit() {
    this.subscriptions.push(
      this.router.events.pipe(filter<NavigationEnd>((e) => e instanceof NavigationEnd)).subscribe((e) => {
        this.isDirectDropoff = e.url.includes('book-shop') || e.url.includes('dropoff');
        this.isMobileApp = e.url.includes('tabs');
        this.cd.markForCheck();
      }),
      this.themeService.theme$.subscribe((theme) => (this.theme = theme)),
      this.userService.signUpEvent$.subscribe((signedUp) => (this.isSignUpSession = signedUp)),
      this.userService.signInEvent$.subscribe((signedIn) => (this.isSignInSession = signedIn)),
      this.userService.loginAnnounced.subscribe((loginEvent) => {
        this.isLoggedIn = loginEvent.isLoggedIn && this.isUserAvailable;
        this.cd.markForCheck();
      }),
      this.windowService.size.subscribe((s) => {
        this.windowWidth = s.windowWidth;
        this.cd.markForCheck();
      }),
      this.windowService.size.subscribe((s) => (this.isSmallScreen = s.isSmallScreen)),
    );
    this.isLoggedIn = (await this.userService.isLoggedInAsync()) && this.isUserAvailable;
  }

  ngOnDestroy() {
    try {
      this.subscriptions.forEach((s) => s.unsubscribe());
    } catch {
      // Ignore
    }
  }

  public abstract showHowItWorks(): Promise<void>;

  // this.modalService.show('PricingModal', { shop: null });
  public abstract showPriceInfo(): Promise<void>;

  public showChat() {
    this.intercom.show();
  }

  logIn() {
    this.userService.showLoginView(true, undefined, false);
  }

  logOut() {
    this.userService.logout();
    this.isLoggedIn = this.userService.isLoggedIn && this.isUserAvailable;
    this.isCollapsed = true;

    if (!this.isLoggedIn) {
      void this.router.navigate(['home']);
    }
  }
}
