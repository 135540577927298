export enum AppPlatform {
  Web = 'Web',
  iOS = 'iOS',
  Android = 'Android',
}

export interface AppEvent {
  category?: string;
  label?: string;
  value?: number;
  currency?: string;
  orderId?: string;
  appPlatform?: AppPlatform;
  appVersion?: string;
  geoLocation?: {
    lat: number;
    lon: number;
  };
}
