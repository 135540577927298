import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { PricingContext } from '@luggagehero/traveler-shops-models';

@Component({
  selector: 'lh-traveler-shops-ui-pricing-display',
  standalone: true,
  imports: [CommonModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['traveler-shops-ui-pricing-display.component.scss'],
  templateUrl: 'traveler-shops-ui-pricing-display.component.html',
})
export class TravelerShopsUiPricingDisplayComponent {
  @Input() pricing!: PricingContext;
}
