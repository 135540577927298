/* eslint-disable @typescript-eslint/unbound-method */
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppConfig } from '@luggagehero/shared/app-settings/data-access';
import { Config } from '@luggagehero/shared/environment';
import { SharedErrorService } from '@luggagehero/shared/services/error';
import { SharedUserService } from '@luggagehero/shared/services/users';
import { SharedUtilPassword } from '@luggagehero/shared/util';

import { BaseComponent } from '../../../../core';
import { FacebookLoginService, GoogleSigninService } from '../../../../services/index';
import { GuidUtil } from '../../../../utils/guid.util';
import { StringUtil } from '../../../../utils/string.util';
import { PhoneInputBaseComponent } from '../../../ui/index';
import {
  EmailOtpStrategy,
  EmailPasswordStrategy,
  PhoneOtpStrategy,
  PhonePasswordStrategy,
  UserAuthenticationStrategy,
} from './strategies';

export interface UserInfo {
  email: string;
  password: string;
  name: string;
  phone?: string;
  phoneCountry?: string;
  isShopOwner?: boolean;
}

export interface NewUserInfo extends UserInfo {
  sendPassword: boolean;
  tenant: string;
}

export interface TermsOfServiceConfig {
  textBeforeLink: string;
  textAfterLink?: string;
  linkUrl: string;
  linkText: string;
}

export type UserAccountFieldConfig =
  | 'disabled' // Don't ask for the given user account field
  | 'optional' // Ask for the given user account field
  | 'required'; // Require the given user account field

export type LoginMediumConfig =
  | 'disabled' // Don't allow login with the given medium
  | 'password' // Allow login with the given medium using stored password
  | 'code'; // Allow login with the given medium using one-time passwords

export type LoginMedium = 'email' | 'phone' | 'hybrid';

@Component({ template: '' })
export abstract class UserAuthenticationBaseComponent extends BaseComponent implements OnInit, OnChanges {
  @ViewChild('emailInput') emailInput: ElementRef<HTMLInputElement>;
  @ViewChild('phoneInput') phoneInput: PhoneInputBaseComponent;

  @Input() public showFieldLabels = false;
  @Input() public message: string;

  @Input() public loginMedium: LoginMedium = 'email';

  @Input() public emailLogin: LoginMediumConfig = 'password';
  @Input() public phoneLogin: LoginMediumConfig = 'code';

  @Input() public otpLength = 4;

  @Input() public registerName: UserAccountFieldConfig = 'required';
  @Input() public askForFullName = false;
  @Input() public registerPhoneNumber: UserAccountFieldConfig = 'optional';
  @Input() public registerEmail: UserAccountFieldConfig = 'required';
  @Input() public registerPassword: UserAccountFieldConfig = 'required';

  @Input() public termsOfService: TermsOfServiceConfig;

  @Input() public enableThirdPartyLogin = false;
  @Input() public requireCaptcha = false;

  @Input() public isStorageManager = false;

  @Output() public isUserEmailSelectedChange = new EventEmitter<boolean>();
  @Output() public isUserPhoneSelectedChange = new EventEmitter<boolean>();

  @Input() public isUserEmailSelected = false;
  @Input() public isUserPhoneSelected = false;

  @Output() public isExistingUserChange = new EventEmitter<boolean>();
  @Output() public userEmailChange = new EventEmitter<string>();

  @Input() public isRemoteControlled = false;

  public user: UserInfo = {
    email: '',
    password: '',
    name: '',
    phone: '',
    phoneCountry: '',
  };
  public phoneCountries = AppConfig.SUPPORTED_PHONE_COUNTRIES;
  public showPassword = false;
  public get showSkipLogin(): boolean {
    return !Config.isProduction;
  }

  public form: FormGroup;

  public captchaConfig: unknown;

  public isContinueWithEmailButtonClicked = false;
  public isContinueWithPhoneButtonClicked = false;

  public otpDebounceTimeout: NodeJS.Timeout;
  public existingUserPhoneNumbers: string[] = [];

  private _isFullFormVisible = false;
  private _isExistingUser = true;
  private _isLoading = false;

  constructor(
    private emailPasswordStrategy: EmailPasswordStrategy,
    private emailOtpStrategy: EmailOtpStrategy,
    private phonePasswordStrategy: PhonePasswordStrategy,
    private phoneOtpStrategy: PhoneOtpStrategy,
    private userService: SharedUserService,
    private facebookLogin: FacebookLoginService,
    private googleSignin: GoogleSigninService,
    private error: SharedErrorService,
    private formBuilder: FormBuilder,
    private cd: ChangeDetectorRef,
  ) {
    super();

    this.captchaConfig = {
      siteKey: '6LfmWm0gAAAAADjDSNETeSVBCWdXOjnZwW5gzDIj',
      size: 'normal',
      lang: this.currentLang,
      theme: 'light',
      type: 'image',
      badge: '',
    };
  }

  public abstract get isAppleSignInAvailable(): boolean;

  public get isLoading(): boolean {
    return this._isLoading;
  }
  public set isLoading(value: boolean) {
    this._isLoading = value;
    this.cd.markForCheck();
  }

  public get isExistingUser(): boolean {
    return this._isExistingUser;
  }
  @Input() public set isExistingUser(value: boolean) {
    this._isExistingUser = value;
    this.loadFormValidation();
  }

  get userEmail(): string {
    return this.user.email;
  }
  @Input() set userEmail(value: string) {
    if (!value || value === this.user.email) {
      return;
    }
    this.user.email = value;
    this.loginMedium = 'email';
    this.cd.markForCheck();

    this.userEmailChange.emit(value);
  }

  get isFullFormVisible(): boolean {
    return this._isFullFormVisible;
  }
  set isFullFormVisible(value: boolean) {
    this._isFullFormVisible = value;
    this.cd.markForCheck();
  }

  public get isContinueButtonDisabled(): boolean {
    if (this.isLoading) {
      return true;
    }
    if (!this.isContinueWithEmailButtonClicked && !this.isContinueWithPhoneButtonClicked) {
      return false;
    }
    const input = this.loginMedium === 'email' ? this.form.controls.email : this.form.controls.phone;
    return input.invalid;
  }

  public get isValidPhoneNumber(): boolean {
    if (!this.form.controls.phone) {
      return false;
    }
    return this.form.controls.phone.dirty && this.form.controls.phone.valid;
  }

  public get emailInputError(): string {
    if (this.loginMedium === 'email' && !this.isContinueWithEmailButtonClicked) {
      return null;
    }
    const emailInput = this.form.controls.email;
    if (!emailInput || emailInput.valid) {
      return null;
    }
    if (emailInput.errors.required) {
      return 'EMAIL_REQUIRED';
    }
    if (emailInput.errors.pattern) {
      return 'INVALID_EMAIL';
    }
    return 'INVALID_EMAIL';
  }

  public get phoneInputError(): string {
    if (this.loginMedium === 'phone' && !this.isContinueWithPhoneButtonClicked) {
      return null;
    }
    const phone = StringUtil.normalizePhoneNumber(this.user.phone);
    if (this.existingUserPhoneNumbers.includes(phone)) {
      return 'PHONE_NUMBER_ALREADY_EXISTS';
    }
    const phoneInput = this.form.controls.phone;
    if (!phoneInput || phoneInput.untouched || phoneInput.pristine || phoneInput.valid) {
      return null;
    }
    if (phoneInput.errors.required) {
      return 'PHONE_NUMBER_MISSING';
    }
    if (phoneInput.errors.minlength) {
      return 'PHONE_NUMBER_TOO_SHORT';
    }
    if (phoneInput.errors.invalid) {
      if ('reason' in phoneInput.errors.invalid) {
        return (
          phoneInput.errors.invalid as {
            reason: string;
          }
        ).reason;
      }
    }
    return 'INVALID_PHONE_NUMBER';
  }

  public get nameInputError(): string {
    const nameInput = this.form.controls.name;
    if (!nameInput || nameInput.untouched || nameInput.pristine || nameInput.valid) {
      return null;
    }
    if (nameInput.errors.required) {
      return 'NAME_IS_REQUIRED';
    }
    if (nameInput.errors.pattern) {
      return 'INVALID_NAME';
    }
    return 'INVALID_NAME';
  }

  public get passwordInputError(): string {
    const passwordInput = this.form.controls.password;
    if (!passwordInput || passwordInput.untouched || passwordInput.pristine || passwordInput.valid) {
      return null;
    }
    if (passwordInput.errors.required) {
      return this.isExistingUser ? 'PASSWORD_REQUIRED' : 'CHOOSE_PASSWORD';
    }
    return 'CHANGE_PASSWORD_REQUIRED';
  }

  public get showLoginMediumToggle(): boolean {
    if (this.loginMedium === 'hybrid') {
      return false;
    }
    // Show login method toggle when both email and phone login are enabled
    return this.emailLogin !== 'disabled' && this.phoneLogin !== 'disabled';
  }

  public get isEmailInputDisabled(): boolean {
    if (this.loginMedium !== 'email') {
      return false;
    }
    return this.isFullFormVisible;
  }

  public get isPhoneInputDisabled(): boolean {
    if (this.loginMedium !== 'phone') {
      return false;
    }
    return this.isFullFormVisible;
  }

  public get isDevelopment(): boolean {
    return Config.isDevelopment;
  }

  public get showLabels(): boolean {
    return this.showFieldLabels && this.isFullFormVisible && !this.isExistingUser;
  }

  public get showName(): boolean {
    if (this.isExistingUser) {
      return false;
    }
    return this.registerName !== 'disabled';
  }

  public get namePlaceholder(): string {
    if (this.askForFullName) {
      return 'Jane Doe';
    }
    return this.registerName === 'optional' ? 'NAME_OPTIONAL' : 'NAME';
  }

  public get passwordLabel(): string {
    return this.isExistingUser ? 'PASSWORD' : 'CHOOSE_PASSWORD';
  }

  public get passwordPlaceholder(): string {
    return this.isExistingUser ? 'PASSWORD' : 'CHOOSE_PASSWORD_MIN_LENGTH';
  }

  public get isOtpLogin(): boolean {
    if (this.loginMedium === 'email' && this.emailLogin === 'code') {
      return true;
    }

    if (this.loginMedium === 'phone' && this.phoneLogin === 'code') {
      return true;
    }

    return false;
  }

  public get showLoginButton(): boolean {
    if (this.isOtpLogin) {
      return false;
    }
    return this.isFullFormVisible;
  }

  public get hasValidEmail(): boolean {
    if (!this.user.email) {
      return false;
    }
    if (this.emailInputError) {
      return false;
    }
    return true;
  }

  public get hasValidPhone(): boolean {
    if (!this.user.phone) {
      return false;
    }
    if (this.phoneInputError) {
      return false;
    }
    return true;
  }

  public get strategy(): UserAuthenticationStrategy {
    if (
      this.loginMedium === 'email' ||
      (this.loginMedium === 'hybrid' && (this.hasValidEmail || !this.hasValidPhone))
    ) {
      if (this.emailLogin === 'password') {
        return this.emailPasswordStrategy;
      }

      if (this.emailLogin === 'code') {
        return this.emailOtpStrategy;
      }
    }

    if (this.loginMedium === 'phone' || (this.loginMedium === 'hybrid' && this.hasValidPhone)) {
      if (this.phoneLogin === 'password') {
        return this.phonePasswordStrategy;
      }

      if (this.phoneLogin === 'code') {
        return this.phoneOtpStrategy;
      }
    }

    throw new Error('Unsupported configuration');
  }

  public async ngOnInit() {
    this.emailPasswordStrategy.init(this);
    this.emailOtpStrategy.init(this);
    this.phonePasswordStrategy.init(this);
    this.phoneOtpStrategy.init(this);

    // If phone login is enabled, use it by default
    if (this.loginMedium !== 'hybrid') {
      this.loginMedium = this.phoneLogin !== 'disabled' ? 'phone' : 'email';
    }

    if (this.user.email && this.emailLogin !== 'disabled') {
      this.loginMedium = 'email';
      await this.continue();
    } else if (this.user.phone && this.phoneLogin !== 'disabled') {
      this.loginMedium = 'phone';
      await this.continue();
    }
  }

  public ngOnChanges(c: SimpleChanges): void {
    // Check for changes that require the form to be reloaded
    if (
      (c.loginMedium && c.loginMedium.currentValue !== c.loginMedium.previousValue) ||
      (c.registerName && c.registerName.currentValue !== c.registerName.previousValue) ||
      (c.registerPhoneNumber && c.registerPhoneNumber.currentValue !== c.registerPhoneNumber.previousValue) ||
      (c.registerEmail && c.registerEmail.currentValue !== c.registerEmail.previousValue) ||
      (c.registerPassword && c.registerPassword.currentValue !== c.registerPassword.previousValue) ||
      (c.requireCaptcha && c.requireCaptcha.currentValue !== c.requireCaptcha.previousValue)
    ) {
      this.loadForm();
    }
  }

  public onCaptchaReady() {
    console.log(`Hello, reCAPTCHA ready`);
  }

  public onCaptchaReset() {
    console.log(`Hello, reCAPTCHA reset`);
  }

  public onCaptchaExpire() {
    console.log(`Hello, reCAPTCHA expire`);
  }

  public onCaptchaLoad() {
    console.log(`Hello, reCAPTCHA load`);
  }

  public onCaptchaSuccess(token: string) {
    console.log(`Hello, reCAPTCHA success: ${token}`);
  }

  public onLoginMediumInputFocus(loginMedium: LoginMedium) {
    if (this.isFullFormVisible && this.loginMedium === loginMedium && !this.isLoading) {
      this.resetForm();
    }
  }

  public onOtpCompleted(value: string) {
    // Record the one-time password entered
    this.user.password = value;

    //
    // When the code is pasted into the input (e.g. via the iOS keyboard which automatically picks up SMS codes), we
    // receive several completed events. Using a debounce delay of 200ms to avoid submitting the form multiple times.
    //
    clearTimeout(this.otpDebounceTimeout);
    this.otpDebounceTimeout = setTimeout(() => void this.continue(), 200);
  }

  public continue(): Promise<void> {
    return this.strategy.continue();
  }

  public setLoginMedium(value: LoginMedium) {
    this.loginMedium = value;
    this.resetForm();
  }

  public abstract getUserConfirmation(prompt: string): boolean;

  public skipLogin() {
    this.user.email = `${new Date().getTime()}@example.com`;
    this.user.password = 'aBcd1234!';
    this.user.name = 'Test User';

    void this.emailPasswordStrategy.register();
  }

  public async logInAsGuest() {
    // Create a dummy email using a random GUID
    this.user.email = `guest_${GuidUtil.create()}@luggagehero.com`;
    this.user.password = 'aBcd1234!';
    this.user.name = 'Guest User';

    await this.emailPasswordStrategy.register();
  }

  public abstract signInWithApple(): Promise<void>;

  public async logInWithFacebook() {
    const facebookAuthResponse = await this.facebookLogin.login();

    if (!facebookAuthResponse) {
      return;
    }
    this.isLoading = true;

    try {
      // Authenticate user against our API with the Facebook credentials
      await this.userService.authenticateFacebookUser(facebookAuthResponse);

      // Authentication succeeded, get the user info and proceed
      await this.getUserAndRedirect();
    } catch (err) {
      this.error.handleError(err, this._translate('LOGIN_ERROR_MESSAGE'), this._translate('LOGIN_ERROR_TITLE'));
    }

    this.isLoading = false;
  }

  public async signInWithGoogle() {
    const googleAuthResponse = await this.googleSignin.signIn();

    if (!googleAuthResponse) {
      return;
    }

    try {
      await this.userService.authenticateGoogleUser(googleAuthResponse);
      await this.getUserAndRedirect();
    } catch (err) {
      this.error.handleError(err, this._translate('LOGIN_ERROR_MESSAGE'), this._translate('LOGIN_ERROR_TITLE'));
    }
    this.isLoading = false;
  }

  public async getUserAndRedirect() {
    // Reset model which clears the form via its bindings
    this.user = { email: '', password: '', name: '', phone: '', phoneCountry: '' };

    this.isExistingUser = true;
    this.form.markAsPristine();
    this.form.markAsUntouched();

    try {
      await this.userService.getUserAndRedirect();
    } catch (err) {
      this.error.handleError(
        err,
        this._translate(this.isExistingUser ? 'LOGIN_ERROR_MESSAGE' : 'REGISTER_USER_ERROR_MESSAGE'),
        this._translate(this.isExistingUser ? 'LOGIN_ERROR_TITLE' : 'REGISTER_USER_ERROR_TITLE'),
      );
    }
    this.isLoading = false;
  }

  public markForCheck() {
    this.cd.markForCheck();
  }

  private resetForm() {
    this.isFullFormVisible = false;
    this.form.markAsPristine();
    this.form.markAsUntouched();

    this.isUserEmailSelected = false;
    this.isUserEmailSelectedChange.emit(false);

    this.isUserPhoneSelected = false;
    this.isUserPhoneSelectedChange.emit(false);

    setTimeout(() => this.focusLoginMediumInput(), 100);
  }

  private focusLoginMediumInput() {
    if (this.loginMedium === 'email') {
      this.emailInput?.nativeElement.focus();
      this.emailInput?.nativeElement.select();
      return;
    }

    if (this.loginMedium === 'phone') {
      this.phoneInput?.setFocus();
      this.phoneInput?.setSelected();
      return;
    }
  }

  private loadForm() {
    const controlsConfig: { [key: string]: unknown } = {};

    // Email
    if (this.registerEmail !== 'disabled' || this.emailLogin !== 'disabled' || this.phoneLogin === 'disabled') {
      controlsConfig.email = [''];
    }

    // Phone
    if (this.registerPhoneNumber !== 'disabled' || this.phoneLogin !== 'disabled') {
      controlsConfig.phone = [''];
    }

    // Name
    if (this.registerName !== 'disabled') {
      controlsConfig.name = [''];
    }

    // Password
    if (this.registerPassword !== 'disabled' || [this.emailLogin, this.phoneLogin].includes('password')) {
      controlsConfig.password = [''];
    }

    // CAPTCHA
    if (this.requireCaptcha) {
      controlsConfig.captcha = [''];
    }

    this.form = this.formBuilder.group(controlsConfig);
    this.loadFormValidation();
  }

  private loadFormValidation() {
    if (!this.form) {
      return;
    }

    // Email
    if (this.form.controls.email) {
      this.form.controls.email.setValidators([Validators.pattern(AppConfig.EMAIL_REGEX)]);

      if (this.loginMedium === 'email' || this.registerEmail === 'required') {
        this.form.controls.email.addValidators(Validators.required);
      }
    }

    // Phone
    if (this.form.controls.phone) {
      this.form.controls.phone.setValidators([Validators.pattern(AppConfig.PHONE_REGEX)]);
      if (this.loginMedium === 'phone' || (this.registerPhoneNumber === 'required' && !this.isExistingUser)) {
        this.form.controls.phone.addValidators(Validators.required);
      }
    }

    // Name
    if (this.form.controls.name) {
      this.form.controls.name.setValidators([Validators.pattern(AppConfig.NAME_REGEX)]);
      if (this.registerName === 'required' && !this.isExistingUser) {
        this.form.controls.name.addValidators(Validators.required);
      }
    }

    // Password
    if (this.form.controls.password) {
      this.form.controls.password.clearValidators();

      if (
        this.registerPassword === 'required' ||
        (this.loginMedium === 'email' && this.emailLogin === 'password') ||
        (this.loginMedium === 'phone' && this.phoneLogin === 'password')
      ) {
        this.form.controls.password.setValidators([Validators.required]);
      }

      if (!this.isExistingUser) {
        this.form.controls.password.addValidators([Validators.minLength(8), SharedUtilPassword.passwordComplexity()]);
      }
    }

    // CAPTCHA
    if (this.form.controls.captcha) {
      if (this.requireCaptcha) {
        this.form.controls.captcha.setValidators([Validators.required]);
      } else {
        this.form.controls.captcha.clearValidators();
      }
    }

    this.form.updateValueAndValidity();
    this.cd.markForCheck();
  }
}
