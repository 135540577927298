<div class="modal-content">
  <div class="modal-header">
    <h4 class="modal-title pull-left">{{ 'SEARCH' | translate }}</h4>

    <button
      id="closeUniversalSearchModal"
      type="button"
      class="close pull-right"
      aria-label="Close"
      (click)="hideModal()"
    >
      <svg viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
        />
        <path d="M0 0h24v24H0z" fill="none" />
      </svg>
    </button>
  </div>

  <div class="modal-body">
    <div class="form-group">
      <input
        type="text"
        placeholder="{{ 'UNIVERSAL_SEARCH_OPTIONS' | translate }}"
        (input)="debouncedSearch($event.target.value)"
        class="form-control"
      />
    </div>

    <div class="search-results" *ngIf="isSerchResultAvailable">
      <lh-grouped-list [data]="groupedBookings" (itemSelected)="navigateToBooking($event.value)"></lh-grouped-list>
    </div>

    <div class="no-results" *ngIf="!isSerchResultAvailable && isSearchInitiatedByUser">
      <span *ngIf="isSearchQueryTooShort">{{ 'ENTER_MIN_CHARACTERS_TO_SEARCH' | translate }}</span>
      <span *ngIf="!isSearchQueryTooShort">{{ 'NO_SEARCH_RESULT_FOUND' | translate }}</span>
    </div>
  </div>
</div>
