import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

type LHButtonColor = 'orange' | 'white' | 'black' | 'gray-light';
type LHButtonSize = 'sm' | 'md';
type LHButtonStyle = 'solid' | 'outline' | 'solid-block' | 'icon' | 'text';

@Component({
  selector: 'lh-traveler-shops-ui-button',
  standalone: true,
  imports: [CommonModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['traveler-shops-ui-button.component.scss'],
  templateUrl: 'traveler-shops-ui-button.component.html',
})
export class TravelerShopsUiButtonComponent {
  @Input() color: LHButtonColor = 'orange';
  @Input() disabled = false;
  @Input() style: LHButtonStyle = 'solid';
  @Input() size: LHButtonSize = 'md';
  @Input() ariaLabel?: string;
  @Input() title?: string;

  @Output() clicked: EventEmitter<Event> = new EventEmitter<Event>();

  onClick(event: Event) {
    if (!this.disabled) {
      this.clicked.emit(event);
    }
  }
}
