import { ChangeDetectorRef, Component, EventEmitter, Input, NgZone, OnDestroy, OnInit, Output } from '@angular/core';
import { AppConfig } from '@luggagehero/shared/app-settings/data-access';
import { Config } from '@luggagehero/shared/environment';
import {
  BookableStorageLocation,
  DistanceUnit,
  IPricing,
  ITimeInterval,
  ITimePeriod,
  PricingModel,
  StorageCriteria,
} from '@luggagehero/shared/interfaces';
import { SharedDistanceService } from '@luggagehero/shared/services/distance';
import { SharedPricingService } from '@luggagehero/shared/services/pricing';
import { SharedShopsService } from '@luggagehero/shared/services/shops';
import { SharedStorageCriteriaService } from '@luggagehero/shared/services/storage-criteria';
import { SharedUtilDate } from '@luggagehero/shared/util';
import { Observable, Subscription } from 'rxjs';

import { BaseComponent } from '../../../core';
import { RouterExtensions } from '../../../core/services';

@Component({ template: '' })
export abstract class ShopListBaseComponent extends BaseComponent implements OnInit, OnDestroy {
  @Input() public isNoShopsAvailable = false;
  @Input() public numberOfShopsAvailable = 0;
  @Input() public batchSize = 6;

  @Input() public hideUnavailable: boolean;
  @Output() public hideUnavailableChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output() public highlightShopChange: EventEmitter<string> = new EventEmitter<string>();

  public imageUrl = '';
  public selectedShopId = '';
  public numberOfShopsShown = 0;

  private _criteria: StorageCriteria;
  private _isLoading = false;
  private _shops: BookableStorageLocation[] = [];
  private _highlightShop = '';
  private numberOfBatchesShown = 0;
  private criteriaChangeSubscription: Subscription;

  constructor(
    private shopsService: SharedShopsService,
    private criteriaService: SharedStorageCriteriaService,
    private distanceService: SharedDistanceService,
    private priceService: SharedPricingService,
    private router: RouterExtensions,
    private cd: ChangeDetectorRef,
    private ngZone: NgZone,
  ) {
    super();
    this.imageUrl = `${Config.environment.IMAGES_BASE_URL}/images`;
  }

  get shops(): BookableStorageLocation[] {
    return this._shops;
  }
  @Input() set shops(value: BookableStorageLocation[]) {
    this._shops = value;
    this.numberOfBatchesShown = 1;
    this.cd.markForCheck();
  }

  get distanceUnit(): Observable<DistanceUnit> {
    return this.distanceService.distanceUnit$;
  }

  get pricingModel(): Observable<PricingModel> {
    return this.priceService.pricingModel$;
  }

  get highlightShop(): string {
    return this._highlightShop;
  }
  @Input() set highlightShop(value: string) {
    this._highlightShop = value;
    this.cd.markForCheck();
  }

  get isLoading(): boolean {
    return this._isLoading;
  }
  @Input() set isLoading(value: boolean) {
    this._isLoading = value;
    this.cd.markForCheck();
  }

  get criteria(): StorageCriteria {
    return this._criteria;
  }
  set criteria(value: StorageCriteria) {
    this._criteria = value;
    this.cd.markForCheck();
  }

  get selectedPeriod(): ITimePeriod {
    if (!this.criteria) {
      return null;
    }
    return this.criteria.period;
  }

  get maxShopsToShow(): number {
    return this.batchSize * this.numberOfBatchesShown;
  }

  get pricing(): IPricing {
    return this.shopsService.latestPrice ? this.shopsService.latestPrice.pricings[0] : null;
  }

  get showDistanceUnitToggle(): boolean {
    if (AppConfig.IS_SHOP_LIST_DISTANCE_UNIT_TOGGLE_DISABLED) {
      return false;
    }
    return true;
  }

  get showPricingModelToggle(): boolean {
    if (AppConfig.IS_SHOP_LIST_PRICING_MODEL_TOGGLE_DISABLED) {
      return false;
    }
    return this.pricing ? true : false;
  }

  ngOnInit(): void {
    this.criteriaChangeSubscription = this.criteriaService.change.subscribe((value) => (this.criteria = value));
  }

  ngOnDestroy() {
    this.criteriaChangeSubscription.unsubscribe();

    super.ngOnDestroy();
  }

  getOpeningHoursFromDate(date: Date, shop: BookableStorageLocation): ITimeInterval[] {
    return this.shopsService.getOpeningHoursForDate(date, shop);
  }

  isNotSameDay(date1: Date, date2: Date) {
    if (date1 && date2) {
      return date1.toDateString() !== date2.toDateString();
    }
    return false;
  }

  onShowShopDetails(shop: BookableStorageLocation) {
    this.ngZone.run(() => {
      this.shopsService.setCurrent(shop);
      void this.router.navigate(this.getShopDetailsRouterLink(shop._id));
    });
  }

  navigateBookShop(id) {
    const shop = this.shops.find((s) => s._id === id);
    this.shopsService.setCurrent(shop);
    void this.router.navigate(['/book-shop', id]);
  }

  getShopDetailsRouterLink(shopId: string) {
    return [
      '/shop-details',
      shopId,
      {
        bags: this.criteria.luggage.normal,
        from: SharedUtilDate.serializeDate(this.selectedPeriod.from, false),
        to: SharedUtilDate.serializeDate(this.selectedPeriod.to, false),
        source: 'app',
      },
    ];
  }

  showShopDetails(id: string) {
    this.selectedShopId = id;
  }

  setHoverShop(shopId: string) {
    this.highlightShop = shopId;
    this.highlightShopChange.next(shopId);
  }

  listShops(hideUnavailable: boolean): BookableStorageLocation[] {
    if (!this.criteria || this.isLoading || !this.shops) {
      return [];
    }
    let shops = this.shops;
    if (hideUnavailable) {
      shops = shops.filter((shop) => shop.available);
    }
    const shopsToShow = shops.length > this.maxShopsToShow ? shops.slice(0, this.maxShopsToShow) : shops;
    this.numberOfShopsShown = shopsToShow.length;
    return shopsToShow;
  }

  showNextBatch() {
    this.numberOfBatchesShown++;
    this.cd.markForCheck();
  }

  toggleHideUnavailable(hideUnavailable: boolean) {
    this.hideUnavailable = hideUnavailable;
    this.hideUnavailableChange.next(hideUnavailable);
  }

  toggleDistanceUnit() {
    if (this.distanceService.distanceUnit === 'metric') {
      this.distanceService.changeDistanceUnit('imperial');
    } else {
      this.distanceService.changeDistanceUnit('metric');
    }
  }

  togglePricingModel() {
    if (this.priceService.pricingModel === 'hourly') {
      this.priceService.changePricing('daily');
    } else {
      this.priceService.changePricing('hourly');
    }
  }

  onShopSelected(shop: BookableStorageLocation) {
    this.shopsService.setCurrent(shop);
  }

  isRecommended(shop: BookableStorageLocation): boolean {
    return this.shopsService.isRecommended(shop);
  }

  abstract showHowItWorks(): Promise<void>;

  abstract showPriceInfo(): Promise<void>;
}
