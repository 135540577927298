import { ChangeDetectorRef, Component, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { ITimePeriod } from '@luggagehero/shared/interfaces';
import { SharedLoggingService } from '@luggagehero/shared/services/logging';
import { SharedStorageCriteriaService } from '@luggagehero/shared/services/storage-criteria';

import { BaseComponent } from '../../../core';
import { ModalService } from '../../../core/services/index';
import { IconService } from '../../../services/index';
import { DateUtil } from '../../../utils/date.util';
import { DateTimePickerBaseComponent } from './date-time-picker.base-component';

const noop = () => {
  /**/
};

@Component({ template: '' })
export abstract class TimePeriodPickerBaseComponent extends BaseComponent implements OnInit, ControlValueAccessor {
  @ViewChild('fromAnchor') fromAnchor: ElementRef<HTMLAnchorElement>;
  @ViewChild('fromPopup', { read: ElementRef }) fromPopup: ElementRef<HTMLElement>;
  abstract fromDateTimePicker: DateTimePickerBaseComponent;

  @ViewChild('toAnchor') toAnchor: ElementRef<HTMLAnchorElement>;
  @ViewChild('toPopup', { read: ElementRef }) toPopup: ElementRef<HTMLElement>;
  abstract toDateTimePicker: DateTimePickerBaseComponent;

  private _disabledDates: (date: Date) => boolean;
  private _useModal = false;
  private _value: ITimePeriod;
  private _from: Date;
  private _to: Date;

  private _showFrom = false;
  private _showTo = false;

  private _isCollapsed = true;
  private isInitialized = false;

  private onTouchedCallback: () => void = noop;
  private onChangeCallback: (_: unknown) => void = noop;

  constructor(
    private modalService: ModalService,
    private criteria: SharedStorageCriteriaService,
    private icon: IconService,
    private log: SharedLoggingService,
    private cd: ChangeDetectorRef,
  ) {
    super();
    this.cd.markForCheck();
  }

  get disabledDates(): (date: Date) => boolean {
    return this._disabledDates;
  }
  @Input() set disabledDates(value: (date: Date) => boolean) {
    this._disabledDates = value;
    this.cd.detectChanges();
  }

  get showFrom(): boolean {
    return this._showFrom;
  }
  set showFrom(value: boolean) {
    this._showFrom = value;
    this.cd.detectChanges();
  }

  get showTo(): boolean {
    return this._showTo;
  }
  set showTo(value: boolean) {
    this._showTo = value;
    this.cd.detectChanges();
  }

  get useModal(): boolean {
    return this._useModal;
  }
  @Input() set useModal(value: boolean) {
    this._useModal = value;
    this.cd.markForCheck();
  }

  get value(): ITimePeriod {
    return this._value;
  }
  set value(value: ITimePeriod) {
    if (!value || value === this._value) {
      return;
    }
    this._value = value;
    this._from = value.from;
    this._to = value.to;
    this.isInitialized = true;

    this.onChangeCallback(value);
    this.cd.markForCheck();
  }

  get from(): Date {
    return this._from;
  }
  set from(from: Date) {
    if (!from || DateUtil.isSameDate(from, this._from)) {
      return;
    }
    this._from = from;
    this._to = DateUtil.getValidTo(from, this._to, this.useModal);
    this.updateValue();

    // Close the view when from is selected as we are currently only using the from date
    this.toggleFromAndApply();
  }

  get to(): Date {
    return this._to;
  }
  set to(to: Date) {
    if (!to || DateUtil.isSameDate(to, this._to)) {
      return;
    }
    this._to = to;
    this._from = DateUtil.getValidFrom(this._from, to);
    this.updateValue();
  }

  get isCollapsed(): boolean {
    return this._isCollapsed;
  }
  set isCollapsed(value: boolean) {
    this._isCollapsed = value;
    this.cd.markForCheck();
  }

  ngOnInit() {
    this.cd.markForCheck();
  }

  // listen for click event and close the popups if matched
  @HostListener('document:click', ['$event'])
  documentClick(event: Event): void {
    if (!this.contains(event.target)) {
      this.toggleFrom(false);
      this.toggleTo(false);
    }
  }

  // toggle the from popup
  toggleFrom(show?: boolean): void {
    this.showFrom = show !== undefined ? show : !this.showFrom;
    // close to if from is visible
    if (this.showTo) {
      this.showTo = false;
    }
  }

  // toggle the to popup
  toggleTo(show?: boolean): void {
    this.showTo = show !== undefined ? show : !this.showTo;
    // close from if to is visible
    if (this.showFrom) {
      this.showFrom = false;
    }
  }

  // check if the target is either to or from popup
  private contains(target: EventTarget): boolean {
    if (this.toAnchor && this.toAnchor.nativeElement.contains(target as Node)) {
      return true;
    }
    if (this.toPopup && this.toPopup.nativeElement.contains(target as Node)) {
      return true;
    }
    if (this.fromAnchor && this.fromAnchor.nativeElement.contains(target as Node)) {
      return true;
    }
    if (this.fromPopup && this.fromPopup.nativeElement.contains(target as Node)) {
      return true;
    }
    return false;
  }

  // toggle the from popop and apply the values
  toggleToAndApply(): void {
    if (this.showTo) {
      // invoke the apply when closing the popup/clicking the apply button
      this.toDateTimePicker.apply();
    }
    this.toggleTo(!this.showTo);
  }

  // toggle the from popop and apply the values
  toggleFromAndApply(): void {
    if (this.showFrom) {
      // invoke the apply when closing the popup/clicking the apply button
      this.fromDateTimePicker.apply();
    }
    this.toggleFrom(!this.showFrom);
  }

  writeValue(value: ITimePeriod) {
    if (value !== undefined) {
      this.value = value;
    }
  }

  registerOnChange(fn: (_: unknown) => void) {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: () => void) {
    this.onTouchedCallback = fn;
  }

  private updateValue() {
    if (!this.isInitialized) {
      return;
    }
    this.value = { from: this._from, to: this._to };
  }
}
