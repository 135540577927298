import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { BookingIncidentType } from '@luggagehero/shared/interfaces';
import { SharedBookingService } from '@luggagehero/shared/services/bookings';
import { SharedNotificationService } from '@luggagehero/shared/services/notification';
import { SharedShopsService } from '@luggagehero/shared/services/shops';
import { SharedStorageService } from '@luggagehero/shared/services/storage';
import { TranslateService } from '@ngx-translate/core';

import { BookingActionBaseComponent } from './booking-action.base-component';

const MIN_COMMENT_LENGTH = 15;
const DISABLED_INCIDENT_TYPES: BookingIncidentType[] = [BookingIncidentType.UnableToFindLocation];

@Component({ template: '' })
export abstract class ReportIncidentActionBaseComponent extends BookingActionBaseComponent implements OnInit {
  public selectedIncident: BookingIncidentType;
  public isIncidentSelected = false;
  public acceptIncidentImage = false;
  public showStorageLocationTelephone = false;
  public isCancelBookingSelected = false;
  public incidentTypes = Object.values(BookingIncidentType).filter((i) => !DISABLED_INCIDENT_TYPES.includes(i));
  public comments: string;
  public showComments = false;
  public imageName: string = null;
  public remoteIncidentImage: string;
  public localIncidentImage: string;
  public storageLocationTelephoneNumber: string;

  public constructor(
    private translate: TranslateService,
    bookingService: SharedBookingService,
    shopsService: SharedShopsService,
    storageService: SharedStorageService,
    cd: ChangeDetectorRef,
    private notificationService: SharedNotificationService,
  ) {
    super(bookingService, shopsService, storageService, cd);

    this.selectedIncident = this.translate.instant('WHAT_HAPPENED') as BookingIncidentType;
  }

  async ngOnInit() {
    await super.ngOnInit();
    const shopDetails = await this.shopsService.getShop(this.booking.shopId);
    this.storageLocationTelephoneNumber = shopDetails.phone;
  }

  public get isSubmitAllowed(): boolean {
    if (this.isLoading) {
      return false;
    }
    if (this.acceptIncidentImage && !this.localIncidentImage) {
      return false;
    }
    return this.isIncidentSelected && this.comments && this.comments.length >= MIN_COMMENT_LENGTH;
  }

  public get isCancelBookingVisible(): boolean {
    if (!this.isIncidentSelected) {
      return false;
    }
    return ['CONFIRMED', 'CHECKED_IN'].includes(this.booking.status);
  }

  public get isLegacyBooking(): boolean {
    return this.booking?.schemaVersion < 3;
  }

  public get commentsPlaceholder(): string {
    const comments = this.translate.instant('COMMENTS') as string;
    const minChars = (this.translate.instant('MIN_15_CHARACTERS') as string).toLowerCase();
    return `${comments} (${minChars})`;
  }

  public selectIncident(value: BookingIncidentType) {
    this.selectedIncident = value;
    this.isIncidentSelected = true;

    if (this.selectedIncident === BookingIncidentType.UnableToFindLocation) {
      this.acceptIncidentImage = false;
      if (this.storageLocationTelephoneNumber) {
        this.showStorageLocationTelephone = true;
      }
    } else {
      this.acceptIncidentImage = true;
      this.showStorageLocationTelephone = false;
    }

    this.cd.markForCheck();
  }

  onLocalImageLoad() {
    URL.revokeObjectURL(this.localIncidentImage);
  }

  public async submitIncidentReport() {
    this.isLoading = true;

    try {
      let images: string[] = null;
      if (this.imageName) {
        images = [this.imageName];
      }
      const res = await this.bookingService.reportIncident(
        this.booking._id,
        this.selectedIncident,
        this.comments,
        images,
        this.isCancelBookingSelected,
        this.isLegacyBooking,
      );

      this.notificationService.chat(
        this.translate.instant('INCIDENT_REPORT_SUCCESS_BODY') as string,
        this.translate.instant('INCIDENT_REPORT_SUCCESS_TITLE') as string,
      );

      if (this.isCancelBookingSelected && res.status !== 'CANCELLED') {
        const cancelError = this.translate.instant('CANCEL_FAILED') as string;
        this.notificationService.error(cancelError);
      }

      this.updateBooking(res);
      this.close();
    } catch (err) {
      // TODO: Handle errors better
      this.notificationService.error((err as { message?: string }).message);
    }
    this.isLoading = false;
  }
}
