import { IWindow } from '@luggagehero/shared/interfaces';

export class WindowPlatformService implements IWindow {
  public wootric_survey_immediately: boolean;
  public wootric_no_surveyed_cookie: boolean;
  public wootricSettings: unknown = {};
  public Intercom = {} as (event: string, ...args: unknown[]) => unknown;
  public intercomSettings: unknown = {};
  public onresize: unknown = {};
  public ontouchstart: unknown = {};
  public onmsgesturechange: unknown = {};
  public navigator = {} as Navigator;
  public top: { location: { href: string } };
  public location = {} as Location;
  public localStorage: unknown;
  public innerWidth: number;
  public innerHeight: number;
  public screen: { availWidth: number; availHeight: number };
  public scrollX: number;
  public scrollY: number;
  public document: {
    body: { scrollHeight: number };
  };
  public currentUrl: string;
  public previousUrl: string;
  public readonly closed: boolean;

  public focus(): void {
    void 0;
  }

  public scroll(_x: number, _y: number) {
    void 0;
  }

  public alert(_msg: unknown, _title?: unknown) {
    void 0;
  }
  public confirm(_msg: unknown): unknown {
    return null;
  }

  public setTimeout(_handler: (...args: unknown[]) => void, _timeout?: number): number {
    return 0;
  }

  public clearTimeout(_timeoutId: number) {
    void 0;
  }

  public setInterval(_handler: (...args: unknown[]) => void, _ms?: number, ..._args: unknown[]): number {
    return 0;
  }

  public clearInterval(_intervalId: number) {
    void 0;
  }

  public open(_url?: string, _target?: string, _features?: string, _replace?: boolean): IWindow | null {
    return null;
  }

  public print(): void {
    void 0;
  }

  public back(): void {
    void 0;
  }
  public forward(): void {
    void 0;
  }

  // ...You can expand support for more window methods as you need them here...
}
