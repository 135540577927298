import { ChangeDetectionStrategy, Component } from '@angular/core';
import { DropoffBaseComponent } from '@luggagehero/features/shops';
import { Step, StepBaseComponent } from '@luggagehero/features/ui';

import { PaymentCardStepComponent, PhotoStepComponent, WelcomeStepComponent } from './steps';
import { ConfirmationStepComponent } from './steps/confirmation-step/confirmation-step.component';
import { ContactStepComponent } from './steps/contact-step/contact-step.component';
import { OrderStepComponent } from './steps/order-step/order-step.component';
import { PaymentStepComponent } from './steps/payment-step/payment-step.component';
import { TipStepComponent } from './steps/tip-step/tip-step.component';

@Component({
  selector: 'lh-dropoff',
  templateUrl: './dropoff.component.html',
  styleUrls: ['./dropoff.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class DropoffComponent extends DropoffBaseComponent {
  buildSteps(): Step<StepBaseComponent>[] {
    const steps: Step<StepBaseComponent>[] = [];

    steps.push({ component: WelcomeStepComponent, isStepCountableInNavigation: true });
    steps.push({ component: PhotoStepComponent, isStepCountableInNavigation: true });
    steps.push({ component: OrderStepComponent, isStepCountableInNavigation: true });

    if (this.storageLocation.isDropOffTipEnabled) {
      steps.push({ component: TipStepComponent, isStepCountableInNavigation: false });
    }

    steps.push({ component: PaymentStepComponent, isStepCountableInNavigation: true });

    // Push tip step here
    if (!this.isLoggedIn) {
      steps.push({ component: ContactStepComponent, isStepCountableInNavigation: true });
      steps.push({ component: PaymentCardStepComponent, isStepCountableInNavigation: false });
    }

    steps.push({ component: ConfirmationStepComponent, isStepCountableInNavigation: true });

    return steps;
  }
}
