import { HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Config } from '@luggagehero/shared/environment';
import { LoggingService } from '@luggagehero/shared/interfaces';
import map from 'lodash-es/map';

import { LogEvent, LOGGER_TOKEN, LogLevel, LogTarget } from './log.target';

@Injectable({
  providedIn: 'root',
})
export class SharedLoggingService implements LoggingService {
  constructor(@Inject(LOGGER_TOKEN) private targets: LogTarget[]) {}

  // debug (standard output)
  public async debug(...data: unknown[]): Promise<void> {
    if (Config.debug.LEVEL_4) {
      await Promise.allSettled(map(this.targets, (target) => target.log(this.parseData(data, LogLevel.Debug))));
    }
  }

  // error
  public async error(...data: unknown[]): Promise<void> {
    if (Config.debug.LEVEL_4 || Config.debug.LEVEL_3) {
      await Promise.allSettled(map(this.targets, (target) => target.log(this.parseData(data, LogLevel.Error))));
    }
  }

  // warn
  public async warn(...data: unknown[]): Promise<void> {
    if (Config.debug.LEVEL_4 || Config.debug.LEVEL_2) {
      await Promise.allSettled(map(this.targets, (target) => target.log(this.parseData(data, LogLevel.Warning))));
    }
  }

  // info
  public async info(...data: unknown[]): Promise<void> {
    if (Config.debug.LEVEL_4 || Config.debug.LEVEL_1) {
      await Promise.allSettled(map(this.targets, (target) => target.log(this.parseData(data, LogLevel.Info))));
    }
  }

  private parseData(data: unknown[], level: LogLevel): LogEvent {
    const event = data.reduce<LogEvent>(
      (acc: LogEvent, curr) => {
        if (typeof curr === 'string') {
          acc.message = `${acc.message} ${curr}`;
        }
        if (typeof curr === 'object') {
          if (acc.context == null) {
            acc.context = { ...curr };
          } else {
            acc.context = { ...acc.context, ...curr };
          }
        }
        if (curr instanceof Error) {
          acc.error = curr;

          if (acc.message == null || acc.message === '') {
            acc.message = curr.message;
          }
        }

        return acc;
      },
      {
        level,
        message: '',
      },
    );

    return event;
  }

  private parseArg(arg: unknown, event: LogEvent): void {
    try {
      if (arg instanceof Error || arg instanceof HttpErrorResponse) {
        event.error = arg;
        return;
      }

      if (Array.isArray(arg)) {
        arg.forEach((item) => this.parseArg(item, event));
        return;
      }

      if (typeof arg === 'object') {
        if (!event.context) {
          event.context = {};
        }
        Object.assign(event.context, arg);
        return;
      }

      const str = typeof arg === 'string' ? arg : String(arg);
      event.message = event.message ? `${event.message} | ${str}` : str;
    } catch (err) {
      console.error('Failed to parse log argument', err);
    }
  }
}
