<div class="timer" [class.timer-large-digits]="largeDigits" [class.timer-dark]="darkMode">
  <label [hidden]="!timerLabel || compactMode" class="timer-title label-sm">
    @if (timerLabel) {
      {{ timerLabel | lhTranslate }}
    }
  </label>

  @if (days) {
    <span>{{ days }}</span>
    <span>{{ daysLabel | lhTranslate }}</span>
  }

  <span>{{ hours }}</span>
  <span>{{ hoursLabel | lhTranslate }}</span>

  <span>{{ minutes }}</span>
  <span>{{ minutesLabel | lhTranslate }}</span>

  <span>{{ seconds }}</span>
  <span>{{ secondsLabel | lhTranslate }}</span>
</div>
