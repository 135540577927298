// taxes.actions.ts
import { CreateTaxPayload, DeleteTaxPayload, TaxItem, UpdateTaxPayload } from '@luggagehero/shared/interfaces';
import { createAction, props } from '@ngrx/store';

// Get Taxes
export const getTaxes = createAction('[Taxes] Get Taxes', props<{ storageLocationId: string }>());
export const getTaxesSuccess = createAction('[Taxes] Get Taxes Success', props<{ taxes: TaxItem[] }>());
export const getTaxesFailure = createAction('[Taxes] Get Taxes Failure', props<{ error: unknown }>());

// Add Tax
export const addTax = createAction('[Taxes] Add Tax', props<{ payload: CreateTaxPayload }>());
export const addTaxSuccess = createAction('[Taxes] Add Tax Success', props<{ taxItem: TaxItem }>());
export const addTaxFailure = createAction('[Taxes] Add Tax Failure', props<{ error: unknown }>());

// Update Tax
export const updateTax = createAction('[Taxes] Update Tax', props<{ payload: UpdateTaxPayload }>());
export const updateTaxSuccess = createAction('[Taxes] Update Tax Success', props<{ updatedTaxItem: TaxItem }>());
export const updateTaxFailure = createAction(
  '[Taxes] Update Tax Failure',
  props<{ error: unknown; taxItemId: string }>(),
);

// Delete Tax
export const deleteTax = createAction('[Taxes] Delete Tax', props<{ payload: DeleteTaxPayload }>());
export const deleteTaxSuccess = createAction('[Taxes] Delete Tax Success', props<{ taxItemId: string }>());
export const deleteTaxFailure = createAction(
  '[Taxes] Delete Tax Failure',
  props<{ error: unknown; taxItemId: string }>(),
);
