<div *ngFor="let listGroup of data" class="grouped-list">
  <div class="list-group-header">
    <i *ngIf="listGroup.icon" [class]="'icon-' + listGroup.icon"></i>
    {{ listGroup.name }}
  </div>

  <div class="list-items">
    <div
      *ngFor="let listItem of listGroup.items"
      class="list-item"
      [class.disabled]="listItem.disabled"
      [class.no-image]="!listItem.image && !listItem.imagePlaceholder"
      (click)="selectItem(listItem)"
    >
      <div class="list-item-image" (click)="showImage($event, listItem)">
        <div
          class="background-image"
          [class.no-image]="!listItem.image && !listItem.imagePlaceholder"
          [remoteImage]="listItem.image"
          [imagePlaceholder]="listItem.imagePlaceholder"
          [remoteImageSize]="'thumb'"
          lhBackgroundImage
        ></div>
        <!-- First icon -->
        <div
          class="list-item-icon"
          [class.no-image]="!listItem.image && !listItem.imagePlaceholder"
          *ngIf="listItem.icons && listItem.icons[0]"
        >
          <i [class]="listItem.icons[0].class"></i> {{ listItem.icons[0].text }}
        </div>
        <!-- Second icon (shown on hover) -->
        <div
          class="list-item-icon alt"
          [class.no-image]="!listItem.image && !listItem.imagePlaceholder"
          *ngIf="listItem.icons && listItem.icons[0]"
        >
          <!-- Use first icon when there is no image or second icon -->
          <span *ngIf="!listItem.icons[1] || !listItem.image">
            <i [class]="listItem.icons[0].class"></i> {{ listItem.icons[0].text }}
          </span>
          <!-- Use second icon if provided and there is an image -->
          <span *ngIf="listItem.icons[1] && listItem.image">
            <i [class]="listItem.icons[1].class"></i> {{ listItem.icons[1].text }}
          </span>
        </div>
      </div>
      <div class="list-item-content">
        <div class="list-item-header">
          <!-- Title and labels -->
          <span class="list-item-title">{{ listItem.title }}</span>

          <span class="list-item-labels">
            <span *ngFor="let label of listItem.labels" [class]="label.class" [tooltip]="label.tooltip">{{
              label.text
            }}</span>
          </span>
        </div>

        <!-- Main text contents of the item -->
        <div class="list-item-body">
          <span class="strong" *ngIf="listItem.content.strong">{{ listItem.content.strong }}</span>
          <span>{{ listItem.content.normal }}</span>
        </div>
      </div>
    </div>
  </div>
</div>
