<button
  id="applePayButton"
  role="button"
  tabindex="0"
  aria-label="ApplePay"
  class="btn btn-modern"
  [class.theme-light]="theme === 'light'"
  [class.theme-dark]="theme === 'dark'"
  [class.btn-info]="theme === 'light'"
  [class.btn-sm]="size === 'small'"
  [class.btn-md]="size === 'medium'"
  [class.btn-lg]="size === 'large'"
  [class.btn-rounded]="rounded"
  [class.elevated]="elevated"
  [class.selected]="selected"
  [disabled]="disabled || isLoading"
  (click)="onClick($event)"
>
  <!-- Ensure that the div is inflated also if there is no text to display -->
  <div>&nbsp;</div>

  <!-- The Apple Pay button will be rendered here -->
  <div
    class="apple-pay-button"
    [class.type-plain]="type === 'plain'"
    [class.type-book]="type === 'book'"
    [class.type-pay]="type === 'pay'"
  ></div>

  <!-- Here to make sure the content is centered as we have a leading space -->
  <div>&nbsp;</div>
</button>
