import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  forwardRef,
  Provider,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { StripeAccountFormBaseComponent } from '@luggagehero/features/ui';
import { SharedLoggingService } from '@luggagehero/shared/services/logging';
import { SharedUserService } from '@luggagehero/shared/services/users';

export const STRIPE_ACCOUNT_FORM_CONTROL_VALUE_ACCESSOR: Provider = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => StripeAccountFormComponent),
  multi: true,
};

@Component({
  selector: 'lh-stripe-account-form',
  templateUrl: './stripe-account-form.component.html',
  styleUrls: ['./stripe-account-form.component.scss'],
  providers: [STRIPE_ACCOUNT_FORM_CONTROL_VALUE_ACCESSOR],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StripeAccountFormComponent
  extends StripeAccountFormBaseComponent
  implements ControlValueAccessor, AfterViewInit
{
  constructor(userService: SharedUserService, log: SharedLoggingService, cd: ChangeDetectorRef) {
    super(userService, log, cd);
  }
}
