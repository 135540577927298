<div class="luggage-picker">
  <span
    >{{ value }}
    @if (value > 1) {
      <span>{{ 'BAGS' | lhTranslate }}</span>
    } @else {
      <span>{{ 'BAG' | lhTranslate }}</span>
    }
  </span>

  <!-- select -->
  <select [(ngModel)]="value" (change)="valueChange.emit(value)">
    <option *ngFor="let item of optionCount; let i = index" [value]="i + 1">
      {{ i + 1 }}
    </option>
  </select>
</div>
