<ng-template #projectedContent>
  <ng-content></ng-content>
</ng-template>

@if (nativeLookAndFeel) {
  <!-- Use Ionic -->
  <ion-button
    [type]="type"
    [disabled]="disabled"
    (click)="onClick($event)"
    class="native-button"
    [color]="nativeButtonColor"
    [fill]="nativeButtonFill"
    [size]="nativeButtonSize"
    [expand]="block ? 'block' : null"
  >
    <ng-container *ngTemplateOutlet="projectedContent"></ng-container>
  </ion-button>
} @else if (tailwind) {
  <!-- Use Tailwind CSS -->
  <button [type]="type" [disabled]="disabled" (click)="onClick($event)" [class]="tailwindClasses">
    <ng-container *ngTemplateOutlet="projectedContent"></ng-container>
    <lh-shared-ui-spinner [visible]="isLoading" [darkMode]="true"></lh-shared-ui-spinner>
  </button>
} @else {
  <!-- Use Bootstrap -->
  <button
    [type]="type"
    [disabled]="disabled"
    (click)="onClick($event)"
    class="btn btn-modern"
    [class.btn-sm]="size === 'small'"
    [class.btn-md]="size === 'medium'"
    [class.btn-lg]="size === 'large'"
    [class.btn-default]="style === 'default'"
    [class.btn-primary]="style === 'primary'"
    [class.btn-success]="style === 'success'"
    [class.btn-warning]="style === 'warning'"
    [class.btn-danger]="style === 'danger'"
    [class.btn-info]="style === 'info'"
    [class.btn-block]="block"
    [class.btn-round]="round && !block"
    [class.btn-rounded]="rounded"
    [class.justify-left]="textAlign === 'left'"
    [class.justify-center]="textAlign === 'center'"
    [class.justify-right]="textAlign === 'right'"
    [class.elevated]="shadow"
    [class.selected]="selected"
  >
    <div class="button-content">
      <ng-container *ngTemplateOutlet="projectedContent"></ng-container>

      @switch (icon) {
        @case ('forward') {
          <svg width="13" height="10" viewBox="0 0 13 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M8.48791 9.41541L7.43791 8.32791L10.1004 5.66541H0.987915V4.16541H10.1004L7.43791 1.50291L8.48791 0.415405L12.9879 4.91541L8.48791 9.41541Z"
              fill="currentColor"
            />
          </svg>
        }
        @case ('back') {
          <svg viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M20.016 11.016v1.969h-12.188l5.578 5.625-1.406 1.406-8.016-8.016 8.016-8.016 1.406 1.406-5.578 5.625h12.188z"
            ></path>
          </svg>
        }
      }
    </div>
  </button>
}
