import { EventEmitter, Injectable } from '@angular/core';
import { SharedAppSettings, SharedAppSettingsService } from '@luggagehero/shared/app-settings/data-access';
import { ILocation, ILuggage, ITimePeriod, StorageCriteria } from '@luggagehero/shared/interfaces';
import { SharedLocationService } from '@luggagehero/shared/services/locations';
import { SharedLoggingService } from '@luggagehero/shared/services/logging';
import { SharedUtilDate } from '@luggagehero/shared/util';
import { BehaviorSubject, Observable } from 'rxjs';

import { SharedStorageCriteria } from './shared-storage-criteria';

@Injectable({
  providedIn: 'root',
})
export class SharedStorageCriteriaService {
  public change: Observable<StorageCriteria>;
  public addressChange: Observable<string>;
  public changeRequested = new EventEmitter<StorageCriteria>();

  private _current: BehaviorSubject<StorageCriteria>;
  private _address: BehaviorSubject<string>;
  private _next: StorageCriteria;
  private _timeout: NodeJS.Timeout;

  constructor(
    private locationService: SharedLocationService,
    private appSeettingsService: SharedAppSettingsService,
    private log: SharedLoggingService,
  ) {
    this._current = new BehaviorSubject<SharedStorageCriteria>(null);
    this._address = new BehaviorSubject<string>(null);

    this.change = this._current.asObservable();
    this.change.subscribe((criteria) => void this.log.debug(`Criteria changed`, criteria));

    this.addressChange = this._address.asObservable();
  }

  get currentOrDefault(): StorageCriteria {
    return this.current || this.default;
  }

  get current(): StorageCriteria {
    return this._next || this._current.value;
  }
  set current(value: StorageCriteria) {
    this.updateCurrentCriteria(value, true);
  }

  get location(): ILocation {
    return this.current ? this.current.location : null;
  }
  set location(value: ILocation) {
    const current = this.currentOrDefault;
    this.current = new SharedStorageCriteria(value, current.period, current.luggage);
  }

  get period(): ITimePeriod {
    return this.current ? this.current.period : null;
  }
  set period(value: ITimePeriod) {
    const current = this.currentOrDefault;
    this.current = new SharedStorageCriteria(current.location, this.sanitizePeriod(value), current.luggage);
  }

  get from(): Date {
    return this.current ? this.current.period.from : null;
  }
  set from(value: Date) {
    const current = this.currentOrDefault;
    const to = SharedUtilDate.getValidTo(value, current.period.to);
    this.period = { from: value, to: to };
  }

  get to(): Date {
    return this.current ? this.current.period.to : null;
  }
  set to(value: Date) {
    const current = this.currentOrDefault;
    const from = SharedUtilDate.getValidFrom(current.period.from, value);
    this.period = { from: from, to: value };
  }

  get luggage(): ILuggage {
    return this.current ? this.current.luggage : null;
  }
  set luggage(value: ILuggage) {
    const current = this.currentOrDefault;
    this.current = new SharedStorageCriteria(current.location, current.period, value);
  }

  get default(): StorageCriteria {
    if (!this.locationService.isInitialized) {
      return null;
    }
    const location = this.getDefaultLocation();
    const period = this.getDefaultPeriod();
    const luggage = this.getDefaultLuggage();
    return new SharedStorageCriteria(location, period, luggage);
  }

  private get settings(): SharedAppSettings {
    return this.appSeettingsService.current;
  }

  public updateCurrentCriteria(value: StorageCriteria, debounce: boolean): void {
    const criteria = this.sanitizeCriteria(value);
    if (!criteria) {
      return;
    }
    this._next = criteria;

    // Immediately emit change request event so listeners can know changes are coming
    this.changeRequested.emit(this._next);

    clearTimeout(this._timeout);

    if (debounce) {
      // Avoid broadcasting changes repeatedly when many small adjustments are made quickly
      this._timeout = setTimeout(() => {
        this._current.next(this._next);
        this._next = null;
      }, 2000);
    } else {
      this._current.next(this._next);
      this._next = null;
    }

    if (this._next?.location?.address) {
      this._address.next(this._next.location.address);
    }
  }

  public updateAddress(address: string, region: string) {
    const current = this.currentOrDefault;
    // Create a new location object instead of mutating the existing one
    const newLocation: ILocation = { ...current.location, address, region };
    this.current = new SharedStorageCriteria(newLocation, current.period, current.luggage);
    this._address.next(address);
  }

  private getDefaultLocation(): ILocation | null {
    return this.locationService.nearestOfficialLocation;
  }

  private getDefaultPeriod(): ITimePeriod {
    let from = new Date();

    if (!this.settings.IS_REALTIME_AVAILABILITY_ENABLED) {
      if (from.getHours() < 12) {
        // Before 12pm move forward to 12pm
        from.setHours(12, 0, 0, 0);
      }

      if (from.getHours() >= 18) {
        // After 6pm move to 12pm next day
        from = SharedUtilDate.addDays(from, 1);
        from.setHours(12, 0, 0, 0);
      }

      from = SharedUtilDate.roundTimeHalfHour(from);
    }

    const to = SharedUtilDate.addHours(from, 1);

    return { from, to };
  }

  private getDefaultLuggage(): ILuggage {
    const minBags = this.settings.IS_BOOKING_MIN_BAGS_ENABLED ? this.settings.BOOKING_MIN_BAGS : 1;
    const luggage: ILuggage = {
      normal: Math.max(minBags, this.settings.BOOKING_DEFAULT_NUMBER_OF_BAGS),
      hand: 0,
    };
    return luggage;
  }

  private sanitizeCriteria(value: StorageCriteria): StorageCriteria {
    if (!value || (this._next && value.equals(this._next))) {
      // Value is empty or didn't change
      return null;
    }

    if (value.period) {
      value.period = this.sanitizePeriod(value.period);
    }

    return value;
  }

  private sanitizePeriod(value: ITimePeriod): ITimePeriod {
    value.to = SharedUtilDate.getValidTo(value.from, value.to);
    return value;
  }
}
