import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { Router } from '@angular/router';
import { SmartImage } from '@luggagehero/shared/interfaces';
import { SharedUiSpinnerComponent } from '@luggagehero/shared/ui';
import { TranslatePipe } from '@luggagehero/shared/ui-pipes';
import { SharedFeatureBagImageInputComponent } from '@luggagehero/shared-feature-bag-image-input';
import { SharedFeatureBookingsTimerComponent } from '@luggagehero/shared-feature-bookings-timer';

import { GuestDropoffStepBaseComponent } from '../guest-dropoff-step-base-component';
import { ConfirmationStepProperties } from './confirmation-step-properties';

@Component({
  selector: 'lh-confirmation-step',
  standalone: true,
  imports: [
    CommonModule,
    SharedFeatureBagImageInputComponent,
    SharedFeatureBookingsTimerComponent,
    SharedUiSpinnerComponent,
    TranslatePipe,
  ],
  templateUrl: './confirmation-step.component.html',
  styleUrl: './confirmation-step.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmationStepComponent extends GuestDropoffStepBaseComponent<ConfirmationStepProperties> {
  private router = inject(Router);

  public get remoteLuggageImage(): SmartImage {
    return this.bookingDraft.remoteLuggageImage;
  }
  public set remoteLuggageImage(value: SmartImage) {
    this.bookingDraft.remoteLuggageImage = value;
  }

  public get localLuggageImage(): string {
    return this.bookingDraft.localLuggageImage;
  }
  public set localLuggageImage(value: string) {
    this.bookingDraft.localLuggageImage = value;
  }

  public get accessCode(): string {
    return this.storageLocation?.storageRoomAccessCode || null;
  }

  public get accessText(): string {
    return this.storageLocation?.storageRoomAccessText || null;
  }

  public get isSelfStorage(): boolean {
    return this.accessCode || this.accessText ? true : false;
  }

  public get numberOfBags(): number {
    if (!this.bookingDraft.luggage) {
      return null;
    }
    return this.bookingDraft.luggage.normal;
  }

  public get defaultProperties(): ConfirmationStepProperties {
    if (this.isSelfStorage) {
      return {
        header: 'DROPOFF_CONFIRMED',
        body: this.accessText ? [this.accessText] : ['DEPOSIT_BAGS_IN_STORAGE_AREA'],
        forwardButtonLabel: 'PROCEED_TO_PICKUP',
        requireBooking: true,
        showStorageTimer: true,
        showLuggageImage: false,
      };
    }
    return {
      header: 'DROPOFF_CONFIRMED',
      body: ['SHOW_TO_HOTEL_STAFF_AND_HAND_OVER'],
      forwardButtonLabel: 'PROCEED_TO_PICKUP',
      requireBooking: true,
      showStorageTimer: false,
      showLuggageImage: true,
    };
  }

  protected onInit(): Promise<void> {
    // Nothing to initialize
    return Promise.resolve();
  }

  public async goForward(): Promise<void> {
    this.booking
      ? await this.router.navigate(['bookings', this.booking._id])
      : await this.router.navigate(['bookings', 'user', 'list']);
  }

  protected onCheckCanGoBack(): boolean {
    return false;
  }
}
