<div class="modal-content" *ngIf="isSuggestingOptionalProducts">
  <div class="modal-body">
    <lh-product-selector
      [bags]="order.bags"
      [currency]="order.currency"
      [availableProducts]="optionalProducts"
      [(ngModel)]="selectedProducts"
      (confirm)="onOptionalProductsSelected()"
    ></lh-product-selector>
  </div>
</div>

<div class="modal-content" *ngIf="isAskingForUserDetails && !isSuggestingOptionalProducts">
  <div class="modal-body">
    <!-- Email input -->
    <div *ngIf="isAskingForEmail">
      <p>{{ 'EMAIL_EXPLANATION' | translate }}</p>
      <form [formGroup]="emailForm">
        <div class="form-group">
          <input
            id="email"
            type="email"
            autocomplete="email"
            class="form-control"
            name="email"
            formControlName="email"
            [(ngModel)]="email"
            [placeholder]="'EMAIL_ADDRESS' | translate"
          />
          <div *ngIf="isEmailFormSubmitted && emailInputError" class="alert alert-danger">
            {{ emailInputError | translate }}
          </div>
        </div>
      </form>
    </div>

    <!-- Phone number input -->
    <div *ngIf="isAskingForPhoneNumber">
      <p>{{ 'SMS_EXPLANATION' | translate }}</p>
      <form [formGroup]="phoneNumberForm">
        <div class="form-group">
          <lh-phone-input
            id="phone"
            formControlName="phone"
            [advancedMode]="true"
            [(ngModel)]="phoneNumber"
            [placeholder]="'PHONE_NUMBER' | translate"
            [countries]="phoneCountries"
            [(selectedCountry)]="phoneCountry"
            [requestFocus]="true"
          >
          </lh-phone-input>
          <div *ngIf="isPhoneNumberFormSubmitted && phoneInputError" class="alert alert-danger">
            {{ phoneInputError | translate }}
          </div>
        </div>
      </form>

      <div class="small">{{ 'SMS_DELAY_EXPLANATION' | translate }}</div>
    </div>
  </div>

  <div class="modal-footer">
    <button
      [id]="isAskingForPhoneNumber ? 'continueBookingWitoutSms' : 'continueBookingWithoutEmail'"
      type="button"
      class="btn btn-info"
      (click)="confirmBooking()"
      [disabled]="isLoading"
      tabindex="-1"
    >
      {{ 'NO_THANKS' | translate }}
    </button>
    <button
      *ngIf="isAskingForPhoneNumber"
      id="confirmBookingWithSms"
      type="button"
      class="btn btn-primary"
      (click)="addPhoneNumber()"
      [disabled]="!isSubmitPhoneNumberEnabled"
    >
      {{ 'CONTINUE_WITH_SMS' | translate }}
    </button>

    <button
      *ngIf="isAskingForEmail"
      id="confirmBookingWithEmail"
      type="button"
      class="btn btn-primary"
      (click)="addEmail()"
      [disabled]="!isSubmitEmailEnabled"
    >
      {{ 'CONTINUE_WITH_EMAIL' | translate }}
    </button>
  </div>
</div>

<div class="modal-content" *ngIf="!isAskingForUserDetails && !isSuggestingOptionalProducts">
  <!-- Storage location -->
  <div
    class="storage-location-item"
    [class.compact]="isCompactModeEnabled"
    [class.collapsed]="isSelectingPaymentMethod"
  >
    <!-- Storefront image -->
    <div class="img-container">
      <img [src]="storageLocationImage" />
    </div>

    <!-- Header with distance to POI -->
    <div class="header">
      <div class="pre-title">{{ 'LUGGAGE_STORAGE' | translate }}</div>
      <div class="title">
        {{ distance | lhDistance: (distanceUnit | async) : false | async }} {{ fromSuffix | async }}
      </div>

      <!-- Rating -->
      <div class="rating">
        <rating [ngModel]="averageRating" [max]="numberOfStars" [readonly]="true"></rating>
        <span class="avg-rating">{{ averageRating | number: '1.1-1' }}</span>
        <span *ngIf="showNumberOfReviews" class="no-reviews"> ({{ numberOfReviews }})</span>
      </div>
    </div>

    <!-- Close button -->
    <button
      type="button"
      class="close pull-right"
      aria-label="Close"
      (click)="cancel()"
      *ngIf="!isSelectingPaymentMethod"
    >
      <svg viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
        />
        <path d="M0 0h24v24H0z" fill="none" />
      </svg>
    </button>

    <!-- Back button -->
    <button type="button" class="close pull-right" aria-label="Back" (click)="reset()" *ngIf="isSelectingPaymentMethod">
      <svg viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M20.016 11.016v1.969h-12.188l5.578 5.625-1.406 1.406-8.016-8.016 8.016-8.016 1.406 1.406-5.578 5.625h12.188z"
        ></path>
      </svg>
    </button>
  </div>

  <!-- Modal body -->
  <div class="modal-body main" [class.pull-up]="!isSelectingPaymentMethod">
    <!-- Dropoff / pickup dates -->
    <div class="section" [class.collapsed]="isSelectingPaymentMethod">
      <lh-simple-time-period-picker [storageLocation]="storageLocation"></lh-simple-time-period-picker>

      <div class="note">
        {{ 'CHOOSE_DATES_AND_CANCEL_EXPLANATION' | translate }}
        <lh-shared-ui-tooltip
          [callToAction]="'MORE_INFO' | translate"
          [header]="'OUR_BOOKINGS_ARE_FLEXIBLE' | translate"
          [body]="dropoffPickupTooltip"
        ></lh-shared-ui-tooltip>
      </div>
    </div>

    <!-- Number of bags -->
    <div class="section" [class.collapsed]="isSelectingPaymentMethod">
      <lh-simple-bag-picker [maxBags]="storageLocation.capacity.normal"></lh-simple-bag-picker>
      <div class="alert alert-success" *ngIf="minBagsWarning">{{ minBagsWarning }}</div>
    </div>

    <!-- Pricing model -->
    <div class="section" [class.collapsed]="isSelectingPaymentMethod">
      <lh-pricing-model-selector
        [simpleMode]="true"
        [hourlyRate]="hourlyRate"
        [dailyRate]="dailyRate"
        [currency]="currency"
      ></lh-pricing-model-selector>
    </div>

    <!-- Almost there -->
    <div class="section" [class.collapsed]="!isSelectingPaymentMethod">
      <div class="headline">{{ 'ALMOST_THERE' | translate }} 👏</div>
      <div>
        {{ 'CHOOSE_PAYMENT_METHOD_TO_CONFIRM_BOOKING_FOR_BAGS' | translate }}
        {{ criteria | shopCriteria: 'luggage' }} {{ criteria | shopCriteria: 'period' }}
        <a class="href" (click)="reset()">{{ 'MODIFY_BOOKING_SHORT' | translate }}</a>
      </div>
    </div>
  </div>

  <lh-ticket-stub dock="bottom">
    <lh-simple-order-summary
      [headline]="'SUMMARY' | translate"
      [isPaymentCardValidationEnabled]="isPaymentCardValidationVariant"
      [isPaid]="false"
      [isCollapsed]="isSelectingPaymentMethod"
      [isLoading]="isUpdating"
      [order]="order"
      [callToAction]="promoCodeCta | translate"
      (callToActionClick)="showPromoCodeModal()"
      (orderLineChange)="onOrderLineChange($event)"
    ></lh-simple-order-summary>

    <div class="sticky-footer">
      <!-- Confirm booking -->
      <button
        *ngIf="!isPaymentMethodSelectorVisible"
        [disabled]="isLoading || isUpdating || !canConfirmBooking"
        class="btn btn-block btn-warning btn-confirm"
        (click)="selectPaymentMethod()"
      >
        <lh-shared-ui-spinner [visible]="isLoading"></lh-shared-ui-spinner>
        <span *ngIf="!isLoading">{{ 'CONFIRM_BOOKING' | translate }}</span>
        <div class="btn-overlay-inflate" *ngIf="isLoading || isUpdating || !canConfirmBooking"></div>
      </button>

      <!-- Select payment method -->
      <lh-payment-method-selector
        *ngIf="isPaymentMethodSelectorVisible"
        [(ngModel)]="paymentMethod"
        [paymentMethods]="paymentMethods"
        [isPaymentDue]="isPaymentDue | async"
        [isConfirmingBooking]="true"
        [isSimpleMode]="isSimplePaymentSelectorMode"
        [isSelectingPaymentMethod]="isSelectingPaymentMethod"
        [isWalletPaymentAllowed]="isWalletPaymentAllowed"
        [order]="order"
        [payment]="payment"
        [storageLocation]="storageLocation"
        [disabled]="isLoading || isUpdating || !canConfirmBooking"
        (confirm)="onPaymentMethodSelected()"
        (paymentMethodAuthenticationError)="onPaymentMethodError($event)"
        #paymentMethodSelector
      ></lh-payment-method-selector>

      <lh-shared-ui-tooltip
        *ngIf="!isLoading && !canConfirmBooking"
        [body]="'LOCATION_NOT_AVAILABLE' | translate"
      ></lh-shared-ui-tooltip>
    </div>

    <div class="non-sticky-footer" *ngIf="footer">
      <div class="button-container">
        <button
          class="btn btn-block btn-info"
          (click)="selectPaymentMethod()"
          *ngIf="isUseDifferentPaymentMethodVisible"
        >
          {{ 'USE_OTHER_PAYMENT_METHOD' | translate }}
        </button>
      </div>

      <div class="footnote" [class.text-danger]="footer.isError" *ngIf="footer.text">
        {{ footer.text }}
        <a
          class="href"
          (click)="footer.action && footer.action()"
          [attr.target]="!footer.action && footer.link ? '_blank' : null"
          [attr.href]="!footer.action && footer.link ? footer.link : null"
          *ngIf="footer.callToAction && (footer.action || footer.link)"
          >{{ footer.callToAction }}</a
        >
      </div>
    </div>
  </lh-ticket-stub>
</div>
