import { CommonModule, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Review } from '@luggagehero/shared/interfaces';
import { SharedUiUserProfileImageComponent } from '@luggagehero/shared/ui';
import { MomentPipe, UppercaseFirstLetterPipe } from '@luggagehero/shared/ui-pipes';
import { TranslateModule } from '@ngx-translate/core';
import { RatingModule } from 'ngx-bootstrap/rating';

@Component({
  selector: 'lh-traveler-shops-ui-public-review',
  templateUrl: './traveler-shops-ui-public-review.component.html',
  styleUrls: ['./traveler-shops-ui-public-review.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    FormsModule,
    RatingModule,
    NgIf,
    TranslateModule,
    MomentPipe,
    SharedUiUserProfileImageComponent,
    UppercaseFirstLetterPipe,
  ],
  standalone: true,
})
export class PublicReviewComponent implements OnChanges {
  @Input() public review!: Review;
  @Input() public profileBackgroundColors: string[] = [];

  public userName = '';
  public body = '';
  public luggage = '';
  public isRated = false;
  public isRecommended = false;

  constructor(private cdr: ChangeDetectorRef) {}

  /**
   * Recompute display fields whenever inputs change.
   */
  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['review']) {
      this.update();
    }
  }

  /**
   * Populate display fields based on the current `review`.
   */
  private update(): void {
    if (!this.review) {
      return;
    }

    this.userName = this.computeUserName();
    this.body = this.computeBody();
    this.luggage = this.computeLuggage();
    this.isRated = this.computeIsRated();
    this.isRecommended = this.computeIsRecommended();

    this.cdr.markForCheck();
  }

  /**
   * Derives a display-friendly user name.
   * Returns `'TRAVELER'` as a fallback key if not a custom username.
   */
  private computeUserName(): string {
    if (this.review.userName && !this.review.userName.match(/guest|traveler/i)) {
      // Example: "John Smith" -> "John S."
      let name = this.review.userName.trim();
      const parts = name.split(' ');
      if (parts.length > 1) {
        name = `${parts[0]} ${parts[1].charAt(0)}`;
      }
      return name;
    }

    return 'TRAVELER';
  }

  /**
   * Derives the main body text for a review.
   * If not published, returns `'RATED_THIS_LOCATION'` as a translation key.
   * Otherwise, combines a trimmed headline and comments if available.
   */
  private computeBody(): string {
    try {
      if (!this.review.published) {
        return 'RATED_THIS_LOCATION';
      }

      const bodyParts: string[] = [];

      // Remove leading/trailing punctuation and whitespace from the headline and comments
      const headline = this.review.headline?.replace(/^[.,;:\s]+|[.,;:\s]+$/g, '');
      const comments = this.review.shopComments?.replace(/^[.,;:\s]+|[,;:\s]+$/g, '');
      if (headline != null) {
        if (headline?.length > 1 && !comments?.toLowerCase().startsWith(headline.toLowerCase())) {
          bodyParts.push(headline);
        }
      }

      if (comments != null) {
        if (comments?.length > 1) {
          bodyParts.push(comments);
        }
      }

      return bodyParts.join(' - ');
    } catch {
      // Do nothing
    }
    return 'Failed to load review';
  }

  private computeLuggage(): string {
    if (this.review.bookingBags == null) {
      return '';
    }

    const count = this.review.bookingBags;
    const bagKey = count === 1 ? 'BAG' : 'BAGS';

    // We store them combined with a '#' so the template can parse:
    // "2#BAGS" -> "2 {{ 'BAGS' | translate }}"
    return `${count}#${bagKey}`;
  }

  private computeIsRated(): boolean {
    return !!(this.review.rating && this.review.rating > 0);
  }

  private computeIsRecommended(): boolean {
    return (this.review.satisfaction ?? 0) > 0;
  }
}
