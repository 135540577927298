<div class="date-picker">
  <div class="date" (click)="dateClick()">
    <span>{{ selectedDate | lhMoment: (useModal ? 'calendar' : 'localeDateTime') | uppercaseFirstLetter }}</span>
  </div>
  @if (showCalendar) {
    <div class="calendar-wrapper">
      <kendo-calendar
        class="kendo-calendar"
        [min]="minDate"
        [max]="maxDate"
        [(value)]="selectedDate"
        [navigation]="false"
      ></kendo-calendar>
    </div>
  }
</div>
