import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  inject,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MapInfoWindow } from '@angular/google-maps';
import { ActivatedRoute, Params } from '@angular/router';
import { AppConfig } from '@luggagehero/shared/app-settings/data-access';
import {
  BookableStorageLocation,
  DistanceUnit,
  ILuggage,
  IPrice,
  IPricing,
  IShopReviewsComponent,
  ITimeInterval,
  LatLng,
  ReviewStats,
  StorageCriteria,
} from '@luggagehero/shared/interfaces';
import { SharedDistanceService } from '@luggagehero/shared/services/distance';
import { SharedDocumentService } from '@luggagehero/shared/services/document';
import { SharedErrorService } from '@luggagehero/shared/services/error';
import { SharedGoogleMapsService } from '@luggagehero/shared/services/google-maps';
import { SharedNotificationService } from '@luggagehero/shared/services/notification';
import { SharedPageTaggingService } from '@luggagehero/shared/services/page-tagging';
import { SharedParamsService } from '@luggagehero/shared/services/params';
import { SharedReviewsService } from '@luggagehero/shared/services/reviews';
import { SharedShopsService } from '@luggagehero/shared/services/shops';
import { SharedSplitTestingService } from '@luggagehero/shared/services/split-testing';
import { SharedStorageCriteria, SharedStorageCriteriaService } from '@luggagehero/shared/services/storage-criteria';
import { SharedThemeService } from '@luggagehero/shared/services/theme';
import { SharedWindowService } from '@luggagehero/shared/services/window';
import { TranslateService } from '@ngx-translate/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { firstValueFrom, forkJoin, map, Observable, Subscription } from 'rxjs';

import { BaseModalComponent } from '../../../core';
import { RouterExtensions } from '../../../core/services/index';
import { DateUtil } from '../../../utils/date.util';
import { GeoUtil } from '../../../utils/geo.util';

@Component({ template: '' })
export abstract class ShopDetailsBaseComponent extends BaseModalComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild(MapInfoWindow) public infoWindow: MapInfoWindow;
  @ViewChild('wifiModal') public wifiModal: ModalDirective;
  @ViewChild('discountModal') public discountModal: ModalDirective;
  @ViewChild('safetyModal') public safetyModal: ModalDirective;
  @ViewChild('reviewsModal') public reviewsModal: ModalDirective;
  @ViewChild('shopReviews') public shopReviews: IShopReviewsComponent;
  @ViewChild('shareStorageLocationAnchor') public shareStorageLocationAnchor: ElementRef;
  @ViewChild('reviewsElement') public reviewsElement: ElementRef<HTMLElement>;

  public shareStorageLocationOptionsVisible = false;
  public isFullOpeningHoursVisible = false;
  public isCriteriaExpanded = false;
  public defaultZoom = AppConfig.DEFAULT_SHOP_DETAILS_MAP_ZOOM;
  public shopId: string;
  public placeId: string;
  public source = 'unknown';

  protected _shop: BookableStorageLocation;
  private _shopLoaded = false;
  private forceShopReload = false;
  private _isLoading = false;
  // private imageUrl = '';
  private subscriptions: Subscription[] = [];
  private _averageRating: number;
  private _isDescriptionCollapsed = true;
  private isFirstLoad = true;
  private _globalStats: ReviewStats;
  private _isModal = false;
  private shareLink: string;
  private previousCriteria: StorageCriteria;

  private readonly splitTestingService = inject(SharedSplitTestingService);

  constructor(
    private themeService: SharedThemeService,
    private documentService: SharedDocumentService,
    private router: RouterExtensions,
    private route: ActivatedRoute,
    private shopsService: SharedShopsService,
    private criteriaService: SharedStorageCriteriaService,
    protected reviewsService: SharedReviewsService,
    private googleMapsService: SharedGoogleMapsService,
    private distanceService: SharedDistanceService,
    private translate: TranslateService,
    private paramsService: SharedParamsService,
    private tag: SharedPageTaggingService,
    private windowService: SharedWindowService,
    private notify: SharedNotificationService,
    private cd: ChangeDetectorRef,
    private error: SharedErrorService,
  ) {
    super();
    this.documentService.addBodyClass('shop-details');
  }

  _isShopDetailsUSPExperimentEnabled: boolean;
  get isShopDetailsUSPExperimentEnabled(): boolean {
    return this._isShopDetailsUSPExperimentEnabled;
  }
  private set isShopDetailsUSPExperimentEnabled(value: boolean) {
    this._isShopDetailsUSPExperimentEnabled = value;
    this.cd.markForCheck();
  }

  get isNative(): boolean {
    return this.windowService.isNative;
  }

  get isSharingAvailable(): boolean {
    return this.windowService.isSharingAvailable;
  }

  get isClipboardAvailable(): boolean {
    return this.windowService.isClipboardAvailable;
  }

  get maxZoom(): number {
    if (AppConfig.IS_SHOP_MAP_MAX_ZOOM_DISABLED) {
      return undefined;
    }
    return this.defaultZoom + 1;
  }

  get circleFillColor(): string {
    return this.themeService.mapCircleFillColor;
  }

  get circleStrokeColor(): string {
    return this.themeService.mapCircleStrokeColor;
  }

  get showDistance(): boolean {
    if (this.shop.distance === undefined || this.shop.distance === null) {
      return false;
    }
    // if (this.criteria.location.type === 'official') {
    //   return false;
    // }
    if (!this.criteria || !this.criteria.location || !this.criteria.location.radius) {
      return false;
    }
    const maxDistance = this.criteria.location.radius * 1000;
    return this.shop.distance <= maxDistance;
  }

  get showMapCallToAction(): boolean {
    if (AppConfig.IS_SHOP_DETAILS_MAP_CTA_DISABLED) {
      return false;
    }
    return true;
  }

  get isModal(): boolean {
    return this._isModal;
  }
  @Input() set isModal(value: boolean) {
    this._isModal = value;
    this.cd.markForCheck();
  }

  get globalStats(): ReviewStats {
    return this._globalStats;
  }
  set globalStats(value: ReviewStats) {
    this._globalStats = value;
    this.cd.markForCheck();
  }

  get myLocation(): google.maps.LatLngLiteral {
    if (!this.criteria?.location || this.criteria.location.type === 'official') {
      return null;
    }
    return {
      lat: this.criteria.location.lat,
      lng: this.criteria.location.lon,
    };
  }

  get mapCenter(): google.maps.LatLngLiteral {
    if (!this.shop) {
      return null;
    }
    return {
      lat: this.shop.location.coordinates[1] + 0.0005,
      lng: this.shop.location.coordinates[0],
    };
  }

  get mapOptions(): google.maps.MapOptions {
    return this.googleMapsService.mapOptions;
  }

  get mapStyles(): google.maps.MapTypeStyle[] {
    return this.googleMapsService.mapStyles;
  }

  get mapMarkerIcon() {
    const size = { width: 45, height: 66 };
    const iconConfig = {
      url: this.themeService.mapMarkerIcon,
      size: size,
      scaledSize: size,
    };
    return iconConfig;
  }

  get isTrustedPartner(): boolean {
    if (!this.shop.partnerLevel) {
      return false;
    }
    return this.shop.partnerLevel >= 1;
  }

  get ios(): boolean {
    return this.windowService.iOS;
  }

  get criteria(): StorageCriteria {
    return this.criteriaService.current;
  }
  set criteria(value: StorageCriteria) {
    if (!value) {
      return;
    }
    this.criteriaService.current = value;
    this.cd.markForCheck();
  }

  get isLoading(): boolean {
    return this._isLoading;
  }
  set isLoading(value: boolean) {
    this._isLoading = value;
    this.cd.markForCheck();
  }

  get isAvailableForBooking(): boolean {
    if (this.isLoading) {
      return false;
    }
    return this.shop.active;
  }

  get shop(): BookableStorageLocation {
    return this.isModal ? this.shopsService.current : this._shop;
  }
  @Input() set shop(value: BookableStorageLocation) {
    if (this.isModal) {
      this.shopsService.setCurrent(value);
    } else {
      this._shop = value;
    }
    this.cd.markForCheck();
  }

  get shopLoaded(): boolean {
    return this._shopLoaded;
  }
  set shopLoaded(value: boolean) {
    this._shopLoaded = value;
    this.cd.markForCheck();
  }

  get estimatedPrice(): IPrice {
    return this.shopsService.latestPrice ? this.shopsService.latestPrice.estimated : null;
  }

  get pricing(): IPricing {
    if (!this.shop) {
      return null;
    }
    return this.shop.pricing;
  }

  get numberOf5StarRatings(): number {
    return (this.shopReviews && this.shopReviews.perfectRatingsCount) || 0;
  }

  set luggage(value: ILuggage) {
    this.forceShopReload = true;
    this.criteria = new SharedStorageCriteria(this.criteria.location, this.criteria.period, value);
  }
  get luggage(): ILuggage {
    return this.criteria.luggage;
  }

  set averageRating(value: number) {
    this._averageRating = value;
    this.cd.markForCheck();
  }
  get averageRating(): number {
    return this._averageRating;
  }

  get isWifiInfoAvailable(): boolean {
    if (!this.shop || !this.shop.wifiInfo) {
      return false;
    }
    if (this.shop.wifiInfo.ssid && this.shop.wifiInfo.ssid !== '') {
      return true;
    }
    if (this.shop.wifiInfo.username && this.shop.wifiInfo.username !== '') {
      return true;
    }
    if (this.shop.wifiInfo.password && this.shop.wifiInfo.password !== '') {
      return true;
    }
    return false;
  }

  get truncatedDescription(): string {
    if (this.shop.description.length < 150) {
      return this.shop.description;
    }
    return `${this.shop.description.substring(0, 150)}...`;
  }

  get isDescriptionCollapsed(): boolean {
    return this._isDescriptionCollapsed;
  }
  set isDescriptionCollapsed(value: boolean) {
    this._isDescriptionCollapsed = value;
    this.cd.markForCheck();
  }

  get numberOfRatings(): number {
    return this.shopsService.getNumberOfRatings(this.shop);
  }

  get showShopRating(): boolean {
    if (!this.shop || !this.shop.stats) {
      return false;
    }
    return this.shop.stats.averageRating >= 1;
  }

  get poiListHtml(): Observable<string[]> {
    const pois = [...this.poiList];

    // If first POI is being displayed as the title, remove it from the list
    const start = this.isNearestPoiMostRelevant ? 1 : 0;
    const end = start + AppConfig.MAX_NUMBER_OF_POIS_TO_DISPLAY;

    const poisToDisplay = pois.slice(start, end);

    return forkJoin(poisToDisplay.map((poi) => this.distanceService.convertDistance(poi.distance, 'time', false))).pipe(
      map((distances) =>
        poisToDisplay.map((poi, index) => `<span class="text-warning">${distances[index]}</span> ➝ ${poi.poiName}`),
      ),
    );
  }

  get poiList() {
    if (!this.shop || !this.shop.poiList) {
      return [];
    }
    const pois = [...this.shop.poiList];

    return pois.map((poi) => {
      // HACK: Remove POI_ prefix which is added to some POI names
      poi.poiName = poi.poiName.replace('POI_', '');

      return poi;
    });
  }

  get isNearestPoiMostRelevant(): boolean {
    if (this.poiList.length === 0) {
      // No POIs nearby
      return false;
    }
    if (this.criteria.location.type === 'official') {
      // The center of the city is not relevant, use POI as reference point instead
      return true;
    }
    // If distance to current location is more than 500m, consider nearest POI more relevant
    return this.shop.distance > 500;
  }

  get distanceUnit(): Observable<DistanceUnit> {
    return this.distanceService.distanceUnit$;
  }

  get distance(): number {
    if (!this.shop) {
      return undefined;
    }
    if (this.isNearestPoiMostRelevant) {
      return this.poiList[0].distance;
    }
    return this.shop.distance;
  }

  get title(): string {
    const name = this.poiList?.length > 0 ? this.poiList[0].poiName : this.shop?.address?.city;
    return `LuggageHero ${name}`;
  }

  ngOnInit() {
    this.subscriptions.push(
      this.criteriaService.change.subscribe((criteria) => void this.onCriteriaChange(criteria)),
      this.criteriaService.changeRequested.subscribe(() => this.onCriteriaChangeRequested(this.criteria)),
      this.route.params.subscribe((params) => void this.parseParams(params)),
      // this.script.load('sumo').subscribe(),
    );

    this.tag.disableCrawling();
  }

  async ngAfterViewInit() {
    this.isShopDetailsUSPExperimentEnabled = await this.splitTestingService.isVariantEnabled(
      AppConfig.EXPERIMENT_VARIANTS.shopDetailsUSP,
    );
  }

  ngOnDestroy() {
    try {
      this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    } catch {
      // Ignore
    }

    this.documentService.removeBodyClass('shop-details');
    this.tag.clearTags();
  }

  onCriteriaChangeRequested(_criteria: StorageCriteria) {
    this.isLoading = true;
  }

  async onCriteriaChange(criteria: StorageCriteria) {
    try {
      if (this.previousCriteria?.equals(criteria)) {
        return; // Nothing changed
      }

      const shopId = this.shopId || this.shop?._id;
      if (!shopId && !this.placeId) {
        // Haven't parsed params yet (or invalid params)
        return;
      }

      let skipCache = false;

      if (!this.isFirstLoad) {
        this.updateUrl();

        // If the criteria was changed by the user, force a reload of the shop
        skipCache = true;
      }

      this.isFirstLoad = false;
      this.previousCriteria = criteria;

      if (this.isShopValid(this.shopsService.current) && !skipCache) {
        // Cached shop is still valid so no need to call API
        await this.onShopLoaded(this.shopsService.current);
      } else {
        // Need to fetch shop details from the API using current criteria
        const from = DateUtil.serializeDate(criteria.period.from);
        const to = DateUtil.serializeDate(criteria.period.to);
        const normal = criteria.luggage.normal;
        const hand = criteria.luggage.hand;

        const shop = shopId
          ? await this.shopsService.getAvailability(shopId, from, to, normal, hand, 'marketplace')
          : await this.shopsService.getAvailabilityByPlaceId(this.placeId, from, to, normal, hand);

        if (!shop) {
          // The shop no longer exists or none could be retrieve for the given place id
          void this.router.navigate(['/']);
        }

        if (shop && !shop.distance) {
          // Calculate distance of shop from currently selected location
          const shopLocation: LatLng = {
            lat: shop.location.coordinates[1],
            lng: shop.location.coordinates[0],
          };
          const userLocation: LatLng = { lat: criteria.location.lat, lng: criteria.location.lon };
          shop.distance = GeoUtil.getDistance(shopLocation, userLocation) * 1000;
        }
        await this.onShopLoaded(shop);
      }
    } finally {
      this.isLoading = false;
    }
  }

  isShopValid(shop: BookableStorageLocation): boolean {
    if (this.forceShopReload) {
      return false;
    }
    if (!shop || !this.shop || shop._id !== this.shop._id) {
      return false;
    }
    return true;
  }

  isMultidayStorageAllowed(storageLocation: BookableStorageLocation): boolean {
    return this.shopsService.isMultidayStorageAllowed(storageLocation);
  }

  viewFullMap() {
    if (!this.criteria) {
      return;
    }
    const params = {
      location: this.criteria.location.address,
      lat: SharedParamsService.serializeNumber(this.criteria.location.lat),
      lon: SharedParamsService.serializeNumber(this.criteria.location.lon),
      bags: this.criteria.luggage.normal,
      from: DateUtil.serializeDate(this.criteria.period.from),
      to: DateUtil.serializeDate(this.criteria.period.to),
      showMap: true,
      selectedShop: this.shop._id,
      geolocate: false,
    };
    void this.router.navigate(['/home', params]);
  }

  showWifiInfo() {
    this.wifiModal.show();
  }

  showSafetyInfo() {
    this.safetyModal.show();
  }

  showReviews() {
    this.reviewsElement.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }

  toggleShareStorageLocationOptions() {
    this.shareStorageLocationOptionsVisible = !this.shareStorageLocationOptionsVisible;
  }

  async getLinkForSharing(): Promise<string> {
    // TODO: Store in local or session storage
    if (!this.shareLink) {
      this.shareLink = await this.shopsService.getShareableLink(this.shop._id);
    }
    return this.shareLink;
  }

  async shareStorageLocationCopyToClipboard() {
    try {
      // Get shareable link for the current storage location and copy to the clipboard
      await this.windowService.writeToClipboard(this.shareLink || this.getLinkForSharing());

      // Notify the user that the link was copied
      this.notify.success(this.translate.instant('LINK_COPIED') as string);
    } catch (err) {
      // Notify the user that something went wrong
      this.notify.warning(`Error copying link: ${(err as { message?: string })?.message}`);
    }
  }

  async shareStorageLocationVia() {
    if (!this.isSharingAvailable) {
      return;
    }

    try {
      // Get the url to share
      const url = await this.getLinkForSharing();

      // Use the current location as the reference point if it's a Google Place and the storage location is close to it
      const useCurrentLocation = this.criteria.location.type === 'place' && this.shop.distance < 500;

      // Otherwise, use the nearest POI if available and the storage location is close to it
      const nearestPoi = this.poiList && this.poiList.length > 0 && this.poiList[0];
      const useNearestPoi = !useCurrentLocation && nearestPoi && nearestPoi.distance < 500;

      // Compile the text to share
      let text: string;

      const checkOutThisStorageLocationText = this.translate.instant('CHECK_OUT_THIS_STORAGE_LOCATION') as string;

      if (useCurrentLocation) {
        const distanceText = await firstValueFrom(
          this.distanceService.convertDistance(this.distance, this.distanceService.distanceUnit, false),
        );
        const fromSuffix = await firstValueFrom(this.distanceService.getFromSuffix());
        text = `${checkOutThisStorageLocationText} ${distanceText} ${fromSuffix}`;
      } else if (useNearestPoi) {
        const distanceText = await firstValueFrom(
          this.distanceService.convertDistance(nearestPoi.distance, this.distanceService.distanceUnit, false),
        );
        const fromSuffix = `${(this.translate.instant('FROM') as string).toLowerCase()} ${nearestPoi.poiName}`;
        text = `${checkOutThisStorageLocationText} ${distanceText} ${fromSuffix}`;
      } else {
        // If neither using the current location or a nearby POI, fall back to using the storage location city
        text = `${checkOutThisStorageLocationText} ${this.translate.instant('IN')} ${this.shop.address.city}`;
      }

      await this.windowService.shareLink(url, text);
    } catch (err) {
      this.notify.warning(`Sharing failed, please check your connection and try again`);
    }
  }

  isNotSameDay(date1: Date, date2: Date) {
    if (date1 && date2) {
      return date1.toDateString() !== date2.toDateString();
    }
    return false;
  }

  showHolidayWarning(date: Date): boolean {
    return this.shopsService.showHolidayWarning(date, this.shop);
  }

  showConfirmedHolidayHours(date: Date): boolean {
    return this.shopsService.isHoliday(date, this.shop) && this.shopsService.hasConfirmedHours(date, this.shop);
  }

  getHolidayName(date: Date): string {
    return this.shopsService.getHolidayName(date, this.shop);
  }

  getOpeningHoursFromDate(date: Date, shop: BookableStorageLocation): ITimeInterval[] {
    return this.shopsService.getOpeningHoursForDate(date, shop);
  }

  toggleFullOpeningHours() {
    this.isFullOpeningHoursVisible = !this.isFullOpeningHoursVisible;
    this.cd.markForCheck();
  }

  expandCriteria() {
    this.isCriteriaExpanded = true;
    this.cd.markForCheck();
  }

  collapseCriteria() {
    this.isCriteriaExpanded = false;
    this.cd.markForCheck();
  }

  makeBooking() {
    if (!this.isAvailableForBooking) {
      return;
    }
    this.shopsService.setCurrent(this.shop);
    void this.showConfirmBooking();
  }

  public abstract showConfirmBooking(): Promise<void>;

  public abstract showPriceInfo(): Promise<void>;

  public abstract showDiscountOptions(): Promise<void>;

  chooseAnotherShop() {
    const params = {
      location: this.criteria.location.address,
      lat: SharedParamsService.serializeNumber(this.criteria.location.lat),
      lon: SharedParamsService.serializeNumber(this.criteria.location.lon),
      bags: this.criteria.luggage.normal,
      from: DateUtil.serializeDate(this.criteria.period.from),
      to: DateUtil.serializeDate(this.criteria.period.to),
    };
    void this.router.navigate(['/home', params]);
  }

  private updateUrl() {
    // if (this.shop._id) {
    //   const commands = ['/shop-details', this.shop._id, {
    //     bags: this.criteria.luggage.normal,
    //     from: DateUtil.serializeDate(this.criteria.period.from, false),
    //     to: DateUtil.serializeDate(this.criteria.period.to, false),
    //     source: 'app'
    //   }];
    //   this.router.navigate(commands);
    // }
  }

  private async onShopLoaded(shop: BookableStorageLocation) {
    if (!shop) {
      this.error.handleError(new Error('Shop not found'));
      return;
    }
    this.globalStats = await this.reviewsService.getStats();

    this.shop = shop;
    this.shopLoaded = true;
    this.forceShopReload = false;
    this.isLoading = false;

    const available = this.shopsService.isShopAvailable(this.shop, this.criteria);
    this.shop.available = available;

    if (this.shop.stats?.averageRating) {
      console.log(`Setting average rating from shop stats ${this.shop.stats.averageRating}`, shop.stats);
      this.averageRating = this.shop.stats.averageRating;
    } else {
      console.log(
        `No stats for shop ${this.shop._id}, setting average rating from global stats ${this.globalStats.averageRating}`,
      );
      this.averageRating = this.globalStats.averageRating;
    }
    // this.tag.addShopTags(this.shop);

    // HACK: This fixes rating component not showing full stars until
    // something else triggers change detection
    setTimeout(() => this.cd.markForCheck(), 100);
  }

  private async parseParams(params: Params) {
    if (!params.id) {
      // TODO: Show error
      return;
    }

    if (params.preview) {
      // Treat as a google place id
      this.placeId = String(params.id);
    } else {
      // Treat as a normal storage location id
      this.shopId = String(params.id);
    }

    if (params.source) {
      this.source = (<string>params.source).toLowerCase();
    }
    const p = await this.paramsService.parseFindShop(params);
    this.criteria = p ? p.criteria : this.criteriaService.currentOrDefault;
    void this.onCriteriaChange(this.criteria);
  }
}
