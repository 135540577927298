import { DecimalPipe } from '@angular/common';
import { HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AnalyticsModule } from '@luggagehero/analytics/analytics.module';
import { CoreModule, IConsole, ModalService, PlatformLanguageToken } from '@luggagehero/core';
import { TaxesEffects } from '@luggagehero/dashboard-taxes-data-access';
import { OrdersEffects } from '@luggagehero/guest-data-access-orders';
import { LanguageProviders, Languages, MultilingualEffects } from '@luggagehero/i18n';
import { MultilingualModule } from '@luggagehero/i18n/multilingual.module';
import { metaReducers, reducers } from '@luggagehero/ngrx';
import { LUGGAGEHERO_PROVIDERS } from '@luggagehero/services';
import { AppConfig, SharedAppSettingsService } from '@luggagehero/shared/app-settings/data-access';
import { Config } from '@luggagehero/shared/environment';
import {
  BOOKING_TRANSFORMATION_SERVICE,
  DISCOUNT_TRANSFORMER_SERVICE,
  IWindow,
  MOMENT_SERVICE,
  PAYMENT_METHOD_TRANSFORMER_SERVICE,
  PRICING_SERVICE,
  TIME_INTERVAL_TRANSFORMER_SERVICE,
  TRANSLATE_SERVICE,
} from '@luggagehero/shared/interfaces';
import { SHARED_APP_EVENT_SERVICE_CONFIG_TOKEN } from '@luggagehero/shared/services/app-events';
import { SharedBookingTransformationService } from '@luggagehero/shared/services/booking-transformation';
import { SharedDiscountTransformerService } from '@luggagehero/shared/services/discount-transformer';
import { SHARED_GEOCODING_SERVICE_CONFIG_TOKEN } from '@luggagehero/shared/services/geocoding';
import { SHARED_HTTP_SERVICE_CONFIG_TOKEN } from '@luggagehero/shared/services/http';
import {
  ConsoleService,
  ConsoleTarget,
  LOG_PROVIDERS,
  LogLevel,
  SharedLoggingService,
} from '@luggagehero/shared/services/logging';
import { SharedMomentService } from '@luggagehero/shared/services/moment';
import { SharedNotificationModule } from '@luggagehero/shared/services/notification';
import { SharedPaymentMethodTransformerService } from '@luggagehero/shared/services/payment-method-transformer';
import { SharedPricingService } from '@luggagehero/shared/services/pricing';
import { SHARED_STORAGE_SERVICE_CONFIG } from '@luggagehero/shared/services/storage';
import { SharedTimeIntervalTransformerService } from '@luggagehero/shared/services/time-interval-transformer';
import {
  createTranslateLoader,
  createTranslateParser,
  SharedTranslateService,
  SharedTranslationsService,
} from '@luggagehero/shared/services/translation';
import { WindowPlatformService } from '@luggagehero/shared/services/window';
import { throwIfAlreadyLoaded } from '@luggagehero/utils';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TranslateLoader, TranslateParser } from '@ngx-translate/core';
import { ModalModule } from 'ngx-bootstrap/modal';
import { RatingModule } from 'ngx-bootstrap/rating';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';

import { PROVIDERS } from './services';
import { WebModalService } from './services/web-modal.service';

declare let window: IWindow, console: IConsole;

// For AoT compilation to work
export function windowFactory() {
  return window;
}

export function consoleFactory() {
  return console;
}

export function consoleLogTarget(consoleService: ConsoleService) {
  return new ConsoleTarget(consoleService, { minLogLevel: LogLevel.Debug });
}

export function platformLangFactory() {
  const browserLang = window.navigator.language || 'en'; // fallback English
  // browser language has 2 codes, ex: 'en-US'
  return browserLang.split('-')[0];
}

const storeDevtoolsModule = !Config.isProduction
  ? StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      logOnly: !Config.isDevelopment, // Restrict extension to log-only mode
      autoPause: true, // Pauses recording actions and state changes when the extension window is not open
      trace: Config.isDevelopment, //  If set to true, will include stack trace for every dispatched action, so you can see it in trace tab jumping directly to that part of code
      traceLimit: 75, // maximum stack trace frames to be stored (in case trace option was provided as true)
      connectInZone: true, // If set to true, the connection is established within the Angular zone
    })
  : [];

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CoreModule.forRoot([
      {
        provide: PlatformLanguageToken,
        useFactory: platformLangFactory,
      },
      {
        provide: WindowPlatformService,
        useFactory: windowFactory,
      },
      {
        provide: ConsoleService,
        useFactory: consoleFactory,
      },
      {
        provide: ModalService,
        useClass: WebModalService,
      },
      ...LOG_PROVIDERS,
    ]),
    AnalyticsModule,
    SharedNotificationModule,
    MultilingualModule.forRoot([
      {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [SharedTranslationsService, SharedAppSettingsService, HttpClient],
      },
      {
        provide: TranslateParser,
        useFactory: createTranslateParser,
        deps: [SharedTranslationsService, SharedLoggingService],
      },
    ]),
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: { strictStateImmutability: true, strictActionImmutability: true },
    }),
    EffectsModule.forRoot([MultilingualEffects, TaxesEffects, OrdersEffects]),
    TypeaheadModule.forRoot(),
    RatingModule.forRoot(),
    ModalModule.forRoot(),
    storeDevtoolsModule,
  ],
  providers: [
    DecimalPipe,
    ...PROVIDERS,
    ...LUGGAGEHERO_PROVIDERS,
    LanguageProviders,
    // override with supported languages
    {
      provide: Languages,
      useFactory: () => Config.supportedLanguages,
      deps: [],
    },
    {
      provide: SHARED_STORAGE_SERVICE_CONFIG,
      useFactory: () => ({
        domain: Config.isDevelopment ? 'localhost' : 'luggagehero.com',
      }),
    },
    {
      provide: SHARED_HTTP_SERVICE_CONFIG_TOKEN,
      useFactory: () => {
        return {
          usesLegacyHttpMode: () => {
            return AppConfig.LEGACY_HTTP_MODE;
          },
        };
      },
    },
    {
      provide: SHARED_GEOCODING_SERVICE_CONFIG_TOKEN,
      useFactory: () => {
        return {
          apiURL: () => Config.environment.TRAVELER_API,
        };
      },
    },
    {
      provide: SHARED_APP_EVENT_SERVICE_CONFIG_TOKEN,
      useFactory: () => {
        return {
          apiURL: () => `${Config.environment.TRAVELER_API}/v2`,
        };
      },
    },
    SharedTranslateService,
    { provide: TRANSLATE_SERVICE, useExisting: SharedTranslateService },
    { provide: PRICING_SERVICE, useExisting: SharedPricingService },
    { provide: MOMENT_SERVICE, useExisting: SharedMomentService },
    { provide: BOOKING_TRANSFORMATION_SERVICE, useExisting: SharedBookingTransformationService },
    { provide: DISCOUNT_TRANSFORMER_SERVICE, useExisting: SharedDiscountTransformerService },
    { provide: TIME_INTERVAL_TRANSFORMER_SERVICE, useExisting: SharedTimeIntervalTransformerService },
    { provide: PAYMENT_METHOD_TRANSFORMER_SERVICE, useExisting: SharedPaymentMethodTransformerService },
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class LhCoreModule {
  constructor(
    @Optional()
    @SkipSelf()
    parentModule: LhCoreModule,
  ) {
    throwIfAlreadyLoaded(parentModule, 'LhCoreModule');
  }
}
