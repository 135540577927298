import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ViewImageBaseComponent } from '@luggagehero/features/ui';

@Component({
  selector: 'lh-view-image',
  templateUrl: './view-image.component.html',
  styleUrls: ['./view-image.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ViewImageComponent extends ViewImageBaseComponent {}
