import { Location as AngularLocation } from '@angular/common';
import { Injectable } from '@angular/core';
import { Router, RoutesRecognized } from '@angular/router';
import { Config } from '@luggagehero/shared/environment';
import { AppInfo, BrowserName, IWindow, WindowSizeInfo } from '@luggagehero/shared/interfaces';
import { SharedUtilWindow } from '@luggagehero/shared/util';
import { BehaviorSubject, filter, Observable, pairwise } from 'rxjs';

import { isIOS, isNativeScript } from './platform.helper';
import { WindowPlatformService } from './window-platform-service';

@Injectable({
  providedIn: 'root',
})
export class SharedWindowService implements IWindow {
  public size: BehaviorSubject<WindowSizeInfo>;
  public browserName: BrowserName;
  public browserVersion: number;
  public isAppUpdateRequired = false;

  private _currentUrl: string;
  private _previousUrl: string;
  private _appVersion: string;
  private _appBuildId: string;
  private _appName: string;
  private _isSharingAvailable = false;
  private _isClipboardAvailable = false;
  private _initTask: Promise<void>;

  public get wootricSettings(): unknown {
    return this.window.wootricSettings;
  }
  public set wootricSettings(value: unknown) {
    this.window.wootricSettings = value;
  }

  public get wootric_survey_immediately(): boolean {
    return this.window.wootric_survey_immediately;
  }
  public set wootric_survey_immediately(value: boolean) {
    this.window.wootric_survey_immediately = value;
  }

  public get wootric_no_surveyed_cookie(): boolean {
    return this.window.wootric_no_surveyed_cookie;
  }
  public set wootric_no_surveyed_cookie(value: boolean) {
    this.window.wootric_no_surveyed_cookie = value;
  }

  public get Intercom() {
    return this.window.Intercom;
  }

  public get intercomSettings() {
    return this.window.intercomSettings;
  }
  public set intercomSettings(value: unknown) {
    this.window.intercomSettings = value;
  }

  public get navigator(): Navigator {
    return this.window.navigator;
  }

  public get isSharingAvailable(): boolean {
    return this._isSharingAvailable;
  }

  public get isClipboardAvailable(): boolean {
    return this._isClipboardAvailable;
  }

  public get location(): Location {
    return this.window.location;
  }

  public get currentUrl(): string {
    return this._currentUrl;
  }

  public get previousUrl(): string {
    return this._previousUrl;
  }

  public get closed(): boolean {
    return this.window.closed;
  }

  public get currentWindowSize(): WindowSizeInfo {
    return new WindowSizeInfo(
      this.window.innerWidth,
      this.window.innerHeight,
      this.window.screen.availWidth,
      this.window.screen.availHeight,
    );
  }

  public get innerWidth(): number {
    return this.window.innerWidth;
  }

  public get innerHeight(): number {
    return this.window.innerHeight;
  }

  public get scrollX(): number {
    return this.window.scrollX;
  }

  public get scrollY(): number {
    return this.window.scrollY;
  }

  public get isIonic(): boolean {
    return false;
  }

  public get isNative(): boolean {
    return false;
  }

  public get platform(): string {
    return 'web';
  }

  public get appVersion(): string {
    return this._appVersion;
  }

  public get appBuildId(): string {
    return this._appBuildId;
  }

  public get appName(): string {
    return this._appName;
  }

  public get isTouch(): boolean {
    return 'ontouchstart' in this.window || 'onmsgesturechange' in this.window;
  }

  public get iOS(): boolean {
    if (isNativeScript()) {
      return isIOS();
    }
    return !!this.navigator.platform && /iPad|iPhone|iPod/.test(this.navigator.platform);
  }

  public get locale(): string {
    return this.navigator.language;
  }

  public get unicodeRegEx(): boolean {
    if (this.checkBrowser('Safari', 11, false)) {
      // Safari 10 and earlier don't support the unicode flag in regex constructors
      return false;
    }
    return true;
  }

  /**
   * Compares two version strings in the format `"x.y.z"` where `x`, `y` and `z` are integers. If the version strings
   * have a different number of parts, they are not comparable and the function returns 0.
   *
   * @param version1 The first version string
   * @param version2 The second version string
   * @returns 1 if `version1` is greater than `version2`, -1 if `version1` is less than `version2`, 0 if they are equal
   */
  public static compareVersions(version1: string, version2: string): number {
    if (version1 === version2) {
      // The version strings are equal
      return 0;
    }

    const parts1 = version1?.split('.');
    const parts2 = version2?.split('.');

    if (parts1?.length !== parts2?.length) {
      // If the version strings have different number of parts, they are not comparable
      return 0;
    }

    for (let i = 0; i < parts1.length; i++) {
      const part1 = Number(parts1[i]);
      const part2 = Number(parts2[i]);

      if (part1 > part2) {
        return 1;
      } else if (part1 < part2) {
        return -1;
      }
    }

    return 0;
  }

  constructor(
    private window: WindowPlatformService,
    private ngRouter: Router,
    private ngLocation: AngularLocation,
  ) {
    this.size = new BehaviorSubject<WindowSizeInfo>(this.currentWindowSize);
    if ('onresize' in this.window) {
      this.window.onresize = () => this.size.next(this.currentWindowSize);
    }

    // Store URLs for help with Angular navigation
    ngRouter.events
      .pipe(
        filter((event) => event instanceof RoutesRecognized),
        pairwise<RoutesRecognized>(),
      )
      .subscribe((events) => {
        this._previousUrl = events[0].urlAfterRedirects;
        this._currentUrl = events[1].urlAfterRedirects;
      });
  }

  public async ready(): Promise<void> {
    return this._initTask;
  }

  public async init(appInfo: AppInfo): Promise<void> {
    if (this._initTask === undefined) {
      this._initTask = this._init(appInfo);
    }
    await this._initTask;
  }

  private async _init(appInfo: AppInfo): Promise<void> {
    this._appVersion = appInfo.version;
    this._appBuildId = appInfo.buildId;
    this._appName = appInfo.name ? (typeof appInfo.name === 'string' ? appInfo.name : String(appInfo.name)) : '';

    this.initBrowserInfo();

    this._isSharingAvailable = await this.canShare();
    this._isClipboardAvailable = await this.canCopyToClipboard();
  }

  public checkAppVersion(minAppVersion: string): void {
    try {
      console.log(`Running ${this.appName} v${this.appVersion}`);

      // On web the version format is 2.8.1 as the build number is reset for every major/minor version increment
      const currentVersion = this.appVersion || '0.0.0';
      const minimumVersion = minAppVersion || '0.0.0';

      // If the current version is lower than the minimum version, force an update
      this.isAppUpdateRequired = SharedWindowService.compareVersions(currentVersion, minimumVersion) < 0;
      console.log(`Web app update required: ${this.isAppUpdateRequired} (${currentVersion} vs ${minimumVersion})`);
    } catch (err) {
      if (!Config.isProduction) {
        console.log(`Error checking web app version`, err);
      }
    }
  }

  public canShare(): Promise<boolean> {
    let canShare = false;

    try {
      if (this.navigator.share && this.navigator.canShare) {
        canShare = this.navigator.canShare();
      }
    } catch {
      // Ignore
    }

    // Nothing async here, only relevant in derived classes
    return Promise.resolve(canShare);
  }

  public async canCopyToClipboard(): Promise<boolean> {
    if (!this.navigator.clipboard) {
      return false;
    }

    try {
      // If querying permissions is supported, check for clipboard access
      if (this.navigator.permissions && this.navigator.permissions.query) {
        const res = await this.navigator.permissions.query({
          // HACK: need to cast here because the typescript definition doesn't include clipboard permission names
          name: 'clipboard-write' as PermissionName,
        });

        if (res.state === 'denied') {
          // We don't have clipboard write access
          return false;
        }
      }
    } catch (err) {
      //
      // For now if this should happen we assume it to be an error with the permission check (for instance Safari and
      // Firefox don't support the clipboard permissions check) rather than to mean we don't have clipboard access; so
      // we do nothing and return true below
      //
      console.log(`Error checking clipboard permissions`, err);
    }

    return true;
  }

  public print() {
    if (this.window.print) {
      this.window.print();
    }
  }

  public async shareLink(url: string, text?: string): Promise<void> {
    let shareData: ShareData;

    switch (this.browserName) {
      case 'Chrome':
        shareData = { title: text, text, url };
        break;

      case 'Edge':
        shareData = { title: text, url };
        break;

      default:
        shareData = { text, url };
        break;
    }

    try {
      await this.navigator.share(shareData);
    } catch {
      // Happens when user abandons the web share ui; just ignore
    }
  }

  public async writeToClipboard(value: string | Promise<string>): Promise<void> {
    if (this.browserName === 'Safari' && typeof value !== 'string') {
      //
      // When needing to do an async operation prior to writing to the clipboard (e.g. to fetch a link for sharing from
      // our API), Safari for iOS requires that the promise for the given operation is passed to the clipboard item.
      //
      // If instead we await the promise in our code first, we get an error when trying to write to the clipboard:
      //
      //   NotAllowedError: The request is not allowed by the user agent or the platform in the current context, possibly
      //   because the user denied permission.
      //
      // https://stackoverflow.com/questions/66312944/javascript-clipboard-api-write-does-not-work-in-safari
      //
      const clipboardItem = new ClipboardItem({
        'text/plain': value.then((text) => new Blob([text], { type: 'text/plain' })),
      });

      return this.navigator.clipboard.write([clipboardItem]);
    }

    // Using simple writeText in other cases as e.g. Firefox doesn't support the write function using ClipboardItem
    return this.navigator.clipboard.writeText(await value);
  }

  public openCentered(url: string, title: string, waitForClose: boolean): Observable<IWindow>;
  public openCentered(
    url: string,
    title: string,
    width: number,
    height: number,
    waitForClose: boolean,
  ): Observable<IWindow>;
  public openCentered(url: string, title: string, a: unknown, b?: unknown, c?: unknown): Observable<IWindow> {
    return new Observable<IWindow>((observer) => {
      let waitForClose = false;
      let width = 600;
      let height = 800;

      if (typeof a === 'boolean') {
        waitForClose = a;
      } else {
        width = <number>a;
        height = <number>b;
        waitForClose = <boolean>c;
      }

      const left = this.getCenteredWindowLeft(width);
      const top = this.getCenteredWindowTop(height);

      console.log(`Opening window ${title} at ${url} with width ${width} and height ${height} at ${left},${top}`);

      const features = `scrollbars=yes, width=${width}, height=${height}, top=${top}, left=${left}`;
      const newWindow = this.open(url, title, features);

      console.log(`Opened window ${title} at ${url} with features ${features}`);
      // Puts focus on the new window
      if (this.focus && newWindow) {
        console.log(`Focusing window ${title}`);
        newWindow.focus();
      }

      console.log(`Waiting for window ${title} to close: ${waitForClose}`);

      if (waitForClose) {
        SharedUtilWindow.completeOnClose(newWindow, observer);
        console.log(`Window ${title} is closed`);
      } else {
        console.log(`Window ${title} is not waiting for close`);
        observer.next(newWindow);
        observer.complete();
      }
    });
  }

  private getCenteredWindowLeft(windowWidth: number): number {
    // Fixes dual screen position
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const dualScreenLeft: number =
      window.screenLeft !== undefined
        ? window.screenLeft // Most browsers
        : // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-member-access
          (<any>screen).left; // Firefox

    const parentWidth = window.innerWidth || document.documentElement.clientWidth || screen.width;
    return parentWidth / 2 - windowWidth / 2 + dualScreenLeft;
  }

  private getCenteredWindowTop(windowHeight: number): number {
    // Fixes dual screen position
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const dualScreenTop: number =
      window.screenTop !== undefined
        ? window.screenTop // Most browsers
        : // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-member-access
          (<any>screen).top; // Firefox

    const parentHeight = window.innerHeight || document.documentElement.clientHeight || screen.height;
    return parentHeight / 2 - windowHeight / 2 + dualScreenTop;
  }

  private initBrowserInfo() {
    const userAgent: string = this.navigator.userAgent;

    let temp: Array<string>;
    let match = userAgent.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];

    if (/trident/i.test(match[1])) {
      temp = /\brv[ :]+(\d+)/g.exec(userAgent) || [];
      this.browserName = 'IE';
      this.browserVersion = Number(temp[1] || -1);
      return;
    }

    if (match[1] === 'Chrome') {
      temp = userAgent.match(/\b(OPR|Edge|Edg)\/(\d+)/);
      if (temp != null) {
        temp = temp.slice(1);
        this.browserName = temp[0].replace('OPR', 'Opera').replace('Edg', 'Edge') as BrowserName;
        this.browserVersion = Number(temp[1]);
        return;
      }
    }

    match = match[2] ? [match[1], match[2]] : [this.navigator.appName, this.navigator.appVersion, '-?'];

    if ((temp = userAgent.match(/version\/(\d+)/i)) != null) {
      match.splice(1, 1, temp[1]);
    }

    this.browserName = match[0] as BrowserName;
    this.browserVersion = Number(match[1] || -1);
  }

  public checkBrowser(browser: 'Safari' | 'Chrome' | 'Edge' | 'IE', version?: number, greaterThan = true): boolean {
    if (this.browserName !== browser) {
      return false;
    }
    if (version === undefined) {
      return true;
    }
    if (greaterThan) {
      return this.browserVersion > version;
    }
    return this.browserVersion < version;
  }

  public scrollToTop() {
    this.window.scroll(this.scrollX, 0);
  }

  public scrollToBottom() {
    const y = this.window.document.body.scrollHeight;
    this.window.scroll(this.scrollX, y);
  }

  public scrollVertical(position: number) {
    this.window.scroll(this.scrollX, position);
  }

  public scrollHorizontal(position: number) {
    this.window.scroll(position, this.scrollY);
  }

  public alert(msg: unknown, title?: unknown): void {
    this.window.alert(msg, title);
  }

  public confirm(msg: unknown): void {
    const res = this.window.confirm(msg);
    if (!res) {
      throw new Error('User cancelled');
    }
  }

  public setTimeout(handler: (...args: unknown[]) => void, timeout?: number): number {
    return this.window.setTimeout(handler, timeout);
  }

  public clearTimeout(timeoutId: number): void {
    return this.window.clearTimeout(timeoutId);
  }

  public setInterval(handler: (...args: unknown[]) => void, ms?: number, ...args: unknown[]): number {
    return this.window.setInterval(handler, ms, args);
  }

  public clearInterval(intervalId: number): void {
    return this.window.clearInterval(intervalId);
  }

  public setTopLocationHref(value: string) {
    console.log(`Redirecting to ${value}`);
    this.window.top.location.href = value;
  }

  public open(url?: string, target?: string, features?: string, replace?: boolean): IWindow {
    return this.window.open(url, target, features, replace);
  }

  public focus() {
    this.window.focus();
  }

  public back() {
    this.ngLocation.back();
  }

  public forward() {
    this.ngLocation.forward();
  }
}
