import { LatLng } from '@luggagehero/shared/interfaces';

/**
 * @deprecated Use SharedUtilGeo from @luggagehero/shared/util
 */
export class GeoUtil {
  static getDistance(locA: LatLng, locB: LatLng): number {
    const radlat1 = (Math.PI * locA.lat) / 180;
    const radlat2 = (Math.PI * locB.lat) / 180;
    const theta = locA.lng - locB.lng;
    const radtheta = (Math.PI * theta) / 180;
    let dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    dist = Math.acos(dist);
    dist = (dist * 180) / Math.PI;
    dist = dist * 60 * 1.1515;
    dist = dist * 1.609344;
    return dist;
  }

  static isDistanceLessThan(locA: LatLng, locB: LatLng, maxDistance: number): boolean {
    if (!locA || !locB) {
      return false;
    }
    return GeoUtil.getDistance(locA, locB) < maxDistance;
  }

  static isDistanceGreaterThan(locA: LatLng, locB: LatLng, maxDistance: number): boolean {
    if (!locA || !locB) {
      return false;
    }
    return GeoUtil.getDistance(locA, locB) > maxDistance;
  }

  static isSame(locA: LatLng, locB: LatLng): boolean {
    if (locA === locB) {
      return true;
    }
    if (!locA || !locB) {
      return false;
    }
    return locA.lat === locB.lat && locA.lng === locB.lng;
  }
}
