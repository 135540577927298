import { ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AppConfig } from '@luggagehero/shared/app-settings/data-access';
import { SharedBookingService } from '@luggagehero/shared/services/bookings';
import { SharedShopsService } from '@luggagehero/shared/services/shops';
import { SharedStorageService } from '@luggagehero/shared/services/storage';
import { SharedWindowService } from '@luggagehero/shared/services/window';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';

import { ScriptService } from '../../../../../services/index';
import { BookingActionBaseComponent } from './booking-action.base-component';

type TiqetsWidgetLayout = 'horizontal' | 'vertical';
type TiqetsWidgetType = 'discovery' | 'availability';

type GetYourGuideWidgetPlacement =
  | 'content-top'
  | 'content-middle'
  | 'content-end'
  | 'sidebar-left'
  | 'sidebar-right'
  | 'email'
  | 'app';

type GetYourGuideWidgetType = 'activities' | 'city';

interface TiqetsIframeUrlParams {
  partner: string;
  city_id: number;
  currency: string;
  language: string;
  tq_campaign: string;
  cards_layout: TiqetsWidgetLayout;
  widget_index: number;
  width: number;
  height: number;
}

interface GetYourGuideIframeUrlParams {
  partner_id: string;
  number_of_items: number;
  currency: string;
  locale: string;
  cmp: string;
  placement: GetYourGuideWidgetPlacement;
  see_more?: boolean;
  id: string;
  tour_ids?: string;
  lat: number;
  lon: number;
  widget: GetYourGuideWidgetType;
}

interface GetYourGuideConfig {
  partnerId: string;
  numberOfItems: number;
  currency: string;
  locale: string;
  campaign: string;
  placement: GetYourGuideWidgetPlacement;
  enableSeeMore?: boolean;
  id: string;
  tourIds?: string;
  lat: number;
  lon: number;
  widgetType: GetYourGuideWidgetType;
  widgetUrl: string;
}

interface ExperienceInfo {
  name: string;
  title: string;
  subtitle?: string;
  description: string;
  imageUrl: string;
  linkUrl: string;
  callToAction: string;
}

enum ExperienceProvider {
  LuggageHero = 'LuggageHero',
  GetYourGuide = 'GetYourGuide',
  Tiqets = 'Tiqets',
}

const DEFAULT_EXPERIENCE_PROVIDER: ExperienceProvider = ExperienceProvider.Tiqets;

const GYG_PARTNER_ID = 'NYXJJ41';
const GYG_NUMBER_OF_ITEMS = 11;
const GYG_CAMPAIGN = 'booking-timeline';
const GYG_PLACEMENT: GetYourGuideWidgetPlacement = 'content-middle';
const GYG_ENABLE_SEE_MORE = false;
const GYG_ID = 'luggagehero-web-app';
const GYG_WIDGET_TYPE: GetYourGuideWidgetType = 'activities';
const GYG_WIDGET_URL = `https://widget.getyourguide.com/${GYG_PARTNER_ID}/${GYG_WIDGET_TYPE}.frame`;

const TIQETS_PARTNER = 'luggagehero';
const TIQETS_CAMPAIGN = 'booking_timeline';
const TIQETS_CITY_ID_LONDON = 67458;
const TIQETS_WIDGET_LAYOUT: TiqetsWidgetLayout = 'vertical';
const TIQETS_WIDGET_INDEX = 0;
const TIQETS_WIDGET_WIDTH = 250;
const TIQETS_WIDGET_HEIGHT = 1450;
const TIQETS_WIDGET_TYPE: TiqetsWidgetType = 'discovery';
const TIQETS_WIDGET_URL = `https://www.tiqets.com/widgets/${TIQETS_WIDGET_TYPE}`;

@Component({ template: '' })
export abstract class ExploreExperiencesActionBaseComponent extends BookingActionBaseComponent implements OnDestroy {
  static showCount = 0;

  provider = DEFAULT_EXPERIENCE_PROVIDER;
  tiqetsIframeUrl: string;
  gygConfig: GetYourGuideConfig;
  gygIframeUrl: string;
  isCheckInSession = false;

  private _experiences: ExperienceInfo[];
  private bookingEventSubscription: Subscription;

  constructor(
    private translate: TranslateService,
    private route: ActivatedRoute,
    private window: SharedWindowService,
    private script: ScriptService,
    bookingService: SharedBookingService,
    shopsService: SharedShopsService,
    storageService: SharedStorageService,
    cd: ChangeDetectorRef,
  ) {
    super(bookingService, shopsService, storageService, cd);
  }

  get isFirstShow(): boolean {
    return ExploreExperiencesActionBaseComponent.showCount <= 1;
  }

  get experiences(): ExperienceInfo[] {
    return this._experiences;
  }

  get showTimer(): boolean {
    if (!this.shop) {
      return false;
    }
    return AppConfig.CITIES_WITH_EXPERIENCES_AUTO_PROMPTED.includes(this.shop.officialLocationKey);
  }

  ngOnDestroy() {
    try {
      this.bookingEventSubscription.unsubscribe();
    } catch {
      // Ignore
    }
  }

  async onBaseInit() {
    ExploreExperiencesActionBaseComponent.showCount++;

    this.bookingEventSubscription = this.bookingService.bookingEvent$.subscribe((e) => {
      if (e && e.eventType === 'bagsDroppedOff') {
        this.isCheckInSession = true;
        this.cd.markForCheck();
      }
    });

    this.shop = await this.shopsService.getShop(this.booking.shopId);

    // Wait for route params
    const params = await this.route.params.pipe(first()).toPromise();
    if (params.experienceProvider in ExperienceProvider) {
      // Override default experience provider if a valid param is provided
      this.provider = params.experienceProvider as ExperienceProvider;
    }

    // Init the experiences by the given provider
    switch (this.provider) {
      case ExperienceProvider.Tiqets:
        await this.initTiqets();
        break;

      case ExperienceProvider.GetYourGuide:
        await this.initGetYourGuide();
        break;

      case ExperienceProvider.LuggageHero:
        await this.initLuggageHero();
        break;
    }
  }

  onHidden() {
    if (this.booking.state.exploreExperiencesDone) {
      return;
    }
    void this.setExploreExperiencesDone(false);
  }

  done() {
    void this.setExploreExperiencesDone(true);
  }

  async setExploreExperiencesDone(closeAction = true): Promise<void> {
    if (this.booking.state.exploreExperiencesDone) {
      this.close();
      return;
    }
    this.isLoading = true;
    this.booking.state.exploreExperiencesDone = true;

    try {
      const booking = await this.bookingService.updateState(this.booking);
      this.updateBooking(booking);
    } catch (err) {
      // Handle error
    }
    this.isLoading = false;

    if (closeAction) {
      this.close();
    }
  }

  async initLuggageHero(): Promise<void> {
    // TODO: Load experiences from API based on booking params
    this._experiences = [
      {
        name: 'TivoliGardens',
        title: 'Tivoli Gardens',
        subtitle: 'Skip-the-Line Entry Ticket',
        description:
          "Discover Copenhagen's world-famous Tivoli Gardens, the world's second-oldest amusement park. Enjoy fast-track access with your skip-the-line admission ticket. Explore the park, enjoy the beautiful gardens and flowers, and stop for refreshments at a café.",
        imageUrl: 'https://cdn.getyourguide.com/img/tour_img-491404-97.jpg',
        linkUrl: 'https://www.getyourguide.com/copenhagen-l12/copenhagen-tivoli-skip-the-line-entrance-ticket-t72048',
        callToAction: 'Book Now',
      },
      {
        name: 'CanalCruise',
        title: 'Canal Cruise',
        description:
          'Experience Copenhagen from the water and see how the past and present merge as you travel along idyllic canals.',
        imageUrl: 'https://files.guidedanmark.org/files/382/299_Under_the_14_bridges.jpg',
        linkUrl: 'https://www.visitcopenhagen.com/copenhagen/canal-tours-grand-tour-copenhagen-gdk410731',
        callToAction: 'More Info',
      },
      {
        name: 'DonkeyBikes',
        title: 'Donkey Bikes',
        description:
          "You're in one of the best cycling cities in the world so renting a bike to explore Copenhagen is really a no-brainer.",
        imageUrl: 'https://lh3.googleusercontent.com/u/0/d/1QIqGqpCNEXfsazOE30u2mIBrkLiEoPJb=w3840-h1885-iv3',
        linkUrl: 'https://www.donkey.bike/cities/bike-rental-copenhagen',
        callToAction: 'Get App',
      },
    ];
    return Promise.resolve();
  }

  initTiqets(): Promise<void> {
    const browserLanguage = this.translate.getBrowserLang();
    const config: TiqetsIframeUrlParams = {
      partner: TIQETS_PARTNER,
      city_id: TIQETS_CITY_ID_LONDON, // Hard code to London for now
      currency: this.booking.price.pricing.currency.toUpperCase(),
      language: browserLanguage,
      tq_campaign: TIQETS_CAMPAIGN,
      cards_layout: TIQETS_WIDGET_LAYOUT,
      widget_index: TIQETS_WIDGET_INDEX,
      width: TIQETS_WIDGET_WIDTH,
      height: TIQETS_WIDGET_HEIGHT,
    };
    const urlParams: string[] = [];
    for (const paramName in config) {
      urlParams.push(`${paramName}=${config[paramName]}`);
    }
    this.tiqetsIframeUrl = `${TIQETS_WIDGET_URL}?${urlParams.join('&')}`;
    return void 0;
  }

  async initGetYourGuide(useIframe = true): Promise<void> {
    // Wait for the GetYourGuide script to load
    await this.script.load('gyg').toPromise();

    if (useIframe) {
      this.initGetYourGuideIframe();
    } else {
      this.initGetYourGuideWidget();
    }
  }

  initGetYourGuideIframe() {
    const config: GetYourGuideIframeUrlParams = {
      partner_id: GYG_PARTNER_ID,
      number_of_items: GYG_NUMBER_OF_ITEMS,
      currency: this.booking.price.pricing.currency.toUpperCase(),
      locale: this.window.locale,
      cmp: GYG_CAMPAIGN,
      placement: GYG_PLACEMENT,
      id: GYG_ID,
      lat: this.shop.location.coordinates[1],
      lon: this.shop.location.coordinates[0],
      widget: GYG_WIDGET_TYPE,
    };
    const topRankedTours = AppConfig.GYG_CITY_TOURS[this.shop.officialLocationKey];
    if (topRankedTours) {
      config.tour_ids = topRankedTours.map((t) => t.id).join(',');
    }
    if (GYG_ENABLE_SEE_MORE) {
      config.see_more = true;
    }
    const urlParams: string[] = [];
    for (const paramName in config) {
      urlParams.push(`${paramName}=${config[paramName]}`);
    }
    this.gygIframeUrl = `${GYG_WIDGET_URL}?${urlParams.join('&')}`;
  }

  private initGetYourGuideWidget() {
    // Configure the GetYourGuide widget
    const config: GetYourGuideConfig = {
      partnerId: GYG_PARTNER_ID,
      numberOfItems: GYG_NUMBER_OF_ITEMS,
      currency: this.booking.price.pricing.currency.toUpperCase(),
      locale: this.window.locale,
      campaign: GYG_CAMPAIGN,
      placement: GYG_PLACEMENT,
      id: GYG_ID,
      widgetType: GYG_WIDGET_TYPE,
      lat: this.shop.location.coordinates[1],
      lon: this.shop.location.coordinates[0],
      widgetUrl: GYG_WIDGET_URL,
    };
    const topRankedTours = AppConfig.GYG_CITY_TOURS[this.shop.officialLocationKey];
    if (topRankedTours) {
      config.tourIds = topRankedTours.map((t) => t.id).join(',');
    }
    if (GYG_ENABLE_SEE_MORE) {
      config.enableSeeMore = true;
    }
    this.gygConfig = config;
  }
}
