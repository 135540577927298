import { EventEmitter, Injectable } from '@angular/core';
import { BaseModalComponent, ModalOptions, ModalRef, ModalService, ModalStyle } from '@luggagehero/core';
import { SharedDocumentService } from '@luggagehero/shared/services/document';
import { BsModalRef, BsModalService, ModalOptions as BsModalOptions } from 'ngx-bootstrap/modal';

const DEFAULT_OPTIONS_TYPE = ModalStyle.Dialog;
const DEFAULT_OPTIONS_ANIMATED = true;

const MODAL_TYPE_CLASS_MAP: { [type in ModalStyle]: string } = {
  [ModalStyle.FullScreen]: 'modal-dialog modal-fullscreen-sm',
  [ModalStyle.Dialog]: 'modal-dialog modal-fullscreen-sm',
  [ModalStyle.Alert]: 'modal-dialog modal-center-sm',
};

@Injectable()
export class WebModalService extends ModalService {
  constructor(
    private bsModalService: BsModalService,
    private document: SharedDocumentService,
  ) {
    super();

    this.bsModalService.onHidden.subscribe(() => this.decrementCount());
    this.bsModalService.onShown.subscribe(() => this.incrementCount());
  }

  public incrementCount(): void {
    super.incrementCount();

    this.document.fixBsModalBackdrop(this.modalCount);
  }

  public decrementCount(): void {
    super.decrementCount();

    if (this.modalCount > 0) {
      //
      // This solves a problem where scrolling stops working on a modal after a second modal is shown on top and then
      // hidden. It happens because Bootstrap removes the "modal-open" css class from the <body> element whenever a
      // modal is closed. The official documentation states that Bootstrap doesn't support multiple modals open at the
      // same time.
      //
      // https://stackoverflow.com/questions/28077066/bootstrap-modal-issue-scrolling-gets-disabled
      // https://stackoverflow.com/questions/36460538/scrolling-issues-with-multiple-bootstrap-modals/64662694#64662694
      //
      this.document.addBodyClass('modal-open');
    } else {
      this.document.removeBodyClass('modal-open');
    }
  }

  public async show<T extends BaseModalComponent>(options: ModalOptions<T>): Promise<ModalRef> {
    // Reference to the Bootstrap modal we will create
    let bsModal: BsModalRef = null;

    if (!options.componentState) {
      options.componentState = {};
    }

    // Create a modal reference on the state object
    options.componentState.modalRef = {
      // Hide the Bootstrap modal
      hide: () => Promise.resolve(bsModal?.hide()),
      result: new EventEmitter<unknown>(),
      onHidden: new EventEmitter<void>(),
    };

    // Create options for Bootstrap modal
    const bsModalOptions: BsModalOptions<unknown> = {
      initialState: options.componentState,
      class: MODAL_TYPE_CLASS_MAP[options.style || DEFAULT_OPTIONS_TYPE],
      animated: options.animated || DEFAULT_OPTIONS_ANIMATED,
      show: true, // Always show the modal
    };

    if (options.autoWidth) {
      bsModalOptions.class += ` auto-width-${options.autoWidth}`;
    }

    // Create and show the Bootstrap modal
    bsModal = this.bsModalService.show(options.component, bsModalOptions);
    bsModal.onHidden.subscribe(() => options.componentState.modalRef.onHidden.emit());

    // Return the reference to the caller
    return Promise.resolve(options.componentState.modalRef);
  }

  public hide(): Promise<void> {
    // TODO: Test how this works?
    this.bsModalService.hide(1);
    return Promise.resolve();
  }
}
