import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { TicketStubBaseComponent } from '@luggagehero/features/ui';

@Component({
  selector: 'lh-ticket-stub',
  templateUrl: './ticket-stub.component.html',
  styleUrls: ['./ticket-stub.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TicketStubComponent extends TicketStubBaseComponent {
  constructor(cd: ChangeDetectorRef) {
    super(cd);
  }
}
