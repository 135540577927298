import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TranslatePipe } from '@luggagehero/shared/ui-pipes';

export interface ShopRatingContext {
  numberOfRatings?: number;
  numberOfRatingsAndRecommendations?: number;
  averageRating?: number;
}

@Component({
  selector: 'lh-traveler-shops-ui-shop-rating',
  standalone: true,
  imports: [CommonModule, TranslatePipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['traveler-shops-ui-shop-rating.component.scss'],
  templateUrl: 'traveler-shops-ui-shop-rating.component.html',
})
export class TravelerShopsUiShopRatingComponent {
  @Input() shopRating?: ShopRatingContext;
}
