import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';

import { TravelerShopsUiLanguageSwitcherComponent } from '../language-switcher/traveler-shops-ui-language-switcher.component';
import { TravelerShopsUiMenuButtonComponent } from '../menu-button/traveler-shops-ui-menu-button.component';

@Component({
  selector: 'lh-traveler-shops-ui-black-navbar',
  standalone: true,
  imports: [CommonModule, TravelerShopsUiMenuButtonComponent, TravelerShopsUiLanguageSwitcherComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['traveler-shops-ui-black-navbar.component.scss'],
  templateUrl: 'traveler-shops-ui-black-navbar.component.html',
})
export class TravelerShopsUiBlackNavbarComponent {}
