import { inject, Pipe, PipeTransform } from '@angular/core';
import { DISCOUNT_TRANSFORMER_SERVICE, DiscountTransformerService, IDiscount } from '@luggagehero/shared/interfaces';

@Pipe({ name: 'discount', standalone: true })
export class DiscountPipe implements PipeTransform {
  private discountTransformerService = inject<DiscountTransformerService>(DISCOUNT_TRANSFORMER_SERVICE);

  transform(discount: IDiscount, bags: number, includeCode = false): string {
    return this.discountTransformerService.transform(discount, bags, includeCode);
  }
}
