<button
  role="button"
  [attr.aria-label]="ariaLabel"
  [attr.aria-disabled]="disabled"
  [ngClass]="['lug24-btn', color, size, style]"
  [disabled]="disabled"
  (click)="onClick($event)"
  [attr.title]="title"
>
  <ng-content></ng-content>
</button>
