import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslatePipe } from '@luggagehero/shared/ui-pipes';

import { GuestDropoffStepBaseComponent } from '../guest-dropoff-step-base-component';
import { GuestVerificationStepProperties } from './guest-verification-step-properties';

interface Reservation {
  lastName: string;
  checkInDate: Date;
}

const DUMMY_RESERVATION_DATA: Reservation[] = [
  {
    lastName: 'Doe',
    checkInDate: new Date(),
  },
  {
    lastName: 'Lawaetz',
    checkInDate: new Date(),
  },
  {
    lastName: 'Hejl',
    checkInDate: new Date(),
  },
];

@Component({
  selector: 'lh-bag-selection-step',
  standalone: true,
  imports: [CommonModule, FormsModule, TranslatePipe],
  templateUrl: './guest-verification-step.component.html',
  styleUrl: './guest-verification-step.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GuestVerificationStepComponent extends GuestDropoffStepBaseComponent<GuestVerificationStepProperties> {
  public errorMessage?: string;

  private static isReservationValid = false;

  public get lastName(): string {
    return this.bookingDraft.lastName;
  }
  public set lastName(value: string) {
    this.bookingDraft.lastName = value;
    this.onReservationDetailsChanged();
  }

  public get checkInDate(): Date {
    return this.bookingDraft.checkInDate;
  }
  public set checkInDate(value: Date) {
    this.bookingDraft.checkInDate = value;
    this.onReservationDetailsChanged();
  }

  public get checkInDateString(): string {
    return this.checkInDate ? this.checkInDate.toISOString().substring(0, 10) : '';
  }
  public set checkInDateString(value: string) {
    this.checkInDate = value ? new Date(value) : undefined;
  }

  private get isReservationValid(): boolean {
    return GuestVerificationStepComponent.isReservationValid;
  }
  private set isReservationValid(value: boolean) {
    GuestVerificationStepComponent.isReservationValid = value;
  }

  protected onInit(): Promise<void> {
    if (!this.checkInDate) {
      this.checkInDate = new Date();
    }
    return Promise.resolve();
  }

  public async goForward(): Promise<void> {
    if (this.isReservationValid) {
      return super.goForward();
    }

    this.isReservationValid = await this.loader.load(() => this.checkReservationDetails());

    if (!this.isReservationValid) {
      this.errorMessage = 'RESERVATION_DETAILS_NOT_FOUND_ERROR';
      this.canGoForward = false;
      return;
    }

    return super.goForward();
  }

  protected onCheckCanGoForward(): boolean {
    if (this.isReservationValid) {
      return true;
    }
    return !!this.lastName && !!this.bookingDraft.checkInDate;
  }

  private onReservationDetailsChanged(): void {
    this.isReservationValid = false;
    this.errorMessage = null;
    void this.checkCanGoForward(false);
  }

  private async checkReservationDetails(): Promise<boolean> {
    // Simulate a network request
    const reservationData = await new Promise<Reservation[]>((resolve) =>
      setTimeout(() => resolve(DUMMY_RESERVATION_DATA), 500),
    );

    // Return true if a reservation with the given last name is found
    return !!reservationData.find((r) => r.lastName.match(new RegExp(this.lastName, 'i')));
  }
}
