<div class="location-map">
  @if (mapOptions) {
    <google-map
      width="100%"
      height="100%"
      [center]="center"
      [zoom]="zoom"
      [options]="mapOptions"
      (centerChanged)="onCenterChange()"
      (zoomChanged)="onZoomChange()"
      (mapClick)="onMapClick($event)"
      (mapInitialized)="onMapInitialized($event)"
    >
      @if (myLocationMarker) {
        <map-marker [options]="myLocationMarker"></map-marker>
      }

      @for (marker of storageLocationMarkers; track marker) {
        <map-marker
          [options]="getMarkerOptions(marker)"
          (mapClick)="onStorageLocationMarkerClick(marker)"
          (mapMouseover)="onStorageLocationMarkerMouseOver(marker)"
          (mapMouseout)="onStorageLocationMarkerMouseOut(marker)"
        >
        </map-marker>
      }

      @for (circle of storageLocationCircles; track circle) {
        <map-circle [options]="circle" />
      }

      @if (enableStorageLocationInfoWindows) {
        <map-info-window [options]="mapInfoWindowOptions">
          @if (openStorageLocation) {
            <a
              [id]="'makeBookingFromMap_' + openStorageLocation.id"
              class="shop-list-item"
              (click)="onStorageLocationClick(openStorageLocation.shopItem)"
            >
              <lh-traveler-shops-ui-shop-card
                [isHighlighted]="false"
                [recommendedTag]="openStorageLocation.recommendedTag"
                [title]="openStorageLocation.title"
                [shopRating]="openStorageLocation.shopRating"
                [distance]="openStorageLocation.distance"
                [pricingDaily]="openStorageLocation.pricingDaily"
                [pricingHourly]="openStorageLocation.pricingHourly"
                [availability]="openStorageLocation.availability"
                [badges]="openStorageLocation.badges"
                (bookNow)="onBookingRequest(openStorageLocation.shopItem)"
              >
              </lh-traveler-shops-ui-shop-card>
            </a>
          }
        </map-info-window>
      }
    </google-map>
  }
</div>
