import { BookableStorageLocation } from '@luggagehero/shared/interfaces';
import { createAction, props } from '@ngrx/store';

import { CreateHotelsHeroStorageLocationPayload } from './storage-locations-setup.interfaces';

export const createHotelsHeroStorageLocation = createAction(
  '[StorageLocationsSetupActions] Add Tax',
  props<{ payload: CreateHotelsHeroStorageLocationPayload }>(),
);
export const createHotelsHeroStorageLocationSuccess = createAction(
  '[StorageLocationsSetupActions] Add Storage Location Success',
  props<{ storageLocation: BookableStorageLocation }>(),
);
export const createHotelsHeroStorageLocationFailure = createAction(
  '[StorageLocationsSetupActions] Add Storage Location Failure',
  props<{ error: unknown }>(),
);
