import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  inject,
  Input,
  Output,
} from '@angular/core';

export enum ToggleButtonMode {
  Checkbox = 'Checkbox',
}

@Component({
  selector: 'lh-shared-ui-toggle-button',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './shared-ui-toggle-button.component.html',
  styleUrl: './shared-ui-toggle-button.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SharedUiToggleButtonComponent {
  @Output() public readonly enabledChange = new EventEmitter<boolean>();

  private _enabled = false;
  private _mode = ToggleButtonMode.Checkbox;

  private readonly cd = inject(ChangeDetectorRef);

  public get enabled(): boolean {
    return this._enabled;
  }
  @Input() public set enabled(value: boolean) {
    this._enabled = value;
    this.cd.markForCheck();
    this.enabledChange.emit(value);
  }

  public get mode(): ToggleButtonMode {
    return this._mode;
  }
  @Input() public set mode(value: ToggleButtonMode) {
    this._mode = value;
    this.cd.markForCheck();
  }
}
