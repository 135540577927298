import { ColorInfo, RGBColor, TagColor } from '@luggagehero/shared/interfaces';
import { closest } from 'color-diff';
import tinycolor from 'tinycolor2';

export interface TagColorInfo extends ColorInfo {
  name: TagColor;
}

export class ColorUtil {
  public static hexToRgb(hex: string): RGBColor {
    // Convert hex to RGB
    const bigint = parseInt(hex.replace('#', ''), 16);
    return {
      R: (bigint >> 16) & 255,
      G: (bigint >> 8) & 255,
      B: bigint & 255,
      A: 1,
    };
  }

  public static rgbToHex(rgb: RGBColor): string {
    // Convert RGB to hex
    return `#${((1 << 24) + (rgb.R << 16) + (rgb.G << 8) + rgb.B).toString(16).slice(1)}`;
  }

  public static normalizeColor<T extends ColorInfo>(color: string, palette: T[]): T {
    // Convert input to RGB values
    const rgbColor = ColorUtil.hexToRgb(color);
    const rgbPalette = palette.map((hex) => ColorUtil.hexToRgb(hex.hex));

    // Find the closest color in the palette
    const closestColorRGB = closest(rgbColor, rgbPalette);
    const closestColor = palette.find((c) => ColorUtil.isSameRGB(c.rgb, closestColorRGB));

    return closestColor ?? palette[0];
  }

  public static isSameRGB(color1: RGBColor, color2: RGBColor): boolean {
    return color1.R === color2.R && color1.G === color2.G && color1.B === color2.B;
  }

  public static darken(color: string, amount: number): string {
    return tinycolor(color).darken(amount).toString();
  }

  public static lighten(color: string, amount: number): string {
    return tinycolor(color).lighten(amount).toString();
  }

  public static isLight(color: string): boolean {
    return tinycolor(color).isLight();
  }

  public static isDark(color: string): boolean {
    return tinycolor(color).isDark();
  }

  public static pickRandomColor(colors: string[]): string {
    return colors[Math.floor(Math.random() * colors.length)];
  }

  public static pickColorByObjectId(objectId: string, colors: string[]): string {
    // Convert the ObjectId hex string to a bigint
    const objectIdBigInt = BigInt('0x' + objectId);

    // Use modulus operation to get the index within the bounds of the colors array
    const index = Number(objectIdBigInt % BigInt(colors.length));

    // Return the color at the index
    return colors[index];
  }

  public static pickColorByUsername(username: string, colors: string[]): string {
    // Convert the username to a BigInt
    const usernameBigInt = BigInt(
      username
        .split('')
        .map((c) => c.charCodeAt(0))
        .join(''),
    );

    // Use modulus operation to get the index within the bounds of the colors array
    const index = Number(usernameBigInt % BigInt(colors.length));

    // Return the color at the index
    return colors[index];
  }
}
