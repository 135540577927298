import { InjectionToken } from '@angular/core';

import { IPaymentRecord } from './payment-record.interface';

export interface PaymentMethodTransformerService {
  transform(value: IPaymentRecord, format?: string, html?: boolean): string;
}

export const PAYMENT_METHOD_TRANSFORMER_SERVICE = new InjectionToken<PaymentMethodTransformerService>(
  'PAYMENT_METHOD_TRANSFORMER_SERVICE',
);
