import { HttpClient } from '@angular/common/http';
import { SharedAppSettingsService } from '@luggagehero/shared/app-settings/data-access';
import { TranslateLoader } from '@ngx-translate/core';

import { SharedTranslateLoader } from './shared-translate-loader';
import { SharedTranslationsService } from './shared-translations.service';

export const createTranslateLoader = (
  translationsService: SharedTranslationsService,
  appSettingsService: SharedAppSettingsService,
  http: HttpClient,
): TranslateLoader => {
  return new SharedTranslateLoader(translationsService, appSettingsService, http);
};
