import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ContactStepBaseComponent } from '@luggagehero/features/shops';

@Component({
  selector: 'lh-contact-step',
  templateUrl: './contact-step.component.html',
  styleUrls: ['./contact-step.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class ContactStepComponent extends ContactStepBaseComponent {}
