import { Injectable } from '@angular/core';

@Injectable()
export class IconService {
  public map = String.fromCharCode(0xf278);
  public list = String.fromCharCode(0xf03a);
  public locationPin = String.fromCharCode(0xf041);
  public tag = String.fromCharCode(0xf02b);
  public tags = String.fromCharCode(0xf02c);
  public user = String.fromCharCode(0xf2be);
  public gear = String.fromCharCode(0xf013);
  public save = String.fromCharCode(0xf0c7);
  public search = String.fromCharCode(0xf002);
  public logout = String.fromCharCode(0xf08b);
  public globe = String.fromCharCode(0xf0ac);
  public down = String.fromCharCode(0xf078);
  public up = String.fromCharCode(0xf077);
  public suitcase = String.fromCharCode(0xf0f2);
  public calendar = String.fromCharCode(0xf073);
}
