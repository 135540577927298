import { ChangeDetectorRef, Directive, EventEmitter, inject, Input } from '@angular/core';

import { BaseComponent } from '../../../../core';

@Directive()
export abstract class StepBaseComponent extends BaseComponent {
  public forward: EventEmitter<void> = new EventEmitter<void>();
  public back: EventEmitter<void> = new EventEmitter<void>();

  protected cd = inject(ChangeDetectorRef);

  private _isLoading = false;
  private _canGoForward = false;
  private _canGoBack = false;
  private _canSkip = false;

  public get isLoading(): boolean {
    return this._isLoading;
  }
  @Input() public set isLoading(value: boolean) {
    this._isLoading = value;
    this.cd.markForCheck();
  }

  public get canGoForward(): boolean {
    return this._canGoForward;
  }
  @Input() public set canGoForward(value: boolean) {
    this._canGoForward = value;
    this.cd.markForCheck();
  }

  public get canGoBack(): boolean {
    return this._canGoBack;
  }
  @Input() public set canGoBack(value: boolean) {
    this._canGoBack = value;
    this.cd.markForCheck();
  }

  public get canSkip(): boolean {
    return this._canSkip;
  }
  @Input() public set canSkip(value: boolean) {
    this._canSkip = value;
    this.cd.markForCheck();
  }

  public get hideNavigation(): boolean {
    if (!this.useDefaultNavigation) {
      return true;
    }
    return !this.canGoBack && !this.canGoForward && !this.canSkip;
  }

  /**
   * Can be overridden by child classes to change the default call to action for the forward button.
   */
  public get forwardCallToAction(): string {
    return 'NEXT';
  }

  /**
   * Can be overridden by child classes to change the default call to action for the back button.
   */
  public get backCallToAction(): string {
    return 'BACK';
  }

  /**
   * Implemented by child classes to determine whether to use the default navigation or not.
   */
  protected abstract get useDefaultNavigation(): boolean;

  goForward(): void {
    if (!this.canGoForward && !this.canSkip) {
      return;
    }
    this.forward.emit();
  }

  goBack(): void {
    if (!this.canGoBack) {
      return;
    }
    this.back.emit();
  }
}
