<div class="timer-label">{{ 'STORAGE_TIMER' | lhTranslate }}</div>

<lh-shared-ui-timer
  [days]="days"
  [compactMode]="true"
  [hours]="hours"
  [minutes]="minutes"
  [seconds]="seconds"
  [largeDigits]="true"
></lh-shared-ui-timer>
