import { Type } from '@angular/core';
import { Booking, Review } from '@luggagehero/shared/interfaces';
import { Observable } from 'rxjs';

import { BookingActionBaseComponent } from './booking-action.base-component';

export interface BookingChange {
  changeType: 'booking' | 'review';
  changedValue: Booking | Review;
}

export interface BookingAction {
  componentToShow?: Type<BookingActionBaseComponent>;
  functionToCall?: () => Observable<BookingChange> | Promise<BookingChange>;
}

export type BookingActionState = 'none' | 'error' | 'warning' | 'success';
export type BookingActionViewSize = 'default' | 'auto';

export type BookingChangeHandler = (booking: Booking) => void;
export type ReviewChangeHandler = (review: Review) => void;

export class BookingActionInfo {
  private _name: string;
  private _callToAction: string;
  private _title: string;
  private _subtitle: string;
  private _viewSize: BookingActionViewSize;
  private _action: BookingAction;
  private _skipAction: BookingAction;
  private _skipText: string;
  private _skipTitle: string;
  private _skipSubtitle: string;
  private _disabled = false;
  private _displayTimer = false;
  private _bookingChangeHandler: BookingChangeHandler;
  private _reviewChangeHandler: ReviewChangeHandler;
  private _state: BookingActionState;

  constructor(name: string) {
    this._name = name;
  }

  get name(): string {
    return this._name;
  }

  get callToAction(): string {
    return this._callToAction;
  }

  get title(): string {
    return this._title;
  }

  get subtitle(): string {
    return this._subtitle;
  }

  get viewSize(): BookingActionViewSize {
    return this._viewSize;
  }

  get action(): BookingAction {
    return this._action;
  }

  get skipAction(): BookingAction {
    return this._skipAction;
  }

  get skipText(): string {
    return this._skipText;
  }

  get skipTitle(): string {
    return this._skipTitle;
  }

  get skipSubtitle(): string {
    return this._skipSubtitle;
  }

  get disabled(): boolean {
    return this._disabled;
  }

  get displayTimer(): boolean {
    return this._displayTimer;
  }

  get bookingChangeHandler(): BookingChangeHandler {
    return this._bookingChangeHandler;
  }

  get reviewChangeHandler(): ReviewChangeHandler {
    return this._reviewChangeHandler;
  }

  get state(): BookingActionState {
    return this._state;
  }

  get error(): boolean {
    return this._state === 'error';
  }

  get warning(): boolean {
    return this._state === 'warning';
  }

  get success(): boolean {
    return this._state === 'success';
  }

  withCallToAction(value: string): BookingActionInfo {
    this._callToAction = value;
    return this;
  }

  withTitle(value: string): BookingActionInfo {
    this._title = value;
    return this;
  }

  withSubtitle(value: string): BookingActionInfo {
    this._subtitle = value;
    return this;
  }

  withViewSize(value: BookingActionViewSize): BookingActionInfo {
    this._viewSize = value;
    return this;
  }

  withAction(value: BookingAction): BookingActionInfo {
    this._action = value;
    return this;
  }

  withSkipAction(value: BookingAction): BookingActionInfo {
    this._skipAction = value;
    return this;
  }

  withSkipText(value: string): BookingActionInfo {
    this._skipText = value;
    return this;
  }

  withSkipTitle(value: string): BookingActionInfo {
    this._skipTitle = value;
    return this;
  }

  withSkipSubtitle(value: string): BookingActionInfo {
    this._skipSubtitle = value;
    return this;
  }

  withDisabled(value: boolean): BookingActionInfo {
    this._disabled = value;
    return this;
  }

  withDisplayTimer(value: boolean): BookingActionInfo {
    this._displayTimer = value;
    return this;
  }

  withBookingChangeHandler(value: BookingChangeHandler): BookingActionInfo {
    this._bookingChangeHandler = value;
    return this;
  }

  withReviewChangeHandler(value: ReviewChangeHandler): BookingActionInfo {
    this._reviewChangeHandler = value;
    return this;
  }

  withState(value: BookingActionState): BookingActionInfo {
    this._state = value;
    return this;
  }

  destroy() {
    this._action = null;
    this._skipAction = null;
    this._bookingChangeHandler = null;
    this._reviewChangeHandler = null;
  }
}
