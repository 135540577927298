<div class="location-picker">
  <a id="startTyping" (click)="startTyping()">{{ displayValue }}</a>

  <div class="input-overlay" [hidden]="!isTyping">
    <lh-places-search [(ngModel)]="selectedPlace" #placesSearch></lh-places-search>

    <button id="stopTyping" class="close" (click)="stopTyping()">
      <svg _ngcontent-c3="" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
        <path
          _ngcontent-c3=""
          d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
        ></path>
        <path _ngcontent-c3="" d="M0 0h24v24H0z" fill="none"></path>
      </svg>
    </button>
  </div>
</div>
