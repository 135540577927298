export enum StorageLocationBadgeType {
  Recommended = 'Recommended',
  HighlyRated = 'HighlyRated',
  OpenLate = 'OpenLate',
}

export interface StorageLocationItemBadge {
  type: StorageLocationBadgeType;
  text: string;
}
