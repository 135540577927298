import { TaxItem } from '@luggagehero/shared/interfaces';

export type TaxesState = {
  taxes: TaxItem[];
  savingStates: Record<string, boolean>;
  deletingStates: Record<string, boolean>;
  isSavingDraft: boolean;
  isLoading: boolean;
};

export const initialState: TaxesState = {
  taxes: [],
  savingStates: {},
  deletingStates: {},
  isSavingDraft: false,
  isLoading: false,
};
