import { ChangeDetectorRef, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormControl, FormGroup } from '@angular/forms';
import { AppConfig } from '@luggagehero/shared/app-settings/data-access';
import { BankAccountCountryInfo, IBankAccount } from '@luggagehero/shared/interfaces';
import { SharedStripeService } from '@luggagehero/shared/services/stripe';
import { Observable } from 'rxjs';

import { BaseComponent } from '../../../core';
const noop = () => {
  /**/
};

@Component({ template: '' })
export class BankAccountFormBaseComponent extends BaseComponent implements ControlValueAccessor {
  @ViewChild('form') form: FormGroup;
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() cancel: EventEmitter<unknown>;
  @Input() allowCancel = true;

  private _value: IBankAccount;

  private _supportedCountries: BankAccountCountryInfo[];
  private _country: string;
  private _currency: string;
  private _accountNumber: string;
  private _routingNumber: string;
  private _bankCode: string;
  private _branchCode: string;
  private _accountHolderName: string;
  private _accountHolderType: string;

  private _isLoading = false;

  private onTouchedCallback: () => void = noop;
  private onChangeCallback: (_) => void = noop;

  constructor(
    private stripe: SharedStripeService,
    private cd: ChangeDetectorRef,
  ) {
    super();
    this.cancel = new EventEmitter<unknown>();
  }

  get valid(): boolean {
    if (this.isInvalidAccountNumber || this.isInvalidRoutingNumber) {
      return false;
    }
    return this.form.valid;
  }

  get valueChanges(): Observable<unknown> {
    return this.form.valueChanges;
  }

  get value(): IBankAccount {
    return this._value;
  }
  set value(value: IBankAccount) {
    if (!value || value === this._value) {
      return;
    }
    this._value = value;

    // HACK: Fixes validity state of form fields not updated
    setTimeout(() => this.cd.markForCheck(), 0);
    this.onChangeCallback(value);
  }

  get isLoading(): boolean {
    return this._isLoading;
  }
  @Input() set isLoading(value: boolean) {
    this._isLoading = value;
    this.cd.markForCheck();
  }

  get supportedCountries(): BankAccountCountryInfo[] {
    return this._supportedCountries;
  }
  @Input() set supportedCountries(value: BankAccountCountryInfo[]) {
    this._supportedCountries = value;
    this.updateValue(true);
  }

  get country(): string {
    return this._country;
  }
  @Input() set country(value: string) {
    this._country = value;
    this.updateValue(true);
  }

  get currency(): string {
    return this._currency;
  }
  @Input() set currency(value: string) {
    this._currency = value;
    this.updateValue(true);
  }

  get selectedCountry(): BankAccountCountryInfo {
    return this.supportedCountries?.find((c) => c.countryCode === this.country);
  }

  get supportedCurrencies() {
    return this.selectedCountry?.supportedCurrencies;
  }

  get defaultCurrency() {
    return this.selectedCountry?.defaultCurrency || 'eur';
  }

  get accountNumberLabel(): string {
    const currentCountry = AppConfig.SUPPORTED_BANK_ACCOUNT_COUNTRIES.find((c) => c.countryCode === this.country);
    return (currentCountry && currentCountry.accountNumberLabel) || 'ACCOUNT_NUMBER';
  }

  get routingNumberLabel(): string {
    const currentCountry = AppConfig.SUPPORTED_BANK_ACCOUNT_COUNTRIES.find((c) => c.countryCode === this.country);
    return (currentCountry && currentCountry.routingNumberLabel) || '';
  }

  get bankCodeLabel(): string {
    const currentCountry = AppConfig.SUPPORTED_BANK_ACCOUNT_COUNTRIES.find((c) => c.countryCode === this.country);
    return (currentCountry && currentCountry.bankCodeLabel) || '';
  }

  get branchCodeLabel(): string {
    const currentCountry = AppConfig.SUPPORTED_BANK_ACCOUNT_COUNTRIES.find((c) => c.countryCode === this.country);
    return (currentCountry && currentCountry.branchCodeLabel) || '';
  }

  get accountNumber(): string {
    return this._accountNumber;
  }
  set accountNumber(value: string) {
    this._accountNumber = value;
    this.updateValue();
  }

  get routingNumber(): string {
    return this._routingNumber;
  }
  set routingNumber(value: string) {
    this._routingNumber = value;
    this.updateValue();
  }

  get bankCode(): string {
    return this._bankCode;
  }
  set bankCode(value: string) {
    this._bankCode = value;
    this.updateValue();
  }

  get branchCode(): string {
    return this._branchCode;
  }
  set branchCode(value: string) {
    this._branchCode = value;
    this.updateValue();
  }

  get isInvalidAccountNumber(): boolean {
    if (!this.value || !this.value.accountNumber) {
      return false;
    }
    // TODO: Validate account number
    return false;
  }

  get isInvalidRoutingNumber(): boolean {
    if (!this.value || !this.value.routingNumber) {
      return false;
    }
    // TODO: Validate routing number
    return false;
  }

  get isAccountNumberPristine(): boolean {
    if (!this.controls) {
      return true;
    }
    return this.controls.accountNumber.pristine || this.controls.accountNumber.untouched;
  }

  get isRoutingNumberPristine(): boolean {
    if (!this.controls) {
      return true;
    }
    let input: AbstractControl;
    if ((input = this.controls.routingNumber) && (input.pristine || input.untouched)) {
      return true;
    }
    if ((input = this.controls.bankCode) && (input.pristine || input.untouched)) {
      return true;
    }
    if ((input = this.controls.branchCode) && (input.pristine || input.untouched)) {
      return true;
    }
    return false;
  }

  get invalidRoutingNumberError(): string {
    switch (this.country) {
      case 'CA':
        return 'INVALID_BANK_TRANSIT_OR_INSTITUTION_NUMBER';

      case 'GB':
      case 'UK':
        return 'INVALID_BANK_SORT_CODE';

      default:
        return 'INVALID_BANK_ROUTING_NUMBER';
    }
  }

  get controls(): { [key: string]: AbstractControl } {
    return this.form ? this.form.controls : null;
  }

  writeValue(value: IBankAccount) {
    if (value !== undefined) {
      this.value = value;
    }
  }

  registerOnChange(fn: (_) => void) {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: () => void) {
    this.onTouchedCallback = fn;
  }

  onCancel() {
    this.cancel.emit();
  }

  isMissing(control: FormControl): boolean {
    return control && control.enabled && control.invalid;
  }

  isComplete(control: FormControl): boolean {
    return control && control.enabled && control.valid;
  }

  isInvalid(control: FormControl): boolean {
    return this.isMissing(control) && control.touched;
  }

  markAllAsTouched() {
    for (const controlName in this.form.controls) {
      const control = this.form.controls[controlName];
      if (control) {
        control.markAsTouched();
        control.updateValueAndValidity();
      }
    }
  }

  printDebugInfo() {
    console.log(`this.country: ${this.country}`);
    console.log(`this.currency: ${this.currency}`);
    console.log(`this.value`, this.value);
    console.log(`this.isInvalidAccountNumber: ${this.isInvalidAccountNumber}`);
    console.log(`this.isInvalidRoutingNumber: ${this.isInvalidRoutingNumber}`);
  }

  private updateValue(reset = false) {
    if (reset) {
      this.accountNumber = '';
      this.routingNumber = '';
      this.bankCode = '';
      this.branchCode = '';
    }
    const newValue: IBankAccount = {
      country: this.country,
      currency: this.currency,
      accountNumber: this.accountNumber,
    };
    if (this.bankCode && this.branchCode) {
      newValue.routingNumber = `${this.bankCode}-${this.branchCode}`;
    } else if (this.routingNumber) {
      newValue.routingNumber = this.routingNumber;
    }
    this.value = newValue;
  }
}
