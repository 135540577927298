import { Injectable } from '@angular/core';
import { Config } from '@luggagehero/shared/environment';
import { SharedHttpService } from '@luggagehero/shared/services/http';

// Fallback currency conversion rates
export const DEFAULT_CONVERSION_RATES: CurrencyConversionData = {
  conversionRates: {
    EURUSD: 1.2,
    EURGBP: 0.88,
    EURDKK: 7.45,
    USDEUR: 0.84,
    USDGBP: 0.74,
    USDDKK: 6.23,
    GBPEUR: 1.13,
    GBPUSD: 1.36,
    GBPDKK: 8.44,
    DKKEUR: 0.13,
    DKKUSD: 0.16,
    DKKGBP: 0.12,
  },
  provider: 'internal',
};

export interface CurrencyConversionData {
  conversionRates: { [key: string]: number };
  provider: string;
}

interface CurrencyConversionRateDto {
  key: string;
  rate: number;
  provider: string;
  date: Date;
}

@Injectable()
export class CurrencyService {
  private data: CurrencyConversionData = DEFAULT_CONVERSION_RATES;
  private loadConversionRatesTask: Promise<void>;

  constructor(private httpService: SharedHttpService) {
    void this.init();
  }

  public async init(): Promise<void> {
    if (this.loadConversionRatesTask != null) {
      this.loadConversionRatesTask = this.loadConversionRates();
    }
    await this.loadConversionRatesTask;
  }

  private async loadConversionRates(): Promise<void> {
    const url = `${Config.environment.TRAVELER_API}/currency/conversion_rates`;
    const res = await this.httpService.get<CurrencyConversionRateDto[]>(url);

    if (!res || res.length === 0) {
      return;
    }

    this.data.provider = res[0].provider;

    res.forEach((conversionRate) => {
      this.data.conversionRates[conversionRate.key] = conversionRate.rate;
    });
  }

  public convert(amount: number, fromCurrency: string, toCurrency: string): number {
    if (!fromCurrency || !toCurrency) {
      return amount;
    }

    const src = fromCurrency.toUpperCase();
    const dst = toCurrency.toUpperCase();

    if (src === dst) {
      return amount;
    }

    const conversionKey = `${src}${dst}`;
    const conversionRate = this.data.conversionRates[conversionKey];

    if (conversionRate === undefined) {
      throw new Error(`Unsupported conversion (${fromCurrency} > ${toCurrency})`);
    }

    return (amount || 0) * conversionRate;
  }
}
