import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { RatingModule } from 'ngx-bootstrap/rating';

@Component({
  selector: 'lh-traveler-shops-ui-poi-list-details',
  standalone: true,
  imports: [CommonModule, RatingModule, FormsModule, TranslateModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './traveler-shops-ui-poi-list-details.component.html',
  styleUrls: ['./traveler-shops-ui-poi-list-details.component.scss'],
})
export class TravelerShopsUiPOIListDetailsComponent {
  @Input() poiListHtml: string[] = [];
}
