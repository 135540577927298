import { Inject, Pipe, PipeTransform } from '@angular/core';
import { ITranslateService, TRANSLATE_SERVICE } from '@luggagehero/shared/interfaces';

@Pipe({ name: 'lhTranslate', standalone: true })
export class TranslatePipe implements PipeTransform {
  constructor(@Inject(TRANSLATE_SERVICE) private translationService: ITranslateService) {}

  public transform(value: string, variant?: string): string {
    return this.translationService.instant(value, variant);
  }
}
