import { Injectable } from '@angular/core';
import { AppConfig } from '@luggagehero/shared/app-settings/data-access';
import { IDiscount } from '@luggagehero/shared/interfaces';
import { SharedPaymentService } from '@luggagehero/shared/services/payments';
import { SharedStorageService } from '@luggagehero/shared/services/storage';
import { SharedUserService } from '@luggagehero/shared/services/users';
import { CachedItem } from '@luggagehero/shared/util';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SharedPromoCodeService {
  public promoCode: BehaviorSubject<string>;

  public groupDiscountCode = AppConfig.DEFAULT_AUTO_GROUP_DISCOUNT_CODE;
  public groupDiscountMinBags = AppConfig.DEFAULT_AUTO_GROUP_DISCOUNT_MIN_BAGS;

  private _appliedDiscount: IDiscount;
  private _checkedPromoCode: string;
  private _checkedDiscount: IDiscount;

  private _task: Promise<IDiscount>;
  private _cache: Record<string, CachedItem<IDiscount>> = {};

  constructor(
    private paymentService: SharedPaymentService,
    private storageService: SharedStorageService,
    private userService: SharedUserService,
  ) {
    this.promoCode = new BehaviorSubject<string>(this.appliedPromoCode);
    this.userService.loginAnnounced.subscribe((loginEvent: { isLoggedIn: boolean; userLoggedOut: boolean }) => {
      if (loginEvent.userLoggedOut) {
        this.clearPromoCode();
      }
    });
    // this.criteria.change.pipe(filter((c) => (c ? true : false))).subscribe(async (c) => {
    //   const numberOfBags = c.luggage.normal + c.luggage.hand;
    //   if (numberOfBags >= this.groupDiscountMinBags) {
    //     await this.tryApplyGroupDiscount();
    //   } else {
    //     this.tryRemoveGroupDiscount();
    //   }
    // });
  }

  get appliedPromoCode(): string {
    return this.storageService.promoCode;
  }

  get appliedDiscount(): IDiscount {
    return this._appliedDiscount;
  }

  get isAppliedPromoCodeValid(): boolean {
    if (!this.appliedPromoCode || !this.appliedDiscount || !this.appliedDiscount.active) {
      return false;
    }
    return true;
  }

  get checkedPromoCode(): string {
    return this._checkedPromoCode;
  }

  get checkedDiscount(): IDiscount {
    return this._checkedDiscount;
  }

  get isCheckedPromoCodeValid(): boolean {
    if (!this.checkedPromoCode || !this.checkedDiscount || !this.checkedDiscount.active) {
      return false;
    }
    return true;
  }

  clearPromoCode() {
    this.storageService.promoCode = '';
    this._appliedDiscount = undefined;
    this._checkedPromoCode = '';
    this._checkedDiscount = undefined;

    // Notify listeners that the promo code was removed
    this.promoCode.next('');
  }

  async checkPromoCode(code: string): Promise<IDiscount> {
    if (code) {
      code = code.toUpperCase();
    }
    this._checkedPromoCode = code;

    if (this._cache[code]?.isValid) {
      // Use the cached value for this promo code
      this._checkedDiscount = this._cache[code].value;
    } else {
      // No valid value in cache for this promo code
      try {
        this._checkedDiscount = await this.paymentService.getDiscount(code);

        if (this._checkedDiscount) {
          // Update the cache
          if (this._cache[code]) {
            this._cache[code].value = this._checkedDiscount;
          } else {
            this._cache[code] = new CachedItem(this._checkedDiscount);
          }
        } else {
          this._checkedDiscount = undefined;
        }
      } catch {
        // TODO: Handle error
        this._checkedDiscount = undefined;
      }
    }

    if (this._checkedDiscount && ['absolute', 'bags'].includes(this._checkedDiscount.type)) {
      // HACK: Don't allow absolute or bags discounts for now
      this._checkedDiscount = undefined;
    }

    return this.checkedDiscount;
  }

  async applyPromoCode(code: string): Promise<IDiscount> {
    this._task = this.apply(code);
    return this._task;
  }

  async waitForAppliedDiscount(): Promise<IDiscount> {
    // TODO: Refactor this
    if (this._task != null) {
      // Await any ongoing async operation
      await this._task;
    } else if (
      this.appliedPromoCode &&
      (!this.appliedDiscount || this.appliedDiscount.code !== this.appliedPromoCode)
    ) {
      await this.applyPromoCode(this.appliedPromoCode);
    }

    return this.appliedDiscount;
  }

  private async apply(code: string): Promise<IDiscount> {
    if (code) {
      code = code.toUpperCase();
    }
    // Set applied promo code
    this.storageService.promoCode = code;

    if (this.appliedPromoCode !== this.checkedPromoCode) {
      // This code has not been checked, so we need to do that
      await this.checkPromoCode(this.appliedPromoCode);
    }
    // Code is checked, set the applied discount if any
    this._appliedDiscount = this.checkedDiscount;

    // Notify listeners of new promo code
    this.promoCode.next(this.appliedPromoCode);

    return this.appliedDiscount;
  }

  private async tryApplyGroupDiscount() {
    if (this.appliedDiscount) {
      // There is already another discount applied
      return;
    }
    await this.applyPromoCode(this.groupDiscountCode);
  }

  private tryRemoveGroupDiscount() {
    if (this.appliedDiscount?.code !== this.groupDiscountCode) {
      // Group discount is not currently applied
      return;
    }
    this.clearPromoCode();
  }
}
