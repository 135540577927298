// angular
import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
// libs
import { DynamicFormButtonBaseComponent } from '@luggagehero/features/ui';

@Component({
  selector: 'lh-dynamic-form-button',
  templateUrl: './dynamic-form-button.component.html',
  styleUrls: ['./dynamic-form-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DynamicFormButtonComponent extends DynamicFormButtonBaseComponent {
  constructor(cd: ChangeDetectorRef) {
    super(cd);
  }
}
