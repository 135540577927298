// angular
import { NgModule } from '@angular/core';
// feature modules
import { UserModalComponent, UserModule } from '@luggagehero/web/features/user';

@NgModule({
  imports: [UserModule],
  exports: [UserModalComponent],
})
export class UserLoaderModule {}
