import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { Router } from '@angular/router';
import { TabBarBaseComponent } from '@luggagehero/features/ui';
import { SharedUserService } from '@luggagehero/shared/services/users';
@Component({
  selector: 'lh-tab-bar',
  templateUrl: 'tab-bar.component.html',
  styleUrls: ['tab-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabBarComponent extends TabBarBaseComponent {
  constructor(userService: SharedUserService, router: Router, cd: ChangeDetectorRef) {
    super(userService, router, cd);
  }
}
