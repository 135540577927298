import { ChangeDetectorRef, Component } from '@angular/core';
import { SharedBookingService } from '@luggagehero/shared/services/bookings';
import { SharedErrorService } from '@luggagehero/shared/services/error';
import { SharedShopsService } from '@luggagehero/shared/services/shops';
import { SharedStorageService } from '@luggagehero/shared/services/storage';
import { TranslateService } from '@ngx-translate/core';

import { BookingActionBaseComponent } from './booking-action.base-component';

@Component({ template: '' })
export abstract class UndoDropOffActionBaseComponent extends BookingActionBaseComponent {
  constructor(
    private translate: TranslateService,
    private error: SharedErrorService,
    bookingService: SharedBookingService,
    shopsService: SharedShopsService,
    storageService: SharedStorageService,
    cd: ChangeDetectorRef,
  ) {
    super(bookingService, shopsService, storageService, cd);
  }

  async undoCheckIn() {
    this.isLoading = true;

    try {
      const res = await this.bookingService.undoCheckIn(this.booking._id);
      this.updateBooking(res);
      this.close();
    } catch (err) {
      this.error.handleError(err, this.translate.instant('UNEXPECTED_ERROR') as string);
    }

    this.isLoading = false;
  }
}
