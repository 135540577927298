import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  inject,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';

import { SharedUiSpinnerComponent } from '../spinner/shared-ui-spinner.component';

@Component({
  selector: 'lh-shared-ui-confirm-button',
  standalone: true,
  imports: [CommonModule, TranslateModule, FormsModule, SharedUiSpinnerComponent],
  templateUrl: './shared-ui-confirm-button.component.html',
  styleUrl: './shared-ui-confirm-button.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SharedUiConfirmButtonComponent implements OnDestroy {
  @Output() public confirm = new EventEmitter<void>();
  @Input() public mode: 'primary' | 'success' | 'warning' = 'primary';

  public destroy$: Subject<unknown> = new Subject();

  private _cd = inject(ChangeDetectorRef);
  private _translateService = inject(TranslateService);

  get currentLang(): string {
    return this._translateService.currentLang;
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  protected _detectChanges(): void {
    this._cd.detectChanges();
  }

  protected _markForCheck(): void {
    this._cd.markForCheck();
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  protected _translate(key: string | string[], params?: Object): string {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return this._translateService.instant(key, params);
  }

  public value = 0;

  private translate = inject(TranslateService);
  private cd = inject(ChangeDetectorRef);

  private _slideCallToAction = this.translate.instant('SLIDE_TO_CONFIRM') as string;
  private _clickCallToAction = this.translate.instant('CONFIRM') as string;
  private _disabled = false;
  private _confirmed = false;
  private _isLoading = false;
  private checkValueTimeout?: ReturnType<typeof setTimeout>;

  public get isLoading(): boolean {
    return this._isLoading;
  }
  @Input() public set isLoading(value: boolean) {
    console.log(`Confirm button isLoading: ${value}`);
    this._isLoading = value;
    this.cd.markForCheck();
  }

  public get disabled(): boolean {
    return this._disabled;
  }
  @Input() public set disabled(value: boolean) {
    this._disabled = value;
    this.cd.markForCheck();
  }

  public get slideCallToAction(): string {
    return this._slideCallToAction;
  }
  @Input() public set slideCallToAction(value: string) {
    this._slideCallToAction = value;
    this.cd.markForCheck();
  }

  public get clickCallToAction(): string {
    return this._clickCallToAction;
  }
  @Input() public set clickCallToAction(value: string) {
    this._clickCallToAction = value;
    this.cd.markForCheck();
  }

  public get confirmed(): boolean {
    return this._confirmed;
  }
  public set confirmed(value: boolean) {
    this._confirmed = value;
    this.cd.markForCheck();
  }

  public get isTouch(): boolean {
    return 'ontouchstart' in window;
  }

  public reset() {
    this.value = 0;
    this.confirmed = false;
  }

  public checkValue(): void {
    clearTimeout(this.checkValueTimeout);
    this.checkValueTimeout = setTimeout(() => this._checkValue(), 100);
  }

  public getButtonContentLength(value: string): number {
    const textLength = Math.max(value.length - 7, 0);
    return Math.floor(textLength / 7);
  }

  public getSliderContentLength(value: string): number {
    const textLength = Math.max(value.length, 0);
    return Math.floor(textLength / 7);
  }

  private _checkValue(): void {
    if (this.value === 100) {
      // Notify listeners that the user has requested confirmation
      this.confirm.emit();

      // Give listeners a chance to cancel and reset the value before setting confirmed
      setTimeout(() => (this.confirmed = this.value === 100), 100);
    } else {
      // Partial slide, reset
      this.reset();
    }
  }
}
