/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

// timeout to check if a user is inactive for
const USER_INACTIVE_TIMEOUT = 5 * 60 * 1000; // 5 minutes

@Injectable()
export class UserActiveService {
  public userIdle: BehaviorSubject<boolean> = new BehaviorSubject(false);

  private _isUserIdle = false;
  private _timeout: NodeJS.Timeout;

  private get isUserIdle() {
    return this._isUserIdle;
  }
  private set isUserIdle(idle: boolean) {
    this._isUserIdle = idle;
    this.userIdle.next(idle);
  }

  registerUserIdleTimeMonitoring() {
    this.resetTimeout();

    // DOM Events
    document.onmousemove = this.resetTimeout.bind(this);
    document.onkeypress = this.resetTimeout.bind(this);
  }

  unregisterUserIdleTimeMonitoring() {
    document.onmousemove = undefined;
    document.onkeypress = undefined;
    this.isUserIdle = false;
    clearTimeout(this._timeout);
  }

  private resetTimeout() {
    if (this.isUserIdle && this._timeout) {
      this.isUserIdle = false;
    }

    clearTimeout(this._timeout);
    this._timeout = setTimeout(() => {
      this.userIdle.next(true);
      this.isUserIdle = true;
    }, USER_INACTIVE_TIMEOUT);
  }
}
