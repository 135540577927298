import {
  storageLocationsSetupReducer,
  StorageLocationsSetupState,
} from '@luggagehero/dashboard-storage-locations-setup-data-access';
import * as taxes from '@luggagehero/dashboard-taxes-data-access';
import * as guestOrders from '@luggagehero/guest-data-access-orders';
import { Config } from '@luggagehero/shared/environment';
import { ActionReducerMap, MetaReducer } from '@ngrx/store';
import { storeFreeze } from 'ngrx-store-freeze';

import * as multilingual from '../../i18n';

export interface IAppState {
  i18n: multilingual.IMultilingualState;
  taxes: taxes.TaxesState;
  orders: guestOrders.OrdersState;
  orderRequests: guestOrders.OrderRequestsState;
  storageLocationsSetup: StorageLocationsSetupState;
}

export const reducers: ActionReducerMap<IAppState> = {
  i18n: multilingual.reducer,
  taxes: taxes.taxesReducer,
  orders: guestOrders.ordersReducer,
  orderRequests: guestOrders.orderRequestsReducer,
  storageLocationsSetup: storageLocationsSetupReducer,
};

export const metaReducers: MetaReducer<IAppState>[] = !Config.isProduction ? [storeFreeze] : [];
