import { ChangeDetectorRef, ComponentRef, Directive, inject, Input, ViewChild, ViewContainerRef } from '@angular/core';
import { Config } from '@luggagehero/shared/environment';
import { Observable, Subscription } from 'rxjs';

import { BaseComponent } from '../../../../core';
import { Step } from './step';
import { StepBaseComponent } from './step-base.component';

@Directive()
export abstract class StepNavigationBaseComponent extends BaseComponent {
  @ViewChild('stepContainer', { read: ViewContainerRef }) public stepContainer: ViewContainerRef;

  public stepComponentRef: ComponentRef<StepBaseComponent>;
  public currentStepIndex = 0;

  private cd = inject(ChangeDetectorRef);

  private _steps: Step<StepBaseComponent>[];
  private currentStepSubscriptions: Subscription[] = [];

  public get steps(): Step<StepBaseComponent>[] {
    return this._steps;
  }
  @Input() public set steps(value: Step<StepBaseComponent>[]) {
    this._steps = value;
    this.currentStepIndex = 0;
    this.loadCurrentStep();
  }

  public get countableSteps(): Step<StepBaseComponent>[] {
    return this._steps.filter((step) => step.isStepCountableInNavigation);
  }

  public get allowStepNavigation(): boolean {
    return Config.isDevelopment;
  }

  public get currentStep(): Step<StepBaseComponent> {
    if (!this.steps || this.steps.length <= this.currentStepIndex) {
      return null;
    }
    return this.steps[this.currentStepIndex];
  }

  public get showNavigation(): boolean {
    // TODO: This is not updated when the state of the step instance changes
    return this.stepComponentRef?.instance.hideNavigation ? false : true;
  }

  public get hasNextStep(): boolean {
    return this.currentStepIndex < this.steps.length - 1;
  }

  public get canGoForward(): boolean {
    // TODO: This is not updated when the state of the step instance changes
    return this.stepComponentRef?.instance.canGoForward ? true : false;
  }

  public get canGoBack(): boolean {
    if (this.currentStepIndex === 0) {
      return false;
    }
    // TODO: This is not updated when the state of the step instance changes
    return this.stepComponentRef?.instance.canGoBack ? true : false;
  }

  public get canSkip(): boolean {
    if (this.canGoForward) {
      return false;
    }
    // TODO: This is not updated when the state of the step instance changes
    return this.stepComponentRef?.instance.canSkip ? true : false;
  }

  public get forwardCallToAction(): string {
    return this.stepComponentRef?.instance.forwardCallToAction || 'NEXT';
  }

  public get backCallToAction(): string {
    return this.stepComponentRef?.instance.backCallToAction || 'BACK';
  }

  public goForward() {
    this.stepComponentRef?.instance.goForward();
  }

  public goBack() {
    this.stepComponentRef?.instance.goBack();
  }

  private onStepForward() {
    if (this.currentStepIndex < this.steps.length - 1) {
      this.currentStepIndex++;
      this.loadStep(this.currentStep);
    }
  }

  private onStepBack() {
    if (this.currentStepIndex > 0) {
      this.currentStepIndex--;
      this.loadStep(this.currentStep);
    }
  }

  public goToStep(index: number) {
    if (!this.allowStepNavigation) {
      return;
    }
    if (index >= 0 && index < this.steps.length) {
      this.currentStepIndex = index;
      this.loadStep(this.currentStep);
    }
  }

  public loadCurrentStep() {
    if (!this.currentStep) {
      return;
    }
    this.loadStep(this.currentStep);
  }

  public loadStep(step: Step<StepBaseComponent>) {
    // Destroy the current step if it exists
    this.destroyCurrentStep();

    // Create the component inside the container and store the reference to the component instance
    this.stepComponentRef = this.stepContainer.createComponent(step.component);

    // Subscribe to navigation events from the step component
    this.addStepSubscription(this.stepComponentRef.instance.forward, () => this.onStepForward());
    this.addStepSubscription(this.stepComponentRef.instance.back, () => this.onStepBack());

    //
    // HACK: Some step components don't render properly unless we force change detection after the Angular
    // initialization has completed
    //
    this.cd.detectChanges();
  }

  private destroyCurrentStep() {
    try {
      if (this.currentStepSubscriptions?.length > 0) {
        this.currentStepSubscriptions.forEach((subscription) => subscription.unsubscribe());
        this.currentStepSubscriptions = [];
      }
    } catch {
      // Ignore
    }

    this.stepContainer?.clear();

    this.stepComponentRef?.destroy();
    this.stepComponentRef = null;
  }

  protected addStepSubscription<T>(observable: Observable<T>, handler: (value: T) => void) {
    this.currentStepSubscriptions.push(observable.subscribe(handler));
  }
}
