import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AppConfig } from '@luggagehero/shared/app-settings/data-access';
import { Config } from '@luggagehero/shared/environment';
import { SharedErrorService } from '@luggagehero/shared/services/error';
import { SharedLoggingService } from '@luggagehero/shared/services/logging';
import { SharedNotificationService } from '@luggagehero/shared/services/notification';
import { SharedStorageService } from '@luggagehero/shared/services/storage';
import { INewUser, SharedUserService } from '@luggagehero/shared/services/users';
import { SharedWindowService } from '@luggagehero/shared/services/window';
import { SharedUtilPassword } from '@luggagehero/shared/util';
import { TranslateService } from '@ngx-translate/core';

import { BaseComponent } from '../../../core';
import { RouterExtensions } from '../../../core/services/index';
import { FacebookLoginService, GoogleSigninService } from '../../../services';
import { StringUtil } from '../../../utils/string.util';

type UserType = 'new' | 'existing';

interface UserInfo {
  email: string;
  password: string;
  name: string;
  phone: string;
  phoneCountry: string;
  type: UserType;
}

@Component({ template: '' })
export abstract class LoginBaseComponent extends BaseComponent implements OnInit {
  @Output() public isExistingUserChange = new EventEmitter<boolean>();
  @Output() public userEmailChange = new EventEmitter<string>();

  @Input() public isStorageManager = false;

  public user: UserInfo = {
    email: '',
    password: '',
    name: '',
    phone: '',
    phoneCountry: '',
    type: 'existing',
  };
  public isEmailFormVisible = false;
  public isPhoneFormVisible = false;
  public get showSkipLogin(): boolean {
    return !Config.isProduction;
  }
  public showPassword = false;
  public bookingRequested = false;
  public isMobileSignInEnabled = AppConfig.IS_PHONE_SIGN_IN_ENABLED;
  public signInUsingMobile = this.isMobileSignInEnabled ? true : false;
  public phoneCountries = AppConfig.SUPPORTED_PHONE_COUNTRIES;

  private _forceEmailLogin = false;
  private _requireName = false;
  private _requirePhone = false;
  private _choosePassword = false;
  private _isLoading = false;

  constructor(
    protected userService: SharedUserService,
    protected facebookLogin: FacebookLoginService,
    protected googleSignin: GoogleSigninService,
    protected storage: SharedStorageService,
    protected window: SharedWindowService,
    protected router: RouterExtensions,
    protected translate: TranslateService,
    protected notify: SharedNotificationService,
    protected error: SharedErrorService,
    protected log: SharedLoggingService,
    protected cd: ChangeDetectorRef,
  ) {
    super();
  }

  get isSwapHero() {
    return this.window.appName.match(/swap/i);
  }

  get message(): string {
    if (this.isSwapHero) {
      return this.translate.instant(
        this.signInUsingMobile ? 'SWAP_UPDATES_WILL_BE_SENT_VIA_SMS' : 'SWAP_UPDATES_WILL_BE_SENT_VIA_EMAIL',
      ) as string;
    }
    if (!this.bookingRequested) {
      return this.translate.instant(
        this.signInUsingMobile ? 'BOOKING_CONFIRMATION_VIA_SMS' : 'BOOKING_CONFIRMATION_VIA_EMAIL',
      ) as string;
    }
    return '';
  }

  get forceEmailLogin(): boolean {
    return this._forceEmailLogin;
  }
  @Input() set forceEmailLogin(value: boolean) {
    if (value && value !== this._forceEmailLogin) {
      this.isEmailFormVisible = true;
      this.isPhoneFormVisible = false;
      this.signInUsingMobile = false;
    }
    this._forceEmailLogin = value;
    this.cd.markForCheck();
  }

  get requireName(): boolean {
    return this._requireName;
  }
  @Input() set requireName(value: boolean) {
    this._requireName = value;
    this.cd.markForCheck();

    this.loadForms();
  }

  get requirePhone(): boolean {
    return this._requirePhone;
  }
  @Input() set requirePhone(value: boolean) {
    this._requirePhone = value;
    this.cd.markForCheck();

    this.loadForms();
  }

  get choosePassword(): boolean {
    return this._choosePassword;
  }
  @Input() set choosePassword(value: boolean) {
    this._choosePassword = value;
    this.cd.markForCheck();

    this.loadForms();
  }

  get isExistingUser(): boolean {
    return this.user.type === 'existing';
  }
  @Input() set isExistingUser(value: boolean) {
    this.user.type = value ? 'existing' : 'new';
    this.cd.markForCheck();
  }

  get userEmail(): string {
    return this.user.email;
  }
  @Input() set userEmail(value: string) {
    if (!value || value === this.user.email) {
      return;
    }
    this.user.email = value;
    this.isEmailFormVisible = true;
    this.isPhoneFormVisible = false;
    this.cd.markForCheck();
  }

  get isLoading(): boolean {
    return this._isLoading;
  }
  set isLoading(value: boolean) {
    this._isLoading = value;
    this.cd.markForCheck();
  }

  get isNewUser(): boolean {
    return this.user.type === 'new';
  }

  set userType(value: UserType) {
    const wasExistingUser = this.isExistingUser;

    this.user.type = value;
    this.cd.markForCheck();

    if (this.isExistingUser !== wasExistingUser) {
      this.isExistingUserChange.emit(this.isExistingUser);
    }
  }
  get userType(): UserType {
    return this.user.type;
  }

  abstract get isValidPhoneNumber(): boolean;
  abstract get currentEmailForm(): FormGroup;
  abstract loadForms(): void;

  ngOnInit() {
    this.bookingRequested = this.userService.bookingRequested;
    this.cd.markForCheck();

    this.loadForms();
  }

  async logInWithFacebook() {
    const facebookAuthResponse = await this.facebookLogin.login();

    if (!facebookAuthResponse) {
      return;
    }
    this.isLoading = true;

    try {
      // Authenticate user against our API with the Facebook credentials
      await this.userService.authenticateFacebookUser(facebookAuthResponse);

      // Authentication succeeded, get the user info and proceed
      this.getUserAndRedirect();
    } catch (err) {
      this.error.handleError(
        err,
        this.translate.instant('LOGIN_ERROR_MESSAGE') as string,
        this.translate.instant('LOGIN_ERROR_TITLE') as string,
      );
    }

    this.isLoading = false;
  }

  async signInWithGoogle() {
    const googleAuthResponse = await this.googleSignin.signIn();

    if (!googleAuthResponse) {
      return;
    }

    try {
      await this.userService.authenticateGoogleUser(googleAuthResponse);

      this.getUserAndRedirect();
    } catch (err) {
      this.error.handleError(
        err,
        this.translate.instant('LOGIN_ERROR_MESSAGE') as string,
        this.translate.instant('LOGIN_ERROR_TITLE') as string,
      );
    }
    this.isLoading = false;
  }

  emailLogin() {
    if (this.isNewUser) {
      this.register();
    } else {
      void this.login();
    }
  }

  skipLogin() {
    this.user.email = `${new Date().getTime()}@example.com`;
    this.user.password = 'aBcd1234!';
    this.user.name = 'Test User';

    this.register();
  }

  async login() {
    this.isLoading = true;

    try {
      const response = await (this.signInUsingMobile
        ? this.userService.loginWithPhone({
            phoneNumber: this.user.phone,
            phoneCountry: this.user.phoneCountry,
            locale: this.storage.language || this.window.locale,
            password: this.user.password,
          })
        : this.userService.loginWithEmail({
            email: this.user.email,
            password: this.user.password,
          }));

      if (response.success) {
        this.getUserAndRedirect();
      } else {
        this.error.handleError(
          response.message,
          response.message,
          this.translate.instant('LOGIN_ERROR_TITLE') as string,
        );
      }
    } catch (err) {
      this.user.password = '';
      this.markExistingFormPristine();
      this.isPhoneFormVisible = false;

      if (this._extractStatus(err) === 401 || this._extractStatus(err) === 429) {
        this.onAuthenticationFailed();
      } else {
        this.error.handleError(err, StringUtil.errorToString(err) || 'Uknown error, please try again');
      }
    }

    this.isLoading = false;
  }

  private _extractStatus(err: unknown): number {
    return typeof err === 'object' && 'status' in err ? (err as { status: number }).status : -1;
  }

  public onAuthenticationFailed(): void {
    this.notify.error(`Login failed, please try again`);
  }

  register() {
    if (!this.choosePassword) {
      this.user.password = SharedUtilPassword.generate();
    }

    if (!this.user.name) {
      this.user.name = 'Guest User';
    }

    const newUser: INewUser = {
      email: this.user.email,
      password: this.user.password,
      name: this.user.name,
      sendPassword: !this.choosePassword,
    };

    if (this.isStorageManager) {
      newUser.isShopOwner = true;
    }

    if (this.isValidPhoneNumber) {
      newUser.phone = this.user.phone;
      newUser.phoneCountry = this.user.phoneCountry;
    }

    this.isLoading = true;
    this.userService.registerUser(newUser).then(
      (response) => {
        if (response.success) {
          this.userService.getUserAndRedirect().then(
            (_) => {
              this.isLoading = false;
            },
            (err) => {
              this.error.handleError(
                StringUtil.errorToString(err),
                this.translate.instant('REGISTER_USER_ERROR_MESSAGE') as string,
                this.translate.instant('REGISTER_USER_ERROR_TITLE') as string,
              );
              this.isLoading = false;
            },
          );
        } else {
          this.error.handleError(
            response.message,
            response.message,
            this.translate.instant('REGISTER_USER_ERROR_TITLE') as string,
          );
          this.isLoading = false;
        }
      },
      (err) => {
        this.error.handleError(err, StringUtil.errorToString(err));
        this.isLoading = false;
      },
    );
  }

  abstract isEmailFieldValid(): boolean;
  abstract markEmailFieldAsDirty(): void;
  abstract markNewUserFormPristine(): void;
  abstract markExistingFormPristine(): void;
  abstract markPhoneNumberFormPristine(): void;

  resetPassword() {
    if (!this.isEmailFieldValid()) {
      this.markEmailFieldAsDirty();
      this.notify.warning(this.translate.instant('RESET_PASSWORD_MISSING_EMAIL') as string);
      this.cd.markForCheck();
      return;
    }
    if (!this.confirmPasswordReset()) {
      return;
    }
    this.isLoading = true;
    this.userService.resetPasswordViaEmail(this.user.email).then(
      (_res) => {
        this.notify.success(this.translate.instant('RESET_PASSWORD_SUCCESS_MESSAGE') as string);
        this.isLoading = false;
      },
      (err) => {
        this.error.handleError(err, StringUtil.errorToString(err));
        this.isLoading = false;
      },
    );
  }

  abstract getUserConfirmation(prompt: string): boolean;

  private confirmPasswordReset(): boolean {
    const part1 = this.translate.instant('CONFIRM_RESET_PASSWORD_PART_1') as string;
    const part2 = this.translate.instant('CONFIRM_RESET_PASSWORD_PART_2') as string;
    return this.getUserConfirmation(`${part1} ${this.user.email}. ${part2}.`);
  }

  public getUserAndRedirect() {
    // Reset email forms
    this.user = { email: '', password: '', name: '', phone: '', phoneCountry: '', type: 'existing' };
    this.markNewUserFormPristine();
    this.markExistingFormPristine();
    this.markPhoneNumberFormPristine();

    this.userService.getUserAndRedirect().then(
      (_) => {
        this.isLoading = false;
      },
      (err) => {
        this.error.handleError(
          err,
          this.translate.instant('LOGIN_ERROR_MESSAGE') as string,
          this.translate.instant('LOGIN_ERROR_TITLE') as string,
        );
        this.isLoading = false;
      },
    );
  }
}
