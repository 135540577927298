export const DEFAULT_CACHE_LIFETIME = 5 * 60 * 1000; // 5 minutes

export class CachedItem<T> {
  public lifeTime: number;

  private _value: T | undefined;
  private _lastUpdated: Date = new Date();

  constructor(value?: T, lifeTime: number = DEFAULT_CACHE_LIFETIME) {
    this.value = value;
    this.lifeTime = lifeTime;
  }

  get value(): T | undefined {
    return this._value;
  }
  set value(value: T | undefined) {
    this._value = value;
    this._lastUpdated = new Date();
  }

  get lastUpdated(): Date {
    return this._lastUpdated;
  }

  get age(): number {
    return new Date().getTime() - this.lastUpdated.getTime();
  }

  get isValid(): boolean {
    if (!this.value) {
      return false;
    }
    return this.age < this.lifeTime;
  }
}
