import { Actions, ActionTypes } from '../actions/multilingual.action';
import { IMultilingualState, initialState } from '../states/multilingual.state';

export function reducer(state: IMultilingualState = initialState, action: Actions): IMultilingualState {
  switch (action.type) {
    case ActionTypes.LANG_CHANGED:
      if (state.lang !== action.payload) {
        return { ...state, lang: action.payload };
      }
      return state;

    default:
      return state;
  }
}
