import { ChangeDetectorRef, Directive, HostBinding, HostListener, Input, OnInit } from '@angular/core';

const INITIAL_RETRY_INTERVAL_SECONDS = 3;
const MAX_ATTEMPTS = 5;

type Optional<T> = T | null | undefined;

type ImageSize = 'large' | 'medium' | 'thumb';

@Directive({
  selector: '[lhSharedDirectiveBackgroundImage]',
  standalone: true,
})
export class SharedDirectiveBackgroundImageDirective implements OnInit {
  @HostBinding('class.load-success') public success = false;
  @HostBinding('class.load-error') public error = false;
  @HostBinding('style.background-image') public backgroundImage: string | undefined;

  private _remoteImage: Optional<string>;
  private _remoteImageSize: Optional<ImageSize>;
  private _localImage: Optional<string>;
  private _imagePlaceholder: Optional<string>;

  private attemptNumber = 0;
  private retryInterval = INITIAL_RETRY_INTERVAL_SECONDS * 1000;
  @Input() public baseUrl = '';

  @Input() public set remoteImage(value: Optional<string>) {
    this._remoteImage = value;
    this.loadImage();
  }
  public get remoteImage(): Optional<string> {
    return this._remoteImage;
  }

  @Input() public set remoteImageSize(value: Optional<ImageSize>) {
    this._remoteImageSize = value;
    this.loadImage();
  }
  public get remoteImageSize(): Optional<ImageSize> {
    return this._remoteImageSize;
  }

  @Input() public set localImage(value: Optional<string>) {
    this._localImage = value;
    this.loadImage();
  }
  public get localImage(): Optional<string> {
    return this._localImage;
  }

  @Input() public set imagePlaceholder(value: Optional<string>) {
    this._imagePlaceholder = value;
    this.loadImage();
  }
  public get imagePlaceholder(): Optional<string> {
    return this._imagePlaceholder;
  }

  public get remoteImageUrl(): Optional<string> {
    return `${this.baseUrl}/images/${this.remoteImageSize || 'medium'}/${this.remoteImage}`;
  }

  constructor(private cd: ChangeDetectorRef) {}

  @HostListener('error') public onError() {
    this.error = true;
    this.success = false;
  }

  @HostListener('load') public onLoad() {
    this.success = true;
    this.error = false;
  }

  public ngOnInit() {
    this.loadImage();
  }

  private loadImage() {
    this.success = false;
    this.error = false;
    this.attemptNumber = 0;

    if (this.localImage) {
      this.showLocalImage();
    } else if (this.remoteImage) {
      this.loadRemoteImage();
    } else if (this.imagePlaceholder) {
      this.showImagePlaceholder();
    }
  }

  private showLocalImage() {
    this.backgroundImage = `url('${this.localImage}')`;
    this.success = true;
    this.error = false;
    this.cd.markForCheck();
  }

  private loadRemoteImage() {
    if (this.success) {
      return; // The image has loaded successfully, stop trying
    }
    if (this.attemptNumber >= MAX_ATTEMPTS) {
      return; // Maximum attempts reached, stop trying
    }
    this.attemptNumber++;

    let imageUrl = this.remoteImageUrl;
    if (this.attemptNumber > 1) {
      // Update image URL with new timestamp to force reload
      imageUrl += `?${new Date().getTime()}`;

      // Double the interval after every retry
      this.retryInterval *= 2;
    }
    // Update the background-image style binding on the element
    this.backgroundImage = `url('${imageUrl}')`;
    this.cd.markForCheck();

    // Keep trying until image loads successfully or max attempts is reached
    setTimeout(() => this.loadRemoteImage(), this.retryInterval);
  }

  private showImagePlaceholder() {
    this.backgroundImage = `url('${this.imagePlaceholder}')`;
    this.cd.markForCheck();
  }
}
