import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Config } from '@luggagehero/shared/environment';
import { InitiatePaymentResult, IPaymentRecord, IStripeSetupIntentResult } from '@luggagehero/shared/interfaces';
import { SharedLoadingService } from '@luggagehero/shared/services/loading';
import { SharedStripeService } from '@luggagehero/shared/services/stripe';
import { SharedUiSpinnerComponent } from '@luggagehero/shared/ui';
import { SharedUtilString } from '@luggagehero/shared/util';
import { StripeCardElement, StripeCardElementChangeEvent } from '@stripe/stripe-js';
import { Subscription } from 'rxjs';

@Component({
  selector: 'lh-shared-feature-payment-card-input',
  standalone: true,
  imports: [CommonModule, FormsModule, SharedUiSpinnerComponent],
  templateUrl: './shared-feature-payment-card-input.component.html',
  styleUrl: './shared-feature-payment-card-input.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SharedFeaturePaymentCardInputComponent implements OnInit, AfterViewInit, OnDestroy {
  @Output() readonly paymentCardResult = new EventEmitter<IStripeSetupIntentResult>();
  @Output() readonly canSubmitChange = new EventEmitter<boolean>();
  @ViewChild('cardContainer') public cardContainer: ElementRef<HTMLElement>;

  public cardElement: StripeCardElement;
  public cardError: string | undefined;

  private subscriptions = new Subscription();
  private _payment: IPaymentRecord<InitiatePaymentResult>;
  private _canSubmit = false;
  private isConfirming = false;
  private _isLoading = false;

  private loader = inject(SharedLoadingService);
  private stripe = inject(SharedStripeService);
  private cd = inject(ChangeDetectorRef);

  public get isLoading(): boolean {
    return this._isLoading;
  }
  public set isLoading(value: boolean) {
    this._isLoading = value;
    this.cd.markForCheck();
  }

  get payment(): IPaymentRecord<InitiatePaymentResult> {
    return this._payment;
  }
  @Input() set payment(value: IPaymentRecord<InitiatePaymentResult>) {
    this._payment = value;
    this.cd.markForCheck();
  }

  public get canSubmit(): boolean {
    return this._canSubmit;
  }

  public ngOnInit(): void {
    this.subscriptions.add(
      this.loader.loading$.subscribe((value) => {
        this.isLoading = value;
      }),
    );
  }

  public async ngAfterViewInit(): Promise<void> {
    this.cardElement = await this.stripe.createCardElement(this.cardContainer);
    this.cardElement.on('change', (e) => this.onCardElementChange(e));
  }

  public ngOnDestroy() {
    if (this.cardElement) {
      this.cardElement.off('change');
      this.cardElement.unmount();
      this.cardElement.destroy();
    }

    this.subscriptions.unsubscribe();
  }

  public onCardElementChange(e: StripeCardElementChangeEvent) {
    let canSubmit: boolean;

    if (e.error) {
      canSubmit = false;
      this.cardError = e.error.message;
    } else {
      canSubmit = e.complete;
      this.cardError = null;
    }

    if (this._canSubmit !== canSubmit) {
      this._canSubmit = canSubmit;
      this.canSubmitChange.emit(canSubmit);
    }

    this.cd.detectChanges(); // TODO: Is this necessary?
  }

  public async onSubmit() {
    await this.confirmPaymentCard();
  }

  public submit() {
    void this.onSubmit();
  }

  private async confirmPaymentCard(): Promise<void> {
    if (this.isConfirming) {
      return;
    }
    this.isConfirming = true;

    try {
      await this.confirmStripe();
    } finally {
      this.isConfirming = false;
    }
  }

  private async confirmStripe(): Promise<void> {
    if (this.cardError) {
      return;
    }

    // Use the payment provided via binding if available
    const clientSecret = this.payment?.data.client_secret;

    if (!clientSecret) {
      return;
    }

    await this.loader.load(async () => {
      const result = await this.stripe.confirmPayment(clientSecret, this.cardElement);
      this.paymentCardResult.emit(result);

      if (!Config.isProduction) {
        if (result.error) {
          console.debug(SharedUtilString.formatPaymentEvent(this.payment.data, 'failed'), result);
        } else {
          console.debug(SharedUtilString.formatPaymentEvent(this.payment.data, 'confirmed'), result);
        }
      }
    });
  }
}
