import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  inject,
  Input,
  Output,
} from '@angular/core';
import { AppConfig } from '@luggagehero/shared/app-settings/data-access';
import { Config } from '@luggagehero/shared/environment';
import { BookableStorageLocation, Booking, SecuritySeal, SmartImage } from '@luggagehero/shared/interfaces';
import { SharedBookingService } from '@luggagehero/shared/services/bookings';
import { SharedStorageCriteriaService } from '@luggagehero/shared/services/storage-criteria';
import { SharedWindowService } from '@luggagehero/shared/services/window';
import { SharedUiImageInputComponent, SharedUiTagListComponent } from '@luggagehero/shared/ui';
import { TranslatePipe } from '@luggagehero/shared/ui-pipes';

const DEFAULT_LUGGAGE_IMAGE_LOADING_MESSAGE = 'PROCESSING_IMAGE_LONG';
const LUGGAGE_IMAGE_UPLOADING_MESSAGE = 'SAVING_IMAGE';

@Component({
  selector: 'lh-shared-feature-bag-image-input',
  standalone: true,
  imports: [CommonModule, SharedUiImageInputComponent, SharedUiTagListComponent, TranslatePipe],
  templateUrl: './shared-feature-bag-image-input.component.html',
  styleUrl: './shared-feature-bag-image-input.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SharedFeatureBagImageInputComponent {
  @Output() public bookingChange = new EventEmitter<Booking>();
  @Output() public isLoadingChange = new EventEmitter<boolean>();
  @Output() public localLuggageImageChange = new EventEmitter<string>();
  @Output() public remoteLuggageImageChange = new EventEmitter<SmartImage>();
  @Output() public completedChange = new EventEmitter<boolean>();
  @Output() public allowSkipChange = new EventEmitter<boolean>();
  @Output() public photoValidationSucceededChange = new EventEmitter<boolean>();
  @Output() public photoValidationFailedChange = new EventEmitter<boolean>();

  public appConfig = AppConfig;
  public imageLoadingMessage = DEFAULT_LUGGAGE_IMAGE_LOADING_MESSAGE;

  private _storageLocation: BookableStorageLocation;
  private _booking: Booking;
  private _numberOfBags: number;
  private imageUploadAttempts = 0;
  private _completed = false;
  private _allowSkip = false;
  private _photoValidationFailed = false;
  private _photoValidationSucceeded = false;
  private _luggageImageUploadStarted = false;
  private _mediaAccessDeniedCount = 0;
  private _isTakingPhoto = true;
  private _isImageRequired = true;
  private _localLuggageImage: string;
  private _remoteLuggageImage: SmartImage;
  private _isLoading = false;

  private bookingService = inject(SharedBookingService);
  private window = inject(SharedWindowService);
  private criteria = inject(SharedStorageCriteriaService);
  private cd = inject(ChangeDetectorRef);

  public get numberOfBags(): number {
    if (this._numberOfBags) {
      return this._numberOfBags;
    }
    if (this.booking) {
      return this.booking.luggage.normal;
    }
    if (this.securitySeals) {
      return this.securitySeals?.length;
    }
    return null;
  }
  @Input() public set numberOfBags(value: number) {
    this._numberOfBags = value;
    this.cd.markForCheck();
  }

  public get storageLocation(): BookableStorageLocation {
    return this._storageLocation;
  }
  @Input() public set storageLocation(value: BookableStorageLocation) {
    this._storageLocation = value;
    this.cd.markForCheck();
  }

  public get booking(): Booking {
    return this._booking;
  }
  @Input() public set booking(value: Booking) {
    this._booking = value;
    this.cd.markForCheck();

    this.bookingChange.emit(value);
  }

  public get isLoading(): boolean {
    return this._isLoading;
  }
  @Input() public set isLoading(value: boolean) {
    this._isLoading = value;
    this.cd.markForCheck();

    this.isLoadingChange.emit(value);
  }

  public get localLuggageImage(): string {
    return this._localLuggageImage;
  }
  @Input() public set localLuggageImage(value: string) {
    this._localLuggageImage = value;
    this.cd.markForCheck();

    this.localLuggageImageChange.emit(value);
  }

  public get remoteLuggageImage(): SmartImage {
    return this._remoteLuggageImage;
  }
  @Input() public set remoteLuggageImage(value: SmartImage) {
    this._remoteLuggageImage = value;

    if (this._remoteLuggageImage) {
      this.updateState();
    } else {
      this.cd.markForCheck();
    }

    this.remoteLuggageImageChange.emit(value);
  }

  public get isImageRequired(): boolean {
    return this._isImageRequired;
  }
  @Input() public set isImageRequired(value: boolean) {
    this._isImageRequired = value;
    this.cd.markForCheck();
  }

  public get isTakingPhoto(): boolean {
    return this._isTakingPhoto;
  }
  @Input() public set isTakingPhoto(value: boolean) {
    if (value && value !== this._isTakingPhoto) {
      // Changed from not taking photo to taking photo
      this.photoValidationFailed = false;
      this.photoValidationSucceeded = false;
    }
    this._isTakingPhoto = value;
    this.cd.markForCheck();
  }

  public get completed(): boolean {
    return this._completed;
  }
  private set completed(value: boolean) {
    this._completed = value;
    this.cd.markForCheck();

    this.completedChange.emit(value);
  }

  public get allowSkip(): boolean {
    return this._allowSkip;
  }
  private set allowSkip(value: boolean) {
    this._allowSkip = value;
    this.cd.markForCheck();

    this.allowSkipChange.emit(value);
  }

  public get photoValidationSucceeded(): boolean {
    return this._photoValidationSucceeded;
  }
  private set photoValidationSucceeded(value: boolean) {
    this._photoValidationSucceeded = value;
    this.cd.markForCheck();

    this.photoValidationSucceededChange.emit(value);
  }

  public get photoValidationFailed(): boolean {
    return this._photoValidationFailed;
  }
  private set photoValidationFailed(value: boolean) {
    this._photoValidationFailed = value;
    this.cd.markForCheck();

    this.photoValidationFailedChange.emit(value);
  }

  public get isPhotoValidationRequired(): boolean {
    return AppConfig.IS_REQUIRED_TAGS_PHOTO_VALIDATION && this.storageLocation?.validateBagPhoto ? true : false;
  }

  public get isLuggageImageAdded(): boolean {
    if (this.isImageRequired) {
      // For shops which require the luggage image, wait until the upload has completed
      return this.remoteLuggageImage ? true : false;
    }
    //
    // For other shops, allow the user to continue as soon as upload of an image has started. There is a small risk
    // here that if the connection is slow, the user might complete the rest of the check-in process and close the app
    // before the upload completes.
    //
    return this.localLuggageImage || this._luggageImageUploadStarted ? true : false;
  }

  public get securitySeals(): SecuritySeal[] {
    return this.remoteLuggageImage?.securitySeals;
  }

  public get baseUrl(): string {
    return Config.environment.IMAGES_BASE_URL;
  }

  public get isTouch(): boolean {
    return this.window.isTouch;
  }

  public async onLocalImageChange(localImageDataUri: string): Promise<void> {
    if (!localImageDataUri) {
      // this.canGoForward = false;
      return;
    }

    if (localImageDataUri === this.localLuggageImage) {
      return;
    }

    // Save the local image so it can be retrieved by other views
    this.localLuggageImage = localImageDataUri;

    if (!this.storageLocation) {
      return;
    }

    // Mark upload as started
    this._luggageImageUploadStarted = true;
    this.imageLoadingMessage = LUGGAGE_IMAGE_UPLOADING_MESSAGE;
    this.imageUploadAttempts++;

    this.photoValidationFailed = false;
    this.photoValidationSucceeded = false;
    this.remoteLuggageImage = null;

    this.isLoading = true;

    try {
      // Upload the local image to the server
      if (this.booking) {
        this.booking = await this.bookingService.addBagImage(this.booking._id, localImageDataUri);
        this.bookingChange.emit(this.booking);

        this.remoteLuggageImage = (this.booking.luggage.smartImages && this.booking.luggage.smartImages[0]) || null;
      } else {
        this.remoteLuggageImage = await this.bookingService.prepareBagImage(
          localImageDataUri,
          this.storageLocation._id,
        );
      }

      this.updateState();
    } finally {
      this.isLoading = false;
    }
  }

  public onMediaAccessChange(mediaAccess: boolean) {
    if (!mediaAccess) {
      this._mediaAccessDeniedCount++;
    }
    this.allowSkip = !mediaAccess && this._mediaAccessDeniedCount > 1;
  }

  private updateState() {
    if (this.isPhotoValidationRequired && this.securitySeals !== undefined && this.securitySeals.length === 0) {
      // Validation is required and failed
      this.photoValidationFailed = true;
      this.completed = false;
      this.allowSkip = this.imageUploadAttempts > 1;
      return;
    }

    // Validation succeeded or not required
    if (this.securitySeals !== undefined && this.securitySeals.length > 0) {
      this.photoValidationSucceeded = true;

      if (this.criteria.luggage) {
        this.criteria.luggage.normal = this.securitySeals.length;
      }
    }

    this.imageLoadingMessage = DEFAULT_LUGGAGE_IMAGE_LOADING_MESSAGE;
    this.photoValidationFailed = false;

    this.completed = true;
  }
}
