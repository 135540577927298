// angular
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { IFormFieldConfig } from '@luggagehero/shared/interfaces';
// libs
import { BehaviorSubject, Subscription } from 'rxjs';

import { BaseComponent } from '../../../core';

@Component({ template: '' })
export abstract class DynamicFormBaseComponent extends BaseComponent implements OnInit, OnChanges {
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() submit: EventEmitter<unknown> = new EventEmitter<unknown>();
  @Output() valueChanges: EventEmitter<unknown> = new EventEmitter<unknown>();
  @Output() formReady: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private _form: FormGroup;
  private _fields: IFormFieldConfig[];
  private currentControlNames: string[];
  private formChangesSubscription: Subscription;

  constructor(
    private formBuilder: FormBuilder,
    private cd: ChangeDetectorRef,
  ) {
    super();
  }

  get form(): FormGroup {
    return this._form;
  }
  set form(value: FormGroup) {
    this._form = value;

    if (this.formChangesSubscription) {
      this.formChangesSubscription.unsubscribe();
    }
    let previousValid = this._form.valid;
    this.formChangesSubscription = this._form.valueChanges.subscribe((changes) => {
      if (this._form.valid !== previousValid) {
        previousValid = this._form.valid;
        this.setDisabled('submit', !previousValid);
      }
      this.valueChanges.emit(changes);
    });
    this.setDisabled('submit', !previousValid);

    this.cd.markForCheck();
  }

  get fields(): IFormFieldConfig[] {
    return this._fields;
  }
  @Input() set fields(value: IFormFieldConfig[]) {
    if (!value) {
      return;
    }
    this._fields = value;
    this.cd.markForCheck();
  }

  get controlConfigurations(): IFormFieldConfig[] {
    return this.fields ? this.fields.filter(({ type }) => type !== 'button') : [];
  }

  get valid() {
    return this.form.valid;
  }

  get value(): unknown {
    return this.form.value as unknown;
  }

  ngOnInit() {
    this.createForm();
  }

  ngOnChanges(_changes: SimpleChanges) {
    this.updateForm();
  }

  createForm() {
    const form = this.formBuilder.group({});

    this.controlConfigurations.forEach((controlConfig) => {
      this.addControl(controlConfig, form);
    });
    this.form = form;
    this.currentControlNames = this.controlConfigurations.map((c) => c.fullyQualifiedName);

    this.formReady.next(this.controlConfigurations.length > 0);
  }

  updateForm() {
    if (!this.form) {
      return;
    }
    const newControlNames = this.controlConfigurations.map((c) => c.fullyQualifiedName);

    this.currentControlNames
      .filter((controlName) => !newControlNames.includes(controlName))
      .forEach((controlName) => this.removeControl(controlName, this.form));

    newControlNames
      .filter((controlName) => !this.currentControlNames.includes(controlName))
      .forEach((controlName) => {
        const controlConfig = this.fields.find((c) => c.fullyQualifiedName === controlName);
        this.addControl(controlConfig, this.form);
      });

    this.currentControlNames = newControlNames;

    this.formReady.next(this.controlConfigurations.length > 0);
    this.cd.markForCheck();
  }

  onSubmit(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    this.submit.emit(this.value);
  }

  setDisabled(name: string, disable: boolean) {
    const control = this.form.get(name);
    if (control) {
      const method = disable ? 'disable' : 'enable';
      control[method]();
      return;
    }
    if (!this.fields) {
      return;
    }
    this.fields = this.fields.map((formField) => {
      if (formField.fullyQualifiedName === name) {
        // Creating a new object to trigger update of bindings
        const newControlConfig = Object.assign({}, formField);
        newControlConfig.disabled = disable;
        return newControlConfig;
      }
      return formField;
    });
  }

  setValue(name: string, value: unknown) {
    const control = this.form.get(name);
    if (!control) {
      return;
    }
    control.setValue(value, { emitEvent: true });
    this.cd.markForCheck();
  }

  markAllAsTouched() {
    this.controlConfigurations.forEach((c) => {
      const control = this.form.get(c.fullyQualifiedName);
      if (control) {
        control.markAsTouched();
        control.updateValueAndValidity();
      }
    });
  }

  private addControl(controlConfig: IFormFieldConfig, form: FormGroup) {
    const nameParts = controlConfig.fullyQualifiedName.split('.');

    let currentGroup = form;
    for (let i = 0; i < nameParts.length - 1; i++) {
      const groupName = nameParts[i];
      let subGroup = currentGroup.get(groupName) as FormGroup;
      if (!subGroup) {
        subGroup = this.formBuilder.group({});
        currentGroup.addControl(groupName, subGroup);
      }
      currentGroup = subGroup;
    }
    const propertyName = nameParts[nameParts.length - 1];
    const { disabled, validation, value } = controlConfig;
    const control = this.formBuilder.control({ disabled, value }, validation);
    currentGroup.addControl(propertyName, control);
  }

  private removeControl(name: string, form: FormGroup) {
    const nameParts = name.split('.');

    let parentGroup = form;
    for (let i = 0; i < nameParts.length - 1; i++) {
      parentGroup = parentGroup.get(nameParts[i]) as FormGroup;
    }
    const propertyName = nameParts[nameParts.length - 1];
    parentGroup.removeControl(propertyName);

    // TODO: Remove form group if empty
  }
}
