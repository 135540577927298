import { Injectable } from '@angular/core';
import { Config } from '@luggagehero/shared/environment';
import { Swap } from '@luggagehero/shared/interfaces';
import { SharedHttpService } from '@luggagehero/shared/services/http';
import { SharedUserService } from '@luggagehero/shared/services/users';

@Injectable()
export class SwapService {
  private _current: Swap | undefined = null;

  constructor(
    private http: SharedHttpService,
    private userService: SharedUserService,
  ) {}

  public get current(): Swap {
    return this._current;
  }
  public set current(value: Swap) {
    this._current = value;
  }

  async createSwap(itemName: string): Promise<Swap> {
    await this.userService.getUserInfo();
    const data = { itemName };
    const res = await this.http.post<Swap>(`${Config.environment.TRAVELER_API}/swaps`, data);

    this.current = res;

    return res;
  }

  async getSwap(id: string): Promise<Swap> {
    const res = await this.http.get<Swap>(`${Config.environment.TRAVELER_API}/swaps/${id}`);
    this.current = res;
    return res;
  }

  async getSwapByReferenceNumber(referenceNumber: string): Promise<Swap> {
    const res = await this.http.get<Swap>(
      `${Config.environment.TRAVELER_API}/swaps?referenceNumber=${referenceNumber}`,
      false,
    );
    this.current = res;
    return res;
  }

  async dropOffSwap(swapId: string): Promise<Swap> {
    const res = await this.http.put<Swap>(`${Config.environment.TRAVELER_API}/swaps/${swapId}/check_in`, {});

    return res;
  }

  async pickUpSwap(swapId: string): Promise<Swap> {
    const res = await this.http.put<Swap>(`${Config.environment.TRAVELER_API}/swaps/${swapId}/check_out`, {});

    return res;
  }

  async assignStorageLocation(swapId: string, storageLocationId: string): Promise<Swap> {
    const res = await this.http.put<Swap>(
      `${Config.environment.TRAVELER_API}/swaps/${swapId}/storage_locations/${storageLocationId}`,
      {},
    );

    return res;
  }

  async getSwapsByCurrentUser(): Promise<Swap[]> {
    return this.http.get<Swap[]>(`${Config.environment.TRAVELER_API}/swaps`, true);
  }

  async getShareableLink(_id: string): Promise<string> {
    // TODO: Fetch link from API
    return Promise.resolve(`https://app.swaphero.io/swaps/${_id}`);
  }
}
