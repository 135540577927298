import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { PaymentCardInputBaseComponent } from '@luggagehero/features/ui';
import { SharedPaymentService } from '@luggagehero/shared/services/payments';
import { SharedPricingService } from '@luggagehero/shared/services/pricing';
import { SharedStorageService } from '@luggagehero/shared/services/storage';
import { SharedStripeService } from '@luggagehero/shared/services/stripe';
import { SharedUserService } from '@luggagehero/shared/services/users';
import { SharedWindowService } from '@luggagehero/shared/services/window';

@Component({
  selector: 'lh-payment-card-input',
  templateUrl: './payment-card-input.component.html',
  styleUrls: ['./payment-card-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaymentCardInputComponent extends PaymentCardInputBaseComponent {
  constructor(
    userService: SharedUserService,
    paymentService: SharedPaymentService,
    stripe: SharedStripeService,
    storage: SharedStorageService,
    price: SharedPricingService,
    cd: ChangeDetectorRef,
    windowService: SharedWindowService,
  ) {
    super(userService, paymentService, stripe, storage, price, cd, windowService);
  }
}
