<div class="distance-display">
  <svg width="11" height="18" viewBox="0 0 11 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.06071 17.6425L3.27738 6.48001L1.85238 7.03417V9.72584H0.269043V6.00501L4.26696 4.30292C4.45168 4.22376 4.6463 4.17758 4.85081 4.16438C5.05533 4.15119 5.24995 4.17758 5.43467 4.24355C5.61939 4.30952 5.79422 4.40188 5.95915 4.52063C6.12408 4.63938 6.25932 4.79112 6.36488 4.97584L7.15654 6.24251C7.4996 6.79667 7.9647 7.25188 8.55186 7.60813C9.13901 7.96438 9.80863 8.14251 10.5607 8.14251V9.72584C9.6371 9.72584 8.81245 9.53452 8.08675 9.15188C7.36106 8.76924 6.74092 8.28105 6.22633 7.6873L5.73154 10.1217L7.39404 11.705V17.6425H5.81071V12.4967L4.14821 11.23L2.72321 17.6425H1.06071ZM6.20654 3.78834C5.77113 3.78834 5.39838 3.63331 5.08831 3.32324C4.77824 3.01317 4.62321 2.64042 4.62321 2.20501C4.62321 1.76959 4.77824 1.39685 5.08831 1.08678C5.39838 0.776708 5.77113 0.621674 6.20654 0.621674C6.64196 0.621674 7.0147 0.776708 7.32477 1.08678C7.63484 1.39685 7.78988 1.76959 7.78988 2.20501C7.78988 2.64042 7.63484 3.01317 7.32477 3.32324C7.0147 3.63331 6.64196 3.78834 6.20654 3.78834Z"
      fill="currentColor"
    />
  </svg>

  <span>{{ distance }}</span>
  <span class="text-lighter">{{ 'DISTANCE_AWAY' | translate }}</span>
</div>
