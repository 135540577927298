import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { SharedStorageService } from '@luggagehero/shared/services/storage';
import { SharedUserService } from '@luggagehero/shared/services/users';
import { SharedWindowService } from '@luggagehero/shared/services/window';

import { BaseModalComponent } from '../../../core';
@Component({ template: '' })
export abstract class UserModalBaseComponent extends BaseModalComponent {
  private _isStorageManager = false;
  private _isExistingUser = true;
  private _isUserEmailSelected: boolean;
  private _isUserPhoneSelected: boolean;
  private _userEmail: string;
  private _navigateHomeOnHide = false;

  constructor(
    protected userService: SharedUserService,
    protected storageService: SharedStorageService,
    protected windowService: SharedWindowService,
    protected cd: ChangeDetectorRef,
  ) {
    super();
  }

  get isSwapHero(): boolean {
    return this.windowService.appName.match(/swap/i) ? true : false;
  }

  get modalTitle(): string {
    if (this.isSwapHero) {
      return this._translate('START_SWAPPING');
    }
    if (this.isStorageManager) {
      return this._translate('WELCOME');
    }
    if (this.bookingRequested) {
      return this._translate('YOUR_CONTACT_DETAILS');
    }
    if (this.isUserEmailSelected || this.isUserPhoneSelected) {
      return this._translate(this.isExistingUser ? 'WELCOME_BACK' : 'FIRST_TIME_USING_LUGGAGEHERO');
    }
    return this._translate('SIGN_IN');
  }

  get bookingRequested(): boolean {
    return this.userService.bookingRequested;
  }

  get ios(): boolean {
    return this.windowService.iOS;
  }

  get isStorageManager(): boolean {
    return this._isStorageManager;
  }
  @Input() set isStorageManager(value: boolean) {
    this._isStorageManager = value;
    this.cd.markForCheck();
  }

  get isUserEmailSelected(): boolean {
    return this._isUserEmailSelected;
  }
  set isUserEmailSelected(value: boolean) {
    this._isUserEmailSelected = value;
    this.cd.markForCheck();
  }

  get isUserPhoneSelected(): boolean {
    return this._isUserPhoneSelected;
  }
  set isUserPhoneSelected(value: boolean) {
    this._isUserPhoneSelected = value;
    this.cd.markForCheck();
  }

  get isExistingUser(): boolean {
    return this._isExistingUser;
  }
  set isExistingUser(value: boolean) {
    this._isExistingUser = value;
    this.cd.markForCheck();
  }

  get userEmail(): string {
    return this._userEmail;
  }
  set userEmail(value: string) {
    this._userEmail = value;
    this.cd.markForCheck();
  }

  get navigateHomeOnHide(): boolean {
    return this._navigateHomeOnHide;
  }
  set navigateHomeOnHide(value: boolean) {
    this._navigateHomeOnHide = value;
    this.cd.markForCheck();
  }
}
