<div class="container-fluid lh-container-fluid" itemscope itemtype="http://schema.org/LocalBusiness">
  <div class="flex" *ngIf="shop">
    <div class="fl-1">
      <h2 class="mt-4 mb-2">{{ title }}</h2>
      <lh-storage-location-availability
        [storageLocation]="shop"
        [showFullHours]="false"
      ></lh-storage-location-availability>
      <lh-storage-location-holiday-notice [storageLocation]="shop"></lh-storage-location-holiday-notice>
    </div>

    <div class="fl-2" *ngIf="!isModal">
      <div *ngIf="shop">
        <div *ngIf="criteria" class="shop-criteria-small d-none d-md-flex">
          <lh-shop-criteria
            [shop]="shop"
            [showPrice]="false"
            [(ngModel)]="criteria"
            [estimatedPrice]="estimatedPrice"
            [pricing]="pricing"
            [showLocation]="false"
            [inPage]="true"
          ></lh-shop-criteria>

          <button
            id="makeBooking"
            type="button"
            class="btn btn-md btn-warning btn-book"
            [disabled]="!isAvailableForBooking"
            (click)="makeBooking()"
          >
            <span *ngIf="!isLoading">{{ 'BOOK' | translate }}</span>
            <span *ngIf="isLoading">
              <lh-shared-ui-spinner [visible]="true"></lh-shared-ui-spinner>
            </span>
          </button>

          <div (click)="collapseCriteria()" [class.active]="isCriteriaExpanded" class="criteria-backdrop"></div>
        </div>

        <div class="book-button-container d-block d-md-none">
          <button
            id="makeBooking"
            type="button"
            class="btn btn-block btn-warning"
            [disabled]="!isAvailableForBooking"
            (click)="makeBooking()"
          >
            <span *ngIf="!isLoading">{{ 'BOOK' | translate }}</span>
            <span *ngIf="isLoading">
              <lh-shared-ui-spinner [visible]="true"></lh-shared-ui-spinner>
            </span>
          </button>
        </div>

        <hr />

        <div class="message">
          <!-- Shop is unavailable -->
          <div *ngIf="!shop.available" class="text-end text-danger">
            <small>{{ 'SHOP_UNAVAILABLE_MESSAGE' | translate }}</small>
          </div>

          <!-- Shop is inactive -->
          <div *ngIf="shop.available && !shop.active" class="text-end text-danger">
            <small>
              {{ 'SHOP_INACTIVE_MESSAGE' | translate }}
              <a id="chooseAnotherShop" class="href text-decoration-none" (click)="chooseAnotherShop()">{{
                'CHOOSE_ANOTHER_SHOP' | translate
              }}</a>
            </small>
          </div>

          <!-- Shop is active -->
          <div *ngIf="shop.available && shop.active" class="text-end">
            <div class="small" *ngIf="source === 'app'">{{ 'BOOK_AHEAD_FOR_BEST_RATES' | translate }}</div>
            <div class="small" *ngIf="source !== 'app'">{{ 'FLEXIBLE_BOOKING' | translate }}</div>
          </div>
        </div>
      </div>
    </div>

    <div class="fl-3">
      <!-- Shop badges -->
      <lh-storage-location-badge-list [storageLocation]="shop"></lh-storage-location-badge-list>

      @if (isShopDetailsUSPExperimentEnabled) {
        <hr />

        <div class="usp-list">
          <div class="usp-item">
            <div class="icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M6.633 10.25c.806 0 1.533-.446 2.031-1.08a9.041 9.041 0 0 1 2.861-2.4c.723-.384 1.35-.956 1.653-1.715a4.498 4.498 0 0 0 .322-1.672V2.75a.75.75 0 0 1 .75-.75 2.25 2.25 0 0 1 2.25 2.25c0 1.152-.26 2.243-.723 3.218-.266.558.107 1.282.725 1.282m0 0h3.126c1.026 0 1.945.694 2.054 1.715.045.422.068.85.068 1.285a11.95 11.95 0 0 1-2.649 7.521c-.388.482-.987.729-1.605.729H13.48c-.483 0-.964-.078-1.423-.23l-3.114-1.04a4.501 4.501 0 0 0-1.423-.23H5.904m10.598-9.75H14.25M5.904 18.5c.083.205.173.405.27.602.197.4-.078.898-.523.898h-.908c-.889 0-1.713-.518-1.972-1.368a12 12 0 0 1-.521-3.507c0-1.553.295-3.036.831-4.398C3.387 9.953 4.167 9.5 5 9.5h1.053c.472 0 .745.556.5.96a8.958 8.958 0 0 0-1.302 4.665c0 1.194.232 2.333.654 3.375Z"
                />
              </svg>
            </div>
            <div class="body">
              <div class="title">{{ 'ONLINE_BOOKING_REQUIRED_USP_TITLE' | lhTranslate }}</div>
              <div class="description">{{ 'ONLINE_BOOKING_REQUIRED_USP_DESCRIPTION' | lhTranslate }}</div>
            </div>
          </div>
          <div class="usp-item">
            <div class="icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
              >
                <path stroke-linecap="round" stroke-linejoin="round" d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z"
                />
              </svg>
            </div>
            <div class="body">
              <div class="title">{{ 'ADDRESS_AVAILABLE_AFTER_BOOKING_USP_TITLE' | lhTranslate }}</div>
            </div>
          </div>
          <div class="usp-item">
            <div class="icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M9 12.75 11.25 15 15 9.75m-3-7.036A11.959 11.959 0 0 1 3.598 6 11.99 11.99 0 0 0 3 9.749c0 5.592 3.824 10.29 9 11.623 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.571-.598-3.751h-.152c-3.196 0-6.1-1.248-8.25-3.285Z"
                />
              </svg>
            </div>
            <div class="body">
              <div class="title">{{ 'PROTECTION_INSURANCE_USP_TITLE' | lhTranslate }}</div>
            </div>
          </div>
        </div>

        <hr />
      }

      <!-- Shop Rating -->
      <lh-traveler-shops-ui-aggregate-rating
        [showShopRating]="showShopRating"
        [averageRating]="averageRating"
        [numberOfRatings]="numberOfRatings"
        [shopStatsNumberOfRatings]="shop.stats.numberOfRatings"
        [numberOf5StarRatings]="numberOf5StarRatings"
        [hasShopStats]="!!shop.stats"
        [globalNumberOfRatings]="globalStats?.numberOfRatings"
        [shopLoaded]="shopLoaded"
        (showReviews)="showReviews()"
      ></lh-traveler-shops-ui-aggregate-rating>
      <hr />

      <!--Pricing and safety info-->
      <div *ngIf="pricing" class="d-flex flex-column gap-1">
        <h5>{{ 'PRICING' | translate }}</h5>
        <lh-traveler-shops-ui-pricing-details
          [pricing]="pricing"
          (priceInfoClicked)="showPriceInfo()"
          (discountOptionsClicked)="showDiscountOptions()"
        />
      </div>
      <hr />
      <!-- Nearby points of interest -->
      <div class="d-flex flex-column gap-1">
        <h5>{{ 'TAB_BAR_NEARBY' | translate }}</h5>
        <lh-traveler-shops-ui-poi-list-details [poiListHtml]="poiListHtml | async" />
      </div>

      <hr />

      <!--Shop opening hours-->
      <div *ngIf="criteria" class="d-flex flex-column gap-1">
        <h5>{{ 'OPENING_HOURS' | translate }}</h5>
        <lh-traveler-shops-feature-opening-hours [shop]="shop"></lh-traveler-shops-feature-opening-hours>
      </div>

      <hr />

      <!-- Internet -->
      <!-- <h5 class="heading-5 first-letter-uppercase">{{ 'CHECK_IN_CONNECTION' | translate }}</h5>

      <div *ngIf="shop.hasWifi">
        {{ 'THIS_SHOP_HAS_WIFI' | translate }}
        <a id="showWifiInfo" class="href" (click)="showWifiInfo()"> {{ 'MORE_INFO' | translate }}</a>
      </div>

      <div *ngIf="!shop.hasWifi">
        {{ 'NO_WIFI_AT_THIS_SHOP_EXPLANATION' | translate }}
      </div>

<hr /> -->

      <div class="d-flex flex-column gap-1">
        <h5>{{ 'ABOUT_THE_STORAGE_LOCATION' | translate }}</h5>
        <div class="row icon-container">
          <div
            class="col-5 mb-3"
            [title]="(shop.hasWifi ? 'THIS_SHOP_HAS_WIFI' : 'NO_WIFI_AT_THIS_SHOP_TOOLTIP') | translate"
          >
            <svg class="icon shop-icon" [class.disabled]="!shop.hasWifi"><use xlink:href="#icon-wifi"></use></svg>
            <small>{{ (shop.hasWifi ? 'FREE_WIFI' : 'NO_WIFI_AT_THIS_SHOP') | translate }}</small>
          </div>
          <div class="col-7 mb-3" [title]="'EACH_BAG_IS_INSURED' | translate">
            <svg class="icon shop-icon"><use xlink:href="#icon-shield"></use></svg>
            <small>
              {{ 'EACH_BAG_IS_INSURED' | translate }}
              {{ pricing.insuranceCoverage | price: pricing.currency : 0 : false }}
            </small>
          </div>
          <div class="col-5 mb-3" [title]="'FLEXIBLE_BOOKING' | translate">
            <svg class="icon shop-icon"><use xlink:href="#icon-thumbs-up"></use></svg>
            <small>{{ 'FLEXIBLE_BOOKING' | translate }}</small>
          </div>
          <div class="col-7 mb-3" [title]="'PAY_BY_THE_HOUR' | translate">
            <svg class="icon shop-icon" [class.disabled]="!isMultidayStorageAllowed(shop)">
              <use xlink:href="#icon-clock-regular"></use>
            </svg>
            <small>
              {{ (isMultidayStorageAllowed(shop) ? 'OVERNIGHT_STORAGE' : 'NO_OVERNIGHT_STORAGE') | translate }}
            </small>
          </div>
        </div>
      </div>

      <hr />

      <!-- Description -->
      <div *ngIf="shop.description && shop.description !== ''" class="d-flex flex-column gap-1">
        <h5>{{ 'HOW_IT_WORKS' | translate }}</h5>

        <p class="mb-0">
          {{ 'HOW_IT_WORKS_EXPLANATION' | translate }}
          <a id="showHowItWorksFromShopDetails" class="href" (click)="showHowItWorks()">
            {{ 'MORE_INFO' | translate }}
          </a>
        </p>

        <hr />
      </div>

      <div class="d-flex flex-column gap-1">
        <h5>{{ 'NOT_RELEVANT_QUESTION' | translate }}</h5>
        <p>{{ 'OTHER_STORAGE_OPTIONS_EXPLANATION' | translate }}</p>
        <button id="seeMoreShops" class="btn btn-primary mb-1 align-self-start" (click)="viewFullMap()">
          {{ 'SEE_MORE_SHOPS' | translate }}
        </button>
      </div>

      <hr />

      <!-- Reviews -->
      <div #reviewsElement class="d-flex flex-column gap-1">
        <h5>{{ 'REVIEWS' | translate }}</h5>
        <lh-traveler-shops-feature-shop-reviews #shopReviews [shop]="shop"></lh-traveler-shops-feature-shop-reviews>
      </div>
    </div>

    <!--Shop map-->
    <div class="fl-4">
      <div class="map-wrapper">
        <button id="makeBookingFromShopDetailsMapBanner" class="btn btn-info map-banner" (click)="makeBooking()">
          <span *ngIf="source === 'app'">{{ 'ADDRESS_VISIBLE_AFTER_BOOKING' | translate }}</span>
          <span *ngIf="source !== 'app'">{{ 'BOOK_AHEAD_FOR_BEST_RATES' | translate }}</span>
        </button>
        <div class="map-cta">
          <div class="dropdown" dropdown *ngIf="isSharingAvailable || isClipboardAvailable">
            <!--Dropdown toggle button-->
            <button
              id="shareStorageLocationFromShopDetailsMap"
              class="btn btn-md btn-primary"
              type="button"
              dropdownToggle
            >
              <i>
                <svg
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="#fff"
                  width="24"
                  height="24"
                  viewBox="0 0 28 28"
                >
                  <title>ios_share</title>
                  <path
                    d="M15.984 5.016l-1.406 1.406-1.594-1.594v11.156h-1.969v-11.156l-1.594 1.594-1.406-1.406 3.984-4.031zM20.016 9.984v11.016q0 0.844-0.586 1.43t-1.43 0.586h-12q-0.844 0-1.43-0.586t-0.586-1.43v-11.016q0-0.797 0.586-1.383t1.43-0.586h3v1.969h-3v11.016h12v-11.016h-3v-1.969h3q0.844 0 1.43 0.586t0.586 1.383z"
                  ></path>
                </svg>
              </i>
              {{ 'SHARE_STORAGE_LOCATION' | translate }}
            </button>
            <!--Dropdown list-->
            <ul class="dropdown-menu dropdown-menu-right" *dropdownMenu role="menu" aria-labelledby="share-actions">
              <!-- Copy link item -->
              <li *ngIf="isClipboardAvailable">
                <a id="copyLinkToStorageLocation" class="href" (click)="shareStorageLocationCopyToClipboard()">
                  <i>
                    <svg
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="#384347"
                      width="20"
                      height="20"
                      viewBox="0 0 28 28"
                    >
                      <title>link</title>
                      <path
                        d="M17.016 6.984q2.063 0 3.516 1.477t1.453 3.539-1.453 3.539-3.516 1.477h-4.031v-1.922h4.031q1.266 0 2.18-0.914t0.914-2.18-0.914-2.18-2.18-0.914h-4.031v-1.922h4.031zM8.016 12.984v-1.969h7.969v1.969h-7.969zM3.891 12q0 1.266 0.914 2.18t2.18 0.914h4.031v1.922h-4.031q-2.063 0-3.516-1.477t-1.453-3.539 1.453-3.539 3.516-1.477h4.031v1.922h-4.031q-1.266 0-2.18 0.914t-0.914 2.18z"
                      ></path>
                    </svg>
                  </i>
                  {{ 'COPY_LINK' | translate }}
                </a>
              </li>

              <!-- Share via... item -->
              <li *ngIf="isSharingAvailable">
                <a id="shareStorageLocationVia" class="href" (click)="shareStorageLocationVia()">
                  <i>
                    <svg
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="#384347"
                      width="20"
                      height="20"
                      viewBox="0 0 28 30"
                    >
                      <title>ios_share</title>
                      <path
                        d="M15.984 5.016l-1.406 1.406-1.594-1.594v11.156h-1.969v-11.156l-1.594 1.594-1.406-1.406 3.984-4.031zM20.016 9.984v11.016q0 0.844-0.586 1.43t-1.43 0.586h-12q-0.844 0-1.43-0.586t-0.586-1.43v-11.016q0-0.797 0.586-1.383t1.43-0.586h3v1.969h-3v11.016h12v-11.016h-3v-1.969h3q0.844 0 1.43 0.586t0.586 1.383z"
                      ></path>
                    </svg>
                  </i>
                  {{ 'SHARE_VIA' | translate }}...
                </a>
              </li>
            </ul>
          </div>
        </div>

        <lh-storage-location-map
          *ngIf="criteria?.location"
          [center]="mapCenter"
          [myLocation]="myLocation"
          [zoom]="defaultZoom"
          [maxZoom]="maxZoom"
          [clickableIcons]="mapOptions.clickableIcons"
          [keyboardShortcuts]="mapOptions.keyboardShortcuts"
          [fullscreenControl]="false"
          [scaleControl]="mapOptions.scaleControl"
          [streetViewControl]="mapOptions.streetViewControl"
          [zoomControl]="false"
          [disableDoubleClickZoom]="false"
          [scrollwheel]="true"
          [draggable]="true"
          [styles]="mapStyles"
          [storageLocationObfuscation]="source === 'app'"
          [largeStorageLocationCircles]="true"
          [largeStorageLocationMarkers]="true"
          [enableStorageLocationMarkerNotice]="false"
          [storageLocations]="[shop]"
        ></lh-storage-location-map>
      </div>
    </div>
  </div>
</div>

<!--Safety modal-->
<div
  class="modal fade"
  bsModal
  #safetyModal="bs-modal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="mySmallModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-lg modal-fullscreen-md">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left">{{ 'YOUR_LUGGAGE_IS_SAFE' | translate }}</h4>
        <button
          id="closeSafetyModal"
          type="button"
          class="close pull-right"
          aria-label="Close"
          (click)="safetyModal.hide()"
        >
          <svg viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
            />
            <path d="M0 0h24v24H0z" fill="none" />
          </svg>
        </button>
      </div>
      <div class="modal-body">
        <table class="table">
          <tr>
            <td class="checkmark">✔</td>
            <td>{{ 'SAFETY_BULLETS_1' | translate }}</td>
          </tr>
          <tr>
            <td class="checkmark">✔</td>
            <td>{{ 'SAFETY_BULLETS_2' | translate }}</td>
          </tr>
          <tr *ngIf="shopLoaded && !shop.noSecuritySeals">
            <td class="checkmark">✔</td>
            <td>{{ 'SAFETY_BULLETS_3' | translate }}</td>
          </tr>
          <!-- <tr>
            <td class="checkmark">✔</td>
            <td>{{ 'SAFETY_BULLETS_4' | translate }}</td>
          </tr> -->
        </table>
      </div>
    </div>
  </div>
</div>

<!--Wifi modal-->
<div
  class="modal fade"
  bsModal
  #wifiModal="bs-modal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="mySmallModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-lg modal-fullscreen-md">
    <div class="modal-content">
      <div class="modal-header">
        <!-- <h4 class="modal-title pull-left">{{ shop.name }} {{ 'FREE_WIFI' | translate }}</h4> -->
        <h4 class="modal-title pull-left">{{ 'FREE_WIFI' | translate }}</h4>
        <button
          id="closeWifiModal"
          type="button"
          class="close pull-right"
          aria-label="Close"
          (click)="wifiModal.hide()"
        >
          <svg viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
            />
            <path d="M0 0h24v24H0z" fill="none" />
          </svg>
        </button>
      </div>
      <div class="modal-body">
        <div *ngIf="!isWifiInfoAvailable">
          {{ 'ASK_STAFF_FOR_WIFI' | translate }}
        </div>

        <div *ngIf="isWifiInfoAvailable">
          <table class="table">
            <tr *ngIf="shop.wifiInfo.ssid && shop.wifiInfo.ssid !== ''">
              <td>{{ 'WIFI_SSID' | translate }}</td>
              <td>{{ shop.wifiInfo.ssid }}</td>
            </tr>
            <tr *ngIf="shop.wifiInfo.username && shop.wifiInfo.username !== ''">
              <td>{{ 'WIFI_USERNAME' | translate }}</td>
              <td>{{ shop.wifiInfo.username }}</td>
            </tr>
            <tr *ngIf="shop.wifiInfo.password && shop.wifiInfo.password !== ''">
              <td>{{ 'WIFI_PASSWORD' | translate }}</td>
              <td>{{ shop.wifiInfo.password }}</td>
            </tr>
          </table>

          <small>{{ 'WIFI_SCREENSHOT_HINT' | translate }}</small>
        </div>
      </div>
    </div>
  </div>
</div>
