import { Component, EventEmitter, inject, Input, Output, Type } from '@angular/core';

import { BaseComponent, ModalStyle } from '../../../../core';
import { ModalService } from '../../../../core/services';
import { ViewImageBaseComponent } from '../view-image.base-component';

export interface ListGroup<T> {
  items: ListItem<T>[];
  name: string;
  icon: 'calendar' | 'user';
}

export interface ListItem<T> {
  image?: string;
  imagePlaceholder?: string;
  title: string;
  content: {
    strong?: string;
    normal: string;
  };
  labels: ListItemLabel[];
  icons: ListItemIcon[];
  disabled?: boolean;
  value: T;
}

export type ListItemIconType = 'bag' | 'zoom-in';

export class ListItemIcon {
  constructor(
    public icon: ListItemIconType,
    public text?: string,
  ) {}

  public get class(): string {
    return `icon-${this.icon}`;
  }
}

export class ListItemLabel {
  constructor(
    public text: string,
    public tooltip: string,
    public style: UiStyle,
    public isAlert = false,
  ) {}

  public get class(): string {
    const cssClasses = ['list-item-label'];

    if (this.isAlert) {
      cssClasses.push('alert');

      if (this.style) {
        cssClasses.push(`alert-${this.style}`);
      }
    } else if (this.style) {
      cssClasses.push(this.style);
    }

    return cssClasses.join(' ');
  }
}

export type UiStyle = 'success' | 'warning' | 'danger' | 'primary' | 'info' | 'yellow';

@Component({ template: '' })
export abstract class GroupedListBaseComponent<T> extends BaseComponent {
  @Output() public itemSelected = new EventEmitter<ListItem<T>>();
  @Input() public data: ListGroup<T>;

  private modalService = inject(ModalService);

  protected abstract get viewImageComponent(): Type<ViewImageBaseComponent>;

  public selectItem(item: ListItem<T>) {
    this.itemSelected.emit(item);
  }

  public showImage(event: Event, item: ListItem<T>) {
    event.stopPropagation();

    if (!item.image) {
      return;
    }

    void this.modalService.show({
      component: this.viewImageComponent,
      componentState: {
        image: item.image,
        imageSize: 'large',
      },
      style: ModalStyle.Alert,
      autoWidth: 'md',
    });
  }
}
