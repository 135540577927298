import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { BookableStorageLocation, PricingModel } from '@luggagehero/shared/interfaces';
import { SharedPricingService } from '@luggagehero/shared/services/pricing';
import { Observable } from 'rxjs';

import { BaseComponent } from '../../../core';

@Component({ template: '' })
export abstract class PricingModelSelectorBaseComponent extends BaseComponent {
  @Input() public simpleMode = false;
  @Input() public storageLocation: BookableStorageLocation;

  private _hourlyRate: number;
  private _dailyRate: number;
  private _currency: string;

  constructor(
    private priceService: SharedPricingService,
    private cd: ChangeDetectorRef,
  ) {
    super();
  }

  public get pricingModel(): Observable<PricingModel> {
    return this.priceService.pricingModel$;
  }

  public get currency(): string {
    return this._currency || this.storageLocation?.pricing?.currency || 'usd';
  }
  @Input() set currency(value: string) {
    this._currency = value;
    this.cd.markForCheck();
  }

  public get hourlyRate(): number {
    return this._hourlyRate || this.storageLocation?.pricing?.hourlyRate;
  }
  @Input() set hourlyRate(value: number) {
    this._hourlyRate = value;
    this.cd.markForCheck();
  }

  public get dailyRate(): number {
    return this._dailyRate || this.storageLocation?.pricing?.dailyRate;
  }
  @Input() set dailyRate(value: number) {
    this._dailyRate = value;
    this.cd.markForCheck();
  }

  public setPricingModel(value: PricingModel) {
    this.priceService.changePricing(value);
  }
}
