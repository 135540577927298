@if (shop.recommendedTag) {
  <lh-traveler-shops-ui-best-choice-display class="best-choice" [text]="shop.recommendedTag" />
}

<div class="traveler-shops-feature-storage-location-details-sheet">
  <lh-traveler-shops-ui-shop-card
    #shopCard
    [isHighlighted]="false"
    [title]="shop.title"
    [shopRating]="shop.shopRating"
    [distance]="shop.distance"
    [pricingDaily]="shop.pricingDaily"
    [pricingHourly]="shop.pricingHourly"
    [holidayNoticeHtml]="shop.holidayNoticeHtml"
    [availability]="shop.availability"
    [insideModal]="true"
    [bookButtonStyle]="'solid'"
    [showBookButton]="!bookNowButtonVisible"
    [badges]="shop.badges"
    (bookNow)="onBookNow?.()"
    (cardClick)="onCardClicked?.()"
  />

  <div class="shop-details">
    <lh-traveler-shops-ui-aggregate-rating
      [showShopRating]="showShopRating"
      [averageRating]="averageRating"
      [numberOfRatings]="numberOfRatings"
      [shopStatsNumberOfRatings]="shop.shopItem.stats?.numberOfRatings"
      [numberOf5StarRatings]="numberOf5StarRatings"
      [hasShopStats]="!!shop.shopItem.stats"
      [globalNumberOfRatings]="globalStats?.numberOfRatings"
      [shopLoaded]="true"
      (showReviews)="showReviews()"
    ></lh-traveler-shops-ui-aggregate-rating>

    <hr />

    <div *ngIf="shop.shopItem.pricing" class="d-flex flex-column gap-1">
      <h5>{{ 'PRICING' | translate }}</h5>
      <lh-traveler-shops-ui-pricing-details
        [pricing]="shop.shopItem.pricing"
        [showDiscountOptions]="false"
        [showPriceInfoOptions]="false"
      />

      <lh-shared-ui-tooltip
        [callToAction]="'MORE_INFO' | translate"
        [body]="priceInfoTooltipHtml"
      ></lh-shared-ui-tooltip>
    </div>

    <hr />

    <div class="d-flex flex-column gap-1">
      <h5>{{ 'TAB_BAR_NEARBY' | translate }}</h5>
      <lh-traveler-shops-ui-poi-list-details [poiListHtml]="(poiListHtml | async) || []" />
    </div>

    <hr />
    <div *ngIf="criteria" class="d-flex flex-column gap-1">
      <h5>{{ 'OPENING_HOURS' | translate }}</h5>
      <lh-traveler-shops-feature-opening-hours [shop]="shop.shopItem" />
    </div>

    <hr />

    <div class="d-flex flex-column gap-1">
      <h5>{{ 'ABOUT_THE_STORAGE_LOCATION' | translate }}</h5>
      <div class="row icon-container">
        <div
          class="col-5 mb-3"
          [title]="(shop.shopItem.hasWifi ? 'THIS_SHOP_HAS_WIFI' : 'NO_WIFI_AT_THIS_SHOP_TOOLTIP') | translate"
        >
          <svg class="icon shop-icon" [class.disabled]="!shop.shopItem.hasWifi">
            <use xlink:href="#icon-wifi"></use>
          </svg>
          <small>{{ (shop.shopItem.hasWifi ? 'FREE_WIFI' : 'NO_WIFI_AT_THIS_SHOP') | translate }}</small>
        </div>
        <div
          class="col-7 mb-3"
          [title]="'EACH_BAG_IS_INSURED' | translate"
          *ngIf="shop.shopItem.pricing && shop.shopItem.pricing.insuranceCoverage"
        >
          <svg class="icon shop-icon"><use xlink:href="#icon-shield"></use></svg>
          <small>
            {{ 'EACH_BAG_IS_INSURED' | translate }}
            {{ shop.shopItem.pricing.insuranceCoverage | price: shop.shopItem.pricing.currency : 0 : false }}
          </small>
        </div>
        <div class="col-5 mb-3" [title]="'FLEXIBLE_BOOKING' | translate">
          <svg class="icon shop-icon"><use xlink:href="#icon-thumbs-up"></use></svg>
          <small>{{ 'FLEXIBLE_BOOKING' | translate }}</small>
        </div>
        <div class="col-7 mb-3" [title]="'PAY_BY_THE_HOUR' | translate">
          <svg class="icon shop-icon" [class.disabled]="!isMultidayStorageAllowed()">
            <use xlink:href="#icon-clock-regular"></use>
          </svg>
          <small>
            {{ (isMultidayStorageAllowed() ? 'OVERNIGHT_STORAGE' : 'NO_OVERNIGHT_STORAGE') | translate }}
          </small>
        </div>
      </div>
    </div>
    <hr />

    <div #reviewsElement class="d-flex flex-column gap-1">
      <h5>{{ 'REVIEWS' | translate }}</h5>
      <lh-traveler-shops-feature-shop-reviews
        #shopReviews
        [shop]="shop.shopItem"
      ></lh-traveler-shops-feature-shop-reviews>
    </div>
  </div>

  <div class="footer show">
    <div class="footer__content">
      <lh-shared-ui-button [style]="'warning'" [block]="true" (press)="onBookNow?.()" #bookNowButton>
        {{ 'BOOK_NOW' | lhTranslate }}
      </lh-shared-ui-button>
    </div>
  </div>
</div>
