import { SatisfactionScore } from '@luggagehero/shared/interfaces';

import { DefaultManageBookingStrategy } from './manage-booking.strategy.default';

export class PaymentFirstManageBookingStrategy extends DefaultManageBookingStrategy {
  get activeSection(): string {
    if (!this.booking) {
      return 'reservation';
    }
    switch (this.booking.status) {
      case 'CONFIRMED':
        return this.booking.paymentMethodId ? 'checkIn' : 'paymentMethod';

      case 'CHECKED_IN':
        return 'checkOut';

      default:
        return this.isFeedbackSectionCompleted ? '' : 'feedback';
    }
  }

  get isCheckInSectionVisible(): boolean {
    if (!this.booking) {
      return false;
    }
    if (this.booking.status === 'CANCELLED') {
      return false;
    }
    if (!this.booking.paymentMethodId) {
      return false;
    }
    return true;
  }

  get showDirections(): boolean {
    if (!this.booking) {
      return false;
    }
    if (this.booking.status === 'CANCELLED') {
      return false;
    }
    if (!this.booking.paymentMethodId) {
      return false;
    }
    return true;
  }

  get isPaymentInfoDue(): boolean {
    return !this.isPaymentMethodSectionCompleted;
  }

  onBookingLoaded(satisfaction: SatisfactionScore) {
    if (!this.booking.paymentMethodId && !this.isCanceledSectionVisible) {
      this.owner.paymentMethodModal.show();
    } else {
      super.onBookingLoaded(satisfaction);
    }
    // if (!this.owner.paymentMethods || this.owner.paymentMethods.length === 0) {
    //   this.owner.paymentMethodModal.show();
    // }
  }

  onPaymentMethodUpdated() {
    this.owner.paymentMethodModal.hide();
  }
}
