import { ChangeDetectorRef, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { SharedAnalyticsService } from '@luggagehero/shared/services/analytics';
import { SharedNotificationService } from '@luggagehero/shared/services/notification';
import { SharedStorageService } from '@luggagehero/shared/services/storage';
import { SharedUserService } from '@luggagehero/shared/services/users';

import { BaseModalComponent } from '../../../core';
interface AbandonReason {
  key: string;
  value: string;
  hidden: boolean;
}

@Component({ template: '' })
export abstract class AbandonBookingBaseComponent extends BaseModalComponent implements OnInit {
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() public onClose = new EventEmitter<void>();

  public selectedAbandonReason: AbandonReason = null;
  public abandonReasons: AbandonReason[] = [
    {
      key: 'TOO_EXPENSIVE',
      value: this._translate('ABANDON_REASON_TOO_EXPENSIVE'),
      hidden: false,
    },
    {
      key: 'JUST_BROWSING',
      value: this._translate('ABANDON_REASON_JUST_BROWSING'),
      hidden: false,
    },
    {
      key: 'CHECKING_PRICE',
      value: this._translate('ABANDON_REASON_CHECKING_PRICE'),
      hidden: false,
    },
    {
      key: 'COULDNT_ADD_PAYMENT_METHOD',
      value: this._translate('ABANDON_REASON_COULDNT_ADD_PAYMENT_METHOD'),
      hidden: false,
    },
    {
      key: 'OTHER',
      value: this._translate('ABANDON_REASON_OTHER'),
      hidden: false,
    },
  ];

  public abandonReasonSubmitted = false;

  public constructor(
    private userService: SharedUserService,
    private notificationService: SharedNotificationService,
    private analyticsService: SharedAnalyticsService,
    private storage: SharedStorageService,
    private cd: ChangeDetectorRef,
  ) {
    super();
  }

  public ngOnInit() {
    // Store the time we last asked the user for feedback
    this.storage.abandonCartLastPromptedAt = new Date();
  }

  public close(suppressThanks?: boolean) {
    if (this.abandonReasonSubmitted) {
      if (!suppressThanks) {
        this.notificationService.success(this._translate('THANK_YOU_FOR_FEEDBACK'));
      }

      this.analyticsService.track('removeFromCart', {
        category: 'booking',
        label: this.selectedAbandonReason.key,
        user: this.userService.user,
      });
    }
    this.onClose.emit();
    void this.hideModal();
  }

  public selectAbandonReason(reason: AbandonReason) {
    // Store the response so we can avoid asking the user again
    this.storage.abandonCartResponse = reason.key;
    this.storage.abandonCartLastResponseAt = new Date();

    this.abandonReasonSubmitted = true;
    this.selectedAbandonReason = reason;

    this.cd.markForCheck();
  }
}
