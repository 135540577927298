<div class="modal-content">
  <div class="modal-header">
    <h4 class="modal-title pull-left">{{ 'ABANDON_CART_TITLE' | translate }}</h4>

    <button id="closeAbandonCart" type="button" class="close pull-right" aria-label="Close" (click)="close(true)">
      <svg viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
        />
        <path d="M0 0h24v24H0z" fill="none" />
      </svg>
    </button>
  </div>

  <div class="modal-body">
    <p>{{ 'ABANDON_CART_DESCRIPTION' | translate }}</p>
    <div class="form-group">
      <div class="tab-group">
        <button
          *ngFor="let reason of abandonReasons"
          [id]="'selectReason_' + reason"
          (click)="selectAbandonReason(reason)"
          class="btn btn-info btn-tab"
          [class.active]="selectedAbandonReason && reason.key === selectedAbandonReason.key"
        >
          {{ reason.value }}
        </button>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <button
      class="btn btn-block btn-primary"
      id="closeAbandonCart"
      [disabled]="!abandonReasonSubmitted"
      (click)="close()"
    >
      {{ 'DONE' | translate }}
    </button>
  </div>
</div>
