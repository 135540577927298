import { Injectable } from '@angular/core';
import { MultilingualService } from '@luggagehero/i18n';
import { SharedAppSettingsService } from '@luggagehero/shared/app-settings/data-access';
import { AppInfo } from '@luggagehero/shared/interfaces';
import { SharedDocumentService } from '@luggagehero/shared/services/document';
import { SharedIntercomService } from '@luggagehero/shared/services/intercom';
import { SharedLoggingService } from '@luggagehero/shared/services/logging';
import { SharedParamsService } from '@luggagehero/shared/services/params';
import { SharedSplitTestingService } from '@luggagehero/shared/services/split-testing';
import { SharedStorageService } from '@luggagehero/shared/services/storage';
import { SharedThemeService } from '@luggagehero/shared/services/theme';
import { SharedWindowService, WindowPlatformService } from '@luggagehero/shared/services/window';
import { SharedUtilDate, SharedUtilGuid } from '@luggagehero/shared/util';
import { DateUtil } from '@luggagehero/utils';
import { cloneDeep } from 'lodash-es';

import packageInfo from '../../../package.json';
import { DefaultAppSettings } from './app.settings';

/**
 * App initializer service which runs before the app is loaded
 */
@Injectable({
  providedIn: 'root',
})
export class AppInitializer {
  constructor(
    private storageService: SharedStorageService,
    private paramsService: SharedParamsService,
    private appSettingsService: SharedAppSettingsService,
    private multilingualService: MultilingualService,
    private splitTestingService: SharedSplitTestingService,
    private intercomService: SharedIntercomService,
    private themeService: SharedThemeService,
    private documentService: SharedDocumentService,
    private windowService: SharedWindowService,
    private window: WindowPlatformService, // TODO: Can we call this via windowService?
    private log: SharedLoggingService,
  ) {}

  public async init(): Promise<void> {
    let params: Record<string, string>;

    try {
      // Get query params from the url as an object
      params = Object.fromEntries(new URLSearchParams(this.window.location.search));
    } catch (err) {
      void this.log.error('Error parsing query params', err);
      params = {};
    }

    //
    // Create session and visitor ids and store them locally before making the first api calls as they will be added to
    // http request headers
    //
    this.initSession(params);

    //
    // Wait for window service before doing other initialization as other services depend on the window service during
    // their initialization (note that the native window service also waits for the Ionic platform to be ready)
    //
    const app: AppInfo = { name: packageInfo.name, version: packageInfo.version };
    await this.windowService.init(app);

    // Initialize app settings which are required for other initialization tasks
    await this.appSettingsService.init(app.name, this.windowService.platform, DefaultAppSettings);

    // Parse tracking data from query params
    const trackingData = this.paramsService.parseTrackingData(params);

    if (this.checkHeroCodeRedirect(trackingData)) {
      return; // Redirected away from the app
    }

    // Check if the app update is required (this may refresh the page/reload the app)
    this.windowService.checkAppVersion(this.appSettingsService.current.minAppVersion);

    //
    // Start intialization of other services which should be ready before the app is loaded, but which no other
    // intialization logic depends upon, so they can run in parallel to speed up the app load time (e.g. in case of
    // network requests)
    //
    const asyncTasks = [
      this.multilingualService.init(params).catch((err) => void this.log.error(`Error initializing language`, err)),
      this.themeService.init().catch((err) => void this.log.error(`Error initializing theme`, err)),
    ];

    this.initSplitTesting(params);

    // Initialize Intercom without waiting for it to be ready
    void this.intercomService.init(app.name).catch((err) => void this.log.error(`Error initializing Intercom`, err));

    // Set body classes required for styling
    this.documentService.addBodyClass(this.windowService.isTouch ? 'touchevents' : 'no-touchevents');

    try {
      // Configure utils which cannot depend on app settings service directly
      DateUtil.TimeFormatDetectionVersion = this.appSettingsService.current.TIME_FORMAT_DETECTION_VERSION;
      SharedUtilDate.TimeFormatDetectionVersion = this.appSettingsService.current.TIME_FORMAT_DETECTION_VERSION;
    } catch (err) {
      void this.log.error('Error configuring utils', err);
    }

    // Wait for all async tasks to complete
    await Promise.all(asyncTasks);
  }

  private initSplitTesting(params: Record<string, string>): void {
    try {
      // Enable experiment and variant if provided
      this.splitTestingService.init(params);

      // Apply variant-specific configurations
      switch (this.splitTestingService.variant) {
        // case this.appSettingsService.current.EXPERIMENT_VARIANTS.someVariant: {
        //   this.appSettingsService.current.SOME_CONFIG_PROPERTY = true;
        //   break;
        // }
        case this.appSettingsService.current.EXPERIMENT_VARIANTS.premiumInsuranceCoverage: {
          this.appSettingsService.current.premiumInsuranceEnabled = true;
          break;
        }
        case this.appSettingsService.current.EXPERIMENT_VARIANTS.noSecuritySealCommunication: {
          // Clone how it works config and remove security seals slide
          const modifiedConfig = cloneDeep(this.appSettingsService.current.HOW_IT_WORKS_DEFAULT);
          modifiedConfig.slides = modifiedConfig.slides.filter((s) => s.text.header !== 'APPLY_SECURITY_SEALS');

          // Replace configuration
          this.appSettingsService.current.HOW_IT_WORKS_DEFAULT = modifiedConfig;

          break;
        }
        case this.appSettingsService.current.EXPERIMENT_VARIANTS.freeCancellationBanner:
        case this.appSettingsService.current.EXPERIMENT_VARIANTS.noShopUrgency: {
          this.appSettingsService.current.IS_SHOP_URGENCY_DISABLED = true;
          break;
        }
        case this.appSettingsService.current.EXPERIMENT_VARIANTS.minBags: {
          this.appSettingsService.current.IS_BOOKING_MIN_BAGS_ENABLED = true;
          this.appSettingsService.current.IS_DROP_OFF_MIN_BAGS_ENABLED = true;
          this.appSettingsService.current.IS_PRICE_ON_PRICING_MODEL_SELECTOR_ENABLED = false;
          break;
        }
        case this.appSettingsService.current.EXPERIMENT_VARIANTS.original:
        default: {
          break;
        }
      }
    } catch (err) {
      void this.log.error('Error initializing split testing', err);
    }
  }

  private initSession(params: Record<string, string>): void {
    try {
      //
      // Create session and visitor ids and store them locally before making the first api calls as they will be added to
      // http request headers. Take visitor id from query params first if provided (e.g. when passed from landing site);
      // otherwise, take from storage if present there, or ultimately create a new one.
      //
      this.storageService.visitorId = params.visitorId || this.storageService.visitorId || SharedUtilGuid.create();
      this.storageService.sessionId = SharedUtilGuid.create();

      if (params.t) {
        this.storageService.bookingToken = params.t;
      }
    } catch (err) {
      void this.log.error('Error initializing session', err);
    }
  }

  private checkHeroCodeRedirect(trackingData: Record<string, unknown>): boolean {
    try {
      if (trackingData?.utm_medium === 'hero_code' && this.appSettingsService.current.HERO_CODE_REDIRECT_URL) {
        const url = new URL(this.appSettingsService.current.HERO_CODE_REDIRECT_URL);

        // Add all utm_ parameters to the redirect URL
        for (const key in trackingData) {
          if (!key.startsWith('utm_')) {
            // Only add utm_ parameters
            continue;
          }

          // Get the value of the parameter
          const value = trackingData[key];

          // Only add string values
          if (typeof value === 'string') {
            url.searchParams.append(key, value);
          }
        }

        // Redirect to the URL
        this.windowService.setTopLocationHref(url.toString());

        return true; // Redirected
      }
    } catch (err) {
      void this.log.error('Error checking hero code redirect', err);
    }

    return false; // Not redirected
  }
}
