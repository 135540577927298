<div class="app-container" [class.light-header]="isThemeHeaderLight">
  <!-- Top navbar -->
  <lh-navbar></lh-navbar>

  <!-- Angular router outlet -->
  <router-outlet (attach)="onAttach($event)" (detach)="onDetach($event)"></router-outlet>

  <!-- Bottom tab bar -->
  <lh-tab-bar *ngIf="isTabbedNavigationEnabled"></lh-tab-bar>

  <!-- Intercom widget -->
  <div *ngIf="isIntercomEnabled" id="intercomTrigger" class="intercom-trigger" [hidden]="isTabbedNavigationEnabled">
    <span class="chat-widget-header">{{ 'CHAT_WIDGET_HEADER' | translate }}</span>
  </div>
</div>

<div class="app-version">
  <span>v{{ appVersion }}</span>
</div>
