import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, Input } from '@angular/core';

export interface Step {
  label: string;
}

export type UiProgressSize = 'small' | 'medium' | 'large';

@Component({
  selector: 'lh-shared-ui-step-progress',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './shared-ui-step-progress.component.html',
  styleUrl: './shared-ui-step-progress.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SharedUiStepProgressComponent {
  @Input() public steps: Step[] = [];
  @Input() public currentStepIndex = 0;

  private _size: UiProgressSize = 'medium';

  private cd = inject(ChangeDetectorRef);

  public get size(): UiProgressSize {
    return this._size;
  }
  @Input() public set size(value: UiProgressSize) {
    this._size = value;
    this.cd.markForCheck();
  }

  public get visibleSteps(): Step[] {
    // If there are less than 3 steps, show all steps
    if (this.steps.length <= 3) {
      return this.steps;
    }
    // If current step is the first step, show the first 3 steps
    if (this.currentStepIndex === 0) {
      return this.steps.slice(0, 3);
    }
    // If current step is the last step, show the last 3 steps
    if (this.currentStepIndex === this.steps.length - 1) {
      return this.steps.slice(-3);
    }
    // If current step is in the middle, show the current step and the steps before and after
    return this.steps.slice(this.currentStepIndex - 1, this.currentStepIndex + 2);
  }

  public get currentVisibleStepIndex(): number {
    return this.visibleSteps.findIndex((step) => step === this.steps[this.currentStepIndex]);
  }

  public isCurrentStep(stepIndex: number): boolean {
    return this.currentVisibleStepIndex === stepIndex;
  }

  public hasMoreSteps(stepIndex: number): boolean {
    return stepIndex < this.visibleSteps.length - 1;
  }
}
