import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AppConfig } from '@luggagehero/shared/app-settings/data-access';
import { Config } from '@luggagehero/shared/environment';
import { BookableStorageLocation, ITimeInterval, StorageCriteria } from '@luggagehero/shared/interfaces';
import { SharedBookingService } from '@luggagehero/shared/services/bookings';
import { SharedGoogleMapsService } from '@luggagehero/shared/services/google-maps';
import { SharedShopsService } from '@luggagehero/shared/services/shops';
import { SharedStorageService } from '@luggagehero/shared/services/storage';
import { SharedStorageCriteriaService } from '@luggagehero/shared/services/storage-criteria';
import { SharedWindowService } from '@luggagehero/shared/services/window';

import { BookingActionBaseComponent } from './booking-action.base-component';

@Component({ template: '' })
export abstract class GoToShopActionBaseComponent extends BookingActionBaseComponent implements OnInit {
  isFullOpeningHoursVisible = false;

  private _isHelpWithDirectionsExpanded = false;
  private _storageLocationTelephoneNumber: string;
  private baseUrl = '';

  constructor(
    private criteriaService: SharedStorageCriteriaService,
    private googleMapsService: SharedGoogleMapsService,
    private windowService: SharedWindowService,
    bookingService: SharedBookingService,
    shopsService: SharedShopsService,
    storageService: SharedStorageService,
    cd: ChangeDetectorRef,
  ) {
    super(bookingService, shopsService, storageService, cd);
    this.baseUrl = `${Config.environment.IMAGES_BASE_URL}/images`;
  }

  get criteria(): StorageCriteria {
    return this.criteriaService.currentOrDefault;
  }

  get ios(): boolean {
    return this.windowService.iOS;
  }

  get showLocationType(): boolean {
    return AppConfig.IS_STORAGE_LOCATION_TYPE_ENABLED;
  }

  get showFullOpeningHours(): boolean {
    if (!this.shop) {
      return false;
    }
    return this.isFullOpeningHoursVisible;
  }

  get showSimpleOpeningHours(): boolean {
    if (!this.shop || !this.booking || !this.criteria) {
      return false;
    }
    return !this.isFullOpeningHoursVisible;
  }

  get imageSize(): string {
    const windowWidth = this.windowService.innerWidth;
    return windowWidth > 460 && windowWidth < 769 ? 'large' : 'medium';
  }

  get imageUrl(): string {
    if (!this.shop || !this.shop.images || this.shop.images.length === 0) {
      return '/assets/no-image.jpg';
    }
    return `${this.baseUrl}/${this.imageSize}/${this.shop.images[0]}`;
  }

  get storageLocationTelephoneNumber(): string {
    return this._storageLocationTelephoneNumber;
  }

  get showHelpWithDirectionsCta(): boolean {
    if (this.storageLocationTelephoneNumber) {
      return true;
    }
    return false;
  }

  get isHelpWithDirectionsExpanded(): boolean {
    return this._isHelpWithDirectionsExpanded;
  }
  set isHelpWithDirectionsExpanded(value: boolean) {
    this._isHelpWithDirectionsExpanded = value;
    this.cd.markForCheck();
  }

  async ngOnInit() {
    this.isInitialized;
    await super.ngOnInit();
    // let shopDetails = await this.shopsService.getShop(this.booking.shopId)
    this._storageLocationTelephoneNumber = this.shopsService.current?.phone;
    this.cd.markForCheck();
  }
  toggleFullOpeningHours() {
    this.isFullOpeningHoursVisible = !this.isFullOpeningHoursVisible;
    this.cd.markForCheck();
  }

  isNotSameDay(date1: Date, date2: Date) {
    if (date1 && date2) {
      return date1.toDateString() !== date2.toDateString();
    }
    return false;
  }

  getOpeningHoursFromDate(date: Date, shop: BookableStorageLocation): ITimeInterval[] {
    return this.shopsService.getOpeningHoursForDate(date, shop);
  }
}
