<div itemprop="aggregateRating" itemscope itemtype="http://schema.org/AggregateRating">
  <div *ngIf="showShopRating">
    <meta content="{{ averageRating }}" itemprop="ratingValue" />
    <meta content="{{ shopStatsNumberOfRatings }}" itemprop="reviewCount" />
    <meta content="5" itemprop="bestRating" />
    <meta content="0" itemprop="worstRating" />
  </div>

  <div class="ratings-container">
    <a id="showAllRatings" class="href" (click)="showReviews.emit()">
      <rating class="shop-rating-md" [(ngModel)]="averageRating" [readonly]="true"></rating>
    </a>

    <span *ngIf="showShopRating && numberOfRatings > 1" class="sidenote">
      &nbsp;<span class="avg-rating">{{ averageRating | number }}</span> &nbsp;({{ numberOfRatings | number }}
      {{ 'RATINGS' | translate | lowercase }})
    </span>

    <span *ngIf="!showShopRating || numberOfRatings <= 1" class="sidenote">
      &nbsp;({{ averageRating | number: '1.1-1' }} {{ 'AVERAGE_RATING' | translate | lowercase }})
    </span>
  </div>

  <div *ngIf="shopLoaded">
    <div *ngIf="hasShopStats && shopStatsNumberOfRatings && shopStatsNumberOfRatings > 1">
      <span *ngIf="numberOf5StarRatings > 1">
        {{ numberOf5StarRatings | number }} {{ 'TRAVELERS_GAVE_THIS_5_STARS' | translate }}!
        {{ 'SEE_WHAT_THEY_SAID' | translate }}
      </span>
      <span *ngIf="numberOf5StarRatings <= 1">
        {{ numberOfRatings | number }} {{ 'TRAVELERS_RATED_THIS' | translate }}
      </span>
      <a id="readAllReviews" class="href text-decoration-none" (click)="showReviews.emit()">{{
        'READ_ALL_REVIEWS' | translate
      }}</a>
    </div>

    <div *ngIf="!hasShopStats || numberOfRatings <= 1">
      <span *ngIf="globalNumberOfRatings">
        {{ globalNumberOfRatings | number }} {{ 'PEOPLE_GAVE_FEEDBACK' | translate }}
        {{ 'SEE_WHAT_THEY_SAID' | translate }}
      </span>
      <a id="readReviews text-decoration-none" class="href" (click)="showReviews.emit()">{{
        'READ_REVIEWS' | translate
      }}</a>
    </div>
  </div>
</div>
