import { Injectable } from '@angular/core';
import { Device } from '@capacitor/device';
import { SignInWithApple, SignInWithAppleOptions } from '@capacitor-community/apple-sign-in';
import { AppleAuthResponse, AppleSignin } from '@luggagehero/shared/interfaces';

@Injectable()
export class AppleSigninService implements AppleSignin {
  private majorOsVersion: number;
  private iosDevice: boolean;
  private initialized = false;

  private options: SignInWithAppleOptions;

  public async checkAppleSignInSupport(): Promise<boolean> {
    if (!this.initialized) {
      await this.init();
    }
    // Enable Apple sign-in for iOS 13.0 and above
    return this.iosDevice && this.majorOsVersion >= 13;
  }

  private async init() {
    const deviceInfo = await Device.getInfo();

    // Get platform info
    const platform = deviceInfo ? deviceInfo.platform : undefined;
    const iosDevice = platform === 'ios';

    if (iosDevice) {
      try {
        const versionComponents = deviceInfo.osVersion.split('.');
        this.majorOsVersion = Number(versionComponents[0]);
      } catch {
        // Parsing of the OS version failed (this shouldn't happen)
      }
    }

    this.options = {
      clientId: 'com.luggagehero.ios',
      redirectURI: 'https://luggagehero.com/',
      scopes: 'email name',
    };

    this.initialized = true;
  }

  public async signIn(): Promise<AppleAuthResponse> {
    const result = await SignInWithApple.authorize();

    return result.response;
  }
}
