import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Route, RouterModule } from '@angular/router';
import {
  EmailOtpStrategy,
  EmailPasswordStrategy,
  PhoneOtpStrategy,
  PhonePasswordStrategy,
} from '@luggagehero/features/user';
import { AppVersionGuard, AuthGuard } from '@luggagehero/services';
import { SharedFeatureFluentAuthComponent } from '@luggagehero/shared-feature-fluent-auth';
import { TranslateModule } from '@ngx-translate/core';
import { CodeInputModule } from 'angular-code-input';
import { ModalModule } from 'ngx-bootstrap/modal';
import { NgxCaptchaModule } from 'ngx-captcha';

import { UiModule } from '../ui/ui.module';
import {
  ProfileComponent,
  USER_COMPONENTS,
  UserAuthenticationComponent,
  UserComponent,
  UserModalComponent,
} from './components';

export const UserRoutes: Array<Route> = [
  {
    path: '',
    canActivate: [AppVersionGuard],
    children: [
      { path: 'user', component: UserComponent },
      { path: 'user/account', component: ProfileComponent, canActivate: [AuthGuard] },
    ],
  },
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule, // FIXME: Shouldn't this be removed?
    RouterModule.forChild(UserRoutes),
    ReactiveFormsModule,
    UiModule,
    TranslateModule,
    ModalModule.forRoot(),
    CodeInputModule,
    NgxCaptchaModule,
    SharedFeatureFluentAuthComponent,
  ],
  declarations: [...USER_COMPONENTS],
  providers: [EmailPasswordStrategy, EmailOtpStrategy, PhonePasswordStrategy, PhoneOtpStrategy],
  exports: [
    // we have to export the modal component for it to be available in app
    UserModalComponent,
    UserAuthenticationComponent,
  ],
})
export class UserModule {}
