<div *ngIf="isNewGuestDropoffFlowEnabled">
  <!-- New guest dropoff flow -->
  <lh-guest-feature-dropoff [storageLocation]="storageLocation"></lh-guest-feature-dropoff>
</div>

<div *ngIf="!isNewGuestDropoffFlowEnabled">
  <!-- Old dropoff flow -->
  <div *ngIf="isLoading" class="spinner-container">
    <lh-shared-ui-spinner [visible]="true"></lh-shared-ui-spinner>
  </div>

  <lh-step-navigation [steps]="dropoffSteps"></lh-step-navigation>
</div>
