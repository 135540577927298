import { Inject, Injectable } from '@angular/core';
import { SharedHttpClient } from '@luggagehero/shared/services/http';
import { SharedStorageService } from '@luggagehero/shared/services/storage';
import { map, Observable, of } from 'rxjs';

import { TRANSLATE_CONFIG, TranslateConfig, TranslationMap } from './+state';

/**
 * A service for communicating with the translation API
 */
@Injectable({
  providedIn: 'root',
})
export class SharedTranslationsService {
  private excludedAppTranslationKeys: Set<string> = new Set();

  private get registeredAppTranslationKeys(): string[] {
    const storedTranslations = this.storage.registeredAppTranslations;
    return (storedTranslations && storedTranslations[this.config.appName]) || [];
  }

  constructor(
    @Inject(TRANSLATE_CONFIG) private config: TranslateConfig,
    private storage: SharedStorageService,
    private http: SharedHttpClient,
  ) {}

  /**
   * Get app text translations from the API as a map of key-value pairs, where the key is the translation key and the
   * value is the translated text in the requested language.
   * @param lang The language code for the translations to get
   * @returns An observable of the translation map
   * @throws Error if the API address is not set or if no app text is received from the API
   */
  public getAppTranslations(lang: string): Observable<TranslationMap> {
    if (!this.config.apiAddress) {
      throw new Error('API address not set');
    }
    // Build URL for the request to the app text API endpoint
    const url = new URL(`${this.config.apiAddress}/app_text`);
    url.searchParams.append('appName', this.config.appName);
    url.searchParams.append('locale', lang);

    return this.http.get<TranslationMap>(url.toString()).pipe(
      map((res) => {
        if (Object.keys(res).length > 0) {
          return res;
        }
        throw new Error('No app text received from API');
      }),
    );
  }

  /**
   * Exclude the given app translation keys from being registered with the API
   * @param keys The keys to exclude
   */
  public excludeAppTranslationKeys(...keys: string[]): void {
    keys.forEach((key) => this.excludedAppTranslationKeys.add(key));
  }

  /**
   * Register the given app translation keys with the API
   * @param keys The keys to register
   * @returns An observable that emits when the keys have been registered
   * @throws Error if the API address is not set
   */
  public registerAppTranslationKeys(keys: string[]): Observable<void> {
    if (!this.config.apiAddress) {
      throw new Error('API address not set');
    }
    const url = new URL(`${this.config.apiAddress}/app_text`);
    url.searchParams.append('appName', this.config.appName);

    // Filter out excluded keys and keys that were already submitted for the current app
    keys = keys.filter(
      (key) => !this.excludedAppTranslationKeys.has(key) && !this.registeredAppTranslationKeys.includes(key),
    );

    if (keys.length === 0) {
      // No translation keys to register
      return of();
    }

    // Save the keys we are submitting so we don't have to submit them again
    this.saveRegisteredAppTranslationKeys(keys);

    return this.http.post(url.toString(), keys, false);
  }

  private saveRegisteredAppTranslationKeys(newTranslationKeys: string[]) {
    // Get existing stored translations, if any
    let storedTranslations = this.storage.registeredAppTranslations;

    if (!storedTranslations) {
      // No translation keys stored, create a new object
      storedTranslations = { [this.config.appName]: newTranslationKeys.sort() };
    } else if (!storedTranslations[this.config.appName]) {
      // No translation keys stored for the current app
      storedTranslations[this.config.appName] = newTranslationKeys.sort();
    } else {
      // Merge with existing keys stored for the current app
      const combinedKeys = new Set(storedTranslations[this.config.appName]);
      newTranslationKeys.forEach((key) => combinedKeys.add(key));
      storedTranslations[this.config.appName] = Array.from(combinedKeys).sort();
    }

    // Save the registered translations in browser storage
    this.storage.registeredAppTranslations = storedTranslations;
  }
}
