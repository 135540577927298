import { ChangeDetectorRef, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';

import { BaseComponent } from '../../../core';
import { DateUtil } from '../../../utils/date.util';

const noop = () => {
  /**/
};

@Component({ template: '' })
export abstract class TimePickerBaseComponent extends BaseComponent implements ControlValueAccessor {
  @ViewChild('focusStealer') focusStealer: ElementRef<HTMLButtonElement>;

  _value: Date;
  _disabled = false;
  hourStep = 1;
  minuteStep = 10;
  showSpinners = false;
  enableArrowKeys = true;
  enableMouseWheel = false;

  private onTouchedCallback: () => void = noop;
  private onChangeCallback: (_) => void = noop;

  constructor(private cd: ChangeDetectorRef) {
    super();
  }

  get value(): Date {
    return this._value;
  }
  set value(value: Date) {
    if (!value || value === this._value) {
      return;
    }
    this._value = value;
    this.onChangeCallback(value);
    this.cd.markForCheck();
  }

  get disabled(): boolean {
    return this._disabled;
  }
  @Input() set disabled(value: boolean) {
    this._disabled = value;
    this.cd.markForCheck();
  }

  get is12HourMode(): boolean {
    return DateUtil.is12HourMode;
  }

  onTimePickerClose() {
    this.focusStealer.nativeElement.focus();
  }

  writeValue(value: Date) {
    if (value !== undefined) {
      this._value = value;
      this.cd.markForCheck();
    }
  }

  registerOnChange(fn: (_) => void) {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: () => void) {
    this.onTouchedCallback = fn;
  }
}
