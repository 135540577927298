export class SharedUtilList {
  /**
   * Appends the item to the list if it is not equal to any of the provided negative values.
   * @param list The list to append the item to.
   * @param item The item to append.
   * @param negativeValues The values to compare the item to.
   * @returns The new length of the list.
   */
  public static appendIfNotEqualTo<T>(list: T[], item: T, ...negativeValues: unknown[]): number {
    if (negativeValues?.some((negativeValue) => item === negativeValue)) {
      return list.length;
    }
    return list.push(item);
  }

  /**
   * Appends the item to the list if it is not null or undefined.
   * @param list The list to append the item to.
   * @param item The item to append.
   * @returns The new length of the list.
   */
  public static appendIfNotNullOrUndefined<T>(list: T[], item: T): number {
    return this.appendIfNotEqualTo(list, item, null, undefined);
  }
}
