import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppConfig } from '@luggagehero/shared/app-settings/data-access';
import { HowItWorksConfig, HowItWorksScenario } from '@luggagehero/shared/interfaces';

import { BaseModalComponent } from '../../../core';

@Component({ template: '' })
export abstract class HowItWorksBaseComponent extends BaseModalComponent implements OnInit {
  public config: HowItWorksConfig;

  public get howItWorksScenario(): HowItWorksScenario {
    if (
      this.router.routerState.snapshot.url.includes('book-shop') ||
      this.router.routerState.snapshot.url.includes('dropoff')
    ) {
      return HowItWorksScenario.walkIn;
    }
    return HowItWorksScenario.default;
  }

  constructor(private router: Router) {
    super();
  }

  public ngOnInit(): void {
    if (!this.config) {
      switch (this.howItWorksScenario) {
        case HowItWorksScenario.walkIn:
          this.config = AppConfig.HOW_IT_WORKS_WALK_IN;
          break;

        case HowItWorksScenario.mobileApp:
          this.config = AppConfig.HOW_IT_WORKS_MOBILE_APP;
          break;

        case HowItWorksScenario.default:
        default:
          this.config = AppConfig.HOW_IT_WORKS_DEFAULT;
          break;
      }
    }
  }

  public hasDividerAfterSlide(slideIndex: number): boolean {
    // Get current slide
    const currentSlide = this.config.slides[slideIndex];

    if (!currentSlide.image) {
      // Show divider if current slide only has text
      return true;
    }

    // Get last slide
    const lastSlideIndex = this.config.slides.length - 1;

    if (slideIndex >= lastSlideIndex) {
      // No divider after last slide
      return false;
    }

    // Get next slide
    const nextSlide = this.config.slides[slideIndex + 1];

    if (!nextSlide.image) {
      // Show divider if next slide only has text
      return true;
    }

    // Show divider if next slide has a round image
    return nextSlide.image.round;
  }
}
