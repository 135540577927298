import { inject, Injectable } from '@angular/core';
import { Config } from '@luggagehero/shared/environment';
import { SharedLoggingService } from '@luggagehero/shared/services/logging';
import { BehaviorSubject, Observable } from 'rxjs';

/**
 * A service for managing a shared loading state. Keeps track of the number of tasks that are currently loading and
 * emits a boolean observable indicating whether any tasks are currently loading.
 */
@Injectable({
  providedIn: 'root',
})
export class SharedLoadingService {
  public readonly loading$: Observable<boolean>;

  private loadingCount = 0;
  private loadingSubject = new BehaviorSubject<boolean>(false);

  private log = inject(SharedLoggingService);

  constructor() {
    this.loading$ = this.loadingSubject.asObservable();
  }

  /**
   * Increment the loading count
   */
  public increment() {
    this.loadingCount++;
    this.loadingSubject.next(true);

    if (Config.isDevelopment) {
      void this.log.info(`Incremented loading count to ${this.loadingCount}`);
    }
  }

  /**
   * Decrement the loading count
   */
  public decrement() {
    this.loadingCount = Math.max(this.loadingCount - 1, 0);
    if (this.loadingCount === 0) {
      this.loadingSubject.next(false);
    }

    if (Config.isDevelopment) {
      void this.log.info(`Decremented loading count to ${this.loadingCount}`);
    }
  }

  /**
   * Executes an async method, incrementing the loading count before and decrementing it after. Also, optionally
   * attaches a callback to invoke when the promise returned by the async method is rejected and another to invoke when
   * it is settled (fulfilled or rejected).
   * @param value The async method to execute
   * @param onError A callback to invoke if the promise returned by the async method is rejected (optional)
   * @param onFinally A callback to invoke when the promise returned by the async method is settled (optional)
   * @returns The promise returned by the async method which the caller can await to get the result
   */
  public load<T>(value: () => Promise<T>, onError?: (err: unknown) => void, onFinally?: () => void): Promise<T> {
    // Increment the loading count before calling the async method
    this.increment();

    // Call the async method and attach a finally callback to decrement the loading count
    const promise = value().finally(() => {
      this.decrement();

      if (onFinally) {
        // Invoke the provided finally callback
        onFinally();
      }
    });

    if (onError) {
      // Attach the provided error callback
      promise.catch(onError);
    }

    // Return the promise so the caller can await it
    return promise;
  }
}
