import { ChangeDetectionStrategy, Component, Type } from '@angular/core';
import { GroupedListBaseComponent, ViewImageBaseComponent } from '@luggagehero/features/ui';

import { ViewImageComponent } from '../view-image/view-image.component';

@Component({
  selector: 'lh-grouped-list',
  templateUrl: './grouped-list.component.html',
  styleUrls: ['./grouped-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GroupedListComponent<T> extends GroupedListBaseComponent<T> {
  protected get viewImageComponent(): Type<ViewImageBaseComponent> {
    return ViewImageComponent;
  }
}
