import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { EventEmitter, Inject, inject, Injectable, InjectionToken } from '@angular/core';
import { AppConfig } from '@luggagehero/shared/app-settings/data-access';
import { Config } from '@luggagehero/shared/environment';
import {
  BookableStorageLocation,
  EncryptedString,
  FREE_CANCELLATION_FEE_PRODUCT_KEY,
  HeroLevel,
  IHoliday,
  INSURANCE_PRODUCT_KEY,
  IPrice,
  IPricing,
  ITimeInterval,
  ITimePeriod,
  LinkInfo,
  PREMIUM_INSURANCE_PRODUCT_KEY,
  ProductInfo,
  SECURITY_SEAL_PRODUCT_KEY,
  SERVICE_FEE_PRODUCT_KEY,
  STORAGE_DAY_PRODUCT_KEY,
  STORAGE_HOUR_PRODUCT_KEY,
  StorageCriteria,
  StorageLocationBadgeType,
  StorageLocationItemBadge,
  StorageLocationService,
  StorageLocationTag,
  TIME_INTERVAL_TRANSFORMER_SERVICE,
  WALK_IN_DAILY_RATE_PRODUCT_KEY,
  WALK_IN_HOURLY_RATE_PRODUCT_KEY,
  WALK_IN_INSURANCE_FEE_PRODUCT_KEY,
  WALK_IN_SERVICE_FEE_PRODUCT_KEY,
} from '@luggagehero/shared/interfaces';
import { SharedHolidayService } from '@luggagehero/shared/services/holiday';
import { SharedHttpService } from '@luggagehero/shared/services/http';
import { SharedLoggingService } from '@luggagehero/shared/services/logging';
import { SharedMomentService } from '@luggagehero/shared/services/moment';
import { SharedStorageService } from '@luggagehero/shared/services/storage';
import { SharedStorageCriteria, SharedStorageCriteriaService } from '@luggagehero/shared/services/storage-criteria';
import { cloneDeep, SharedUtilDate, SharedUtilString, SharedUtilTimeInterval } from '@luggagehero/shared/util';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { map, Observable } from 'rxjs';

interface FindShopsResponse {
  shops: BookableStorageLocation[];
  price: PriceInfo;
}

interface ShopDetailsResponse {
  shop: BookableStorageLocation;
  price: PriceInfo;
}

interface ProductList {
  currency: string;
  priceContextIds: string[];
  priceContextKey: string;
  products: ProductInfo[];
}

interface StorageLocationListResponse {
  productLists: ProductListInfo[];
  shops: BookableStorageLocation[];
}

interface StorageLocationAvailabilityResponse {
  productList: ProductList | EncryptedString;
  shop: BookableStorageLocation;
}

export interface ProductListInfo {
  hash: string;
  productList: ProductList;
}

export interface PriceInfo {
  estimated: IPrice;
  pricing: IPricing;
  pricings: IPricing[];
  region: string;
}

export const STORAGE_LOCATIONS_SERVICE_ENDPOINT_TOKEN = new InjectionToken<string>(
  'STORAGE_LOCATIONS_SERVICE_ENDPOINT_TOKEN',
);

@Injectable({
  providedIn: 'root',
})
export class SharedShopsService {
  protected momentService = inject(SharedMomentService);
  protected timeIntervalTransformerService = inject(TIME_INTERVAL_TRANSFORMER_SERVICE);

  public listShopsAsyncComplete: EventEmitter<void> = new EventEmitter<void>();
  public latestPriceChange: EventEmitter<PriceInfo> = new EventEmitter<PriceInfo>();
  public latestPrice: PriceInfo;
  public requestCount = 0;

  private apiEndPoint: string;
  private apiEndPointV3: string;
  private storageLocationsEndPoint: string;

  private shops: { [shopId: string]: BookableStorageLocation } = {};
  private latestProductLists: ProductListInfo[];

  private _current: BookableStorageLocation;
  private _walkIn = false;

  constructor(
    private criteriaService: SharedStorageCriteriaService,
    private httpService: SharedHttpService,
    private holidayService: SharedHolidayService,
    private storageService: SharedStorageService,
    private translate: TranslateService,
    private log: SharedLoggingService,
    @Inject(STORAGE_LOCATIONS_SERVICE_ENDPOINT_TOKEN) storageLocationsEndPoint: string,
  ) {
    this.apiEndPoint = `${Config.environment.TRAVELER_API}/v2/storage_locations`;
    this.apiEndPointV3 = `${Config.environment.TRAVELER_API}/v3/storage_locations`;
    this.storageLocationsEndPoint = storageLocationsEndPoint;
  }

  public get current(): BookableStorageLocation {
    return this._current;
  }

  public get walkIn(): boolean {
    return this._walkIn;
  }

  public getNumberOfRatings(shop: BookableStorageLocation): number {
    if (shop?.stats?.numberOfRatingsAndRecommendations && AppConfig.IS_SHOW_RECOMMENDATIONS_WITH_REVIEWS_ENABLED) {
      return shop.stats.numberOfRatingsAndRecommendations;
    }
    if (shop?.stats?.numberOfRatings) {
      return shop.stats.numberOfRatings;
    }
    return 0;
  }

  public getBadgeList(storageLocation: BookableStorageLocation): StorageLocationItemBadge[] {
    const badges: StorageLocationItemBadge[] = [];

    if (this.isRecommended(storageLocation)) {
      badges.push({
        type: StorageLocationBadgeType.Recommended,
        text: AppConfig.IS_SUPERHERO_TERMINOLOGY_ENABLED
          ? 'SUPERHERO'
          : (this.translate.instant('RECOMMENDED') as string),
      });
    }

    if (this.isHighlyRated(storageLocation)) {
      badges.push({
        type: StorageLocationBadgeType.HighlyRated,
        text: this.translate.instant('HIGHLY_RATED') as string,
      });
    }

    const openLate = this.isOpenLate(this.criteriaService.period.from, storageLocation);
    const alwaysOpen = this.isAlwaysOpen(
      this.criteriaService.period.from,
      this.criteriaService.period.to,
      storageLocation,
    );

    if (openLate || alwaysOpen) {
      badges.push({
        type: StorageLocationBadgeType.OpenLate,
        text: this.translate.instant(alwaysOpen ? 'ALWAYS_OPEN' : 'OPEN_LATE') as string,
      });
    }

    return badges;
  }

  async getShareableLink(storageLocationId: string): Promise<string> {
    const url = `${this.apiEndPoint}/${storageLocationId}/links`;
    const res = await this.httpService.get<LinkInfo>(url);

    return res.shortLink;
  }

  public setCurrent(value: BookableStorageLocation, walkIn = false): void {
    this._current = value;
    this._walkIn = walkIn;
  }

  async getShop(shopId: string): Promise<BookableStorageLocation> {
    // check if we already have the shop instead of getting it from the API
    const cachedShop = this.shops[shopId];
    if (cachedShop) {
      // Ensure that shop has id set
      cachedShop._id = shopId;
      return cachedShop;
    }

    const url = `${this.storageLocationsEndPoint}/${shopId}`;

    const storageLocation = await this.httpService.get<BookableStorageLocation>(url);

    if (storageLocation) {
      void this.holidayService.initCountry(storageLocation.address.countryCode);
      return this.deserializeShop(storageLocation);
    }
  }

  //
  // Where are we fetching shops based on criteria which may have been using the period where we move to a later hour
  // or next day if after a certain time?
  //

  async getAvailability(
    storageLocationId: string,
    from: string,
    to: string,
    normal: number,
    hand: number,
    bookingType: 'marketplace' | 'dropoff',
  ): Promise<BookableStorageLocation> {
    const url = new URL(`${this.apiEndPointV3}/${storageLocationId}/availability`);

    url.searchParams.append('from', from);
    url.searchParams.append('to', to);
    url.searchParams.append('normal', String(normal));
    url.searchParams.append('hand', String(hand));
    url.searchParams.append('bookingType', bookingType);

    if (this.storageService.variant) {
      url.searchParams.append('variant', this.storageService.variant);
    }

    let response: StorageLocationAvailabilityResponse;

    try {
      response = await this.httpService.get<StorageLocationAvailabilityResponse>(url.toString(), false);
    } catch (err) {
      if (err instanceof HttpErrorResponse && err.status === HttpStatusCode.NotFound.valueOf()) {
        // This storage location no longer exists
        return null;
      }

      void this.log.error(
        'Error while loading storage location in getAvailability. Storage location id is : ' + storageLocationId,
      );
      return null;
    }

    if (response?.shop?.address?.countryCode) {
      // TODO: Should we wait for this?
      await this.holidayService.initCountry(response.shop.address.countryCode);
    }

    if (response) {
      return this.deserializeShop(response.shop, response.productList);
    }

    void this.log.error('getAvailability api response is falsy. Storage location id is : ' + storageLocationId);
    return null;
  }

  async getAvailabilityByPlaceId(
    placeId: string,
    from: string,
    to: string,
    normal: number,
    hand: number,
  ): Promise<BookableStorageLocation> {
    const url = new URL(`${this.apiEndPointV3}/${placeId}/availability`);

    url.searchParams.append('from', from);
    url.searchParams.append('to', to);
    url.searchParams.append('normal', String(normal));
    url.searchParams.append('hand', String(hand));
    url.searchParams.append('preview', 'true');

    if (this.storageService.variant) {
      url.searchParams.append('variant', this.storageService.variant);
    }

    let response: StorageLocationAvailabilityResponse;

    try {
      response = await this.httpService.get<StorageLocationAvailabilityResponse>(url.toString(), false);
    } catch (err) {
      if (err instanceof HttpErrorResponse && err.status === HttpStatusCode.NotFound.valueOf()) {
        // This storage location no longer exists
        return null;
      }
      /// TODO: retry
      void this.log.error('Failed to get availability by place id. Place id is : ' + placeId, err);
      return null;
    }

    if (response?.shop?.address?.countryCode) {
      // TODO: Should we wait for this?
      await this.holidayService.initCountry(response.shop.address.countryCode);
    }

    return this.deserializeShop(response.shop, response.productList);
  }

  async getShopDetails(
    shopId: string,
    from: string,
    to: string,
    normal: number,
    hand: number,
    bookingType: 'marketplace' | 'dropoff',
  ): Promise<BookableStorageLocation> {
    const url = new URL(`${this.storageLocationsEndPoint}/${shopId}/availability`);

    url.searchParams.append('from', from);
    url.searchParams.append('to', to);
    url.searchParams.append('normal', String(normal));
    url.searchParams.append('hand', String(hand));
    url.searchParams.append('bookingType', bookingType);

    let response: ShopDetailsResponse;

    try {
      response = await this.httpService.get<ShopDetailsResponse>(url.toString(), false);
    } catch (err) {
      if (err instanceof HttpErrorResponse && err.status === HttpStatusCode.NotFound.valueOf()) {
        // This storage location no longer exists
        return null;
      }
    }

    if (response) {
      // TODO: Should we wait for this?
      await this.holidayService.initCountry(response.shop.address.countryCode);
    }

    // HACK
    response.price.pricings = [response.price.pricing];
    this.updateLatestPrice(response.price, response.shop.region);

    return this.deserializeShop(response.shop);
  }

  async listShops(criteria: StorageCriteria): Promise<BookableStorageLocation[]> {
    if (!AppConfig.IS_SHOP_LIST_ASYNC_PRICING_DISABLED) {
      return this.listShopsPricingAsync(criteria);
    }
    if (!criteria) {
      return null;
    }
    this.logRequest('listShops', ++this.requestCount, criteria);

    const url = this.buildGetStorageLocationsUrl(criteria, false);

    const response = await this.httpService.get<StorageLocationListResponse>(url.toString(), false);

    if (response.shops && response.shops.length > 0) {
      await this.holidayService.initCountry(response.shops[0].address.countryCode);
    }

    this.latestProductLists = this.deserializeProductLists(response.productLists);

    return this.deserializeShops(response.shops);
  }

  async listShopsPricingAsync(criteria: StorageCriteria): Promise<BookableStorageLocation[]> {
    if (!criteria) {
      return null;
    }
    this.logRequest('listShops', ++this.requestCount, criteria);

    // Issue the first request with paging enabled (will be fast but only includes pricing for the first 12 shops)
    const url1 = this.buildGetStorageLocationsUrl(criteria, true);
    const response1 = await this.httpService.get<StorageLocationListResponse>(url1, false);

    if (response1.shops?.length > 0) {
      await this.holidayService.initCountry(response1.shops[0].address.countryCode);
    }

    this.latestProductLists = this.deserializeProductLists(response1.productLists);

    // Keep a reference to the first deserialized list of shops so we can populate missing pricing asynchronously
    const deserializedShops1 = this.deserializeShops(response1.shops);

    // Issue a second request with shop paging disabled (all pricing included, but will be slower)
    const url2 = this.buildGetStorageLocationsUrl(criteria, false);

    // Attach callback for when the second response is received to fill in missing pricing info on the first response
    void this.httpService.get<StorageLocationListResponse>(url2, false).then((response2) => {
      if (!response2.shops || response2.shops.length === 0) {
        return;
      }
      // Store the full product list before deserializing the second shop list reponse
      this.latestProductLists = this.deserializeProductLists(response2.productLists);

      const deserializedShops2 = this.deserializeShops(response2.shops);

      deserializedShops1.forEach((shop1) => {
        if (!shop1.pricing) {
          const shop2 = deserializedShops2.find((s2) => s2._id === shop1._id);
          shop1.pricing = shop2?.pricing;

          if (!shop1.pricing) {
            console.log(`${shop1._id} ended up priceless (get it?) even after second response`, {
              shop1,
              shop2,
            });
          }
        }
      });

      this.listShopsAsyncComplete.emit();
    });

    // Return the reference to the first response without waiting for the second request to complete
    return deserializedShops1;
  }

  /**
   * @deprecated Use `listShops()` instead.
   * @param criteria Defines the criteria to use when fetching shops
   * @returns A list of shops matching the provided criteria
   */
  async findNearBy(criteria: StorageCriteria): Promise<BookableStorageLocation[]> {
    if (!criteria) {
      return null;
    }
    this.logRequest('findNearBy', ++this.requestCount, criteria);

    const url = new URL(this.apiEndPoint);

    url.searchParams.append('from', SharedUtilDate.serializeDate(criteria.period.from));
    url.searchParams.append('to', SharedUtilDate.serializeDate(criteria.period.to));
    url.searchParams.append('normal', String(criteria.luggage.normal));
    url.searchParams.append('hand', String(criteria.luggage.hand));
    url.searchParams.append('lat', String(criteria.location.lat));
    url.searchParams.append('lon', String(criteria.location.lon));
    url.searchParams.append('region', String(criteria.location.region));

    if (criteria.activeOnly) {
      url.searchParams.append('activeOnly', String(criteria.activeOnly));
    }

    const response = await this.httpService.get<FindShopsResponse>(url.toString(), false);

    if (response.shops && response.shops.length > 0) {
      await this.holidayService.initCountry(response.shops[0].address.countryCode);
    }

    this.updateLatestPrice(response.price, criteria.location.region);

    return this.deserializeShops(response.shops);
  }

  /**
   * Determines if a specific date should be disabled in a calendar view.
   *
   * @param date The date to check
   * @param shop The shop to check availability for or null if the calendar view is not related to a specific shop
   * @returns True if the date should be disabled, false otherwise
   */
  isCalendarDateDisabled(date: Date, shop?: BookableStorageLocation): boolean {
    if (!shop || !AppConfig.IS_DISABLE_UNAVAILABLE_CALENDAR_DATES_ENABLED) {
      return false;
    }

    const location = this.criteriaService.currentOrDefault?.location;
    const luggage = this.criteriaService.currentOrDefault?.luggage;

    if (!location || !luggage) {
      return false;
    }

    const period: ITimePeriod = { from: date, to: date };

    // If shop is not available on the date we return true
    const criteria = new SharedStorageCriteria(location, period, luggage);
    const disabled = this.isShopAvailable(shop, criteria) === false;

    return disabled;
  }

  isShopAvailable(shop: BookableStorageLocation, criteria: StorageCriteria, includeUncertified = false): boolean {
    if (!shop) {
      return false;
    }

    // If no space left return false
    if (!shop.hasSpace) {
      return false;
    }
    // For now don't count uncertified shops
    if (!includeUncertified && shop.certified === false) {
      return false;
    }
    const from = criteria.period.from;
    const to = criteria.period.to;

    const openingHoursDateFrom = this.getOpeningHoursForDate(from, shop);
    const openingHoursDateTo = this.getOpeningHoursForDate(to, shop);

    // If shop is closed on either from or to date we return false right away
    if (openingHoursDateFrom.length === 0 || openingHoursDateTo.length === 0) {
      return false;
    }
    //
    // HACK: Disabling opening hours check for now
    //
    // const dateFromMinutes = from.getHours() * 60 + from.getMinutes();
    // const dateToMinutes = to.getHours() * 60 + to.getMinutes();

    // // Check if the from and to date is between the opening hours
    // return (
    //   this.isBetweenMinutes(openingHoursDateFrom[0], dateFromMinutes) &&
    //   this.isBetweenMinutes(openingHoursDateTo[0], dateToMinutes)
    // );
    return true;
  }

  isRecommended(shop: BookableStorageLocation): boolean {
    if (!shop || !shop.available || !this.isMultidayStorageAllowed(shop)) {
      // Don't recommend shops that are not available or don't allow multiday storage
      return false;
    }
    // Recommend shops that are at least Pro or Elite or have a partner level greater than 0
    return shop.heroLevel === HeroLevel.Pro || shop.heroLevel === HeroLevel.Elite || shop.partnerLevel > 0;
  }

  isOpenLate(date: Date, shop: BookableStorageLocation): boolean {
    const hours = this.getOpeningHoursForDate(date, shop);

    if (!hours || hours.length === 0) {
      return false;
    }

    const firstOpenInterval = hours[0];
    const lastOpenInterval = hours[hours.length - 1];

    if (firstOpenInterval.from > AppConfig.MIN_OPENS_AT_FOR_OPEN_LATE) {
      // If the first open interval starts too late we don't consider the shop as open late
      return false;
    }

    if (lastOpenInterval.to < AppConfig.MIN_CLOSES_AT_FOR_OPEN_LATE) {
      // If the last open interval ends too early we don't consider the shop as open late
      return false;
    }

    return true;
  }

  isAlwaysOpen(from: Date, to: Date, shop: BookableStorageLocation): boolean {
    const fromHours = this.getOpeningHoursForDate(from, shop);
    if (!fromHours || fromHours.length === 0 || fromHours.length > 1) {
      return false;
    }
    if (fromHours[0].from !== 0 || fromHours[0].to !== 1440) {
      return false;
    }

    const toHours = this.getOpeningHoursForDate(to, shop);
    if (!toHours || toHours.length === 0 || toHours.length > 1) {
      return false;
    }
    if (toHours[0].from !== 0 || toHours[0].to !== 1440) {
      return false;
    }

    return true;
  }

  isHighlyRated(shop: BookableStorageLocation): boolean {
    if (!shop || !shop.stats) {
      return false;
    }
    if ((shop.stats.numberOfRatings || 0) < AppConfig.MIN_NUMBER_OF_RATINGS_FOR_HIGHLY_RATED) {
      return false;
    }
    if ((shop.stats.averageRating || 0) < AppConfig.MIN_AVERAGE_RATING_FOR_HIGHLY_RATED) {
      return false;
    }
    return true;
  }

  isMultidayStorageAllowed(storageLocation: BookableStorageLocation): boolean {
    return storageLocation.services && storageLocation.services.includes(StorageLocationService.MultiDayStorage);
  }

  isBetweenMinutes(timeInterval: ITimeInterval, timeInMinutes: number): boolean {
    return timeInterval.from <= timeInMinutes && timeInterval.to >= timeInMinutes;
  }

  getOpeningHoursForToday(shop: BookableStorageLocation): ITimeInterval[] {
    const localNow = SharedUtilDate.getStorageLocationNow(shop);
    return this.getOpeningHoursForDate(localNow, shop);
  }

  getOpeningHoursForDate(date: Date, shop: BookableStorageLocation): ITimeInterval[] {
    if (!shop) {
      return null;
    }

    date = date || new Date();

    // First check if date has exceptional opening hours
    if (shop.openingHours.exceptions) {
      for (let i = 0; i < shop.openingHours.exceptions.length; i++) {
        const exception = shop.openingHours.exceptions[i];
        if (SharedUtilDate.isSameDate(new Date(exception.date), date)) {
          return exception.openingHours;
        }
      }
    }
    // Then check if date is a holiday with specified hours
    if (shop.openingHours.holidays) {
      for (let i = 0; i < shop.openingHours.holidays.length; i++) {
        const holiday = shop.openingHours.holidays[i];
        const instances = this.getHolidayInstances(holiday.holiday);
        for (let j = 0; j < instances.length; j++) {
          if (SharedUtilDate.isSameDate(instances[j], date)) {
            if (holiday.openingHours === null) {
              // Inherit normal opening hours
              return this.getWeekdayOpeningHours(date, shop);
            }
            return holiday.openingHours;
          }
        }
      }
    }
    // Otherwise pick the normal opening hours based on day of the week
    return this.getWeekdayOpeningHours(date, shop);
  }

  getNextOpenDay(startDate: Date, shop: BookableStorageLocation): Date {
    return this.getNextOpenDayRecursive(cloneDeep(startDate), shop);
  }

  private getNextOpenDayRecursive(startDate: Date, shop: BookableStorageLocation, iterationCount = 0): Date {
    if (iterationCount >= 7) {
      // Only check one week ahead
      return null;
    }
    const openingHours = this.getOpeningHoursForDate(startDate, shop);
    if (openingHours?.length > 0 && (!SharedUtilDate.isToday(startDate) || !this.isClosedForTheDay(shop))) {
      return startDate;
    }
    startDate.setDate(startDate.getDate() + 1);
    return this.getNextOpenDayRecursive(startDate, shop, ++iterationCount);
  }

  getWeekdayOpeningHours(date: Date, shop: BookableStorageLocation): ITimeInterval[] {
    const days = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
    const day = days[date.getDay()];
    const openingHours = shop.openingHours[day] as ITimeInterval[];
    return openingHours;
  }

  getHolidayNoticeHtml(date: Date, shop: BookableStorageLocation): string {
    if (this.showHolidayWarning(date, shop)) {
      const holidayName = this.getHolidayName(date, shop);
      const mightAffectHours = this.translate.instant('MIGHT_AFFECT_THESE_HOURS') as string;
      return `<span class="holiday-warning">${holidayName} ${mightAffectHours}</span>`;
    }

    if (this.showHolidayConfirmed(date, shop)) {
      const holidayName = this.getHolidayName(date, shop);
      const confirmedHoursFor = this.translate.instant('CONFIRMED_HOURS_FOR') as string;
      return `<span class="holiday-success">${confirmedHoursFor} ${holidayName}</span>`;
    }

    return null;
  }

  showHolidayWarning(date: Date, shop: BookableStorageLocation): boolean {
    if (this.hasException(date, shop)) {
      return false;
    }
    if (this.hasHoliday(date, shop)) {
      return false;
    }
    if (this.isClosed(date, shop)) {
      return false;
    }
    return this.isHoliday(date, shop);
  }

  showHolidayConfirmed(date: Date, shop: BookableStorageLocation): boolean {
    return this.isHoliday(date, shop) && this.hasConfirmedHours(date, shop);
  }

  isHoliday(date: Date, shop: BookableStorageLocation): boolean {
    const holidays = this.holidayService.getHolidays(shop.address.countryCode);
    return SharedUtilDate.matchHoliday(date, holidays) !== null;
  }

  getHolidayName(date: Date, shop: BookableStorageLocation): string {
    const holidays = this.holidayService.getHolidays(shop.address.countryCode);
    const holiday = SharedUtilDate.matchHoliday(date, holidays);
    if (holiday) {
      return 'name' in holiday ? holiday.name : holiday.summary;
    }
    return null;
  }

  isOpen(date: Date, shop: BookableStorageLocation): boolean {
    return !this.isClosed(date, shop);
  }

  isClosed(date: Date, shop: BookableStorageLocation): boolean {
    const openingHours = this.getOpeningHoursForDate(date, shop);
    if (!openingHours || openingHours.length === 0) {
      // The storage location is closed on the given day
      return true;
    }

    if (!SharedUtilDate.isToday(date)) {
      // If the date is in the future, the fact that the storage location is open at any point during the day is enough
      return false;
    }

    // Check if the given time is within any of the opening hours
    const timeOfDay = SharedUtilDate.getStorageLocationTimeOfDay(shop);
    for (const hours of openingHours) {
      if (timeOfDay >= hours.from && timeOfDay <= hours.to) {
        // The storage location is open at the given time
        return false;
      }
    }

    // The storage location is not open at the given time
    return true;
  }

  isClosedForTheDay(shop: BookableStorageLocation): boolean {
    const openingHours = this.getOpeningHoursForToday(shop);
    if (!openingHours || openingHours.length === 0) {
      // The storage location is closed on the given day
      return true;
    }

    // Check if the current time is after the last closing time
    const timeOfDay = SharedUtilDate.getStorageLocationTimeOfDay(shop);
    const lastOpenHours = openingHours[openingHours.length - 1];

    return timeOfDay > lastOpenHours.to;
  }

  isOpenNow(shop: BookableStorageLocation): boolean {
    let result = false;

    if (shop) {
      const openHours: string[] = [];
      const timeOfDay = SharedUtilDate.getStorageLocationTimeOfDay(shop);

      try {
        // Get today's opening hours for the storage location
        const openingHours = this.getOpeningHoursForToday(shop) || [];

        // Check if current time is within a time interval where the storage location is open
        for (const hours of openingHours) {
          // Add hours for logging
          openHours.push(`${hours.from}-${hours.to}`);

          // Check if the current time is within this time interval
          if (timeOfDay >= hours.from && timeOfDay <= hours.to) {
            result = true; // The storage location is currently open
            break;
          }
        }
      } catch (err) {
        if (!Config.isProduction) {
          console.error(`Failed to check if storage location is currently open\n`, err);
        }
      }

      if (!Config.isProduction) {
        try {
          const _storageLocationName = shop.name.trim();
          const _openStatus = result ? 'OPEN' : 'NOT open';
          const _hours = openHours.join(', ') || 'closed';
        } catch {
          // Ignore
        }
      }
    }

    return result;
  }

  isOpeningSoon(shop: BookableStorageLocation): boolean {
    if (this.isOpenNow(shop) || this.isClosedForTheDay(shop)) {
      return false;
    }

    const openingHours = this.getOpeningHoursForDate(new Date(), shop);
    if (!openingHours || openingHours.length === 0) {
      return false;
    }

    const now = SharedUtilDate.getStorageLocationTimeOfDay(shop);
    const openingTime = openingHours[0].from;

    const minutesUntilOpening = openingTime - now;

    return minutesUntilOpening > 0 && minutesUntilOpening <= AppConfig.OPENING_SOON_IN_MINUTES;
  }

  isClosingSoon(shop: BookableStorageLocation): boolean {
    if (!this.isOpenNow(shop)) {
      return false;
    }

    const openingHours = this.getOpeningHoursForDate(new Date(), shop);
    if (!openingHours || openingHours.length === 0) {
      return false;
    }

    const now = SharedUtilDate.getStorageLocationTimeOfDay(shop);
    const closingTime = openingHours[0].to;

    const minutesUntilClosing = closingTime - now;

    return minutesUntilClosing > 0 && minutesUntilClosing <= AppConfig.CLOSING_SOON_IN_MINUTES;
  }

  isOpenAllDay(date: Date, shop: BookableStorageLocation): boolean {
    const openingHours = this.getOpeningHoursForDate(date, shop);
    return SharedUtilTimeInterval.is24Hours(openingHours && openingHours[0]);
  }

  getOpensAt(date: Date, shop: BookableStorageLocation): Observable<string> {
    const isOpen = this.isOpen(date, shop);

    if (!isOpen) {
      date = this.getNextOpenDay(date, shop);
    }

    const isToday = SharedUtilDate.isToday(date);
    const timeOnly = this.isOpeningSoon(shop) && AppConfig.IS_OPENING_AND_CLOSING_SOON_TIME_ONLY_ENABLED;

    const hours = isToday ? this.getOpeningHoursForToday(shop) : this.getOpeningHoursForDate(date, shop);
    return this.timeIntervalTransformerService
      .transform(hours, false, timeOnly, 'from')
      .pipe(
        map((hoursFormatted) =>
          isToday ? hoursFormatted : `${hoursFormatted} ${this.momentService.formatDate(date, 'calendar')}`,
        ),
      );
  }

  getClosesAt(date: Date, shop: BookableStorageLocation): Observable<string> {
    const hours = this.getOpeningHoursForDate(date, shop);
    const alwaysOpen = this.isAlwaysOpen(date, date, shop);
    const timeOnly = this.isClosingSoon(shop) && AppConfig.IS_OPENING_AND_CLOSING_SOON_TIME_ONLY_ENABLED;
    return this.timeIntervalTransformerService.transform(hours, alwaysOpen, timeOnly, 'to');
  }

  hasConfirmedHours(date: Date, shop: BookableStorageLocation): boolean {
    return this.hasException(date, shop) || this.hasHoliday(date, shop);
  }

  hasException(date: Date, shop: BookableStorageLocation): boolean {
    for (let i = 0; i < shop.openingHours.exceptions.length; i++) {
      const exception = shop.openingHours.exceptions[i];
      if (SharedUtilDate.isSameDate(exception.date, date)) {
        return true;
      }
    }
    return false;
  }

  hasHoliday(date: Date, shop: BookableStorageLocation): boolean {
    if (!shop.openingHours.holidays || shop.openingHours.holidays.length === 0) {
      return false;
    }
    for (let i = 0; i < shop.openingHours.holidays.length; i++) {
      const holiday = shop.openingHours.holidays[i].holiday;
      const instances = this.getHolidayInstances(holiday);
      for (let j = 0; j < instances.length; j++) {
        if (SharedUtilDate.isSameDate(instances[j], date)) {
          return true;
        }
      }
    }
    return false;
  }

  private getHolidayInstances(holiday: IHoliday): Date[] {
    return this.holidayService
      .getInstances(holiday.countryCode, holiday.key)
      .map((instance) => moment(instance, 'YYYY-MM-DD').toDate());
  }

  private deserializeProductLists(productLists: ProductListInfo[]): ProductListInfo[] {
    productLists.forEach(
      (p) => (p.productList = SharedUtilString.tryDecryptAndParseString(p.productList, Config.environment.CRYPTO_KEY)),
    );
    return productLists;
  }

  private deserializeShops(shops: BookableStorageLocation[]) {
    for (let i = 0; i < shops.length; i++) {
      shops[i] = this.deserializeShop(shops[i]);
    }
    return shops;
  }

  private deserializeShop(
    shop: BookableStorageLocation,
    shopProductList?: ProductList | EncryptedString,
  ): BookableStorageLocation {
    if (!shop.openingHours.exceptions) {
      shop.openingHours.exceptions = [];
    }

    // TODO: Remove if we start returning shops with the tags property instead of badges
    if (shop.tags === undefined && shop['badges'] !== undefined) {
      shop.tags = shop['badges'] as StorageLocationTag[];
      delete shop['badges'];
    }

    // Convert dates from strings to Date objects
    for (let i = 0; i < shop.openingHours.exceptions.length; i++) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const d: any = shop.openingHours.exceptions[i].date;
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      shop.openingHours.exceptions[i].date = SharedUtilDate.deserializeDate(d, false);
    }

    // Make sure exceptions are sorted by date
    shop.openingHours.exceptions.sort((a, b) => a.date.getTime() - b.date.getTime());
    // add shop to the shops object with the _id as key for fast lookup
    this.shops[shop._id] = shop;

    // HACK: Handles that not all shops have official location keys in the db
    if (!shop.officialLocationKey && shop.officialLocationName) {
      shop.officialLocationKey = shop.officialLocationName.toUpperCase().replace(/ /g, '_');
    }

    shop.address.street = SharedUtilString.tryDecryptString(shop.address.street, Config.environment.CRYPTO_KEY);
    shop.address.formattedAddress = SharedUtilString.tryDecryptString(
      shop.address.formattedAddress,
      Config.environment.CRYPTO_KEY,
    );
    shop.name = SharedUtilString.tryDecryptString(shop.name, Config.environment.CRYPTO_KEY);
    shop.mapsShortUrl = SharedUtilString.tryDecryptString(shop.mapsShortUrl, Config.environment.CRYPTO_KEY);
    shop.location.coordinates = SharedUtilString.tryDecryptAndParseString(
      shop.location.coordinates,
      Config.environment.CRYPTO_KEY,
    );

    if (shop.place && shop.place.id) {
      shop.place.id = SharedUtilString.tryDecryptString(shop.place.id, Config.environment.CRYPTO_KEY);
    }

    if (shop.phone) {
      shop.phone = SharedUtilString.tryDecryptString(shop.phone, Config.environment.CRYPTO_KEY);
    }

    if (!shopProductList && this.latestProductLists?.length > 0) {
      // Try to find the product list for the storage location by hash from the latest list response
      shopProductList = this.latestProductLists.find((pl) => pl.hash === shop.productListHash)?.productList;
    }

    if (shopProductList) {
      const decryptedProductList = SharedUtilString.tryDecryptAndParseString(
        shopProductList,
        Config.environment.CRYPTO_KEY,
      );

      // HACK: Build legacy pricing object from known product keys
      shop.pricing = {
        currency: decryptedProductList.currency,
        hourlyRate: this.walkIn
          ? decryptedProductList.products.find((p) => p.key === WALK_IN_HOURLY_RATE_PRODUCT_KEY)?.cost
          : decryptedProductList.products.find((p) => p.key === STORAGE_HOUR_PRODUCT_KEY)?.cost || 0,
        dailyRate: this.walkIn
          ? decryptedProductList.products.find((p) => p.key === WALK_IN_DAILY_RATE_PRODUCT_KEY)?.cost
          : decryptedProductList.products.find((p) => p.key === STORAGE_DAY_PRODUCT_KEY)?.cost || 0,
        firstDayMax: this.walkIn
          ? decryptedProductList.products.find((p) => p.key === WALK_IN_DAILY_RATE_PRODUCT_KEY)?.cost
          : decryptedProductList.products.find((p) => p.key === STORAGE_DAY_PRODUCT_KEY)?.cost || 0, // this is not being used anymore
        serviceFee: this.walkIn
          ? decryptedProductList.products.find((p) => p.key === WALK_IN_SERVICE_FEE_PRODUCT_KEY)?.cost
          : decryptedProductList.products.find((p) => p.key === SERVICE_FEE_PRODUCT_KEY)?.cost || 0,
        startupFee: this.walkIn
          ? decryptedProductList.products.find((p) => p.key === WALK_IN_INSURANCE_FEE_PRODUCT_KEY)?.cost
          : decryptedProductList.products.find((p) => p.key === INSURANCE_PRODUCT_KEY)?.cost || 0,
        insuranceCoverage: this.walkIn
          ? decryptedProductList.products.find((p) => p.key === WALK_IN_INSURANCE_FEE_PRODUCT_KEY)?.value
          : decryptedProductList.products.find((p) => p.key === INSURANCE_PRODUCT_KEY)?.value || 0,
        premiumInsuranceFee:
          decryptedProductList.products.find((p) => p.key === PREMIUM_INSURANCE_PRODUCT_KEY)?.cost || 0,
        premiumInsuranceCoverage:
          decryptedProductList.products.find((p) => p.key === PREMIUM_INSURANCE_PRODUCT_KEY)?.value || 0,
        freeCancellationFee:
          decryptedProductList.products.find((p) => p.key === FREE_CANCELLATION_FEE_PRODUCT_KEY)?.cost || 0,
        securitySeal: decryptedProductList.products.find((p) => p.key === SECURITY_SEAL_PRODUCT_KEY)?.cost || 0,
      };
    }

    // TODO: Remove this when completely migrated to new product pricing
    if (!shop.pricing && this.latestPrice) {
      const pricing =
        this.latestPrice.pricings.find((p) => p.location?.id === shop.officialLocationId) ||
        this.latestPrice.pricings[0];

      // Copy to avoid overriding pricing for other shops
      const shopPricing = Object.assign({}, pricing);

      if (shop.priceFactor >= 0) {
        // Multiply variable price components by the shop price factor
        shopPricing.hourlyRate = Math.round(shopPricing.hourlyRate * shop.priceFactor * 100) / 100;
        shopPricing.firstDayMax = Math.round(shopPricing.firstDayMax * shop.priceFactor * 100) / 100;
        shopPricing.dailyRate = Math.round(shopPricing.dailyRate * shop.priceFactor * 100) / 100;
      }

      shop.pricing = shopPricing;
    }

    if (typeof shop.noSecuritySeals !== 'boolean') {
      // Disable security seals by default
      shop.noSecuritySeals = true;
    }

    if (shop.directPaymentEnabled === undefined || shop.directPaymentEnabled === null) {
      // By default allow direct payments for all shops (when online payment fails)
      shop.directPaymentEnabled = true;
    }

    if (typeof shop.stats?.averageRating === 'number') {
      shop.stats.averageRating = +shop.stats.averageRating.toFixed(1);
    }

    shop.compulsoryBagPhoto = shop.compulsoryBagPhoto || shop.validateBagPhoto || false;
    shop.validateBagPhoto = shop.validateBagPhoto || false;

    return shop;
  }

  private buildGetStorageLocationsUrl(criteria: StorageCriteria, shopPaging: boolean): string {
    const url = new URL(this.apiEndPointV3);

    url.searchParams.append('from', SharedUtilDate.serializeDate(criteria.period.from));
    url.searchParams.append('to', SharedUtilDate.serializeDate(criteria.period.to));
    url.searchParams.append('normal', String(criteria.luggage.normal));
    url.searchParams.append('hand', String(criteria.luggage.hand));
    url.searchParams.append('lat', String(criteria.location.lat));
    url.searchParams.append('lon', String(criteria.location.lon));
    url.searchParams.append('region', String(criteria.location.region));
    url.searchParams.append('shopPaging', String(shopPaging));

    if (this.storageService.variant) {
      url.searchParams.append('variant', this.storageService.variant);
    }

    if (criteria.activeOnly) {
      url.searchParams.append('activeOnly', String(criteria.activeOnly));
    }

    return url.toString();
  }

  private updateLatestPrice(value: PriceInfo, region: string) {
    this.latestPrice = value;
    this.latestPrice.region = region;
    this.latestPriceChange.emit(this.latestPrice);
  }

  private logRequest(request: string, count = 0, data?: unknown) {
    let info: string;
    switch (request) {
      case 'findNearBy': {
        const criteria = data as StorageCriteria;
        info = criteria.toString();
        break;
      }
    }
    void this.log.info(`${request} #${count}: ${info}`);
  }
}
