import { ChangeDetectionStrategy, Component } from '@angular/core';
import { TipStepBaseComponent } from '@luggagehero/features/shops/base/dropoff/steps/tip-step.base-component';

@Component({
  selector: 'lh-tip-step',
  templateUrl: './tip-step.component.html',
  styleUrls: ['./tip-step.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class TipStepComponent extends TipStepBaseComponent {}
