import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { AppConfig } from '@luggagehero/shared/app-settings/data-access';
import { Booking, IUser, Review, SatisfactionScore } from '@luggagehero/shared/interfaces';
import { SharedIntercomService } from '@luggagehero/shared/services/intercom';
import { SharedReviewsService } from '@luggagehero/shared/services/reviews';
import { SharedUserService } from '@luggagehero/shared/services/users';

import { BaseComponent } from '../../../core';
import { SurveyService } from '../../../services/index';
import { EXTERNAL_REVIEW_PLATFORMS } from '../../../utils/url.validators';

const noop = () => {
  /**/
};

export const createReview = (): Review => {
  return {} as Review;
};

@Component({ template: '' })
export abstract class ReviewBaseComponent extends BaseComponent implements ControlValueAccessor {
  @Output() done: EventEmitter<unknown>;
  feedback = '';

  private _value: Review = createReview();
  private _externalReviewUrl: string;
  private _rating: number;
  private _booking: Booking;
  private _isLoading = false;
  private _isEditing = false;
  private _reviewRequested = false;
  private _user: IUser = {};
  public showInAppReview = false;
  public reviewLoaded = false;

  private onTouchedCallback: () => void = noop;
  private onChangeCallback: (_) => void = noop;

  constructor(
    protected cd: ChangeDetectorRef,
    private reviewsService: SharedReviewsService,
    protected userService: SharedUserService,
    private surveyService: SurveyService,
    private intercomService: SharedIntercomService,
  ) {
    super();
    this.done = new EventEmitter<unknown>();

    this.surveyService.init();
  }

  set value(value: Review) {
    if (!value || value === this._value) {
      return;
    }
    this._value = value;

    this.onChangeCallback(value);
    this.cd.markForCheck();
    this.reviewLoaded = true;
  }
  get value(): Review {
    return this._value;
  }

  protected get user(): IUser {
    return this._user;
  }

  @Input() set booking(booking: Booking) {
    if (!booking || booking === this._booking) {
      return;
    }
    this._booking = booking;

    // create the user object once as it's used multiple places. Create a new from the booking if user not logged in
    this._user = this.userService.user
      ? this.userService.user
      : { email: this.booking.userEmail, name: this.booking.userName, picture: this.booking.userPicture };

    void this.reviewsService.getReview(booking._id).then((review) => {
      if (review) {
        console.log(`Review found for booking ${booking._id}`, {
          review,
          booking,
        });
        // Existing review
        this.value = review;
        this.rating = review.rating;
        this.isEditing = review.rating && !review.published;
      } else {
        // New review
        const newReview = createReview();
        newReview.bookingId = this.booking._id;
        newReview.shopId = this.booking.shopId;
        newReview.userName = this._user.name;
        newReview.userPicture = this._user.picture || undefined;
        newReview.shopName = this.booking.shopName;
        newReview.published = false;

        console.log(`No review found for booking ${booking._id}`, {
          booking,
          newReview,
        });

        this.value = newReview;
      }
      this.markForCheck();
    });
  }
  get booking(): Booking {
    return this._booking;
  }

  @Input() set externalReviewUrl(value: string) {
    this._externalReviewUrl = value;
  }
  get externalReviewUrl(): string {
    return this._externalReviewUrl;
  }

  get isLoading(): boolean {
    return this._isLoading;
  }
  set isLoading(value: boolean) {
    this._isLoading = value;
    this.cd.markForCheck();
  }

  get isEditing(): boolean {
    return this._isEditing;
  }
  set isEditing(value: boolean) {
    this._isEditing = value;
    this.markForCheck();
  }

  get reviewRequested(): boolean {
    return this._reviewRequested;
  }
  set reviewRequested(value: boolean) {
    this._reviewRequested = value;
    this.markForCheck();
  }

  get rating(): number {
    return this._rating;
  }
  set rating(value: number) {
    this._rating = value;
    this.markForCheck();

    if (!this.value.published) {
      void this.save();
    }
  }

  get showEditableReview(): boolean {
    if (this.value.satisfaction === undefined) {
      return false;
    }
    if (this.value.published && !this.isEditing) {
      return false;
    }
    if (this.value.satisfaction === 0 && !this.rating && !this.reviewRequested) {
      return false;
    }

    if (this.showInAppReview) {
      return false;
    }

    return true;
  }

  get showReadOnlyReview(): boolean {
    return this.value.published && !this.isEditing;
  }

  get showFeedbackForm() {
    if (this.value.satisfaction === 0 && !this.value.feedback) {
      return true;
    }
    // if (this.value.published && !this.value.feedback) {
    //   return true;
    // }
    return false;
  }

  get showReviewButton(): boolean {
    return this.value.feedback && !this.value.rating && !this.reviewRequested;
  }

  get showSatisfactionOptions(): boolean {
    if (!this.value) {
      return false;
    }

    if (this.value && this.value.satisfaction === undefined) {
      return true;
    }

    return !(this.value.satisfaction >= 0);
  }

  get showExternalReview(): boolean {
    if (AppConfig.IS_EXTERNAL_REVIEW_DISABLED) {
      return false;
    }
    if (!this.externalReviewUrl) {
      return false;
    }
    if (this.value.satisfaction === undefined) {
      return false;
    }
    return this.value.satisfaction >= 1;
  }

  get externalReviewPlatform(): string {
    if (!this.externalReviewUrl) {
      return null;
    }
    for (let i = 0; i < EXTERNAL_REVIEW_PLATFORMS.length; i++) {
      const platform = EXTERNAL_REVIEW_PLATFORMS[i];
      if (platform.urlPattern.test(this.externalReviewUrl)) {
        return platform.providerName;
      }
    }
    return null;
  }

  writeValue(value: Review) {
    if (value !== undefined) {
      this.value = value;
    }
  }

  registerOnChange(fn: (_) => void) {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: () => void) {
    this.onTouchedCallback = fn;
  }

  setSatisfaction(value: SatisfactionScore) {
    this.value.satisfaction = value;

    void this.save();

    if (value === 0) {
      this.intercomService.show();
      this.done.emit();
    }
  }

  publishReview() {
    if (!this.value.published) {
      // This is a new review being published
      const properties = {
        shopCountry: this.booking.address.countryCode,
        shopName: this.booking.shopName,
        numberOfBags: this.booking.luggage.normal,
        userType: this.userService.user ? this.userService.user.source : 'n/a',
        revenueAmount: this.booking.price.final.total,
      };
      this.surveyService.showSurvey(this._user, properties);
    }
    // Set review as published and save
    this.value.published = true;
    void this.save();

    // this.done.next();
  }

  async save() {
    // if (!this.rating && this.value.satisfaction > 1) {
    //   // Prefill rating to 5 start when people respond "Great"
    //   this.rating = 5;
    // }

    if (this.rating > 0) {
      this.value.rating = this.rating;
    }

    this.value = await (this.value._id
      ? this.reviewsService.updateReview(this.value)
      : this.reviewsService.addReview(this.value));

    this.isEditing = false;
  }

  startEditing() {
    this.isEditing = true;
  }

  requestReview() {
    this.reviewRequested = true;
  }

  async submitFeedback() {
    this.value.feedback = this.feedback;
    this.value = await this.reviewsService.updateReview(this.value);
    // this.done.next();
  }

  private markForCheck() {
    // HACK: Ensures that star rating component is properly updated
    setTimeout(() => this.cd.markForCheck(), 100);
  }
}
