import { Component, inject, OnInit } from '@angular/core';
import { AppConfig } from '@luggagehero/shared/app-settings/data-access';
import { Booking } from '@luggagehero/shared/interfaces';

import { RouterExtensions } from '../../../../../core/services/index';
import { PhotoStepBaseComponent } from './photo-step.base-component';

@Component({ template: '' })
export abstract class ConfirmationStepBaseComponent extends PhotoStepBaseComponent implements OnInit {
  public booking: Booking;

  private router = inject(RouterExtensions);

  constructor() {
    super();

    //
    // Keep a reference to the booking object locally in the component because the booking service invalidates its
    // cached objects after a while; in case the user stays on the confirmation step for a long time (e.g. pockets the
    // phone with the tab still open on this view), we don't want to lose the booking object which will break the UI.
    //
    this.booking = this.bookingService.currentBooking;
  }

  public get numberOfBags(): number {
    return this.booking.luggage.normal + this.booking.luggage.hand;
  }

  public get isLuggageImageEnabled(): boolean {
    return AppConfig.IS_LUGGAGE_IMAGE_ON_DROPOFF_CONFIRMATION_STEP_ENABLED;
  }

  public get isStorageAccessRequired(): boolean {
    if (!this.storageLocation) {
      return false;
    }
    return this.storageLocation.storageRoomAccessCode || this.storageLocation.storageRoomAccessText ? true : false;
  }

  public get isSecuritySealInfoVisible(): boolean {
    if (this.isStorageAccessRequired) {
      return false;
    }
    if (!this.securitySeals || this.securitySeals.length === 0) {
      return false;
    }
    return true;
  }

  protected get useDefaultNavigation(): boolean {
    return false;
  }

  public async goToBooking() {
    await this.router.navigate(['/bookings', this.booking._id], { queryParams: { utm_sorce: 'dropoff_confirmation' } });
  }

  protected showExample() {
    // Do nothing
  }
}
