import { inject, Injectable } from '@angular/core';
import { AppConfig } from '@luggagehero/shared/app-settings/data-access';
import {
  Booking,
  BookingAddOns,
  DISCOUNT_TRANSFORMER_SERVICE,
  DiscountTransformerService,
  IDiscount,
  ILuggage,
  INSURANCE_PRODUCT_KEY,
  IPricing,
  ITimePeriod,
  LegacyOrder,
  LegacyOrderLine,
  PRICING_SERVICE,
  PricingModel,
  PricingService,
} from '@luggagehero/shared/interfaces';
import { SharedPromoCodeService } from '@luggagehero/shared/services/promo-codes';
import { SharedUtilDate, SharedUtilList } from '@luggagehero/shared/util';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';

/** @deprecated Use `SharedOrderService` instead */
@Injectable()
export class LegacyOrderService {
  protected discountTransformerService = inject<DiscountTransformerService>(DISCOUNT_TRANSFORMER_SERVICE);
  private pricingService = inject<PricingService>(PRICING_SERVICE);

  constructor(
    private promoCodeService: SharedPromoCodeService,
    private translateService: TranslateService,
  ) {}

  /** @deprecated Use equivalent function from `SharedOrderService` instead */
  public async getByBooking(booking: Booking): Promise<LegacyOrder[]> {
    if (booking.price.discountCode && booking.price.discountCode !== this.promoCodeService.checkedPromoCode) {
      await this.promoCodeService.checkPromoCode(booking.price.discountCode);
    }

    if (['CHECKED_OUT', 'PAID'].includes(booking.status)) {
      // Booking is completed so the order needs to reflect the final price which was charged (or attempted charged)
      return [await this.generateReceipt(booking)];
    }

    // Booking is not completed, so we need to create an order based on current booking properties
    const isFinal = booking.price.pricingModel === 'daily';
    const timeToAdd = booking.price.pricingModel === 'daily' ? booking.metadata.lh_storage_days || 1 : 1;
    const timeUnit = booking.price.pricingModel === 'daily' ? 'days' : 'hours';
    const estimatedCheckout = moment(booking.period.checkIn).add(timeToAdd, timeUnit);
    const period: ITimePeriod = { from: booking.period.checkIn, to: estimatedCheckout.toDate() };

    const quote = await this.generateQuote(
      booking.price.pricing,
      booking.price.pricingModel,
      booking.luggage,
      period,
      booking.price.addOns,
      isFinal,
    );
    quote._id = `order_${booking._id}`;

    return [quote];
  }

  /**
   * Genenerates an order which is final to be used for receipts on completed bookings.
   *
   * @deprecated Use equivalent function from `SharedOrderService` instead
   */
  private generateReceipt(booking: Booking): Promise<LegacyOrder> {
    const storageLine = this.createStorageLine(booking);
    const multiDayDiscountLine = this.createMultiDayDiscountLine(booking);
    const serviceFeeLine = this.createServiceFeeLine(booking);
    const insuranceLine = this.createInsuranceLine(booking);
    const freeCancellationLine = this.createFreeCancellationLine(booking);
    const securitySealLine = this.createSecuritySealLine(booking);
    const tipLine = this.createTipLine(booking);
    const promoCodeLine = this.createPromoCodeLine(booking);

    const orderLines: LegacyOrderLine[] = [];
    SharedUtilList.appendIfNotNullOrUndefined(orderLines, storageLine);
    SharedUtilList.appendIfNotNullOrUndefined(orderLines, multiDayDiscountLine);
    SharedUtilList.appendIfNotNullOrUndefined(orderLines, serviceFeeLine);
    SharedUtilList.appendIfNotNullOrUndefined(orderLines, insuranceLine);
    SharedUtilList.appendIfNotNullOrUndefined(orderLines, freeCancellationLine);
    SharedUtilList.appendIfNotNullOrUndefined(orderLines, securitySealLine);
    SharedUtilList.appendIfNotNullOrUndefined(orderLines, tipLine);
    SharedUtilList.appendIfNotNullOrUndefined(orderLines, promoCodeLine);

    const now = new Date();

    const order: LegacyOrder = {
      // Create a dummy order ID; using booking ID for receipts
      _id: `order_${booking._id}`,
      orderRequest: null,
      pricingModel: booking.price.pricingModel,
      bags: booking.luggage.hand + booking.luggage.normal,
      currency: booking.price.final.currency,
      total: booking.price.final.total,
      orderLines,
      createdAt: now,
      updatedAt: now,
    };

    const calculatedTotal = this.calculateTotal(order.orderLines);

    if (order.total !== calculatedTotal) {
      console.warn(`Order lines don't match order total`, {
        bokingId: booking._id,
        calculatedTotal,
        order,
      });
    }

    return Promise.resolve(order);
  }

  /**
   * Generates a preliminary order based on the specified pricing and booking paramters. Used in order summary views
   * before creating a booking and for bookings that are not yet completed.
   *
   * @deprecated Use equivalent function from `SharedOrderService` instead
   */
  private generateQuote(
    pricing: IPricing,
    pricingModel: PricingModel,
    luggage: ILuggage,
    period: ITimePeriod,
    addOns: BookingAddOns,
    isFinal: boolean,
  ): Promise<LegacyOrder> {
    const numberOfBags = luggage.hand + luggage.normal;

    if (!isFinal) {
      // When order is not final, show pricing for 1 hour or 1 day
      period = {
        from: period.from,
        to: moment(period.from)
          .add(1, pricingModel === 'daily' ? 'day' : 'hour')
          .toDate(),
      };
    }

    const storageLine = this.createStorageLine(pricing, pricingModel, numberOfBags, period, isFinal);
    const multiDayDiscountLine = this.createMultiDayDiscountLine(pricing, pricingModel, numberOfBags, period);
    const serviceFeeLine = this.createServiceFeeLine(pricing, numberOfBags);
    const insuranceLine = this.createInsuranceLine(pricing, numberOfBags, addOns);
    const freeCancellationLine = this.createFreeCancellationLine(pricing, numberOfBags, addOns);
    const securitySealLine = this.createSecuritySealLine(pricing, addOns);
    const promoCodeLine = this.createPromoCodeLine(pricing, pricingModel, numberOfBags, period);

    const orderLines: LegacyOrderLine[] = [];
    SharedUtilList.appendIfNotNullOrUndefined(orderLines, storageLine);
    SharedUtilList.appendIfNotNullOrUndefined(orderLines, multiDayDiscountLine);
    SharedUtilList.appendIfNotNullOrUndefined(orderLines, serviceFeeLine);
    SharedUtilList.appendIfNotNullOrUndefined(orderLines, insuranceLine);
    SharedUtilList.appendIfNotNullOrUndefined(orderLines, freeCancellationLine);
    SharedUtilList.appendIfNotNullOrUndefined(orderLines, securitySealLine);
    SharedUtilList.appendIfNotNullOrUndefined(orderLines, promoCodeLine);

    const now = new Date();

    const quote: LegacyOrder = {
      // Create a dummy order ID; use Date.now() to be able to tell if an order has changed
      _id: `order_${Date.now()}`,
      orderRequest: null,
      pricingModel,
      bags: numberOfBags,
      currency: pricing.currency,
      orderLines,
      createdAt: now,
      updatedAt: now,
    };

    return Promise.resolve(quote);
  }

  /** @deprecated Use equivalent function from `SharedOrderService` instead */
  public calculateTotal(orderLines: LegacyOrderLine[]): number {
    let total = 0;

    for (const line of orderLines) {
      if (line.isPricing) {
        continue; // Skip pricing lines as they don't reflect a final price
      }

      if (line.optional && !line.selected) {
        continue; // Skip optional lines that are not selected
      }

      const quantity = typeof line.quantity === 'number' ? line.quantity : 1;
      total += quantity * line.unitPrice;
    }
    return total;
  }

  private createStorageLine(booking: Booking): LegacyOrderLine;
  private createStorageLine(
    pricing: IPricing,
    pricingModel: PricingModel,
    numberOfBags: number,
    period: ITimePeriod,
    isFinal: boolean,
  ): LegacyOrderLine;
  private createStorageLine(
    pricingOrBooking: IPricing | Booking,
    pricingModel?: PricingModel,
    numberOfBags?: number,
    period?: ITimePeriod,
    isFinal?: boolean,
  ): LegacyOrderLine {
    const booking = pricingOrBooking as Booking;
    const pricing = booking.price ? booking.price.pricing : (pricingOrBooking as IPricing);

    let timeUnits: number;
    let unitPrice: number;

    if (booking.price) {
      pricingModel = booking.price.pricingModel;
      numberOfBags = booking.luggage.hand + booking.luggage.normal;

      timeUnits = this.getBillableTimeUnits(booking);

      isFinal = ['CHECKED_OUT', 'PAID'].includes(booking.status);

      let storageCost = booking.price.final.storage;
      if (this.isEligibleForMultiDayDiscount(booking)) {
        // Calculate storage as if paying first day rate for all days to accommodate multi-day discount line
        const firstDayRate = pricing.firstDayMax - pricing.startupFee;
        storageCost = firstDayRate * numberOfBags * timeUnits;
      }
      // Retro-fit unit price to match what was charged for storage divided by the number of bags
      unitPrice = storageCost / numberOfBags;
    } else {
      timeUnits = this.getTimeUnits(pricingModel, period);

      const firstDayRate = pricing.firstDayMax - pricing.startupFee;
      unitPrice = pricingModel === 'daily' ? firstDayRate * timeUnits : pricing.hourlyRate * timeUnits;
    }

    const pricingModelLabel = this.translate(pricingModel === 'daily' ? 'PAY_UP_FRONT' : 'PAY_AS_YOU_GO').toLowerCase();
    const storageLabel = this.translate('PRICE_STORAGE');
    const daysLabel = this.translate(timeUnits === 1 ? 'DAY' : 'DAYS').toLowerCase();
    const hoursLabel = this.translate(timeUnits === 1 ? 'HOUR' : 'HOURS').toLowerCase();

    const periodLabel = `${timeUnits} ${pricingModel === 'daily' ? daysLabel : hoursLabel}`;

    const storageLine: LegacyOrderLine = {
      product: {
        name: `${storageLabel}, ${isFinal ? periodLabel : pricingModelLabel}`,
        unitSingular: 'BAG',
        unitPlural: 'BAGS',
      },
      unitPrice,
      quantity: numberOfBags,
      showQuantity: true,
      isPricing: !isFinal,
      pricingSuffix: `/${this.pricingService.formatPricingModel(pricingModel)}`,
    };

    return storageLine;
  }

  private createServiceFeeLine(booking: Booking): LegacyOrderLine;
  private createServiceFeeLine(pricing: IPricing, numberOfBags: number): LegacyOrderLine;
  private createServiceFeeLine(pricingOrBooking: IPricing | Booking, numberOfBags?: number): LegacyOrderLine {
    const booking = pricingOrBooking as Booking;
    let pricing = pricingOrBooking as IPricing;

    let unitPrice: number;

    if (booking.price) {
      pricing = booking.price.pricing;
      numberOfBags = booking.luggage.hand + booking.luggage.normal;

      // Retro-fit unit price to match what was charged in service fee divided by the number of bags
      unitPrice = booking.price.final.serviceFee / numberOfBags;
    } else {
      unitPrice = pricing.serviceFee;
    }

    const isServiceFeeApplied = unitPrice > 0;

    if (!isServiceFeeApplied) {
      return null;
    }

    const serviceFeeText = this.translate('PRICE_SERVICE_FEE');

    // const bagGuarantee = this.pricePipe.transform(pricing.bagGuarantee, pricing.currency, 2, true);
    // const serviceFeeExplanation = `${this.translate('PRICE_SERVICE_FEE_EXPLANATION')} ${bagGuarantee}`;
    const serviceFeeExplanation = this.translate('PRICE_SERVICE_FEE_EXPLANATION_SHORT');

    const serviceFeeLine: LegacyOrderLine = {
      product: {
        name: serviceFeeText,
        info: {
          header: serviceFeeText,
          body: serviceFeeExplanation,
        },
      },
      unitPrice,
      quantity: numberOfBags,
      isPricing: false,
    };

    return serviceFeeLine;
  }

  private createMultiDayDiscountLine(booking: Booking): LegacyOrderLine;
  private createMultiDayDiscountLine(
    pricing: IPricing,
    pricingModel: PricingModel,
    numberOfBags: number,
    period: ITimePeriod,
  ): LegacyOrderLine;
  private createMultiDayDiscountLine(
    pricingOrBooking: IPricing | Booking,
    pricingModel?: PricingModel,
    numberOfBags?: number,
    period?: ITimePeriod,
  ): LegacyOrderLine {
    const booking = pricingOrBooking as Booking;
    const pricing = booking.price ? booking.price.pricing : (pricingOrBooking as IPricing);

    const isEligibleForMultiDayDiscount = booking.price
      ? this.isEligibleForMultiDayDiscount(booking)
      : this.isEligibleForMultiDayDiscount(pricingModel, period);

    if (!isEligibleForMultiDayDiscount) {
      return;
    }

    let totalAdditionalDayDiscount: number;
    let productDetail: string;

    if (booking.price) {
      pricingModel = booking.price.pricingModel;
      numberOfBags = booking.luggage.hand + booking.luggage.normal;

      const timeUnits = this.getBillableTimeUnits(booking);

      const firstDayRate = pricing.firstDayMax - pricing.startupFee;
      const storageTotalWithFullPrice = firstDayRate * numberOfBags * timeUnits;
      const savings = storageTotalWithFullPrice - booking.price.final.storage;
      totalAdditionalDayDiscount = -Math.max(0, savings);
    } else {
      const timeUnits = this.getTimeUnits(pricingModel, period);
      const toDeductFromDaily = pricing.startupFee;
      const dailyUnitPrice = pricing.firstDayMax - toDeductFromDaily;
      const additionalDayDiscount = -(dailyUnitPrice - pricing.dailyRate);
      totalAdditionalDayDiscount = additionalDayDiscount * numberOfBags * (timeUnits - 1);

      const savings = Math.round(-(additionalDayDiscount / dailyUnitPrice) * 100);
      const textPart1 = this.translate('YOU_ARE_SAVING_X_ON_ADDITIONAL_DAYS_PART_1');
      const textPart2 = this.translate('YOU_ARE_SAVING_X_ON_ADDITIONAL_DAYS_PART_2');
      productDetail = `${textPart1} ${savings}% ${textPart2}`;
    }

    if (!totalAdditionalDayDiscount) {
      return null;
    }

    const multiDayDiscountLine: LegacyOrderLine = {
      product: {
        name: this.translate('MULTI_DAY_DISCOUNT'),
      },
      unitPrice: totalAdditionalDayDiscount,
      quantity: 1,
      isPricing: false,
    };
    if (productDetail) {
      multiDayDiscountLine.product.info = { body: productDetail };
    }
    return multiDayDiscountLine;
  }
  private createInsuranceLine(pricing: Booking): LegacyOrderLine;
  private createInsuranceLine(pricing: IPricing, numberOfBags: number, addOns: BookingAddOns): LegacyOrderLine;
  private createInsuranceLine(
    pricingOrBooking: IPricing | Booking,
    numberOfBags?: number,
    addOns?: BookingAddOns,
  ): LegacyOrderLine {
    const booking = pricingOrBooking as Booking;
    let pricing = pricingOrBooking as IPricing;

    const insuranceLabel = this.translate('INSURANCE');

    let unitPrice: number;
    let selected: boolean;
    let productName: string;
    let productBody: string;

    if (booking.price) {
      pricing = booking.price.pricing;
      numberOfBags = booking.luggage.hand + booking.luggage.normal;

      if (booking.price.final.startupFee > 0) {
        // Retro-fit unit price to match what was charged for insurance (startup fee) divided by the number of bags
        unitPrice = booking.price.final.startupFee / numberOfBags;
        selected = true;
      } else {
        unitPrice = pricing.startupFee;
        selected = false;
      }

      productName = insuranceLabel;
    } else {
      unitPrice = pricing.startupFee;
      selected = addOns.insurance;

      const coverageAmount = this.pricingService.format(pricing.insuranceCoverage, pricing.currency, 2, true);
      let coverageLabel: string;

      if (AppConfig.IS_ORDER_SUMMARY_INLINE_INSURANCE_COVERAGE_ENABLED) {
        coverageLabel = AppConfig.IS_ORDER_SUMMARY_SIMPLE_INSURANCE_COVERAGE_ENABLED
          ? `${this.translate('COVERAGE')}/${this.translate('BAG').toLowerCase()}`
          : `${this.translate('COVERAGE')} ${this.translate('PER_BAG')}`;
        productName = `${insuranceLabel} (${coverageAmount} ${coverageLabel})`.trim();
      } else {
        coverageLabel = `${this.translate('COVERAGE')} ${this.translate('PER_BAG')}`;
        productName = insuranceLabel;
        productBody = `${coverageAmount} ${coverageLabel}`;
      }
    }

    const insuranceLine: LegacyOrderLine = {
      product: {
        _id: INSURANCE_PRODUCT_KEY,
        name: productName,
      },
      unitPrice,
      quantity: numberOfBags,
      optional: true,
      selected,
      isPricing: false,
    };

    if (productBody) {
      insuranceLine.product.info = {
        header: this.translate('INSURANCE'),
        body: productBody,
      };
    }

    return insuranceLine;
  }

  private createSecuritySealLine(booking: Booking): LegacyOrderLine;
  private createSecuritySealLine(pricing: IPricing, addOns: BookingAddOns): LegacyOrderLine;
  private createSecuritySealLine(pricingOrBooking: IPricing | Booking, addOns?: BookingAddOns): LegacyOrderLine {
    const booking = pricingOrBooking as Booking;
    let pricing = pricingOrBooking as IPricing;

    let unitPrice: number;
    let quantity: number;

    if (booking.price) {
      pricing = booking.price.pricing;
      addOns = booking.price.addOns;

      // Retro-fit unit price to match what was charged for security seals divided by the number of bags
      unitPrice = booking.price.final.securitySealFee
        ? booking.price.final.securitySealFee / addOns.securitySeals
        : pricing.securitySeal;

      quantity = booking.price.final.securitySealFee ? addOns.securitySeals : 0;
    } else {
      unitPrice = pricing.securitySeal;
      quantity = addOns.securitySeals || 0;
    }

    const hasSecuritySealsAdded = quantity > 0;

    if (!hasSecuritySealsAdded) {
      return null;
    }

    const securitySealLine: LegacyOrderLine = {
      product: {
        name: this.translate('SECURITY_SEAL_ORDER_DESCRIPTION'),
        info: {
          body: this.translate('SECURITY_SEAL_ORDER_DETAIL'),
        },
      },
      unitPrice,
      quantity,
      showQuantity: true,
      optional: true,
      selected: true,
      isPricing: false,
    };

    return securitySealLine;
  }

  private createFreeCancellationLine(booking: Booking): LegacyOrderLine;
  private createFreeCancellationLine(pricing: IPricing, numberOfBags: number, addOns: BookingAddOns): LegacyOrderLine;
  private createFreeCancellationLine(
    pricingOrBooking: IPricing | Booking,
    numberOfBags?: number,
    addOns?: BookingAddOns,
  ): LegacyOrderLine {
    const booking = pricingOrBooking as Booking;
    let pricing = pricingOrBooking as IPricing;

    let _unitPrice: number;
    let isFreeCancellationAdded: boolean;

    if (booking.price) {
      pricing = booking.price.pricing;
      numberOfBags = booking.luggage.hand + booking.luggage.normal;
      addOns = booking.price.addOns;

      // TODO: Show as disabled when not selected
      isFreeCancellationAdded = booking.price.final.freeCancellationFee > 0;

      // Retro-fit unit price to match what was charged for free cancellation by the number of bags
      _unitPrice = booking.price.final.freeCancellationFee / numberOfBags;
    } else {
      isFreeCancellationAdded = addOns.freeCancellation;
    }

    if (!isFreeCancellationAdded) {
      return null;
    }

    const freeCancellationFeeLine: LegacyOrderLine = {
      product: {
        name: this.translate('FREE_CANCELLATION'),
      },
      unitPrice: pricing.freeCancellationFee,
      quantity: numberOfBags,
      optional: true,
      selected: true,
      isPricing: false,
    };

    return freeCancellationFeeLine;
  }

  private createPromoCodeLine(booking: Booking): LegacyOrderLine;
  private createPromoCodeLine(
    pricing: IPricing,
    pricingModel: PricingModel,
    numberOfBags: number,
    period: ITimePeriod,
  ): LegacyOrderLine;
  private createPromoCodeLine(
    pricingOrBooking: IPricing | Booking,
    pricingModel?: PricingModel,
    numberOfBags?: number,
    period?: ITimePeriod,
  ): LegacyOrderLine {
    const booking = pricingOrBooking as Booking;
    const pricing = booking.price ? booking.price.pricing : (pricingOrBooking as IPricing);

    // TODO: Should applied discount win in some cases if we have both?
    const discount = this.promoCodeService.checkedDiscount || this.promoCodeService.appliedDiscount;

    let unitPrice: number;

    if (booking.price) {
      numberOfBags = booking.luggage.hand + booking.luggage.normal;
      unitPrice = -(booking.price.final.discount / numberOfBags);
    } else if (discount) {
      const discountValue = this.getDiscountValue(pricing, pricingModel, discount, numberOfBags, period);
      unitPrice = -(discountValue / numberOfBags);
    } else {
      unitPrice = 0;
    }

    if (!unitPrice) {
      // No discount
      return null;
    }

    const discountLine: LegacyOrderLine = {
      product: {
        name: 'PRICE_DISCOUNT',
      },
      unitPrice,
      quantity: numberOfBags,
      isPricing: false,
    };

    if (discount) {
      discountLine.product.info = {
        header: discount.code,
        body: this.discountTransformerService.transform(discount, numberOfBags, false),
      };

      if (pricingModel === 'hourly' && discount.type === 'percentage') {
        discountLine.isRelative = true;
        discountLine.isPricing = true;
      }

      if (discount.type === 'percentage') {
        discountLine.pricingSuffix = '%';
      }

      switch (discount.code) {
        case AppConfig.DEFAULT_AUTO_GROUP_DISCOUNT_CODE:
          discountLine.product.info.body += `. ${this.translate('GROUP_DISCOUNT_APPLIES')}.`;
          break;
      }
    }

    return discountLine;
  }

  private getDiscountValue(
    pricing: IPricing,
    pricingModel: PricingModel,
    discount: IDiscount,
    numberOfBags: number,
    period: ITimePeriod,
  ): number {
    const timeUnits = this.getTimeUnits(pricingModel, period);
    let discountValue = 0;
    const maxBagsForDiscount = discount.maxBags ? discount.maxBags : numberOfBags;

    switch (discount.type) {
      case 'absolute':
        discountValue = discount.value;
        break;

      case 'hours':
        discountValue = discount.value * pricing.hourlyRate * Math.min(maxBagsForDiscount, numberOfBags);

        break;

      case 'percentage':
        if (pricingModel === 'daily') {
          const firsDayStoragePricePerBag = pricing.firstDayMax - pricing.startupFee;
          const followingDaysStoragePricePerBag = (timeUnits - 1) * pricing.dailyRate;
          const storagePricePerBag = firsDayStoragePricePerBag + followingDaysStoragePricePerBag;

          discountValue = (discount.value / 100) * storagePricePerBag * Math.min(maxBagsForDiscount, numberOfBags);
        } else {
          discountValue = discount.value;
        }
        break;

      default:
        // Ignore
        break;
    }

    return discountValue;
  }

  private createTipLine(booking: Booking): LegacyOrderLine {
    const isTipAdded = booking.price.final.tip > 0;

    if (!isTipAdded) {
      return null;
    }

    const tipLine: LegacyOrderLine = {
      product: {
        name: this.translate('PRICE_TIP'),
      },
      unitPrice: booking.price.final.tip,
      quantity: 1,
      isPricing: false,
    };

    return tipLine;
  }

  private isEligibleForMultiDayDiscount(booking: Booking): boolean;
  private isEligibleForMultiDayDiscount(pricingModel: PricingModel, period: ITimePeriod): boolean;
  private isEligibleForMultiDayDiscount(pricingModelOrBooking: PricingModel | Booking, period?: ITimePeriod): boolean {
    const booking = pricingModelOrBooking as Booking;
    const pricingModel = booking.price ? booking.price.pricingModel : (pricingModelOrBooking as PricingModel);

    if (pricingModel === 'hourly') {
      return false;
    }

    const storageDays = booking.price ? this.getBillableTimeUnits(booking) : this.getTimeUnits(pricingModel, period);

    return storageDays > 1;
  }

  private getBillableTimeUnits(booking: Booking): number {
    const pricingModel = booking.price.pricingModel;
    const period = { from: booking.period.checkIn, to: booking.period.checkOut };

    // Calculate time units based on booking period
    let billableTimeUnits = this.getTimeUnits(pricingModel, period);

    if (pricingModel === 'daily' && (booking.metadata.lh_storage_days as number) > billableTimeUnits) {
      if (booking.price.final) {
        // Deduce number of storage days based on price final as that indicates what was actually charged on the backend
        const numberOfBags = booking.luggage.normal || 1 + booking.luggage.hand || 0;
        const firstDayRate = (booking.price.pricing.firstDayMax - booking.price.pricing.startupFee) * numberOfBags;
        const additionalDayRate = booking.price.pricing.dailyRate * numberOfBags;
        const additionalDaytotal = Math.floor(booking.price.final.storage - firstDayRate);
        const numberOfAdditionalDays = additionalDaytotal > 0 ? additionalDaytotal / additionalDayRate : 0;
        billableTimeUnits = 1 + Math.ceil(numberOfAdditionalDays);
      } else {
        // Use the number of days selected when booking was created as it's greater than the actual number of days stored
        billableTimeUnits = booking.metadata.lh_storage_days as number;
      }
    }

    return billableTimeUnits;
  }

  private getTimeUnits(pricingModel: PricingModel, period: ITimePeriod): number {
    return pricingModel === 'daily'
      ? Math.max(SharedUtilDate.daysBetween(period.from, period.to), 1)
      : Math.max(SharedUtilDate.hoursBetween(period.from, period.to), 1);
  }

  private translate(key: string): string {
    return this.translateService.instant(key) as string;
  }
}
