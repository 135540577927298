import { Component, Input } from '@angular/core';
import { Review } from '@luggagehero/shared/interfaces';

import { BaseComponent } from '../../../core';
import { StringUtil } from '../../../utils/string.util';

@Component({ template: '' })
export class PublicReviewBaseComponent extends BaseComponent {
  public userName: string;
  public body: string;
  public luggage: string;
  public isRated: boolean;
  public isRecommended: boolean;

  private _review: Review;
  private _storageLocationId: string;

  public get review(): Review {
    return this._review;
  }
  @Input() public set review(value: Review) {
    this._review = value;
    this.update();
  }

  public get storageLocationId(): string {
    return this._storageLocationId;
  }
  @Input() public set storageLocationId(value: string) {
    this._storageLocationId = value;
    this.update();
  }

  private update() {
    if (!this.review) {
      return;
    }

    this.userName = this.getUserName();
    this.body = this.getBody();
    this.luggage = this.getLuggage();
    this.isRated = this.getIsRated();
    this.isRecommended = this.getIsRecommended();

    this._markForCheck();
  }

  private getUserName(): string {
    let name = this._translate('TRAVELER');

    try {
      if (this.review.userName && !this.review.userName.match(/guest|traveler/i)) {
        name = this.review.userName;
      }

      const nameParts = name.split(' ');
      if (nameParts.length > 1) {
        // Take the first name and the first letter of the last name
        name = `${nameParts[0]} ${nameParts[1].charAt(0)}`;
      }
    } catch {
      // Do nothing
    }

    return name;
  }

  private getBody(): string {
    try {
      if (!this.review.published) {
        return this._translate('RATED_THIS_LOCATION');
      }

      const bodyParts: string[] = [];

      // Remove leading/trailing punctuation and whitespace from the headline and comments
      const headline = this.review.headline?.replace(/^[.,;:\s]+|[.,;:\s]+$/g, '');
      const comments = this.review.shopComments?.replace(/^[.,;:\s]+|[,;:\s]+$/g, '');

      // Add the headline if longer than 1 character and not repeated at the start of the comments
      if (headline?.length > 1 && !comments?.toLowerCase().startsWith(headline.toLowerCase())) {
        bodyParts.push(headline);
      }

      // Add the comments if longer than 1 character
      if (comments?.length > 1) {
        bodyParts.push(comments);
      }

      return bodyParts.join(' - ');
    } catch {
      // Do nothing
    }
    return 'Failed to load review';
  }

  private getLuggage(): string {
    try {
      const stored = this._translate('STORED');
      const bags = this._translate(this.review.bookingBags === 1 ? 'BAG' : 'BAGS');
      return `${stored} ${this.review.bookingBags} ${bags.toLowerCase()} ${this.getLocation()}`;
    } catch {
      // Do nothing
    }

    return '';
  }

  private getIsRated(): boolean {
    try {
      if (!this.review.rating) {
        return false;
      }
      // Eliminate reviews where the user just filled in a dot or similar
      return this.body.length > 1;
    } catch {
      // Do nothing
    }
    return false;
  }

  private getIsRecommended(): boolean {
    try {
      return this.review.satisfaction > 0;
    } catch {
      // Do nothing
    }
    return false;
  }

  private getLocation(): string {
    if (this.storageLocationId === this.review.shopId) {
      return this._translate('HERE');
    }

    let location = this._translate(this.review.bookingLocation);

    if (StringUtil.isUpperCase(location)) {
      location = StringUtil.toPasalCase(location, true);
    }

    return `${this._translate('IN')} ${location}`;
  }
}
