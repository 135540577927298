<div
  class="ticket-container"
  [class.container-fluid]="isDocked"
  [class.lh-container-fluid-sm]="isDocked"
  [class.padding-bottom]="isDockedToBottom && isPaddingBottomRequired"
  [class.padding-top]="isDockedToTop"
>
  <!-- This draws the top edge of the ticket stub -->
  <div class="ticket-edge-top-bg" [class.row]="isDockedToBottom" *ngIf="!isDockedToTop">
    <div class="ticket-edge-top"></div>
  </div>

  <ng-content></ng-content>

  <!-- This draws the bottom edge of the ticket stub -->
  <div class="ticket-edge-bottom-bg" [class.row]="isDockedToTop" *ngIf="!isDockedToBottom">
    <div class="ticket-edge-bottom"></div>
  </div>
</div>
