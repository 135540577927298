<lh-shared-feature-bag-image-input
  [numberOfBags]="numberOfBags"
  [remoteLuggageImage]="remoteLuggageImage"
  [localLuggageImage]="localLuggageImage"
  [storageLocation]="storageLocation"
  (localLuggageImageChange)="onLocalLuggageImageChange($event)"
  (remoteLuggageImageChange)="onRemoteLuggageImageChange($event)"
  (completedChange)="onCompletedChange($event)"
  (allowSkipChange)="onAllowSkipChange($event)"
></lh-shared-feature-bag-image-input>
