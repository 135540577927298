<div class="storage-location-badge">
  <div
    [class.recommended]="badge.type === 'Recommended'"
    [class.highly-rated]="badge.type === 'HighlyRated'"
    [class.open-late]="badge.type === 'OpenLate'"
    *ngFor="let badge of badges"
  >
    <i class="icon"></i>
    <span>{{ badge.text | translate }}</span>
  </div>
</div>
