<div class="modal-content" *ngIf="modalShown">
  <div class="modal-header">
    <h4 class="modal-title pull-left">{{ modalTitle }}</h4>

    <button id="closeLoginModal" type="button" class="close pull-right" aria-label="Close" (click)="hideModal()">
      <svg viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
        />
        <path d="M0 0h24v24H0z" fill="none" />
      </svg>
    </button>
  </div>

  <div class="modal-body">
    <!-- Storage Manager Login -->
    <lh-user-authentication
      *ngIf="isStorageManager"
      [isStorageManager]="true"
      [(isExistingUser)]="isExistingUser"
      [(isUserEmailSelected)]="isUserEmailSelected"
      [(isUserPhoneSelected)]="isUserPhoneSelected"
      [userEmail]="userEmail"
      [emailLogin]="'password'"
      [phoneLogin]="'disabled'"
      [registerName]="'required'"
      [askForFullName]="true"
      [registerPhoneNumber]="'required'"
      [registerPassword]="'required'"
      [enableThirdPartyLogin]="false"
      [requireCaptcha]="false"
      [showFieldLabels]="true"
    ></lh-user-authentication>
    <!-- <lh-login
          *ngIf="isStorageManager"
          [(isExistingUser)]="isExistingUser"
          [userEmail]="userEmail"
          [requirePhone]="true"
          [requireName]="true"
          [choosePassword]="true"
          [forceEmailLogin]="true"
          [isStorageManager]="true"
        >
        </lh-login> -->

    <!-- Traveler Login -->
    <!-- <lh-user-authentication
          *ngIf="!isStorageManager"
          [(isExistingUser)]="isExistingUser"
          [(isUserEmailSelected)]="isUserEmailSelected"
          [(isUserPhoneSelected)]="isUserPhoneSelected"
          [termsOfService]="tosConfig"
          [emailLogin]="'password'"
          [phoneLogin]="'code'"
          [registerName]="'disabled'"
          [registerPhoneNumber]="'disabled'"
          [registerPassword]="'disabled'"
          [enableThirdPartyLogin]="true"
          [requireCaptcha]="false"
          [showFieldLabels]="false"
        ></lh-user-authentication> -->
    <lh-smooth-login
      *ngIf="!isStorageManager"
      [(isExistingUser)]="isExistingUser"
      [(isUserEmailSelected)]="isUserEmailSelected"
      [userEmail]="userEmail"
      [requireName]="false"
      [choosePassword]="false"
      [forceEmailLogin]="false"
    >
    </lh-smooth-login>
  </div>
</div>
