import { EventEmitter, Injectable } from '@angular/core';
import { Config } from '@luggagehero/shared/environment';
import { BookableStorageLocation, StorageCriteria, Swap } from '@luggagehero/shared/interfaces';
import { SharedHolidayService } from '@luggagehero/shared/services/holiday';
import { SharedHttpService } from '@luggagehero/shared/services/http';
import { PriceInfo } from '@luggagehero/shared/services/shops';

import { DateUtil } from '../../utils/date.util';
import { StringUtil } from '../../utils/string.util';

interface FindShopsResponse {
  shops: BookableStorageLocation[];
  price: PriceInfo;
}

@Injectable()
export class SwapLocationsService {
  public latestPriceChange: EventEmitter<PriceInfo> = new EventEmitter<PriceInfo>();
  private _current: Swap | undefined = null;
  public latestPrice: PriceInfo;
  private apiEndPoint: string;
  private shops: { [shopId: string]: BookableStorageLocation } = {};
  constructor(
    private http: SharedHttpService,
    private holidayService: SharedHolidayService,
  ) {
    this.apiEndPoint = `${Config.environment.TRAVELER_API}/swap_locations`;
  }

  public get current(): Swap {
    return this._current;
  }
  public set current(value: Swap) {
    this._current = value;
  }

  async findNearBy(criteria: StorageCriteria): Promise<BookableStorageLocation[]> {
    if (!criteria) {
      return null;
    }
    const url = new URL(this.apiEndPoint);

    url.searchParams.append('lat', String(criteria.location.lat));
    url.searchParams.append('lon', String(criteria.location.lon));

    const response = await this.http.get<FindShopsResponse>(url.toString(), false);

    if (response.shops && response.shops.length > 0) {
      await this.holidayService.initCountry(response.shops[0].address.countryCode);
    }

    this.updateLatestPrice(response.price, criteria.location.region);

    return this.deserializeShops(response.shops);
  }

  private updateLatestPrice(value: PriceInfo, region: string) {
    this.latestPrice = value;
    this.latestPrice.region = region;
    this.latestPriceChange.emit(this.latestPrice);
  }

  private deserializeShops(shops: BookableStorageLocation[]) {
    for (let i = 0; i < shops.length; i++) {
      shops[i] = this.deserializeShop(shops[i]);
    }
    return shops;
  }
  private deserializeShop(shop: BookableStorageLocation): BookableStorageLocation {
    // Convert dates from strings to Date objects
    for (let i = 0; i < shop.openingHours.exceptions.length; i++) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const d = shop.openingHours.exceptions[i].date as unknown as string;
      // d is a Date but deserializeDate expects a string.
      // TODO: Verify that this is the correct way to handle this with (any)
      shop.openingHours.exceptions[i].date = DateUtil.deserializeDate(d, false);
    }
    // Make sure exceptions are sorted by date
    shop.openingHours.exceptions.sort((a, b) => a.date.getTime() - b.date.getTime());
    // add shop to the shops object with the _id as key for fast lookup
    this.shops[shop._id] = shop;

    // HACK: Handles that not all shops have official location keys in the db
    if (!shop.officialLocationKey && shop.officialLocationName) {
      shop.officialLocationKey = shop.officialLocationName.toUpperCase().replace(/ /g, '_');
    }

    shop.address.street = StringUtil.decryptString(shop.address.street, Config.environment.CRYPTO_KEY);
    shop.address.formattedAddress = StringUtil.decryptString(
      shop.address.formattedAddress,
      Config.environment.CRYPTO_KEY,
    );
    shop.name = StringUtil.decryptString(shop.name, Config.environment.CRYPTO_KEY);
    shop.mapsShortUrl = StringUtil.decryptString(shop.mapsShortUrl, Config.environment.CRYPTO_KEY);

    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-explicit-any
    shop.location.coordinates = JSON.parse(
      StringUtil.decryptString(shop.location.coordinates as unknown as string, Config.environment.CRYPTO_KEY),
    );

    if (shop.place && shop.place.id) {
      shop.place.id = StringUtil.decryptString(shop.place.id, Config.environment.CRYPTO_KEY);
    }

    return shop;
  }
}
