import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ComponentRef,
  EventEmitter,
  inject,
  Input,
  Output,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { TranslatePipe } from '@luggagehero/shared/ui-pipes';
import { Observable, Subscription } from 'rxjs';

import { SharedUiButtonComponent, SharedUiButtonIcon, SharedUiButtonStyle } from '../button/shared-ui-button.component';
import {
  SharedUiStepProgressComponent,
  Step,
  UiProgressSize,
} from '../step-progress/shared-ui-step-progress.component';
import { WizardConfig, WizardNavigationState, WizardStepConfig, WizardStepProperties } from './wizard-interfaces';
import { WizardStepBaseComponent } from './wizard-step-base-component';

const LARGE_SCREEN_HEIGHT_MIN = 800;
const MEDIUM_SCREEN_HEIGHT_MAX = LARGE_SCREEN_HEIGHT_MIN - 1;
const MEDIUM_SCREEN_HEIGHT_MIN = 600;
const SMALL_SCREEN_HEIGHT_MAX = MEDIUM_SCREEN_HEIGHT_MIN - 1;

@Component({
  selector: 'lh-shared-ui-wizard',
  standalone: true,
  imports: [CommonModule, SharedUiButtonComponent, SharedUiStepProgressComponent, TranslatePipe],
  templateUrl: './shared-ui-wizard.component.html',
  styleUrl: './shared-ui-wizard.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SharedUiWizardComponent implements AfterViewInit {
  @ViewChild('stepContainer', { read: ViewContainerRef }) public stepContainer: ViewContainerRef | undefined;

  @Output() public readonly next = new EventEmitter<void>();
  @Output() public readonly back = new EventEmitter<void>();

  public stepComponentRef: ComponentRef<WizardStepBaseComponent> | undefined;

  private currentStepIndex = 0;
  private currentStepSubscriptions: Subscription[] = [];
  private _currentStep: WizardStepConfig | undefined;
  private _config: WizardConfig = { sections: [{ steps: [] }] };
  private _navigationState: WizardNavigationState = {
    canGoForward: false,
    canGoBack: false,
    canSkip: false,
    useDefaultNavigation: true,
  };
  private _navigatedBack = false;
  private _hideNavigation = false;
  private _showStepNumber = false;
  private _properties: WizardStepProperties | undefined;
  private _screenHeight?: number;
  private _isLoading = false;

  private cd = inject(ChangeDetectorRef);

  public get config(): WizardConfig {
    return this._config;
  }
  @Input() public set config(value: WizardConfig) {
    this._config = value;
    this.currentStepIndex = 0;
    this.loadCurrentStep();
  }

  public get showStepNumber(): boolean {
    return this._showStepNumber;
  }
  @Input() public set showStepNumber(value: boolean) {
    this._showStepNumber = value;
    this.cd.markForCheck();
  }

  public get screenHeight(): number | undefined {
    return this._screenHeight;
  }
  @Input() public set screenHeight(value: number) {
    this._screenHeight = value;
    this.cd.markForCheck();
  }

  public get isSmallHeightScreen(): boolean {
    if (!this.screenHeight) {
      return false;
    }
    return this.screenHeight <= SMALL_SCREEN_HEIGHT_MAX;
  }

  public get isMediumHeightScreen(): boolean {
    if (!this.screenHeight) {
      return false;
    }
    return this.screenHeight >= MEDIUM_SCREEN_HEIGHT_MIN && this.screenHeight <= MEDIUM_SCREEN_HEIGHT_MAX;
  }

  public get isLargeHeightScreen(): boolean {
    if (!this.screenHeight) {
      return false;
    }
    return this.screenHeight >= LARGE_SCREEN_HEIGHT_MIN;
  }

  public get isFullScreenEnabled(): boolean {
    return this.config?.fullScreenEnabled || false;
  }

  public get progressSize(): UiProgressSize {
    if (this.isSmallHeightScreen) {
      return 'small';
    }
    if (this.isMediumHeightScreen) {
      return 'medium';
    }
    return 'large';
  }

  public get numberOfSections(): number {
    if (!this.config) {
      return 0;
    }
    return this.config.sections.length;
  }

  public get currentSection(): WizardConfig['sections'][number] | undefined {
    if (!this.config) {
      return undefined;
    }
    return this.config.sections[this.currentSectionIndex];
  }

  public get currentSectionIndex(): number {
    if (!this.config) {
      return 0;
    }
    let stepCount = 0;
    return this.config.sections.findIndex((section) => {
      stepCount += section.steps.length;
      return stepCount > this.currentStepIndex;
    });
  }

  public get currentSectionNumber(): number {
    return this.currentSectionIndex + 1;
  }

  public get sectionSteps(): Step[] {
    if (!this.config) {
      return [];
    }
    return this.config.sections.map((section, index) => {
      // Default to just the section numnber (e.g. "1.") as the step label
      const step: Step = { label: `${index + 1}.` };

      if (section.title && this.showStepNumber) {
        // Add section title to step number (e.g. "1. Contact information")
        step.label += ` ${section.title}`;
      } else if (section.title) {
        // Just show the section title (e.g. "Contact information")
        step.label = section.title;
      }

      return step;
    });
  }

  public get allSteps(): WizardStepConfig[] {
    if (!this.config) {
      return [];
    }
    return this.config.sections.flatMap((section) => section.steps);
  }

  public get currentStep(): WizardStepConfig | undefined {
    return this._currentStep;
  }

  public get currentStepNumber(): number {
    return this.currentStepIndex + 1;
  }

  public get numberOfSteps(): number {
    return this.allSteps.length;
  }

  public get isFirstStep(): boolean {
    return this.currentStepIndex === 0;
  }

  public get isLastStep(): boolean {
    if (this.allSteps.length === 0) {
      return false;
    }
    return this.currentStepIndex === this.allSteps.length - 1;
  }

  public get hasNextStep(): boolean {
    if (this.allSteps.length === 0) {
      return false;
    }
    return this.currentStepIndex < this.allSteps.length - 1;
  }

  public get navigationState(): WizardNavigationState {
    return this._navigationState;
  }

  public get navigatedBack(): boolean {
    return this._navigatedBack;
  }

  public get hideNavigation(): boolean {
    return this._hideNavigation;
  }

  public get properties(): WizardStepProperties | undefined {
    return this._properties;
  }
  public set properties(value: WizardStepProperties | undefined) {
    this._properties = value;
    this.cd.markForCheck();
  }

  public get isLoading(): boolean {
    return this._isLoading;
  }
  public set isLoading(value: boolean) {
    this._isLoading = value;
    this.updateNavigation();
  }

  public get isForwardButtonDisabled(): boolean {
    if (this.isLoading) {
      return true;
    }
    if (!this.navigationState.canGoForward && !this.navigationState.canSkip) {
      return true;
    }
    return false;
  }

  public get isBackButtonDisabled(): boolean {
    if (this.isLoading) {
      return true;
    }
    if (!this.navigationState.canGoBack) {
      return true;
    }
    return false;
  }

  public get isForwardButtonVisible(): boolean {
    if (!this.isLastStep) {
      return true;
    }
    if (this.properties?.forwardButtonLabel) {
      return true;
    }
    return false;
  }

  public get isBackButtonVisible(): boolean {
    if (this.isBackButtonDisabled && !this.isLoading) {
      return false;
    }
    if (this.isFirstStep) {
      return false;
    }
    return true;
  }

  public get forwardButtonLabel(): string {
    if (this.navigationState.canSkip && !this.navigationState.canGoForward) {
      return 'SKIP';
    }
    return this.properties?.forwardButtonLabel || 'Next';
  }

  public get forwardButtonStyle(): SharedUiButtonStyle {
    return this.navigationState.canGoForward ? 'warning' : 'default';
  }

  public get forwardButtonIcon(): SharedUiButtonIcon | null {
    if (this.properties?.hideForwardButtonIcon) {
      return null;
    }
    return 'forward';
  }

  public ngAfterViewInit() {
    // Load the first step when we know the step container is available
    this.loadCurrentStep();
  }

  public loadStep(value: WizardStepConfig | undefined) {
    if (!value || !this.stepContainer) {
      // No value provided or the step container is not available yet
      return;
    }

    // Destroy the current step if it exists
    this.destroyCurrentStep();

    // Set the default navigation state
    this.updateNavigation({
      useDefaultNavigation: true,
      canGoForward: !this.isLastStep,
      canGoBack: !this.isFirstStep,
      canSkip: false,
    });

    if (value.component) {
      // Create the step component inside the container and keep a reference to the component instance
      this.stepComponentRef = this.stepContainer.createComponent(value.component);

      // Subscribe to navigation events from the component instance
      this.addStepSubscription(this.stepComponentRef.instance.stepForward, () => this.onStepForward());
      this.addStepSubscription(this.stepComponentRef.instance.stepBack, () => this.onStepBack());
      this.addStepSubscription(this.stepComponentRef.instance.stepAutoSkip, () => this.onStepAutoSkip());
      this.addStepSubscription(this.stepComponentRef.instance.navigationChange, (e) => this.updateNavigation(e));
      this.addStepSubscription(this.stepComponentRef.instance.isLoadingChange, (e) => (this.isLoading = e));

      // Subscribe to property changes from the component instance
      this.addStepSubscription(this.stepComponentRef.instance.propertiesChange, (e) => (this.properties = e));

      // Override default property values on the component instance with the properties provided in the config
      this.stepComponentRef.instance.applyProperties(value.properties);
      this.properties = this.stepComponentRef.instance.properties;
    } else {
      //
      // When there's no component which may have default property values or its own navigation state logic, simply use
      // the properties provided in the config as-is
      //
      this.properties = value.properties;
    }

    //
    // HACK: Some step components don't render properly unless we force change detection after the Angular
    // initialization has completed
    //
    this.cd.detectChanges();
  }

  public loadCurrentStep() {
    if (this.allSteps.length === 0) {
      return;
    }
    this._currentStep = this.allSteps[this.currentStepIndex];
    this.cd.markForCheck();

    this.loadStep(this.currentStep);
  }

  private destroyCurrentStep() {
    try {
      if (this.currentStepSubscriptions?.length > 0) {
        this.currentStepSubscriptions.forEach((subscription) => subscription.unsubscribe());
        this.currentStepSubscriptions = [];
      }
    } catch {
      // Ignore
    }

    this.stepContainer?.clear();

    this.stepComponentRef?.destroy();
    this.stepComponentRef = undefined;
  }

  public goForward() {
    if (this.stepComponentRef) {
      // Call go forward on the step, which will raise the forward event if the step allows it
      void this.stepComponentRef.instance.goForward();
    } else {
      // When there is no step component, just go forward directly
      this.onStepForward();
    }
  }

  public goBack() {
    if (this.stepComponentRef) {
      // Call go back on the step, which will raise the back event if the step allows it
      void this.stepComponentRef.instance.goBack();
    } else {
      // Where there is no step component, just go back directly
      this.onStepBack();
    }
  }

  public onStepForward(): void {
    this._navigatedBack = false;

    // Point forward to the next step
    const i = this.currentStepIndex;
    if (i < this.allSteps.length - 1) {
      this.currentStepIndex = i + 1;
      this.cd.markForCheck();
    }

    // Load the step and emit the next event
    this.loadCurrentStep();
    this.next.emit();
  }

  public onStepBack(): void {
    this._navigatedBack = true;

    // Point back to the previous step
    const currentStepIndex = this.currentStepIndex;
    if (currentStepIndex > 0) {
      this.currentStepIndex = currentStepIndex - 1;
      this.cd.markForCheck();
    }

    // Load the step and emit the back event
    this.loadCurrentStep();
    this.back.emit();
  }

  public onStepAutoSkip(): void {
    if (this.navigatedBack) {
      this.onStepBack();
    } else {
      this.onStepForward();
    }
  }

  protected addStepSubscription<T>(observable: Observable<T>, handler: (value: T) => void) {
    this.currentStepSubscriptions.push(observable.subscribe(handler));
  }

  private updateNavigation(updates?: Partial<WizardNavigationState>) {
    if (updates) {
      this._navigationState = { ...this._navigationState, ...updates };
    }
    this._hideNavigation = this.shouldHideNavigation(this._navigationState);
    this.cd.markForCheck();

    // HACK: Sometimes the state of the navigation buttons isn't update properly without this
    this.cd.detectChanges();
  }

  private shouldHideNavigation(state: WizardNavigationState): boolean {
    if (!state.useDefaultNavigation) {
      return true;
    }
    // Uncomment to hide navigation when loading
    // if (this.isLoading) {
    //   return true;
    // }
    return !state.canGoForward && !state.canSkip && !this.isLoading;
  }
}
