import { Inject, Pipe, PipeTransform } from '@angular/core';
import {
  ITimeInterval,
  TIME_INTERVAL_TRANSFORMER_SERVICE,
  TimeIntervalTransformerService,
} from '@luggagehero/shared/interfaces';
import { Observable } from 'rxjs';

@Pipe({ name: 'timeInterval', standalone: true })
export class TimeIntervalPipe implements PipeTransform {
  constructor(@Inject(TIME_INTERVAL_TRANSFORMER_SERVICE) private transformer: TimeIntervalTransformerService) {}

  transform(
    value: ITimeInterval[],
    short = true,
    timeOnly = false,
    ...components: ('from' | 'to')[]
  ): Observable<string> {
    return this.transformer.transform(value, short, timeOnly, ...components);
  }
}
