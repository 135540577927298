@if (isLoading) {
  <div class="form-control">
    <lh-shared-ui-spinner [visible]="true"></lh-shared-ui-spinner>
  </div>
}

<form class="paymentCardForm" (ngSubmit)="onSubmit()" #paymentCardForm="ngForm" [hidden]="isLoading">
  <div id="cardContainer" class="form-control" #cardContainer>
    <!-- a Stripe Element will be inserted here. -->
  </div>
  <div id="cardErrors" role="alert" *ngIf="cardError" class="alert alert-danger">{{ cardError }}</div>
</form>
