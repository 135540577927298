<div class="chart-container">
  <ngx-charts-number-card
    [results]="results"
    [valueFormatting]="valueFormatter"
    [innerPadding]="0"
    [cardColor]="cardColor"
    [bandColor]="bandColor"
    [textColor]="textColor"
    #ngxChartsNumberCard
  ></ngx-charts-number-card>
</div>
