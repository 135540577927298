import { ChangeDetectorRef, Component, Input, NgZone, OnInit, ViewChild } from '@angular/core';
import { AppConfig } from '@luggagehero/shared/app-settings/data-access';
import { BookableStorageLocation, ILocation, LatLng } from '@luggagehero/shared/interfaces';
import { SharedDistanceService } from '@luggagehero/shared/services/distance';
import { SharedGoogleMapsService } from '@luggagehero/shared/services/google-maps';
import { SharedLoggingService } from '@luggagehero/shared/services/logging';
import { SharedPricingService } from '@luggagehero/shared/services/pricing';
import { SharedShopsService } from '@luggagehero/shared/services/shops';
import { SharedStorageCriteriaService } from '@luggagehero/shared/services/storage-criteria';
import { DEFAULT_MAP_MARKER_COLOR, SharedThemeService } from '@luggagehero/shared/services/theme';
import { ModalDirective } from 'ngx-bootstrap/modal';

import { RouterExtensions } from '../../../core/services/index';
import { ShopMapBaseComponent } from './shop-map.base-component';

const DEFAULT_ZOOM_LEVEL = 14;

@Component({ template: '' })
export abstract class ShopMapCardBaseComponent extends ShopMapBaseComponent implements OnInit {
  @ViewChild('shopDetailsModal') public shopDetailsModal: ModalDirective;

  mapLatitude: number;
  mapLongitude: number;
  directionsTravelMode = 'WALKING';
  directionsRenderOptions: google.maps.DirectionsRendererOptions;
  directionsOrigin: LatLng;
  directionsDestination: LatLng;

  private _activeShop: BookableStorageLocation;
  private _zoom = DEFAULT_ZOOM_LEVEL;

  constructor(
    themeService: SharedThemeService,
    criteriaService: SharedStorageCriteriaService,
    googleMapsService: SharedGoogleMapsService,
    shopsService: SharedShopsService,
    distanceService: SharedDistanceService,
    priceService: SharedPricingService,
    router: RouterExtensions,
    cd: ChangeDetectorRef,
    ngZone: NgZone,
    log: SharedLoggingService,
  ) {
    super(
      themeService,
      criteriaService,
      googleMapsService,
      shopsService,
      distanceService,
      priceService,
      router,
      cd,
      ngZone,
      log,
    );
  }

  get maxZoom(): number {
    if (AppConfig.IS_SHOP_MAP_MAX_ZOOM_DISABLED) {
      return undefined;
    }
    return AppConfig.SHOP_MAP_MAX_ZOOM;
  }

  get location(): ILocation {
    return this._location;
  }
  @Input() set location(value: ILocation) {
    this._location = value;
    this.zoom = value && value.zoom ? value.zoom : DEFAULT_ZOOM_LEVEL;
  }

  get activeShop(): BookableStorageLocation {
    return this._activeShop;
  }
  @Input() set activeShop(value: BookableStorageLocation) {
    this._activeShop = value;

    if (!value) {
      this.directionsOrigin = null;
      this.directionsDestination = null;
      this.zoom = this.location ? this.location.zoom : DEFAULT_ZOOM_LEVEL;
    } else if (this.location) {
      // this.zoom = this.location.zoom;
      setTimeout(() => {
        this.directionsOrigin = {
          lat: this.location.lat,
          lng: this.location.lon,
        };
        this.directionsDestination = {
          lat: value.location.coordinates[1],
          lng: value.location.coordinates[0],
        };
        this.directionsChanged();
        this.cd.markForCheck();
      }, 1);
    }

    this.highlightShop = value?._id;
  }

  protected directionsChanged(): void {
    // Here to allow override
  }

  get showDirections(): boolean {
    if (!this.directionsRenderOptions) {
      return false;
    }
    if (!this.directionsOrigin || !this.directionsDestination) {
      return false;
    }
    return true;
  }

  get latitude() {
    if (this.mapLatitude !== undefined) {
      return this.mapLatitude;
    }
    return this.location.lat;
  }

  get longitude(): number {
    if (this.mapLongitude !== undefined) {
      return this.mapLongitude;
    }
    return this.location.lon;
  }

  get zoom(): number {
    return this._zoom;
  }
  set zoom(value: number) {
    this._zoom = value;
    this.cd.markForCheck();
  }

  ngOnInit() {
    this.themeService.theme$.subscribe((theme) => {
      this.directionsRenderOptions = {
        suppressMarkers: true,
        polylineOptions: {
          strokeColor: theme ? theme.accentColor.dark : DEFAULT_MAP_MARKER_COLOR,
          strokeOpacity: 0.65,
          strokeWeight: 6,
        },
      };
      this.cd.markForCheck();
    });
  }

  setActiveShop(value: BookableStorageLocation) {
    this.shopsService.setCurrent(value);
    this.activeShop = value;
  }
}
