import { ChangeDetectionStrategy, Component } from '@angular/core';
import { SimpleOrderSummaryBaseComponent } from '@luggagehero/features/ui';

@Component({
  selector: 'lh-simple-order-summary',
  templateUrl: './simple-order-summary.component.html',
  styleUrls: ['./simple-order-summary.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SimpleOrderSummaryComponent extends SimpleOrderSummaryBaseComponent {}
