import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({ name: 'safeUrl', standalone: true })
export class SafeUrlPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(url: string, type = 'resource') {
    switch (type) {
      case 'style':
        return this.sanitizer.bypassSecurityTrustStyle(`url(${url})`);

      case 'resource':
      default:
        return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }
  }
}
