<div class="wrapper">
  <div (click)="showInputField()" class="places-search-container">
    <!-- prettier-ignore -->
    <svg class="search" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.7956 19.2294C13.0994 19.2294 15.0657 18.4201 16.6943 16.8014C18.3229 15.1827 19.1372 13.2115 19.1372 10.8877C19.1372 8.58383 18.3229 6.61758 16.6943 4.98897C15.0657 3.36036 13.0994 2.54605 10.7956 2.54605C8.4718 2.54605 6.50059 3.36036 4.88191 4.98897C3.26323 6.61758 2.45389 8.58383 2.45389 10.8877C2.45389 13.2115 3.26323 15.1827 4.88191 16.8014C6.50059 18.4201 8.4718 19.2294 10.7956 19.2294ZM10.7956 21.6127C9.30597 21.6127 7.91073 21.3297 6.60983 20.7637C5.30892 20.1976 4.17684 19.433 3.21358 18.4697C2.25031 17.5064 1.48566 16.3744 0.919619 15.0735C0.353577 13.7725 0.0705566 12.3773 0.0705566 10.8877C0.0705566 9.418 0.353577 8.03269 0.919619 6.73178C1.48566 5.43088 2.25031 4.29383 3.21358 3.32064C4.17684 2.34744 5.30892 1.57782 6.60983 1.01178C7.91073 0.445741 9.30597 0.16272 10.7956 0.16272C12.2653 0.16272 13.6506 0.445741 14.9515 1.01178C16.2524 1.57782 17.3894 2.34744 18.3626 3.32064C19.3358 4.29383 20.1054 5.43088 20.6715 6.73178C21.2375 8.03269 21.5206 9.418 21.5206 10.8877C21.5206 12.1588 21.312 13.3604 20.8949 14.4925C20.4778 15.6246 19.9019 16.6574 19.167 17.5908L23.9039 22.3277L22.2058 23.9961L17.4689 19.289C16.5354 20.0238 15.5026 20.5948 14.3706 21.002C13.2385 21.4091 12.0468 21.6127 10.7956 21.6127Z" fill="currentColor"/>
  </svg>

    <!-- prettier-ignore -->
    <svg class="logo" width="30" height="32" viewBox="0 0 30 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.6677 23.4831L15 21.1446L18.2446 22.7231L17.6892 18.6016L20.4077 15.2985L16.7539 15.1231L15.1754 11.5277L13.4508 15.5323L9.7677 16.5846L12.3692 19.2154L11.6677 23.4831Z" fill="currentColor"/>
    <path d="M29.1185 4.54155C28.6216 4.07386 27.9492 3.86924 27.2769 3.95694L21.4892 4.65847L21.5185 2.81694C21.5185 2.14463 21.2554 1.50155 20.7585 1.03386C20.2616 0.566166 19.5892 0.361551 18.9169 0.449243L11.1416 1.41386C10.0016 1.56001 9.12463 2.52463 9.0954 3.69386L9.06617 6.2077L3.45386 6.90924C1.61232 7.11386 0.530782 7.87386 0.472321 10.6508L0.121552 29.0077V29.1539C0.121552 29.8262 0.384629 30.4692 0.881552 30.9369C1.32001 31.3169 1.8754 31.5508 2.46001 31.5508C2.54771 31.5508 2.6354 31.5508 2.75232 31.5215L27.4523 28.4815C28.6216 28.3354 29.4692 27.3708 29.4985 26.2015L29.8785 6.32463C29.8785 5.65232 29.6154 5.00924 29.1185 4.54155ZM12.1646 4.10309L18.4785 3.34309L18.42 5.03847L12.1354 5.79847L12.1646 4.10309ZM7.28309 27.7215L3.33694 28.2185L3.68771 10.0954L7.63386 9.59847L7.28309 27.7215ZM21.6646 25.9385L8.07232 27.6046L8.42309 9.48155L22.0154 7.8154L21.6646 25.9385ZM26.4 25.3539L22.4539 25.8508L22.8046 7.7277L26.7508 7.23078L26.4 25.3539Z" fill="currentColor"/>
  </svg>

    <div class="input-wrapper" [ngClass]="{ show: locationInputShow }">
      <input
        (input)="onLocationInputChange($event)"
        type="text"
        [(ngModel)]="value"
        class="location-input"
        #locationInputField
        autocomplete="off"
        autocorrect="off"
        autocapitalize="off"
        spellcheck="off"
        name="searchInput"
        [placeholder]="placeholder"
        (focus)="onInputFocus()"
        (blur)="onInputBlur()"
      />
    </div>
    <div class="location-label">{{ value || placeholder }}</div>

    <div *ngIf="locationInputShow && value.length && false" class="clear-button">
      <!-- prettier-ignore -->
      <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1.80046 12.5744L0.633789 11.4078L5.30046 6.74111L0.633789 2.07444L1.80046 0.907776L6.46712 5.57444L11.1338 0.907776L12.3005 2.07444L7.63379 6.74111L12.3005 11.4078L11.1338 12.5744L6.46712 7.90778L1.80046 12.5744Z" fill="currentColor"/>
    </svg>
    </div>

    <lh-traveler-shops-ui-dropdown [topDistance]="'var(--places-search-dropdown-top)'" [isOpen]="dropdownShow">
      <ul class="lug-dropdown-content-list">
        @for (prediction of predictions; track prediction) {
          <li
            (click)="setPlace($event, prediction, $index)"
            class="prediction-item href"
            [class.highlighted]="$index === this.highlightedIndex"
          >
            <a class="prediction-item-button" [ngClass]="{ 'multi-line': doMultiLine(prediction[0], prediction[1]) }">
              <span class="prediction-item-title">{{ prediction[0] }}</span>
              <span class="prediction-item-subtitle">{{ prediction[1] }}</span>
            </a>
          </li>
        }
      </ul>
    </lh-traveler-shops-ui-dropdown>
  </div>
</div>
