// angular
import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
// libs
import { DynamicFormInputBaseComponent } from '@luggagehero/features/ui';

@Component({
  selector: 'lh-dynamic-form-input',
  templateUrl: './dynamic-form-input.component.html',
  styleUrls: ['./dynamic-form-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DynamicFormInputComponent extends DynamicFormInputBaseComponent {
  constructor(cd: ChangeDetectorRef) {
    super(cd);
  }
}
