import { Provider } from '@angular/core';
import { SharedLoggingService } from '@luggagehero/shared/services/logging';

import { ModalService } from './modal.service';
import { RouterExtensions } from './router-extensions.service';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const CORE_PROVIDERS = [
  SharedLoggingService,
  ModalService, // abstract class is not a provider TODO: check if this is correct
  RouterExtensions,
] as Provider[]; // fix typescript error by casting to Provider[]

export * from './modal.service';
export * from './router-extensions.service';
export * from './tokens';
