import 'bootstrap';

import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { Router } from '@angular/router';
import { ModalService } from '@luggagehero/core';
import { NavbarBaseComponent } from '@luggagehero/features/ui';
import { SharedParamsService } from '@luggagehero/shared/services/params';
import { SharedThemeService } from '@luggagehero/shared/services/theme';
import { SharedUserService } from '@luggagehero/shared/services/users';
import { SharedWindowService } from '@luggagehero/shared/services/window';

import { HowItWorksComponent } from '../how-it-works/how-it-works.component';
import { PricingComponent } from '../pricing/pricing.component';

@Component({
  selector: 'lh-navbar',
  templateUrl: 'navbar.component.html',
  styleUrls: ['navbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavbarComponent extends NavbarBaseComponent {
  constructor(
    private modalService: ModalService,
    paramsService: SharedParamsService,
    themeService: SharedThemeService,
    userService: SharedUserService,
    windowService: SharedWindowService,
    router: Router,
    cd: ChangeDetectorRef,
  ) {
    super(paramsService, themeService, userService, windowService, router, cd);
  }

  public async showHowItWorks(): Promise<void> {
    await this.modalService.show({ component: HowItWorksComponent });
  }

  public async showPriceInfo(): Promise<void> {
    await this.modalService.show({ component: PricingComponent });
  }
}
