/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Config } from '@luggagehero/shared/environment';
import { Observable } from 'rxjs';

@Injectable()
export class SmoobuService {
  constructor(private http: HttpClient) {}

  connectAccount(apiKey: string): Observable<any> {
    const url = `${Config.environment.PMS_INTEGRATIONS_API}/providers/smoobu/connect`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8',
    });
    const payload = { code: apiKey };
    return this.http.post(url, payload, { headers: headers });
  }
}
