import { DecimalPipe } from '@angular/common';
import { ChangeDetectorRef, Component, EventEmitter, inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import {
  IPaymentRecord,
  LegacyOrder,
  LegacyOrderLine,
  PAYMENT_METHOD_TRANSFORMER_SERVICE,
  PaymentMethodTransformerService,
  PRICING_SERVICE,
  PricingService,
} from '@luggagehero/shared/interfaces';
import { SharedOrderService } from '@luggagehero/shared/services/orders';
import { SharedPaymentService } from '@luggagehero/shared/services/payments';
import { SharedTranslateService } from '@luggagehero/shared/services/translation';
import { map, Observable, of, Subscription } from 'rxjs';

import { BaseComponent } from '../../../core';

const MAX_SHORT_CTA_CHAR_COUNT = 15;
const MAX_SHORT_CTA_WORD_COUNT = 2;

type DummyItemSizeClass = 'sm' | 'md' | 'lg';

@Component({ template: '' })
export abstract class SimpleOrderSummaryBaseComponent extends BaseComponent implements OnInit, OnDestroy {
  protected paymentMethodTransformerService = inject<PaymentMethodTransformerService>(
    PAYMENT_METHOD_TRANSFORMER_SERVICE,
  );
  protected pricingService = inject<PricingService>(PRICING_SERVICE);
  @Output() public callToActionClick = new EventEmitter();
  @Output() public orderLineChange = new EventEmitter<LegacyOrderLine>();

  @Input() public dummyOrderLineCount = 4;
  @Input() public isPaymentCardValidationEnabled = false;

  private _paymentCardValidationAmount: number;
  private _paymentCardValidationCurrency: string;

  private subscriptions: Subscription[] = [];

  private _headline: string;
  private _callToAction: string;
  private _order: LegacyOrder;
  private _totalLabel: string;
  private _totalNote: string;
  private _isPaid = false;
  private _isCanceled = false;
  private _isReadOnly = false;
  private _isInfoEnabled = true;
  private _isCollapsed = false;
  private _noPadding = false;
  private _paymentMethod: IPaymentRecord;
  private _isLoading = false;

  constructor(
    private orderService: SharedOrderService,
    private paymentService: SharedPaymentService,
    private decimalPipe: DecimalPipe,
    private translate: SharedTranslateService,
    private cd: ChangeDetectorRef,
  ) {
    super();
  }

  get paymentCardValidationAmount(): number {
    return this._paymentCardValidationAmount;
  }
  set paymentCardValidationAmount(value: number) {
    this._paymentCardValidationAmount = value;
    this.cd.markForCheck();
  }

  get paymentCardValidationCurrency(): string {
    return this._paymentCardValidationCurrency;
  }
  set paymentCardValidationCurrency(value: string) {
    this._paymentCardValidationCurrency = value;
    this.cd.markForCheck();
  }

  get isLoading(): boolean {
    return this._isLoading;
  }
  @Input() set isLoading(value: boolean) {
    this._isLoading = value;
    this.cd.markForCheck();
  }

  get headline(): string {
    return this._headline;
  }
  @Input() set headline(value: string) {
    this._headline = value;
    this.cd.markForCheck();
  }

  get callToAction(): string {
    return this._callToAction;
  }
  @Input() set callToAction(value: string) {
    this._callToAction = value;
    this.cd.markForCheck();
  }

  get order(): LegacyOrder {
    return this._order;
  }
  @Input() set order(value: LegacyOrder) {
    this._order = value;
    this.cd.markForCheck();

    this.dummyOrderLineCount = value?.orderLines?.length || 4;
  }

  get totalLabel(): string {
    if (this.isLoading) {
      return null;
    }
    if (this._totalLabel) {
      return this._totalLabel;
    }

    if (this.order) {
      return this.order.pricingModel === 'daily' || this.isFinal || this.isPaid
        ? this.translate.instant('PRICE_TOTAL')
        : this.translate.instant('DUE_NOW');
    }

    return null;
  }
  @Input() set totalLabel(value: string) {
    this._totalLabel = value;
    this.cd.markForCheck();
  }

  get totalNote(): Observable<string> {
    if (this.isLoading) {
      return of(null);
    }
    if (this._totalNote) {
      return of(this._totalNote);
    }
    if (this.isPaid && this.paymentMethod) {
      return of(this.paymentMethodTransformerService.transform(this.paymentMethod, 'summary'));
    }
    if (this.isPaid || this.isFinal || this.order?.pricingModel === 'daily') {
      return of(null);
    }
    const totalNoteTranslationKey =
      this.paymentCardValidationAmount > 0 ? 'PAYMENT_VERIFICATION_FOOTNOTE' : 'PRICE_FOR_PAYG_BOOKINGS_UPON_PICKUP';
    return this.translate.get(totalNoteTranslationKey).pipe(map((localizedTotalNote) => `* ${localizedTotalNote}`));
  }
  @Input() set totalNote(value: string) {
    this._totalNote = value;
    this.cd.markForCheck();
  }

  get isPaid(): boolean {
    return this._isPaid;
  }
  @Input() set isPaid(value: boolean) {
    this._isPaid = value;
    this.cd.markForCheck();
  }

  get isCanceled(): boolean {
    return this._isCanceled;
  }
  @Input() set isCanceled(value: boolean) {
    this._isCanceled = value;
    this.cd.markForCheck();
  }

  get isReadOnly(): boolean {
    return this._isReadOnly;
  }
  @Input() set isReadOnly(value: boolean) {
    this._isReadOnly = value;
    this.cd.markForCheck();
  }

  get isInfoEnabled(): boolean {
    return this._isInfoEnabled;
  }
  @Input() set isInfoEnabled(value: boolean) {
    this._isInfoEnabled = value;
    this.cd.markForCheck();
  }

  get isCollapsed(): boolean {
    return this._isCollapsed;
  }
  @Input() set isCollapsed(value: boolean) {
    this._isCollapsed = value;
    this.cd.markForCheck();
  }

  get noPadding(): boolean {
    return this._noPadding;
  }
  @Input() set noPadding(value: boolean) {
    this._noPadding = value;
    this.cd.markForCheck();
  }

  get paymentMethod(): IPaymentRecord {
    return this._paymentMethod;
  }
  @Input() set paymentMethod(value: IPaymentRecord) {
    this._paymentMethod = value;
    this.cd.markForCheck();
  }

  get isFinal(): boolean {
    if (this.order?.orderLines.some((line) => line.isPricing)) {
      // One or more order lines reflect pricing to be used to calculate a final price later
      return false;
    }
    return true;
  }

  get orderTotal(): string {
    if (!this.order || this.isLoading) {
      return null;
    }

    let total = 0;
    let currency = this.order.currency;
    let asterisk = '* ';

    if (this.order.pricingModel === 'daily' || this.isFinal || this.isPaid) {
      total = this.order.total || this.orderService.calculateTotal(this.order.orderLines);
      asterisk = '';
    } else if (this.paymentCardValidationAmount > 0) {
      total = this.paymentCardValidationAmount;
      currency = this.paymentCardValidationCurrency;
    }
    return `${asterisk}${this.pricingService.format(total, currency, 2, false)}`;
  }

  get isCallToActionLong(): boolean {
    const cta = this.callToAction;
    if (!cta) {
      return false;
    }

    const numberOfCharacters = cta.length;
    if (numberOfCharacters > MAX_SHORT_CTA_CHAR_COUNT) {
      return true;
    }

    const numberOfWords = cta.split(/\b\s+/).length;
    if (numberOfWords > MAX_SHORT_CTA_WORD_COUNT) {
      return true;
    }

    return false;
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.paymentService.initatePaymentResult.subscribe((result) => {
        if (result && this.isPaymentCardValidationEnabled) {
          this.paymentCardValidationAmount = result.amount || 0;
          this.paymentCardValidationCurrency = result.currency;
          this.cd.markForCheck();
        }
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscriptions?.forEach((sub) => sub.unsubscribe());
  }

  onCallToActionClick() {
    this.callToActionClick.emit();
  }

  onOrderLineChange(line: LegacyOrderLine) {
    this.orderLineChange.emit(line);
  }

  isIncludedForFree(line: LegacyOrderLine): boolean {
    return line.unitPrice === 0 && line.selected;
  }

  getPriceText(line: LegacyOrderLine): string {
    if (line.unitPrice === 0 && line.selected) {
      return 'INCLUDED_IN_PRICE_SHORT';
    }

    let priceText = '';

    if (line.optional) {
      priceText += '+ ';
    }

    const priceValue = line.unitPrice * line.quantity;
    priceText += line.isRelative
      ? this.decimalPipe.transform(priceValue, '1.0-0')
      : this.pricingService.format(priceValue, this.order.currency, 2, line.isPricing);

    if (line.isPricing) {
      priceText += line.pricingSuffix;
    }

    return priceText;
  }

  getDummyItemSizeClass(index: number): DummyItemSizeClass {
    // Provide a pseudo-random list of sizes to use for dummy items based on index (after 7 items default to medium)
    const sizes: DummyItemSizeClass[] = ['lg', 'sm', 'md', 'sm', 'lg', 'md', 'sm'];
    return index < sizes.length ? sizes[index] : 'md';
  }
}
