<div class="header" *ngIf="review">
  <!-- User profile image -->
  <lh-shared-ui-user-profile-image
    [name]="userName"
    size="md"
    [profileBackgroundColors]="profileBackgroundColors"
  ></lh-shared-ui-user-profile-image>

  <div class="username-date">
    <!-- User Name -->
    <div class="username">
      <!-- If userName === 'TRAVELER', translate it. Otherwise just show the name. -->
      <ng-container *ngIf="userName !== 'TRAVELER'; else travelerNameKey">
        {{ userName }}
      </ng-container>
      <ng-template #travelerNameKey>
        {{ 'TRAVELER' | translate }}
      </ng-template>
    </div>

    <!-- Date (e.g., "3 days ago") -->
    <div class="date" *ngIf="review.created">
      {{ review.created | lhMoment: 'fromNow' | uppercaseFirstLetter }}
    </div>
  </div>

  <!-- Rating stars if review is rated -->
  <div class="rating" *ngIf="isRated">
    <rating
      class="shop-rating-md"
      [(ngModel)]="review.rating"
      [max]="5"
      [readonly]="true"
      *ngIf="review.rating"
    ></rating>
  </div>

  <!-- Recommendation icon if not rated -->
  <div class="recommendation" *ngIf="!isRated">
    <i class="icon-recommended" *ngIf="isRecommended"></i>
    <i class="icon-not-recommended" *ngIf="!isRecommended"></i>
  </div>
</div>

<!-- Main review text -->
<div class="body">
  <!-- If the review is star-rated, show "body". Otherwise show recommendation text. -->
  <ng-container *ngIf="isRated; else recommendationText">
    <!-- If body is a translation key like 'RATED_THIS_LOCATION' or 'FAILED_TO_LOAD_REVIEW', pipe it -->
    <ng-container *ngIf="body !== 'RATED_THIS_LOCATION' && body !== 'FAILED_TO_LOAD_REVIEW'; else bodyKey">
      {{ body }}
    </ng-container>
    <ng-template #bodyKey>
      {{ body | translate }}
    </ng-template>
  </ng-container>

  <ng-template #recommendationText>
    <span *ngIf="isRecommended">
      {{ 'RECOMMENDS_THIS_LOCATION' | translate }}
    </span>
    <span *ngIf="!isRecommended">
      {{ 'DOES_NOT_RECOMMEND_THIS_LOCATION' | translate }}
    </span>
  </ng-template>
</div>

<!-- Luggage info -->
<div class="footer">
  <!-- If we have "X#BAG" or "X#BAGS", we split by "#" and translate the second part. -->
  <ng-container *ngIf="luggage; else noLuggage">
    <!-- Example "2#BAGS" -> ["2", "BAGS"] -->
    <ng-container *ngIf="luggage.includes('#'); else rawLuggage">
      {{ 'STORED' | translate }}
      {{ luggage.split('#')[0] }}
      {{ luggage.split('#')[1] | translate }}
    </ng-container>
    <ng-template #rawLuggage>
      {{ luggage }}
    </ng-template>
  </ng-container>
  <ng-template #noLuggage></ng-template>
</div>
