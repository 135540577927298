import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { ILuggage } from '@luggagehero/shared/interfaces';
import { SharedLoggingService } from '@luggagehero/shared/services/logging';

// Importing services directly from their own files instead of via index as otherwise getting this issue:
// http://stackoverflow.com/questions/37997824/angular-2-di-error-exception-cant-resolve-all-parameters
import { BaseComponent } from '../../../core';
import { IconService } from '../../../services/icon.service';

const noop = () => {
  /**/
};

@Component({ template: '' })
export abstract class LuggagePickerBaseComponent extends BaseComponent implements ControlValueAccessor {
  @Input() inPage = false;
  normalCount = 1;
  handCount = 1;
  normalOptions: number[] = [];
  handOptions: number[] = [];
  normalSelectedIndex: number;
  handSelectedIndex: number;
  private _value: ILuggage;
  private _isCollapsed = true;

  private onTouchedCallback: () => void = noop;
  private onChangeCallback: (_) => void = noop;

  constructor(
    private icon: IconService,
    private log: SharedLoggingService,
    private cd: ChangeDetectorRef,
  ) {
    super();

    this.normalOptions = this.getLuggageOptions(100);
    this.handOptions = this.getLuggageOptions(100);
  }

  get value(): ILuggage {
    return this._value;
  }
  set value(value: ILuggage) {
    if (!value || value === this._value) {
      return;
    }
    this._value = value;
    this.normalCount = value.normal;
    this.handCount = value.hand;

    this.updateSelectedIndex();
    this.onChangeCallback(value);
    this.cd.markForCheck();
  }

  get isCollapsed(): boolean {
    return this._isCollapsed;
  }
  set isCollapsed(value: boolean) {
    this._isCollapsed = value;
    this.cd.markForCheck();
  }

  get luggageCount(): number {
    return this.normalCount + this.handCount;
  }

  writeValue(value: ILuggage) {
    if (value !== undefined) {
      this.value = value;
    }
  }

  registerOnChange(fn: (_) => void) {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: () => void) {
    this.onTouchedCallback = fn;
  }

  selectNormalLuggage(index: number) {
    this.normalCount = this.normalOptions[index];
    this.updateValue();
  }

  selectHandLuggage(index: number) {
    this.handCount = this.handOptions[index];
    this.updateValue();
  }

  private updateValue() {
    this.value = {
      normal: this.normalCount,
      hand: this.handCount,
    };
  }

  private updateSelectedIndex() {
    if (!this.value) {
      return;
    }
    this.normalSelectedIndex = this.normalOptions.indexOf(this.value.normal);
    this.handSelectedIndex = this.handOptions.indexOf(this.value.hand);
  }

  private getLuggageOptions(max: number): number[] {
    const options: number[] = [];
    for (let count = 1; count <= max; count++) {
      options.push(count);
    }
    return options;
  }
}
