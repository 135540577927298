// angular
import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';
// libs
import { DynamicFormBaseComponent } from '@luggagehero/features/ui';

@Component({
  exportAs: 'lhDynamicForm',
  selector: 'lh-dynamic-form',
  templateUrl: './dynamic-form.component.html',
  styleUrls: ['./dynamic-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DynamicFormComponent extends DynamicFormBaseComponent {
  constructor(formBuilder: FormBuilder, cd: ChangeDetectorRef) {
    super(formBuilder, cd);
  }
}
