<div class="bags">
  <div class="fl-1">
    <div class="headline" [class.large]="!subheading">{{ heading | translate }}</div>
    <div class="note" *ngIf="subheading">
      {{ subheading | translate }}
      <lh-shared-ui-tooltip
        [callToAction]="'MORE_INFO' | translate"
        [body]="subheadingInfo | translate"
        *ngIf="subheadingInfo"
      ></lh-shared-ui-tooltip>
    </div>
  </div>

  <div class="fl-2">
    <div class="bag-picker">
      <button class="btn btn-default me-3" (click)="decrementBags()"><div>–</div></button>
      <div class="bag-count me-3" [class.large]="!subheading">{{ numberOfBags }}</div>
      <button class="btn btn-default" (click)="incrementBags()"><div>+</div></button>
    </div>
  </div>
</div>
