<lh-shared-ui-spinner [visible]="isLoading"></lh-shared-ui-spinner>

<!-- E-mail sign-in -->
<button
  id="continueWithEmail"
  *ngIf="!isEmailFormVisible"
  class="btn btn-block btn-lg btn-primary btn-email"
  (click)="isEmailFormVisible = true"
>
  <i>
    <svg fill="#fff" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 0h24v24H0z" fill="none" />
      <path
        d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 14H4V8l8 5 8-5v10zm-8-7L4 6h16l-8 5z"
      />
    </svg> </i
  >{{ 'CONTINUE_WITH_EMAIL' | translate }}
</button>

<div *ngIf="isEmailFormVisible">
  <!-- New user form -->
  <form *ngIf="isNewUser" class="new-user-form" [formGroup]="newUserForm" (submit)="emailLogin()">
    <!-- Name -->
    <div class="form-group">
      <label for="name">{{ 'FULL_NAME' | translate }}</label>
      <input
        id="name"
        type="text"
        autocomplete="name"
        class="form-control"
        name="name"
        formControlName="name"
        [(ngModel)]="user.name"
        [placeholder]="(requireName ? (isStorageManager ? 'Jane Doe' : 'NAME') : 'NAME_OPTIONAL') | translate"
      />
      <div *ngIf="emailLoginButtonClicked && newUserForm.get('name').errors">
        <div *ngIf="newUserForm.get('name').errors.required" class="alert alert-danger">
          {{ 'NAME_IS_REQUIRED' | translate }}
        </div>
        <div *ngIf="newUserForm.get('name').errors.pattern" class="alert alert-danger">
          {{ 'INVALID_NAME' | translate }}
        </div>
      </div>
    </div>

    <!-- E-mail address -->
    <div class="form-group">
      <label for="email">{{ 'EMAIL_ADDRESS' | translate }}</label>
      <input
        id="email"
        type="email"
        autocomplete="email username"
        class="form-control"
        name="email"
        formControlName="email"
        [(ngModel)]="user.email"
        placeholder="jane.doe@example.com"
      />
      <div *ngIf="emailLoginButtonClicked && newUserForm.get('email').errors">
        <div *ngIf="newUserForm.get('email').errors.required" class="alert alert-danger">
          {{ 'EMAIL_REQUIRED' | translate }}
        </div>
        <div *ngIf="newUserForm.get('email').errors.pattern" class="alert alert-danger">
          {{ 'INVALID_EMAIL' | translate }}
        </div>
      </div>
    </div>

    <!-- Phone number -->
    <div class="form-group">
      <label for="phone">{{ 'SMS_PHONE_NUMBER' | translate }}</label>
      <lh-phone-input
        id="phone"
        formControlName="phone"
        [advancedMode]="true"
        [(ngModel)]="user.phone"
        [placeholder]="'SMS_PHONE_NUMBER' | translate"
        [countries]="phoneCountries"
        [(selectedCountry)]="user.phoneCountry"
        [requestFocus]="false"
        #phoneInput
      >
      </lh-phone-input>
      <div *ngIf="phoneInputError" class="alert alert-danger">{{ phoneInputError | translate }}</div>
    </div>

    <!-- Password -->
    <div class="form-group password-form-group mt-4" *ngIf="choosePassword">
      <label for="password">{{ 'CHOOSE_PASSWORD' | translate }}</label>
      <input
        id="password"
        [type]="showPassword ? 'text' : 'password'"
        autocomplete="new-password"
        class="form-control"
        name="password"
        formControlName="password"
        [(ngModel)]="user.password"
        [placeholder]="'CHOOSE_PASSWORD_MIN_LENGTH' | translate"
      />
      <a id="toggleShowPassword" class="toggle-show-password with-label href" (click)="showPassword = !showPassword">
        <span *ngIf="!showPassword">{{ 'SHOW_PASSWORD' | translate }}</span>
        <span *ngIf="showPassword">{{ 'HIDE_PASSWORD' | translate }}</span>
      </a>
      <div *ngIf="emailLoginButtonClicked && newUserForm.get('password').errors">
        <div *ngIf="newUserForm.get('password').errors.required" class="alert alert-danger">
          {{ 'CHOOSE_PASSWORD' | translate }}
        </div>
      </div>
    </div>

    <input type="submit" class="hidden" />
  </form>

  <!-- Existing user form -->
  <form *ngIf="isExistingUser" class="existing-user-form" [formGroup]="existingUserForm" (submit)="emailLogin()">
    <!-- Password reset -->
    <div *ngIf="isExistingUser" class="form-group text-end forgot-password">
      <small>
        {{ 'FORGOT_PASSWORD' | translate }}
        <a id="requestPasswordReset" class="href" (click)="resetPassword()">{{ 'RESET_PASSWORD' | translate }}</a>
      </small>
    </div>

    <div class="form-group">
      <input
        id="email"
        type="email"
        autocomplete="email username"
        class="form-control"
        name="email"
        formControlName="email"
        [(ngModel)]="user.email"
        [placeholder]="'EMAIL_ADDRESS' | translate"
      />
      <div *ngIf="isExistingUserEmailInvalid" class="alert alert-danger">
        {{ 'INVALID_EMAIL' | translate }}
      </div>
    </div>

    <div class="form-group password-form-group">
      <input
        id="password"
        [type]="showPassword ? 'text' : 'password'"
        autocomplete="current-password"
        class="form-control"
        name="password"
        formControlName="password"
        [(ngModel)]="user.password"
        [placeholder]="'PASSWORD' | translate"
      />
      <a id="toggleShowPassword" class="toggle-show-password href" (click)="showPassword = !showPassword">
        <span *ngIf="!showPassword">{{ 'SHOW_PASSWORD' | translate }}</span>
        <span *ngIf="showPassword">{{ 'HIDE_PASSWORD' | translate }}</span>
      </a>
      <div *ngIf="isExistingUserPasswordInvalid">
        <div *ngIf="existingUserForm.get('password').errors.required" class="alert alert-danger">
          {{ 'PASSWORD_REQUIRED' | translate }}
        </div>
      </div>
    </div>

    <input type="submit" class="hidden" />
  </form>

  <!-- E-mail login button -->
  <div class="form-group">
    <button
      id="emailSignIn"
      class="btn btn-block btn-primary btn-create"
      (click)="emailLogin()"
      [disabled]="(emailLoginButtonClicked && !currentEmailForm.valid) || isLoading"
    >
      {{ (isLoading ? 'PLEASE_WAIT' : isExistingUser ? 'LOG_IN_WITH_EMAIL' : 'REGISTER_NEW_USER') | translate }}
    </button>
  </div>

  <!-- Toggle new / existing user -->
  <div class="form-group text-center toggle-user-type">
    <div *ngIf="userType === 'new'">
      {{ 'ALREADY_HAVE_AN_ACCOUNT' | translate }}
      <a id="selectExistingUser" class="href" (click)="userType = 'existing'">{{ 'LOG_IN' | translate }}</a>
    </div>
    <div *ngIf="userType === 'existing'">
      {{ 'FIRST_TIME_USER' | translate }}
      <a id="selectNewUser" class="href" (click)="userType = 'new'">{{ 'REGISTER' | translate }}</a>
    </div>
  </div>
</div>

<!-- Large social sign-in buttons -->
<!-- <div *ngIf="true" class="row"> -->
<div *ngIf="!isEmailFormVisible && !forceEmailLogin" class="row">
  <!-- Divider before social sign-in buttons -->
  <div class="divider">
    <small class="divider-text">{{ 'OR_CONNECT_WITH' | translate | lowercase }}</small>
  </div>

  <!--Facebook sign-in-->
  <div class="col-12">
    <a id="facebookSignIn" class="btn btn-block btn-facebook" (click)="logInWithFacebook()">
      <i>
        <svg viewBox="0 0 32 32" role="presentation" aria-hidden="true" focusable="false">
          <path
            fill-rule="evenodd"
            d="M8 14.408v-4.165c0-.424.35-.812.77-.812h2.519V7.347c0-4.84 2.484-7.311 7.42-7.347 1.645 0 3.219.212 4.692.636.455.141.63.424.595.883l-.56 4.062c-.035.178-.14.354-.315.531-.21.105-.42.176-.63.14-.875-.247-1.784-.352-2.799-.352-1.399 0-1.61.283-1.61 1.73v1.8H22.6c.42 0 .805.423.805.883l-.349 4.17c0 .422-.35.705-.77.705H18.08v16c0 .424-.349.812-.769.812h-5.213c-.42 0-.804-.388-.804-.812V15.185h-2.52A.781.781 0 0 1 8 14.408"
          ></path>
        </svg> </i
      >Facebook
    </a>
  </div>

  <!--Google sign-in-->
  <div class="col-12">
    <a id="googleSignIn" class="btn btn-info btn-block btn-google" (click)="signInWithGoogle()">
      <i>
        <svg viewBox="0 0 18 18" role="presentation" aria-hidden="true" focusable="false">
          <g fill="none" fill-rule="evenodd">
            <path
              d="M9 3.48c1.69 0 2.83.73 3.48 1.34l2.54-2.48C13.46.89 11.43 0 9 0 5.48 0 2.44 2.02.96 4.96l2.91 2.26C4.6 5.05 6.62 3.48 9 3.48z"
              fill="#EA4335"
            ></path>
            <path
              d="M17.64 9.2c0-.74-.06-1.28-.19-1.84H9v3.34h4.96c-.1.83-.64 2.08-1.84 2.92l2.84 2.2c1.7-1.57 2.68-3.88 2.68-6.62z"
              fill="#4285F4"
            ></path>
            <path
              d="M3.88 10.78A5.54 5.54 0 0 1 3.58 9c0-.62.11-1.22.29-1.78L.96 4.96A9.008 9.008 0 0 0 0 9c0 1.45.35 2.82.96 4.04l2.92-2.26z"
              fill="#FBBC05"
            ></path>
            <path
              d="M9 18c2.43 0 4.47-.8 5.96-2.18l-2.84-2.2c-.76.53-1.78.9-3.12.9-2.38 0-4.4-1.57-5.12-3.74L.97 13.04C2.45 15.98 5.48 18 9 18z"
              fill="#34A853"
            ></path>
            <path d="M0 0h18v18H0V0z"></path>
          </g>
        </svg> </i
      >Google
    </a>
  </div>
</div>

<!-- Small social sign-in buttons -->
<!-- <div *ngIf="false" class="text-center form-group"> -->
<div *ngIf="isEmailFormVisible && !forceEmailLogin" class="text-center form-group">
  <!-- Divider before social sign-in buttons -->
  <div class="row mt-4"><div class="divider"></div></div>

  <!--Facebook sign-in-->
  <a id="facebookSignIn" class="btn btn-facebook btn-facebook-sm" (click)="logInWithFacebook()">
    <i>
      <svg viewBox="0 0 32 32" role="presentation" aria-hidden="true" focusable="false">
        <path
          fill-rule="evenodd"
          d="M8 14.408v-4.165c0-.424.35-.812.77-.812h2.519V7.347c0-4.84 2.484-7.311 7.42-7.347 1.645 0 3.219.212 4.692.636.455.141.63.424.595.883l-.56 4.062c-.035.178-.14.354-.315.531-.21.105-.42.176-.63.14-.875-.247-1.784-.352-2.799-.352-1.399 0-1.61.283-1.61 1.73v1.8H22.6c.42 0 .805.423.805.883l-.349 4.17c0 .422-.35.705-.77.705H18.08v16c0 .424-.349.812-.769.812h-5.213c-.42 0-.804-.388-.804-.812V15.185h-2.52A.781.781 0 0 1 8 14.408"
        ></path>
      </svg>
    </i>
  </a>

  <!--Google sign-in-->
  <a id="googleSignIn" class="btn btn-info btn-google btn-google-sm" (click)="signInWithGoogle()">
    <i>
      <svg viewBox="0 0 18 18" role="presentation" aria-hidden="true" focusable="false">
        <g fill="none" fill-rule="evenodd">
          <path
            d="M9 3.48c1.69 0 2.83.73 3.48 1.34l2.54-2.48C13.46.89 11.43 0 9 0 5.48 0 2.44 2.02.96 4.96l2.91 2.26C4.6 5.05 6.62 3.48 9 3.48z"
            fill="#EA4335"
          ></path>
          <path
            d="M17.64 9.2c0-.74-.06-1.28-.19-1.84H9v3.34h4.96c-.1.83-.64 2.08-1.84 2.92l2.84 2.2c1.7-1.57 2.68-3.88 2.68-6.62z"
            fill="#4285F4"
          ></path>
          <path
            d="M3.88 10.78A5.54 5.54 0 0 1 3.58 9c0-.62.11-1.22.29-1.78L.96 4.96A9.008 9.008 0 0 0 0 9c0 1.45.35 2.82.96 4.04l2.92-2.26z"
            fill="#FBBC05"
          ></path>
          <path
            d="M9 18c2.43 0 4.47-.8 5.96-2.18l-2.84-2.2c-.76.53-1.78.9-3.12.9-2.38 0-4.4-1.57-5.12-3.74L.97 13.04C2.45 15.98 5.48 18 9 18z"
            fill="#34A853"
          ></path>
          <path d="M0 0h18v18H0V0z"></path>
        </g>
      </svg>
    </i>
  </a>
</div>

<!-- Skip button for testing -->
<div class="text-center" *ngIf="showSkipLogin">
  <button id="skipLogin" class="btn btn-xs btn-link btn-skip" (click)="skipLogin()" [disabled]="isLoading">Skip</button>
</div>

<div class="tos-acceptance" *ngIf="!isStorageManager">
  <small>
    {{ 'ACCEPT_TERMS_PART_1' | translate }}
    <a target="_blank" href="https://luggagehero.com/terms-conditions/" class="tos-link href">
      {{ 'ACCEPT_TERMS_PART_2' | translate }}</a
    >
  </small>
</div>

<div class="padding-bottom"></div>
