<div class="menu-button" (click)="toggleMenu()" [class]="theme" [ngClass]="{ active: isOpen }">
  <div class="lines">
    <div></div>
    <div></div>
    <div></div>
  </div>

  <div class="status-circle"></div>
</div>

<lh-traveler-shops-ui-dropdown [isOpen]="isOpen">
  <ul class="lug-dropdown-content-list sm">
    <li>
      <a href="#">Link</a>
    </li>
    <li>
      <a href="#">Link</a>
    </li>
    <li>
      <a href="#">Link</a>
    </li>
  </ul>
</lh-traveler-shops-ui-dropdown>
