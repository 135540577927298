<div class="step-content">
  <div class="step-body">
    <div class="step-headline">
      <span *ngIf="!isStorageAccessRequired">{{ 'HAND_OVER_YOUR_LUGGAGE' | lhTranslate: variant }}</span>
      <span *ngIf="isStorageAccessRequired">{{ 'DROP_OFF_YOUR_LUGGAGE' | lhTranslate: variant }}</span>
    </div>
    <p>{{ 'BOOKING_READY_TIMER_STARTED' | lhTranslate: variant }}</p>

    <div class="storage-access-info" *ngIf="isStorageAccessRequired">
      <div class="storage-timer">
        <lh-storage-timer
          [booking]="booking"
          [isCheckInSession]="true"
          [darkMode]="false"
          [compactMode]="true"
          [largeDigits]="true"
        ></lh-storage-timer>
      </div>

      <div class="divider">
        <small class="divider-text" *ngIf="storageLocation.storageRoomAccessCode">
          {{ 'STORAGE_AREA_ACCESS_CODE' | lhTranslate: variant }}
        </small>
        <small class="divider-text" *ngIf="!storageLocation.storageRoomAccessCode">
          {{ 'STORAGE_AREA_ACCESS' | lhTranslate: variant }}
        </small>
      </div>

      <div class="access-code" *ngIf="storageLocation.storageRoomAccessCode">
        <div class="access-code-value">
          <span *ngFor="let char of storageLocation.storageRoomAccessCode.split('')">{{ char }}</span>
        </div>
      </div>
      <div class="access-text" *ngIf="storageLocation.storageRoomAccessText">
        {{ storageLocation.storageRoomAccessText }}
      </div>

      <div class="divider">
        <small class="divider-text">{{ 'NEXT_STEP' | lhTranslate: variant }}</small>
      </div>
    </div>

    <div class="image-input-container" *ngIf="!isStorageAccessRequired">
      <div class="image-input-wrapper">
        <lh-shared-feature-bag-image-input
          [remoteLuggageImage]="remoteLuggageImage"
          [localLuggageImage]="localLuggageImage"
          [storageLocation]="storageLocation"
          [booking]="booking"
        ></lh-shared-feature-bag-image-input>
      </div>
    </div>

    <div class="mt-4">
      <lh-storage-timer
        [booking]="booking"
        [isCheckInSession]="true"
        [darkMode]="false"
        [compactMode]="true"
        [largeDigits]="true"
      ></lh-storage-timer>
    </div>

    <p class="mt-2">
      {{ 'STOP_TIMER_UPON_RETURN' | lhTranslate: variant }}
    </p>
  </div>

  <div class="step-footer">
    <button class="btn btn-lg btn-info" (click)="goToBooking()">
      <span *ngIf="!isLoading">{{ 'GO_TO_MY_BOOKING' | lhTranslate: variant }}</span>
    </button>
  </div>
</div>
