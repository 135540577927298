import { Booking, IPaymentRecord, IUser, Review, SatisfactionScore } from '@luggagehero/shared/interfaces';
import { SharedUserService } from '@luggagehero/shared/services/users';

import { ManageBookingBaseComponent } from '../../manage-booking.base-component';
import { IManageBookingStrategy } from './manage-booking.strategy';

export class DefaultManageBookingStrategy implements IManageBookingStrategy {
  get activeSection(): string {
    if (!this.booking) {
      return 'reservation';
    }
    switch (this.booking.status) {
      case 'CONFIRMED':
        return 'checkIn';

      case 'CHECKED_IN':
        return 'checkOut';

      default:
        return this.isFeedbackSectionCompleted ? '' : 'feedback';
    }
  }

  get isReservationSectionVisible(): boolean {
    if (!this.booking) {
      return false;
    }
    return !['CANCELLED', 'CONFIRMED'].includes(this.booking.status);
  }

  get isPaymentMethodSectionVisible(): boolean {
    if (!this.booking) {
      return false;
    }
    return this.booking.status !== 'CANCELLED';
  }

  get isPaymentMethodSectionCompleted(): boolean {
    if (this.booking && this.booking.paymentMethodId) {
      return true;
    }
    return false;
  }

  get isPaymentReceiptVisible(): boolean {
    if (!this.booking) {
      return false;
    }
    return this.booking.status === 'CHECKED_OUT' || this.booking.status === 'PAID';
  }

  get isPaymentOptionsVisible(): boolean {
    if (!this.booking) {
      return false;
    }
    return ['CONFIRMED', 'CHECKED_IN', 'CHECKED_OUT'].includes(this.booking.status);
  }

  get isCheckInSectionVisible(): boolean {
    if (!this.booking) {
      return false;
    }
    if (this.booking.status === 'CANCELLED') {
      return false;
    }
    return true;
  }

  get isCheckInSectionHighlighted(): boolean {
    if (!this.booking) {
      return false;
    }
    return this.booking.status === 'CHECKED_IN';
  }

  get isCheckInSectionCompleted(): boolean {
    return this.isCheckOutSectionVisible;
  }

  get isCheckOutSectionVisible(): boolean {
    if (!this.booking) {
      return false;
    }
    return ['CHECKED_IN', 'CHECKED_OUT', 'PAID'].includes(this.booking.status);
  }

  get isCheckOutSectionHighlighted(): boolean {
    if (!this.booking) {
      return false;
    }
    return ['CHECKED_OUT', 'PAID'].includes(this.booking.status);
  }

  get isCheckOutSectionCompleted(): boolean {
    return this.booking && this.booking.status === 'PAID';
  }

  get isCheckOutSectionWarning(): boolean {
    if (!this.booking) {
      return false;
    }
    if (!this.paymentMethod) {
      return false;
    }
    return this.booking.status === 'PAID' && this.paymentMethod.provider === 'direct_payment';
  }

  get isCheckOutSectionFailed(): boolean {
    return this.booking && this.booking.status === 'CHECKED_OUT';
  }

  get showDirections(): boolean {
    if (!this.booking) {
      return false;
    }
    if (this.booking.status === 'CANCELLED') {
      return false;
    }
    return true;
  }

  get isFeedbackSectionVisible(): boolean {
    if (!this.booking) {
      return false;
    }
    return this.owner.isBookingCheckedOut;
  }

  get isFeedbackSectionCompleted(): boolean {
    if (!this.review) {
      return false;
    }
    if (this.review.rating || this.review.feedback) {
      return true;
    }
    return false;
  }

  get isCanceledSectionVisible(): boolean {
    if (!this.booking) {
      return false;
    }
    return this.booking.status === 'CANCELLED';
  }

  get isCanceledSectionCompleted(): boolean {
    return this.booking && this.booking.status === 'CANCELLED';
  }

  get showGoogleReview(): boolean {
    if (!this.review || !this.review.satisfaction || this.review.satisfaction < 2) {
      return false;
    }
    if (!this.booking || this.booking.address.countryCode !== 'us') {
      return false;
    }
    return true;
  }

  get review(): Review {
    return this.owner.review;
  }

  get booking(): Booking {
    return this.owner.booking;
  }

  get paymentMethod(): IPaymentRecord {
    return this.owner.paymentMethod;
  }

  get user(): IUser {
    return this.userService.user;
  }

  get isPaymentInfoDue(): boolean {
    return this.owner.booking.status === 'CHECKED_IN' && !this.isPaymentMethodSectionCompleted;
  }

  get isPaymentDue(): boolean {
    return ['CHECKED_IN', 'CHECKED_OUT'].includes(this.booking.status);
  }

  constructor(
    protected owner: ManageBookingBaseComponent,
    protected userService: SharedUserService,
  ) {}

  onBookingLoaded(satisfaction: SatisfactionScore) {
    if (!this.booking.paymentMethodId && !this.isCanceledSectionVisible) {
      this.owner.tutorialModal.show();
    }
    if (this.isFeedbackSectionVisible && !this.booking.feedbackReceived && typeof satisfaction === 'number') {
      this.owner.reviewComponent.setSatisfaction(satisfaction);
      this.owner.feedbackModal.show();
    }
  }

  onPaymentMethodUpdated() {
    // Not implemented
  }
}
