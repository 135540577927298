import { Inject, Injectable, InjectionToken } from '@angular/core';
import { Config } from '@luggagehero/shared/environment';
import { IDiscount, IPaymentRecord } from '@luggagehero/shared/interfaces';
import { SharedHttpService } from '@luggagehero/shared/services/http';
import { SharedUserService } from '@luggagehero/shared/services/users';
import { SharedUtilString } from '@luggagehero/shared/util';
import { BehaviorSubject } from 'rxjs';

type PaymentProvider = 'stripe' | 'paypal';

export interface InitiatePaymentRequest {
  provider: PaymentProvider;
  bookingId?: string;
  orderId?: string;
  storageLocationId?: string;
  returnUrl?: string;
}

export interface ReinitiatePaymentRequest extends InitiatePaymentRequest {
  providerId: string;
}

export interface InitiatePaymentResult {
  amount?: number;
  currency?: string;
  id?: string;
  client_secret?: string;
  redirectUrl?: string;
}

export interface CompletePaymentResponse {
  paymentMethod: {
    _id: string;
    providerRecord: {
      provider: string;
      data: { [key: string]: unknown };
    };
    created: Date | string;
  };
  accessToken?: string;
}

export const DISCOUNT_SERVICE_ENDPOINT_TOKEN = new InjectionToken<string>('DISCOUNT_SERVICE_ENDPOINT_TOKEN');
export const PAYMENT_SERVICE_ENDPOINT_TOKEN = new InjectionToken<string>('PAYMENT_SERVICE_ENDPOINT_TOKEN');

@Injectable({
  providedIn: 'root',
})
export class SharedPaymentService {
  public initatePaymentResult = new BehaviorSubject<InitiatePaymentResult>(null);

  constructor(
    @Inject(PAYMENT_SERVICE_ENDPOINT_TOKEN) private paymentsEndpoint: string,
    @Inject(DISCOUNT_SERVICE_ENDPOINT_TOKEN) private discountsEndpoint: string,
    private http: SharedHttpService,
    private user: SharedUserService,
  ) {}

  async initiatePayment(
    provider: PaymentProvider = 'stripe',
    storageLocationId?: string,
    orderId?: string,
    bookingId?: string,
    returnUrl?: string,
  ): Promise<IPaymentRecord<InitiatePaymentResult>> {
    const url = `${this.paymentsEndpoint}/initiate_setup`;

    const payload: InitiatePaymentRequest = {
      provider,
      storageLocationId,
      orderId,
      bookingId,
      returnUrl,
    };

    const res = await this.http.post<IPaymentRecord<InitiatePaymentResult>>(url, payload, this.user.isLoggedIn);

    if (!Config.isProduction) {
      console.debug(SharedUtilString.formatPaymentEvent(res.data, 'initiated'), {
        storageLocationId,
        orderId,
        bookingId,
      });
    }

    // Announce the result to any listeners
    this.initatePaymentResult.next(res.data);

    return res;
  }

  async reinitiatePayment(
    provider: PaymentProvider = 'stripe',
    providerId: string,
    storageLocationId: string,
    orderId: string,
  ): Promise<IPaymentRecord<InitiatePaymentResult>> {
    const url = `${this.paymentsEndpoint}/reinitiate_setup`;

    const payload: ReinitiatePaymentRequest = { provider, providerId, storageLocationId, orderId };

    const res = await this.http.put<IPaymentRecord<InitiatePaymentResult>>(url, payload, this.user.isLoggedIn);

    if (!Config.isProduction) {
      console.debug(SharedUtilString.formatPaymentEvent(res.data, 'reinitiated'), { storageLocationId, orderId });
    }

    // Announce the result to any listeners
    this.initatePaymentResult.next(res.data);

    return res;
  }

  async addPaymentMethod(value: IPaymentRecord, bookingId?: string): Promise<IPaymentRecord> {
    let url = `${this.paymentsEndpoint}/complete_setup`;

    if (bookingId) {
      url += `?bookingId=${bookingId}`;
    }

    const res = await this.http.post<CompletePaymentResponse>(url, value, this.user.isLoggedIn);

    const newPaymentMethod: IPaymentRecord = {
      id: res.paymentMethod._id,
      provider: res.paymentMethod.providerRecord.provider,
      data: res.paymentMethod.providerRecord.data,
      created: new Date(res.paymentMethod.created),
    };

    if (res.accessToken) {
      // We got logged in via the payment provider, save the access token and get user info
      this.user.accessToken = res.accessToken;
      await this.user.getUserInfo();
    }

    return newPaymentMethod;
  }

  async getPaymentMethods(bookingId?: string, _refresh = false): Promise<IPaymentRecord[]> {
    const url = bookingId
      ? `${Config.environment.TRAVELER_API}/v2/bookings/${bookingId}/payment_methods`
      : `${this.paymentsEndpoint}/payment_methods`;

    const res = await this.http.get<
      Array<{
        _id: string;
        providerRecord: {
          provider: string;
          data: { [key: string]: unknown };
        };
        created: Date | string;
      }>
    >(url, this.user.isLoggedIn);
    const currentPaymentMethods = this.parsePaymentMethodsResponse(res);

    return currentPaymentMethods;
  }

  async deletePaymentMethod(paymentMethodId: string): Promise<void> {
    const url = `${this.paymentsEndpoint}/payment_methods/${paymentMethodId}`;
    await this.http.delete(url, true);
  }

  async getDiscount(code: string): Promise<IDiscount> {
    const url = `${this.discountsEndpoint}?code=${code}`;
    const res = await this.http.get<IDiscount>(url, false);

    return res;
  }

  private parsePaymentMethodsResponse(
    response: Array<{
      _id: string;
      providerRecord: {
        provider: string;
        data: { [key: string]: unknown };
      };
      created: Date | string;
    }>,
  ): IPaymentRecord[] {
    const paymentMethods: IPaymentRecord[] = [];
    for (let i = 0; i < response.length; i++) {
      const paymentMethod: IPaymentRecord = {
        id: response[i]._id,
        provider: response[i].providerRecord.provider,
        data: response[i].providerRecord.data,
        created: new Date(response[i].created),
      };
      paymentMethods.push(paymentMethod);
    }
    // Sort newest to oldest so the most recently added payment method is selected by default
    return paymentMethods.sort((a, b) => b.created.getTime() - a.created.getTime());
  }
}
