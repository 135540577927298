import { AfterViewInit, ChangeDetectorRef, Component, inject, Input, OnInit, ViewChild } from '@angular/core';
import { PRICING_SERVICE, PricingService } from '@luggagehero/shared/interfaces';
import { NumberCardComponent } from '@swimlane/ngx-charts';

import { BaseComponent } from '../../../../core';

@Component({ template: '' })
export abstract class NumberCardBaseComponent extends BaseComponent implements OnInit, AfterViewInit {
  @ViewChild('ngxChartsNumberCard') numberCardComponent: NumberCardComponent;
  private pricingService = inject<PricingService>(PRICING_SERVICE);

  public valueFormatter = (data: { value: number }): string => this.formatValue(data);
  public results = undefined;

  public cardColor = '#f7f7f7';
  public bandColor = '#00d59b';
  public textColor = '#384347';

  private _label: string;
  private _value: number;
  private _currency: string;
  private _decimalCount = 0;
  private isInitialized = false;

  constructor(private cd: ChangeDetectorRef) {
    super();
  }

  public get label(): string {
    return this._label;
  }
  @Input() public set label(value: string) {
    this._label = value;

    if (this.isInitialized) {
      this.updateCard();
    }
  }

  public get value(): number {
    return this._value;
  }
  @Input() public set value(value: number) {
    this._value = value;

    if (this.isInitialized) {
      this.updateCard();
    }
  }

  public get currency(): string {
    return this._currency;
  }
  @Input() public set currency(value: string) {
    this._currency = value;

    if (this.isInitialized) {
      this.updateCard();
    }
  }

  public get decimalCount(): number {
    return this._decimalCount;
  }
  @Input() public set decimalCount(value: number) {
    this._decimalCount = value;

    if (this.isInitialized) {
      this.updateCard();
    }
  }

  ngOnInit(): void {
    this.updateCard();

    this.isInitialized = true;
  }

  ngAfterViewInit(): void {
    this.numberCardComponent.margin = [0, 0, 0, 0];
    this.numberCardComponent.update();
  }

  formatValue(data: { value: number }): string {
    if (this.currency) {
      return this.pricingService.format(data.value, this.currency, this.decimalCount, this.decimalCount === 0);
    }
    return data.value.toString();
  }

  updateCard() {
    this.results = [{ name: this.label, value: this.value }];
    this.cd.markForCheck();
  }
}
