import { inject, Injectable } from '@angular/core';
import { Config } from '@luggagehero/shared/environment';
import { EncryptedString } from '@luggagehero/shared/interfaces';
import { SharedHttpClient } from '@luggagehero/shared/services/http';
import { SharedUtilString } from '@luggagehero/shared/util';
import { map, Observable } from 'rxjs';

import { OrderRequest } from './+state/order-requests.models';
import { Order } from './+state/orders.models';

@Injectable({
  providedIn: 'root',
})
export class GuestOrderService {
  private client = inject(SharedHttpClient);

  public createOrder(request: OrderRequest): Observable<Order> {
    // TODO: Remove when API is updated to handle this
    delete request.addOns.loyaltyMember;

    const url = `${Config.environment.TRAVELER_API}/orders/request_quote`;

    return this.client
      .post<Order | EncryptedString>(url, request)
      .pipe(map((res) => SharedUtilString.tryDecryptAndParseString(res, Config.environment.CRYPTO_KEY)));
  }
}
