import { Product } from '../product.interface';

export const MULTI_BAG_REDUCE_STORAGE_RATES_MODIFIER_KEY = 'MULTI_BAG_REDUCE_STORAGE_RATES';
export const MULTI_DAY_REDUCE_STORAGE_RATES_MODIFIER_KEY = 'MULTI_DAY_REDUCE_STORAGE_RATES';

/**
 * @deprecated Use `OrderLine` instead
 */
export interface LegacyOrderLine {
  product: Product;
  quantity: number;
  showQuantity?: boolean;
  showQuantityAsUpTo?: boolean;
  unitPrice: number;
  showUnitPrice?: boolean;
  optional?: boolean;
  selected?: boolean;
  isRelative?: boolean;
  isPricing: boolean;
  pricingSuffix?: string;
  priority?: number;
}

export interface OrderLine {
  name: string; // Storage Day
  productKey: string;
  productId: string;
  units: number; // 3
  unitPrice: number; // 10
  price: number; // 30
  modifiedPrice?: ModifiedOrderLineInfo[];
  finalPrice: number;
  priceDelta: number;
}

export interface ModifiedOrderLineInfo {
  priceModifierId: string;
  priceModifierName: string;
  priceModifierKey: string; // for translations
  unitPrice: number;
  price: number; // 25
  priceDelta: number; // -5  if negative then discounted
  description?: string; // Multi Day Discount
}
