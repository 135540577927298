import { InjectionToken } from '@angular/core';

import { SharedAppEventService } from './shared-app-event.service';
import { SharedAppEventServiceConfig } from './shared-app-event-service-config.interface';

export const SHARED_APP_EVENT_SERVICE_TOKEN = new InjectionToken<SharedAppEventService>(
  'SHARED_APP_EVENT_SERVICE_TOKEN',
);
export const SHARED_APP_EVENT_SERVICE_CONFIG_TOKEN = new InjectionToken<SharedAppEventServiceConfig>(
  'SHARED_APP_EVENT_SERVICE_CONFIG_TOKEN',
);
