import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { StorageTimerBaseComponent } from '@luggagehero/features/bookings';

@Component({
  selector: 'lh-storage-timer',
  templateUrl: './storage-timer.component.html',
  styleUrls: ['./storage-timer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StorageTimerComponent extends StorageTimerBaseComponent {
  constructor(cd: ChangeDetectorRef) {
    super(cd);
  }
}
