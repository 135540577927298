<div class="info-header">
  <div class="container-fluid lh-container-fluid" [class.d-block.d-sm-none]="isShopOwner">
    <div class="flex">
      <h1 *ngIf="!userIdentifier || isShopOwner" class="heading-5">
        {{ 'ACCOUNT' | translate }} <span *ngIf="!userIdentifier">({{ 'EMAIL_MISSING' | translate }})</span>
      </h1>
      <h1 *ngIf="userIdentifier && !isShopOwner" class="heading-5">{{ userIdentifier }}</h1>
    </div>
  </div>
</div>

<div class="container-fluid lh-container-fluid p-b-30">
  <div class="row" *ngIf="!isShopOwner">
    <div class="row">
      <div class="form-group text-center">
        {{ 'LOOKING_FOR_BOOKING' | translate }} {{ 'GO_TO' | translate }}
        <a id="goToMyBookings" routerLink="/bookings/user/list" class="href">{{ 'MY_BOOKINGS' | translate }}</a>
      </div>
    </div>
  </div>

  <div class="row">
    <!--User information-->
    <div class="col-md-6">
      <div class="panel panel-default">
        <div class="panel-heading">
          <label>{{ 'USER_INFORMATION' | translate }}</label>
        </div>
        <div class="panel-body">
          <div class="row">
            <div class="col-12">
              <p>{{ 'MAKE_SURE_CONTACT_INFO_IS_UP_TO_DATE' | translate }}</p>
              <p *ngIf="user.email || user.phone">
                {{ 'CONTACT_FOR_EMAIL_OR_PHONE_CHANGE' | translate }}
              </p>
            </div>
          </div>

          <form [formGroup]="form">
            <div class="row">
              <div class="col col-12 col-sm-4">
                <div class="name">{{ (isShopOwner ? 'FULL_NAME' : 'NAME') | translate }}</div>
              </div>
              <div class="col col-12 col-sm-8 form-group">
                <div *ngIf="!isEditingUser" class="value">{{ user.name }}</div>
                <div *ngIf="isEditingUser">
                  <input
                    id="name"
                    type="text"
                    autocomplete="name"
                    class="form-control"
                    formControlName="name"
                    [(ngModel)]="newName"
                    [placeholder]="'FULL_NAME' | translate"
                  />
                  <div *ngIf="form.get('name').errors && form.get('name').errors.required" class="alert alert-danger">
                    {{ 'NAME_IS_REQUIRED' | translate }}
                  </div>
                  <div *ngIf="form.get('name').errors && form.get('name').errors.pattern" class="alert alert-danger">
                    {{ 'INVALID_NAME' | translate }}
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col col-12 col-sm-4">
                <div class="name">{{ 'EMAIL_ADDRESS' | translate }}</div>
              </div>
              <div class="col col-12 col-sm-8 form-group">
                <div *ngIf="!isEditingUser" class="value">{{ user.email }}</div>
                <div *ngIf="isEditingUser">
                  <!-- Only allow edit if user doesn't already have an email address -->
                  <input
                    id="email"
                    type="email"
                    autocomplete="email username"
                    class="form-control"
                    formControlName="email"
                    [(ngModel)]="newEmail"
                    [placeholder]="'EMAIL_ADDRESS' | translate"
                    [attr.disabled]="user.email ? true : null"
                  />
                  <div *ngIf="form.get('email').dirty && !form.get('email').valid" class="alert alert-danger">
                    {{ 'INVALID_EMAIL' | translate }}
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col col-12 col-sm-4">
                <div class="name">{{ 'SMS_PHONE_NUMBER' | translate }}</div>
              </div>
              <!-- Only allow edit if user doesn't already have a phone number -->
              <div class="col col-12 col-sm-8 form-group">
                <div *ngIf="!isEditingUser" class="value">{{ user.phone }}</div>
                <div *ngIf="isEditingUser">
                  <!-- TODO: Change to phone input with country picker -->
                  <input
                    id="phone"
                    type="tel"
                    class="form-control"
                    formControlName="phone"
                    [(ngModel)]="newPhone"
                    [placeholder]="'SMS_PHONE_NUMBER' | translate"
                    [attr.disabled]="user.phone ? true : null"
                  />
                  <div *ngIf="form.get('phone').dirty && !form.get('phone').valid" class="alert alert-danger">
                    {{ 'INVALID_PHONE_NUMBER_COUNTRY' | translate }}
                  </div>
                </div>
              </div>
            </div>
          </form>

          <div class="row">
            <div class="col-12 actions">
              <button
                id="startEditingUserInfo"
                class="btn btn-info"
                (click)="startEditingUserInfo()"
                *ngIf="!isEditingUser"
              >
                {{ 'EDIT' | translate }}
              </button>
              <button
                id="cancelEditUserInfo"
                class="btn btn-info"
                (click)="stopEditingUserInfo()"
                *ngIf="isEditingUser"
              >
                {{ 'CANCEL' | translate }}
              </button>
              <button
                id="saveUserInfo"
                class="btn btn-warning"
                [disabled]="isLoading || !form.valid"
                (click)="saveUserInfo()"
                *ngIf="isEditingUser"
              >
                {{ 'SAVE' | translate }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Security -->
    <div class="col-md-6">
      <div class="panel panel-default">
        <div class="panel-heading">
          <label>{{ 'SECURITY' | translate }}</label>
        </div>

        <div class="panel-body">
          <div class="row">
            <div class="col-12">
              <p>{{ 'Keep your password strong to prevent unauthorized access to your account' | translate }}</p>
            </div>
          </div>

          <div class="row">
            <div class="col col-12 col-sm-4">
              <div class="name">{{ 'PASSWORD' | translate }}</div>
            </div>
            <div class="col col-12 col-sm-8 form-group">••••••••</div>
          </div>

          <div class="row">
            <div class="col-12 actions">
              <button id="requestChangePassword" class="btn btn-info" (click)="changePassword()">
                {{ 'CHANGE' | translate }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--Payment methods-->
    <div class="col-md-6" *ngIf="!isShopOwner">
      <div class="panel panel-default">
        <div class="panel-heading">
          <label>{{ 'YOUR_PAYMENT_METHODS' | translate }}</label>
        </div>

        <div class="panel-body">
          <lh-manage-payment-methods></lh-manage-payment-methods>
        </div>
      </div>
    </div>

    <!--Delete user-->
    <div class="col-md-6" *ngIf="showDeleteAccount">
      <div class="panel panel-default">
        <div class="panel-heading">
          <label>{{ 'DELETE_ACCOUNT' | translate }}</label>
        </div>

        <div class="panel-body">
          <div>
            {{ 'DELETE_ACCOUNT_WARNING' | translate }}
          </div>
          <button
            (click)="deleteUserAccount()"
            type="button"
            style="margin-top: 20px; margin-bottom: 20px"
            class="btn btn-warning"
            [disabled]="isUserMarkedForDeletion"
          >
            {{ 'DELETE_MY_ACCOUNT' | translate }}
          </button>
          <div *ngIf="isUserMarkedForDeletion" class="alert alert-success">
            {{ 'DELETE_ACCOUNT_REQUEST_RECEIVED' | translate }}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!--Change password modal-->
<div
  class="modal fade"
  [class.modal-ios]="ios"
  bsModal
  #changePasswordModal="bs-modal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="mySmallModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-fullscreen-sm">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left">
          <div>{{ 'CHANGE_PASSWORD' | translate }}</div>
        </h4>
        <button
          id="closeChangePasswordModal"
          type="button"
          class="close pull-right"
          aria-label="Close"
          (click)="changePasswordModal.hide()"
        >
          <svg viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
            />
            <path d="M0 0h24v24H0z" fill="none" />
          </svg>
        </button>
      </div>

      <div class="modal-body">
        <div class="form-group">
          <input
            id="oldPassword"
            type="password"
            autocomplete="current-password"
            class="form-control"
            #password
            name="password"
            [(ngModel)]="currentPassword"
            [placeholder]="'OLD_PASSWORD' | translate"
            required
          />
        </div>
        <div class="form-group">
          <input
            id="password"
            type="password"
            autocomplete="new-password"
            class="form-control"
            #password
            name="password"
            [(ngModel)]="newPassword"
            [placeholder]="'NEW_PASSWORD' | translate"
            required
          />
        </div>
        <div class="actions">
          <button id="cancelChangePassword" class="btn btn-info" (click)="changePasswordModal.hide()">
            {{ 'CANCEL' | translate }}
          </button>
          <button id="saveNewPassword" class="btn btn-warning" [disabled]="isLoading" (click)="savePassword()">
            {{ 'SAVE' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
