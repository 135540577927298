import { ChangeDetectionStrategy, Component } from '@angular/core';
import { HowItWorksBaseComponent } from '@luggagehero/features/ui';

@Component({
  selector: 'lh-how-it-works',
  templateUrl: './how-it-works.component.html',
  styleUrls: ['./how-it-works.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HowItWorksComponent extends HowItWorksBaseComponent {}
