<form class="form" [formGroup]="userForm" (submit)="continue()">
  <lh-shared-ui-input
    type="email"
    autocomplete="email username"
    formControlName="email"
    [placeholder]="'EMAIL_ADDRESS' | lhTranslate"
    (focus)="onEmailFocus()"
  ></lh-shared-ui-input>

  @if (isExistingUser) {
    <lh-shared-ui-input
      type="tel"
      formControlName="password"
      [placeholder]="(isOtpEnabled ? 'CODE' : 'PASSWORD') | lhTranslate"
      [hidden]="!isPasswordFieldVisible"
      #passwordInput
    ></lh-shared-ui-input>
  }

  <input type="submit" hidden />
</form>

@if (isPasswordFieldVisible) {
  @if (isOtpEnabled) {
    <div class="text-center mt-2">{{ 'ENTER_VERIFICATION_CODE_EMAIL' | lhTranslate }}</div>
  }

  @if (!hideButtons) {
    <!-- E-mail login button -->
    <lh-shared-ui-button
      id="emailSignIn"
      type="button"
      class="mt-2"
      [size]="'medium'"
      [style]="'primary'"
      [block]="true"
      [disabled]="userForm.invalid || isLoading"
      (press)="continue()"
    >
      {{ (isLoading ? 'PLEASE_WAIT' : isExistingUser ? 'LOG_IN_WITH_EMAIL' : 'REGISTER_NEW_USER') | lhTranslate }}
    </lh-shared-ui-button>

    <!-- TODO: Add resend code? -->

    <!-- @if (isExistingUser) {
      <div *ngIf="isExistingUser && isPasswordFieldVisible" class="forgot-password">
        {{ 'FORGOT_PASSWORD' | lhTranslate }}
        <a id="requestPasswordReset" class="reset-pass-link" (click)="resetPassword()">
          {{ 'RESET_PASSWORD' | lhTranslate }}
        </a>
      </div>
    } -->
  }
} @else if (!isPasswordFieldVisible && !hideButtons) {
  <!-- Continue with email button -->
  <lh-shared-ui-button
    id="continueWithEmail"
    type="button"
    class="mt-2"
    [size]="'medium'"
    [style]="'warning'"
    [block]="true"
    [disabled]="isContinueWithEmailButtonDisabled"
    (press)="continue()"
  >
    @if (isLoading) {
      <lh-shared-ui-spinner [visible]="true"></lh-shared-ui-spinner>
    } @else {
      <span>{{ 'CONTINUE' | lhTranslate }}</span>
    }
  </lh-shared-ui-button>
}

@if (isTestMode) {
  <div class="text-center mt-2">
    <a id="skipLoginForTest" class="href" (click)="skipLoginForTest()">Skip</a>
  </div>
}
