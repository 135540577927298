import { Injectable } from '@angular/core';
import { AppConfig } from '@luggagehero/shared/app-settings/data-access';
import { Config } from '@luggagehero/shared/environment';
import { ITheme, IThemeColor } from '@luggagehero/shared/interfaces';
import { SharedDocumentService } from '@luggagehero/shared/services/document';
import { SharedHttpService } from '@luggagehero/shared/services/http';
import { SharedStorageService } from '@luggagehero/shared/services/storage';
import { ColorUtil, SharedUtilCSS, SharedUtilString } from '@luggagehero/shared/util';
import { ReplaySubject } from 'rxjs';

const SVG_ICONS_URL = 'assets/icons.svg';
const SVG_ICONS_VERSION = 5;
const UI_THEME_VERSION = 4;
export const DEFAULT_MAP_MARKER_COLOR = '#FF4B00';
export const DEFAULT_ACTIVE_MAP_MARKER_COLOR = '#FF4B00';
export const DEFAULT_MAP_CIRCLE_FILL_COLOR = '#3BC1ED';
export const DEFAULT_MAP_CIRCLE_STROKE_COLOR = '#0A7EA5';

export class ThemeColor implements IThemeColor {
  private constructor(public value: string) {}

  static fromHex(value: string): ThemeColor {
    if (!value) {
      return null;
    }
    return new ThemeColor(value);
  }

  public get dark(): string {
    return ColorUtil.darken(this.value, AppConfig.THEME_COLOR_DARK_PERCENTAGE);
  }

  public get light(): string {
    return ColorUtil.lighten(this.value, AppConfig.THEME_COLOR_LIGHT_PERCENTAGE);
  }

  public get isLight(): boolean {
    return ColorUtil.isLight(this.value);
  }

  public get isDark(): boolean {
    return ColorUtil.isDark(this.value);
  }
}

@Injectable({
  providedIn: 'root',
})
export class SharedThemeService {
  public theme$ = new ReplaySubject<ITheme>();
  public isInitialized = false;

  private endpoint: string;
  private _theme: ITheme;

  constructor(
    private httpService: SharedHttpService,
    private storageService: SharedStorageService,
    private documentService: SharedDocumentService,
  ) {
    this.endpoint = `${Config.environment.TRAVELER_API}/themes`;
  }

  public get theme(): ITheme {
    return this._theme;
  }

  public get mapMarkerIcon(): string {
    return 'assets/markers/map-marker.svg';
  }

  public get activeMapMarkerIcon(): string {
    return 'assets/markers/map-marker-active.svg';
  }

  public get unavailableMapMarkerIcon(): string {
    return 'assets/markers/map-marker-unavailable.svg';
  }

  public get mapMarkerBestChoiceIcon(): string {
    return 'assets/markers/map-marker-best-choice.svg';
  }
  public get activeMapMarkerBestChoiceIcon(): string {
    return 'assets/markers/map-marker-best-choice-active.svg';
  }
  public get unavailableMapMarkerBestChoiceIcon(): string {
    return 'assets/markers/map-marker-best-choice-unavailable.svg';
  }

  public get mapCircleFillColor(): string {
    return this._theme?.primaryColor.light || DEFAULT_MAP_CIRCLE_FILL_COLOR;
  }

  public get mapCircleStrokeColor(): string {
    return this._theme?.primaryColor.dark || DEFAULT_MAP_CIRCLE_STROKE_COLOR;
  }

  public set storedTheme(value: ITheme) {
    this.storageService.uiTheme = value;
  }
  public get storedTheme(): ITheme {
    return this.storageService.uiTheme;
  }

  public set storedThemeVersion(value: number) {
    this.storageService.uiThemeVersion = value;
  }
  public get storedThemeVersion(): number {
    return this.storageService.uiThemeVersion;
  }

  public async init(theme?: string): Promise<void> {
    if (this.isInitialized) {
      return;
    }
    await this.loadThemableIcons();
    await this.loadThemeStyling(theme);
  }

  public async getTheme(theme: string): Promise<ITheme> {
    if (!theme) {
      return null;
    }
    const url = `${this.endpoint}/${theme}`;
    const res = await this.httpService.get<ITheme>(url, false);

    // Record if this theme belongs to an organization
    res.organizationId = SharedUtilString.isValidObjectId(theme) ? theme : null;

    return res;
  }

  public async loadThemeStyling(theme?: string) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    theme = theme || (this.documentService.queryParams.theme as string);

    //
    // HACK: This is to ensure backwards compatibility with kiwi URLs that
    // use 'utm_source' rather than 'theme' query param
    //
    if (
      !theme &&
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      this.documentService.queryParams.utm_source
    ) {
      // eslint-disable-next-line  @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-assignment
      const utmSource = this.documentService.queryParams.utm_source;
      if (Array.isArray(utmSource) && utmSource.includes('kiwi')) {
        theme = 'kiwi';
      } else if (typeof utmSource === 'string' && utmSource.toLowerCase() === 'kiwi') {
        theme = 'kiwi';
      }
    }

    let themeData: ITheme;

    if (this.isStoredThemeValid(theme)) {
      themeData = this.storedTheme;
    } else {
      try {
        themeData = !theme || theme === 'none' ? null : await this.getTheme(theme);
        this.storedTheme = themeData;
        this.storedThemeVersion = UI_THEME_VERSION;
      } catch {
        // A theme with the given name was not found; fall back to stored theme value
        themeData = this.storedTheme;
      }
    }
    if (themeData) {
      if (themeData.name === 'ua' && !themeData.link) {
        themeData.link = 'https://donate.redcrossredcrescent.org/ua/donate/~my-donation';
      }

      // Create theme instance from theme info
      this._theme = {
        name: themeData.name,
        logoStyle: themeData.logoStyle,
        images: themeData.images,
        primaryColor: ThemeColor.fromHex(themeData.colors.primary),
        accentColor: ThemeColor.fromHex(themeData.colors.accent),
        headerColor: ThemeColor.fromHex(themeData.colors.header),
        link: themeData.link,
        organizationId: themeData.organizationId,
        colors: themeData.colors,
      };

      // Set root styling on document to enable theme
      const themeCss = SharedUtilCSS.generateRootCss(this._theme);
      this.documentService.addHeadStyling(themeCss, 'lh-theme');
    } else {
      // No theme found; remove any existing theme styling
      this._theme = null;
      this.documentService.removeHeadStyling('lh-theme');
    }
    this.isInitialized = true;
    this.theme$.next(this._theme);
  }

  public async loadThemableIcons() {
    let svg = this.storageService.svgIcons;
    if (!svg || this.storageService.svgIconsVersion !== SVG_ICONS_VERSION) {
      try {
        svg = await this.downloadSvgData();
        this.storageService.svgIcons = svg;
        this.storageService.svgIconsVersion = SVG_ICONS_VERSION;
      } catch {
        // Ignore
      }
    }
    if (svg) {
      this.documentService.addBodySvg(svg);
    }
  }

  public async removeThemeStyling(): Promise<void> {
    return this.loadThemeStyling('none');
  }

  private async downloadSvgData(): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      try {
        const request = new XMLHttpRequest();
        request.open('GET', SVG_ICONS_URL, true);
        request.onload = () => {
          if (request.status >= 200 && request.status < 400) {
            resolve(request.responseText);
          } else {
            reject(`${request.status} ${request.statusText}`);
          }
        };
        request.onerror = () => {
          reject(`${request.status} ${request.statusText}`);
        };
        request.send();
      } catch (err) {
        reject(err);
      }
    });
  }

  private isStoredThemeValid(newTheme: string): boolean {
    if (!this.storedTheme) {
      // No stored theme
      return false;
    }

    if (!newTheme) {
      // No new theme was provided; keep existing theme
      return true;
    }

    const isSameTheme = SharedUtilString.isValidObjectId(newTheme)
      ? newTheme === this.storedTheme.organizationId
      : newTheme === this.storedTheme.name;

    if (isSameTheme) {
      // Stored theme matches new theme; check version
      return this.storedThemeVersion === UI_THEME_VERSION;
    }

    // We need to load the new theme and replace the stored theme
    return false;
  }
}
