import { Injectable } from '@angular/core';
import { datadogLogs } from '@datadog/browser-logs';
import { Config } from '@luggagehero/shared/environment';
import { SharedWindowService } from '@luggagehero/shared/services/window';

import { LogEvent, LogLevel, LogTargetBase, LogTargetOptions } from './log.target';

@Injectable()
export class DataDogTarget extends LogTargetBase {
  private static initialized = false;
  constructor(
    options: LogTargetOptions,
    private windowService: SharedWindowService,
  ) {
    super(options);
  }

  writeToLog(event: LogEvent) {
    if (Config.isDevelopment) {
      console.dir(event);
      return;
    }
    if (!DataDogTarget.initialized) {
      this.initializeDatadog();
      DataDogTarget.initialized = true;
    }

    switch (event.level) {
      case LogLevel.Debug:
        datadogLogs.logger.debug(event.message, event.context, event.error);
        break;
      case LogLevel.Info:
        datadogLogs.logger.info(event.message, event.context, event.error);
        break;
      case LogLevel.Warning:
        datadogLogs.logger.warn(event.message, event.context, event.error);
        break;
      case LogLevel.Error:
        datadogLogs.logger.error(event.message, event.context, event.error);
        break;
    }
    return Promise.resolve();
  }

  private initializeDatadog() {
    {
      let serviceName = this.windowService.appName;
      if (this.windowService.isNative) {
        serviceName = 'native-app-traveler';
      }
      datadogLogs.init({
        clientToken: Config.environment.DATADOG_CLIENT_TOKEN,
        site: Config.environment.DATADOG_SITE,
        forwardErrorsToLogs: false, // Enable after we have received angular errors in datadog
        service: serviceName,
        version: this.windowService.appVersion,
        env: Config.environment.ENV,
      });
    }
  }
}
