import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class RedirectGuard {
  constructor(private router: ActivatedRoute) {}

  canActivate(route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): boolean {
    const externalUrl: (() => string) | undefined = route.data['externalUrl'] as (() => string) | undefined;
    if (typeof externalUrl === 'function') {
      const resolvedUrl: string = externalUrl();
      if (typeof resolvedUrl === 'string' && resolvedUrl) {
        window.location.href = resolvedUrl;
        return false;
      }
    }
    return true;
  }
}
