import { ChangeDetectionStrategy, Component } from '@angular/core';
import { PlatformLogoBaseComponent } from '@luggagehero/features/ui';

@Component({
  selector: 'lh-platform-logo',
  templateUrl: './platform-logo.component.html',
  styleUrls: ['./platform-logo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlatformLogoComponent extends PlatformLogoBaseComponent {}
