import { Component, Input } from '@angular/core';
import { StorageCriteria } from '@luggagehero/shared/interfaces';
import { SharedStorageCriteriaService } from '@luggagehero/shared/services/storage-criteria';

import { BaseComponent } from '../../../core';

@Component({ template: '' })
export abstract class SimpleBagPickerBaseComponent extends BaseComponent {
  @Input() public maxBags: number;
  @Input() public heading = 'HOW_MANY_BAGS_SHORT';
  @Input() public subheading = 'WHAT_CAN_I_STORE_TEXT';
  @Input() public subheadingInfo = 'WHAT_CAN_I_STORE_TOOLTIP';

  constructor(private criteriaService: SharedStorageCriteriaService) {
    super();
  }

  public get criteria(): StorageCriteria {
    return this.criteriaService.currentOrDefault;
  }

  public get numberOfBags(): number {
    return this.criteria.luggage.hand + this.criteria.luggage.normal;
  }

  public incrementBags() {
    if (this.criteriaService.luggage.normal >= this.maxBags) {
      return;
    }
    this.criteriaService.luggage = { hand: 0, normal: this.criteria.luggage.normal + 1 };
  }

  public decrementBags() {
    if (this.criteria.luggage.normal === 1) {
      return;
    }
    this.criteriaService.luggage = { hand: 0, normal: this.criteria.luggage.normal - 1 };
  }
}
