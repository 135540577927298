import { Injectable, Provider } from '@angular/core';

import { ConsoleService } from './console.service';
import { LogEvent, LogLevel, LogTarget, LogTargetBase, LogTargetOptions } from './log.target';

@Injectable()
export class ConsoleTarget extends LogTargetBase {
  constructor(
    private console: ConsoleService,
    options: LogTargetOptions,
  ) {
    super(options);
  }

  writeToLog(event: LogEvent) {
    const data: unknown[] = Array.isArray(event.message) ? event.message : [event.message];

    switch (event.level) {
      case LogLevel.Debug:
        this.console.debug(...data);
        break;
      case LogLevel.Info:
        this.console.info(...data);
        break;
      case LogLevel.Warning:
        this.console.warn(...data);
        break;
      case LogLevel.Error:
        this.console.error(event.message, event.context, event.error);
        break;
    }
    return Promise.resolve();
  }
}

export function createConsoleTarget(level: LogLevel, consoleService: ConsoleService) {
  return new ConsoleTarget(consoleService, { minLogLevel: level });
}

export function provideConsoleTarget(logLevel: LogLevel): Provider {
  return {
    provide: LogTarget,
    deps: [ConsoleService],
    multi: true,
    useFactory: (c: ConsoleService) => new ConsoleTarget(c, { minLogLevel: logLevel }),
  };
}
