import { ChangeDetectionStrategy, Component } from '@angular/core';
import { PaymentStepBaseComponent } from '@luggagehero/features/shops';

@Component({
  selector: 'lh-payment-step',
  templateUrl: './payment-step.component.html',
  styleUrls: ['./payment-step.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class PaymentStepComponent extends PaymentStepBaseComponent {}
