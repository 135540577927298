<div class="calendar-dropdown">
  <button id="toggleTimePeriodFrom" class="btn btn-default lh-btn" (click)="toggleFrom()" #fromAnchor>
    <i class="icon-calendar"></i>
    <span>{{ from | lhMoment: (useModal ? 'calendar' : 'localeDateTime') | uppercaseFirstLetter }}</span>
  </button>
</div>

<!--Large screen from-->
<div *ngIf="!useModal">
  <kendo-popup
    class="date-time-popup-wrapper"
    [anchor]="fromAnchor"
    (click)="toggleFromAndApply()"
    #fromPopup
    (anchorViewportLeave)="showFrom = false"
    [hidden]="!showFrom"
  >
    <div class="popup-wrapper-content">
      <lh-date-time-picker #fromPicker [(ngModel)]="from" [disabledDates]="disabledDates"></lh-date-time-picker>
      <!-- <button id="applyTimePeriodFrom" *ngIf="fromPicker.isSelectTimeEnabled" type="button" class="btn btn-primary apply dropdown-toggle" (click)="toggleFromAndApply()">
        {{ 'APPLY' | translate }}
      </button> -->
    </div>
  </kendo-popup>
</div>

<!--Small screen from-->
<div *ngIf="useModal">
  <kendo-popup
    class="date-time-popup-wrapper-sm from-dp"
    [anchor]="fromAnchor"
    #fromPopup
    (anchorViewportLeave)="showFrom = false"
    [hidden]="!showFrom"
  >
    <div class="popup-wrapper-content">
      <!-- <div class="datepicker-title">
        {{'EXPECTED_DROP_OFF' | translate}}
      </div> -->
      <lh-date-time-picker #fromPicker [(ngModel)]="from" [disabledDates]="disabledDates"></lh-date-time-picker>
      <!-- <button
        id="applyTimePeriodFrom"
        type="button"
        class="btn btn-primary apply dropdown-toggle"
        (click)="toggleFromAndApply()"
      >
        {{ 'APPLY' | translate }}
      </button> -->
    </div>
  </kendo-popup>
</div>

<!--Arrow-->
<svg
  *ngIf="!useModal"
  class="arrow"
  width="40px"
  height="31px"
  viewBox="0 0 40 31"
  version="1.1"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
>
  <path
    d="M0,12.4 L24.6153846,12.4 L24.6153846,18.6 L0,18.6 L0,12.4 Z M40,15.5 L24.6153846,31 L24.6153846,0 L40,15.5 Z"
  ></path>
</svg>

<div *ngIf="!useModal">
  <button id="toggleTimePeriodTo" #toAnchor type="button" class="btn btn-default lh-btn" (click)="toggleTo()">
    {{ to | lhMoment: 'localeDateTime' }}
  </button>
</div>

<!--Large screen to-->
<div *ngIf="!useModal">
  <kendo-popup
    class="date-time-popup-wrapper"
    [anchor]="toAnchor"
    (click)="toggleToAndApply()"
    #toPopup
    (anchorViewportLeave)="showTo = false"
    *ngIf="showTo"
  >
    <div class="popup-wrapper-content">
      <lh-date-time-picker #toPicker [(ngModel)]="to" [disabledDates]="disabledDates"></lh-date-time-picker>
      <button
        id="applyTimePeriodTo"
        *ngIf="toPicker.isSelectTimeEnabled"
        type="button"
        class="btn btn-primary apply dropdown-toggle"
        (click)="toggleToAndApply()"
      >
        {{ 'APPLY' | translate }}
      </button>
    </div>
  </kendo-popup>
</div>

<!--Small screen to-->
<div *ngIf="useModal">
  <kendo-popup
    class="date-time-popup-wrapper-sm to-dp"
    [anchor]="toAnchor"
    #toPopup
    (anchorViewportLeave)="showTo = false"
    *ngIf="showTo"
  >
    <div class="popup-wrapper-content">
      <!-- <div class="datepicker-title">
        {{'EXPECTED_PICK_UP' | translate}}
      </div> -->
      <lh-date-time-picker #toPicker [(ngModel)]="to" [disabledDates]="disabledDates"></lh-date-time-picker>
      <button
        id="applyTimePeriodTo"
        type="button"
        class="btn btn-primary apply dropdown-toggle"
        (click)="toggleToAndApply()"
      >
        {{ 'APPLY' | translate }}
      </button>
    </div>
  </kendo-popup>
</div>
