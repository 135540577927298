import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { TooltipModule } from 'ngx-bootstrap/tooltip';

@Component({
  selector: 'lh-shared-ui-tooltip',
  standalone: true,
  imports: [CommonModule, TranslateModule, TooltipModule],
  templateUrl: './shared-ui-tooltip.component.html',
  styleUrls: ['./shared-ui-tooltip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SharedUITooltipComponent {
  @Input() callToAction?: string;
  @Input() header?: string;
  @Input() body?: string;
  @Input() darkMode?: boolean;
  @Input() compactMode?: boolean;
}
