import { Inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { map, Observable } from 'rxjs';

import { ILang } from '../../core';
import * as multilingual from '../actions/multilingual.action';
import { Languages } from '../services/multilingual.service';

@Injectable()
export class MultilingualEffects {
  change$: Observable<Action>;

  constructor(
    private actions$: Actions,
    @Inject(Languages) private languages: ILang[],
  ) {
    this.change$ = createEffect(() =>
      this.actions$.pipe(
        ofType(multilingual.ActionTypes.CHANGE),
        map((action: multilingual.ChangeAction) => {
          const lang = action.payload;

          if (this.languages.map((l) => l.code).includes(lang)) {
            // change state
            return new multilingual.LangChangedAction(lang);
          } else {
            // not supported (here for example)
            return new multilingual.LangUnsupportedAction(lang);
          }
        }),
      ),
    );
  }
}
