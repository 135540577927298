<lh-ticket-stub>
  <div class="order-summary">
    <div class="header" [class.compact]="showBagPicker">
      <div class="pre-title">{{ 'LUGGAGE_STORAGE' | translate }}</div>
      <div class="headline" [innerHtml]="headline"></div>
      <div class="subtitle" [innerHtml]="subtitle | uppercaseFirstLetter"></div>
    </div>

    <!-- Bag selector -->
    <div class="text-center" *ngIf="showBagPicker">
      <lh-bag-selector
        [(ngModel)]="numberOfBags"
        [(bagsSelected)]="bagsSelected"
        [maxInlineBags]="3"
        [addBagSuffix]="true"
        [compact]="true"
      ></lh-bag-selector>

      <!-- Divider -->
      <div class="row item">
        <div class="col-12">
          <hr />
        </div>
      </div>
    </div>

    <!-- Order lines -->
    <div *ngFor="let line of orderLines; let i = index" class="row item">
      <div class="col-8">
        <div class="item-label">
          <label class="me-3">
            {{ line.description }}
            <input
              *ngIf="line.optional"
              [id]="'orderLine' + i + 'Selected'"
              [name]="'orderLine' + i + 'Selected'"
              type="checkbox"
              [(ngModel)]="line.selected"
              (change)="line.onSelectedChange && line.onSelectedChange(line.selected)"
            />
          </label>

          <span class="qty-buttons" *ngIf="line.quantity >= 0">
            <button class="btn btn-info btn-qty" (click)="decrementQty(i)">–</button>
            <button class="btn btn-info btn-qty" (click)="incrementQty(i)">+</button>
          </span>

          <span *ngIf="line.tooltip" class="tooltip-cta" [tooltip]="tooltipTemplate">
            <i class="icon-info"></i>
          </span>
          <ng-template #tooltipTemplate>
            <div class="tooltip-content">
              <div *ngIf="line.tooltip.header" class="tooltip-header">{{ line.tooltip.header }}</div>
              <div class="tooltip-body">{{ line.tooltip.body }}</div>
            </div>
          </ng-template>
        </div>
      </div>
      <div
        class="col-4 item-price"
        [class.text-success]="line.discount || line.priceValue < 0"
        [class.disabled]="(line.optional && !line.selected) || line.quantity === 0"
      >
        <span *ngIf="line.optional || line.minQuantity === 0">+ </span>
        {{ line.priceText }}
      </div>
      <div class="col-12 item-detail" *ngIf="line.detail">{{ line.detail }}</div>
    </div>

    <!-- Divider -->
    <div class="row item">
      <div class="col-12">
        <hr />
      </div>
    </div>

    <div class="row item">
      <!-- Promo call-to-action -->
      <div class="col-6 discount-cta">
        <a *ngIf="!discount" id="addPromoCode" class="href" (click)="requestPromoCodeModal()">{{
          'HAVE_A_VOUCHER_OR_PROMO_CODE_MINI' | translate
        }}</a>
        <a *ngIf="discount" id="addPromoCode" class="href" (click)="requestPromoCodeModal()">{{
          'CHANGE_DISCOUNT_CODE' | translate
        }}</a>
      </div>

      <!-- Total -->
      <div class="col-6 total-amount">
        <span class="item-label-light">{{ totalLabel }}</span>
        <span class="item-price-lg">{{ orderTotal | price: currency : 2 : false }}</span>
      </div>
      <div class="col-12 total-alert" *ngIf="totalAlertText">
        <div class="alert alert-success">{{ totalAlertText | translate }}</div>
      </div>
    </div>
  </div>
</lh-ticket-stub>
