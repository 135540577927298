import { Directive } from '@angular/core';

import { ModalRef } from '../interfaces';
import { BaseComponent } from './base-component';

@Directive()
export abstract class BaseModalComponent extends BaseComponent {
  public modalRef: ModalRef;

  public get isModal(): boolean {
    return this.modalRef ? true : false;
  }

  public async hideModal(result?: unknown): Promise<void> {
    await this.modalRef?.hide(result);
  }
}
