import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GoogleMapsModule } from '@angular/google-maps';
import { RouterModule } from '@angular/router';
import { UiSharedModule } from '@luggagehero/features/ui';
import { PlacesSearchComponent } from '@luggagehero/shared/places/feature-places-search';
import {
  SharedUiApplePayButtonComponent,
  SharedUiConfirmButtonComponent,
  SharedUiGooglePayButtonComponent,
  SharedUiImageInputComponent,
  SharedUiPaypalButtonComponent,
  SharedUiSpinnerComponent,
  SharedUiTagListComponent,
  SharedUITooltipComponent,
  SharedUiUserProfileImageComponent,
} from '@luggagehero/shared/ui';
import { CalendarModule, DateInputsModule, DatePickerModule } from '@progress/kendo-angular-dateinputs';
import { PopupModule } from '@progress/kendo-angular-popup';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { FileUploadModule } from 'ng2-file-upload';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';
import { RatingModule } from 'ngx-bootstrap/rating';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { WebcamModule } from 'ngx-webcam';

import { COMPONENTS } from './components';
import { DIRECTIVES } from './directives';

const MODULES = [
  CommonModule,
  RouterModule,
  FormsModule,
  ReactiveFormsModule,
  CalendarModule,
  DatePickerModule,
  DateInputsModule,
  PopupModule,
  BsDropdownModule,
  CollapseModule,
  ModalModule,
  TooltipModule,
  GoogleMapsModule,
  NgxChartsModule,
  UiSharedModule,
  FileUploadModule,
  WebcamModule,
  PlacesSearchComponent,
  SharedUiImageInputComponent,
  SharedUiSpinnerComponent,
  SharedUiConfirmButtonComponent,
  SharedUiApplePayButtonComponent,
  SharedUiGooglePayButtonComponent,
  SharedUiPaypalButtonComponent,
  SharedUiUserProfileImageComponent,
  SharedUiTagListComponent,
];

@NgModule({
  imports: [
    ...MODULES,
    BsDropdownModule.forRoot(),
    CollapseModule.forRoot(),
    ModalModule.forRoot(),
    RatingModule.forRoot(),
    SharedUITooltipComponent,
  ],
  exports: [...MODULES, ...COMPONENTS, ...DIRECTIVES, PlacesSearchComponent],
  declarations: [...COMPONENTS, ...DIRECTIVES],
})
export class UiModule {}
