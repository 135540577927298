import { ChangeDetectionStrategy, Component } from '@angular/core';
import { SimpleBagPickerBaseComponent } from '@luggagehero/features/ui';

@Component({
  selector: 'lh-simple-bag-picker',
  templateUrl: './simple-bag-picker.component.html',
  styleUrls: ['./simple-bag-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SimpleBagPickerComponent extends SimpleBagPickerBaseComponent {}
