<div
  class="shop-card"
  [ngClass]="{ 'is-highlighted': isHighlighted, 'is-best-choice': !!recommendedTag, 'inside-modal': insideModal }"
  (click)="cardClicked()"
>
  <div *ngIf="recommendedTag && !insideModal" class="best-choice-display">
    <lh-traveler-shops-ui-best-choice-display [text]="recommendedTag" />
  </div>

  <div class="card-subtitle">{{ subtitle }}</div>
  <div class="card-title">{{ title }}</div>

  @if (holidayNoticeHtml && !insideModal) {
    <div class="mt-2">
      <lh-traveler-shops-ui-holiday-notice [html]="holidayNoticeHtml" />
    </div>
  }
  <div class="lines">
    <lh-traveler-shops-ui-availability-display [availability]="availability" />
    <lh-traveler-shops-ui-shop-rating [shopRating]="shopRating" />

    @if (pricingHourly !== null) {
      <div class="pricing-wrapper">
        <lh-traveler-shops-ui-pricing-display [pricing]="pricingHourly" />
        @if (pricingDaily && pricingHourly) {
          <span class="slash"> / </span>
          <lh-traveler-shops-ui-pricing-display [pricing]="pricingDaily" />
        }
      </div>
    }

    <lh-traveler-shops-ui-distance-display [distance]="distance" />
  </div>

  <div class="card-footer">
    @if (badges && badges.length) {
      <div class="badges" [class.is-compact]="insideModal">
        @for (badge of badges; track badge) {
          <div class="badge">{{ badge.text | uppercaseFirstLetter: true }}</div>
        }
      </div>
    }

    <div class="ms-auto book-button" [class.show]="showBookButton">
      <lh-traveler-shops-ui-button
        [size]="'sm'"
        [style]="bookButtonStyle"
        (clicked)="$event.stopPropagation(); bookNowClicked()"
        >{{ getTranslation('BOOK_NOW') }}</lh-traveler-shops-ui-button
      >
    </div>
  </div>
</div>
