import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, Input, OnInit, Type } from '@angular/core';
import { SharedAppSettingsService } from '@luggagehero/shared/app-settings/data-access';
import { Config } from '@luggagehero/shared/environment';
import { BookableStorageLocation } from '@luggagehero/shared/interfaces';
import { SharedWindowService } from '@luggagehero/shared/services/window';
import {
  SharedUiWizardComponent,
  WizardConfig,
  WizardStepBaseComponent,
  WizardStepConfig,
} from '@luggagehero/shared/ui';
import { map, Observable, take } from 'rxjs';

import {
  BagSelectionStepComponent,
  ConfirmationStepComponent,
  CoverageSelectionStepComponent,
  GuestDropoffStep,
  GuestDropoffStepConfig,
  GuestSegmentationStepComponent,
  GuestVerificationStepComponent,
  PaymentMethodSelectionStepComponent,
  PhotoStepComponent,
  StorageSelectionStepComponent,
  TippingStepComponent,
} from './guest-dropoff-steps';
import { GuestDropoffConfig } from './model';
import defaultGuestDropoffConfig from './model/guest-dropoff-config-default.json';

/** Titles of sections that should be removed from the wizard. Here to ease development. */
const REMOVED_SECTIONS: string[] = [
  // 'Intro', // Uncomment to remove intro section
];
/** Steps that should be removed from the wizard. Here to ease development. */
const REMOVED_STEPS: string[] = [
  // GuestDropoffStep.LeaveTip, // Uncomment to remove tipping step
  // GuestDropoffStep.SegmentGuest, // Uncomment to remove guest segmentation step
  // GuestDropoffStep.SelectBags, // Uncomment to remove bag selection step
  // GuestDropoffStep.SelectStorage, // Uncomment to remove storage selection step
  // GuestDropoffStep.SelectCoverage, // Uncomment to remove coverage selection step
  // GuestDropoffStep.TakePhoto, // Uncomment to remove photo step
  // GuestDropoffStep.VerifyGuest, // Uncomment to remove guest verification step
];

const STEP_COMPONENT_TYPE_MAP: { [key in GuestDropoffStep]: Type<WizardStepBaseComponent> } = {
  [GuestDropoffStep.VerifyGuest]: GuestVerificationStepComponent,
  [GuestDropoffStep.SegmentGuest]: GuestSegmentationStepComponent,
  [GuestDropoffStep.SelectBags]: BagSelectionStepComponent,
  [GuestDropoffStep.TakePhoto]: PhotoStepComponent,
  [GuestDropoffStep.SelectStorage]: StorageSelectionStepComponent,
  [GuestDropoffStep.SelectCoverage]: CoverageSelectionStepComponent,
  [GuestDropoffStep.LeaveTip]: TippingStepComponent,
  [GuestDropoffStep.SelectPaymentMethod]: PaymentMethodSelectionStepComponent,
  [GuestDropoffStep.Confirmation]: ConfirmationStepComponent,
};

@Component({
  selector: 'lh-guest-feature-dropoff',
  standalone: true,
  imports: [CommonModule, SharedUiWizardComponent],
  templateUrl: './guest-feature-dropoff.component.html',
  styleUrl: './guest-feature-dropoff.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GuestFeatureDropoffComponent implements OnInit {
  public wizardConfig$: Observable<WizardConfig>;

  private _storageLocation: BookableStorageLocation;
  private _wizardConfig: WizardConfig;

  private appSettingsService = inject(SharedAppSettingsService);
  private windowService = inject(SharedWindowService);
  private cd = inject(ChangeDetectorRef);

  public showStepNumber$ = this.appSettingsService.$current.pipe(map((s) => s.guestDropoffShowStepNumber ?? false));
  public screenHeight$ = this.windowService.size.pipe(map((s) => s.windowHeight));

  @Input() public set storageLocation(value: BookableStorageLocation) {
    this._storageLocation = value;
    this.cd.markForCheck();
  }
  public get storageLocation(): BookableStorageLocation {
    return this._storageLocation;
  }

  public get wizardConfig(): WizardConfig {
    return this._wizardConfig;
  }

  public ngOnInit(): void {
    // Initialize the wizard config with the configuration from the API
    this.wizardConfig$ = this.appSettingsService.$current.pipe(
      take(1),
      map((settings) => {
        // Start with the default config from server or use static json fallback
        let config =
          (settings.defaultGuestDropoffConfig as GuestDropoffConfig) ||
          (defaultGuestDropoffConfig as GuestDropoffConfig);

        if (
          settings.storageLocationGuestDropoffConfigs &&
          settings.storageLocationGuestDropoffConfigs[this.storageLocation?._id]
        ) {
          // Override with the config for the specific storage location
          config = settings.storageLocationGuestDropoffConfigs[this.storageLocation._id] as GuestDropoffConfig;
        }

        if (Config.isDevelopment) {
          // Remove sections and steps that are not needed in development
          config.sections = config.sections.filter((s) => !REMOVED_SECTIONS.includes(s.title));
          config.sections.forEach((s) => (s.steps = s.steps.filter((s) => !REMOVED_STEPS.includes(s.step))));
        }

        // Deserialize the config (maps the step names to components)
        return this.deserializeGuestDropoffConfig(config);
      }),
    );
  }

  private deserializeGuestDropoffConfig(value: GuestDropoffConfig): WizardConfig {
    // Clone the config received from the API
    const res: WizardConfig = { ...value };

    // Deserialize the sections and steps
    res.sections = value.sections.map((section) => ({
      title: section.title,
      steps: section.steps.map((step) => this.deserializeGuestDropoffStep(step)),
    }));

    // Set default title if not provided
    if (!res.title) {
      res.title = this.storageLocation.name;
      res.subtitle = res.fullScreenEnabled ? 'LuggageHero' : 'LUGGAGE_STORAGE';
    }

    return res;
  }

  private deserializeGuestDropoffStep(value: GuestDropoffStepConfig): WizardStepConfig {
    const res: WizardStepConfig = {
      component: STEP_COMPONENT_TYPE_MAP[value.step],
      properties: { ...value.properties },
    };
    return res;
  }
}
