import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  inject,
  Input,
  NgZone,
  Output,
  Type,
  ViewChild,
} from '@angular/core';
import { AppConfig } from '@luggagehero/shared/app-settings/data-access';
import {
  BookableStorageLocation,
  DistanceUnit,
  ILocation,
  LatLng,
  PricingModel,
  StorageCriteria,
} from '@luggagehero/shared/interfaces';
import { SharedDistanceService } from '@luggagehero/shared/services/distance';
import { SharedGoogleMapsService } from '@luggagehero/shared/services/google-maps';
import { SharedLoggingService } from '@luggagehero/shared/services/logging';
import { SharedPricingService } from '@luggagehero/shared/services/pricing';
import { SharedShopsService } from '@luggagehero/shared/services/shops';
import { SharedStorageCriteriaService } from '@luggagehero/shared/services/storage-criteria';
import { SharedThemeService } from '@luggagehero/shared/services/theme';
import { Observable } from 'rxjs';

import { BaseComponent } from '../../../core';
import { ModalService, RouterExtensions } from '../../../core/services/index';
import { DateUtil } from '../../../utils/date.util';
import { StorageLocationMapBaseComponent } from '../../ui/index';
import { ConfirmBookingBaseComponent } from './confirm-booking.base-component';

@Component({ template: '' })
export abstract class ShopMapBaseComponent extends BaseComponent {
  @ViewChild('storageLocationMap') public storageLocationMap: StorageLocationMapBaseComponent;

  @Input() public defaultZoom = 14;
  @Input() public initialSelectedShopId: string;

  @Output() public hoverShopId: EventEmitter<string> = new EventEmitter<string>();
  @Output() public highlightShopChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() public locationChange: EventEmitter<LatLng> = new EventEmitter<LatLng>();
  @Output() public userLocationRequest = new EventEmitter<void>();

  public mapStyles: google.maps.MapTypeStyle[];
  public mapOptions: google.maps.MapOptions;

  protected modalService = inject(ModalService);

  protected _location: ILocation;
  private _shops: BookableStorageLocation[];
  private _visibleShops: BookableStorageLocation[];
  private _hideUnavailable: boolean;
  private _highlightShop = '';
  private _isLoading = false;
  private _isInitialized = false;

  constructor(
    protected themeService: SharedThemeService,
    protected criteriaService: SharedStorageCriteriaService,
    protected googleMapsService: SharedGoogleMapsService,
    protected shopsService: SharedShopsService,
    protected distanceService: SharedDistanceService,
    protected priceService: SharedPricingService,
    protected router: RouterExtensions,
    protected cd: ChangeDetectorRef,
    protected ngZone: NgZone,
    protected log: SharedLoggingService,
  ) {
    super();

    this.mapStyles = googleMapsService.mapStyles;
    this.mapOptions = googleMapsService.mapOptions;
  }

  protected abstract get confirmBookingComponentType(): Type<ConfirmBookingBaseComponent>;

  get maxZoom(): number {
    if (AppConfig.IS_SHOP_MAP_MAX_ZOOM_DISABLED) {
      return undefined;
    }
    return (this.location.zoom || this.defaultZoom) + 1;
  }

  get circleFillColor(): string {
    return this.themeService.mapCircleFillColor;
  }

  get circleStrokeColor(): string {
    return this.themeService.mapCircleStrokeColor;
  }

  get visibleShops(): BookableStorageLocation[] {
    return this._visibleShops;
  }

  get shops(): BookableStorageLocation[] {
    return this._shops;
  }
  @Input() set shops(value: BookableStorageLocation[]) {
    this._shops = value;
    this.updateVisibleShops();
  }

  get isLoading(): boolean {
    return this._isLoading;
  }
  @Input() set isLoading(value: boolean) {
    this._isLoading = value;
    this.cd.markForCheck();
  }

  get isInitialized(): boolean {
    return this._isInitialized;
  }
  @Input() set isInitialized(value: boolean) {
    this._isInitialized = value;
    this.cd.markForCheck();
  }

  get highlightShop(): string {
    return this._highlightShop;
  }
  @Input() set highlightShop(value: string) {
    this._highlightShop = value;
    this.cd.markForCheck();
  }

  get hideUnavailable(): boolean {
    return this._hideUnavailable;
  }
  @Input() set hideUnavailable(value: boolean) {
    this._hideUnavailable = value;
    this.updateVisibleShops();
  }

  get location(): ILocation {
    return this._location;
  }
  @Input() set location(value: ILocation) {
    this._location = value;
    this.cd.markForCheck();
  }

  get mapCenter(): google.maps.LatLngLiteral {
    return this.location && { lat: this.location.lat, lng: this.location.lon };
  }

  get pricingModel(): Observable<PricingModel> {
    return this.priceService.pricingModel$;
  }

  get distanceUnit(): Observable<DistanceUnit> {
    return this.distanceService.distanceUnit$;
  }

  get criteria(): StorageCriteria {
    return this.criteriaService.current;
  }

  get selectedPeriod() {
    if (!this.criteria) {
      return null;
    }
    return this.criteria.period;
  }

  onCenterChange(latLng: LatLng) {
    this.locationChange.emit(latLng);
  }

  openShopMarker(shopId: string) {
    this.storageLocationMap.openStorageLocationInfoWindow(shopId);
  }

  getShopDetailsRouterLink(shopId: string) {
    return [
      '/shop-details',
      shopId,
      {
        bags: this.criteria.luggage.normal,
        from: DateUtil.serializeDate(this.selectedPeriod.from, false),
        to: DateUtil.serializeDate(this.selectedPeriod.to, false),
        source: 'app',
      },
    ];
  }

  onShopSelected(shop: BookableStorageLocation) {
    this.shopsService.setCurrent(shop);
  }

  makeBooking(shop: BookableStorageLocation) {
    this.onShopSelected(shop);
    void this.modalService.show({ component: this.confirmBookingComponentType });
  }

  viewShopDetails(shop: BookableStorageLocation) {
    this.ngZone.run(() => {
      this.onShopSelected(shop);
      void this.router.navigate(this.getShopDetailsRouterLink(shop._id));
    });
  }

  private updateVisibleShops() {
    this._visibleShops = this.shops?.filter((s) => !this.hideUnavailable || s.available) || [];
    this.cd.markForCheck();
  }
}
