import { Component, inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { SharedWindowService } from '@luggagehero/shared/services/window';
import { TranslateService } from '@ngx-translate/core';

import { TermsOfServiceConfig, UserAuthenticationBaseComponent } from '../../../../user';
import { DropoffStepBaseComponent } from './dropoff-step.base-component';

@Component({ template: '' })
export abstract class ContactStepBaseComponent extends DropoffStepBaseComponent implements OnInit, OnDestroy {
  @ViewChild('userAuthentication') public userAuthentication: UserAuthenticationBaseComponent;

  private window = inject(SharedWindowService);
  private translate = inject(TranslateService);

  public get tosConfig(): TermsOfServiceConfig {
    return {
      textBeforeLink: 'ACCEPT_TERMS_PART_1',
      linkText: 'ACCEPT_TERMS_PART_2',
      // TODO: Move this to app config
      linkUrl: 'https://luggagehero.com/terms-conditions/',
    };
  }

  public get isGuestLoginAllowed(): boolean {
    // For now allow guest login for all zero payment bookings
    return this.isZeroPaymentBooking;
  }

  protected get useDefaultNavigation(): boolean {
    return true;
  }

  constructor() {
    super();

    this.canGoForward = true;
    this.canGoBack = this.totalUnitPriceItems > 0;
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.onLoginEvent();
  }

  public onLoginEvent(): void {
    if (this.isLoggedIn && this.totalUnitPriceItems > 0) {
      this.canGoForward = true;
      super.goForward();
    }
  }

  public updateState() {
    this.canGoForward = this.isLoggedIn || !this.userAuthentication.isContinueButtonDisabled;
  }

  public async goForward(): Promise<void> {
    if (!this.userAuthentication) {
      return;
    }

    this.userAuthentication.isContinueWithEmailButtonClicked = true;
    await this.userAuthentication.continue();

    this.updateState();

    // Only actually go forward if the user is logged in
    if (!this.isLoggedIn) {
      return;
    }

    super.goForward();
  }

  public async continueAsGuest(): Promise<void> {
    if (!this.userAuthentication) {
      return;
    }
    try {
      this.window.confirm(this.translate.instant('NO_EMAIL_RECEIPT_CONFIRMATION'));
    } catch (err) {
      // User cancelled
      return;
    }

    this.isLoading = true;

    await this.userAuthentication.logInAsGuest();

    if (this.totalUnitPriceItems === 0) {
      await this.bookingService.confirmBookingAndCheckIn(
        this.bookingDraft.order._id,
        this.bookingDraft.remoteLuggageImage,
        this.bookingDraft.paymentMethodId,
        this.bookingDraft.metadata,
      );
    }
    void this.goForward();
  }

  private get totalUnitPriceItems(): number {
    let totalUnitPriceItems = 0;

    this.bookingDraft.order.orderLines.forEach((orderLine) => {
      if (!orderLine.optional || (orderLine.optional && orderLine.selected)) {
        totalUnitPriceItems += orderLine.unitPrice;
      }
    });

    return totalUnitPriceItems;
  }
}
