import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { SharedAnalyticsService } from '@luggagehero/shared/services/analytics';
import { SharedLoggingService } from '@luggagehero/shared/services/logging';
import { Store } from '@ngrx/store';
import { take } from 'rxjs';

import { BaseComponent, ILang } from '../../../core';
import { ChangeAction, Languages } from '../../../i18n';
import { IAppState } from '../../../ngrx/state/app.state';

@Component({ template: '' })
export class LangSwitcherBaseComponent extends BaseComponent implements OnInit {
  public supportedLanguages: Array<ILang>;
  private _lang: string;

  constructor(
    private analytics: SharedAnalyticsService,
    private store: Store<IAppState>,
    private log: SharedLoggingService,
    private cd: ChangeDetectorRef,
    @Inject(Languages) private languages: ILang[],
  ) {
    super();

    store.pipe(take(1)).subscribe((s) => {
      this.lang = s.i18n.lang;
    });
  }

  public get selectedLanguageTitle(): string {
    return this.supportedLanguages.find((l) => l.code === this.lang).title;
  }

  public get lang(): string {
    return this._lang;
  }
  public set lang(value: string) {
    this._lang = value;
    this.cd.markForCheck();
  }

  public ngOnInit() {
    this.supportedLanguages = this.languages;
  }

  public changeLang(e: ILang) {
    let lang = this.supportedLanguages[0].code; // fallback to default 'en'

    if (e && e.code) {
      lang = e.code;
    }

    this.analytics.track('ChangeLanguage', { category: 'Multilingual', label: lang });
    void this.log.debug(`Language change: ${lang}`);
    this.store.dispatch(new ChangeAction(lang));

    this.lang = lang;
  }
}
