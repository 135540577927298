import { EventEmitter, Type } from '@angular/core';

import { BaseModalComponent } from '../base/base-modal-component';

export enum ModalStyle {
  /**
   * Modal that takes up the entire or the majority of the screen and can contain complex views.
   */
  FullScreen = 'FullScreen',
  /**
   * Modal that adjusts its size to fit its content and typically contains relatively small and simple views.
   */
  Dialog = 'Dialog',
  /**
   * Modal that adjusts its size to fit its content and typically contains only basic text with one or two buttons.
   */
  Alert = 'Alert',
}

export interface ModalOptions<T extends BaseModalComponent> {
  component: Type<T>;
  componentState?: Partial<T>;
  id?: string;
  animated?: boolean;
  style?: ModalStyle;
  autoWidth?: 'sm' | 'md' | 'lg';
  cardMode?: boolean;
  breakpoints?: number[];
  initialBreakpoint?: number;
}

export interface ModalRef {
  hide(result?: unknown): Promise<unknown>;
  result: EventEmitter<unknown>;
  onHidden?: EventEmitter<void>;
}

export interface ModalFactory {
  show<T extends BaseModalComponent>(options: ModalOptions<T>): Promise<ModalRef>;
  hide(modalId?: string): Promise<void>;
}
