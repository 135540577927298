import { ChangeDetectionStrategy, Component, forwardRef, OnInit, Provider } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DateTimePickerBaseComponent } from '@luggagehero/features/ui';

export const DATE_TIME_PICKER_CONTROL_VALUE_ACCESSOR: Provider = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => DateTimePickerComponent),
  multi: true,
};
@Component({
  selector: 'lh-date-time-picker',
  templateUrl: 'date-time-picker.component.html',
  styleUrls: ['date-time-picker.component.scss'],
  providers: [DATE_TIME_PICKER_CONTROL_VALUE_ACCESSOR],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DateTimePickerComponent extends DateTimePickerBaseComponent implements OnInit, ControlValueAccessor {}
