@if (isLoading) {
  <lh-shared-ui-spinner [visible]="true"></lh-shared-ui-spinner>
} @else {
  <lh-shared-ui-tip-options
    [(tip)]="selectedTip"
    [tipOptions]="tipOptions"
    [maxTipAmount]="maxTipAmount"
    [currency]="order.currency"
  ></lh-shared-ui-tip-options>

  <div class="tip-note">{{ 'CHANGE_TIP_AT_PICK_UP' | lhTranslate }}</div>
}
