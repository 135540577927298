import { inject, Injectable } from '@angular/core';
import {
  Booking,
  BookingIncidentType,
  BookingTransformationService,
  BookingTransformType,
  ILuggage,
  ITranslateService,
  MOMENT_SERVICE,
  MomentService,
  TRANSLATE_SERVICE,
} from '@luggagehero/shared/interfaces';
import { isDummyUserName, SharedUtilDate } from '@luggagehero/shared/util';

const CHECKOUT_GRACE_PERIOD = 0; // 2 * 60 * 1000; // 10 minutes

@Injectable({
  providedIn: 'root',
})
export class SharedBookingTransformationService implements BookingTransformationService {
  private translateService = inject<ITranslateService>(TRANSLATE_SERVICE);
  private momentService = inject<MomentService>(MOMENT_SERVICE);

  transformBooking(booking: Booking, type: BookingTransformType, includePeriod = true, format?: string): string {
    if (!booking) {
      return '';
    }
    switch (type) {
      case 'periodValue':
        return this.getPeriodHoursSummary(booking.period, false, true);

      case 'periodSuffix':
        return this.getPeriodHoursSummary(booking.period, true, false);

      case 'luggage':
        return this.getLuggageSummary(booking.luggage);

      case 'luggageValue':
        return this.getLuggageSummary(booking.luggage, false, true);

      case 'luggageSuffix':
        return this.getLuggageSummary(booking.luggage, true, false);

      case 'summary':
        return this.getBookingSummary(booking, includePeriod);

      case 'latestAction':
        return this.getLatestActionDescription(booking, format);

      case 'latestIncident':
        return this.getLatestIncidentDescription(booking, format);

      case 'user': {
        const userName = booking.userName && booking.userName.trim().toLowerCase();
        if (userName && !isDummyUserName(userName)) {
          //  userName !== 'traveler' && userName !== 'guest user' && userName !== 'test user') {
          return booking.userName.trim();
        }
        return this.translateService.instant('CUSTOMER');
      }
      case 'status':
        switch (booking.status) {
          case 'CONFIRMED':
            return this.translateService.instant('CONFIRMED');
          case 'CHECKED_IN':
            return this.translateService.instant('CHECKED_IN');
          case 'CHECKED_OUT':
            return this.translateService.instant('PAYMENT_FAILED');
          case 'PAID':
            if (booking.paidDirectly) {
              return this.translateService.instant('PAYMENT_METHOD_DIRECT');
            } else if (booking.isZeroPaymentBooking) {
              return this.translateService.instant('CHECKED_OUT');
            } else {
              return this.translateService.instant('PAID');
            }
          case 'CANCELLED':
            return this.translateService.instant('CANCELLED');
          default:
            return '';
        }

      case 'statusVerbose':
        switch (booking.status) {
          case 'CONFIRMED':
            return this.translateService.instant('STATUS_CONFIRMED_EXPLANATION');
          case 'CHECKED_IN':
            return this.translateService.instant('STATUS_CHECKED_IN_EXPLANATION');
          case 'CHECKED_OUT':
            return this.translateService.instant('STATUS_PAYMENT_FAILED_EXPLANATION');
          case 'PAID':
            if (booking.paidDirectly) {
              return this.translateService.instant('STATUS_PAYMENT_FAILED_EXPLANATION');
            } else if (booking.isZeroPaymentBooking) {
              return this.translateService.instant('STATUS_ZERO_PAYMENT_PAID_EXPLANATION');
            } else {
              return this.translateService.instant('STATUS_PAID_EXPLANATION');
            }
          case 'CANCELLED':
            return this.translateService.instant('STATUS_CANCELLED_EXPLANATION');
          default:
            return '';
        }

      default:
        return '';
    }
  }

  private getLatestIncidentDescription(booking: Booking, format = 'fromNow'): string {
    if (!booking.incidents || booking.incidents.length === 0) {
      return this.translateService.instant('NO_INCIDENTS_REPORTED').toLowerCase();
    }

    // Sort newest to oldest
    booking.incidents.sort((a, b) => b.reportedAt.getTime() - a.reportedAt.getTime());

    const latestIncident = booking.incidents[0];
    let incidentText: string;

    switch (latestIncident.incidentType) {
      case BookingIncidentType.AskedToPayDirectly:
        incidentText = this.translateService.instant('REPORTED_INCIDENT_ASKED_TO_PAY_DIRECTLY');
        break;

      case BookingIncidentType.LocationClosedOnArrival:
        incidentText = this.translateService.instant('REPORTED_INCIDENT_LOCATION_CLOSED_ON_ARRIVAL');
        break;

      case BookingIncidentType.LocationRefusedBags:
        incidentText = this.translateService.instant('REPORTED_INCIDENT_LOCATION_REFUSED_BAGS');
        break;

      case BookingIncidentType.StaffUnawareOfService:
        incidentText = this.translateService.instant('REPORTED_INCIDENT_STAFF_UNAWARE_OF_SERVICE');
        break;

      case BookingIncidentType.UnableToFindLocation:
        incidentText = this.translateService.instant('REPORTED_INCIDENT_UNABLE_TO_FIND_LOCATION');
        break;

      default:
        // This shouldn't happen
        incidentText = this.translateService.instant('REPORTED_AN_INCIDENT');
        break;
    }

    const timeOfReport =
      latestIncident && latestIncident.reportedAt
        ? this.momentService.formatDate(latestIncident.reportedAt, format)
        : '';

    return `${incidentText.toLowerCase()} ${timeOfReport}`;
  }

  private getLatestActionDescription(booking: Booking, format = 'fromNow'): string {
    switch (booking.status) {
      case 'CONFIRMED': {
        const text = this.translateService.instant('CONFIRMED_BOOKING').toLowerCase();
        return `${text} ${this.momentService.formatDate(booking.created, format)}`;
      }
      case 'CHECKED_IN': {
        const text = this.translateService.instant('DROPPED_OFF').toLowerCase();
        return `${text} ${this.momentService.formatDate(booking.period.checkIn, format)}`;
      }
      case 'CHECKED_OUT': {
        const text = this.translateService.instant('FAILED_TO_COMPLETE_PAYMENT').toLowerCase();
        return `${text} ${this.momentService.formatDate(booking.period.checkOut, format)}`;
      }
      case 'PAID': {
        let text = this.translateService.instant('COMPLETED_PAYMENT_ONLINE').toLowerCase();
        if (booking.paidDirectly) {
          text = this.translateService.instant('FAILED_TO_COMPLETE_PAYMENT').toLowerCase();
        } else if (booking.isZeroPaymentBooking) {
          text = this.translateService.instant('COMPLETED_BOOKING');
        }

        return `${text} ${this.momentService.formatDate(booking.period.checkOut, format)}`;
      }
      case 'CANCELLED': {
        return this.translateService.instant('CANCELED_BOOKING').toLowerCase();
      }
    }
  }

  private getBookingSummary(booking: Booking, includePeriod = true): string {
    const luggage = this.getLuggageSummary(booking.luggage);
    if (!includePeriod) {
      return luggage;
    }

    const period =
      booking.price.pricingModel === 'hourly'
        ? this.getPeriodHoursSummary(booking.period)
        : this.getPeriodDaysSummary(booking.period);
    const forTimePeriod = this.translateService.instant('FOR_TIME_PERIOD').toLowerCase();
    return `${luggage} ${forTimePeriod} ${period}`;
  }

  private getLuggageSummary(luggage: ILuggage, noValue = false, noSuffix = false): string {
    const luggageCount = luggage.hand + luggage.normal;
    const suffix = this.translateService.instant(luggageCount === 1 ? 'BAG' : 'BAGS').toLowerCase();
    return `${noValue ? '' : luggageCount} ${noSuffix ? '' : suffix}`.trim();
  }

  private getPeriodDaysSummary(period: { from: Date; to: Date; checkIn?: Date; checkOut?: Date }): string {
    const from = new Date(period.checkIn);
    const to = new Date(period.checkOut);

    // Show minimum 1 day for bookings with daily pricing
    const daysBetween = Math.max(SharedUtilDate.daysBetween(from, to), 1);
    const daysSuffix = this.translateService.instant(daysBetween === 1 ? 'DAY' : 'DAYS').toLowerCase();
    return `${daysBetween} ${daysSuffix}`.trim();
  }

  private getPeriodHoursSummary(
    period: {
      from: Date;
      to: Date;
      checkIn?: Date;
      checkOut?: Date;
    },
    noValue = false,
    noSuffix = false,
  ): string {
    const from = new Date(period.checkIn);
    const to = new Date(period.checkOut);

    // const daysBetween = SharedUtilDate.daysBetween(from, to);
    // if (daysBetween > 0) {
    //   const daysSuffix = this.translate.instant(daysBetween === 1 ? 'DAY' : 'DAYS').toLowerCase();
    //   return `${noValue ? '' : daysBetween} ${noSuffix ? '' : daysSuffix}`.trim();
    // }

    let hoursBetween = SharedUtilDate.hoursBetween(from, to, CHECKOUT_GRACE_PERIOD);
    if (hoursBetween === 0) {
      hoursBetween = 1;
    }
    const hoursSuffix = this.translateService.instant(hoursBetween === 1 ? 'HOUR' : 'HOURS').toLowerCase();
    return `${noValue ? '' : hoursBetween} ${noSuffix ? '' : hoursSuffix}`.trim();
  }
}
