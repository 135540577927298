import { Component, inject, Input } from '@angular/core';
import { BookableStorageLocation } from '@luggagehero/shared/interfaces';
import { SharedShopsService } from '@luggagehero/shared/services/shops';
import { SharedStorageCriteriaService } from '@luggagehero/shared/services/storage-criteria';

import { BaseComponent } from '../../../core';

@Component({ template: '' })
export abstract class StorageLocationHolidayNoticeBaseComponent extends BaseComponent {
  private storageLocationService = inject(SharedShopsService);
  private criteria = inject(SharedStorageCriteriaService);

  private _storageLocation: BookableStorageLocation;
  private _html: string;

  public get storageLocation(): BookableStorageLocation {
    return this._storageLocation;
  }
  @Input() public set storageLocation(value: BookableStorageLocation) {
    this._storageLocation = value;
    this._markForCheck();
  }

  public get html(): string {
    if (!this.storageLocation) {
      return this._html;
    }
    return this.storageLocationService.getHolidayNoticeHtml(this.criteria.period.from, this.storageLocation);
  }
  @Input() public set html(value: string) {
    this._html = value;
    this._markForCheck();
  }
}
