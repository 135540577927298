@switch (mode) {
  @case ('Checkbox') {
    <div class="checkbox-wrapper">
      <label>
        <input type="checkbox" [(checked)]="enabled" />
        <span class="custom-checkbox"></span>
      </label>
    </div>
  }
  @default {
    <div class="checkbox-wrapper">
      <label>
        <input type="checkbox" [(checked)]="enabled" />
        <span class="custom-checkbox"></span>
      </label>
    </div>
  }
}
