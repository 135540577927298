import { ChangeDetectorRef, Component, inject, Input, OnDestroy, OnInit } from '@angular/core';
import { Booking } from '@luggagehero/shared/interfaces';
import { Subscription } from 'rxjs';

import { BaseComponent } from '../../../core';
import { StorageTimerService } from '../../../services/index';

const TIME_COMPONENT_PLACEHOLDER = '--';

@Component({ template: '' })
export class StorageTimerBaseComponent extends BaseComponent implements OnInit, OnDestroy {
  @Input() public compactMode = false;
  @Input() public largeDigits = false;
  @Input() public darkMode = false;

  public timer = inject(StorageTimerService);

  private timerSubscription: Subscription;
  private _border = false;
  private _days: string;
  private _hours: string;
  private _minutes: string;
  private _seconds: string;

  constructor(private cd: ChangeDetectorRef) {
    super();
  }

  public get days(): string {
    return this.formatTimeComponent(this._days);
  }

  public get hours(): string {
    return this.formatTimeComponent(this._hours, TIME_COMPONENT_PLACEHOLDER);
  }

  public get minutes(): string {
    return this.formatTimeComponent(this._minutes, TIME_COMPONENT_PLACEHOLDER);
  }

  public get seconds(): string {
    return this.formatTimeComponent(this._seconds, TIME_COMPONENT_PLACEHOLDER);
  }

  public get booking(): Booking {
    return this.timer.booking;
  }
  @Input() public set booking(value: Booking) {
    this.timer.set(value, this.isCheckInSession);
    this.cd.markForCheck();
  }

  public get isCheckInSession(): boolean {
    return this.timer.isCheckInSession;
  }
  @Input() public set isCheckInSession(value: boolean) {
    this.timer.set(this.booking, value);
    this.cd.markForCheck();
  }

  public get border(): boolean {
    return this._border;
  }
  @Input() public set border(value: boolean) {
    this._border = value;
    this.cd.markForCheck();
  }

  public ngOnInit(): void {
    this.timerSubscription = this.timer.tick.subscribe((t) => {
      this._days = t.days;
      this._hours = t.hours;
      this._minutes = t.minutes;
      this._seconds = t.seconds;
      this.cd.markForCheck();
    });
  }

  public ngOnDestroy(): void {
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
    }
  }

  private formatTimeComponent(value: string, placeholder?: string): string {
    if (placeholder || (value && Number(value) > 0)) {
      return value ? `0${value}`.slice(-2) : placeholder;
    }
    // Not a positive numeric value and no placeholder provided
    return null;
  }
}
