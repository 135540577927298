<div class="tip-options-container">
  <div class="tip-options">
    @for (tipOption of tipOptions; track $index) {
      <button
        (click)="setTipAmount(tipOption.amount)"
        class="btn btn-info btn-modern btn-md"
        [class.selected]="tip === tipOption.amount && !isCustomTipVisible"
      >
        <span>{{ tipOption.label }}</span>
      </button>
    }
  </div>

  @if (isCustomTipEnabled) {
    @if (!isCustomTipVisible) {
      <button
        class="btn btn-info btn-modern btn-md btn-block btn-custom-tip"
        [class.active]="isCustomTipVisible"
        (click)="showCustomTip()"
      >
        {{ 'OTHER_TIP_AMOUNT' | lhTranslate }}
      </button>
    }

    <div [hidden]="!isCustomTipVisible" class="custom-tip">
      <button class="btn btn-info btn-modern btn-md btn-block selected">
        <span>&nbsp;</span>
        <span class="currency-symbol">{{ currencySymbol }}</span>
        <input
          id="customTip"
          name="customTip"
          #customTip="ngModel"
          class="tip-input"
          type="tel"
          pattern="^\d+\.{0,1}\d{0,2}$"
          [(ngModel)]="tipString"
          [placeholder]="'TIP_AMOUNT' | lhTranslate"
          #customTipInput
        />
      </button>
    </div>

    <div class="alerts">
      @if (isCustomTipInvalid) {
        <div class="alert alert-danger">
          {{ 'TIP_AMOUNT_INVALID' | lhTranslate }}
        </div>
      }
      @if (isTipTooLarge) {
        <div class="alert alert-danger">
          {{ 'TIP_NO_MORE_THAN_X' | lhTranslate }} {{ maxTipAmount | price: currency }}
        </div>
      }
    </div>
  }
</div>
