import { InjectionToken } from '@angular/core';

import { IDiscount } from './discount.interface';

export interface DiscountTransformerService {
  transform(discount: IDiscount, bags: number, includeCode?: boolean): string;
}

export const DISCOUNT_TRANSFORMER_SERVICE = new InjectionToken<DiscountTransformerService>(
  'DISCOUNT_TRANSFORMER_SERVICE',
);
