<div [formGroup]="group" [hidden]="config.hidden" class="form-group">
  <label *ngIf="config.label" [for]="config.fullyQualifiedName">{{ config.label | translate }}</label>
  <select
    class="form-select"
    [class.missing]="isMissing"
    [class.complete]="isComplete"
    [class.invalid]="isInvalid"
    [formControlName]="config.formControlName"
    [id]="config.fullyQualifiedName"
    [name]="config.fullyQualifiedName"
  >
    <option [ngValue]="undefined" disabled selected hidden>
      {{ config.placeholder | translate }}
    </option>
    <option *ngFor="let option of config.options" [ngValue]="option.value || option">
      {{ (option.name | translate) || option }}
    </option>
  </select>
</div>
