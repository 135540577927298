import { ChangeDetectorRef, Component, Input } from '@angular/core';

import { BaseComponent } from '../../../core';

export type DockStyle = 'none' | 'top' | 'bottom';

@Component({ template: '' })
export abstract class TicketStubBaseComponent extends BaseComponent {
  @Input() public isPaddingBottomRequired = true;

  private _dock: DockStyle = 'none';

  constructor(private cd: ChangeDetectorRef) {
    super();
  }

  public get dock(): DockStyle {
    return this._dock;
  }
  @Input() set dock(value: DockStyle) {
    this._dock = value;
    this.cd.markForCheck();
  }

  public get isDocked(): boolean {
    return this.dock !== 'none';
  }

  public get isDockedToBottom(): boolean {
    return this.dock === 'bottom';
  }

  public get isDockedToTop(): boolean {
    return this.dock === 'top';
  }
}
