import { ITheme } from '@luggagehero/shared/interfaces';

export class SharedUtilCSS {
  static generateRootCss(theme: ITheme): string {
    const css = [];

    css.push(``);
    css.push(`:root {`);

    css.push(`  --theme-primary: ${theme.primaryColor.value};`);
    css.push(`  --theme-primary-dark: ${theme.primaryColor.dark};`);
    css.push(`  --theme-primary-light: ${theme.primaryColor.light};`);

    if (theme.accentColor) {
      css.push(`  --theme-accent: ${theme.accentColor.value};`);
      css.push(`  --theme-accent-dark: ${theme.accentColor.dark};`);
      css.push(`  --theme-accent-light: ${theme.accentColor.light};`);
    }

    if (theme.headerColor) {
      css.push(`  --theme-header: ${theme.headerColor.value};`);
      css.push(`  --theme-header-dark: ${theme.headerColor.dark};`);
      css.push(`  --theme-header-light: ${theme.headerColor.light};`);
    }

    css.push(`}`);
    css.push(``);

    return css.join('\n');
  }
}
