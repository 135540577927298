import { ChangeDetectionStrategy, Component } from '@angular/core';
import { PricingModelSelectorBaseComponent } from '@luggagehero/features/ui';

@Component({
  selector: 'lh-pricing-model-selector',
  templateUrl: './pricing-model-selector.component.html',
  styleUrls: ['./pricing-model-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PricingModelSelectorComponent extends PricingModelSelectorBaseComponent {}
