import { Component, OnInit, ViewChild } from '@angular/core';
import { AppConfig } from '@luggagehero/shared/app-settings/data-access';
import { Booking, IPaymentRecord } from '@luggagehero/shared/interfaces';
import { SharedErrorService } from '@luggagehero/shared/services/error';
import { SharedPaymentService } from '@luggagehero/shared/services/payments';
import { SharedWindowService } from '@luggagehero/shared/services/window';

import { PaymentMethodSelectorBaseComponent } from '../../../../ui/index';
import { DropoffStepBaseComponent } from './dropoff-step.base-component';

@Component({ template: '' })
export abstract class PaymentStepBaseComponent extends DropoffStepBaseComponent implements OnInit {
  @ViewChild('paymentMethodSelector') public paymentMethodSelector: PaymentMethodSelectorBaseComponent;

  private booking: Booking;
  private _paymentMethod: IPaymentRecord;
  private _paymentMethods: IPaymentRecord[];

  public constructor(
    private paymentService: SharedPaymentService,
    private errorService: SharedErrorService,
    private windowService: SharedWindowService,
  ) {
    super();
  }

  public get numberOfBags(): number {
    return this.criteria.luggage.normal + this.criteria.luggage.hand;
  }

  public get paymentMethod(): IPaymentRecord {
    return this._paymentMethod;
  }
  public set paymentMethod(value: IPaymentRecord) {
    this._paymentMethod = value;
    this.cd.markForCheck();

    if (value?.id) {
      this.bookingDraft.paymentMethodId = value.id;
    }
  }

  public get paymentMethods(): IPaymentRecord[] {
    return this._paymentMethods;
  }
  public set paymentMethods(value: IPaymentRecord[]) {
    this._paymentMethods = value;
    this.cd.markForCheck();
  }

  public get forwardCallToAction(): string {
    return 'CONFIRM';
  }

  protected get useDefaultNavigation(): boolean {
    return !this.isAddPaymentCardButtonVisible;
  }

  public get isAddPaymentCardButtonVisible(): boolean {
    return !this.userService.isLoggedIn;
  }

  public async ngOnInit() {
    this.canGoForward = true;

    if (this.bookingService.currentBooking) {
      // Booking was already created
      this.booking = this.bookingService.currentBooking;
      super.goForward();
      return;
    }

    let totalUnitPriceItems = 0;

    this.bookingDraft.order.orderLines.forEach((orderLine) => {
      if (!orderLine.optional || (orderLine.optional && orderLine.selected)) {
        totalUnitPriceItems += orderLine.unitPrice;
      }
    });

    if (totalUnitPriceItems === 0 && !this.bookingDraft.paymentMethodId) {
      if (this.isLoggedIn) {
        await this.createBooking();
      }
      super.goForward();
      return;
    }

    if (this.bookingDraft.paymentMethodId) {
      void this.createBooking();
    } else if (this.userService.isLoggedIn) {
      this.paymentMethods = await this.paymentService.getPaymentMethods();
    }
  }

  public collectContactDetails() {
    super.goForward();
  }

  public goForward(): void {
    if (this.booking) {
      // Booking was already created
      return super.goForward();
    }

    if (this.paymentMethodSelector?.isPaymentCardFormVisible) {
      // The user is adding a new payment card, so submit the form
      this.paymentMethodSelector.submitPaymentCardForm();
    } else if (this.bookingDraft.paymentMethodId) {
      // The user has selected a payment method, so create the booking
      void this.createBooking();
    }
  }

  public async onPaymentMethodError(_event) {
    let storageLocationId: string;

    if (this.bookingDraft.storageLocationId) {
      storageLocationId = this.bookingDraft.storageLocationId;
    }
    const returnUrl = this.windowService.isNative ? AppConfig.STRIPE_PAYPAL_NATIVE_RETURN_URL : null;

    const orderId = this.bookingDraft.order._id;
    this.bookingDraft.payment = await this.paymentService.initiatePayment(
      'stripe',
      storageLocationId,
      orderId,
      undefined,
      returnUrl,
    );
    this.cd.markForCheck();
  }

  public async createBooking() {
    this.isLoading = true;

    try {
      if (!this.bookingDraft.remoteLuggageImage) {
        this.bookingDraft.remoteLuggageImage = { name: AppConfig.DUMMY_LUGGAGE_IMAGE, securitySeals: [] };
      }
      this.booking = await this.bookingService.confirmBookingAndCheckIn(
        this.bookingDraft.order._id,
        this.bookingDraft.remoteLuggageImage,
        this.bookingDraft.paymentMethodId,
        this.bookingDraft.metadata,
      );

      // Navigate to next step
      this.goForward();
    } catch (err) {
      this.errorService.handleError(err, (err as { message?: string })?.message);
    } finally {
      this.isLoading = false;
    }
  }
}
