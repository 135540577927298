import { Inject, Injectable } from '@angular/core';
import {
  DistanceUnit,
  IDistanceService,
  ITranslateService,
  LocationType,
  TRANSLATE_SERVICE,
} from '@luggagehero/shared/interfaces';
import { SharedStorageCriteriaService } from '@luggagehero/shared/services/storage-criteria';
import { BehaviorSubject, combineLatest, map, Observable, of, switchMap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SharedDistanceService implements IDistanceService {
  // Translation keys used by the service
  private text = {
    UNIT_SUFFIX_METERS: '',
    UNIT_SUFFIX_KILOMETERS: '',
    UNIT_SUFFIX_FEET: '',
    UNIT_SUFFIX_MILE: '',
    UNIT_SUFFIX_MILES: '',
    UNIT_SUFFIX_MINUTE: '',
    UNIT_SUFFIX_MINUTES: '',
  };
  private _distanceUnit = new BehaviorSubject<DistanceUnit>('metric');

  constructor(
    private criteriaService: SharedStorageCriteriaService,
    @Inject(TRANSLATE_SERVICE) private translate: ITranslateService,
  ) {}

  public get distanceUnit$(): Observable<DistanceUnit> {
    return this._distanceUnit;
  }

  public get distanceUnit(): DistanceUnit {
    return this._distanceUnit.value;
  }

  public changeDistanceUnit(value: DistanceUnit): void {
    this._distanceUnit.next(value);
  }

  public getFromSuffix(placeName?: string): Observable<string> {
    const locationType: LocationType = placeName ? 'place' : this.criteriaService.currentOrDefault.location.type;

    if (locationType === 'place' && !placeName) {
      placeName = this.criteriaService.currentOrDefault.location.address;
    }

    switch (locationType) {
      case 'user':
        return this.translate.get('FROM_YOUR_LOCATION');

      case 'official': {
        return combineLatest([
          this.translate.get('FROM_CENTER_OF'),
          this.translate.get(this.criteriaService.currentOrDefault.location.key),
        ]).pipe(
          map(([fromCenterOf, locationName]) => {
            fromCenterOf = fromCenterOf.toLowerCase();

            if (locationName === this.criteriaService.currentOrDefault.location.key) {
              //
              // We don't have a translation for the location key, so fall back to the name if available; if not, at least
              // improve the casing of the key
              //
              locationName =
                this.criteriaService.currentOrDefault.location.name || this.uppercaseFirstLetter(locationName);
            }

            return `${fromCenterOf} ${locationName}`;
          }),
        );
      }
      case 'place': {
        return this.translate.get('FROM').pipe(map((from) => `${from.toLowerCase()} ${placeName}`));
      }
      case 'custom':
      default:
        return this.translate.get('FROM_SELECTED_LOCATION');
    }
  }

  public convertDistance(
    meters: number,
    distanceUnit: DistanceUnit = 'metric',
    includeFrom = true,
    place?: string,
  ): Observable<string> {
    return this.translate.get(this.text).pipe(
      switchMap((translations) => {
        this.text = translations;

        switch (distanceUnit) {
          case 'time':
            return this.convertTime(meters, includeFrom, place);

          case 'imperial':
            return this.convertImperial(meters, includeFrom, place);

          case 'metric':
          default:
            return this.convertMetric(meters, includeFrom, place);
        }
      }),
    );
  }

  private convertMetric(meters: number, includeFrom: boolean, place?: string): Observable<string> {
    if (meters < 1000) {
      return this.suffix(meters.toFixed(0), this.text.UNIT_SUFFIX_METERS, includeFrom);
    }
    return this.suffix(+(meters / 1000).toFixed(1), this.text.UNIT_SUFFIX_KILOMETERS, includeFrom, place);
  }

  private convertImperial(meters: number, includeFrom: boolean, place?: string): Observable<string> {
    const feetPerMeter = 3.2808;
    const feetPerMile = 5280;
    const feet = meters * feetPerMeter;

    // If less than 0.1 mile show as feet
    if (feet < feetPerMile / 10) {
      return this.suffix(feet.toFixed(0), this.text.UNIT_SUFFIX_FEET, includeFrom, place);
    }

    const miles = +(feet / 5280).toFixed(1);
    const suffix = miles === 1 ? this.text.UNIT_SUFFIX_MILE : this.text.UNIT_SUFFIX_MILES;

    return this.suffix(miles, suffix, includeFrom, place);
  }

  private convertTime(meters: number, includeFrom: boolean, place?: string): Observable<string> {
    //
    // Using 1.34 meters/second as the walking speed. Source:
    // https://www.healthline.com/health/exercise-fitness/average-walking-speed
    //
    const metersPerSecond = 1.34;
    const metersPerMinute = metersPerSecond * 60;

    const minutes = +(meters / metersPerMinute).toFixed(0);
    const suffix = this.text.UNIT_SUFFIX_MINUTE; // minutes === 1 ? this.text.UNIT_SUFFIX_MINUTE : this.text.UNIT_SUFFIX_MINUTES;

    return this.suffix(minutes, suffix, includeFrom, place);
  }

  private suffix(value: string | number, unitSuffix: string, includefrom: boolean, place?: string): Observable<string> {
    if (includefrom) {
      return this.getFromSuffix(place).pipe(map((fromSuffix) => `${value} ${unitSuffix} ${fromSuffix}`));
    }
    return of(`${value} ${unitSuffix}`);
  }

  private uppercaseWord(value: string): string {
    const firstLetter = value[0].toUpperCase();
    const tail = value.substring(1);

    return `${firstLetter}${tail.toLowerCase()}`;
  }

  private uppercaseFirstLetter(value: string): string {
    if (!value) {
      return '';
    }

    return value
      .split(/[_ ]+/)
      .map((word) => this.uppercaseWord(word))
      .join(' ');
  }
}
