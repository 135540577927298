import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { RouterExtensions } from '@luggagehero/core';
import { TermsOfServiceConfig, UserModalBaseComponent } from '@luggagehero/features/user';
import { SharedStorageService } from '@luggagehero/shared/services/storage';
import { SharedUserService } from '@luggagehero/shared/services/users';
import { SharedWindowService } from '@luggagehero/shared/services/window';
import { ModalDirective } from 'ngx-bootstrap/modal';

@Component({
  selector: 'lh-user-modal',
  templateUrl: './user-modal.component.html',
  styleUrls: ['./user-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserModalComponent extends UserModalBaseComponent implements OnInit, OnDestroy {
  @ViewChild('loginModal', { static: true }) public loginModal: ModalDirective;
  public modalShown = false;

  public tosConfig: TermsOfServiceConfig = {
    textBeforeLink: 'ACCEPT_TERMS_PART_1',
    linkText: 'ACCEPT_TERMS_PART_2',
    linkUrl: 'https://luggagehero.com/terms-conditions/',
  };

  constructor(
    private router: RouterExtensions,
    userService: SharedUserService,
    storageService: SharedStorageService,
    windowService: SharedWindowService,
    cd: ChangeDetectorRef,
  ) {
    super(userService, storageService, windowService, cd);
  }

  ngOnInit() {
    this.modalShown = true;
  }

  ngOnDestroy() {
    if (this.navigateHomeOnHide && !this.userService.isLoggedIn) {
      void this.router.navigate(['home']);
    }
  }
}
