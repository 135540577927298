import { Injectable } from '@angular/core';
import { AppConfig } from '@luggagehero/shared/app-settings/data-access';
import { Config } from '@luggagehero/shared/environment';
import { PayoutProvider } from '@luggagehero/shared/interfaces';
import { SharedHolidayService } from '@luggagehero/shared/services/holiday';
import { SharedHttpService } from '@luggagehero/shared/services/http';

import { DateUtil } from '../../utils/date.util';
import { ManageableStorageLocation } from './storage-location';

// TODO: Move this elsewhere
export interface PerformanceStatsResponse extends PerformanceStats {
  shopId: string;
  durationDays: number;
  startDate: Date;
  endDate: Date;
}

export interface PerformanceStats {
  totalPaidBookings: number;
  totalBagsStored: number;
  totalRevenue: number;
  totalTips: number;
  averageValuePerBooking: number;
  averageValuePerBag: number;
  currency: string;
}

export interface StorageLocationAgreement {
  /**
   * The id of the agreement.
   */
  _id: string;
  /**
   * URL of the agreement.
   * */
  agreementUrl: string;
  /**
   * The contents of the agreement as a URI and Base64 encoded string. To decode do:
   *
   * `const decodedContent = decodeURIComponent(atob(agreementContent))`
   */
  agreementContent: string;
  /**
   * Defines the region of the agreement (e.g. `'dk'`). Will contain `'USEREMAIL'` if this is a custom agreement for
   * currently authenticated the storage manager.
   */
  // region: string;
  /**
   * Will be either the region that the agreement covers (e.g. `'dk'`), or the id of the storage manager's user account
   * in case of a custom agreement (e.g. `'58d9293d18f0dd000595a528'`).
   */
  // key: string;
  /**
   * Indicates whether this agreement has been accepted by the currently autenticated storage manager user.
   */
  signed: boolean;
  createdAt: Date;
}

@Injectable()
export class StorageManagerService {
  private apiEndPoint: string;
  private latestStorageLocation: ManageableStorageLocation;

  constructor(
    private httpService: SharedHttpService,
    private holidayService: SharedHolidayService,
  ) {
    this.apiEndPoint = Config.environment.STORAGE_MANAGER_API;
  }

  public async getLatestTermsOfService(): Promise<StorageLocationAgreement> {
    let tosAgreement: StorageLocationAgreement;

    try {
      const url = `${this.apiEndPoint}/agreements/terms_of_service`;
      tosAgreement = await this.httpService.get<StorageLocationAgreement>(url, true);
    } catch (err) {
      console.log(`Error getting ToS agreement`, err);
    }

    if (tosAgreement && tosAgreement.agreementContent) {
      try {
        // Content is URI and Base64 encoded by the server
        tosAgreement.agreementContent = decodeURIComponent(atob(tosAgreement.agreementContent));
      } catch (err) {
        // This shouldn't happen but here just in case the encoding is not as expected
      }
    }

    return tosAgreement;
  }

  public async requestAcceptTermsOfService(agreementId: string): Promise<void> {
    const url = `${this.apiEndPoint}/agreements/${agreementId}/request_sign_code`;
    await this.httpService.post(url, null, true);
  }

  public async confirmAcceptTermsOfService(agreementId: string, code: string): Promise<StorageLocationAgreement> {
    const url = `${this.apiEndPoint}/agreements/${agreementId}/sign_agreement`;
    const payload = { code };

    const res = await this.httpService.post<StorageLocationAgreement>(url, payload, true);

    return res;
  }

  public async getSupportedPayoutProviders(): Promise<PayoutProvider[]> {
    const url = `${this.apiEndPoint}/payouts/providers`;
    const res = await this.httpService.get<PayoutProvider[]>(url);

    return res;
  }

  public async getDefaultPayoutCurrency(country: string): Promise<string> {
    const url = `${this.apiEndPoint}/payouts/currencies?country=${country.toLowerCase()}`;
    const res = await this.httpService.get<{ default: string; supported: string[] }>(url);

    return res.default;
  }

  public async getPerformance(storageLocationId: string, days: number): Promise<PerformanceStatsResponse> {
    const url = `${this.apiEndPoint}/storage_locations/${storageLocationId}/performance_reports_v2?days=${days}`;
    const res = await this.httpService.get<PerformanceStatsResponse[]>(url);

    return res && res.length > 0 ? res[0] : undefined;
  }

  public async getPerformanceByPeriod(
    storageLocationId: string,
    startDate: string,
    endDate: string,
  ): Promise<PerformanceStatsResponse> {
    const url = `${this.apiEndPoint}/storage_locations/${storageLocationId}/performance_reports_v2?startDate=${startDate}&endDate=${endDate}`;
    const res = await this.httpService.get<PerformanceStatsResponse[]>(url);

    return res && res.length > 0 ? res[0] : undefined;
  }

  public async getStorageLocationByPlaceId(placeId: string): Promise<ManageableStorageLocation> {
    const url = `${this.apiEndPoint}/storage_locations/${placeId}?provider=google`;
    const response = await this.httpService.get<ManageableStorageLocation>(url);

    if (response) {
      // TODO: Should we wait for this?
      void this.holidayService.initCountry(response.address.countryCode);
    }

    // Cache the latest storage location fetched
    this.latestStorageLocation = this.deserialize(response);
    return this.latestStorageLocation;
  }

  public async getStorageLocation(id: string, forceApiRequest = false): Promise<ManageableStorageLocation> {
    if (!forceApiRequest && this.latestStorageLocation && this.latestStorageLocation._id === id) {
      // Return cached storage location
      return Promise.resolve(this.latestStorageLocation);
    }
    const url = `${this.apiEndPoint}/storage_locations/${id}`;
    const response = await this.httpService.get<ManageableStorageLocation>(url);

    if (response) {
      // TODO: Should we wait for this?
      void this.holidayService.initCountry(response.address.countryCode);
    }
    // Cache the latest storage location fetched
    this.latestStorageLocation = this.deserialize(response);

    return this.latestStorageLocation;
  }

  public async getStorageLocations(): Promise<ManageableStorageLocation[]> {
    const url = `${this.apiEndPoint}/storage_locations`;

    const response = await this.httpService.get<ManageableStorageLocation[]>(url);

    return this.deserializeMany(response);
  }

  public async addStorageLocation(value: ManageableStorageLocation): Promise<ManageableStorageLocation> {
    const url = `${this.apiEndPoint}/storage_locations`;
    const payload = this.serialize(value);

    const response = await this.httpService.post<ManageableStorageLocation>(url, payload);

    return this.deserialize(response);
  }

  public async updateStorageLocation(value: ManageableStorageLocation): Promise<ManageableStorageLocation> {
    const url = `${this.apiEndPoint}/storage_locations/${value._id}`;
    const payload = this.serialize(value);

    const response = await this.httpService.put<ManageableStorageLocation>(url, payload);

    return this.deserialize(response);
  }

  public deleteStorageLocation(id: string): Promise<void> {
    const url = `${this.apiEndPoint}/storage_locations/${id}`;
    return this.httpService.delete(url);
  }

  public deleteImage(storageLocationId: string, imageName: string): Promise<ManageableStorageLocation> {
    return this.httpService.delete<ManageableStorageLocation>(
      `${this.apiEndPoint}/storage_locations/${storageLocationId}/images/${imageName}`,
    );
  }

  private deserializeMany(value: ManageableStorageLocation[]) {
    for (let i = 0; i < value.length; i++) {
      value[i] = this.deserialize(value[i]);
    }
    return value;
  }

  private deserialize(value: ManageableStorageLocation): ManageableStorageLocation {
    // Convert dates from strings to Date objects
    for (let i = 0; i < value.openingHours.exceptions.length; i++) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const d: any = value.openingHours.exceptions[i].date;
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      value.openingHours.exceptions[i].date = DateUtil.deserializeDate(d, false);
    }

    // Make sure exceptions are sorted by date
    value.openingHours.exceptions.sort((a, b) => a.date.getTime() - b.date.getTime());

    // HACK: Handles that not all shops have official location keys in the db
    if (!value.officialLocationKey && value.officialLocationName) {
      value.officialLocationKey = value.officialLocationName.toUpperCase().replace(/ /g, '_');
    }

    if (!value.minimumCapacity) {
      // Default to min capacity of 5 bags
      value.minimumCapacity = {
        normal: AppConfig.DEFAULT_STORAGE_LOCATION_MIN_CAPACITY_NORMAL,
        hand: AppConfig.DEFAULT_STORAGE_LOCATION_MIN_CAPACITY_HAND,
      };
    }

    if (!value.maximumCapacity) {
      // Default to max capacity of 1000 bags
      value.maximumCapacity = {
        normal: AppConfig.DEFAULT_STORAGE_LOCATION_MAX_CAPACITY_NORMAL,
        hand: AppConfig.DEFAULT_STORAGE_LOCATION_MAX_CAPACITY_HAND,
      };
    }

    // Use a percentage so we can map to slider more easily
    value.maxCapacityForAdvanceBookings = (value.capacity?.maxCapacityForAdvanceBookings ?? 1) * 100;

    if (value.hasSpace === undefined || value.hasSpace === null) {
      value.hasSpace = true;
    }

    return value;
  }

  private serialize(value: ManageableStorageLocation): ManageableStorageLocation {
    // Clone the provided storage location object
    // set maxCapacityForAdvanceBookings to a percentage value from 0 to 1
    value.capacity.maxCapacityForAdvanceBookings = (value.maxCapacityForAdvanceBookings ?? 100) / 100;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const serializedStorageLocation: any = Object.assign({}, value);

    // Overwrite the dates of exceptional opening hours with strings
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    serializedStorageLocation.openingHours.exceptions = value.openingHours.exceptions.map((e) => {
      return {
        // TODO: (lint): Should this be converted to a date this way?
        date: DateUtil.serializeDate(e.date, false),
        openingHours: e.openingHours,
        description: e.description,
      };
    });

    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return serializedStorageLocation;
  }
}
