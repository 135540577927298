import { Component, Input } from '@angular/core';

import { BaseModalComponent } from '../../../core';

@Component({ template: '' })
export abstract class ViewImageBaseComponent extends BaseModalComponent {
  @Input() public image: string;
  @Input() public imageSize: 'small' | 'medium' | 'large' = 'medium';

  public close() {
    void this.hideModal();
  }
}
