import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  inject,
  NgZone,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { ModalService, RouterExtensions } from '@luggagehero/core';
import { FindStorageBaseComponent } from '@luggagehero/features/shops';
import { AppConfig } from '@luggagehero/shared/app-settings/data-access';
import {
  BookableStorageLocation,
  DistanceUnit,
  GeolocationState,
  IDiscount,
  ILocation,
  ILuggage,
  IPrice,
  IPricing,
  ITimePeriod,
  LatLng,
  StorageCriteria,
  WindowSizeGroup,
} from '@luggagehero/shared/interfaces';
import { SharedDistanceService } from '@luggagehero/shared/services/distance';
import { SharedDocumentService } from '@luggagehero/shared/services/document';
import { SharedGeocodingService } from '@luggagehero/shared/services/geocoding';
import { SharedGeolocationService } from '@luggagehero/shared/services/geolocation';
import { SharedLocationService } from '@luggagehero/shared/services/locations';
import { SharedLoggingService } from '@luggagehero/shared/services/logging';
import { SharedPageTaggingService } from '@luggagehero/shared/services/page-tagging';
import { SharedParamsService } from '@luggagehero/shared/services/params';
import { SharedPricingService } from '@luggagehero/shared/services/pricing';
import { SharedPromoCodeService } from '@luggagehero/shared/services/promo-codes';
import { SharedShopsService } from '@luggagehero/shared/services/shops';
import { SharedSplitTestingService } from '@luggagehero/shared/services/split-testing';
import { SharedStorageCriteria, SharedStorageCriteriaService } from '@luggagehero/shared/services/storage-criteria';
import { SharedThemeService } from '@luggagehero/shared/services/theme';
import { SharedUserService } from '@luggagehero/shared/services/users';
import { SharedWindowService } from '@luggagehero/shared/services/window';
import { SharedUtilDate } from '@luggagehero/shared/util';
import { MapMarkerThemeOptions } from '@luggagehero/traveler/shops/util';
import { GeoUtil } from '@luggagehero/utils';
import { PromoCodeComponent } from '@luggagehero/web/features/ui';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Observable, Subscription } from 'rxjs';

import { ConfirmBookingComponent } from '../confirm-booking/confirm-booking.component';
import { ShopListComponent } from '../shop-list/shop-list.component';
import { ShopMapComponent } from '../shop-map/shop-map.component';

@Component({
  selector: 'lh-find-shop',
  templateUrl: './find-shop.component.html',
  styleUrls: ['./find-shop.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FindShopComponent extends FindStorageBaseComponent implements OnInit, OnDestroy {
  @ViewChild('filterModal') public filterModal: ModalDirective;
  @ViewChild('promoCodeModal') public promoCodeModal: ModalDirective;
  @ViewChild('shopList') public shopList: ShopListComponent;
  @ViewChild('shopMap') public shopMap: ShopMapComponent;
  @ViewChild('googlePlacesInput') public googlePlacesInput: ElementRef;

  public selectedShopId: string;
  public showList = true;
  public hideCriteria = false;
  public isCriteriaExpanded = false;
  public isPromoCodeAlertDismissed = false;
  public isMapInitialized = false;
  public isSearchOnMoveMapAllowed = !AppConfig.IS_SEARCH_ON_MOVE_MAP_DISABLED;
  private router: RouterExtensions = inject(RouterExtensions);
  private themeService = inject(SharedThemeService);

  mapMarkerThemeOptions: MapMarkerThemeOptions = {
    mapMarkerIcon: this.themeService.mapMarkerIcon,
    activeMapMarkerIcon: this.themeService.activeMapMarkerIcon,
    unavailableMapMarkerIcon: this.themeService.unavailableMapMarkerIcon,
    mapMarkerBestChoiceIcon: this.themeService.mapMarkerBestChoiceIcon,
    activeMapMarkerBestChoiceIcon: this.themeService.activeMapMarkerBestChoiceIcon,
    unavailableMapMarkerBestChoiceIcon: this.themeService.unavailableMapMarkerBestChoiceIcon,
    mapCircleFillColor: this.themeService.mapCircleFillColor,
    mapCircleStrokeColor: this.themeService.mapCircleStrokeColor,
    activeStorageLocationMarkerNotice: AppConfig.ACTIVE_STORAGE_LOCATION_MARKER_NOTICE,
    markerSizeDownscaleFactor: AppConfig.SHOP_MAP_MARKER_SIZE_DOWNSCALE_FACTOR,
  };

  private isCriteriaInitialized = false;
  private _showMap = false;
  private _mapLocation: LatLng;
  private subscriptions: Subscription[] = [];
  private geoLocationState: GeolocationState = 'unknown';
  private isFirstParamsChange = true;
  private _isCheckingPromoCode = false;
  private _isSearchOnMoveMapEnabled = false;
  private _loadFailed = false;
  private _highlightShop = '';
  private _location: ILocation;
  private _showRedoSearchButton = false;
  private _windowSize: WindowSizeGroup;
  private loadShopsByMapLocationTimeout: NodeJS.Timeout;
  private _isLoadShopsByMapLocationRequested = false;
  private _isNewFindStorageViewVariantEnabled = false;

  constructor(
    private modalService: ModalService,
    private promoCodeService: SharedPromoCodeService,
    private documentService: SharedDocumentService,
    private splitTestingService: SharedSplitTestingService,
    shopsService: SharedShopsService,
    criteriaService: SharedStorageCriteriaService,
    priceService: SharedPricingService,
    private distanceService: SharedDistanceService,
    private locationService: SharedLocationService,
    private geolocationService: SharedGeolocationService,
    private tag: SharedPageTaggingService,
    private route: ActivatedRoute,
    private paramsService: SharedParamsService,
    private userService: SharedUserService,
    cd: ChangeDetectorRef,
    private log: SharedLoggingService,
    private ngZone: NgZone,
    windowService: SharedWindowService,
    private geocodeService: SharedGeocodingService,
  ) {
    super(shopsService, criteriaService, priceService, cd);

    this.documentService.addBodyClass('find-shop');
    windowService.size.subscribe((s) => (this.windowSize = s.sizeGroup));
  }

  get filterModalSub(): ModalDirective {
    return this.filterModal;
  }

  get simpleAddPromoCodeAlert() {
    return this.isListVisible;
  }

  get distanceUnit(): Observable<DistanceUnit> {
    return this.distanceService.distanceUnit$;
  }

  get windowSize(): WindowSizeGroup {
    return this._windowSize;
  }
  set windowSize(value: WindowSizeGroup) {
    if (this._windowSize === value) {
      return; // No change
    }
    this._windowSize = value;

    const smallWindowSizes: WindowSizeGroup[] = ['xs', 'sm'];

    if (smallWindowSizes.includes(value)) {
      this.showList = true;
      this.showMap = false;
      this.hideCriteria = true;
    } else {
      this.showList = true;
      this.showMap = true;
      this.hideCriteria = false;
    }

    this.cd.markForCheck();
  }

  get criteria(): StorageCriteria {
    return this.criteriaService.current;
  }
  set criteria(value: StorageCriteria) {
    this.updateCriteria(value, true);
  }

  get location(): ILocation {
    return this._location;
  }
  set location(value: ILocation) {
    this._location = value;
    this.cd.markForCheck();
  }

  get estimatedPrice(): IPrice {
    return this.shopsService.latestPrice ? this.shopsService.latestPrice.estimated : null;
  }

  get pricing(): IPricing {
    return this.shopsService.latestPrice ? this.shopsService.latestPrice.pricings[0] : null;
  }

  set highlightShop(val: string) {
    this._highlightShop = val;
    this.cd.markForCheck();

    // Fixes map pins not highlighting
    this.cd.detectChanges();
  }
  get highlightShop() {
    return this._highlightShop;
  }

  get isCheckingPromoCode(): boolean {
    return this._isCheckingPromoCode;
  }
  set isCheckingPromoCode(value: boolean) {
    this._isCheckingPromoCode = value;
    this.cd.markForCheck();
  }

  get isLoading(): boolean {
    return this._isLoading;
  }
  set isLoading(value: boolean) {
    this._isLoading = value;
    this.cd.markForCheck();
  }

  get loadFailed(): boolean {
    return this._loadFailed;
  }
  set loadFailed(value: boolean) {
    this._loadFailed = value;
    this.cd.markForCheck();
  }

  get isSearchOnMoveMapEnabled(): boolean {
    return this._isSearchOnMoveMapEnabled;
  }
  set isSearchOnMoveMapEnabled(value: boolean) {
    this._isSearchOnMoveMapEnabled = value;
    this.cd.markForCheck();

    this.showRedoSearchButton = !this.isSearchOnMoveMapEnabled && this.isMapLocationChanged;
  }

  get mapLocation(): LatLng {
    return this._mapLocation;
  }
  set mapLocation(value: LatLng) {
    this._mapLocation = value;
    this.cd.markForCheck();

    this.showRedoSearchButton = !this.isSearchOnMoveMapEnabled && this.isMapLocationChanged;
  }

  get showMap(): boolean {
    return this._showMap;
  }
  set showMap(value: boolean) {
    if (value) {
      this.isMapInitialized = true;
    }
    this._showMap = value;
    this.cd.markForCheck();
  }

  get isLoadShopsByMapLocationRequested(): boolean {
    return this._isLoadShopsByMapLocationRequested;
  }
  set isLoadShopsByMapLocationRequested(value: boolean) {
    this._isLoadShopsByMapLocationRequested = value;
    this.cd.markForCheck();
  }

  get isMapVisible(): boolean {
    return this.showMap && !this.isLoading && !this.loadFailed;
  }

  get isListVisible(): boolean {
    const largeWindowSizes: WindowSizeGroup[] = ['lg', 'md'];
    if (largeWindowSizes.includes(this.windowSize)) {
      return true;
    }
    return !this.showMap && !this.isLoading && !this.loadFailed;
  }

  get showRedoSearchButton(): boolean {
    return this._showRedoSearchButton;
  }
  set showRedoSearchButton(value: boolean) {
    this._showRedoSearchButton = value;
    this.cd.markForCheck();
  }

  get isMapLocationChanged(): boolean {
    if (!this.criteria) {
      return false;
    }
    const currentLocation: LatLng = {
      lat: this.criteria.location.lat,
      lng: this.criteria.location.lon,
    };
    return GeoUtil.isDistanceGreaterThan(currentLocation, this.mapLocation, this.mapIgnoreThreshold);
  }

  get mapIgnoreThreshold(): number {
    return AppConfig.MAP_MOVE_IGNORE_THRESHOLD;
  }

  get appliedPromoCode(): string {
    return this.promoCodeService.appliedPromoCode;
  }

  get appliedDiscount(): IDiscount {
    return this.promoCodeService.appliedDiscount;
  }

  get showAddPromoCodeAlert(): boolean {
    if (!AppConfig.IS_PROMO_CODE_ALERT_ENABLED) {
      return false;
    }
    if (this.promoCodeService.appliedPromoCode) {
      return false;
    }
    return true;
  }

  get showValidPromoCodeAlert(): boolean {
    if (!this.promoCodeService.appliedPromoCode) {
      return false;
    }
    if (!this.promoCodeService.isAppliedPromoCodeValid) {
      return false;
    }
    if (!this.criteria) {
      return false;
    }
    return true;
  }

  get showInvalidPromoCodeAlert(): boolean {
    if (this.isCheckingPromoCode) {
      return false;
    }
    if (!this.promoCodeService.appliedPromoCode) {
      return false;
    }
    if (this.promoCodeService.isAppliedPromoCodeValid) {
      return false;
    }
    return true;
  }

  get isAlertAllowed(): boolean {
    return this.criteria && !this.isLoading && !this.isPromoCodeAlertDismissed;
  }

  get isAlertVisible(): boolean {
    if (!this.isAlertAllowed) {
      return false;
    }
    if (!this.showAddPromoCodeAlert && !this.showValidPromoCodeAlert && !this.showInvalidPromoCodeAlert) {
      return false;
    }
    return this.visibleShops?.length > 0;
  }

  public get isNewFindStorageViewVariantEnabled(): boolean {
    return this._isNewFindStorageViewVariantEnabled;
  }

  ngOnInit() {
    if (this.themeService.theme?.organizationId) {
      //
      // Remove organization-specific theme styling when visiting the find shop page. It will be reloaded if the user
      // visits the organization's dropoff page again.
      //
      void this.themeService.removeThemeStyling();
    }

    this.userService.enableUserTracking();
    this.tag.disableCrawling();

    this.subscriptions.push(
      this.route.queryParams.subscribe((p) => void this.onQueryParamsChange(p)),
      this.route.params.subscribe((p) => void this.onParamsChange(p)),
      this.criteriaService.change.subscribe((c) => void this.onCriteriaChange(c)),
      this.criteriaService.changeRequested.subscribe((c) => this.onCriteriaChangeRequested(c)),
    );

    // Fixes some issues with UI not updating
    this.ngZone.run(() => this.cd.detectChanges());
  }

  ngOnDestroy() {
    try {
      this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    } catch {
      // Ignore
    }

    this.documentService.removeBodyClass('find-shop');
    this.documentService.removeBodyClass('fullscreen-map');
    this.tag.clearTags();
  }

  onCriteriaChangeRequested(_criteria: StorageCriteria) {
    this.isLoading = true;
  }

  async onCriteriaChange(criteria: StorageCriteria) {
    try {
      if (!criteria) {
        return;
      }
      if (!this.locationService.isInitialized) {
        // Only get shops once official locations have been loaded
        return;
      }
      if (criteria.equals(this.currentCriteria)) {
        // Criteria is the same as was used to get the current result
        return;
      }

      this.location = criteria.location;
      this.isLoading = true;
      this.isLoadShopsByMapLocationRequested = false;
      this.showRedoSearchButton = !this.isSearchOnMoveMapEnabled && this.isMapLocationChanged;
      const shops = await this.shopsService.listShops(this.criteria);

      try {
        this.processAndDisplayShops(shops, this.criteria);
        this.loadFailed = false;
      } catch (err) {
        this.isLoading = false;
        this.loadFailed = true;
      }
    } finally {
      this.isLoading = false;
    }
  }

  onMapLocationChange(location: LatLng) {
    this.mapLocation = location;

    if (this.isSearchOnMoveMapEnabled) {
      this.loadShopsByMapLocation(true);
    }
  }

  loadShopsByMapLocation(debounce: boolean) {
    if (this.loadShopsByMapLocationTimeout) {
      clearTimeout(this.loadShopsByMapLocationTimeout);
    }
    if (debounce) {
      this.loadShopsByMapLocationTimeout = setTimeout(() => this._loadShopsByMapLocation(), 1000);
    } else {
      this._loadShopsByMapLocation();
    }
  }

  _loadShopsByMapLocation() {
    if (!this.mapLocation) {
      return;
    }

    this.isLoadShopsByMapLocationRequested = true;

    // Update criteria immediately with the new location coordinates
    const location: ILocation = {
      lat: this.mapLocation.lat,
      lon: this.mapLocation.lng,
      region: this.location.region,
      address: this.location.address,
      zoom: this.criteria.location.zoom,
      radius: this.criteria.location.radius,
      type: 'custom',
    };
    const newCriteria = new SharedStorageCriteria(location, this.criteria.period, this.criteria.luggage);
    this.updateCriteria(newCriteria, false);

    // Then fetch new address (place name) to display asynchonously
    void this.geocodeService
      .requestReverseGeoCodeForLatLong(this.mapLocation.lat, this.mapLocation.lng, 'FindShopWeb')
      .then((place) => {
        if (place) {
          this.criteriaService.updateAddress(place.name, place.address.region);
        }
      });
  }

  // Called when the user explicitly requests geolocation
  async loadUserLocation() {
    this.isLoading = true;

    try {
      const location = await this.locationService.getUserLocation(true);
      if (location) {
        this.criteria = new SharedStorageCriteria(location, this.criteria.period, this.criteria.luggage);
      } else {
        this.isLoading = false;
      }
    } catch (err) {
      this.isLoading = false;
    }
  }

  setHoverShopId(shopId: string) {
    this.highlightShop = shopId;
  }

  toggleCriteria() {
    this.hideCriteria = !this.hideCriteria;
    this.cd.markForCheck();
  }

  toggleDistanceUnit() {
    if (this.distanceService.distanceUnit === 'metric') {
      this.distanceService.changeDistanceUnit('imperial');
    } else {
      this.distanceService.changeDistanceUnit('metric');
    }
  }

  showView(view: string) {
    switch (view) {
      case 'map':
        this.showMap = true;
        this.showList = false;
        break;

      case 'list':
        this.showMap = false;
        this.showList = true;
        break;

      default:
        this.showMap = true;
        this.showList = true;
        break;
    }

    if (this.showList) {
      this.documentService.removeBodyClass('fullscreen-map');
    } else {
      this.documentService.addBodyClass('fullscreen-map');
    }
    this.cd.markForCheck();
  }

  toggleMap() {
    this.showMap = !this.showMap;
    this.cd.markForCheck();
  }

  expandCriteria() {
    this.isCriteriaExpanded = true;
    this.cd.markForCheck();
  }

  collapseCriteria() {
    this.isCriteriaExpanded = false;
    this.cd.markForCheck();
  }

  showPromoCodeModal() {
    void this.modalService.show({ component: PromoCodeComponent });
  }

  dismissPromoCodeAlert() {
    this.isPromoCodeAlertDismissed = true;
    this.cd.markForCheck();
  }

  isShowingModal = false;

  makeBooking(shop: BookableStorageLocation) {
    this.shopsService.setCurrent(shop);
    this.isShowingModal = true;
    void this.modalService.show({ component: ConfirmBookingComponent }).then((modalRef) => {
      modalRef.onHidden.subscribe(() => {
        this.isShowingModal = false;
        this.cd.markForCheck();
      });
    });
  }

  private updateCriteria(value: StorageCriteria, debounce: boolean) {
    if (!value) {
      return;
    }

    if (this.isCriteriaInitialized && value.equals(this.criteriaService.current)) {
      // No change to criteria, make sure to stop loading state
      this.isLoading = false;
      return;
    }

    this.criteriaService.updateCurrentCriteria(value, debounce);
    this.isCriteriaInitialized = true;

    this.location = value ? value.location : null;
    this.showRedoSearchButton = !this.isSearchOnMoveMapEnabled && this.isMapLocationChanged;

    this.cd.markForCheck();
  }

  getShopDetailsRouterLink(shopId: string) {
    return [
      '/shop-details',
      shopId,
      {
        bags: this.criteria.luggage.normal,
        from: SharedUtilDate.serializeDate(this.criteriaService.currentOrDefault.period.from, false),
        to: SharedUtilDate.serializeDate(this.criteriaService.currentOrDefault.period.to, false),
        source: 'app',
      },
    ];
  }

  onShowShopDetails(shop: BookableStorageLocation) {
    this.ngZone.run(() => {
      this.shopsService.setCurrent(shop);
      void this.router.navigate(this.getShopDetailsRouterLink(shop._id));
    });
  }

  private async onQueryParamsChange(_queryParams: Params) {
    if (this.promoCodeService.appliedPromoCode) {
      this.isCheckingPromoCode = true;

      // Wait for any potential discount to be determined
      await this.promoCodeService.waitForAppliedDiscount();

      this.isCheckingPromoCode = false;
    }
  }

  private async onParamsChange(params: Params) {
    if (!this.isFirstParamsChange) {
      return;
    }
    this.isFirstParamsChange = false;

    this.geoLocationState = await this.geolocationService.queryGeolocationState();

    this._isNewFindStorageViewVariantEnabled = await this.splitTestingService.isVariantEnabled(
      AppConfig.EXPERIMENT_VARIANTS.newFindStorageView,
    );

    // Determine if we should show the map before the list on mobile
    let showMap = await this.splitTestingService.isVariantEnabled(AppConfig.EXPERIMENT_VARIANTS.travelerMapFirst);

    const findShopParams = await this.paramsService.parseFindShop(params);
    if (findShopParams) {
      if (typeof findShopParams.showMap === 'boolean') {
        showMap = findShopParams.showMap;
      }
      if (findShopParams.geolocate) {
        void this.loadShops(findShopParams.criteria);
      } else {
        this.criteria = findShopParams.criteria;
      }
      if (findShopParams.expand) {
        this.expandCriteria();
      }
      this.showUncertified = findShopParams.showUncertified;
      this.selectedShopId = findShopParams.selectedShop;
    } else {
      // No valid params provided
      void this.loadShops(this.criteriaService.currentOrDefault);
    }

    if (showMap) {
      this.showView('map');
    }
  }

  private async loadShops(criteria: StorageCriteria) {
    void this.log.debug(`Loading shops with geolocation state '${this.geoLocationState}'`);
    this.isLoading = true;

    if (this.geoLocationState === 'granted') {
      try {
        const success = await this.loadShopsByUserLocation(criteria.period, criteria.luggage);
        if (!success) {
          // No shops near the user's current location
          this.criteria = criteria;
        }
        this.isLoading = false;
      } catch (err) {
        this.criteria = criteria;
        this.isLoading = false;
      }
    } else {
      // Use what criteria we have now to guarantee a result
      this.criteria = criteria;
      this.isLoading = false;

      if (this.geoLocationState !== 'denied') {
        // Try to load shops by the user's location
        void this.loadShopsByUserLocation(criteria.period, criteria.luggage);
      }
    }
  }

  private async loadShopsByUserLocation(period: ITimePeriod, luggage: ILuggage): Promise<boolean> {
    const location = await this.locationService.getUserLocation(false);
    if (!location) {
      return false;
    }
    const criteria = new SharedStorageCriteria(location, period, luggage);
    const shops = await this.shopsService.listShops(criteria);

    const success = this.getAvailableShopCount(shops, criteria) > 0;
    if (success) {
      this.criteria = criteria;
      this.processAndDisplayShops(shops, criteria);
      this.loadFailed = false;
    }
    return success;
  }

  private getAvailableShopCount(shops: BookableStorageLocation[], criteria: StorageCriteria): number {
    if (!shops) {
      return 0;
    }
    let availableCount = 0;
    shops.forEach((shop) => {
      if (this.shopsService.isShopAvailable(shop, criteria)) {
        availableCount++;
      }
    });
    return availableCount;
  }
}
