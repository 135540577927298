<div class="reservation-details">
  <div class="form-group">
    <label for="lastName">
      {{ 'LAST_NAME' | lhTranslate }}
      <input
        type="text"
        name="lastName"
        id="lastName"
        placeholder="Smith"
        class="form-control"
        [(ngModel)]="lastName"
      />
    </label>
  </div>

  <div class="form-group">
    <label for="arrivalDate">
      {{ 'CHECK_IN_DATE' | lhTranslate }}
      <input type="date" name="arrivalDate" id="arrivalDate" class="form-control" [(ngModel)]="checkInDateString" />
    </label>
  </div>

  @if (errorMessage) {
    <div class="alert alert-warning">{{ errorMessage | lhTranslate }}</div>
  }
</div>
