import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { Config } from '@luggagehero/shared/environment';

import { WebModule } from './web.module';

if (!Config.isDevelopment) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(WebModule)
  .catch((err) => console.log(err));
