import { ChangeDetectionStrategy, Component } from '@angular/core';
import { LineChartBaseComponent } from '@luggagehero/features/ui';

@Component({
  selector: 'lh-line-chart',
  templateUrl: './line-chart.component.html',
  styleUrls: ['./line-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LineChartComponent extends LineChartBaseComponent {}
