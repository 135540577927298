<div class="step-content">
  <div class="step-body">
    <!-- Number of bags -->
    <div class="section">
      <lh-simple-bag-picker heading="HOW_MANY_BAGS_SHORT" subheading="" [maxBags]="maxBags"></lh-simple-bag-picker>
      <div class="alert alert-success" *ngIf="minBagsWarning">{{ minBagsWarning }}</div>
    </div>

    <!-- Pricing model -->
    <div class="section" *ngIf="isPricingModelSelectorEnabled">
      <lh-pricing-model-selector
        [simpleMode]="true"
        [hourlyRate]="hourlyRate"
        [dailyRate]="dailyRate"
        [currency]="currency"
      ></lh-pricing-model-selector>
    </div>
  </div>

  <div class="step-footer">
    <lh-ticket-stub dock="bottom" [isPaddingBottomRequired]="false">
      <lh-simple-order-summary
        [headline]="'SUMMARY' | translate"
        [isPaymentCardValidationEnabled]="true"
        [isPaid]="false"
        [isCollapsed]="false"
        [isLoading]="isUpdating"
        [order]="bookingDraft.order"
        [callToAction]="promoCodeCta | translate"
        (callToActionClick)="showPromoCodeModal()"
        (orderLineChange)="onOrderLineChange($event)"
      ></lh-simple-order-summary>

      <div class="sticky-footer" [class.padding-top]="!footer && !isAddPaymentCardButtonVisible">
        <!-- Confirm booking -->
        <button
          *ngIf="!isPaymentMethodSelectorVisible"
          [disabled]="isLoading || isUpdating"
          class="btn btn-lg btn-block btn-primary btn-confirm"
          (click)="goForward()"
        >
          <lh-shared-ui-spinner [visible]="isLoading"></lh-shared-ui-spinner>
          <span *ngIf="!isLoading">{{ 'CONFIRM' | translate }}</span>
          <div class="btn-overlay-inflate" *ngIf="isLoading || isUpdating"></div>
        </button>

        <!-- Select payment method -->
        <lh-payment-method-selector
          *ngIf="isPaymentMethodSelectorVisible"
          [(ngModel)]="paymentMethod"
          [paymentMethods]="paymentMethods"
          [isPaymentDue]="false"
          [isConfirmingBooking]="true"
          [isSimpleMode]="true"
          [isLargeButtonsEnabled]="true"
          [isWalletPaymentAllowed]="true"
          [order]="bookingDraft.order"
          [payment]="bookingDraft.payment"
          [storageLocation]="storageLocation"
          [disabled]="isLoading || isUpdating"
          (confirm)="goForward()"
          #paymentMethodSelector
        ></lh-payment-method-selector>
      </div>

      <div class="non-sticky-footer" *ngIf="footer || isAddPaymentCardButtonVisible">
        <button class="btn btn-block btn-info btn-rounded" (click)="goForward()" *ngIf="isAddPaymentCardButtonVisible">
          {{ 'USE_OTHER_PAYMENT_METHOD' | translate }}
        </button>

        <div class="footnote" [class.text-danger]="footer?.isError" *ngIf="footer?.text">
          {{ footer.text }}
          <a
            class="href"
            (click)="footer.action && footer.action()"
            [attr.target]="!footer.action && footer.link ? '_blank' : null"
            [attr.href]="!footer.action && footer.link ? footer.link : null"
            *ngIf="footer.callToAction && (footer.action || footer.link)"
            >{{ footer.callToAction }}</a
          >
        </div>
      </div>
    </lh-ticket-stub>
  </div>
</div>
