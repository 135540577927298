import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TranslatePipe } from '@luggagehero/shared/ui-pipes';

@Component({
  selector: 'lh-shared-ui-timer',
  standalone: true,
  imports: [CommonModule, TranslatePipe],
  templateUrl: './shared-ui-timer.component.html',
  styleUrl: './shared-ui-timer.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SharedUiTimerComponent {
  @Input() public largeDigits = false;
  @Input() public compactMode = false;
  @Input() public darkMode = false;

  @Input() public timerLabel = '';
  @Input() public daysLabel = 'D';
  @Input() public hoursLabel = 'H';
  @Input() public minutesLabel = 'M';
  @Input() public secondsLabel = 'S';

  @Input() public days = '--';
  @Input() public hours = '--';
  @Input() public minutes = '--';
  @Input() public seconds = '--';
}
