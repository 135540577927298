import { NgModule, NO_ERRORS_SCHEMA, Type } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BaseComponent } from '@luggagehero/core';
import { GuestFeatureDropoffComponent } from '@luggagehero/guest-feature-dropoff';
import { SharedUITooltipComponent } from '@luggagehero/shared/ui';
import { SharedFeatureBagImageInputComponent } from '@luggagehero/shared-feature-bag-image-input';
import { TravelerShopsFeatureFindComponent } from '@luggagehero/traveler/shops/feature-find';
import {
  TravelerShopsUiAggregateRatingComponent,
  TravelerShopsUiPOIListDetailsComponent,
  TravelerShopsUiPricingDetailsComponent,
} from '@luggagehero/traveler/shops/ui';
import { TravelerShopsFeatureOpeningHoursComponent } from '@luggagehero/traveler-shops-feature-opening-hours';
import { TravelerShopsFeatureShopReviewsComponent } from '@luggagehero/traveler-shops-feature-reviews';
import { UiModule } from '@luggagehero/web/features/ui/ui.module';
import { RatingModule } from 'ngx-bootstrap/rating';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';

import { UserModule } from '../user/user.module';
import {
  AbandonBookingComponent,
  DropoffComponent,
  FindShopComponent,
  FindStorageComponent,
  ShopDetailsComponent,
  SHOPS_COMPONENTS,
} from './components';

//
// Defines components to be used in routing. Configure A/B testing here.
//
const ROUTE_COMPONENTS: { [componentName: string]: Type<BaseComponent> } = {
  // Send approximately half of the users to the variant home component
  home: FindShopComponent, // Math.random() < 0.5 ? FindShopComponent : FindStorageComponent,
  findShop: FindShopComponent,
  findStorage: FindStorageComponent,
  shopDetails: ShopDetailsComponent,
  dropoff: DropoffComponent,
};

const SHOPS_ROUTES: Routes = [
  //
  // We are using 'home' as the base route instead of just '' because angular route params
  // don't work with a root path.
  //
  // For example:
  //   https://example.com;foo=bar        <--  this does NOT work
  //   https://example.com/;foo=bar       <--  this does NOT work
  //   https://example.com/home;foo=bar   <--  this works
  //
  // The root path works with query params, so if we switch to using those we could drop
  // the /home from the base route. Query params, as opposed to route params, are available
  // to all routes, so we would have to decide if that is what we want before switching.
  //
  // More info:
  //   https://angular.io/guide/router
  //   https://alligator.io/angular/query-parameters/
  //
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'home', component: ROUTE_COMPONENTS.home },
  { path: 'home/find-shop', component: ROUTE_COMPONENTS.findShop },
  { path: 'home/find-storage', redirectTo: 'home' },
  { path: 'shop-details/:id', component: ROUTE_COMPONENTS.shopDetails },
  { path: 'book-shop/:id', component: ROUTE_COMPONENTS.dropoff },
  { path: 'dropoff/:id', component: ROUTE_COMPONENTS.dropoff },
];

@NgModule({
  imports: [
    TypeaheadModule.forRoot(),
    UiModule,
    UserModule,
    RatingModule.forRoot(),
    RouterModule.forChild(SHOPS_ROUTES),
    TravelerShopsFeatureFindComponent,
    GuestFeatureDropoffComponent,
    SharedFeatureBagImageInputComponent,
    TravelerShopsFeatureShopReviewsComponent,
    TravelerShopsUiAggregateRatingComponent,
    TravelerShopsUiPricingDetailsComponent,
    TravelerShopsUiPOIListDetailsComponent,
    TravelerShopsFeatureOpeningHoursComponent,
    SharedUITooltipComponent,
  ],
  declarations: [...SHOPS_COMPONENTS],
  schemas: [NO_ERRORS_SCHEMA],
  // TODO: Move this component to the bookings module
  exports: [AbandonBookingComponent],
})
export class ShopsModule {}
