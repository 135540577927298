import { Injectable } from '@angular/core';
import { DiscountTransformerService, IDiscount } from '@luggagehero/shared/interfaces';
import { SharedPricingService } from '@luggagehero/shared/services/pricing';
import { SharedTranslateService } from '@luggagehero/shared/services/translation';

@Injectable({
  providedIn: 'root',
})
export class SharedDiscountTransformerService implements DiscountTransformerService {
  constructor(
    private priceService: SharedPricingService,
    private translate: SharedTranslateService,
  ) {}

  transform(discount: IDiscount, bags: number, includeCode = false): string {
    if (!discount) {
      return '';
    }

    switch (discount.code) {
      case 'BESTBUY':
        return this.translate.instant('BESTBUY_PROMO_CODE_TEXT');
    }

    let text: string;

    const maxBagsForDiscount = discount.maxBags ? discount.maxBags : bags;

    switch (discount.type) {
      case 'absolute':
        text = this.formatAbsolute(discount.value, discount.currency);
        break;

      case 'bags':
        text = this.formatBags(discount.value);
        break;

      case 'hours':
        text = this.formatHours(discount.value, discount.maxBags);
        break;

      case 'percentage':
        if (maxBagsForDiscount >= bags) {
          text = this.formatPercentage(discount.value);
        } else {
          text = `${discount.value}% ${this.translate.instant('PRICE_DISCOUNT').toLowerCase()}`;
        }
        break;

      default:
        text = this.formatPercentage(discount.value);
        break;
    }

    if (includeCode) {
      text = `${discount.code} – ${text}`;
    }

    return text;
  }

  formatAbsolute(value: number, currency: string): string {
    const suffix = this.translate.instant('OFF_THE_FINAL_STORAGE_PRICE');
    const amount = this.priceService.format(value, currency);
    return `${amount} ${suffix.toLowerCase()}`;
  }

  formatBags(value: number): string {
    const prefix = this.translate.instant('FREE_STORAGE_OF_X_BAGS');
    const suffix = this.translate.instant(value === 1 ? 'BAG' : 'BAGS');
    return `${prefix} ${value} ${suffix.toLowerCase()}`;
  }

  formatHours(value: number, maxBags: number): string {
    const hours = this.translate.instant(value === 1 ? 'X_HOUR_OF_FREE_STORAGE' : 'X_HOURS_OF_FREE_STORAGE');
    const hoursText = `${value} ${hours}`;

    const textParts = [hoursText.toLowerCase()];

    if (typeof maxBags === 'number' && maxBags !== 0) {
      const bags = this.translate.instant(maxBags === 1 ? 'BAG' : 'BAGS');
      const bagsText = `${this.translate.instant('FOR_BAGS')} ${maxBags} ${bags}`;

      textParts.push(bagsText.toLowerCase());
    }

    return textParts.join(' ');
  }

  formatPercentage(value: number): string {
    const suffix = this.translate.instant('OFF_THE_FINAL_STORAGE_PRICE');
    return `${value}% ${suffix.toLowerCase()}`;
  }
}
