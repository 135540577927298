import { Inject, Injectable, InjectionToken } from '@angular/core';
import { SharedHttpService } from '@luggagehero/shared/services/http';
import { SharedLoggingService } from '@luggagehero/shared/services/logging';

export interface IPLocationInfo {
  ip: string;
  country_code: string;
  country_name: string;
  region_code: string;
  region_name: string;
  city: string;
  zip_code: string;
  time_zone: string;
  latitude: number;
  longitude: number;
  metro_code: number;
}

export const USERS_IP_LOCATION_SERVICE_ENDPOINT_TOKEN = new InjectionToken<string>(
  'USERS_IP_LOCATION_SERVICE_ENDPOINT_TOKEN',
);

@Injectable()
export class IPLookupService {
  private apiEndPoint: string;

  constructor(
    private httpService: SharedHttpService,
    private log: SharedLoggingService,
    @Inject(USERS_IP_LOCATION_SERVICE_ENDPOINT_TOKEN) private ipLocationEndpoint: string,
  ) {
    this.apiEndPoint = ipLocationEndpoint;
  }

  public async locate(): Promise<IPLocationInfo> {
    // FIXME: This endpoint should probably not be on the traveler API
    const url = `${this.apiEndPoint}/users/location`;
    const res = await this.httpService.get<IPLocationInfo>(url, false);

    void this.log.info(`Got IP location result`, res);

    if (!res.country_code) {
      // This happens when running e.g. on localhost while developing
      throw new Error('Could not locate user by IP');
    }

    return res;
  }
}
