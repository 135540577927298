import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { OrderRequestsFacade } from '@luggagehero/guest-data-access-orders';
import { SharedAppSettingsService } from '@luggagehero/shared/app-settings/data-access';
import { SharedUiNumericUpDownComponent } from '@luggagehero/shared/ui';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { GuestDropoffStepBaseComponent } from '../guest-dropoff-step-base-component';
import { BagSelectionStepProperties } from './bag-selection-step-properties';

@Component({
  selector: 'lh-bag-selection-step',
  standalone: true,
  imports: [CommonModule, SharedUiNumericUpDownComponent],
  templateUrl: './bag-selection-step.component.html',
  styleUrl: './bag-selection-step.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BagSelectionStepComponent extends GuestDropoffStepBaseComponent<BagSelectionStepProperties> {
  public maxNumberOfBags = 100;
  public minNumberOfBags = 1;
  public numberOfBags$: Observable<number>;

  private appSettings = inject(SharedAppSettingsService);
  private orderRequestsFacade = inject(OrderRequestsFacade);

  constructor() {
    super();

    this.addSubscription(
      this.appSettings.$current
        .pipe(
          take(1),
          map((settings) => {
            this.minNumberOfBags = 1; // settings.DROP_OFF_MIN_BAGS;
            this.maxNumberOfBags = settings.DROP_OFF_MAX_BAGS;

            // Initialize numberOfBags$ after settings are loaded
            this.numberOfBags$ = this.orderRequestsFacade.orderRequest$.pipe(
              map((orderRequest) => orderRequest.bags ?? this.minNumberOfBags),
            );

            this.canGoForward = true;
          }),
        )
        .subscribe(),
    );
  }

  protected get defaultProperties(): BagSelectionStepProperties {
    return {
      header: 'NUMBER_OF_BAGS',
      body: ['SELECT_NUMBER_OF_BAGS_TO_STORE'],
    };
  }

  protected onInit(): Promise<void> {
    // Nothing to initialize
    return Promise.resolve();
  }

  public onNumberOfBagsChange(value: number): void {
    if (!value) {
      return;
    }
    // Update the order request via the facade
    this.orderRequestsFacade.updateOrderRequest({ bags: value });
    this.bookingDraft.luggage = { normal: value, hand: 0 };
  }
}
