import { Component, inject, Input } from '@angular/core';
import { BookableStorageLocation, StorageLocationItemBadge } from '@luggagehero/shared/interfaces';
import { SharedShopsService } from '@luggagehero/shared/services/shops';

import { BaseComponent } from '../../../core';

@Component({ template: '' })
export abstract class StorageLocationBadgeListBaseComponent extends BaseComponent {
  private storageLocationService = inject(SharedShopsService);

  private _storageLocation: BookableStorageLocation;
  private _badges: StorageLocationItemBadge[] = [];

  public get storageLocation(): BookableStorageLocation {
    return this._storageLocation;
  }
  @Input() public set storageLocation(value: BookableStorageLocation) {
    this._storageLocation = value;
    this._detectChanges();
  }

  public get badges(): StorageLocationItemBadge[] {
    if (!this.storageLocation) {
      return this._badges;
    }
    return this.storageLocationService.getBadgeList(this.storageLocation);
  }
  @Input() public set badges(value: StorageLocationItemBadge[]) {
    this._badges = value;
    this._detectChanges();
  }
}
