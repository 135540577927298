import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import {
  EmailOtpStrategy,
  EmailPasswordStrategy,
  PhoneOtpStrategy,
  PhonePasswordStrategy,
  UserAuthenticationBaseComponent,
} from '@luggagehero/features/user';
import { FacebookLoginService, GoogleSigninService } from '@luggagehero/services';
import { SharedErrorService } from '@luggagehero/shared/services/error';
import { SharedUserService } from '@luggagehero/shared/services/users';

@Component({
  selector: 'lh-user-authentication',
  templateUrl: './user-authentication.component.html',
  styleUrls: ['./user-authentication.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserAuthenticationComponent extends UserAuthenticationBaseComponent {
  constructor(
    emailPasswordStrategy: EmailPasswordStrategy,
    emailOtpStrategy: EmailOtpStrategy,
    phonePasswordStrategy: PhonePasswordStrategy,
    phoneOtpStrategy: PhoneOtpStrategy,
    userService: SharedUserService,
    facebookLogin: FacebookLoginService,
    googleSignin: GoogleSigninService,
    error: SharedErrorService,
    formBuilder: FormBuilder,
    cd: ChangeDetectorRef,
  ) {
    super(
      emailPasswordStrategy,
      emailOtpStrategy,
      phonePasswordStrategy,
      phoneOtpStrategy,
      userService,
      facebookLogin,
      googleSignin,
      error,
      formBuilder,
      cd,
    );
  }

  public get isAppleSignInAvailable(): boolean {
    // Not supporting Apple Sign-in on web for now
    return false;
  }

  public signInWithApple(): Promise<void> {
    throw new Error('Apple sign-in is not supported on web');
  }

  public getUserConfirmation(prompt: string): boolean {
    return confirm(prompt);
  }
}
