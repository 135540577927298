<div class="step-content">
  <div class="step-body">
    <div class="tip-container" *ngIf="showTip">
      <h5>{{ 'WILL_YOU_TIP_THE_STAFF' | translate }}</h5>
      <p>{{ 'PARTNERS_RECEIVE_ALL_TIPS' | translate }}</p>
      <div class="tip-options">
        <button
          *ngFor="let tipOption of tipOptions"
          (click)="setTipAmount(tipOption.amount)"
          class="btn btn-default btn-lg"
          [class.active]="tip === tipOption.amount && !isCustomTipVisible"
          [disabled]="isLoading"
        >
          <span>{{ tipOption.label }}</span>
        </button>
      </div>

      <button
        class="btn btn-default btn-block btn-lg btn-custom-tip"
        [class.active]="isCustomTipVisible"
        (click)="toggleCustomTip()"
        [disabled]="isLoading"
        *ngIf="!isCustomTipVisible"
      >
        {{ 'OTHER_TIP_AMOUNT' | translate }}
      </button>

      <div [hidden]="!isCustomTipVisible" class="custom-tip">
        <span class="currency-symbol">{{ currencySymbol }}</span>
        <input
          id="customTip"
          name="customTip"
          #customTip="ngModel"
          class="form-control tip-input"
          type="tel"
          pattern="^\d+\.{0,1}\d{0,2}$"
          [(ngModel)]="tip"
          [placeholder]="'Tip amount' | translate"
          #customTipInput
        />
      </div>

      <div class="alerts" *ngIf="!isLoading">
        <div *ngIf="isCustomTipInvalid" class="alert alert-danger">
          {{ 'TIP_AMOUNT_INVALID' | translate }}
        </div>
        <div *ngIf="isTipTooLarge" class="alert alert-danger">
          {{ 'TIP_NO_MORE_THAN_X' | translate }} {{ maxTipFormatted }}
        </div>
        <div *ngIf="isMissingTipSelection" class="alert alert-danger">
          {{ 'MISSING_TIP_CHOICE_ALERT' | translate }}
        </div>
      </div>

      <div class="tip-note">
        {{ 'CHANGE_TIP_AT_PICK_UP' | translate }}
      </div>
    </div>
  </div>
</div>
