import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';

import { BaseComponent } from '../../../core';

const noop = () => {
  /**/
};

const DEFAULT_MAX_BAGS = 50;
const DEFAULT_MAX_INLINE_BAGS = 5;

@Component({ template: '' })
export abstract class BagSelectorBaseComponent extends BaseComponent implements ControlValueAccessor, OnInit {
  @Output() public bagsSelectedChange = new EventEmitter<boolean>();
  public inlineOptions: number[];
  public moreBagsOptions: number[];

  private _maxBags = DEFAULT_MAX_BAGS;
  private _maxInlineBags = DEFAULT_MAX_INLINE_BAGS;
  private _addBagSuffix = false;
  private _compact = false;
  private _bagsSelected = false;
  private _moreBagsSelectedOption: number;
  private _value: number;
  private _disabled = false;
  private isFirstValue = true;

  private onTouchedCallback: () => void = noop;
  private onChangeCallback: (_) => void = noop;

  constructor(private cd: ChangeDetectorRef) {
    super();
  }

  get addBagSuffix(): boolean {
    return this._addBagSuffix;
  }
  @Input() set addBagSuffix(value: boolean) {
    this._addBagSuffix = value;
    this.loadOptions();
  }

  get compact(): boolean {
    return this._compact;
  }
  @Input() set compact(value: boolean) {
    this._compact = value;
    this.cd.markForCheck();
  }

  get maxInlineBags(): number {
    return this._maxInlineBags;
  }
  @Input() set maxInlineBags(value: number) {
    this._maxInlineBags = value;
    this.loadOptions();
  }

  get maxBags(): number {
    return this._maxBags;
  }
  @Input() set maxBags(value: number) {
    this._maxBags = value;
    this.loadOptions();
  }

  get bagsSelected(): boolean {
    return this._bagsSelected;
  }
  @Input() set bagsSelected(value: boolean) {
    this._bagsSelected = value;
    this.cd.markForCheck();
  }

  get disabled(): boolean {
    return this._disabled;
  }
  @Input() set disabled(value: boolean) {
    this._disabled = value;
    this.cd.markForCheck();
  }

  get value(): number {
    return this._value;
  }
  set value(value: number) {
    this._value = value;

    if (value && this.isFirstValue) {
      // Avoid triggering infinite loop
      this.isFirstValue = false;

      if (value > 5) {
        this.moreBagsSelectedOption = value;
      }
    }
    this.onChangeCallback(value);
    this.cd.markForCheck();
  }

  get moreBagsSelectedOption(): number {
    return this._moreBagsSelectedOption;
  }
  set moreBagsSelectedOption(value: number) {
    if (value) {
      this.selectBags(value);
    }
    this._moreBagsSelectedOption = value;
    this.cd.markForCheck();
  }

  ngOnInit() {
    this.loadOptions();
  }

  selectBags(numberOfBags: number) {
    if (numberOfBags <= this.maxInlineBags) {
      // Reset dropdown to default option
      this.moreBagsSelectedOption = undefined;
    }
    this.value = numberOfBags;

    this.bagsSelected = true;
    this.bagsSelectedChange.emit(true);
  }

  isNumberOfBagsSelected(numberOfBags: number): boolean {
    if (!this.bagsSelected) {
      return false;
    }
    return this.value === numberOfBags;
  }

  writeValue(value: number) {
    if (value !== undefined) {
      this.value = value;
    }
  }

  registerOnChange(fn: (_) => void) {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: () => void) {
    this.onTouchedCallback = fn;
  }

  private loadOptions() {
    this.inlineOptions = this.getLuggageOptions(1, this.maxInlineBags);
    this.moreBagsOptions = this.getLuggageOptions(this.maxInlineBags + 1, this.maxBags);
    this.cd.markForCheck();
  }

  private getLuggageOptions(min: number, max: number): number[] {
    const options: number[] = [];
    for (let count = min; count <= max; count++) {
      options.push(count);
    }
    return options;
  }
}
