@if (isLoggedIn) {
  <div [class.margin-bottom]="isPaymentCardInputVisible">
    <lh-shared-feature-payment-method-picker
      [paymentMethods]="paymentMethods"
      (selectedPaymentMethodChange)="onSelectedPaymentMethodChange($event)"
    ></lh-shared-feature-payment-method-picker>
  </div>

  @if (isPaymentCardInputVisible) {
    <lh-shared-feature-payment-card-input
      [hidden]="!isPaymentCardInputVisible"
      [payment]="payment"
      (canSubmitChange)="onPaymentCardInputCanSubmitChange($event)"
      (paymentCardResult)="onPaymentCardResult($event)"
      #paymentCardInput
    ></lh-shared-feature-payment-card-input>
  }
}
