export class NumberUtil {
  /**
   * Returns the provided number if it is valid; otherwise, returns the provided fallback value (0 by default)
   *
   * @param value The number to validate
   * @param fallback The fallback value if the number is invalid
   * @returns The valid number or the fallback value
   */
  public static valid(value: number, fallback = 0): number {
    if (typeof value !== 'number' || isNaN(value) || !isFinite(value)) {
      // Not a valid number
      return fallback;
    }
    // Valid number
    return value;
  }

  /**
   * Returns a random number between the provided minimum and maximum values
   *
   * @param min The minimum value
   * @param max The maximum value
   * @returns The random number
   */
  public static random(min: number, max: number): number {
    if (min > max) {
      // Swap the values
      [min, max] = [max, min];
    }

    if (min === max) {
      // Return the min value
      return min;
    }

    return Math.random() * (max - min) + min;
  }
}
