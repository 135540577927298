import { inject, Injectable } from '@angular/core';
import { SharedHttpService } from '@luggagehero/shared/services/http';

import { AppEvent } from './app-event';
import { SHARED_APP_EVENT_SERVICE_CONFIG_TOKEN } from './shared-app-event-service.token';
import { SharedAppEventServiceConfig } from './shared-app-event-service-config.interface';

@Injectable({
  providedIn: 'root',
})
export class SharedAppEventService {
  private config = inject<SharedAppEventServiceConfig>(SHARED_APP_EVENT_SERVICE_CONFIG_TOKEN);

  constructor(private httpService: SharedHttpService) {}

  public async add(appEvent: AppEvent): Promise<void> {
    const url = `${this.config.apiURL()}/app-event`;
    await this.httpService.post<AppEvent>(url, appEvent);
  }
}
