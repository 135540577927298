import { ErrorHandler, Injectable } from '@angular/core';
import { SharedLoggingService } from '@luggagehero/shared/services/logging';
import { SharedNotificationService } from '@luggagehero/shared/services/notification';

@Injectable({
  providedIn: 'root',
})
export class SharedErrorService implements ErrorHandler {
  private static listenersAdded = false;

  constructor(
    private notify: SharedNotificationService,
    private log: SharedLoggingService,
  ) {
    if (!SharedErrorService.listenersAdded) {
      SharedErrorService.listenersAdded = true;
      window.addEventListener('unhandledrejection', (event: PromiseRejectionEvent) => {
        this.handleError(event.reason);
      });
      window.addEventListener('rejectionhandled', (event: PromiseRejectionEvent) => {
        this.handleError(event.reason);
      });
    }
  }

  public handleError(err: unknown, notificationMessage?: string, notificationTitle?: string): void {
    void this.log.error(err);

    if (notificationMessage) {
      this.notify.error(notificationMessage, notificationTitle);
    }
  }
}
