import { Component, inject, OnInit } from '@angular/core';
import { SecuritySeal, SmartImage } from '@luggagehero/shared/interfaces';

import { ModalService } from '../../../../../core/services/index';
import { DropoffStepBaseComponent } from './dropoff-step.base-component';

@Component({ template: '' })
export abstract class PhotoStepBaseComponent extends DropoffStepBaseComponent implements OnInit {
  protected modalService = inject(ModalService);

  private _localLuggageImage: string;

  public get remoteLuggageImage(): SmartImage {
    return this.bookingDraft.remoteLuggageImage;
  }
  public set remoteLuggageImage(value: SmartImage) {
    this.bookingDraft.remoteLuggageImage = value;
  }

  public get localLuggageImage(): string {
    return this._localLuggageImage;
  }

  public get securitySeals(): SecuritySeal[] {
    return this.remoteLuggageImage?.securitySeals;
  }

  public get forwardCallToAction(): string {
    return 'CONTINUE';
  }

  protected get useDefaultNavigation(): boolean {
    return true;
  }

  public ngOnInit(): void {
    this._localLuggageImage = this.bookingDraft.localLuggageImage || '';
    this.canGoForward = this.remoteLuggageImage ? true : false;
  }

  public onRemoteImageChange(remoteImage: SmartImage): void {
    if (!remoteImage) {
      return;
    }

    this.remoteLuggageImage = remoteImage;
  }

  public onCompletedChange(completed: boolean): void {
    this.canGoForward = completed;
  }

  public onAllowSkipChange(allowSkip: boolean): void {
    this.canSkip = allowSkip;
  }

  protected abstract showExample(): void;
}
