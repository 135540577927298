import { ChangeDetectorRef, Component, EventEmitter, inject, Input } from '@angular/core';

import { BaseComponent } from '../../../core';

@Component({ template: '' })
export abstract class PaypalButtonBaseComponent extends BaseComponent {
  public click = new EventEmitter<Event>();
  private cd = inject(ChangeDetectorRef);

  private _disabled = false;
  private _isLoading = false;

  public get isLoading(): boolean {
    return this._isLoading;
  }
  @Input() public set isLoading(value: boolean) {
    this._isLoading = value;
    this.cd.markForCheck();
  }

  public get disabled(): boolean {
    return this._disabled;
  }
  @Input() public set disabled(value: boolean) {
    this._disabled = value;
    this.cd.markForCheck();
  }

  onClick(event: Event): void {
    this.click.emit(event);
  }
}
