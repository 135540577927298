import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  forwardRef,
  Provider,
  QueryList,
  ViewChildren,
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import { PhoneInputBaseComponent } from '@luggagehero/features/ui';
import { IPLookupService } from '@luggagehero/services';
import { TranslateService } from '@ngx-translate/core';

import { IntlTelInputDirective } from '../../directives/intl-tel-input.directive';

export const PHONE_INPUT_CONTROL_VALUE_ACCESSOR: Provider = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => PhoneInputComponent),
  multi: true,
};
export const PHONE_INPUT_VALIDATOR: Provider = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => PhoneInputComponent),
  multi: true,
};

@Component({
  selector: 'lh-phone-input',
  templateUrl: './phone-input.component.html',
  styleUrls: ['./phone-input.component.scss'],
  providers: [PHONE_INPUT_CONTROL_VALUE_ACCESSOR, PHONE_INPUT_VALIDATOR],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PhoneInputComponent extends PhoneInputBaseComponent implements ControlValueAccessor, Validator {
  @ViewChildren(IntlTelInputDirective) intlTelInputDirectives: QueryList<IntlTelInputDirective>;

  constructor(ipLookup: IPLookupService, translate: TranslateService, cd: ChangeDetectorRef) {
    super(ipLookup, translate, cd);
  }

  validate(_control: AbstractControl): ValidationErrors {
    if (!this.intlTelInputDirectives) {
      return null;
    }
    const err = this.intlTelInputDirectives.first.getValidationError();
    if (err) {
      return { invalid: { reason: err, input: this.value } };
    }
    return null;
  }
}
