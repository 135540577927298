import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  NgZone,
  Output,
  Provider,
  ViewChild,
} from '@angular/core';
import { FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { IPlacesSearch, LatLng, Place } from '@luggagehero/shared/interfaces';
import { SharedAnalyticsService } from '@luggagehero/shared/services/analytics';
import { SharedGeocodingService } from '@luggagehero/shared/services/geocoding';
import { SharedPlacesSearchDropdownService } from '@luggagehero/shared/services/places-search-dropdown';
import { TranslatePipe } from '@luggagehero/shared/ui-pipes';
import { TranslateModule } from '@ngx-translate/core';
import { BsDropdownDirective, BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { from, Observable, of } from 'rxjs';

export const PLACES_SEARCH_CONTROL_VALUE_ACCESSOR: Provider = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => PlacesSearchComponent),
  multi: true,
};

const noop = () => void 0;

@Component({
  selector: 'lh-places-search',
  standalone: true,
  imports: [CommonModule, TranslateModule, FormsModule, BsDropdownModule, TranslatePipe],
  providers: [PLACES_SEARCH_CONTROL_VALUE_ACCESSOR, SharedPlacesSearchDropdownService],
  templateUrl: './places-search.component.html',
  styleUrl: './places-search.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlacesSearchComponent implements IPlacesSearch {
  @ViewChild('searchInput') public searchInputElementRef?: ElementRef<HTMLInputElement>;
  @ViewChild('placesDropdown') public placesDropdown?: BsDropdownDirective;
  @Output() public valueChange = new EventEmitter<Place>();

  @Input() typing = false;
  @Output() useCurrentLocation: EventEmitter<boolean> = new EventEmitter<boolean>();
  private queryTimeout?: unknown;
  private _value?: Place;
  private _predictions: google.maps.places.AutocompletePrediction[] = [];
  private _selectedPrediction?: google.maps.places.AutocompletePrediction;
  private _query = '';
  private _placeholder = '';
  private _formControlMode = false;
  private _disabled = false;
  private _location?: LatLng;
  private _radius = 50000; // 50 kilometer default radius
  private _businessesOnly = false;
  private _displayValue = '';

  private onTouchedCallback: () => void = noop;
  private onChangeCallback: (_: unknown) => void = noop;

  constructor(
    protected analytics: SharedAnalyticsService,
    protected ngZone: NgZone,
    protected cd: ChangeDetectorRef,
    protected searchDropDownService: SharedPlacesSearchDropdownService,
    protected geocodeService: SharedGeocodingService,
  ) {}

  get value(): Place | undefined {
    return this._value;
  }
  set value(value: Place | null | undefined) {
    if (!value || value === this._value) {
      return;
    }
    this._value = value;

    // Display the name of the selected place in the search field
    this.displayValue = value.name;

    this.onChangeCallback(value);
    this.cd.markForCheck();

    this.valueChange.emit(value);
  }

  get formControlMode(): boolean {
    return this._formControlMode;
  }
  @Input() set formControlMode(value: boolean) {
    this._formControlMode = value;
    this.cd.markForCheck();
  }

  get placeholder(): string {
    return this._placeholder;
  }
  @Input() set placeholder(value: string) {
    this._placeholder = value;
    this.cd.markForCheck();
  }

  get displayValue(): string {
    return this._displayValue;
  }
  set displayValue(value: string) {
    this._displayValue = value;
    this.cd.markForCheck();
  }

  get disabled(): boolean {
    return this._disabled;
  }
  @Input() set disabled(value: boolean) {
    this._disabled = value;
    this.cd.markForCheck();
  }

  get location(): LatLng | undefined {
    return this._location;
  }

  @Input() set location(value: LatLng) {
    this._location = value;
    this.cd.markForCheck();
  }

  get radius(): number {
    return this._radius;
  }
  @Input() set radius(value: number) {
    this._radius = value;
    this.cd.markForCheck();
  }

  get businessesOnly(): boolean {
    return this._businessesOnly;
  }

  @Input() set businessesOnly(value: boolean) {
    this._businessesOnly = value;
    this.cd.markForCheck();
  }

  get query(): string {
    return this._query;
  }
  set query(value: string) {
    this._query = value;
    this.cd.markForCheck();
  }

  get predictions(): google.maps.places.AutocompletePrediction[] {
    return this._predictions;
  }
  set predictions(value: google.maps.places.AutocompletePrediction[]) {
    this._predictions = value;
    this.cd.markForCheck();

    if (this.predictions.length > 0) {
      // Select the first prediction by default
      this.selectedPrediction = value[0];
    }
    // Show dropdown only if there are preditions
    if (this.predictions.length > 0) {
      this.placesDropdown?.show();
    } else {
      this.placesDropdown?.hide();
    }
    this.searchDropDownService.setIsOpen(this.predictions.length > 0 || this.typing);
  }

  get selectedPrediction(): google.maps.places.AutocompletePrediction | undefined {
    return this._selectedPrediction;
  }
  set selectedPrediction(value: google.maps.places.AutocompletePrediction) {
    this._selectedPrediction = value;
    this.cd.markForCheck();
  }

  get selectedPlaceName(): string | undefined {
    if (!this.value) {
      return undefined;
    }
    return this.value.name;
  }

  writeValue(value: Place) {
    if (value !== undefined) {
      this.value = value;
    }
  }

  registerOnChange(fn: (_: unknown) => void) {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: () => void = noop) {
    this.onTouchedCallback = fn;
  }

  useCurrentLocationSelected() {
    this.useCurrentLocation.emit(true);
  }

  selectPlace(prediction: google.maps.places.AutocompletePrediction) {
    if (!prediction) {
      return;
    }
    void this.geocodeService.requestReverseGeoCodeForPlaceId(prediction.place_id, 'selectPlace').then((place) => {
      // Hide the predictions dropdown
      this.predictions = [];

      place.name = prediction.structured_formatting.main_text;
      this.value = place;
      this.analytics.track('selectPlace', {
        category: place.address.countryCode,
        label: prediction.description,
        event: 'locationChange',
      });
    });
    this.useCurrentLocation.emit(false);
  }

  onQueryChanged() {
    // Clear pending query
    clearTimeout(this.queryTimeout as number);

    // Schedule new query
    this.queryTimeout = setTimeout(() => this.loadPredictions(), 500);
  }

  startTyping() {
    if (!this.searchInputElementRef) {
      return;
    }
    this.placesDropdown?.show();
    setTimeout(() => {
      this.ngZone.run(() => this.searchInputElementRef?.nativeElement.focus());
    }, 100);
  }

  selectAllText() {
    if (!this.searchInputElementRef) {
      return;
    }
    setTimeout(() => {
      this.ngZone.run(() => this.searchInputElementRef?.nativeElement.setSelectionRange(0, 9999));
    }, 0);
  }

  onSearchFieldKeyPress(e: KeyboardEvent) {
    if (e.keyCode !== 13) {
      // Enter was not pressed
      return;
    }
    if (this.selectedPrediction) {
      this.selectPlace(this.selectedPrediction);
    } else {
      // Search for the query and select the first result
      this.getPlacePredictions(this.query).subscribe((res: google.maps.places.AutocompletePrediction[]) =>
        this.selectPlace(res[0]),
      );
    }
  }

  clear() {
    this.value = null;
    this.displayValue = '';
  }

  clearAll() {
    this.clear();
    this.query = '';
    this.predictions = [];
    this.cd.markForCheck();
  }

  getMainText(prediction: google.maps.places.AutocompletePrediction): string {
    return prediction.structured_formatting.main_text;
  }

  getSecondaryText(prediction: google.maps.places.AutocompletePrediction): string {
    return prediction.structured_formatting.secondary_text;
  }

  loadPredictions() {
    this.getPlacePredictions(this.query).subscribe(
      (results: google.maps.places.AutocompletePrediction[]) => (this.predictions = results),
    );
  }

  private getPlacePredictions(query: string): Observable<google.maps.places.AutocompletePrediction[]> {
    if (query.length < 3) {
      return of([] as google.maps.places.AutocompletePrediction[]);
    }

    const types = this.businessesOnly ? 'establishment' : undefined;
    const tag = 'getPlacePredictions';
    return from(this.geocodeService.autocomplete(query, types, tag));
  }
}
