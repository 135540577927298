import { ChangeDetectionStrategy, Component } from '@angular/core';
import { StorageLocationBadgeListBaseComponent } from '@luggagehero/features/ui';

@Component({
  selector: 'lh-storage-location-badge-list',
  templateUrl: './storage-location-badge-list.component.html',
  styleUrls: ['./storage-location-badge-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StorageLocationBadgeListComponent extends StorageLocationBadgeListBaseComponent {}
