@if (isArrivalDepartureSegmentationVisible) {
  <div class="segmentation">
    <lh-shared-ui-select-option-button-group
      [prompt]="guestMovementPrompt"
      [options]="guestMovementOptions"
      [selected]="guestMovement$ | async"
      (selectedChange)="onGuestMovementChange($event)"
    ></lh-shared-ui-select-option-button-group>
  </div>
}

@if (isLoyaltyMemberSegmentationVisible) {
  <div class="segmentation">
    <lh-shared-ui-select-option-button-group
      [prompt]="loyaltyMemberPrompt"
      [options]="loyaltyMemberOptions"
      [selected]="loyaltyMember$ | async"
      (selectedChange)="onLoyaltyMemberChange($event)"
    ></lh-shared-ui-select-option-button-group>
  </div>
}
