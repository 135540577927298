<div class="shop-rating">
  <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.57904 16.4505L5.43904 10.3705L0.519043 6.85047H6.59904L8.51904 0.45047L10.439 6.85047H16.519L11.599 10.3705L13.459 16.4505L8.51904 12.6905L3.57904 16.4505Z"
      fill="currentColor"
    />
  </svg>
  @if (
    shopRating &&
    shopRating.numberOfRatingsAndRecommendations &&
    shopRating.averageRating &&
    shopRating.numberOfRatingsAndRecommendations > 0 &&
    shopRating.averageRating > 0
  ) {
    <div>{{ shopRating.averageRating }}</div>
    <div class="text-lighter">({{ shopRating.numberOfRatingsAndRecommendations }})</div>
  } @else {
    <div>{{ 'NEW' | lhTranslate }}</div>
  }
</div>
