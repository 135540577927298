import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Config } from '@luggagehero/shared/environment';
import {
  BookableStorageLocation,
  Booking,
  IPaymentRecord,
  ITimeInterval,
  Review,
  StorageCriteria,
} from '@luggagehero/shared/interfaces';
import { SharedAnalyticsService } from '@luggagehero/shared/services/analytics';
import { SharedBookingService } from '@luggagehero/shared/services/bookings';
import { SharedDocumentService } from '@luggagehero/shared/services/document';
import { SharedErrorService } from '@luggagehero/shared/services/error';
import { SharedGoogleMapsService } from '@luggagehero/shared/services/google-maps';
import { SharedLocationService } from '@luggagehero/shared/services/locations';
import { SharedLoggingService } from '@luggagehero/shared/services/logging';
import { SharedNotificationService } from '@luggagehero/shared/services/notification';
import { SharedPageTaggingService } from '@luggagehero/shared/services/page-tagging';
import { SharedPaymentService } from '@luggagehero/shared/services/payments';
import { SharedShopsService } from '@luggagehero/shared/services/shops';
import { SharedStorageCriteriaService } from '@luggagehero/shared/services/storage-criteria';
import { SharedUserService } from '@luggagehero/shared/services/users';
import { SharedWindowService } from '@luggagehero/shared/services/window';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment-timezone';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';

import { BaseComponent } from '../../../../core';
import { ScriptService } from '../../../../services/index';
import { DateUtil } from '../../../../utils/date.util';
import { ReviewBaseComponent } from '../review.base-component';
import { BookingStrategyFactory } from './booking-strategies/booking-strategy-factory';
import { IManageBookingStrategy } from './booking-strategies/manage-booking/manage-booking.strategy';

const dateFormat = 'YYYY-MM-DD HH:mm:ss';
const zeroDate = '1900-01-01 00:00:00';

@Component({ template: '' })
export abstract class ManageBookingBaseComponent extends BaseComponent implements OnInit, OnDestroy {
  @ViewChild('reservationModal') public reservationModal: ModalDirective;
  @ViewChild('paymentMethodModal') public paymentMethodModal: ModalDirective;
  @ViewChild('checkInModal') public checkInModal: ModalDirective;
  @ViewChild('checkOutModal') public checkOutModal: ModalDirective;
  @ViewChild('feedbackModal') public feedbackModal: ModalDirective;
  @ViewChild('tutorialModal') public tutorialModal: ModalDirective;
  @ViewChild('reviewComponent') public reviewComponent: ReviewBaseComponent;

  requirePaymentBefore: 'check-in' | 'check-out' = 'check-out';

  shop: BookableStorageLocation;
  isFullOpeningHoursVisible = false;
  errorOccurred = false;
  errorMessage = '';

  storageTimerDays = '--';
  storageTimerHours = '--';
  storageTimerMinutes = '--';
  storageTimerSeconds = '--';
  private timerSecondsOffset: number;
  private isCheckInSession = false;
  private isCtaClicked = false;
  private isGoogleReviewDone = false;

  private _paymentMethod: IPaymentRecord;
  private _paymentMethods: IPaymentRecord[] = [];
  private _isPaymentMethodChanged = false;
  private _review: Review;

  private paramsSubscription: Subscription;
  private _isLoading = false;
  private _booking: Booking;
  private strategy: IManageBookingStrategy;

  public constructor(
    private documentService: SharedDocumentService,
    private bookingService: SharedBookingService,
    private shopsService: SharedShopsService,
    private criteriaService: SharedStorageCriteriaService,
    private locationService: SharedLocationService,
    private paymentService: SharedPaymentService,
    private userService: SharedUserService,
    private gms: SharedGoogleMapsService,
    private tag: SharedPageTaggingService,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private notify: SharedNotificationService,
    private analytics: SharedAnalyticsService,
    private error: SharedErrorService,
    private windowService: SharedWindowService,
    private script: ScriptService,
    private log: SharedLoggingService,
    private cd: ChangeDetectorRef,
  ) {
    super();

    this.documentService.addBodyClass('manage-booking');
  }

  get ios(): boolean {
    return this.windowService.iOS;
  }

  get showFullOpeningHours(): boolean {
    if (!this.shop) {
      return false;
    }
    return this.isFullOpeningHoursVisible;
  }

  get showSimpleOpeningHours(): boolean {
    if (!this.shop || !this.booking || !this.criteria) {
      return false;
    }
    return !this.isFullOpeningHoursVisible;
  }

  get criteria(): StorageCriteria {
    return this.criteriaService.currentOrDefault;
  }

  get booking(): Booking {
    return this._booking;
  }
  set booking(value: Booking) {
    this._booking = value;
    this.cd.markForCheck();
  }

  get isLoading(): boolean {
    return this._isLoading;
  }
  set isLoading(value: boolean) {
    this._isLoading = value;
    this.cd.markForCheck();
  }

  get paymentMethod(): IPaymentRecord {
    return this._paymentMethod;
  }
  set paymentMethod(value: IPaymentRecord) {
    this._paymentMethod = value;
    void this.updatePaymentMethod();
  }

  get paymentMethods(): IPaymentRecord[] {
    return this._paymentMethods;
  }
  set paymentMethods(value: IPaymentRecord[]) {
    this._paymentMethods = value;
    if (!this.booking || !this.booking.paymentMethodId) {
      return;
    }
    for (let i = 0; i < this.paymentMethods.length; i++) {
      if (this.paymentMethods[i].id === this.booking.paymentMethodId) {
        this.paymentMethod = this.paymentMethods[i];
        break;
      }
    }
  }

  get isPaymentMethodChanged(): boolean {
    return this._isPaymentMethodChanged;
  }
  set isPaymentMethodChanged(value: boolean) {
    this._isPaymentMethodChanged = value;
    this.cd.markForCheck();
  }

  get review(): Review {
    return this._review;
  }
  set review(value: Review) {
    this._review = value;
    this.cd.markForCheck();
  }

  get imageSize(): string {
    const windowWidth = this.windowService.innerWidth;
    return windowWidth > 460 && windowWidth < 769 ? 'large' : 'medium';
  }

  get imageUrl(): string {
    if (!this.shop || !this.shop.images) {
      return '';
    }
    return `${Config.environment.IMAGES_BASE_URL}/images/${this.imageSize}/${this.shop.images[0]}`;
  }

  get activeSection(): string {
    return this.strategy.activeSection;
  }

  get activeHint(): string {
    switch (this.activeSection) {
      case 'paymentMethod':
        return 'HINT_PAYMENT_METHOD';

      case 'checkIn':
        if (this.booking && !this.booking.price.discountCode) {
          return 'HINT_CHECK_IN_NO_DISCOUNT_CODE';
        }
        return 'HINT_CHECK_IN';

      case 'checkOut':
        return 'HINT_CHECK_OUT';

      case 'feedback':
        return 'HINT_FEEDBACK';
    }
    return '';
  }

  get isBookingCheckedOut(): boolean {
    if (!this.booking) {
      return false;
    }
    return ['CHECKED_OUT', 'PAID'].includes(this.booking.status);
  }

  get luggageSummary(): string {
    const luggageCount = this.booking.luggage.hand + this.booking.luggage.normal;
    const suffix = (this.translate.instant(luggageCount > 1 ? 'BAGS' : 'BAG') as string).toLowerCase();
    return `${luggageCount} ${suffix}`;
  }

  get timerFrom(): string {
    if (['CONFIRMED', 'CANCELLED'].includes(this.booking.status)) {
      return zeroDate;
    }
    return this.formatTimerDate(this.booking.period.checkIn);
  }

  get timerTo(): string {
    switch (this.booking.status) {
      case 'CHECKED_IN':
        return this.formatTimerDate(this.now, true);

      case 'CHECKED_OUT':
      case 'PAID':
        return this.formatTimerDate(this.booking.period.checkOut);

      case 'CONFIRMED':
      case 'CANCELLED':
      // Fall through to default

      default:
        return zeroDate;
    }
  }

  get now(): Date {
    const now = new Date();
    if (!this.timerSecondsOffset) {
      this.timerSecondsOffset = this.isCheckInSession ? now.getSeconds() : 0;
    }
    return now;
  }

  get insuranceCoverage(): number {
    return this.shop.pricing.insuranceCoverage;
  }

  get showGoogleReview(): boolean {
    return !this.isGoogleReviewDone && this.strategy.showGoogleReview;
  }

  get isDirectPaymentSelected(): boolean {
    if (!this.paymentMethod) {
      return false;
    }
    return this.paymentMethod.provider === 'direct_payment';
  }

  ngOnInit() {
    // This is to make sure the shop criteria are available
    void this.locationService.init().then(() => this.cd.markForCheck());
    this.paramsSubscription = this.route.params.subscribe(
      (params: { id: string; feedback: unknown; linkToPlaces: string }) => {
        if (params.id) {
          void this.loadBooking(params.id, params.feedback);
          if (params.linkToPlaces) {
            this.gms.linkToPlaces = params.linkToPlaces === 'true';
          }
        } else {
          this.errorOccurred = true;
          this.cd.markForCheck();
        }
      },
    );
    this.tag.disableCrawling();
  }

  ngOnDestroy() {
    try {
      this.paramsSubscription.unsubscribe();
    } catch {
      // Ignore
    }
    this.documentService.removeBodyClass('manage-booking');
    this.tag.clearTags();
  }

  showFeedback() {
    this.analytics.track('InitiateFeedback', {
      category: this.booking.address.countryCode,
      label: this.booking.shopName,
      user: this.userService.user,
    });
    this.feedbackModal.show();
  }

  showCheckOut() {
    if (this.requirePaymentBefore === 'check-out' && !this.booking.paymentMethodId) {
      this.notify.info(this.translate.instant('PAYMENT_METHOD_REQUIRED') as string);
      this.showPaymentMethod(true);
    } else {
      this.analytics.track('InitiateCheckOut', {
        category: this.booking.address.countryCode,
        label: this.booking.shopName,
        user: this.userService.user,
      });
      this.checkOutModal.show();
    }
  }

  showCheckIn() {
    if (this.requirePaymentBefore === 'check-in' && !this.booking.paymentMethodId) {
      this.notify.info(this.translate.instant('PAYMENT_METHOD_REQUIRED') as string);
      this.showPaymentMethod(true);
    } else {
      this.analytics.track('InitiateCheckIn', {
        category: this.booking.address.countryCode,
        label: this.booking.shopName,
        user: this.userService.user,
      });
      this.checkInModal.show();
    }
  }

  showPaymentMethod(ctaClicked = false) {
    this.isCtaClicked = ctaClicked;

    this.analytics.track('InitiatePaymentMethod', {
      category: this.booking.address.countryCode,
      label: this.booking.shopName,
      user: this.userService.user,
    });
    this.paymentMethodModal.show();
  }

  showReservation() {
    this.analytics.track('ShowReservationInfo', {
      category: this.booking.address.countryCode,
      label: this.booking.shopName,
      user: this.userService.user,
    });
    this.reservationModal.show();
  }

  async updatePaymentMethod(): Promise<void> {
    if (this.paymentMethodModal) {
      // Commented to let the user close the modal manually
      // this.paymentMethodModal.hide();
    }

    if (this.paymentMethod.id === this.booking.paymentMethodId) {
      // No change in payment method
      return;
    }

    this.isLoading = true;

    try {
      this.analytics.track('SetPaymentMethod', {
        category: this.booking.address.countryCode,
        label: this.booking.shopName,
        user: this.userService.user,
      });

      this.booking = await this.bookingService.setPaymentMethod(this.booking._id, this.paymentMethod.id);
      void this.log.debug(`Payment method successfully set on booking`);

      this.strategy.onPaymentMethodUpdated();

      this.isPaymentMethodChanged = true;
      this.errorOccurred = false;
      this.errorMessage = '';
      this.isLoading = false;

      if (this.isCtaClicked) {
        this.isCtaClicked = false;

        switch (this.requirePaymentBefore) {
          case 'check-in':
            this.showCheckIn();
            break;

          case 'check-out':
            this.showCheckOut();
            break;
        }
      }
    } catch (err) {
      // TODO: Do not show internal error messages to user
      this.errorOccurred = true;
      this.errorMessage = err as string;
      this.isLoading = false;
    }
  }

  async checkIn() {
    this.isLoading = true;

    try {
      this.analytics.track('ConfirmCheckIn', {
        category: this.booking.address.countryCode,
        label: this.booking.shopName,
        user: this.userService.user,
      });

      const res = await this.bookingService.checkIn(this.booking._id);

      this.isCheckInSession = true;
      this.booking = res;

      this.checkInModal.hide();
    } catch (err) {
      // TODO: Notify user
      this.error.handleError(err);
    }

    this.isLoading = false;
  }

  setGoogleReviewDone() {
    this.isGoogleReviewDone = true;
    this.cd.markForCheck();
  }

  toggleFullOpeningHours() {
    this.isFullOpeningHoursVisible = !this.isFullOpeningHoursVisible;
    this.cd.markForCheck();
  }

  isNotSameDay(date1: Date, date2: Date) {
    if (date1 && date2) {
      return date1.toDateString() !== date2.toDateString();
    }
    return false;
  }

  getOpeningHoursFromDate(date: Date, shop: BookableStorageLocation): ITimeInterval[] {
    return this.shopsService.getOpeningHoursForDate(date, shop);
  }

  onTwitterTweet() {
    this.analytics.track('TwitterTweet', {});
  }

  onTwitterFollow() {
    this.analytics.track('TwitterFollow', {});
  }

  onFacebookShare() {
    this.analytics.track('FacebookShare', {});
  }

  onFacebookLike() {
    this.analytics.track('FacebookLike', {});
  }

  async checkOut() {
    this.isLoading = true;
    try {
      const res = await this.bookingService.checkOut(this.booking._id);
      this.booking = res;
      this.checkOutModal.hide();
      this.analytics.track('CompleteCheckOut', {
        category: this.booking.address.countryCode,
        label: this.booking.shopName,
        value: this.booking.price.final.total,
        currency: this.booking.price.final.currency.toUpperCase(),
        user: this.userService.user,
      });
      if (this.booking.status !== 'PAID') {
        this.notify.warning('Payment failed');
      }
      this.isPaymentMethodChanged = false;
    } catch (err) {
      this.isLoading = false;
      // TODO: Notify user
      this.error.handleError(err);
    }
  }

  private async loadBooking(id: string, feedback: unknown) {
    this.isLoading = true;

    try {
      const booking = await this.bookingService.getBooking(id);

      this.booking = booking;
      this.analytics.identify({ userId: this.booking.userId });

      // Initialize booking strategy
      this.strategy = BookingStrategyFactory.createLegacyStrategy(this, this.userService);

      // Force modals with *ngIf="booking" to be created
      this.cd.detectChanges();

      this.shop = await this.shopsService.getShopDetails(
        this.booking.shopId,
        DateUtil.serializeDate(this.booking.period.from),
        DateUtil.serializeDate(this.booking.period.to),
        this.booking.luggage.normal,
        this.booking.luggage.hand,
        this.booking.isGuest || this.booking.isWalkIn ? 'dropoff' : 'marketplace',
      );

      this.paymentMethods = await this.paymentService.getPaymentMethods(id);
      this.isLoading = false;
      this.runStorageTimer();
      this.strategy.onBookingLoaded(feedback);
    } catch (err) {
      // TODO: Do not show internal error messages to user
      this.errorOccurred = true;
      this.errorMessage = err as string;
      this.isLoading = false;
    }
  }

  private runStorageTimer() {
    setTimeout(() => {
      this.updateStorageTime();
      this.runStorageTimer();
    }, 1000);
  }

  private updateStorageTime() {
    let fromMoment = moment(this.timerFrom);
    const toMoment = moment(this.timerTo);

    this.storageTimerDays = toMoment.diff(fromMoment, 'days').toString();
    fromMoment = fromMoment.add(this.storageTimerDays, 'days');

    this.storageTimerHours = toMoment.diff(fromMoment, 'hours').toString();
    fromMoment = fromMoment.add(this.storageTimerHours, 'hours');

    this.storageTimerMinutes = toMoment.diff(fromMoment, 'minutes').toString();
    fromMoment = fromMoment.add(this.storageTimerMinutes, 'minutes');

    this.storageTimerSeconds = toMoment.diff(fromMoment, 'seconds').toString();

    this.cd.markForCheck();
  }

  private formatTimerDate(value: Date, isLocalTime = false): string {
    let m = moment(value);
    if (isLocalTime) {
      m = m.subtract(this.timerSecondsOffset, 'seconds');
      m = m.tz(this.booking.timezone);
    }
    return m.format(dateFormat);
  }
}
