import { APP_BASE_HREF, CommonModule } from '@angular/common';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy, RouterModule } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
// import { ShopsLoaderModule } from './app/components/shops/shops.module';
// we are including the user module here as the user Modal breaks things currently
import { IonicModule } from '@ionic/angular';
import { provideIonicAngular } from '@ionic/angular/standalone';
import { AnalyticsModule } from '@luggagehero/analytics/analytics.module';
import { LanguageProviders, Languages } from '@luggagehero/i18n';
import { USERS_IP_LOCATION_SERVICE_ENDPOINT_TOKEN } from '@luggagehero/services';
import { APP_SETTINGS_SERVICE_ENDPOINT_TOKEN, AppConfig } from '@luggagehero/shared/app-settings/data-access';
import { Config } from '@luggagehero/shared/environment';
// import { ShopsLoaderModule } from './app/components/shops/shops.module';
// we are including the user module here as the user Modal breaks things currently
import {
  BOOKING_TRANSFORMATION_SERVICE,
  DISCOUNT_TRANSFORMER_SERVICE,
  MOMENT_SERVICE,
  PAYMENT_METHOD_TRANSFORMER_SERVICE,
  PRICING_SERVICE,
  TIME_INTERVAL_TRANSFORMER_SERVICE,
  TRANSLATE_SERVICE,
} from '@luggagehero/shared/interfaces';
import { SHARED_APP_EVENT_SERVICE_CONFIG_TOKEN } from '@luggagehero/shared/services/app-events';
import { SharedBookingTransformationService } from '@luggagehero/shared/services/booking-transformation';
import { SharedDiscountTransformerService } from '@luggagehero/shared/services/discount-transformer';
import { SHARED_GEOCODING_SERVICE_CONFIG_TOKEN } from '@luggagehero/shared/services/geocoding';
import { HOLIDAY_SERVICE_ENDPOINT_TOKEN } from '@luggagehero/shared/services/holiday';
// import { ShopsLoaderModule } from './app/components/shops/shops.module';
// we are including the user module here as the user Modal breaks things currently
import { SHARED_HTTP_SERVICE_CONFIG_TOKEN } from '@luggagehero/shared/services/http';
import { LOCATIONS_SERVICE_ENDPOINT_TOKEN } from '@luggagehero/shared/services/locations';
import { SharedMomentService } from '@luggagehero/shared/services/moment';
import { ORDER_SERVICE_ENDPOINT_TOKEN } from '@luggagehero/shared/services/orders';
import { SharedPaymentMethodTransformerService } from '@luggagehero/shared/services/payment-method-transformer';
import { DISCOUNT_SERVICE_ENDPOINT_TOKEN, PAYMENT_SERVICE_ENDPOINT_TOKEN } from '@luggagehero/shared/services/payments';
import { SharedPricingService } from '@luggagehero/shared/services/pricing';
import { STORAGE_LOCATIONS_SERVICE_ENDPOINT_TOKEN } from '@luggagehero/shared/services/shops';
import { SHARED_STORAGE_SERVICE_CONFIG } from '@luggagehero/shared/services/storage';
import { SharedTimeIntervalTransformerService } from '@luggagehero/shared/services/time-interval-transformer';
import { SharedTranslateService, TRANSLATE_CONFIG, TranslateConfig } from '@luggagehero/shared/services/translation';
import { LhCoreModule } from '@luggagehero/web/core';
import { UiModule } from '@luggagehero/web/features/ui';

import { AppComponent } from './app/components/app.component';
import { AppInitializer } from './app/components/app.initializer';
import { AppRouteReuseStrategy } from './app/components/app.route-reuse-strategy';
import { routes } from './app/components/app.routes';
import { UserLoaderModule } from './app/components/user/user.module';

// DISABLE lazy loading --- begin
// import { ShopsLoaderModule } from './app/components/shops/shops.module';                // Comment to enable lazy loading
// import { BookingsLoaderModule } from './app/components/bookings/bookings.module';             // Comment to enable lazy loading
// import { ShopManagerLoaderModule } from './app/components/shop-manager/shop-manager.module';  // Comment to enable lazy loading
// const featureModules = [ShopsLoaderModule, BookingsLoaderModule, ShopManagerLoaderModule];    // Comment to enable lazy loading
// DISABLE lazy loading --- end

// ENABLE lazy loading --- begin
const featureModules = []; // Comment to disable lazy loading
// ENABLE lazy loading --- end

const routerModule = RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' });

// Factory for supported languages
export function getSupportedLanguages() {
  return Config.supportedLanguages;
}

// Factory for shared storage config
export function getStorageServiceConfig() {
  return {
    domain: Config.isDevelopment ? 'localhost' : 'luggagehero.com',
  };
}

@NgModule({
  declarations: [AppComponent],
  exports: [
    // UiModule
  ],
  bootstrap: [AppComponent],
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  imports: [
    LhCoreModule,
    IonicModule.forRoot(),
    BrowserModule.withServerTransition({ appId: 'luggagehero' }),
    BrowserAnimationsModule,
    CommonModule,
    routerModule,
    AnalyticsModule,
    UiModule,
    UserLoaderModule, // Remove this? (lazy load instead)
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    ...featureModules,
    ServiceWorkerModule.register('/ngsw-worker.js', { enabled: Config.isProduction }),
  ],
  providers: [
    provideIonicAngular(),
    {
      provide: APP_BASE_HREF,
      useValue: '',
    },
    // default language providers
    LanguageProviders,
    // override with supported languages
    {
      provide: Languages,
      useFactory: getSupportedLanguages,
      deps: [],
    },
    {
      provide: RouteReuseStrategy,
      useClass: AppRouteReuseStrategy,
    },
    // Provide shared storage config dynamically based on the environment
    {
      provide: SHARED_STORAGE_SERVICE_CONFIG,
      useFactory: getStorageServiceConfig,
      deps: [],
    },
    {
      provide: SHARED_HTTP_SERVICE_CONFIG_TOKEN,
      useFactory: () => {
        return {
          usesLegacyHttpMode: () => {
            return AppConfig.LEGACY_HTTP_MODE;
          },
        };
      },
    },
    {
      provide: SHARED_GEOCODING_SERVICE_CONFIG_TOKEN,
      useFactory: () => {
        return {
          apiURL: () => Config.environment.TRAVELER_API,
        };
      },
    },
    {
      provide: SHARED_APP_EVENT_SERVICE_CONFIG_TOKEN,
      useFactory: () => {
        return {
          apiURL: () => `${Config.environment.TRAVELER_API}/v2`,
        };
      },
    },
    {
      provide: HOLIDAY_SERVICE_ENDPOINT_TOKEN,
      useValue: Config.environment.TRAVELER_API,
    },
    {
      provide: APP_SETTINGS_SERVICE_ENDPOINT_TOKEN,
      useValue: Config.environment.TRAVELER_API,
    },
    {
      provide: LOCATIONS_SERVICE_ENDPOINT_TOKEN,
      useValue: Config.environment.TRAVELER_API,
    },
    {
      provide: STORAGE_LOCATIONS_SERVICE_ENDPOINT_TOKEN,
      useValue: `${Config.environment.TRAVELER_API}/v2/storage_locations`,
    },
    {
      provide: DISCOUNT_SERVICE_ENDPOINT_TOKEN,
      useValue: `${Config.environment.TRAVELER_API}/discounts`,
    },
    {
      provide: PAYMENT_SERVICE_ENDPOINT_TOKEN,
      useValue: `${Config.environment.TRAVELER_API}/payments`,
    },
    {
      provide: ORDER_SERVICE_ENDPOINT_TOKEN,
      useValue: `${Config.environment.TRAVELER_API}/orders`,
    },
    {
      provide: USERS_IP_LOCATION_SERVICE_ENDPOINT_TOKEN,
      useValue: Config.environment.TRAVELER_API,
    },
    {
      provide: TRANSLATE_CONFIG,
      useFactory: () => {
        return {
          appName: 'web-traveler',
          apiAddress: Config.environment.TRAVELER_API,
        } as TranslateConfig;
      },
    },
    SharedTranslateService,
    { provide: TRANSLATE_SERVICE, useExisting: SharedTranslateService },
    { provide: PRICING_SERVICE, useExisting: SharedPricingService },
    { provide: MOMENT_SERVICE, useExisting: SharedMomentService },
    { provide: BOOKING_TRANSFORMATION_SERVICE, useExisting: SharedBookingTransformationService },
    { provide: DISCOUNT_TRANSFORMER_SERVICE, useExisting: SharedDiscountTransformerService },
    { provide: TIME_INTERVAL_TRANSFORMER_SERVICE, useExisting: SharedTimeIntervalTransformerService },
    { provide: PAYMENT_METHOD_TRANSFORMER_SERVICE, useExisting: SharedPaymentMethodTransformerService },
    provideHttpClient(withInterceptorsFromDi()),
    {
      provide: APP_INITIALIZER,
      useFactory: (appInitializer: AppInitializer) => {
        return () => appInitializer.init();
      },
      deps: [AppInitializer],
      multi: true,
    },
  ],
})
export class WebModule {}
