import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NumberCardBaseComponent } from '@luggagehero/features/ui';

@Component({
  selector: 'lh-number-card',
  templateUrl: './number-card.component.html',
  styleUrls: ['./number-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NumberCardComponent extends NumberCardBaseComponent {}
