import { Inject, Injectable } from '@angular/core';
import {
  ITimeInterval,
  ITranslateService,
  TimeIntervalTransformerService,
  TRANSLATE_SERVICE,
} from '@luggagehero/shared/interfaces';
import { SharedUtilDate } from '@luggagehero/shared/util';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SharedTimeIntervalTransformerService implements TimeIntervalTransformerService {
  private text = {
    CLOSED: '',
    HOURS: '',
    OPEN_24_HOURS: '',
    CLOSES: '',
    OPENS: '',
    OPEN: '',
  };

  constructor(@Inject(TRANSLATE_SERVICE) private translate: ITranslateService) {}

  transform(
    value: ITimeInterval[],
    short = true,
    timeOnly = false,
    ...components: ('from' | 'to')[]
  ): Observable<string> {
    return this.translate.get(this.text).pipe(
      map((translatedText) => {
        this.text = translatedText;

        if (!value || value.length === 0) {
          return this.text.CLOSED;
        }

        let count = 0;
        return value.map((t) => this.transformOne(t, short, timeOnly, count++, ...components)).join(', ');
      }),
    );
  }

  private transformOne(
    value: ITimeInterval,
    short: boolean,
    timeOnly = false,
    index: number,
    ...components: ('from' | 'to')[]
  ): string {
    if (!components || components.length === 0) {
      components = ['from', 'to'];
    }

    const includeFrom = components.includes('from');
    const includeTo = components.includes('to');

    const fromNum = Math.floor(value.from / 60);
    const toNum = Math.floor(value.to / 60);

    if (fromNum === 0 && toNum === 24) {
      return short ? `24 ${this.text.HOURS}` : this.text.OPEN_24_HOURS;
    }

    const fromHour = SharedUtilDate.formatTimeComponent(fromNum, true);
    const fromMinute = SharedUtilDate.formatTimeComponent(value.from % 60, false);
    const fromTime = SharedUtilDate.formatTime(fromHour, fromMinute, short);

    const toHour = SharedUtilDate.formatTimeComponent(toNum, true);
    const toMinute = SharedUtilDate.formatTimeComponent(value.to % 60, false);
    const toTime = SharedUtilDate.formatTime(toHour, toMinute, short);

    const nextDay = toNum > 24 ? '(+1)' : '';

    if (!includeFrom) {
      const closes = timeOnly ? '' : this.text.CLOSES;
      return `${closes} ${toTime} ${nextDay}`.trim();
    }

    if (!includeTo) {
      const opens = timeOnly ? '' : this.text.OPENS;
      return `${opens} ${fromTime}`.trim();
    }

    const open = short || index > 0 || !includeFrom ? '' : this.text.OPEN;
    return `${open} ${fromTime}–${toTime} ${nextDay}`.trim();
  }
}
