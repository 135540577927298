import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'lh-shared-ui-spinner',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: './shared-ui-spinner.component.html',
  styleUrl: './shared-ui-spinner.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SharedUiSpinnerComponent {
  @Input() visible = false;
  @Input() darkMode = false;
}
