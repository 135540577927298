<div class="row small mb-4" *ngIf="isDebugging">
  <div class="col-3"><b>localTime</b></div>
  <div class="col-9">{{ (localTime | async) || 'n/a' }}</div>

  <!-- <div class="col-3"><b>showFullHours</b></div>
  <div class="col-9">{{ showFullHours }}</div> -->

  <div class="col-3"><b>nextOpenDay</b></div>
  <div class="col-9">{{ nextOpenDay | lhMoment: '' }} ({{ nextOpenDay | lhMoment: 'calendar' }})</div>

  <div class="col-3"><b>arrivingAt</b></div>
  <div class="col-9">{{ arrivingAt | lhMoment: '' }} ({{ arrivingAt | lhMoment: 'calendar' }})</div>

  <div class="col-3"><b>isOpenOnArrival</b></div>
  <div class="col-9">{{ isOpenOnArrival }}</div>

  <div class="col-3"><b>isFullyBooked</b></div>
  <div class="col-9">{{ isFullyBooked }}</div>

  <div class="col-3"><b>opensAt</b></div>
  <div class="col-9">{{ opensAt | async }}</div>

  <div class="col-3"><b>closesAt</b></div>
  <div class="col-9">{{ closesAt | async }}</div>

  <div class="col-3"><b>openingHours</b></div>
  <div class="col-9">{{ openingHours | async }}</div>
</div>

<!-- Opening hours -->
<div
  class="opening-hours"
  [attr.data-is-open]="isOpen || isOpenAllDay ? true : false"
  [attr.data-is-opening-soon]="isOpeningSoon"
  [attr.data-is-closing-soon]="isClosingSoon"
  *ngIf="!isFullyBooked && (displayHours | async)"
>
  <span class="red-green"></span>
  <span class="open-closed">{{ openClosedText | translate }}</span>
  <span *ngIf="isClosed || (isArrivingToday && !isOpenAllDay)">·</span>
  <span class="hours">{{ displayHours | async }}</span>
</div>

<!-- Fully booked -->
<div class="opening-hours" [attr.data-is-open]="false" *ngIf="isFullyBooked">
  <span class="red-green"></span>
  <span class="open-closed">{{ 'FULLY_BOOKED' | translate }}</span>
</div>

<!-- TODO: Translate this -->
<!-- <div class="text-warning small" *ngIf="isClosingSoon">{{ 'Closing soon' | translate }}</div>
<div class="text-warning small" *ngIf="isOpeningSoon">{{ 'Opening soon' | translate }}</div> -->
