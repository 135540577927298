<div *ngIf="!isThemeReady">
  <!-- TODO: Implement navbar placeholder while theme is loading -->
</div>

<nav class="navbar navbar-expand-md" [class.navbar-light]="isThemeHeaderLight" *ngIf="isThemeReady">
  <div class="container-fluid">
    <!-- Brand and toggle get grouped for better mobile display -->
    <div class="navbar-brand">
      <div class="logos">
        <!-- LuggageHero logo -->
        <a id="goHome" class="href" [href]="homeUrl" (click)="onNavbarBrandClick($event)" *ngIf="isThemeReady">
          <lh-platform-logo
            [iconOnly]="isPlatformLogoCollapsed"
            [lightBackground]="isThemeHeaderLight"
          ></lh-platform-logo>
        </a>

        <div class="theme-logo" *ngIf="themeLogo">
          <a [href]="themeLink" target="_blank" *ngIf="themeLink">
            <img [src]="themeLogo | safeUrl" />
          </a>
          <img [src]="themeLogo | safeUrl" *ngIf="!themeLink" />
        </div>
      </div>
    </div>

    <div class="ms-auto d-flex align-items-center gap-2 navbar-toolbar">
      <div *ngIf="!isDirectDropoff" class="d-block d-md-none">
        <a id="showHowItWorks" class="href text-white" (click)="showHowItWorks()">{{ 'HOW_IT_WORKS' | translate }}</a>
      </div>
      <div *ngIf="isDirectDropoff" class="d-block d-md-none">
        <a id="showSupportChat" class="href text-white" (click)="showChat()">{{
          'CUSTOMER_SUPPORT_SHORT' | translate
        }}</a>
      </div>

      <button
        *ngIf="isLoggedIn"
        class="navbar-toggler"
        type="button"
        data-bs-target="#navbar"
        aria-controls="navbar"
        aria-expanded="false"
        aria-label="Toggle navigation"
        (click)="isCollapsed = !isCollapsed"
      >
        <lh-user-avatar [lightBackground]="isThemeHeaderLight"></lh-user-avatar>
      </button>

      <button
        *ngIf="!isLoggedIn"
        class="navbar-toggler"
        type="button"
        data-bs-target="#navbar"
        aria-controls="navbar"
        aria-expanded="false"
        aria-label="Toggle navigation"
        (click)="logIn()"
      >
        <i class="icon-lock mobile"></i>
      </button>
    </div>

    <div class="navbar-collapse" id="navbar" [class.collapse]="isCollapsed">
      <ul class="navbar-nav my-2 d-block d-md-none">
        <!-- 'Signed in as' header -->
        <div *ngIf="isLoggedIn && isUserAvailable" class="form-group text-center mb-2" routerLink="user/account">
          <div class="user-dropdown-sm-subtitle">{{ 'SIGNED_IN_AS' | translate }}</div>
          <div class="user-dropdown-sm-title">{{ userIdentifier }}</div>
        </div>

        <!-- 'My bookings' item -->
        <li class="d-flex align-items-center">
          <a id="goToMyBookings" routerLink="bookings/user/list" (click)="isCollapsed = true" class="href">
            {{ 'MY_BOOKINGS' | translate }} <span *ngIf="notificationCount > 0">({{ notificationCount }})</span>
          </a>
        </li>

        <!-- 'Account' item -->
        <li class="d-flex align-items-center">
          <a id="goToAccount" routerLink="user/account" (click)="isCollapsed = true" class="href">
            {{ 'ACCOUNT' | translate }}
          </a>
        </li>

        <!-- 'Shop manager' item -->
        <li *ngIf="isShopOwner" class="d-flex align-items-center">
          <a id="goToShopManager" routerLink="shop-manager" (click)="isCollapsed = true" class="href">
            {{ (isShopOwner ? 'SHOP_MANAGER' : 'BECOME_A_HERO') | translate }}
          </a>
        </li>

        <!-- 'Log out' item -->
        <li class="d-flex align-items-center">
          <a id="logOut" (click)="logOut()" class="href">{{ 'LOG_OUT' | translate }}</a>
        </li>
      </ul>

      <!-- Navbar for large screens only -->
      <ul class="navbar-nav ms-auto d-none d-md-flex align-items-stretch min-h-inherit">
        <li *ngIf="!isDirectDropoff" class="d-flex align-items-center">
          <a id="showHowItWorks" class="href" (click)="showHowItWorks()">{{ 'HOW_IT_WORKS' | translate }}</a>
        </li>

        <li class="d-flex align-items-center">
          <a id="showSupportChat" class="href" (click)="showChat()" *ngIf="isDirectDropoff">
            {{ 'CUSTOMER_SUPPORT_SHORT' | translate }}
          </a>
        </li>

        <li *ngIf="!isLoggedIn" class="d-flex align-items-center">
          <a id="logIn" (click)="logIn()" class="href"><i class="icon-lock"></i>{{ 'SIGN_IN' | translate }}</a>
        </li>

        <!-- 'My bookings' item -->
        <li *ngIf="isLoggedIn" class="d-flex align-items-center">
          <a class="dropdown" id="userDropdown" data-bs-toggle="dropdown" aria-expanded="false">
            <lh-user-avatar [lightBackground]="isThemeHeaderLight"></lh-user-avatar>
          </a>

          <ul class="dropdown-menu dropdown-menu-end py-1" aria-labelledby="userDropdown">
            <li *ngIf="isLoggedIn && isUserAvailable" class="form-group text-center mb-2">
              <div class="user-dropdown-lg-subtitle">{{ 'SIGNED_IN_AS' | translate }}</div>
              <div class="user-dropdown-lg-title">{{ userIdentifier }}</div>
            </li>

            <li>
              <a id="goToMyBookings" routerLink="bookings/user/list" class="dropdown-item">
                {{ 'MY_BOOKINGS' | translate }} <span *ngIf="notificationCount > 0">({{ notificationCount }})</span>
              </a>
            </li>

            <li>
              <a id="goToAccount" routerLink="user/account" class="dropdown-item">
                {{ 'ACCOUNT' | translate }}
              </a>
            </li>

            <li *ngIf="isShopOwner">
              <a id="goToShopManager" routerLink="shop-manager" class="dropdown-item">
                {{ (isShopOwner ? 'SHOP_MANAGER' : 'BECOME_A_HERO') | translate }}
              </a>
            </li>

            <li>
              <a id="logOut" (click)="logOut()" class="dropdown-item">{{ 'LOG_OUT' | translate }}</a>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
  <!-- /.container-fluid -->
</nav>
