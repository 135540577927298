import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  forwardRef,
  OnInit,
  Provider,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ModalService } from '@luggagehero/core';
import { TimePeriodPickerBaseComponent } from '@luggagehero/features/ui';
import { IconService } from '@luggagehero/services';
import { SharedLoggingService } from '@luggagehero/shared/services/logging';
import { SharedStorageCriteriaService } from '@luggagehero/shared/services/storage-criteria';

import { DateTimePickerComponent } from '../date-time-picker/date-time-picker.component';

export const TIME_PERIOD_PICKER_CONTROL_VALUE_ACCESSOR: Provider = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => TimePeriodPickerComponent),
  multi: true,
};

@Component({
  selector: 'lh-time-period-picker',
  templateUrl: './time-period-picker.component.html',
  styleUrls: ['./time-period-picker.component.scss'],
  providers: [TIME_PERIOD_PICKER_CONTROL_VALUE_ACCESSOR],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimePeriodPickerComponent extends TimePeriodPickerBaseComponent implements OnInit, ControlValueAccessor {
  @ViewChild('fromPicker') fromDateTimePicker: DateTimePickerComponent;
  @ViewChild('toPicker') toDateTimePicker: DateTimePickerComponent;

  constructor(
    modalService: ModalService,
    criteria: SharedStorageCriteriaService,
    icon: IconService,
    log: SharedLoggingService,
    cd: ChangeDetectorRef,
  ) {
    super(modalService, criteria, icon, log, cd);
  }
}
