<nav class="tab-bar-container" *ngIf="isTabBarVisible">
  <div class="tabs">
    <button
      id="showBrowseStorageTab"
      type="button"
      class="btn btn-tab"
      [class.active]="isBrowseStorageTabActive"
      routerLink="/home"
    >
      <i class="icon-browse"></i>
      <div>{{ 'TAB_BAR_BROWSE' | translate }}</div>
    </button>
    <button
      id="showNearbyStorageTab"
      type="button"
      class="btn btn-tab"
      [class.active]="isNearbyStorageTabActive"
      routerLink="/home/find-storage"
    >
      <i class="icon-nearby"></i>
      <div>{{ 'TAB_BAR_NEARBY' | translate }}</div>
    </button>
    <button
      *ngIf="isLoggedIn"
      id="showMyBookingsTab"
      type="button"
      class="btn btn-tab"
      [class.active]="isBookingsTabActive"
      routerLink="/bookings/user/list"
    >
      <i class="icon-bookings"></i>
      <div>{{ 'TAB_BAR_BOOKINGS' | translate }}</div>
    </button>
    <button
      id="showChatTab"
      type="button"
      class="btn btn-tab intercom-trigger"
      [class.active]="false"
      (click)="showChat()"
    >
      <i class="icon-chat"></i>
      <div>{{ 'TAB_BAR_CHAT' | translate }}</div>
    </button>
    <!-- <button id="showMenuTab" type="button" class="btn btn-tab" [class.active]="false">
      <i class="icon-menu"></i>
      <div>{{ 'TAB_BAR_MENU' | translate }}</div>
    </button> -->
  </div>
</nav>
