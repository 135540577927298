<div class="sli-banner" *ngIf="isBestChoice">{{ 'BEST_CHOICE' | translate }}</div>

<div
  class="sli-container text"
  [class.sli-container-lg]="size === 'large'"
  [class.success]="isRecommended"
  [class.top-banner]="isBestChoice"
>
  <div class="sli-pre-title" *ngIf="locationType">
    <!-- Location type (e.g. "Grocery Store", "Hotel" etc.) -->
    <div class="sli-location-type">
      <i class="sli-location-icon"></i>
      <span>{{ locationType | translate }}</span>
    </div>

    <!-- Rating (if location type is enabled this rating will be shown) -->
    <div class="sli-rating">
      <!-- HACK: Prevents the rating component from showing as focused -->
      <button class="hidden" autofocus></button>
      <rating [(ngModel)]="averageRating" [max]="1" [readonly]="true"></rating>
      <span *ngIf="!showAverageRating">{{ 'NEW' | translate }}</span>
      <span class="sli-avg-rating" *ngIf="showAverageRating">{{ averageRating | number: '1.1-1' }}</span>
      <span class="sli-no-reviews" *ngIf="showNumberOfReviews">({{ numberOfReviews }})</span>
    </div>
  </div>

  <!-- Title (e.g. "Luggage Storage Penn Station") -->
  <div class="sli-title">
    <div class="sli-title-text">{{ title }}</div>

    <!-- Rating (if location type is disabled this rating will be shown) -->
    <div class="sli-rating">
      <!-- HACK: Prevents the rating component from showing as focused -->
      <button class="hidden" autofocus></button>
      <rating [(ngModel)]="averageRating" [max]="1" [readonly]="true"></rating>
      <span *ngIf="!showAverageRating">{{ 'NEW' | translate }}</span>
      <span class="sli-avg-rating" *ngIf="showAverageRating">{{ averageRating | number: '1.1-1' }}</span>
      <span class="sli-no-reviews" *ngIf="showNumberOfReviews">({{ numberOfReviews }})</span>
    </div>
  </div>

  <div class="sli-post-title">
    <!-- Opening hours -->
    <lh-storage-location-availability
      [storageLocation]="storageLocation"
      [isOpenOnArrival]="isOpen"
      [isFullyBooked]="isFullyBooked"
      [openingHours]="openingHours"
      [nextOpenDay]="nextOpenDay"
      [opensAt]="opensAt"
      [closesAt]="closesAt"
    ></lh-storage-location-availability>

    <div class="sli-separator" *ngIf="isFullyBooked || openingHours">·</div>

    <!-- Location -->
    <div class="sli-location">{{ locationInfo }}</div>
  </div>

  <lh-storage-location-holiday-notice [html]="holidayNoticeHtml"></lh-storage-location-holiday-notice>

  <!-- No multi-day storage  -->
  <div class="sli-overnight" *ngIf="!isMultiDayStorageAllowed">
    <span class="sli-overnight-badge">{{ 'SHORT_TERM_ONLY' | translate }}</span>
    <span class="sli-overnight-text" *ngIf="!compactMode">{{ 'NO_OVERNIGHT_STORAGE' | translate }}</span>
  </div>

  <!-- Badges -->
  <lh-storage-location-badge-list [badges]="badges"></lh-storage-location-badge-list>

  <div class="sli-footer" *ngIf="isFooterVisible">
    <div class="sli-footer-info">
      <!-- Footnote -->
      <div class="sli-footnote" *ngIf="footnotePart1Html || footnotePart2Html">
        <span [innerHtml]="footnotePart1Html" *ngIf="footnotePart1Html"></span>
        <span *ngIf="footnotePart1Html && footnotePart2Html">/</span>
        <span [innerHtml]="footnotePart2Html" *ngIf="footnotePart2Html"></span>
        <lh-shared-ui-tooltip
          [callToAction]="'i'"
          [header]="footnoteTooltipHeaderHtml"
          [body]="footnoteTooltipBodyHtml"
          [compactMode]="true"
          *ngIf="footnoteTooltipHeaderHtml || footnoteTooltipBodyHtml"
        ></lh-shared-ui-tooltip>
      </div>

      <!-- Actions -->
      <div class="sli-cta">
        <!-- Dropdown with secondary calls to action -->
        <div class="dropdown" dropdown container="body" autoClose="outsideClick" *ngIf="secondaryActions?.length > 0">
          <div class="href" for="shopActionsMenu">
            <button
              id="toggleShopActionsDropdown"
              class="btn btn-default"
              (click)="$event.stopPropagation()"
              dropdownToggle
            >
              <span *ngIf="secondaryActionsMenuTitle">{{ secondaryActionsMenuTitle | translate }} </span>
              <span class="bi bi-caret-down-fill"></span>
              <!-- <i class="icon-options"></i> -->
            </button>
          </div>
          <ul id="shopActionsMenu" class="dropdown-menu" *dropdownMenu role="menu" aria-labelledby="toggle">
            <li *ngFor="let action of secondaryActions">
              <a [id]="'selectShopAction_' + action.callToAction" class="href" (click)="action.onClick($event)">{{
                action.callToAction | translate
              }}</a>
            </li>
          </ul>
        </div>

        <!-- Inline primary calls to action -->
        <button
          class="btn btn-primary"
          [class.btn-sm]="compactMode"
          (click)="action.onClick($event)"
          *ngFor="let action of primaryActions"
          id="cta-{{ index + 1 }}"
        >
          <span>{{ action.callToAction | translate }}</span>
        </button>
      </div>
    </div>
  </div>
</div>
