import { ChangeDetectorRef, Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { IDiscount, PRICING_SERVICE, PricingService } from '@luggagehero/shared/interfaces';
import { SharedStorageService } from '@luggagehero/shared/services/storage';

import { BaseComponent } from '../../../core';

export interface OrderLineInfo {
  orderLineIndex: number;
  orderLine: OrderLine;
}

export type OrderLineType =
  | 'storage'
  | 'insurance'
  | 'securitySeal'
  | 'freeCancellation'
  | 'campaignDiscount'
  | 'multiDayDiscount'
  | 'service';

export interface OrderLine {
  type: OrderLineType;
  description: string;
  detail?: string;
  tooltip?: {
    header?: string;
    body: string;
  };
  priceValue?: number;
  priceText?: string;
  quantity?: number;
  minQuantity?: number;
  maxQuantity?: number;
  optional?: boolean;
  selected?: boolean;
  discount?: boolean;
  onSelectedChange?: (value: boolean) => void;
  parentLine?: OrderLine;
}

@Component({ template: '' })
export abstract class OrderSummaryBaseComponent extends BaseComponent {
  protected pricingService = inject<PricingService>(PRICING_SERVICE);
  @Output() public requestDiscountModal: EventEmitter<unknown> = new EventEmitter();
  @Output() public orderChange = new EventEmitter<OrderLineInfo>();
  @Output() public bagsChange = new EventEmitter<number>();

  private _numberOfBags: number;
  private _bagsSelected = false;
  private _showBagPicker = false;
  private _headline: string;
  private _subtitle: string;
  private _orderLines: OrderLine[] = [];
  private _currency: string;
  private _totalLabel: string;
  private _discount: IDiscount;
  private _totalAlertText: string;

  constructor(
    private storage: SharedStorageService,
    private cd: ChangeDetectorRef,
  ) {
    super();
  }

  get showBagPicker(): boolean {
    return this._showBagPicker;
  }
  @Input() set showBagPicker(value: boolean) {
    this._showBagPicker = value;
    this.cd.markForCheck();
  }

  get headline(): string {
    return this._headline;
  }
  @Input() set headline(value: string) {
    this._headline = value;
    this.cd.markForCheck();
  }

  get subtitle(): string {
    return this._subtitle;
  }
  @Input() set subtitle(value: string) {
    this._subtitle = value;
    this.cd.markForCheck();
  }

  get orderLines(): OrderLine[] {
    return this._orderLines;
  }
  @Input() set orderLines(value: OrderLine[]) {
    this._orderLines = value;
    this.cd.markForCheck();
  }

  get currency(): string {
    return this._currency;
  }
  @Input() set currency(value: string) {
    this._currency = value;
    this.cd.markForCheck();
  }

  get totalLabel(): string {
    return this._totalLabel;
  }
  @Input() set totalLabel(value: string) {
    this._totalLabel = value;
    this.cd.markForCheck();
  }

  get discount(): IDiscount {
    return this._discount;
  }
  @Input() set discount(value: IDiscount) {
    this._discount = value;
    this.cd.markForCheck();
  }

  get totalAlertText(): string {
    return this._totalAlertText;
  }
  @Input() set totalAlertText(value: string) {
    this._totalAlertText = value;
    this.cd.markForCheck();
  }

  get orderTotal(): number {
    let total = 0;
    for (const line of this.orderLines) {
      if (!line.priceValue) {
        // Skip lines without a value
        continue;
      }

      if (line.optional && !line.selected) {
        // Skip optional lines that are not selected
        continue;
      }

      let quantity = 1;

      if (line.parentLine && typeof line.parentLine.quantity === 'number') {
        quantity = line.parentLine.quantity;
      } else if (typeof line.quantity === 'number') {
        quantity = line.quantity;
      } else if (typeof line.minQuantity === 'number') {
        quantity = line.minQuantity;
      }

      total += quantity * line.priceValue;
    }
    return total;
  }

  get numberOfBags(): number {
    return this._numberOfBags;
  }
  @Input() set numberOfBags(value: number) {
    this._numberOfBags = value;
    this.cd.markForCheck();

    this.bagsChange.emit(value);
  }

  @Input() get bagsSelected(): boolean {
    return this._bagsSelected;
  }
  set bagsSelected(value: boolean) {
    this._bagsSelected = value;
    this.cd.markForCheck();
  }

  formatPrice(price: number | string, qty: number): string {
    if (typeof price === 'string') {
      return price;
    }

    if (price > 0) {
      return this.pricingService.format(price * (qty || 1), this.currency, 2, false);
    }

    return 'FREE';
  }

  incrementQty(orderLineIndex: number) {
    const orderLine = this.orderLines[orderLineIndex];
    const maxQuantity = orderLine.maxQuantity || 50;

    if (orderLine.quantity < maxQuantity) {
      orderLine.quantity++;
      this.orderChange.emit({ orderLineIndex, orderLine });
    }

    this.cd.markForCheck();
  }

  decrementQty(orderLineIndex: number) {
    const orderLine = this.orderLines[orderLineIndex];
    const minQuantity = orderLine.minQuantity || 0;

    if (orderLine.quantity > minQuantity) {
      orderLine.quantity--;
      this.orderChange.emit({ orderLineIndex, orderLine });
    }

    this.cd.markForCheck();
  }

  requestPromoCodeModal() {
    this.requestDiscountModal.emit();
  }
}
