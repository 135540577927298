import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { OrderSummaryBaseComponent } from '@luggagehero/features/ui';
import { SharedStorageService } from '@luggagehero/shared/services/storage';

@Component({
  selector: 'lh-order-summary',
  templateUrl: './order-summary.component.html',
  styleUrls: ['./order-summary.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderSummaryComponent extends OrderSummaryBaseComponent {
  constructor(storage: SharedStorageService, cd: ChangeDetectorRef) {
    super(storage, cd);
  }
}
