import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, ElementRef, HostListener, Input } from '@angular/core';

import { TravelerShopsUiDropdownComponent } from '../dropdown/traveler-shops-ui-dropdown.component';

@Component({
  selector: 'lh-traveler-shops-ui-menu-button',
  standalone: true,
  imports: [CommonModule, TravelerShopsUiDropdownComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['traveler-shops-ui-menu-button.component.scss'],
  templateUrl: 'traveler-shops-ui-menu-button.component.html',
})
export class TravelerShopsUiMenuButtonComponent {
  @Input() theme = 'light';
  isOpen = false;

  constructor(private elementRef: ElementRef<HTMLElement>) {}

  toggleMenu() {
    this.isOpen = !this.isOpen;
  }

  @HostListener('document:click', ['$event'])
  onClickOutside(event: Event) {
    if (!this.elementRef.nativeElement.contains(event.target as Node)) {
      this.isOpen = false;
    }
  }
}
