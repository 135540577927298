import { AfterContentInit, Directive, ElementRef, Input } from '@angular/core';
import { FocusableElement } from '@progress/kendo-angular-grid/navigation/focusable-element.interface';

@Directive({
  selector: '[lhAutoFocus]',
})
export class AutofocusDirective implements AfterContentInit {
  @Input() lhAutoFocus: boolean;

  public constructor(private el: ElementRef<FocusableElement>) {}

  public ngAfterContentInit() {
    if (this.lhAutoFocus === false) {
      return;
    }
    setTimeout(() => this.requestElementFocus(), 350);
  }

  private requestElementFocus() {
    try {
      this.el.nativeElement.focus();
    } catch {
      // Ignore
    }
  }
}
