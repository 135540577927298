import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ModalStyle } from '@luggagehero/core';
import { PhotoStepBaseComponent } from '@luggagehero/features/shops';
import { AppConfig } from '@luggagehero/shared/app-settings/data-access';
import { ViewImageComponent } from '@luggagehero/web/features/ui';

@Component({
  selector: 'lh-photo-step',
  templateUrl: './photo-step.component.html',
  styleUrls: ['./photo-step.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class PhotoStepComponent extends PhotoStepBaseComponent {
  protected showExample() {
    void this.modalService.show({
      component: ViewImageComponent,
      componentState: {
        image: AppConfig.SHOW_EXAMPLE_S3_KEY,
        imageSize: 'large',
      },
      style: ModalStyle.Alert,
      autoWidth: 'md',
    });
  }
}
