export class TimerValue {
  private static _inactive = new TimerValue('', '--', '--', '--');
  private static _zero = new TimerValue('', '00', '00', '00');

  constructor(
    public readonly days: string,
    public readonly hours: string,
    public readonly minutes: string,
    public readonly seconds: string,
  ) {}

  public static get inactive(): TimerValue {
    return this._inactive;
  }

  public static get zero(): TimerValue {
    return this._zero;
  }

  public get totalSeconds(): number {
    let d: number; // days
    let h: number; // hours
    let m: number; // minutes
    let s: number; // seconds

    if (
      isNaN((d = parseInt(this.days))) ||
      isNaN((h = parseInt(this.hours))) ||
      isNaN((m = parseInt(this.minutes))) ||
      isNaN((s = parseInt(this.seconds)))
    ) {
      // If any of the values are not numbers, return 0
      return 0;
    }
    // Return the total seconds
    return d * 24 * 60 * 60 + h * 60 * 60 + m * 60 + s;
  }

  private formatTimeComponent(value: string, placeholder?: string): string {
    if (placeholder || (value && Number(value) > 0)) {
      return value ? `0${value}`.slice(-2) : placeholder;
    }
    // Not a positive numeric value and no placeholder provided
    return null;
  }
}
