<div class="main-content" [class.footer-padding]="showFooter">
  @if (isLoading && !availablePaymentOptions) {
    <lh-shared-ui-spinner [visible]="true"></lh-shared-ui-spinner>
  } @else if (isOrderFree) {
    <lh-shared-feature-fluent-auth
      [hideButtons]="true"
      (canSubmitChange)="onCanSubmitChange($event)"
      #auth
    ></lh-shared-feature-fluent-auth>
  } @else {
    <div class="payment-options" [class.reverse]="!isApplePayAvailable && !isGooglePayAvailable">
      <!-- Apple Pay option -->
      @if (isApplePayAvailable) {
        <lh-shared-ui-apple-pay-button
          [type]="'plain'"
          [theme]="'light'"
          [size]="'small'"
          [elevated]="false"
          [selected]="selectedPaymentOption === 'ApplePay'"
          (press)="selectPaymentOption('ApplePay')"
        ></lh-shared-ui-apple-pay-button>
      }

      <!-- Google Pay option -->
      @if (isGooglePayAvailable) {
        <lh-shared-ui-google-pay-button
          [id]="'googlePayOptionButton'"
          [type]="'plain'"
          [theme]="'light'"
          [size]="'small'"
          [elevated]="false"
          [selected]="selectedPaymentOption === 'GooglePay'"
          (press)="selectPaymentOption('GooglePay')"
        ></lh-shared-ui-google-pay-button>
      }

      <!-- Paypal option -->
      <lh-shared-ui-paypal-button
        [id]="'paypalOptionButton'"
        [type]="'plain'"
        [theme]="'white'"
        [size]="'small'"
        [elevated]="false"
        [logomark]="true"
        [logotype]="false"
        [selected]="selectedPaymentOption === 'Paypal'"
        (press)="selectPaymentOption('Paypal')"
      ></lh-shared-ui-paypal-button>

      <!-- Payment card option -->
      <lh-shared-ui-payment-card-button
        [id]="'paymentCardButton'"
        [theme]="'info'"
        [size]="'small'"
        [elevated]="false"
        [selected]="selectedPaymentOption === 'PaymentCard'"
        (press)="selectPaymentOption('PaymentCard')"
      ></lh-shared-ui-payment-card-button>
    </div>

    @if (isPaymentMethodSelectorInitialized) {
      <div class="payment-method-selector" [hidden]="selectedPaymentOption !== 'PaymentCard'">
        @if (!isLoggedIn) {
          <lh-shared-feature-fluent-auth
            [hideButtons]="true"
            (canSubmitChange)="onCanSubmitChange($event)"
            #auth
          ></lh-shared-feature-fluent-auth>
        } @else {
          <lh-shared-feature-select-payment-method
            [payment]="payment"
            (canSubmitChange)="onCanSubmitChange($event)"
            (paymentMethodSubmit)="onSubmitPaymentMethod($event)"
            (selectedPaymentMethodChange)="onSelectedPaymentMethodChange($event)"
            #paymentMethodSelector
          ></lh-shared-feature-select-payment-method>
        }
      </div>
    }
  }
</div>

@if (showFooter) {
  <div class="footer">
    <lh-shared-feature-bookings-simple-order-summary
      [headline]="'SUMMARY' | lhTranslate"
      [isPaymentCardValidationEnabled]="true"
      [isPaid]="false"
      [isCanceled]="false"
      [isLoading]="false"
      [order]="order"
      [isOrderFree]="isOrderFree"
      [showCollapseToggle]="true"
      [isCollapsed]="true"
      [noPadding]="true"
      [isReadOnly]="true"
    ></lh-shared-feature-bookings-simple-order-summary>

    <div class="mt-2"></div>

    @if (isOrderFree) {
      <lh-shared-ui-button
        [style]="'warning'"
        [block]="true"
        [shadow]="true"
        [disabled]="!canGoForward || isLoading"
        (press)="confirm()"
      >
        {{ 'CONFIRM' | lhTranslate }}
      </lh-shared-ui-button>
    } @else {
      @switch (selectedPaymentOption) {
        @case ('ApplePay') {
          <lh-shared-ui-apple-pay-button
            [disabled]="!canGoForward || isLoading"
            (press)="onApplePayClick($event)"
          ></lh-shared-ui-apple-pay-button>
        }
        @case ('GooglePay') {
          <lh-shared-ui-google-pay-button
            [id]="'googlePayButton'"
            [type]="'plain'"
            [theme]="'dark'"
            [size]="'medium'"
            [elevated]="true"
            [disabled]="!canGoForward || isLoading"
            (press)="onGooglePayClick($event)"
          ></lh-shared-ui-google-pay-button>
        }
        @case ('Paypal') {
          <lh-shared-ui-paypal-button
            [id]="'paypalButton'"
            [type]="'plain'"
            [theme]="'gold'"
            [size]="'medium'"
            [logomark]="true"
            [logotype]="true"
            [elevated]="true"
            [disabled]="!canGoForward || isLoading"
            (press)="onPaypalClick($event)"
          ></lh-shared-ui-paypal-button>
        }
        @default {
          <lh-shared-ui-payment-card-button
            [id]="'paymentCardButton'"
            [theme]="'warning'"
            [size]="'medium'"
            [elevated]="true"
            [disabled]="!canGoForward || isLoading"
            (press)="confirm()"
          >
            {{ 'CONFIRM' | lhTranslate }}
          </lh-shared-ui-payment-card-button>
        }
      }
    }
  </div>
}
