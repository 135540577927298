import '@angular/google-maps';

interface ActiveStorageLocationMarkerNoticeOptions {
  map: google.maps.Map;
  position: google.maps.LatLng;
  text: string;
  minZoomLevel?: number;
  offset?: { x: number; y: number };
  onClick?: () => void;
}

export class ActiveStorageLocationMarkerNotice extends google.maps.OverlayView {
  private containerDiv: HTMLDivElement;
  protected map!: google.maps.Map;
  protected position!: google.maps.LatLng;
  protected minZoomLevel!: number;
  protected text = '';
  protected offset!: { x: number; y: number };

  constructor({
    map,
    position,
    text,
    minZoomLevel = 16,
    offset = { x: 0, y: 50 },
    onClick,
  }: ActiveStorageLocationMarkerNoticeOptions) {
    super();
    this.map = map;
    this.position = position;
    this.text = text;
    this.minZoomLevel = minZoomLevel;
    this.offset = offset;
    this.containerDiv = this.createContainer();
    const content = this.createContent(text);
    this.containerDiv.appendChild(content);
    this.containerDiv.addEventListener('click', (e) => {
      e.preventDefault();
      onClick?.();
    });

    this.setMap(map);
  }

  private createContainer(): HTMLDivElement {
    const container = document.createElement('div');
    Object.assign(container.style, {
      cursor: 'pointer',
      height: '0',
      position: 'absolute',
      width: '200px',
    });

    return container;
  }

  private createContent(text: string): HTMLDivElement {
    const content = document.createElement('div');
    Object.assign(content.style, {
      position: 'absolute',
      top: '0',
      left: '0',
      transform: 'translate(-50%, -100%)',
      backgroundColor: '#FFF1A1',
      padding: '5px 10px',
      borderRadius: '5px',
      overflowY: 'auto',
      maxHeight: '60px',
      boxShadow: '0px 2px 10px 1px rgba(0, 0, 0, 0.1)',
      fontSize: '1rem',
      textAlign: 'center',
      fontWeight: '600',
      whitespace: 'nowrap',
    });
    content.innerHTML = text;
    return content;
  }

  override onAdd(): void {
    this.getPanes()?.floatPane.appendChild(this.containerDiv);
  }

  override onRemove(): void {
    if (this.containerDiv.parentElement) {
      this.containerDiv.parentElement.removeChild(this.containerDiv);
    }
  }

  override draw(): void {
    const divPosition = this.getProjection().fromLatLngToDivPixel(this.position);
    if (!divPosition) {
      return super.draw();
    }

    let display = Math.abs(divPosition.x) < 4000 && Math.abs(divPosition.y) < 4000 ? 'block' : 'none';

    const currentZoom = this.map.getZoom();

    if (currentZoom && currentZoom < this.minZoomLevel) {
      display = 'none';
    }

    if (display === 'block') {
      this.containerDiv.style.left = divPosition.x + this.offset.x + 'px';
      this.containerDiv.style.top = divPosition.y + this.offset.y + 'px';
    }

    if (this.containerDiv.style.display !== display) {
      this.containerDiv.style.display = display;
    }
  }
}
