import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import { AppConfig } from '@luggagehero/shared/app-settings/data-access';
import {
  BookingAddOns,
  GUEST_INSURANCE_FEE_PRODUCT_KEY,
  GUEST_PREMIUM_INSURANCE_FEE_PRODUCT_KEY,
  ILuggage,
  INSURANCE_PRODUCT_KEY,
  ITimePeriod,
  LegacyOrder,
  LegacyOrderLine,
  OrderRequest,
  STORAGE_DAY_PRODUCT_KEY,
  STORAGE_HOUR_PRODUCT_KEY,
  WALK_IN_DAILY_RATE_PRODUCT_KEY,
  WALK_IN_HOURLY_RATE_PRODUCT_KEY,
  WALK_IN_INSURANCE_FEE_PRODUCT_KEY,
} from '@luggagehero/shared/interfaces';
import { SharedBookingService } from '@luggagehero/shared/services/bookings';
import { SharedErrorService } from '@luggagehero/shared/services/error';
import { SharedNotificationService } from '@luggagehero/shared/services/notification';
import { SharedOrderService } from '@luggagehero/shared/services/orders';
import { SharedPricingService } from '@luggagehero/shared/services/pricing';
import { SharedPromoCodeService } from '@luggagehero/shared/services/promo-codes';
import { SharedShopsService } from '@luggagehero/shared/services/shops';
import { SharedStorageService } from '@luggagehero/shared/services/storage';
import { SharedStorageCriteria, SharedStorageCriteriaService } from '@luggagehero/shared/services/storage-criteria';
import { cloneDeep } from '@luggagehero/shared/util';
import { TranslateService } from '@ngx-translate/core';
import { skip, Subscription } from 'rxjs';

import { BookingActionBaseComponent } from './booking-action.base-component';

@Component({ template: '' })
export abstract class ModifyBookingActionBaseComponent
  extends BookingActionBaseComponent
  implements AfterViewInit, OnDestroy
{
  @ViewChild('datePickerAnchor') public datePickerAnchor: ElementRef;
  @ViewChild('datePickerPopup', { read: ElementRef }) public datePickerPopup: ElementRef;

  public modifiedOrder: LegacyOrder;
  public order: LegacyOrder;

  public hourlyRate: number;
  public dailyRate: number;
  public currency: string;

  public minBagsWarning: string;

  private subscriptions: Subscription[];
  private updateOrderTimeout: NodeJS.Timeout;
  private _isUpdating = false;
  private _isModified = false;
  private isWalkIn: boolean;
  private isGuest: boolean;

  public constructor(
    private translate: TranslateService,
    private error: SharedErrorService,
    private notify: SharedNotificationService,
    private criteriaService: SharedStorageCriteriaService,
    private priceService: SharedPricingService,
    private promoCodeService: SharedPromoCodeService,
    private orderService: SharedOrderService,
    bookingService: SharedBookingService,
    shopsService: SharedShopsService,
    storageService: SharedStorageService,
    cd: ChangeDetectorRef,
  ) {
    super(bookingService, shopsService, storageService, cd);
  }

  public get promoCodeCta(): string {
    return this.booking.price.discountCode ? 'CHANGE_DISCOUNT_CODE' : 'HAVE_A_VOUCHER_OR_PROMO_CODE_MINI';
  }

  get isUpdating(): boolean {
    return this._isUpdating;
  }
  set isUpdating(value: boolean) {
    this._isUpdating = value;
    this.cd.markForCheck();
  }

  get isModified(): boolean {
    return this._isModified;
  }
  set isModified(value: boolean) {
    this._isModified = value;
    this.cd.markForCheck();
  }

  public get addOns(): BookingAddOns {
    return {
      insurance: this.priceService.insuranceSelected,
      freeCancellation: this.priceService.freeCancellationFeeSelected,
      securitySeals: this.priceService.numberOfSecuritySeals,
      // Don't change whether this was a walk-in or not
      walkIn: this.isWalkIn,
      guest: this.isGuest,
    };
  }

  public get isModifyBagsAllowed(): boolean {
    if (!this.booking) {
      return false;
    }
    return ['CONFIRMED', 'CHECKED_IN'].includes(this.booking.status);
  }

  public get isModifyDateAllowed(): boolean {
    if (!this.booking) {
      return false;
    }
    return this.booking.status === 'CONFIRMED';
  }

  public get isOpenOnDropoffDate(): boolean {
    const dropoffHours = this.shopsService.getOpeningHoursForDate(this.criteriaService.period.from, this.shop);
    if (!dropoffHours || dropoffHours.length === 0) {
      return false;
    }
    return true;
  }

  public get isOpenOnPickupDate(): boolean {
    const pickupHours = this.shopsService.getOpeningHoursForDate(this.criteriaService.period.to, this.shop);
    if (!pickupHours || pickupHours.length === 0) {
      return false;
    }
    return true;
  }

  public get canConfirmChanges(): boolean {
    if (!this.isOpenOnDropoffDate || !this.isOpenOnPickupDate) {
      return false;
    }
    return true;
  }

  public ngAfterViewInit() {
    // HACK: Using a delay to be sure all initialization is done as we only want to react to user-initiated changes
    setTimeout(() => {
      //
      // Subscribe to service changes to update the order accordingly.
      //
      this.subscriptions = [
        this.priceService.pricingModel$.pipe(skip(1)).subscribe(() => this.updateOrder(false)),
        this.promoCodeService.promoCode.pipe(skip(1)).subscribe(() => this.updateOrder(false)),
        this.criteriaService.changeRequested.subscribe(() => this.updateOrder(false)),
      ];
    }, 1000);
  }

  public onBaseInit(): Promise<void> {
    //
    // Initialize service values to match the current state of the booking
    //
    this.priceService.insuranceSelected = this.booking.price.addOns.insurance ? true : false;
    this.priceService.freeCancellationFeeSelected = this.booking.price.addOns.freeCancellation ? true : false;
    this.priceService.numberOfSecuritySeals = this.booking.price.addOns.securitySeals || 0;
    this.priceService.changePricing(this.booking.price.pricingModel || 'hourly');

    const luggage: ILuggage = {
      normal: (this.booking.metadata?.lh_number_of_bags || this.booking.luggage.normal) as number,
      hand: this.booking.luggage.hand,
    };
    const period: ITimePeriod = {
      from: this.booking.period.checkIn,
      to: this.booking.period.checkOut,
    };
    this.criteriaService.current = new SharedStorageCriteria(
      this.criteriaService.currentOrDefault.location,
      period,
      luggage,
    );

    // Set the current order from the booking (clone to avoid modifying the order stored on the booking)
    this.order = cloneDeep(this.booking.orderDetails[this.booking.orderDetails.length - 1]);

    // Record whether the existing order was a walk-in
    this.isWalkIn = this.order.orderRequest.addOns.walkIn;
    this.isGuest = this.order.orderRequest.addOns.guest;

    this.minBagsWarning = this.orderService.getMinimumBagsWarning(
      this.isWalkIn || this.isGuest,
      this.criteriaService.currentOrDefault.luggage,
    );

    return void 0;
  }

  public ngOnDestroy(): void {
    if (this.subscriptions) {
      this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }
    super.ngOnDestroy();
  }

  public onOrderLineChange(orderLine: LegacyOrderLine) {
    // HACK: Using a hardcoded product id to add/remove insurance for now
    switch (orderLine.product._id) {
      case WALK_IN_INSURANCE_FEE_PRODUCT_KEY:
      case GUEST_INSURANCE_FEE_PRODUCT_KEY:
      case GUEST_PREMIUM_INSURANCE_FEE_PRODUCT_KEY:
      case INSURANCE_PRODUCT_KEY:
        this.priceService.insuranceSelected = orderLine.selected ? true : false;
        break;
    }

    this.updateOrder(true);
  }

  public async confirmChanges() {
    this.isLoading = true;

    try {
      const res = await this.bookingService.modifyBooking(this.booking._id, this.orderService.latestQuote._id);

      this.updateBooking(res);
      this.close();

      this.notify.chat(
        this.translate.instant('BOOKING_WAS_CHANGED_SUCCESSFULLY') as string,
        this.translate.instant('BOOKING_CHANGED') as string,
      );
    } catch (err) {
      this.error.handleError(err, this.translate.instant('BOOKING_COULD_NOT_BE_CHANGED') as string);
    }

    this.isLoading = false;
  }

  private updateOrder(immediate = false) {
    if (!this.isInitialized) {
      return;
    }

    const appliedDiscountCode = this.promoCodeService.appliedDiscount?.code;
    let discountCode = this.booking.price.discountCode;

    // Check if we have an applied discount locally and it's not equal to the discount currently on the booking
    if (appliedDiscountCode && appliedDiscountCode !== discountCode) {
      discountCode = appliedDiscountCode;
    }

    const minBags = this.orderService.getMinimumBags(this.isWalkIn || this.isGuest);
    const luggage = {
      normal: Math.max(this.criteriaService.currentOrDefault.luggage.normal, minBags),
      hand: this.criteriaService.currentOrDefault.luggage.hand,
    };

    this.minBagsWarning = this.orderService.getMinimumBagsWarning(
      this.isWalkIn || this.isGuest,
      this.criteriaService.currentOrDefault.luggage,
    );

    const requestForQuote = this.orderService.getRequestForQuote(
      this.booking.shopId,
      this.priceService.pricingModel,
      luggage,
      this.criteriaService.currentOrDefault.period,
      this.addOns,
      this.booking.orderDetails[0]?.orderRequest?.optionalProducts,
      discountCode,
    );

    this.isModified = this.orderService.hasChanged(requestForQuote, this.booking);

    if (!this.isModified) {
      return;
    }

    if (immediate) {
      void this._updateOrder(requestForQuote);
      return;
    }

    this.isUpdating = true;

    clearTimeout(this.updateOrderTimeout);

    this.updateOrderTimeout = setTimeout(() => {
      void (async () => {
        await this._updateOrder(requestForQuote);
        this.isUpdating = false;
      })();
    }, AppConfig.ORDER_DEBOUNCE_TIME);
  }

  private async _updateOrder(requestForQuote: OrderRequest) {
    this.modifiedOrder = await this.orderService.generateQuote(requestForQuote);

    if (AppConfig.IS_PRICE_ON_PRICING_MODEL_SELECTOR_ENABLED) {
      // Set hourly and daily rates and currency for pricing model selector binding
      this.hourlyRate = this.orderService.latestProductList.products.find((p) =>
        [STORAGE_HOUR_PRODUCT_KEY, WALK_IN_HOURLY_RATE_PRODUCT_KEY].includes(p.key),
      )?.cost;
      this.dailyRate = this.orderService.latestProductList.products.find((p) =>
        [STORAGE_DAY_PRODUCT_KEY, WALK_IN_DAILY_RATE_PRODUCT_KEY].includes(p.key),
      )?.cost;
    }
    this.currency = this.orderService.latestProductList.currency;

    this.isModified = true;
  }
}
