import { Inject, inject, Injectable, InjectionToken } from '@angular/core';
import { SharedAppSettings, SharedAppSettingsService } from '@luggagehero/shared/app-settings/data-access';
import { Config } from '@luggagehero/shared/environment';
import {
  Booking,
  BookingAddOns,
  CANCELLATION_FEE_PRODUCT_KEY,
  DISCOUNT_PRODUCT_KEY,
  DISCOUNT_TRANSFORMER_SERVICE,
  DiscountTransformerService,
  EncryptedString,
  GUEST_DAILY_RATE_PRODUCT_KEY,
  GUEST_HOURLY_RATE_PRODUCT_KEY,
  GUEST_INSURANCE_FEE_PRODUCT_KEY,
  GUEST_PREMIUM_INSURANCE_FEE_PRODUCT_KEY,
  GUEST_SERVICE_FEE_PRODUCT_KEY,
  IDiscount,
  ILuggage,
  INSURANCE_PRODUCT_KEY,
  IPrice,
  ITimePeriod,
  LegacyOrder,
  LegacyOrderLine,
  MULTI_BAG_REDUCE_STORAGE_RATES_MODIFIER_KEY,
  MULTI_DAY_REDUCE_STORAGE_RATES_MODIFIER_KEY,
  Order,
  OrderLine,
  OrderRequest,
  PREMIUM_INSURANCE_PRODUCT_KEY,
  PREMIUM_INSURANCE_PRODUCT_KEYS,
  PRICING_SERVICE,
  PricingModel,
  PricingService,
  ProductList,
  SERVICE_FEE_PRODUCT_KEY,
  STORAGE_DAY_PRODUCT_KEY,
  STORAGE_HOUR_PRODUCT_KEY,
  TIP_PRODUCT_KEY,
  WALK_IN_DAILY_RATE_PRODUCT_KEY,
  WALK_IN_HOURLY_RATE_PRODUCT_KEY,
  WALK_IN_INSURANCE_FEE_PRODUCT_KEY,
  WALK_IN_SERVICE_FEE_PRODUCT_KEY,
} from '@luggagehero/shared/interfaces';
import { SharedHttpService } from '@luggagehero/shared/services/http';
import { SharedPromoCodeService } from '@luggagehero/shared/services/promo-codes';
import { SharedStorageService } from '@luggagehero/shared/services/storage';
import { SharedTranslateService } from '@luggagehero/shared/services/translation';
import { cloneDeep, isEqual, SharedUtilDate, SharedUtilList, SharedUtilString } from '@luggagehero/shared/util';
import moment from 'moment';
import { firstValueFrom } from 'rxjs';

// Order lines are are sorted according to these priorities
const ORDER_LINE_PRIORITY_STORAGE = 0;
const ORDER_LINE_PRIORITY_OTHER = 1;
const ORDER_LINE_PRIORITY_INSURANCE = 20;
const ORDER_LINE_PRIORITY_FREE_CANCELLATION = 20;
const ORDER_LINE_PRIORITY_PROMO_DISCOUNT = 90;
const ORDER_LINE_PRIORITY_TIPS = 100;

export const ORDER_SERVICE_ENDPOINT_TOKEN = new InjectionToken<string>('ORDER_SERVICE_ENDPOINT_TOKEN');

@Injectable({
  providedIn: 'root',
})
export class SharedOrderService {
  // Translation keys needed by the service
  private text = {
    PAY_AS_YOU_GO: '',
    PRICE_STORAGE: '',
    COVERAGE: '',
    PER_BAG: '',
    INSURANCE: '',
    PREMIUM_INSURANCE: '',
    DAY: '',
    DAYS: '',
    HOUR: '',
    HOURS: '',
    STORE_UP_TO: '',
    BAGS: '',
    AT_THIS_RATE: '',
    STORAGE_LOCATION_MIN_X_BAGS: '',
    YOU_ARE_SAVING_X_ON_ADDITIONAL_DAYS_PART_1: '',
    CANCELLATION_FEE_EXPLANATION_PART_1: '',
    CANCELLATION_FEE_EXPLANATION_PART_2: '',
    GROUP_DISCOUNT_APPLIES: '',
    PER_HOUR_SHORT: '',
  };

  private _latestQuote: LegacyOrder;
  private _latestProductList: ProductList;

  protected discountTransformerService = inject<DiscountTransformerService>(DISCOUNT_TRANSFORMER_SERVICE);
  private pricingService = inject<PricingService>(PRICING_SERVICE);

  constructor(
    @Inject(ORDER_SERVICE_ENDPOINT_TOKEN) private ordersEndpoint: string,
    private http: SharedHttpService,
    private promoCodeService: SharedPromoCodeService,
    private translateService: SharedTranslateService,
    private storageService: SharedStorageService,
    private appSettingsService: SharedAppSettingsService,
  ) {}

  public get latestQuote(): LegacyOrder {
    return this._latestQuote;
  }

  public get latestProductList(): ProductList {
    return this._latestProductList;
  }

  private get settings(): SharedAppSettings {
    return this.appSettingsService.current;
  }

  public clear() {
    this._latestQuote = null;
    this._latestProductList = null;
  }

  public getMinimumBags(dropOff: boolean): number {
    const enabled = dropOff ? this.settings.IS_DROP_OFF_MIN_BAGS_ENABLED : this.settings.IS_BOOKING_MIN_BAGS_ENABLED;
    const minBags = dropOff ? this.settings.DROP_OFF_MIN_BAGS : this.settings.BOOKING_MIN_BAGS;

    if (enabled && typeof minBags === 'number' && minBags > 0) {
      return minBags;
    }
    return 1;
  }

  public getMinimumBagsWarning(dropOff: boolean, luggage: ILuggage): string {
    const numberOfBags = luggage.normal + luggage.hand;
    const minBags = this.getMinimumBags(dropOff);

    if (numberOfBags >= minBags) {
      return null;
    }

    const part1 = this.translateService.instant('ADD_UP_TO_X_BAGS');
    const part2 = `${minBags} ${this.translateService.instant('BAGS').toLowerCase()}`;
    const part3 = this.translateService.instant('WITHOUT_PAYING_MORE_AT_LOCATION').toLowerCase();

    return `${part1} ${part2} ${part3}`;
  }

  public async requestCancellation(bookingId: string): Promise<LegacyOrder> {
    const url = `${Config.environment.TRAVELER_API}/v3/bookings/${bookingId}/request_cancel`;
    const res = await this.http.post<Order | EncryptedString>(url, null);

    const newOrder = SharedUtilString.tryDecryptAndParseString(res, Config.environment.CRYPTO_KEY);
    this._latestQuote = await this.convertNewOrder(newOrder, false, false);

    return this._latestQuote;
  }

  public getRequestForQuote(
    storageLocationId: string,
    pricingModel: PricingModel,
    luggage: ILuggage,
    period: ITimePeriod,
    addOns: BookingAddOns,
    optionalProducts: string[],
    discountCode?: string,
  ): OrderRequest {
    const requestForQuote: OrderRequest = {
      storageLocationId,
      from: SharedUtilDate.serializeDate(period.from),
      to: SharedUtilDate.serializeDate(period.to),
      bags: luggage.normal + luggage.hand,
      pricingModel,
      addOns: { ...addOns },
      optionalProducts,
      discountCode,
    };

    if (this.storageService.variant) {
      if (this.storageService.variant === this.settings.EXPERIMENT_VARIANTS.homelessEntrepreneur) {
        addOns.insurance = false;
      }
      requestForQuote.variantId = this.storageService.variant;
    }

    return requestForQuote;
  }

  /**
   * Generates a preliminary order based on the specified pricing and booking paramters. Used in order summary views
   * before creating a booking and for bookings that are not yet completed.
   */
  public async generateQuote(
    storageLocationId: string,
    pricingModel: PricingModel,
    luggage: ILuggage,
    period: ITimePeriod,
    addOns: BookingAddOns,
    optionalProducts: string[],
    discountCode?: string,
  ): Promise<LegacyOrder>;
  public async generateQuote(requestForQuote: OrderRequest): Promise<LegacyOrder>;
  public async generateQuote(
    requestForQuoteOrStorageLocationId: OrderRequest | string,
    pricingModel?: PricingModel,
    luggage?: ILuggage,
    period?: ITimePeriod,
    addOns?: BookingAddOns,
    optionalProducts?: string[],
    discountCode?: string,
  ): Promise<LegacyOrder> {
    let requestForQuote: OrderRequest;

    if (typeof requestForQuoteOrStorageLocationId === 'string') {
      const storageLocationId = requestForQuoteOrStorageLocationId;
      requestForQuote = this.getRequestForQuote(
        storageLocationId,
        pricingModel,
        luggage,
        period,
        addOns,
        optionalProducts,
        discountCode,
      );
    } else {
      requestForQuote = requestForQuoteOrStorageLocationId;
    }

    // TODO: Remove when API is updated to handle this
    delete requestForQuote.addOns.loyaltyMember;
    console.log('requestForQuote', requestForQuote);

    const url = `${this.ordersEndpoint}/request_quote`;
    const res = await this.http.post<Order | EncryptedString>(url, requestForQuote);

    const newOrder = SharedUtilString.tryDecryptAndParseString(res, Config.environment.CRYPTO_KEY);
    const quote = await this.convertNewOrder(newOrder, false, false);

    this._latestQuote = quote;

    const appSettings = await firstValueFrom(this.appSettingsService.$current);

    // Set default selection for optional products
    newOrder.productList.products.forEach((p) => {
      if (appSettings.OPTIONAL_PRODUCTS_SELECTED_BY_DEFAULT?.includes(p.key)) {
        p.selected = true;
      }
    });
    this._latestProductList = newOrder.productList;

    if (!Config.isProduction) {
      console.debug(SharedUtilString.formatOrderEvent(this._latestQuote, 'generated'));
    }

    return quote;
  }

  public hasChanged(newRequestForQuote: OrderRequest, existingBooking: Booking): boolean {
    // Generate what would have been the RFQ preceding the existing booking
    const oldRequestForQuote: OrderRequest = this.getRequestForQuote(
      existingBooking.shopId,
      existingBooking.price.pricingModel,
      existingBooking.luggage,
      existingBooking.period,
      existingBooking.price.addOns,
      existingBooking.orderDetails[0]?.orderRequest?.optionalProducts,
      existingBooking.price.discountCode,
    );
    return !isEqual(newRequestForQuote, oldRequestForQuote);
  }

  public calculateTotal(orderLines: LegacyOrderLine[]): number {
    let total = 0;

    for (const line of orderLines) {
      if (line.isPricing) {
        continue; // Skip pricing lines as they don't reflect a final price
      }

      if (line.optional && !line.selected) {
        continue; // Skip optional lines that are not selected
      }

      const quantity = typeof line.quantity === 'number' ? line.quantity : 1;
      total += quantity * line.unitPrice;
    }
    return total;
  }

  public getEstimatedPrice(order: LegacyOrder): IPrice {
    const avgStorageHours = 5;
    let storage: number;

    const dailyStorageLine =
      order.orderLines.find((ol) => ol.product._id === STORAGE_DAY_PRODUCT_KEY) ||
      order.orderLines.find((ol) => ol.product._id === WALK_IN_DAILY_RATE_PRODUCT_KEY);

    if (dailyStorageLine) {
      storage = Math.round(dailyStorageLine.unitPrice * dailyStorageLine.quantity * 100) / 100;
    } else {
      const hourlyStorageLine =
        order.orderLines.find((ol) => ol.product._id === STORAGE_HOUR_PRODUCT_KEY) ||
        order.orderLines.find((ol) => ol.product._id === WALK_IN_HOURLY_RATE_PRODUCT_KEY);

      if (hourlyStorageLine) {
        storage = Math.round(hourlyStorageLine.unitPrice * hourlyStorageLine.quantity * avgStorageHours * 100) / 100;
      }
    }

    const serviceFee =
      this.calculateOrderLineTotal(SERVICE_FEE_PRODUCT_KEY, order) ||
      this.calculateOrderLineTotal(GUEST_SERVICE_FEE_PRODUCT_KEY, order) ||
      this.calculateOrderLineTotal(WALK_IN_SERVICE_FEE_PRODUCT_KEY, order);
    const startupFee =
      this.calculateOrderLineTotal(INSURANCE_PRODUCT_KEY, order) ||
      this.calculateOrderLineTotal(GUEST_SERVICE_FEE_PRODUCT_KEY, order) ||
      this.calculateOrderLineTotal(WALK_IN_INSURANCE_FEE_PRODUCT_KEY, order);

    const discount = this.calculateOrderLineTotal(DISCOUNT_PRODUCT_KEY, order);
    const total = storage + serviceFee + startupFee + discount;

    const estimatedPrice: IPrice = {
      currency: order.currency,
      storage,
      serviceFee,
      startupFee,
      discount,
      total,
    };
    if (!Config.isProduction) {
      console.log(`Estimated price: ${estimatedPrice.currency} ${estimatedPrice.total}`, estimatedPrice);
    }
    return estimatedPrice;
  }

  public async convertNewOrder(newOrder: Order, isCancelled: boolean, isFinal: boolean): Promise<LegacyOrder> {
    if (!Config.isProduction) {
      console.log(`Server-side order (${newOrder.orderRequest.pricingModel})`, newOrder);
    }

    const clonedOrder = cloneDeep(newOrder);

    // Remove zero value discount
    clonedOrder.orderLines = clonedOrder.orderLines.filter(
      (ol) => ol.productKey !== DISCOUNT_PRODUCT_KEY || ol.finalPrice !== 0,
    );

    const appSettings = await firstValueFrom(this.appSettingsService.$current);

    const isCancellation = clonedOrder.orderLines.find((ol) => ol.productKey === CANCELLATION_FEE_PRODUCT_KEY)
      ? true
      : false;

    // For cancellation orders, remove all order lines with zero units (effectively leaving only the cancellation fee)
    if (isCancellation && appSettings.IS_ORDER_SUMMARY_HIDE_CANCELED_ORDER_LINES_ENABLED) {
      clonedOrder.orderLines = clonedOrder.orderLines.filter(
        (ol) => ol.productKey === CANCELLATION_FEE_PRODUCT_KEY || ol.units > 0,
      );
    }

    this.text = await firstValueFrom(this.translateService.get(this.text));

    // Convert all the order lines received from the server to the client side format
    const orderLines: LegacyOrderLine[] = [];
    await Promise.all(
      clonedOrder.orderLines.map(async (ol) => {
        const res = await this.convertNewOrderLine(ol, clonedOrder, isCancellation, isFinal, appSettings);
        if (res) {
          orderLines.push(...res);
        }
      }),
    );

    if (
      !isCancellation &&
      !orderLines.find((ol) => ol.product._id === INSURANCE_PRODUCT_KEY) &&
      !orderLines.find((ol) => ol.product._id === GUEST_INSURANCE_FEE_PRODUCT_KEY) &&
      !orderLines.find((ol) => ol.product._id === GUEST_PREMIUM_INSURANCE_FEE_PRODUCT_KEY) &&
      !orderLines.find((ol) => ol.product._id === WALK_IN_INSURANCE_FEE_PRODUCT_KEY) &&
      this.storageService.variant !== appSettings.EXPERIMENT_VARIANTS.homelessEntrepreneur
    ) {
      // Add order line to show deselected insurance product
      SharedUtilList.appendIfNotNullOrUndefined(orderLines, this.createInsuranceOrderLine(clonedOrder));
    }

    if (
      !isCancellation &&
      !clonedOrder.orderLines.find((ol) => ol.productKey === DISCOUNT_PRODUCT_KEY) &&
      clonedOrder.orderRequest.discountCode
    ) {
      // No discount on the order received by the server but there is a discount code added
      SharedUtilList.appendIfNotNullOrUndefined(orderLines, await this.createDiscountOrderLine(clonedOrder));
    }

    SharedUtilList.appendIfNotNullOrUndefined(
      orderLines,
      this.createFreeCancellationOrderLine(clonedOrder, isCancelled),
    );

    // Create the client side order
    const order: LegacyOrder = {
      _id: clonedOrder._id,
      orderRequest: clonedOrder.orderRequest,
      pricingModel: clonedOrder.orderRequest.pricingModel,
      bags: clonedOrder.orderRequest.bags,
      currency: clonedOrder.productList.currency,
      tip: clonedOrder.tip,
      orderLines: orderLines.sort((a, b) => this.compareOrderLines(a, b)),
      productList: clonedOrder.productList,
      total: this.calculateTotal(orderLines),
      createdAt: clonedOrder.createdAt,
      updatedAt: clonedOrder.updatedAt,
    };

    if (!Config.isProduction) {
      console.log(`Client-side order (${order.pricingModel})`, order);
    }

    return order;
  }

  public compareOrderLines(a: LegacyOrderLine, b: LegacyOrderLine): number {
    if (a.product._id === DISCOUNT_PRODUCT_KEY) {
      // Always put discount at the bottom
      return 1;
    }
    if (typeof a.priority === 'number' && typeof b.priority === 'number') {
      // Both order lines have a priority set, use the difference in priority to prioritize them
      return a.priority - b.priority;
    }
    if (typeof a.priority === 'number') {
      // First order line has a priority set, the second doesn't, so prioritize the first
      return -1;
    }
    if (typeof b.priority === 'number') {
      // Second order line has a priority set, the first doesn't, so prioritize the second
      return 1;
    }
    if (a.optional && !b.optional) {
      // The first order line is optional, the second isn't, so prioritize the second
      return -1;
    }
    if (!a.optional && b.optional) {
      // The first order line is not optional, the second is, so prioritize the first
      return 1;
    }
    // Consider the order lines of equal priority
    return 0;
  }

  private isPremiumInsurance(addOns: BookingAddOns) {
    if (addOns.insuranceKey && PREMIUM_INSURANCE_PRODUCT_KEYS.includes(addOns.insuranceKey)) {
      return true;
    }
    return false;
  }

  public async convertNewOrderLine(
    value: OrderLine,
    order: Order,
    isCancellation: boolean,
    isFinal: boolean,
    appSettings: SharedAppSettings,
  ): Promise<LegacyOrderLine[]> {
    const product = order.productList.products.find((p) => p.key === value.productKey);
    const numberOfBags = order.orderRequest.bags;

    const isDropOff = [
      WALK_IN_DAILY_RATE_PRODUCT_KEY,
      WALK_IN_HOURLY_RATE_PRODUCT_KEY,
      GUEST_DAILY_RATE_PRODUCT_KEY,
      GUEST_HOURLY_RATE_PRODUCT_KEY,
    ].includes(value.productKey);

    // TODO: Once transitioned to fully encrypted orders the separate decryption of modifified price can be removed
    const modifiedPrice = SharedUtilString.tryDecryptAndParseString(value.modifiedPrice, Config.environment.CRYPTO_KEY);

    const orderLine: LegacyOrderLine = {
      isPricing: false,
      product: {
        _id: value.productKey,
        name: value.name,
      },
      quantity: value.units,
      unitPrice: modifiedPrice?.length > 0 ? modifiedPrice[modifiedPrice.length - 1].unitPrice : value.unitPrice,
      showQuantity: false,
      showUnitPrice: false,
    };

    if (isCancellation) {
      orderLine.selected = value.units > 0;
    }

    const orderLines = [orderLine];

    const storageSavingsModifierKeys: string[] = [];
    let savingsOrderLineUnitPriceMultiplier = 1;

    // HACK: Until we have placeholder functionality in the tags, we are hardcoding most product info here
    switch (value.productKey) {
      //TODO: add guests
      case WALK_IN_DAILY_RATE_PRODUCT_KEY:
      case GUEST_DAILY_RATE_PRODUCT_KEY:
      case STORAGE_DAY_PRODUCT_KEY: {
        const storageLabel = this.text.PRICE_STORAGE;
        const numberOfDays = value.units > 0 ? value.units / numberOfBags : 0;
        const daysLabel = (numberOfDays === 1 ? this.text.DAY : this.text.DAYS).toLowerCase();

        // if a guest booking then minBags should be 1
        const minBagsEnabled = isDropOff
          ? appSettings.IS_DROP_OFF_MIN_BAGS_ENABLED
          : appSettings.IS_BOOKING_MIN_BAGS_ENABLED;
        const minBags = isDropOff ? appSettings.DROP_OFF_MIN_BAGS : appSettings.BOOKING_MIN_BAGS;

        orderLine.product.name = `${storageLabel}, ${numberOfDays} ${daysLabel}`;
        orderLine.product.unitSingular = 'BAG';
        orderLine.product.unitPlural = 'BAGS';
        orderLine.showQuantity = true;
        orderLine.showQuantityAsUpTo = minBagsEnabled && numberOfBags <= minBags ? true : false;
        orderLine.priority = ORDER_LINE_PRIORITY_STORAGE;

        if (orderLine.showQuantityAsUpTo && numberOfBags <= minBags) {
          const storeUpTo = this.text.STORE_UP_TO;
          const bags = `${minBags} ${this.text.BAGS.toLowerCase()}`;
          const atThisRate = this.text.AT_THIS_RATE.toLowerCase();
          const noBookingsLessThan = this.text.STORAGE_LOCATION_MIN_X_BAGS;

          orderLine.product.info = {
            header: `${storeUpTo} ${bags} ${atThisRate}`,
            body: `${noBookingsLessThan} ${bags}`,
          };
        }

        if (isCancellation && orderLine.quantity === 0) {
          orderLine.optional = true;
        }

        // HACK: For daily bookings, we get e.g. 6 units if the traveler stored 2 bags for 3 days
        orderLine.quantity = value.units > 0 ? value.units / numberOfDays : 0;
        orderLine.unitPrice *= numberOfDays;

        savingsOrderLineUnitPriceMultiplier = numberOfDays;

        storageSavingsModifierKeys.push(
          MULTI_BAG_REDUCE_STORAGE_RATES_MODIFIER_KEY,
          MULTI_DAY_REDUCE_STORAGE_RATES_MODIFIER_KEY,
        );

        break;
      }
      case WALK_IN_HOURLY_RATE_PRODUCT_KEY:
      case GUEST_HOURLY_RATE_PRODUCT_KEY:
      case STORAGE_HOUR_PRODUCT_KEY: {
        const isPricing = !isFinal && value.units === 0;
        const pricingModelLabel = this.text.PAY_AS_YOU_GO.toLowerCase();
        const storageLabel = this.text.PRICE_STORAGE;
        const numberOfHours = value.units > 0 ? value.units / numberOfBags : 1;
        const hoursLabel = (numberOfHours === 1 ? this.text.HOUR : this.text.HOURS).toLowerCase();
        const periodLabel = `${numberOfHours} ${hoursLabel}`;

        const minBagsEnabled = isDropOff
          ? appSettings.IS_DROP_OFF_MIN_BAGS_ENABLED
          : appSettings.IS_BOOKING_MIN_BAGS_ENABLED;
        const minBags = isDropOff ? appSettings.DROP_OFF_MIN_BAGS : appSettings.BOOKING_MIN_BAGS;

        orderLine.product.name = `${storageLabel}, ${isPricing ? pricingModelLabel : periodLabel}`;
        orderLine.product.unitSingular = 'BAG';
        orderLine.product.unitPlural = 'BAGS';
        orderLine.showQuantity = !isFinal || value.finalPrice > 0;
        orderLine.showQuantityAsUpTo = minBagsEnabled && numberOfBags <= minBags ? true : false;
        orderLine.priority = ORDER_LINE_PRIORITY_STORAGE;

        if (orderLine.showQuantityAsUpTo && numberOfBags <= minBags) {
          const storeUpTo = this.text.STORE_UP_TO;
          const bags = `${minBags} ${this.text.BAGS.toLowerCase()}`;
          const atThisRate = this.text.AT_THIS_RATE.toLowerCase();
          const noBookingsLessThan = this.text.STORAGE_LOCATION_MIN_X_BAGS;

          orderLine.product.info = {
            header: `${storeUpTo} ${bags} ${atThisRate}`,
            body: `${noBookingsLessThan} ${bags}`,
          };
        }

        if (isCancellation && orderLine.quantity === 0) {
          orderLine.optional = true;
        }

        if (isPricing) {
          // For hourly bookings that are not completed, we show the hourly rate as pricing
          orderLine.quantity = numberOfBags;
          orderLine.isPricing = true;
          orderLine.pricingSuffix = this.text.PER_HOUR_SHORT;
        } else {
          // HACK: For completed hourly bookings, we get e.g. 6 units if the traveler stored 2 bags for 3 hours
          orderLine.quantity = value.units > 0 ? value.units / numberOfHours : 0;
          orderLine.unitPrice *= numberOfHours;

          savingsOrderLineUnitPriceMultiplier = numberOfHours;
        }

        storageSavingsModifierKeys.push(
          MULTI_BAG_REDUCE_STORAGE_RATES_MODIFIER_KEY,
          MULTI_DAY_REDUCE_STORAGE_RATES_MODIFIER_KEY,
        );

        break;
      }
      case WALK_IN_INSURANCE_FEE_PRODUCT_KEY:
      case GUEST_INSURANCE_FEE_PRODUCT_KEY:
      case GUEST_PREMIUM_INSURANCE_FEE_PRODUCT_KEY:
      case PREMIUM_INSURANCE_PRODUCT_KEY:
      case INSURANCE_PRODUCT_KEY: {
        if (!product) {
          // No insurance product found, so we don't show insurance on the order summary
          return null;
        }
        const coverageAmount = this.pricingService.format(product.value, order.productList.currency, 2, true);
        const coverageLabel = `${this.text.COVERAGE} ${this.text.PER_BAG}`;

        const isPremium = this.isPremiumInsurance(order.orderRequest.addOns);

        orderLine.product.name = isPremium ? this.text.PREMIUM_INSURANCE : this.text.INSURANCE;
        orderLine.product.info = {
          header: orderLine.product.name,
          body: `${coverageAmount} ${coverageLabel}`,
        };
        orderLine.optional = true;
        // If the server side order includes insurance as an order line, it means insurance was selected
        orderLine.selected = isCancellation ? orderLine.quantity > 0 : true;
        orderLine.priority = ORDER_LINE_PRIORITY_INSURANCE;

        break;
      }
      case DISCOUNT_PRODUCT_KEY: {
        const discount = await this.promoCodeService.checkPromoCode(order.orderRequest.discountCode);

        orderLine.product.name = 'PRICE_DISCOUNT';
        orderLine.product.info = {
          header: order.orderRequest.discountCode,
          body: this.discountTransformerService.transform(discount, numberOfBags, false),
        };
        orderLine.priority = ORDER_LINE_PRIORITY_PROMO_DISCOUNT;

        break;
      }
      case TIP_PRODUCT_KEY: {
        orderLine.product.name = product?.tags.find((t) => t.key === 'displayName')?.value || 'PRICE_TIP';
        orderLine.priority = ORDER_LINE_PRIORITY_TIPS;

        break;
      }
      case CANCELLATION_FEE_PRODUCT_KEY: {
        if (isCancellation && orderLine.quantity === 0) {
          // Only show cancellation fee on order summary when one or more units are actually included in the order
          return null;
        }

        orderLine.product.name = 'CANCELLATION_FEE';
        orderLine.product.unitSingular = 'BAG';
        orderLine.product.unitPlural = 'BAGS';
        orderLine.showQuantity = true;
        orderLine.showUnitPrice = true;

        if (orderLine.unitPrice > 0) {
          const bodyPart1 = this.text.CANCELLATION_FEE_EXPLANATION_PART_1;
          const bodyPart2 = this.text.CANCELLATION_FEE_EXPLANATION_PART_2;
          orderLine.product.info = {
            header: 'SAME_DAY_CANCELLATIONS',
            body: `${bodyPart1}<br><br>${bodyPart2}`,
          };
        }
        break;
      }
      default: {
        // Fall back to order line name if we don't find a product with display name and other product info
        orderLine.product.name = product
          ? product.tags?.find((t) => t.key === 'displayName')?.value || product.name
          : value.name;

        const header = product?.tags?.find((t) => t.key === 'infoHeader')?.value;
        const body = product?.tags?.find((t) => t.key === 'infoBody')?.value;

        if (body) {
          orderLine.product.info = { header, body };
        }
        orderLine.priority = ORDER_LINE_PRIORITY_OTHER;

        break;
      }
    }

    if (storageSavingsModifierKeys.length > 0) {
      modifiedPrice?.forEach((modifier) => {
        // Check if this modification is a known discount on storage that we want to display explicitly to the user
        if (storageSavingsModifierKeys.includes(modifier.priceModifierKey)) {
          // Get the savings from the modifier and calculate savings per order line unit
          const modifierSavings = -modifier.priceDelta;
          const savingsPerUnit = modifierSavings * savingsOrderLineUnitPriceMultiplier;

          // Add savings to the original order line as we will deduct them on a separate line
          orderLine.unitPrice += savingsPerUnit;

          const percentageSavings = Math.round((modifierSavings / (modifier.unitPrice + modifierSavings)) * 100);
          const youAreSaving = this.text.YOU_ARE_SAVING_X_ON_ADDITIONAL_DAYS_PART_1;

          // Add separate order line representing the savings
          orderLines.push({
            product: {
              name: modifier.priceModifierKey,
              info: {
                header: 'DISCOUNT_APPLIED',
                body: `${youAreSaving} ${percentageSavings}%`,
              },
            },
            priority: orderLine.priority + 1, // Place immediately after original order line
            unitPrice: -savingsPerUnit,
            quantity: orderLine.quantity,
            isPricing: orderLine.isPricing,
            pricingSuffix: orderLine.pricingSuffix,
          });
        }
      });
    }

    return orderLines;
  }

  private calculateOrderLineTotal(productId: string, order: LegacyOrder): number {
    const orderLine = order.orderLines.find((ol) => ol.product._id === productId);
    return orderLine ? Math.round(orderLine.unitPrice * orderLine.quantity * 100) / 100 : 0;
  }

  private createFreeCancellationOrderLine(order: Order, isCancelled = false): LegacyOrderLine {
    const endOfToday = moment().endOf('day');
    const startOfCheckinDay = moment(order.orderRequest.from).startOf('day');
    const isAdvanceBooking = !isCancelled && endOfToday.isBefore(startOfCheckinDay);
    const isFreeCancellationOrder =
      order.orderLines.find((ol) => ol.productKey === CANCELLATION_FEE_PRODUCT_KEY) && order.total === 0 ? true : false;

    if (!Config.isProduction) {
      console.log(`Cancellation debug info`, {
        isFreeCancellationOrder,
        isAdvanceBooking,
        endOfToday,
        startOfCheckinDay,
      });
    }

    if (!isAdvanceBooking && !isFreeCancellationOrder) {
      return null;
    }

    const orderLine: LegacyOrderLine = {
      isPricing: false,
      product: {
        _id: 'freeCancellation',
        name: 'FREE_CANCELLATION',
        info: {
          header: 'FREE_CANCELLATION',
          body: 'CANCEL_UNTIL_MIDNIGHT_EXPLANATION',
        },
      },
      quantity: 1,
      unitPrice: 0,
      selected: isAdvanceBooking || isFreeCancellationOrder,
      priority: ORDER_LINE_PRIORITY_FREE_CANCELLATION,
    };

    return orderLine;
  }

  private getInsuranceKey = (addOns: {
    insurance: boolean;
    insuranceKey?: string;
    walkIn?: boolean;
    guest?: boolean;
  }) => {
    if (addOns.walkIn) {
      return WALK_IN_INSURANCE_FEE_PRODUCT_KEY;
    }
    if (addOns.guest) {
      const insuranceKey = addOns.insuranceKey ?? '';
      if (insuranceKey === GUEST_PREMIUM_INSURANCE_FEE_PRODUCT_KEY) {
        return GUEST_PREMIUM_INSURANCE_FEE_PRODUCT_KEY;
      }
      return GUEST_INSURANCE_FEE_PRODUCT_KEY;
    }
    return INSURANCE_PRODUCT_KEY;
  };

  private createInsuranceOrderLine(order: Order): LegacyOrderLine {
    const insuranceProduct = order.productList.products.find(
      (p) => p.key === this.getInsuranceKey(order.orderRequest.addOns),
    );

    if (!insuranceProduct) {
      // This shouldn't happen

      console.log('returning empty insurance order line');
      return null;
    }

    const coverageAmount = this.pricingService.format(insuranceProduct.value, order.productList.currency, 2, true);
    const coverageLabel = `${this.text.COVERAGE} ${this.text.PER_BAG}`;

    const orderLine: LegacyOrderLine = {
      isPricing: false,
      product: {
        _id: insuranceProduct.key,
        name: 'INSURANCE',
        info: {
          header: this.text.INSURANCE,
          body: `${coverageAmount} ${coverageLabel}`,
        },
      },
      quantity: order.orderRequest.bags,
      unitPrice: insuranceProduct.cost,
      optional: true,
      selected: false,
      showQuantity: false,
      showUnitPrice: false,
      priority: ORDER_LINE_PRIORITY_INSURANCE,
    };

    return orderLine;
  }

  private async createDiscountOrderLine(order: Order): Promise<LegacyOrderLine> {
    const discount = await this.promoCodeService.checkPromoCode(order.orderRequest.discountCode);
    const numberOfBags = order.orderRequest.bags;

    const discountValue = this.getDiscountValue(order, discount);

    if (!discountValue) {
      return null;
    }

    const orderLine: LegacyOrderLine = {
      product: {
        _id: DISCOUNT_PRODUCT_KEY,
        name: 'PRICE_DISCOUNT',
        info: {
          header: discount.code,
          body: this.discountTransformerService.transform(discount, numberOfBags, false),
        },
      },
      unitPrice: -(discountValue / numberOfBags),
      quantity: numberOfBags,
      isRelative: order.orderRequest.pricingModel === 'hourly' && discount.type === 'percentage',
      isPricing: order.orderRequest.pricingModel === 'hourly' && discount.type === 'percentage',
      pricingSuffix: discount.type === 'percentage' ? '%' : null,
      priority: ORDER_LINE_PRIORITY_PROMO_DISCOUNT,
    };

    const appSettings = await firstValueFrom(this.appSettingsService.$current);

    switch (discount.code) {
      case appSettings.DEFAULT_AUTO_GROUP_DISCOUNT_CODE:
        orderLine.product.info.body += `. ${this.text.GROUP_DISCOUNT_APPLIES}.`;
        break;
    }

    return orderLine;
  }

  private getDiscountValue(order: Order, discount: IDiscount): number {
    const numberOfBags = order.orderRequest.bags;
    const maxBagsForDiscount = discount.maxBags || numberOfBags;

    if (order.finalised || order.orderRequest.pricingModel === 'daily') {
      const discountFromOrderLines =
        order.orderLines.find((ol) => ol.productKey === DISCOUNT_PRODUCT_KEY)?.finalPrice || 0;

      return discountFromOrderLines;
    }

    let discountValue = discount.value;

    if (discount.type === 'hours') {
      const hourlyRateProductKeys: string[] = [
        STORAGE_HOUR_PRODUCT_KEY,
        WALK_IN_HOURLY_RATE_PRODUCT_KEY,
        GUEST_HOURLY_RATE_PRODUCT_KEY,
      ];

      const hourlyRate = order.orderLines.find((p) => hourlyRateProductKeys.includes(p.productKey))?.unitPrice || null;

      if (hourlyRate) {
        discountValue = discount.value * hourlyRate * Math.min(maxBagsForDiscount, numberOfBags);
      }
    }

    return discountValue;
  }
}
