import { ChangeDetectorRef, Component } from '@angular/core';
import { SharedBookingService } from '@luggagehero/shared/services/bookings';
import { SharedShopsService } from '@luggagehero/shared/services/shops';
import { SharedStorageService } from '@luggagehero/shared/services/storage';

import { BookingActionBaseComponent } from './booking-action.base-component';

@Component({ template: '' })
export abstract class LeaveFeedbackActionBaseComponent extends BookingActionBaseComponent {
  constructor(
    bookingService: SharedBookingService,
    shopsService: SharedShopsService,
    storageService: SharedStorageService,
    cd: ChangeDetectorRef,
  ) {
    super(bookingService, shopsService, storageService, cd);
  }

  get shopName(): string {
    if (!this.shop) {
      return null;
    }
    return this.shop.name;
  }

  async onBaseInit() {
    this.shop = await this.shopsService.getShop(this.booking.shopId);
  }
}
