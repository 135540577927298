import { IWindow } from '@luggagehero/shared/interfaces';
import { Observable, Observer } from 'rxjs';

export class SharedUtilWindow {
  public static waitForValue(win: IWindow, key: string): Observable<string> {
    return new Observable<string>((observer) => {
      this.completeOnValue(win, key, observer);
    });
  }

  public static waitForClose(win: IWindow): Observable<IWindow> {
    return new Observable<IWindow>((observer) => {
      this.completeOnClose(win, observer);
    });
  }

  public static completeOnValue(win: IWindow, key: string, observer: Observer<string | undefined>) {
    const value = window.localStorage.getItem(key);
    if (value) {
      window.localStorage.removeItem(key);
      observer.next(value);
      observer.complete();
      return;
    }
    if (win.closed) {
      observer.next(undefined);
      observer.complete();
      return;
    }
    setTimeout(() => this.completeOnValue(win, key, observer), 100);
  }

  public static completeOnClose(win: IWindow, observer: Observer<IWindow>) {
    if (win.closed) {
      observer.next(win);
      observer.complete();
      return;
    }
    setTimeout(() => this.completeOnClose(win, observer), 100);
  }
}
