import { ChangeDetectionStrategy, Component, forwardRef, Provider } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { LocationPickerBaseComponent } from '@luggagehero/features/ui';

export const LOCATION_PICKER_CONTROL_VALUE_ACCESSOR: Provider = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => LocationPickerComponent),
  multi: true,
};

@Component({
  selector: 'lh-location-picker',
  templateUrl: './location-picker.component.html',
  styleUrls: ['./location-picker.component.scss'],
  providers: [LOCATION_PICKER_CONTROL_VALUE_ACCESSOR],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LocationPickerComponent extends LocationPickerBaseComponent {}
