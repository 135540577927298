import { Injectable } from '@angular/core';

import { UserAuthenticationBaseComponent } from '../user-authentication.base-component';
import { UserAuthenticationStrategy } from './user-authentication-strategy';

@Injectable()
export class UserAuthenticationBaseStrategy implements UserAuthenticationStrategy {
  protected parent: UserAuthenticationBaseComponent;

  public get isResetPasswordVisible(): boolean {
    return false;
  }

  public get isResendOtpAllowed(): boolean {
    return false;
  }

  public get otpResendDelay(): number {
    return 0;
  }

  public continue(): Promise<void> {
    throw new Error('Operation not supported by this strategy');
  }

  public logIn(): Promise<void> {
    throw new Error('Operation not supported by this strategy');
  }

  public register(): Promise<void> {
    throw new Error('Operation not supported by this strategy');
  }

  public resetPassword(): Promise<void> {
    throw new Error('Operation not supported by this strategy');
  }

  public onAuthenticationFailed(): void {
    throw new Error('Operation not supported by this strategy');
  }

  public init(parent: UserAuthenticationBaseComponent) {
    this.parent = parent;
  }
}
