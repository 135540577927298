import { Injectable, Renderer2 } from '@angular/core';
import { IPayout, IPayoutData } from '@luggagehero/shared/interfaces';
import { SharedPricingService } from '@luggagehero/shared/services/pricing';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { mergeMap } from 'rxjs/operators';

import { ScriptService } from './script.service';
import { ManageableStorageLocation } from './storage-manager/storage-location';

interface Company {
  name: string;
  address: string;
  vatNumber?: string;
}

// TODO: Move values to config and get from there
const DK_COMPANY: Company = {
  name: `LuggageHero ApS`,
  address: 'Erik Menveds Vej 2A, DK-1965 Copenhagen',
  vatNumber: 'DK37611328',
};
const US_COMPANY: Company = {
  name: `LuggageHero, LLC`,
  address: '137 W 25th St., 11th floor, New York 10001',
};
const DATE_FORMAT = 'YYYYMMDD';
const LOGO_URL = 'assets/logo_light_bg.png';

declare let pdfMake: {
  createPdf: (_doc) => {
    open: () => void;
    print: () => void;
    download: (filename: string) => void;
  };
};

@Injectable()
export class InvoiceService {
  private logoDataURL: string;
  private isInitialized = false;

  public get isReady(): boolean {
    return this.logoDataURL ? true : false;
  }

  constructor(
    private priceService: SharedPricingService,
    private script: ScriptService,
    private translate: TranslateService,
  ) {}

  public init(renderer: Renderer2, _doc: unknown) {
    if (this.isInitialized) {
      return;
    }
    this.isInitialized = true;

    // Load logo as data URL
    const img = new Image();
    img.crossOrigin = 'Anonymous';
    img.onload = () => {
      const canvas = <HTMLCanvasElement>renderer.createElement('CANVAS');
      canvas.height = img.height;
      canvas.width = img.width;

      const ctx = canvas.getContext('2d');
      ctx.drawImage(img, 0, 0);

      this.logoDataURL = canvas.toDataURL('image/png');
    };
    img.src = LOGO_URL;

    // Load pdfmake first and then vfs_fonts
    this.script
      .load('pdfmake')
      .pipe(mergeMap((_res) => this.script.load('vfs_fonts')))
      .subscribe();
  }

  public open(payout: IPayout, shop: ManageableStorageLocation) {
    const doc = this.getDocDefinition(payout, shop);
    const pdf = pdfMake.createPdf(doc);
    pdf.open();
  }

  public print(payout: IPayout, shop: ManageableStorageLocation) {
    const doc = this.getDocDefinition(payout, shop);
    const pdf = pdfMake.createPdf(doc);
    pdf.print();
  }

  public download(payout: IPayout, shop: ManageableStorageLocation) {
    const doc = this.getDocDefinition(payout, shop);
    const pdf = pdfMake.createPdf(doc);
    pdf.download(doc.info.title);
  }

  public getDocDefinition(
    payout: IPayout,
    shop: ManageableStorageLocation,
  ): {
    info: Record<string, string>;
    content: unknown;
    pageSize: string;
  } {
    return {
      info: this.getDocInfo(payout, shop),
      content: this.getDocContent(payout, shop),
      pageSize: 'A4',
    };
  }

  public getDocInfo(payout: IPayout, _shop: ManageableStorageLocation): Record<string, string> {
    const date = moment(payout.created).format('YYYY-MM-DD');
    return {
      title: `[${date}] LuggageHero - invoice - ${payout.invoiceNumber}`,
      author: 'LuggageHero',
      creator: 'LuggageHero',
      producer: 'LuggageHero',
      subject: `LuggageHero invoice ${payout.invoiceNumber}`,
      keywords: `LuggageHero invoice ${payout.invoiceNumber}`,
    };
  }

  public getDocContent(payout: IPayout, shop: ManageableStorageLocation): unknown {
    return [
      this.getHeader(payout, shop),
      this.getSales(payout, shop),
      this.getBalance(payout, shop),
      this.getFooter(payout, shop),
    ];
  }

  public getHeader(payout: IPayout, shop: ManageableStorageLocation): Array<Record<string, unknown>> {
    return [
      {
        text: (this.translate.instant('INVOICE') as string).toUpperCase(),
        bold: true,
        margin: [0, 0, 0, 20],
      },
      {
        image: this.logoDataURL,
        width: 120,
        alignment: 'right',
      },
      {
        layout: 'noBorders',
        table: {
          widths: ['*', 'auto', 'auto'],
          margin: [0, 30, 0, 0],
          body: [
            [{ text: `${this.translate.instant('BILL_TO')}: `, bold: true }, '', ''],
            [
              this.getBillTo(shop),
              { text: `${this.translate.instant('INVOICE_NUMBER')}:`, alignment: 'right' },
              { text: payout.invoiceNumber, alignment: 'right' },
            ],
            [
              this.getShopAddress(shop),
              { text: `${this.translate.instant('INVOICE_DATE')}:`, alignment: 'right' },
              { text: new Date(payout.created).toLocaleDateString(), alignment: 'right' },
            ],
          ],
        },
      },
    ];
  }

  public getSales(payout: IPayout, shop: ManageableStorageLocation): unknown {
    const body = [];
    const showVat = !shop.region.match(/us/i);
    const commission = payout.data.serviceFee || 0;
    const totalServiceFees = (payout.data.paymentProcessingFee || 0) + (payout.data.securityTagFee || 0);

    let commissionText = this.translate.instant('PAYOUT_INVOICE_COMMISSION') as string;

    let vatText = '';
    if (commission > 0) {
      // const feePercentage = this.getServiceFeePercentage(payout.data);
      // const ofTotalText = this.translate.instant('OF_TOTAL_SALES') as string;
      let excludingVATText = '';
      if (showVat) {
        vatText += `${this.translate.instant('PAYOUT_INVOICE_SERVICE_FEE_VAT')} (${this.getServiceVatPercentage(
          payout.data,
        )})`;
        excludingVATText = this.translate.instant('PAYOUT_INVOICE_EXCL_VAT') as string;
      }
      // commissionText += ` ${excludingVATText} (${feePercentage} ${ofTotalText})`;
      commissionText += ` ${excludingVATText}`;
    }

    body.push([commissionText, { text: this.formatPrice(commission, payout.currency), alignment: 'right' }]);
    if (totalServiceFees > 0) {
      let serviceFeeText = `${this.translate.instant('PAYOUT_SERVICE_FEE')}`;
      if (showVat) {
        serviceFeeText += ` ${this.translate.instant('PAYOUT_INVOICE_EXCL_VAT')}`;
      }
      body.push([
        serviceFeeText,
        { text: `${this.formatPrice(totalServiceFees, payout.currency)}`, alignment: 'right' },
      ]);
    }
    if (showVat) {
      body.push([vatText, { text: this.formatPrice(payout.data.vat, payout.currency), alignment: 'right' }]);
    }

    const totalCostOfService = this.getTotalCostOfService(payout);
    const totalRevenue = this.formatPrice(payout.data.totalRevenue, payout.currency);
    let totalTips = '';

    if (payout.data.totalTips > 0) {
      totalTips = ` ${this.translate.instant('AND')} ${this.formatPrice(
        payout.data.totalTips,
        payout.currency,
      )} ${(this.translate.instant('TIPS') as string).toLowerCase()}`;
    }

    const payoutPeriod = this.getPeriod(payout);

    let totalText = this.translate.instant('PAYOUT_INVOICE_TOTAL') as string;
    if (showVat) {
      totalText += ` ${this.translate.instant('PAYOUT_INVOICE_FEES_PLUS_VAT')}`;
    }
    body.push([
      { text: totalText, bold: true, line: true },
      { text: this.formatPrice(totalCostOfService, payout.currency), bold: true, alignment: 'right' },
    ]);
    return [
      {
        text: `${this.translate.instant('TOTAL_SALES_OF')} ${totalRevenue}${totalTips} ${this.translate.instant(
          'PAYOUT_INVOICE_ON_BEHALF_OF_YOUR_CUSTOMERS',
        )}`,
        bold: true,
        margin: [0, 50, 0, 0],
      },
      {
        text: `${this.translate.instant('PAYOUT_INVOICE_IN_THE_PERIOD')} ${payoutPeriod}`,
        bold: true,
        margin: [0, 0, 0, 0],
      },
      {
        text: `${this.translate.instant('FOR_FACILITATING_SALES_IN_SAME_PERIOD')}:`,
        bold: true,
        margin: [0, 50, 0, 0],
      },
      this.getHorizontalLine(),
      {
        layout: 'noBorders',
        table: {
          widths: ['*', 'auto'],
          margin: [0, 0, 0, 0],
          body: body,
        },
      },
      this.getHorizontalLine(),
    ];
  }

  public getTotalSales(payout: IPayout, _shop: ManageableStorageLocation): unknown {
    const body = [[this.translate.instant('PAYOUT_INVOICE_TOTAL_SALES'), '']];
    const totalSales = [];
    if (payout.data.inShopRevenue > 0) {
      const onlineAmount = this.formatPrice(payout.data.onlineRevenue, payout.currency, true);
      const inShopAmount = this.formatPrice(payout.data.inShopRevenue, payout.currency, true);
      const onlineText = this.translate.instant('PAID_VIA_LUGGAGGEHERO') as string;
      const inShopText = this.translate.instant('PAID_DIRECTLY_TO_YOU') as string;
      totalSales.push(`(${onlineAmount} ${onlineText} + ${inShopAmount} ${inShopText})`);
    } else {
      totalSales.push(`(${this.translate.instant('PAID_VIA_LUGGAGGEHERO')})`);
    }

    totalSales.push({
      text: this.formatPrice(payout.data.totalRevenue, payout.currency),
      alignment: 'right',
      bold: true,
    });
    body.push(totalSales);
    body.push(['', this.getHorizontalLine()]);

    return {
      layout: 'noBorders',
      table: {
        widths: ['*', 'auto'],
        body: body,
      },
    };
  }

  public getBalance(payout: IPayout, _shop: ManageableStorageLocation): unknown {
    const balanceText = this.translate.instant('PAYOUT_INVOICE_FINAL_BALANCE_DUE') as string;
    const body = [];

    // Add total revenue in the period
    body.push([
      this.translate.instant('PAYOUT_INVOICE_AMOUNT_RECEIVABLE'),
      { text: this.formatPrice(payout.data.totalRevenue, payout.currency), alignment: 'right' },
    ]);

    // Add amount received directly
    if (payout.data.inShopRevenue > 0 || payout.data.paidDirectly > 0) {
      let inShopRevenue = payout.data.inShopRevenue;
      if (payout.data.paidDirectly && payout.data.paidDirectly > 0) {
        // inShopRevenue is the old one and only includes storage amount, paidDirectly is the new one and includes storage amount + other services
        inShopRevenue = payout.data.paidDirectly;
        if (payout.data.inShopTips > 0) {
          inShopRevenue = inShopRevenue - payout.data.inShopTips;
        }
      }

      body.push([
        this.translate.instant('PAYOUT_INVOICE_AMOUNT_RECEIVED_DIRECTLY'),
        { text: `- ${this.formatPrice(inShopRevenue, payout.currency)}`, alignment: 'right' },
      ]);
    }

    // Add LuggageHero fee
    const totalCostOfService = this.getTotalCostOfService(payout);
    body.push([
      this.translate.instant('PAYOUT_INVOICE_AMOUNT_OWED_FOR_SERVICE'),
      { text: `- ${this.formatPrice(totalCostOfService, payout.currency)}`, alignment: 'right' },
    ]);

    if (payout.data.totalTips > 0) {
      if (payout.data.inShopTips > 0) {
        body.push([
          this.translate.instant('IN_SHOP_TIPS'),
          {
            text: this.formatPrice(payout.data.inShopTips * -1, payout.currency),
            alignment: 'right',
            bold: false,
          },
        ]);
      }

      body.push([
        { text: this.translate.instant('TOTAL_TIPS') as string, bold: true, line: true },
        {
          text: this.formatPrice(payout.data.totalTips, payout.currency),
          alignment: 'right',
          bold: true,
        },
      ]);
    }

    // Add previous balance line
    if (payout.data.previousBalance) {
      body.push([
        this.translate.instant('PAYOUT_INVOICE_PREVIOUS_BALANCE'),
        { text: `${this.formatPrice(payout.data.previousBalance, payout.currency)}`, alignment: 'right' },
      ]);
    }

    // Add a line for each transaction
    if (payout.data.transactions) {
      payout.data.transactions.forEach((t) => {
        body.push([t.description, { text: this.formatPrice(t.amount, payout.currency), alignment: 'right' }]);
      });
    }

    // Add final balance
    body.push([
      { text: balanceText, bold: true, line: true },
      {
        text: this.formatPrice(payout.data.balance, payout.currency),
        bold: true,
        alignment: 'right',
      },
    ]);

    // Add undeducted TAX
    if (payout.data.taxes != null && payout.data.taxes > 0) {
      body.push([
        this.translate.instant('PAYOUT_UNDEDUCTED_TAX'),
        { text: `(${this.formatPrice(payout.data.taxes, payout.currency)})`, alignment: 'right' },
      ]);
    }

    let footer = '';
    if (payout.data.balance > 0) {
      const fundsAvailableDate = moment(payout.created).add(8, 'days').toDate().toLocaleDateString();
      footer = `${this.translate.instant('THE_FUNDS_ARE_AVAILABLE_ON')} ${fundsAvailableDate}`;
    } else if (payout.data.balance < 0) {
      footer = `${this.translate.instant('THE_BALANCE_WILL_BE_TRANSFERRED_TO_NEXT_BILL')}`;
    }
    return [
      {
        text: `${this.translate.instant('YOU_ARE_OWED')}:`,
        bold: true,
        margin: [0, 30, 0, 0],
      },
      this.getHorizontalLine(),
      {
        layout: 'noBorders',
        table: {
          widths: ['*', 'auto'],
          margin: [0, 0, 0, 0],
          body: body,
        },
      },
      this.getHorizontalLine(),
      footer,
    ];
  }

  public getFooter(payout: IPayout, shop: ManageableStorageLocation): unknown {
    const company = shop.region.match(/us/i) ? US_COMPANY : DK_COMPANY;
    let text = `${company.name} | ${company.address}`;
    if (company.vatNumber) {
      text += ` | ${this.translate.instant('VAT_NUMBER')}: ${company.vatNumber}`;
    }
    return {
      text: text,
      alignment: 'center',
      margin: [0, 50, 0, 0],
    };
  }

  public getHorizontalLine(): unknown {
    return {
      table: {
        widths: ['*'],
        body: [[''], ['']],
      },
      layout: 'lightHorizontalLines',
      margin: [0, 10, 0, 10],
    };
  }

  public getBillTo(shop: ManageableStorageLocation): string {
    if (shop.vatNumber) {
      return `${shop.name} | ${this.translate.instant('VAT_NUMBER')}: ${shop.vatNumber}`;
    }
    return shop.name;
  }

  public getShopAddress(shop: ManageableStorageLocation): string {
    return shop.address.formattedAddress.replace('Storbritannien', 'United Kingdom');
  }

  public getShopVat(data: IPayoutData): number {
    const vat = (data.totalRevenue - data.serviceFee - data.securityTagFee - data.vat) * 0.2;
    return Math.round(vat * 100) / 100;
  }

  public getServiceFeePercentage(data: IPayoutData): string {
    return this.formatPercentage(data.serviceFee, data.totalRevenue);
  }

  public getServiceVatPercentage(data: IPayoutData): string {
    let paymentProcessingFees = 0;
    if (data.paymentProcessingFee && data.paymentProcessingFee > 0) {
      paymentProcessingFees = data.paymentProcessingFee;
    }
    return this.formatPercentage(data.vat, data.serviceFee + data.securityTagFee + paymentProcessingFees);
  }

  public getPeriod(payout: IPayout) {
    const dateLength = DATE_FORMAT.length;
    const keyParts = payout.key.split('-');
    if (keyParts.length !== 2) {
      return payout.key;
    }
    let from = keyParts[0],
      to = keyParts[1];
    if (from.length !== dateLength || to.length !== dateLength) {
      return payout.key;
    }
    from = moment(from, DATE_FORMAT).format('L');
    to = moment(to, DATE_FORMAT).format('L');
    return `${from} - ${to}`;
  }

  public formatPercentage(dividend: number, divisor: number): string {
    const percentage = (dividend / divisor) * 100;
    return `${Math.round(percentage).toFixed(0)}%`;
  }

  public formatPrice(price: number, currency: string, removeZeroDecimals = false) {
    return this.priceService.format(price, currency, 2, removeZeroDecimals);
  }

  private getTotalCostOfService(payout: IPayout): number {
    let paymentProcessingFee = 0;
    if (payout.data.paymentProcessingFee && payout.data.paymentProcessingFee > 0) {
      paymentProcessingFee = payout.data.paymentProcessingFee;
    }
    const totalCostOfService =
      payout.data.serviceFee + payout.data.securityTagFee + payout.data.vat + paymentProcessingFee;

    return totalCostOfService;
  }
}
