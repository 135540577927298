import { Injectable } from '@angular/core';
import { SharedErrorService } from '@luggagehero/shared/services/error';
import { SharedNotificationService } from '@luggagehero/shared/services/notification';
import { SharedStorageService } from '@luggagehero/shared/services/storage';
import { AuthPassthroughError, SharedUserService } from '@luggagehero/shared/services/users';
import { SharedWindowService } from '@luggagehero/shared/services/window';
import { TranslateService } from '@ngx-translate/core';

import { StringUtil } from '../../../../../utils';
import { UserInfo } from '../user-authentication.base-component';
import { UserAuthenticationBaseStrategy } from './user-authentication-base-strategy';

// Delay before allowing resend of one-time passwords
const OTP_RESEND_DELAY_MS = 59 * 1000;

@Injectable()
export class PhoneOtpStrategy extends UserAuthenticationBaseStrategy {
  constructor(
    private userService: SharedUserService,
    private translate: TranslateService,
    private storage: SharedStorageService,
    private notify: SharedNotificationService,
    private error: SharedErrorService,
    private window: SharedWindowService,
  ) {
    super();
  }

  public get isResendOtpAllowed(): boolean {
    if (!this.lastOtpRequest) {
      return true;
    }
    if (this.user.phone !== this.lastOtpRecipient) {
      return true;
    }
    const msPassedSinceLastOtpRequest = new Date().getTime() - this.lastOtpRequest;
    return msPassedSinceLastOtpRequest > OTP_RESEND_DELAY_MS;
  }

  public get otpResendDelay(): number {
    if (!this.lastOtpRequest) {
      return 0;
    }

    const msPassedSinceLastOtpRequest = new Date().getTime() - this.lastOtpRequest;
    const secsRemaining = (OTP_RESEND_DELAY_MS - msPassedSinceLastOtpRequest) / 1000;

    return Math.round(secsRemaining);
  }

  private get lastOtpRecipient(): string {
    return this.storage.lastOtpRecipient;
  }
  private set lastOtpRecipient(value: string) {
    this.storage.lastOtpRecipient = value;
  }

  private get lastOtpRequest(): number {
    return this.storage.lastOtpRequest;
  }
  private set lastOtpRequest(value: number) {
    this.storage.lastOtpRequest = value;
  }

  private get user(): UserInfo {
    return this.parent.user;
  }

  public async continue(): Promise<void> {
    this.parent.isContinueWithPhoneButtonClicked = true;
    this.parent.form.controls.phone.markAsDirty();

    if (this.parent.phoneInputError) {
      return;
    }

    if (!this.parent.isFullFormVisible) {
      this.parent.isLoading = true;

      try {
        // this.isExistingUser = await this.userService.checkIfPhoneUserExists(this.user.phone);
        this.parent.isExistingUser = true;
        this.parent.isExistingUserChange.emit(this.parent.isExistingUser);
      } catch (error) {
        this.notify.error('Invalid phone number, please try again');
        this.parent.isLoading = false;
        return;
      }

      await this.requestOneTimePasswordViaPhone();

      this.parent.isUserPhoneSelected = true;
      this.parent.isUserPhoneSelectedChange.emit(true);

      this.parent.isFullFormVisible = true;

      this.parent.isLoading = false;
      return;
    }

    // Remove focus from the code input field
    // this.oneTimePasswordInput.nativeElement.blur();

    // Start the login process
    return this.logIn();
  }

  public async logIn(): Promise<void> {
    try {
      const response = await this.userService.loginWithPhone({
        phoneNumber: this.user.phone,
        phoneCountry: this.user.phoneCountry,
        locale: this.storage.language || this.window.locale,
        password: this.user.password,
      });
      if (response.success) {
        void this.parent.getUserAndRedirect();
      } else {
        this.error.handleError(
          response.message,
          response.message,
          this.translate.instant('LOGIN_ERROR_TITLE') as string,
        );
      }
    } catch (err) {
      this.user.password = '';
      this.parent.form.markAsPristine();
      this.parent.isFullFormVisible = false;

      if (typeof err === 'object' && 'status' in err && (err as { status?: number })?.status === 401) {
        this.onAuthenticationFailed();
      } else {
        this.error.handleError(err, StringUtil.errorToString(err));
      }
    }
  }

  private async requestOneTimePasswordViaPhone(): Promise<void> {
    if (!this.isResendOtpAllowed) {
      return;
    }
    try {
      // Request OTP to be sent to the user via SMS
      await this.userService.requestOneTimePasswordViaPhone(this.user.phone);

      this.lastOtpRequest = new Date().getTime();
      this.lastOtpRecipient = this.user.phone;

      // Make sure we show the resend option in the UI after the delay has passed
      this.runOtpRefresh();

      // setTimeout(() => this.oneTimePasswordInput.nativeElement.focus(), 100);
    } catch (err) {
      if (err instanceof AuthPassthroughError) {
        // Just log the user in without OTP validation
        await this.logIn();
      } else {
        switch ((err as { status?: number })?.status) {
          case 400: {
            // Get the validation error message if available (it should be with 400 errors from Hapi)
            const message = StringUtil.errorToString(err).replace('"phoneNumber"', '');

            //
            // Error message from Hapi validation is of the form: "\"phoneNumber\" must be a mobile phone number" so we
            // strip out the "phoneNumber" part for a more human-readable message
            //

            // Will display something like "Failed to send SMS: must be a valid mobile phone number"
            this.notify.error(`Failed to send SMS: ${message}`);

            break;
          }

          case 405: {
            // Just log the user in without OTP valiation
            await this.logIn();
            break;
          }

          case 422: {
            // The phone number is registered with multiple accounts
            this.notify.error(`Cannot sign in by phone, please use another sign-in method`);
            break;
          }

          default: {
            this.notify.error(`Sign-in failed, please try again or use another sign-in method`);
            break;
          }
        }
      }
    }
  }

  public resetPassword(): Promise<void> {
    throw new Error('Operation not supported');
  }

  public onAuthenticationFailed(): void {
    this.notify.error(`Login failed, please try again`);

    this.lastOtpRecipient = null;
    this.lastOtpRequest = null;
  }

  private runOtpRefresh() {
    setTimeout(() => {
      this.parent.markForCheck();

      if (this.otpResendDelay > 0) {
        this.runOtpRefresh();
      }
    }, 1000);
  }
}
