import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import {
  BookableStorageLocation,
  CreateBookingParams,
  PricingModel,
  StorageCriteria,
} from '@luggagehero/shared/interfaces';
import { SharedBookingService } from '@luggagehero/shared/services/bookings';
import { SharedPricingService } from '@luggagehero/shared/services/pricing';
import { SharedShopsService } from '@luggagehero/shared/services/shops';
import { SharedStorageCriteriaService } from '@luggagehero/shared/services/storage-criteria';
import { SharedUserService } from '@luggagehero/shared/services/users';
import { Subscription } from 'rxjs';

import { StepBaseComponent } from '../../../../ui';

@Component({ template: '' })
export abstract class DropoffStepBaseComponent extends StepBaseComponent implements OnInit, OnDestroy {
  protected bookingService = inject(SharedBookingService);
  protected storageLocationService = inject(SharedShopsService);
  protected criteriaService = inject(SharedStorageCriteriaService);
  protected priceService = inject(SharedPricingService);
  protected userService = inject(SharedUserService);

  protected subscriptions: Subscription[] = [];

  public get isLoggedIn(): boolean {
    return this.userService.isLoggedIn;
  }

  public get isZeroPaymentBooking(): boolean {
    return this.bookingService.bookingDraft?.order?.total === 0 ? true : false;
  }

  public get bookingDraft(): Partial<CreateBookingParams> {
    return this.bookingService.bookingDraft;
  }
  public set bookingDraft(value: Partial<CreateBookingParams>) {
    this.bookingService.bookingDraft = value;
  }

  public get criteria(): StorageCriteria {
    return this.criteriaService.current;
  }
  public set criteria(value: StorageCriteria) {
    this.criteriaService.current = value;
  }

  public get pricingModel(): PricingModel {
    return this.priceService.pricingModel;
  }
  public set pricingModel(value: PricingModel) {
    this.priceService.changePricing(value);
  }

  public get storageLocation(): BookableStorageLocation {
    return this.storageLocationService.current;
  }
  public set storageLocation(value: BookableStorageLocation) {
    this.storageLocationService.setCurrent(value, true);
  }

  public get variant(): string {
    return this.storageLocation?._id;
  }

  public ngOnInit(): void {
    this.subscriptions.push(
      this.userService.loginAnnounced.subscribe(this.onLoginEvent.bind(this) as typeof this.onLoginEvent),
    );
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  protected onLoginEvent(): void {
    // Here to allow overriding in derived classes
  }
}
