import { Type } from '@angular/core';
import { AppConfig } from '@luggagehero/shared/app-settings/data-access';
import { Review } from '@luggagehero/shared/interfaces';

import { BookingActionInfo } from '../booking-actions/booking-action-info';
import { LeaveFeedbackActionBaseComponent } from '../booking-actions/leave-feedback-action.base-component';
import { BookingStep } from './booking-step';

export abstract class LeaveFeedbackBaseStep extends BookingStep {
  //
  // We only know the base action components here, so force the derived class
  // to provide the specific types
  //
  protected abstract get leaveFeedbackActionComponentType(): Type<LeaveFeedbackActionBaseComponent>;

  getIsVisible(): boolean {
    return !['CONFIRMED', 'CANCELLED'].includes(this.booking.status);
  }

  getTitle(): string {
    return this.translate('RATE_YOUR_EXPERIENCE');
  }

  getSubtitle(): string {
    if (this.getIsCompleted()) {
      return this.translate('FEEDBACK_RECEIVED');
    }
    return `${this.translate('HOW_WAS_YOUR_EXPERIENCE_WITH')} ${this.booking.shopName}?`;
  }

  getIsSubtitleHidden(): boolean {
    return ['CONFIRMED', 'CHECKED_IN', 'CHECKED_OUT'].includes(this.booking.status);
  }

  getIsActive(): boolean {
    return this.booking.status === 'PAID' && !this.isCompleted;
  }

  getIsCompleted(): boolean {
    if (!this.shop) {
      return false;
    }
    if (this.shop.externalReviewUrl && !AppConfig.IS_EXTERNAL_REVIEW_DISABLED) {
      return this.booking.feedbackReceived;
    }
    if (!this.review) {
      return false;
    }
    if (this.review.rating || this.review.feedback) {
      return true;
    }
    return false;
  }

  getIsFailed(): boolean {
    return false;
  }

  getActionInfo(): BookingActionInfo {
    if (['CONFIRMED', 'CHECKED_IN'].includes(this.booking.status)) {
      return null;
    }
    if (this.getIsCompleted()) {
      const showExternalReview = this.shop.externalReviewUrl && !AppConfig.IS_EXTERNAL_REVIEW_DISABLED ? true : false;
      return new BookingActionInfo('EditReviewFromLeaveFeedbackStep')
        .withCallToAction(this.translate(showExternalReview ? 'FEEDBACK' : 'EDIT_REVIEW'))
        .withAction({ componentToShow: this.leaveFeedbackActionComponentType })
        .withTitle(
          showExternalReview
            ? this.translate('FEEDBACK')
            : `${this.translate('YOUR_REVIEW_OF_SHOP_NAME')} ${this.booking.shopName}`,
        );
    }
    return new BookingActionInfo('LeaveFeedbackFromLeaveFeedbackStep')
      .withCallToAction(this.translate('LEAVE_FEEDBACK'))
      .withAction({ componentToShow: this.leaveFeedbackActionComponentType })
      .withTitle(this.translate('FEEDBACK'))
      .withReviewChangeHandler((r) => this.onFeedbackReceived(r));
  }

  getIsActionCollapsed(): boolean {
    return !this.previousStep.isCompleted || this.isCompleted;
  }

  private onFeedbackReceived(_review: Review) {
    void this.reloadBooking();
  }
}
