import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  inject,
  Input,
  NgZone,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { App as CapacitorApp } from '@capacitor/app';
import { Browser } from '@capacitor/browser';
import { AppConfig } from '@luggagehero/shared/app-settings/data-access';
import {
  BookableStorageLocation,
  Booking,
  InitiatePaymentResult,
  IPaymentRecord,
  IStripePaymentCard,
  IStripePaymentIntentResult,
  IStripeSetupIntentResult,
  LegacyOrder,
} from '@luggagehero/shared/interfaces';
import { SharedErrorService } from '@luggagehero/shared/services/error';
import { SharedNotificationService } from '@luggagehero/shared/services/notification';
import { SharedPaymentService } from '@luggagehero/shared/services/payments';
import { SharedStorageService, StorageTarget } from '@luggagehero/shared/services/storage';
import { SharedStripeService } from '@luggagehero/shared/services/stripe';
import { SharedUserService } from '@luggagehero/shared/services/users';
import { SharedWindowService } from '@luggagehero/shared/services/window';
import { SharedUtilWindow } from '@luggagehero/shared/util';
import { TranslateService } from '@ngx-translate/core';
import { firstValueFrom } from 'rxjs';

import { BaseModalComponent } from '../../../core';
import { PaymentCardInputBaseComponent } from './payment-card-input.base-component';

const noop = () => {
  /**/
};

@Component({ template: '' })
export abstract class PaymentMethodSelectorBaseComponent
  extends BaseModalComponent
  implements OnInit, ControlValueAccessor
{
  @ViewChild('paymentCardInput') public paymentCardInput: PaymentCardInputBaseComponent;
  @Output() public paymentMethodCancel = new EventEmitter();
  @Output() public confirm = new EventEmitter();
  @Output() public paymentMethodAuthenticationError = new EventEmitter();
  @Input() public isLargeButtonsEnabled = false;
  @Input() public isConfirmButtonHidden = false;

  private _value: IPaymentRecord;
  private _booking: Booking;
  private _order: LegacyOrder;
  private _payment: IPaymentRecord<InitiatePaymentResult>;
  private _storageLocation: BookableStorageLocation;
  private _paymentMethods: IPaymentRecord[];
  private _previousPaymentMethod: IPaymentRecord;
  private selectedIndex = 0;
  private previousSelectedIndex = 0;
  private _paymentCardData: IStripePaymentCard;
  private _disabled = false;
  private _isInitialized = false;
  private _isLoading = false;
  private _isPaymentDue = false;
  private _isAddingNewPaymentCard = false;
  private _isWalletPaymentAllowed = false;
  private _isPayPalPaymentAllowed = true;
  private _isConfirmingBooking = false;
  private _isSimpleMode = false;
  private _isAppUrlOpenEventListenerInitialised = false;
  private ngZone = inject(NgZone);

  public walletPaymentMethodAdded = false;

  private onTouchedCallback: () => void = noop;
  private onChangeCallback: (_) => void = noop;

  constructor(
    protected paymentService: SharedPaymentService,
    protected stripeService: SharedStripeService,
    protected storageService: SharedStorageService,
    protected userService: SharedUserService,
    protected notify: SharedNotificationService,
    protected error: SharedErrorService,
    protected translate: TranslateService,
    protected cd: ChangeDetectorRef,
    private windowService: SharedWindowService,
  ) {
    super();
  }

  get language(): string {
    return this.translate.currentLang;
  }

  get confirmCallToAction(): string {
    if (!this.isLoggedIn) {
      return 'ADD_PAYMENT_CARD';
    }
    if (this.isConfirmingBooking) {
      return this.isPaymentDue ? 'PAY_NOW' : 'CONFIRM_BOOKING';
    }
    return 'DONE';
  }

  set value(value: IPaymentRecord) {
    if (!value || value === this._value) {
      return;
    }
    this._value = value;

    this.onChangeCallback(value);
    this.cd.markForCheck();
  }
  get value(): IPaymentRecord {
    return this._value;
  }

  @Input() set booking(value: Booking) {
    this._booking = value;
  }
  get booking(): Booking {
    return this._booking;
  }

  @Input() set order(value: LegacyOrder) {
    this._order = value;
  }
  get order(): LegacyOrder {
    return this._order;
  }

  @Input() set storageLocation(value: BookableStorageLocation) {
    this._storageLocation = value;
  }
  get storageLocation(): BookableStorageLocation {
    return this._storageLocation;
  }

  @Input() set disabled(value: boolean) {
    this._disabled = value;
  }
  get disabled(): boolean {
    return this._disabled;
  }

  @Input() set isConfirmingBooking(value: boolean) {
    this._isConfirmingBooking = value;
    this.cd.markForCheck();
  }
  get isConfirmingBooking(): boolean {
    return this._isConfirmingBooking;
  }

  @Input() set isWalletPaymentAllowed(value: boolean) {
    this._isWalletPaymentAllowed = value;
    this.cd.markForCheck();
  }
  get isWalletPaymentAllowed(): boolean {
    return this._isWalletPaymentAllowed;
  }

  @Input() set isPayPalPaymentAllowed(value: boolean) {
    this._isPayPalPaymentAllowed = value;
    this.cd.markForCheck();
  }
  get isPayPalPaymentAllowed(): boolean {
    return this._isPayPalPaymentAllowed;
  }

  @Input() set isSimpleMode(value: boolean) {
    this._isSimpleMode = value;
    if (value && this.isWalletPaymentAvailable) {
      this.hidePaymentCardForm();
    }
    this.cd.markForCheck();
  }
  get isSimpleMode(): boolean {
    return this._isSimpleMode;
  }

  @Input() set paymentMethods(value: IPaymentRecord[]) {
    if (value) {
      value = value.filter((p) => p.provider !== 'direct_payment');
    }
    this._paymentMethods = value || [];

    this.walletPaymentMethodAdded = this._paymentMethods.some(
      (pm) =>
        typeof pm.data === 'object' &&
        'card' in pm.data &&
        (pm.data as { card: { wallet: unknown } }).card != null &&
        (pm.data.card as { wallet: unknown }).wallet,
    );

    if (this.booking && this.booking.paymentMethodId) {
      for (let i = 0; i < this.paymentMethods.length; i++) {
        const paymentMethod = this.paymentMethods[i];
        if (paymentMethod.id === this.booking.paymentMethodId) {
          this.selectPaymentMethod(i);
          this.previousPaymentMethod = paymentMethod;
          break;
        }
      }
    } else {
      // No booking or booking has no payment method set
      // TODO: Choose default payment method based on a user setting
      this.selectPaymentMethod(0);
    }
  }
  get paymentMethods(): IPaymentRecord[] {
    return this._paymentMethods;
  }

  get previousPaymentMethod(): IPaymentRecord {
    return this._previousPaymentMethod;
  }
  set previousPaymentMethod(value: IPaymentRecord) {
    this._previousPaymentMethod = value;
    this.cd.markForCheck();
  }

  @Input() set isPaymentDue(value: boolean) {
    this._isPaymentDue = value;
    this.cd.markForCheck();
  }
  get isPaymentDue(): boolean {
    return this._isPaymentDue;
  }

  get isPaymentCardFormVisible(): boolean {
    if (this.isSimpleMode && this.isWalletPaymentVisible) {
      return false;
    }
    if (!this.isLoggedIn) {
      return false;
    }
    if (!this.paymentMethods || this.paymentMethods.length === 0) {
      // No payment methods for this user, so show payment card form
      return true;
    }
    // Show payment card form if we are adding a new payment card
    return this._isAddingNewPaymentCard;
  }

  get isPayPalPaymentVisible(): boolean {
    if (!AppConfig.IS_PAYPAL_ENABLED) {
      return false;
    }

    if (!this.isPayPalPaymentAllowed) {
      return false;
    }

    if (this.isSimpleMode && this.isWalletPaymentVisible) {
      return false;
    }
    if (!this.paymentMethods || this.paymentMethods.length === 0) {
      // No payment methods for this user, so show PayPal
      return true;
    }
    // Show PayPal if we are adding a new payment card
    return this._isAddingNewPaymentCard;
  }

  get isWalletPaymentVisible(): boolean {
    if (!this.isWalletPaymentAvailable || !this.isWalletPaymentAllowed) {
      return false;
    }

    if (!this.isLoggedIn && !this.isSimpleMode) {
      return true;
    }

    return this.isSimpleMode || this.isPaymentCardFormVisible;
  }

  get isWalletPaymentAvailable(): boolean {
    return this.stripeService.isWalletPaymentAvailable;
  }

  get isApplePayAvailable(): boolean {
    return this.stripeService.isApplePayAvailable;
  }

  get isGooglePayAvailable(): boolean {
    return this.stripeService.isGooglePayAvailable;
  }

  get isNewPaymentMethodSelected(): boolean {
    if (!this.value || !this.previousPaymentMethod) {
      return false;
    }
    return this.value.id !== this.previousPaymentMethod.id;
  }

  get isDirectPaymentSelected(): boolean {
    if (!this.value) {
      return false;
    }
    return this.value.provider === 'direct_payment';
  }

  get isPaymentMethodDropdownVisible(): boolean {
    if (this.isSimpleMode && this.isWalletPaymentVisible) {
      return false;
    }
    return this.paymentMethods?.length > 0;
  }

  get isConfirmButtonVisible() {
    if (this.isConfirmButtonHidden) {
      return false;
    }
    if (this.isPaymentCardFormVisible) {
      // The payment card form has its own confirm button
      return false;
    }
    if (this.isSimpleMode && this.isWalletPaymentAvailable) {
      // In simple mode show only the Apple Pay or Google Pay button if available
      return false;
    }
    return true;
  }

  get paymentCardData(): IStripePaymentCard {
    return this._paymentCardData;
  }
  set paymentCardData(value: IStripePaymentCard) {
    this._paymentCardData = value;
  }

  get isInitialized(): boolean {
    return this._isInitialized;
  }
  set isInitialized(value: boolean) {
    this._isInitialized = value;
    this.cd.markForCheck();
  }

  get isLoading(): boolean {
    return this._isLoading;
  }
  set isLoading(value: boolean) {
    this._isLoading = value;
    this.cd.markForCheck();
  }

  get payment(): IPaymentRecord<InitiatePaymentResult> {
    return this._payment;
  }
  @Input() set payment(value: IPaymentRecord<InitiatePaymentResult>) {
    this._payment = value;
    this.cd.markForCheck();
  }

  get paymentAmount(): number {
    // TODO: Handle zero-decimal currencies from Stripe (https://stripe.com/docs/currencies#zero-decimal)
    return Math.round((this.payment?.data.amount || 0) * 100);
  }

  get paymentCurrency(): string {
    return this.payment?.data.currency || this.booking?.price.pricing.currency || AppConfig.MERCHANT_CURRENCY;
  }

  get bookingId(): string {
    if (!this.booking) {
      return undefined;
    }
    return this.booking._id;
  }

  get orderId(): string {
    let orderId: string;
    if (this.order) {
      orderId = this.order._id;
    }
    return orderId;
  }

  get storageLocationId(): string {
    let storageLocationId: string;
    if (this.storageLocation && this.isPaymentCardValidationVariant) {
      storageLocationId = this.storageLocation._id;
    }
    return storageLocationId;
  }

  ngOnInit() {
    this.isInitialized = true;
  }

  writeValue(value: IPaymentRecord) {
    if (value !== undefined) {
      this.value = value;
    }
  }

  registerOnChange(fn: (_) => void) {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: () => void) {
    this.onTouchedCallback = fn;
  }

  submitPaymentCardForm() {
    void this.paymentCardInput?.submit();
  }

  confirmPaymentMethod() {
    this.confirm.emit();
    void this.hideModal(this.value);
  }

  showPaymentCardForm() {
    this._isAddingNewPaymentCard = true;
    this.cd.markForCheck();
  }

  cancelAddPaymentCard() {
    this.selectedIndex = this.previousSelectedIndex;
    this.hidePaymentCardForm();
    this.paymentMethodCancel.emit();
  }

  hidePaymentCardForm() {
    this._isAddingNewPaymentCard = false;
    this.cd.markForCheck();
  }

  onPaypalClick(event: Event) {
    event.preventDefault();
    void this.confirmBookingWithPaypal();
  }

  onPaymentCardResult(result: IStripeSetupIntentResult | IStripePaymentIntentResult): Promise<void> {
    if (result.error) {
      this.notify.warning(result.error.message);
      this.isLoading = false;
      return;
    }

    const setupIntentResult = result as IStripeSetupIntentResult;
    const paymentIntentResult = result as IStripePaymentIntentResult;

    void this.addPaymentMethod('stripe', setupIntentResult.setupIntent || paymentIntentResult.paymentIntent);
  }

  get isLoggedIn(): boolean {
    return this.userService.isLoggedIn;
  }

  get isSkipLoginVariant(): boolean {
    if (this.storageService.variant === AppConfig.EXPERIMENT_VARIANTS.skipLogin) {
      return this.stripeService.isWalletPaymentAvailable;
    }
    return false;
  }

  get isPaymentCardValidationVariant(): boolean {
    return this.storageService.variant === AppConfig.EXPERIMENT_VARIANTS.paymentCardValidation;
  }

  async addPaymentMethod(provider: string, data: unknown): Promise<boolean> {
    this.isLoading = true;
    let success = false;

    let paymentMethod: IPaymentRecord;

    try {
      paymentMethod = await this.paymentService.addPaymentMethod({ provider, data }, this.booking?._id || undefined);
      success = true;

      // Removing this notification for now as it's redundant and hides the next CTA on native
      // this.notify.success(this.translate.instant('PAYMENT_CARD_ADDED'));
    } catch (err) {
      this.error.handleError(err, this.translate.instant('ERROR_ADDING_PAYMENT_CARD') as string);

      this.isLoading = false;
      return false;
    }

    if (paymentMethod) {
      // Add the new payment method to the beginning of the list and clear the form
      this.paymentMethods.splice(0, 0, paymentMethod);

      // Update selected index to select the new card
      this.previousSelectedIndex = this.selectedIndex;
      this.selectedIndex = 0;

      // Update value
      this.value = paymentMethod;
    }

    this.hidePaymentCardForm();
    this.confirmPaymentMethod();

    if (!this.isConfirmingBooking) {
      this.isLoading = false;
    }

    return success;
  }

  isDirectPayment(paymentMethod: IPaymentRecord): boolean {
    return paymentMethod.provider === 'direct_payment';
  }

  isPayPal(
    paymentMethod: IPaymentRecord<{
      type: string;
    }>,
  ): boolean {
    if (!paymentMethod.data) {
      return false;
    }
    return paymentMethod.data.type === 'paypal';
  }

  isWalletPayment(
    paymentMethod: IPaymentRecord<{ type: string } & { [key: string]: { wallet: { type: string } } }>,
  ): boolean {
    switch (paymentMethod.provider) {
      case 'stripe':
        if (paymentMethod.data[paymentMethod.data.type] && paymentMethod.data[paymentMethod.data.type].wallet) {
          return true;
        }
        return false;

      case 'paypal':
        // TODO: Add support for PayPal
        return false;

      default:
        return false;
    }
  }

  selectNewPaymentCard() {
    this.selectPaymentMethod(this.paymentMethods.length);
  }

  selectPaymentMethod(index: number) {
    if (this.isSimpleMode && this.isWalletPaymentVisible) {
      // In simple mode, don't show other payment methods when wallet payment is visible
      return;
    }
    if (!this.paymentMethods || this.paymentMethods.length <= index) {
      // New payment card
      this.showPaymentCardForm();
    } else {
      this.hidePaymentCardForm();
      this.value = this.paymentMethods[index];
    }
    this.previousSelectedIndex = this.selectedIndex;
    this.selectedIndex = index;
  }

  public async confirmBookingWithPaypal() {
    if (this.windowService.isNative) {
      this.addAppUrlOpenEventListener();
      return Browser.open({ url: this.payment.data.redirectUrl });
    }

    this.storageService.remove('PAYPAL_SETUP_INTENT', StorageTarget.Local);
    this.storageService.remove('PAYPAL_PAYMENT_INTENT', StorageTarget.Local);
    this.storageService.remove('PAYPAL_SETUP_INTENT_CLIENT_SECRET', StorageTarget.Local);
    this.storageService.remove('PAYPAL_PAYMENT_INTENT_CLIENT_SECRET', StorageTarget.Local);

    const redirectUrl = '/providers/stripe/paypal.html?intent=' + this.payment.data.client_secret;

    const window = await firstValueFrom(this.windowService.openCentered(redirectUrl, '', false));
    let paypalAuthenticationStatus = await firstValueFrom(
      SharedUtilWindow.waitForValue(window, 'PAYPAL_REDIRECT_STATUS'),
    );

    if (!this.storageService.isLocalStorageSupported && !this.storageService.isSessionStorageSupported) {
      this.error.handleError(
        new Error(`Local and session storage are not available (client_secret: ${this.payment.data.client_secret}`),
      );
      this.paymentMethodAuthenticationError.emit();

      return;
    }

    let setupIntentId: string, paymentIntentId: string;

    if (this.storageService.isLocalStorageSupported) {
      setupIntentId = this.storageService.get('PAYPAL_SETUP_INTENT', StorageTarget.Local);
      paymentIntentId = this.storageService.get('PAYPAL_PAYMENT_INTENT', StorageTarget.Local);
    } else {
      setupIntentId = this.storageService.get('PAYPAL_SETUP_INTENT', StorageTarget.Session);
      paymentIntentId = this.storageService.get('PAYPAL_PAYMENT_INTENT', StorageTarget.Session);

      if (!paypalAuthenticationStatus) {
        paypalAuthenticationStatus = this.storageService.get('PAYPAL_REDIRECT_STATUS', StorageTarget.Session);
      }
    }

    if (
      (paypalAuthenticationStatus === 'succeeded' || paypalAuthenticationStatus === 'pending') &&
      (setupIntentId || paymentIntentId)
    ) {
      await this.addPaymentMethod('stripe', this.payment.data);
    } else if (paypalAuthenticationStatus === 'failed') {
      this.paymentMethodAuthenticationError.emit();
    }
  }

  public addAppUrlOpenEventListener() {
    if (this._isAppUrlOpenEventListenerInitialised) {
      return;
    }
    this._isAppUrlOpenEventListenerInitialised = true;
    void CapacitorApp.removeAllListeners();

    void CapacitorApp.addListener('appUrlOpen', (event) => {
      void this.ngZone.run(async () => {
        if (event.url) {
          const url = new URL(event.url);
          if (
            url &&
            url.protocol === 'luggagehero:' &&
            (url.pathname.endsWith('stripe-paypal-redirect') || url.host.endsWith('stripe-paypal-redirect')) &&
            url.searchParams.get('redirect_status')
          ) {
            void Browser.close();
            const redirectStatus = url.searchParams.get('redirect_status');
            if (['succeeded', 'pending'].includes(redirectStatus)) {
              await this.addPaymentMethod('stripe', this.payment.data);
            } else if (redirectStatus === 'failed') {
              this.paymentMethodAuthenticationError.emit();
            }
          }
        }
      });
    });
  }
}
