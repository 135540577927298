import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  inject,
  OnDestroy,
  OnInit,
  Output,
  Type,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppConfig } from '@luggagehero/shared/app-settings/data-access';
import {
  BookableStorageLocation,
  Booking,
  BookingAddOns,
  DistanceUnit,
  ILuggage,
  InitiatePaymentResult,
  INSURANCE_PRODUCT_KEY,
  IPaymentRecord,
  IPricing,
  ITimePeriod,
  LegacyOrder,
  LegacyOrderLine,
  PAYMENT_METHOD_TRANSFORMER_SERVICE,
  PaymentMethodTransformerService,
  PricingModel,
  ProductInfo,
  STORAGE_DAY_PRODUCT_KEY,
  STORAGE_HOUR_PRODUCT_KEY,
  StorageCriteria,
  WALK_IN_INSURANCE_FEE_PRODUCT_KEY,
} from '@luggagehero/shared/interfaces';
import { SharedBookingService } from '@luggagehero/shared/services/bookings';
import { SharedDistanceService } from '@luggagehero/shared/services/distance';
import { SharedOrderService } from '@luggagehero/shared/services/orders';
import { SharedPaymentService } from '@luggagehero/shared/services/payments';
import { SharedPricingService } from '@luggagehero/shared/services/pricing';
import { SharedPromoCodeService } from '@luggagehero/shared/services/promo-codes';
import { SharedShopsService } from '@luggagehero/shared/services/shops';
import { SharedStorageService } from '@luggagehero/shared/services/storage';
import { SharedStorageCriteriaService } from '@luggagehero/shared/services/storage-criteria';
import { SharedStripeService } from '@luggagehero/shared/services/stripe';
import { SharedUserService } from '@luggagehero/shared/services/users';
import { SharedWindowService } from '@luggagehero/shared/services/window';
import { TranslateService } from '@ngx-translate/core';
import { map, Observable, of, Subscription } from 'rxjs';

import { BaseModalComponent } from '../../../core';
import { ModalService, RouterExtensions } from '../../../core/services/index';
import { DateUtil } from '../../../utils/date.util';
import { PaymentMethodSelectorBaseComponent } from '../../ui/index';
import { AbandonBookingBaseComponent } from './abandon-booking.base-component';

interface Footer {
  text: string;
  callToAction?: string;
  action?: () => void;
  link?: string;
  isError?: boolean;
}

@Component({ template: '' })
export abstract class ConfirmBookingBaseComponent extends BaseModalComponent implements OnInit, OnDestroy {
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() public result = new EventEmitter<Booking>();
  @ViewChild('paymentMethodSelector') paymentMethodSelector: PaymentMethodSelectorBaseComponent;
  protected paymentMethodTransformerService = inject<PaymentMethodTransformerService>(
    PAYMENT_METHOD_TRANSFORMER_SERVICE,
  );

  public emailForm: FormGroup;
  public phoneNumberForm: FormGroup;
  public isAddingPaymentCard = false;
  public selectedProducts: ProductInfo[];

  public hourlyRate: number;
  public dailyRate: number;
  public currency: string;

  public minBagsWarning: string;

  private _order: LegacyOrder;
  private _payment: IPaymentRecord<InitiatePaymentResult>;

  private _paymentMethod: IPaymentRecord;
  private _paymentMethods: IPaymentRecord[];

  private subscriptions: Subscription[] = [];
  private updateOrderTimeout: NodeJS.Timeout;
  private isInitialized = false;
  private _isLoading = false;
  private _isUpdating = false;
  private _email = '';
  private _phoneNumber = '';
  private _phoneCountry = '';
  private _isAskingForPhoneNumber = false;
  private _isAskingForEmail = false;
  private _isEmailFormSubmitted = false;
  private _isPhoneNumberFormSubmitted = false;
  private _isSelectingPaymentMethod = false;
  private isAddPaymentCardRequested = false;
  private isUseNonWalletPaymentMethodRequested = false;
  private isUsingNonWalletPaymentMethod = false;
  private hasLoginStateChanged = false;
  private isConfirmBookingClicked = false;
  private isMakeBookingCalled = false;

  private hasSuggestedOptionalProducts = false;
  private _isSuggestingOptionalProducts = false;

  constructor(
    formBuilder: FormBuilder,
    private bookingService: SharedBookingService,
    private newOrderService: SharedOrderService,
    private storageLocationService: SharedShopsService,
    private stripeService: SharedStripeService,
    private priceService: SharedPricingService,
    private promoCodeService: SharedPromoCodeService,
    private criteriaService: SharedStorageCriteriaService,
    private distanceService: SharedDistanceService,
    private userService: SharedUserService,
    private windowService: SharedWindowService,
    protected modalService: ModalService,
    private storage: SharedStorageService,
    private translate: TranslateService,
    private router: RouterExtensions,
    private cd: ChangeDetectorRef,
    protected paymentService: SharedPaymentService,
  ) {
    super();

    this.phoneNumberForm = formBuilder.group({
      // eslint-disable-next-line @typescript-eslint/unbound-method
      phone: ['', [Validators.required, Validators.minLength(6)]],
    });

    this.emailForm = formBuilder.group({
      // eslint-disable-next-line @typescript-eslint/unbound-method
      email: ['', [Validators.required, Validators.pattern(AppConfig.EMAIL_REGEX)]],
    });
  }

  public get dropoffPickupTooltip(): string {
    const lines: string[] = [
      `${this.translate.instant('FLEXIBLE_BOOKINGS_TOOLTIP')}`,
      `<ul>`,
      `<li>${this.translate.instant('CANCEL_UNTIL_MIDNIGHT')}</li>`,
      `<li>${this.translate.instant('NO_SHOW_FEE_ONE_DAY_STORAGE')}</li>`,
      // `<li>${this.translate.instant('TAXES_AND_SERVICE_CHARGES_INCLUDED')}</li>`,
      `<li>${this.translate.instant('BOOKING_PROTECTION_INCLUDED')}</li>`,
      `</ul>`,
    ];
    return lines.join('\n');
  }

  public get isLoading(): boolean {
    return this._isLoading;
  }
  set isLoading(value: boolean) {
    this._isLoading = value;
    this.cd.markForCheck();
  }

  public get isUpdating(): boolean {
    return this._isUpdating;
  }
  set isUpdating(value: boolean) {
    this._isUpdating = value;
    this.cd.markForCheck();
  }

  public get isAskingForUserDetails(): boolean {
    return this.isAskingForEmail || this.isAskingForPhoneNumber;
  }

  public get isAskingForPhoneNumber(): boolean {
    return this._isAskingForPhoneNumber;
  }
  set isAskingForPhoneNumber(value: boolean) {
    this._isAskingForPhoneNumber = value;
    this.cd.markForCheck();
  }

  public get isSuggestingOptionalProducts(): boolean {
    return this._isSuggestingOptionalProducts;
  }
  set isSuggestingOptionalProducts(value: boolean) {
    this._isSuggestingOptionalProducts = value;
    this.cd.markForCheck();
  }

  public get isAskingForEmail(): boolean {
    return this._isAskingForEmail;
  }
  set isAskingForEmail(value: boolean) {
    this._isAskingForEmail = value;
    this.cd.markForCheck();
  }

  public get phoneNumber(): string {
    return this._phoneNumber;
  }
  set phoneNumber(value: string) {
    this._phoneNumber = value;
    this.cd.markForCheck();
  }

  public get email(): string {
    return this._email;
  }
  set email(value: string) {
    this._email = value;
    this.cd.markForCheck();
  }

  public get phoneCountry(): string {
    return this._phoneCountry;
  }
  set phoneCountry(value: string) {
    this._phoneCountry = value;
    this.cd.markForCheck();
  }

  public get isEmailFormSubmitted(): boolean {
    return this._isEmailFormSubmitted;
  }
  set isEmailFormSubmitted(value: boolean) {
    this._isEmailFormSubmitted = value;
    this.cd.markForCheck();
  }

  public get isPhoneNumberFormSubmitted(): boolean {
    return this._isPhoneNumberFormSubmitted;
  }
  set isPhoneNumberFormSubmitted(value: boolean) {
    this._isPhoneNumberFormSubmitted = value;
    this.cd.markForCheck();
  }

  public get order(): LegacyOrder {
    return this._order;
  }

  get optionalProducts(): ProductInfo[] {
    const res = this.newOrderService.latestProductList?.products.filter(
      (p) => p.tags?.some((t) => t.key === 'optional' && t.value === 'true') || false,
    );
    return res;
  }

  public get payment(): IPaymentRecord<InitiatePaymentResult> {
    return this._payment;
  }

  get storageLocation(): BookableStorageLocation {
    return this.storageLocationService.current;
  }

  public get pricing(): IPricing {
    return this.storageLocation.pricing;
  }

  public get pricingModel(): Observable<PricingModel> {
    return this.priceService.pricingModel$;
  }

  public get addOns(): BookingAddOns {
    return {
      insurance: this.priceService.insuranceSelected,
      freeCancellation: this.priceService.freeCancellationFeeSelected,
      securitySeals: this.priceService.numberOfSecuritySeals,
    };
  }

  public get criteria(): StorageCriteria {
    return this.criteriaService.currentOrDefault;
  }

  public get luggage(): ILuggage {
    return this.criteria.luggage;
  }

  public get numberOfBags(): number {
    return this.luggage.hand + this.luggage.normal;
  }

  public get period(): ITimePeriod {
    return this.criteria.period;
  }

  public get from(): Date {
    return this.criteria.period.from;
  }

  public get to(): Date {
    return this.criteria.period.to;
  }

  public get promoCodeCta(): string {
    return this.promoCodeService.appliedDiscount ? 'CHANGE_DISCOUNT_CODE' : 'HAVE_A_VOUCHER_OR_PROMO_CODE_MINI';
  }

  public get showNumberOfReviews(): boolean {
    return this.numberOfReviews > 1;
  }

  public get averageRating(): number {
    return this.storageLocation.stats ? this.storageLocation.stats.averageRating : AppConfig.GLOBAL_AVERAGE_RATING;
  }

  public get numberOfReviews(): number {
    if (!this.storageLocation || !this.storageLocation.stats) {
      return 0;
    }
    return this.storageLocation.stats.numberOfRatings;
  }

  public get numberOfStars(): number {
    if (AppConfig.IS_FULL_STAR_RATING_DISABLED) {
      return 1;
    }
    return 5;
  }

  public get storageLocationImage(): string {
    // if (this.storageLocation?.images.length > 0) {
    //   return `${Config.ENVIRONMENT.IMAGES_BASE_URL}/images/medium/${this.storageLocation.images[0]}`;
    // }
    return 'assets/no-image.jpg';
  }

  public get poiList() {
    if (!this.storageLocation || !this.storageLocation.poiList) {
      return [];
    }
    const pois = [...this.storageLocation.poiList];

    return pois.map((poi) => {
      // HACK: Remove POI_ prefix which is added to some POI names
      poi.poiName = poi.poiName.replace('POI_', '');

      return poi;
    });
  }

  public get isNearestPoiMostRelevant(): boolean {
    if (this.poiList.length === 0) {
      // No POIs nearby
      return false;
    }
    if (this.criteria.location.type === 'official') {
      // The center of the city is not relevant, use POI as reference point instead
      return true;
    }
    // If distance to current location is more than 500m, consider nearest POI more relevant
    return this.storageLocation.distance > 500;
  }

  public get distance(): number {
    if (!this.storageLocation) {
      return undefined;
    }
    if (this.isNearestPoiMostRelevant) {
      return this.poiList[0].distance;
    }
    return this.storageLocation.distance;
  }

  public get distanceUnit(): Observable<DistanceUnit> {
    return this.distanceService.distanceUnit$;
  }

  public get fromSuffix(): Observable<string> {
    if (this.isNearestPoiMostRelevant) {
      return this.translate.get('FROM').pipe(map((from: string) => `${from.toLowerCase()} ${this.poiList[0].poiName}`));
    }
    return this.distanceService.getFromSuffix();
  }

  get emailInputError(): string {
    if (!this.isEmailFormSubmitted) {
      return undefined;
    }
    const emailInput = this.emailForm.get('email');
    if (!emailInput.errors) {
      return undefined;
    }

    if (emailInput.errors) {
      return 'INVALID_EMAIL';
    }

    return undefined;
  }

  get phoneInputError(): string {
    if (!this.isPhoneNumberFormSubmitted) {
      return undefined;
    }
    const phoneInput = this.phoneNumberForm.get('phone');
    if (!phoneInput.errors) {
      return undefined;
    }
    if (phoneInput.errors.required) {
      return 'PHONE_NUMBER_MISSING';
    }
    if (phoneInput.errors.minlength) {
      return 'PHONE_NUMBER_TOO_SHORT';
    }
    if (phoneInput.errors.invalid) {
      return (phoneInput.errors.invalid as { reason?: string }).reason;
    }
    return 'INVALID_PHONE_NUMBER';
  }

  get isSubmitPhoneNumberEnabled(): boolean {
    if (!this.isPhoneNumberFormSubmitted) {
      return true;
    }
    return this.phoneNumberForm.valid && !this.isLoading;
  }

  get isSubmitEmailEnabled(): boolean {
    if (!this.isEmailFormSubmitted) {
      return true;
    }
    return this.emailForm.valid && !this.isLoading;
  }

  public get isOpenOnDropoffDate(): boolean {
    const dropoffHours = this.storageLocationService.getOpeningHoursForDate(this.from, this.storageLocation);
    if (!dropoffHours || dropoffHours.length === 0) {
      return false;
    }
    return true;
  }

  public get isOpenOnPickupDate(): boolean {
    const pickupHours = this.storageLocationService.getOpeningHoursForDate(this.to, this.storageLocation);
    if (!pickupHours || pickupHours.length === 0) {
      return false;
    }
    return true;
  }

  public get isSelectingPaymentMethod(): boolean {
    return this._isSelectingPaymentMethod;
  }
  public set isSelectingPaymentMethod(value: boolean) {
    this._isSelectingPaymentMethod = value;
    this.cd.markForCheck();
  }

  public get paymentMethod(): IPaymentRecord {
    return this._paymentMethod;
  }
  public set paymentMethod(value: IPaymentRecord) {
    if (this._paymentMethod === value) {
      return;
    }
    this._paymentMethod = value;
    this.cd.markForCheck();
  }

  public get paymentMethods(): IPaymentRecord[] {
    return this._paymentMethods;
  }
  public set paymentMethods(value: IPaymentRecord[]) {
    this._paymentMethods = value;
    this.paymentMethod = value[0];
  }

  public get isPaymentDue(): Observable<boolean> {
    //
    // For now considering all bookings as not paid up front as this aligns better with what we are actually doing on
    // the backend.
    //
    // return this.pricingModel.pipe(map((value) => value === 'daily'));
    return of(false);
  }

  public get canConfirmBooking(): boolean {
    const hasSpace = this.storageLocation?.hasSpace ?? true;

    if (!this.isOpenOnDropoffDate || !this.isOpenOnPickupDate || !hasSpace) {
      return false;
    }
    return true;
  }

  public get canConfirmPayment(): boolean {
    if (this.paymentMethod) {
      // TODO: Also check if payment method is still valid (or do it on the backend)
      return true;
    }
    if (this.stripeService.isWalletPaymentAvailable) {
      return true;
    }
    return this.isSelectingPaymentMethod;
  }

  public get isSimplePaymentSelectorMode(): boolean {
    if (this.isSelectingPaymentMethod) {
      return false;
    }
    if (this.isAddingPaymentCard) {
      return false;
    }
    return true;
  }

  public get isPaymentMethodSelectorVisible(): boolean {
    if (!this.paymentMethod && this.canConfirmPayment) {
      // No existing payment method and either wallet payment is available or we are in select payment method mode
      return true;
    }
    if (this.isLoggedIn && this.isConfirmBookingClicked) {
      return true;
    }
    return this.isSelectingPaymentMethod;
  }

  public get showPayPal(): boolean {
    if (this.isLoggedIn) {
      return false;
    }
    return true;
  }

  public get isUseDifferentPaymentMethodVisible(): boolean {
    if (!AppConfig.IS_OVERRIDE_WALLET_PAYMENT_ENABLED) {
      return false;
    }
    return this.isWalletPaymentVisible && !this.isSelectingPaymentMethod;
  }

  public get isWalletPaymentVisible(): boolean {
    return this.paymentMethodSelector?.isWalletPaymentVisible;
  }

  public get isWalletPaymentAllowed(): boolean {
    if (this.isUsingNonWalletPaymentMethod) {
      return false;
    }
    if (this.isAddingPaymentCard && !this.isSelectingPaymentMethod) {
      return false;
    }
    return true;
  }

  public get isPaymentCardValidationVariant(): boolean {
    return this.storage.variant === AppConfig.EXPERIMENT_VARIANTS.paymentCardValidation;
  }

  public get isCompactModeEnabled(): boolean {
    return AppConfig.IS_COMPACT_MODE_ON_CONFIRM_BOOKING_ENABLED;
  }

  public get isLoggedIn(): boolean {
    return this.userService.isLoggedIn;
  }

  public get footer(): Footer {
    if (!this.canConfirmBooking) {
      return { text: this.translate.instant('LOCATION_NOT_AVAILABLE_WITH_YOUR_SELECTION') as string, isError: true };
    }

    if (this.paymentMethod && !this.isSelectingPaymentMethod) {
      return {
        text: `${this.translate.instant('USING_PAYMENT_CARD')} ${this.paymentMethodTransformerService.transform(this.paymentMethod)}`,
        callToAction: this.translate.instant('CHANGE') as string,
        action: () => (this.isSelectingPaymentMethod = true),
      };
    }

    if (!this.isLoggedIn && this.isSelectingPaymentMethod) {
      // We should not reach here as we currently only support adding payment cards after signing in
      return {
        text: this.translate.instant('HAVE_AN_ACCOUNT_QUESTION') as string,
        callToAction: this.translate.instant('SIGN_IN') as string,
        action: () => void this.signIn(),
      };
    }

    if (!this.isLoggedIn && this.isWalletPaymentVisible) {
      return {
        text: this.translate.instant('ACCEPT_TERMS_PART_1') as string,
        callToAction: this.translate.instant('ACCEPT_TERMS_PART_2') as string,
        // TODO: Move this to AppConfig
        link: 'https://luggagehero.com/terms-conditions/',
      };
    }

    return { text: this.translate.instant('NO_CHARGE_YET') as string };
  }

  protected abstract get abandonBookingComponentType(): Type<AbandonBookingBaseComponent>;

  public async ngOnInit() {
    // Don't use previous quotes for this instance of the confirm booking view
    this.newOrderService.clear();

    this.subscriptions = [
      this.userService.loginAnnounced.subscribe(() => void this.onLoginEvent()),
      this.priceService.pricingModel$.subscribe(() => void this.updateOrder()),
      this.promoCodeService.promoCode.subscribe(() => void this.updateOrder()),
      this.criteriaService.changeRequested.subscribe(() => void this.updateOrder()),
    ];

    await this.updateOrder(true);
    this.isInitialized = true;
  }

  public ngOnDestroy(): void {
    try {
      this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    } catch {
      // Ignore
    }

    // Send user to booking page if user has clicked outside of modal window while collecting non-essential information
    if ((this.isAskingForUserDetails || this.isSuggestingOptionalProducts) && !this.isMakeBookingCalled) {
      void this.confirmBooking(true);
    }

    if (!this.isMakeBookingCalled) {
      this.showAbandonBooking();
    }
  }

  public showAbandonBooking() {
    if (
      this.storage.abandonCartLastResponseAt &&
      this.storage.timeSinceAbandonCartLastResponse < AppConfig.ABANDON_CART_FEEDBACK_MIN_DELAY_AFTER_RESPONSE
    ) {
      return; // User has given feedback recently
    }

    if (
      this.storage.abandonCartLastPromptedAt &&
      this.storage.timeSinceAbandonCartLastPrompted < AppConfig.ABANDON_CART_FEEDBACK_MIN_DELAY_AFTER_PROMPT
    ) {
      return; // User has been asked for feedback recently
    }

    void this.modalService.show({ component: this.abandonBookingComponentType });
  }

  public onOrderLineChange(orderLine: LegacyOrderLine) {
    // HACK: Using a hardcoded product id to add/remove insurance for now
    switch (orderLine.product._id) {
      case WALK_IN_INSURANCE_FEE_PRODUCT_KEY:
      case INSURANCE_PRODUCT_KEY:
        this.priceService.insuranceSelected = orderLine.selected ? true : false;
        break;
    }

    void this.updateOrder(true);
  }

  public onPaymentMethodSelected() {
    void this.confirmBooking();
  }

  public async onOptionalProductsSelected() {
    if (this.selectedProducts?.length > 0) {
      await this.updateOrder(true);
    }

    void this.confirmBooking();
  }

  public async useNonWalletPaymentMethod() {
    if (!this.isLoggedIn) {
      this.isUseNonWalletPaymentMethodRequested = true;
    } else {
      this.isUsingNonWalletPaymentMethod = true;
    }
    await this.confirmBooking();
  }

  public selectPaymentMethod() {
    if (this.isLoggedIn && this.paymentMethod) {
      void this.confirmBooking();
      return;
    }
    this.isSelectingPaymentMethod = true;
  }

  public addPaymentCard() {
    if (this.isLoggedIn) {
      this.isAddPaymentCardRequested = false;
      this.isAddingPaymentCard = true;
      this.isSelectingPaymentMethod = true;
    } else {
      // Until we handle adding payment cards before signing in or having a booking, fall back to sign-in here
      this.isAddPaymentCardRequested = true;
      void this.signIn();
    }
  }

  public reset() {
    this.isConfirmBookingClicked = false;
    this.isMakeBookingCalled = false;

    this.isAddPaymentCardRequested = false;
    this.isAddingPaymentCard = false;
    this.isSelectingPaymentMethod = false;

    this.isUseNonWalletPaymentMethodRequested = false;
    this.isUsingNonWalletPaymentMethod = false;

    this.isAskingForEmail = false;
    this.isAskingForPhoneNumber = false;
  }

  public signIn() {
    this.userService.showLoginView(true);
  }

  public async addEmail() {
    this.isEmailFormSubmitted = true;
    if (this.emailInputError) {
      return;
    }
    if (this.email) {
      const currentUser = this.userService.user;
      currentUser.email = this.email;

      this.isLoading = true;
      try {
        await this.userService.updateUser(currentUser);
        // const user = await this.userService.updateUser(currentUser);
        // this.log.debug(`Added email ${this.email} to user ${this.userService.user.phone}`);
      } catch (err) {
        // this.log.debug(`Error adding email ${this.email} to user ${this.userService.user.phone}`);
        // this.log.error(err);
      }
    }

    void this.confirmBooking();
  }

  public async addPhoneNumber() {
    this.isPhoneNumberFormSubmitted = true;
    if (this.phoneInputError) {
      return;
    }
    if (this.phoneNumber) {
      const currentUser = this.userService.user;
      currentUser.phone = this.phoneNumber;
      currentUser.phoneCountry = this.phoneCountry;

      this.isLoading = true;

      try {
        await this.userService.updateUser(currentUser);
        // const user = await this.userService.updateUser(currentUser);
        //this.log.debug(`Added phone number ${this.phoneNumber} to user ${this.userService.user.email}`);
      } catch (err) {
        // this.log.debug(`Error adding phone number ${this.phoneNumber} to user ${this.userService.user.email}`);
        // this.log.error(err);
      }
    }

    void this.confirmBooking();
  }

  public async onPaymentMethodError() {
    let storageLocationId: string;

    if (this.isPaymentCardValidationVariant) {
      storageLocationId = this.storageLocation._id;
    }

    const orderId = this._order?._id;
    const returnUrl = this.windowService.isNative ? AppConfig.STRIPE_PAYPAL_NATIVE_RETURN_URL : null;
    this._payment = await this.paymentService.initiatePayment(
      'stripe',
      storageLocationId,
      orderId,
      undefined,
      returnUrl,
    );
    this.cd.markForCheck();
  }

  public async confirmBooking(skipMissingInfo = false) {
    this.isConfirmBookingClicked = true;

    if (!this.isLoggedIn) {
      this.userService.bookingRequested = true;
      this.userService.showLoginView(true);
      return;
    }

    if (!this.paymentMethod) {
      this.isSelectingPaymentMethod = true;
      return;
    }

    if (this.hasSuggestedOptionalProducts) {
      this.isSuggestingOptionalProducts = false;
    } else if (this.optionalProducts?.length > 0) {
      this.hasSuggestedOptionalProducts = true;
      this.isSuggestingOptionalProducts = true;
      return;
    }

    if (!this.userService.user.phone && !this.isAskingForUserDetails && !skipMissingInfo) {
      // This will show the phone number form
      this.isAskingForPhoneNumber = true;
      this.isSelectingPaymentMethod = false;
      return;
    }

    if (!this.userService.user.email && !this.isAskingForUserDetails && !skipMissingInfo) {
      // This will show the email form
      this.isAskingForEmail = true;
      this.isSelectingPaymentMethod = false;
      return;
    }

    this.isLoading = true;

    const booking = await this.makeBooking();

    if (!booking) {
      this.isLoading = false;
      return;
    }

    void this.router.navigate(['/bookings', booking._id], { queryParams: { utm_source: 'confirm_booking' } });
    this.result.emit(booking);

    void this.hideModal();
  }

  private async makeBooking(): Promise<Booking> {
    if (this.isMakeBookingCalled) {
      return;
    }
    this.isMakeBookingCalled = true;

    const bookingCategory = DateUtil.isToday(this.criteria.period.from) ? 'same_day' : 'advance';

    const metadata = this.bookingService.compileMetaData(bookingCategory, this.storageLocation);

    let booking: Booking;

    try {
      booking = await this.bookingService.confirmBooking(this.newOrderService.latestQuote._id, metadata);
    } catch {
      // Allow the user to try again
      this.isMakeBookingCalled = false;
    }

    return booking;
  }

  private async onLoginEvent() {
    await this.loadPaymentMethods();

    // Record that login state has changed
    this.hasLoginStateChanged = true;

    if (!this.isLoggedIn) {
      return;
    }

    if (this.isAddPaymentCardRequested) {
      this.addPaymentCard();
    } else if (this.isUseNonWalletPaymentMethodRequested) {
      this.isUseNonWalletPaymentMethodRequested = false;
      this.isUsingNonWalletPaymentMethod = true;
      this.cd.markForCheck();
    }
  }

  private async loadPaymentMethods() {
    if (!this.isLoggedIn) {
      return;
    }

    try {
      // Get existing payment methods for the user
      const paymentMethods = await this.paymentService.getPaymentMethods();
      this.paymentMethods = paymentMethods;

      //
      // Check if the login state has changed before potentially showing the payment method selection UI. This is to
      // avoid showing it prematurely the first time the users open the confirm booking view, in case they are already
      // signed in, as then we would be skipping the selection of basic booking options.
      //
      if (this.hasLoginStateChanged) {
        this.isSelectingPaymentMethod = true;
      }
    } catch (err) {
      if ((err as { status?: number })?.status === 401) {
        // Token issue, user needs to log in again
        this.userService.logout();
      }
    }
  }
  public cancel() {
    this.result.emit(null);
    void this.hideModal();
  }

  private async updateOrder(immediate = false) {
    if (!this.isInitialized && !immediate) {
      return;
    }

    if (immediate) {
      await this._updateOrder();
      return;
    }

    //
    // This is an update of the order, so debounce to avoid firing multiple requests to the API when the user is making
    // multiple consecutive changes
    //
    this.isUpdating = true;

    clearTimeout(this.updateOrderTimeout);

    return new Promise<void>((resolve, reject) => {
      this.updateOrderTimeout = setTimeout(() => {
        void (async () => {
          try {
            await this._updateOrder();
            resolve();
          } catch (err) {
            reject(err);
          } finally {
            this.isUpdating = false;
          }
        })();
      }, AppConfig.ORDER_DEBOUNCE_TIME);
    });
  }

  private async _updateOrder() {
    let optionalProductKeys: string[];

    if (this.selectedProducts?.length > 0 && this.isSuggestingOptionalProducts) {
      optionalProductKeys = this.selectedProducts.map((p) => p.key);
    }

    const minBags = this.newOrderService.getMinimumBags(false);
    const luggage = {
      normal: Math.max(this.luggage.normal, minBags),
      hand: this.luggage.hand,
    };

    this._order = await this.newOrderService.generateQuote(
      this.storageLocation._id,
      this.priceService.pricingModel,
      luggage,
      this.period,
      this.addOns,
      optionalProductKeys,
      this.promoCodeService.appliedDiscount?.code,
    );

    if (AppConfig.IS_PRICE_ON_PRICING_MODEL_SELECTOR_ENABLED) {
      // Set hourly and daily rates and currency for pricing model selector binding
      this.hourlyRate = this.newOrderService.latestProductList.products.find(
        (p) => p.key === STORAGE_HOUR_PRODUCT_KEY,
      )?.cost;
      this.dailyRate = this.newOrderService.latestProductList.products.find(
        (p) => p.key === STORAGE_DAY_PRODUCT_KEY,
      )?.cost;
    }
    this.currency = this.newOrderService.latestProductList.currency;

    this.minBagsWarning = this.newOrderService.getMinimumBagsWarning(false, this.luggage);

    //
    // By providing a storage location id we tell the server to validate the payment card with an up front payment if
    // necessary for the given storage location (only do this if we're on the variant for now)
    //
    let storageLocationId: string;

    if (this.isPaymentCardValidationVariant) {
      storageLocationId = this.storageLocation._id;
    }

    const paymentId = this._payment?.data.id;
    const orderId = this._order?._id;
    const returnUrl = this.windowService.isNative ? AppConfig.STRIPE_PAYPAL_NATIVE_RETURN_URL : null;

    // Initiate payment so we know what amount, if any, will be paid or reserved up front for this order
    this._payment = paymentId
      ? await this.paymentService.reinitiatePayment('stripe', paymentId, storageLocationId, orderId)
      : await this.paymentService.initiatePayment('stripe', storageLocationId, orderId, undefined, returnUrl);

    this.cd.markForCheck();
  }
}
