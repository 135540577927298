import { SharedLoggingService } from '@luggagehero/shared/services/logging';
import { TranslateParser } from '@ngx-translate/core';

import { SharedTranslateParser } from './shared-translate-parser';
import { SharedTranslationsService } from './shared-translations.service';

export const createTranslateParser = (
  translationsService: SharedTranslationsService,
  log: SharedLoggingService,
): TranslateParser => {
  return new SharedTranslateParser(translationsService, log);
};
