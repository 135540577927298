import { Inject, Injectable } from '@angular/core';
import { MomentService, TRANSLATE_SERVICE } from '@luggagehero/shared/interfaces';
import { SharedTranslateService } from '@luggagehero/shared/services/translation';
import moment from 'moment-timezone';

@Injectable({
  providedIn: 'root',
})
export class SharedMomentService implements MomentService {
  private text = {
    TODAY: 'today',
    TOMORROW: 'tomorrow',
    YESTERDAY: 'yesterday',
    LAST: 'last',
    ON_DATE: 'on',
  };

  private dateTimeOptions: Intl.DateTimeFormatOptions = {
    month: 'short',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
  };
  private shortDateOptions: Intl.DateTimeFormatOptions = {
    year: '2-digit',
    month: '2-digit',
    day: '2-digit',
  };
  private shortestDateOptions: Intl.DateTimeFormatOptions = {
    year: undefined,
    month: '2-digit',
    day: '2-digit',
  };

  private get calendarFormats(): moment.CalendarSpec {
    return {
      sameDay: `[${this.text.TODAY.toLowerCase()}]`,
      nextDay: `[${this.text.TOMORROW.toLowerCase()}]`,
      nextWeek: 'dddd',
      lastDay: `[${this.text.YESTERDAY.toLowerCase()}]`,
      lastWeek: `[${this.text.LAST.toLowerCase()}] dddd`,
      sameElse: `MMM D`,
    };
  }

  private get onCalendarFormats(): moment.CalendarSpec {
    return {
      sameDay: `[${this.text.TODAY.toLowerCase()}]`,
      nextDay: `[${this.text.TOMORROW.toLowerCase()}]`,
      nextWeek: 'dddd',
      lastDay: `[${this.text.YESTERDAY.toLowerCase()}]`,
      lastWeek: `[${this.text.LAST.toLowerCase()}] dddd`,
      sameElse: `[${this.text.ON_DATE.toLowerCase()}] MMM D, YYYY`,
    };
  }

  private get calendarTimeFormats(): moment.CalendarSpec {
    return {
      sameDay: `LT [${this.text.TODAY.toLowerCase()}]`,
      nextDay: `LT [${this.text.TOMORROW.toLowerCase()}]`,
      nextWeek: 'LT dddd',
      lastDay: `LT [${this.text.YESTERDAY.toLowerCase()}]`,
      lastWeek: `LT [${this.text.LAST.toLowerCase()}] dddd`,
      sameElse: `LT MMM D, YYYY`,
    };
  }

  private get calendarTimeShortFormats(): moment.CalendarSpec {
    return {
      sameDay: `LT`,
      nextDay: `[${this.text.TOMORROW.toLowerCase()}] LT`,
      nextWeek: 'dddd LT',
      lastDay: `[${this.text.YESTERDAY.toLowerCase()}] LT`,
      lastWeek: `[${this.text.LAST.toLowerCase()}] dddd LT`,
      sameElse: `MMM D LT`,
    };
  }

  constructor(@Inject(TRANSLATE_SERVICE) private translate: SharedTranslateService) {
    this.translate.stream(this.text).subscribe((translatedText) => (this.text = translatedText));
  }

  formatDate(value: string | Date, format = '', timezone?: string): string {
    if (!value || value === '') {
      return '';
    }
    const m = moment(value).locale(this.translate.currentLanguage);

    if (timezone) {
      m.tz(timezone);
    }

    const d = m.toDate();

    switch (format) {
      case 'calendar':
        return m.calendar(null, this.calendarFormats);

      case 'onCalendar':
        return m.calendar(null, this.onCalendarFormats);

      case 'calendarTime':
        return m.calendar(null, this.calendarTimeFormats);

      case 'calendarTimeShort':
        return m.calendar(null, this.calendarTimeShortFormats);

      case 'localeDate':
        return d.toLocaleDateString();

      case 'localeDateShort':
        return d.toLocaleDateString([], this.shortDateOptions);

      case 'localeDateShortest':
        return d.toLocaleDateString([], this.shortestDateOptions);

      case 'localeDateTime':
        return d.toLocaleTimeString([], this.dateTimeOptions);

      case 'fromNow':
        return m.fromNow();

      default:
        return m.format(format);
    }
  }
}
