export interface SecuritySeal {
  tag: string;
  color?: string;
}

export class SecuritySealInstance implements SecuritySeal {
  constructor(
    public tag: string,
    public color?: string,
  ) {}
}
