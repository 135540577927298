import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { WelcomeStepBaseComponent } from '@luggagehero/features/shops';

@Component({
  selector: 'lh-welcome-step',
  templateUrl: './welcome-step.component.html',
  styleUrls: ['./welcome-step.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class WelcomeStepComponent extends WelcomeStepBaseComponent implements OnInit {}
