import { ChangeDetectionStrategy, Component } from '@angular/core';
import { StepNavigationBaseComponent } from '@luggagehero/features/ui';

@Component({
  selector: 'lh-step-navigation',
  templateUrl: './step-navigation.component.html',
  styleUrls: ['./step-navigation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StepNavigationComponent extends StepNavigationBaseComponent {}
