import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { IPaymentRecord } from '@luggagehero/shared/interfaces';
import { SharedUiButtonComponent } from '@luggagehero/shared/ui';
import { PaymentMethodPipe, TranslatePipe } from '@luggagehero/shared/ui-pipes';

// TODO: Move this to shared ui
@Component({
  selector: 'lh-shared-feature-payment-method-picker',
  standalone: true,
  imports: [CommonModule, SharedUiButtonComponent, TranslatePipe, PaymentMethodPipe],
  templateUrl: './shared-feature-payment-method-picker.component.html',
  styleUrl: './shared-feature-payment-method-picker.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SharedFeaturePaymentMethodPickerComponent implements OnInit {
  @Output() public selectedPaymentMethodChange = new EventEmitter<IPaymentRecord>();

  private _paymentMethods: IPaymentRecord[];
  private _selectedPaymentMethod: IPaymentRecord;
  private _isExistingPaymentMethodsCollapsed = false;
  private _isLoading = false;

  private cd = inject(ChangeDetectorRef);

  public get isLoading(): boolean {
    return this._isLoading;
  }
  @Input() public set isLoading(value: boolean) {
    this._isLoading = value;
    this.cd.markForCheck();
  }

  public get paymentMethods(): IPaymentRecord[] {
    return this._paymentMethods;
  }
  @Input() public set paymentMethods(value: IPaymentRecord[]) {
    this._paymentMethods = value;
    this.selectPaymentMethod(value?.length > 0 ? value[0] : null);
  }

  public get selectedPaymentMethod(): IPaymentRecord {
    return this._selectedPaymentMethod;
  }
  @Input() public set selectedPaymentMethod(value: IPaymentRecord) {
    this._selectedPaymentMethod = value;
    this.cd.markForCheck();
  }

  public get isExistingPaymentMethodsCollapsed(): boolean {
    return this._isExistingPaymentMethodsCollapsed;
  }

  public get isAddingNewPaymentMethod(): boolean {
    return !this.selectedPaymentMethod;
  }

  public ngOnInit(): void {
    this.selectExistingPaymentMethod();
  }

  public isSelected(paymentMethod: IPaymentRecord): boolean {
    return this.selectedPaymentMethod && this.selectedPaymentMethod.id === paymentMethod.id;
  }

  public selectNewPaymentMethod(): void {
    this.selectPaymentMethod(null);

    if (this.paymentMethods?.length > 1) {
      this._isExistingPaymentMethodsCollapsed = true;
      this.cd.markForCheck();
    }
  }

  public selectExistingPaymentMethod(): void {
    if (this.paymentMethods?.length > 0) {
      this._isExistingPaymentMethodsCollapsed = false;
      this.selectPaymentMethod(this.paymentMethods[0]);
    }
  }

  public selectPaymentMethod(value: IPaymentRecord): void {
    this.selectedPaymentMethod = value;
    this.cd.markForCheck();
    this.selectedPaymentMethodChange.emit(value);
  }
}
