import { ChangeDetectorRef, Directive, inject, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';

@Directive()
export abstract class BaseComponent implements OnDestroy {
  public destroy$: Subject<unknown> = new Subject();

  private _cd = inject(ChangeDetectorRef);
  private _translateService = inject(TranslateService);

  get currentLang(): string {
    return this._translateService.currentLang;
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  protected _detectChanges(): void {
    this._cd.detectChanges();
  }

  protected _markForCheck(): void {
    this._cd.markForCheck();
  }

  protected _translate(key: string | string[], params?: unknown): string {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return this._translateService.instant(key, params);
  }
}
