import { Inject, Injectable, InjectionToken } from '@angular/core';
import { IHoliday } from '@luggagehero/shared/interfaces';
import { SharedHttpService } from '@luggagehero/shared/services/http';

export const HOLIDAY_SERVICE_ENDPOINT_TOKEN = new InjectionToken<string>('HOLIDAY_SERVICE_ENDPOINT_TOKEN');

@Injectable()
export class SharedHolidayService {
  @Injectable({
    providedIn: 'root',
  })
  private holidays: { [countryCode: string]: IHoliday[] } = {};
  private apiEndPoint: string;

  constructor(
    private httpService: SharedHttpService,
    @Inject(HOLIDAY_SERVICE_ENDPOINT_TOKEN) private holidayEndpoint: string,
  ) {
    this.apiEndPoint = `${holidayEndpoint}/holidays`;
  }

  async initCountry(countryCode: string): Promise<void> {
    if (this.holidays[countryCode]) {
      // The country has already been initialized
      return;
    }
    const url = `${this.apiEndPoint}/list?country=${countryCode}`;
    this.holidays[countryCode] = await this.httpService.get<IHoliday[]>(url);
  }

  getHolidays(countryCode: string): IHoliday[] {
    return this.holidays[countryCode] || [];
  }

  getInstances(countryCode: string, holidayKey: string): string[] {
    const holidays = this.getHolidays(countryCode);
    if (!holidays || holidays.length === 0) {
      return [];
    }
    const holiday = holidays.find((h) => h.key === holidayKey);
    return holiday ? holiday.instances : [];
  }
}
