import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { StorageCriteria } from '@luggagehero/shared/interfaces';
import {
  TravelerShopsUiLanguageSwitcherComponent,
  TravelerShopsUiMenuButtonComponent,
} from '@luggagehero/traveler/shops/ui';
import { TravelerFeatureCriteriaBarComponent } from '@luggagehero/traveler-shops-feature-criteria-bar';

@Component({
  selector: 'lh-traveler-feature-navbar',
  standalone: true,
  imports: [
    CommonModule,
    TravelerShopsUiMenuButtonComponent,
    TravelerShopsUiLanguageSwitcherComponent,
    TravelerFeatureCriteriaBarComponent,
  ],
  templateUrl: './traveler-feature-navbar.component.html',
  styleUrl: './traveler-feature-navbar.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TravelerFeatureNavbarComponent {
  @Input() criteria!: StorageCriteria;
  @Output() criteriaChange = new EventEmitter<StorageCriteria>();

  private _showMap = false;

  @Input()
  set showMap(value: boolean) {
    this._showMap = value;
    this.showMapChange.emit(this._showMap); // Emit the new value to the parent
  }

  get showMap(): boolean {
    return this._showMap;
  }

  @Output() showMapChange = new EventEmitter<boolean>();
}
