import { ActivatedRouteSnapshot, DetachedRouteHandle, RouteReuseStrategy } from '@angular/router';

export class AppRouteReuseStrategy implements RouteReuseStrategy {
  private cache = new Map<string, DetachedRouteHandle>();
  private allowRetrieveCache = {
    home: true,
  };

  shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
    if (this.getPath(curr) === 'shop-details/:id' && this.getPath(future) === 'home') {
      this.allowRetrieveCache.home = true;
    } else {
      this.allowRetrieveCache.home = false;
    }
    return future.routeConfig === curr.routeConfig;
  }

  retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle | null {
    const path = this.getPath(route);
    return this.cache.get(path);
  }

  shouldAttach(route: ActivatedRouteSnapshot): boolean {
    const path = this.getPath(route);

    if (this.allowRetrieveCache[path]) {
      return this.cache.has(path);
    }

    return false;
  }

  shouldDetach(route: ActivatedRouteSnapshot): boolean {
    const path = this.getPath(route);

    if (path in this.allowRetrieveCache) {
      return true;
    }
    return false;
  }

  store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle): void {
    const path = this.getPath(route);
    this.cache.set(path, handle);
  }

  private getPath(route: ActivatedRouteSnapshot): string {
    return route?.routeConfig?.path || '';
  }
}
