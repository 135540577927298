import { ChangeDetectorRef, Component, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { BookableStorageLocation, ITimeInterval, StorageLocationService } from '@luggagehero/shared/interfaces';
import { SharedShopsService } from '@luggagehero/shared/services/shops';
import { SharedStorageCriteriaService } from '@luggagehero/shared/services/storage-criteria';

import { BaseComponent } from '../../../core';
import { DateUtil } from '../../../utils/date.util';
import { DateTimePickerBaseComponent } from './date-time-picker.base-component';

@Component({ template: '' })
export abstract class SimpleTimePeriodPickerBaseComponent extends BaseComponent implements OnInit {
  @ViewChild('fromAnchor') fromAnchor: ElementRef<HTMLAnchorElement>;
  @ViewChild('fromPopup', { read: ElementRef }) fromPopup: ElementRef<HTMLElement>;
  @ViewChild('fromPicker') fromDateTimePicker: DateTimePickerBaseComponent;

  @ViewChild('toAnchor') toAnchor: ElementRef<HTMLAnchorElement>;
  @ViewChild('toPopup', { read: ElementRef }) toPopup: ElementRef<HTMLAnchorElement>;
  @ViewChild('toPicker') toDateTimePicker: DateTimePickerBaseComponent;

  public disabledDates = (date: Date) => this.storageLocationService.isCalendarDateDisabled(date, this.storageLocation);

  private _from: Date;
  private _to: Date;

  private _disableFrom = false;
  private _showFrom = false;
  private _showTo = false;

  constructor(
    private criteriaService: SharedStorageCriteriaService,
    private storageLocationService: SharedShopsService,
    private cd: ChangeDetectorRef,
  ) {
    super();
  }

  public get from(): Date {
    return this._from;
  }
  public set from(value: Date) {
    if (!value || DateUtil.isSameDate(value, this._from)) {
      return;
    }
    this._from = value;
    this._to = DateUtil.getValidTo(value, this._to, false);
    this.updatePeriod();

    // Close the view when from is selected as we are currently only using the from date
    this.toggleFromAndApply();
  }

  public get to(): Date {
    return this._to;
  }
  public set to(value: Date) {
    if (!value || DateUtil.isSameDate(value, this._to)) {
      return;
    }
    this._to = value;
    this._from = DateUtil.getValidFrom(this._from, value);
    this.updatePeriod();
  }

  @Input() public get disableFrom(): boolean {
    return this._disableFrom;
  }
  public set disableFrom(value: boolean) {
    this._disableFrom = value;
    this.cd.markForCheck();
  }

  public get showFrom(): boolean {
    return this._showFrom;
  }
  public set showFrom(value: boolean) {
    this._showFrom = value;
    this.cd.detectChanges();
  }

  public get showTo(): boolean {
    return this._showTo;
  }
  public set showTo(value: boolean) {
    this._showTo = value;
    this.cd.detectChanges();
  }

  public get isSameDayOnly(): boolean {
    return this.storageLocation && !this.storageLocation.services.includes(StorageLocationService.MultiDayStorage);
  }

  get storageLocation(): BookableStorageLocation {
    return this.storageLocationService.current;
  }

  public get dropoffHours(): ITimeInterval[] {
    return this.storageLocationService.getOpeningHoursForDate(this.from, this.storageLocation);
  }

  public get pickupHours(): ITimeInterval[] {
    return this.storageLocationService.getOpeningHoursForDate(this.to, this.storageLocation);
  }

  public get isOpenOnDropoffDate(): boolean {
    const dropoffHours = this.storageLocationService.getOpeningHoursForDate(this.from, this.storageLocation);
    if (!dropoffHours || dropoffHours.length === 0) {
      return false;
    }
    return true;
  }

  public get isOpenOnPickupDate(): boolean {
    const pickupHours = this.storageLocationService.getOpeningHoursForDate(this.to, this.storageLocation);
    if (!pickupHours || pickupHours.length === 0) {
      return false;
    }
    return true;
  }

  // listen for click event and close the popups if matched
  @HostListener('document:click', ['$event'])
  public documentClick(event: Event): void {
    if (
      this.toAnchor?.nativeElement.contains(event.target as Node) ||
      this.toPopup?.nativeElement.contains(event.target as Node) ||
      this.fromAnchor?.nativeElement.contains(event.target as Node) ||
      this.fromPopup?.nativeElement.contains(event.target as Node)
    ) {
      return;
    }

    this.toggleFrom(false);
    this.toggleTo(false);
  }

  public ngOnInit() {
    this._from = this.criteriaService.currentOrDefault.period.from;
    this._to = this.criteriaService.currentOrDefault.period.to;
  }

  public toggleFromAndApply(_arg = null): void {
    if (this.showFrom) {
      this.fromDateTimePicker.apply();
    }
    this.toggleFrom(!this.showFrom);
  }

  public toggleToAndApply(): void {
    if (this.showTo) {
      this.toDateTimePicker.apply();
    }
    this.toggleTo(!this.showTo);
  }

  public toggleFrom(show?: boolean): void {
    this.showFrom = show !== undefined ? show : !this.showFrom;

    if (this.showTo) {
      this.showTo = false;
    }
  }

  public toggleTo(show?: boolean): void {
    this.showTo = show !== undefined ? show : !this.showTo;

    if (this.showFrom) {
      this.showFrom = false;
    }
  }

  public showHolidayWarning(date: Date): boolean {
    return this.storageLocationService.showHolidayWarning(date, this.storageLocation);
  }

  public showConfirmedHolidayHours(date: Date): boolean {
    return (
      this.storageLocationService.isHoliday(date, this.storageLocation) &&
      this.storageLocationService.hasConfirmedHours(date, this.storageLocation)
    );
  }

  public getHolidayName(date: Date): string {
    return this.storageLocationService.getHolidayName(date, this.storageLocation);
  }

  private updatePeriod() {
    this.criteriaService.period = { from: this._from, to: this._to };
  }
}
