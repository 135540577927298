import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppConfig } from '@luggagehero/shared/app-settings/data-access';
import { IUser } from '@luggagehero/shared/interfaces';
import { SharedNotificationService } from '@luggagehero/shared/services/notification';
import { SharedUserService } from '@luggagehero/shared/services/users';
import { SharedWindowService } from '@luggagehero/shared/services/window';
import { TranslateService } from '@ngx-translate/core';
import { ModalDirective } from 'ngx-bootstrap/modal';

import { BaseComponent } from '../../../core';
import { RouterExtensions } from '../../../core/services/index';
@Component({ template: '' })
export abstract class ProfileBaseComponent extends BaseComponent implements OnInit {
  @ViewChild('changePasswordModal') public changePasswordModal: ModalDirective;
  loggedIn = false;
  user: IUser = {};
  isEditingUser = false;
  newName: string;
  newEmail: string;
  newPhone: string;
  newPassword: string;
  currentPassword: string;
  form: FormGroup;

  private _isLoading = false;

  constructor(
    formBuilder: FormBuilder,
    private userService: SharedUserService,
    private notify: SharedNotificationService,
    private window: SharedWindowService,
    private router: RouterExtensions,
    private cd: ChangeDetectorRef,
    private translate: TranslateService,
  ) {
    super();

    const nameRegEx = this.window.unicodeRegEx ? new RegExp(AppConfig.NAME_REGEX, 'u') : AppConfig.NAME_REGEX;
    this.form = formBuilder.group({
      // eslint-disable-next-line @typescript-eslint/unbound-method
      name: ['', [Validators.required, Validators.pattern(nameRegEx)]],
      // eslint-disable-next-line @typescript-eslint/unbound-method
      email: ['', [Validators.required, Validators.pattern(AppConfig.EMAIL_REGEX)]],
      phone: ['', Validators.pattern(AppConfig.PHONE_REGEX)],
    });
  }

  get userIdentifier(): string {
    return this.userService.userIdentifier;
  }

  get ios(): boolean {
    return this.window.iOS;
  }

  get isUserMarkedForDeletion(): boolean {
    return this.user.deletionRequestedAt ? true : false;
  }

  get isLoading(): boolean {
    return this._isLoading;
  }
  set isLoading(value: boolean) {
    this._isLoading = value;
    this.cd.markForCheck();
  }
  get isShopOwner(): boolean {
    return this.user && this.user.isShopOwner;
  }

  get isUserAccountRegisteredForDeletion(): boolean {
    return this.user && this.user.isShopOwner;
  }

  get showDeleteAccount(): boolean {
    return true;
    return this.window.isNative && this.window.iOS;
  }

  async ngOnInit() {
    await this.getUser();
  }

  async getUser() {
    this.loggedIn = await this.userService.isLoggedInAsync();

    if (this.loggedIn) {
      this.user = this.userService.user;
    } else {
      this.user = {};
      void this.router.navigate(['']);
    }
  }

  // logout() {
  //   this.userService.logout();
  //   this.getUser();
  // }

  startEditingUserInfo() {
    this.newName = this.user.name;
    this.newEmail = this.user.email;
    this.newPhone = this.user.phone;

    this.isEditingUser = true;
    this.cd.markForCheck();
  }

  stopEditingUserInfo() {
    this.isEditingUser = false;
    this.cd.markForCheck();
  }

  saveUserInfo() {
    this.user.name = this.newName;
    this.user.email = this.newEmail;
    if (this.newPhone) {
      this.user.phone = this.newPhone.replace(' ', '');
    }
    this.isLoading = true;
    this.userService.updateUser(this.user).then(
      () => {
        this.stopEditingUserInfo();
        this.isLoading = false;
      },
      () => {
        // TODO: Notify user
        this.isLoading = false;
      },
    );
  }

  changePassword() {
    this.newPassword = '';
    this.changePasswordModal.show();
  }

  savePassword() {
    this.isLoading = true;
    this.userService.changeUserPassword(this.currentPassword, this.newPassword).then(
      () => {
        this.newPassword = '';
        this.changePasswordModal.hide();
        this.isLoading = false;
      },
      (_err) => {
        this.notify.error(`Password could not be changed, please try again`);
        this.isLoading = false;
      },
    );
  }

  async deleteUserAccount() {
    this.isLoading = true;
    const confirmationMessage = `${this.translate.instant('DELETE_USER_CONFIRM_1')}\n\n${this.translate.instant(
      'DELETE_USER_CONFIRM_2',
    )}`;
    const confirmed = confirm(confirmationMessage);
    if (confirmed) {
      if (this.user.isShopOwner) {
        window.open('https://luggagehero.typeform.com/to/mI7M98Wl', '_blank');
      } else {
        this.user = await this.userService.deleteUserAccount();
      }

      this.stopEditingUserInfo();
    }

    this.isLoading = false;
  }
}
