import { ChangeDetectorRef, Directive, EventEmitter, inject, Input, OnDestroy, Output } from '@angular/core';
import { Subscription } from 'rxjs';

import { WizardNavigationState, WizardStepProperties } from './wizard-interfaces';

@Directive()
export abstract class WizardStepBaseComponent<T extends WizardStepProperties = WizardStepProperties>
  implements OnDestroy
{
  public stepForward: EventEmitter<void> = new EventEmitter<void>();
  public stepBack: EventEmitter<void> = new EventEmitter<void>();
  public stepAutoSkip: EventEmitter<void> = new EventEmitter<void>();

  @Output() public navigationChange = new EventEmitter<Partial<WizardNavigationState>>();
  @Output() public isLoadingChange = new EventEmitter<boolean>();
  @Output() public propertiesChange = new EventEmitter<T>();

  protected cd = inject(ChangeDetectorRef);

  private _isLoading = false;
  private _navigationState: WizardNavigationState = {
    canGoForward: false,
    canGoBack: true,
    canSkip: false,
    useDefaultNavigation: true,
  };
  private _properties: T | undefined;
  private subsriptions = new Subscription();

  public get isLoading(): boolean {
    return this._isLoading;
  }
  @Input() public set isLoading(value: boolean) {
    this._isLoading = value;
    this.cd.markForCheck();
    this.isLoadingChange.emit(value);
  }

  public get canGoForward(): boolean {
    return this._navigationState.canGoForward;
  }
  @Input() public set canGoForward(value: boolean) {
    this._navigationState.canGoForward = value;
    this.cd.markForCheck();
    this.navigationChange.emit({ canGoForward: value });
  }

  public get canGoBack(): boolean {
    return this._navigationState.canGoBack;
  }
  @Input() public set canGoBack(value: boolean) {
    this._navigationState.canGoBack = value;
    this.cd.markForCheck();
    this.navigationChange.emit({ canGoBack: value });
  }

  public get canSkip(): boolean {
    return this._navigationState.canSkip;
  }
  @Input() public set canSkip(value: boolean) {
    this._navigationState.canSkip = value;
    this.cd.markForCheck();
    this.navigationChange.emit({ canSkip: value });
  }

  /**
   * Determines whether the default wizard navigation button should be used. Set to false if the step component will
   * provide its own navigation controls.
   */
  public get useDefaultNavigation(): boolean {
    return this._navigationState.useDefaultNavigation;
  }
  @Input() public set useDefaultNavigation(value: boolean) {
    this._navigationState.useDefaultNavigation = value;
    this.cd.markForCheck();
    this.navigationChange.emit({ useDefaultNavigation: value });
  }

  public get properties(): T | undefined {
    if (!this._properties) {
      this._properties = this.defaultProperties;
    }
    return this._properties;
  }

  /**
   * Can be overridden by derived classes to provide default properties.
   */
  protected get defaultProperties(): T | undefined {
    return undefined;
  }

  public ngOnDestroy(): void {
    this.subsriptions.unsubscribe();
  }

  /**
   * Applies the provided properties to the step component, leaving in place any existing or default values for
   * properties that are not specified on the provided object.
   * @param properties The properties to apply.
   */
  public applyProperties(properties: Partial<T>): void {
    if (properties) {
      this._properties = this.properties ? { ...this.properties, ...properties } : (properties as T);
      this.cd.markForCheck();
    }
    this.propertiesChange.emit(this._properties);
  }

  public goForward(): Promise<void> {
    if (this.canGoForward || this.canSkip) {
      this.stepForward.emit();
    }
    return Promise.resolve();
  }

  public goBack(): Promise<void> {
    if (this.canGoBack) {
      this.stepBack.emit();
    }
    return Promise.resolve();
  }

  public autoSkip(): Promise<void> {
    if (this.canSkip) {
      this.stepAutoSkip.emit();
    }
    return Promise.resolve();
  }

  protected addSubscription(subscription: Subscription): void {
    this.subsriptions.add(subscription);
  }
}
