<div class="header" *ngIf="review">
  <!-- User profile image -->
  <lh-shared-ui-user-profile-image [name]="userName" size="md" [profileBackgroundColors]="profileBackgroundColors" />

  <div class="username-date">
    <!-- Username -->
    <div class="username">{{ userName }}</div>

    <!-- Date -->
    <div class="date">{{ review.created | lhMoment: 'fromNow' | uppercaseFirstLetter }}</div>
  </div>

  <!-- Rating stars -->
  <div class="rating" *ngIf="isRated">
    <rating
      class="shop-rating-md"
      [(ngModel)]="review.rating"
      [max]="5"
      [readonly]="true"
      *ngIf="review.rating"
    ></rating>
  </div>

  <!-- Recommendation icon -->
  <div class="recommendation" *ngIf="!isRated">
    <i class="icon-recommended" *ngIf="isRecommended"></i>
    <i class="icon-not-recommended" *ngIf="!isRecommended"></i>
  </div>
</div>

<!-- Main review text -->
<div class="body">
  <span *ngIf="isRated">{{ body }}</span>
  <span *ngIf="!isRated && isRecommended">{{ 'RECOMMENDS_THIS_LOCATION' | translate }}</span>
  <span *ngIf="!isRated && !isRecommended">{{ 'DOES_NOT_RECOMMEND_THIS_LOCATION' | translate }}</span>
</div>

<!-- Luggage -->
<div class="footer">{{ luggage }}</div>
