<lh-shared-ui-spinner [visible]="isLoading"></lh-shared-ui-spinner>

<div class="review-list" *ngIf="isShowingRecommendations">
  <div class="review-container" *ngFor="let review of visibleReviews; let i = index">
    <div class="divider" *ngIf="getDividerText(i)">
      <div class="divider-text">{{ getDividerText(i) }}</div>
    </div>
    <lh-traveler-shops-ui-public-review
      [review]="review"
      [profileBackgroundColors]="profileBackgroundColors"
    ></lh-traveler-shops-ui-public-review>
  </div>
</div>

<ul class="list-group" *ngIf="!isShowingRecommendations">
  <a [id]="'selectReview_' + review._id" *ngFor="let review of visibleReviews" class="list-group-item">
    <div class="media-left">
      <img
        height="28"
        width="28"
        [src]="'https://d22r54gnmuhwmk.cloudfront.net/public/img/default-user-avatar-dc6f2da9.gif'"
      />
    </div>
    <div class="media-body">
      <div class="form-group">
        <rating class="shop-rating-md" [(ngModel)]="review.rating" [readonly]="true"></rating>
      </div>
      <h5 class="media-heading review-heading" *ngIf="review.headline">
        {{ (review.translation && review.translation.headline) || review.headline }}
      </h5>

      <div class="review-body" *ngIf="review.shopComments">
        <span *ngIf="!review.showFullBody">
          {{ getSnippet(review)
          }}<span *ngIf="isClipped(review)"
            >...
            <a [id]="'showFullReview_' + review._id" class="href" (click)="review.showFullBody = true">{{
              'MORE' | translate
            }}</a></span
          >
        </span>
        <span *ngIf="review.showFullBody">
          {{ (review.translation && review.translation.shopComments) || review.shopComments }}
          <a [id]="'hideFullReview_' + review._id" class="href" (click)="review.showFullBody = false">{{
            'LESS' | translate
          }}</a>
        </span>
      </div>

      <div class="review-user">
        <span> – </span>
        <span *ngIf="review.userName">{{ review.userName.trim() }}</span>
        <span *ngIf="!review.userName">{{ 'UNNAMED_TRAVELER' | translate }}</span>
      </div>

      <div class="review-relevance">
        <span *ngIf="review.shopId === shop?._id">
          {{ 'STORED' | translate }} {{ getLuggage(review) | booking: 'luggage' }} {{ 'AT_THIS_LOCATION' | translate }}
        </span>

        <span *ngIf="review.shopId !== shop?._id">
          {{ 'USED_LUGGAGEHERO' | translate }}
          <span *ngIf="review.bookingLocation"> {{ 'IN' | translate }} {{ review.bookingLocation | translate }} </span>
        </span>
        <span>{{ review.bookingDate || review.created | lhMoment: 'onCalendar' }}</span>
      </div>
    </div>
  </a>
</ul>

<div class="spinner">
  <button
    id="loadMoreReviews"
    *ngIf="visibleReviews.length > 0 && visibleReviews.length < reviews.length"
    class="btn btn-default btn-load-more"
    (click)="showNextBatch()"
  >
    {{ 'LOAD_MORE_REVIEWS' | translate }}
  </button>
</div>
