import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'lh-traveler-shops-ui-dropdown',
  standalone: true,
  imports: [CommonModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['traveler-shops-ui-dropdown.component.scss'],
  templateUrl: 'traveler-shops-ui-dropdown.component.html',
})
export class TravelerShopsUiDropdownComponent {
  @Input() isOpen = true;
  @Input() topDistance = 'calc(100% + 6px)';
}
