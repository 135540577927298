import { ChangeDetectionStrategy, Component, forwardRef, Provider } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ProductSelectorBaseComponent } from '@luggagehero/features/ui/base/product-selector.base-component';

export const PRODUCT_SELECTOR_CONTROL_VALUE_ACCESSOR: Provider = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => ProductSelectorComponent),
  multi: true,
};

@Component({
  selector: 'lh-product-selector',
  templateUrl: './product-selector.component.html',
  styleUrls: ['./product-selector.component.scss'],
  providers: [PRODUCT_SELECTOR_CONTROL_VALUE_ACCESSOR],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductSelectorComponent extends ProductSelectorBaseComponent implements ControlValueAccessor {}
