import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AbandonBookingBaseComponent } from '@luggagehero/features/shops/base/abandon-booking.base-component';

// TODO: Move this component to the bookings module
@Component({
  selector: 'lh-abandon-booking',
  templateUrl: './abandon-booking.component.html',
  styleUrls: ['./abandon-booking.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AbandonBookingComponent extends AbandonBookingBaseComponent {
  @Input() modalTitle: string;
}
