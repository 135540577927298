import { EnvConfig } from '../environment.interface';

export const environment: EnvConfig = {
  ENV: 'PRODUCTION',
  TRAVELER_API: 'https://traveler-api.luggagehero.com',
  TRAVELER_UI: 'https://app.luggagehero.com',
  IDENTITY_API: 'https://identity-api.luggagehero.com',
  IDENTITY_ST_API: 'https://identity-st-api.luggagehero.com',
  DASHBOARD_API: 'https://dashboard-api.luggagehero.com',
  DASHBOARD_UI: 'https://dashboard.luggagehero.com',
  STORAGE_MANAGER_API: 'https://storage-manager-api.luggagehero.com',
  STORAGE_MANAGER_UI: 'https://storage-manager.luggagehero.com',
  IMAGES_BASE_URL: 'https://static.luggagehero.com',
  FACEBOOK_APP_ID: '546972105509031',
  STRIPE_PUBLISHABLE_KEY: 'pk_live_zp8zuAYAfspjsFZfBIt2MA7Y',
  WOOTRIC_ACCOUNT_TOKEN: 'NPS-daab9e85',
  SUMO_SITE_ID: '5b687000c5b0e9005760240043608e001d871a0094cd1200be20cc008312eb00',
  BOOKINGSYNC_CLIENT_ID: '8dc4a65539f2e316f790141094676d9ab1700d6c89d42b3a8d1189b0a11ada1b',
  BOOKINGSYNC_OAUTH_URL: 'https://www.bookingsync.com/oauth/authorize',
  BOOKINGSYNC_OAUTH_REDIRECT_URI: 'https://partner.luggagehero.com/bookingsync/admin',
  PMS_INTEGRATIONS_API: 'https://pms-integrations-api.luggagehero.com',
  GOOGLE_WEB_CLIENT_ID: '595935798811-bkojh18ok4unniqh1okq8p0q1fbbqp4m.apps.googleusercontent.com',
  INTERCOM_APP_ID: 'gumq1av0',
  CRYPTO_KEY: '3339716b746769573331436d3670697a6d6a6b45746a324f7656307845514970',
  DATADOG_CLIENT_TOKEN: 'pubc6008b30608b6418df348967dbc28a00',
  DATADOG_SITE: 'datadoghq.com',
};
