import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Config } from '@luggagehero/shared/environment';
import { BookableStorageLocation, Booking, StorageCriteria, Swap } from '@luggagehero/shared/interfaces';

const defaultTitle = `Free yourself from luggage | LuggageHero`;

@Injectable({
  providedIn: 'root',
})
export class SharedPageTaggingService {
  private tags: HTMLMetaElement[];

  constructor(
    private title: Title,
    private meta: Meta,
  ) {}

  disableCrawling() {
    // this.clearTags();
    // this.tags = this.meta.addTags([
    //   { name: 'robots', content: 'noindex,nofollow' },
    // ]);
  }

  addBookingTags(booking: Booking) {
    if (!booking) {
      return;
    }
    this.clearTags();

    const title = `LuggageHero booking at ${booking.shopName} in ${booking.address.city}`;
    const description = `Manage your booking - view payment options, get directions to the storage location, and start/stop the storage timer to enable insurance of your luggage while it is in storage.`;
    this.tags = this.meta.addTags([
      { property: 'og:url', content: `https://app.luggagehero.com/bookings/${booking._id}` },
      { property: 'og:title', content: title },
      { property: 'og:description', content: description },
      { property: 'og:image', content: `assets/no-image.jpg` },
    ]);
    this.title.setTitle(title);
  }

  addSwapTags(swap: Swap) {
    if (!swap) {
      return;
    }
    this.clearTags();

    const title = `SwapHero swap`;
    const description = `Manage your swap.`;
    this.tags = this.meta.addTags([
      { property: 'og:url', content: `https://app.swaphero.io/swaps/${swap._id}` },
      { property: 'og:title', content: title },
      { property: 'og:description', content: description },
      { property: 'og:image', content: `assets/no-image.jpg` },
    ]);
    this.title.setTitle(title);
  }

  addShopTags(shop: BookableStorageLocation) {
    if (!shop) {
      return;
    }
    this.clearTags();

    const desc = `Let ${shop.name.trim()} keep your luggage safe while you explore ${shop.address.city}.`;
    this.tags = this.meta.addTags([
      // Facebook tags
      { property: 'fb:app_id', content: Config.environment.FACEBOOK_APP_ID },
      { property: 'og:type', content: 'place' },
      { property: 'og:url', content: `https://app.luggagehero.com/shop-details/${shop._id}` },
      { property: 'og:title', content: `${shop.name.trim()} on LuggageHero` },
      { property: 'og:description', content: desc },
      {
        property: 'og:image',
        content: `${Config.environment.IMAGES_BASE_URL}/images/large/${shop.images[0]}`,
      },
      { property: 'place:location:latitude', content: `${shop.location.coordinates[1]}` },
      { property: 'place:location:longitude', content: `${shop.location.coordinates[0]}` },

      // SEO tags
      { name: 'description', content: this.getShopDescription(shop) },
      { name: 'keywords', content: this.getShopKeywords(shop) },
      { name: 'robots', content: 'index,follow' },
    ]);
    this.title.setTitle(`${this.getShopTitle(shop)} | LuggageHero`);
  }

  clearTags() {
    if (this.tags && this.tags.length > 0) {
      this.tags.forEach((element) => this.meta.removeTagElement(element));
    }
    this.title.setTitle(defaultTitle);
  }

  addFindShopTags(criteria: StorageCriteria) {
    if (!criteria) {
      return;
    }
    this.clearTags();

    this.meta.addTags([
      // SEO tags
      { name: 'description', content: this.getFindShopDescription(criteria) },
      { name: 'keywords', content: this.getFindShopKeywords(criteria) },
      { name: 'robots', content: 'index,follow' },
    ]);
    this.title.setTitle(`${this.getFindShopTitle(criteria)} | LuggageHero`);
  }

  private getShopTitle(shop: BookableStorageLocation): string {
    if (shop.meta && shop.meta.title && shop.meta.title !== '') {
      return shop.meta.title;
    }
    return `Store your luggage at ${shop.name.trim()} while exploring ${shop.address.city}`;
  }

  private getShopDescription(shop: BookableStorageLocation): string {
    if (shop.meta && shop.meta.description && shop.meta.description !== '') {
      return shop.meta.description;
    }
    return (
      `Luggage storage at ${shop.name.trim()} in ${shop.address.city}. ` +
      `LuggageHero offers left luggage services to travelers using local shops. All luggage is insured. Book now!`
    );
  }

  private getShopKeywords(shop: BookableStorageLocation): string {
    if (shop.meta && shop.meta.keywords && shop.meta.keywords !== '') {
      return shop.meta.keywords;
    }
    return (
      `luggage, storage, left-luggage, travel, shop, local, hourly, insurance, insured, safe, tourism, ` +
      `${shop.address.city}, ${shop.address.country}`
    );
  }

  private getFindShopTitle(criteria: StorageCriteria): string {
    return `Store your luggage at a local shop near ${criteria.location.address}`;
  }

  private getFindShopDescription(criteria: StorageCriteria): string {
    return (
      `Luggage storage in ${criteria.location.address}. ` +
      `LuggageHero offers left luggage services to travelers using local shops. €1/hour per item. All luggage is insured. Book now!`
    );
  }

  private getFindShopKeywords(criteria: StorageCriteria): string {
    return (
      `luggage, storage, left-luggage, travel, shop, local, hourly, insurance, insured, safe, tourism, ` +
      `${criteria.location.address}, ${criteria.location.region}`
    );
  }
}
