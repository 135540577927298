import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';
import { AvailabilityContext, TRANSLATE_SERVICE } from '@luggagehero/shared/interfaces';

@Component({
  selector: 'lh-traveler-shops-ui-availability-display',
  standalone: true,
  imports: [CommonModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['traveler-shops-ui-availability-display.component.scss'],
  templateUrl: 'traveler-shops-ui-availability-display.component.html',
})
export class TravelerShopsUiAvailabilityDisplayComponent {
  @Input() public availability!: AvailabilityContext;

  private translate = inject(TRANSLATE_SERVICE);

  public get isOpen(): boolean {
    return (
      this.availability.isForCurrentDay &&
      this.availability.openTime != null &&
      this.availability.closeTime != null &&
      !this.availability.isPermanentlyClosed
    );
  }

  public getTranslatedText(key: string): string {
    return this.translate.instant(key);
  }
}
