import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { PromoCodeBaseComponent } from '@luggagehero/features/ui';
import { SharedPromoCodeService } from '@luggagehero/shared/services/promo-codes';
import { SharedStorageCriteriaService } from '@luggagehero/shared/services/storage-criteria';

@Component({
  selector: 'lh-promo-code',
  templateUrl: './promo-code.component.html',
  styleUrls: ['./promo-code.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PromoCodeComponent extends PromoCodeBaseComponent {
  constructor(
    promoCodeService: SharedPromoCodeService,
    criteriaService: SharedStorageCriteriaService,
    cd: ChangeDetectorRef,
  ) {
    super(promoCodeService, criteriaService, cd);
  }
}
