import { ChangeDetectionStrategy, Component } from '@angular/core';
import { UserAvatarBaseComponent } from '@luggagehero/features/ui';

@Component({
  selector: 'lh-user-avatar',
  templateUrl: 'user-avatar.component.html',
  styleUrls: ['user-avatar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserAvatarComponent extends UserAvatarBaseComponent {}
