import { ChangeDetectionStrategy, Component, Type } from '@angular/core';
import { AbandonBookingBaseComponent, ConfirmBookingBaseComponent } from '@luggagehero/features/shops';
import { PromoCodeComponent } from '@luggagehero/web/features/ui';

import { AbandonBookingComponent } from '../abandon-booking/abandon-booking.component';

@Component({
  selector: 'lh-confirm-booking',
  templateUrl: './confirm-booking.component.html',
  styleUrls: ['./confirm-booking.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmBookingComponent extends ConfirmBookingBaseComponent {
  public get abandonBookingComponentType(): Type<AbandonBookingBaseComponent> {
    return AbandonBookingComponent;
  }

  public async showPromoCodeModal(): Promise<void> {
    await this.modalService.show({ component: PromoCodeComponent });
  }
}
