<div
  class="storage-timer"
  [class.large-digits]="largeDigits"
  [class.compact-timer]="compactMode"
  [class.dummy-timer]="!booking"
  [class.timer-border]="border"
  [class.dark-mode]="darkMode"
>
  <label [hidden]="compactMode" class="storage-timer-title label-sm">
    {{ 'STORAGE_TIMER' | translate }}
  </label>

  <span *ngIf="days">{{ days }}</span>
  <span *ngIf="days">{{ 'STORAGE_TIMER_DAYS' | translate }}</span>

  <span>{{ hours }}</span>
  <span>{{ 'STORAGE_TIMER_HOURS' | translate }}</span>

  <span>{{ minutes }}</span>
  <span>{{ 'STORAGE_TIMER_MINUTES' | translate }}</span>

  <span>{{ seconds }}</span>
  <span>{{ 'STORAGE_TIMER_SECONDS' | translate }}</span>
</div>
