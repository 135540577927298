import { inject, Injectable } from '@angular/core';
import { getAPIUpsertPayload, taxItemFromAPIResponse } from '@luggagehero/dashboard/taxes/util';
import { Config } from '@luggagehero/shared/environment';
import { GetTaxesResponse, TaxItem, TaxItemResponse } from '@luggagehero/shared/interfaces';
import { SharedHttpService } from '@luggagehero/shared/services/http';
import { from, map, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class DashboardTaxesService {
  private readonly httpService = inject(SharedHttpService);
  private readonly apiUrl: string = `${Config.environment.STORAGE_MANAGER_API}/storage_locations`;

  private constructUrl(storageLocationId: string, path = ''): string {
    return `${this.apiUrl}/${storageLocationId}/${path}`;
  }

  getTaxes(storageLocationId: string): Observable<TaxItem[]> {
    const url = this.constructUrl(storageLocationId, 'taxes');
    return from(this.httpService.get<GetTaxesResponse>(url, true)).pipe(
      map((taxItems) => taxItems.map(taxItemFromAPIResponse)),
    );
  }

  addTax(storageLocationId: string, payload: TaxItem): Observable<TaxItem> {
    const url = this.constructUrl(storageLocationId, 'taxes');
    const data = getAPIUpsertPayload(payload);
    return from(this.httpService.post<TaxItemResponse>(url, data, true)).pipe(
      map((taxItem) => taxItemFromAPIResponse(taxItem)),
    );
  }

  updateTax(storageLocationId: string, payload: TaxItem): Observable<TaxItem> {
    const url = this.constructUrl(storageLocationId, `taxes/${payload._id}`);
    const data = getAPIUpsertPayload({ ...payload, _id: undefined });
    return from(this.httpService.put<TaxItemResponse>(url, data, true)).pipe(
      map((taxItem) => taxItemFromAPIResponse(taxItem)),
    );
  }

  deleteTax(storageLocationId: string, id: string): Observable<unknown> {
    const url = this.constructUrl(storageLocationId, `taxes/${id}`);
    return from(this.httpService.delete(url, true));
  }
}
