<lh-storage-location-map
  *ngIf="location"
  (bookingRequest)="makeBooking($event)"
  (detailsRequest)="viewShopDetails($event)"
  (centerChange)="onCenterChange($event)"
  [center]="mapCenter"
  [myLocation]="mapCenter"
  [zoom]="location.zoom || defaultZoom"
  [maxZoom]="maxZoom"
  [clickableIcons]="mapOptions.clickableIcons"
  [keyboardShortcuts]="mapOptions.keyboardShortcuts"
  [fullscreenControl]="mapOptions.fullscreenControl"
  [scaleControl]="mapOptions.scaleControl"
  [streetViewControl]="mapOptions.streetViewControl"
  [zoomControl]="mapOptions.zoomControl"
  [zoomControlOptions]="mapOptions.zoomControlOptions"
  [disableDoubleClickZoom]="mapOptions.disableDoubleClickZoom"
  [scrollwheel]="mapOptions.scrollwheel"
  [draggable]="mapOptions.draggable"
  [gestureHandling]="mapOptions.gestureHandling"
  [styles]="mapStyles"
  [storageLocations]="visibleShops"
  [highlightedStorageLocationId]="highlightShop"
  [initialOpenStorageLocationId]="initialSelectedShopId"
  [enableStorageLocationInfoWindows]="true"
  [enableStorageLocationMarkerNotice]="true"
  #storageLocationMap
></lh-storage-location-map>
