import { ChangeDetectorRef, Component, OnDestroy, OnInit, Type } from '@angular/core';
import { AppConfig } from '@luggagehero/shared/app-settings/data-access';
import { Booking, ILuggage, SmartImage } from '@luggagehero/shared/interfaces';
import { SharedBookingService } from '@luggagehero/shared/services/bookings';
import { SharedOrderService } from '@luggagehero/shared/services/orders';
import { SharedShopsService } from '@luggagehero/shared/services/shops';
import { SharedStorageService } from '@luggagehero/shared/services/storage';
import { SharedUserService } from '@luggagehero/shared/services/users';

import { BookingActionBaseComponent } from './booking-action.base-component';
import { BookingActionInfo } from './booking-action-info';
import { ValidateBookingActionBaseComponent } from './validate-booking-action.base-component';

const SHOPS_WITH_LUGGAGE_IMAGE_REQUIRED = AppConfig.SHOPS_WITH_LUGGAGE_IMAGE_REQUIRED || [];

@Component({ template: '' })
export abstract class DropOffActionBaseComponent extends BookingActionBaseComponent implements OnInit, OnDestroy {
  public remoteLuggageImage: SmartImage;
  public isSlideToConfirmEnabled =
    AppConfig.IS_SLIDE_TO_CONFIRM_ENABLED && AppConfig.IS_STORAGE_TIMER_IN_DROPOFF_ACTION_ENABLED;

  public isWalkIn: boolean;

  private isCheckingIn = false;
  private _numberOfBags: number;
  private _bagsSelected = false;
  private _bagsSecured = false;
  private _expandBagPicker = false;
  private allowSkipBagPhoto = false;
  private isBagPhotoCompleted = false;

  constructor(
    protected orderService: SharedOrderService,
    protected userService: SharedUserService,
    bookingService: SharedBookingService,
    shopsService: SharedShopsService,
    storageService: SharedStorageService,
    cd: ChangeDetectorRef,
  ) {
    super(bookingService, shopsService, storageService, cd);
  }

  get isImageLoading(): boolean {
    if (this.isCheckingIn) {
      return false;
    }
    return this.isLoading;
  }

  get isCheckinAllowed(): boolean {
    if (this.isLoading) {
      return false;
    }

    if (!this.bagsSelected) {
      return false;
    }

    if (this.isLuggageImageRequired && !this.isBagPhotoCompleted && !this.allowSkipBagPhoto) {
      return false;
    }

    return true;
  }

  get isLuggageImageRequired(): boolean {
    if (SHOPS_WITH_LUGGAGE_IMAGE_REQUIRED.includes(this.shop._id)) {
      return true;
    }
    if (this.shopsService.current.compulsoryBagPhoto) {
      return true;
    }
    return false;
  }

  get isStorageTimerEnabled(): boolean {
    return AppConfig.IS_STORAGE_TIMER_IN_DROPOFF_ACTION_ENABLED;
  }

  get numberOfBags(): number {
    return this._numberOfBags;
  }
  set numberOfBags(value: number) {
    this._numberOfBags = value;
    this.cd.markForCheck();
  }

  get bagsSelected(): boolean {
    return this._bagsSelected;
  }
  set bagsSelected(value: boolean) {
    this._bagsSelected = value;
    this.cd.markForCheck();

    if (value) {
      this.expandBagPicker = false;
    }
  }

  get bagsSecured(): boolean {
    return this._bagsSecured;
  }
  set bagsSecured(value: boolean) {
    this._bagsSecured = value;
    this.cd.markForCheck();
  }

  get expandBagPicker(): boolean {
    return this._expandBagPicker;
  }
  set expandBagPicker(value: boolean) {
    this._expandBagPicker = value;
    this.cd.markForCheck();
  }

  get insuranceCoverage(): number {
    return this.shop.pricing.insuranceCoverage;
  }

  protected abstract get validateBookingActionComponentType(): Type<ValidateBookingActionBaseComponent>;

  public get showDropoffConfirmationAction(): BookingActionInfo {
    return new BookingActionInfo('ShowDropoffConfirmationFromDropOff')
      .withCallToAction(this._translate('SHOW_DROPOFF_CONFIRMATION_LONG'))
      .withAction({ componentToShow: this.validateBookingActionComponentType });
  }

  async ngOnInit() {
    await super.ngOnInit();

    if (this.booking.luggage.smartImages?.length > 0) {
      this.remoteLuggageImage = this.booking.luggage.smartImages[0];
    }

    this.isWalkIn =
      this.booking.price.addOns?.walkIn || this.booking.metadata?.lh_booking_category === 'walk_in' || false;
    this.numberOfBags = this.booking.luggage.normal + this.booking.luggage.hand;
    this.bagsSelected = this.numberOfBags !== 2 || this.isWalkIn;
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  async checkIn() {
    this.isCheckingIn = true;
    this.isLoading = true;
    try {
      if (this.numberOfBags !== this.booking.luggage.normal) {
        const minBags = this.orderService.getMinimumBags(this.isWalkIn);
        const updatedLuggage: ILuggage = {
          normal: Math.max(this.numberOfBags, minBags),
          hand: 0,
        };
        // Request new quote with the adjusted number of bags
        const updatedRequest = this.orderService.getRequestForQuote(
          this.booking.shopId,
          this.booking.price.pricingModel,
          updatedLuggage,
          this.booking.period,
          this.booking.price.addOns,
          this.booking.orderDetails[0]?.orderRequest?.optionalProducts,
          this.booking.price.discountCode,
        );
        const updatedOrder = await this.orderService.generateQuote(updatedRequest);

        // Modify the booking to use the new quote
        await this.bookingService.modifyBooking(this.booking._id, updatedOrder._id);
      }
      const res = await this.bookingService.checkIn(this.booking._id);
      this.updateBooking(res);
      this.close();

      if (AppConfig.IS_DROPOFF_CONFIRMATION_ACTION_ENABLED) {
        this.requestAction(this.showDropoffConfirmationAction);
      }
    } catch (err) {
      // TODO: Notify user
    } finally {
      this.isCheckingIn = false;
      this.isLoading = false;
    }
  }

  public onBookingChange(booking: Booking) {
    if (booking.luggage.smartImages?.length > 0) {
      this.remoteLuggageImage = booking.luggage.smartImages[0];
    }

    this.updateBooking(booking);
  }

  public onBagPhotoCompletedChange(completed: boolean) {
    this.isBagPhotoCompleted = completed;
    this.cd.markForCheck();
  }

  public onAllowSkipBagPhotoChange(allowSkip: boolean) {
    this.allowSkipBagPhoto = allowSkip;
    this.cd.markForCheck();
  }
}
