import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { BookableStorageLocation, Booking, ITimeInterval } from '@luggagehero/shared/interfaces';
import { SharedGoogleMapsService } from '@luggagehero/shared/services/google-maps';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';

import { BaseComponent } from '../../../core';

@Component({ template: '' })
export abstract class BookingInfoBaseComponent extends BaseComponent {
  private _booking: Booking;
  private _shop: BookableStorageLocation;

  constructor(
    private gms: SharedGoogleMapsService,
    private translate: TranslateService,
    private cd: ChangeDetectorRef,
  ) {
    super();
  }

  @Input() set booking(value: Booking) {
    this._booking = value;
    this.cd.markForCheck();
  }
  get booking(): Booking {
    return this._booking;
  }

  @Input() set shop(value: BookableStorageLocation) {
    this._shop = value;
    this.cd.markForCheck();
  }
  get shop(): BookableStorageLocation {
    return this._shop;
  }

  get isCheckedIn(): boolean {
    return ['CHECKED_IN', 'CHECKED_OUT', 'PAID'].includes(this.booking.status);
  }

  get isCheckedOut(): boolean {
    return ['CHECKED_OUT', 'PAID'].includes(this.booking.status);
  }

  get openingHours(): ITimeInterval[] {
    if (!this.booking || !this.shop) {
      return null;
    }
    const day = moment(this.booking.period.from).day();
    switch (day) {
      case 0:
        return this.shop.openingHours.sunday;
      case 1:
        return this.shop.openingHours.monday;
      case 2:
        return this.shop.openingHours.tuesday;
      case 3:
        return this.shop.openingHours.wednesday;
      case 4:
        return this.shop.openingHours.thursday;
      case 5:
        return this.shop.openingHours.friday;
      case 6:
        return this.shop.openingHours.saturday;
    }
    return null;
  }

  get weekday(): string {
    if (!this.booking) {
      return null;
    }
    const day = moment(this.booking.period.from).day();
    switch (day) {
      case 0:
        return this.translate.instant('SUNDAY') as string;
      case 1:
        return this.translate.instant('MONDAY') as string;
      case 2:
        return this.translate.instant('TUESDAY') as string;
      case 3:
        return this.translate.instant('WEDNESDAY') as string;
      case 4:
        return this.translate.instant('THURSDAY') as string;
      case 5:
        return this.translate.instant('FRIDAY') as string;
      case 6:
        return this.translate.instant('SATURDAY') as string;
    }
    return '';
  }
}
