export enum HowItWorksScenario {
  default,
  walkIn,
  mobileApp,
}

export interface HowItWorksSlide {
  text: {
    header: string;
    body: string;
  };
  image: {
    url: string;
    frame?: boolean;
    round?: boolean;
  } | null;
}

export interface HowItWorksConfig {
  intro: {
    inline: boolean;
    headline: string;
    tagline: string;
  };
  slides: HowItWorksSlide[];
}
