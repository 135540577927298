<!-- Date picker -->
<div class="datepicker-wrapper">
  <kendo-calendar
    [min]="minDate"
    [max]="maxDate"
    [(value)]="selectedDate"
    [disabledDates]="disabledDates"
    [navigation]="false"
    [class.calendar-background]="isSelectTimeEnabled"
  ></kendo-calendar>

  <div *ngIf="isSelectTimeEnabled" class="time-explanation">
    <button
      id="closeSelectTime"
      type="button"
      class="btn close pull-right"
      aria-label="Close"
      (click)="disableSelectTime($event)"
    >
      <svg viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
        />
        <path d="M0 0h24v24H0z" fill="none" />
      </svg>
    </button>

    <label>{{ 'EXPECTED_TIME' | translate }}</label
    ><br />
    <small>{{ 'EXPECTED_TIME_EXPLANATION' | translate }}</small>
  </div>
</div>

<!-- <div class="timepicker-wrapper" (click)="$event.stopPropagation()">
  <div class="timepicker">
    <div *ngIf="!isSelectTimeEnabled">
      <span><a id="enableSelectTime" (click)="enableSelectTime($event)" class="href">{{ 'SELECT_TIME' | translate }}</a> ({{ 'OPTIONAL' | translate | lowercase }})</span>
    </div>

    <div *ngIf="isSelectTimeEnabled">
      <timepicker [(ngModel)]="selectedTime" [min]="minTime" [hourStep]="1" [minuteStep]="30" [showMeridian]="is12HourMode" [readonlyInput]="false"></timepicker>
    </div>
  </div>
</div> -->
