import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  inject,
  Input,
  Output,
} from '@angular/core';
import { TranslatePipe } from '@luggagehero/shared/ui-pipes';

type GooglePayButtonType = 'plain' | 'book' | 'pay';
type GooglePayButtonTheme = 'dark' | 'light';
type GooglePayButtonSize = 'small' | 'medium' | 'large';

@Component({
  selector: 'lh-shared-ui-google-pay-button',
  standalone: true,
  imports: [CommonModule, TranslatePipe],
  templateUrl: './shared-ui-google-pay-button.component.html',
  styleUrl: './shared-ui-google-pay-button.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SharedUiGooglePayButtonComponent {
  @Input() public type: GooglePayButtonType = 'plain';
  @Input() public theme: GooglePayButtonTheme = 'dark';
  @Input() public size: GooglePayButtonSize = 'medium';
  @Input() public rounded = false;
  @Input() public elevated = false;

  @Output() public readonly press = new EventEmitter<Event>();

  private _selected = false;
  private _disabled = false;
  private _isLoading = false;
  private _id = '';

  private readonly cd = inject(ChangeDetectorRef);

  public get id(): string {
    return this._id || 'googlePayButton';
  }
  @Input() public set id(value: string) {
    this._id = value;
    this.cd.markForCheck();
  }

  public get isLoading(): boolean {
    return this._isLoading;
  }
  @Input() public set isLoading(value: boolean) {
    this._isLoading = value;
    this.cd.markForCheck();
  }

  public get disabled(): boolean {
    return this._disabled;
  }
  @Input() public set disabled(value: boolean) {
    this._disabled = value;
    this.cd.markForCheck();
  }

  public get selected(): boolean {
    return this._selected;
  }
  @Input() public set selected(value: boolean) {
    this._selected = value;
    this.cd.markForCheck();
  }

  public get callToAction(): string {
    switch (this.type) {
      case 'book':
        return 'BOOK_WITH_PAYMENT_PROVIDER';

      case 'pay':
        return 'PAY_WITH_PAYMENT_PROVIDER';

      case 'plain':
      default:
        return '';
    }
  }

  public onClick(event: Event): void {
    if (this.disabled || this.isLoading) {
      event.preventDefault();
      event.stopPropagation();
      return;
    }
    this.press.emit(event);
  }
}
