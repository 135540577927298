import { inject, Injectable } from '@angular/core';
import {
  IPricing,
  ITranslateService,
  PricingModel,
  PricingService,
  TRANSLATE_SERVICE,
} from '@luggagehero/shared/interfaces';
import { BehaviorSubject, map, Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SharedPricingService implements PricingService {
  // Translation keys needed by the service
  private text = {
    FROM: '',
    PER_DAY_SHORT: '',
    PER_HOUR_SHORT: '',
    INSURANCE_FEE: '',
    PER_BAG: '',
    NEVER_MORE_THAN: '',
    DAY: '',
    HOUR: '',
  };

  public transformPricing(
    pricing: IPricing,
    includePerBag = true,
    includeDailyPrice = true,
    includeHourlyPrice = true,
    includeStartupFee = true,
    includeFrom = false,
    html = false,
  ): Observable<string | null> {
    if (!pricing) {
      return of(null);
    }

    return this.translateService.get(this.text).pipe(
      map((localizedText) => {
        this.text = localizedText;

        const currency = pricing.currency;
        const startupFee = pricing.startupFee;
        const hourlyRate = pricing.hourlyRate;
        const firstDayMax = pricing.firstDayMax;

        const firstSpanBegin = html ? '<span class="pricing">' : '';
        const spanBegin = html ? '<span class="pricing-item">' : '';
        const spanEnd = html ? '</span>' : '';

        let output = includeFrom ? `${firstSpanBegin}${this.text.FROM} ` : firstSpanBegin;

        if (includeDailyPrice && !includeHourlyPrice) {
          const perDayText = this.text.PER_DAY_SHORT.toLowerCase();
          output += `${spanBegin}${this.format(firstDayMax, currency)}${spanEnd}${spanBegin}${perDayText}${spanEnd}`;
        } else {
          const perHourText = this.text.PER_HOUR_SHORT.toLowerCase();
          output += `${spanBegin}${this.format(hourlyRate, currency)}${spanEnd}${spanBegin}${perHourText}${spanEnd}`;
        }

        if (includeStartupFee && pricing.startupFee > 0) {
          const startupText = this.text.INSURANCE_FEE.toLowerCase();
          output += ` + ${spanBegin}${this.format(startupFee, currency)}${spanEnd} ${spanBegin}${startupText}${spanEnd}`;
        }

        if (includePerBag) {
          output += ` ${this.text.PER_BAG.toLowerCase()}`;
        }

        if (includeDailyPrice && includeHourlyPrice) {
          const dailyPriceText = this.text.NEVER_MORE_THAN.toLowerCase();
          const perDayText = this.text.PER_DAY_SHORT.toLowerCase();
          const firstDayPriceText = this.format(firstDayMax, currency);
          output += ` ${dailyPriceText} ${spanBegin}${firstDayPriceText}${spanEnd}${spanBegin}${perDayText}${spanEnd}`;
        }

        output = `${output}${spanEnd}`;

        return output;
      }),
    );
  }

  private translateService = inject<ITranslateService>(TRANSLATE_SERVICE);
  private _pricingModel = new BehaviorSubject<PricingModel>('hourly');

  public insuranceSelected = true;
  public freeCancellationFeeSelected = false;
  public numberOfSecuritySeals = 0;

  public get pricingModel$(): Observable<PricingModel> {
    return this._pricingModel;
  }

  public get pricingModel(): PricingModel {
    return this._pricingModel.value;
  }

  changePricing(value: PricingModel): void {
    this._pricingModel.next(value);
  }

  formatPricingModel(pricingModel: PricingModel): string | null {
    switch (pricingModel) {
      case 'daily':
        return this.translateService.instant('DAY');

      case 'hourly':
        return this.translateService.instant('HOUR');

      default:
        return null;
    }
  }

  getCurrencyPrefix(currency: string): string {
    switch (currency.toLowerCase()) {
      case 'eur':
        return '€';
      case 'usd':
        return '$';
      case 'gbp':
        return '£';
      case 'dkk':
        return 'kr ';
      case 'nok':
        return 'kr ';
      case 'sek':
        return 'kr ';
      default:
        return currency.toUpperCase();
    }
  }

  format(price: number, currency: string, decimalCount = 2, removeZeroDecimals = true): string {
    if (price === undefined || price === null) {
      price = 0;
    }
    let currencySymbol = '';
    let currencyString = '';
    switch (currency.toLowerCase()) {
      case 'eur':
        currencySymbol = '€';
        break;

      case 'usd':
        currencySymbol = '$';
        break;

      case 'gbp':
        currencySymbol = '£';
        break;

      case 'dkk':
        currencySymbol = 'kr ';
        break;

      case 'nok':
        currencySymbol = 'kr ';
        break;

      case 'sek':
        currencySymbol = 'kr ';
        break;

      default:
        currencyString = ` ${currency.toUpperCase()}`;
        break;
    }
    let sign = '';
    if (price < 0) {
      price *= -1;
      sign = '- ';
    }
    const priceString = this.getPriceString(price, decimalCount, removeZeroDecimals);
    return `${sign}${currencySymbol}${priceString}${currencyString}`;
  }

  private getPriceString(price: number, decimalCount: number, removeZeroDecimals: boolean, localize = true): string {
    let priceString: string = price.toFixed(decimalCount);

    if (decimalCount > 0 && removeZeroDecimals && priceString.includes('.')) {
      const parts = priceString.split('.');
      const decimalString = parts[1];

      let allZeroes = true;

      for (let i = 0; i < decimalString.length; i++) {
        if (decimalString.charAt(i) !== '0') {
          allZeroes = false;
          break;
        }
      }

      if (allZeroes) {
        priceString = priceString.substring(0, parts[0].length);
      } else {
        removeZeroDecimals = false;
      }
    }

    if (localize) {
      try {
        // Try to add thousand separators
        priceString = parseFloat(priceString).toLocaleString(undefined, {
          maximumFractionDigits: decimalCount,
          minimumFractionDigits: removeZeroDecimals ? 0 : decimalCount,
        });
      } catch {
        // Ignore errors
      }
    }

    return priceString;
  }
}
