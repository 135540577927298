import { ChangeDetectionStrategy, Component } from '@angular/core';
import { PaymentStepBaseComponent } from '@luggagehero/features/shops';

@Component({
  selector: 'lh-payment-card-step',
  templateUrl: './payment-card-step.component.html',
  styleUrls: ['./payment-card-step.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class PaymentCardStepComponent extends PaymentStepBaseComponent {}
