import { HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Config } from '@luggagehero/shared/environment';
import {
  AppleAuthResponse,
  FacebookAuthResponse,
  GoogleAuthResponse,
  IUser,
  Result,
} from '@luggagehero/shared/interfaces';
import { SharedAnalyticsService } from '@luggagehero/shared/services/analytics';
import { SharedHttpService } from '@luggagehero/shared/services/http';
import { IntercomSettings, SharedIntercomService } from '@luggagehero/shared/services/intercom';
import { SharedLoggingService } from '@luggagehero/shared/services/logging';
import { SharedStorageService } from '@luggagehero/shared/services/storage';
import { SharedWindowService } from '@luggagehero/shared/services/window';
import { isDummyUserName } from '@luggagehero/shared/util';
import jwt_decode from 'jwt-decode';
import { BehaviorSubject, filter } from 'rxjs';

export interface INewUser {
  email: string;
  password: string;
  name: string;
  phone?: string;
  phoneCountry?: string;
  sendPassword?: boolean;
  locale?: string;
  appStoreReviewRequested?: boolean;
  playStoreReviewRequested?: boolean;
  isShopOwner?: boolean;
  metadata?: unknown;
}

export interface ILogin {
  phoneNumber?: string;
  phoneCountry?: string;
  locale?: string;
  email?: string;
  password: string;
  otp?: boolean;
}

export interface IShowLogin {
  show: boolean;
  redirectTo: string;
  register?: boolean;
  userEmail?: string;
  goHomeOnAbort?: boolean;
}

export interface LoginEvent {
  isLoggedIn: boolean;
  userLoggedOut: boolean;
}

export interface AccessToken {
  exp: number;
  iat: number;
  scope: string;
  sub: string;
}

export interface AuthenticateAppleUserRequest {
  identityToken: string;
  familyName?: string;
  givenName?: string;
  locale?: string;
}

export interface AuthenticateFacebookUserRequest {
  accessToken: string;
}

export interface AuthenticateGoogleUserRequest {
  code: string;
  idToken: string;
}

export interface AuthenticateUserResponse {
  success: boolean;
  message: string;
  accessToken: string;
  firstTimeUser: boolean;
  isImpersonated?: boolean;
  isAdmin?: boolean;
}

export interface CheckUserRequest {
  email?: string;
  phone?: string;
}

export interface CheckUserResponse {
  userFound: boolean;
}

export class AuthPassthroughError extends Error {
  constructor() {
    super(`Authentication is not required for this action`);

    Object.setPrototypeOf(this, AuthPassthroughError.prototype);
    this.name = AuthPassthroughError.name;
  }
}

@Injectable({
  providedIn: 'root',
})
export class SharedUserService {
  protected _usersEndpoint: string;

  get UsersEndpoint() {
    return this._usersEndpoint;
  }

  public signUpEvent$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public signInEvent$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isNewUser = false;
  public showLogin: BehaviorSubject<IShowLogin> = new BehaviorSubject({
    show: false,
    redirectTo: '',
  });
  public loginAnnounced: BehaviorSubject<LoginEvent> = new BehaviorSubject({
    isLoggedIn: false,
    userLoggedOut: false,
  });
  public bookingRequested = false;
  public redirectTo = '';

  protected _user: IUser = undefined;

  constructor(
    protected router: Router,
    protected http: SharedHttpService,
    protected storageService: SharedStorageService,
    protected intercom: SharedIntercomService,
    protected analytics: SharedAnalyticsService,
    protected windowService: SharedWindowService,
    protected log: SharedLoggingService,
  ) {
    this._usersEndpoint = `${Config.environment.IDENTITY_API}/users`;
    this.http.error$.pipe(filter((e) => e === HttpStatusCode.Unauthorized)).subscribe(() => this.logout());
  }

  get userIdentifier(): string {
    if (!this.user) {
      return '';
    }

    if (this.user.name && !isDummyUserName(this.user.name)) {
      // If we have a real name, use that
      return this.user.name;
    }

    if (this.user.phone && this.user.phoneVerified) {
      // Otherwise, if we have a verified phone number, use that
      return this.user.phone;
    }

    if (this.isGuestUser || !this.user.email) {
      return this.user.name || 'Guest User';
    }

    // Fall back to email address
    return this.user.email;
  }

  public get user(): IUser {
    //
    // Always using user info from local storage if available to avoid issues
    // when multiple service instances exist because of lazy loading
    //
    if (this.storageService.profile) {
      this._user = this.storageService.profile;
    }
    return this._user;
  }
  public set user(user: IUser) {
    this._user = user;
    this.storageService.profile = this._user;

    void this.isLoggedInAsync().then((isLoggedIn) => {
      this.showLoginView(!isLoggedIn, this.redirectTo);
      this.announceLogin(isLoggedIn);
    });
  }

  public get accessToken() {
    return this.storageService.token;
  }
  public set accessToken(token) {
    this.storageService.token = token;
  }

  async isLoggedInAsync(): Promise<boolean> {
    return Promise.resolve(this.isLoggedIn);
  }

  public get isLoggedIn(): boolean {
    if (!this.accessToken) {
      return false;
    }
    const jwt = jwt_decode<AccessToken>(this.accessToken);
    const currentTime = Date.now() / 1000;
    const isTokenValid = jwt.exp > currentTime;
    return isTokenValid;
  }

  public get notificationCount(): number {
    if (!this.isLoggedIn || !this.user || !this.user.stats) {
      return 0;
    }
    return this.user.stats.totalActiveBookings;
  }

  public get isImpersonatedUser(): boolean {
    return this.storageService.isImpersonator;
  }

  public get isGuestUser(): boolean {
    return this.isGuestUserEmail(this.user?.email);
  }

  public async markReviewRequestedAsTrue(platform: string) {
    const url = `${this.UsersEndpoint}/mark_review_as_requested`;
    const payload = { platform };

    const _result = await this.http.post<Response>(url, payload, true);
  }

  public async markAbandonReasonRequestedAsTrue() {
    this._user.abandonReasonRequested = true;
    this.storageService.profile = this._user;
    const url = `${this.UsersEndpoint}/mark_abandon_reason_as_requested`;

    const _result = await this.http.post<Response>(url, {}, true);
  }

  public async requestOneTimePasswordViaEmail(email: string): Promise<void> {
    await this.requestOneTimePassword({ email });
  }

  public async requestOneTimePasswordViaPhone(phoneNumber: string): Promise<void> {
    await this.requestOneTimePassword({ phoneNumber });
  }

  // TODO: Move to string util?
  public isGuestUserEmail(email: string): boolean {
    if (!email) {
      return false;
    }
    email = email.trim().toLowerCase();
    return email.startsWith('guest_') && email.endsWith('@luggagehero.com');
  }

  // TODO: Move to string util?
  public isMobileUserEmail(email: string): boolean {
    if (!email) {
      return false;
    }
    email = email.trim().toLowerCase();
    return email.startsWith('mobile_') && email.endsWith('@luggagehero.com');
  }

  // TODO: Move to string util?
  public isDummyUserEmail(email: string): boolean {
    return this.isGuestUserEmail(email) || this.isMobileUserEmail(email);
  }

  private async requestOneTimePassword(payload: { phoneNumber?: string; email?: string }): Promise<void> {
    const url = `${this.UsersEndpoint}/authenticate/otp`;

    // Submit request to the API
    const request = this.http.post<Result>(url, payload, false);

    // Add minimum delay for the user
    await new Promise((resolve) => setTimeout(resolve, 1000));

    // Get the response
    const res = await request;

    void this.log.info(`Got OTP response`, { res });

    if (!res.success) {
      // For now interpret 200 responses with success false as OTP bypass
      throw new AuthPassthroughError();
    }
  }

  public loginWithPhone(credentials: ILogin): Promise<AuthenticateUserResponse> {
    return this.login(credentials, 'phone');
  }

  public loginWithEmail(credentials: ILogin): Promise<AuthenticateUserResponse> {
    return this.login(credentials, 'email');
  }

  private async login(credentials: ILogin, method: 'email' | 'phone'): Promise<AuthenticateUserResponse> {
    const url = `${this.UsersEndpoint}/authenticate/${method}`;

    // Submit request to the API
    const request = this.http.post<AuthenticateUserResponse>(url, credentials, false);

    // Add minimum delay for the user
    await new Promise((resolve) => setTimeout(resolve, 2000));

    // Get the response
    const res = await request;

    if (res.success) {
      this.accessToken = res.accessToken;

      // Make sure we track if this is a signup event
      this.isNewUser = res.firstTimeUser ? true : false;
      // this.storageService.isImpersonatedUser = res.isImpersonated ? true : false;
      // this.storageService.isAdminUser = res.isAdmin ? true : false;
    }

    return res;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public isImpersonationMode(): boolean {
    return false;
  }

  public softLogout() {
    this.announceLogin(false, true);
  }

  public logout() {
    this.facebookLogout();
    this.intercom.shutdown();

    this._user = {};

    // TODO: Should we clear everything in local storage or only token and user info?
    this.storageService.clearUserData();
    // this.storageService.clear();

    this.announceLogin(false, true);
  }

  // anounce login changes
  public announceLogin(isLoggedIn: boolean, userLoggedOut = false) {
    this.loginAnnounced.next({ isLoggedIn, userLoggedOut });
  }

  public enableUserTracking() {
    if (this.isLoggedIn && this.user) {
      this.analytics.identify({ userId: this.user._id });
    }
  }

  public checkIfEmailUserExists(email: string): Promise<boolean> {
    return this.checkIfUserExists({ email });
  }

  public checkIfPhoneUserExists(phone: string): Promise<boolean> {
    return this.checkIfUserExists({ phone });
  }

  private async checkIfUserExists(payload: CheckUserRequest): Promise<boolean> {
    const url = `${this.UsersEndpoint}/check`;

    const result = await this.http.post<CheckUserResponse>(url, payload, false);

    return result.userFound;
  }

  public showSmoothSignIn() {
    this.showLoginView(true, undefined, false, undefined);
  }

  public showLoginView(
    show: boolean,
    redirectTo?: string,
    register?: boolean,
    userEmail?: string,
    goHomeOnAbort?: boolean,
  ) {
    this.redirectTo = redirectTo;
    this.showLogin.next({
      show,
      redirectTo,
      register,
      userEmail,
      goHomeOnAbort,
    });
  }

  public async authenticateAppleUser(
    credentials: AppleAuthResponse,
    locale: string,
  ): Promise<AuthenticateUserResponse> {
    const url = `${this.UsersEndpoint}/authenticate/apple`;

    const payload: AuthenticateAppleUserRequest = { identityToken: credentials.identityToken, locale };
    if (credentials.givenName) {
      payload.givenName = credentials.givenName;
    }
    if (credentials.familyName) {
      payload.familyName = credentials.familyName;
    }

    const response = await this.http.post<AuthenticateUserResponse>(url, payload, false);

    // Store access token
    this.accessToken = response.accessToken;

    // Make sure we track if this is a signup event
    this.isNewUser = response.firstTimeUser ? true : false;

    return response;
  }

  public async authenticateFacebookUser(credentials: FacebookAuthResponse): Promise<AuthenticateUserResponse> {
    const url = `${this.UsersEndpoint}/authenticate/facebook`;
    const payload: AuthenticateFacebookUserRequest = { accessToken: credentials.accessToken };

    const response = await this.http.post<AuthenticateUserResponse>(url, payload, false);

    // Store access token
    this.accessToken = response.accessToken;

    // Make sure we track if this is a signup event
    this.isNewUser = response.firstTimeUser ? true : false;

    return response;
  }

  public async authenticateGoogleUser(credentials: GoogleAuthResponse): Promise<AuthenticateUserResponse> {
    const url = `${this.UsersEndpoint}/authenticate/google`;
    const payload: AuthenticateGoogleUserRequest = {
      code: credentials.code,
      idToken: credentials.idToken,
    };

    const response = await this.http.post<AuthenticateUserResponse>(url, payload, false);

    // Store access token
    this.accessToken = response.accessToken;

    // Make sure we track if this is a signup event
    this.isNewUser = response.firstTimeUser ? true : false;

    return response;
  }

  public async getUserInfo(refresh = false): Promise<IUser> {
    const url = new URL(`${this.UsersEndpoint}/me`);

    if (refresh) {
      url.searchParams.append('refresh', 'true');
    }

    this.user = await this.http.get<IUser>(url.toString(), true);

    if (!refresh) {
      this.trackUser(this.user);
    }

    return this.user;
  }

  public async registerUser(newUser: INewUser): Promise<AuthenticateUserResponse> {
    const url = this.UsersEndpoint;
    const res = await this.http.post<AuthenticateUserResponse>(url, this.serializeNewUser(newUser), false);

    if (res.success) {
      this.accessToken = res.accessToken;

      // Make sure we track that this is a signup event
      this.isNewUser = true;
    }

    return res;
  }

  public async getUserAndRedirect(redirectTo?: string): Promise<void> {
    await this.getUserInfo();

    redirectTo = redirectTo || this.redirectTo;

    if (redirectTo) {
      setTimeout(() => {
        void this.router.navigateByUrl(redirectTo);
      }, 500);
    }
  }

  public changeUserPassword(oldPassword: string, newPassword: string): Promise<unknown> {
    const url = `${this.UsersEndpoint}/me/password`;
    return this.http.put(url, { oldPassword, newPassword }, true);
  }

  public resetPasswordViaEmail(email: string): Promise<unknown> {
    const url = `${this.UsersEndpoint}/password_reset`;
    return this.http.post(url, { email }, false);
  }

  public resetPasswordViaPhone(phone: string): Promise<unknown> {
    const url = `${this.UsersEndpoint}/password_reset`;
    return this.http.post(url, { phone }, false);
  }

  public async updateUser(value: IUser): Promise<IUser> {
    const url = `${this.UsersEndpoint}/me`;
    this.user = await this.http.put<IUser>(url, this.serializeUser(value), true);
    return this.user;
  }

  public async deleteUserAccount(): Promise<IUser> {
    const url = `${this.UsersEndpoint}/me`;
    this.user = await this.http.delete<IUser>(url, true);
    return this.user;
  }

  private facebookLogout() {
    if (!this._user || this._user.source !== 'facebook') {
      return;
    }
    // TODO: Should we ever log out of facebook?
    // this.fb.logout();
  }

  private trackUser(user: IUser) {
    const settings: IntercomSettings = {
      userId: user._id,
      email: user.email,
      phone: user.phone,
      name: user.name,
      createdAt: new Date(user.dateCreated),
      customAttributes: {
        phoneCountry: user.phoneCountry,
        phoneVerified: user.phoneVerified,
        isStorageManager: user.isShopOwner,
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        metadata: this.storageService.trackingData as unknown as string,
      },
    };

    if (this.storageService.variant) {
      settings.customAttributes.experimentVariant = this.storageService.variant;
    }

    // Record user info in Intercom
    this.intercom.boot(settings);

    // Set user id to be included in analytics events
    this.analytics.identify({ userId: user._id });

    if (this.isNewUser) {
      this.signUpEvent$.next(true);
      this.analytics.track('signUp', {
        category: 'user',
        label: user.source,
        event: 'userChange',
        user,
      });

      // Reset new user flag so we only send the signup event once
      this.isNewUser = false;
    }
    this.signInEvent$.next(true);
    this.analytics.track('signIn', {
      category: 'user',
      label: user.source,
      event: 'sessionChange',
      user,
    });
  }

  protected serializeNewUser(user: INewUser): INewUser {
    if (user.phone && user.phone.startsWith('00')) {
      user.phone = `+${user.phone.substring(2)}`;
    }
    if (user.phoneCountry) {
      user.phoneCountry = user.phoneCountry.toLowerCase();
    }
    // If we have locale on the user, keep it, otherwise take from storage or ultimately browser
    user.locale = user.locale || this.storageService.language || this.windowService.locale;

    if (this.storageService.trackingData) {
      user.metadata = this.storageService.trackingData;
    }

    return user;
  }

  private serializeUser(user: IUser): IUser {
    if (user.phone && user.phone.startsWith('00')) {
      user.phone = `+${user.phone.substring(2)}`;
    }
    if (user.phoneCountry) {
      user.phoneCountry = user.phoneCountry.toLowerCase();
    }
    return user;
  }
}
