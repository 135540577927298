import { Inject, Injectable, InjectionToken } from '@angular/core';
import { Config } from '@luggagehero/shared/environment';
import { SharedHttpService } from '@luggagehero/shared/services/http';
import { cloneDeep } from '@luggagehero/shared/util';
import { Observable, ReplaySubject } from 'rxjs';

import { AppConfig } from './app-config';
import { AppSettings } from './interfaces';
import { SharedAppSettings } from './shared-app-settings';

export const APP_SETTINGS_SERVICE_ENDPOINT_TOKEN = new InjectionToken<string>('APP_SETTINGS_SERVICE_ENDPOINT_TOKEN');

@Injectable({
  providedIn: 'root',
})
export class SharedAppSettingsService {
  /**
   * Observable of the current settings
   */
  public $current: Observable<SharedAppSettings>;

  private apiEndPoint: string;
  private settings: ReplaySubject<SharedAppSettings>;
  private _current?: SharedAppSettings;
  private isInitialized = false;

  private default: { [platform: string]: AppSettings } = {
    android: {
      minAppVersion: '90',
    },
    ios: {
      minAppVersion: '2.29.0',
    },
    web: {
      minAppVersion: '1.0.0',
    },
  };

  /**
   * Returns the current settings value
   */
  public get current(): SharedAppSettings | undefined {
    return this._current;
  }

  constructor(
    private http: SharedHttpService,
    @Inject(APP_SETTINGS_SERVICE_ENDPOINT_TOKEN) private appSettingsServiceEndpoint: string,
  ) {
    this.settings = new ReplaySubject<SharedAppSettings>();
    this.$current = this.settings.asObservable();
    this.apiEndPoint = appSettingsServiceEndpoint;
  }

  public async init(appName: string, platform: string, defaultSettings?: Partial<SharedAppSettings>): Promise<void> {
    if (this.isInitialized) {
      return;
    }
    this.isInitialized = true;

    const appSettings: AppSettings = await this.fetchRemoteSettings(appName, platform);
    this._current = this.createSharedAppSettings(appSettings, defaultSettings);
    this.settings.next(this._current);
  }

  public updateSettings(appSettings: AppSettings): void {
    this._current = this.createSharedAppSettings(appSettings, this._current);
    this.settings.next(this._current);
  }

  private async fetchRemoteSettings(appName: string, platform: string): Promise<AppSettings> {
    let appSettings = this.default[platform];

    try {
      const url = `${this.apiEndPoint}/settings?appName=${appName}&platform=${platform}`;
      appSettings = await this.http.get<AppSettings>(url);
    } catch (err) {
      if (!Config.isProduction) {
        console.log(`Failed to load app settings from server`, err);
      }
    }

    return appSettings;
  }

  private createSharedAppSettings(
    appSettings: AppSettings,
    defaultSettings: Partial<SharedAppSettings> = {},
  ): SharedAppSettings {
    // Start with default settings
    const settings = cloneDeep(defaultSettings);

    // Override the default settings in AppConfig with app settings retrieved from the server
    AppConfig.override(appSettings);

    // Loop through all AppConfig fields and add them to the settings object
    for (const key of Object.keys(AppConfig)) {
      const value = AppConfig[key];
      if (typeof value !== 'function') {
        settings[key] = value;
      }
    }

    return settings as SharedAppSettings;
  }
}
