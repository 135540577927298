import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { RegisterBaseComponent } from '@luggagehero/features/user';
import { SharedErrorService } from '@luggagehero/shared/services/error';
import { SharedLoggingService } from '@luggagehero/shared/services/logging';
import { SharedUserService } from '@luggagehero/shared/services/users';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'lh-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RegisterComponent extends RegisterBaseComponent {
  constructor(
    loginService: SharedUserService,
    error: SharedErrorService,
    translate: TranslateService,
    log: SharedLoggingService,
    cd: ChangeDetectorRef,
  ) {
    super(loginService, error, translate, log, cd);
  }
}
