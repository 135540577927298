import { inject, Pipe, PipeTransform } from '@angular/core';
import { PRICING_SERVICE, PricingService } from '@luggagehero/shared/interfaces';

@Pipe({ name: 'price', standalone: true })
export class PricePipe implements PipeTransform {
  private pricingService = inject<PricingService>(PRICING_SERVICE);

  transform(price: number, currency: string, decimalCount = 2, removeZeroDecimals = true): string {
    return this.pricingService.format(price, currency, decimalCount, removeZeroDecimals);
  }
}
