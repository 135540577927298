import { ChangeDetectorRef, Component, inject, Input } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import {
  BookableStorageLocation,
  ILocation,
  ILuggage,
  IPrice,
  IPricing,
  ITimePeriod,
  PricingModel,
  StorageCriteria,
} from '@luggagehero/shared/interfaces';
import { SharedLocationService } from '@luggagehero/shared/services/locations';
import { SharedPricingService } from '@luggagehero/shared/services/pricing';
import { SharedShopsService } from '@luggagehero/shared/services/shops';
import { SharedStorageCriteria } from '@luggagehero/shared/services/storage-criteria';
import { Observable } from 'rxjs';

import { BaseComponent } from '../../../core';

const noop = () => {
  /**/
};

@Component({ template: '' })
export abstract class ShopCriteriaBaseComponent extends BaseComponent implements ControlValueAccessor {
  public disabledDates = (date: Date) => this.storageLocationService.isCalendarDateDisabled(date, this.shop);

  private storageLocationService = inject(SharedShopsService);

  private _inPage = false;
  private _shop: BookableStorageLocation;
  private _estimatedPrice: IPrice;
  private _pricing: IPricing;
  private _showLocation = true;
  private _showPrice = true;
  private _value: StorageCriteria = new SharedStorageCriteria();
  private _location: ILocation;
  private _period: ITimePeriod;
  private _luggage: ILuggage;
  private _isCollapsed = true;
  private _isDirty = false;
  private _hasStartupFee = false;

  private onTouchedCallback: () => void = noop;
  private onChangeCallback: (_) => void = noop;

  constructor(
    private locationService: SharedLocationService,
    private priceService: SharedPricingService,
    private cd: ChangeDetectorRef,
  ) {
    super();
  }

  get pricingModel(): Observable<PricingModel> {
    return this.priceService.pricingModel$;
  }

  get shop(): BookableStorageLocation {
    return this._shop;
  }
  @Input() set shop(value: BookableStorageLocation) {
    this._shop = value;
    this.cd.markForCheck();
  }

  get showPrice(): boolean {
    return this._showPrice;
  }
  @Input() set showPrice(value: boolean) {
    this._showPrice = value;
    this.cd.markForCheck();
  }

  get showLocation(): boolean {
    return this._showLocation;
  }
  @Input() set showLocation(value: boolean) {
    this._showLocation = value;
    this.cd.markForCheck();
  }

  get estimatedPrice(): IPrice {
    return this._estimatedPrice;
  }
  @Input() set estimatedPrice(value: IPrice) {
    this._estimatedPrice = value;
    this.cd.markForCheck();
  }

  get pricing(): IPricing {
    return this._pricing;
  }
  @Input() set pricing(value: IPricing) {
    this._pricing = value;
    this.cd.markForCheck();

    this.hasStartupFee = value && value.startupFee > 0;
  }

  get inPage(): boolean {
    return this._inPage;
  }
  @Input() set inPage(value: boolean) {
    this._inPage = value;
    this.cd.markForCheck();
  }

  get isCollapsed(): boolean {
    return this._isCollapsed;
  }
  set isCollapsed(value: boolean) {
    this._isCollapsed = value;
    this.cd.markForCheck();
  }

  get hasStartupFee(): boolean {
    return this._hasStartupFee;
  }
  set hasStartupFee(value: boolean) {
    this._hasStartupFee = value;
    this.cd.markForCheck();
  }

  get value(): StorageCriteria {
    return this._value;
  }
  set value(value: StorageCriteria) {
    if (!value || value === this._value) {
      return;
    }
    this._value = value;
    this._location = value.location;
    this._luggage = value.luggage;
    this._period = value.period;

    this.onChangeCallback(value);
    this.cd.markForCheck();
  }

  get officialLocations(): ILocation[] {
    return this.locationService.officialLocations;
  }

  get isDirty(): boolean {
    return this._isDirty;
  }
  set isDirty(value: boolean) {
    this._isDirty = value;

    if (value) {
      this.applyCriteria();
    }
  }

  get location(): ILocation {
    return this._location;
  }
  set location(value: ILocation) {
    this._location = value;
    this.isDirty = true;
  }

  get period(): ITimePeriod {
    return this._period;
  }
  set period(value: ITimePeriod) {
    this._period = value;
    this.isDirty = true;
  }

  get luggage(): ILuggage {
    return this._luggage;
  }
  set luggage(value: ILuggage) {
    this._luggage = value;
    this.isDirty = true;
  }

  writeValue(value: StorageCriteria) {
    if (value !== undefined) {
      this.value = value;
    }
  }

  registerOnChange(fn: (_) => void) {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: () => void) {
    this.onTouchedCallback = fn;
  }

  applyCriteria() {
    this.isCollapsed = true;

    if (this.isDirty) {
      this.value = new SharedStorageCriteria(this.location, this.period, this.luggage);
      this.isDirty = false;
    }
  }
}
