<div class="btn-group-bags form-group">
  <!-- Enumerate inline bag options -->
  <button
    *ngFor="let bags of inlineOptions"
    [id]="'selectBags' + bags"
    class="btn"
    [class.btn-info]="!isNumberOfBagsSelected(bags)"
    [class.active]="isNumberOfBagsSelected(bags)"
    [class.compact]="compact"
    (click)="selectBags(bags)"
    [disabled]="disabled"
  >
    <span>{{ bags }}</span>
    <span *ngIf="addBagSuffix">&nbsp;{{ (bags === 1 ? 'BAG' : 'BAGS') | translate | lowercase }}</span>
  </button>

  <!-- Use dropdown for more bags -->
  <select
    id="showMoreBagOptions"
    class="btn"
    [class.btn-info]="!bagsSelected || value <= maxInlineBags"
    [class.active]="bagsSelected && value > maxInlineBags"
    [class.compact]="compact"
    [(ngModel)]="moreBagsSelectedOption"
    [disabled]="disabled"
    #dropdown
  >
    <option [ngValue]="undefined" disabled selected hidden>
      <span>•••</span>
    </option>

    <option *ngFor="let bags of moreBagsOptions" [ngValue]="bags">
      <span>{{ bags }}</span>
      <span *ngIf="addBagSuffix">&nbsp;{{ (bags === 1 ? 'BAG' : 'BAGS') | translate | lowercase }}</span>
    </option>
  </select>
</div>
