<div *ngIf="mediaAccess">
  <div *ngIf="isEmbeddedCameraEnabled" class="user-media-container">
    <div class="camera-container" *ngIf="isTakingPhoto">
      <webcam
        id="camera"
        name="camera"
        class="webcam"
        [allowCameraSwitch]="isCameraSwitchingEnabled"
        (imageCapture)="onCameraImageCapture($event)"
        (cameraSwitched)="onCameraInitSuccess()"
        (initError)="onCameraInitError($event)"
        #camera
      ></webcam>
      <div class="loader" *ngIf="!isCameraInitialized && isCameraLoaderEnabled">
        <lh-shared-ui-spinner [visible]="true"></lh-shared-ui-spinner>
        <strong>{{ 'CAMERA_STARTING' | lhTranslate }}</strong>
      </div>
    </div>

    <div class="image-wrapper">
      <div
        class="image-container"
        [class.processing]="isLoading"
        [class.no-image]="!remoteImage"
        [remoteImage]="remoteImage"
        [remoteImageSize]="'medium'"
        [localImage]="localImage"
        [imagePlaceholder]="imagePlaceholder"
        [baseUrl]="baseUrl"
        lhSharedDirectiveBackgroundImage
        *ngIf="!isTakingPhoto"
      ></div>
      <div class="image-spinner" *ngIf="isLoading">
        <lh-shared-ui-spinner [visible]="true" [darkMode]="true"></lh-shared-ui-spinner>
        <span *ngIf="loadingMessage">{{ loadingMessage | lhTranslate }}</span>
      </div>
    </div>

    <div class="camera-cta" [class.simple]="callToAction" [class.retake]="(remoteImage || localImage) && !isLoading">
      <button
        id="takeEmbeddedPhotoButton"
        class="btn btn-lg btn-warning"
        (click)="takePhoto()"
        [disabled]="!isCameraInitialized || isLoading"
        *ngIf="isTakingPhoto || isLoading"
      >
        <i class="camera-icon mb-1">
          <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            width="32"
            height="32"
            viewBox="0 0 32 32"
          >
            <title>camera</title>
            <path
              d="M9.5 19c0 3.59 2.91 6.5 6.5 6.5s6.5-2.91 6.5-6.5-2.91-6.5-6.5-6.5-6.5 2.91-6.5 6.5zM30 8h-7c-0.5-2-1-4-3-4h-8c-2 0-2.5 2-3 4h-7c-1.1 0-2 0.9-2 2v18c0 1.1 0.9 2 2 2h28c1.1 0 2-0.9 2-2v-18c0-1.1-0.9-2-2-2zM16 27.875c-4.902 0-8.875-3.973-8.875-8.875s3.973-8.875 8.875-8.875c4.902 0 8.875 3.973 8.875 8.875s-3.973 8.875-8.875 8.875zM30 14h-4v-2h4v2z"
            ></path>
          </svg>
        </i>

        <span *ngIf="callToAction">{{ callToAction | translate }}</span>
      </button>

      <button
        id="retakeEmbeddedPhotoButton"
        class="btn btn-default"
        [class.btn-transparent]="!promoteRetake"
        (click)="retakePhoto()"
        *ngIf="!isTakingPhoto && !isLoading"
      >
        {{ 'RETAKE_PHOTO' | translate }}
      </button>
    </div>
  </div>

  <div *ngIf="!isEmbeddedCameraEnabled" class="image-input-container">
    <!-- Hidden input for native camera or file picker -->
    <input
      id="imageInput"
      name="imageInput"
      type="file"
      class="image-input"
      capture="environment"
      [accept]="allowedMimeTypes.join(',')"
      (change)="onFileSelected($event)"
      (click)="onFileInputClick($event)"
      (cancel)="onFileInputCancel($event)"
      #fileInput
    />

    <!-- Image and call-to-action for taking photo of the luggage -->
    <label class="take-photo" for="imageInput">
      <div class="image-wrapper">
        <div
          class="image-container"
          [class.processing]="isLoading"
          [class.no-image]="!remoteImage"
          [remoteImage]="remoteImage"
          [remoteImageSize]="'medium'"
          [localImage]="localImage"
          [imagePlaceholder]="imagePlaceholder"
          [baseUrl]="baseUrl"
          lhSharedDirectiveBackgroundImage
        ></div>
        <div class="image-spinner" *ngIf="isLoading">
          <lh-shared-ui-spinner [visible]="true" [darkMode]="true"></lh-shared-ui-spinner>
          <span *ngIf="loadingMessage">{{ loadingMessage | translate }}</span>
        </div>
      </div>

      <div class="camera-cta" [class.simple]="callToAction" [class.retake]="remoteImage || localImage">
        <a class="btn btn-lg btn-warning" *ngIf="isTakingPhoto && !remoteImage && !localImage">
          <i class="camera-icon mb-1">
            <svg
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              width="32"
              height="32"
              viewBox="0 0 32 32"
            >
              <title>camera</title>
              <path
                d="M9.5 19c0 3.59 2.91 6.5 6.5 6.5s6.5-2.91 6.5-6.5-2.91-6.5-6.5-6.5-6.5 2.91-6.5 6.5zM30 8h-7c-0.5-2-1-4-3-4h-8c-2 0-2.5 2-3 4h-7c-1.1 0-2 0.9-2 2v18c0 1.1 0.9 2 2 2h28c1.1 0 2-0.9 2-2v-18c0-1.1-0.9-2-2-2zM16 27.875c-4.902 0-8.875-3.973-8.875-8.875s3.973-8.875 8.875-8.875c4.902 0 8.875 3.973 8.875 8.875s-3.973 8.875-8.875 8.875zM30 14h-4v-2h4v2z"
              ></path>
            </svg>
          </i>

          <span *ngIf="callToAction">{{ callToAction | translate }}</span>
        </a>

        <a
          class="btn btn-default"
          [class.btn-transparent]="!promoteRetake"
          *ngIf="(remoteImage || localImage) && !isTakingPhoto && !isLoading"
        >
          {{ 'RETAKE_PHOTO' | translate }}
        </a>
      </div>
    </label>
  </div>
</div>

<div *ngIf="!mediaAccess" class="no-camera">
  <div class="dummy-image-container">
    <div class="alert alert-warning">
      <p>{{ 'NO_CAMERA_ACCESS_WARNING' | translate }}</p>
    </div>
  </div>

  <div class="camera-cta retake" [class.simple]="callToAction">
    <button id="retryCameraAccessButton" class="btn btn-warning" (click)="reset()">
      {{ 'TRY_AGAIN' | translate }}
    </button>
  </div>
</div>
