import { Action, createReducer, on } from '@ngrx/store';

import * as OrdersActions from './orders.actions';
import { OrdersState } from './orders.models';

export const initialOrderState: OrdersState = {
  order: null,
  loading: false,
  error: null,
};

const reducer = createReducer(
  initialOrderState,
  on(OrdersActions.createOrder, (state) => ({ ...state, loading: true, error: null })),
  on(OrdersActions.createOrderSuccess, (state, { order }) => ({
    ...state,
    order,
    loading: false,
  })),
  on(OrdersActions.createOrderFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),
);

export function ordersReducer(state: OrdersState | undefined, action: Action) {
  return reducer(state, action);
}
