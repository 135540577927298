import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { SharedAppSettingsService } from '@luggagehero/shared/app-settings/data-access';
import { TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { catchError, map, Observable, switchMap, take } from 'rxjs';

import { TranslationMap } from './+state';
import { SharedTranslationsService } from './shared-translations.service';

@Injectable()
export class SharedTranslateLoader implements TranslateLoader {
  public static get loadedLanguage$(): Observable<string[]> {
    return SharedTranslateLoader.loadedLanguages.asObservable();
  }

  private static loadedLanguages = new EventEmitter<string[]>();
  private static languageSet = new Set<string>();
  private _staticAssetLoader: TranslateHttpLoader;

  constructor(
    private translationsService: SharedTranslationsService,
    private appSettingsService: SharedAppSettingsService,
    private http: HttpClient,
  ) {}

  private get staticAssetLoader(): TranslateHttpLoader {
    if (!this._staticAssetLoader) {
      this._staticAssetLoader = new TranslateHttpLoader(this.http, 'assets/i18n/', '.json');
    }
    return this._staticAssetLoader;
  }

  public getTranslation(lang: string): Observable<TranslationMap> {
    // Try to load app text from API; fall back to static assets
    return this.getTranslationFromApi(lang).pipe(catchError(() => this.getTranslationFromAssets(lang)));
  }

  private getTranslationFromApi(lang: string): Observable<TranslationMap> {
    return this.appSettingsService.$current.pipe(
      take(1),
      switchMap((appSettings) => {
        if (!appSettings.isApiBasedAppTranslationsEnabled) {
          throw new Error('API based translations disabled');
        }
        // Load translations from the API
        return this.translationsService.getAppTranslations(lang).pipe(
          map((res) => {
            SharedTranslateLoader.registerLoadedLanguage(lang);
            return res;
          }),
        );
      }),
    );
  }

  private getTranslationFromAssets(lang: string): Observable<TranslationMap> {
    return this.staticAssetLoader.getTranslation(lang).pipe(
      map((res) => {
        SharedTranslateLoader.registerLoadedLanguage(lang);
        return res as TranslationMap;
      }),
    );
  }

  private static registerLoadedLanguage(lang: string): void {
    if (SharedTranslateLoader.languageSet.has(lang)) {
      return;
    }
    SharedTranslateLoader.languageSet.add(lang);
    SharedTranslateLoader.loadedLanguages.emit(Array.from(SharedTranslateLoader.languageSet));
  }
}
