<div class="dropdown" dropdown>
  <button id="langSwitcherDropdownToggle" type="button" class="btn dropdown-toggle" dropdownToggle>
    {{ selectedLanguageTitle }}
  </button>

  <ul class="dropdown-menu dropdown-menu-right" *dropdownMenu role="menu">
    <li *ngFor="let lang of supportedLanguages">
      <a (click)="changeLang(lang)" class="href">{{ lang.title }}</a>
    </li>
  </ul>
</div>
