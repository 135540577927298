import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SharedPlacesSearchDropdownService {
  private open = new BehaviorSubject<boolean>(false);
  public isOpen = this.open.asObservable();

  constructor() {
    this.open.next(false);
  }

  public setIsOpen(nextState: boolean) {
    this.open.next(nextState);
  }
}
