import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  inject,
  Input,
  NgZone,
  Output,
  ViewChild,
} from '@angular/core';
import { FormsModule } from '@angular/forms';

import { TravelerShopsUiDropdownComponent } from '../dropdown/traveler-shops-ui-dropdown.component';

@Component({
  selector: 'lh-traveler-shops-ui-places-search',
  standalone: true,
  imports: [CommonModule, FormsModule, TravelerShopsUiDropdownComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['traveler-shops-ui-places-search.component.scss'],
  templateUrl: 'traveler-shops-ui-places-search.component.html',
})
export class TravelerShopsUiPlacesSearchComponent {
  private cd = inject(ChangeDetectorRef);
  private zone = inject(NgZone);

  @Output() public predictionSelected = new EventEmitter<{
    prediction: [string, string];
    index: number;
  }>();

  @ViewChild('locationInputField') locationInputField!: ElementRef<HTMLInputElement>;
  @Input() value = 'Victoria station, London UK, London UK';
  @Output() valueChange = new EventEmitter<string>();

  locationInputShow = false;
  showDropdown = false;
  @Input() placeholder = 'Where?';
  private _predictions: [string, string][] = [];
  get predictions(): [string, string][] {
    return this._predictions;
  }

  @Input() set predictions(value: [string, string][]) {
    this._predictions = value;
    this.highlightedIndex = value.length > 0 ? 0 : -1;
  }

  constructor(private elementRef: ElementRef<HTMLElement>) {}

  get dropdownShow(): boolean {
    return this.predictions.length > 0 && this.locationInputShow && this.value.length > 3;
  }

  onLocationInputChange(_event: Event): void {
    _event.preventDefault();
    this.valueChange.emit(this.value);
  }

  hideInputField(event: Event | null = null): void {
    if (event) {
      event.stopPropagation();
    }

    this.locationInputShow = false;
  }

  showInputField(): void {
    if (this.locationInputShow) return;

    // Maybe this runOutsideAngular is not needed, but it's here to be sure
    this.zone.runOutsideAngular(() => {
      this.locationInputShow = true;
      const input = this.locationInputField?.nativeElement;
      input?.focus();
    });
  }

  onInputFocus(): void {
    this.locationInputShow = true;
    this.selectAllText();
    this.cd.detectChanges();
  }

  selectAllText() {
    if (!this.locationInputField) {
      return;
    }
    setTimeout(() => {
      this.locationInputField.nativeElement.select();
      this.locationInputField.nativeElement.setSelectionRange(0, 9999);
    }, 0);
  }

  onSelectPrediction(prediction: [string, string], index: number): void {
    this.value = prediction[0];
    this.locationInputShow = false;
    this.predictionSelected.emit({
      prediction,
      index,
    });
  }

  onInputBlur(): void {
    if (this.dropdownShow) {
      return;
    }
    this.hideInputField();
  }

  setPlace(event: Event | null = null, prediction: [string, string], index: number): void {
    if (event) {
      event.stopPropagation();
    }
    this.onSelectPrediction(prediction, index);
    this.hideInputField();
  }

  doMultiLine(title = '', subtitle = ''): boolean {
    return title.length + subtitle.length > 40 ? true : false;
  }

  highlightedIndex = -1;

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.hideInputField();
    }

    if (event.key === 'Enter') {
      if (this.predictions.length > 0 && this.highlightedIndex >= 0) {
        this.setPlace(null, this.predictions[this.highlightedIndex], 0);
      }
    }

    if (event.key === 'ArrowDown') {
      this.highlightedIndex = Math.min(this.highlightedIndex + 1, this.predictions.length - 1);
    } else if (event.key === 'ArrowUp') {
      this.highlightedIndex = Math.max(this.highlightedIndex - 1, -1);
    }
  }

  @HostListener('document:click', ['$event'])
  onClickOutside(event: Event) {
    if (!this.elementRef.nativeElement.contains(event.target as Node)) {
      this.hideInputField();
    }
  }
}
