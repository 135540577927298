import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { SharedUserService } from '@luggagehero/shared/services/users';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard {
  constructor(private userService: SharedUserService) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    //
    // Sometimes params are not present on parent route, so if there are any child
    // routes, go to the last one
    //
    if (route.children && route.children.length > 0) {
      route = route.children[route.children.length - 1];
    }

    // If a login email was provided, pass it to the login view
    const email = route.params.user ? String(route.params.user) : undefined;

    const loggedIn = await this.userService.isLoggedInAsync();

    if (loggedIn) {
      // If the user is already logged in with a different email than the one provided, log them out
      if (email && email !== this.userService.user.email) {
        this.userService.logout();
      } else {
        // Otherwise allow the navigation to proceed
        return true;
      }
    }

    const register = route.queryParams.register ? true : false;
    const redirectTo = state.url;

    // Show the login view
    this.userService.showLoginView(true, redirectTo, register, email, true);

    // Don't allow the navigation to proceed
    return false;
  }
}
