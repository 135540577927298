import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  inject,
  Input,
  Output,
} from '@angular/core';
import {
  AvailabilityContext,
  RecommendedTag,
  StorageLocationItemBadge,
  TRANSLATE_SERVICE,
} from '@luggagehero/shared/interfaces';
import { UppercaseFirstLetterPipe } from '@luggagehero/shared/ui-pipes';
import { PricingContext } from '@luggagehero/traveler-shops-models';

import { TravelerShopsUiAvailabilityDisplayComponent } from '../availability-display/traveler-shops-ui-availability-display.component';
import { TravelerShopsUiBestChoiceDisplayComponent } from '../best-choice-display/traveler-shops-ui-best-choice-display.component';
import { TravelerShopsUiButtonComponent } from '../button/traveler-shops-ui-button.component';
import { TravelerShopsUiDistanceDisplayComponent } from '../distance-display/traveler-shops-ui-distance-display.component';
import { TravelerShopsUiHolidayNoticeComponent } from '../holiday-notice/traveler-shops-ui-holiday-notice.component';
import { TravelerShopsUiPricingDisplayComponent } from '../pricing-display/traveler-shops-ui-pricing-display.component';
import {
  ShopRatingContext,
  TravelerShopsUiShopRatingComponent,
} from '../shop-rating/traveler-shops-ui-shop-rating.component';

const defaultAvailability: AvailabilityContext = {
  openTime: null,
  closeTime: '14.00',
  isOpenAllDay: false,
  isPermanentlyClosed: false,
  isFullyBooked: false,
  isForCurrentDay: false,
};

const defaultHourlyPrice: PricingContext = {
  price: 5,
  currency: 'Kr',
  unit: 'hour',
};
const defaultDailyPrice: PricingContext = {
  price: 50,
  currency: 'Kr',
  unit: 'day',
};

@Component({
  selector: 'lh-traveler-shops-ui-shop-card',
  standalone: true,
  imports: [
    CommonModule,
    TravelerShopsUiAvailabilityDisplayComponent,
    TravelerShopsUiBestChoiceDisplayComponent,
    TravelerShopsUiShopRatingComponent,
    TravelerShopsUiDistanceDisplayComponent,
    TravelerShopsUiButtonComponent,
    TravelerShopsUiPricingDisplayComponent,
    UppercaseFirstLetterPipe,
    TravelerShopsUiHolidayNoticeComponent,
  ],
  styleUrls: ['traveler-shops-ui-shop-card.component.scss'],
  templateUrl: 'traveler-shops-ui-shop-card.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TravelerShopsUiShopCardComponent {
  private translate = inject(TRANSLATE_SERVICE);
  private cd = inject(ChangeDetectorRef);

  @Input() subtitle = 'LuggageHero';
  @Input() title = 'Rådhuspladsen';
  @Input() availability = defaultAvailability;
  @Input() pricingHourly: PricingContext | null = defaultHourlyPrice;
  @Input() pricingDaily: PricingContext | null = defaultDailyPrice;
  @Input() holidayNoticeHtml?: string | null = null;
  @Input() shopRating?: ShopRatingContext;
  @Input() recommendedTag?: RecommendedTag | null = null;
  @Input() distance = '0.5 km';
  @Input() bookButtonStyle: 'outline' | 'solid' = 'outline';
  @Input() showBookButton = true;
  @Input() insideModal = false;
  @Input() badges: StorageLocationItemBadge[] = [];
  @Output() bookNow = new EventEmitter<void>();
  @Output() cardClick = new EventEmitter<void>();

  @Input() onBookNow?: () => void;
  @Input() onCardClicked?: () => void;

  private _isHighlighted = false;

  @Input() set isHighlighted(value: boolean) {
    this._isHighlighted = value;
    this.cd.markForCheck();
  }

  get isHighlighted(): boolean {
    return this._isHighlighted;
  }

  cardClicked() {
    this.cardClick.emit();

    if (this.onCardClicked) {
      this.onCardClicked();
    }
  }

  bookNowClicked() {
    // Emit the event
    this.bookNow.emit();

    // Also call the callback if provided
    if (this.onBookNow) {
      this.onBookNow();
    }
  }

  public getTranslation(key: string): string {
    return this.translate.instant(key);
  }
}
