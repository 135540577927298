import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, OnInit, Provider } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { PaymentMethodSelectorBaseComponent } from '@luggagehero/features/ui';
import { AppConfig } from '@luggagehero/shared/app-settings/data-access';
import { Config } from '@luggagehero/shared/environment';
import { SharedErrorService } from '@luggagehero/shared/services/error';
import { SharedNotificationService } from '@luggagehero/shared/services/notification';
import { SharedPaymentService } from '@luggagehero/shared/services/payments';
import { SharedStorageService } from '@luggagehero/shared/services/storage';
import { SharedStripeService } from '@luggagehero/shared/services/stripe';
import { SharedUserService } from '@luggagehero/shared/services/users';
import { SharedWindowService } from '@luggagehero/shared/services/window';
import { SharedUtilString } from '@luggagehero/shared/util';
import { StringUtil } from '@luggagehero/utils';
import { TranslateService } from '@ngx-translate/core';
import { PaymentMethod, PaymentRequest, PaymentRequestPaymentMethodEvent } from '@stripe/stripe-js';

export const PAYMENT_METHOD_SELECTOR_CONTROL_VALUE_ACCESSOR: Provider = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => PaymentMethodSelectorComponent),
  multi: true,
};

@Component({
  selector: 'lh-payment-method-selector',
  templateUrl: './payment-method-selector.component.html',
  styleUrls: ['./payment-method-selector.component.scss'],
  providers: [PAYMENT_METHOD_SELECTOR_CONTROL_VALUE_ACCESSOR],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaymentMethodSelectorComponent extends PaymentMethodSelectorBaseComponent implements OnInit {
  private browserPaymentRequest: PaymentRequest;

  constructor(
    paymentService: SharedPaymentService,
    stripeService: SharedStripeService,
    storageService: SharedStorageService,
    userService: SharedUserService,
    notify: SharedNotificationService,
    error: SharedErrorService,
    translate: TranslateService,
    cd: ChangeDetectorRef,
    windowService: SharedWindowService,
  ) {
    super(paymentService, stripeService, storageService, userService, notify, error, translate, cd, windowService);
  }

  async ngOnInit() {
    await this.initBrowserPayment();
    super.ngOnInit();
  }

  async initBrowserPayment() {
    // Initiate the browser payment request (amount, currency and label will be updated before it's shown)
    this.browserPaymentRequest = await this.stripeService.requestBrowserPayment(
      this.paymentAmount,
      this.paymentCurrency,
      AppConfig.MERCHANT_COUNTRY_CODE,
      this.translate.instant(AppConfig.PAYMENT_VERIFICATION_LABEL) as string,
    );

    if (this.browserPaymentRequest) {
      // Register payment method event handler
      this.browserPaymentRequest.on('paymentmethod', (e) => this.onPaymentMethodEvent(e));
    }
  }

  //
  // This has to be kept a simple synchronous function used as a user gesture handler for Apple Pay to work in Safari.
  // https://github.com/stripe-archive/react-stripe-elements/issues/335
  //
  showBrowserPayment() {
    if (
      !this.isWalletPaymentAvailable ||
      !this.browserPaymentRequest ||
      this.isLoading ||
      this.browserPaymentRequest.isShowing()
    ) {
      return;
    }
    this.isLoading = true;
    this.browserPaymentRequest.update({
      total: {
        amount: this.paymentAmount,
        label: this.translate.instant(AppConfig.PAYMENT_VERIFICATION_LABEL) as string,
      },
      currency: this.paymentCurrency,
    });
    this.browserPaymentRequest.show();
    this.isLoading = false;
  }

  async onPaymentMethodEvent(e: PaymentRequestPaymentMethodEvent): Promise<void> {
    let paymentMethod: PaymentMethod;

    try {
      paymentMethod = await this.stripeService.confirmBrowserPayment(this.payment.data.client_secret, e);
    } catch (err) {
      // The process failed, show the error to the user
      this.notify.error(StringUtil.errorToString(err));

      if (!Config.isProduction) {
        console.debug(SharedUtilString.formatPaymentEvent(this.payment.data, 'failed'), err);
      }
    }

    if (paymentMethod) {
      if (!Config.isProduction) {
        console.debug(SharedUtilString.formatPaymentEvent(this.payment.data, 'confirmed'));
      }

      // The process succeeded, add the payment method in our backend
      this.walletPaymentMethodAdded = await this.addPaymentMethod('stripe', e.paymentMethod);
    }
  }
}
