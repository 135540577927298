import { ILocation, ILuggage, ITimePeriod, StorageCriteria } from '@luggagehero/shared/interfaces';
import { SharedUtilDate } from '@luggagehero/shared/util';

export class SharedStorageCriteria implements StorageCriteria {
  public constructor(
    public location?: ILocation,
    public period?: ITimePeriod,
    public luggage?: ILuggage,
    public activeOnly = true,
  ) {}

  public toString(): string {
    const where = `'${this.location.address}' [${this.formatLatLon(this.location)}] ${this.location.type}`;
    const when = `${this.formatDate(this.period.from)} - ${this.formatDate(this.period.to)}`;
    const what = `${this.luggage.normal}/${this.luggage.hand}`;
    return `${where} | ${when} | ${what}`;
  }

  /**
   * Compare this `ShopCriteria` instance with another one
   * @param other The other `ShopCriteria` instance to compare with
   * @param includeTime Whether to include the time in the comparison
   * @returns `true` if the two `ShopCriteria` instances are equal, `false` otherwise
   */
  public equals(other: SharedStorageCriteria, includeTime = false): boolean {
    if (!other) {
      return false;
    }

    // Compare the location
    if (
      this.location.address !== other.location.address ||
      this.location.lat !== other.location.lat ||
      this.location.lon !== other.location.lon
    ) {
      // The location has changed
      return false;
    }

    // Compare the luggage
    if (this.luggage.normal !== other.luggage.normal || this.luggage.hand !== other.luggage.hand) {
      // The number of bags has changed
      return false;
    }

    if (includeTime) {
      // Compare the period including the time
      return (
        this.period.from.getTime() === other.period.from.getTime() &&
        this.period.to.getTime() === other.period.to.getTime()
      );
    }

    // Compare the period using only the date
    return (
      SharedUtilDate.isSameDate(this.period.from, other.period.from) &&
      SharedUtilDate.isSameDate(this.period.to, other.period.to)
    );
  }

  private formatDate(value: Date): string {
    return value.toISOString().substring(0, 16).replace('T', ' ');
  }

  private formatLatLon(value: ILocation): string {
    return `${value.lat.toFixed(2)},${value.lon.toFixed(2)}`;
  }
}
