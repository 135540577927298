import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import {
  CUSTOM_ELEMENTS_SCHEMA,
  ModuleWithProviders,
  NgModule,
  NO_ERRORS_SCHEMA,
  Optional,
  Provider,
  SkipSelf,
} from '@angular/core';
import { SharedAppSettingsService } from '@luggagehero/shared/app-settings/data-access';
import { TRANSLATE_SERVICE } from '@luggagehero/shared/interfaces';
import { SharedLoggingService } from '@luggagehero/shared/services/logging';
import {
  createTranslateLoader,
  createTranslateParser,
  SharedTranslateService,
  SharedTranslationsService,
} from '@luggagehero/shared/services/translation';
import { SharedWindowService } from '@luggagehero/shared/services/window';
import { TranslateLoader, TranslateModule, TranslateParser } from '@ngx-translate/core';

import { MultilingualService } from './services/index';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [SharedTranslationsService, SharedAppSettingsService, HttpClient],
      },
      parser: {
        provide: TranslateParser,
        useFactory: createTranslateParser,
        deps: [SharedTranslationsService, SharedLoggingService],
      },
    }),
  ],
  declarations: [],
  exports: [TranslateModule],
  providers: [
    MultilingualService,
    SharedTranslateService,
    { provide: TRANSLATE_SERVICE, useExisting: SharedTranslateService },
    SharedWindowService,
  ],
  schemas: [NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA],
})
export class MultilingualModule {
  // optional usage
  // ideally we could use this to override TranslateModule, but it requires the static above at moment
  static forRoot(configuredProviders: Provider[]): ModuleWithProviders<MultilingualModule> {
    return {
      ngModule: MultilingualModule,
      providers: configuredProviders,
    };
  }

  constructor(@Optional() @SkipSelf() parentModule: MultilingualModule) {
    if (parentModule) {
      throw new Error('MultilingualModule already loaded; Import in root module only.');
    }
  }
}
