import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  NgZone,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { App as CapacitorApp } from '@capacitor/app';
import { Browser } from '@capacitor/browser';
import { AppConfig } from '@luggagehero/shared/app-settings/data-access';
import {
  Booking,
  InitiatePaymentResult,
  IPaymentRecord,
  IStripePaymentCard,
  IStripeSetupIntentResult,
} from '@luggagehero/shared/interfaces';
import { SharedBookingService } from '@luggagehero/shared/services/bookings';
import { SharedErrorService } from '@luggagehero/shared/services/error';
import { SharedNotificationService } from '@luggagehero/shared/services/notification';
import { SharedOrderService } from '@luggagehero/shared/services/orders';
import { SharedPaymentService } from '@luggagehero/shared/services/payments';
import { SharedStorageService, StorageTarget } from '@luggagehero/shared/services/storage';
import { SharedWindowService } from '@luggagehero/shared/services/window';
import { SharedUtilWindow } from '@luggagehero/shared/util';
import { TranslateService } from '@ngx-translate/core';
import { PaymentMethod, SetupIntent } from '@stripe/stripe-js';
import { firstValueFrom } from 'rxjs';

import { BaseComponent } from '../../../core';
const noop = () => {
  /**/
};

@Component({ template: '' })
export abstract class PaymentOptionsBaseComponent extends BaseComponent implements ControlValueAccessor, OnInit {
  @Output() public paymentMethodCancel = new EventEmitter<unknown>();
  @Output() public paymentMethodSelect = new EventEmitter<IPaymentRecord>();

  @ViewChild('paymentMethodDropdown') public paymentMethodDropdown: ElementRef<HTMLSelectElement>;

  public isPaymentCardFormVisible = false;
  public isBrowserPaymentAvailable = false;

  private _isApplePayAvailable = false;
  private _isGooglePayAvailable = false;

  private _value: IPaymentRecord;
  private _booking: Booking;
  private _payment: IPaymentRecord<InitiatePaymentResult>;
  private _paymentMethods: IPaymentRecord[];
  private _previousPaymentMethod: IPaymentRecord;
  private _selectedIndex = 0;
  private previousSelectedIndex = 0;
  private _newPaymentCard: IStripePaymentCard;
  private _isInitialized = false;
  private _isInitiatingPayment = false;
  private _isLoading = false;
  private _isPaymentDue = false;
  private _isLegacyMode = false;
  private _isAppUrlOpenEventListenerInitialised = false;

  public walletPaymentMethodAdded = false;
  private ngZone = inject(NgZone);

  private onTouchedCallback: () => void = noop;
  private onChangeCallback: (_) => void = noop;

  constructor(
    protected cd: ChangeDetectorRef,
    protected bookingService: SharedBookingService,
    protected orderService: SharedOrderService,
    protected paymentService: SharedPaymentService,
    protected notify: SharedNotificationService,
    protected error: SharedErrorService,
    protected translate: TranslateService,
    private windowService: SharedWindowService,
    protected storageService: SharedStorageService,
  ) {
    super();
  }

  set value(value: IPaymentRecord) {
    if (!value || value === this._value) {
      return;
    }
    this._value = value;
    this.hidePaymentCardForm();

    this.onChangeCallback(value);
    this.cd.markForCheck();
  }
  get value(): IPaymentRecord {
    return this._value;
  }

  @Input() set booking(value: Booking) {
    this._booking = value;
    this.cd.markForCheck();
  }
  get booking(): Booking {
    return this._booking;
  }

  get payment(): IPaymentRecord<InitiatePaymentResult> {
    return this._payment;
  }
  @Input() set payment(value: IPaymentRecord<InitiatePaymentResult>) {
    this._payment = value;
    this.cd.markForCheck();
  }

  get paymentAmount(): number {
    // TODO: Handle zero-decimal currencies from Stripe (https://stripe.com/docs/currencies#zero-decimal)
    return Math.round((this.payment?.data.amount || 0) * 100);
  }

  @Input() set paymentMethods(value: IPaymentRecord[]) {
    if (value) {
      value = value.filter((p) => p.provider !== 'direct_payment');
    }
    this._paymentMethods = value;
    if (!this._paymentMethods) {
      return;
    }
    if (this._paymentMethods.length === 0) {
      // No payment methods for this user so show payment card form
      void this.showPaymentCardForm();
      return;
    }

    this.walletPaymentMethodAdded = this._paymentMethods.some(
      (pm) =>
        typeof pm.data === 'object' &&
        'card' in pm.data &&
        (pm.data as { card: { wallet: unknown } }).card != null &&
        (pm.data.card as { wallet: unknown }).wallet,
    );

    if (this.booking.paymentMethodId) {
      for (let i = 0; i < this.paymentMethods.length; i++) {
        const paymentMethod = this.paymentMethods[i];
        if (paymentMethod.id === this.booking.paymentMethodId) {
          this.selectPaymentMethod(i);
          this.previousPaymentMethod = paymentMethod;
          break;
        }
      }
    } else {
      // Booking has no payment method set
      if (this._paymentMethods.length > 1) {
        // Use the first existing payment card when available
        // TODO: Choose default payment method based on a user setting
        this.selectPaymentMethod(0);
      } else {
        // Only direct payment available so show new payment card option
        this.selectPaymentMethod(this._paymentMethods.length);
      }
    }
  }
  get paymentMethods(): IPaymentRecord[] {
    return this._paymentMethods;
  }

  get previousPaymentMethod(): IPaymentRecord {
    return this._previousPaymentMethod;
  }
  set previousPaymentMethod(value: IPaymentRecord) {
    this._previousPaymentMethod = value;
    this.cd.markForCheck();
  }

  get selectedIndex(): number {
    return this._selectedIndex;
  }
  set selectedIndex(value: number) {
    //
    // HACK: The index binding alone doesn't seem to change the selected item of the dropdown since Angular 9 so
    // setting the index directly on the select item. Also the UI doesn't update if we don't push it to the back
    // of the change detection queue.
    //
    setTimeout(() => {
      this._selectedIndex = value;

      if (this.paymentMethodDropdown) {
        this.paymentMethodDropdown.nativeElement.selectedIndex = this._selectedIndex;
      }
      this.cd.markForCheck();
    }, 1);
  }

  get isNewPaymentMethodSelected(): boolean {
    if (!this.value || !this.previousPaymentMethod) {
      return false;
    }
    return this.value.id !== this.previousPaymentMethod.id;
  }

  @Input() set isPaymentDue(value: boolean) {
    this._isPaymentDue = value;
    this.cd.markForCheck();
  }
  get isPaymentDue(): boolean {
    return this._isPaymentDue;
  }

  @Input() set isLegacyMode(value: boolean) {
    this._isLegacyMode = value;
    this.cd.markForCheck();
  }
  get isLegacyMode(): boolean {
    return this._isLegacyMode;
  }

  get paymentCurrency(): string {
    if (this.payment?.data?.currency) {
      return this.payment.data.currency;
    }
    if (this.booking) {
      return this.booking?.orderDetails && this.booking.orderDetails.length > 0
        ? this.booking.orderDetails[0].currency
        : this.booking.price.pricing.currency;
    }
    return 'usd';
  }

  get isDirectPaymentSelected(): boolean {
    if (!this.value) {
      return false;
    }
    return this.value.provider === 'direct_payment';
  }

  get newPaymentCard(): IStripePaymentCard {
    return this._newPaymentCard;
  }
  set newPaymentCard(value: IStripePaymentCard) {
    this._newPaymentCard = value;
  }

  get isPaymentCardValidationVariant(): boolean {
    const variant = this.bookingService.getVariant(this.booking);
    return variant === AppConfig.EXPERIMENT_VARIANTS.paymentCardValidation;
  }

  get storageLocationId(): string {
    let storageLocationId: string;
    if (this.booking && this.isPaymentCardValidationVariant) {
      storageLocationId = this.booking.shopId;
    }
    return storageLocationId;
  }

  get orderId(): string {
    let orderId: string;
    if (this.booking) {
      orderId = this.booking.orderDetails[0]._id;
    }
    return orderId;
  }

  get bookingId(): string {
    let bookingId: string;
    if (this.booking) {
      bookingId = this.booking._id.toString();
    }
    return bookingId;
  }

  get isApplePayAvailable() {
    return this._isApplePayAvailable;
  }
  set isApplePayAvailable(value: boolean) {
    this._isApplePayAvailable = value;
    this.cd.markForCheck();
  }

  get isGooglePayAvailable() {
    return this._isGooglePayAvailable;
  }
  set isGooglePayAvailable(value: boolean) {
    this._isGooglePayAvailable = value;
    this.cd.markForCheck();
  }

  get isInitialized(): boolean {
    return this._isInitialized;
  }
  set isInitialized(value: boolean) {
    this._isInitialized = value;
    this.cd.markForCheck();
  }

  get isLoading(): boolean {
    return this._isLoading;
  }
  set isLoading(value: boolean) {
    this._isLoading = value;
    this.cd.markForCheck();
  }

  get isInitiatingPayment(): boolean {
    return this._isInitiatingPayment;
  }
  set isInitiatingPayment(value: boolean) {
    this._isInitiatingPayment = value;
    this.cd.markForCheck();
  }

  async ngOnInit() {
    await this.loadPaymentMethods();

    this.isInitialized = true;
  }

  private async loadPaymentMethods() {
    this.isLoading = true;

    try {
      const res = await this.paymentService.getPaymentMethods(this.booking && this.booking._id, true);

      const paymentMethods: IPaymentRecord<unknown>[] = [];
      res.forEach((paymentMethod) => {
        if (paymentMethod.provider !== 'direct_payment') {
          paymentMethods.push(paymentMethod);
        }
      });

      this.paymentMethods = paymentMethods;
      this.cd.markForCheck();
    } catch {
      // Ignore
    }

    this.isLoading = false;
  }

  writeValue(value: IPaymentRecord<unknown>) {
    if (value !== undefined) {
      this.value = value;
    }
  }

  registerOnChange(fn: (_) => void) {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: () => void) {
    this.onTouchedCallback = fn;
  }

  async showPaymentCardForm() {
    this.isInitiatingPayment = true;

    const returnUrl = this.windowService.isNative ? AppConfig.STRIPE_PAYPAL_NATIVE_RETURN_URL : null;
    this.payment = await this.paymentService.initiatePayment(
      'stripe',
      this.storageLocationId,
      this.orderId,
      this.bookingId,
      returnUrl,
    );

    this.isPaymentCardFormVisible = true;
    this.isInitiatingPayment = false;
  }

  cancelAddPaymentCard() {
    this.selectedIndex = this.previousSelectedIndex;
    this.hidePaymentCardForm();
    this.paymentMethodCancel.emit();
  }

  hidePaymentCardForm() {
    this.isPaymentCardFormVisible = false;
    this.cd.markForCheck();
  }

  onPaymentCardResult(result: IStripeSetupIntentResult): Promise<void> {
    if (result.error) {
      this.notify.warning(result.error.message);
      return;
    }
    void this.addPaymentMethod(result.setupIntent);
  }

  async addPaymentMethod(data: InitiatePaymentResult | SetupIntent | PaymentMethod): Promise<boolean> {
    this.isLoading = true;
    let success = false;

    try {
      const paymentMethod = await this.paymentService.addPaymentMethod({ provider: 'stripe', data }, this.booking._id);
      success = true;

      this.notify.success(this.translate.instant('PAYMENT_CARD_ADDED') as string);

      this.paymentMethods.splice(0, 0, paymentMethod);
      this.previousSelectedIndex = this.selectedIndex;
      this.selectedIndex = 0;

      this.value = paymentMethod;
      this.paymentMethodSelect.emit(this.value);
    } catch (err) {
      this.error.handleError(err, this.translate.instant('ERROR_ADDING_PAYMENT_CARD') as string);
    } finally {
      this.isLoading = false;
    }

    return success;
  }

  isDirectPayment(paymentMethod: IPaymentRecord): boolean {
    return paymentMethod.provider === 'direct_payment';
  }

  isWalletPayment(
    paymentMethod: IPaymentRecord<
      {
        type: string;
      } & {
        [key: string]: {
          wallet: unknown;
        };
      }
    >,
  ): boolean {
    switch (paymentMethod.provider) {
      case 'stripe':
        if (paymentMethod.data[paymentMethod.data.type] && paymentMethod.data[paymentMethod.data.type].wallet) {
          return true;
        }
        return false;

      case 'paypal':
        // TODO: Add support for PayPal
        return false;

      default:
        return false;
    }
  }

  get isPayPalPaymentVisible(): boolean {
    if (!AppConfig.IS_PAYPAL_ENABLED) {
      return false;
    }

    return true;
  }

  selectNewPaymentCard() {
    this.selectPaymentMethod(this.paymentMethods.length);
  }

  selectPaymentMethod(index: number) {
    if (!this.paymentMethods || this.paymentMethods.length <= index) {
      // New payment card
      void this.showPaymentCardForm();
    } else {
      this.hidePaymentCardForm();
      this.value = this.paymentMethods[index];
      this.paymentMethodSelect.emit(this.value);
    }
    this.previousSelectedIndex = this.selectedIndex;
    this.selectedIndex = index;
  }

  onPaypalClick(event: Event) {
    event.preventDefault();
    void this.confirmBookingWithPaypal();
  }

  public async confirmBookingWithPaypal() {
    if (this.windowService.isNative) {
      await this.addAppUrlOpenEventListener();
      return Browser.open({ url: this.payment.data.redirectUrl });
    }

    this.storageService.remove('PAYPAL_SETUP_INTENT', StorageTarget.Local);
    this.storageService.remove('PAYPAL_PAYMENT_INTENT', StorageTarget.Local);
    this.storageService.remove('PAYPAL_SETUP_INTENT_CLIENT_SECRET', StorageTarget.Local);
    this.storageService.remove('PAYPAL_PAYMENT_INTENT_CLIENT_SECRET', StorageTarget.Local);

    const redirectUrl = '/providers/stripe/paypal.html?intent=' + this.payment.data.client_secret;

    const window = await this.windowService.openCentered(redirectUrl, '', false).toPromise();
    let paypalAuthenticationStatus = await firstValueFrom(
      SharedUtilWindow.waitForValue(window, 'PAYPAL_REDIRECT_STATUS'),
    );

    if (!this.storageService.isLocalStorageSupported && !this.storageService.isSessionStorageSupported) {
      this.error.handleError(
        new Error(`Local and session storage are not available (client_secret: ${this.payment.data.client_secret}`),
      );
    } else {
      let setupIntentId: string;

      if (this.storageService.isLocalStorageSupported) {
        setupIntentId = this.storageService.get('PAYPAL_SETUP_INTENT', StorageTarget.Local);
      } else {
        setupIntentId = this.storageService.get('PAYPAL_SETUP_INTENT', StorageTarget.Session);

        if (!paypalAuthenticationStatus) {
          paypalAuthenticationStatus = this.storageService.get('PAYPAL_REDIRECT_STATUS', StorageTarget.Session);
        }
      }

      if ((paypalAuthenticationStatus === 'succeeded' || paypalAuthenticationStatus === 'pending') && setupIntentId) {
        await this.addPaymentMethod({ id: setupIntentId });
      } else if (paypalAuthenticationStatus === 'failed') {
        // Re-initiate the payment
        void this.showPaymentCardForm();
      }
    }
  }

  public async addAppUrlOpenEventListener() {
    if (this._isAppUrlOpenEventListenerInitialised) {
      return;
    }

    this._isAppUrlOpenEventListenerInitialised = true;
    await CapacitorApp.removeAllListeners();

    await CapacitorApp.addListener('appUrlOpen', (event) => {
      void this.ngZone.run(async () => {
        if (event.url) {
          const url = new URL(event.url);
          if (
            url &&
            url.protocol === 'luggagehero:' &&
            (url.pathname.endsWith('stripe-paypal-redirect') || url.host.endsWith('stripe-paypal-redirect')) &&
            url.searchParams.get('redirect_status')
          ) {
            void Browser.close();

            const redirectStatus = url.searchParams.get('redirect_status');
            if (['succeeded', 'pending'].includes(redirectStatus)) {
              const setupIntentId = url.searchParams.get('setup_intent');
              await this.addPaymentMethod({ id: setupIntentId });
            } else if (redirectStatus === 'failed') {
              void this.showPaymentCardForm();
            }
          }
        }
      });
    });
  }
}
