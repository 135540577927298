import { ChangeDetectionStrategy, Component } from '@angular/core';
import { PublicReviewBaseComponent } from '@luggagehero/features/ui';
import { AppConfig } from '@luggagehero/shared/app-settings/data-access';

@Component({
  selector: 'lh-public-review',
  templateUrl: './public-review.component.html',
  styleUrls: ['./public-review.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PublicReviewComponent extends PublicReviewBaseComponent {
  public profileBackgroundColors = AppConfig.PROFILE_BACKGROUND_COLORS;
}
