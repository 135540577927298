import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { AbstractControl, FormGroup, ValidationErrors } from '@angular/forms';
import { IFormField, IFormFieldConfig } from '@luggagehero/shared/interfaces';
import { Subscription } from 'rxjs';

import { BaseComponent } from '../../../core';

@Component({ template: '' })
export abstract class DynamicFormControlBaseComponent
  extends BaseComponent
  implements IFormField, AfterViewInit, OnDestroy
{
  config: IFormFieldConfig;
  group: FormGroup;

  private valueChangesSubscription: Subscription;

  constructor(private cd: ChangeDetectorRef) {
    super();
  }

  get isMissing(): boolean {
    if (!this.enabled) {
      return false;
    }
    if (this.valid) {
      return false;
    }
    return true;
  }

  get isComplete(): boolean {
    return this.config.mandatory && this.valid;
  }

  get isInvalid(): boolean {
    return this.enabled && this.touched && !this.valid;
  }

  get dirty(): boolean {
    if (!this.control) {
      return false;
    }
    return this.control.dirty;
  }

  get disabled(): boolean {
    if (!this.control) {
      return false;
    }
    return this.control.disabled;
  }

  get enabled(): boolean {
    if (!this.control) {
      return false;
    }
    return this.control.enabled;
  }

  get errors(): ValidationErrors {
    if (!this.control) {
      return null;
    }
    return this.control.errors;
  }

  get invalid(): boolean {
    if (!this.control) {
      return true;
    }
    return this.control.invalid;
  }

  get pristine(): boolean {
    if (!this.control) {
      return true;
    }
    return this.control.pristine;
  }

  get touched(): boolean {
    if (!this.control) {
      return false;
    }
    return this.control.touched;
  }

  get untouched(): boolean {
    if (!this.control) {
      return true;
    }
    return this.control.untouched;
  }

  get valid(): boolean {
    if (!this.control) {
      return false;
    }
    return this.control.valid;
  }

  ngAfterViewInit() {
    if (!this.control) {
      return;
    }
    this.valueChangesSubscription = this.control.valueChanges.subscribe(() => this.cd.markForCheck());
  }

  ngOnDestroy() {
    try {
      this.valueChangesSubscription.unsubscribe();
    } catch {
      // Ignore
    }
  }

  private get control(): AbstractControl {
    if (!this.config) {
      return null;
    }
    const localName = this.config.formControlName;
    return this.group.controls[localName];
  }
}
