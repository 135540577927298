import { FormControl } from '@angular/forms';

export interface ProviderUrl {
  providerName: string;
  urlPattern: RegExp;
}

export const EXTERNAL_REVIEW_PLATFORMS: ProviderUrl[] = [
  { urlPattern: /^https:\/\/search.google.com\/local\/writereview/, providerName: 'Google' },
  { urlPattern: /^https:\/\/g.page\/r\/..*\/review/, providerName: 'Google' },
  { urlPattern: /^https:\/\/(www.)?tripadvisor.com\/UserReviewEdit/, providerName: 'TripAdvisor' },
  { urlPattern: /^https:\/\/(www.)?facebook.com\/..*\/reviews(\/)?$/, providerName: 'Facebook' },
  { urlPattern: /^https:\/\/(www.)?yelp.com\/writeareview\/biz\//, providerName: 'Yelp' },
];

export const GOOGLE_MAPS_EMBED_URL_MATCHER: ProviderUrl = {
  urlPattern: /^https:\/\/(www.)?google.com\/maps\/embed/,
  providerName: 'Google',
};

export class UrlValidators {
  static googleMapsEmbedUrl(control: FormControl) {
    if (!control.value) {
      return null;
    }
    const url = control.value as string;
    if (GOOGLE_MAPS_EMBED_URL_MATCHER.urlPattern.test(url)) {
      return null;
    }
    return { invalidUrl: true };
  }

  static externalReviewUrl(control: FormControl) {
    if (!control.value) {
      return null;
    }
    const url = control.value as string;
    for (let i = 0; i < EXTERNAL_REVIEW_PLATFORMS.length; i++) {
      const provider = EXTERNAL_REVIEW_PLATFORMS[i];
      if (provider.urlPattern.test(url)) {
        // Matching provider found
        return null;
      }
    }
    return { invalidUrl: true };
  }
}
