import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Config } from '@luggagehero/shared/environment';
import { GuestyResponse } from '@luggagehero/shared/interfaces';

const DUMMY_CONNECT_ACCOUNT_RESPONSE: GuestyResponse = {
  accountId: 'dummy_account_id',
  providerRecord: {
    provider: 'guesty',
    data: {
      account: {
        name: 'Acme Properties',
        email: 'jane.doe@acmeprops.co.uk',
      },
    },
  },
  response: undefined,
};

@Injectable()
export class GuestyService {
  constructor(private http: HttpClient) {}

  async connectAccount(apiKey: string): Promise<GuestyResponse> {
    if (Config.isDevelopment) {
      return this.simulateRequest(apiKey);
    }
    const url = `${Config.environment.PMS_INTEGRATIONS_API}/providers/guesty/connect`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8',
    });
    const payload = { token: apiKey };
    return this.http.post<GuestyResponse>(url, payload, { headers: headers }).toPromise();
  }

  private async simulateRequest(apiKey: string): Promise<GuestyResponse> {
    await new Promise<void>((resolve) => setTimeout(() => resolve(), 1000));

    switch (apiKey) {
      case 'test':
        return DUMMY_CONNECT_ACCOUNT_RESPONSE;

      default:
        throw new Error('Request failed');
    }
  }
}
