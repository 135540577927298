import { ChangeDetectionStrategy, ChangeDetectorRef, Component, NgZone, Type } from '@angular/core';
import { RouterExtensions } from '@luggagehero/core';
import { ConfirmBookingBaseComponent, ShopMapBaseComponent } from '@luggagehero/features/shops';
import { SharedDistanceService } from '@luggagehero/shared/services/distance';
import { SharedGoogleMapsService } from '@luggagehero/shared/services/google-maps';
import { SharedLoggingService } from '@luggagehero/shared/services/logging';
import { SharedPricingService } from '@luggagehero/shared/services/pricing';
import { SharedShopsService } from '@luggagehero/shared/services/shops';
import { SharedStorageCriteriaService } from '@luggagehero/shared/services/storage-criteria';
import { SharedThemeService } from '@luggagehero/shared/services/theme';

import { ConfirmBookingComponent } from '../confirm-booking/confirm-booking.component';

@Component({
  selector: 'lh-shop-map',
  templateUrl: './shop-map.component.html',
  styleUrls: ['./shop-map.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShopMapComponent extends ShopMapBaseComponent {
  constructor(
    themeService: SharedThemeService,
    criteriaService: SharedStorageCriteriaService,
    googleMapsService: SharedGoogleMapsService,
    shopsService: SharedShopsService,
    distanceService: SharedDistanceService,
    priceService: SharedPricingService,
    router: RouterExtensions,
    cd: ChangeDetectorRef,
    ngZone: NgZone,
    log: SharedLoggingService,
  ) {
    super(
      themeService,
      criteriaService,
      googleMapsService,
      shopsService,
      distanceService,
      priceService,
      router,
      cd,
      ngZone,
      log,
    );
  }

  get confirmBookingComponentType(): Type<ConfirmBookingBaseComponent> {
    return ConfirmBookingComponent;
  }
}
