import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, NavigationEnd, RouterStateSnapshot } from '@angular/router';
import { SharedAppSettingsService } from '@luggagehero/shared/app-settings/data-access';
import { SharedStorageService } from '@luggagehero/shared/services/storage';
import { SharedWindowService } from '@luggagehero/shared/services/window';
import { filter, take } from 'rxjs';

import { RouterExtensions } from '../core/services/index';

@Injectable({
  providedIn: 'root',
})
export class AppVersionGuard {
  constructor(
    private appSettings: SharedAppSettingsService,
    private router: RouterExtensions,
    private window: SharedWindowService,
    private storage: SharedStorageService,
  ) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    if (state.url === '/') {
      // HACK: Navigating from / to /home here as it currently doesn't work from the route config
      void this.router.navigate(['home']);
    }

    await this.window.ready();

    // TODO: Check if we failed to init settings and show network problem view

    const subscription = this.router.router.events
      .pipe(
        filter((e) => e instanceof NavigationEnd),
        take(1),
      )
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      .subscribe(async () => {
        // Stop listening to events
        subscription.unsubscribe();

        if (!this.storage.reloadedAt && this.window.isAppUpdateRequired) {
          console.log(`Update required (${this.window.appVersion} < ${this.appSettings.current?.minAppVersion})`);

          // Store that we reloaded so we don't do it multiple times
          this.storage.reloadedAt = String(Date.now());

          // Add timestamp to url to help ensure no caching kicks in
          const commands = route.url.flatMap((u) => [u.path, u.parameters]);
          const queryParams = { ...route.queryParams, ts: this.storage.reloadedAt };
          await this.router.navigate(commands, { queryParams });

          // Reload the page to fetch the latest version
          this.window.location.reload();

          return Promise.resolve(false);
        }

        this.storage.reloadedAt = null;
      });

    return true;
  }
}
