import { HttpClient } from '@angular/common/http';
import { ElementRef, Injectable } from '@angular/core';
import { Stripe as CapacitorStripe } from '@capacitor-community/stripe';
import { AppConfig } from '@luggagehero/shared/app-settings/data-access';
import { Config } from '@luggagehero/shared/environment';
import { IBankAccount } from '@luggagehero/shared/interfaces';
import { SharedWindowService } from '@luggagehero/shared/services/window';
import { SharedUtilString } from '@luggagehero/shared/util';
import {
  ConfirmCardPaymentData,
  CreateTokenBankAccountData,
  CreateTokenCardData,
  loadStripe,
  PaymentIntentOrSetupIntentResult,
  PaymentMethod,
  PaymentRequest,
  PaymentRequestPaymentMethodEvent,
  Stripe as StripeJs,
  StripeCardElement,
  StripeElements,
  TokenResult,
} from '@stripe/stripe-js';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SharedStripeService {
  private stripe: StripeJs;
  private elements: StripeElements;
  private initComplete = false;
  private initTask: Promise<void>;
  private _isWalletPaymentAvailable = false;
  private _isApplePayAvailable = false;
  private _isGooglePayAvailable = false;

  constructor(
    private window: SharedWindowService,
    private http: HttpClient,
  ) {}

  public get isWalletPaymentAvailable(): boolean {
    return this._isWalletPaymentAvailable;
  }

  public get isApplePayAvailable(): boolean {
    return this._isApplePayAvailable;
  }

  public get isGooglePayAvailable(): boolean {
    return this._isGooglePayAvailable;
  }

  async init(): Promise<void> {
    if (this.initComplete) {
      return;
    }
    if (this.initTask === undefined || this.initTask === null) {
      this.initTask = this._init();
    }
    await this.initTask;
    this.initComplete = true;
  }

  private async _init(): Promise<void> {
    this.stripe = await loadStripe(Config.environment.STRIPE_PUBLISHABLE_KEY);

    if (!Config.isProduction) {
      console.log(`Stripe initialized correctly: ${this.stripe ? 'Yes' : 'NO'}`);
    }
    void CapacitorStripe.initialize({
      publishableKey: Config.environment.STRIPE_PUBLISHABLE_KEY,
    });

    //
    // Initialize Stripe elements
    //
    let fontUrl = AppConfig.STRIPE_ELEMENTS_FONT_URL;

    if (this.window.isNative) {
      try {
        const fontBuffer = await firstValueFrom(this.http.get(fontUrl, { responseType: 'arraybuffer' }));
        const fontBase64 = SharedUtilString.toBase64String(fontBuffer);
        fontUrl = `data:font/woff;base64,${fontBase64}`;
      } catch {
        //
        // Ignore error and continue. If we fail to load the font and prepare a base64 encoded data URL, we will pass
        // the relative URL for the font file to Stripe instead. This will work on web but fail on capacitor because
        // Stripe Elements does not support capacitor:// as a URL scheme.
        //
        // All this means is that _if_ we fail to load the font as base64 on a native device (which shouldn't happen),
        // we will just eventually fall back to a default font for Stripe Elements.
        //
      }

      this._isApplePayAvailable = await this.checkNativeApplePay();
      this._isGooglePayAvailable = await this.checkNativeGooglePay();

      this._isWalletPaymentAvailable =
        (this._isApplePayAvailable && AppConfig.IS_APPLE_PAY_ENABLED) ||
        (this._isGooglePayAvailable && AppConfig.IS_GOOGLE_PAY_ENABLED);
    } else {
      // Create a dummy payment request which will determine wallet availability
      await this.createBrowserPaymentRequest(0, 'usd', AppConfig.MERCHANT_COUNTRY_CODE, 'Dummy charge');
    }

    this.elements = this.stripe.elements({
      fonts: [
        {
          family: 'Eina',
          src: `url('${fontUrl}')`,
          style: 'normal',
        },
      ],
    });

    if (!Config.isProduction) {
      console.log(`Stripe ready`, {
        isWalletPaymentAvailable: this._isWalletPaymentAvailable,
        isApplePayAvailable: this._isApplePayAvailable,
        isGooglePayAvailable: this._isGooglePayAvailable,
      });
    }
  }

  async checkNativeApplePay(): Promise<boolean> {
    if (!AppConfig.IS_APPLE_PAY_ENABLED) {
      return false;
    }
    try {
      await CapacitorStripe.isApplePayAvailable();
    } catch (error) {
      return false;
    }
    return true;
  }

  async checkNativeGooglePay(): Promise<boolean> {
    if (!AppConfig.IS_GOOGLE_PAY_ENABLED) {
      return false;
    }
    try {
      await CapacitorStripe.isGooglePayAvailable();
    } catch (error) {
      return false;
    }
    return true;
  }

  async createCardElement(container: ElementRef<HTMLElement>): Promise<StripeCardElement> {
    await this.init();

    const cardElement = this.elements.create('card', {
      iconStyle: 'default',
      hidePostalCode: true,
      style: {
        base: {
          iconColor: '#1dafe0',
          color: '#384347',
          fontFamily: '"Eina", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif',
          fontSize: '16px',

          '::placeholder': {
            color: '#656D70',
          },
        },
        invalid: {
          iconColor: '#A94442',
          color: '#A94442',
        },
      },
    });
    // Add an instance of the card UI component into the card container <div>
    cardElement.mount(container.nativeElement);

    return cardElement;
  }

  public async requestBrowserPayment(
    amount: number,
    currency: string,
    country: string,
    label: string,
  ): Promise<PaymentRequest> {
    await this.init();

    return this.createBrowserPaymentRequest(amount, currency, country, label);
  }

  private async createBrowserPaymentRequest(
    amount: number,
    currency: string,
    country: string,
    label: string,
  ): Promise<PaymentRequest> {
    let browserPaymentRequest: PaymentRequest;

    try {
      browserPaymentRequest = this.stripe.paymentRequest({
        country,
        currency,
        total: { label, amount },
        requestPayerName: false,
        requestPayerEmail: true,
      });

      if (browserPaymentRequest) {
        const browserPaymentInfo = await browserPaymentRequest.canMakePayment();

        if (browserPaymentInfo) {
          this._isApplePayAvailable = browserPaymentInfo.applePay ? true : false;
          // If both Apple and Google Pay should be available, Apple wins for now (our UI only supports one provider)
          this._isGooglePayAvailable = !browserPaymentInfo.applePay && browserPaymentInfo.googlePay ? true : false;
          this._isWalletPaymentAvailable = this._isApplePayAvailable || this._isGooglePayAvailable;

          return browserPaymentRequest;
        }
      }
    } catch (err) {
      if (!Config.isProduction) {
        console.log(`Error checking for browser payment availability`, err);
      }
    }

    this._isApplePayAvailable = false;
    this._isGooglePayAvailable = false;
    this._isWalletPaymentAvailable = false;

    return null;
  }

  /**
   * Handles the payment method event from the browser payment request. Follows the flow described here:
   * https://stripe.com/docs/stripe-js/elements/payment-request-button?client=html#html-js-complete-payment
   * @param clientSecret The client secret of the PaymentIntent or SetupIntent to confirm.
   * @param e The payment method event from the browser payment request.
   * @returns The payment method that was confirmed.
   * @throws An error if the payment failed.
   */
  async confirmBrowserPayment(clientSecret: string, e: PaymentRequestPaymentMethodEvent): Promise<PaymentMethod> {
    await this.init();

    // Confirm the PaymentIntent without handling potential next actions (yet).
    const { error, paymentIntent, setupIntent } = await this.confirmPayment(clientSecret, e.paymentMethod.id, false);

    let errorMessage: string;

    if (error) {
      //
      // Report to the browser that the payment failed, prompting it to re-show the payment interface, or show an error
      // message and close the payment interface.
      //
      e.complete('fail');

      errorMessage = error.message;
    } else {
      //
      // Report to the browser that the confirmation was successful, prompting it to close the browser payment method
      // collection interface.
      //
      e.complete('success');

      //
      // Check if the PaymentIntent requires any actions and, if so, let Stripe.js
      // handle the flow.
      //
      if (paymentIntent?.status === 'requires_action' || setupIntent?.status === 'requires_action') {
        // Let Stripe.js handle the rest of the payment flow.
        const { error } = await this.confirmPayment(clientSecret);
        if (error) {
          errorMessage = error.message;
        }
      }
    }

    if (errorMessage) {
      throw new Error(errorMessage);
    }

    return e.paymentMethod;
  }

  async tokenizeCardElement(element: StripeCardElement, tokenData?: CreateTokenCardData): Promise<TokenResult> {
    await this.init();

    return this.stripe.createToken(element, tokenData);
  }

  async tokenizeBankAccount(value: IBankAccount): Promise<TokenResult> {
    await this.init();

    const bankAccountData: CreateTokenBankAccountData = {
      country: value.country,
      currency: value.currency,
      account_number: value.accountNumber,
      // TODO: Validate that hardcoding 'company' here does not cause problems
      account_holder_type: value.accountHolderType || 'company',
    };
    if (value.routingNumber) {
      bankAccountData.routing_number = value.routingNumber;
    }
    if (value.accountHolderName) {
      bankAccountData.account_holder_name = value.accountHolderName;
    }

    const res = await this.stripe.createToken('bank_account', bankAccountData);

    return res;
  }

  async confirmPayment(
    clientSecret: string,
    paymentMethodIdOrCardElement?: string | StripeCardElement,
    handleActions = true,
  ): Promise<PaymentIntentOrSetupIntentResult> {
    await this.init();

    const data: ConfirmCardPaymentData = paymentMethodIdOrCardElement
      ? {
          payment_method:
            typeof paymentMethodIdOrCardElement === 'string'
              ? paymentMethodIdOrCardElement
              : { card: paymentMethodIdOrCardElement },
        }
      : undefined;

    const res = clientSecret.startsWith('seti_')
      ? await this.stripe.confirmCardSetup(clientSecret, data, { handleActions })
      : await this.stripe.confirmCardPayment(clientSecret, data, { handleActions });

    return res;
  }
}
