import { ChangeDetectorRef, Component, EventEmitter, inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AppConfig } from '@luggagehero/shared/app-settings/data-access';
import {
  BookableStorageLocation,
  DistanceUnit,
  IPricing,
  ITimeInterval,
  PRICING_SERVICE,
  PricingService,
  StorageLocationItemBadge,
} from '@luggagehero/shared/interfaces';
import { SharedDistanceService } from '@luggagehero/shared/services/distance';
import { SharedShopsService } from '@luggagehero/shared/services/shops';
import { SharedStorageCriteriaService } from '@luggagehero/shared/services/storage-criteria';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin, map, Observable, Subscription } from 'rxjs';

import { BaseComponent } from '../../../core';
import { StorageLocationItemAction } from './storage-location-item.base-component';

const MAX_POI_DISTANCE = 500;

@Component({ template: '' })
export abstract class SimpleShopItemBaseComponent extends BaseComponent implements OnInit, OnDestroy {
  @Output() public bookingRequest = new EventEmitter<void>();
  @Output() public bookShopRequest = new EventEmitter<void>();
  protected pricingService = inject<PricingService>(PRICING_SERVICE);
  public averageRating = 0;
  public showAverageRating = true;
  public numberOfReviews = 0;
  public showNumberOfReviews = true;

  public actions: StorageLocationItemAction[] = [
    {
      callToAction: 'BOOK_NOW',
      isPrimary: true,
      onClick: (event) => this.requestBooking(event),
    },
    // {
    //   callToAction: 'Drop off',
    //   isPrimary: true,
    //   onClick: (event) => this.openBookShop(event),
    // },
  ];
  public badges: StorageLocationItemBadge[] = [];

  private storageLocationService = inject(SharedShopsService);
  private distanceService = inject(SharedDistanceService);
  private criteria = inject(SharedStorageCriteriaService);
  private translate = inject(TranslateService);
  private cd = inject(ChangeDetectorRef);

  private _shop: BookableStorageLocation;
  private _distanceUnit: DistanceUnit = 'metric';
  private globalAverageRating = 4.7; // TODO: Load this from API
  private subscriptions: Subscription[] = [];
  private _index = -1;

  public get shop(): BookableStorageLocation {
    return this._shop;
  }
  @Input() set shop(value: BookableStorageLocation) {
    this._shop = value;

    this.numberOfReviews = this.storageLocationService.getNumberOfRatings(value);
    this.showNumberOfReviews = this.numberOfReviews > 1;

    this.averageRating = value?.stats?.averageRating || this.globalAverageRating;
    this.showAverageRating =
      value?.stats?.averageRating > 0 || AppConfig.IS_GLOBAL_AVERAGE_RATING_ON_SIMPLE_SHOP_ITEM_ENABLED;

    this.badges = this.storageLocationService.getBadgeList(value);
  }

  public get distanceUnit(): DistanceUnit {
    return this._distanceUnit;
  }
  @Input() public set distanceUnit(value: DistanceUnit) {
    this._distanceUnit = value;
    this.cd.markForCheck();
  }

  public get locationInfo(): Observable<string> {
    return forkJoin([
      this.distanceService.convertDistance(this.shop.distance, this.distanceUnit, false),
      this.translate.get('DISTANCE_AWAY'),
    ]).pipe(map(([distance, suffix]) => `${distance} ${suffix}`));
  }

  public get locationType(): string {
    if (!AppConfig.IS_STORAGE_LOCATION_TYPE_ENABLED) {
      return null;
    }

    let locationType: string;

    // TODO: Parse locationType to translation key
    switch (this.shop.locationType) {
      default:
        locationType = 'LUGGAGE_STORAGE';
        break;
    }

    return locationType;
  }

  public get title(): string {
    if (!this.shop) {
      return '';
    }

    const nearestPoi = this.shop.poiList?.[0];
    const distance = Math.round(nearestPoi?.distance || -1);

    const poiName = distance >= 0 && distance <= MAX_POI_DISTANCE ? nearestPoi.poiName : this.shop.address.city;
    const prefix = AppConfig.IS_LUGGAGEHERO_BRAND_IN_SHOP_TITLE_ENABLED
      ? 'LuggageHero'
      : (this.translate.instant('LUGGAGE_STORAGE') as string);

    return `${prefix} ${poiName}`;
  }

  public get pricing(): IPricing {
    if (!this.shop) {
      return null;
    }
    return this.shop.pricing;
  }

  public get openingHours(): ITimeInterval[] {
    return this.storageLocationService.getOpeningHoursForDate(this.criteria.current.period.from, this.shop);
  }

  public get nextOpenDay(): Date {
    return this.storageLocationService.getNextOpenDay(this.criteria.current.period.from, this.shop);
  }

  public get opensAt(): Observable<string> {
    return this.storageLocationService.getOpensAt(this.criteria.current.period.from, this.shop);
  }

  public get closesAt(): Observable<string> {
    return this.storageLocationService.getClosesAt(this.criteria.current.period.from, this.shop);
  }

  public get isMultiDayStorageAllowed(): boolean {
    return this.storageLocationService.isMultidayStorageAllowed(this.shop);
  }

  public get isRecommended(): boolean {
    return this.storageLocationService.isRecommended(this.shop);
  }

  public get isOpenLate(): boolean {
    if (!AppConfig.IS_OPEN_LATE_BADGE_ON_SIMPLE_SHOP_ITEM_ENABLED) {
      return false;
    }
    return this.storageLocationService.isOpenLate(this.criteria.period.from, this.shop);
  }

  public get isAlwaysOpen(): boolean {
    if (!AppConfig.IS_ALWAYS_OPEN_BADGE_ON_SIMPLE_SHOP_ITEM_ENABLED) {
      return false;
    }
    return this.storageLocationService.isAlwaysOpen(this.criteria.period.from, this.criteria.period.to, this.shop);
  }

  public get isHighlyRated(): boolean {
    if (!AppConfig.IS_HIGHLY_RATED_BADGE_ON_SIMPLE_SHOP_ITEM_ENABLED) {
      return false;
    }
    if (this.isRecommended) {
      // Don't show highly rated badge if shop is recommended
      return false;
    }
    return this.storageLocationService.isHighlyRated(this.shop);
  }

  public get isFullyBooked(): boolean {
    return this.isOpen && !this.shop.available;
  }

  public get isOpen(): boolean {
    return this.storageLocationService.isOpen(this.criteria.period.from, this.shop);
  }

  public get isClosed(): boolean {
    return this.storageLocationService.isClosed(this.criteria.period.from, this.shop);
  }

  public get isSuperheroTerminologyEnabled(): boolean {
    return AppConfig.IS_SUPERHERO_TERMINOLOGY_ENABLED;
  }

  public get hourlyRateHtml(): Observable<string> {
    return this.pricingService.transformPricing(this.pricing, false, false, true, false, false, true).pipe(
      map((html) => html?.replace('>/', '>') ?? ''), // HACK: Remove "/" from "/hour"
    );
  }

  public get dailyRateHtml(): Observable<string> {
    return this.pricingService.transformPricing(this.pricing, false, true, false, false, false, true).pipe(
      map((html) => html?.replace('>/', '>') ?? ''), // HACK: Remove "/" from "/day"
    );
  }

  public get holidayNoticeHtml(): string {
    return this.storageLocationService.getHolidayNoticeHtml(this.criteria.period.from, this.shop);
  }

  public ngOnInit(): void {
    this.subscriptions.push(
      this.storageLocationService.listShopsAsyncComplete.subscribe(() => {
        // In case the shop has had properties changed, we need to refresh the shop item
        this.cd.markForCheck();
      }),
    );
  }

  public ngOnDestroy(): void {
    try {
      this.subscriptions?.forEach((s) => s.unsubscribe());
    } catch {
      // Ignore
    }
  }

  public requestBooking(e: Event) {
    e.stopPropagation();
    e.preventDefault();
    this.bookingRequest.emit();
  }

  public openBookShop(e: Event) {
    e.stopPropagation();
    e.preventDefault();
    this.bookShopRequest.emit();
  }

  get index(): number {
    return this._index;
  }
  @Input() set index(value: number) {
    this._index = value;
    this.cd.markForCheck();
  }
}
