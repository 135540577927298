<div *ngIf="!isThemeReady">
  <!-- TODO: Implement navbar placeholder while theme is loading -->
</div>

<nav class="navbar navbar-expand-md" *ngIf="isThemeReady">
  <div class="container-fluid">
    <div class="navbar-brand">
      <div class="logos">
        <!-- LuggageHero logo -->
        <a id="goHome" class="href" [href]="homeUrl" *ngIf="isThemeReady">
          <lh-platform-logo
            [iconOnly]="isPlatformLogoCollapsed"
            [lightBackground]="isThemeHeaderLight"
          ></lh-platform-logo>
        </a>

        <div *ngIf="isValidImpersonationMode">ADMIN MODE</div>

        <div class="theme-logo" *ngIf="themeLogo">
          <a [href]="themeLink" target="_blank" *ngIf="themeLink">
            <img [src]="themeLogo | safeUrl" />
          </a>
          <img [src]="themeLogo | safeUrl" *ngIf="!themeLink" />
        </div>
      </div>
    </div>

    <div class="ms-auto d-flex align-items-center">
      <div *ngIf="!isDirectDropoff" class="d-block d-md-none">
        <lh-lang-switcher></lh-lang-switcher>
      </div>
      <button
        *ngIf="isLoggedIn"
        class="navbar-toggler"
        type="button"
        data-bs-target="#navbar"
        aria-controls="navbar"
        aria-expanded="false"
        aria-label="Toggle navigation"
        (click)="isCollapsed = !isCollapsed"
      >
        <lh-user-avatar [lightBackground]="isThemeHeaderLight"></lh-user-avatar>
      </button>
      <button
        *ngIf="!isLoggedIn"
        class="navbar-toggler"
        type="button"
        data-bs-target="#navbar"
        aria-controls="navbar"
        aria-expanded="false"
        aria-label="Toggle navigation"
        (click)="logIn()"
      >
        <i class="icon-lock mobile"></i>
      </button>
    </div>

    <!-- Brand and toggle get grouped for better mobile display -->

    <!-- Collect the nav links, forms, and other content for toggling -->
    <div class="navbar-collapse" id="navbar" [class.collapse]="isCollapsed" *ngIf="!isImpersonationMode()">
      <!-- Navbar for large screens only -->
      <ul class="navbar-nav ms-auto d-none d-md-flex align-items-stretch min-h-inherit">
        <li class="d-flex align-items-center cursor-pointer">
          <lh-lang-switcher></lh-lang-switcher>
        </li>

        <li
          *ngIf="isLoggedIn"
          [class.active]="router.isActive('dashboard', true)"
          class="d-flex align-items-center cursor-pointer"
        >
          <a id="goToShopManager" routerLink="/dashboard" (click)="isCollapsed = true" class="href">
            {{ 'SHOP_MANAGER_DASHBOARD' | translate }}
          </a>
        </li>

        <!-- 'Account' item -->
        <li
          *ngIf="isLoggedIn"
          [class.active]="router.isActive('user/account', true)"
          class="d-flex align-items-center cursor-pointer"
        >
          <a id="goToAccount" routerLink="user/account" (click)="isCollapsed = true" class="href">
            {{ 'ACCOUNT' | translate }}
          </a>
        </li>

        <li *ngIf="isLoggedIn" class="d-flex align-items-center cursor-pointer">
          <a id="showSearchInDropdown" (click)="showSearch()" class="href"
            ><i class="bi bi-search"></i>{{ 'SEARCH' | translate }}
          </a>
        </li>

        <li *ngIf="!isLoggedIn" class="d-flex align-items-center cursor-pointer">
          <a id="logIn" (click)="logIn()" class="href"><i class="icon-lock"></i>{{ 'SIGN_IN' | translate }}</a>
        </li>

        <!-- 'My bookings' item -->
        <li *ngIf="isLoggedIn" class="d-flex align-items-center cursor-pointer">
          <a class="dropdown" id="userDropdown" data-bs-toggle="dropdown" aria-expanded="false">
            <lh-user-avatar [lightBackground]="isThemeHeaderLight"></lh-user-avatar>
          </a>

          <ul class="dropdown-menu dropdown-menu-end py-1" aria-labelledby="userDropdown">
            <li *ngIf="isLoggedIn && isUserAvailable" class="form-group text-center mb-1">
              <div class="user-dropdown-lg-subtitle">{{ 'SIGNED_IN_AS' | translate }}</div>
              <div class="user-dropdown-lg-title">{{ userIdentifier }}</div>
            </li>

            <li>
              <a id="logOut" (click)="logOut()" class="dropdown-item">{{ 'LOG_OUT' | translate }}</a>
            </li>
          </ul>
        </li>
      </ul>

      <!-- Navbar for small screens only -->
      <ul class="d-block d-md-none nav navbar-nav navbar-right">
        <!-- 'Signed in as' header -->
        <div
          *ngIf="isLoggedIn && isUserAvailable"
          class="form-group text-center"
          routerLink="user/account"
          (click)="isCollapsed = true"
        >
          <div class="user-dropdown-sm-subtitle">{{ 'SIGNED_IN_AS' | translate }}</div>
          <div class="user-dropdown-sm-title">{{ user.email }}</div>
        </div>

        <!-- 'Dashboard' item -->
        <li
          *ngIf="isLoggedIn"
          [class.active]="router.isActive('dashboard', true)"
          class="d-flex align-items-center cursor-pointer"
        >
          <a
            id="goToShopManager"
            routerLink="/dashboard"
            (click)="isCollapsed = true"
            class="href text-decoration-none cursor-pointer"
          >
            {{ 'SHOP_MANAGER_DASHBOARD' | translate }}
          </a>
        </li>

        <!-- 'Account' item -->
        <li
          *ngIf="isLoggedIn"
          [class.active]="router.isActive('user/account', true)"
          class="d-flex align-items-center cursor-pointer"
        >
          <a
            id="goToAccount"
            routerLink="user/account"
            (click)="isCollapsed = true"
            class="href text-decoration-none cursor-pointer"
          >
            {{ 'ACCOUNT' | translate }}
          </a>
        </li>

        <li *ngIf="isLoggedIn" class="d-flex align-items-center cursor-pointer">
          <a id="showSearchInDropdown" (click)="showSearch()" class="href text-decoration-none cursor-pointer"
            ><i class="bi bi-search"></i>{{ 'SEARCH' | translate }}
          </a>
        </li>

        <!-- 'Log out' item -->
        <li *ngIf="isLoggedIn" class="d-flex align-items-center cursor-pointer">
          <a id="logOut" (click)="logOut()" class="href text-decoration-none cursor-pointer">{{
            'LOG_OUT' | translate
          }}</a>
        </li>
      </ul>
    </div>
    <!-- /.navbar-collapse -->

    <!-- Admin mode nav links -->
    <div class="navbar-collapse" id="navbar" [class.collapse]="isCollapsed" *ngIf="isValidImpersonationMode">
      <ul class="navbar-nav ms-auto d-none d-md-flex align-items-stretch min-h-inherit">
        <li
          *ngIf="isLoggedIn"
          [class.active]="router.isActive('dashboard', true)"
          class="d-flex align-items-center cursor-pointer"
        >
          <a id="goToShopManager" [routerLink]="storageLocationSettingsLink" (click)="isCollapsed = true" class="href">
            {{ 'SHOP_MANAGER_SETTINGS' | translate }}
          </a>
        </li>

        <li
          *ngIf="isLoggedIn"
          [class.active]="router.isActive('dashboard', true)"
          class="d-flex align-items-center cursor-pointer"
        >
          <a id="goToShopManager" [routerLink]="storageLocationBookingsLink" (click)="isCollapsed = true" class="href">
            {{ 'SHOP_MANAGER_BOOKINGS' | translate }}
          </a>
        </li>

        <li
          *ngIf="isLoggedIn"
          [class.active]="router.isActive('dashboard', true)"
          class="d-flex align-items-center cursor-pointer"
        >
          <a id="goToShopManager" [routerLink]="storageLocationPricingLink" (click)="isCollapsed = true" class="href">
            {{ 'SHOP_MANAGER_PRICING' | translate }}
          </a>
        </li>
      </ul>
    </div>
  </div>
  <!-- /.container-fluid -->
</nav>
