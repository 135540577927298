<div class="step-headline">{{ 'Your contact information' | translate }}</div>

<div *ngIf="isLoading" class="text-center mt-4">
  <lh-shared-ui-spinner [visible]="true"></lh-shared-ui-spinner>
</div>

<div [hidden]="isLoading">
  <p>{{ 'PROVIDE_EMAIL_FOR_BOOKING' | translate }}</p>

  <lh-user-authentication
    [isRemoteControlled]="true"
    [isStorageManager]="false"
    [isExistingUser]="false"
    [isUserEmailSelected]="false"
    [isUserPhoneSelected]="false"
    [loginMedium]="'email'"
    [emailLogin]="'password'"
    [phoneLogin]="'disabled'"
    [registerName]="'disabled'"
    [askForFullName]="false"
    [registerPhoneNumber]="'disabled'"
    [registerPassword]="'disabled'"
    [enableThirdPartyLogin]="false"
    [requireCaptcha]="false"
    [showFieldLabels]="false"
    [termsOfService]="tosConfig"
    (userEmailChange)="updateState()"
    #userAuthentication
  ></lh-user-authentication>

  <div class="skip-login" *ngIf="isGuestLoginAllowed">
    <button class="btn btn-md btn-default" (click)="continueAsGuest()">
      {{ 'PROCEED_WITHOUT_EMAIL' | translate }}
    </button>
  </div>
</div>
