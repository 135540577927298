<div *ngIf="isLoading" class="loader">
  <lh-shared-ui-spinner [visible]="true"></lh-shared-ui-spinner>
  <div class="mt-2">{{ 'LOADING_SHOPS_MESSAGE' | translate }}</div>
</div>

<div *ngIf="!isLoading && isNoShopsAvailable" class="message-bar">
  {{ 'NO_SHOPS_FOUND' | translate }}
</div>

<!-- <div *ngIf="!isLoading && !isNoShopsAvailable" class="promo-bar" [class.text-left]="showPricingModelToggle">
  <div *ngIf="showPricingModelToggle">
    <button id="togglePricingModel" class="btn btn-sm btn-default pricing-model" (click)="togglePricingModel()">
      <span [class.active]="(pricingModel | async) === 'hourly'">{{ 'PRICING_MODEL_HOURLY' | translate }}</span> /
      <span [class.active]="(pricingModel | async) === 'daily'">{{ 'PRICING_MODEL_DAILY' | translate }}</span>
    </button>
  </div>

  <div *ngIf="showDistanceUnitToggle" class="btn btn-sm btn-default distance-units" (click)="toggleDistanceUnit()">
    <span [class.active]="distanceUnit === 'metric'">{{ 'UNIT_SUFFIX_KILOMETERS' | translate }}</span> /
    <span [class.active]="distanceUnit === 'imperial'">{{ 'UNIT_SUFFIX_MILES' | translate }}</span>
  </div>
</div> -->

<div class="list-wrapper">
  <a
    [id]="'goToShopDetailsFromList_' + shop._id"
    class="href text-decoration-none"
    [class.success]="isRecommended(shop)"
    *ngFor="let shop of listShops(hideUnavailable); let i = index"
    (click)="onShowShopDetails(shop)"
    (mouseenter)="setHoverShop(shop._id)"
    (mouseleave)="setHoverShop('')"
  >
    <lh-simple-shop-item
      [shop]="shop"
      [distanceUnit]="distanceUnit | async"
      (bookingRequest)="makeBooking(shop)"
      [index]="i"
    ></lh-simple-shop-item>
  </a>
</div>

<div class="spinner">
  <button
    id="loadMoreShops"
    *ngIf="!isLoading && numberOfShopsShown > 0 && numberOfShopsShown < numberOfShopsAvailable"
    class="btn btn-default btn-load-more"
    (click)="showNextBatch()"
  >
    {{ 'LOAD_MORE_SHOPS' | translate }}
  </button>
</div>
