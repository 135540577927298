/* eslint-disable */
import { Injectable } from '@angular/core';

import { Config } from '@luggagehero/shared/environment';
import { ScriptService } from './script.service';

declare const FB: any;

@Injectable()
export class FacebookSdk {
  private initialized = false;

  constructor(protected script: ScriptService) {}

  public async load(): Promise<any> {
    await this.script.load('facebook').toPromise();

    if (!this.initialized) {
      FB.init({
        appId: Config.environment.FACEBOOK_APP_ID,
        cookie: false, // enable cookies to allow the server to access the session
        xfbml: true, // parse social plugins on this page
        version: 'v8.0', // use graph api version 8.0
      });
      this.initialized = true;
    }
    return FB;
  }
}
