import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';

import { BaseComponent } from '../../../core';
import { DateUtil } from '../../../utils/date.util';

const noop = () => {
  /**/
};

@Component({ template: '' })
export abstract class DatePickerBaseComponent extends BaseComponent implements ControlValueAccessor, OnInit {
  private _value: Date;
  private _minDate: Date;
  private _maxDate: Date;

  private onTouchedCallback: () => void = noop;
  private onChangeCallback: (_) => void = noop;

  constructor(private cd: ChangeDetectorRef) {
    super();
  }

  @Input() set minDate(value: Date) {
    this._minDate = value;
    this.cd.markForCheck();
  }
  get minDate(): Date {
    return this._minDate;
  }

  @Input() set maxDate(value: Date) {
    this._maxDate = value;
    this.cd.markForCheck();
  }
  get maxDate(): Date {
    return this._maxDate;
  }

  set value(value: Date) {
    if (!value) {
      return;
    }
    if (this._value && DateUtil.isSameDate(value, this._value)) {
      return;
    }
    this._value = value;

    this.onChangeCallback(value);
    this.cd.markForCheck();
  }
  get value(): Date {
    return this._value;
  }

  ngOnInit() {
    //
    // HACK: This makes kendo calendar show something initially when
    // opening the dropdown, but it is still buggy.
    //
    this.value = new Date(2017, 3, 1);
  }

  writeValue(value: Date) {
    if (value !== undefined) {
      this.value = value;
    }
  }

  registerOnChange(fn: (_) => void) {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: () => void) {
    this.onTouchedCallback = fn;
  }
}
