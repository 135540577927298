import { Inject, Pipe, PipeTransform } from '@angular/core';
import { DISTANCE_SERVICE, DistanceUnit, IDistanceService } from '@luggagehero/shared/interfaces';
import { Observable } from 'rxjs';

@Pipe({ name: 'lhDistance', standalone: true })
export class DistancePipe implements PipeTransform {
  constructor(@Inject(DISTANCE_SERVICE) private distanceService: IDistanceService) {}

  transform(
    meters: number,
    distanceUnit: DistanceUnit = 'metric',
    includefrom = true,
    place?: string,
  ): Observable<string> {
    return this.distanceService.convertDistance(meters, distanceUnit, includefrom, place);
  }
}
