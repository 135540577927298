import { InjectionToken } from '@angular/core';

export enum LogLevel {
  Debug = 0,
  Info = 1,
  Warning = 2,
  Error = 4,
}

export const LOGGER_TOKEN = new InjectionToken<LogTarget[]>('logger-token');
export interface LogEvent {
  message: string;
  level: LogLevel;
  context?: object;
  error?: Error;
}
export abstract class LogTargetOptions {
  minLogLevel: LogLevel;
}
export class LogTargetOptionsError implements LogTargetOptions {
  minLogLevel: LogLevel = LogLevel.Error;
}
export abstract class LogTarget {
  abstract log(event: LogEvent): Promise<unknown>;
}
export abstract class LogTargetBase implements LogTarget {
  constructor(protected options: LogTargetOptions) {}

  log(event: LogEvent): Promise<unknown> {
    if (event.level >= this.options.minLogLevel) {
      return this.writeToLog(event);
    }
    return Promise.resolve();
  }

  protected abstract writeToLog(event: LogEvent): Promise<unknown>;
}
