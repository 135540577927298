/* eslint-disable @typescript-eslint/unbound-method */
import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RouterExtensions } from '@luggagehero/core';
import { LoginBaseComponent } from '@luggagehero/features/user';
import { FacebookLoginService, GoogleSigninService } from '@luggagehero/services';
import { AppConfig } from '@luggagehero/shared/app-settings/data-access';
import { SharedErrorService } from '@luggagehero/shared/services/error';
import { SharedLoggingService } from '@luggagehero/shared/services/logging';
import { SharedNotificationService } from '@luggagehero/shared/services/notification';
import { SharedStorageService } from '@luggagehero/shared/services/storage';
import { SharedUserService } from '@luggagehero/shared/services/users';
import { SharedWindowService } from '@luggagehero/shared/services/window';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'lh-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginComponent extends LoginBaseComponent {
  public newUserForm: FormGroup;
  public existingUserForm: FormGroup;

  private _emailLoginButtonClicked = false;

  constructor(
    private formBuilder: FormBuilder,
    loginService: SharedUserService,
    facebookLogin: FacebookLoginService,
    googleSignin: GoogleSigninService,
    storage: SharedStorageService,
    window: SharedWindowService,
    router: RouterExtensions,
    translate: TranslateService,
    notify: SharedNotificationService,
    error: SharedErrorService,
    log: SharedLoggingService,
    cd: ChangeDetectorRef,
  ) {
    super(loginService, facebookLogin, googleSignin, storage, window, router, translate, notify, error, log, cd);
  }

  public get emailLoginButtonClicked(): boolean {
    return this._emailLoginButtonClicked;
  }
  public set emailLoginButtonClicked(value: boolean) {
    this._emailLoginButtonClicked = value;
    this.cd.markForCheck();
  }

  public get isExistingUserEmailInvalid(): boolean {
    if (!this.emailLoginButtonClicked) {
      return false;
    }

    const emailLoginInput = this.existingUserForm.get('email');

    return emailLoginInput.invalid;
  }

  public get isExistingUserPasswordInvalid(): boolean {
    if (!this.emailLoginButtonClicked) {
      return false;
    }

    const passwordLoginInput = this.existingUserForm.get('password');

    if (!passwordLoginInput.errors) {
      return false;
    }

    return true;
  }

  get isValidPhoneNumber(): boolean {
    const phoneNumberInput = this.newUserForm.get('phone');
    return phoneNumberInput.dirty && phoneNumberInput.valid;
  }

  public get phoneInputError(): string {
    const phoneInput = this.newUserForm.get('phone');

    if (!this.emailLoginButtonClicked || !phoneInput.errors) {
      return undefined;
    }
    if (phoneInput.errors.required) {
      return 'PHONE_NUMBER_MISSING';
    }
    if (phoneInput.errors.minlength) {
      return 'PHONE_NUMBER_TOO_SHORT';
    }
    if (phoneInput.errors.invalid) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-member-access
      return phoneInput.errors.invalid.reason;
    }
    return 'INVALID_PHONE_NUMBER';
  }

  get currentEmailForm() {
    return this.isNewUser ? this.newUserForm : this.existingUserForm;
  }

  emailLogin() {
    this.emailLoginButtonClicked = true;

    if (!this.currentEmailForm.valid || (this.isNewUser && this.phoneInputError)) {
      return;
    }

    super.emailLogin();
  }

  loadForms() {
    const nameRegEx = this.window.unicodeRegEx ? new RegExp(AppConfig.NAME_REGEX, 'u') : AppConfig.NAME_REGEX;
    const group = this.formBuilder.group({
      email: ['', [Validators.required, Validators.pattern(AppConfig.EMAIL_REGEX)]],
      password: ['', this.choosePassword ? [Validators.required] : undefined],
      name: [
        '',
        this.requireName ? [Validators.required, Validators.pattern(nameRegEx)] : [Validators.pattern(nameRegEx)],
      ],
      phone: ['', this.requirePhone ? [Validators.required, Validators.minLength(6)] : [Validators.minLength(6)]],
    });
    this.newUserForm = group;

    this.existingUserForm = this.formBuilder.group({
      // Use angular email validator for login as we have legacy users created with this
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]],
    });
  }

  getUserConfirmation(prompt: string): boolean {
    return confirm(prompt);
  }

  isEmailFieldValid(): boolean {
    return this.existingUserForm.get('email').valid;
  }

  markEmailFieldAsDirty(): void {
    this.existingUserForm.get('email').markAsDirty();
  }

  markNewUserFormPristine() {
    this.newUserForm.markAsPristine();
  }

  markExistingFormPristine() {
    this.existingUserForm.markAsPristine();
  }

  markPhoneNumberFormPristine() {
    // Not implemented
  }
}
