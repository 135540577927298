import { Injectable } from '@angular/core';
import { LHTenant, StorageManagerLHTenant } from '@luggagehero/shared/app-settings/data-access';
import { SharedErrorService } from '@luggagehero/shared/services/error';
import { SharedNotificationService } from '@luggagehero/shared/services/notification';
import { SharedUserService, StorageManagerUserService } from '@luggagehero/shared/services/users';
import { SharedUtilPassword } from '@luggagehero/shared/util';
import { TranslateService } from '@ngx-translate/core';

import { StringUtil } from '../../../../../utils/string.util';
import { NewUserInfo, UserInfo } from '../../..';
import { UserAuthenticationBaseStrategy } from './user-authentication-base-strategy';

interface HapiError {
  error: {
    message: string;
  };
  message: string;
  status: number;
}

function isHapiError(err: unknown): err is HapiError {
  return typeof err === 'object' && err !== null && 'status' in err && 'message' in err && 'error' in err;
}

@Injectable()
export class EmailPasswordStrategy extends UserAuthenticationBaseStrategy {
  constructor(
    private userService: SharedUserService,
    private translate: TranslateService,
    private notify: SharedNotificationService,
    private error: SharedErrorService,
  ) {
    super();
  }

  public get isResetPasswordVisible(): boolean {
    return this.parent.isExistingUser && this.parent.isFullFormVisible;
  }

  private get user(): UserInfo {
    return this.parent.user;
  }

  public async continue(): Promise<void> {
    this.parent.isContinueWithEmailButtonClicked = true;
    this.parent.form.controls.email.markAsDirty();

    if (this.parent.form.controls.email.invalid) {
      return;
    }

    // Fix common email typo '.con' instead of '.com'
    if (this.parent.user.email.trim().endsWith('.con')) {
      this.parent.user.email = this.parent.user.email.trim().slice(0, -3) + 'com';
    }

    if (!this.parent.isFullFormVisible) {
      this.parent.isLoading = true;

      try {
        this.parent.isExistingUser = await this.userService.checkIfEmailUserExists(this.parent.userEmail);
      } catch (error) {
        this.notify.error('Invalid email, please try again');
        this.parent.isLoading = false;
        return;
      }

      this.parent.isExistingUserChange.emit(this.parent.isExistingUser);

      this.parent.isUserEmailSelected = true;
      this.parent.isUserEmailSelectedChange.emit(true);

      if (this.parent.isExistingUser || this.parent.registerPassword !== 'disabled') {
        this.parent.isFullFormVisible = true;
        this.parent.isLoading = false;
        return;
      }
    }

    this.parent.form.controls.password.markAsDirty();
    this.parent.form.controls.password.markAsTouched();

    if (this.parent.registerPassword !== 'disabled' && this.parent.form.controls.password.invalid) {
      this.parent.isLoading = false;
      return;
    }

    return this.parent.isExistingUser ? this.logIn() : this.register();
  }

  public async logIn(): Promise<void> {
    this.parent.isLoading = true;

    try {
      const response = await this.userService.loginWithEmail({
        email: this.user.email,
        password: this.user.password,
      });

      if (response.success) {
        console.log('Post login', response);
        void this.parent.getUserAndRedirect();
      } else {
        this.error.handleError(
          response.message,
          response.message,
          this.translate.instant('LOGIN_ERROR_TITLE') as string,
        );
      }
    } catch (err) {
      this.user.password = '';

      this.parent.form.markAsPristine();
      this.parent.form.markAsUntouched();

      // this.parent.isFullFormVisible = false;

      if ('status' in err && (err as { status: number }).status === 401) {
        this.onAuthenticationFailed();
      } else {
        this.error.handleError(err, StringUtil.errorToString(err) || 'Uknown error, please try again');
      }
    }

    this.parent.isLoading = false;
  }

  public async register(): Promise<void> {
    let sendPassword = false;

    if (this.parent.registerPassword === 'disabled') {
      this.user.password = SharedUtilPassword.generate();
      sendPassword = true;
    }

    if (!this.user.name) {
      this.user.name = 'Guest User';
    }

    const newUser: NewUserInfo = {
      email: this.user.email,
      password: this.user.password,
      name: this.user.name,
      sendPassword,
      tenant: LHTenant,
    };

    if (this.parent.isStorageManager) {
      newUser.isShopOwner = true;
      newUser.tenant = StorageManagerLHTenant;
    }

    if (this.parent.isValidPhoneNumber) {
      newUser.phone = this.user.phone;
      newUser.phoneCountry = this.user.phoneCountry;
    }

    this.parent.isLoading = true;

    try {
      if (newUser.phone) {
        const isExistingUser = await this.userService.checkIfPhoneUserExists(newUser.phone);

        if (isExistingUser) {
          this.parent.existingUserPhoneNumbers.push(StringUtil.normalizePhoneNumber(newUser.phone));
          this.parent.markForCheck();

          throw Error(this.translate.instant('USER_WITH_PHONE_ALREADY_EXISTS') as string);
        }
      }

      const res = await this.userService.registerUser(newUser);

      if (res.success) {
        void this.parent.getUserAndRedirect();
      } else {
        this.error.handleError(res.message, res.message, this.translate.instant('REGISTER_USER_ERROR_TITLE') as string);
      }
    } catch (err) {
      if (isHapiError(err) && err.status === 400) {
        // Get the validation error message if available (it should be with 400 errors from Hapi)
        let message = String(err.error && err.error.message) || 'uknown error';

        //
        // Error message from Hapi validation is of the form: "\"phoneNumber\" must be a mobile phone number" so we
        // strip out the "phoneNumber" part for a more human-readable message
        //
        message = message.replace('"phoneNumber"', '').toLowerCase();

        // Will display something like "Registration failed: not a mobile phone number"
        this.notify.error(`Registration failed: ${message}`);
      } else {
        this.error.handleError(err, StringUtil.errorToString(err));
      }
    }

    this.parent.isLoading = false;
  }

  public async resetPassword(): Promise<void> {
    if (this.parent.form.controls.email.invalid) {
      this.parent.form.controls.email.markAsTouched();
      this.notify.warning(this.translate.instant('RESET_PASSWORD_MISSING_EMAIL') as string);
      return;
    }

    let message = '';

    if (this.userService instanceof StorageManagerUserService) {
      const part1 = this.translate.instant('CONFIRM_RESET_PASSWORD_EMAIL') as string;
      message = `${part1} ${this.user.email}.`;
    } else {
      const part1 = this.translate.instant('CONFIRM_RESET_PASSWORD_PART_1') as string;
      const part2 = this.translate.instant('CONFIRM_RESET_PASSWORD_PART_2') as string;
      message = `${part1} ${this.user.email}. ${part2}.`;
    }

    if (!this.parent.getUserConfirmation(message)) {
      return;
    }

    this.parent.isLoading = true;

    try {
      await this.userService.resetPasswordViaEmail(this.user.email);
      this.notify.success(this.translate.instant('RESET_PASSWORD_SUCCESS_MESSAGE') as string);
    } catch (err) {
      this.error.handleError(err, StringUtil.errorToString(err));
    }

    this.parent.isLoading = false;
  }

  public onAuthenticationFailed(): void {
    this.notify.error(`Login failed, please try again`);
  }
}
