<p>{{ 'ADD_OPTIONAL_PRODUCTS_TO_BOOKING' | translate }}</p>
<div *ngIf="availableProducts" class="product-list">
  <div *ngFor="let product of availableProducts; let i = index" class="product" [class.selected]="product.selected">
    <div class="banner" *ngIf="i === 0">{{ 'RECOMMENDED' | translate }}</div>
    <div class="left">
      <div class="name">
        {{ getProductDisplayName(product) }}
        <lh-shared-ui-tooltip callToAction="i" [body]="getProductInfoBody(product)"></lh-shared-ui-tooltip>
      </div>
      <div class="description">{{ getProductInfoHeader(product) }}</div>
    </div>
    <div class="right">
      <div class="price">{{ getProductCost(product) | price: currency }}</div>
      <div class="toggle">
        <input
          type="checkbox"
          [id]="'switch_' + product.key"
          [(ngModel)]="product.selected"
          (change)="onProductSelectedChange()"
        />
        <label [for]="'switch_' + product.key">Toggle</label>
      </div>
    </div>
  </div>
</div>

<div class="mt-4">
  <button
    class="btn btn-block"
    [class.btn-default]="!isSelectionMade"
    [class.btn-primary]="isSelectionMade"
    (click)="confirmSelection()"
  >
    {{ 'PROCEED_TO_MY_BOOKING' | translate }}
  </button>
</div>
