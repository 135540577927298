import { Injectable } from '@angular/core';
import { Config } from '@luggagehero/shared/environment';
import { GeolocationProvider, GeolocationState, LatLng, UserLocation } from '@luggagehero/shared/interfaces';
import { SharedErrorService } from '@luggagehero/shared/services/error';
import { SharedNotificationService } from '@luggagehero/shared/services/notification';
import { SharedTranslateService } from '@luggagehero/shared/services/translation';
import { SharedWindowService } from '@luggagehero/shared/services/window';

export enum GeoErrorType {
  GEO_PERMISSION_DENIED = 1,
  GEO_POSITION_UNAVAILABLE = 2,
  GEO_TIMEOUT = 3,
}

@Injectable({
  providedIn: 'root',
})
export class SharedGeolocationService implements GeolocationProvider {
  // Only time geolocation lookups when not in production
  private timeGeolocation = !Config.isProduction;
  private _currentLocation: UserLocation;

  constructor(
    protected translate: SharedTranslateService,
    private windowService: SharedWindowService,
    protected notify: SharedNotificationService,
    protected error: SharedErrorService,
  ) {}

  get currentLocation(): UserLocation {
    return this._currentLocation;
  }

  private get navigator(): Navigator {
    return this.windowService.navigator;
  }

  async getCurrentPosition(isUserRequest: boolean): Promise<LatLng> {
    return new Promise<LatLng>((resolve, reject) => {
      if (!this.navigator || !this.navigator.geolocation) {
        const errorMessage = this.translate.instant(GeoErrorType[GeoErrorType.GEO_POSITION_UNAVAILABLE]);

        if (isUserRequest) {
          this.notify.info(errorMessage, this.translate.instant('GEO_ERROR_TITLE'));
        }

        reject(errorMessage);
      } else {
        if (this.timeGeolocation) {
          console.time('geolocation.getCurrentPosition()');
        }
        //
        // NOTE: This takes a long time in some browsers (more than 5 seconds) which means the user is waiting quite
        // long for a result if they have already allowed us to access their location
        //
        const options: PositionOptions = {
          enableHighAccuracy: isUserRequest,
          timeout: 10000,
          maximumAge: 0,
        };
        this.navigator.geolocation.getCurrentPosition(
          (pos) => {
            if (this.timeGeolocation) {
              console.timeEnd('geolocation.getCurrentPosition()');
            }

            this._currentLocation = {
              updatedAt: new Date(),
              location: {
                lat: pos.coords.latitude,
                lng: pos.coords.longitude,
              },
            };
            resolve(this._currentLocation.location);
          },
          (err) => {
            const errorMessage = this.translate.instant(GeoErrorType[err.code]);

            if (isUserRequest) {
              this.notify.info(errorMessage, this.translate.instant('GEO_ERROR_TITLE'));
            }
            reject(errorMessage);
          },
          options,
        );
      }
    });
  }

  async queryGeolocationState(): Promise<GeolocationState> {
    //
    // Uses new permissions API which is not so widely supported yet
    // http://caniuse.com/#search=navigator.permissions
    // https://stackoverflow.com/questions/10077606/check-if-geolocation-was-allowed-and-get-lat-lon
    //
    return new Promise<GeolocationState>((resolve) => {
      if (this.navigator && this.navigator.permissions) {
        this.navigator.permissions.query({ name: 'geolocation' }).then(
          (permission) => resolve(permission.state),
          (_err) => resolve('unknown'),
        );
      } else {
        resolve('unknown');
      }
    });
  }
}
