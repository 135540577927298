import { ChangeDetectorRef, Directive, HostBinding, HostListener, Input, OnInit } from '@angular/core';
import { Config } from '@luggagehero/shared/environment';

const INITIAL_RETRY_INTERVAL_SECONDS = 3;
const MAX_ATTEMPTS = 5;

@Directive({
  selector: '[lhImage]',
})
export class ImageDirective implements OnInit {
  @Input() imageName: string;
  @Input() imageSize: 'large' | 'medium' | 'thumb';

  @HostBinding('class.load-success') success = false;
  @HostBinding('class.load-error') error = false;
  @HostBinding('src') src: string;

  private attemptNumber = 0;
  private retryInterval = INITIAL_RETRY_INTERVAL_SECONDS * 1000;

  @HostListener('error') onError() {
    this.error = true;
    this.success = false;
  }

  @HostListener('load') onLoad() {
    this.success = true;
    this.error = false;
  }

  constructor(private cd: ChangeDetectorRef) {}

  ngOnInit() {
    if (!this.imageName) {
      return;
    }
    this.tryLoadImage();
  }

  tryLoadImage() {
    if (this.success) {
      return; // The image has loaded successfully, stop trying
    }
    if (this.attemptNumber >= MAX_ATTEMPTS) {
      return; // Maximum attempts reached, stop trying
    }
    this.attemptNumber++;

    let imageUrl = `${Config.environment.IMAGES_BASE_URL}/images/${this.imageSize}/${this.imageName}`;
    if (this.attemptNumber > 1) {
      // Update image URL with new timestamp to force reload
      imageUrl += `?${new Date().getTime()}`;

      // Double the interval after every retry
      this.retryInterval *= 2;
    }
    // Update the src binding on the image element
    this.src = imageUrl;
    this.cd.markForCheck();

    // Keep trying until image loads successfully or max attempts is reached
    setTimeout(() => this.tryLoadImage(), this.retryInterval);
  }
}
