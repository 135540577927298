import { HttpClient, HttpErrorResponse, HttpHeaders, HttpStatusCode } from '@angular/common/http';
import { EventEmitter, inject, Injectable } from '@angular/core';
import { SharedNotificationService } from '@luggagehero/shared/services/notification';
import { SharedStorageService } from '@luggagehero/shared/services/storage';
import { SharedWindowService } from '@luggagehero/shared/services/window';
import { catchError, Observable } from 'rxjs';

import { SHARED_HTTP_SERVICE_CONFIG_TOKEN } from './shared-http-service.token';

// TODO: Move to shared util
const HTTP_STATUS_CODES = Object.values(HttpStatusCode).filter((code) => typeof code === 'number') as number[];
const isHttpStatusCode = (value: number): value is HttpStatusCode => HTTP_STATUS_CODES.includes(value);

@Injectable({
  providedIn: 'root',
})
export class SharedHttpClient {
  public readonly error$: Observable<HttpStatusCode>;

  public readonly client = inject(HttpClient);
  private readonly window = inject(SharedWindowService);
  private readonly notify = inject(SharedNotificationService);
  private readonly storage = inject(SharedStorageService);
  private readonly config = inject(SHARED_HTTP_SERVICE_CONFIG_TOKEN);

  private error = new EventEmitter<HttpStatusCode>();

  constructor() {
    this.error$ = this.error.asObservable();
  }

  public get<T>(url: string, authorize = true): Observable<T> {
    const options = this.getRequestOptions(authorize);
    return this.interceptErrors(this.client.get<T>(url, options));
  }

  public postFile<T>(url: string, file: Blob, fileName: string, authorize = true): Observable<T> {
    const options = this.getRequestOptions(authorize, true);
    const formData = new FormData();
    formData.append('file', file, fileName);
    return this.interceptErrors(this.client.post<T>(url, formData, options));
  }

  public put<T>(url: string, data: unknown, authorize = true): Observable<T> {
    const options = this.getRequestOptions(authorize);
    const payload = JSON.stringify(data);
    return this.interceptErrors(this.client.put<T>(url, payload, options));
  }

  public delete<T>(url: string, authorize = true): Observable<T> {
    const options = this.getRequestOptions(authorize);
    return this.interceptErrors(this.client.delete<T>(url, options));
  }

  public post<T>(url: string, data: unknown, authorize = true): Observable<T> {
    const options = this.getRequestOptions(authorize);
    const payload = JSON.stringify(data);
    return this.interceptErrors(this.client.post<T>(url, payload, options));
  }

  public patch<T>(url: string, data: unknown, authorize = true): Observable<T> {
    const options = this.getRequestOptions(authorize);
    const payload = JSON.stringify(data);
    return this.interceptErrors(this.client.patch<T>(url, payload, options));
  }

  private interceptErrors<T>(observable: Observable<T>): Observable<T> {
    return observable.pipe(
      catchError((err: Error, caught: Observable<T>) => {
        this.handleError(err);
        return caught;
      }),
    );
  }

  private handleError(err: unknown): void {
    if (err instanceof HttpErrorResponse) {
      if (err.status === 0) {
        // Client-side connection error
        if (err.error instanceof ProgressEvent) {
          this.notify.error('An error occurred, please check your internet connection');
        }
      } else if (isHttpStatusCode(err.status)) {
        this.error.emit(err.status);
      }
    }

    // Rethrow the error
    throw err;
  }

  private getRequestOptions(authorizeRequest = false, isFileUpload = false): { headers: HttpHeaders } {
    // TODO: Remove when we are sure the APIs are fully backwards compatible
    if (this.config.usesLegacyHttpMode()) {
      return this.getLegacyRequestOptions(authorizeRequest);
    }

    let headers = new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json; charset=utf-8',
    });

    if (isFileUpload) {
      headers = new HttpHeaders({
        Accept: '*/*',
      });
    }

    if (authorizeRequest && this.storage.token) {
      headers = headers.append('Authorization', `Bearer ${this.storage.token}`);
    }

    if (typeof this.storage.variant === 'string' && this.storage.variant.length > 0) {
      headers = headers.append('X-LH-Variant-ID', this.storage.variant);
    }

    if (typeof this.storage.experimentId === 'string' && this.storage.experimentId.length > 0) {
      headers = headers.append('X-LH-Experiment-ID', this.storage.experimentId);
    }

    if (typeof this.storage.sessionId === 'string' && this.storage.sessionId.length > 0) {
      headers = headers.append('X-LH-Session-ID', this.storage.sessionId);
    }

    if (typeof this.storage.visitorId === 'string' && this.storage.visitorId.length > 0) {
      headers = headers.append('X-LH-Visitor-ID', this.storage.visitorId);
    }

    if (typeof this.storage.bookingToken === 'string' && this.storage.bookingToken.length > 0) {
      headers = headers.append('X-LH-Booking-Token', this.storage.bookingToken);
    }

    if (typeof this.window.appName === 'string' && this.window.appName.length > 0) {
      headers = headers.append('X-LH-App-Name', this.window.appName);
    }

    if (typeof this.window.appVersion === 'string' && this.window.appVersion.length > 0) {
      headers = headers.append('X-LH-App-Version', this.window.appVersion);
    }

    if (typeof this.window.appBuildId === 'string' && this.window.appBuildId.length > 0) {
      headers = headers.append('X-LH-App-Build-ID', this.window.appBuildId);
    }

    if (typeof this.window.platform === 'string' && this.window.platform.length > 0) {
      headers = headers.append('X-LH-Platform', this.window.platform);
    }

    return { headers };
  }

  private getLegacyRequestOptions(authorizeRequest: boolean): { headers: HttpHeaders } {
    const options: { headers: HttpHeaders } = { headers: undefined };

    if (authorizeRequest) {
      options.headers = new HttpHeaders({
        Accept: 'application/json',
        'Content-Type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${this.storage.token}`,
      });
    }

    return options;
  }
}
