// libs
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SharedUserService } from '@luggagehero/shared/services/users';

import { BaseComponent } from '../../../core';
@Component({ template: '' })
export class UserBaseComponent extends BaseComponent implements OnInit {
  constructor(
    private userService: SharedUserService,
    private router: Router,
  ) {
    super();
  }

  ngOnInit() {
    if (this.userService.isLoggedIn) {
      void this.router.navigate(['user/account']);
    }
  }
}
