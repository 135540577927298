import { ChangeDetectionStrategy, Component } from '@angular/core';
import { UniversalSearchBaseComponent } from '@luggagehero/features/ui';

@Component({
  selector: 'lh-universal-search',
  templateUrl: './universal-search.component.html',
  styleUrls: ['./universal-search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UniversalSearchComponent extends UniversalSearchBaseComponent {}
