<div class="border-container">
  <div class="total">
    <div class="total-label">{{ totalLabel }}</div>
    <div class="total-value">
      <span *ngIf="currency">{{ totalValue | price: currency }}</span>
      <span *ngIf="!currency">{{ totalValue }}</span>
    </div>
  </div>
  <div class="chart-container">
    <ngx-charts-line-chart
      [results]="results"
      [scheme]="scheme"
      [showGridLines]="false"
      [xAxis]="true"
      [xAxisTicks]="xAxisTicks"
      [yAxis]="false"
      [yAxisTicks]="yAxisTicks"
      [yAxisLabel]="'Revenue'"
      [yScaleMin]="yScaleMin"
      [yScaleMax]="yScaleMax"
    >
    </ngx-charts-line-chart>
  </div>
</div>
