import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { OrderRequestsFacade } from '@luggagehero/guest-data-access-orders';
import { GuestMovement } from '@luggagehero/shared/interfaces';
import { SelectOptionButtonGroupOption, SharedUiSelectOptionButtonGroupComponent } from '@luggagehero/shared/ui';
import { map, Observable } from 'rxjs';

import { GuestDropoffStepBaseComponent } from '../guest-dropoff-step-base-component';
import { GuestSegmentationStepProperties } from './guest-segmentation-step-properties';

@Component({
  selector: 'lh-guest-segmentation-step',
  standalone: true,
  imports: [CommonModule, SharedUiSelectOptionButtonGroupComponent],
  templateUrl: './guest-segmentation-step.component.html',
  styleUrl: './guest-segmentation-step.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GuestSegmentationStepComponent extends GuestDropoffStepBaseComponent<GuestSegmentationStepProperties> {
  public readonly guestMovement$: Observable<GuestMovement>;
  public readonly loyaltyMember$: Observable<boolean>;

  private orderRequestsFacade = inject(OrderRequestsFacade);

  private get guestMovement(): GuestMovement {
    if (!this.bookingDraft.addOns) {
      return 'unset'; // This shouldn't happen
    }
    return this.bookingDraft.addOns.guestMovement;
  }
  private set guestMovement(value: GuestMovement) {
    if (!this.bookingDraft.addOns) {
      return; // This shouldn't happen
    }
    this.bookingDraft.addOns.guestMovement = value;
    void this.checkCanGoForward(true);
  }

  public get loyaltyMember(): boolean {
    if (!this.bookingDraft.addOns) {
      return false; // This shouldn't happen
    }
    return this.bookingDraft.addOns.loyaltyMember;
  }
  public set loyaltyMember(value: boolean) {
    if (!this.bookingDraft.addOns) {
      return; // This shouldn't happen
    }
    this.bookingDraft.addOns.loyaltyMember = value;
    void this.checkCanGoForward(true);
  }

  public guestMovementOptions: SelectOptionButtonGroupOption<GuestMovement>[] = [
    { value: 'arriving', primaryContent: { title: this.translate('ARRIVING') } },
    { value: 'departing', primaryContent: { title: this.translate('DEPARTING') } },
  ];

  public loyaltyMemberOptions: SelectOptionButtonGroupOption<boolean>[] = [
    { value: true, primaryContent: { title: 'YES' } },
    { value: false, primaryContent: { title: 'NO' } },
  ];

  public get isArrivalDepartureSegmentationVisible(): boolean {
    if (this.isLoading) {
      return false;
    }
    return this.properties.enableArrivalDepartureSegmentation;
  }

  public get isLoyaltyMemberSegmentationVisible(): boolean {
    if (this.isLoading) {
      return false;
    }
    if (this.properties.enableArrivalDepartureSegmentation && this.guestMovement === 'unset') {
      // Don't show the loyalty member segmentation until the user has selected a guest movement
      return false;
    }
    return this.properties.enableLoyaltyMemberSegmentation;
  }

  protected get defaultProperties(): GuestSegmentationStepProperties {
    return {
      header: 'WELCOME',
      body: ['DROPOFF_WELCOME_STEP_INTRO_MESSAGE'],
      forwardButtonLabel: 'GET_STARTED',
      enableArrivalDepartureSegmentation: false,
      enableLoyaltyMemberSegmentation: false,
    };
  }

  public get guestMovementPrompt(): string {
    return this.translate('GUEST_MOVEMENT_OPTION');
  }

  public get loyaltyMemberPrompt(): string {
    if (this.properties.loyaltyProgramName) {
      return `${this.translate('ARE_YOU_A_MEMBER_OF')} ${this.properties.loyaltyProgramName}?`;
    }
    return `${this.translate('ARE_YOU_A_LOYALTY_MEMBER_AT')} ${this.storageLocation.name}?`;
  }

  constructor() {
    super();

    this.guestMovement$ = this.orderRequestsFacade.orderRequest$.pipe(
      map((orderRequest) => (this.guestMovement = orderRequest.addOns?.guestMovement ?? 'unset')),
    );
    this.loyaltyMember$ = this.orderRequestsFacade.orderRequest$.pipe(
      map((orderRequest) => (this.loyaltyMember = orderRequest.addOns?.loyaltyMember ?? undefined)),
    );
  }

  protected onInit(): Promise<void> {
    // Nothing to initialize
    return Promise.resolve();
  }

  public onGuestMovementChange(value: GuestMovement): void {
    if (!value || value === 'unset') {
      return;
    }
    this.orderRequestsFacade.updateOrderRequest({ addOns: { guestMovement: value } });
  }

  public onLoyaltyMemberChange(value: boolean): void {
    if (value == null) {
      return;
    }
    this.orderRequestsFacade.updateOrderRequest({ addOns: { loyaltyMember: value } });
  }

  protected onCheckCanGoForward(): boolean {
    if (this.properties.enableArrivalDepartureSegmentation && this.guestMovement === 'unset') {
      // Don't allow the user to go forward until they've selected a guest movement
      return false;
    }

    if (this.properties.enableLoyaltyMemberSegmentation && this.loyaltyMember === undefined) {
      // Don't allow the user to go forward until they've selected if they're a loyalty member
      return false;
    }

    return true;
  }
}
