import { ChangeDetectionStrategy, Component } from '@angular/core';
import { LiveIconBaseComponent } from '@luggagehero/features/ui';

@Component({
  selector: 'lh-live-icon',
  templateUrl: './live-icon.component.html',
  styleUrls: ['./live-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LiveIconComponent extends LiveIconBaseComponent {}
