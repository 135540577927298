// taxes.effects.ts
import { inject, Injectable } from '@angular/core';
import { SharedNotificationService } from '@luggagehero/shared/services/notification';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { catchError, delay, exhaustMap, map, of, tap } from 'rxjs';

import * as TaxesActions from './taxes.actions';
import { DashboardTaxesService } from './taxes.service';

@Injectable()
export class TaxesEffects {
  private actions$ = inject(Actions);
  private taxesService = inject(DashboardTaxesService);
  private notification = inject(SharedNotificationService);
  private translate = inject(TranslateService);

  // Get Taxes Effect
  getTaxes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TaxesActions.getTaxes),
      exhaustMap(({ storageLocationId }) =>
        of(storageLocationId).pipe(
          delay(1500),
          exhaustMap((id) =>
            this.taxesService.getTaxes(id).pipe(
              map((taxes) => TaxesActions.getTaxesSuccess({ taxes })),
              catchError((error) => of(TaxesActions.getTaxesFailure({ error }))),
            ),
          ),
        ),
      ),
    ),
  );

  // Show Notification on Get Taxes Failure
  getTaxesFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(TaxesActions.getTaxesFailure),
        tap(() => {
          this.notification.error(this.translate.instant('ERROR_LOADING_TAXES') as string);
        }),
      ),
    { dispatch: false },
  );

  // Add Tax Effect
  addTax$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TaxesActions.addTax),
      exhaustMap(({ payload }) =>
        this.taxesService.addTax(payload.storageLocationId, payload.taxItem).pipe(
          map((taxItem) => TaxesActions.addTaxSuccess({ taxItem })),
          catchError((error) => of(TaxesActions.addTaxFailure({ error }))),
        ),
      ),
    ),
  );

  // Show Notification on Add Tax Success/Failure
  addTaxSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(TaxesActions.addTaxSuccess),
        tap(() => {
          this.notification.success(this.translate.instant('TAX_ITEM_SAVED_SUCCESSFULLY') as string);
        }),
      ),
    { dispatch: false },
  );

  addTaxFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(TaxesActions.addTaxFailure),
        tap(() => {
          this.notification.error(this.translate.instant('TAX_ITEM_SAVED_ERROR') as string);
        }),
      ),
    { dispatch: false },
  );

  // Update Tax Effect
  updateTax$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TaxesActions.updateTax),
      exhaustMap(({ payload }) =>
        this.taxesService.updateTax(payload.storageLocationId, payload.taxItem).pipe(
          map((updatedTaxItem) => TaxesActions.updateTaxSuccess({ updatedTaxItem })),
          catchError((error) =>
            of(
              TaxesActions.updateTaxFailure({
                error,
                taxItemId: payload.taxItemId,
              }),
            ),
          ),
        ),
      ),
    ),
  );

  // Show Notification on Update Tax Success/Failure
  updateTaxSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(TaxesActions.updateTaxSuccess),
        tap(() => {
          this.notification.success(this.translate.instant('TAX_ITEM_SAVED_SUCCESSFULLY') as string);
        }),
      ),
    { dispatch: false },
  );

  updateTaxFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(TaxesActions.updateTaxFailure),
        tap(() => {
          this.notification.error(this.translate.instant('TAX_ITEM_SAVED_ERROR') as string);
        }),
      ),
    { dispatch: false },
  );

  // Delete Tax Effect
  deleteTax$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TaxesActions.deleteTax),
      exhaustMap(({ payload }) =>
        this.taxesService.deleteTax(payload.storageLocationId, payload.taxItemId).pipe(
          map(() => TaxesActions.deleteTaxSuccess({ taxItemId: payload.taxItemId })),
          catchError((error) =>
            of(
              TaxesActions.deleteTaxFailure({
                error,
                taxItemId: payload.taxItemId,
              }),
            ),
          ),
        ),
      ),
    ),
  );

  // Show Notification on Delete Tax Success/Failure
  deleteTaxSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(TaxesActions.deleteTaxSuccess),
        tap(() => {
          this.notification.success(this.translate.instant('TAX_ITEM_DELETED') as string);
        }),
      ),
    { dispatch: false },
  );

  deleteTaxFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(TaxesActions.deleteTaxFailure),
        tap(() => {
          this.notification.error(this.translate.instant('ERROR_DELETING_TAX_ITEM') as string);
        }),
      ),
    { dispatch: false },
  );
}
