<div class="modal-content">
  <div class="modal-header">
    <h4 class="modal-title pull-left">
      <div>{{ 'PROMOTIONS' | translate }}</div>
    </h4>
    <button id="closePromoCodeModal" type="button" class="close pull-right" aria-label="Close" (click)="hideModal()">
      <svg viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
        />
        <path d="M0 0h24v24H0z" fill="none" />
      </svg>
    </button>
  </div>

  <div class="modal-body">
    <p>{{ 'DISCOUNT_VOUCHER_EXPLANATION' | translate }}</p>

    <div class="form-group">
      <div class="input-group">
        <input
          type="text"
          autocomplete="off"
          class="form-control discount-code"
          [(ngModel)]="promoCode"
          [placeholder]="'CODE' | translate"
          (keyup.enter)="checkPromoCodeButton.click()"
        />
        <span class="input-group-btn">
          <button
            id="checkPromoCode"
            class="btn btn-primary"
            type="button"
            (click)="checkPromoCode()"
            [disabled]="!canCheckPromoCode"
            #checkPromoCodeButton
          >
            {{ 'VALIDATE_CODE' | translate }}
          </button>
        </span>
      </div>
      <div class="alert alert-danger" *ngIf="showCheckedPromoCodeInvalid">
        <span>{{ 'THE_CODE' | translate }} {{ checkedPromoCode }} {{ 'IS_NOT_VALID' | translate }}</span>
      </div>
      <div class="alert alert-success" *ngIf="showCheckedPromoCodeValid">
        <span>{{ checkedDiscount | discount: bags }}</span>
      </div>
    </div>

    <div class="text-center">
      <lh-shared-ui-spinner [visible]="isLoading"></lh-shared-ui-spinner>
    </div>

    <button
      id="saveDiscountCode"
      class="btn btn-block btn-primary lh-btn mt-2"
      (click)="applyPromoCode()"
      *ngIf="canApplyPromoCode"
    >
      {{ 'APPLY_CODE' | translate }}
    </button>
  </div>
</div>
