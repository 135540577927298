/* eslint-disable */

// TODO: (lint): enable lint

import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

const ENABLE_BS_MODAL_BACKDROP_FIX = false;
const INTERCOM_FRAME_CONTAINER_SELECTOR = '#intercom-container .intercom-messenger-frame';
const INTERCOM_FRAME_SELECTOR = '#intercom-container .intercom-messenger-frame iframe';
const INTERCOM_VIEWPORT_SELECTOR = '#intercom-viewport-meta';
const VIEWPORT_CONTENT =
  'viewport-fit=cover, width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no';

@Injectable({
  providedIn: 'root',
})
export class SharedDocumentService {
  private _isFullscreenAvailable = false;

  get document(): Document {
    return this._document;
  }

  get scrollHeight(): number {
    return this.document.scrollingElement?.scrollHeight ?? 0;
  }

  get scrollPosition(): number {
    return this.document.scrollingElement?.scrollTop ?? 0;
  }

  get scrollClientHeight(): number {
    return this.document.scrollingElement?.clientHeight ?? 0;
  }

  get queryParams(): any {
    // get query string from url (optional) or window
    const queryString = this.document.location.search.slice(1).split('#')[0];

    // we'll store the parameters here
    const obj: any = {};

    // if query string exists
    if (queryString) {
      // split our query string into its component parts
      const arr = queryString.split('&');

      for (let i = 0; i < arr.length; i++) {
        // separate the keys and the values
        const a = arr[i].split('=');

        // set parameter name and value (use 'true' if empty)
        const paramName = a[0].toLowerCase();
        const paramValue = typeof a[1] === 'undefined' ? true : a[1].toLowerCase();

        // if the paramName ends with square brackets, e.g. colors[] or colors[2]
        if (paramName.match(/\[(\d+)?\]$/)) {
          // create key if it doesn't exist
          const key = paramName.replace(/\[(\d+)?\]/, '');
          if (!obj[key]) obj[key] = [];

          // if it's an indexed array e.g. colors[2]
          if (paramName.match(/\[\d+\]$/)) {
            // get the index value and add the entry at the appropriate position
            const match = /\[(\d+)\]/.exec(paramName);
            const index = match ? match[1] : null;
            if (index !== null) {
              obj[key][index] = paramValue;
            }
          } else {
            // otherwise add the value to the end of the array
            (obj[key] as string[]).push(paramValue as string);
          }
        } else {
          // we're dealing with a string
          if (!obj[paramName]) {
            // if it doesn't exist, create property
            obj[paramName] = paramValue;
          } else if (obj[paramName] && typeof obj[paramName] === 'string') {
            // if property does exist and it's a string, convert it to an array
            obj[paramName] = [obj[paramName]];
            (obj[paramName] as string[]).push(paramValue as string);
          } else {
            // otherwise add the property
            (obj[paramName] as string[]).push(paramValue as string);
          }
        }
      }
    }
    return obj;
  }

  get intercomFrameContainer(): HTMLIFrameElement | null {
    return this.document.body.querySelector(INTERCOM_FRAME_CONTAINER_SELECTOR);
  }

  get intercomFrame(): HTMLIFrameElement | null {
    return this.document.body.querySelector(INTERCOM_FRAME_SELECTOR);
  }

  get intercomViewport() {
    return this.document.head.querySelector(INTERCOM_VIEWPORT_SELECTOR);
  }

  public get isFullscreenAvailable(): boolean {
    return this._isFullscreenAvailable;
  }

  constructor(@Inject(DOCUMENT) private _document: Document) {
    const docElement = _document.documentElement as any;
    this._isFullscreenAvailable =
      docElement.requestFullscreen ||
      /* Firefox */
      docElement.mozRequestFullScreen ||
      /* Chrome, Safari and Opera */
      docElement.webkitRequestFullscreen ||
      /* IE/Edge */
      docElement.msRequestFullscreen;
  }

  enterFullscreen() {
    const docElement = this.document.documentElement as any;
    if (docElement.requestFullscreen) {
      docElement.requestFullscreen();
    } else if (docElement.mozRequestFullScreen) {
      /* Firefox */
      docElement.mozRequestFullScreen();
    } else if (docElement.webkitRequestFullscreen) {
      /* Chrome, Safari and Opera */
      docElement.webkitRequestFullscreen();
    } else if (docElement.msRequestFullscreen) {
      /* IE/Edge */
      docElement.msRequestFullscreen();
    }
  }

  exitFullscreen() {
    const doc = this.document as any;
    if (doc.exitFullscreen) {
      doc.exitFullscreen();
    } else if (doc.mozCancelFullScreen) {
      /* Firefox */
      doc.mozCancelFullScreen();
    } else if (doc.webkitExitFullscreen) {
      /* Chrome, Safari and Opera */
      doc.webkitExitFullscreen();
    } else if (doc.msExitFullscreen) {
      /* IE/Edge */
      doc.msExitFullscreen();
    }
  }

  addHeadStyling(css: string, id = 'lh-styles') {
    this.removeHeadStyling(id);

    const style = this.document.createElement('style');
    style.setAttribute('id', id);
    style.appendChild(this.document.createTextNode(css));

    this.document.head.appendChild(style);
  }

  removeHeadStyling(id = 'lh-styles') {
    const style = this.document.getElementById(id);
    if (style) {
      this.document.head.removeChild(style);
    }
  }

  addBodyClass(className: string) {
    this.document.body.classList.add(className);
  }

  removeBodyClass(className: string) {
    this.document.body.classList.remove(className);
  }

  addBodySvg(svg: string) {
    if (
      !this.document.createElementNS ||
      !this.document.createElementNS('http://www.w3.org/2000/svg', 'svg').createSVGRect
    ) {
      return;
    }
    this.document.body.insertAdjacentHTML('afterbegin', svg);
  }

  fixBsModalBackdrop(modalCount: number) {
    if (!ENABLE_BS_MODAL_BACKDROP_FIX) {
      return;
    }

    // Calculate the desired z-index for the top modal and its backdrop
    const zIndexModal = 1040 + 10 * modalCount;
    const zIndexBackdrop = zIndexModal - 1;

    // Get all modal elements
    const modalElements = this.document.getElementsByClassName('modal');

    if (modalElements && modalElements.length > 0) {
      // Set z-index on the top modal element
      const topModalElement = modalElements[modalElements.length - 1];
      const existingModalStyle = topModalElement.getAttribute('style');
      topModalElement.setAttribute('style', `z-index: ${zIndexModal}; ${existingModalStyle}`);

      // Using setTimeout as the backdrop is not created when the modal shown event is triggered
      setTimeout(() => {
        const modalBackdropElements = this.document.getElementsByClassName('modal-backdrop');

        for (let i = 0; i < modalBackdropElements.length; i++) {
          // Look for a backdrop element that hasn't already been modified
          const modalBackdropElement = modalBackdropElements[i];
          if (!modalBackdropElement.classList.contains('modal-stack')) {
            // Set z-index on the backdrop element and add a class so we know this backdrop was modified
            const existingBackdropStyle = modalBackdropElement.getAttribute('style');
            modalBackdropElement.setAttribute('style', `z-index: ${zIndexBackdrop}; ${existingBackdropStyle}`);
            modalBackdropElement.classList.add('modal-stack');

            // Only modify one backdrop element
            break;
          }
        }
      });
    }
  }

  setIntercomMessengerHeight(value: string) {
    if (!this.intercomFrame) {
      return;
    }
    this.intercomFrame.setAttribute('style', `height: ${value} !important`);
  }

  resetIntercomMessengerHeight() {
    if (!this.intercomFrame) {
      return;
    }
    this.intercomFrame.removeAttribute('style');
  }

  fixIntercomViewport(currentDelay = 1, totalDelay = 0) {
    if (totalDelay >= 100) {
      return;
    }
    setTimeout(() => {
      const fixed = this.tryFixIntercomViewport();
      if (!fixed) {
        totalDelay += currentDelay;
        this.fixIntercomViewport(++currentDelay, totalDelay);
      }
    }, currentDelay);
  }

  private tryFixIntercomViewport(): boolean {
    if (!this.intercomViewport) {
      return false;
    }
    this.intercomViewport.setAttribute('content', VIEWPORT_CONTENT);
    return true;
  }
}
