import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { ImageSlideBaseComponent } from '@luggagehero/features/ui';

@Component({
  selector: 'lh-image-slide',
  templateUrl: './image-slide.component.html',
  styleUrls: ['./image-slide.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImageSlideComponent extends ImageSlideBaseComponent {
  constructor(cd: ChangeDetectorRef) {
    super(cd);
  }
}
