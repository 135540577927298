<div class="row">
  <div *ngIf="isSkipLoginVariant && !isLoggedIn" class="col-12">
    <button *ngIf="isPrimary" class="btn btn-block btn-primary" (click)="requestLogin()">
      {{ 'ADD_PAYMENT_CARD' | translate }}
    </button>
    <button *ngIf="!isPrimary" class="btn btn-block btn-link mt-2" (click)="requestLogin()">
      {{ 'USE_OTHER_PAYMENT_METHOD' | translate }}
    </button>
  </div>

  <div [hidden]="isSkipLoginVariant && !isLoggedIn">
    <form class="paymentCardForm" (ngSubmit)="submit()" #paymentCardForm="ngForm">
      <div class="col-12 form-group" *ngIf="isLoading">
        <div class="form-control spinner-container">
          <lh-shared-ui-spinner [visible]="true"></lh-shared-ui-spinner>
        </div>
      </div>

      <div class="col-12 form-group" [hidden]="isLoading">
        <div id="cardContainer" class="form-control card-input" #cardContainer>
          <!-- a Stripe Element will be inserted here. -->
        </div>
        <div id="cardErrors" role="alert" *ngIf="cardError" class="alert alert-danger alert-card">{{ cardError }}</div>
      </div>

      <div class="col-12 buttons" [class.compact]="isSimpleMode" *ngIf="isSubmitButtonVisible">
        <button id="confirmAddPaymentCard" class="btn btn-primary btn-block" type="submit" [disabled]="isLoading">
          {{ addCardCta | async | translate }}
        </button>

        <button
          id="cancelAddPaymentCard"
          *ngIf="allowCancel"
          class="btn btn-link btn-block"
          type="button"
          (click)="onCancel()"
          [disabled]="isLoading"
        >
          {{ 'CANCEL' | translate }}
        </button>

        <!-- Skip button for testing -->
        <!-- <button
        id="skipPaymentCard"
        *ngIf="showSkipCard"
        class="btn btn-link btn-xs btn-skip"
        [class.right]="allowCancel"
        type="submit"
        [disabled]="isLoading"
        (click)="skipCard()"
      >
        Skip
      </button> -->

        <div class="security-badge" *ngIf="!isSimpleMode">
          <svg width="20" height="20" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <title>https</title>
            <path
              d="M15.094 8.016v-2.016q0-1.266-0.914-2.18t-2.18-0.914-2.18 0.914-0.914 2.18v2.016h6.188zM12 17.016q0.797 0 1.406-0.609t0.609-1.406-0.609-1.406-1.406-0.609-1.406 0.609-0.609 1.406 0.609 1.406 1.406 0.609zM18 8.016q0.797 0 1.406 0.586t0.609 1.383v10.031q0 0.797-0.609 1.383t-1.406 0.586h-12q-0.797 0-1.406-0.586t-0.609-1.383v-10.031q0-0.797 0.609-1.383t1.406-0.586h0.984v-2.016q0-2.063 1.477-3.539t3.539-1.477 3.539 1.477 1.477 3.539v2.016h0.984z"
            ></path>
          </svg>
          <div>{{ 'SECURE_PAYMENTS' | translate }}</div>
        </div>
      </div>
    </form>

    <div class="col-12" *ngIf="showCardBrands && !isSimpleMode">
      <!-- Supported cards -->
      <div class="span-xs-12">
        <ul class="card-list" [class.compact]="!isSubmitButtonVisible">
          <!-- Cards supporting payments in all currencies -->
          <li class="cardBrand-visa" tooltip="Visa">Visa</li>
          <li class="cardBrand-masterCard" tooltip="Master Card">Master Card</li>
          <li class="cardBrand-maestro" tooltip="Maestro">Maestro</li>
          <li class="cardBrand-americanExpress" tooltip="American Express">American Express</li>
          <li class="cardBrand-chinaUnion" tooltip="UnionPay">UnionPay</li>
          <li class="cardBrand-discover" tooltip="Discover">Discover</li>

          <!-- Not supported with a DK Stripe account -->
          <!-- <li class="cardBrand-jcb" tooltip="JCB">JCB</li> -->
          <!-- <li class="cardBrand-dinersClub" tooltip="Diners Club">Diners Club</li> -->
        </ul>
      </div>
    </div>
  </div>
</div>
