/* eslint-disable @typescript-eslint/no-unsafe-enum-comparison */
import { isPlatformBrowser } from '@angular/common';
import {
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  OnInit,
  Output,
  PLATFORM_ID,
} from '@angular/core';
import { IntlTelInputData } from '@luggagehero/shared/interfaces';
import intlTelInput from 'intl-tel-input';

const ERROR_PHONE_COUNTRY_CODE_INVALID = 'PHONE_COUNTRY_CODE_INVALID';
const ERROR_PHONE_NUMBER_TOO_SHORT = 'PHONE_NUMBER_TOO_SHORT';
const ERROR_PHONE_NUMBER_TOO_LONG = 'PHONE_NUMBER_TOO_LONG';
const ERROR_PHONE_NUMBER_INVALID = 'PHONE_NUMBER_INVALID';

@Directive({ selector: '[lhIntlTelInput]' })
export class IntlTelInputDirective implements OnInit {
  @Input() public intlTelInputOptions: intlTelInput.Options;
  @Output() public inputChange: EventEmitter<IntlTelInputData> = new EventEmitter<IntlTelInputData>();

  public intlTelInput: intlTelInput.Plugin;

  private data: IntlTelInputData = {};

  constructor(
    private el: ElementRef<HTMLInputElement>,
    @Inject(PLATFORM_ID) private platformId: string,
  ) {}

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      this.intlTelInput = intlTelInput(this.el.nativeElement, this.intlTelInputOptions);
      this.data.countryData = this.intlTelInput.getSelectedCountryData();
      this.el.nativeElement.addEventListener('countrychange', () => {
        this.data.countryData = this.intlTelInput.getSelectedCountryData();
        this.inputChange.emit(this.data);
      });
    }
  }

  @HostListener('keydown', ['$event']) onKeyDown(_e: KeyboardEvent) {
    if (!this.intlTelInput) {
      return;
    }
    this.data.phoneNumber = this.getPhoneNumber();
    this.data.errorCode = this.getValidationError();
    this.inputChange.emit(this.data);
  }

  getValidationError(): string {
    if (!this.intlTelInput) {
      return null;
    }

    if (this.intlTelInput.isValidNumber()) {
      return null;
    }

    const errorCode = this.intlTelInput.getValidationError();
    switch (errorCode) {
      case intlTelInputUtils.validationError.IS_POSSIBLE:
        // This shouldn't happen as we already know the number is invalid
        return null;
      case intlTelInputUtils.validationError.INVALID_COUNTRY_CODE:
        return ERROR_PHONE_COUNTRY_CODE_INVALID;
      case intlTelInputUtils.validationError.TOO_SHORT:
        return ERROR_PHONE_NUMBER_TOO_SHORT;
      case intlTelInputUtils.validationError.TOO_LONG:
        return ERROR_PHONE_NUMBER_TOO_LONG;
      case intlTelInputUtils.validationError.NOT_A_NUMBER:
        return ERROR_PHONE_NUMBER_INVALID;
      default:
        // In case the error code doesn't map to anything we know, use the most generic error
        return ERROR_PHONE_NUMBER_INVALID;
    }
  }

  getPhoneNumber(): string {
    if (!this.intlTelInput) {
      return null;
    }
    return this.intlTelInput.getNumber();
  }
}
