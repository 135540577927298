import { inject, Pipe, PipeTransform } from '@angular/core';
import { BookableStorageLocation, GOOGLE_MAPS_SERVICE, IGoogleMapsService } from '@luggagehero/shared/interfaces';

@Pipe({ name: 'mapsLink', standalone: true })
export class MapsLinkPipe implements PipeTransform {
  private googleMapsService = inject<IGoogleMapsService>(GOOGLE_MAPS_SERVICE);

  transform(value: BookableStorageLocation, directions = false): string {
    let link = '';

    try {
      link = this.transformStorageLocation(value, directions);
    } catch {
      // Ignore errors
    }

    return link;
  }

  transformStorageLocation(value: BookableStorageLocation, directions: boolean): string {
    return directions ? this.googleMapsService.getDirectionsLink(value) : this.googleMapsService.getPlaceLink(value);
  }
}
