import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'zeroPaddedNumber', standalone: true })
export class ZeroPaddedNumberPipe implements PipeTransform {
  transform(value: number, minLength = 4): string {
    let output = String(value);

    if (output.length < minLength) {
      let paddingCount = minLength - output.length;

      while (paddingCount-- > 0) {
        output = `0${output}`;
      }
    }
    return output;
  }
}
