<div class="spinner-container" *ngIf="!isInitialized">
  <lh-shared-ui-spinner [visible]="true"></lh-shared-ui-spinner>
</div>

<div *ngIf="isInitialized">
  <div class="form-group payment-method-dropdown" *ngIf="isPaymentMethodDropdownVisible">
    <div class="custom-select form-group">
      <select
        class="form-select"
        id="paymentMethodList"
        [ngModel]="selectedIndex"
        #paymentMethodSelect
        (change)="selectPaymentMethod(paymentMethodSelect.value)"
        [disabled]="disabled || isLoading"
      >
        <option *ngFor="let p of paymentMethods; let i = index" [value]="i">
          <span *ngIf="!isDirectPayment(p) && !isPayPal(p)">
            {{ p | paymentMethod: 'summary' }} ({{ p | paymentMethod: 'expiryShort' }})
          </span>
          <span *ngIf="isPayPal(p)">
            {{ p | paymentMethod: 'summary' }}
          </span>
          <span *ngIf="isDirectPayment(p)">{{ 'PAY_IN_SHOP' | translate }}</span>
        </option>
        <option [value]="paymentMethods.length">&lt;{{ 'NEW_PAYMENT_METHOD' | translate }}&gt;</option>
      </select>
    </div>
  </div>

  <!-- Wallet payment -->
  <div *ngIf="isWalletPaymentVisible" class="mb-2">
    <!-- Apple Pay -->
    <lh-shared-ui-apple-pay-button
      [type]="isSimpleMode && isConfirmingBooking ? 'book' : 'plain'"
      [theme]="'dark'"
      [size]="isSimpleMode ? 'medium' : 'small'"
      [elevated]="false"
      [rounded]="isSimpleMode"
      [disabled]="disabled || isLoading"
      *ngIf="isApplePayAvailable"
    ></lh-shared-ui-apple-pay-button>

    <!-- Google Pay -->
    <lh-shared-ui-google-pay-button
      [id]="'googlePayButton'"
      [type]="isSimpleMode && isConfirmingBooking ? 'book' : 'plain'"
      [theme]="'dark'"
      [size]="isSimpleMode ? 'medium' : 'small'"
      [elevated]="false"
      [rounded]="isSimpleMode"
      [disabled]="disabled || isLoading"
      (press)="showBrowserPayment()"
      *ngIf="isGooglePayAvailable"
    ></lh-shared-ui-google-pay-button>
  </div>

  <!-- PayPal payment -->
  <div *ngIf="isPayPalPaymentVisible" class="mb-2">
    <lh-shared-ui-paypal-button
      [id]="'paypalButton'"
      [type]="isSimpleMode && isConfirmingBooking ? 'book' : 'plain'"
      [theme]="'gold'"
      [size]="isSimpleMode ? 'medium' : 'small'"
      [elevated]="false"
      [rounded]="isSimpleMode"
      [logomark]="!isSimpleMode"
      [logotype]="true"
      [disabled]="disabled || isLoading"
      (press)="onPaypalClick($event)"
    ></lh-shared-ui-paypal-button>
  </div>

  <!-- Payment card form -->
  <div class="form-group" *ngIf="isPaymentCardFormVisible">
    <div
      class="divider"
      [hidden]="
        isSimpleMode || (isSkipLoginVariant && !isLoggedIn) || (!isPayPalPaymentVisible && !isWalletPaymentVisible)
      "
    >
      <small class="divider-text">{{ 'OR' | translate | lowercase }}</small>
    </div>

    <lh-payment-card-input
      [isLoading]="isLoading"
      [isPrimary]="!isWalletPaymentAvailable"
      [allowCancel]="false"
      [isConfirmingBooking]="isConfirmingBooking"
      [isSimpleMode]="isSimpleMode"
      [isSubmitButtonVisible]="!isConfirmButtonHidden"
      [orderId]="orderId"
      [storageLocationId]="storageLocationId"
      [payment]="payment"
      (result)="onPaymentCardResult($event)"
      (cancel)="cancelAddPaymentCard()"
      #paymentCardInput
    ></lh-payment-card-input>
  </div>

  <!-- Direct payment info -->
  <div class="form-group" *ngIf="isDirectPaymentSelected && !isPaymentCardFormVisible">
    <small>{{ 'YOU_CAN_PAY_THE_STAFF_DIRECTLY' | translate }}.</small>
  </div>

  <button
    id="confirmPaymentMethod"
    class="btn btn-sm btn-block btn-primary"
    (click)="confirmPaymentMethod()"
    *ngIf="isConfirmButtonVisible"
    [disabled]="disabled || isLoading"
  >
    {{ confirmCallToAction | translate }}
  </button>
</div>
