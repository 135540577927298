<div class="modal-content">
  <div class="modal-header">
    <h4 class="modal-title pull-left">
      {{ 'PRICE' | translate }} {{ 'PER_BAG' | translate | lowercase }}
      <span *ngIf="region">({{ region | uppercase }})</span>
    </h4>
    <button id="closePriceModal" type="button" class="close pull-right" aria-label="Close" (click)="hideModal()">
      <svg viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
        />
        <path d="M0 0h24v24H0z" fill="none" />
      </svg>
    </button>
  </div>

  <div class="modal-body">
    <div *ngIf="!pricing" class="text-center mb-4">
      <lh-shared-ui-spinner [visible]="true"> </lh-shared-ui-spinner>
    </div>

    <div *ngIf="pricing">
      <div class="pricing-header">
        <h3 class="price-option" [innerHtml]="hourlyRateHtml | async"></h3>
        <span class="pricing-separator"></span>
        <h3 class="price-option" [innerHtml]="dailyRateHtml | async"></h3>
      </div>

      <br />

      <table class="table">
        <tr>
          <td class="checkmark">✔</td>
          <td>{{ 'CHOICE_OF_PRICING_MODEL' | translate }}</td>
        </tr>

        <tr>
          <td class="checkmark">✔</td>
          <td>
            {{ 'PRICES_APPLY_TO_ADVANCE_BOOKINGS' | translate }}.
            <b>{{ 'WALK_IN_RATES_MAY_BE_HIGHER' | translate }}</b>
          </td>
        </tr>

        <tr>
          <td class="checkmark">✔</td>
          <td>
            {{ 'VOLUME_DISCOUNTS_FOR_ONLINE_BOOKINGS' | translate }}
          </td>
        </tr>
      </table>
    </div>

    <!-- OK button -->
    <div class="footer">
      <button id="understoodPricing" type="button" (click)="hideModal()" class="btn btn-lg btn-primary btn-ok">
        {{ 'OK_GOT_IT' | translate }}
      </button>
    </div>
  </div>
</div>
