<p>
  {{ pricing | pricing: false : false : false : false : false : false | async }}
  {{ 'OR' | translate }}
  {{ pricing | pricing: false : true : false : false : false : false | async }}
  {{ 'PER_BAG' | translate | lowercase }}.
  {{ 'BOOK_AHEAD_FOR_BEST_RATES' | translate }}

  @if (showPriceInfoOptions) {
    <a id="showPriceInfo" class="href text-decoration-none" (click)="showPriceInfoClick()">
      {{ 'DETAILS' | translate }}
    </a>
  }
</p>

@if (showDiscountOptions) {
  <p class="mb-0">
    <a id="showDiscountCodeOptions" class="href text-decoration-none" (click)="showDiscountOptionsClick()">
      {{ 'HAVE_A_DISCOUNT_CODE' | translate }}
    </a>
  </p>
}
