import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  inject,
  Input,
  Output,
} from '@angular/core';

type PaymentCardButtonTheme = 'primary' | 'warning' | 'info';
type PaymentCardButtonSize = 'small' | 'medium' | 'large';

@Component({
  selector: 'lh-shared-ui-payment-card-button',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './shared-ui-payment-card-button.component.html',
  styleUrl: './shared-ui-payment-card-button.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SharedUiPaymentCardButtonComponent {
  @Input() public theme: PaymentCardButtonTheme = 'warning';
  @Input() public size: PaymentCardButtonSize = 'medium';
  @Input() public rounded = false;
  @Input() public elevated = false;

  @Output() public readonly press = new EventEmitter<Event>();

  private _selected = false;
  private _disabled = false;
  private _isLoading = false;
  private _id = '';

  private readonly cd = inject(ChangeDetectorRef);

  public get id(): string {
    return this._id || 'paymentCardButton';
  }
  @Input() public set id(value: string) {
    this._id = value;
    this.cd.markForCheck();
  }

  public get isLoading(): boolean {
    return this._isLoading;
  }
  @Input() public set isLoading(value: boolean) {
    this._isLoading = value;
    this.cd.markForCheck();
  }

  public get disabled(): boolean {
    return this._disabled;
  }
  @Input() public set disabled(value: boolean) {
    this._disabled = value;
    this.cd.markForCheck();
  }

  public get selected(): boolean {
    return this._selected;
  }
  @Input() public set selected(value: boolean) {
    this._selected = value;
    this.cd.markForCheck();
  }

  public onClick(event: Event): void {
    if (this.disabled || this.isLoading) {
      event.preventDefault();
      event.stopPropagation();
      return;
    }
    this.press.emit(event);
  }
}
