<div class="modal-content">
  <div class="modal-header">
    <h4 *ngIf="!config.intro.inline" class="modal-title pull-left">
      <div>{{ config.intro.headline | translate }}</div>
      <div class="modal-subtitle">{{ config.intro.tagline | translate }}</div>
    </h4>
    <button id="closeHowItWorksModal" type="button" class="close pull-right" aria-label="Close" (click)="hideModal()">
      <svg viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
        />
        <path d="M0 0h24v24H0z" fill="none" />
      </svg>
    </button>
  </div>

  <div class="modal-body">
    <div *ngIf="config.intro.inline" class="intro">
      <div class="logo-container">
        <img src="/assets/logo_light_bg.png" />
      </div>
      <div class="headline">{{ config.intro.headline | translate }}</div>
      <div class="tagline">{{ config.intro.tagline | translate }}</div>

      <h4 class="how-it-works">{{ 'HOW_IT_WORKS' | translate }}</h4>
    </div>

    <!-- Slides -->
    <div class="slide-list">
      <div *ngFor="let slide of config.slides; let i = index" class="slide">
        <div class="image-container" *ngIf="slide.image">
          <img [class.frame]="slide.image.frame" [class.round]="slide.image.round" [src]="slide.image.url" />
        </div>
        <div class="text-container" *ngIf="slide.text">
          <div class="header">{{ slide.text.header | translate }}</div>
          <div class="body">{{ slide.text.body | translate }}</div>
        </div>
        <hr class="step-divider" *ngIf="hasDividerAfterSlide(i)" />
      </div>
    </div>

    <!-- OK button -->
    <div class="footer">
      <button id="understoodHowItWorks" type="button" (click)="hideModal()" class="btn btn-lg btn-primary btn-ok">
        {{ 'OK_GOT_IT' | translate }}
      </button>
    </div>
  </div>
</div>
