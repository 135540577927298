// angular
import { Injectable } from '@angular/core';
import { AbstractControl, ValidationErrors } from '@angular/forms';

// libs
import { StringUtil } from '../utils/string.util';

const VALID_PHONE_REGEX = new RegExp('^(?:00|\\+)[0-9\\s.\\/-]{6,20}$');

@Injectable()
export class PhoneNumberService {
  validateMultiple(control: AbstractControl): ValidationErrors {
    if (!control.value) {
      return null;
    }
    const inputStrings = StringUtil.split(control.value as string);
    for (let i = 0; i < inputStrings.length; i++) {
      const input = inputStrings[i].trim();
      if (!VALID_PHONE_REGEX.test(input)) {
        return { invalidPhoneNumber: true };
      }
    }
    // No parsing errors so all phone numbers are valid
    return null;
  }

  validateSingle(control: AbstractControl): ValidationErrors {
    if (!control.value) {
      return null;
    }
    const input = (<string>control.value).trim();
    if (!VALID_PHONE_REGEX.test(input)) {
      return { invalidPhoneNumber: true };
    }
    // No parsing errors so all phone numbers are valid
    return null;
  }
}
