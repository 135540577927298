import { inject, Pipe, PipeTransform } from '@angular/core';
import {
  Booking,
  BOOKING_TRANSFORMATION_SERVICE,
  BookingTransformationService,
  BookingTransformType,
} from '@luggagehero/shared/interfaces';

@Pipe({ name: 'booking', standalone: true })
export class BookingPipe implements PipeTransform {
  private bookingTransformationService = inject<BookingTransformationService>(BOOKING_TRANSFORMATION_SERVICE);

  transform(booking: Booking, type: BookingTransformType, includePeriod = true, format?: string): string {
    return this.bookingTransformationService.transformBooking(booking, type, includePeriod, format);
  }
}
