import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { ProductInfo, ProductScope } from '@luggagehero/shared/interfaces';
import { TranslateService } from '@ngx-translate/core';

import { BaseComponent } from '../../../core';

const noop = () => {
  /**/
};

@Component({ template: '' })
export abstract class ProductSelectorBaseComponent extends BaseComponent implements ControlValueAccessor {
  @Output() public confirm = new EventEmitter<void>();
  @Input() public currency = 'usd';
  @Input() public bags = 1;

  public isSelectionMade = false;

  private _value: ProductInfo[] = [];
  private _availableProducts: ProductInfo[];

  private onTouchedCallback: () => void = noop;
  private onChangeCallback: (_) => void = noop;

  constructor(
    private translate: TranslateService,
    private cd: ChangeDetectorRef,
  ) {
    super();
  }

  get value(): ProductInfo[] {
    return this._value;
  }
  set value(value: ProductInfo[]) {
    this._value = value || [];

    this.onChangeCallback(value);
    this.cd.markForCheck();
  }

  get availableProducts(): ProductInfo[] {
    return this._availableProducts;
  }
  @Input() set availableProducts(value: ProductInfo[]) {
    if (!this.isSelectionMade && value.some((p) => p.selected)) {
      // If one or more optional products are preselected, we consider the selection made
      this.isSelectionMade = true;
    }
    this._availableProducts = value;
    this.updateSelectedProducts();
  }

  onProductSelectedChange(): void {
    this.isSelectionMade = true;
    this.updateSelectedProducts();
  }

  updateSelectedProducts(): void {
    this.value = this.availableProducts.filter((p) => p.selected);
  }

  getProductDisplayName(product: ProductInfo): string {
    const translateKey = product.tags?.find((t) => t.key === 'displayName')?.value;
    return ((translateKey && this.translate.instant(translateKey)) as string) || '';
  }

  getProductInfoHeader(product: ProductInfo): string {
    const translateKey = product.tags?.find((t) => t.key === 'infoHeader')?.value;
    return ((translateKey && this.translate.instant(translateKey)) as string) || '';
  }

  getProductInfoBody(product: ProductInfo): string {
    const translateKey = product.tags?.find((t) => t.key === 'infoBody')?.value;
    return ((translateKey && this.translate.instant(translateKey)) as string) || '';
  }

  getProductCost(product: ProductInfo): number {
    const multiplier = product.scope === ProductScope.PerBag ? this.bags : 1;
    return product.cost * multiplier;
  }

  confirmSelection() {
    this.confirm.emit();
  }

  writeValue(value: ProductInfo[]): void {
    if (value !== undefined) {
      this.value = value;
    }
  }

  registerOnChange(fn: (_) => void): void {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouchedCallback = fn;
  }
}
