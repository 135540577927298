import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { AbstractControl, ControlValueAccessor, ValidationErrors, Validator } from '@angular/forms';
import { BaseComponent } from '@luggagehero/core';
import { IPLookupService } from '@luggagehero/services';
import { AppConfig } from '@luggagehero/shared/app-settings/data-access';
import { IPhoneCountry, IntlTelInputData } from '@luggagehero/shared/interfaces';
import { TranslateService } from '@ngx-translate/core';

const noop = () => {
  /**/
};

export enum PhoneNumberType {
  'FIXED_LINE' = 0,
  'MOBILE' = 1,
  // In some regions (e.g. the USA), it is impossible to distinguish between
  // fixed-line and mobile numbers by looking at the phone number itself.
  'FIXED_LINE_OR_MOBILE' = 2,
  // Freephone lines
  'TOLL_FREE' = 3,
  'PREMIUM_RATE' = 4,
  // The cost of this call is shared between the caller and the recipient, and
  // is hence typically less than PREMIUM_RATE calls. See
  // http://en.wikipedia.org/wiki/Shared_Cost_Service for more information.
  'SHARED_COST' = 5,
  // Voice over IP numbers. This includes TSoIP (Telephony Service over IP).
  'VOIP' = 6,
  // A personal number is associated with a particular person, and may be routed
  // to either a MOBILE or FIXED_LINE number. Some more information can be found
  // here = http://en.wikipedia.org/wiki/Personal_Numbers
  'PERSONAL_NUMBER' = 7,
  'PAGER' = 8,
  // Used for 'Universal Access Numbers' or 'Company Numbers'. They may be
  // further routed to specific offices, but allow one number to be used for a
  // company.
  'UAN' = 9,
  // Used for 'Voice Mail Access Numbers'.
  'VOICEMAIL' = 10,
  // A phone number is of type UNKNOWN when it does not fit any of the known
  // patterns for a specific region.
  'UNKNOWN' = -1,
}

export interface AdvancedPhoneInputOptions {
  allowDropdown?: boolean;
  autoHideDialCode?: boolean;
  autoPlaceholder?: 'polite' | 'aggressive' | 'off';
  customPlaceholder?: (selectedCountryPlaceholder: string, selectedCountryData: unknown) => string;
  dropdownContainer?: Node;
  excludeCountries?: string[];
  formatOnDisplay?: boolean;
  geoIpLookup?: (success, failure) => void;
  hiddenInput?: string;
  initialCountry?: string;
  localizedCountries?: { [countryCode: string]: string };
  nationalMode?: boolean;
  onlyCountries?: string[];
  placeholderNumberType?: PhoneNumberType;
  preferredCountries?: string[];
  separateDialCode?: boolean;
  utilsScript?: string;
}

@Component({ template: '' })
export abstract class PhoneInputBaseComponent extends BaseComponent implements ControlValueAccessor, Validator, OnInit {
  @ViewChild('intlTelInput') public telInput: ElementRef<HTMLInputElement>;
  @Output() public selectedCountryChange = new EventEmitter<string>();
  @Output() public focus = new EventEmitter<void>();
  @Input() public requestFocus = false;
  @Input() public readonly = false;

  public advancedOptions: AdvancedPhoneInputOptions = {
    geoIpLookup: (callback) => {
      this.ipLookup
        .locate()
        .then((res) => callback(res.country_code))
        .catch(() => callback(AppConfig.INITIAL_PHONE_COUNTRY_CODE));
    },
    initialCountry: 'auto', // AppConfig.INITIAL_PHONE_COUNTRY_CODE,
    preferredCountries: AppConfig.PREFERRED_PHONE_COUNTRIES,
    separateDialCode: true,
    utilsScript: 'assets/scripts/intl-tel-input/utils.js',
    customPlaceholder: () => this.translate.instant('PHONE_NUMBER'),
  };

  private _value: string;
  private _placeholder: string;
  private _selectedDialCode: string;
  private _selectedCountry: string;
  private _countries: IPhoneCountry[];
  private _phoneNumber = '';
  private _advancedMode = false;
  private isFirstUpdate = true;

  private onTouchedCallback: () => void = noop;
  private onChangeCallback: (_: unknown) => void = noop;

  constructor(
    private ipLookup: IPLookupService,
    private translate: TranslateService,
    private cd: ChangeDetectorRef,
  ) {
    super();
  }

  get value(): string {
    return this._value;
  }
  set value(value: string) {
    if (!value || value === this._value) {
      return;
    }
    this._value = value;

    if (value !== this.selectedDialCode) {
      this.onChangeCallback(value);
    }
  }

  get placeholder(): string {
    return this._placeholder;
  }
  @Input() set placeholder(value: string) {
    this._placeholder = value;
    this.cd.markForCheck();
  }

  get countries(): IPhoneCountry[] {
    return this._countries;
  }
  @Input() set countries(value: IPhoneCountry[]) {
    this._countries = value;
    this.cd.markForCheck();
  }

  get advancedMode(): boolean {
    return this._advancedMode;
  }
  @Input() set advancedMode(value: boolean) {
    this._advancedMode = value;
    this.cd.markForCheck();
  }

  get selectedCountry(): string {
    return this._selectedCountry;
  }
  @Input() set selectedCountry(value: string) {
    if (!value || value === this._value) {
      return;
    }
    if (this.advancedMode) {
      this._selectedCountry = value;
      this.selectedCountryChange.emit(this._selectedCountry);
    } else {
      // This will set the country code by looking it up from our list of countries
      this.selectedDialCode = this.countries.find((c) => c.countryCode === value).dialCode;
    }
  }

  get selectedDialCode(): string {
    return this._selectedDialCode;
  }
  set selectedDialCode(value: string) {
    if (!value || value === this._value) {
      return;
    }
    this._selectedDialCode = value;
    this.updateValue();

    if (!this.advancedMode) {
      this._selectedCountry = this.countries.find((c) => c.dialCode === value).countryCode;
      this.selectedCountryChange.emit(this._selectedCountry);
    }
  }

  get phoneNumber(): string {
    return this._phoneNumber;
  }
  set phoneNumber(value: string) {
    this._phoneNumber = value;
    this.updateValue();
  }

  ngOnInit() {
    if (this.advancedMode) {
      this.selectedDialCode = AppConfig.INITIAL_PHONE_DIAL_CODE;
      this.selectedCountry = AppConfig.INITIAL_PHONE_COUNTRY_CODE;
    } else if (this.countries) {
      this.selectedDialCode = this.countries[0].dialCode;
    }
  }

  writeValue(value: string) {
    if (value !== undefined) {
      this.value = value;
    }
  }

  registerOnChange(fn: (_: unknown) => void) {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: () => void) {
    this.onTouchedCallback = fn;
  }

  abstract validate(control: AbstractControl): ValidationErrors;

  onIntlTelInputChange(value: IntlTelInputData) {
    this.selectedCountry = value.countryData.iso2;
    this.selectedDialCode = `+${value.countryData.dialCode}`;

    // Avoid setting dirty on initial setting of country
    if (value.phoneNumber || !this.isFirstUpdate) {
      this.isFirstUpdate = false;
      this.onChangeCallback(this.value);
    }
  }

  onInputBlur() {
    this.onTouchedCallback();
  }

  onInputFocus() {
    this.focus.emit();
  }

  setFocus() {
    if (!this.telInput) {
      return;
    }
    this.telInput.nativeElement.focus();
  }

  setSelected() {
    if (!this.telInput) {
      return;
    }
    this.telInput.nativeElement.select();
  }

  updateValue() {
    // Remove dial code from the phone number if it matches the dial code selected in the dropdown
    if (this.phoneNumber.startsWith(this.selectedDialCode)) {
      this.phoneNumber = this.phoneNumber.substring(this.selectedDialCode.length);
    }

    // Check if the user pasted or entered phone number with dial code icluded
    const phoneNumberIncludesDialCode = this.phoneNumber.match(/^\+|^(00)/) ? true : false;

    // Change 00 to + for dial codes included in the phone number itself
    if (phoneNumberIncludesDialCode && this.phoneNumber.startsWith('00')) {
      this.phoneNumber = this.phoneNumber.replace(/^(00)/, '+');
    }

    // Set value depending on whether dial code is already included in the phone number
    this.value = phoneNumberIncludesDialCode ? this.phoneNumber : `${this.selectedDialCode}${this.phoneNumber}`;
  }
}
