import { CommonModule } from '@angular/common';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { Angulartics2Facebook, Angulartics2GoogleTagManager, Angulartics2Module } from 'angulartics2';

/**
 * Do not specify providers for modules that might be imported by a lazy loaded module.
 */

@NgModule({
  imports: [CommonModule, RouterModule, Angulartics2Module.forRoot()],
  providers: [provideHttpClient(withInterceptorsFromDi())],
})
export class AnalyticsModule {
  constructor(angulartics2GoogleTagManager: Angulartics2GoogleTagManager, angulartics2Facebook: Angulartics2Facebook) {
    // start tracking!
    angulartics2GoogleTagManager.startTracking();
    angulartics2Facebook.startTracking();
  }
}
