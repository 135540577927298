import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, Provider } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { LuggagePickerBaseComponent } from '@luggagehero/features/ui';
// Importing services directly from their own files instead of via index as otherwise getting this issue:
// http://stackoverflow.com/questions/37997824/angular-2-di-error-exception-cant-resolve-all-parameters
import { IconService } from '@luggagehero/services/icon.service';
import { SharedLoggingService } from '@luggagehero/shared/services/logging';

export const LUGGAGE_PICKER_CONTROL_VALUE_ACCESSOR: Provider = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => LuggagePickerComponent),
  multi: true,
};

@Component({
  selector: 'lh-luggage-picker',
  templateUrl: './luggage-picker.component.html',
  styleUrls: ['./luggage-picker.component.scss'],
  providers: [LUGGAGE_PICKER_CONTROL_VALUE_ACCESSOR],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LuggagePickerComponent extends LuggagePickerBaseComponent implements ControlValueAccessor {
  constructor(icon: IconService, log: SharedLoggingService, cd: ChangeDetectorRef) {
    super(icon, log, cd);
  }
}
