import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { OrderRequestsFacade } from '@luggagehero/guest-data-access-orders';
import { SmartImage } from '@luggagehero/shared/interfaces';
import { SharedFeatureBagImageInputComponent } from '@luggagehero/shared-feature-bag-image-input';

import { GuestDropoffStepBaseComponent } from '../guest-dropoff-step-base-component';
import { PhotoStepProperties } from './photo-step-properties';

@Component({
  selector: 'lh-photo-step',
  standalone: true,
  imports: [CommonModule, SharedFeatureBagImageInputComponent],
  templateUrl: './photo-step.component.html',
  styleUrl: './photo-step.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PhotoStepComponent extends GuestDropoffStepBaseComponent<PhotoStepProperties> {
  private isPhotoTaken = false;
  private allowSkip = false;

  private orderRequestsFacade = inject(OrderRequestsFacade);

  constructor() {
    super();

    this.addSubscription(
      this.orderRequestsFacade.orderRequest$.subscribe((o) => {
        if (!o.bags) {
          return;
        }
        this.bookingDraft.luggage = { normal: o.bags, hand: 0 };
      }),
    );
  }

  public get remoteLuggageImage(): SmartImage {
    return this.bookingDraft.remoteLuggageImage;
  }
  public set remoteLuggageImage(value: SmartImage) {
    this.bookingDraft.remoteLuggageImage = value;
  }

  public get localLuggageImage(): string {
    return this.bookingDraft.localLuggageImage;
  }
  public set localLuggageImage(value: string) {
    this.bookingDraft.localLuggageImage = value;
  }

  public get numberOfBags(): number {
    if (!this.bookingDraft.luggage) {
      return null;
    }
    return this.bookingDraft.luggage.normal;
  }

  public get isRequired(): boolean {
    return this.properties?.required ? true : false;
  }

  protected get defaultProperties(): PhotoStepProperties {
    return {
      header: 'TAKE_A_PHOTO',
      body: this.numberOfBags === 1 ? ['TAKE_A_PICTURE_OF_YOUR_LUGGAGE_TAG'] : ['TAKE_A_PICTURE_OF_YOUR_LUGGAGE_TAGS'],
      required: true,
    };
  }

  protected onInit(): Promise<void> {
    // Nothing to initialize
    return Promise.resolve();
  }

  public onLocalLuggageImageChange(value: string): void {
    if (!value) {
      return;
    }
    this.localLuggageImage = value;
  }

  public onRemoteLuggageImageChange(value: SmartImage): void {
    if (!value) {
      return;
    }
    this.remoteLuggageImage = value;
  }

  public onCompletedChange(value: boolean): void {
    this.isPhotoTaken = value;
    void this.checkCanGoForward(false);
  }

  public onAllowSkipChange(value: boolean): void {
    this.allowSkip = value;
    void this.checkCanGoForward(false);
  }

  protected onCheckCanGoForward(): boolean {
    return this.isPhotoTaken;
  }

  protected onCheckCanSkip(): boolean {
    return this.allowSkip || this.isPhotoTaken || !this.isRequired;
  }
}
