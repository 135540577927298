import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { AppConfig } from '@luggagehero/shared/app-settings/data-access';
import { Config } from '@luggagehero/shared/environment';
import { InitiatePaymentResult, IPaymentRecord, IStripeSetupIntentResult } from '@luggagehero/shared/interfaces';
import { SharedPaymentService } from '@luggagehero/shared/services/payments';
import { SharedPricingService } from '@luggagehero/shared/services/pricing';
import { SharedStorageService } from '@luggagehero/shared/services/storage';
import { SharedStripeService } from '@luggagehero/shared/services/stripe';
import { SharedUserService } from '@luggagehero/shared/services/users';
import { SharedWindowService } from '@luggagehero/shared/services/window';
import { SharedUtilString } from '@luggagehero/shared/util';
import { StripeCardElement, StripeCardElementChangeEvent } from '@stripe/stripe-js';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseComponent } from '../../../core';
@Component({ template: '' })
export class PaymentCardInputBaseComponent extends BaseComponent implements OnInit, AfterViewInit, OnDestroy {
  /**
   * @description Emits an event when the result of an attempt to add a new payment card is received
   */
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() result: EventEmitter<IStripeSetupIntentResult> = new EventEmitter<IStripeSetupIntentResult>();
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() cancel: EventEmitter<unknown> = new EventEmitter();
  @Input() isSubmitButtonVisible = true;
  @Input() allowCancel = true;
  @Input() showCardBrands = true;
  @ViewChild('cardContainer') cardContainer: ElementRef<HTMLElement>;

  cardElement: StripeCardElement;

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  cardChangeHandler: (event: StripeCardElementChangeEvent) => unknown = this.onChange.bind(this);
  cardError: string;
  showSkipCard = !Config.isProduction;

  private _payment: IPaymentRecord<InitiatePaymentResult>;
  private _bookingId: string;
  private _orderId: string;
  private _storageLocationId: string;
  private _isSimpleMode = false;
  private _isConfirmingBooking = false;
  private _isLoading = false;
  private _isPrimary = true;
  private subscriptions: Subscription[];

  constructor(
    private userService: SharedUserService,
    private paymentService: SharedPaymentService,
    private stripe: SharedStripeService,
    private storage: SharedStorageService,
    private price: SharedPricingService,
    private cd: ChangeDetectorRef,
    private windowService: SharedWindowService,
  ) {
    super();
  }

  get isLoggedIn(): boolean {
    return this.userService.isLoggedIn;
  }

  get isPaymentCardValidationVariant(): boolean {
    return this.storage.variant === AppConfig.EXPERIMENT_VARIANTS.paymentCardValidation;
  }

  get isDailyPricingVariant(): boolean {
    return this.storage.variant === AppConfig.EXPERIMENT_VARIANTS.dailyPriceNotPaidUpFront;
  }

  get isSkipLoginVariant(): boolean {
    if (this.storage.variant === AppConfig.EXPERIMENT_VARIANTS.skipLogin) {
      return this.stripe.isWalletPaymentAvailable;
    }
    return false;
  }

  get addCardCta(): Observable<string> {
    return this.price.pricingModel$.pipe(
      // NOTE: What if insurance is selected so part of the booking is paid up-front?
      map((pricingModel) => {
        if (this.isConfirmingBooking) {
          return pricingModel === 'hourly' || this.isSimpleMode || this.isDailyPricingVariant
            ? 'CONFIRM_BOOKING'
            : 'PAY_NOW';
        }
        return 'ADD_PAYMENT_CARD';
      }),
    );
  }

  get isLoading(): boolean {
    return this._isLoading;
  }
  @Input() set isLoading(value: boolean) {
    this._isLoading = value;
    this.cd.markForCheck();
  }

  get isPrimary(): boolean {
    return this._isPrimary;
  }
  @Input() set isPrimary(value: boolean) {
    this._isPrimary = value;
    this.cd.markForCheck();
  }

  get isConfirmingBooking(): boolean {
    return this._isConfirmingBooking;
  }
  @Input() set isConfirmingBooking(value: boolean) {
    this._isConfirmingBooking = value;
    this.cd.markForCheck();
  }

  @Input() set isSimpleMode(value: boolean) {
    this._isSimpleMode = value;
    this.cd.markForCheck();
  }
  get isSimpleMode(): boolean {
    return this._isSimpleMode;
  }

  get bookingId(): string {
    return this._bookingId;
  }
  @Input() set bookingId(value: string) {
    this._bookingId = value;
    this.cd.markForCheck();
  }

  get orderId(): string {
    return this._orderId;
  }
  @Input() set orderId(value: string) {
    this._orderId = value;
    this.cd.markForCheck();
  }

  get storageLocationId(): string {
    return this._storageLocationId;
  }
  @Input() set storageLocationId(value: string) {
    this._storageLocationId = value;
    this.cd.markForCheck();
  }

  get payment(): IPaymentRecord<InitiatePaymentResult> {
    return this._payment;
  }
  @Input() set payment(value: IPaymentRecord<InitiatePaymentResult>) {
    this._payment = value;
    this.cd.markForCheck();
  }

  public ngOnInit() {
    this.subscriptions = [this.userService.loginAnnounced.subscribe(() => this.cd.markForCheck())];
  }

  public async ngAfterViewInit() {
    this.cardElement = await this.stripe.createCardElement(this.cardContainer);
    this.cardElement.on('change', this.cardChangeHandler);
  }

  public ngOnDestroy() {
    this.cardElement?.destroy();
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  public onChange({ error }) {
    if (error) {
      this.cardError = typeof error === 'object' && 'message' in error && (error as { message: string }).message;
      this.isLoading = false;
    } else {
      this.cardError = null;
    }
    this.cd.detectChanges();
  }

  public async submit() {
    if (this.cardError || this.isLoading) {
      return;
    }

    this.isLoading = true;

    // Use the payment provided via binding if available
    let clientSecret = this.payment?.data.client_secret;
    const returnUrl = this.windowService.isNative ? AppConfig.STRIPE_PAYPAL_NATIVE_RETURN_URL : null;

    if (!clientSecret) {
      // Initiate a new payment via the backend
      this.payment = await this.paymentService.initiatePayment(
        'stripe',
        this.storageLocationId,
        this.orderId,
        this.bookingId,
        returnUrl,
      );
      clientSecret = this.payment.data.client_secret;
    }

    const result = await this.stripe.confirmPayment(clientSecret, this.cardElement);
    this.result.emit(result);

    if (!Config.isProduction) {
      if (result.error) {
        console.debug(SharedUtilString.formatPaymentEvent(this.payment.data, 'failed'), result);
      } else {
        console.debug(SharedUtilString.formatPaymentEvent(this.payment.data, 'confirmed'), result);
      }
    }

    this.isLoading = false;
  }

  public requestLogin() {
    // this.userService.bookingRequested = true;
    this.userService.showLoginView(true);
  }

  public skipCard() {
    const dummySetupIntentResult: IStripeSetupIntentResult = {
      setupIntent: {
        id: 'seti_foo',
        object: 'setup_intent',
        cancellation_reason: null,
        client_secret: 'seti_foo_secret_bar',
        created: 1608939449,
        description: null,
        last_setup_error: null,
        livemode: false,
        next_action: null,
        payment_method: 'pm_card_authenticationRequiredOnSetup',
        payment_method_types: ['card'],
        status: 'succeeded',
        usage: 'off_session',
      },
    };
    this.result.emit(dummySetupIntentResult);
  }

  public onCancel() {
    this.cancel.emit();
  }
}
