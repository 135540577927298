import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';

import { DistanceUnit } from './distance-unit.type';

export interface IDistanceService {
  get distanceUnit$(): Observable<DistanceUnit>;
  get distanceUnit(): DistanceUnit;
  changeDistanceUnit(value: DistanceUnit): void;
  getFromSuffix(placeName?: string): Observable<string>;
  convertDistance(meters: number, distanceUnit: DistanceUnit, includeFrom: boolean, place?: string): Observable<string>;
}

export const DISTANCE_SERVICE = new InjectionToken<IDistanceService>('CoreDistanceService');
