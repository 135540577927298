import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AppConfig } from '@luggagehero/shared/app-settings/data-access';
import { GuestMovement, StorageLocationService, StorageLocationType } from '@luggagehero/shared/interfaces';
import { Subscription } from 'rxjs';

import { DropoffStepBaseComponent } from './dropoff-step.base-component';

@Component({ template: '' })
export abstract class WelcomeStepBaseComponent extends DropoffStepBaseComponent implements OnInit, OnDestroy {
  private route = inject(ActivatedRoute);

  public showGuestOption = false;
  public isGuestOptionSelected = false;
  public isGuest = false;

  public guestMovement: GuestMovement = 'unset';

  private _isSecuritySealConfirmed = false;
  private paramsSubscription: Subscription;

  public get isSecuritySealConfirmed(): boolean {
    return this._isSecuritySealConfirmed;
  }
  public set isSecuritySealConfirmed(value: boolean) {
    this._isSecuritySealConfirmed = value;
    this.checkCanGoForward();
  }

  public get isSecuritySealStorageLocation(): boolean {
    if (this.storageLocation.noSecuritySeals) {
      // This storage location doesn't use security seals
      return false;
    }
    return true;
  }

  public get isSecuritySealInfoVisible(): boolean {
    if (this.showGuestOption && !this.isGuestOptionSelected) {
      // Wait for the user to choose a guest option before showing the security seal check
      return false;
    }
    return this.isSecuritySealStorageLocation;
  }

  public get isSecuritySealConfirmationRequired() {
    return this.isSecuritySealStorageLocation && AppConfig.IS_USER_CONFIRMATION_OF_SECURITY_TAGS_ENABLED;
  }

  public get isGuestStorageEnabled(): boolean {
    if (!this.storageLocation?.services) {
      return false;
    }
    return this.storageLocation.services.includes(StorageLocationService.GuestLuggageStorage);
  }

  public get guestOptionText(): string {
    switch (this.storageLocation.locationType) {
      case StorageLocationType.Hotel:
        return 'ARE_YOU_A_GUEST_AT_THE_HOTEL';

      case StorageLocationType.Hostel:
        return 'ARE_YOU_A_GUEST_AT_THE_HOSTEL';

      default:
        return 'ARE_YOU_A_GUEST_HERE';
    }
  }

  public get forwardCallToAction(): string {
    if (this.showGuestOption || this.isSecuritySealConfirmationRequired) {
      return 'CONTINUE';
    }
    return 'GET_STARTED';
  }

  protected get useDefaultNavigation(): boolean {
    return true;
  }

  public ngOnInit(): void {
    this.bookingDraft.addOns = {
      insurance: undefined,
      freeCancellation: undefined,
      securitySeals: undefined,
    };

    this.showGuestOption = this.isGuestStorageEnabled;
    this.checkCanGoForward();

    if (!this.showGuestOption) {
      return;
    }

    // if storage location services includes GuestLuggageStorage but
    // doesnt include SameDayStorage and MultiDayStorage then assume this is guest
    if (
      this.showGuestOption &&
      [StorageLocationService.SameDayStorage, StorageLocationService.MultiDayStorage].every(
        (service) => !this.storageLocation.services.includes(service),
      )
    ) {
      this.showGuestOption = false;
      this.setIsGuest(true);
      return;
    }

    this.paramsSubscription = this.route.params.subscribe((params) => {
      if (params.isGuest === 'true') {
        this.showGuestOption = false;
        this.setIsGuest(true);
      }

      if (params.isGuest === 'false') {
        this.showGuestOption = false;
        this.setIsGuest(false);
      }
    });
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
    this.paramsSubscription?.unsubscribe();
  }

  public goForward(): void {
    this.bookingDraft.addOns.walkIn = this.isGuest ? false : true;
    this.bookingDraft.addOns.guest = this.isGuest ? true : false;
    this.bookingDraft.addOns.guestMovement = this.guestMovement;

    super.goForward();
  }

  public setIsGuest(value: boolean) {
    this.isGuest = value;
    this.isGuestOptionSelected = true;

    this.checkCanGoForward();
  }

  public setIsArrivingGuest() {
    this.guestMovement = 'arriving';
    this.checkCanGoForward();
  }

  public setIsDepartingGuest() {
    this.guestMovement = 'departing';
    this.checkCanGoForward();
  }

  public get isArrivingGuest(): boolean {
    return this.guestMovement === 'arriving';
  }

  public get isDepartingGuest(): boolean {
    return this.guestMovement === 'departing';
  }

  private checkCanGoForward(): void {
    if (this.showGuestOption && !this.isGuestOptionSelected) {
      // Don't allow the user to go forward until they've selected a guest option
      this.canGoForward = false;
      return;
    }

    if (this.isGuest && this.guestMovement === 'unset') {
      // Don't allow the user to go forward until they've selected a guest movement
      this.canGoForward = false;
      return;
    }

    if (
      this.isSecuritySealStorageLocation &&
      this.isSecuritySealConfirmationRequired &&
      !this.isSecuritySealConfirmed
    ) {
      // Don't allow the user to go forward until they've confirmed the security seals
      this.canGoForward = false;
      return;
    }

    this.canGoForward = true;
  }
}
