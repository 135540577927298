<div class="wrapper" [class.fullscreen]="isFullScreenEnabled || isSmallHeightScreen">
  <div
    class="container-fluid lh-container-fluid-sm wizard-container"
    [class.fullscreen]="isFullScreenEnabled || isSmallHeightScreen"
    [class.height-sm]="isSmallHeightScreen"
    [class.height-md]="isMediumHeightScreen"
    [class.height-lg]="isLargeHeightScreen"
  >
    <div class="wizard-header">
      <div class="wizard-header-text">
        @if (config && config.title) {
          <div class="wizard-title">{{ config.title | lhTranslate }}</div>
        }

        @if (config && config.subtitle) {
          <div class="wizard-subtitle">{{ config.subtitle | lhTranslate }}</div>
        }
      </div>

      <div class="wizard-header-navigation">
        @if (isBackButtonVisible) {
          <lh-shared-ui-button
            type="button"
            [round]="true"
            [size]="'small'"
            [style]="'info'"
            [icon]="'back'"
            [disabled]="isBackButtonDisabled"
            (press)="goBack()"
          >
          </lh-shared-ui-button>
        }
      </div>
    </div>

    @if (config && config.progressEnabled) {
      <!-- Wizard progress -->
      <lh-shared-ui-step-progress
        [steps]="sectionSteps"
        [size]="progressSize"
        [currentStepIndex]="currentSectionIndex"
      ></lh-shared-ui-step-progress>
    }

    <div class="wizard-content">
      @if (properties) {
        <!-- Step properties -->
        <div class="step-properties">
          @if (properties.header) {
            <!-- Step header -->
            <div class="step-header">{{ properties.header | lhTranslate }}</div>
          }

          @if (properties.body && properties.body.length > 0) {
            <!-- Step body -->
            <div class="step-body">
              @for (paragraph of properties.body; track $index) {
                <p>{{ paragraph | lhTranslate }}</p>
              }
            </div>
          }

          @if (properties.image) {
            <!-- Step image -->
            <div class="step-image">
              <img [src]="properties.image" />
            </div>
          }
        </div>
      }

      <!-- The step component will be rendered here -->
      <div class="step-component" #stepContainer></div>
    </div>

    @if (!hideNavigation) {
      <div class="wizard-footer-navigation">
        @if (isForwardButtonVisible) {
          <lh-shared-ui-button
            type="button"
            [size]="'medium'"
            [block]="true"
            [style]="forwardButtonStyle"
            [icon]="forwardButtonIcon"
            [disabled]="isForwardButtonDisabled"
            (press)="goForward()"
          >
            {{ forwardButtonLabel | lhTranslate }}
          </lh-shared-ui-button>
        }
      </div>
    }
  </div>
</div>
