import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { SharedAppSettingsService } from '@luggagehero/shared/app-settings/data-access';
import {
  GUEST_INSURANCE_FEE_PRODUCT_KEY,
  GUEST_PREMIUM_INSURANCE_FEE_PRODUCT_KEY,
  ProductInfo,
} from '@luggagehero/shared/interfaces';
import {
  SelectOptionButtonGroupOption,
  SharedUiSelectOptionButtonGroupComponent,
  SharedUiSpinnerComponent,
} from '@luggagehero/shared/ui';

import { GuestDropoffStepBaseComponent } from '../guest-dropoff-step-base-component';
import { CoverageSelectionStepProperties } from './coverage-selection-step-properties';

@Component({
  selector: 'lh-coverage-selection-step',
  standalone: true,
  imports: [CommonModule, SharedUiSelectOptionButtonGroupComponent, SharedUiSpinnerComponent],
  templateUrl: './coverage-selection-step.component.html',
  styleUrl: './coverage-selection-step.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CoverageSelectionStepComponent extends GuestDropoffStepBaseComponent<CoverageSelectionStepProperties> {
  public coverageOptions: SelectOptionButtonGroupOption<ProductInfo>[] = [];

  private appSettingsService = inject(SharedAppSettingsService);

  protected get defaultProperties(): CoverageSelectionStepProperties {
    return {
      header: 'ADD_PROTECTION',
      body: ['CHOOSE_COVERAGE_LONG'],
      allowNoCoverage: true,
      defaultCoverage: GUEST_INSURANCE_FEE_PRODUCT_KEY,
      createOrder: true,
    };
  }

  protected async onInit(): Promise<void> {
    if (!this.luggage) {
      this.luggage = { normal: 1, hand: 0 };
    }

    if (!this.dailyOrder || !this.hourlyOrder) {
      // TODO: Handle this differently
      await this.updateOrders();
    }

    this.updateOptions();
  }

  private updateOptions(): void {
    const order = this.pricingModel === 'hourly' ? this.hourlyOrder : this.dailyOrder;

    const basicCoverageProduct = order.productList.products?.find((p) => p.key === GUEST_INSURANCE_FEE_PRODUCT_KEY);
    const premiumCoverageProduct = order.productList.products?.find(
      (p) => p.key === GUEST_PREMIUM_INSURANCE_FEE_PRODUCT_KEY,
    );

    const basicCoverageCost = this.pricingService.format(basicCoverageProduct?.cost, this.dailyOrder.currency);
    const basicCoverageValue = this.pricingService.format(basicCoverageProduct?.value, this.dailyOrder.currency);
    const premiumCoverageCost = this.pricingService.format(premiumCoverageProduct?.cost, this.dailyOrder.currency);
    const premiumCoverageValue = this.pricingService.format(premiumCoverageProduct?.value, this.dailyOrder.currency);

    const bag = this.translate('BAG').toLowerCase();
    const coverage = this.translate('COVERAGE_UP_TO_X_AMOUNT');

    this.coverageOptions = [
      {
        value: basicCoverageProduct,
        primaryContent: {
          title: 'Basic',
          // paragraphs: ['Covers day-to-day items such as clothes, shoes, and smaller electronics'],
          paragraphs: [`${coverage} ${basicCoverageValue}/${bag}`],
        },
        secondaryContent: {
          title: `${basicCoverageCost}/${bag}`,
          // paragraphs: [`${coverage} ${basicCoverageValue}/${bag}`],
        },
      },
    ];

    if (this.appSettingsService.current?.premiumInsuranceEnabled && premiumCoverageProduct) {
      this.coverageOptions.push({
        value: premiumCoverageProduct,
        primaryContent: {
          title: 'Premium',
          // paragraphs: ['If you wish to cover larger electronics such as laptops, jewelry, or other valuables'],
          paragraphs: [`${coverage} ${premiumCoverageValue}/${bag}`],
        },
        secondaryContent: {
          title: `${premiumCoverageCost}/${bag}`,
          // paragraphs: [`${coverage} ${premiumCoverageValue}/${bag}`],
        },
      });
    }

    if (this.properties.allowNoCoverage) {
      this.coverageOptions.push({
        value: null,
        primaryContent: {
          title: 'No coverage',
        },
      });
    }

    this.setDefaultCoverage();
  }

  private setDefaultCoverage(): void {
    // Check if coverage is already set or no default is provided
    if (this.coverage !== undefined || this.properties.defaultCoverage === undefined) {
      this.cd.markForCheck(); // Just make sure the view is updated
      return;
    }

    // Check if default coverage is set to no coverage and if no coverage is allowed
    if (this.properties.defaultCoverage === null && this.properties.allowNoCoverage) {
      this.coverage = null; // Select no coverage
      return;
    }

    // Select the default coverage provided if a matching option is found
    this.coverage = this.coverageOptions.find((o) => o.value?.key === this.properties.defaultCoverage)?.value;
  }

  protected onCheckCanGoForward(): boolean {
    if (this.coverage === undefined) {
      // Don't allow the user to go forward until they've selected a pricing model
      return false;
    }
    return true;
  }
}
