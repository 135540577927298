import { ChangeDetectorRef, Component, Input } from '@angular/core';

import { BaseComponent } from '../../../core';

@Component({ template: '' })
export abstract class ProgressBarBaseComponent extends BaseComponent {
  private _compact = false;
  private _currentValue = 0;
  private _maxValue = 100;

  constructor(private cd: ChangeDetectorRef) {
    super();
  }

  get compact(): boolean {
    return this._compact;
  }
  @Input() set compact(value: boolean) {
    this._compact = value;
    this.cd.markForCheck();
  }

  get currentValue(): number {
    return this._currentValue;
  }
  @Input() set currentValue(value: number) {
    this._currentValue = value;
    this.cd.markForCheck();
  }

  get maxValue(): number {
    return this._maxValue;
  }
  @Input() set maxValue(value: number) {
    this._maxValue = value;
    this.cd.markForCheck();
  }

  get progress(): number {
    return Math.round((this.currentValue / this.maxValue) * 100);
  }
}
