import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { RatingModule } from 'ngx-bootstrap/rating';

@Component({
  selector: 'lh-traveler-shops-ui-aggregate-rating',
  standalone: true,
  imports: [CommonModule, RatingModule, FormsModule, TranslateModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './traveler-shops-ui-aggregate-rating.component.html',
  styleUrls: ['./traveler-shops-ui-aggregate-rating.component.scss'],
})
export class TravelerShopsUiAggregateRatingComponent {
  @Input() showShopRating = true;
  @Input() averageRating = 4.5;
  @Input() numberOfRatings = 1;
  @Input() shopStatsNumberOfRatings?: number = 1;
  @Input() globalNumberOfRatings?: number = 1;
  @Input() numberOf5StarRatings = 1;
  @Input() shopLoaded = false;
  @Input() hasShopStats = false;
  @Output() showReviews = new EventEmitter<void>();
}
