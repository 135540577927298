<!-- Circle with colored background and first letter of name -->
<div
  class="circle"
  [class.circle-sm]="size === 'sm'"
  [class.circle-md]="size === 'md'"
  [class.circle-lg]="size === 'lg'"
  [ngStyle]="{ background: color }"
  *ngIf="name && name.length > 0"
>
  {{ name[0] | uppercase }}
</div>
