import { NgModule } from '@angular/core';
import {
  AddressPipe,
  BookingPipe,
  DiscountPipe,
  DistancePipe,
  MapsLinkPipe,
  MomentPipe,
  PaymentMethodPipe,
  PhoneNumberPipe,
  PricePipe,
  PricingModelPipe,
  PricingPipe,
  SafeUrlPipe,
  ShopCriteriaPipe,
  TimeIntervalPipe,
  TimePeriodPipe,
  TranslatePipe,
  UppercaseFirstLetterPipe,
  ZeroPaddedNumberPipe,
} from '@luggagehero/shared/ui-pipes';
import { TranslateModule } from '@ngx-translate/core';

const MODULES = [
  TranslateModule,
  DistancePipe,
  TranslatePipe,
  PaymentMethodPipe,
  PricingPipe,
  UppercaseFirstLetterPipe,
  PhoneNumberPipe,
  MomentPipe,
  BookingPipe,
  DiscountPipe,
  MapsLinkPipe,
  TimePeriodPipe,
  ShopCriteriaPipe,
  SafeUrlPipe,
  AddressPipe,
  TimeIntervalPipe,
  ZeroPaddedNumberPipe,
  PricePipe,
  PricingModelPipe,
];

@NgModule({
  imports: [...MODULES],
  exports: [...MODULES],
})
export class UiSharedModule {}
