<lh-shared-ui-spinner [visible]="isLoading"></lh-shared-ui-spinner>

<form role="form" (submit)="registerNewUser()" #registerForm="ngForm">
  <div class="form-group">
    <input
      id="email"
      type="email"
      autocomplete="email username"
      class="form-control"
      #email="ngModel"
      name="email"
      [(ngModel)]="user.email"
      placeholder="{{ 'EMAIL_ADDRESS' | translate }}"
      required
    />
  </div>
  <div class="form-group">
    <input
      id="password"
      type="password"
      autocomplete="new-password"
      class="form-control"
      name="password"
      #password="ngModel"
      [(ngModel)]="user.password"
      placeholder="{{ 'CHOOSE_PASSWORD' | translate }}"
      required
    />
  </div>
  <div class="form-group">
    <input
      id="name"
      type="text"
      autocomplete="name"
      class="form-control"
      name="name"
      #name="ngModel"
      [(ngModel)]="user.name"
      placeholder="{{ 'FULL_NAME' | translate }}"
      required
    />
  </div>
  <div class="form-group">
    <input
      id="phone"
      type="text"
      autocomplete="tel"
      class="form-control"
      name="phone"
      #phone="ngModel"
      [(ngModel)]="user.phone"
      placeholder="{{ 'PHONE_NUMBER' | translate }}"
      required
    />
  </div>

  <button
    id="registerNewUser"
    type="submit"
    class="btn btn-block btn-primary"
    [disabled]="!registerForm.form.valid || isLoading"
  >
    {{ 'REGISTER' | translate }}
  </button>
</form>
