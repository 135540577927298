import { Action, createReducer, on } from '@ngrx/store';
import { merge } from 'lodash-es';

import * as OrderRequestsActions from './order-requests.actions';
import { OrderRequestsState } from './order-requests.models';

export const initialOrderRequestsState: OrderRequestsState = {
  orderRequest: {},
};

const reducer = createReducer(
  initialOrderRequestsState,
  on(OrderRequestsActions.updateOrderRequest, (state, { updates }) => ({
    ...state,
    orderRequest: merge({}, state.orderRequest, updates),
  })),
);

export function orderRequestsReducer(state: OrderRequestsState | undefined, action: Action) {
  return reducer(state, action);
}
