@if (prompt) {
  <div class="prompt">{{ prompt }}</div>
}

@if (advancedMode) {
  <div class="advanced-options">
    @for (option of options; track option) {
      <button class="btn btn-info" [class.selected]="selected === option.value" (click)="selectOption(option.value)">
        <div class="primary-wrapper">
          <div class="primary-content">
            <div class="primary-title">{{ option.primaryContent.title | lhTranslate }}</div>

            @for (paragraph of option.primaryContent.paragraphs || []; track $index) {
              <div class="primary-paragraph">{{ paragraph | lhTranslate }}</div>
            }
          </div>

          <div class="primary-checkbox">
            <lh-shared-ui-toggle-button [enabled]="selected === option.value"></lh-shared-ui-toggle-button>
          </div>
        </div>

        @if (option.secondaryContent) {
          <div class="secondary-wrapper">
            <div class="secondary-content">
              <div class="secondary-title">{{ option.secondaryContent.title | lhTranslate }}</div>

              @for (paragraph of option.secondaryContent.paragraphs || []; track $index) {
                <div class="secondary-paragraph">{{ paragraph | lhTranslate }}</div>
              }
            </div>
          </div>
        }
      </button>
    }
  </div>
} @else {
  <div class="simple-options">
    @for (option of options; track option.value) {
      <lh-shared-ui-button
        type="button"
        [size]="'small'"
        [style]="'info'"
        [block]="true"
        [shadow]="false"
        [selected]="selected === option.value"
        (press)="selectOption(option.value)"
      >
        {{ option.primaryContent.title | lhTranslate }}
      </lh-shared-ui-button>
    }
  </div>
}
