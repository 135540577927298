<div class="bag-image-container">
  @if (!isTakingPhoto && !isLoading) {
    <div class="status-icon">
      @if (completed) {
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="bg-success"
        >
          <polyline points="20 6 9 17 4 12"></polyline>
        </svg>
      } @else if (photoValidationFailed) {
        <!-- Error svg -->
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="bg-danger"
        >
          <!-- <circle cx="12" cy="12" r="10"></circle> -->
          <line x1="12" y1="8" x2="12" y2="12"></line>
          <line x1="12" y1="16" x2="12" y2="16"></line>
        </svg>
      }
    </div>
  }
  <div class="security-seals">
    <div class="security-seal-list" *ngIf="photoValidationSucceeded && securitySeals?.length > 0">
      <lh-shared-ui-tag-list
        [noWrap]="isTouch"
        [size]="'small'"
        [securitySeals]="securitySeals"
        [maxTagsCollapsed]="appConfig.MAX_ITEMS_IN_COLLAPSED_TAG_LIST"
      ></lh-shared-ui-tag-list>
    </div>

    <div *ngIf="photoValidationFailed" class="alert alert-warning">
      {{ 'COULDNT_DETECT_SEALS' | lhTranslate }}
    </div>
  </div>

  <div class="bag-image" [class.no-image]="!localLuggageImage && !remoteLuggageImage">
    <lh-shared-ui-image-input
      [(isTakingPhoto)]="isTakingPhoto"
      [promoteRetake]="photoValidationFailed"
      [isLoading]="isLoading"
      [loadingMessage]="imageLoadingMessage"
      [callToAction]="''"
      [remoteImage]="remoteLuggageImage?.name"
      [localImage]="localLuggageImage"
      (localImageChange)="onLocalImageChange($event)"
      (mediaAccessChange)="onMediaAccessChange($event)"
      [isEmbeddedCameraEnabled]="appConfig.IS_EMBEDDED_CAMERA_ENABLED"
      [isCameraSwitchingEnabled]="appConfig.IS_CAMERA_SWITCHING_ENABLED"
      [isCameraLoaderEnabled]="appConfig.IS_CAMERA_LOADER_ENABLED"
      [isImageCompressionEnabled]="appConfig.IS_IMAGE_COMPRESSION_ENABLED"
      [imageCompressionQuality]="appConfig.IMAGE_COMPRESSION_QUALITY"
      [imageCompressionRatio]="appConfig.IMAGE_COMPRESSION_RATIO"
      [imageCompressionMaxWidth]="appConfig.IMAGE_COMPRESSION_MAX_WIDTH"
      [imageCompressionMaxHeight]="appConfig.IMAGE_COMPRESSION_MAX_HEIGHT"
      [baseUrl]="baseUrl"
    ></lh-shared-ui-image-input>

    <div class="icon-container" *ngIf="numberOfBags && !photoValidationFailed && !isTakingPhoto && !isLoading">
      <div class="luggage-icon" [class.no-image]="!localLuggageImage && !remoteLuggageImage">
        <i class="icon-bag"></i>{{ numberOfBags }}
      </div>
      <!-- FIXME: The hover effect is not working properly on touch devices -->
      <!-- <div class="luggage-icon hidden-hover" [class.no-image]="!localLuggageImage && !remoteLuggageImage">
        <i class="icon-bag"></i>{{ numberOfBags }}
      </div>
      <div class="luggage-icon visible-hover" [class.no-image]="!localLuggageImage && !remoteLuggageImage">
        <i class="icon-zoom-in"></i>
      </div> -->
    </div>
  </div>
</div>
