import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  inject,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { IShopReviewsComponent, ReviewStats, StorageCriteria } from '@luggagehero/shared/interfaces';
import { SharedDistanceService } from '@luggagehero/shared/services/distance';
import { SharedReviewsService } from '@luggagehero/shared/services/reviews';
import { SharedShopsService } from '@luggagehero/shared/services/shops';
import { SharedStorageCriteriaService } from '@luggagehero/shared/services/storage-criteria';
import { SharedTranslateService } from '@luggagehero/shared/services/translation';
import { SharedUiButtonComponent, SharedUITooltipComponent } from '@luggagehero/shared/ui';
import { PricePipe, TranslatePipe } from '@luggagehero/shared/ui-pipes';
import {
  TravelerShopsUiAggregateRatingComponent,
  TravelerShopsUiBestChoiceDisplayComponent,
  TravelerShopsUiPOIListDetailsComponent,
  TravelerShopsUiPricingDetailsComponent,
  TravelerShopsUiShopCardComponent,
} from '@luggagehero/traveler/shops/ui';
import { TravelerShopsFeatureOpeningHoursComponent } from '@luggagehero/traveler-shops-feature-opening-hours';
import { TravelerShopsFeatureShopReviewsComponent } from '@luggagehero/traveler-shops-feature-reviews';
import { ShopDisplayModel } from '@luggagehero/traveler-shops-models';
import { TranslateModule } from '@ngx-translate/core';
import { forkJoin, Observable, of, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'lh-traveler-shops-feature-storage-location-details-sheet',
  standalone: true,
  imports: [
    CommonModule,
    TravelerShopsUiShopCardComponent,
    TravelerShopsFeatureShopReviewsComponent,
    TravelerShopsUiAggregateRatingComponent,
    TranslatePipe,
    TranslateModule,
    TravelerShopsUiPricingDetailsComponent,
    SharedUiButtonComponent,
    TravelerShopsUiPOIListDetailsComponent,
    TravelerShopsFeatureOpeningHoursComponent,
    PricePipe,
    SharedUITooltipComponent,
    TravelerShopsUiBestChoiceDisplayComponent,
  ],
  templateUrl: './traveler-shops-feature-storage-location-details-sheet.component.html',
  styleUrl: './traveler-shops-feature-storage-location-details-sheet.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TravelerShopsFeatureStorageLocationDetailsSheetComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('shopReviews') public shopReviews?: IShopReviewsComponent;
  @ViewChild('reviewsElement') public reviewsElement?: ElementRef<HTMLElement>;
  @ViewChild('shopCard', { read: ElementRef }) public shopCard?: ElementRef<HTMLElement>;
  @ViewChild('bookNowButton', { read: ElementRef }) public bookNowButton?: ElementRef<HTMLElement>;

  private readonly cd = inject(ChangeDetectorRef);
  private readonly shopsService = inject(SharedShopsService);
  private readonly reviewsService = inject(SharedReviewsService);
  private readonly distanceService = inject(SharedDistanceService);
  private readonly criteriaService = inject(SharedStorageCriteriaService);
  private readonly translate = inject(SharedTranslateService);

  private observer?: IntersectionObserver;
  private subscriptions: Subscription[] = [];

  get criteria(): StorageCriteria {
    return this.criteriaService.current;
  }

  @Input() shop!: ShopDisplayModel;
  @Input() onCardClicked?: () => void;
  @Input() onBookNow?: () => void;

  globalStats: ReviewStats | undefined;

  private _bookNowButtonVisible = true;
  get bookNowButtonVisible() {
    return this._bookNowButtonVisible;
  }
  set bookNowButtonVisible(value: boolean) {
    this._bookNowButtonVisible = value;
    this.cd.detectChanges();
  }

  public get priceInfoTooltipHtml(): string {
    const lines: string[] = [
      `<ul>`,
      `<li>${this.translate.instant('CHOICE_OF_PRICING_MODEL')}</li>`,
      `<li>${this.translate.instant('PRICES_APPLY_TO_ADVANCE_BOOKINGS')}</li>`,
      `<li>${this.translate.instant('VOLUME_DISCOUNTS_FOR_ONLINE_BOOKINGS')}</li>`,
      `</ul>`,
    ];
    return lines.join('\n');
  }

  ngAfterViewInit() {
    if (this.bookNowButton) {
      this.observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            this.bookNowButtonVisible = entry.isIntersecting;
          });
        },
        { root: null, threshold: 0 },
      );

      this.observer.observe(this.bookNowButton.nativeElement);
    }
  }

  async ngOnInit(): Promise<void> {
    this.globalStats = await this.reviewsService.getStats();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  get numberOf5StarRatings(): number {
    return (this.shopReviews && this.shopReviews.perfectRatingsCount) || 0;
  }

  public showReviews() {
    this.reviewsElement?.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }

  get numberOfRatings(): number {
    return this.shopsService.getNumberOfRatings(this.shop.shopItem);
  }

  get showShopRating(): boolean {
    if (!this.shop || !this.shop.shopItem.stats) {
      return false;
    }
    return (this.shop.shopItem?.stats?.averageRating ?? 0) >= 1;
  }

  private globalAverageRating = 4.7;

  get averageRating(): number {
    return this.shop.shopItem?.stats?.averageRating || this.globalAverageRating;
  }

  get poiList() {
    if (!this.shop.shopItem.poiList) {
      return [];
    }
    return this.shop.shopItem.poiList.slice(0, 3).map((poi) => {
      // HACK: Remove POI_ prefix which is added to some POI names
      poi.poiName = poi.poiName.replace('POI_', '');

      return poi;
    });
  }

  isMultidayStorageAllowed(): boolean {
    return this.shopsService.isMultidayStorageAllowed(this.shop.shopItem);
  }

  get poiListHtml(): Observable<string[]> {
    const htmlObservables = this.poiList.map((poi) =>
      this.distanceService
        .convertDistance(poi.distance, 'time', false)
        .pipe(map((distance) => `<span class="text-warning">${distance}</span> ➝ ${poi.poiName}`)),
    );

    // If there are no POIs, return an Observable of an empty array.
    if (htmlObservables.length === 0) {
      return of([]);
    }

    // forkJoin waits for all the inner Observables to complete
    // and then emits an array of their last emitted values.
    return forkJoin(htmlObservables);
  }
}
