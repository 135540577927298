import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { AppConfig } from '@luggagehero/shared/app-settings/data-access';
import { Config } from '@luggagehero/shared/environment';
import {
  BookableStorageLocation,
  Booking,
  BookingConfig,
  PRICING_SERVICE,
  Review,
} from '@luggagehero/shared/interfaces';
import { SharedBookingService } from '@luggagehero/shared/services/bookings';
import { SharedLoggingService } from '@luggagehero/shared/services/logging';
import { SharedMomentService } from '@luggagehero/shared/services/moment';
import { SharedWindowService } from '@luggagehero/shared/services/window';
import { TranslateService } from '@ngx-translate/core';
import { filter, Subscription } from 'rxjs';

import { BaseComponent } from '../../../../core';
import { BookingActionInfo } from './booking-actions';
import { BookingStep } from './booking-steps';
import { BookingStrategyFactory } from './booking-strategies/booking-strategy-factory';
import { BookingTimelineStrategy } from './booking-strategies/booking-timeline/booking-timeline-strategy';

@Component({ template: '' })
export abstract class BookingTimelineBaseComponent extends BaseComponent implements OnInit, AfterViewInit, OnDestroy {
  protected momentService = inject(SharedMomentService);
  protected pricingService = inject(PRICING_SERVICE);
  @Output() selectAction = new EventEmitter<BookingActionInfo>();
  @Output() skipAction = new EventEmitter<BookingActionInfo>();
  @Output() strategyChange = new EventEmitter<BookingTimelineStrategy>();

  isCheckInSession = false;

  private _bookingSteps: BookingStep[];
  private _booking: Booking;
  private _review: Review;
  private _shop: BookableStorageLocation;
  private _strategy: BookingTimelineStrategy;
  private _config = AppConfig.DEFAULT_BOOKING_CONFIG;
  private bookingEventSubscription: Subscription;

  public constructor(
    public bookingService: SharedBookingService,
    public translate: TranslateService,
    protected windowService: SharedWindowService,
    private elementRef: ElementRef<HTMLDivElement>,
    private log: SharedLoggingService,
    private cd: ChangeDetectorRef,
  ) {
    super();
  }

  get bookingSteps(): BookingStep[] {
    return this._bookingSteps;
  }
  set bookingSteps(value: BookingStep[]) {
    this._bookingSteps = value;
    this.refresh();
  }

  get lastStep(): BookingStep {
    if (!this.bookingSteps || this.bookingSteps.length === 0) {
      return null;
    }
    return this.bookingSteps[this.bookingSteps.length - 1];
  }

  get isCompleted(): boolean {
    if (this.booking.status === 'CANCELLED') {
      return false;
    }
    return this.lastStep && this.lastStep.isCompleted;
  }

  get booking(): Booking {
    return this._booking;
  }
  @Input() set booking(value: Booking) {
    this._booking = value;
    this.configure();
    this.refresh();
  }

  get config(): BookingConfig {
    return this._config;
  }

  get strategy(): BookingTimelineStrategy {
    return this._strategy;
  }

  get review(): Review {
    return this._review;
  }
  @Input() set review(value: Review) {
    this._review = value;
    this.refresh();
  }

  get shop(): BookableStorageLocation {
    return this._shop;
  }
  @Input() set shop(value: BookableStorageLocation) {
    this._shop = value;
    this.refresh();
  }

  ngOnInit() {
    this.bookingSteps = this.buildTimeline();

    this.bookingEventSubscription = this.bookingService.bookingEvent$
      .pipe(filter((e) => e?.eventType === 'bagsDroppedOff'))
      .subscribe((_e) => {
        this.isCheckInSession = true;
        this.cd.markForCheck();
      });
  }

  ngAfterViewInit() {
    // Scroll down to active step on the timeline after short delay
    setTimeout(() => this.scrollToCurrentStep(), 1000);
  }

  ngOnDestroy() {
    super.ngOnDestroy();

    try {
      this.bookingEventSubscription.unsubscribe();
    } catch {
      // Ignore
    }
  }

  abstract buildTimeline(): BookingStep[];

  refresh() {
    if (!this.booking) {
      return;
    }
    if (this.bookingSteps) {
      this.bookingSteps.forEach((step) => step.refresh());
    }
    this.cd.markForCheck();
  }

  configure() {
    if (!this.booking) {
      return;
    }
    if (this.booking.config) {
      this._config = this.booking.config; // Override default config
    }
    // HACK: This fixes some bookings that have invalid strategy IDs in the database
    if (!this.booking.strategy || this.booking.strategy.length <= 3) {
      const variant = this.bookingService.getVariant(this.booking);
      this.booking.strategy = BookingStrategyFactory.pickStrategy(variant);
    }
    this._strategy = BookingStrategyFactory.createTimelineStrategy(this.booking);
    this.strategyChange.emit(this.strategy);
  }

  logStep(step: BookingStep) {
    if (Config.isProduction) {
      return;
    }
    void this.log.debug(step);
  }

  scrollToCurrentStep() {
    if (this.booking.status === 'CONFIRMED') {
      // Don't scroll timeline until there is progress in the booking
      return;
    }

    try {
      const completedSteps = this.elementRef.nativeElement.getElementsByClassName('booking-step is-completed');
      const lastCompletedStep = completedSteps[completedSteps.length - 1];

      if (lastCompletedStep) {
        lastCompletedStep.scrollIntoView({ behavior: 'smooth' });
      }
    } catch {
      // Ignore
    }
  }
}
