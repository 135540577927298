import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, Type } from '@angular/core';
import { AppConfig } from '@luggagehero/shared/app-settings/data-access';
import { BookableStorageLocation, Booking, BookingConfig } from '@luggagehero/shared/interfaces';
import { SharedShopsService } from '@luggagehero/shared/services/shops';
import { TranslateService } from '@ngx-translate/core';

import { BaseComponent } from '../../../../core';
import {
  BookingActionInfo,
  CancelBookingActionBaseComponent,
  GoToShopActionBaseComponent,
  ModifyBookingActionBaseComponent,
  ReportIncidentActionBaseComponent,
  SelectPaymentMethodActionBaseComponent,
  ShowReceiptActionBaseComponent,
} from './booking-actions';

@Component({ template: '' })
export abstract class BookingOptionsBaseComponent extends BaseComponent implements OnInit {
  @Output() selectAction: EventEmitter<BookingActionInfo> = new EventEmitter<BookingActionInfo>();
  actions: BookingActionInfo[] = [];

  private _booking: Booking;
  private _storageLocation: BookableStorageLocation;
  private _config = AppConfig.DEFAULT_BOOKING_CONFIG;

  public constructor(
    private storageLocationService: SharedShopsService,
    private translate: TranslateService,
    private cd: ChangeDetectorRef,
  ) {
    super();
  }

  get booking(): Booking {
    return this._booking;
  }
  @Input() set booking(value: Booking) {
    this._booking = value;
    this.configure();
    this.refresh();
  }

  get storageLocation(): BookableStorageLocation {
    return this._storageLocation;
  }
  @Input() set storageLocation(value: BookableStorageLocation) {
    this._storageLocation = value;
    this.cd.markForCheck();
  }

  get config(): BookingConfig {
    return this._config;
  }

  get showReceiptActionInfo(): BookingActionInfo {
    const titleKey = this.booking.paidDirectly ? 'PAY_IN_SHOP' : 'PAYMENT_SUCCEEDED';
    const subtitleKey = this.booking.paidDirectly
      ? 'SHOW_THE_RECEIPT_AND_PAY_IN_SHOP'
      : 'SHOW_THE_RECEIPT_TO_THE_STAFF';
    return new BookingActionInfo('ShowReceiptActionFromBookingOptions')
      .withCallToAction(this.translate.instant('VIEW_RECEIPT') as string)
      .withAction({ componentToShow: this.showReceiptActionComponentType })
      .withTitle(this.translate.instant(titleKey) as string)
      .withSubtitle(this.translate.instant(subtitleKey) as string)
      .withState(this.booking.paidDirectly ? 'warning' : 'success');
  }

  //
  // We only know the base action components here, so force the derived class
  // to provide the specific types
  //
  protected abstract get goToShopActionComponentType(): Type<GoToShopActionBaseComponent>;
  protected abstract get selectPaymentMethodActionComponentType(): Type<SelectPaymentMethodActionBaseComponent>;
  protected abstract get cancelBookingActionComponentType(): Type<CancelBookingActionBaseComponent>;
  protected abstract get modifyBookingActionComponentType(): Type<ModifyBookingActionBaseComponent>;
  protected abstract get showReceiptActionComponentType(): Type<ShowReceiptActionBaseComponent>;
  protected abstract get reportIncidentActionComponentType(): Type<ReportIncidentActionBaseComponent>;

  protected abstract showHowItWorks(): Promise<void>;

  ngOnInit() {
    this.refresh();
  }

  refresh() {
    if (!this.booking) {
      return;
    }
    this.actions = [];
    if (['CONFIRMED', 'CHECKED_IN', 'CHECKED_OUT'].includes(this.booking.status)) {
      this.actions.push(
        new BookingActionInfo('ShowPaymentOptionsFromBookingOptions')
          .withCallToAction(this.translate.instant('PAYMENT_OPTIONS') as string)
          .withAction({ componentToShow: this.selectPaymentMethodActionComponentType })
          .withTitle(this.translate.instant('PAYMENT_OPTIONS') as string),
      );
    }
    if (['CONFIRMED', 'CHECKED_IN'].includes(this.booking.status)) {
      this.actions.push(
        new BookingActionInfo('ModifyBookingFromBookingOptions')
          .withCallToAction(this.translate.instant('MODIFY_BOOKING_CTA') as string)
          .withAction({ componentToShow: this.modifyBookingActionComponentType })
          .withTitle(this.translate.instant('MODIFY_BOOKING_CTA') as string),
      );
    }
    if (this.booking.status === 'CHECKED_IN' || this.storageLocationService.isOpenNow(this.storageLocation)) {
      this.actions.push(
        new BookingActionInfo('ReportIncidentFromBookingOptions')
          .withCallToAction(this.translate.instant('REPORT_INCIDENT') as string)
          .withAction({ componentToShow: this.reportIncidentActionComponentType })
          .withTitle(this.translate.instant('REPORT_INCIDENT') as string),
      );
    }

    if (this.booking.status === 'CONFIRMED' && !this.booking.chargedNoShow) {
      this.actions.push(
        new BookingActionInfo('CancelBookingFromBookingOptions')
          .withCallToAction(this.translate.instant('CANCEL_BOOKING') as string)
          .withAction({ componentToShow: this.cancelBookingActionComponentType })
          .withTitle(
            this.translate.instant(
              this.booking.price.addOns.freeCancellation ? 'CANCEL_BOOKING_CONFIRMATION' : 'CANCEL_BOOKING',
            ) as string,
          ),
      );
    }

    if (this.booking.status === 'PAID') {
      this.actions.push(this.showReceiptActionInfo);
    }
    this.cd.markForCheck();
  }

  configure() {
    if (this.booking && this.booking.config) {
      this._config = this.booking.config; // Override default config
    }
  }
}
