import { ChangeDetectorRef, Component, inject, Input } from '@angular/core';

import { BaseComponent } from '../../../core';

@Component({ template: '' })
export abstract class PlatformLogoBaseComponent extends BaseComponent {
  private cd = inject(ChangeDetectorRef);

  private _iconOnly: boolean;
  private _lightBackground = false;

  public get iconOnly(): boolean {
    return this._iconOnly;
  }
  @Input() public set iconOnly(value: boolean) {
    this._iconOnly = value;
    this.cd.markForCheck();
  }

  public get lightBackground(): boolean {
    return this._lightBackground;
  }
  @Input() public set lightBackground(value: boolean) {
    this._lightBackground = value;
    this.cd.markForCheck();
  }
}
