import { InjectionToken } from '@angular/core';

import { SharedGeocodingService } from './shared-geocoding.service';
import { SharedGeocodingServiceConfig } from './shared-geocoding-service-config.interface';

export const SHARED_GEOCODING_SERVICE_TOKEN = new InjectionToken<SharedGeocodingService>(
  'SHARED_GEOCODING_SERVICE_TOKEN',
);
export const SHARED_GEOCODING_SERVICE_CONFIG_TOKEN = new InjectionToken<SharedGeocodingServiceConfig>(
  'SHARED_GEOCODING_SERVICE_CONFIG_TOKEN',
);
