import { inject, Injectable } from '@angular/core';
import {
  AvailabilityContext,
  BookableStorageLocation,
  DistanceUnit,
  Optional,
  RecommendedTag,
  StorageCriteria,
  StorageLocationItemBadge,
} from '@luggagehero/shared/interfaces';
import { SharedDistanceService } from '@luggagehero/shared/services/distance';
import { SharedPricingService } from '@luggagehero/shared/services/pricing';
import { SharedShopsService } from '@luggagehero/shared/services/shops';
import { SharedStorageAvailabilityService } from '@luggagehero/shared/services/storage-availability';
import { SharedTranslateService } from '@luggagehero/shared/services/translation';
import { PricingContext, ShopDisplayModel } from '@luggagehero/traveler-shops-models';
import { forkJoin, map, Observable, of } from 'rxjs';

export const TRAVELER_SHOPS_MAX_POI_DISTANCE = 500;

@Injectable({ providedIn: 'root' })
export class TravelerShopsService {
  private readonly translateService = inject(SharedTranslateService);
  private readonly distanceService = inject(SharedDistanceService);
  private readonly pricingService = inject(SharedPricingService);
  private readonly shopsService = inject(SharedShopsService);
  private readonly storageAvailabilityService = inject(SharedStorageAvailabilityService);

  getShops(): Observable<BookableStorageLocation[]> {
    return of([] as BookableStorageLocation[]);
  }

  private getShopDistance(shop: BookableStorageLocation, distanceUnit: DistanceUnit): Observable<string> {
    return this.distanceService.convertDistance(shop.distance, distanceUnit, false);
  }

  private getPricingDaily(shop: BookableStorageLocation): PricingContext | null {
    if (shop.pricing?.dailyRate == null) return null;
    return {
      price: shop.pricing.dailyRate,
      currency: this.pricingService.getCurrencyPrefix(shop.pricing.currency),
      unit: this.translateService.instant('DAY'),
    };
  }

  private getPricingHourly(shop: BookableStorageLocation): PricingContext | null {
    if (shop.pricing?.hourlyRate == null) return null;
    return {
      price: shop.pricing.hourlyRate,
      currency: this.pricingService.getCurrencyPrefix(shop.pricing.currency),
      unit: this.translateService.instant('HOUR'),
    };
  }

  private getAvailability(shop: BookableStorageLocation): AvailabilityContext {
    return this.storageAvailabilityService.getAvailability(shop);
  }

  private getBadges(shop: BookableStorageLocation): StorageLocationItemBadge[] {
    return this.shopsService.getBadgeList(shop);
  }

  private getShopName(shop: BookableStorageLocation): string {
    const nearestPoi = shop.poiList?.[0];
    const distance = Math.round(nearestPoi?.distance || -1);

    if (distance >= 0 && distance <= TRAVELER_SHOPS_MAX_POI_DISTANCE) {
      return nearestPoi?.poiName ?? this.translateService.instant('LUGGAGE_STORAGE');
    }

    return shop.address.city ?? this.translateService.instant('LUGGAGE_STORAGE');
  }

  convertToDisplayModel(
    shop: BookableStorageLocation,
    distanceUnit: DistanceUnit,
    criteria?: Optional<StorageCriteria>,
  ): Observable<ShopDisplayModel> {
    return this.getShopDistance(shop, distanceUnit).pipe(
      map((shopDistance) => {
        const criteriaStartDate = criteria?.period?.from;

        return {
          id: shop._id,
          shopItem: shop,
          title: this.getShopName(shop),
          shopRating: shop.stats,
          distance: shopDistance,
          pricingDaily: this.getPricingDaily(shop),
          pricingHourly: this.getPricingHourly(shop),
          availability: this.getAvailability(shop),
          badges: this.getBadges(shop),
          holidayNoticeHtml: criteriaStartDate
            ? this.shopsService.getHolidayNoticeHtml(criteriaStartDate, shop)
            : undefined,
        };
      }),
    );
  }

  convertToDisplayModels(
    shops: BookableStorageLocation[],
    distanceUnit: DistanceUnit,
    criteria?: Optional<StorageCriteria>,
  ): Observable<ShopDisplayModel[]> {
    return forkJoin(
      shops.map((shop, i) =>
        this.convertToDisplayModel(shop, distanceUnit, criteria).pipe(
          map((displayModel) => ({
            ...displayModel,
            recommendedTag:
              i === 0 ? ((shop.distance < 500 ? 'BEST_CHOICE' : 'MOST_POPULAR') as RecommendedTag) : undefined,
          })),
        ),
      ),
    );
  }
}
