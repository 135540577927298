import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';

export interface ITranslateService {
  /**
   * An observable that emits when the language changes
   */
  readonly onLanguageChange: Observable<string>;
  /**
   * Get the current language
   * @returns The current language
   */
  get currentLanguage(): string;
  /**
   * Set the language to use for translations and return an observable that emits when the language is set
   * @param value The language to use
   * @returns An observable that emits when the language is set
   */
  setLanguage<T extends object>(value: string): Observable<T>;
  /**
   * Set the default language to use if no language is set
   * @param value The default language to use
   */
  setDefaultLang(value: string): void;
  /**
   * Get a translation for a given key instantly, without waiting for translations to be loaded
   * @param key The key of the translation
   * @param variantName The name of the variant to use for the translation (optional
   * @returns The translation
   * @deprecated Use get instead
   */
  instant(key: string, variantName?: string): string;
  /**
   * Get a translation for a given key as an observable, waiting for translations to be loaded
   * @param key The key of the translation
   * @param variantName The name of the variant to use for the translation (optional)
   */
  get(key: string, variantName?: string): Observable<string>;
  /**
   * Get translations for multiple keys as an observable, waiting for translations to be loaded
   * @param value An array of keys to get translations for
   * @param variantName The name of the variant to use for the translations (optional)
   * @returns An object with the keys as properties and the translations as values
   */
  get<T extends object>(value: string[], variantName?: string): Observable<T>;
  /**
   * Get a translation for all keys in an object as an observable, waiting for translations to be loaded
   * @param value An object with keys to get translations for
   * @param variantName The name of the variant to use for the translation (optional)
   * @returns An object with the the same keys as the input object and the translations as values
   */
  get<T extends object>(value: T, variantName?: string): Observable<T>;
  /**
   * Get a translation for a given key as an observable, waiting for translations to be loaded. The observable keeps
   * emitting when the language changes.
   * @param key The key of the translation
   * @param variantName The name of the variant to use for the translation (optional)
   * @returns An observable that emits the translation and keeps emitting when the language changes
   */
  stream(key: string, variantName?: string): Observable<string>;
  /**
   * Get translations for multiple keys as an observable, waiting for translations to be loaded. The observable keeps
   * emitting when the language changes.
   * @param value An array of keys to get translations for
   * @param variantName The name of the variant to use for the translations (optional)
   * @returns An object with the keys as properties and the translations as values
   */
  stream<T extends object>(value: string[], variantName?: string): Observable<T>;
  /**
   * Get a translation for all keys in an object as an observable, waiting for translations to be loaded. The observable
   * keeps emitting when the language changes.
   * @param value An object with keys to get translations for
   * @param variantName The name of the variant to use for the translation (optional)
   * @returns An object with the the same keys as the input object and the translations as values
   */
  stream<T extends object>(value: T, variantName?: string): Observable<T>;
}

export const TRANSLATE_SERVICE = new InjectionToken<ITranslateService>('CoreTranslateService');
