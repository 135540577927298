import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  inject,
  Input,
  Output,
} from '@angular/core';

@Component({
  selector: 'lh-shared-ui-numeric-up-down',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './shared-ui-numeric-up-down.component.html',
  styleUrl: './shared-ui-numeric-up-down.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SharedUiNumericUpDownComponent {
  @Output() valueChange = new EventEmitter<number>();

  private cd = inject(ChangeDetectorRef);

  private _min = 0;
  private _max = 100;
  private _value: number | undefined;

  get value(): number | undefined {
    return this._value;
  }
  @Input() set value(value: number) {
    this._value = value;
    this.cd.markForCheck();
    this.valueChange.emit(this._value);
  }

  get min(): number {
    return this._min;
  }
  @Input() set min(value: number) {
    this._min = value;

    if (this.value && this.value < this.min) {
      this.value = this.min;
    } else {
      this.cd.markForCheck();
    }
  }

  get max(): number {
    return this._max;
  }
  @Input() set max(value: number) {
    this._max = value;

    if (this.value && this.value > this.max) {
      this.value = this.max;
    } else {
      this.cd.markForCheck();
    }
  }

  public increment(): void {
    if (!this.value) {
      return;
    }
    this.value = Math.min(this.max, this.value + 1);
  }

  public decrement(): void {
    if (!this.value) {
      return;
    }
    this.value = Math.max(this.min, this.value - 1);
  }
}
