<!-- Icon call-to-action -->
@if (callToAction !== null && callToAction !== undefined) {
  <div *ngIf="callToAction.length === 1" class="icon-container" [class.compact]="compactMode">
    <div
      [tooltip]="tooltipTemplate"
      container="body"
      class="tooltip-cta icon-cta"
      [class.dark-mode]="darkMode"
      (click)="$event.preventDefault()"
    >
      {{ callToAction || 'i' }}
    </div>
  </div>

  <!-- Text call-to-action -->
  <div *ngIf="callToAction.length > 1" class="text-container">
    <div
      [tooltip]="tooltipTemplate"
      container="body"
      class="tooltip-cta text-cta"
      [class.dark-mode]="darkMode"
      (click)="$event.stopPropagation()"
    >
      {{ callToAction | translate }}
    </div>
  </div>
}

<!-- No call-to-action -->
<div
  *ngIf="!callToAction"
  [tooltip]="tooltipTemplate"
  container="body"
  class="tooltip-inflate"
  (click)="$event.stopPropagation()"
></div>

<!-- Tooltip -->
<ng-template #tooltipTemplate>
  <div class="tooltip-content">
    <div *ngIf="header" class="tooltip-header" [innerHtml]="header"></div>
    <div class="tooltip-body" [innerHtml]="body"></div>
  </div>
</ng-template>
