import { AppConfig } from '@luggagehero/shared/app-settings/data-access';
import { BookableStorageLocation, Booking, LegacyOrder, Review } from '@luggagehero/shared/interfaces';

import { BookingActionInfo, BookingChange } from '../booking-actions/booking-action-info';
import { BookingTimelineBaseComponent } from '../booking-timeline.base-component';

export abstract class BookingStep {
  private _isVisible: boolean;
  private _title: string;
  private _subtitle: string;
  private _isSubtitleHidden: boolean;
  private _isActive: boolean;
  private _isCompleted: boolean;
  private _isFailed: boolean;
  private _actionInfo: BookingActionInfo;
  private _isActionCollapsed: boolean;
  private _continueTimeline: boolean;

  constructor(protected parent: BookingTimelineBaseComponent) {}

  protected get booking(): Booking {
    return this.parent.booking;
  }
  protected set booking(value: Booking) {
    this.parent.booking = value;
  }

  protected get order(): LegacyOrder {
    return this.booking?.orderDetails?.length > 0
      ? this.booking.orderDetails[this.booking.orderDetails.length - 1]
      : null;
  }

  protected get shop(): BookableStorageLocation {
    return this.parent.shop;
  }
  protected set shop(value: BookableStorageLocation) {
    this.parent.shop = value;
  }

  protected get review(): Review {
    return this.parent.review;
  }
  protected set review(value: Review) {
    this.parent.review = value;
  }

  protected get isExperiencesEnabled() {
    if (!this.shop) {
      return false;
    }
    return (
      this.parent.config.experiencesEnabled &&
      AppConfig.CITIES_WITH_EXPERIENCES_ENABLED.includes(this.shop.officialLocationKey)
    );
  }

  get previousStep(): BookingStep {
    if (this.position > 0) {
      return this.parent.bookingSteps[this.position - 1];
    }
    // This is the first step
    return null;
  }

  get lastStep(): BookingStep {
    return this.parent.lastStep;
  }

  get isLastStep(): boolean {
    return this.parent.lastStep === this;
  }

  get position(): number {
    return this.parent.bookingSteps.findIndex((step) => step === this);
  }

  get isVisible(): boolean {
    return this._isVisible;
  }

  get title(): string {
    return this._title;
  }

  get subtitle(): string {
    return this._subtitle;
  }

  get isSubtitleHidden(): boolean {
    return this._isSubtitleHidden;
  }

  get isActive(): boolean {
    return this._isActive;
  }

  get isCompleted(): boolean {
    return this._isCompleted;
  }

  get isFailed(): boolean {
    return this._isFailed;
  }

  get actionInfo(): BookingActionInfo {
    return this._actionInfo;
  }

  get isActionCollapsed(): boolean {
    return this._isActionCollapsed;
  }

  get continueTimeline(): boolean {
    return this._continueTimeline;
  }

  refresh() {
    this._isVisible = this.getIsVisible();
    this._title = this.getTitle();
    this._subtitle = this.getSubtitle();
    this._isSubtitleHidden = this.getIsSubtitleHidden();
    this._isActive = this.getIsActive();
    this._isCompleted = this.getIsCompleted();
    this._isFailed = this.getIsFailed();
    this._actionInfo = this.getActionInfo();
    this._isActionCollapsed = this.getIsActionCollapsed();
    this._continueTimeline = this.getContinueTimeline();
  }

  abstract getIsVisible(): boolean;
  abstract getTitle(): string;
  abstract getSubtitle(): string;
  abstract getIsSubtitleHidden(): boolean;
  abstract getIsActive(): boolean;
  abstract getIsCompleted(): boolean;
  abstract getIsFailed(): boolean;
  abstract getActionInfo(): BookingActionInfo;
  abstract getIsActionCollapsed(): boolean;

  getContinueTimeline(): boolean {
    // return true;
    return !this.isLastStep;
  }

  translate(key: string): string {
    return this.parent.translate.instant(key) as string;
  }

  triggerAction(action: BookingActionInfo) {
    this.parent.selectAction.emit(action);
  }

  async updateBookingState(): Promise<BookingChange> {
    const booking = await this.parent.bookingService.updateState(this.booking);

    const change: BookingChange = {
      changeType: 'booking',
      changedValue: booking,
    };
    return change;
  }

  async reloadBooking(): Promise<Booking> {
    this.booking = await this.parent.bookingService.getBooking(this.booking._id, true);
    return this.booking;
  }
}
