import { Component, inject, OnInit } from '@angular/core';
import {
  BookableStorageLocation,
  Booking,
  BookingAddOns,
  GUEST_INSURANCE_FEE_PRODUCT_KEY,
  ILuggage,
  IPaymentRecord,
  ITimePeriod,
  LegacyOrder,
  PricingModel,
  ProductInfo,
} from '@luggagehero/shared/interfaces';
import { SharedBookingService } from '@luggagehero/shared/services/bookings';
import { SharedLoadingService } from '@luggagehero/shared/services/loading';
import { SharedLoggingService } from '@luggagehero/shared/services/logging';
import { SharedOrderService } from '@luggagehero/shared/services/orders';
import { SharedPaymentService } from '@luggagehero/shared/services/payments';
import { SharedPricingService } from '@luggagehero/shared/services/pricing';
import { SharedPromoCodeService } from '@luggagehero/shared/services/promo-codes';
import { SharedShopsService } from '@luggagehero/shared/services/shops';
import { SharedTranslateService } from '@luggagehero/shared/services/translation';
import { SharedWindowService } from '@luggagehero/shared/services/window';
import { WizardStepBaseComponent } from '@luggagehero/shared/ui';
import { SharedUtilProduct } from '@luggagehero/shared/util';

import { GuestDropoffParams, GuestDropoffStepProperties } from '../model';

const DEFAULT_ADDONS: BookingAddOns = {
  walkIn: false,
  guest: true,
  loyaltyMember: undefined,
  guestMovement: 'unset',
  insurance: false,
  freeCancellation: false,
  securitySeals: 0,
};

@Component({ template: '' })
export abstract class GuestDropoffStepBaseComponent<T extends GuestDropoffStepProperties = GuestDropoffStepProperties>
  extends WizardStepBaseComponent<T>
  implements OnInit
{
  protected isOrderValid = false;

  protected bookingService = inject(SharedBookingService);
  protected storageLocationService = inject(SharedShopsService);
  protected orderService = inject(SharedOrderService);
  protected paymentService = inject(SharedPaymentService);
  protected pricingService = inject(SharedPricingService);
  protected promoCodeService = inject(SharedPromoCodeService);
  protected translateService = inject(SharedTranslateService);
  protected windowService = inject(SharedWindowService);
  protected loader = inject(SharedLoadingService);
  protected logger = inject(SharedLoggingService);

  // HACK: Using a static variable until we have the services needed to keep the state
  private static readonly bookingDraftInstance: Partial<GuestDropoffParams> = {};
  private static bookingInstance?: Booking;
  private static _createBookingPromise: Promise<void> | null = null;

  private _storageLocation = this.storageLocationService.current;

  constructor() {
    super();
    this.addSubscription(this.loader.loading$.subscribe((value) => (this.isLoading = value)));
  }

  public get isLoading(): boolean {
    return super.isLoading;
  }
  private set isLoading(value: boolean) {
    super.isLoading = value;
    void this.checkCanGoForward(false);
  }

  public get pricingModel(): PricingModel {
    return this.bookingDraft.pricingModel;
  }
  public set pricingModel(value: PricingModel) {
    this.bookingDraft.pricingModel = value;
    void this.checkCanGoForward(true);
  }

  public get coverage(): ProductInfo {
    return this.bookingDraft.coverage;
  }
  public set coverage(value: ProductInfo) {
    this.bookingDraft.coverage = value;
    void this.checkCanGoForward(true);
  }

  public get dailyOrder(): LegacyOrder {
    return this.bookingDraft.dailyOrder;
  }
  public set dailyOrder(value: LegacyOrder) {
    this.bookingDraft.dailyOrder = value;
    void this.checkCanGoForward(false);
  }

  public get hourlyOrder(): LegacyOrder {
    return this.bookingDraft.hourlyOrder;
  }
  public set hourlyOrder(value: LegacyOrder) {
    this.bookingDraft.hourlyOrder = value;
    void this.checkCanGoForward(false);
  }

  public get order(): LegacyOrder {
    return this.bookingDraft.order;
  }
  public set order(value: LegacyOrder) {
    this.bookingDraft.order = value;
    this.isOrderValid = true;
    void this.checkCanGoForward(false);
  }

  public get luggage(): ILuggage {
    return this.bookingDraft.luggage;
  }
  public set luggage(value: ILuggage) {
    this.bookingDraft.luggage = value;
    void this.checkCanGoForward(true);
  }

  public get addOns(): BookingAddOns {
    return this.bookingDraft.addOns;
  }
  public set addOns(value: BookingAddOns) {
    this.bookingDraft.addOns = value;
    void this.checkCanGoForward(true);
  }

  public get discountCode(): string {
    return this.bookingDraft.discountCode;
  }
  public set discountCode(value: string) {
    this.bookingDraft.discountCode = value;
    void this.checkCanGoForward(true);
  }

  public get period(): ITimePeriod {
    return this.bookingDraft.period;
  }
  public set period(value: ITimePeriod) {
    this.bookingDraft.period = value;
    void this.checkCanGoForward(true);
  }

  public get paymentMethod(): IPaymentRecord {
    return this.bookingDraft.paymentMethod;
  }
  public set paymentMethod(value: IPaymentRecord) {
    this.bookingDraft.paymentMethod = value;
    void this.checkCanGoForward(true);
  }

  private get createBookingPromise(): Promise<void> {
    return GuestDropoffStepBaseComponent._createBookingPromise;
  }
  private set createBookingPromise(value: Promise<void>) {
    GuestDropoffStepBaseComponent._createBookingPromise = value;
  }

  protected get realtimeOrderUpdates(): boolean {
    return false;
  }

  protected get bookingDraft(): Partial<GuestDropoffParams> {
    return GuestDropoffStepBaseComponent.bookingDraftInstance;
  }

  protected get booking(): Booking {
    return GuestDropoffStepBaseComponent.bookingInstance;
  }
  protected set booking(value: Booking) {
    GuestDropoffStepBaseComponent.bookingInstance = value;
  }

  public get storageLocation(): BookableStorageLocation {
    return this._storageLocation;
  }

  public async ngOnInit(): Promise<void> {
    await this.loader.load(async () => {
      this.initDropoffParams();

      if (this.properties.requireOrder && !this.order) {
        await this.createOrder();
      }

      if (this.properties.requireBooking && !this.booking) {
        await this.createBooking();
      }

      // Allow the derived class to initialize
      await this.onInit();

      await this.checkCanGoForward(false);
    });
  }

  /**
   * Derived classes should override this method to perform any initialization logic.
   */
  protected abstract onInit(): Promise<void>;

  private initDropoffParams(): void {
    //
    // Set default values if they are not already set
    //
    if (!this.period) {
      this.period = this.getDefaultPeriod();
    }

    if (!this.addOns) {
      this.addOns = DEFAULT_ADDONS;
    }
  }

  protected translate(key: string): string {
    return this.translateService.instant(key, this.storageLocation?._id);
  }

  protected async checkCanGoForward(orderParamsChanged: boolean): Promise<void> {
    if (orderParamsChanged) {
      this.isOrderValid = false;

      if (this.properties.requireOrderUpdates) {
        await this.createOrder();
      }
    }
    this.canGoForward = this.onCheckCanGoForward();
    this.canSkip = this.onCheckCanSkip();
    this.canGoBack = this.onCheckCanGoBack();
  }

  public async goForward(): Promise<void> {
    await this.loader.load(async () => {
      if (this.properties.createOrder && (!this.order || !this.isOrderValid)) {
        await this.createOrder();
      }

      if (this.properties.createBooking && !this.booking) {
        await this.createBooking();
      }

      await super.goForward();
    });
  }

  protected onCheckCanGoForward(): boolean {
    return true;
  }

  protected onCheckCanGoBack(): boolean {
    return true;
  }

  protected onCheckCanSkip(): boolean {
    return false;
  }

  protected async updateOrders(): Promise<void> {
    await this.loader.load(
      async () => {
        const orders = await Promise.all([this.getQuote('daily'), this.getQuote('hourly')]);
        this.dailyOrder = orders[0];
        this.hourlyOrder = orders[1];
      },
      (err) => void this.logger.error('Failed to generate quote', err),
    );
  }

  protected async createBooking(): Promise<void> {
    if (this.createBookingPromise == null) {
      this.createBookingPromise = this.bookingService
        .confirmBookingAndCheckIn(
          this.order._id,
          this.bookingDraft.remoteLuggageImage,
          this.bookingDraft.paymentMethod?.id,
          this.bookingService.compileMetaData('guest', this.storageLocation, this.bookingDraft.addOns),
        )
        .then((booking) => {
          this.booking = booking;
          this.createBookingPromise = null;
        })
        .catch((err) => {
          void this.logger.error('Failed to create booking', err);
          this.createBookingPromise = null;
        });
    }
    await this.createBookingPromise;
  }

  protected async createOrder(): Promise<void> {
    try {
      const minBags = this.orderService.getMinimumBags(true);
      const luggage = {
        normal: Math.max(this.luggage?.normal || 1, minBags),
        hand: this.luggage?.hand || 0,
      };

      // HACK: For now we don't distinguish between different coverage products
      // TODO: Specify the product selected as part of the order
      this.addOns.insurance = SharedUtilProduct.isInsuranceFee(this.coverage);
      if (this.addOns.insurance) {
        this.addOns.insuranceKey = this.coverage?.key || GUEST_INSURANCE_FEE_PRODUCT_KEY;
      } else {
        // reset it in the case that it was set before and the user changed it
        this.addOns.insuranceKey = undefined;
      }

      this.order = await this.orderService.generateQuote(
        this.storageLocation._id,
        this.pricingModel || 'daily',
        luggage,
        this.period,
        this.addOns,
        undefined,
        this.promoCodeService.appliedDiscount?.code,
      );

      this.discountCode = this.promoCodeService.appliedDiscount?.code;

      // await this.updatePayment();
    } catch (err) {
      void this.logger.error(err);
    }
  }

  private async getQuote(pricingModel: PricingModel): Promise<LegacyOrder> {
    const period = this.getDefaultPeriod();

    const order = await this.orderService.generateQuote(
      this.storageLocation._id,
      pricingModel,
      this.luggage,
      period,
      this.addOns,
      undefined,
    );

    return order;
  }

  private getDefaultPeriod(): ITimePeriod {
    switch (this.pricingModel) {
      case 'hourly':
        return this.getDefaultHourlyPeriod();

      case 'daily':
      default:
        return this.getDefaultDailyPeriod();
    }
  }

  private getDefaultDailyPeriod(): ITimePeriod {
    // We don't support multi-day bookings currently, so just use same period for daily for now
    return this.getDefaultHourlyPeriod();
  }

  private getDefaultHourlyPeriod(): ITimePeriod {
    // 5 hours is the average time for hourly bookings
    const from = new Date();
    const to = new Date(from.getTime() + 1000 * 60 * 60 * 5);

    return { from, to };
  }
}
