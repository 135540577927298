export const SERVICE_FEE_PRODUCT_KEY = 'serviceFee';
export const WALK_IN_SERVICE_FEE_PRODUCT_KEY = 'WALK_IN_SERVICE_FEE';
export const WALK_IN_DAILY_RATE_PRODUCT_KEY = 'WALK_IN_DAILY_RATE';
export const WALK_IN_HOURLY_RATE_PRODUCT_KEY = 'WALK_IN_HOURLY_RATE';
export const WALK_IN_INSURANCE_FEE_PRODUCT_KEY = 'WALK_IN_INSURANCE_FEE';
export const GUEST_SERVICE_FEE_PRODUCT_KEY = 'GUEST_SERVICE_FEE';
export const GUEST_DAILY_RATE_PRODUCT_KEY = 'GUEST_DAILY_RATE';
export const GUEST_HOURLY_RATE_PRODUCT_KEY = 'GUEST_HOURLY_RATE';
export const GUEST_INSURANCE_FEE_PRODUCT_KEY = 'GUEST_INSURANCE_FEE';
export const GUEST_PREMIUM_INSURANCE_FEE_PRODUCT_KEY = 'GUEST_PREMIUM_INSURANCE_FEE';
export const INSURANCE_PRODUCT_KEY = 'startupFee';
export const PREMIUM_INSURANCE_PRODUCT_KEY = 'premiumInsuranceFee';
export const STORAGE_HOUR_PRODUCT_KEY = 'hourlyRate';
export const STORAGE_DAY_PRODUCT_KEY = 'dailyRate';
export const DISCOUNT_PRODUCT_KEY = 'discountCode';
export const TIP_PRODUCT_KEY = 'tip';
export const CANCELLATION_FEE_PRODUCT_KEY = 'CANCELLATION_FEE';
export const FREE_CANCELLATION_FEE_PRODUCT_KEY = 'freeCancellationFee';
export const SECURITY_SEAL_PRODUCT_KEY = 'securitySeal';

export const PREMIUM_INSURANCE_PRODUCT_KEYS = [GUEST_PREMIUM_INSURANCE_FEE_PRODUCT_KEY, PREMIUM_INSURANCE_PRODUCT_KEY];

export interface Product {
  _id?: string;
  name: string;
  unitSingular?: string;
  unitPlural?: string;
  info?: {
    header?: string;
    body: string;
  };
}

export interface NewProduct {
  _id?: string;
  name: string;
  key: string;
  description: string;
  created?: Date;
  modified?: Date;
}
