@if (isLoading) {
  <div>Loading...</div>
} @else if (paymentMethods && paymentMethods.length > 0) {
  <div class="payment-methods">
    @if (isExistingPaymentMethodsCollapsed) {
      <lh-shared-ui-button
        type="button"
        [size]="'small'"
        [style]="'info'"
        [textAlign]="'left'"
        [block]="true"
        [shadow]="false"
        (press)="selectExistingPaymentMethod()"
      >
        <div class="title">{{ 'SAVED_PAYMENT_METHODS' | lhTranslate }} ({{ paymentMethods.length }})</div>
      </lh-shared-ui-button>
    } @else {
      @for (paymentMethod of paymentMethods; track $index) {
        <button
          class="btn btn-info btn-sm btn-block"
          [class.selected]="isSelected(paymentMethod)"
          (click)="selectPaymentMethod(paymentMethod)"
        >
          <div class="title">
            <span>{{ paymentMethod | paymentMethod: 'brand' | uppercase }}</span>
            <span>{{ paymentMethod | paymentMethod: 'number' }}</span>
          </div>
          <div class="subtitle">{{ paymentMethod | paymentMethod: 'expiryShort' }}</div>
        </button>
      }
    }

    <lh-shared-ui-button
      type="button"
      [size]="'small'"
      [style]="'info'"
      [textAlign]="'left'"
      [block]="true"
      [shadow]="false"
      [selected]="isAddingNewPaymentMethod"
      (press)="selectNewPaymentMethod()"
    >
      <div class="title">{{ 'NEW_PAYMENT_METHOD' | lhTranslate }}</div>
    </lh-shared-ui-button>
  </div>
}
