<google-map
  *ngIf="mapOptions"
  width="100%"
  height="100%"
  [center]="center"
  [zoom]="zoom"
  [options]="mapOptions"
  (centerChanged)="onCenterChange()"
  (zoomChanged)="onZoomChange()"
  (mapClick)="onMapClick($event)"
  (mapInitialized)="onMapInitialized($event)"
>
  <map-marker *ngIf="myLocationMarker" [options]="myLocationMarker"></map-marker>

  <map-marker
    *ngFor="let marker of storageLocationMarkers"
    [options]="getMarkerOptions(marker)"
    (mapClick)="onStorageLocationMarkerClick(marker)"
    (mapMouseover)="onStorageLocationMarkerMouseOver(marker)"
    (mapMouseout)="onStorageLocationMarkerMouseOut(marker)"
  >
  </map-marker>

  <map-circle *ngFor="let circle of storageLocationCircles" [options]="circle" />

  <map-info-window [options]="mapInfoWindowOptions" *ngIf="enableStorageLocationInfoWindows">
    <a
      [id]="'makeBookingFromMap_' + openStorageLocation._id"
      class="shop-list-item"
      (click)="onStorageLocationClick(openStorageLocation)"
      *ngIf="openStorageLocation"
    >
      <lh-simple-shop-item
        [shop]="openStorageLocation"
        [distanceUnit]="distanceUnit | async"
        (bookingRequest)="onBookingRequest(openStorageLocation)"
      ></lh-simple-shop-item>
    </a>
  </map-info-window>
</google-map>
