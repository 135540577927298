import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, Provider } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BankAccountFormBaseComponent } from '@luggagehero/features/ui';
import { SharedStripeService } from '@luggagehero/shared/services/stripe';

const BANK_ACCOUNT_FORM_CONTROL_VALUE_ACCESSOR: Provider = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => BankAccountFormComponent),
  multi: true,
};

@Component({
  selector: 'lh-bank-account-form',
  templateUrl: './bank-account-form.component.html',
  styleUrls: ['./bank-account-form.component.scss'],
  providers: [BANK_ACCOUNT_FORM_CONTROL_VALUE_ACCESSOR],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BankAccountFormComponent extends BankAccountFormBaseComponent implements ControlValueAccessor {
  constructor(stripe: SharedStripeService, cd: ChangeDetectorRef) {
    super(stripe, cd);
  }
}
