import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { SharedUserService } from '@luggagehero/shared/services/users';
import { Subscription } from 'rxjs';

import { BaseComponent } from '../../../core';
const PAGES_WITHOUT_TAB_BAR = ['dropoff', 'book-shop', 'shop-details'];

@Component({ template: '' })
export abstract class TabBarBaseComponent extends BaseComponent implements OnInit, OnDestroy {
  public isLoggedIn = false;

  private subscriptions: Subscription[] = [];

  constructor(
    private userService: SharedUserService,
    private router: Router,
    private cd: ChangeDetectorRef,
  ) {
    super();
  }

  get isUserAvailable(): boolean {
    if (!this.userService.user) {
      return false;
    }
    return true;
  }

  get isTabBarVisible(): boolean {
    for (const page of PAGES_WITHOUT_TAB_BAR) {
      if (this.router.routerState.snapshot.url.includes(page)) {
        return false;
      }
    }
    return true;
  }

  get isBrowseStorageTabActive(): boolean {
    if (this.isNearbyStorageTabActive) {
      return false;
    }
    if (this.router.routerState.snapshot.url.includes('home')) {
      return true;
    }
    return false;
  }

  get isNearbyStorageTabActive(): boolean {
    if (this.router.routerState.snapshot.url.includes('find-storage')) {
      return true;
    }
    return false;
  }

  get isQuickDropTabActive(): boolean {
    if (this.router.routerState.snapshot.url.includes('quick-drop')) {
      return true;
    }
    return false;
  }

  get isBookingsTabActive(): boolean {
    if (this.router.routerState.snapshot.url.includes('bookings/')) {
      return true;
    }
    return false;
  }

  ngOnInit() {
    this.subscriptions.push(
      this.router.events.subscribe((e) => {
        if (e instanceof NavigationEnd) {
          // This will update the tab bar state based on what page was navigated to
          this.cd.markForCheck();
        }
      }),
      this.userService.loginAnnounced.subscribe((loggedIn) => {
        this.isLoggedIn = loggedIn && this.isUserAvailable;
        this.cd.markForCheck();
      }),
    );
    this.isLoggedIn = this.userService.isLoggedIn && this.isUserAvailable;
  }

  ngOnDestroy() {
    try {
      this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    } catch {
      // Ignore
    }
  }
}
