/* eslint-disable */
import { Injectable } from '@angular/core';

import { FacebookAuthResponse, FacebookLogin, FacebookLoginResponse } from '@luggagehero/shared/interfaces';
import { FacebookSdk } from './facebook.sdk';

@Injectable()
export class FacebookLoginService implements FacebookLogin {
  constructor(private facebookSdk: FacebookSdk) {}

  async login(): Promise<FacebookAuthResponse> {
    const fb = await this.facebookSdk.load();

    return new Promise<FacebookAuthResponse>((resolve) => {
      const options = { scope: 'email', return_scopes: true };

      fb.login((res: FacebookLoginResponse) => {
        resolve(res.status === 'connected' ? res.authResponse : null);
      }, options);
    });
  }

  async logout() {
    try {
      const fb = await this.facebookSdk.load();
      await new Promise<void>((resolve) => fb.logout(() => resolve()));
    } catch {
      // Ignore
    }
  }
}
