import { ChangeDetectorRef, Component, inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AppConfig, SharedAppSettingsService } from '@luggagehero/shared/app-settings/data-access';
import {
  BookableStorageLocation,
  CreateBookingParams,
  ILuggage,
  ITimePeriod,
  StorageCriteria,
  StorageLocationService,
} from '@luggagehero/shared/interfaces';
import { SharedBookingService } from '@luggagehero/shared/services/bookings';
import { SharedDocumentService } from '@luggagehero/shared/services/document';
import { SharedLocationService } from '@luggagehero/shared/services/locations';
import { SharedShopsService } from '@luggagehero/shared/services/shops';
import { SharedSplitTestingService } from '@luggagehero/shared/services/split-testing';
import { SharedStorageService } from '@luggagehero/shared/services/storage';
import { SharedStorageCriteria, SharedStorageCriteriaService } from '@luggagehero/shared/services/storage-criteria';
import { SharedThemeService } from '@luggagehero/shared/services/theme';
import { SharedUserService } from '@luggagehero/shared/services/users';
import { Subscription } from 'rxjs';

import { BaseComponent } from '../../../../core';
import { DateUtil } from '../../../../utils/date.util';
import { Step, StepBaseComponent } from '../../../ui';

@Component({ template: '' })
export abstract class DropoffBaseComponent extends BaseComponent implements OnInit, OnDestroy {
  private appSettings = inject(SharedAppSettingsService);
  private themeService = inject(SharedThemeService);

  private _dropoffSteps: Step<StepBaseComponent>[];
  private _isLoading: boolean;
  private subscriptions: Subscription[] = [];
  private _isNewGuestDropoffFlowEnabled = false;

  constructor(
    private bookingService: SharedBookingService,
    private userService: SharedUserService,
    private storageLocationService: SharedShopsService,
    private criteriaService: SharedStorageCriteriaService,
    private locationService: SharedLocationService,
    private storageService: SharedStorageService,
    private splitTestingService: SharedSplitTestingService,
    private document: SharedDocumentService,
    private router: Router,
    private route: ActivatedRoute,
    private cd: ChangeDetectorRef,
  ) {
    super();

    this.document.addBodyClass('dropoff');
  }

  get isLoggedIn(): boolean {
    return this.userService.isLoggedIn;
  }

  get isNewGuestDropoffFlowEnabled(): boolean {
    return this._isNewGuestDropoffFlowEnabled;
  }

  get dropoffSteps(): Step<StepBaseComponent>[] {
    return this._dropoffSteps;
  }

  get isLoading(): boolean {
    return this._isLoading;
  }
  set isLoading(value: boolean) {
    this._isLoading = value;
    this.cd.markForCheck();
  }

  get bookingDraft(): Partial<CreateBookingParams> {
    return this.bookingService.bookingDraft;
  }
  set bookingDraft(value: Partial<CreateBookingParams>) {
    this.bookingService.bookingDraft = value;
  }

  get criteria(): StorageCriteria {
    return this.criteriaService.current;
  }
  set criteria(value: StorageCriteria) {
    this.criteriaService.current = value;
  }

  get storageLocation(): BookableStorageLocation {
    return this.storageLocationService.current;
  }
  set storageLocation(value: BookableStorageLocation) {
    this.storageLocationService.setCurrent(value, true);
  }

  public ngOnInit() {
    this.subscriptions.push(this.route.params.subscribe((p) => void this.initialize(p)));
  }

  public ngOnDestroy() {
    try {
      this.subscriptions?.forEach((sub) => sub.unsubscribe());
    } catch {
      // Ignore
    }

    this.document.removeBodyClass('dropoff');
  }

  abstract buildSteps(): Step<StepBaseComponent>[];

  private async initialize(params: Params) {
    this.isLoading = true;

    try {
      // Initialize the booking draft with the storage location id
      const storageLocationId = String(params['id']);
      this.bookingDraft = { storageLocationId };

      // Initialize the location service
      await this.locationService.init();

      // Initialize the criteria
      const period: ITimePeriod = { from: new Date(), to: new Date() };
      const minBags = AppConfig.IS_DROP_OFF_MIN_BAGS_ENABLED ? AppConfig.DROP_OFF_MIN_BAGS : 1;
      const luggage: ILuggage = {
        normal: Math.max(minBags, AppConfig.DROP_OFF_DEFAULT_NUMBER_OF_BAGS),
        hand: 0,
      };
      const location = this.criteriaService.currentOrDefault.location;
      this.criteria = new SharedStorageCriteria(location, period, luggage);

      //
      // HACK: Requesting both availability and shop details in parallel because we need pricing and shop name. Instead
      // we should have an endpoint that returns both.
      //
      const res = await Promise.all([
        this.storageLocationService.getAvailability(
          this.bookingDraft.storageLocationId,
          DateUtil.serializeDate(this.criteria.period.from),
          DateUtil.serializeDate(this.criteria.period.to),
          this.criteria.luggage.normal,
          this.criteria.luggage.hand,
          'dropoff',
        ),
        this.storageLocationService.getShopDetails(
          this.bookingDraft.storageLocationId,
          DateUtil.serializeDate(this.criteria.period.from),
          DateUtil.serializeDate(this.criteria.period.to),
          this.criteria.luggage.normal,
          this.criteria.luggage.hand,
          'dropoff',
        ),
      ]);

      if (!res[0]) {
        // The storage location no longer exists
        void this.router.navigate(['/']);
      }

      this.storageLocation = res[0];
      this.storageLocation.name = res[1].name;
      this.storageLocation.storageRoomAccessCode = res[1].storageRoomAccessCode;
      this.storageLocation.storageRoomAccessText = res[1].storageRoomAccessText;

      // Load theme by organization if there is one
      void this.themeService.loadThemeStyling(this.storageLocation.organizationId);

      // Check if the user has an existing booking that we should send them to
      if (await this.checkForRedirect()) {
        return; // A redirect happened so we can stop here
      }

      const isGuestStorageEnabled = this.storageLocation.services?.includes(StorageLocationService.GuestLuggageStorage);
      if (isGuestStorageEnabled) {
        this._isNewGuestDropoffFlowEnabled =
          !this.appSettings.current?.storageLocationsUsingOldGuestDropoffFlow?.includes(this.storageLocation._id) ||
          (await this.splitTestingService.isVariantEnabled(AppConfig.EXPERIMENT_VARIANTS.newGuestDropoffFlow, {
            storageLocation: this.storageLocation,
          }));
      } else {
        this._isNewGuestDropoffFlowEnabled = false;
      }

      // Initialize the navigation steps
      this._dropoffSteps = this.buildSteps();
    } catch (err) {
      // TODO: Handle errors
    } finally {
      this.isLoading = false;
    }
  }

  private async checkForRedirect(): Promise<boolean> {
    if (!this.storageLocation) {
      return false;
    }

    // Check if the user has an active booking at the storage location
    if (this.userService.isLoggedIn) {
      // Get the user's bookings from the API
      const currentUserBookings = await this.bookingService.getBookingsByCurrentUser();
      let activeBookingId: string;
      if (currentUserBookings?.length > 0) {
        currentUserBookings.every((booking) => {
          if (this.storageLocation._id === booking.shopId && ['CONFIRMED', 'CHECKED_IN'].includes(booking.status)) {
            activeBookingId = booking._id;
            return false;
          }
          return true;
        });
      }

      if (activeBookingId) {
        // Navigate to the booking page for the active booking
        void this.router.navigate(['/bookings', activeBookingId], {
          queryParams: { utm_source: 'drop_off_active_booking' },
        });
        return;
      }
    }

    // Check if the user has an active booking in local storage
    const storedBookingIds = this.storageService.bookings;
    if (storedBookingIds?.length > 0) {
      const activeBooking = await this.bookingService.findActiveBooking(this.storageLocation._id, storedBookingIds);
      if (activeBooking?.success) {
        let queryParams: unknown = {
          utm_source: 'client_cache',
        };
        if (activeBooking.bookingToken) {
          queryParams = {
            t: activeBooking.bookingToken,
          };
        }
        // Navigate to the booking page for the active booking
        void this.router.navigate(['/bookings', activeBooking.id], { queryParams });
        return;
      }
    }

    // No active booking found so no redirect
    return false;
  }
}
