import { ChangeDetectionStrategy, Component } from '@angular/core';
import { PricingBaseComponent } from '@luggagehero/features/ui';

@Component({
  selector: 'lh-pricing',
  templateUrl: './pricing.component.html',
  styleUrls: ['./pricing.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PricingComponent extends PricingBaseComponent {}
