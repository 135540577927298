<div class="tool-bar" [ngClass]="{ 'is-map-view': showMap }">
  <lh-traveler-shops-ui-shop-filters
    [distanceUnit]="distanceUnit"
    (distanceUnitClick)="distanceUnitChangeRequest.emit()"
    [isHotelFilterVisible]="isHotelFilterVisible"
    [isOpen24HoursFilterVisible]="isOpen24HoursFilterVisible"
    [isMultiDayStorageFilterVisible]="isMultiDayStorageFilterVisible"
    [isWifiAvailableFilterVisible]="isWifiAvailableFilterVisible"
    (toggleOpenAllDayFilter)="toggleOpenAllDayFilter.emit()"
    (toggleMultiDayStorageFilter)="toggleMultiDayStorageFilter.emit()"
    (toggleWifiAvailableFilter)="toggleWifiAvailableFilter.emit()"
    (toggleHotelsFilter)="toggleHotelsFilter.emit()"
    [hotelsFilterEnabled]="hotelsFilterEnabled"
    [openAllDayFilterEnabled]="openAllDayFilterEnabled"
    [multiDayStorageFilterEnabled]="multiDayStorageFilterEnabled"
    [wifiAvailableFilterEnabled]="wifiAvailableFilterEnabled"
  >
  </lh-traveler-shops-ui-shop-filters>

  <div class="view-options-bar">
    <div (click)="showMap = true" *ngIf="!showMap">
      <!-- prettier-ignore -->
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M10.403 15.2415L5.40295 13.4915L1.52795 14.9915C1.25018 15.1026 0.993232 15.0713 0.757121 14.8977C0.52101 14.7241 0.402954 14.4915 0.402954 14.1998V2.53312C0.402954 2.35257 0.455037 2.19284 0.559204 2.05395C0.663371 1.91507 0.805732 1.8109 0.986287 1.74146L5.40295 0.241455L10.403 1.99146L14.278 0.491455C14.5557 0.380344 14.8127 0.411594 15.0488 0.585205C15.2849 0.758816 15.403 0.991455 15.403 1.28312V12.9498C15.403 13.1303 15.3509 13.2901 15.2467 13.429C15.1425 13.5678 15.0002 13.672 14.8196 13.7415L10.403 15.2415ZM9.56962 13.1998V3.44979L6.23629 2.28312V12.0331L9.56962 13.1998ZM11.2363 13.1998L13.7363 12.3665V2.49146L11.2363 3.44979V13.1998ZM2.06962 12.9915L4.56962 12.0331V2.28312L2.06962 3.11646V12.9915Z" fill="currentColor"/>
        </svg>

      Show map
    </div>
    <div (click)="showMap = false" *ngIf="showMap">
      <!-- prettier-ignore -->
      <svg width="16" height="9" viewBox="0 0 16 9" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M3.73629 2.24147V0.574799H15.403V2.24147H3.73629ZM3.73629 5.5748V3.90813H15.403V5.5748H3.73629ZM3.73629 8.90813V7.24147H15.403V8.90813H3.73629ZM1.23629 2.24147C1.00018 2.24147 0.80226 2.1616 0.642537 2.00188C0.482815 1.84216 0.402954 1.64424 0.402954 1.40813C0.402954 1.17202 0.482815 0.974104 0.642537 0.814382C0.80226 0.65466 1.00018 0.574799 1.23629 0.574799C1.4724 0.574799 1.67032 0.65466 1.83004 0.814382C1.98976 0.974104 2.06962 1.17202 2.06962 1.40813C2.06962 1.64424 1.98976 1.84216 1.83004 2.00188C1.67032 2.1616 1.4724 2.24147 1.23629 2.24147ZM1.23629 5.5748C1.00018 5.5748 0.80226 5.49494 0.642537 5.33522C0.482815 5.17549 0.402954 4.97758 0.402954 4.74147C0.402954 4.50535 0.482815 4.30744 0.642537 4.14772C0.80226 3.98799 1.00018 3.90813 1.23629 3.90813C1.4724 3.90813 1.67032 3.98799 1.83004 4.14772C1.98976 4.30744 2.06962 4.50535 2.06962 4.74147C2.06962 4.97758 1.98976 5.17549 1.83004 5.33522C1.67032 5.49494 1.4724 5.5748 1.23629 5.5748ZM1.23629 8.90813C1.00018 8.90813 0.80226 8.82827 0.642537 8.66855C0.482815 8.50883 0.402954 8.31091 0.402954 8.0748C0.402954 7.83869 0.482815 7.64077 0.642537 7.48105C0.80226 7.32133 1.00018 7.24147 1.23629 7.24147C1.4724 7.24147 1.67032 7.32133 1.83004 7.48105C1.98976 7.64077 2.06962 7.83869 2.06962 8.0748C2.06962 8.31091 1.98976 8.50883 1.83004 8.66855C1.67032 8.82827 1.4724 8.90813 1.23629 8.90813Z" fill="currentColor"/>
        </svg>
      Show list
    </div>
  </div>
</div>
