import { inject, Pipe, PipeTransform } from '@angular/core';
import { IPricing, PRICING_SERVICE, PricingService } from '@luggagehero/shared/interfaces';
import { Observable } from 'rxjs';

@Pipe({ name: 'pricing', standalone: true })
export class PricingPipe implements PipeTransform {
  private pricingService = inject<PricingService>(PRICING_SERVICE);

  public transform(
    pricing: IPricing,
    includePerBag = true,
    includeDailyPrice = true,
    includeHourlyPrice = true,
    includeStartupFee = true,
    includeFrom = false,
    html = false,
  ): Observable<string | null> {
    return this.pricingService.transformPricing(
      pricing,
      includePerBag,
      includeDailyPrice,
      includeHourlyPrice,
      includeStartupFee,
      includeFrom,
      html,
    );
  }
}
