<!-- Dummy input for google places -->
<input #googlePlacesInput hidden />

@if (isNewFindStorageViewVariantEnabled) {
  <lh-traveler-shops-feature-find
    [isLoading]="isLoading"
    [shops]="visibleShops"
    [location]="location"
    [mapMarkerThemeOptions]="mapMarkerThemeOptions"
    (locationChange)="onMapLocationChange($event)"
    (bookNow)="makeBooking($event)"
    (openShopDetails)="onShowShopDetails($event)"
    (userLocationRequest)="loadUserLocation()"
    [showRedoSearchButton]="showRedoSearchButton"
    (loadShopsByMapLocationRequest)="loadShopsByMapLocation(false)"
    [isNoShopsAvailable]="isNoShopsAvailable"
    [(criteria)]="criteria"
    [highlightShop]="highlightShop"
    [isParentShowingModal]="isShowingModal"
    [isHotelFilterVisible]="isHotelFilterVisible"
    [isOpen24HoursFilterVisible]="isOpen24HoursFilterVisible"
    [isMultiDayStorageFilterVisible]="isMultiDayStorageFilterVisible"
    [isWifiAvailableFilterVisible]="isWifiAvailableFilterVisible"
    (toggleOpenAllDayFilter)="toggleOpenAllDayFilter()"
    (toggleMultiDayStorageFilter)="toggleMultiDayStorageFilter()"
    (toggleWifiAvailableFilter)="toggleWifiAvailableFilter()"
    (toggleHotelsFilter)="toggleHotelsFilter()"
    [hotelsFilterEnabled]="hotelsFilterEnabled"
    [openAllDayFilterEnabled]="openAllDayFilterEnabled"
    [multiDayStorageFilterEnabled]="multiDayStorageFilterEnabled"
    [wifiAvailableFilterEnabled]="wifiAvailableFilterEnabled"
    [hideUnavailable]="hideUnavailable"
  ></lh-traveler-shops-feature-find>
} @else {
  <!--Shop criteria for small screens-->
  <div class="shop-criteria-container" *ngIf="criteria">
    <lh-shop-criteria [(ngModel)]="criteria" [estimatedPrice]="estimatedPrice" [pricing]="pricing"></lh-shop-criteria>

    <div class="shop-filter-container" *ngIf="filtersReady">
      <div class="gradient-left" [hidden]="!isFilterGradientLeftVisible"></div>

      <div class="shop-filters" (scroll)="onFilterScroll()" #shopFilters>
        <div class="ps-4"></div>

        <!-- <button id="togglePricingModel" class="btn btn-sm btn-info btn-filter active" (click)="togglePricingModel()">
        <span [class.active]="(pricingModel | async) === 'hourly'">{{ 'PAY_AS_YOU_GO' | translate }}</span>
        <span class="inactive m-1">/</span>
        <span [class.active]="(pricingModel | async) === 'daily'">{{ 'PAY_UP_FRONT' | translate }}</span>
      </button> -->

        <button
          *ngIf="isHotelFilterVisible"
          id="toggleHotelsFilter"
          class="btn btn-sm btn-info btn-filter"
          [class.active]="hotelsFilterEnabled"
          (click)="toggleHotelsFilter()"
        >
          {{ 'HOTELS_FILTER' | translate }}
        </button>

        <button
          *ngIf="isOpen24HoursFilterVisible"
          id="toggleOpenAllDayFilter"
          class="btn btn-sm btn-info btn-filter"
          [class.active]="openAllDayFilterEnabled"
          (click)="toggleOpenAllDayFilter()"
        >
          {{ 'OPEN_24_HOURS' | translate }}
        </button>

        <button
          *ngIf="isMultiDayStorageFilterVisible"
          id="toggleMultiDayStorageFilter"
          class="btn btn-sm btn-info btn-filter"
          [class.active]="multiDayStorageFilterEnabled"
          (click)="toggleMultiDayStorageFilter()"
        >
          {{ 'MULTI_DAY_STORAGE_FILTER' | translate }}
        </button>

        <button
          *ngIf="isWifiAvailableFilterVisible"
          id="toggleWifiAvailableFilter"
          class="btn btn-sm btn-info btn-filter"
          [class.active]="wifiAvailableFilterEnabled"
          (click)="toggleWifiAvailableFilter()"
        >
          {{ 'WIFI_FILTER' | translate }}
        </button>

        <button id="toggleDistanceUnit" class="btn btn-sm btn-info btn-filter active" (click)="toggleDistanceUnit()">
          <span [class.active]="(distanceUnit | async) === 'metric'">{{ 'UNIT_SUFFIX_KILOMETERS' | translate }}</span>
          <span class="inactive m-2">/</span>
          <span [class.active]="(distanceUnit | async) === 'imperial'">{{ 'UNIT_SUFFIX_MILES' | translate }}</span>
        </button>

        <div class="ps-4"></div>
      </div>

      <div class="gradient-right" [hidden]="!isFilterGradientRightVisible"></div>
    </div>
  </div>

  <!--View options for small screens-->
  <div class="view-options-bar">
    <button
      id="showShopListView"
      type="button"
      (click)="showView('list')"
      class="btn"
      [class.full-width]="!isShopFilteringEnabled"
      [class.hidden]="showList"
      [title]="'LIST_VIEW' | translate"
    >
      <i class="icon-list"></i>{{ 'SHOW_LIST' | translate }}
    </button>
    <button
      id="showShopMapView"
      type="button"
      (click)="showView('map')"
      class="btn"
      [class.full-width]="!isShopFilteringEnabled"
      [class.hidden]="!showList"
      [title]="'MAP_VIEW' | translate"
    >
      <i class="icon-map"></i>{{ 'SHOW_MAP' | translate }}
    </button>
    <button id="showShopFilters" *ngIf="isShopFilteringEnabled" type="button" class="btn" (click)="filterModal.show()">
      <i class="icon-equalizer"></i>{{ 'FILTER' | translate }}
    </button>
  </div>

  <!--Shop map/list-->
  <div class="map-list">
    <div class="promo-alert" [class.promo-alert-map]="!showList" *ngIf="isAlertAllowed && visibleShops?.length > 0">
      <div class="alert text-center" [class.alert-info]="!simpleAddPromoCodeAlert" *ngIf="!isPromoCodeAlertEnabled">
        <div class="alert-message">{{ 'EXACT_ADDRESS_AND_DIRECTIONS_AFTER_BOOKING' | translate }}</div>
      </div>

      <div
        class="alert"
        [class.alert-info]="!simpleAddPromoCodeAlert"
        [class.text-center]="simpleAddPromoCodeAlert"
        *ngIf="showAddPromoCodeAlert"
      >
        <div class="alert-message">
          <span>{{ 'HAVE_A_VOUCHER_OR_PROMO_CODE' | translate }}</span>
          <a id="addPromoCode" class="href alert-cta" (click)="showPromoCodeModal()">{{ 'ADD_NOW' | translate }}</a>
        </div>
      </div>

      <div class="alert alert-success" *ngIf="showValidPromoCodeAlert">
        <div class="alert-message">
          <span>{{ 'DISCOUNT_APPLIED' | translate }}: {{ appliedDiscount | discount: criteria.luggage.normal }}</span>
          <a id="showPromoCodeDetails" class="href alert-cta" (click)="showPromoCodeModal()">{{
            'INFO' | translate
          }}</a>
        </div>
      </div>

      <div class="alert alert-warning" *ngIf="showInvalidPromoCodeAlert">
        <div class="alert-message">
          <span>{{ 'PROMO_CODE' | translate }} "{{ appliedPromoCode }}" {{ 'INVALID' | translate }}</span>
          <a id="editPromoCode" class="href alert-cta" (click)="showPromoCodeModal()">{{ 'EDIT' | translate }}</a>
        </div>
      </div>

      <button
        id="dismissPromoCodeAlert"
        type="button"
        class="btn-dismiss pull-right"
        aria-label="Dismiss"
        (click)="dismissPromoCodeAlert()"
        *ngIf="isPromoCodeAlertEnabled && (!simpleAddPromoCodeAlert || !showAddPromoCodeAlert)"
      >
        <svg viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
          />
          <path d="M0 0h24v24H0z" fill="none" />
        </svg>
      </button>
    </div>

    <div [hidden]="!showList" class="shop-list">
      <lh-shop-list
        #shopList
        [shops]="visibleShops"
        [(highlightShop)]="highlightShop"
        [(hideUnavailable)]="hideUnavailable"
        [isLoading]="isLoading"
        [isNoShopsAvailable]="isNoShopsAvailable"
        [numberOfShopsAvailable]="visibleShops.length"
      ></lh-shop-list>
    </div>

    <div *ngIf="isMapInitialized" [hidden]="!showMap" class="map-wrapper">
      <button
        id="loadMyLocation"
        *ngIf="location"
        class="btn my-location"
        [ngClass]="{ loading: isLoading === true }"
        [title]="'MY_LOCATION' | translate"
        type="button"
        (click)="loadUserLocation()"
      >
        <i class="icon"></i>
      </button>

      <div *ngIf="location" class="redo-search" [class.alert-padding]="isAlertVisible">
        <div *ngIf="!showRedoSearchButton && isSearchOnMoveMapAllowed" class="checkbox-redo">
          <div class="checkbox">
            <label for="map-auto-refresh-checkbox">
              <input type="checkbox" id="map-auto-refresh-checkbox" [(ngModel)]="isSearchOnMoveMapEnabled" />{{
                'SEARCH_ON_MOVE_MAP' | translate
              }}
            </label>
          </div>
        </div>
        <div *ngIf="showRedoSearchButton && !isLoadShopsByMapLocationRequested && !isLoading" class="button-redo">
          <button id="redoSearchHere" type="button" class="btn btn-primary" (click)="loadShopsByMapLocation(false)">
            <label>{{ 'REDO_SEARCH_HERE' | translate }}<i class="icon-reload"></i></label>
          </button>
        </div>
      </div>

      <lh-shop-map
        #shopMap
        [shops]="visibleShops"
        [initialSelectedShopId]="selectedShopId"
        [(highlightShop)]="highlightShop"
        [hideUnavailable]="hideUnavailable"
        [location]="location"
        (locationChange)="onMapLocationChange($event)"
      >
      </lh-shop-map>
    </div>
  </div>
}

<!-- Filter modal -->
<div
  class="modal fade"
  bsModal
  #filterModal="bs-modal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="mySmallModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-lg modal-fullscreen-sm full-height-footer">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left">
          <div>{{ 'FILTER_SHOPS' | translate }}</div>
        </h4>
        <button
          id="closeFilterModal"
          type="button"
          class="close pull-right"
          aria-label="Close"
          (click)="filterModal.hide()"
        >
          <svg viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
            />
            <path d="M0 0h24v24H0z" fill="none" />
          </svg>
        </button>
      </div>

      <div class="modal-body">
        <!--Availability-->
        <div class="checkbox">
          <label for="onlyAvailableShops">
            <input
              id="onlyAvailableShops"
              type="checkbox"
              #onlyAvailableShops
              name="onlyAvailableShops"
              [(ngModel)]="hideUnavailable"
            />
            {{ 'ONLY_AVAILABLE_SHOPS' | translate }}
          </label>
        </div>

        <!--Wi-Fi-->
        <div class="checkbox">
          <label for="onlyShopsWithWifi">
            <input
              id="onlyShopsWithWifi"
              type="checkbox"
              #onlyShopsWithWifi
              name="onlyShopsWithWifi"
              [(ngModel)]="showOnlyShopsWithWifi"
            />
            {{ 'ONLY_SHOPS_WITH_WIFI' | translate }}
          </label>
        </div>
      </div>

      <div class="modal-footer">
        <button id="applyFilters" class="btn btn-warning" [disabled]="isLoading" (click)="applyFilters()">
          {{ 'APPLY' | translate }}
        </button>
        <button id="cancelFilters" class="btn btn-info" (click)="filterModal.hide()">
          {{ 'CANCEL' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
