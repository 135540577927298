/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Injectable } from '@angular/core';
import { Console } from '@luggagehero/shared/interfaces';

@Injectable()
export class ConsoleService implements Console {
  log(...data: unknown[]): void {}
  debug(...data: unknown[]): void {}
  error(...data: unknown[]): void {}
  warn(...data: unknown[]): void {}
  info(...data: unknown[]): void {}
}
