import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { IPaymentRecord, IStripePaymentCard, IStripeSetupIntentResult } from '@luggagehero/shared/interfaces';
import { SharedErrorService } from '@luggagehero/shared/services/error';
import { SharedNotificationService } from '@luggagehero/shared/services/notification';
import { SharedPaymentService } from '@luggagehero/shared/services/payments';
import { SharedUserService } from '@luggagehero/shared/services/users';
import { SharedWindowService } from '@luggagehero/shared/services/window';
import { TranslateService } from '@ngx-translate/core';
import { ModalDirective } from 'ngx-bootstrap/modal';

import { BaseComponent } from '../../../core';
@Component({ template: '' })
export abstract class ManagePaymentMethodsBaseComponent extends BaseComponent implements OnInit {
  @ViewChild('paymentCardModal') public paymentCardModal: ModalDirective;
  paymentMethods: IPaymentRecord[] = [];
  isPaymentCardFormVisible = false;
  private _isLoading = false;
  private _newPaymentCard: IStripePaymentCard;

  constructor(
    private paymentService: SharedPaymentService,
    private windowService: SharedWindowService,
    private notify: SharedNotificationService,
    private error: SharedErrorService,
    private translate: TranslateService,
    private cd: ChangeDetectorRef,
    private userService: SharedUserService,
  ) {
    super();
  }

  get ios(): boolean {
    return this.windowService.iOS;
  }

  get newPaymentCard(): IStripePaymentCard {
    return this._newPaymentCard;
  }
  set newPaymentCard(value: IStripePaymentCard) {
    this._newPaymentCard = value;
  }

  get isLoading(): boolean {
    return this._isLoading;
  }
  set isLoading(value: boolean) {
    this._isLoading = value;
    this.cd.markForCheck();
  }

  ngOnInit() {
    if (this.userService.user?.isShopOwner !== true) {
      void this.loadPaymentMethods();
    }
  }

  async delete(paymentMethod: IPaymentRecord) {
    if (!confirm(this.translate.instant('CONFIRM_DELETE_PAYMENT_METHOD') as string)) {
      return;
    }
    try {
      await this.paymentService.deletePaymentMethod(paymentMethod.id);

      const index = this.paymentMethods.indexOf(paymentMethod);
      if (index >= 0) {
        this.paymentMethods.splice(index, 1);
      }
      this.cd.markForCheck();
    } catch (err) {
      // FIXME: Use UI notification service here
      alert(this.translate.instant('ERROR_REMOVING_PAYMENT_METHOD'));
    }
  }

  async onPaymentCardResult(result: IStripeSetupIntentResult): Promise<void> {
    if (result.error) {
      this.notify.warning(result.error.message);
      return;
    }

    this.isLoading = true;

    try {
      const newPaymentMethod = await this.paymentService.addPaymentMethod({
        provider: 'stripe',
        data: result.setupIntent,
      });

      this.notify.success(this.translate.instant('PAYMENT_CARD_ADDED') as string);
      this.paymentMethods.push(newPaymentMethod);

      this.paymentCardModal.hide();
    } catch (err) {
      this.error.handleError(err, this.translate.instant('ERROR_ADDING_PAYMENT_CARD') as string);
    }

    this.isLoading = false;
  }

  private async loadPaymentMethods() {
    this.isLoading = true;

    try {
      const res = await this.paymentService.getPaymentMethods();

      const paymentMethods: IPaymentRecord[] = [];
      res.forEach((paymentMethod) => {
        if (paymentMethod.provider !== 'direct_payment') {
          paymentMethods.push(paymentMethod);
        }
      });

      this.paymentMethods = paymentMethods;
      this.cd.markForCheck();
    } catch {
      // Ignore
    }

    this.isLoading = false;
  }
}
