import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, OnInit, Provider } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DatePickerBaseComponent } from '@luggagehero/features/ui';

export const DATE_PICKER_CONTROL_VALUE_ACCESSOR: Provider = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => DatePickerComponent),
  multi: true,
};

@Component({
  selector: 'lh-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
  providers: [DATE_PICKER_CONTROL_VALUE_ACCESSOR],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DatePickerComponent extends DatePickerBaseComponent implements ControlValueAccessor, OnInit {
  constructor(cd: ChangeDetectorRef) {
    super(cd);
  }
}
