import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { AppConfig } from '@luggagehero/shared/app-settings/data-access';

import { BaseComponent } from '../../../core';

export type ImageFormat = 'normal' | 'wide' | 'cinematic';

@Component({ template: '' })
export abstract class ImageSlideBaseComponent extends BaseComponent {
  @Input() blurImages = true;
  @Input() imageFormat: ImageFormat = 'normal';
  @Input() fullWidth = false;
  @Input() bannerText: string;
  @Input() bannerTooltip: string;
  @Input() bannerTextList: string[];
  @Input() bannerTextListReverse = false;
  @Input() bannerHighlightIndex: number;
  @Input() bannerHighlightIcon: string;
  @Input() bannerHighlightTooltip: string;
  @Input() bannerHighlightStyle: 'success' | 'warning' | 'danger' | 'yellow' = 'success';
  @Input() bannerType: 'primary' | 'success' | 'warning' | 'info';
  @Input() bannerMode: 'default' | 'sticker' | 'list' = 'default';
  @Input() promoted = false;

  private _imageUrl: string;
  private _labelText: string;
  private _headerText: string;
  private _ctaText: string;
  private _showLogo = false;
  private _showWatermark = false;
  private _isInitialized = false;

  constructor(private cd: ChangeDetectorRef) {
    super();
  }

  get bannerTextItems(): string[] {
    if (this.bannerTextList && this.bannerTextList.length > 0) {
      return this.bannerTextList;
    }
    return undefined;
  }

  get showCallToAction(): boolean {
    if (!this.ctaText) {
      return false;
    }
    if (this.showBanner && this.bannerMode === 'default') {
      return false;
    }
    return true;
  }

  get showBanner(): boolean {
    if (AppConfig.IS_SHOP_IMAGE_BANNER_DISABLED) {
      return false;
    }
    return this.bannerText || this.bannerTextItems ? true : false;
  }

  get showHeader(): boolean {
    if (AppConfig.IS_SHOP_IMAGE_HEADER_DISABLED) {
      return false;
    }
    if (this.imageFormat === 'cinematic') {
      return false;
    }
    return true;
  }

  get imageUrl(): string {
    return this._imageUrl;
  }
  @Input() set imageUrl(value: string) {
    this._imageUrl = value;
    this._isInitialized = true;
    this.cd.markForCheck();
  }

  get headerText(): string {
    return this._headerText;
  }
  @Input() set headerText(value: string) {
    this._headerText = value;
    this.cd.markForCheck();
  }

  get ctaText(): string {
    return this._ctaText;
  }
  @Input() set ctaText(value: string) {
    this._ctaText = value;
    this.cd.markForCheck();
  }

  get labelText(): string {
    return this._labelText;
  }
  @Input() set labelText(value: string) {
    this._labelText = value;
    this.cd.markForCheck();
  }

  get showWatermark(): boolean {
    if (AppConfig.IS_SHOP_IMAGE_CERTIFIED_WATERMARK_DISABLED) {
      return false;
    }
    return this._showWatermark;
  }
  @Input() set showWatermark(value: boolean) {
    this._showWatermark = value;
    this.cd.markForCheck();
  }

  get showLogo(): boolean {
    return this._showLogo;
  }
  @Input() set showLogo(value: boolean) {
    this._showLogo = value;
    this.cd.markForCheck();
  }

  get isInitialized(): boolean {
    return this._isInitialized;
  }
}
