import { Injectable, InjectionToken } from '@angular/core';
import { Analytics, SharedAnalyticsService } from '@luggagehero/shared/services/analytics';
import { SharedStorageService } from '@luggagehero/shared/services/storage';
import { SharedTranslateLoader, SharedTranslateService } from '@luggagehero/shared/services/translation';
import { SharedWindowService } from '@luggagehero/shared/services/window';
import { Store } from '@ngrx/store';

import { ILang } from '../../core';
import { IAppState } from '../../ngrx/state/app.state';
import { ChangeAction } from '../actions';
import { CATEGORY } from '../common/category.common';
import { IMultilingualState, initialState } from '../states';

// provide supported languages at runtime
export const Languages: InjectionToken<Array<ILang>> = new InjectionToken('Languages');
export const LanguageProviders = [{ provide: Languages, useValue: [] }];

@Injectable()
export class MultilingualService extends Analytics {
  private serviceInitialized: Promise<void>;
  private requestedLangLoaded: Promise<void>;
  private requestedLang: string;
  private markServiceInitialized: () => void;
  private markRequestedLangLoaded: () => void;

  constructor(
    public analytics: SharedAnalyticsService,
    private translate: SharedTranslateService,
    private window: SharedWindowService,
    private storage: SharedStorageService,
    private store: Store<IAppState>,
  ) {
    super(analytics);

    this.category = CATEGORY;

    this.serviceInitialized = new Promise((resolve) => (this.markServiceInitialized = resolve));
    this.requestedLangLoaded = new Promise((resolve) => (this.markRequestedLangLoaded = resolve));

    SharedTranslateLoader.loadedLanguage$.subscribe((languages) => {
      void this.serviceInitialized.then(() => {
        if (languages.includes(this.requestedLang)) {
          this.markRequestedLangLoaded();
        }
      });
    });

    // This language will be used as a fallback when a translation isn't found in the current language
    translate.setDefaultLang(initialState.lang);

    // Subscribe to changes
    store.select((s) => s.i18n).subscribe((state: IMultilingualState) => this.setLang(state.lang));
  }

  public async init(params?: Record<string, string>): Promise<void> {
    // Get the language code from the URL or local storage, or use the browser's language as a fallback
    const language = params?.lang || this.storage.language || this.window.locale.split('-')[0];

    if (language && language.length === 2 && language !== initialState.lang) {
      // Record the requested language so we can resolve the promise when it's loaded
      this.requestedLang = language;

      // Request language change
      this.store.dispatch(new ChangeAction(language));
    } else {
      // Resolve when the initial language is loaded
      this.requestedLang = initialState.lang;
    }

    // Mark the service as initialized
    this.markServiceInitialized();

    // Wait for the requested language to be loaded
    await this.requestedLangLoaded;
  }

  private setLang(lang: string) {
    if (lang !== initialState.lang) {
      // Save the language to local storage
      this.storage.language = lang;
    }

    // Change the language
    this.translate.setLanguage(lang);
  }
}
