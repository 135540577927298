import { ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { AppConfig } from '@luggagehero/shared/app-settings/data-access';
import { Config } from '@luggagehero/shared/environment';
import { CheckoutParams, IPaymentRecord } from '@luggagehero/shared/interfaces';
import { SharedBookingService } from '@luggagehero/shared/services/bookings';
import { SharedErrorService } from '@luggagehero/shared/services/error';
import { SharedNotificationService } from '@luggagehero/shared/services/notification';
import { SharedPaymentService } from '@luggagehero/shared/services/payments';
import { SharedShopsService } from '@luggagehero/shared/services/shops';
import { SharedStorageService } from '@luggagehero/shared/services/storage';
import { TranslateService } from '@ngx-translate/core';

import { DateUtil } from '../../../../../utils/date.util';
import { PaymentOptionsBaseComponent } from '../../payment-options.base-component';
import { BookingActionBaseComponent } from './booking-action.base-component';

@Component({ template: '' })
export abstract class ShowReceiptActionBaseComponent extends BookingActionBaseComponent {
  @ViewChild('paymentOptions') paymentOptions: PaymentOptionsBaseComponent;

  private _paymentMethod: IPaymentRecord;
  private _paymentMethods: IPaymentRecord[];
  private _isPaymentOptionsVisible = false;

  constructor(
    private paymentService: SharedPaymentService,
    private translate: TranslateService,
    private notify: SharedNotificationService,
    private error: SharedErrorService,
    bookingService: SharedBookingService,
    shopsService: SharedShopsService,
    storageService: SharedStorageService,
    cd: ChangeDetectorRef,
  ) {
    super(bookingService, shopsService, storageService, cd);
  }

  get isPayDirectlyAllowed(): boolean {
    return this.shop?.directPaymentEnabled ? true : false;
  }

  get isDeferPaymentAvailable(): boolean {
    if (this.booking.paymentPostponedAt) {
      // Payment already posponed
      return false;
    }
    // TODO: Test that this works as intended with different timezones
    const minutesSinceCheckout = DateUtil.minutesBetween(this.booking.period.checkOut, new Date());

    if (!Config.isProduction) {
      console.log(`It has been ${minutesSinceCheckout} minutes since checkout`);
    }

    return minutesSinceCheckout <= AppConfig.HIDE_PAY_DIRECTLY_AFTER_MINUTES;
  }

  get isPaymentDue(): boolean {
    return ['CHECKED_IN', 'CHECKED_OUT'].includes(this.booking.status);
  }

  get newPaymentCardSelected(): boolean {
    if (!this.paymentOptions) {
      return false;
    }
    return this.paymentOptions.isNewPaymentMethodSelected;
  }

  get paymentMethod(): IPaymentRecord {
    return this._paymentMethod;
  }
  set paymentMethod(value: IPaymentRecord) {
    this._paymentMethod = value;
    void this.updatePaymentMethod();
  }

  get paymentMethods(): IPaymentRecord[] {
    return this._paymentMethods;
  }
  set paymentMethods(value: IPaymentRecord[]) {
    this._paymentMethods = value;
    if (!this.booking || !this.booking.paymentMethodId) {
      return;
    }
    for (let i = 0; i < this.paymentMethods.length; i++) {
      if (this.paymentMethods[i].id === this.booking.paymentMethodId) {
        this.paymentMethod = this.paymentMethods[i];
        break;
      }
    }

    if (!this.paymentMethod && this.paymentMethods.length > 0) {
      // Fall back to the first payment method available
      this.paymentMethod = this.paymentMethods[0];
    }
  }

  get isPaymentOptionsVisible(): boolean {
    return this._isPaymentOptionsVisible;
  }
  set isPaymentOptionsVisible(value: boolean) {
    this._isPaymentOptionsVisible = value;
    this.cd.markForCheck();
  }

  get isPaymentFailed(): boolean {
    return this.booking?.status === 'CHECKED_OUT';
  }

  get isDirectPaymentSelected(): boolean {
    if (!this.paymentMethod) {
      return false;
    }
    return this.paymentMethod.provider === 'direct_payment';
  }

  async onBaseInit() {
    try {
      // Bypass locally cached payment methods if payment has failed
      const noCache = this.booking.status === 'CHECKED_OUT';
      const res = await this.paymentService.getPaymentMethods(this.booking._id, noCache);
      this.paymentMethods = res || [];
    } catch (err) {
      // TODO: Do not show internal error messages to user
      // this.errorOccurred = true;
      // this.errorMessage = err;
    }
    this.isLoading = false;
  }

  async retryPayment() {
    this.isLoading = true;

    try {
      const params: CheckoutParams = {};

      if (this.paymentMethod.id !== this.booking.paymentMethodId) {
        // The currently selected payment method needs to set on the booking first
        params.paymentMethodId = this.paymentMethod.id;
      }

      // Try to check out and charge the payment
      const res = await this.bookingService.checkOut(this.booking._id, params);
      this.updateBooking(res);

      if (this.booking.status === 'PAID') {
        this.close();
      } else {
        this.notify.error(this.translate.instant('PAYMENT_FAILED') as string);
      }
    } catch (err) {
      this.error.handleError(err, 'Unable to complete payment');
    } finally {
      this.isLoading = false;
    }
  }

  async deferPayment(): Promise<void> {
    this.isLoading = true;

    try {
      const params: CheckoutParams = {};

      if (this.isPayDirectlyAllowed) {
        const directPaymentMethod = this.paymentMethods.find((p) => p.provider === 'direct_payment');
        if (!directPaymentMethod) {
          // This shouldn't happen
          return;
        }
        // Set direct payment method on booking
        params.paymentMethodId = directPaymentMethod.id;
      } else {
        // Postpone payment for booking
        params.postponePayment = true;
      }
      const res = await this.bookingService.checkOut(this.booking._id, params);

      this.updateBooking(res);
    } catch (err) {
      this.error.handleError(err, 'Unable to complete payment');
    } finally {
      this.isLoading = false;
    }
  }

  useAnotherPaymentMethod() {
    this.isPaymentOptionsVisible = true;
    this.paymentOptions.selectNewPaymentCard();
  }

  async updatePaymentMethod(): Promise<void> {
    if (this.paymentMethod.id === this.booking.paymentMethodId) {
      // No change in payment method
      return;
    }
    this.isLoading = true;

    try {
      const res = await this.bookingService.setPaymentMethod(this.booking._id, this.paymentMethod.id);

      // this.log.debug(`Payment method successfully set on booking`);
      this.updateBooking(res);

      await this.retryPayment();
    } catch (err) {
      // TODO: Handle errors
    }

    this.isLoading = false;
  }

  async done() {
    if (this.booking.state.showReceiptDone) {
      this.close();
      return;
    }

    this.isLoading = true;
    this.booking.state.showReceiptDone = true;

    try {
      const res = await this.bookingService.updateState(this.booking);
      this.updateBooking(res);
    } catch (err) {
      // TODO: Handle errors
    }

    this.isLoading = false;
    this.close();
  }
}
