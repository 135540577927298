import { Config } from '@luggagehero/shared/environment';
import {
  BankAccountCountryInfo,
  HowItWorksConfig,
  IFormFieldConfig,
  TagColorInfo,
} from '@luggagehero/shared/interfaces';

import { AppSettings, BookingConfig, IPhoneCountry, TimeFormatDetectionVersion } from './interfaces';

const APP_CONFIG_SETTING_PREFIX = 'AppConfig.';

/** @deprecated Use `SharedAppSettingsService` instead */
export class AppConfig {
  static [key: string]: unknown;

  private static authorizedBirthYearOptions: number[];

  /**
   * Parses an app settings object looking for overrides for the app config and replaces the relevant config values
   * where matches are found.
   *
   * @param appSettings The app settings object that may contain overrides for the app config
   * @returns void
   */
  public static override(appSettings: AppSettings): void {
    if (!appSettings) {
      return;
    }

    for (const settingName in appSettings) {
      const appConfigName = settingName.startsWith(APP_CONFIG_SETTING_PREFIX)
        ? settingName.substring(APP_CONFIG_SETTING_PREFIX.length)
        : settingName;

      // Get the value from the settings
      const appConfigValue = appSettings[settingName];

      // Check that the types match before replacing the config
      if (
        typeof AppConfig[appConfigName] === 'undefined' ||
        typeof AppConfig[appConfigName] === typeof appConfigValue
      ) {
        console.log(
          `Changing AppConfig.${appConfigName} from ${String(AppConfig[appConfigName])} to ${String(appConfigValue)}`,
        );

        // Override the local value in AppConfig with the one from the settings
        AppConfig[appConfigName] = appConfigValue;
      }
    }
  }

  public static NAME_REGEX =
    /^[a-zA-ZàáâäãåæąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅÆĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒČŠŽ∂ð ,.'-]+$/;
  public static EMAIL_REGEX =
    /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})/;
  public static PHONE_REGEX = /^(?:00|\+)[0-9\s./-]{6,20}$/;

  public static MAX_AUTHORIZED_BIRTH_YEAR = new Date().getFullYear() - 18;

  public static get AUTHORIZED_BIRTH_YEAR_OPTIONS(): number[] {
    if (!this.authorizedBirthYearOptions) {
      const years: number[] = [];
      let year = 1900;
      while (year <= AppConfig.MAX_AUTHORIZED_BIRTH_YEAR) {
        years.push(year);
        year++;
      }
      this.authorizedBirthYearOptions = years;
    }
    return this.authorizedBirthYearOptions;
  }

  public static ORDER_DEBOUNCE_TIME = 1000;
  public static UNIVERSAL_SEARCH_DEBOUNCE_TIME = 500;
  public static UNIVERSAL_MIN_SEARCH_QUERY_LENGTH = 3;

  public static DOB_MONTH_OPTIONS: { name: string; value: number }[] = [
    { name: 'JANUARY', value: 1 },
    { name: 'FEBRUARY', value: 2 },
    { name: 'MARCH', value: 3 },
    { name: 'APRIL', value: 4 },
    { name: 'MAY', value: 5 },
    { name: 'JUNE', value: 6 },
    { name: 'JULY', value: 7 },
    { name: 'AUGUST', value: 8 },
    { name: 'SEPTEMBER', value: 9 },
    { name: 'OCTOBER', value: 10 },
    { name: 'NOVEMBER', value: 11 },
    { name: 'DECEMBER', value: 12 },
  ];

  public static DOB_DAY_OPTIONS: { name: string; value: number }[] = [
    { name: '01', value: 1 },
    { name: '02', value: 2 },
    { name: '03', value: 3 },
    { name: '04', value: 4 },
    { name: '05', value: 5 },
    { name: '06', value: 6 },
    { name: '07', value: 7 },
    { name: '08', value: 8 },
    { name: '09', value: 9 },
    { name: '10', value: 10 },
    { name: '11', value: 11 },
    { name: '12', value: 12 },
    { name: '13', value: 13 },
    { name: '14', value: 14 },
    { name: '15', value: 15 },
    { name: '16', value: 16 },
    { name: '17', value: 17 },
    { name: '18', value: 18 },
    { name: '19', value: 19 },
    { name: '20', value: 20 },
    { name: '21', value: 21 },
    { name: '22', value: 22 },
    { name: '23', value: 23 },
    { name: '24', value: 24 },
    { name: '25', value: 25 },
    { name: '26', value: 26 },
    { name: '27', value: 27 },
    { name: '28', value: 28 },
    { name: '29', value: 29 },
    { name: '30', value: 30 },
    { name: '31', value: 31 },
  ];

  public static HERO_CODE_REDIRECT_URL =
    'https://help.luggagehero.com/en/articles/9531211-this-is-an-unclaimed-herocode';

  public static IS_OVERRIDE_WALLET_PAYMENT_ENABLED = true;
  public static IS_GOOGLE_PAY_ENABLED = true;
  public static IS_APPLE_PAY_ENABLED = true;
  public static IS_PAYPAL_ENABLED = false;
  public static IS_GOOGLE_SIGN_IN_ENABLED = true;
  public static IS_FACEBOOK_SIGN_IN_ENABLED = true;
  public static IS_APPLE_SIGN_IN_ENABLED = true;
  public static IS_PHONE_SIGN_IN_ENABLED = true;
  public static IS_TIPPING_ENABLED = true;
  public static IS_BIG_TIP_DISABLED = true;
  public static IS_TABBED_NAVIGATION_ENABLED = false;
  public static IS_UNDO_DROPOFF_ENABLED = false;
  public static IS_BOOKING_MIN_BAGS_ENABLED = false;
  public static IS_DROP_OFF_MIN_BAGS_ENABLED = false;
  public static IS_REQUIRED_TAGS_PHOTO_VALIDATION = true;

  public static MAX_ITEMS_IN_COLLAPSED_TAG_LIST = 2;

  public static MAX_TIP_FACTOR_ESTIMATED_TOTAL = 2;
  public static MAX_TIP_FACTOR_HOURLY_RATE = 20;

  public static DKK_TIP_CONVERSION_RATE = 7; // 7 dkk to 1 eur/gpb/usd
  public static SEK_TIP_CONVERSION_RATE = 11; // 11 sek to 1 eur/gpb/usd
  public static NOK_TIP_CONVERSION_RATE = 11; // 11 nok to 1 eur/gpb/usd

  // HACK: Set a hard limit on tips to avoid abuse
  public static TIP_LIMIT = 50; // 50 eur/gbp/usd
  public static TIP_LIMIT_DKK = AppConfig.TIP_LIMIT * AppConfig.DKK_TIP_CONVERSION_RATE;
  public static TIP_LIMIT_SEK = AppConfig.TIP_LIMIT * AppConfig.SEK_TIP_CONVERSION_RATE;
  public static TIP_LIMIT_NOK = AppConfig.TIP_LIMIT * AppConfig.NOK_TIP_CONVERSION_RATE;

  public static MAX_DISTANCE_TO_SHOW_RECOMMENDED_SHOP_FIRST = 500;

  public static MIN_OPENS_AT_FOR_OPEN_LATE = 10 * 60;
  public static MIN_CLOSES_AT_FOR_OPEN_LATE = 21 * 60;
  public static MIN_AVERAGE_RATING_FOR_HIGHLY_RATED = 4.7;
  public static MIN_NUMBER_OF_RATINGS_FOR_HIGHLY_RATED = 10;

  public static ABANDON_CART_FEEDBACK_MIN_DELAY_AFTER_PROMPT = 3 * 60 * 1000; // 3 minutes
  public static ABANDON_CART_FEEDBACK_MIN_DELAY_AFTER_RESPONSE = 15 * 60 * 1000; // 15 minutes

  public static BOOKING_DEFAULT_NUMBER_OF_BAGS = 2;
  public static BOOKING_MIN_BAGS = 2;
  public static DROP_OFF_DEFAULT_NUMBER_OF_BAGS = 1;
  public static DROP_OFF_MIN_BAGS = 2;
  // Set this to 0 or null/undefined to apply the storage location's own max capacity
  public static DROP_OFF_MAX_BAGS = 50;

  public static THEME_COLOR_DARK_PERCENTAGE = 15;
  public static THEME_COLOR_LIGHT_PERCENTAGE = 15;

  public static SHOP_MAP_MARKER_SIZE_DOWNSCALE_FACTOR = 0.75;
  public static SHOP_MAP_MAX_ZOOM = 15;
  public static DEFAULT_SHOP_DETAILS_MAP_ZOOM = 15;
  public static MAX_NUMBER_OF_POIS_TO_DISPLAY = 3;
  public static HIDE_PAY_DIRECTLY_AFTER_MINUTES = 10;
  public static HIDE_DROPOFF_CONFIRMATION_AFTER_MINUTES = 15;

  /** The maximum time to wait for the Ionic platform to be ready before continuing with the app initialization */
  public static IONIC_PLATFORM_READY_TIMEOUT = 10 * 1000;

  // Disable specific UI elements
  public static IS_REALTIME_AVAILABILITY_ENABLED = true;
  public static IS_NO_SCREENSHOT_TICKER_ENABLED = false;
  public static IS_EXTERNAL_REVIEW_DISABLED = false;
  public static IS_SHOW_RECOMMENDATIONS_WITH_REVIEWS_ENABLED = true;
  public static MIN_SATISFACTION_TO_SHOW_WITH_REVIEWS = 2;
  public static IS_DISABLE_UNAVAILABLE_CALENDAR_DATES_ENABLED = true;
  public static IS_SHOP_IMAGE_BANNER_DISABLED = false;
  public static IS_SHOP_IMAGE_CERTIFIED_WATERMARK_DISABLED = true;
  public static IS_SHOP_IMAGE_HEADER_DISABLED = true;
  public static IS_SHOP_WALK_IN_CTA_DISABLED = true;
  public static IS_SHOP_PRICE_DISABLED = true;
  public static IS_SHOP_LIST_FILTER_DISABLED = true;
  public static IS_FULL_STAR_RATING_DISABLED = true;
  public static IS_SHOP_DETAILS_MAP_CTA_DISABLED = false;
  public static IS_SHOP_LIST_DISTANCE_UNIT_TOGGLE_DISABLED = true;
  public static IS_SHOP_LIST_PRICING_MODEL_TOGGLE_DISABLED = false;
  public static IS_SHOP_LIST_ASYNC_PRICING_DISABLED = false;
  public static IS_SHOP_URGENCY_DISABLED = false;
  public static IS_SHOP_MAP_MAX_ZOOM_DISABLED = true;
  public static IS_SHOP_MAP_DIRECTIONS_DISABLED = true;
  public static IS_SHOP_LIST_SORT_UNAVAILABLE_LAST_ENABLED = false;
  public static IS_SHOP_LIST_SHOW_RECOMMENDED_SHOP_FIRST_ENABLED = true;
  public static IS_SHOP_LIST_FILTER_BY_HOTELS_ENABLED = false;
  public static IS_SHOP_LIST_FILTER_BY_OPEN_24_HOURS_ENABLED = true;
  public static IS_SHOP_LIST_FILTER_BY_MULTIDAY_STORAGE_ENABLED = true;
  public static IS_SHOP_LIST_FILTER_BY_WIFI_AVAILABLE_ENABLED = true;
  public static IS_MODERN_TRAVELER_BOOKING_LIST_ENABLED = true;
  public static IS_ORDER_SUMMARY_TOTAL_ALERT_ENABLED = true;
  public static IS_ORDER_SUMMARY_ON_BOOKING_TIMELINE_ENABLED = true;
  public static IS_ORDER_SUMMARY_ON_BOOK_SHOP_ENABLED = false;
  public static IS_ORDER_SUMMARY_INLINE_INSURANCE_COVERAGE_ENABLED = false;
  public static IS_ORDER_SUMMARY_SIMPLE_INSURANCE_COVERAGE_ENABLED = true;
  public static IS_ORDER_SUMMARY_HIDE_CANCELED_ORDER_LINES_ENABLED = true;
  public static IS_STORAGE_TIMER_IN_DROPOFF_ACTION_ENABLED = true;
  public static IS_STORAGE_TIMER_IN_PICKUP_ACTION_ENABLED = true;
  public static IS_STORAGE_LOCATION_TYPE_ENABLED = false;
  public static IS_HIDE_UNAVAILABLE_STORAGE_LOCATIONS_ENABLED = false;
  public static IS_HERO_LEVEL_TRANSLATION_ENABLED = false;
  public static IS_SLIDE_TO_CONFIRM_ENABLED = true;
  public static IS_PROMO_CODE_ALERT_ENABLED = false;
  public static IS_COMPACT_MODE_ON_CONFIRM_BOOKING_ENABLED = true;
  public static IS_PRICE_ON_PRICING_MODEL_SELECTOR_ENABLED = true;
  public static IS_GLOBAL_AVERAGE_RATING_ON_SIMPLE_SHOP_ITEM_ENABLED = false;
  public static IS_OPEN_LATE_BADGE_ON_SIMPLE_SHOP_ITEM_ENABLED = true;
  public static IS_ALWAYS_OPEN_BADGE_ON_SIMPLE_SHOP_ITEM_ENABLED = true;
  public static IS_HIGHLY_RATED_BADGE_ON_SIMPLE_SHOP_ITEM_ENABLED = true;
  public static IS_DROPOFF_CONFIRMATION_ACTION_ENABLED = true;
  public static IS_PRICING_MODEL_SELECTOR_FOR_GUESTS_ENABLED = true;

  public static IS_OPEN_LATE_BADGE_ON_MANAGE_SHOP_ITEM_ENABLED = false;
  public static IS_ALWAYS_OPEN_BADGE_ON_MANAGE_SHOP_ITEM_ENABLED = false;
  public static IS_HIGHLY_RATED_BADGE_ON_MANAGE_SHOP_ITEM_ENABLED = true;

  public static IS_LUGGAGEHERO_BRAND_IN_SHOP_TITLE_ENABLED = true;
  public static IS_SUPERHERO_TERMINOLOGY_ENABLED = true;

  public static IS_STORAGE_MANAGER_PROFILE_COMPLETENESS_ENABLED = true;
  public static IS_STORAGE_MANAGER_ACTIVITY_STATS_ENABLED = true;
  public static IS_STORAGE_MANAGER_DASHBOARD_ALERTS_ENABLED = true;
  public static IS_STORAGE_MANAGER_BOOKING_AMOUNT_ENABLED = true;
  public static IS_STORAGE_MANAGER_BOOKING_ACTIONS_ENABLED = false;
  public static IS_STORAGE_MANAGER_SAVE_WITHOUT_PAYOUT_ENABLED = true;
  public static IS_STORAGE_MANAGER_SELECT_PAYOUT_COUNTRY_ENABLED = false;

  public static IS_BOOKING_ACTION_MODAL_AUTO_SCROLL_ENABLED = false;

  public static DEFAULT_STORAGE_LOCATION_MIN_CAPACITY_NORMAL = 0;
  public static DEFAULT_STORAGE_LOCATION_MAX_CAPACITY_NORMAL = 1000;
  public static DEFAULT_STORAGE_LOCATION_MIN_CAPACITY_HAND = 0;
  public static DEFAULT_STORAGE_LOCATION_MAX_CAPACITY_HAND = 1000;
  public static DEFAULT_MIN_STORAGE_LOCATION_OPEN_HOURS = 0;
  public static STRIPE_PAYPAL_NATIVE_RETURN_URL = 'luggagehero://stripe-paypal-redirect';

  public static OPENING_SOON_IN_MINUTES = 30;
  public static CLOSING_SOON_IN_MINUTES = 60;
  public static IS_OPENING_AND_CLOSING_SOON_TIME_ONLY_ENABLED = true;

  // Here to allow overriding from the server side app settings
  public static OPTIONAL_PRODUCTS_SELECTED_BY_DEFAULT: string[] = [
    // Example: enabling the SMS Notifications product by default
    // 'SMS_COMM_FEE',
  ];

  // TODO: Load this from API
  public static GLOBAL_AVERAGE_RATING = 4.7;

  public static IN_STORE_QR_SHORT_LINKS: { [shopId: string]: string } = {
    '627a2eb80f569a0a8c682fc7': 'lughe.ro/jw',
  };

  public static SHOPS_WITH_HOURLY_RATE_DISABLED: string[] = [
    '6688026d66da0860e6e2714f', // Danhostel Copenhagen City (guest)
  ];
  public static SHOPS_WITH_LUGGAGE_IMAGE_EXAMPLE_ENABLED: string[] = [
    // '627a2eb80f569a0a8c682fc7', // JW Marriott
  ];
  public static SHOPS_WITH_ALL_TIME_REPORTING_DISABLED = [
    '5b1ffd2a5dac500005bde027', // Best Western Premier
  ];
  public static SHOPS_WITH_LUGGAGE_IMAGE_REQUIRED: string[] = [
    '627a2eb80f569a0a8c682fc7', // JW Marriott
  ];
  public static SHOPS_WITH_NEW_DROPOFF_UI_ENABLED: string[] = [
    '5e4a653ee64e5d000336dc49', // LuggageHero @TechBBQ
  ];
  public static SHOPS_WITH_LUGGAGE_IMAGE_BEFORE_PICKUP: string[] = [
    '5e4a653ee64e5d000336dc49', // LuggageHero @TechBBQ
  ];
  public static DUMMY_LUGGAGE_IMAGE = '72b302a0-6ea2-f6e0-5334-9e44a4585717.jpg';
  public static IS_LUGGAGE_IMAGE_ON_DROPOFF_CONFIRMATION_STEP_ENABLED = true;
  public static IS_USER_CONFIRMATION_OF_SECURITY_TAGS_ENABLED = true;
  public static IS_USER_CONFIRMATION_OF_NO_BAGS_CHANGE_ENABLED = false;

  public static IS_EMBEDDED_CAMERA_ENABLED = true;
  public static IS_CAMERA_SWITCHING_ENABLED = false;
  public static IS_CAMERA_LOADER_ENABLED = false;
  public static IS_IMAGE_COMPRESSION_ENABLED = true;
  public static IMAGE_COMPRESSION_QUALITY = 90;
  public static IMAGE_COMPRESSION_RATIO = 100;
  public static IMAGE_COMPRESSION_MAX_WIDTH = 1800;
  public static IMAGE_COMPRESSION_MAX_HEIGHT: number;

  public static STANDARD_SECURITY_SEAL_COLORS: TagColorInfo[] = [
    { hex: '#FFFFFF', rgb: { R: 255, G: 255, B: 255 }, name: 'white' },
    // { hex: '#FF0000', rgb: { R: 255, G: 0, B: 0 }, name: 'red' },
    { hex: '#008000', rgb: { R: 0, G: 128, B: 0 }, name: 'green' },
    { hex: '#0000FF', rgb: { R: 0, G: 0, B: 255 }, name: 'blue' },
    // Using a darker orange which more closely resembles the orange security seals that JW Marriott uses
    { hex: '#FF4500', rgb: { R: 255, G: 69, B: 0 }, name: 'orange' },
    // { hex: '#FFA500', rgb: { R: 255, G: 165, B: 0 }, name: 'orange' },
    { hex: '#FFFF00', rgb: { R: 255, G: 255, B: 0 }, name: 'yellow' },
    { hex: '#800080', rgb: { R: 128, G: 0, B: 128 }, name: 'purple' },
  ];

  /** Colors to use for user avatar backgrounds similar to what Google does with their accounts */
  public static PROFILE_BACKGROUND_COLORS = [
    '#F44336', // Red
    '#E91E63', // Pink
    '#9C27B0', // Purple
    '#673AB7', // Deep Purple
    '#3F51B5', // Indigo
    '#2196F3', // Blue
    '#03A9F4', // Light Blue
    '#00BCD4', // Cyan
    '#009688', // Teal
    '#4CAF50', // Green
    '#8BC34A', // Light Green
    '#CDDC39', // Lime
    '#FFC107', // Amber
    '#FF9800', // Orange
    '#FF5722', // Deep Orange
    '#795548', // Brown
    '#9E9E9E', // Grey
    '#607D8B', // Blue Grey
  ];

  // Used to disable chat in Ionic
  public static IS_CHAT_ENABLED = true;
  public static IS_CHAT_ON_CONTACT_LINK_ENABLED = false;
  public static IS_INTERCOM_ENABLED = true;
  public static IS_PUSH_ENABLED = false;

  public static APPLE_PAY_MERCHANT_ID = 'merchant.com.luggagehero.traveler';
  public static MERCHANT_COUNTRY_CODE = 'DK';
  public static MERCHANT_CURRENCY = 'eur';
  public static PAYMENT_VERIFICATION_LABEL = 'LUGGAGEHERO_PAYMENT_VERIFICATION_LABEL'; // Translation key

  // Used to enable/disable active storage location marker in maps view
  public static ACTIVE_STORAGE_LOCATION_MARKER_NOTICE = ''; // activate marker notice when set to a string or translation key string. Disable with empty string or null
  public static ACTIVE_STORAGE_LOCATION_MARKER_NOTICE_MIN_ZOOM_LEVEL = 16; // minimum zoom level before we show the notice

  // Used to enable tax settings for storage managers based on shop country
  public static STORAGE_MANAGER_TAX_SETTINGS_ENABLED_COUNTRIES = ['us'];
  public static STORAGE_MANAGER_ENABLE_TAX_FEATURE = false;

  public static TIME_FORMAT_DETECTION_VERSION: TimeFormatDetectionVersion = 2;

  /**
   * When enabled, skips setting `Accept` and `Content-Type` headers to `application/json` on http requests. Only here
   * for testing backwards compatibility on the APIs. Remove when we are sure the APIs are fully backwards compatible.
   */
  public static LEGACY_HTTP_MODE = false;

  // TODO: Fetch this from API instead
  public static CURRENT_TOS_DATE = new Date('2020-01-01T12:00:00');

  public static TEXT_VARIANTS: { [variant: string]: { [key: string]: string } } = {
    /** LuggageHero TechBBQ */
    '5e4a653ee64e5d000336dc49': {
      ATTACH_SECURITY_SEAL_BEFORE_PROCEEDING: 'GET_A_SECURITY_SEAL_AND_ATTACH_IT',
      TAKE_PHOTO_OF_SECURITY_SEALS: 'TAKE_PHOTO_OF_SECURITY_SEALS_ON_YOUR_BAG',
      DROPOFF_TAKE_A_PHOTO_OF_YOUR_LUGGAGE_PART_2: 'NUMBER_MUST_BE_READABLE_IN_PICTURE',
      HAND_OVER_YOUR_LUGGAGE: 'STORE_YOUR_LUGGAGE',
    },
  };

  public static EXPERIMENT_VARIANTS = {
    original: 'a',
    dailyPriceNotPaidUpFront: 'q',
    hourlyInsurance: 'r',
    securitySealAddOn: 's',
    noSecuritySealCommunication: 't',
    multiDayStorage: 'u',
    freeCancellationAddOn: 'v',
    skipLogin: 'w',
    orderSummaryHeader: 'x',
    mandatoryServiceFee: 'y',
    newOrderSummary: 'z',
    timerOnPickup: 'aa',
    noShopUrgency: 'ab',
    freeCancellationBanner: 'ac',
    homelessEntrepreneur: 'hent',
    paymentCardValidation: 'ad',
    heroLevelBanner: 'ae',
    simpleShopItem: 'af',
    minBags: 'ag',
    travelerMapFirst: '66fd8b1eef1aa8507eca4d4e',
    newGuestDropoffFlow: '6713a3124c6e357329a72f95',
    newFindStorageView: '67371161557ead28f2a4236b',
    shopDetailsUSP: '67d2c6976db353207701c180',
    premiumInsuranceCoverage: '67dc2cf76db353207701ca98',
    noMinBags: '67da96d4d0cf5bc17180c86c',
  };

  public static DEFAULT_AUTO_GROUP_DISCOUNT_MIN_BAGS = 5;
  public static DEFAULT_AUTO_GROUP_DISCOUNT_CODE = 'GROUPSAVER';

  // TODO: This should be part of the pricing managed server side
  public static DEFAULT_SERVICE_FEE_PRICING = {
    usd: 0.95,
    eur: 0.95,
    gbp: 0.82,
    dkk: 7.0,
    sek: 11.0,
    nok: 11.0,
  };

  public static DEFAULT_BOOKING_CONFIG: BookingConfig = {
    experiencesEnabled: true,
    paymentInfoRequiredBeforeDirections: true,
    paymentInfoRequiredBeforeDropoff: true,
  };

  public static CITIES_WITH_EXPERIENCES_ENABLED = [];
  public static CITIES_WITH_EXPERIENCES_AUTO_PROMPTED = [];

  public static GYG_CITY_TOURS: {
    [city: string]: { id: number; title: string; ranking: number }[];
  } = {
    ROME: [
      {
        id: 62214,
        ranking: 1,
        title: 'Vatican Museums & Sistine Chapel Fast-Track Ticket Options',
      },
      { id: 47164, ranking: 4, title: 'Reserved Entrance: Saint Peter Basilica Self-Guided Tour' },
      { id: 7725, ranking: 5, title: 'Skip the Line: Colosseum and Ancient Rome Walking Tour' },
      {
        id: 212586,
        ranking: 10,
        title: 'Vatican Museums & Sistine Chapel: Skip-the-Line & Audioguide',
      },
      {
        id: 1881,
        ranking: 11,
        title: 'Skip the Line: Colosseum, Forum & Ancient Rome Guided Tour',
      },
      {
        id: 50675,
        ranking: 13,
        title: 'Rome Hop-on Hop-off: 1-Day, 24, 48 or 72-Hours Bus Ticket',
      },
      { id: 175128, ranking: 15, title: 'Skip-the-Line Vatican, Sistine Chapel, St. Peter’s Tour' },
      { id: 68896, ranking: 17, title: 'Roma Pass: 48 or 72-Hour City Card' },
    ],
    NICE: [
      { id: 68769, ranking: 1, title: 'Nice: 1 or 2-Day Hop-On Hop-Off Bus Tour' },
      { id: 48542, ranking: 2, title: 'Discover Nice: 1-Hour Guided Segway tour' },
      { id: 114805, ranking: 6, title: 'French Riviera: Marineland Entrance Ticket' },
      { id: 142470, ranking: 9, title: 'Nice: Perfume Creation Workshop' },
      { id: 64997, ranking: 11, title: 'Nice: Guided City Tour by Electric Vélotaxi' },
      { id: 72671, ranking: 12, title: 'From Nice: French Riviera by Open-Top Car' },
      { id: 196583, ranking: 14, title: 'Nice Bar Crawl' },
      { id: 181433, ranking: 18, title: 'Nice: Digital Self-Guided Sightseeing Tour' },
    ],
    NEW_YORK_CITY: [
      { id: 109024, ranking: 1, title: '9/11 Memorial & Museum Admission: Skip the Ticket Line' },
      { id: 120169, ranking: 2, title: 'NYC: Priority or Flexible Statue of Liberty Ticket' },
      { id: 52132, ranking: 3, title: 'One World Observatory Ticket: Skip-the-Ticket-Line' },
      {
        id: 6195,
        ranking: 4,
        title: 'Empire State Building General & Skip-the-Line Ticket Options',
      },
      { id: 37483, ranking: 5, title: 'Top of the Rock Observation Deck: Flexible Date Ticket' },
      {
        id: 89139,
        ranking: 6,
        title: '60-Minute Cruise Around the Statue of Liberty & Ellis Island',
      },
      { id: 5721, ranking: 7, title: 'NYC Explorer Pass: Over 85 Tours and Attractions' },
      {
        id: 111601,
        ranking: 10,
        title: 'New York: Museum of Modern Art (MoMA) Skip-the-Line Tickets',
      },
    ],
    MONTREAL: [
      { id: 24634, ranking: 2, title: 'Hop-On Hop-Off Double-Decker 2-Day Tour' },
      { id: 26693, ranking: 3, title: 'Montreal: St. Lawrence River Sightseeing Guided Cruise' },
      { id: 155673, ranking: 4, title: 'Montreal: 1-Hour St. Lawrence Sightseeing Cruise' },
      { id: 100397, ranking: 5, title: 'Montréal Tower Observatory and Botanical Garden' },
      { id: 25171, ranking: 8, title: 'Old Montreal: Traditional Ghost Walk of the Haunted City' },
      { id: 188301, ranking: 13, title: 'Montreal: Place Ville Marie Observatory Ticket' },
      { id: 50444, ranking: 15, title: 'Montreal: 1.5-Hour Underground City Walking Tour' },
      { id: 167525, ranking: 20, title: 'Montreal Old Port: Urban Zipline Skip-the-Line Ticket' },
    ],
    MADRID: [
      { id: 32264, ranking: 1, title: 'Skip-the-Line Prado Museum Entrance Ticket' },
      { id: 1251, ranking: 2, title: 'Madrid City Tour (Hop-On Hop-Off Bus Tour)' },
      { id: 194657, ranking: 3, title: 'Madrid: Bernabéu Stadium Tour with Direct Access Tickets' },
      { id: 117453, ranking: 5, title: 'Madrid: Skip-the-Line Guided Tour of the Royal Palace' },
      { id: 72810, ranking: 7, title: 'Madrid Royal Palace 90-Minute Guided Tour' },
      { id: 158268, ranking: 14, title: 'Wanda Metropolitano Stadium Tour' },
      { id: 7965, ranking: 15, title: 'Skip the Line: New Madrid Free Walking Tour' },
      { id: 131916, ranking: 16, title: 'Prado Museum Skip-the-Line Guided Tour' },
    ],
    LONDON: [
      { id: 16403, ranking: 1, title: 'Harry Potter: Warner Brothers Studio Tour and Transfers' },
      { id: 21253, ranking: 2, title: 'Tower of London Ticket with Crown Jewels Exhibition' },
      { id: 174648, ranking: 3, title: 'Magical London: Harry Potter Guided Walking Tour' },
      {
        id: 24625,
        ranking: 6,
        title: 'The View from the Shard Entrance Ticket & Optional Champagne',
      },
      { id: 655, ranking: 9, title: 'The Original London Hop-On Hop-Off Sightseeing Bus Tour' },
      { id: 5273, ranking: 12, title: 'River Thames Hop-On Hop-Off Sightseeing Cruise' },
      { id: 12486, ranking: 14, title: 'Guided Wembley Stadium Tour' },
      { id: 170451, ranking: 19, title: 'Coca-Cola London Eye' },
    ],
    LISBON: [
      { id: 27232, ranking: 1, title: 'Lisbon Card: 24-, 48-, and 72-Hour Options' },
      { id: 38079, ranking: 4, title: 'Lisbon Oceanarium Entrance Ticket' },
      { id: 1021, ranking: 5, title: 'Lisbon Sightseeing Hop-On Hop-Off Tour' },
      { id: 39551, ranking: 6, title: 'Lisbon: Free Walking Tour' },
      { id: 131169, ranking: 8, title: 'Jerónimos Monastery: Entrance Ticket' },
      { id: 137750, ranking: 10, title: 'Belém Tower Fast Track Entrance Ticket' },
      { id: 58028, ranking: 11, title: 'Lisbon: Luz Stadium Tour and SL Benfica Museum Ticket' },
      { id: 52218, ranking: 13, title: 'Lisbon: 2.5-Hour Hills Tour by Electric Bike' },
    ],
    COPENHAGEN: [
      {
        id: 37848,
        ranking: 1,
        title: 'Copenhagen: 1-Hour Canal Cruise from Ved Stranden or Nyhavn',
      },
      { id: 21328, ranking: 2, title: 'Copenhagen free 2.5-hour Sightseeing Tour' },
      { id: 72048, ranking: 3, title: 'Copenhagen: Tivoli Gardens Skip-the-Line Entry Ticket' },
      { id: 66660, ranking: 4, title: 'Copenhagen City Card (including transportation)' },
      { id: 127388, ranking: 5, title: 'Copenhagen Tivoli Gardens 1-Day Unlimited Rides Ticket' },
      { id: 21339, ranking: 6, title: 'Copenhagen Bus and Boat Hop-On, Hop-Off 48-Hour Pass' },
      { id: 78025, ranking: 7, title: 'Copenhagen Red Hop-on Hop-off Bus and Boat' },
      { id: 142758, ranking: 8, title: 'Copenhagen: 48-Hour Hop-On Hop-Off Boat Ticket' },
    ],
    CHICAGO: [
      { id: 19830, ranking: 1, title: 'Chicago: Skydeck & The Ledge Experience Ticket' },
      { id: 46389, ranking: 2, title: 'Chicago River Architecture Cruise: Skip-the-Ticket-Office' },
      {
        id: 31619,
        ranking: 4,
        title: '360 Chicago Observation Deck Admission: Skip the Ticket Desk',
      },
      { id: 46393, ranking: 5, title: 'Chicago Classic Lake Tour: 40-Minute Sightseeing Cruise' },
      { id: 40483, ranking: 6, title: 'Chicago: 90-Minute Mob and Crime Bus Tour' },
      { id: 5613, ranking: 7, title: 'Go Chicago Card: Save up to 55% on top attractions' },
      { id: 65119, ranking: 9, title: 'Chicago: Hop-on Hop-off Open Top Bus Tour' },
      { id: 71207, ranking: 10, title: 'Chicago Explorer Pass: Over 25 Tours and Attractions' },
    ],
    BRUSSELS: [
      { id: 71219, ranking: 1, title: 'Brussels Atomium Admission Ticket' },
      {
        id: 12738,
        ranking: 2,
        title: 'Brussels Hop-On Hop-Off Guided Sightseeing - 24h/48h-Ticket',
      },
      { id: 74947, ranking: 3, title: 'Brussels Mini-Europe Admission Ticket' },
      { id: 46955, ranking: 4, title: 'Brussels 2.5-Hour Belgian Beer Tasting Experience' },
      { id: 77074, ranking: 5, title: 'Choco-Story Brussels Museum Entrance' },
      { id: 172767, ranking: 6, title: 'Brussels Chocolate Tasting Tour' },
      { id: 79467, ranking: 9, title: 'Brussels Autoworld Museum Admission Ticket' },
      {
        id: 43870,
        ranking: 18,
        title: 'Brussels: Express Tour with Belgian Lunch, Chocolate, & Beer',
      },
    ],
    BOSTON: [
      { id: 51989, ranking: 1, title: 'Boston: New England Aquarium Skip- the-Line Ticket' },
      { id: 52676, ranking: 2, title: 'Harvard: 70-Minute Hahvahd Tour' },
      { id: 48797, ranking: 3, title: 'Boston Duck Tour: The Original and World-Famous' },
      { id: 16853, ranking: 5, title: 'Boston: Hop-On, Hop-Off Trolley 1-Day Ticket' },
      { id: 15344, ranking: 6, title: 'Old Town Trolley Tour of Boston' },
      { id: 148535, ranking: 8, title: 'New England Aquarium/IMAX Combo - Save up to 13%' },
      { id: 7207, ranking: 12, title: 'Boston Harbor USS Constitution Cruise' },
      { id: 5599, ranking: 13, title: 'Go Boston Card: Save on Top Attractions' },
    ],
    BARCELONA: [
      { id: 50000, ranking: 1, title: 'Hola BCN: 2,3,4 or 5-Day Public Transport Ticket' },
      { id: 132132, ranking: 2, title: 'Sagrada Familia Skip-the-Line Guided Tour' },
      { id: 61515, ranking: 4, title: 'Barcelona Aquarium: Skip-the-Line Admission Ticket' },
      { id: 1225, ranking: 6, title: 'Hop-On Hop-Off Barcelona City Tour 1 or 2 Day' },
      {
        id: 1227,
        ranking: 7,
        title: 'Camp Nou Tour: F.C. Barcelona Open Date Ticket (Ticket Only)',
      },
      { id: 22219, ranking: 15, title: 'Barcelona Card: 3, 4, or 5 Days with Discounts' },
      { id: 51441, ranking: 16, title: 'Fast-Track Guided Tour: Sagrada Familia with Towers' },
      { id: 24943, ranking: 19, title: 'Barcelona Zoo Tickets' },
    ],
  };

  public static INITIAL_PHONE_COUNTRY_CODE = Config.isDevelopment ? 'dk' : 'gb';
  public static INITIAL_PHONE_DIAL_CODE = '+1';
  public static PREFERRED_PHONE_COUNTRIES = ['gb', 'us'];

  public static SUPPORTED_PHONE_COUNTRIES: IPhoneCountry[] = [
    { name: 'United States', dialCode: '+1', countryCode: 'US' },
    { name: 'United Kingdom', dialCode: '+44', countryCode: 'UK' },
    { name: 'Denmark', dialCode: '+45', countryCode: 'DK' },
    { name: 'Malta', dialCode: '+356', countryCode: 'MT' },
  ];

  /**
   * List of countries in which bank account based payouts can be set up. If e.g. Stripe adds support for a new
   * country, it will not be available for users to select until added here as well.
   *
   * The field label configuration for each country in this list determines how the form to collect bank account
   * information is generated. For example, the following country config will generate a form with a single input field
   * for an IBAN number:
   *
   * ```
   * {
   *   countryCode: 'DK',
   *   name: 'DENMARK',
   *   accountNumberLabel: 'IBAN',
   * }
   * ```
   *
   * Whereas the following will generate a form with two input fields for account number and routing number
   * respectively:
   *
   * ```
   * {
   *   countryCode: 'US',
   *   name: 'UNITED_STATES',
   *   accountNumberLabel: 'Account number',
   *   routingNumberLabel: 'Routing number',
   * }
   * ```
   */
  public static SUPPORTED_BANK_ACCOUNT_COUNTRIES: BankAccountCountryInfo[] = [
    { countryCode: 'AE', name: 'UNITED_ARAB_EMIRATES', accountNumberLabel: 'IBAN' },
    { countryCode: 'AG', name: 'ANTIGUA_AND_BARBUDA', accountNumberLabel: 'STRIPE_BANK_ACCOUNT_NUMBER_LABEL_AG' },
    { countryCode: 'AL', name: 'ALBANIA', accountNumberLabel: 'STRIPE_BANK_ACCOUNT_NUMBER_LABEL_AL' },
    { countryCode: 'AM', name: 'ARMENIA', accountNumberLabel: 'STRIPE_BANK_ACCOUNT_NUMBER_LABEL_AM' },
    { countryCode: 'AO', name: 'ANGOLA', accountNumberLabel: 'STRIPE_BANK_ACCOUNT_NUMBER_LABEL_AO' },
    { countryCode: 'AR', name: 'ARGENTINA', accountNumberLabel: 'STRIPE_BANK_ACCOUNT_NUMBER_LABEL_AR' },
    {
      countryCode: 'AT',
      name: 'AUSTRIA',
      accountNumberLabel: 'STRIPE_BANK_ACCOUNT_NUMBER_LABEL_AT', // "IBAN"
    },
    {
      countryCode: 'AU',
      name: 'AUSTRALIA',
      accountNumberLabel: 'STRIPE_BANK_ACCOUNT_NUMBER_LABEL_AU', // "Account number"
      routingNumberLabel: 'STRIPE_BANK_ROUTING_NUMBER_LABEL_AU', // "BSB"
    },
    { countryCode: 'AZ', name: 'AZERBAIJAN', accountNumberLabel: 'STRIPE_BANK_ACCOUNT_NUMBER_LABEL_AZ' },
    { countryCode: 'BA', name: 'BOSNIA_AND_HERZEGOVINA', accountNumberLabel: 'STRIPE_BANK_ACCOUNT_NUMBER_LABEL_BA' },
    { countryCode: 'BD', name: 'BANGLADESH', accountNumberLabel: 'STRIPE_BANK_ACCOUNT_NUMBER_LABEL_BD' },
    {
      countryCode: 'BE',
      name: 'BELGIUM',
      accountNumberLabel: 'STRIPE_BANK_ACCOUNT_NUMBER_LABEL_BE', // "IBAN"
    },
    { countryCode: 'BG', name: 'BULGARIA', accountNumberLabel: 'IBAN' },
    { countryCode: 'BH', name: 'BAHRAIN', accountNumberLabel: 'STRIPE_BANK_ACCOUNT_NUMBER_LABEL_BH' },
    { countryCode: 'BJ', name: 'BENIN', accountNumberLabel: 'STRIPE_BANK_ACCOUNT_NUMBER_LABEL_BJ' },
    { countryCode: 'BN', name: 'BRUNEI', accountNumberLabel: 'STRIPE_BANK_ACCOUNT_NUMBER_LABEL_BN' },
    { countryCode: 'BO', name: 'BOLIVIA', accountNumberLabel: 'STRIPE_BANK_ACCOUNT_NUMBER_LABEL_BO' },
    // { // Brazil is currently not supported by Stripe US platform
    //   countryCode: 'BR',
    //   name: 'BRAZIL',
    //   accountNumberLabel: 'Account number', // TODO: Translate this
    //   bankCodeLabel: 'Bank code', // TODO: Translate this
    //   branchCodeLabel: 'Branch code', // TODO: Translate this
    // },
    { countryCode: 'BS', name: 'BAHAMAS', accountNumberLabel: 'STRIPE_BANK_ACCOUNT_NUMBER_LABEL_BS' },
    { countryCode: 'BT', name: 'BHUTAN', accountNumberLabel: 'STRIPE_BANK_ACCOUNT_NUMBER_LABEL_BT' },
    { countryCode: 'BW', name: 'BOTSWANA', accountNumberLabel: 'STRIPE_BANK_ACCOUNT_NUMBER_LABEL_BW' },
    {
      countryCode: 'CA',
      name: 'CANADA',
      accountNumberLabel: 'STRIPE_BANK_ACCOUNT_NUMBER_LABEL_CA', // "Account number"
      bankCodeLabel: 'STRIPE_BANK_BANK_CODE_LABEL_CA', // "Transit number" external_account.bank_code
      branchCodeLabel: 'STRIPE_BANK_BRANCH_CODE_LABEL_CA', // "Institution number" external_account.branch_code
    },
    {
      countryCode: 'CH',
      name: 'SWITZERLAND',
      accountNumberLabel: 'STRIPE_BANK_ACCOUNT_NUMBER_LABEL_CH', // "IBAN"
    },
    { countryCode: 'CI', name: 'COTE_DIVOIRE', accountNumberLabel: 'STRIPE_BANK_ACCOUNT_NUMBER_LABEL_CI' },
    { countryCode: 'CL', name: 'CHILE', accountNumberLabel: 'STRIPE_BANK_ACCOUNT_NUMBER_LABEL_CL' },
    { countryCode: 'CO', name: 'COLOMBIA', accountNumberLabel: 'STRIPE_BANK_ACCOUNT_NUMBER_LABEL_CO' },
    { countryCode: 'CR', name: 'COSTA_RICA', accountNumberLabel: 'STRIPE_BANK_ACCOUNT_NUMBER_LABEL_CR' },
    { countryCode: 'CY', name: 'CYPRUS', accountNumberLabel: 'IBAN' },
    {
      countryCode: 'CZ',
      name: 'CZECH_REPUBLIC',
      accountNumberLabel: 'STRIPE_BANK_ACCOUNT_NUMBER_LABEL_CZ', // "IBAN"
    },
    {
      countryCode: 'DE',
      name: 'GERMANY',
      accountNumberLabel: 'STRIPE_BANK_ACCOUNT_NUMBER_LABEL_DE', // "IBAN"
    },
    {
      countryCode: 'DK',
      name: 'DENMARK',
      accountNumberLabel: 'STRIPE_BANK_ACCOUNT_NUMBER_LABEL_DK', // "IBAN"
    },
    { countryCode: 'DO', name: 'DOMINICAN_REPUBLIC', accountNumberLabel: 'STRIPE_BANK_ACCOUNT_NUMBER_LABEL_DO' },
    { countryCode: 'DZ', name: 'ALGERIA', accountNumberLabel: 'STRIPE_BANK_ACCOUNT_NUMBER_LABEL_DZ' },
    { countryCode: 'EC', name: 'ECUADOR', accountNumberLabel: 'STRIPE_BANK_ACCOUNT_NUMBER_LABEL_EC' },
    { countryCode: 'EE', name: 'ESTONIA', accountNumberLabel: 'IBAN' },
    { countryCode: 'EG', name: 'EGYPT', accountNumberLabel: 'STRIPE_BANK_ACCOUNT_NUMBER_LABEL_EG' },
    {
      countryCode: 'ES',
      name: 'SPAIN',
      accountNumberLabel: 'STRIPE_BANK_ACCOUNT_NUMBER_LABEL_ES', // "IBAN"
    },
    { countryCode: 'ET', name: 'ETHIOPIA', accountNumberLabel: 'STRIPE_BANK_ACCOUNT_NUMBER_LABEL_ET' },
    {
      countryCode: 'FI',
      name: 'FINLAND',
      accountNumberLabel: 'STRIPE_BANK_ACCOUNT_NUMBER_LABEL_FI', // "IBAN"
    },
    {
      countryCode: 'FR',
      name: 'FRANCE',
      accountNumberLabel: 'STRIPE_BANK_ACCOUNT_NUMBER_LABEL_FR', // "IBAN"
    },
    { countryCode: 'GA', name: 'GABON', accountNumberLabel: 'STRIPE_BANK_ACCOUNT_NUMBER_LABEL_GA' },
    {
      countryCode: 'GB',
      name: 'UNITED_KINGDOM',
      accountNumberLabel: 'STRIPE_BANK_ACCOUNT_NUMBER_LABEL_GB', // "Account number"
      routingNumberLabel: 'STRIPE_BANK_ROUTING_NUMBER_LABEL_GB', // "Sort code"
    },
    { countryCode: 'GH', name: 'GHANA', accountNumberLabel: 'STRIPE_BANK_ACCOUNT_NUMBER_LABEL_GH' },
    { countryCode: 'GI', name: 'GIBRALTAR', accountNumberLabel: 'IBAN' },
    { countryCode: 'GM', name: 'GAMBIA', accountNumberLabel: 'STRIPE_BANK_ACCOUNT_NUMBER_LABEL_GM' },
    {
      countryCode: 'GR',
      name: 'GREECE',
      accountNumberLabel: 'STRIPE_BANK_ACCOUNT_NUMBER_LABEL_GR', // "IBAN"
    },
    { countryCode: 'GT', name: 'GUATEMALA', accountNumberLabel: 'STRIPE_BANK_ACCOUNT_NUMBER_LABEL_GT' },
    { countryCode: 'GY', name: 'GUYANA', accountNumberLabel: 'STRIPE_BANK_ACCOUNT_NUMBER_LABEL_GY' },
    {
      countryCode: 'HK',
      name: 'HONG_KONG',
      accountNumberLabel: 'Account number', // TODO: Translate this
      bankCodeLabel: 'Clearing code', // TODO: Translate this
      branchCodeLabel: 'Branch code', // TODO: Translate this
    },
    { countryCode: 'HR', name: 'CROATIA', accountNumberLabel: 'IBAN' },
    { countryCode: 'HU', name: 'HUNGARY', accountNumberLabel: 'IBAN' },
    { countryCode: 'ID', name: 'INDONESIA', accountNumberLabel: 'STRIPE_BANK_ACCOUNT_NUMBER_LABEL_ID' },
    {
      countryCode: 'IE',
      name: 'IRELAND',
      accountNumberLabel: 'STRIPE_BANK_ACCOUNT_NUMBER_LABEL_IE', // "IBAN"
    },
    { countryCode: 'IL', name: 'ISRAEL', accountNumberLabel: 'STRIPE_BANK_ACCOUNT_NUMBER_LABEL_IL' },
    // { // India is currently not supported by Stripe US platform
    //   countryCode: 'IN',
    //   name: 'INDIA',
    //   accountNumberLabel: 'Account number', // TODO: Translate this
    //   routingNumberLabel: 'IFSC Code', // TODO: Translate this
    // },
    { countryCode: 'IS', name: 'ICELAND', accountNumberLabel: 'STRIPE_BANK_ACCOUNT_NUMBER_LABEL_IS' },
    {
      countryCode: 'IT',
      name: 'ITALY',
      accountNumberLabel: 'STRIPE_BANK_ACCOUNT_NUMBER_LABEL_IT', // "IBAN"
    },
    { countryCode: 'JM', name: 'JAMAICA', accountNumberLabel: 'STRIPE_BANK_ACCOUNT_NUMBER_LABEL_JM' },
    { countryCode: 'JO', name: 'JORDAN', accountNumberLabel: 'STRIPE_BANK_ACCOUNT_NUMBER_LABEL_JO' },
    {
      //
      // Stripe currently only supports ordinary accounts (普通預金) for bank accounts in Japan.
      //
      // When using the API to create a bank account, combine the bank code and branch code to create a full routing
      // number (for example, 0123456). Also required is the name of the person or business that owns the bank account;
      // use the account_holder_name attribute for this. If the bank account holder name contains kanji characters, you
      // need to replace them with katakana.
      //
      // Source: https://stripe.com/docs/connect/payouts-bank-accounts
      //
      countryCode: 'JP',
      name: 'JAPAN',
      accountNumberLabel: 'Account number', // TODO: Translate this
      bankCodeLabel: 'Bank code', // TODO: Translate this
      branchCodeLabel: 'Branch code', // TODO: Translate this
      //
      // TODO: Account owner name is also required for Japan, and bank name and branch name are also listed by Stripe.
      // We would need to add support for this to our bank account form in order to onboard Japanese partners.
      //
      // accountOwnerLabel: 'Account owner name',
      // bankNameLabel: 'Bank name',
      // branchNameLabel: 'Branch name',
    },
    { countryCode: 'KE', name: 'KENYA', accountNumberLabel: 'STRIPE_BANK_ACCOUNT_NUMBER_LABEL_KE' },
    { countryCode: 'KH', name: 'CAMBODIA', accountNumberLabel: 'STRIPE_BANK_ACCOUNT_NUMBER_LABEL_KH' },
    { countryCode: 'KR', name: 'SOUTH_KOREA', accountNumberLabel: 'STRIPE_BANK_ACCOUNT_NUMBER_LABEL_KR' },
    { countryCode: 'KW', name: 'KUWAIT', accountNumberLabel: 'STRIPE_BANK_ACCOUNT_NUMBER_LABEL_KW' },
    { countryCode: 'KZ', name: 'KAZAKHSTAN', accountNumberLabel: 'STRIPE_BANK_ACCOUNT_NUMBER_LABEL_KZ' },
    { countryCode: 'LA', name: 'LAOS', accountNumberLabel: 'STRIPE_BANK_ACCOUNT_NUMBER_LABEL_LA' },
    { countryCode: 'LC', name: 'SAINT_LUCIA', accountNumberLabel: 'STRIPE_BANK_ACCOUNT_NUMBER_LABEL_LC' },
    { countryCode: 'LI', name: 'LIECHTENSTEIN', accountNumberLabel: 'IBAN' },
    { countryCode: 'LK', name: 'SRI_LANKA', accountNumberLabel: 'STRIPE_BANK_ACCOUNT_NUMBER_LABEL_LK' },
    { countryCode: 'LT', name: 'LITHUANIA', accountNumberLabel: 'IBAN' },
    {
      countryCode: 'LU',
      name: 'LUXEMBOURG',
      accountNumberLabel: 'STRIPE_BANK_ACCOUNT_NUMBER_LABEL_LU', // "IBAN"
    },
    { countryCode: 'LV', name: 'LATVIA', accountNumberLabel: 'IBAN' },
    { countryCode: 'MA', name: 'MOROCCO', accountNumberLabel: 'STRIPE_BANK_ACCOUNT_NUMBER_LABEL_MA' },
    { countryCode: 'MC', name: 'MONACO', accountNumberLabel: 'STRIPE_BANK_ACCOUNT_NUMBER_LABEL_MC' },
    { countryCode: 'MD', name: 'MOLDOVA', accountNumberLabel: 'STRIPE_BANK_ACCOUNT_NUMBER_LABEL_MD' },
    { countryCode: 'MG', name: 'MADAGASCAR', accountNumberLabel: 'STRIPE_BANK_ACCOUNT_NUMBER_LABEL_MG' },
    { countryCode: 'MK', name: 'NORTH_MACEDONIA', accountNumberLabel: 'STRIPE_BANK_ACCOUNT_NUMBER_LABEL_MK' },
    { countryCode: 'MN', name: 'MONGOLIA', accountNumberLabel: 'STRIPE_BANK_ACCOUNT_NUMBER_LABEL_MN' },
    { countryCode: 'MO', name: 'MACAU', accountNumberLabel: 'STRIPE_BANK_ACCOUNT_NUMBER_LABEL_MO' },
    { countryCode: 'MT', name: 'MALTA', accountNumberLabel: 'IBAN' },
    { countryCode: 'MU', name: 'MAURITIUS', accountNumberLabel: 'STRIPE_BANK_ACCOUNT_NUMBER_LABEL_MU' },
    {
      countryCode: 'MX',
      name: 'MEXICO',
      accountNumberLabel: 'CLABE', // TODO: Translate this
    },
    {
      countryCode: 'MY',
      name: 'MALAYSIA',
      accountNumberLabel: 'Account number', // TODO: Translate this
      routingNumberLabel: 'BIC', // TODO: Translate this
      //
      // TODO: Bank name is also listed by Stripe as a field to collect for Malaysia. We would likely need to add
      // support for this to our bank account form in order to onboard Malaysian partners. Also need to double check
      // that BIC needs to be sent as routing number.
      //
      // Source: https://stripe.com/docs/connect/payouts-bank-accounts
      //
      // bankNameLabel: 'Bank name',
    },
    { countryCode: 'MZ', name: 'MOZAMBIQUE', accountNumberLabel: 'STRIPE_BANK_ACCOUNT_NUMBER_LABEL_MZ' },
    { countryCode: 'NA', name: 'NAMIBIA', accountNumberLabel: 'STRIPE_BANK_ACCOUNT_NUMBER_LABEL_NA' },
    { countryCode: 'NE', name: 'NIGER', accountNumberLabel: 'STRIPE_BANK_ACCOUNT_NUMBER_LABEL_NE' },
    { countryCode: 'NG', name: 'NIGERIA', accountNumberLabel: 'STRIPE_BANK_ACCOUNT_NUMBER_LABEL_NG' },
    {
      countryCode: 'NL',
      name: 'NETHERLANDS',
      accountNumberLabel: 'STRIPE_BANK_ACCOUNT_NUMBER_LABEL_NL', // "IBAN"
    },
    {
      countryCode: 'NO',
      name: 'NORWAY',
      accountNumberLabel: 'STRIPE_BANK_ACCOUNT_NUMBER_LABEL_NO', // "IBAN"
    },
    {
      countryCode: 'NZ',
      name: 'NEW_ZEALAND',
      accountNumberLabel: 'STRIPE_BANK_ACCOUNT_NUMBER_LABEL_NZ', // "Account number"
    },
    { countryCode: 'OM', name: 'OMAN', accountNumberLabel: 'STRIPE_BANK_ACCOUNT_NUMBER_LABEL_OM' },
    { countryCode: 'PA', name: 'PANAMA', accountNumberLabel: 'STRIPE_BANK_ACCOUNT_NUMBER_LABEL_PA' },
    { countryCode: 'PE', name: 'PERU', accountNumberLabel: 'STRIPE_BANK_ACCOUNT_NUMBER_LABEL_PE' },
    { countryCode: 'PH', name: 'PHILIPPINES', accountNumberLabel: 'STRIPE_BANK_ACCOUNT_NUMBER_LABEL_PH' },
    { countryCode: 'PK', name: 'PAKISTAN', accountNumberLabel: 'STRIPE_BANK_ACCOUNT_NUMBER_LABEL_PK' },
    {
      countryCode: 'PL',
      name: 'POLAND',
      accountNumberLabel: 'STRIPE_BANK_ACCOUNT_NUMBER_LABEL_PL', // "IBAN"
    },
    {
      countryCode: 'PT',
      name: 'PORTUGAL',
      accountNumberLabel: 'STRIPE_BANK_ACCOUNT_NUMBER_LABEL_PT', // "IBAN"
    },
    { countryCode: 'PY', name: 'PARAGUAY', accountNumberLabel: 'STRIPE_BANK_ACCOUNT_NUMBER_LABEL_PY' },
    { countryCode: 'QA', name: 'QATAR', accountNumberLabel: 'STRIPE_BANK_ACCOUNT_NUMBER_LABEL_QA' },
    { countryCode: 'RO', name: 'ROMANIA', accountNumberLabel: 'IBAN' },
    { countryCode: 'RS', name: 'SERBIA', accountNumberLabel: 'STRIPE_BANK_ACCOUNT_NUMBER_LABEL_RS' },
    { countryCode: 'RW', name: 'RWANDA', accountNumberLabel: 'STRIPE_BANK_ACCOUNT_NUMBER_LABEL_RW' },
    { countryCode: 'SA', name: 'SAUDI_ARABIA', accountNumberLabel: 'STRIPE_BANK_ACCOUNT_NUMBER_LABEL_SA' },
    {
      countryCode: 'SE',
      name: 'SWEDEN',
      accountNumberLabel: 'STRIPE_BANK_ACCOUNT_NUMBER_LABEL_SE', // "IBAN"
    },
    {
      countryCode: 'SG',
      name: 'SINGAPORE',
      accountNumberLabel: 'Account number', // TODO: Translate this
      bankCodeLabel: 'Bank code', // TODO: Translate this
      branchCodeLabel: 'Branch code', // TODO: Translate this
    },
    { countryCode: 'SI', name: 'SLOVENIA', accountNumberLabel: 'IBAN' },
    { countryCode: 'SK', name: 'SLOVAKIA', accountNumberLabel: 'IBAN' },
    { countryCode: 'SM', name: 'SAN_MARINO', accountNumberLabel: 'STRIPE_BANK_ACCOUNT_NUMBER_LABEL_SM' },
    { countryCode: 'SN', name: 'SENEGAL', accountNumberLabel: 'STRIPE_BANK_ACCOUNT_NUMBER_LABEL_SN' },
    { countryCode: 'SV', name: 'EL_SALVADOR', accountNumberLabel: 'STRIPE_BANK_ACCOUNT_NUMBER_LABEL_SV' },
    {
      countryCode: 'TH',
      name: 'THAILAND',
      accountNumberLabel: 'Account number', // TODO: Translate this
      bankCodeLabel: 'Bank code', // TODO: Translate this
      //
      // TODO: Bank name is also listed by Stripe as a field to collect for Thailand. We would likely need to add
      // support for this to our bank account form in order to onboard Thai partners.
      //
      // Source: https://stripe.com/docs/connect/payouts-bank-accounts
      //
      // bankNameLabel: 'Bank name',
    },
    { countryCode: 'TN', name: 'TUNISIA', accountNumberLabel: 'STRIPE_BANK_ACCOUNT_NUMBER_LABEL_TN' },
    { countryCode: 'TR', name: 'TURKEY', accountNumberLabel: 'STRIPE_BANK_ACCOUNT_NUMBER_LABEL_TR' },
    { countryCode: 'TT', name: 'TRINIDAD_AND_TOBAGO', accountNumberLabel: 'STRIPE_BANK_ACCOUNT_NUMBER_LABEL_TT' },
    { countryCode: 'TW', name: 'TAIWAN', accountNumberLabel: 'STRIPE_BANK_ACCOUNT_NUMBER_LABEL_TW' },
    { countryCode: 'TZ', name: 'TANZANIA', accountNumberLabel: 'STRIPE_BANK_ACCOUNT_NUMBER_LABEL_TZ' },
    {
      countryCode: 'US',
      name: 'UNITED_STATES',
      accountNumberLabel: 'STRIPE_BANK_ACCOUNT_NUMBER_LABEL_US', // "Account number"
      routingNumberLabel: 'STRIPE_BANK_ROUTING_NUMBER_LABEL_US', // "Routing number"
    },
    { countryCode: 'UY', name: 'URUGUAY', accountNumberLabel: 'STRIPE_BANK_ACCOUNT_NUMBER_LABEL_UY' },
    { countryCode: 'UZ', name: 'UZBEKISTAN', accountNumberLabel: 'STRIPE_BANK_ACCOUNT_NUMBER_LABEL_UZ' },
    { countryCode: 'VN', name: 'VIETNAM', accountNumberLabel: 'STRIPE_BANK_ACCOUNT_NUMBER_LABEL_VN' },
    { countryCode: 'ZA', name: 'SOUTH_AFRICA', accountNumberLabel: 'STRIPE_BANK_ACCOUNT_NUMBER_LABEL_ZA' },
  ];

  public static STRIPE_CONNECT_ACCOUNT_FORM_FIELDS: IFormFieldConfig[] = [
    {
      formControlName: 'country',
      fullyQualifiedName: 'country',
      mandatory: true,
      type: 'select',
      options: [],
      label: 'COUNTRY',
      placeholder: 'STRIPE_ACCOUNT_COUNTRY',
    },
    {
      formControlName: 'type',
      fullyQualifiedName: 'type',
      mandatory: true,
      hidden: true,
    },
    {
      formControlName: 'email',
      fullyQualifiedName: 'email',
      mandatory: true,
      hidden: true,
    },
    {
      formControlName: 'external_account',
      fullyQualifiedName: 'external_account',
      hidden: true,
    },
    {
      formControlName: 'type',
      fullyQualifiedName: 'legal_entity.type',
      mandatory: true,
      hidden: true,
      type: 'select',
      options: [
        { name: 'STRIPE_ACCOUNT_LEGAL_ENTITY_TYPE_COMPANY', value: 'company' },
        { name: 'STRIPE_ACCOUNT_LEGAL_ENTITY_TYPE_INDIVIDUAL', value: 'individual' },
      ],
      value: 'company',
    },
    {
      formControlName: 'additional_owners',
      fullyQualifiedName: 'legal_entity.additional_owners',
      hidden: true,
    },
    {
      formControlName: 'date',
      fullyQualifiedName: 'tos_acceptance.date',
      hidden: true,
    },
    {
      formControlName: 'ip',
      fullyQualifiedName: 'tos_acceptance.ip',
      hidden: true,
    },
    {
      formControlName: 'type',
      fullyQualifiedName: 'legal_entity.type',
      hidden: true,
    },
    {
      formControlName: 'business_name',
      fullyQualifiedName: 'legal_entity.business_name',
      hidden: false,
      type: 'input',
      label: 'STRIPE_ACCOUNT_FORM_GROUP_BUSINESS',
      placeholder: 'STRIPE_ACCOUNT_LEGAL_ENTITY_BUSINESS_NAME',
    },
    {
      formControlName: 'business_tax_id',
      fullyQualifiedName: 'legal_entity.business_tax_id',
      hidden: false,
      type: 'input',
      // label: 'STRIPE_ACCOUNT_FORM_GROUP_BUSINESS',
      placeholder: 'STRIPE_ACCOUNT_LEGAL_ENTITY_BUSINESS_TAX_ID',
    },
    {
      formControlName: 'line1',
      fullyQualifiedName: 'legal_entity.address.line1',
      hidden: false,
      type: 'input',
      label: 'STRIPE_ACCOUNT_FORM_GROUP_COMPANY_ADDRESS',
      placeholder: 'STRIPE_ACCOUNT_LEGAL_ENTITY_ADDRESS_LINE_1',
    },
    {
      formControlName: 'line2',
      fullyQualifiedName: 'legal_entity.address.line2',
      attachTo: 'legal_entity.address.line1',
      hidden: false,
      type: 'input',
      // label: 'STRIPE_ACCOUNT_FORM_GROUP_COMPANY_ADDRESS',
      placeholder: 'STRIPE_ACCOUNT_LEGAL_ENTITY_ADDRESS_LINE_2',
    },
    {
      formControlName: 'city',
      fullyQualifiedName: 'legal_entity.address.city',
      hidden: false,
      type: 'input',
      // label: 'STRIPE_ACCOUNT_FORM_GROUP_COMPANY_ADDRESS',
      placeholder: 'STRIPE_ACCOUNT_LEGAL_ENTITY_ADDRESS_CITY',
    },
    {
      formControlName: 'postal_code',
      fullyQualifiedName: 'legal_entity.address.postal_code',
      hidden: false,
      type: 'input',
      // label: 'STRIPE_ACCOUNT_FORM_GROUP_COMPANY_ADDRESS',
      placeholder: 'STRIPE_ACCOUNT_LEGAL_ENTITY_ADDRESS_POSTAL_CODE',
    },
    {
      formControlName: 'state',
      fullyQualifiedName: 'legal_entity.address.state',
      hidden: false,
      type: 'input',
      // label: 'STRIPE_ACCOUNT_FORM_GROUP_COMPANY_ADDRESS',
      placeholder: 'STRIPE_ACCOUNT_LEGAL_ENTITY_ADDRESS_STATE',
    },
    {
      formControlName: 'first_name',
      fullyQualifiedName: 'legal_entity.first_name',
      hidden: false,
      type: 'input',
      label: 'STRIPE_ACCOUNT_FORM_GROUP_REPRESENTATIVE',
      placeholder: 'STRIPE_ACCOUNT_LEGAL_ENTITY_FIRST_NAME',
    },
    {
      formControlName: 'last_name',
      fullyQualifiedName: 'legal_entity.last_name',
      hidden: false,
      type: 'input',
      // label: 'STRIPE_ACCOUNT_FORM_GROUP_REPRESENTATIVE',
      placeholder: 'STRIPE_ACCOUNT_LEGAL_ENTITY_LAST_NAME',
    },
    {
      formControlName: 'ssn_last_4',
      fullyQualifiedName: 'legal_entity.ssn_last_4',
      hidden: false,
      type: 'input',
      // label: 'STRIPE_ACCOUNT_FORM_GROUP_REPRESENTATIVE',
      placeholder: 'STRIPE_ACCOUNT_LEGAL_ENTITY_SSN_LAST4',
    },
    {
      formControlName: 'personal_id_number',
      fullyQualifiedName: 'legal_entity.personal_id_number',
      hidden: false,
      type: 'input',
      // label: 'STRIPE_ACCOUNT_FORM_GROUP_REPRESENTATIVE',
      placeholder: 'STRIPE_ACCOUNT_LEGAL_ENTITY_PERSONAL_ID_NUMBER',
    },
    {
      formControlName: 'day',
      fullyQualifiedName: 'legal_entity.dob.day',
      hidden: false,
      type: 'select',
      options: AppConfig.DOB_DAY_OPTIONS,
      label: 'STRIPE_ACCOUNT_FORM_GROUP_REPRESENTATIVE_DOB',
      placeholder: 'STRIPE_ACCOUNT_LEGAL_ENTITY_DOB_DAY',
    },
    {
      formControlName: 'month',
      fullyQualifiedName: 'legal_entity.dob.month',
      hidden: false,
      type: 'select',
      options: AppConfig.DOB_MONTH_OPTIONS,
      // label: 'STRIPE_ACCOUNT_FORM_GROUP_REPRESENTATIVE_DOB',
      placeholder: 'STRIPE_ACCOUNT_LEGAL_ENTITY_DOB_MONTH',
    },
    {
      formControlName: 'year',
      fullyQualifiedName: 'legal_entity.dob.year',
      hidden: false,
      type: 'select',
      options: AppConfig.AUTHORIZED_BIRTH_YEAR_OPTIONS,
      // label: 'STRIPE_ACCOUNT_FORM_GROUP_REPRESENTATIVE_DOB',
      placeholder: 'STRIPE_ACCOUNT_LEGAL_ENTITY_DOB_YEAR',
    },
    {
      formControlName: 'line1',
      fullyQualifiedName: 'legal_entity.personal_address.line1',
      hidden: false,
      type: 'input',
      label: 'STRIPE_ACCOUNT_FORM_GROUP_REPRESENTATIVE_ADDRESS',
      placeholder: 'STRIPE_ACCOUNT_LEGAL_ENTITY_PERSONAL_ADDRESS_LINE_1',
    },
    {
      formControlName: 'line2',
      fullyQualifiedName: 'legal_entity.personal_address.line2',
      attachTo: 'legal_entity.personal_address.line1',
      hidden: false,
      type: 'input',
      // label: 'STRIPE_ACCOUNT_FORM_GROUP_REPRESENTATIVE_ADDRESS',
      placeholder: 'STRIPE_ACCOUNT_LEGAL_ENTITY_PERSONAL_ADDRESS_LINE_2',
    },
    {
      formControlName: 'city',
      fullyQualifiedName: 'legal_entity.personal_address.city',
      hidden: false,
      type: 'input',
      // label: 'STRIPE_ACCOUNT_FORM_GROUP_REPRESENTATIVE_ADDRESS',
      placeholder: 'STRIPE_ACCOUNT_LEGAL_ENTITY_PERSONAL_ADDRESS_CITY',
    },
    {
      formControlName: 'postal_code',
      fullyQualifiedName: 'legal_entity.personal_address.postal_code',
      hidden: false,
      type: 'input',
      // label: 'STRIPE_ACCOUNT_FORM_GROUP_REPRESENTATIVE_ADDRESS',
      placeholder: 'STRIPE_ACCOUNT_LEGAL_ENTITY_PERSONAL_ADDRESS_POSTAL_CODE',
    },
    {
      formControlName: 'state',
      fullyQualifiedName: 'legal_entity.personal_address.state',
      hidden: false,
      type: 'input',
      // label: 'STRIPE_ACCOUNT_FORM_GROUP_REPRESENTATIVE_ADDRESS',
      placeholder: 'STRIPE_ACCOUNT_LEGAL_ENTITY_PERSONAL_ADDRESS_STATE',
    },
  ];

  public static ENABLE_STRIPE_STANDARD_ACCOUNTS = false;

  public static SHOW_EXAMPLE_S3_KEY = '7d05242e-2c48-4787-8455-2c1cc36414c2.jpg';

  public static STRIPE_ELEMENTS_FONT_URL = '/assets/fonts/eina/3517B1_2_0.woff2';

  public static HOW_IT_WORKS_DEFAULT: HowItWorksConfig = {
    intro: {
      inline: true,
      headline: 'LUGGAGE_STORAGE_IN_A_NEW_WAY',
      tagline: 'WE_USE_LOCAL_SHOPS',
    },
    slides: [
      {
        image: {
          url: 'assets/how-it-works/default/how-it-works-default_01.jpg',
          round: true,
          frame: true,
        },
        text: {
          header: 'BOOK_ONLINE',
          body: 'CHOOSE_LOCATION_ADDRESS_DIRECTIONS_AFTER',
        },
      },
      {
        image: {
          url: 'assets/how-it-works/default/how-it-works-default_03.jpg',
          round: true,
          frame: true,
        },
        text: {
          header: 'START_STORAGE_TIMER_ON_ARRIVAL',
          body: 'USE_LINK_AND_START_STORAGE_TIMER',
        },
      },
      // {
      //   image: {
      //     url: 'assets/how-it-works/default/how-it-works-default_02.jpg',
      //     round: true,
      //     frame: true,
      //   },
      //   text: {
      //     header: 'APPLY_SECURITY_SEALS',
      //     body: 'APPLY_LUGGAGEHERO_SECURITY_SEALS',
      //   },
      // },
      {
        image: {
          url: 'assets/how-it-works/default/how-it-works-default_05.jpg',
          round: true,
          frame: true,
        },
        text: {
          header: 'STOP_THE_STORAGE_TIMER',
          body: 'WHEN_PICKING_UP_STOP_THE_STORAGE_TIMER',
        },
      },
      {
        image: {
          url: 'assets/how-it-works/default/how-it-works-default_04.jpg',
          round: true,
          frame: true,
        },
        text: {
          header: 'ONLY_PAY_FOR_THE_TIME_SPENT',
          body: 'STORAGE_TIMER_ENSURES_YOU_PAY_ONLY_FOR_HOURS_SPENT',
        },
      },
      {
        image: {
          url: 'assets/how-it-works/default/how-it-works-default_06.jpg',
          round: true,
          frame: true,
        },
        text: {
          header: 'YOUR_LUGGAGE_IS_HANDED_BACK',
          body: 'SHOW_PICKUP_CONFIRMATION_TO_RECLAIM',
        },
      },
    ],
  };
  public static HOW_IT_WORKS_WALK_IN: HowItWorksConfig = {
    intro: {
      inline: false,
      headline: 'HOW_IT_WORKS',
      tagline: 'STORE_YOUR_BAGS_SECURELY',
    },
    slides: [
      // {
      //   image: {
      //     url: 'assets/how-it-works/walk-in/luggagehero_2019_instore-booking_flow_1.jpg',
      //     round: false,
      //     frame: true
      //   },
      //   text: {
      //     header: 'USE_YOUR_SMARTPHONE',
      //     body: 'ALL_BOOKINGS_AND_PAYMENTS_HANDLED_ONLINE'
      //   }
      // },
      {
        image: {
          url: 'assets/how-it-works/walk-in/luggagehero_2019_instore-booking_flow_2.jpg',
          round: false,
          frame: true,
        },
        text: {
          header: 'START_THE_STORAGE_TIMER',
          body: 'PROVIDE_EMAIL_AND_PAYMENT_METHOD',
        },
      },
      // {
      //   image: {
      //     url: 'assets/how-it-works/walk-in/luggagehero_2019_instore-booking_flow_3.jpg',
      //     round: false,
      //     frame: true,
      //   },
      //   text: {
      //     header: 'APPLY_SECURITY_SEALS',
      //     body: 'APPLY_LUGGAGEHERO_SECURITY_SEALS',
      //   },
      // },
      // {
      //   image: {
      //     url: 'assets/how-it-works/mobile-app/how-it-works-native_03b.jpg',
      //     round: true,
      //     frame: true
      //   },
      //   text: {
      //     header: 'HAND_OVER_YOUR_LUGGAGE',
      //     body: 'HAND_YOUR_BAGS_OVER_TO_THE_STAFF'
      //   }
      // },
      {
        image: {
          url: 'assets/how-it-works/walk-in/luggagehero_2019_instore-booking_flow_4.jpg',
          round: false,
          frame: true,
        },
        text: {
          header: 'STOP_THE_STORAGE_TIMER',
          body: 'SHOW_PICKUP_CONFIRMATION',
        },
      },
      {
        image: {
          url: 'assets/how-it-works/walk-in/luggagehero_2019_instore-booking_flow_5.jpg',
          round: false,
          frame: true,
        },
        text: {
          header: 'ONLY_PAY_FOR_THE_TIME_SPENT',
          body: 'YOU_ARE_CHARGED_ONLY_FOR_STORAGE_HOURS',
        },
      },
    ],
  };

  public static HOW_IT_WORKS_MOBILE_APP: HowItWorksConfig = {
    intro: {
      inline: false,
      headline: 'HOW_IT_WORKS',
      tagline: 'HOW_IT_WORKS_NATIVE_INTRO_TAGLINE',
    },
    slides: [
      {
        image: null, // TODO: Provide image for this step
        text: {
          header: 'MAKE_A_BOOKING',
          body: 'CHOOSE_STORAGE_LOCATION_AND_MAKE_A_BOOKING',
        },
      },
      {
        image: {
          url: 'assets/how-it-works/mobile-app/how-it-works-native_03.jpg',
          round: true,
          frame: true,
        },
        text: {
          header: 'START_THE_STORAGE_TIMER',
          body: 'START_THE_STORAGE_TIMER_IN_THE_APP',
        },
      },
      // {
      //   image: {
      //     url: 'assets/how-it-works/mobile-app/how-it-works-native_02.jpg',
      //     round: true,
      //     frame: true,
      //   },
      //   text: {
      //     header: 'APPLY_SECURITY_SEALS',
      //     body: 'APPLY_LUGGAGEHERO_SECURITY_SEALS',
      //   },
      // },
      {
        image: {
          url: 'assets/how-it-works/mobile-app/how-it-works-native_03b.jpg',
          round: true,
          frame: true,
        },
        text: {
          header: 'HAND_OVER_YOUR_LUGGAGE',
          body: 'HAND_YOUR_BAGS_OVER_TO_THE_STAFF',
        },
      },
      {
        image: {
          url: 'assets/how-it-works/mobile-app/how-it-works-native_05.jpg',
          round: true,
          frame: true,
        },
        text: {
          header: 'STOP_THE_STORAGE_TIMER',
          body: 'STOP_THE_STORAGE_TIMER_IN_THE_APP',
        },
      },
      {
        image: {
          url: 'assets/how-it-works/mobile-app/how-it-works-native_04.jpg',
          round: true,
          frame: true,
        },
        text: {
          header: 'ONLY_PAY_FOR_THE_TIME_SPENT',
          body: 'STORAGE_TIMER_ENSURES_YOU_PAY_ONLY_FOR_HOURS_SPENT',
        },
      },
      {
        image: {
          url: 'assets/how-it-works/mobile-app/how-it-works-native_06.jpg',
          round: true,
          frame: true,
        },
        text: {
          header: 'YOUR_LUGGAGE_IS_HANDED_BACK',
          body: 'SHOW_PICKUP_CONFIRMATION_IN_THE_APP_TO_RECLAIM',
        },
      },
    ],
  };

  public static IS_MAPS_LOCATION_OBFUSCATION_ENABLED = true;
  public static IS_SEARCH_ON_MOVE_MAP_DISABLED = true;

  public static MAP_MOVE_IGNORE_THRESHOLD = 1.0;

  public static GOOGLE_MAPS_OPTIONS: { [key: string]: google.maps.MapOptions } = {
    DEFAULT: {
      clickableIcons: false,
      keyboardShortcuts: false,
      fullscreenControl: false,
      scaleControl: false,
      streetViewControl: false,
      zoomControl: true,
      zoomControlOptions: {
        position: google.maps.ControlPosition.TOP_LEFT,
      },
      disableDoubleClickZoom: false,
      scrollwheel: true,
      draggable: true,
      gestureHandling: 'greedy',
    },
  };

  public static GOOGLE_MAPS_STYLES: { [key: string]: google.maps.MapTypeStyle[] | null } = {
    DEFAULT: null,
    DROPNLOCAL: [
      {
        elementType: 'geometry',
        stylers: [
          {
            color: '#ececec',
          },
        ],
      },
      {
        elementType: 'labels.icon',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
      {
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: '#616161',
          },
        ],
      },
      {
        elementType: 'labels.text.stroke',
        stylers: [
          {
            color: '#f5f5f5',
          },
        ],
      },
      {
        featureType: 'administrative.land_parcel',
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: '#bdbdbd',
          },
        ],
      },
      {
        featureType: 'poi',
        elementType: 'geometry',
        stylers: [
          {
            color: '#eeeeee',
          },
        ],
      },
      {
        featureType: 'poi',
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: '#757575',
          },
        ],
      },
      {
        featureType: 'poi.park',
        elementType: 'geometry',
        stylers: [
          {
            color: '#e5e5e5',
          },
        ],
      },
      {
        featureType: 'poi.park',
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: '#9e9e9e',
          },
        ],
      },
      {
        featureType: 'road',
        elementType: 'geometry',
        stylers: [
          {
            color: '#ffffff',
          },
        ],
      },
      {
        featureType: 'road.arterial',
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: '#757575',
          },
        ],
      },
      {
        featureType: 'road.highway',
        elementType: 'geometry',
        stylers: [
          {
            color: '#ffffff',
          },
        ],
      },
      {
        featureType: 'road.highway',
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: '#616161',
          },
        ],
      },
      {
        featureType: 'road.local',
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: '#9e9e9e',
          },
        ],
      },
      {
        featureType: 'transit.line',
        elementType: 'geometry',
        stylers: [
          {
            color: '#e5e5e5',
          },
        ],
      },
      {
        featureType: 'transit.station',
        elementType: 'geometry',
        stylers: [
          {
            color: '#eeeeee',
          },
        ],
      },
      {
        featureType: 'transit.station',
        elementType: 'labels.icon',
        stylers: [
          {
            visibility: 'on',
          },
          {
            hue: '#23b8ea',
          },
        ],
      },
      {
        featureType: 'water',
        elementType: 'geometry',
        stylers: [
          {
            color: '#b1dcec',
          },
        ],
      },
      {
        featureType: 'water',
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: '#9e9e9e',
          },
        ],
      },
    ],
    CHANDIGARH: [
      {
        featureType: 'all',
        elementType: 'geometry.fill',
        stylers: [{ weight: 2 }],
      },
      {
        featureType: 'all',
        elementType: 'geometry.stroke',
        stylers: [{ color: '#9c9c9c' }],
      },
      {
        featureType: 'all',
        elementType: 'labels.text',
        stylers: [{ visibility: 'on' }],
      },
      {
        featureType: 'landscape',
        elementType: 'all',
        stylers: [{ color: '#f2f2f2' }],
      },
      {
        featureType: 'landscape',
        elementType: 'geometry.fill',
        stylers: [{ color: '#ffffff' }],
      },
      {
        featureType: 'landscape.man_made',
        elementType: 'geometry.fill',
        stylers: [{ color: '#ffffff' }],
      },
      {
        featureType: 'poi',
        elementType: 'all',
        stylers: [{ visibility: 'off' }],
      },
      {
        featureType: 'road',
        elementType: 'all',
        stylers: [{ saturation: -100 }, { lightness: 45 }],
      },
      {
        featureType: 'road',
        elementType: 'geometry.fill',
        stylers: [{ color: '#eeeeee' }],
      },
      {
        featureType: 'road',
        elementType: 'labels.text.fill',
        stylers: [{ color: '#7b7b7b' }],
      },
      {
        featureType: 'road',
        elementType: 'labels.text.stroke',
        stylers: [{ color: '#ffffff' }],
      },
      {
        featureType: 'road.highway',
        elementType: 'all',
        stylers: [{ visibility: 'simplified' }],
      },
      {
        featureType: 'road.arterial',
        elementType: 'labels.icon',
        stylers: [{ visibility: 'on' }, { saturation: 52 }],
      },
      {
        featureType: 'transit',
        elementType: 'all',
        stylers: [{ visibility: 'off' }],
      },
      {
        featureType: 'water',
        elementType: 'all',
        stylers: [{ color: '#46bcec' }, { visibility: 'on' }],
      },
      {
        featureType: 'water',
        elementType: 'geometry.fill',
        stylers: [{ color: '#a4ddf5' }],
      },
      {
        featureType: 'water',
        elementType: 'labels.text.fill',
        stylers: [{ color: '#070707' }],
      },
      {
        featureType: 'water',
        elementType: 'labels.text.stroke',
        stylers: [{ color: '#ffffff' }],
      },
    ],
  };
}

export const GUESTY_HELP_CENTER_URL = 'https://support.guesty.com/hc/articles/115000937654';
export const GUESTY_MARKETPLACE_URL = 'https://app.guesty.com/marketplace/';
export const OLIVER_CALENDLY_BOOKING_URL = 'https://calendly.com/oliver-somers/30min';
export const THOMAS_CALENDLY_BOOKING_URL = 'https://calendly.com/thomas-luggagehero/30-min';

export const LHTenant = 'luggagehero';
export const StorageManagerLHTenant = 'storage-manager-luggagehero';
export const STORAGE_MANAGER_ROLE = 'StorageManager.Manager';
export const STORAGE_MANAGER_IMPERSONATOR_ROLE = 'StorageManager.Impersonator';
