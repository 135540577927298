import { ChangeDetectionStrategy, ChangeDetectorRef, Component, NgZone, OnInit } from '@angular/core';
import { ModalService, RouterExtensions } from '@luggagehero/core';
import { ShopListBaseComponent } from '@luggagehero/features/shops';
import { BookableStorageLocation } from '@luggagehero/shared/interfaces';
import { SharedDistanceService } from '@luggagehero/shared/services/distance';
import { SharedPricingService } from '@luggagehero/shared/services/pricing';
import { SharedShopsService } from '@luggagehero/shared/services/shops';
import { SharedStorageCriteriaService } from '@luggagehero/shared/services/storage-criteria';
import { SharedWindowService } from '@luggagehero/shared/services/window';
import { HowItWorksComponent, PricingComponent } from '@luggagehero/web/features/ui';

import { ConfirmBookingComponent } from '../confirm-booking/confirm-booking.component';

@Component({
  selector: 'lh-shop-list',
  templateUrl: './shop-list.component.html',
  styleUrls: ['./shop-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShopListComponent extends ShopListBaseComponent implements OnInit {
  private _appVersion: string;

  constructor(
    private windowService: SharedWindowService,
    private modalService: ModalService,
    shopsService: SharedShopsService,
    criteriaService: SharedStorageCriteriaService,
    distanceService: SharedDistanceService,
    priceService: SharedPricingService,
    router: RouterExtensions,
    cd: ChangeDetectorRef,
    ngZone: NgZone,
  ) {
    super(shopsService, criteriaService, distanceService, priceService, router, cd, ngZone);
  }

  get appVersion(): string {
    return this._appVersion;
  }

  ngOnInit(): void {
    super.ngOnInit();

    this._appVersion = this.windowService.appVersion;
  }

  showHowItWorks(): Promise<void> {
    return void this.modalService.show({ component: HowItWorksComponent });
  }

  showPriceInfo(): Promise<void> {
    return void this.modalService.show({ component: PricingComponent });
  }

  makeBooking(shop: BookableStorageLocation): Promise<void> {
    this.onShopSelected(shop);
    return void this.modalService.show({ component: ConfirmBookingComponent });
  }
}
