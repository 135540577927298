import { ChangeDetectorRef, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Booking } from '@luggagehero/shared/interfaces';
import { SharedBookingService } from '@luggagehero/shared/services/bookings';
import { ModalDirective } from 'ngx-bootstrap/modal';

import { BaseComponent } from '../../../../core';
interface CancelReason {
  key: string;
  value: string;
  hidden: boolean;
}

@Component({ template: '' })
export abstract class CancelBookingBaseComponent extends BaseComponent {
  @ViewChild('cancelBookingModal') public cancelBookingModal: ModalDirective;
  @Output() bookingChange: EventEmitter<Booking> = new EventEmitter<Booking>();

  errorOccurred = false;
  errorMessage = '';
  cancelReason = '';
  cancelReasonCode = '';
  reasonsExpanded = false;

  cancelReasons: CancelReason[] = [
    { key: 'FOUND_OTHER_OPTION', value: this._translate('CANCEL_REASON_FOUND_OTHER_OPTION'), hidden: false },
    {
      key: 'UNSUITABLE_OPENING_HOURS',
      value: this._translate('CANCEL_REASON_UNSUITABLE_OPENING_HOURS'),
      hidden: false,
    },
    {
      key: 'LOCATION_CLOSED_AT_ARRIVAL',
      value: this._translate('CANCEL_REASON_LOCATION_CLOSED_AT_ARRIVAL'),
      hidden: false,
    },
    // { key: 'UNSAFE_LOCATION', value: this._translate('CANCEL_REASON_UNSAFE_LOCATION'), hidden: false },
    { key: 'FELT_UNSAFE', value: this._translate('CANCEL_REASON_FELT_UNSAFE'), hidden: false },
    { key: 'STORAGE_NOT_SECURE', value: this._translate('CANCEL_REASON_STORAGE_NOT_SECURE'), hidden: false },
    {
      key: 'ASKED_TO_PAY_OTHER_SERVICE',
      value: this._translate('CANCEL_REASON_ASKED_TO_PAY_OTHER_SERVICE'),
      hidden: false,
    },
    { key: 'CHANGE_OF_PLANS', value: this._translate('CANCEL_REASON_CHANGE_OF_PLANS'), hidden: true },
    { key: 'DUPLICATE_BOOKING', value: this._translate('CANCEL_REASON_DUPLICATE_BOOKING'), hidden: true },
    {
      key: 'STAFF_DIDNT_KNOW_ABOUT_SERVICE',
      value: this._translate('CANCEL_REASON_STAFF_DIDNT_KNOW_ABOUT_SERVICE'),
      hidden: true,
    },
    {
      key: 'UNABLE_TO_FIND_LOCATION',
      value: this._translate('CANCEL_REASON_UNABLE_TO_FIND_LOCATION'),
      hidden: true,
    },
    {
      key: 'DONT_UNDERSTAND_HOW_THIS_WORKS',
      value: this._translate('CANCEL_REASON_DONT_UNDERSTAND_HOW_THIS_WORKS'),
      hidden: true,
    },
    { key: 'OTHER', value: this._translate('CANCEL_REASON_OTHER'), hidden: false },
  ];

  private _booking: Booking;

  public constructor(
    private bookingService: SharedBookingService,
    private cd: ChangeDetectorRef,
  ) {
    super();
  }

  get booking(): Booking {
    return this._booking;
  }
  @Input() set booking(value: Booking) {
    this._booking = value;
    this.cd.markForCheck();
  }

  get isCancelAllowed(): boolean {
    if (!this.cancelReasonCode) {
      return false;
    }
    if (this.cancelReason) {
      return true;
    }
    const codesThatRequireReason = ['FOUND_OTHER_OPTION', 'OTHER'];
    return !codesThatRequireReason.includes(this.cancelReasonCode);
  }

  showCancelBooking() {
    if (!this.cancelBookingModal) {
      return;
    }
    this.cancelBookingModal.show();
  }

  async cancelBooking() {
    try {
      const res = await this.bookingService.cancelBooking(this.booking._id, this.cancelReason, this.cancelReasonCode);

      this.booking = res;
      this.bookingChange.emit(res);

      if (this.cancelBookingModal) {
        this.cancelBookingModal.hide();
      }
    } catch (err) {
      this.errorOccurred = true;
      this.errorMessage = err as string;
    }
  }
}
