/* eslint-disable @typescript-eslint/unbound-method */
import { HttpStatusCode } from '@angular/common/http';
import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppConfig } from '@luggagehero/shared/app-settings/data-access';
import { SharedErrorService } from '@luggagehero/shared/services/error';
import { SharedLoggingService } from '@luggagehero/shared/services/logging';
import { SharedNotificationService } from '@luggagehero/shared/services/notification';
import { SharedStorageService } from '@luggagehero/shared/services/storage';
import { AuthPassthroughError, SharedUserService } from '@luggagehero/shared/services/users';
import { SharedWindowService } from '@luggagehero/shared/services/window';
import { TranslateService } from '@ngx-translate/core';

import { RouterExtensions } from '../../../core/services/index';
import { FacebookLoginService, GoogleSigninService } from '../../../services/index';
import { LoginBaseComponent } from './login.base-component';

// Delay before allowing resend of one-time passwords
const OTP_RESEND_DELAY_MS = 59 * 1000;

@Component({ template: '' })
export abstract class SmoothLoginBaseComponent extends LoginBaseComponent {
  @ViewChild('nameInput') public nameInput: ElementRef<HTMLInputElement>;
  @ViewChild('passwordInput') public passwordInput: ElementRef<HTMLInputElement>;
  @ViewChild('oneTimePasswordInput') public oneTimePasswordInput: ElementRef<HTMLInputElement>;

  @Output() public isUserEmailSelectedChange = new EventEmitter<boolean>();

  @Input() public isUserEmailSelected = false;
  @Input() public otpLength = 4;

  public newUserForm: FormGroup;
  public existingUserForm: FormGroup;
  public phoneNumberForm: FormGroup;

  private isContinueWithEmailButtonClicked = false;
  private isContinueWithPhoneButtonClicked = false;
  private isOtpRefreshRunning = false;

  constructor(
    protected formBuilder: FormBuilder,
    userService: SharedUserService,
    facebookLogin: FacebookLoginService,
    googleSignin: GoogleSigninService,
    storage: SharedStorageService,
    window: SharedWindowService,
    router: RouterExtensions,
    translate: TranslateService,
    notify: SharedNotificationService,
    error: SharedErrorService,
    log: SharedLoggingService,
    cd: ChangeDetectorRef,
  ) {
    super(userService, facebookLogin, googleSignin, storage, window, router, translate, notify, error, log, cd);
  }

  public get isFacebookSignInAvailable(): boolean {
    return AppConfig.IS_FACEBOOK_SIGN_IN_ENABLED;
  }

  public get isGoogleSignInAvailable(): boolean {
    return AppConfig.IS_GOOGLE_SIGN_IN_ENABLED;
  }

  public get isExternalSignInAvailable(): boolean {
    return this.isFacebookSignInAvailable || this.isGoogleSignInAvailable || this.isAppleSignInAvailable;
  }

  public get isResendOtpAllowed(): boolean {
    if (!this.lastOtpRequest) {
      return true;
    }
    if (this.user.phone !== this.lastOtpRecipient) {
      return true;
    }
    const msPassedSinceLastOtpRequest = new Date().getTime() - this.lastOtpRequest;
    return msPassedSinceLastOtpRequest > OTP_RESEND_DELAY_MS;
  }

  public get otpPlaceholder(): string {
    let placeholder = '';
    for (let counter = this.otpLength; counter > 0; counter--) {
      placeholder += '#';
    }
    return placeholder;
  }

  public get secondsRemainingBeforeOtpResend(): number {
    if (!this.lastOtpRequest) {
      return 0;
    }

    const msPassedSinceLastOtpRequest = new Date().getTime() - this.lastOtpRequest;
    const secsRemaining = (OTP_RESEND_DELAY_MS - msPassedSinceLastOtpRequest) / 1000;

    return Math.round(secsRemaining);
  }

  public get lastOtpRecipient(): string {
    return this.storage.lastOtpRecipient;
  }
  public set lastOtpRecipient(value: string) {
    this.storage.lastOtpRecipient = value;
  }

  public get lastOtpRequest(): number {
    return this.storage.lastOtpRequest;
  }
  public set lastOtpRequest(value: number) {
    this.storage.lastOtpRequest = value;
  }

  public get isContinueWithEmailButtonDisabled(): boolean {
    if (this.isLoading) {
      return true;
    }
    if (!this.isContinueWithEmailButtonClicked) {
      return false;
    }
    return !this.isEmailFieldValid();
  }

  public get currentEmailForm() {
    // return this.userForm;
    return this.isNewUser ? this.newUserForm : this.existingUserForm;
  }

  public get isContinueWithPhoneNumberButtonDisabled(): boolean {
    if (this.isLoading) {
      return true;
    }

    if (!this.isContinueWithPhoneButtonClicked) {
      return false;
    }

    return this.phoneInputError ? true : false;
  }

  get isValidPhoneNumber(): boolean {
    const phoneNumberInput = this.phoneNumberForm.get('phone');
    return phoneNumberInput.dirty && phoneNumberInput.valid;
  }

  public get isSubmitPhoneNumberEnabled(): boolean {
    if (!this.isContinueWithPhoneButtonClicked) {
      return true;
    }
    // return this.phoneNumberForm.valid && !this.isLoading;
    return this.phoneNumberForm.valid;
  }

  public get phoneInputError(): string {
    if (!this.isContinueWithPhoneButtonClicked) {
      return undefined;
    }
    const phoneInput = this.phoneNumberForm.get('phone');
    if (phoneInput.pristine || !phoneInput.errors) {
      return undefined;
    }
    if (phoneInput.errors.required) {
      return 'PHONE_NUMBER_MISSING';
    }
    if (phoneInput.errors.minlength) {
      return 'PHONE_NUMBER_TOO_SHORT';
    }
    if (phoneInput.errors.invalid && 'reason' in phoneInput.errors.invalid) {
      return (phoneInput.errors.invalid as { reason?: string })?.reason;
    }
    return 'INVALID_PHONE_NUMBER';
  }

  public abstract get isAppleSignInAvailable(): boolean;
  public abstract signInWithApple(): Promise<void>;

  public onAuthenticationFailed(): void {
    super.onAuthenticationFailed();

    this.lastOtpRecipient = null;
    this.lastOtpRequest = null;
  }

  public loadForms() {
    const nameRegEx = this.window.unicodeRegEx ? new RegExp(AppConfig.NAME_REGEX, 'u') : AppConfig.NAME_REGEX;
    this.newUserForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.pattern(AppConfig.EMAIL_REGEX)]],
      password: ['', this.choosePassword ? [Validators.required] : undefined],
      name: [
        '',
        this.requireName ? [Validators.required, Validators.pattern(nameRegEx)] : [Validators.pattern(nameRegEx)],
      ],
      phone: ['', [Validators.pattern(AppConfig.PHONE_REGEX)]],
    });

    this.existingUserForm = this.formBuilder.group({
      // Use angular email validator for login as we have legacy users created with this
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]],
    });

    this.phoneNumberForm = this.formBuilder.group({
      phone: ['', [Validators.required, Validators.minLength(6)]],
      oneTimePassword: ['', [Validators.required]],
    });
  }

  private async continueWithEmail(): Promise<void> {
    this.isContinueWithEmailButtonClicked = true;
    this.markEmailFieldAsDirty();

    if (!this.isEmailFieldValid()) {
      return;
    }

    if (this.userEmail.trim().endsWith('.con')) {
      this.userEmail = this.userEmail.trim().slice(0, -3) + 'com';
    }

    if (!this.isEmailFormVisible) {
      this.isLoading = true;
      try {
        this.isExistingUser = await this.userService.checkIfEmailUserExists(this.userEmail);
      } catch (error) {
        this.notify.error('Invalid email, please try again');
        this.isLoading = false;
        return;
      }

      this.isExistingUserChange.emit(this.isExistingUser);

      if (this.isNewUser) {
        this.emailLogin();
        return;
      }

      this.isUserEmailSelected = true;
      this.isUserEmailSelectedChange.emit(true);

      this.isEmailFormVisible = true;
      this.isPhoneFormVisible = false;
      this.isLoading = false;

      // Move focus to the next relevant input field
      setTimeout(() => (this.isNewUser ? this.nameInput : this.passwordInput).nativeElement.focus(), 100);

      return;
    }
    this.currentEmailForm.controls.password.markAsDirty();
    if (this.currentEmailForm.controls.password.invalid) {
      return;
    }
    this.emailLogin();
  }

  private async continueWithPhone() {
    this.isContinueWithPhoneButtonClicked = true;
    this.markPhoneNumberFieldAsDirty();

    if (this.phoneInputError) {
      return;
    }

    if (!this.isPhoneFormVisible) {
      this.isLoading = true;

      try {
        if (this.isResendOtpAllowed) {
          // Request OTP to be sent to the user via SMS
          await this.userService.requestOneTimePasswordViaPhone(this.user.phone);

          this.lastOtpRequest = new Date().getTime();
          this.lastOtpRecipient = this.user.phone;
        }

        // Make sure we show the resend option in the UI after the delay has passed
        if (!this.isOtpRefreshRunning) {
          this.runOtpRefresh();
        }

        // Show the input field for the code
        this.isPhoneFormVisible = true;

        setTimeout(() => this.oneTimePasswordInput.nativeElement.focus(), 100);
      } catch (err) {
        if (err instanceof AuthPassthroughError) {
          // Just log the user in without OTP validation
          await this.login();
        } else {
          const error = err as { status?: number; error?: { message?: string } };
          switch (<HttpStatusCode>error.status) {
            case HttpStatusCode.BadRequest: {
              // Get the validation error message if available (it should be with 400 errors from Hapi)
              let message = String(error.error && error.error.message) || 'uknown error';

              //
              // Error message from Hapi validation is of the form: "\"phoneNumber\" must be a mobile phone number" so we
              // strip out the "phoneNumber" part for a more human-readable message
              //
              message = message.replace('"phoneNumber"', '').toLowerCase();

              // Will display something like "Failed to send SMS: must be a valid mobile phone number"
              this.notify.error(`Failed to send SMS: ${message}`);

              break;
            }

            case HttpStatusCode.MethodNotAllowed: {
              // Just log the user in without OTP valiation
              await this.login();
              break;
            }

            case HttpStatusCode.UnprocessableEntity: {
              // The phone number is registered with multiple accounts
              this.notify.error(`Cannot sign in by phone, please use another sign-in method`);
              break;
            }

            default: {
              this.notify.error(`Sign-in failed, please try again or use another sign-in method`);
              break;
            }
          }
        }
      }

      this.isLoading = false;

      return;
    }

    // Remove focus from the code input field
    this.oneTimePasswordInput.nativeElement.blur();

    // Start the login process
    await this.login();
  }

  private runOtpRefresh() {
    this.isOtpRefreshRunning = true;

    setTimeout(() => {
      this.cd.markForCheck();
      this.runOtpRefresh();
    }, 1000);
  }

  public getUserConfirmation(prompt: string): boolean {
    // TODO: Don't use confirm directly here
    return confirm(prompt);
  }

  public changeEmail() {
    this.isEmailFormVisible = false;
    this.userEmail = '';

    this.isUserEmailSelected = false;
    this.isUserEmailSelectedChange.emit(false);

    this.cd.markForCheck();
  }

  public async submitForm() {
    return this.signInUsingMobile ? this.continueWithPhone() : this.continueWithEmail();
  }

  public sendOneTimePassword() {
    this.isPhoneFormVisible = false;
    void this.continueWithPhone();
  }

  public onOneTimePasswordInput(_event: InputEvent) {
    if (this.user.password.length === this.otpLength) {
      void this.continueWithPhone();
    }
  }

  public isEmailFieldValid(): boolean {
    return this.currentEmailForm.get('email').valid;
  }

  public markEmailFieldAsDirty(): void {
    this.currentEmailForm.get('email').markAsDirty();
  }

  public markPhoneNumberFieldAsDirty(): void {
    this.phoneNumberForm.get('phone').markAsDirty();
  }

  public markNewUserFormPristine() {
    this.newUserForm.markAsPristine();
  }

  public markExistingFormPristine() {
    this.existingUserForm.markAsPristine();
  }

  public markPhoneNumberFormPristine() {
    this.phoneNumberForm.markAsPristine();
  }
}
