import { Injectable } from '@angular/core';
import { Booking, BookingStatus } from '@luggagehero/shared/interfaces';
import moment from 'moment';
import { BehaviorSubject, Observable } from 'rxjs';

const DATE_FORMAT = 'YYYY-MM-DD HH:mm:ss';
const ZERO_DATE = '1900-01-01 00:00:00';
const STARTED_TIMER_STATUSES: BookingStatus[] = ['CHECKED_IN', 'CHECKED_OUT', 'PAID'];
const RUNNING_TIMER_STATUSES: BookingStatus[] = ['CHECKED_IN'];

export class TimerValue {
  private static _inactive = new TimerValue('--', '--', '--', '--');
  private static _zero = new TimerValue('00', '00', '00', '00');

  constructor(
    public readonly days: string,
    public readonly hours: string,
    public readonly minutes: string,
    public readonly seconds: string,
  ) {}

  public static get inactive(): TimerValue {
    return this._inactive;
  }

  public static get zero(): TimerValue {
    return this._zero;
  }

  public get totalSeconds(): number {
    let d: number; // days
    let h: number; // hours
    let m: number; // minutes
    let s: number; // seconds

    if (
      isNaN((d = parseInt(this.days))) ||
      isNaN((h = parseInt(this.hours))) ||
      isNaN((m = parseInt(this.minutes))) ||
      isNaN((s = parseInt(this.seconds)))
    ) {
      // If any of the values are not numbers, return 0
      return 0;
    }
    // Return the total seconds
    return d * 24 * 60 * 60 + h * 60 * 60 + m * 60 + s;
  }
}

@Injectable()
export class StorageTimerService {
  public readonly tick: Observable<TimerValue>;

  private _booking: Booking;
  private _isCheckInSession = false;
  private _value$: BehaviorSubject<TimerValue>;
  private _value: TimerValue;

  private timerSecondsOffset: number;

  constructor() {
    this._value = TimerValue.inactive;
    this._value$ = new BehaviorSubject<TimerValue>(this._value);

    this.tick = this._value$.asObservable();

    this.scheduleTick();
  }

  public get booking(): Booking {
    return this._booking;
  }

  public get isCheckInSession(): boolean {
    return this._isCheckInSession;
  }

  private get timerFrom(): string {
    if (!this.booking) {
      return this.formatTimerDate(new Date());
    }
    if (['CONFIRMED', 'CANCELLED'].includes(this.booking.status)) {
      return ZERO_DATE;
    }
    return this.formatTimerDate(this.booking.period.checkIn);
  }

  private get timerTo(): string {
    if (!this.booking) {
      return this.formatTimerDate(new Date());
    }
    switch (this.booking.status) {
      case 'CHECKED_IN':
        return this.formatTimerDate(new Date());

      case 'CHECKED_OUT':
      case 'PAID':
        return this.formatTimerDate(this.booking.period.checkOut);

      case 'CONFIRMED':
      case 'CANCELLED':
      // Fall through to default

      default:
        return ZERO_DATE;
    }
  }

  public get isTimerStarted(): boolean {
    return STARTED_TIMER_STATUSES.includes(this.booking?.status);
  }

  public get isTimerRunning(): boolean {
    return RUNNING_TIMER_STATUSES.includes(this.booking?.status);
  }

  /**
   * Configures the timer to track the provided booking, optionally treating this as a check-in session.
   * @param booking The booking which the timer should track. The timer will be reset to the booking's check-in time.
   * If the booking is not currently checked in, the timer will be reset to zero.
   * @param isCheckInSession `true` if the booking was checked in during the current session; otherwise, `false`.
   */
  public set(booking: Booking, isCheckInSession = false): void {
    this._booking = booking;
    this._isCheckInSession = isCheckInSession;
    this.timerSecondsOffset = null;
  }

  public clear(): void {
    this.set(null);
  }

  private scheduleTick() {
    setTimeout(() => {
      // Update the timer value
      const value = !this.booking ? TimerValue.inactive : this.isTimerStarted ? this.getTimeElapsed() : TimerValue.zero;
      this._value$.next(value);

      // Schedule the next tick
      this.scheduleTick();
    }, 1000);
  }

  private getTimeElapsed(): TimerValue {
    if (typeof this.timerSecondsOffset !== 'number') {
      this.timerSecondsOffset = this.isCheckInSession ? new Date().getSeconds() : 0;
    }

    let fromMoment = moment(this.timerFrom);
    const toMoment = moment(this.timerTo).subtract(this.timerSecondsOffset, 'seconds');

    const days = toMoment.diff(fromMoment, 'days').toString();
    fromMoment = fromMoment.add(days, 'days');

    const hours = toMoment.diff(fromMoment, 'hours').toString();
    fromMoment = fromMoment.add(hours, 'hours');

    const minutes = toMoment.diff(fromMoment, 'minutes').toString();
    fromMoment = fromMoment.add(minutes, 'minutes');

    const seconds = toMoment.diff(fromMoment, 'seconds').toString();

    return new TimerValue(days, hours, minutes, seconds);
  }

  private formatTimerDate(value: Date): string {
    return moment(value).subtract(this.timerSecondsOffset, 'seconds').format(DATE_FORMAT);
  }
}
