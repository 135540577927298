// angular
import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
// libs
import { DynamicFormSelectBaseComponent } from '@luggagehero/features/ui';

@Component({
  selector: 'lh-dynamic-form-select',
  templateUrl: './dynamic-form-select.component.html',
  styleUrls: ['./dynamic-form-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DynamicFormSelectComponent extends DynamicFormSelectBaseComponent {
  constructor(cd: ChangeDetectorRef) {
    super(cd);
  }
}
