<div *ngIf="!isLoading && (!paymentMethods || paymentMethods.length === 0)">
  {{ 'NO_PAYMENT_METHODS' | translate }}
</div>

<ul class="list-group payment-method-list" *ngIf="paymentMethods">
  <div *ngFor="let paymentMethod of paymentMethods" class="list-group-item payment-method">
    <div class="payment-method-inner">
      <!-- Card brand logo or name -->
      <div class="card-brand-container">
        <div class="card-brand-name">{{ paymentMethod | paymentMethod : 'brand' }}</div>
        <div class="card-brand-logo">
          <div class="card-brand" ngClass="card-brand-{{ paymentMethod | paymentMethod : 'brand' }}">
            <div class="card-brand-bg"></div>
          </div>
        </div>
      </div>

      <!-- Card number -->
      <div class="card-number" [innerHtml]="paymentMethod | paymentMethod : 'number' : true"></div>

      <!-- Card expiry date -->
      <div class="card-expiry-date">{{ paymentMethod | paymentMethod : 'expiryShort' }}</div>

      <!-- Remove card call-to-action -->
      <a [id]="'requestDeletePaymentMethod_' + paymentMethod.id" (click)="delete(paymentMethod)" class="href">
        {{ 'REMOVE' | translate }}
      </a>
    </div>
  </div>
</ul>

<button id="showPaymentCardModal" class="btn btn-primary" (click)="paymentCardModal.show()">
  {{ 'NEW_PAYMENT_METHOD' | translate }}
</button>

<!--Payment card modal-->
<div
  class="modal fade"
  [class.modal-ios]="ios"
  bsModal
  #paymentCardModal="bs-modal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="mySmallModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left">
          <div>{{ 'NEW_PAYMENT_METHOD' | translate }}</div>
        </h4>
        <button
          id="closePaymentCardModal"
          type="button"
          class="close pull-right"
          aria-label="Close"
          (click)="paymentCardModal.hide()"
        >
          <svg viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
            />
            <path d="M0 0h24v24H0z" fill="none" />
          </svg>
        </button>
      </div>

      <div class="modal-body" *ngIf="paymentCardModal.isShown">
        <lh-payment-card-input
          [isLoading]="isLoading"
          (result)="onPaymentCardResult($event)"
          (cancel)="paymentCardModal.hide()"
        ></lh-payment-card-input>
      </div>
    </div>
  </div>
</div>
