import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ConfirmationStepBaseComponent } from '@luggagehero/features/shops';

@Component({
  selector: 'lh-confirmation-step',
  templateUrl: './confirmation-step.component.html',
  styleUrls: ['./confirmation-step.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class ConfirmationStepComponent extends ConfirmationStepBaseComponent {}
