<div
  class="track"
  [class.track-sm]="size === 'small'"
  [class.track-md]="size === 'medium'"
  [class.track-lg]="size === 'large'"
>
  @for (step of visibleSteps; track $index) {
    <div
      class="step"
      [class.current-step]="isCurrentStep($index)"
      [class.first-step]="$index === 0"
      [class.last-step]="$index === visibleSteps.length - 1"
    >
      <div class="step-text">{{ step.label }}</div>
    </div>

    @if (hasMoreSteps($index)) {
      <svg width="7" height="19" viewBox="0 0 7 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          opacity="0.1"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M5.34354 9.10331L1.00262 0.0601807H2.12274L6.46611 9.10841L2.07636 18.0602H0.951294L5.34354 9.10331Z"
          fill="black"
        />
      </svg>
    }
  }
</div>
