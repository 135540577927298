import { ChangeDetectorRef, Component } from '@angular/core';
import { IPaymentRecord } from '@luggagehero/shared/interfaces';
import { SharedBookingService } from '@luggagehero/shared/services/bookings';
import { SharedLoggingService } from '@luggagehero/shared/services/logging';
import { SharedPaymentService } from '@luggagehero/shared/services/payments';
import { SharedShopsService } from '@luggagehero/shared/services/shops';
import { SharedStorageService } from '@luggagehero/shared/services/storage';

import { BookingActionBaseComponent } from './booking-action.base-component';

@Component({ template: '' })
export abstract class SelectPaymentMethodActionBaseComponent extends BookingActionBaseComponent {
  private _paymentMethod: IPaymentRecord;
  private _paymentMethods: IPaymentRecord[];

  constructor(
    private paymentService: SharedPaymentService,
    private log: SharedLoggingService,
    bookingService: SharedBookingService,
    shopsService: SharedShopsService,
    storageService: SharedStorageService,
    cd: ChangeDetectorRef,
  ) {
    super(bookingService, shopsService, storageService, cd);
  }

  get paymentMethod(): IPaymentRecord {
    return this._paymentMethod;
  }
  set paymentMethod(value: IPaymentRecord) {
    this._paymentMethod = value;
    void this.updatePaymentMethod();
  }

  get paymentMethods(): IPaymentRecord[] {
    return this._paymentMethods;
  }
  set paymentMethods(value: IPaymentRecord[]) {
    this._paymentMethods = value;
    if (!this.booking || !this.booking.paymentMethodId) {
      return;
    }
    for (let i = 0; i < this.paymentMethods.length; i++) {
      if (this.paymentMethods[i].id === this.booking.paymentMethodId) {
        this.paymentMethod = this.paymentMethods[i];
        break;
      }
    }
  }

  get isPaymentDue(): boolean {
    if (!this.booking) {
      return false;
    }
    return ['CHECKED_IN', 'CHECKED_OUT'].includes(this.booking.status);
  }

  async onBaseInit() {
    try {
      // Bypass locally cached payment methods if payment has failed
      const noCache = this.booking.status === 'CHECKED_OUT';
      const res = await this.paymentService.getPaymentMethods(this.booking._id, noCache);
      this.paymentMethods = res || [];
    } catch (err) {
      // TODO: Do not show internal error messages to user
      // this.errorOccurred = true;
      // this.errorMessage = err;
    }
    this.isLoading = false;
  }

  async updatePaymentMethod(): Promise<void> {
    // Commented to let the user close the modal manually
    // this.close();

    if (this.paymentMethod.id === this.booking.paymentMethodId) {
      // No change in payment method
      return;
    }
    this.isLoading = true;

    try {
      const res = await this.bookingService.setPaymentMethod(this.booking._id, this.paymentMethod.id);

      void this.log.debug(`Payment method successfully set on booking`);
      this.updateBooking(res);

      // this.strategy.onPaymentMethodUpdated();

      // this.isPaymentMethodChanged = true;
      // this.errorOccurred = false;
      // this.errorMessage = '';
      this.isLoading = false;

      // if (this.isCtaClicked) {
      //   this.isCtaClicked = false;

      //   switch (this.requirePaymentBefore) {
      //     case 'check-in':
      //       this.showCheckIn();
      //       break;

      //     case 'check-out':
      //       this.showCheckOut();
      //       break;
      //   }
      // }
    } catch (err) {
      // TODO: Do not show internal error messages to user
      // this.errorOccurred = true;
      // this.errorMessage = err;
      this.isLoading = false;
    }
  }
}
