import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { AlertController, AlertInput, IonicModule } from '@ionic/angular';
import { DistanceUnit } from '@luggagehero/shared/interfaces';
import { TranslatePipe } from '@luggagehero/shared/ui-pipes';

@Component({
  selector: 'lh-traveler-shops-ui-shop-filters',
  standalone: true,
  imports: [CommonModule, IonicModule, TranslatePipe],
  styleUrls: ['traveler-shops-ui-shop-filters.component.scss'],
  templateUrl: 'traveler-shops-ui-shop-filters.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TravelerShopsUiShopFilterComponent {
  @Input() isHotelFilterVisible = false;
  @Input() isOpen24HoursFilterVisible = false;
  @Input() isWifiAvailableFilterVisible = false;
  @Input() isMultiDayStorageFilterVisible = false;
  @Input() hotelsFilterEnabled = false;
  @Input() openAllDayFilterEnabled = false;
  @Input() wifiAvailableFilterEnabled = false;
  @Input() multiDayStorageFilterEnabled = false;
  @Output() showMapChange = new EventEmitter<boolean>();
  @Output() distanceUnitChangeRequest = new EventEmitter();
  @Output() toggleOpenAllDayFilter = new EventEmitter<void>();
  @Output() toggleMultiDayStorageFilter = new EventEmitter<void>();
  @Output() toggleWifiAvailableFilter = new EventEmitter<void>();
  @Output() toggleHotelsFilter = new EventEmitter<void>();

  filterLabel = 'Filter';
  @Input() distanceUnit: DistanceUnit = 'imperial';
  @Output() distanceUnitClick = new EventEmitter();

  activeFiltersCount = 0;

  constructor(
    private alertController: AlertController,
    private cd: ChangeDetectorRef,
  ) {}

  async presentFilterAlert() {
    const inputs: AlertInput[] = [];
    if (this.isHotelFilterVisible) {
      inputs.push({
        name: 'hotels',
        type: 'checkbox',
        label: 'Hotels',
        value: 'hotels',
        checked: this.hotelsFilterEnabled,
        handler: (input: AlertInput) => {
          if (input.checked && !this.hotelsFilterEnabled) {
            this.toggleHotelsFilter.emit();
          } else if (!input.checked && this.hotelsFilterEnabled) {
            this.toggleHotelsFilter.emit();
          }
        },
      });
    }
    if (this.isOpen24HoursFilterVisible) {
      inputs.push({
        name: 'openAllDay',
        type: 'checkbox',
        label: 'Open all day',
        value: 'openAllDay',
        checked: this.openAllDayFilterEnabled,
        handler: (input: AlertInput) => {
          if (input.checked && !this.openAllDayFilterEnabled) {
            this.toggleOpenAllDayFilter.emit();
          } else if (!input.checked && this.openAllDayFilterEnabled) {
            this.toggleOpenAllDayFilter.emit();
          }
        },
      });
    }
    if (this.isWifiAvailableFilterVisible) {
      inputs.push({
        name: 'wifiAvailable',
        type: 'checkbox',
        label: 'Wifi available',
        value: 'wifiAvailable',
        checked: this.wifiAvailableFilterEnabled,

        handler: (input: AlertInput) => {
          if (input.checked && !this.wifiAvailableFilterEnabled) {
            this.toggleWifiAvailableFilter.emit();
          } else if (!input.checked && this.wifiAvailableFilterEnabled) {
            this.toggleWifiAvailableFilter.emit();
          }
        },
      });
    }
    if (this.isMultiDayStorageFilterVisible) {
      inputs.push({
        name: 'multiDayStorage',
        type: 'checkbox',
        label: 'Multi-day storage',
        value: 'multiDayStorage',
        checked: this.multiDayStorageFilterEnabled,

        handler: (input: AlertInput) => {
          if (input.checked && !this.multiDayStorageFilterEnabled) {
            this.toggleMultiDayStorageFilter.emit();
          } else if (!input.checked && this.multiDayStorageFilterEnabled) {
            this.toggleMultiDayStorageFilter.emit();
          }
        },
      });
    }

    const alert = await this.alertController.create({
      header: 'Filter',
      inputs: inputs,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
        },
        {
          text: 'Ok',
          handler: (data: string[]) => {
            this.activeFiltersCount = data.length;
            this.cd.markForCheck();
          },
        },
      ],
    });

    await alert.present();
  }
}
