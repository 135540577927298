/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  LHTenant,
  STORAGE_MANAGER_IMPERSONATOR_ROLE,
  STORAGE_MANAGER_ROLE,
} from '@luggagehero/shared/app-settings/data-access';
import { Config } from '@luggagehero/shared/environment';
import { SharedAnalyticsService } from '@luggagehero/shared/services/analytics';
import { SharedHttpService } from '@luggagehero/shared/services/http';
import { SharedIntercomService } from '@luggagehero/shared/services/intercom';
import { SharedLoggingService } from '@luggagehero/shared/services/logging';
import { SharedStorageService, StorageTarget } from '@luggagehero/shared/services/storage';
import { SharedWindowService } from '@luggagehero/shared/services/window';
import { sendPasswordResetEmail, signIn } from 'supertokens-web-js/recipe/emailpassword';
import Session from 'supertokens-web-js/recipe/session';
import { UserRoleClaim } from 'supertokens-web-js/recipe/userroles';

import {
  AuthenticateUserResponse,
  CheckUserResponse,
  ILogin,
  INewUser,
  SharedUserService,
} from './shared-user.service';

@Injectable({
  providedIn: 'root',
})
export class StorageManagerUserService extends SharedUserService {
  constructor(
    protected router: Router,
    protected http: SharedHttpService,
    protected storageService: SharedStorageService,
    protected intercom: SharedIntercomService,
    protected analytics: SharedAnalyticsService,
    protected windowService: SharedWindowService,
    protected log: SharedLoggingService,
  ) {
    super(router, http, storageService, intercom, analytics, windowService, log);
    this._usersEndpoint = `${Config.environment.IDENTITY_ST_API}/users`;
  }

  public async checkIfEmailUserExists(email: string): Promise<boolean> {
    const url = `${this.UsersEndpoint}/check`;

    const result = await this.http.get<CheckUserResponse>(`${url}?email=${encodeURIComponent(email)}`, false);

    return result.userFound;
  }

  public async checkIfPhoneUserExists(phone: string): Promise<boolean> {
    const url = `${this.UsersEndpoint}/check`;

    const result = await this.http.get<CheckUserResponse>(`${url}?phone=${encodeURIComponent(phone)}`, false);

    return result.userFound;
  }

  public isImpersonationMode(): boolean {
    const isImpersonator = this.storageService.isImpersonator;
    const impersonationMode = this.user && !this.user.isShopOwner && isImpersonator;

    console.log('isImpersonationMode', impersonationMode);

    return impersonationMode;
  }

  public async isLoggedInAsync(): Promise<boolean> {
    const sessionExists = await Session.doesSessionExist();

    if (!sessionExists) {
      return false;
    }

    // if the role is not StorageManager return false
    const roles: string[] | undefined = await Session.getClaimValue({ claim: UserRoleClaim });

    if (
      !roles ||
      roles.length === 0 ||
      !roles.some((role) => [STORAGE_MANAGER_ROLE, STORAGE_MANAGER_IMPERSONATOR_ROLE].includes(role))
    ) {
      this.storageService.clearUserData();
      return false;
    }

    let flippingMode = false;

    if (!this.isImpersonationMode()) {
      if (roles.includes(STORAGE_MANAGER_IMPERSONATOR_ROLE)) {
        console.log('Flipping mode to admin');
        flippingMode = true;
      }
    }

    const accessTokenPayload = await Session.getAccessTokenPayloadSecurely();

    const tenantId = accessTokenPayload?.tId;
    const impersonationMode = accessTokenPayload?.imp === true;

    if (this.user == null || flippingMode) {
      this.user = await this.getUserInfo();
      //await this.router.navigate(['/dashboard']);
    }

    if (tenantId === LHTenant && impersonationMode) {
      console.log('Enabling Admin Mode');
      this.storageService.isImpersonator = true;
    } else {
      console.log('Disabling Admin Mode');
      this.storageService.remove('storageLocationId', StorageTarget.Local);
      this.storageService.isImpersonator = null;
    }

    return sessionExists;
  }

  public softLogout() {
    console.log('Soft logout');
    this.storageService.remove('storageLocationId', StorageTarget.Local);
    this.storageService.isImpersonator = null;

    // this.facebookLogout();
    this.intercom.shutdown();

    this._user = {};

    // TODO: Should we clear everything in local storage or only token and user info?
    this.storageService.clearUserData();
    // this.storageService.clear();

    this.announceLogin(false, true);
  }

  public logout() {
    this.storageService.remove('storageLocationId', StorageTarget.Local);
    this.storageService.isImpersonator = null;

    Session.signOut().then(
      () => {
        // this.facebookLogout();
        this.intercom.shutdown();

        this._user = {};

        // TODO: Should we clear everything in local storage or only token and user info?
        this.storageService.clearUserData();
        // this.storageService.clear();

        this.announceLogin(false, true);
      },
      (error) => {
        console.error('Error logging out', error);
      },
    );
  }

  public loginWithEmail(credentials: ILogin): Promise<AuthenticateUserResponse> {
    return this.authenticateUser(credentials.email, credentials.password);
  }

  public async authenticateUser(email: string, password: string): Promise<AuthenticateUserResponse> {
    try {
      this.storageService.clearUserData();

      try {
        const sessionExists = await Session.doesSessionExist();
        if (sessionExists) {
          await Session.signOut();
        }
      } catch (err) {
        console.log(`Error in sign out: ${err}`);
      }

      const response = await signIn({
        formFields: [
          {
            id: 'email',
            value: email,
          },
          {
            id: 'password',
            value: password,
          },
        ],
      });

      if (response.status === 'OK') {
        const sessionExists = await Session.doesSessionExist();

        if (sessionExists) {
          return {
            success: true,
            message: null,
            accessToken: 'superTokensAccessToken',
            firstTimeUser: false,
            // isImpersonated: boolean,
            // isAdmin: boolean,
          };
        }
      } else {
        throw new Error('Error logging in');
      }
    } catch (error) {
      throw new Error('Error logging in');
    }
  }

  public async registerUser(newUser: INewUser): Promise<AuthenticateUserResponse> {
    const url = this.UsersEndpoint;

    // not required for storage manager users
    delete newUser.sendPassword;

    const res = await this.http.post<AuthenticateUserResponse>(url, this.serializeNewUser(newUser), false);

    this.storageService.clearUserData();

    if (res.success) {
      await this.authenticateUser(newUser.email, newUser.password);
      // Make sure we track that this is a signup event
      this.isNewUser = true;
      res.firstTimeUser = false;
      res.message = null;
      res.accessToken = 'superTokensAccessToken';
    }

    return res;
  }

  public async resetPasswordViaEmail(email: string): Promise<unknown> {
    const response = await sendPasswordResetEmail({
      formFields: [
        {
          id: 'email',
          value: email,
        },
      ],
    });

    if (response.status !== 'OK') {
      throw new Error('Error sending password reset email');
    }

    return;
  }
}
