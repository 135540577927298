import { ChangeDetectorRef, Component, Type } from '@angular/core';
import { SharedBookingService } from '@luggagehero/shared/services/bookings';
import { SharedShopsService } from '@luggagehero/shared/services/shops';
import { SharedStorageService } from '@luggagehero/shared/services/storage';
import { TranslateService } from '@ngx-translate/core';

import { BookingActionBaseComponent } from './booking-action.base-component';
import { BookingActionInfo } from './booking-action-info';
import { ShowReceiptActionBaseComponent } from './show-receipt-action.base-component';

@Component({ template: '' })
export abstract class ShowPickupConfirmationActionBaseComponent extends BookingActionBaseComponent {
  constructor(
    private translate: TranslateService,
    bookingService: SharedBookingService,
    shopsService: SharedShopsService,
    storageService: SharedStorageService,
    cd: ChangeDetectorRef,
  ) {
    super(bookingService, shopsService, storageService, cd);
  }

  public get luggageImage(): string {
    if (!this.booking.luggage.smartImages || this.booking.luggage.smartImages.length === 0) {
      return null;
    }
    return this.booking.luggage.smartImages[0].name;
  }

  public get showReceiptActionInfo(): BookingActionInfo {
    let titleKey: string;
    let subtitleKey: string;

    if (this.booking.paidDirectly) {
      titleKey = 'PAY_IN_SHOP';
      subtitleKey = 'SHOW_THE_RECEIPT_AND_PAY_IN_SHOP';
    } else if (this.booking.paymentPostponedAt) {
      titleKey = 'PAYMENT_FAILED';
      subtitleKey = null;
    } else {
      titleKey = 'PAYMENT_SUCCEEDED';
      subtitleKey = 'SHOW_THE_RECEIPT_TO_THE_STAFF';
    }

    return new BookingActionInfo('ShowReceiptFromPickupConfirmation')
      .withAction({ componentToShow: this.showReceiptActionComponentType })
      .withTitle(this.translate.instant(titleKey) as string)
      .withSubtitle(subtitleKey && (this.translate.instant(subtitleKey) as string))
      .withState(this.booking.paidDirectly || this.booking.paymentPostponedAt ? 'warning' : 'success');
  }

  protected abstract get showReceiptActionComponentType(): Type<ShowReceiptActionBaseComponent>;

  public abstract showLuggageImage(e: Event): void;

  public showReceipt() {
    void this.done();
    this.requestAction(this.showReceiptActionInfo);
  }

  public async done() {
    if (this.booking.state.showReceiptDone) {
      this.close();
      return;
    }

    this.isLoading = true;
    this.booking.state.showReceiptDone = true;

    try {
      const res = await this.bookingService.updateState(this.booking);
      this.updateBooking(res);
    } catch (err) {
      // TODO: Handle errors
    }

    this.isLoading = false;
    this.close();
  }
}
