@if (nativeLookAndFeel) {
  <ion-item>
    @if (floatingLabel) {
      <ion-label position="floating" [ariaLabel]="ariaLabel">{{ label }}</ion-label>
      <ion-input
        [type]="type"
        [autocomplete]="autocomplete"
        [value]="value"
        (ionInput)="onInput($event)"
        (ionFocus)="onFocus($event)"
        (ionBlur)="onBlur()"
        [placeholder]="placeholder"
        [ariaPlaceholder]="ariaPlaceholder"
        [disabled]="disabled"
        #inputElement
      ></ion-input>
    } @else {
      <ion-input
        [type]="type"
        [autocomplete]="autocomplete"
        [value]="value"
        (ionInput)="onInput($event)"
        (ionFocus)="onFocus($event)"
        (ionBlur)="onBlur()"
        [label]="label"
        [ariaLabel]="ariaLabel"
        [placeholder]="placeholder"
        [ariaPlaceholder]="ariaPlaceholder"
        [disabled]="disabled"
        #inputElement
      ></ion-input>
    }
  </ion-item>
} @else if (tailwind) {
  <div class="tw-pb-1">
    @if (label) {
      <div class="tw-flex tw-justify-between tw-items-center">
        <label class="tw-py-2 tw-font-bold" [ariaLabel]="ariaLabel" [for]="id">{{ label }}</label>
        <div class="tw-text-sm">
          <ng-content select="[top-right]"></ng-content>
        </div>
      </div>
    }
    <input
      [id]="id"
      [type]="type"
      [autocomplete]="autocomplete"
      [value]="value"
      (input)="onInput($event)"
      (focus)="onFocus($event)"
      (blur)="onBlur()"
      [placeholder]="placeholder"
      [ariaPlaceholder]="ariaPlaceholder"
      [required]="required"
      [disabled]="disabled"
      [tabindex]="tabindex"
      class="tw-bg-white tw-border tw-border-solid tw-border-neutral-300 tw-text-neutral-950 tw-text-sm tw-rounded-md focus:tw-outline-none focus-visible:tw-outline-none focus:tw-border-orange focus-visible:tw-border-orange tw-block tw-w-full tw-p-2 dark:tw-bg-gray-700 dark:tw-border-gray-600 dark:tw-placeholder-gray-400 dark:tw-text-white dark:focus:tw-ring-primary dark:focus:tw-border-primary dark:focus-visible:tw-ring-primary dark:focus-visible:tw-border-primary"
      #inputElement
    />
  </div>
} @else {
  @if (label) {
    <label [for]="id">{{ label }}</label>
  }
  <input
    [id]="id"
    [type]="type"
    [autocomplete]="autocomplete"
    [value]="value"
    (input)="onInput($event)"
    (focus)="onFocus($event)"
    (blur)="onBlur()"
    [placeholder]="placeholder"
    [ariaPlaceholder]="ariaPlaceholder"
    [required]="required"
    [disabled]="disabled"
    class="form-control"
    #inputElement
  />
}
