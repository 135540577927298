import { DateComponents, TaxItem, TaxItemResponse } from '@luggagehero/shared/interfaces';

function extractDateComponents(date: Date | string): DateComponents {
  const parsedDate = typeof date === 'string' ? new Date(date) : date;
  return {
    day: parsedDate.getDate(),
    month: parsedDate.getMonth(),
    year: parsedDate.getFullYear(),
  };
}

export function getAPIUpsertPayload(taxItem: TaxItem) {
  return {
    ...taxItem,
    rates: taxItem.rates.map(({ rate, validFrom }) => ({
      rate: rate / 100,
      validFrom: extractDateComponents(validFrom),
    })),
  };
}
export function taxItemFromAPIResponse(taxItem: TaxItemResponse): TaxItem {
  return {
    ...taxItem,
    rates: taxItem.rates.map(({ rate, validFrom }) => ({
      rate: rate * 100,
      validFrom: new Date(validFrom.year, validFrom.month, validFrom.day),
    })),
  };
}
