// taxes.reducer.ts
import { createReducer, on } from '@ngrx/store';

import * as Actions from './storage-locations-setup.actions';
import { initialState } from './storage-locations-setup.model';

export const storageLocationsSetupReducer = createReducer(
  initialState,
  on(Actions.createHotelsHeroStorageLocation, (state) => ({ ...state, isLoading: true, error: null })),
  on(Actions.createHotelsHeroStorageLocationSuccess, (state) => ({ ...state, isLoading: false })),
  on(Actions.createHotelsHeroStorageLocationFailure, (state, action) => {
    const error = action.error;
    if (
      typeof error === 'object' &&
      error != null &&
      'error' in error &&
      typeof error.error === 'object' &&
      error.error != null &&
      'message' in error.error &&
      typeof error.error.message === 'string'
    ) {
      return {
        ...state,
        error: error.error.message,
        isLoading: false,
      };
    }
    return {
      ...state,
      error: 'An error occurred',
      isLoading: false,
    };
  }),
);
