/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */

import { inject, Injectable } from '@angular/core';
import {
  IPaymentRecord,
  ITranslateService,
  PaymentMethodTransformerService,
  TRANSLATE_SERVICE,
} from '@luggagehero/shared/interfaces';
import { Card } from '@stripe/stripe-js';
interface StripeCard extends Card {
  wallet: { type: 'google_pay' | 'apple_pay' };
}
const HIDDEN_4 = '••••';
@Injectable({
  providedIn: 'root',
})
export class SharedPaymentMethodTransformerService implements PaymentMethodTransformerService {
  private monthKeys = [
    'JANUARY',
    'FEBRUARY',
    'MARCH',
    'APRIL',
    'MAY',
    'JUNE',
    'JULY',
    'AUGUST',
    'SEPTEMBER',
    'OCTOBER',
    'NOVEMBER',
    'DECEMBER',
  ];
  private translateService = inject<ITranslateService>(TRANSLATE_SERVICE);

  transform(value: IPaymentRecord, format = 'summary', html = false): string {
    if (!value) {
      return '';
    }

    switch (value.provider.toLowerCase()) {
      case 'stripe':
        return this.transformStripe(value, format, html);

      case 'paypal':
        return this.transformPayPal(value, format, html);

      case 'direct_payment':
        return this.transformDirectPayment(value, format, html);
    }
    return '';
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  private transformDirectPayment(value: IPaymentRecord, format: string, html: boolean): string {
    return this.translateService.instant('PAYMENT_METHOD_DIRECT');
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private transformStripe(value: IPaymentRecord<any>, type: string, html: boolean): string {
    //
    // For legacy payment methods, the data property contains the card object, but for newer payment methods created
    // via payment/setup intents, the payment method data is nested inside a property by the same name as the type of
    // the payment method
    //
    const isLegacy = value.data.object !== 'payment_method';
    const paymentMethodType = isLegacy ? value.data.object : value.data.type;
    const paymentMethodData = isLegacy ? value.data : value.data[value.data.type];

    switch (paymentMethodType) {
      case 'card':
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        return this.transformStripeCard(paymentMethodData, type, html);
      case 'paypal':
        return 'PayPal';
    }
    return '';
  }

  private transformStripeCard(card: StripeCard, format: string, html: boolean): string {
    // if (card.wallet) {
    //   switch (card.wallet.type) {
    //     case 'apple_pay':
    //       return 'Apple Pay';

    //     case 'google_pay':
    //       return 'Google Pay';
    //   }
    // }

    switch (format) {
      case 'summary': {
        return `${card.brand.toUpperCase()} ${this.translateService.instant('ENDING_IN')} ${card.last4}`;
      }
      case 'number': {
        const cardNumberComponents = [HIDDEN_4, HIDDEN_4, HIDDEN_4, card.last4];
        return html
          ? cardNumberComponents.map((c) => `<span class="ps-8">${c}</span>`).join('')
          : cardNumberComponents.join(' ');
      }
      case 'expiry': {
        const validUntil = this.translateService.instant('VALID_UNTIL');
        const month = this.translateService.instant(this.monthKeys[card.exp_month - 1]);
        return `${validUntil} ${month} ${card.exp_year.toString()}`;
      }
      case 'expiryShort': {
        const mm = +card.exp_month < 10 ? '0' + card.exp_month : card.exp_month;
        let yy = card.exp_year.toString();
        yy = yy.substring(yy.length - 2);
        return `${mm}/${yy}`;
      }
      case 'expiryMedium': {
        const validUntil = this.translateService.instant('VALID_UNTIL');
        const mm = +card.exp_month < 10 ? '0' + card.exp_month : card.exp_month;
        let yy = card.exp_year.toString();
        yy = yy.substring(yy.length - 2);
        return `${validUntil} ${mm}/${yy}`;
      }
      case 'brand': {
        return card.brand;
      }
    }
    return '';
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  private transformPayPal(paymentMethod: IPaymentRecord, format?: string, html?: boolean): string {
    // TODO: Implement support for PayPal
    return '';
  }
}
