import { ChangeDetectionStrategy, Component } from '@angular/core';
import { PaypalButtonBaseComponent } from '@luggagehero/features/ui';

@Component({
  selector: 'lh-paypal-button',
  templateUrl: './paypal-button.component.html',
  styleUrls: ['./paypal-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaypalButtonComponent extends PaypalButtonBaseComponent {}
