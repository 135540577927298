import { ChangeDetectorRef, Component, inject, Input } from '@angular/core';
import { BookableStorageLocation, StorageLocationItemBadge } from '@luggagehero/shared/interfaces';

import { BaseComponent } from '../../../core';

export interface StorageLocationItemAction {
  callToAction: string;
  isPrimary?: boolean;
  onClick: (event: Event) => void;
}

@Component({ template: '' })
export abstract class StorageLocationItemBaseComponent extends BaseComponent {
  @Input() public locationType: string;
  @Input() public title: string;
  @Input() public isRecommended: boolean;
  @Input() public averageRating: number;
  @Input() public index: number;
  @Input() public numberOfReviews: number;
  @Input() public showAverageRating: boolean;
  @Input() public showNumberOfReviews: boolean;
  @Input() public isOpen: boolean;
  @Input() public openingHours: string;
  @Input() public nextOpenDay: Date;
  @Input() public opensAt: string;
  @Input() public closesAt: string;
  @Input() public isFullyBooked: boolean;
  @Input() public locationInfo: string;
  @Input() public holidayNoticeHtml: string;
  @Input() public isMultiDayStorageAllowed = true;
  @Input() public footnotePart1Html: string;
  @Input() public footnotePart2Html: string;
  @Input() public footnoteTooltipHeaderHtml: string;
  @Input() public footnoteTooltipBodyHtml: string;
  @Input() public badges: StorageLocationItemBadge[];
  @Input() public secondaryActionsMenuTitle: string;
  @Input() public compactMode = false;
  @Input() public size: 'normal' | 'large' = 'normal';

  public primaryActions: StorageLocationItemAction[];
  public secondaryActions: StorageLocationItemAction[];

  private cd = inject(ChangeDetectorRef);

  private _storageLocation: BookableStorageLocation;
  private _actions: StorageLocationItemAction[] = [];

  public get storageLocation(): BookableStorageLocation {
    return this._storageLocation;
  }
  @Input() public set storageLocation(value: BookableStorageLocation) {
    this._storageLocation = value;
    this._markForCheck();
  }

  public get actions(): StorageLocationItemAction[] {
    return this._actions;
  }
  @Input() public set actions(value: StorageLocationItemAction[]) {
    this._actions = value;

    this.primaryActions = value.filter((action) => action.isPrimary);
    this.secondaryActions = value.filter((action) => !action.isPrimary);

    this.cd.markForCheck();
  }

  public get isBestChoice(): boolean {
    if (!this.storageLocation || !this.storageLocation.tags) {
      return false;
    }
    return this.storageLocation.tags.includes('Recommended');
  }

  public get isFooterVisible(): boolean {
    if (this.footnotePart1Html || this.footnotePart2Html) {
      return true;
    }

    if (this.primaryActions?.length > 0 || this.secondaryActions?.length > 0) {
      return true;
    }

    return false;
  }
}
