<div class="pricing-model-options">
  <button
    id="selectHourlyPricing"
    class="btn btn-default"
    [class.simple]="simpleMode"
    [class.selected]="(pricingModel | async) === 'hourly'"
    (click)="setPricingModel('hourly')"
  >
    <div *ngIf="simpleMode" class="btn-title-lg" [class.margin-bottom]="hourlyRate">
      {{ 'PRICING_MODEL_HOURLY' | translate }}
    </div>
    <div *ngIf="simpleMode && hourlyRate" class="btn-desc-lg">
      {{ hourlyRate | price : currency }}/{{ 'HOUR' | translate }}/{{ 'BAG' | translate | lowercase }}
    </div>
    <div *ngIf="!simpleMode" class="btn-title">{{ 'PAY_AS_YOU_GO' | translate }}</div>
    <div *ngIf="!simpleMode" class="btn-desc">{{ 'PAY_AS_YOU_GO_EXPLANATION' | translate }}</div>
  </button>
  <button
    id="selectDailyPricing"
    class="btn btn-default"
    [class.simple]="simpleMode"
    [class.selected]="(pricingModel | async) === 'daily'"
    (click)="setPricingModel('daily')"
  >
    <div *ngIf="simpleMode" class="btn-title-lg" [class.margin-bottom]="dailyRate">
      {{ 'PRICING_MODEL_DAILY' | translate }}
    </div>
    <div *ngIf="simpleMode && dailyRate" class="btn-desc-lg">
      {{ dailyRate | price : currency }}/{{ 'DAY' | translate }}/{{ 'BAG' | translate | lowercase }}
    </div>
    <div *ngIf="!simpleMode" class="btn-title">{{ 'FLAT_RATE' | translate }}</div>
    <div *ngIf="!simpleMode" class="btn-desc">{{ 'FLAT_RATE_EXPLANATION' | translate }}</div>
  </button>
</div>
