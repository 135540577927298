import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Optional } from '@luggagehero/shared/interfaces';

@Component({
  selector: 'lh-traveler-shops-ui-holiday-notice',
  standalone: true,
  imports: [CommonModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['traveler-shops-ui-holiday-notice.component.scss'],
  templateUrl: 'traveler-shops-ui-holiday-notice.component.html',
})
export class TravelerShopsUiHolidayNoticeComponent {
  @Input() html: Optional<string>;
}
