import { LocationStrategy } from '@angular/common';
import { Injectable } from '@angular/core';
import { NavigationExtras, Router, RouterState, UrlTree } from '@angular/router';

export interface ExtendedNavigationExtras extends NavigationExtras {
  // Options for nativescript
  clearHistory?: boolean;
  animated?: boolean;
  transition?: {
    // See -> https://docs.nativescript.org/api-reference/interfaces/_ui_frame_.navigationtransition.html
    name?: string;
    instance?: unknown;
    duration?: number;
    curve?: unknown;
  };
  // END - Options for nativescript
}

export interface IRouterExtensions {
  readonly routerState: RouterState;
  navigate(commands: Array<unknown>, extras?: ExtendedNavigationExtras): Promise<boolean>;
  navigateByUrl(url: string | UrlTree, options?: ExtendedNavigationExtras): Promise<boolean>;
  back(): void;
}

@Injectable()
export class RouterExtensions implements IRouterExtensions {
  constructor(
    public router: Router,
    private locationStrategy: LocationStrategy,
  ) {}

  public get routerState() {
    return this.router.routerState;
  }

  public navigate(commands: Array<unknown>, extras?: ExtendedNavigationExtras): Promise<boolean> {
    return this.router.navigate(commands, extras);
  }

  public navigateByUrl(url: string | UrlTree, extras?: ExtendedNavigationExtras): Promise<boolean> {
    return this.router.navigateByUrl(url, extras);
  }

  public back() {
    this.locationStrategy.back();
  }
}
