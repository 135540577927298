import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  inject,
  Input,
  Output,
} from '@angular/core';
import { IonButton } from '@ionic/angular/standalone';
import { twMerge } from '@luggagehero/shared/util';

import { SharedUiSpinnerComponent } from '../spinner/shared-ui-spinner.component';

const ClassMap: { [style in SharedUiButtonStyle]: string } = {
  default: 'tw-bg-gray-100 tw-text-black',
  primary: 'tw-bg-primary tw-text-white',
  success: 'tw-bg-success tw-text-white',
  warning: 'tw-bg-warning tw-text-white tw-bg-orange hover:tw-bg-orange-dark',
  danger: 'tw-bg-danger tw-text-white',
  info: 'tw-bg-white tw-text-black tw-border tw-border-solid tw-border-info-100',
  link: 'tw-bg-transparent tw-text-primary',
};

const SizeMap: { [style in SharedUiButtonSize]: string } = {
  small: 'tw-px-3 tw-py-2 tw-text-xs',
  medium: 'tw-px-6 tw-py-3 tw-text-base',
  large: 'tw-px-8 tw-py-6 tw-text-lg',
};

const VariantMap: { [style in SharedUiButtonVariant]: string } = {
  ghost: 'tw-bg-transparent tw-text-current hover:tw-bg-gray-100 tw-border-none',
  outline: 'tw-border tw-border-current tw-bg-transparent hover:tw-bg-gray-50',
  filled: '',
};

export type SharedUiButtonSize = 'small' | 'medium' | 'large';
export type SharedUiButtonStyle = 'default' | 'primary' | 'success' | 'warning' | 'danger' | 'info' | 'link';
export type SharedUiButtonIcon = 'forward' | 'back';
export type SharedUiButtonVariant = 'filled' | 'ghost' | 'outline';

@Component({
  selector: 'lh-shared-ui-button',
  standalone: true,
  imports: [CommonModule, IonButton, SharedUiSpinnerComponent],
  templateUrl: './shared-ui-button.component.html',
  styleUrl: './shared-ui-button.component.scss',
  changeDetection: ChangeDetectionStrategy.Default, // TODO: Change to OnPush
  host: { class: 'tw-block' },
})
export class SharedUiButtonComponent {
  @Input() public type: HTMLButtonElement['type'] = 'button';
  @Input() public shadow = true;
  @Input() public block = false;
  @Input() public round = false;
  @Input() public rounded = false;
  @Input() public selected = false;
  @Input() public disabled = false;
  @Input() public nativeLookAndFeel = false;
  @Input() public size: SharedUiButtonSize = 'medium';
  @Input() public style: SharedUiButtonStyle = 'primary';
  @Input() public icon: SharedUiButtonIcon | null = null;
  @Input() public variant: SharedUiButtonVariant = 'filled';
  @Input() public tailwind = false;
  @Input() public textAlign: 'left' | 'center' | 'right' = 'center';

  @Output() public readonly press = new EventEmitter<Event>();

  private _isLoading = false;

  private readonly cd = inject(ChangeDetectorRef);

  public get isLoading(): boolean {
    return this._isLoading;
  }
  @Input() public set isLoading(value: boolean) {
    this._isLoading = value;
    this.cd.markForCheck();
  }

  public get nativeButtonColor(): string {
    switch (this.style) {
      case 'primary':
        return 'primary';

      case 'success':
        return 'success';

      case 'warning':
        return 'warning';

      case 'danger':
        return 'danger';

      case 'info':
        return 'white';

      case 'link':
        return 'primary';

      case 'default':
      default:
        return 'light';
    }
  }

  public get nativeButtonFill(): 'clear' | 'outline' | 'solid' {
    switch (this.style) {
      case 'info':
        return 'outline';

      default:
        return 'solid';
    }
  }

  public get nativeButtonSize(): 'small' | 'default' | 'large' {
    switch (this.size) {
      case 'small':
        return 'small';

      case 'large':
        return 'large';

      case 'medium':
      default:
        return 'default';
    }
  }

  public onClick(event: Event): void {
    if (this.disabled || this.isLoading) {
      event.preventDefault();
      event.stopPropagation();
      return;
    }
    this.press.emit(event);
  }

  public get tailwindClasses(): string {
    const classes = [
      'tw-rounded-lg',
      'tw-font-medium tw-inline-flex tw-justify-center tw-items-center tw-gap-4',
      'tw-transition-colors tw-transition-all tw-duration-200',
      SizeMap[this.size],
    ];
    const conditionalClasses: string[] = [];

    if (this.block) {
      conditionalClasses.push('tw-w-full');
    }

    if (this.disabled === true) {
      conditionalClasses.push('tw-cursor-not-allowed');
    } else {
      conditionalClasses.push(ClassMap[this.style]);
      if (this.variant) {
        conditionalClasses.push(VariantMap[this.variant]);
      }
    }

    return twMerge([...classes, ...conditionalClasses].join(' '));
  }
}
