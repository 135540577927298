/* eslint-disable @angular-eslint/no-output-native */
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { IonInput, IonItem, IonLabel } from '@ionic/angular/standalone';
import { SharedUtilGuid } from '@luggagehero/shared/util';

@Component({
  selector: 'lh-shared-ui-input',
  standalone: true,
  imports: [CommonModule, FormsModule, IonInput, IonItem, IonLabel, ReactiveFormsModule],
  templateUrl: './shared-ui-input.component.html',
  styleUrl: './shared-ui-input.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: SharedUiInputComponent,
      multi: true,
    },
  ],
  host: { class: 'tw-block' },
})
export class SharedUiInputComponent implements ControlValueAccessor, OnChanges {
  @ViewChild('inputElement') public input?: IonInput | ElementRef<HTMLInputElement>;

  @Output() public readonly focus = new EventEmitter<Event>();
  @Output() public readonly blur = new EventEmitter<Event>();
  @Output() public readonly valueChange = new EventEmitter<string>();

  @Input() public id = SharedUtilGuid.create();
  @Input() public type = 'text';
  @Input() public autocomplete: string | undefined;
  @Input() public placeholder = '';
  @Input() public ariaPlaceholder = '';
  @Input() public label = '';
  @Input() public ariaLabel = '';
  @Input() public floatingLabel = false;
  @Input() public nativeLookAndFeel = false;
  @Input() public required = false;
  @Input() public disabled = false;
  @Input() public tailwind = false;
  @Input() public tabindex: number | undefined;

  public value = '';

  private cd = inject(ChangeDetectorRef);

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['disabled']) {
      this.setDisabledState(this.disabled);
    }
  }

  public onInput(event: Event): void {
    if (this.disabled) {
      return;
    }
    const target = event.target as HTMLInputElement;
    this.writeValue(target.value);
    this.onChange(this.value);
  }

  public onFocus(event: Event): void {
    this.focus.emit(event);
  }

  public onBlur(): void {
    this.onTouched();
  }

  public onChange = (_value: string) => {
    this.valueChange.emit(_value);
  };
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  public onTouched = () => {};

  public writeValue(value: string): void {
    this.value = value;
    this.cd.markForCheck();
  }

  public registerOnChange(fn: (value: string) => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    // If [disabled] input is set, it takes precedence
    if (this.disabled !== undefined) {
      isDisabled = this.disabled;
    }
    this.disabled = isDisabled;
    this.cd.markForCheck();
  }

  public setFocus(): void {
    if (!this.input) {
      return;
    }
    if (this.input instanceof IonInput) {
      void this.input.setFocus();
    } else {
      this.input.nativeElement.focus();
    }
  }
}
