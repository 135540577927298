import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { PricingModel } from '@luggagehero/shared/interfaces';
import {
  SelectOptionButtonGroupOption,
  SharedUiSelectOptionButtonGroupComponent,
  SharedUiSpinnerComponent,
} from '@luggagehero/shared/ui';
import { SharedUtilList, SharedUtilOrder } from '@luggagehero/shared/util';

import { GuestDropoffStepBaseComponent } from '../guest-dropoff-step-base-component';
import { StorageSelectionStepProperties } from './storage-selection-step-properties';

@Component({
  selector: 'lh-storage-selection-step',
  standalone: true,
  imports: [CommonModule, SharedUiSelectOptionButtonGroupComponent, SharedUiSpinnerComponent],
  templateUrl: './storage-selection-step.component.html',
  styleUrl: './storage-selection-step.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StorageSelectionStepComponent extends GuestDropoffStepBaseComponent<StorageSelectionStepProperties> {
  public pricingModelOptions: SelectOptionButtonGroupOption<PricingModel>[] = [];

  protected get defaultProperties(): StorageSelectionStepProperties {
    return {
      header: 'SELECT_STORAGE',
      body: ['CHOOSE_WHAT_KIND_OF_STORAGE_YOU_PREFER'],
      enableHourlyRate: true,
      enableDailyRate: true,
      defaultPricingModel: 'hourly',
    };
  }

  private get isStorageFree(): boolean {
    if (!this.dailyOrder || !this.hourlyOrder) {
      return false;
    }
    if (
      SharedUtilOrder.perBagStorageRate(this.dailyOrder) > 0 ||
      SharedUtilOrder.perBagStorageRate(this.hourlyOrder) > 0
    ) {
      return false;
    }
    return true;
  }

  protected async onInit(): Promise<void> {
    if (!this.luggage) {
      this.luggage = { normal: 1, hand: 0 };
    }

    // TODO: Handle this differently
    await this.updateOrders();

    this.updateOptions();
  }

  private updateOptions(): void {
    if (!this.dailyOrder || !this.hourlyOrder) {
      return;
    }

    const options: SelectOptionButtonGroupOption<PricingModel>[] = [];
    SharedUtilList.appendIfNotNullOrUndefined(options, this.createPricingModelOption('hourly'));
    SharedUtilList.appendIfNotNullOrUndefined(options, this.createPricingModelOption('daily'));

    if (options.length === 0) {
      // No pricing model to be selected (e.g. if storage is free)
      this.pricingModel = 'daily';
      void this.autoSkip();
      return;
    }

    this.pricingModelOptions = options;

    this.setDefaultPricingModel();
  }

  private createPricingModelOption(pricingModel: PricingModel): SelectOptionButtonGroupOption<PricingModel> {
    if (
      (pricingModel === 'daily' && !this.properties.enableDailyRate) ||
      (pricingModel === 'hourly' && !this.properties.enableHourlyRate)
    ) {
      // Don't show pricing models that are disabled as an option
      return null;
    }

    const isDaily = pricingModel === 'daily';

    const order = isDaily ? this.dailyOrder : this.hourlyOrder;
    const storageRateAmount = SharedUtilOrder.perBagStorageRate(order);

    if (storageRateAmount === 0) {
      // Don't show pricing models with a rate of 0 as an option
      return null;
    }

    const storageRate = this.pricingService.format(storageRateAmount, order.currency);

    // const serviceFeeAmount = SharedUtilOrder.perBagServiceFee(order);
    // const serviceFee = this.pricingService.format(serviceFeeAmount, order.currency);

    const perBag = this.translate('BAG').toLowerCase();

    return {
      value: pricingModel,
      primaryContent: {
        title: isDaily ? 'DAY_STORAGE' : 'PAY_AS_YOU_GO_DASH',
        paragraphs: isDaily ? ['PAY_FIXED_PRICE_FOR_FULL_DAY_STORAGE'] : ['PAY_BY_THE_HOUR_ONLY_FOR_WHAT_YOU_NEED'],
      },
      secondaryContent: {
        title: `${storageRate}/${perBag}`,
        // paragraphs: [`+ ${serviceFee}/${perBag} ${serviceFee}`],
      },
    };
  }

  private setDefaultPricingModel(): void {
    // Check if coverage is already set or no default is provided
    if (this.pricingModel !== undefined || this.properties.defaultPricingModel === undefined) {
      this.cd.markForCheck(); // Just make sure the view is updated
      return;
    }

    if (this.pricingModelOptions.length === 1) {
      // If there is only one option, select it by default
      this.pricingModel = this.pricingModelOptions[0].value;
      return;
    }

    // Select the default pricing model provided if a matching option is found
    this.pricingModel = this.pricingModelOptions.find((o) => o.value === this.properties.defaultPricingModel)?.value;
  }

  protected onCheckCanGoForward(): boolean {
    if (this.isStorageFree) {
      // If storage is free, we can go forward without selecting a pricing model
      return true;
    }

    if (this.pricingModel) {
      // When a pricing model is selected, we can go forward
      return true;
    }

    return false;
  }

  protected onCheckCanSkip(): boolean {
    return this.onCheckCanGoForward();
  }
}
