import {
  CANCELLATION_FEE_PRODUCT_KEY,
  FREE_CANCELLATION_FEE_PRODUCT_KEY,
  GUEST_DAILY_RATE_PRODUCT_KEY,
  GUEST_HOURLY_RATE_PRODUCT_KEY,
  GUEST_INSURANCE_FEE_PRODUCT_KEY,
  GUEST_PREMIUM_INSURANCE_FEE_PRODUCT_KEY,
  GUEST_SERVICE_FEE_PRODUCT_KEY,
  INSURANCE_PRODUCT_KEY,
  PREMIUM_INSURANCE_PRODUCT_KEY,
  Product,
  ProductInfo,
  SERVICE_FEE_PRODUCT_KEY,
  STORAGE_DAY_PRODUCT_KEY,
  STORAGE_HOUR_PRODUCT_KEY,
  TIP_PRODUCT_KEY,
  WALK_IN_DAILY_RATE_PRODUCT_KEY,
  WALK_IN_HOURLY_RATE_PRODUCT_KEY,
  WALK_IN_INSURANCE_FEE_PRODUCT_KEY,
  WALK_IN_SERVICE_FEE_PRODUCT_KEY,
} from '@luggagehero/shared/interfaces';

//
// Lookup tables for product keys
//
const SERVICE_FEE_PRODUCT_KEYS = [
  SERVICE_FEE_PRODUCT_KEY,
  WALK_IN_SERVICE_FEE_PRODUCT_KEY,
  GUEST_SERVICE_FEE_PRODUCT_KEY,
];
const HOURLY_STORAGE_FEE_PRODUCT_KEYS = [
  WALK_IN_HOURLY_RATE_PRODUCT_KEY,
  GUEST_HOURLY_RATE_PRODUCT_KEY,
  STORAGE_HOUR_PRODUCT_KEY,
];
const DAILY_STORAGE_FEE_PRODUCT_KEYS = [
  WALK_IN_DAILY_RATE_PRODUCT_KEY,
  GUEST_DAILY_RATE_PRODUCT_KEY,
  STORAGE_DAY_PRODUCT_KEY,
];
const STORAGE_FEE_PRODUCT_KEYS = [...HOURLY_STORAGE_FEE_PRODUCT_KEYS, ...DAILY_STORAGE_FEE_PRODUCT_KEYS];
const INSURANCE_FEE_PRODUCT_KEYS = [
  INSURANCE_PRODUCT_KEY,
  WALK_IN_INSURANCE_FEE_PRODUCT_KEY,
  GUEST_INSURANCE_FEE_PRODUCT_KEY,
  GUEST_PREMIUM_INSURANCE_FEE_PRODUCT_KEY,
  PREMIUM_INSURANCE_PRODUCT_KEY,
];
const CANCELLATION_FEE_PRODUCT_KEYS = [CANCELLATION_FEE_PRODUCT_KEY, FREE_CANCELLATION_FEE_PRODUCT_KEY];
const TIP_PRODUCT_KEYS = [TIP_PRODUCT_KEY];

const isProductInfo = (value: Product | ProductInfo): value is ProductInfo => {
  return typeof (value as ProductInfo).key === 'string';
};

export abstract class SharedUtilProduct {
  public static getProductKey(value: Product | ProductInfo): string {
    return isProductInfo(value) ? value.key : value._id || '';
  }

  public static isServiceFee(value: Product | ProductInfo): boolean {
    if (!value) {
      return false;
    }
    return SERVICE_FEE_PRODUCT_KEYS.includes(this.getProductKey(value));
  }

  public static isStorageFee(value: Product | ProductInfo): boolean {
    if (!value) {
      return false;
    }
    return STORAGE_FEE_PRODUCT_KEYS.includes(this.getProductKey(value));
  }

  public static isHourlyStorageFee(value: Product | ProductInfo): boolean {
    if (!value) {
      return false;
    }
    return HOURLY_STORAGE_FEE_PRODUCT_KEYS.includes(this.getProductKey(value));
  }

  public static isDailyStorageFee(value: Product | ProductInfo): boolean {
    if (!value) {
      return false;
    }
    return DAILY_STORAGE_FEE_PRODUCT_KEYS.includes(this.getProductKey(value));
  }

  public static isInsuranceFee(value: Product | ProductInfo): boolean {
    if (!value) {
      return false;
    }
    return INSURANCE_FEE_PRODUCT_KEYS.includes(this.getProductKey(value));
  }

  public static isTip(value: Product | ProductInfo): boolean {
    if (!value) {
      return false;
    }
    return TIP_PRODUCT_KEYS.includes(this.getProductKey(value));
  }

  public static isCancellationFee(value: Product | ProductInfo): boolean {
    if (!value) {
      return false;
    }
    return CANCELLATION_FEE_PRODUCT_KEYS.includes(this.getProductKey(value));
  }
}
