import { ChangeDetectorRef, Component, inject, OnInit } from '@angular/core';
import { SharedAppSettingsService } from '@luggagehero/shared/app-settings/data-access';
import { Booking, BOOKING_TRANSFORMATION_SERVICE } from '@luggagehero/shared/interfaces';
import { SharedBookingService } from '@luggagehero/shared/services/bookings';
import { SharedDocumentService } from '@luggagehero/shared/services/document';
import { SharedMomentService } from '@luggagehero/shared/services/moment';
import { SharedUserService } from '@luggagehero/shared/services/users';
import { TranslateService } from '@ngx-translate/core';

import { BaseComponent } from '../../../core';
import { RouterExtensions } from '../../../core/services/index';
import { ListGroup, ListItem, ListItemIcon, ListItemLabel, UiStyle } from '../../ui';

const BookingStatus = {
  CONFIRMED: 'CONFIRMED',
  CANCELLED: 'CANCELLED',
  CHECKED_IN: 'CHECKED_IN',
  CHECKED_OUT: 'CHECKED_OUT',
  PAID: 'PAID',
};

@Component({ template: '' })
export abstract class BookingsUserBaseComponent extends BaseComponent implements OnInit {
  public groupedBookings: ListGroup<Booking>[];
  public activeTab = '';
  public error = false;
  public errorMsg: string;

  private visibleBookings: Booking[];
  private _isLoading = false;
  private _bookings: Booking[];

  private appSettings = inject(SharedAppSettingsService);
  protected momentService = inject(SharedMomentService);
  protected bookingTansformationService = inject(BOOKING_TRANSFORMATION_SERVICE);

  constructor(
    protected bookingService: SharedBookingService,
    protected userService: SharedUserService,
    protected translate: TranslateService,
    protected router: RouterExtensions,
    protected document: SharedDocumentService,
    protected cd: ChangeDetectorRef,
  ) {
    super();
  }

  get bookings() {
    return this._bookings;
  }
  set bookings(val: Booking[]) {
    this._bookings = val;
  }

  get isLoading(): boolean {
    return this._isLoading;
  }
  set isLoading(value: boolean) {
    this._isLoading = value;
    this.cd.markForCheck();
  }

  get isShopOwner(): boolean {
    if (!this.userService.isLoggedIn) {
      return false;
    }
    return this.userService.user && this.userService.user.isShopOwner ? true : false;
  }

  get isModernBookingListEnabled(): boolean {
    return this.appSettings.current?.IS_MODERN_TRAVELER_BOOKING_LIST_ENABLED || true;
  }

  ngOnInit() {
    void this.loadBookings();
  }

  public navigateToBooking(booking: Booking) {
    void this.router.navigate([`bookings/${booking._id}`]);
  }

  protected async loadBookings(): Promise<void> {
    this.isLoading = true;
    try {
      this.bookings = await this.bookingService.getBookingsByCurrentUser();

      this.showActiveBookings();
    } catch (err) {
      // TODO: Don't show internal errors to the user
      this.error = true;
      this.errorMsg = err as string;
    }
    this.isLoading = false;
  }

  private showActiveBookings() {
    this.activeTab = 'active';
    this.filterBookings(BookingStatus.CONFIRMED, BookingStatus.CHECKED_IN);
  }

  private showCompletedBookings() {
    this.activeTab = 'completed';
    this.filterBookings(BookingStatus.CHECKED_OUT, BookingStatus.PAID);
  }

  private showCanceledBookings() {
    this.activeTab = 'canceled';
    this.filterBookings(BookingStatus.CANCELLED);
  }

  private showAllBookings() {
    this.activeTab = 'all';
    this.filterBookings();
  }

  private filterBookings(...statuses: string[]) {
    if (!statuses || statuses.length === 0) {
      this.visibleBookings = this.bookings;
    } else {
      this.visibleBookings = this.bookings.filter((x) => statuses.indexOf(x.status) >= 0);
    }

    this.groupedBookings = this.groupBookings(this.visibleBookings);
  }
  private groupBookings(value: Booking[]): ListGroup<Booking>[] {
    value.sort((a, b) => {
      return a.period.checkIn.getTime() - b.period.checkIn.getTime();
    });

    const groupedBookings: ListGroup<Booking>[] = [];

    for (const booking of value) {
      // Get the group name of the current booking
      const groupName = this.momentService.formatDate(booking.period.checkIn, 'MMM DD, YYYY');

      // Get the current group
      const currentGroup = groupedBookings[groupedBookings.length - 1];

      if (currentGroup && currentGroup.name === groupName) {
        // Add current booking to the current group
        currentGroup.items.push(this.createBookingListItem(booking));
      } else {
        // Start new group with the current booking
        groupedBookings.push({
          name: groupName,
          icon: 'calendar',
          items: [this.createBookingListItem(booking)],
        });
      }
    }
    return groupedBookings;
  }

  private createBookingListItem(booking: Booking): ListItem<Booking> {
    const listItem: ListItem<Booking> = {
      title: booking.address.city,
      image: booking.luggage.smartImages?.[0]?.name,
      content: {
        strong: booking.shopName,
        normal: booking.address.street,
      },
      labels: this.getBookingLabels(booking),
      icons: [
        new ListItemIcon('bag', String(booking.luggage.normal + booking.luggage.hand)),
        new ListItemIcon('zoom-in'),
      ],
      value: booking,
      disabled: booking.status === 'CANCELLED' && !booking.incidentReported,
    };
    return listItem;
  }

  private getBookingLabels(booking: Booking): ListItemLabel[] {
    const labels: ListItemLabel[] = [];

    // Status
    if (booking.status !== 'CANCELLED') {
      const style: UiStyle =
        (booking.status === 'CHECKED_IN' && 'primary') ||
        (booking.status === 'CHECKED_OUT' && 'danger') ||
        (booking.status === 'PAID' && (booking.paidDirectly ? 'warning' : 'success')) ||
        undefined;
      const text = this.bookingTansformationService.transformBooking(booking, 'status');
      const tooltip: string = undefined;
      labels.push(new ListItemLabel(text, tooltip, style));
    }

    return labels;
  }
}
