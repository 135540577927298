import { Injectable, NgZone } from '@angular/core';
import { AppConfig } from '@luggagehero/shared/app-settings/data-access';
import { Address, BookableStorageLocation, IGoogleMapsService, Place } from '@luggagehero/shared/interfaces';
import { Observable } from 'rxjs';

const GOOGLE_MAPS_URL = 'https://www.google.com/maps';
@Injectable({
  providedIn: 'root',
})
export class SharedGoogleMapsService implements IGoogleMapsService {
  public linkToPlaces = false;
  public mapStyles = AppConfig.GOOGLE_MAPS_STYLES.DROPNLOCAL;
  public mapOptions = AppConfig.GOOGLE_MAPS_OPTIONS.DEFAULT;

  constructor(private ngZone: NgZone) {}

  geocode(request: google.maps.GeocoderRequest): Observable<google.maps.GeocoderResult[]> {
    return new Observable<google.maps.GeocoderResult[]>((observer) => {
      const geocoder = new google.maps.Geocoder();
      void geocoder.geocode(request, (results, status) => {
        this.ngZone.run(() => {
          if (status === google.maps.GeocoderStatus.OK) {
            observer.next(results);
          } else {
            observer.error(`Geocoding failed: ${status}`);
          }
          observer.complete();
        });
      });
    });
  }

  autocomplete(
    request: google.maps.places.AutocompletionRequest,
  ): Observable<google.maps.places.AutocompletePrediction[]> {
    return new Observable<google.maps.places.AutocompletePrediction[]>((observer) => {
      const autocompleteService = new google.maps.places.AutocompleteService();
      void autocompleteService.getPlacePredictions(request, (results, status) => {
        this.ngZone.run(() => {
          if (status === google.maps.places.PlacesServiceStatus.OK) {
            observer.next(results.filter((p) => this.isValidPrediction(p)));
          } else {
            observer.error(`Autocomplete failed: ${status}`);
          }
          observer.complete();
        });
      });
    });
  }

  parsePlaceResult(data: google.maps.places.PlaceResult): Place {
    if (!data.address_components) {
      return null;
    }
    const address = this.parseAddress(
      data.address_components,
      data.formatted_address,
      data.geometry.location.lat(),
      data.geometry.location.lng(),
    );
    const place: Place = {
      id: data.place_id,
      name: data.name,
      address: address,
    };
    return place;
  }

  parseGeocoderResult(value: google.maps.GeocoderResult): Place {
    if (!value.address_components) {
      return null;
    }
    const address = this.parseAddress(
      value.address_components,
      value.formatted_address,
      value.geometry.location.lat(),
      value.geometry.location.lng(),
    );
    const name = value.formatted_address ? value.formatted_address.split(',')[0] : undefined;
    const place: Place = {
      id: value.place_id,
      name: name,
      address: address,
    };
    return place;
  }

  parseAddress(
    addressComponents: google.maps.GeocoderAddressComponent[],
    formattedAddress: string,
    lat: number,
    lon: number,
  ): Address {
    if (!addressComponents) {
      return null;
    }
    const address = {} as Address;
    addressComponents.forEach((addressComponent) => {
      switch (addressComponent.types[0]) {
        case 'street_number':
          address.street = addressComponent.long_name;
          break;

        case 'route':
          address.street = address.street
            ? `${address.street} ${addressComponent.long_name}`
            : addressComponent.long_name;
          break;

        case 'locality':
        case 'postal_town':
          address.city = addressComponent.long_name;
          break;

        case 'country':
          address.country = addressComponent.long_name;
          address.countryCode = addressComponent.short_name.toLowerCase();
          address.region = address.countryCode;
          break;

        case 'postal_code':
          address.postal = addressComponent.long_name;
          break;
      }
    });
    address.formattedAddress = formattedAddress || '';
    address.location = [lon, lat];

    return address;
  }

  parseCity(addressComponents: google.maps.GeocoderAddressComponent[]): string {
    const cityComponent = addressComponents.find((comp) => comp.types[0] === 'postal_town');
    return cityComponent ? cityComponent.long_name.toLowerCase() : '';
  }

  parseRegion(addressComponents: google.maps.GeocoderAddressComponent[]): string {
    const countryComponent = addressComponents.find((comp) => comp.types[0] === 'country');
    return countryComponent ? countryComponent.short_name.toLowerCase() : '';
  }

  getDirectionsLink(storageLocation: BookableStorageLocation): string;
  getDirectionsLink(location: google.maps.LatLng): string;
  getDirectionsLink(locationOrStorageLocation: google.maps.LatLng | BookableStorageLocation): string {
    let lat: number;
    let lng: number;

    // Try to parse the location as a google.maps.LatLng first
    const location = locationOrStorageLocation as google.maps.LatLng;
    if (typeof location.lat === 'function' && typeof location.lng === 'function') {
      lat = location.lat();
      lng = location.lng();
    } else {
      // Fall back to parsing the location as a BookableStorageLocation
      const storageLocation = locationOrStorageLocation as BookableStorageLocation;
      lat = storageLocation.location.coordinates[1];
      lng = storageLocation.location.coordinates[0];
    }

    return `${GOOGLE_MAPS_URL}/dir/?api=1&destination=${lat},${lng}`;
  }

  getPlaceLink(storageLocation: BookableStorageLocation, zoom?: number): string {
    if (!storageLocation) {
      return null;
    }

    const placeId = storageLocation.place ? storageLocation.place.id : null;
    const lat = storageLocation.location.coordinates[1];
    const lng = storageLocation.location.coordinates[0];

    if (placeId) {
      //
      // TODO: Switch to links using the format below when place ID is available for the location
      // https://www.google.com/maps/search/?api=1&query=47.5951518,-122.3316393&query_place_id=ChIJKxjxuaNqkFQR3CK6O1HNNqY
      //
      return `${GOOGLE_MAPS_URL}/search/?api=1&query=${lat},${lng}&query_place_id=${placeId}&zoom=${zoom || 13}`;
    }
    // Fall back to just using coordinates
    return `${GOOGLE_MAPS_URL}/place/${lat},${lng}/@${lat},${lng},${zoom || 13}z`;
  }

  private isValidPrediction(value: google.maps.places.AutocompletePrediction): boolean {
    // Filter out big areas like New York State
    if (value.types.includes('administrative_area_level_1')) {
      return false;
    }
    return true;
  }

  private trim(value: string): string {
    const searchStrings = [' (', ' - '];
    const indexes: number[] = [];
    searchStrings.forEach((s) => {
      const index = value.indexOf(s);
      if (index > 0) {
        indexes.push(index);
      }
    });
    if (indexes.length > 0) {
      const end = Math.min(...indexes);
      value = value.substring(0, end);
    }
    return value.trim();
  }
}
