import { InjectionToken } from '@angular/core';

/**
 * A map of translations.
 */
export interface TranslationMap {
  [key: string]: string;
}

/**
 * Configuration for app translations.
 */
export interface TranslateConfig {
  /**
   * The name of the application for which to load translations.
   */
  readonly appName: string;
  /**
   * The address of the API that serves the translations.
   */
  readonly apiAddress: string;
}

/**
 * Injection token for the translation configuration.
 */
export const TRANSLATE_CONFIG = new InjectionToken<TranslateConfig>('TRANSLATE_CONFIG');
