import { Injectable } from '@angular/core';
import { AppConfig } from '@luggagehero/shared/app-settings/data-access';
import { ITranslateService } from '@luggagehero/shared/interfaces';
import { SharedStorageService } from '@luggagehero/shared/services/storage';
import { TranslateService as NgxTranslateService } from '@ngx-translate/core';
import { map, Observable } from 'rxjs';

/**
 * Service for managing translation of the application, such as setting the language to use and getting translations.
 */
@Injectable({
  providedIn: 'root',
})
export class SharedTranslateService implements ITranslateService {
  public readonly onLanguageChange: Observable<string>;

  /**
   * The current language of the application.
   */
  public get currentLanguage(): string {
    return this.ngxTranslate.currentLang;
  }

  constructor(
    private ngxTranslate: NgxTranslateService,
    private storage: SharedStorageService,
  ) {
    this.onLanguageChange = this.ngxTranslate.onLangChange.pipe(map((e) => e.lang));
  }

  /**
   * Set the language to use for translations.
   * @param value The language code to use
   * @returns An observable that emits when the language has been set
   */
  public setLanguage<T extends object>(value: string): Observable<T> {
    return this.ngxTranslate.use(value) as Observable<T>;
  }

  /**
   * Set the default language to use for translations.
   * @param value The language code to use
   */
  public setDefaultLang(value: string): void {
    this.ngxTranslate.setDefaultLang(value);
  }

  /**
   * Get a translation for the given key.
   * @param key The key of the translation to get
   * @param variantName The name of the variant to use for the translation
   * @returns An observable of the translation
   */
  public get(key: string, variantName?: string): Observable<string>;
  /**
   * Get translations for the given keys.
   * @param value The keys of the translations to get
   * @param variantName The name of the variant to use for the translations
   * @returns An observable of the translations
   */
  public get<T extends object>(value: string[], variantName?: string): Observable<T>;
  /**
   * Get translations for the given object of keys.
   * @param value The object of keys of the translations to get
   * @param variantName The name of the variant to use for the translations
   * @returns An observable of the translations
   */
  public get<T extends object>(value: T, variantName?: string): Observable<T>;
  public get<T extends object>(
    value: string | string[] | T,
    variantName?: string,
  ): Observable<string | Record<string, string>> {
    let key: string | string[];

    if (typeof value === 'string') {
      key = this.getKey(value, variantName);
    } else if (Array.isArray(value)) {
      key = value.map((k) => this.getKey(k, variantName));
    } else {
      key = Object.keys(value).map((k) => this.getKey(k, variantName));
    }

    return this.ngxTranslate.get(key) as Observable<string | Record<string, string>>;
  }

  /**
   * Stream a translation for the given key. The translation will be updated when the language changes.
   * @param key The key of the translation to stream
   * @param variantName The name of the variant to use for the translation
   * @returns An observable of the translation which keeps emitting when the language changes
   */
  public stream(key: string, variantName?: string): Observable<string>;
  /**
   * Stream translations for the given keys. The translations will be updated when the language changes.
   * @param value The keys of the translations to stream
   * @param variantName The name of the variant to use for the translations
   * @returns An observable of the translations which keeps emitting when the language changes
   */
  public stream<T extends object>(value: string[], variantName?: string): Observable<T>;
  /**
   * Stream translations for the given object of keys. The translations will be updated when the language changes.
   * @param value The object of keys of the translations to stream
   * @param variantName The name of the variant to use for the translations
   * @returns An observable of the translations which keeps emitting when the language changes
   */
  public stream<T extends object>(value: T, variantName?: string): Observable<T>;
  public stream<T extends object>(
    value: string | string[] | T,
    variantName?: string,
  ): Observable<string | Record<string, string>> {
    let key: string | string[];

    if (typeof value === 'string') {
      key = this.getKey(value, variantName);
    } else if (Array.isArray(value)) {
      key = value.map((k) => this.getKey(k, variantName));
    } else {
      key = Object.keys(value).map((k) => this.getKey(k, variantName));
    }

    return this.ngxTranslate.stream(key) as Observable<string | Record<string, string>>;
  }

  /**
   * Get a translation for the given key.
   * @param key The key of the translation to get (deprecated). This method doesn't wait for the translation to be loaded.
   * @param variantName The name of the variant to use for the translation
   * @returns The translation
   * @deprecated Use `get()` or `stream()` instead to ensure translations are loaded before use
   */
  public instant(key: string, variantName?: string): string {
    // Check if a variant is set and if the key is overridden in the variant
    const variant = AppConfig.TEXT_VARIANTS[this.storage.variant] || AppConfig.TEXT_VARIANTS[variantName];
    if (variant && variant[key]) {
      key = variant[key];
    }

    return this.ngxTranslate.instant(key) as string;
  }

  private getKey(key: string, variantName?: string): string {
    const variant = AppConfig.TEXT_VARIANTS[this.storage.variant] || AppConfig.TEXT_VARIANTS[variantName];
    return variant ? variant[key] || key : key;
  }
}
