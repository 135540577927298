<div class="best-choice-display">
  <svg width="8" height="9" viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.42398 8.29271L2.28056 5.49271L0.0147705 3.87165H2.81477L3.69898 0.924286L4.58319 3.87165H7.38319L5.1174 5.49271L5.97398 8.29271L3.69898 6.56113L1.42398 8.29271Z"
      fill="currentColor"
    />
  </svg>
  <span class="text">{{ text | translate }}</span>
  <svg width="8" height="9" viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.42398 8.29271L2.28056 5.49271L0.0147705 3.87165H2.81477L3.69898 0.924286L4.58319 3.87165H7.38319L5.1174 5.49271L5.97398 8.29271L3.69898 6.56113L1.42398 8.29271Z"
      fill="currentColor"
    />
  </svg>
</div>
