import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { FindShopParams, ILocation, LatLng, LocationType, PricingModel } from '@luggagehero/shared/interfaces';
import { SharedGeocodingService } from '@luggagehero/shared/services/geocoding';
import { SharedLocationService } from '@luggagehero/shared/services/locations';
import { SharedLoggingService } from '@luggagehero/shared/services/logging';
import { SharedPricingService } from '@luggagehero/shared/services/pricing';
import { SharedPromoCodeService } from '@luggagehero/shared/services/promo-codes';
import { SharedStorageService } from '@luggagehero/shared/services/storage';
import { SharedStorageCriteria, SharedStorageCriteriaService } from '@luggagehero/shared/services/storage-criteria';
import { SharedUtilDate } from '@luggagehero/shared/util';

@Injectable({
  providedIn: 'root',
})
export class SharedParamsService {
  static serializeNumber(value: number): string {
    return String(value).replace('.', ',');
  }

  static deserializeNumber(value: string): number {
    return Number(value.replace(',', '.'));
  }

  constructor(
    private criteriaService: SharedStorageCriteriaService,
    private promoCodeService: SharedPromoCodeService,
    private locationService: SharedLocationService,
    private storageService: SharedStorageService,
    private priceService: SharedPricingService,
    private geocodeService: SharedGeocodingService,
    private log: SharedLoggingService,
  ) {}

  get landingPageOrigin(): string {
    return (this.storageService.trackingData?.utm_landing_page_origin || '') as string;
  }

  get landingPagePath(): string {
    return (this.storageService.trackingData?.utm_landing_page_path || '') as string;
  }

  get landingPageUrl(): string {
    if (!this.landingPageOrigin) {
      return null;
    }
    return `${this.landingPageOrigin}${this.landingPagePath}`;
  }

  isValidFindShop(value: Params): boolean {
    if (!value) {
      return false;
    }
    if (value.location) {
      return true;
    }
    if (value.lon && value.lat) {
      return true;
    }
    if (value.bags) {
      return true;
    }
    if (value.expandCriteria) {
      return true;
    }
    if (value.showMap) {
      return true;
    }
    if (value.selectedShop) {
      return true;
    }
    if (value.geolocate) {
      return true;
    }
    if (value.certified) {
      return true;
    }
    if (value.ath) {
      return true;
    }
    if (value.utm_source) {
      return true;
    }
    return false;
  }

  async parseFindShop(params: Params): Promise<FindShopParams> {
    if (!this.locationService.isInitialized) {
      await this.locationService.init();
    }
    return this.parseFindShopInternal(params);
  }

  parseTrackingData(params: Params): Record<string, unknown> {
    const trackingData: Record<string, unknown> = {};

    try {
      let trackingDataFound = false;

      for (const p in params) {
        if (p.match(/^utm_/i) || p.match(/^lh_/i) || p === 'variant') {
          trackingData[p] = params[p];
          trackingDataFound = true;
        }
        if (['utm_promo_code', 'lh_promo_code', 'promo_code'].includes(p)) {
          // Pick up promo code if provided to be added to booking later
          void this.promoCodeService.applyPromoCode(params[p] as string);
        }
      }
      if (!trackingDataFound) {
        return null;
      }
      this.storageService.trackingData = trackingData;
    } catch (err) {
      void this.log.error('Error parsing tracking data', err);
    }

    return trackingData;
  }

  private async parseFindShopInternal(params: Params): Promise<FindShopParams> {
    if (!this.isValidFindShop(params)) {
      return null;
    }

    if (params.pricing) {
      // Set pricing model if provided
      const pricing: PricingModel = String(params.pricing).toLowerCase() === 'daily' ? 'daily' : 'hourly';
      this.priceService.changePricing(pricing);
    }

    // Check if criteria should be expanded and whether uncertified shops should be included
    const expand = params.expandCriteria ? params.expandCriteria === 'true' : false;
    const showMap = params.showMap ? params.showMap === 'true' : false;
    const selectedShop = params.selectedShop ? (params.selectedShop as string) : undefined;
    const showUncertified = params.showUncertified ? params.showUncertified === 'true' : false;

    // Parse geolocate param if present, otherwise set to true
    const geolocate = params.geolocate ? params.geolocate === 'true' : true;

    // Create copy of current or default shop criteria
    const currentOrDefaultCriteria = this.criteriaService.currentOrDefault;
    const criteria = new SharedStorageCriteria(
      currentOrDefaultCriteria.location,
      currentOrDefaultCriteria.period,
      currentOrDefaultCriteria.luggage,
    );
    // Parse from and to if available
    if (params.from && params.to) {
      criteria.period = {
        from:
          SharedUtilDate.deserializeDate(<string>params.from, (<string>params.from).length === 12, false) ||
          criteria.period.from,
        to:
          SharedUtilDate.deserializeDate(<string>params.to, (<string>params.to).length === 12, false) ||
          criteria.period.to,
      };
    }
    // Parse bags if available
    if (params.bags) {
      criteria.luggage = { normal: Number(params.bags), hand: 0 };
    }
    // Parse location if available
    const location = await this.parseLocation(params);
    if (location) {
      criteria.location = location;
    }
    const result: FindShopParams = {
      expand: expand,
      showMap: showMap,
      selectedShop: selectedShop,
      showUncertified: showUncertified,
      geolocate: geolocate,
      criteria: criteria,
      utm_source: params.utm_source as 'app' | 'homescreen',
    };
    return result;
  }

  private async parseLocation(params: Params): Promise<ILocation> {
    if (params.lat && params.lon) {
      // Parse coordinates
      const coordinates: LatLng = {
        lat: SharedParamsService.deserializeNumber(<string>params.lat),
        lng: SharedParamsService.deserializeNumber(<string>params.lon),
      };

      // Look up address by coordinates
      const place = await this.geocodeService.requestReverseGeoCodeForLatLong(
        coordinates.lat,
        coordinates.lng,
        'ParseLocation',
      );
      console.log(place);

      // Create location from available info
      const defaultZoom = this.criteriaService.default.location.zoom;
      const defaultRadius = this.criteriaService.default.location.radius;
      const location: ILocation = {
        lat: coordinates.lat,
        lon: coordinates.lng,
        address: <string>params.location || place.address.city,
        region: place.address.countryCode,
        zoom: params.zoom ? Number(params.zoom) : defaultZoom,
        radius: defaultRadius,
        type: this.parseLocationType(params),
      };
      return location;
    } else {
      let location: ILocation;
      // Try to match official location if available
      if (params.location) {
        const key = (<string>params.location).toUpperCase();
        location = this.locationService.officialLocations.find((loc) => loc.key === key);
      }
      return location;
    }
  }

  private parseLocationType(params: Params): LocationType {
    if (params.locationType) {
      return params.locationType as LocationType;
    }
    return params.location ? 'place' : 'custom';
  }
}
