import { Inject, Injectable, Injector } from '@angular/core';
import { NotificationService } from '@luggagehero/shared/interfaces';
import { GlobalConfig, Toast, ToastrIconClasses, ToastrService } from 'ngx-toastr';

// Toastr options
const TOASTR_ICON_CLASSES: ToastrIconClasses = {
  error: 'toast-error',
  info: 'toast-info',
  success: 'toast-success',
  warning: 'toast-warning',
};
const BANNER_TOASTR_CONFIG: GlobalConfig = {
  includeTitleDuplicates: false,
  newestOnTop: true,
  maxOpened: 1,
  autoDismiss: true,
  iconClasses: TOASTR_ICON_CLASSES,
  preventDuplicates: true,
  disableTimeOut: false,
  timeOut: 8000,
  closeButton: true,
  extendedTimeOut: 1000,
  progressBar: false,
  enableHtml: true,
  toastClass: 'toast-banner',
  positionClass: 'toast-top-center',
  titleClass: 'toast-title-banner',
  messageClass: 'toast-message-banner',
  easing: 'fade',
  easeTime: 50,
  tapToDismiss: true,
  toastComponent: Toast,
  onActivateTick: false,
  countDuplicates: false,
  progressAnimation: 'decreasing',
  resetTimeoutOnDuplicate: true,
};
const CHAT_TOASTR_CONFIG: GlobalConfig = {
  includeTitleDuplicates: false,
  newestOnTop: true,
  maxOpened: 1,
  autoDismiss: true,
  iconClasses: TOASTR_ICON_CLASSES,
  preventDuplicates: true,
  disableTimeOut: false,
  timeOut: 10000,
  closeButton: true,
  extendedTimeOut: 1000,
  progressBar: false,
  enableHtml: true,
  toastClass: 'toast-chat',
  positionClass: 'toast-bottom-right',
  titleClass: 'toast-title-chat',
  messageClass: 'toast-message-chat',
  easing: 'none',
  easeTime: 0,
  tapToDismiss: true,
  toastComponent: Toast,
  onActivateTick: false,
  countDuplicates: false,
  progressAnimation: 'decreasing',
  resetTimeoutOnDuplicate: true,
};

@Injectable({
  providedIn: 'root',
})
export class SharedNotificationService implements NotificationService {
  // HACK: Workaround for circular dependency if we inject ToastrService directly
  private get toastr(): ToastrService {
    return this.injector.get(ToastrService);
  }

  // TODO: Can we remove the @Inject decorator?
  constructor(@Inject(Injector) private injector: Injector) {}

  success(message: string, _title?: string) {
    // this.toastr.success(message, title);
    this.toastr.success(message, undefined, BANNER_TOASTR_CONFIG);
  }

  error(message: string, _title?: string) {
    // this.toastr.error(message, title);
    this.toastr.error(message, undefined, BANNER_TOASTR_CONFIG);
  }

  warning(message: string, _title?: string) {
    // this.toastr.warning(message, title);
    this.toastr.warning(message, undefined, BANNER_TOASTR_CONFIG);
  }

  info(message: string, _title?: string) {
    // this.toastr.info(message, title);
    this.toastr.info(message, undefined, BANNER_TOASTR_CONFIG);
  }

  chat(message: string, title?: string, delay?: number) {
    if (delay) {
      setTimeout(() => this.chat(message, title), delay);
    } else {
      this.toastr.info(message, title, CHAT_TOASTR_CONFIG);
    }
  }
}
