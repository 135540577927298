<div class="criteria-container">
  <div class="criteria-bar">
    <div *ngIf="showLocation" class="criteria">
      <lh-location-picker [(ngModel)]="location" [cities]="officialLocations"></lh-location-picker>
    </div>

    <div class="criteria">
      <lh-time-period-picker
        [(ngModel)]="period"
        [disabledDates]="disabledDates"
        [useModal]="true"
      ></lh-time-period-picker>
    </div>

    <div class="criteria">
      <lh-luggage-picker [(ngModel)]="luggage" [inPage]="inPage"></lh-luggage-picker>
    </div>
  </div>
</div>
