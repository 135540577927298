import { EventEmitter, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Config } from '@luggagehero/shared/environment';
import {
  ActiveBookingResult,
  BookableStorageLocation,
  Booking,
  BookingAddOns,
  BookingDto,
  BookingEventInfo,
  BookingEventType,
  BookingIncidentType,
  CANCELLATION_FEE_PRODUCT_KEY,
  CheckoutParams,
  CreateBookingParams,
  INSURANCE_PRODUCT_KEY,
  LinkInfo,
  SecuritySealInstance,
  SmartImage,
  SmartImageEx,
  TIP_PRODUCT_KEY,
  WALK_IN_INSURANCE_FEE_PRODUCT_KEY,
} from '@luggagehero/shared/interfaces';
import { IAnalyticsProperties, SharedAnalyticsService } from '@luggagehero/shared/services/analytics';
import { SharedHttpService } from '@luggagehero/shared/services/http';
import { SharedLoggingService } from '@luggagehero/shared/services/logging';
import { SharedNotificationService } from '@luggagehero/shared/services/notification';
import { LegacyOrderService, SharedOrderService } from '@luggagehero/shared/services/orders';
import { SharedPricingService } from '@luggagehero/shared/services/pricing';
import { SharedStorageService } from '@luggagehero/shared/services/storage';
import { SharedStorageCriteriaService } from '@luggagehero/shared/services/storage-criteria';
import { SharedStripeService } from '@luggagehero/shared/services/stripe';
import { SharedTranslateService } from '@luggagehero/shared/services/translation';
import { SharedUserService } from '@luggagehero/shared/services/users';
import { SharedWindowService } from '@luggagehero/shared/services/window';
import { CachedItem, SharedUtilDate, SharedUtilFile, SharedUtilString } from '@luggagehero/shared/util';
import { BehaviorSubject, Observable } from 'rxjs';

const USER_UPDATE_EVENTS: BookingEventType[] = ['bookingCreated', 'bagsPickedUp', 'bookingCanceled'];

@Injectable({
  providedIn: 'root',
})
export class SharedBookingService {
  public bookingEvent$: Observable<BookingEventInfo>;
  public currentBookingChange$: Observable<Booking>;
  public latestBagImage: string;
  public requestCount = 0;
  public bookingDraft: Partial<CreateBookingParams>;

  private _currentBookingId: string;
  private _currentBookingChange = new EventEmitter<Booking>();
  private _bookingEvent = new BehaviorSubject<BookingEventInfo>(undefined);

  private bookingResponses: { [key: string]: CachedItem<Booking> } = {};
  private bookingListResponses: { [key: string]: CachedItem<Booking[]> } = {};

  /** @deprecated Use `TRAVELER_BOOKINGS_ENDPOINT_V3` instead */
  get TRAVELER_BOOKINGS_ENDPOINT_V2(): string {
    return `${Config.environment.TRAVELER_API}/v2/bookings`;
  }
  get TRAVELER_BOOKINGS_ENDPOINT_V3(): string {
    return `${Config.environment.TRAVELER_API}/v3/bookings`;
  }
  get TRAVELER_STORAGE_LOCATIONS_ENDPOINT(): string {
    return `${Config.environment.TRAVELER_API}/v3/storage_locations`;
  }
  get STORAGE_LOCATIONS_ENDPOINT(): string {
    return `${Config.environment.STORAGE_MANAGER_API}/storage_locations`;
  }

  constructor(
    private http: SharedHttpService,
    private order: SharedOrderService,
    private legacyOrder: LegacyOrderService,
    private userService: SharedUserService,
    private windowService: SharedWindowService,
    private criteriaService: SharedStorageCriteriaService,
    private priceService: SharedPricingService,
    private stripe: SharedStripeService,
    private analytics: SharedAnalyticsService,
    private notify: SharedNotificationService,
    private storage: SharedStorageService,
    private translateService: SharedTranslateService,
    private log: SharedLoggingService,
    private router: Router,
  ) {
    this.bookingEvent$ = this._bookingEvent.asObservable();
    this.currentBookingChange$ = this._currentBookingChange.asObservable();
  }

  get currentBookingId(): string {
    return this._currentBookingId;
  }

  get currentBooking(): Booking {
    if (!this.bookingResponses[this._currentBookingId] || !this.bookingResponses[this._currentBookingId].isValid) {
      // Return null if we don't have a valid cached item for the current booking id
      return null;
    }
    // Return the cached booking object
    return this.bookingResponses[this._currentBookingId].value;
  }
  set currentBooking(value: Booking) {
    if (!this.bookingResponses[value._id]) {
      // Add an item to the cache for the given booking id
      this.bookingResponses[value._id] = new CachedItem<Booking>();
    }
    // Store the booking object in the cache
    this.bookingResponses[value._id].value = value;

    // Save the id of the current booking
    this._currentBookingId = value._id;

    // Notify event listeners
    this._currentBookingChange.emit(value);
  }

  get bookingOriginView(): string {
    return this.storage.bookingOriginView;
  }

  get isLoggedIn(): boolean {
    return this.userService.isLoggedIn;
  }

  getVariant(booking: Booking): string {
    if (!booking?.metadata) {
      return null;
    }
    return (booking.metadata.utm_variant || booking.metadata.lh_variant || booking.metadata.variant) as string;
  }

  isVariant(booking: Booking, variant: string): boolean {
    return this.getVariant(booking) === variant;
  }

  async getShareableLink(bookingId: string): Promise<string> {
    const url = `${this.TRAVELER_BOOKINGS_ENDPOINT_V2}/${bookingId}/links`;
    const res = await this.http.get<LinkInfo>(url);

    return res.shortLink;
  }

  public async confirmBookingAndCheckIn(
    orderId: string,
    image: SmartImage,
    paymentMethodId?: string,
    metadata?: unknown,
  ): Promise<Booking> {
    const url = new URL(this.TRAVELER_BOOKINGS_ENDPOINT_V3);
    url.searchParams.append('orderId', orderId);
    url.searchParams.append('isDropoff', 'true');

    if (image && image.name) {
      url.searchParams.append('bagImage', image.name);
    }

    if (paymentMethodId) {
      url.searchParams.append('paymentMethodId', paymentMethodId);
    }

    const res = await this.http.post<BookingDto>(url.toString(), { metadata, securitySeals: image?.securitySeals });

    // Deserialize and store booking, and notify event listeners
    const booking = await this.onBookingReceived(res);
    this.onBookingEvent(booking, 'bookingCreated');
    this.onBookingEvent(booking, 'bagsDroppedOff');

    return booking;
  }

  public async confirmBooking(orderId: string, metadata?: unknown, suppressWelcomeMessage = true): Promise<Booking> {
    const url = `${this.TRAVELER_BOOKINGS_ENDPOINT_V3}?orderId=${orderId}`;
    const res = await this.http.post<BookingDto>(url, { metadata });

    if (!suppressWelcomeMessage) {
      // Show chat message after a 5s delay to ensure that booking page is ready
      const bookingWelcomeMessageDelay = 5 * 1000;
      this.notify.chat(
        this.translateService.instant('BOOKING_PAGE_WELCOME_CHAT_MESSAGE'),
        this.translateService.instant('BOOKING_PAGE_WELCOME_CHAT_TITLE'),
        bookingWelcomeMessageDelay,
      );
    }

    // Deserialize and store booking, and notify event listeners
    const booking = await this.onBookingReceived(res);
    this.onBookingEvent(booking, 'bookingCreated');

    if (!Config.isProduction) {
      console.debug(SharedUtilString.formatBookingEvent(booking, 'created'), { orderId });
    }

    return booking;
  }

  public async modifyBooking(bookingId: string, orderId: string): Promise<Booking> {
    const url = `${this.TRAVELER_BOOKINGS_ENDPOINT_V3}/${bookingId}?orderId=${orderId}`;
    const res = await this.http.put<BookingDto>(url, null);

    return this.onBookingReceived(res);
  }

  public async getBookingsByCurrentUser(useCache = false): Promise<Booking[]> {
    const url = `${this.TRAVELER_BOOKINGS_ENDPOINT_V3}`;

    if (useCache && this.bookingListResponses[url] && this.bookingListResponses[url].isValid) {
      return this.bookingListResponses[url].value;
    }

    const bookings = await this.http.get<BookingDto[]>(url);

    return this.onBookingsReceived(url, bookings);
  }

  public async getBookingsForStorageLocation(
    storageLocationId: string,
    from: Date,
    to: Date,
    useCache = true,
  ): Promise<Booking[]> {
    const f = SharedUtilDate.serializeDate(from, false);
    const t = SharedUtilDate.serializeDate(to, false);

    const url = `${this.STORAGE_LOCATIONS_ENDPOINT}/${storageLocationId}/bookings?from=${f}&to=${t}`;

    if (useCache && this.bookingListResponses[url] && this.bookingListResponses[url].isValid) {
      return this.bookingListResponses[url].value;
    }

    const bookings = await this.http.get<BookingDto[]>(url, true);

    return this.onBookingsReceived(url, bookings);
  }

  public async getActiveBookingsForStorageLocation(storageLocationId: string, refresh = false): Promise<Booking[]> {
    const url = `${this.STORAGE_LOCATIONS_ENDPOINT}/${storageLocationId}/bookings?onlyActive=true`;

    if (this.bookingListResponses[url] && this.bookingListResponses[url].isValid && !refresh) {
      return this.bookingListResponses[url].value;
    }

    const bookings = await this.http.get<BookingDto[]>(url, true);

    return this.onBookingsReceived(url, bookings);
  }

  public async getIncidentBookingsForStorageLocation(storageLocationId: string, refresh = false): Promise<Booking[]> {
    const url = `${this.STORAGE_LOCATIONS_ENDPOINT}/${storageLocationId}/bookings?onlyIncidents=true`;

    if (this.bookingListResponses[url] && this.bookingListResponses[url].isValid && !refresh) {
      return this.bookingListResponses[url].value;
    }

    const bookings = await this.http.get<BookingDto[]>(url, true);

    return this.onBookingsReceived(url, bookings);
  }

  public async findActiveBooking(storageLocationId: string, bookingIds: string[]): Promise<ActiveBookingResult> {
    const url = `${this.TRAVELER_STORAGE_LOCATIONS_ENDPOINT}/${storageLocationId}/bookings/find_active`;
    const result = await this.http.post<ActiveBookingResult>(url, { bookingIds }, this.isLoggedIn);

    // Return the booking id if a match was found, otherwise return null
    return result.success ? result : null;
  }

  public async getBookingForStorageLocation(
    storageLocationId: string,
    bookingId: string,
    refresh = false,
  ): Promise<Booking> {
    if (this.bookingResponses[bookingId] && this.bookingResponses[bookingId].isValid && !refresh) {
      return this.bookingResponses[bookingId].value;
    }

    const url = `${this.STORAGE_LOCATIONS_ENDPOINT}/${storageLocationId}/bookings/${bookingId}`;
    const booking = await this.http.get<BookingDto>(url, true);

    return this.onBookingReceived(booking);
  }

  public async getBooking(id: string, refresh = false): Promise<Booking> {
    if (this.bookingResponses[id] && this.bookingResponses[id].isValid && !refresh) {
      return this.bookingResponses[id].value;
    }

    const url = `${this.TRAVELER_BOOKINGS_ENDPOINT_V3}/${id}`;
    const booking = await this.http.get<BookingDto>(url, this.isLoggedIn);

    return this.onBookingReceived(booking);
  }

  public async setPaymentMethod(bookingId: string, paymentMethodId: string): Promise<Booking> {
    const url = `${this.TRAVELER_BOOKINGS_ENDPOINT_V3}/${bookingId}/payment_methods/${paymentMethodId}`;
    const res = await this.http.put<BookingDto>(url, null, this.isLoggedIn);

    const booking = await this.onBookingReceived(res);
    this.onBookingEvent(booking, 'paymentMethodAdded');

    return booking;
  }

  public async prepareBagImage(imageData: string, shopId?: string): Promise<SmartImage>;
  public async prepareBagImage(imageData: Blob, shopId?: string): Promise<SmartImage>;
  public async prepareBagImage(imageData: string | Blob, shopId?: string): Promise<SmartImage> {
    const image = typeof imageData === 'string' ? SharedUtilFile.dataURIToBlob(imageData) : imageData;

    let url = `${this.TRAVELER_BOOKINGS_ENDPOINT_V3}/bag_images`;

    if (shopId) {
      url += `?shopId=${shopId}`;
    }

    const res = await this.http.postFile<SmartImage>(url, image, 'image.jpg', this.isLoggedIn);
    this.latestBagImage = res.name;

    return this.deserializeImage(res);
  }

  public async addBagImage(bookingId: string, imageData: string): Promise<Booking>;
  public async addBagImage(bookingId: string, imageData: Blob): Promise<Booking>;
  public async addBagImage(bookingId: string, imageData: string | Blob): Promise<Booking> {
    const image = typeof imageData === 'string' ? SharedUtilFile.dataURIToBlob(imageData) : imageData;
    const url = `${this.TRAVELER_BOOKINGS_ENDPOINT_V3}/${bookingId}/bag_images`;
    const res = await this.http.postFile<BookingDto>(url, image, 'image.jpg', this.isLoggedIn);
    return this.onBookingReceived(res);
  }

  public async setBagImage(bookingId: string, value: SmartImage): Promise<Booking> {
    const image = this.serializeImage(value);

    const url = `${this.TRAVELER_BOOKINGS_ENDPOINT_V3}/${bookingId}/bag_images/${value.name}`;
    const res = await this.http.put<BookingDto>(url, { securitySeals: image.securitySeals }, this.isLoggedIn);

    return this.onBookingReceived(res);
  }

  public async addIncidentImage(bookingId: string, image: Blob): Promise<Booking> {
    const url = this.getIncidentImageUploadUrl(bookingId);
    const res = await this.http.postFile<BookingDto>(url, image, 'image.jpg', this.isLoggedIn);
    return this.onBookingReceived(res);
  }

  public getIncidentImageUploadUrl(bookingId: string): string {
    return `${this.TRAVELER_BOOKINGS_ENDPOINT_V3}/${bookingId}/incident_images`;
  }

  public async updateState(booking: Booking): Promise<Booking> {
    const url = `${this.TRAVELER_BOOKINGS_ENDPOINT_V3}/${booking._id}/state`;
    const res = await this.http.put<BookingDto>(url, booking.state, this.isLoggedIn);

    return this.onBookingReceived(res);
  }

  public async reportIncident(
    bookingId: string,
    incidentType: BookingIncidentType,
    comments?: string,
    images?: string[],
    cancelBooking = false,
    isLegacyBooking = false,
  ): Promise<Booking> {
    const endpoint = isLegacyBooking ? this.TRAVELER_BOOKINGS_ENDPOINT_V2 : this.TRAVELER_BOOKINGS_ENDPOINT_V3;
    const url = `${endpoint}/${bookingId}/incidents`;
    const payload = { incidentType, comments, images, cancelBooking };

    const res = await this.http.post<BookingDto>(url, payload, this.isLoggedIn);

    const booking = await this.onBookingReceived(res);
    this.onBookingEvent(booking, 'incidentReported');

    if (cancelBooking) {
      const bookingEvent = booking.status === 'CANCELLED' ? 'bookingCanceled' : 'bookingCanceledFailed';
      this.onBookingEvent(booking, bookingEvent);
    }

    return booking;
  }

  /** @deprecated Use `confirmCancellation()` instead */
  public async cancelBooking(bookingId: string, cancelReason?: string, cancelReasonCode?: string): Promise<Booking> {
    const url = `${this.TRAVELER_BOOKINGS_ENDPOINT_V2}/${bookingId}/cancel`;
    const payload = { cancelReason, cancelReasonCode };

    const res = await this.http.put<BookingDto>(url, payload, this.isLoggedIn);

    const booking = await this.onBookingReceived(res);
    const bookingEvent = booking.status === 'CANCELLED' ? 'bookingCanceled' : 'bookingCanceledFailed';
    this.onBookingEvent(booking, bookingEvent);

    return booking;
  }

  public async confirmCancellation(
    bookingId: string,
    orderId: string,
    cancelReason?: string,
    cancelReasonCode?: string,
  ): Promise<Booking> {
    const url = `${this.TRAVELER_BOOKINGS_ENDPOINT_V3}/${bookingId}/confirm_cancel`;
    const payload = { orderId, cancelReason, cancelReasonCode };

    const res = await this.http.put<BookingDto>(url, payload);

    const booking = await this.onBookingReceived(res);
    const bookingEvent = booking.status === 'CANCELLED' ? 'bookingCanceled' : 'bookingCanceledFailed';
    this.onBookingEvent(booking, bookingEvent);

    return booking;
  }

  public async checkIn(bookingId: string): Promise<Booking> {
    const url = `${this.TRAVELER_BOOKINGS_ENDPOINT_V3}/${bookingId}/check_in`;

    const res = await this.http.put<BookingDto>(url, null, this.isLoggedIn);

    const booking = await this.onBookingReceived(res);
    this.onBookingEvent(booking, 'bagsDroppedOff');

    return booking;
  }

  // TODO: Use v3 route
  public async undoCheckIn(bookingId: string): Promise<Booking> {
    const url = `${this.TRAVELER_BOOKINGS_ENDPOINT_V2}/${bookingId}/undo_checkin`;

    const res = await this.http.put<BookingDto>(url, null, this.isLoggedIn);

    const booking = await this.onBookingReceived(res);
    this.onBookingEvent(booking, 'dropOffCanceled');

    return booking;
  }

  public async checkOut(bookingId: string, params?: CheckoutParams): Promise<Booking> {
    const url = `${this.TRAVELER_BOOKINGS_ENDPOINT_V3}/${bookingId}/check_out`;
    const res = await this.http.put<BookingDto>(url, params, this.isLoggedIn);

    if (res.pendingPayment) {
      const pendingPaymentData = res.pendingPayment as {
        data: {
          client_secret: string;
          last_payment_error: {
            payment_method: {
              id: string;
            };
          };
        };
      };
      const result = await this.stripe.confirmPayment(
        pendingPaymentData.data.client_secret,
        pendingPaymentData.data.last_payment_error.payment_method.id,
      );

      if (!result.error) {
        // Retry the checkout after payment intent is confirmed
        return this.checkOut(bookingId, params);
      }
    }

    const booking = await this.onBookingReceived(res);
    this.onBookingEvent(booking, 'bagsPickedUp');

    return booking;
  }

  public async checkInForStorageLocation(storageLocationId: string, bookingId: string): Promise<Booking> {
    const url = `${this.STORAGE_LOCATIONS_ENDPOINT}/${storageLocationId}/bookings/${bookingId}/check_in`;
    const res = await this.http.put<BookingDto>(url, null, true);

    const booking = await this.onBookingReceived(res);
    this.onBookingEvent(booking, 'bagsDroppedOff');

    return booking;
  }

  public async checkOutForStorageLocation(
    storageLocationId: string,
    bookingId: string,
    params: CheckoutParams,
  ): Promise<Booking> {
    const url = `${this.STORAGE_LOCATIONS_ENDPOINT}/${storageLocationId}/bookings/${bookingId}/check_out`;
    const res = await this.http.put<BookingDto>(url, params, true);

    const booking = await this.onBookingReceived(res);
    this.onBookingEvent(booking, 'bagsPickedUp');

    return booking;
  }

  private onBookingEvent(booking: Booking, eventType: BookingEventType) {
    void this.log.debug(`Booking event: ${eventType} for bookingId=${booking._id}`);

    const properties: IAnalyticsProperties = {
      category: 'booking',
      label: booking.address.countryCode,
      orderId: booking._id,
      event: 'bookingChange',
      booking,
      user: this.userService.user,
    };

    switch (eventType) {
      case 'bookingCreated': {
        if (booking.price.estimated) {
          // FIXME: This should be based on the commission for the storage location instead of being hardcoded to 50%
          const storageCommission = (booking.price.estimated.storage || 0) * 0.5;
          const serviceFee = booking.price.estimated.serviceFee || 0;
          const insuranceFee = booking.price.estimated.startupFee || 0;
          const freeCancellationFee = booking.price.estimated.freeCancellationFee || 0;
          const securitySealFee = booking.price.estimated.securitySealFee || 0;

          const lhTotal = storageCommission + serviceFee + insuranceFee + freeCancellationFee + securitySealFee;

          properties.value = Math.round(lhTotal * 100) / 100;
          properties.currency = booking.price.estimated.currency.toUpperCase();
        }
        break;
      }
      case 'bagsPickedUp': {
        if (booking.price.final) {
          properties.value = Math.round(booking.price.final.total * 100) / 100;
          properties.currency = booking.price.final.currency.toUpperCase();
        }
        break;
      }
    }

    try {
      this.analytics.track(eventType, properties);
    } catch {
      // Ignore
    }

    this._bookingEvent.next({ booking, eventType });

    if (this.isLoggedIn && USER_UPDATE_EVENTS.includes(eventType)) {
      void this.userService.getUserInfo(true);
    }
  }

  private async onBookingsReceived(url: string, bookings: BookingDto[]): Promise<Booking[]> {
    if (!this.bookingListResponses[url]) {
      // Add an item to the cache for the given URL
      this.bookingListResponses[url] = new CachedItem<Booking[]>();
    }

    // Deserialize the response and store in cache
    this.bookingListResponses[url].value = await Promise.all(bookings.map(async (b) => this.deserialize(b)));

    // Return the deserialized response
    return this.bookingListResponses[url].value;
  }

  private async onBookingReceived(booking: BookingDto): Promise<Booking> {
    const deserializedBooking = await this.deserialize(booking);
    this.currentBooking = deserializedBooking;
    this.storage.addBooking(deserializedBooking._id);
    return deserializedBooking;
  }

  public compileMetaData(
    bookingCategory: string,
    storageLocation: BookableStorageLocation,
    addOns: BookingAddOns = null,
  ): unknown {
    const criteria = this.criteriaService.currentOrDefault;

    const metadata: Record<string, unknown> = this.storage.trackingData || {};
    metadata.lh_experiment_id = this.storage.experimentId;
    metadata.lh_variant_id = this.storage.variant;
    metadata.lh_booking_category = bookingCategory;
    metadata.lh_booking_platform = this.windowService.platform;
    metadata.lh_booking_origin_view = this.bookingOriginView;
    metadata.lh_booking_confirm_path = this.router.url.split(';')[0];
    metadata.lh_pricing_model = this.priceService.pricingModel;
    metadata.lh_number_of_bags = criteria.luggage.normal + criteria.luggage.hand;

    metadata.lh_storage_location_hero_level = storageLocation.heroLevel;

    if (storageLocation.stats?.averageRating) {
      metadata.lh_storage_location_average_rating = storageLocation.stats.averageRating;
    }

    if (addOns?.guestMovement && addOns.guestMovement !== 'unset') {
      metadata.lh_guest_movement = addOns.guestMovement;
    }

    const numberOfDays =
      this.priceService.pricingModel === 'daily'
        ? Math.max(SharedUtilDate.daysBetween(criteria.period.from, criteria.period.to), 1)
        : 0;
    metadata.lh_storage_days = numberOfDays;
    metadata.lh_multiday_selected = numberOfDays > 1;

    metadata.lh_order_summary_version = 2;

    return metadata;
  }

  public serialize(booking: Booking): BookingDto {
    const serializedBooking: BookingDto = {
      _id: booking._id,
      userId: booking.userId,
      userEmail: booking.userEmail,
      userName: booking.userName,
      userPicture: booking.userPicture,
      shopId: booking.shopId,
      shopName: booking.shopName,
      address: booking.address,
      period: {
        from: SharedUtilDate.serializeDate(booking.period.from),
        to: SharedUtilDate.serializeDate(booking.period.to),
        checkIn: booking.period.checkIn ? SharedUtilDate.serializeDateUtc(booking.period.checkIn) : undefined,
        checkOut: booking.period.checkOut ? SharedUtilDate.serializeDateUtc(booking.period.checkOut) : undefined,
      },
      luggage: booking.luggage,
      price: booking.price,
      timezone: booking.timezone,
      status: booking.status,
      bookingFlow: booking.bookingFlow,
      strategy: booking.strategy,
      config: booking.config,
      metadata: booking.metadata,
      cancelReason: booking.cancelReason,
      cancelReasonCode: booking.cancelReasonCode,
      state: booking.state,
      feedbackReceived: booking.feedbackReceived,
      paymentMethodId: booking.paymentMethodId,
      paidDirectly: booking.paidDirectly,
      schemaVersion: booking.schemaVersion,
      incidentImages: booking.incidentImages,
      chargedNoShow: booking.chargedNoShow,
      isZeroPaymentBooking: booking.isZeroPaymentBooking,
    };

    booking.luggage.smartImages = booking.luggage.smartImages.map((img) => this.serializeImage(img));

    return serializedBooking;
  }

  public async deserialize(booking: BookingDto): Promise<Booking> {
    booking.address.street = SharedUtilString.decryptString(booking.address.street, Config.environment.CRYPTO_KEY);
    booking.address.formattedAddress = SharedUtilString.decryptString(
      booking.address.formattedAddress,
      Config.environment.CRYPTO_KEY,
    );
    booking.shopName = SharedUtilString.decryptString(booking.shopName, Config.environment.CRYPTO_KEY);

    let fullBookingAmount: number;

    if (booking.price.final) {
      fullBookingAmount =
        booking.price.final.total +
        (booking.price.final.additionalCharge || 0) -
        (booking.price.final.refund || 0) -
        (booking.price.final.tipRefund || 0);
      fullBookingAmount = Math.max(fullBookingAmount, 0);
    }

    let storageLocationBookingAmount: number;

    if (booking.storageLocationRevenuePlus) {
      storageLocationBookingAmount = Math.max(booking.storageLocationRevenuePlus.balanceOwed, 0);
    }

    if (this.userService.isDummyUserEmail(booking.userEmail)) {
      // Remove dummy email
      booking.userEmail = null;
    }

    const deserializedBooking: Booking = {
      _id: booking._id,
      userId: booking.userId,
      userEmail: booking.userEmail,
      userPhone: booking.userPhone,
      userName: booking.userName,
      userPicture: booking.userPicture,
      shopId: booking.shopId,
      shopName: booking.shopName,
      address: booking.address,
      period: {
        from: SharedUtilDate.deserializeDate(booking.period.from),
        to: SharedUtilDate.deserializeDate(booking.period.to),
        checkIn: SharedUtilDate.deserializeDateTz(booking.period.checkIn, booking.timezone),
        checkOut: SharedUtilDate.deserializeDateTz(booking.period.checkOut, booking.timezone),
      },
      luggage: booking.luggage,
      price: booking.price,
      storageLocationRevenuePlus: booking.storageLocationRevenuePlus,
      storageLocationBookingAmount,
      fullBookingAmount,
      timezone: booking.timezone,
      status: booking.status,
      bookingFlow: booking.bookingFlow,
      strategy: booking.strategy,
      config: booking.config,
      metadata: booking.metadata,
      cancelReason: booking.cancelReason,
      cancelReasonCode: booking.cancelReasonCode,
      state: booking.state,
      feedbackReceived: booking.feedbackReceived,
      paymentMethodId: booking.paymentMethodId,
      paidDirectly: booking.paidDirectly,
      paymentPostponedAt: booking.paymentPostponedAt,
      schemaVersion: booking.schemaVersion,
      created: new Date(booking.created),
      modified: new Date(booking.modified),
      isGuest: booking.price.addOns?.guest || false,
      isWalkIn: booking.price.addOns?.walkIn || booking.metadata?.lh_booking_category === 'walk_in' || false,
      incidentImages: booking.incidentImages,
      incidents:
        booking.incidents &&
        booking.incidents.map((incident) => {
          incident.reportedAt = new Date(incident.reportedAt);
          return incident;
        }),
      incidentReported: booking.incidents && booking.incidents.length > 0,
      chargedNoShow: booking.chargedNoShow,
      isZeroPaymentBooking: booking.isZeroPaymentBooking,
    };

    if (deserializedBooking.luggage.smartImages?.length > 0) {
      deserializedBooking.luggage.smartImages = deserializedBooking.luggage.smartImages.map((_) =>
        this.deserializeImage(_),
      );
    } else if (deserializedBooking.luggage.images?.length > 0) {
      // Backfill smart images for older bookings
      deserializedBooking.luggage.smartImages = deserializedBooking.luggage.images.map((image) => ({
        name: image,
        securitySeals: [],
        originalSecuritySeals: [],
      }));
    }

    if (!deserializedBooking.price.pricingModel) {
      // Assume this is a legacy booking where we were always using hourly pricing
      deserializedBooking.price.pricingModel = 'hourly';
    }

    const newOrder = SharedUtilString.tryDecryptAndParseString(booking.order, Config.environment.CRYPTO_KEY);

    if (
      deserializedBooking.status === 'PAID' &&
      newOrder?.orderLines.find((ol) => ol.productKey === CANCELLATION_FEE_PRODUCT_KEY)
    ) {
      // HACK: Set bookings where a cancellation fee was paid to status cancelled
      deserializedBooking.status = 'CANCELLED';
    }

    if (!deserializedBooking.orderDetails) {
      const isCanceled = deserializedBooking.status === 'CANCELLED';
      const isFinal = isCanceled || ['CHECKED_OUT', 'PAID'].includes(deserializedBooking.status);
      if (Config.isStaging && isFinal !== newOrder?.finalised) {
        void this.log.warn(
          `Mismatch between booking order isFinal ${isFinal} and order finalised ${newOrder?.finalised} for booking ${deserializedBooking._id}`,
        );
      }
      try {
        deserializedBooking.orderDetails = booking.order
          ? [await this.order.convertNewOrder(newOrder, isCanceled, isFinal)]
          : await this.legacyOrder.getByBooking(deserializedBooking);
      } catch (err) {
        console.log(`Failed to generate order for booking ${deserializedBooking._id}`, err);
      }
    }

    if (!deserializedBooking.price.estimated) {
      //
      // HACK: Backfill estimated price for new bookings; estimated price is used to add a value to analytics events
      // and for determining the maximum tip amounts)
      //
      booking.price.estimated = this.order.getEstimatedPrice(deserializedBooking.orderDetails[0]);
    }

    if (!deserializedBooking.price.addOns) {
      // HACK: Backfill add-ons based on order details for newer bookings
      const insuranceOrderLine = deserializedBooking.orderDetails[0].orderLines.find((ol) =>
        //
        // This is for very old bookings so if they don't have addons they won't have walk-in products either; just
        // adding the fix anyway for good measure.
        //
        [INSURANCE_PRODUCT_KEY, WALK_IN_INSURANCE_FEE_PRODUCT_KEY].includes(ol.product._id),
      );
      booking.price.addOns = {
        insurance: insuranceOrderLine.selected ? true : false,
        freeCancellation: false,
        securitySeals: 0,
      };
    }

    if (deserializedBooking.price.final && !deserializedBooking.price.final.tip) {
      // HACK: Backfill tip based on order details for newer bookings
      const tipOrderLine = deserializedBooking.orderDetails[0].orderLines.find(
        (ol) => ol.product._id === TIP_PRODUCT_KEY,
      );
      if (tipOrderLine) {
        deserializedBooking.price.final.tip = tipOrderLine.unitPrice * tipOrderLine.quantity;
      }
    }

    if (newOrder?.orderRequest.discountCode) {
      deserializedBooking.price.discountCode = newOrder.orderRequest.discountCode;
    }

    if (!Config.isProduction) {
      console.log(`Deserialized booking ${deserializedBooking._id}`, deserializedBooking);
    }

    return deserializedBooking;
  }

  private serializeImage(image: SmartImageEx): SmartImage {
    const serializedImage: SmartImage = {
      name: image.name,
      // HACK: Return the original security seals to the server
      securitySeals: image.originalSecuritySeals,
    };
    return serializedImage;
  }

  private deserializeImage(image: SmartImage): SmartImage {
    const hasSealNumbers = image.sealNumbers?.length > 0;
    const hasSecuritySeals = image.securitySeals?.length > 0;

    // Use the color from the first security seal object that has one
    const color = hasSecuritySeals ? image.securitySeals.find((s) => s.color)?.color : undefined;

    const deserializedImage: SmartImageEx = {
      name: image.name,
      securitySeals:
        // HACK: Rely primarily on the seal numbers from plain text extraction for now
        (hasSealNumbers && image.sealNumbers.map((sealNum) => new SecuritySealInstance(sealNum, color))) ||
        (hasSecuritySeals && image.securitySeals.map((seal) => new SecuritySealInstance(seal.tag, color))) ||
        [],
      // HACK: Store the original security seals so they can be returned to the server
      originalSecuritySeals: image.securitySeals,
    };

    return deserializedImage;
  }
}
