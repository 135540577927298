import { ChangeDetectionStrategy, Component } from '@angular/core';
import { StorageLocationAvailabilityBaseComponent } from '@luggagehero/features/ui';

@Component({
  selector: 'lh-storage-location-availability',
  templateUrl: './storage-location-availability.component.html',
  styleUrls: ['./storage-location-availability.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StorageLocationAvailabilityComponent extends StorageLocationAvailabilityBaseComponent {}
