import { Pipe, PipeTransform } from '@angular/core';
import { Address } from '@luggagehero/shared/interfaces';

@Pipe({ name: 'address', standalone: true })
export class AddressPipe implements PipeTransform {
  transform(address: Address): string {
    if (!address) {
      return '';
    }
    return address.countryCode === 'dk'
      ? `${address.street}, ${address.city}, ${address.postal}`
      : `${address.street}, ${address.postal}, ${address.city}`;
  }
}
