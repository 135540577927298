import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { GoogleMap as AngularGoogleMap, MapCircle, MapInfoWindow, MapMarker } from '@angular/google-maps';
import { BookableStorageLocation, LatLng, Optional } from '@luggagehero/shared/interfaces';
import { MapMarkerThemeOptions } from '@luggagehero/traveler/shops/util';
import { ShopDisplayModel } from '@luggagehero/traveler-shops-models';

import { ActiveStorageLocationMarkerNotice } from '../maps-components/active-storage-location-marker-notice';
import { TravelerShopsUiShopCardComponent } from '../shop-card/traveler-shops-ui-shop-card.component';

export interface CustomMapMarker extends google.maps.MarkerOptions {
  storageLocation?: Optional<ShopDisplayModel>;
}

export type MapCircleOptions = google.maps.CircleOptions;
export type MapTypeStyle = google.maps.MapTypeStyle;

const STORAGE_LOCATION_MARKER_ICON_HEIGHT = 48;
const STORAGE_LOCATION_MARKER_ICON_WIDTH = 48;
const MAP_UPDATE_DEBOUNCE_MS = 200;

@Component({
  selector: 'lh-traveler-shops-ui-storage-location-map',
  styleUrls: ['traveler-shops-ui-storage-location-map.component.scss'],
  templateUrl: 'traveler-shops-ui-storage-location-map.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, AngularGoogleMap, MapInfoWindow, MapMarker, MapCircle, TravelerShopsUiShopCardComponent],
})
export class TravelerShopsUIStorageLocationMapComponent implements OnInit {
  @ViewChild(AngularGoogleMap) public angularGoogleMap?: AngularGoogleMap;
  @ViewChild(MapInfoWindow) public infoWindow?: MapInfoWindow;

  @Output() public centerChange = new EventEmitter<google.maps.LatLngLiteral>();
  @Output() public zoomChange = new EventEmitter<number>();
  @Output() public bookingRequest = new EventEmitter<BookableStorageLocation>();
  @Output() public detailsRequest = new EventEmitter<BookableStorageLocation>();
  @Output() public markerClick = new EventEmitter<ShopDisplayModel>();
  @Output() public highlightedStorageLocationIdChange = new EventEmitter<Optional<string>>();
  @Output() public mapClick = new EventEmitter<void>();

  @Input() public myLocation?: Optional<google.maps.LatLngLiteral>;
  @Input() public latitude?: number;
  @Input() public longitude?: number;
  @Input() public obfuscateStorageLocations?: boolean;
  @Input() public enableStorageLocationInfoWindows?: boolean;
  @Input() public enableStorageLocationMarkerNotice?: boolean;
  @Input() public maxZoom?: Optional<number>;
  @Input() public clickableIcons?: Optional<boolean>;
  @Input() public keyboardShortcuts?: Optional<boolean>;
  @Input() public fullscreenControl?: Optional<boolean>;
  @Input() public scaleControl?: Optional<boolean>;
  @Input() public streetViewControl?: Optional<boolean>;
  @Input() public zoomControl?: Optional<boolean>;
  @Input() public zoomControlOptions?: google.maps.ZoomControlOptions;
  @Input() public disableDoubleClickZoom?: Optional<boolean>;
  @Input() public scrollwheel?: Optional<boolean>;
  @Input() public draggable?: Optional<boolean>;
  @Input() public gestureHandling?: Optional<string>;
  @Input() public usePanning?: Optional<boolean>;
  @Input() public styles?: Optional<MapTypeStyle[]>;
  @Input() public initialOpenStorageLocationId?: Optional<string>;
  @Input() public largeStorageLocationMarkers?: Optional<boolean>;
  @Input() public largeStorageLocationCircles?: Optional<boolean>;
  @Input() public storageLocationObfuscation = true;
  @Input() public mapClickEnabled = true;
  @Input() public mapMarkerThemeOptions: MapMarkerThemeOptions = {
    markerSizeDownscaleFactor: 0.75,
  };

  public openStorageLocation: Optional<ShopDisplayModel>;

  private cd = inject(ChangeDetectorRef);

  private _storageLocationCircles: MapCircleOptions[] = [];
  private _storageLocationMarkers: CustomMapMarker[] = [];
  private _myLocationMarker: Optional<CustomMapMarker>;
  private _storageLocations: ShopDisplayModel[] = [];
  private _highlightedStorageLocationId: Optional<string>;
  private wasObfuscating = false;
  private loadStorageLocationCirclesTimeout = 0;
  private loadStorageLocationMarkersTimeout = 0;
  private _mapOptions: Optional<google.maps.MapOptions>;
  private _markerNotices: ActiveStorageLocationMarkerNotice[] = [];

  private _center!: google.maps.LatLngLiteral;
  private _zoom = 16;

  public get mapOptions(): Optional<google.maps.MapOptions> {
    return this._mapOptions;
  }

  public get myLocationMarker(): Optional<CustomMapMarker> {
    if (!this.myLocation) {
      return null;
    }
    // TODO: Do we need to store this in a variable?
    this._myLocationMarker = {
      position: this.myLocation,
      icon: {
        url: 'assets/markers/my-location-circle.svg',
        anchor: new google.maps.Point(8, 8),
      },
    };
    return this._myLocationMarker;
  }

  public get mapInfoWindowOptions(): google.maps.InfoWindowOptions {
    const isBestChoice = this.openStorageLocation != null ? this.isBestChoice(this.openStorageLocation) : false;
    const factor =
      this.largeStorageLocationMarkers || isBestChoice ? 1 : this.mapMarkerThemeOptions.markerSizeDownscaleFactor;
    const markerIconHeight = STORAGE_LOCATION_MARKER_ICON_HEIGHT * factor;
    const options: google.maps.InfoWindowOptions = {
      pixelOffset: new google.maps.Size(0, -markerIconHeight / 2 - 5),
    };
    return options;
  }

  public get center(): google.maps.LatLngLiteral {
    return this._center;
  }

  @Input() public set center(value: google.maps.LatLngLiteral) {
    if (!this.isSame(value, this._center)) {
      this._center = value;
      this.cd.markForCheck();
    }
  }

  private isSame(locA: LatLng, locB: LatLng): boolean {
    if (locA === locB) {
      return true;
    }
    if (!locA || !locB) {
      return false;
    }
    return locA.lat === locB.lat && locA.lng === locB.lng;
  }

  public get zoom(): number {
    return this._zoom;
  }
  @Input() public set zoom(value: number) {
    if (!value || value === this._zoom) {
      return;
    }
    this._zoom = value;
    this.cd.markForCheck();
  }

  public get storageLocations(): ShopDisplayModel[] {
    return this._storageLocations;
  }

  @Input() public set storageLocations(value: ShopDisplayModel[]) {
    if (!value || (value.length === 0 && !this._storageLocations)) {
      return;
    }
    this._storageLocations = value;

    this.loadStorageLocationMarkers();

    if (this.initialOpenStorageLocationId) {
      // Delay this a bit so it catches the user's attention
      setTimeout(() => {
        if (this.initialOpenStorageLocationId != null) {
          this.openStorageLocationInfoWindow(this.initialOpenStorageLocationId);
          this.initialOpenStorageLocationId = null;
        }
      }, 1000);
    }
  }

  public get highlightedStorageLocationId(): Optional<string> {
    return this._highlightedStorageLocationId;
  }

  @Input() public set highlightedStorageLocationId(value: Optional<string>) {
    if (this._highlightedStorageLocationId === value) {
      return; // Nothing changed
    }

    // Record previous highlighted storage location id and update with the new value
    const previousHighlightedStorageLocationId = this._highlightedStorageLocationId;
    this._highlightedStorageLocationId = value;
    this.highlightedStorageLocationIdChange.emit(value);

    if (previousHighlightedStorageLocationId != null) {
      // Update marker icons for the previous and new highlighted storage locations
      this.updateStorageLocationMarkerIcon(previousHighlightedStorageLocationId);
    }

    if (this.highlightedStorageLocationId != null) {
      this.updateStorageLocationMarkerIcon(this.highlightedStorageLocationId);
    }

    // Update view
    this.cd.markForCheck();
  }

  public get storageLocationMarkers(): CustomMapMarker[] {
    return this._storageLocationMarkers;
  }

  public get storageLocationCircles(): MapCircleOptions[] {
    return this._storageLocationCircles;
  }

  private get isObfuscating(): boolean {
    if (!this.storageLocationObfuscation) {
      return false;
    }
    return this.zoom >= 16 || this.largeStorageLocationCircles ? true : false;
  }

  public ngOnInit() {
    this.updateMapOptions();
  }

  public onMapInitialized(map: google.maps.Map) {
    if (this.center) {
      map.setCenter(this.center);
    }
    map.setZoom(this.zoom);
  }

  public onCenterChange() {
    const center = this.angularGoogleMap?.getCenter()?.toJSON();

    if (center) {
      this.centerChange.emit(center);
    }
  }

  public onZoomChange() {
    const zoom = this.angularGoogleMap?.getZoom() || this.zoom;
    this.zoom = zoom;
    this.zoomChange.emit(this.zoom);
  }

  public onMapClick(_e: google.maps.MapMouseEvent | google.maps.IconMouseEvent) {
    this.mapClick.emit();
    if (!this.mapClickEnabled) {
      return;
    }

    this.setOpenStorageLocation(null);
    this.closeActiveStorageLocationMarkerNotices();

    if (this.infoWindow) {
      this.infoWindow.close();
    }
  }

  public onStorageLocationClick(storageLocation: BookableStorageLocation) {
    this.detailsRequest.emit(storageLocation);
  }

  public onBookingRequest(storageLocation: BookableStorageLocation) {
    this.bookingRequest.emit(storageLocation);
  }

  public onStorageLocationMarkerClick(marker: Optional<CustomMapMarker>) {
    if (!marker || !marker.storageLocation) {
      return;
    }

    this.openStorageLocationInfoWindow(marker.storageLocation.id);
    this.markerClick.emit(marker.storageLocation);
  }

  public onStorageLocationMarkerMouseOver(marker: Optional<CustomMapMarker>) {
    if (!marker || !marker.storageLocation) {
      return;
    }
    this.highlightedStorageLocationId = marker.storageLocation.id;
  }

  public onStorageLocationMarkerMouseOut(_marker: CustomMapMarker) {
    this.highlightedStorageLocationId = null;
  }

  public isStorageLocationOpen(value: BookableStorageLocation): boolean {
    if (!this.enableStorageLocationInfoWindows) {
      return false;
    }
    if (!this.openStorageLocation || !value) {
      return false;
    }
    return this.openStorageLocation?.id === value._id;
  }

  public openStorageLocationInfoWindow(storageLocationId?: Optional<string>) {
    this.closeActiveStorageLocationMarkerNotices();

    if (!storageLocationId) {
      return;
    }
    this.setOpenStorageLocation(this.storageLocations?.find((s) => s.id === storageLocationId));

    if (this.infoWindow && this.openStorageLocation) {
      this.infoWindow.position = {
        lat: this.openStorageLocation.shopItem.location.coordinates[1],
        lng: this.openStorageLocation.shopItem.location.coordinates[0],
      };
      this.infoWindow.open();
    }

    this.openActiveStorageLocationMarkerNotice(this.openStorageLocation);
  }

  private closeActiveStorageLocationMarkerNotices() {
    this._markerNotices.forEach((x) => {
      x?.setMap(null);
    });
    this._markerNotices = [];
  }

  private openActiveStorageLocationMarkerNotice(storageLocation: Optional<ShopDisplayModel>) {
    if (storageLocation == null) {
      return;
    }

    const notice = this.mapMarkerThemeOptions.activeStorageLocationMarkerNotice;

    if (notice && this.enableStorageLocationMarkerNotice && this.angularGoogleMap?.googleMap != null) {
      const iconHeight = this.isBestChoice(storageLocation)
        ? STORAGE_LOCATION_MARKER_ICON_HEIGHT
        : STORAGE_LOCATION_MARKER_ICON_HEIGHT * this.mapMarkerThemeOptions.markerSizeDownscaleFactor;

      const markerNotice = new ActiveStorageLocationMarkerNotice({
        map: this.angularGoogleMap.googleMap,
        position: new google.maps.LatLng({
          lat: storageLocation.shopItem.location.coordinates[1],
          lng: storageLocation.shopItem.location.coordinates[0],
        }),
        text: notice,
        offset: {
          x: 0,
          y: iconHeight + 15,
        },
        onClick: () => {
          this.bookingRequest.emit(storageLocation.shopItem);
        },
      });

      this._markerNotices.push(markerNotice);
    }
  }

  private isBestChoice(storageLocation?: Optional<ShopDisplayModel>): boolean {
    if (storageLocation == null) {
      return false;
    }
    return storageLocation.recommendedTag != null;
  }

  // HACK: Here to ensure marker is updated visually when using @google/angular-maps
  public getMarkerOptions(marker: CustomMapMarker): google.maps.MarkerOptions {
    const options: google.maps.MarkerOptions = {
      position: marker.position,
      icon: marker.icon,
      title: marker.storageLocation?.title,
      zIndex: this.isBestChoice(marker.storageLocation) ? 99999999 : 1000000,
    };
    return options;
  }

  private setOpenStorageLocation(value?: Optional<ShopDisplayModel>) {
    if (value === this.openStorageLocation) {
      return;
    }

    if (!this.storageLocationMarkers) {
      return;
    }

    const previous = this.openStorageLocation;
    this.openStorageLocation = value;

    this.storageLocationMarkers.forEach((m) => {
      const id = m.storageLocation?.id;
      if (id && [value?.id, previous?.id].includes(id)) {
        this.updateStorageLocationMarkerIcon(m);
      }
    });
  }

  private loadStorageLocationMarkers() {
    if (this.loadStorageLocationMarkersTimeout) {
      clearTimeout(this.loadStorageLocationMarkersTimeout);
    }
    this.loadStorageLocationMarkersTimeout = setTimeout(
      () => this._loadStorageLocationMarkers(),
      MAP_UPDATE_DEBOUNCE_MS,
    ) as unknown as number;
  }

  private _loadStorageLocationMarkers() {
    this._storageLocationMarkers = this.storageLocations?.map((s) => this.createStorageLocationMarker(s));
    this._loadStorageLocationCircles(true);
  }

  private loadStorageLocationCircles(shopsDidChange = false) {
    if (this.loadStorageLocationCirclesTimeout) {
      clearTimeout(this.loadStorageLocationCirclesTimeout);
    }
    this.loadStorageLocationCirclesTimeout = setTimeout(
      () => this._loadStorageLocationCircles(shopsDidChange),
      MAP_UPDATE_DEBOUNCE_MS,
    ) as unknown as number;
  }

  private _loadStorageLocationCircles(shopsDidChange = false) {
    const isObfuscating = this.isObfuscating;
    const wasObfuscating = this.wasObfuscating;

    if (isObfuscating === wasObfuscating && !shopsDidChange) {
      return; // Nothing changed
    }

    // Update storage location circles
    this._storageLocationCircles = isObfuscating
      ? this.storageLocations?.map((s) => this.createStorageLocationCircle(s))
      : [];

    // Record new obfuscation state and update view
    this.wasObfuscating = isObfuscating;
    this.cd.markForCheck();
  }

  private createStorageLocationMarker(storageLocation: ShopDisplayModel): CustomMapMarker {
    const marker: CustomMapMarker = {
      storageLocation,
      position: {
        lat: storageLocation.shopItem.location.coordinates[1],
        lng: storageLocation.shopItem.location.coordinates[0],
      },
      icon: this.getStorageLocationMarkerIcon(storageLocation),
    };
    return marker;
  }

  private createStorageLocationCircle(storageLocation: ShopDisplayModel): MapCircleOptions {
    const zoomToRadiusFactor = this.largeStorageLocationCircles ? 8 : 4.5;
    const currentZoom = this.zoom || (this.angularGoogleMap?.zoom ?? this.zoom);
    const mapCircleRadius = currentZoom * zoomToRadiusFactor;

    const circle: MapCircleOptions = {
      center: {
        lat: storageLocation.shopItem.location.coordinates[1],
        lng: storageLocation.shopItem.location.coordinates[0],
      },
      radius: mapCircleRadius,
      fillColor: this.mapMarkerThemeOptions.mapCircleFillColor,
      strokeColor: this.mapMarkerThemeOptions.mapCircleStrokeColor,
      strokeOpacity: 0.5,
      strokeWeight: 0,
      clickable: false,
    };
    return circle;
  }

  private updateStorageLocationMarkerIcon(storageLocationId: string): void;
  private updateStorageLocationMarkerIcon(marker: CustomMapMarker): void;
  private updateStorageLocationMarkerIcon(storageLocationIdOrMarker: string | CustomMapMarker): void {
    let marker: CustomMapMarker | undefined;

    if (typeof storageLocationIdOrMarker === 'string') {
      marker = this.storageLocationMarkers?.find((m) => {
        if (m.storageLocation != null) {
          return m.storageLocation.id === storageLocationIdOrMarker;
        }

        return false;
      });
    } else {
      marker = storageLocationIdOrMarker;
    }

    if (!marker || !marker.storageLocation) {
      return;
    }

    marker.icon = this.getStorageLocationMarkerIcon(marker.storageLocation);
  }

  private isActive(storageLocation: ShopDisplayModel): boolean {
    if (!storageLocation || !storageLocation.shopItem.available) {
      return false;
    }

    const isHighlighted = this.highlightedStorageLocationId === storageLocation.id;
    const isOpen = this.openStorageLocation?.id === storageLocation.id;

    return isHighlighted || isOpen;
  }

  private getStorageLocationMarkerIcon(storageLocation?: ShopDisplayModel): Optional<google.maps.Icon> {
    if (storageLocation == null) {
      return null;
    }

    const isActive = this.largeStorageLocationMarkers || this.isActive(storageLocation);
    const isBestChoice = this.isBestChoice(storageLocation);
    const isUnavailable = !storageLocation.shopItem.available;
    const markerIconUrl = this.getMarkerIconUrl(isBestChoice, isUnavailable, isActive);
    const size = this.getMarkerIconSize(isBestChoice);

    if (markerIconUrl == null) {
      return null;
    }

    return {
      url: markerIconUrl,
      size,
      scaledSize: size,
      anchor: new google.maps.Point(size.width / 2, size.height / 2),
    };
  }

  private getBestChoiceMarkerIconUrl(isUnavailable: boolean, isActive: boolean): Optional<string> {
    if (isUnavailable) {
      return this.mapMarkerThemeOptions.unavailableMapMarkerBestChoiceIcon ?? null;
    }
    if (isActive) {
      return this.mapMarkerThemeOptions.activeMapMarkerBestChoiceIcon ?? null;
    }
    return this.mapMarkerThemeOptions.mapMarkerBestChoiceIcon ?? null;
  }

  private getRegularMarkerIconUrl(isUnavailable: boolean, isActive: boolean): Optional<string> {
    if (isUnavailable) {
      return this.mapMarkerThemeOptions.unavailableMapMarkerIcon ?? null;
    }
    if (isActive) {
      return this.mapMarkerThemeOptions.activeMapMarkerIcon ?? null;
    }

    return this.mapMarkerThemeOptions.mapMarkerIcon ?? null;
  }

  private getMarkerIconUrl(
    isBestChoice: boolean,
    isUnavailable: boolean,
    isActive: boolean,
  ): Optional<string> | undefined {
    if (isBestChoice) {
      return this.getBestChoiceMarkerIconUrl(isUnavailable, isActive);
    }

    return this.getRegularMarkerIconUrl(isUnavailable, isActive);
  }

  private getMarkerIconSize(isBestChoice: boolean): google.maps.Size {
    const factor =
      this.largeStorageLocationMarkers || isBestChoice ? 1 : this.mapMarkerThemeOptions.markerSizeDownscaleFactor;

    return new google.maps.Size(
      STORAGE_LOCATION_MARKER_ICON_WIDTH * factor,
      STORAGE_LOCATION_MARKER_ICON_HEIGHT * factor,
    );
  }

  private updateMapOptions() {
    this._mapOptions = {
      center: this.center,
      zoom: this.zoom,
      maxZoom: this.maxZoom,
      disableDefaultUI: true,
      clickableIcons: this.clickableIcons,
      keyboardShortcuts: this.keyboardShortcuts,
      fullscreenControl: this.fullscreenControl,
      scaleControl: this.scaleControl,
      streetViewControl: this.streetViewControl,
      zoomControl: this.zoomControl,
      zoomControlOptions: this.zoomControlOptions,
      disableDoubleClickZoom: this.disableDoubleClickZoom,
      scrollwheel: this.scrollwheel,
      draggable: this.draggable,
      gestureHandling: this.gestureHandling,
      styles: this.styles,
    };
  }
}
