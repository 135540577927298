import { Provider } from '@angular/core';
import { Config } from '@luggagehero/shared/environment';

import { ConsoleTarget } from './lib/console.target';
import { DataDogTarget } from './lib/datadog.target';
import { LOGGER_TOKEN, LogTargetOptions, LogTargetOptionsError } from './lib/log.target';

export * from './lib/console.service';
export * from './lib/console.target';
export * from './lib/datadog.target';
export * from './lib/log.target';
export * from './lib/shared-logging.service';

export const LOG_PROVIDERS: Provider[] = [{ provide: LogTargetOptions, useClass: LogTargetOptionsError }];

// Use ConsoleTarget in development and staging
if (Config.isDevelopment || Config.isStaging) {
  LOG_PROVIDERS.push({ provide: LOGGER_TOKEN, useClass: ConsoleTarget, multi: true });
}

// Use DataDogTarget in production and staging
if (Config.isProduction || Config.isStaging) {
  LOG_PROVIDERS.push({ provide: LOGGER_TOKEN, useClass: DataDogTarget, multi: true });
}
