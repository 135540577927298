import { SharedLoggingService } from '@luggagehero/shared/services/logging';
import { TranslateDefaultParser } from '@ngx-translate/core';

import { SharedTranslationsService } from './shared-translations.service';

/**
 * Custom parser for ngx-translate that tracks which translations have been requested by the current application
 */
export class SharedTranslateParser extends TranslateDefaultParser {
  private readonly requestedTranslations = new Set<string>();
  private readonly submitTranslationsDelay = 3 * 1000;
  private submitDebounceTimer: NodeJS.Timeout = null;

  constructor(
    private translationsService: SharedTranslationsService,
    private log: SharedLoggingService,
  ) {
    super();
  }

  public getValue(target: Record<string, string>, key: string): unknown {
    this.registerRequestedTranslation(key);
    return super.getValue(target, key);
  }

  private registerRequestedTranslation(key: string): void {
    if (!this.isTranslationKey(key) || this.requestedTranslations.has(key)) {
      // Key already registered, or not a valid translation key
      return;
    }
    this.requestedTranslations.add(key);

    if (this.submitDebounceTimer) {
      clearTimeout(this.submitDebounceTimer);
    }

    this.submitDebounceTimer = setTimeout(() => this.submitRequestedTranslations(), this.submitTranslationsDelay);
  }

  private submitRequestedTranslations(): void {
    this.submitDebounceTimer = null;

    const subscription = this.translationsService
      .registerAppTranslationKeys(Array.from(this.requestedTranslations))
      .subscribe({
        complete: () => subscription.unsubscribe(),
        error: (err) => void this.log.error('Failed to submit translations', err),
      });
  }

  private isTranslationKey(key: string): boolean {
    //
    // Translation keys by convention are uppercase and may contain underscores. If we encounter a key that does not
    // match this pattern, it is likely a hardcoded text string and not a translation key.
    //
    return /^[A-Z_0-9]+$/.test(key);
  }
}
