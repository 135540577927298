<!-- Dummy input for google places -->
<input #googlePlacesInput hidden />

<!--Shop criteria for small screens-->
<lh-shop-criteria
  *ngIf="criteria"
  [(ngModel)]="criteria"
  [estimatedPrice]="estimatedPrice"
  [pricing]="pricing"
></lh-shop-criteria>

<!--View options for small screens-->
<div class="view-options-bar" *ngIf="!isSmallScreen">
  <button
    id="showShopListView"
    type="button"
    (click)="showView('list')"
    class="btn"
    [class.full-width]="!isShopFilteringEnabled"
    [class.hidden]="showList"
    [title]="'LIST_VIEW' | translate"
  >
    <i class="icon-list"></i>{{ 'TOGGLE_LIST' | translate }}
  </button>
  <button
    id="showShopMapView"
    type="button"
    (click)="showView('map')"
    class="btn"
    [class.full-width]="!isShopFilteringEnabled"
    [class.hidden]="!showList"
    [title]="'MAP_VIEW' | translate"
  >
    <i class="icon-map"></i>{{ 'TOGGLE_MAP' | translate }}
  </button>
  <button id="showShopFilters" *ngIf="isShopFilteringEnabled" type="button" class="btn" (click)="filterModal.show()">
    <i class="icon-equalizer"></i>{{ 'FILTER' | translate }}
  </button>
</div>

<!--Shop map/list-->
<div class="map-list">
  <div class="promo-alert" *ngIf="isAlertAllowed && visibleShops?.length > 0">
    <div class="alert text-center" [class.alert-info]="!simpleAddPromoCodeAlert" *ngIf="!isPromoCodeAlertEnabled">
      <div class="alert-message">{{ 'EXACT_ADDRESS_AND_DIRECTIONS_AFTER_BOOKING' | translate }}</div>
    </div>

    <div
      class="alert"
      [class.alert-info]="!simpleAddPromoCodeAlert"
      [class.text-center]="simpleAddPromoCodeAlert"
      *ngIf="showAddPromoCodeAlert"
    >
      <div class="alert-message">
        <span>{{ 'HAVE_A_VOUCHER_OR_PROMO_CODE' | translate }}</span>
        <a id="addPromoCode" class="href alert-cta" (click)="showPromoCodeModal()">{{ 'ADD_NOW' | translate }}</a>
      </div>
    </div>

    <div class="alert alert-success" *ngIf="showValidPromoCodeAlert">
      <div class="alert-message">
        <span>{{ 'DISCOUNT_APPLIED' | translate }}: {{ checkedDiscount | discount }}</span>
        <a id="showPromoCodeDetails" class="href alert-cta" (click)="showPromoCodeModal()">{{ 'INFO' | translate }}</a>
      </div>
    </div>

    <div class="alert alert-warning" *ngIf="showInvalidPromoCodeAlert">
      <div class="alert-message">
        <span>{{ 'PROMO_CODE' | translate }} "{{ savedPromoCode }}" {{ 'INVALID' | translate }}</span>
        <a id="editPromoCode" class="href alert-cta" (click)="showPromoCodeModal()">{{ 'EDIT' | translate }}</a>
      </div>
    </div>

    <button
      id="dismissPromoCodeAlert"
      type="button"
      class="btn-dismiss pull-right"
      aria-label="Dismiss"
      (click)="dismissPromoCodeAlert()"
      *ngIf="isAlertVisible && isPromoCodeAlertEnabled && (!simpleAddPromoCodeAlert || !showAddPromoCodeAlert)"
    >
      <svg viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
        />
        <path d="M0 0h24v24H0z" fill="none" />
      </svg>
    </button>
  </div>

  <div [hidden]="!showList" class="shop-list d-none d-sm-block">
    <lh-shop-list
      #shopList
      [shops]="visibleShops"
      [(highlightShop)]="highlightShop"
      [(hideUnavailable)]="hideUnavailable"
      [isLoading]="isLoading"
      [isNoShopsAvailable]="isNoShopsAvailable"
      [numberOfShopsAvailable]="visibleShops.length"
    ></lh-shop-list>
  </div>

  <div *ngIf="isMapInitialized" [hidden]="!showMap" class="map-wrapper" [class.alert-padding]="isAlertVisible">
    <div *ngIf="location" class="redo-search" [class.alert-padding]="isAlertVisible">
      <div *ngIf="!showRedoSearchButton" class="checkbox-redo">
        <div class="checkbox">
          <label for="map-auto-refresh-checkbox">
            <input type="checkbox" id="map-auto-refresh-checkbox" [(ngModel)]="isSearchOnMoveMapEnabled" />{{
              'SEARCH_ON_MOVE_MAP' | translate
            }}
          </label>
        </div>
      </div>
      <div *ngIf="showRedoSearchButton" class="button-redo">
        <button id="redoSearchHere" type="button" class="btn btn-primary" (click)="loadShopsByMapLocation()">
          <label>{{ 'REDO_SEARCH_HERE' | translate }}<i class="icon-reload"></i></label>
        </button>
      </div>
    </div>

    <!-- Shop map/card -->
    <lh-shop-map-card
      *ngIf="isSmallScreen"
      [shops]="visibleShops"
      [activeShop]="nearestShop"
      [initialSelectedShopId]="initialSelectedShopId"
      [(highlightShop)]="highlightShop"
      [hideUnavailable]="hideUnavailable"
      [location]="location"
      (locationChange)="onMapLocationChange($event)"
      (userLocationRequest)="loadUserLocation()"
      [isLoading]="isLoading"
      [isInitialized]="loadAttempted"
      [class.tab-bar-padding]="isTabbedNavigationEnabled"
      #shopMap
    >
    </lh-shop-map-card>

    <!-- Shop map w info windows -->
    <div *ngIf="!isSmallScreen">
      <lh-shop-map
        #shopMap
        [shops]="visibleShops"
        [(highlightShop)]="highlightShop"
        [hideUnavailable]="hideUnavailable"
        [location]="location"
        (locationChange)="onMapLocationChange($event)"
      >
      </lh-shop-map>
    </div>
  </div>
</div>

<!-- Filter modal -->
<div
  class="modal fade"
  bsModal
  #filterModal="bs-modal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="mySmallModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-lg modal-fullscreen-sm full-height-footer">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left">
          <div>{{ 'FILTER_SHOPS' | translate }}</div>
        </h4>
        <button
          id="closeFilterModal"
          type="button"
          class="close pull-right"
          aria-label="Close"
          (click)="filterModal.hide()"
        >
          <svg viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
            />
            <path d="M0 0h24v24H0z" fill="none" />
          </svg>
        </button>
      </div>

      <div class="modal-body">
        <!--Availability-->
        <div class="checkbox">
          <label for="onlyAvailableShops">
            <input
              id="onlyAvailableShops"
              type="checkbox"
              #onlyAvailableShops
              name="onlyAvailableShops"
              [(ngModel)]="hideUnavailable"
            />
            {{ 'ONLY_AVAILABLE_SHOPS' | translate }}
          </label>
        </div>

        <!--Wi-Fi-->
        <div class="checkbox">
          <label for="onlyShopsWithWifi">
            <input
              id="onlyShopsWithWifi"
              type="checkbox"
              #onlyShopsWithWifi
              name="onlyShopsWithWifi"
              [(ngModel)]="showOnlyShopsWithWifi"
            />
            {{ 'ONLY_SHOPS_WITH_WIFI' | translate }}
          </label>
        </div>
      </div>

      <div class="modal-footer">
        <button id="applyFilters" class="btn btn-warning" [disabled]="isLoading" (click)="applyFilters()">
          {{ 'APPLY' | translate }}
        </button>
        <button id="cancelFilters" class="btn btn-info" (click)="filterModal.hide()">{{ 'CANCEL' | translate }}</button>
      </div>
    </div>
  </div>
</div>

<!-- Promo code modal -->
<div
  class="modal fade"
  bsModal
  #promoCodeModal="bs-modal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="mySmallModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog">
    <div class="modal-content" *ngIf="promoCodeModal.isShown">
      <div class="modal-header">
        <h4 class="modal-title pull-left">
          <div>{{ 'PROMOTIONS' | translate }}</div>
        </h4>
        <button
          id="closePromoCodeModal"
          type="button"
          class="close pull-right"
          aria-label="Close"
          (click)="cancelPromoCode()"
        >
          <svg viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
            />
            <path d="M0 0h24v24H0z" fill="none" />
          </svg>
        </button>
      </div>

      <div class="modal-body">
        <p>{{ 'DISCOUNT_VOUCHER_EXPLANATION' | translate }}</p>

        <div class="form-group">
          <div class="input-group">
            <input
              type="text"
              autocomplete="off"
              class="form-control discount-code"
              [(ngModel)]="promoCode"
              [placeholder]="'CODE' | translate"
              (keyup.enter)="checkPromoCodeButton.click()"
              [lhAutoFocus]="!promoCode"
            />
            <span class="input-group-btn">
              <button
                id="checkPromoCode"
                class="btn btn-primary"
                type="button"
                (click)="checkPromoCode()"
                [disabled]="!canCheckPromoCode"
                #checkPromoCodeButton
              >
                {{ 'VALIDATE_CODE' | translate }}
              </button>
            </span>
          </div>
          <div class="alert alert-danger" *ngIf="showCheckedPromoCodeInvalid">
            <span>{{ 'THE_CODE' | translate }} {{ checkedPromoCode }} {{ 'IS_NOT_VALID' | translate }}</span>
          </div>
          <div class="alert alert-success" *ngIf="showCheckedPromoCodeValid">
            <span>{{ checkedDiscount | discount }}</span>
          </div>
        </div>

        <!-- <p *ngIf="canRemovePromoCode">
          <strong>{{ 'YOURE_ALL_SET' | translate}}&nbsp;</strong>
          <span>{{ 'PROMO_CODE' | translate }} "{{ savedPromoCode }}" {{ 'WILL_BE_APPLIED_TO_BOOKING' | translate }}</span>          
          <a id="removeSavedPromoCode" class="inline-link href" (click)="removePromoCode()">{{ 'REMOVE_CODE' | translate }}</a>
        </p> -->

        <div class="text-center">
          <lh-shared-ui-spinner [visible]="isCheckingPromoCode"></lh-shared-ui-spinner>
        </div>

        <button
          id="saveDiscountCode"
          class="btn btn-block btn-primary lh-btn"
          (click)="savePromoCode()"
          *ngIf="canSavePromoCode"
        >
          {{ 'APPLY_CODE' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
