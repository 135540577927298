import { Injectable } from '@angular/core';
import { Config } from '@luggagehero/shared/environment';
import { BookingStatus } from '@luggagehero/shared/interfaces';
import { SharedHttpService } from '@luggagehero/shared/services/http';

export enum SearchResultType {
  Booking = 'Booking',
}

export interface SearchResult {
  type: SearchResultType;
  id: string;
  data: unknown;
  status: BookingStatus;
}

@Injectable()
export class SearchService {
  private apiEndPoint: string;
  constructor(private http: SharedHttpService) {
    this.apiEndPoint = Config.environment.STORAGE_MANAGER_API;
  }
  async search(query: string): Promise<SearchResult[]> {
    const res = await this.http.get<SearchResult[]>(`${this.apiEndPoint}/search?query=${query}`);
    return res;
  }
}
