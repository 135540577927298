export type WindowSizeGroup = 'xs' | 'sm' | 'md' | 'lg';
export type BrowserName = 'Safari' | 'Chrome' | 'Edge' | 'Firefox' | 'IE' | 'Opera';
export const SMALL_SCREEN_SIZES: WindowSizeGroup[] = ['xs', 'sm'];
export const SCREEN_SIZE_XS = 480;
export const SCREEN_SIZE_SM = 768;
export const SCREEN_SIZE_MD = 992;
export const SCREEN_SIZE_LG = 1200;

export class WindowSizeInfo {
  public readonly sizeGroup: WindowSizeGroup;
  public readonly isSmallScreen: boolean;

  constructor(
    public readonly windowWidth: number,
    public readonly windowHeight: number,
    public readonly screenWidth: number,
    public readonly screenHeight: number,
  ) {
    // Determine the size group based on the window width
    if (windowWidth < SCREEN_SIZE_XS) {
      this.sizeGroup = 'xs';
    } else if (windowWidth < SCREEN_SIZE_SM) {
      this.sizeGroup = 'sm';
    } else if (windowWidth < SCREEN_SIZE_MD) {
      this.sizeGroup = 'md';
    } else {
      this.sizeGroup = 'lg';
    }
    // Determine if the screen is small based on the size group
    this.isSmallScreen = SMALL_SCREEN_SIZES.includes(this.sizeGroup);
  }
}
