import { Inject, Pipe, PipeTransform } from '@angular/core';
import { MOMENT_SERVICE, MomentService } from '@luggagehero/shared/interfaces';

@Pipe({ name: 'lhMoment', standalone: true })
export class MomentPipe implements PipeTransform {
  constructor(@Inject(MOMENT_SERVICE) private momentService: MomentService) {}

  transform(value: string | Date, format = '', timezone?: string): string {
    return this.momentService.formatDate(value, format, timezone);
  }
}
