/**
 * Represents the different types of issues a user can report.
 *
 * IMPORTANT: The values of the enum are used as keys for translation, so they should not be changed without also
 * updating the corresponding entries in the i18n language files.
 */
export enum BookingIncidentType {
  LocationClosedOnArrival = 'INCIDENT_LOCATION_CLOSED_ON_ARRIVAL',
  AskedToPayDirectly = 'INCIDENT_ASKED_TO_PAY_DIRECTLY',
  StaffUnawareOfService = 'INCIDENT_STAFF_UNAWARE_OF_SERVICE',
  LocationRefusedBags = 'INCIDENT_LOCATION_REFUSED_BAGS',
  UnableToFindLocation = 'INCIDENT_UNABLE_TO_FIND_LOCATION',
}
