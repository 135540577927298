/// <reference types="@types/google.maps" />

import { inject, Injectable } from '@angular/core';
import { Place } from '@luggagehero/shared/interfaces';
import { SharedHttpService } from '@luggagehero/shared/services/http';

import { SHARED_GEOCODING_SERVICE_CONFIG_TOKEN } from './shared-geocoding-service.token';
import { SharedGeocodingServiceConfig } from './shared-geocoding-service-config.interface';

@Injectable({
  providedIn: 'root',
})
export class SharedGeocodingService {
  private config: SharedGeocodingServiceConfig = inject(SHARED_GEOCODING_SERVICE_CONFIG_TOKEN);

  constructor(private http: SharedHttpService) {}

  public async requestReverseGeoCodeForLatLong(lat: number, lon: number, tag: string): Promise<Place> {
    const url = `${this.config.apiURL()}/places?lat=${lat}&lon=${lon}&tag=${tag}`;
    return this.http.get<Place>(url, null);
  }

  public async requestReverseGeoCodeForPlaceId(placeId: string, tag: string): Promise<Place> {
    const url = `${this.config.apiURL()}/places?placeId=${placeId}&tag=${tag}`;
    return this.http.get<Place>(url, null);
  }

  public async autocomplete(
    input: string,
    types?: string,
    tag?: string,
  ): Promise<google.maps.places.AutocompletePrediction[]> {
    const url = new URL(`${this.config.apiURL()}/places/autocomplete`);
    url.searchParams.append('input', input);

    if (types) {
      url.searchParams.append('types', types);
    }

    if (tag) {
      url.searchParams.append('tag', tag);
    }

    return this.http.get<google.maps.places.AutocompletePrediction[]>(url.toString(), null);
  }
}
