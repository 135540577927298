import { ErrorHandler, Provider } from '@angular/core';
import { DISTANCE_SERVICE, GOOGLE_MAPS_SERVICE } from '@luggagehero/shared/interfaces';
import { SharedDistanceService } from '@luggagehero/shared/services/distance';
import { SharedErrorService } from '@luggagehero/shared/services/error';
import { SharedGoogleMapsService } from '@luggagehero/shared/services/google-maps';
import { SharedHolidayService } from '@luggagehero/shared/services/holiday';
import { LegacyOrderService } from '@luggagehero/shared/services/orders';
import { SharedParamsService } from '@luggagehero/shared/services/params';
import { SharedReviewsService } from '@luggagehero/shared/services/reviews';
import { SharedShopsService } from '@luggagehero/shared/services/shops';
import { SharedThemeService } from '@luggagehero/shared/services/theme';

import { AppleSigninService } from './apple-signin.service';
import { CountryService } from './country.service';
import { CurrencyService } from './currency.service';
import { FacebookSdk } from './facebook.sdk';
import { FacebookLoginService } from './facebook-login-service';
import { GoogleSigninService } from './google-signin.service';
import { IconService } from './icon.service';
import { InvoiceService } from './invoice.service';
import { IPLookupService } from './ip-lookup.service';
import { PageTaggingService } from './page-tagging.service';
import { BookingsyncService } from './partners/pms/bookingsync.service';
import { GuestyService } from './partners/pms/guesty.service';
import { SmoobuService } from './partners/pms/smoobu.service';
import { WalletPaymentService } from './payments/wallet-payment.service.';
import { PhoneNumberService } from './phone-number.service';
import { PromoCodeService } from './promo-code.service';
import { RedirectGuard } from './redirect-guard.service';
import { ScriptService } from './script.service';
import { SearchService } from './search/search.service';
import { PayoutService } from './storage-manager/payout.service';
import { StorageManagerService } from './storage-manager/storage-manager.service';
import { StorageTimerService } from './storage-timer.service';
import { SurveyService } from './survey.service';
import { SwapService } from './swaps/swap.service';
import { SwapLocationsService } from './swaps/swap-locations.service';
import { UserActiveService } from './user-active.service';
import { VatNumberService } from './vat-number.service';

export const LUGGAGEHERO_PROVIDERS: Provider[] = [
  CountryService,
  SharedGoogleMapsService,
  { provide: GOOGLE_MAPS_SERVICE, useExisting: SharedGoogleMapsService },
  SharedShopsService,
  RedirectGuard,
  IconService,
  IPLookupService,
  AppleSigninService,
  FacebookLoginService,
  FacebookSdk,
  GoogleSigninService,
  SharedThemeService,
  BookingsyncService,
  GuestyService,
  SmoobuService,
  WalletPaymentService,
  { provide: ErrorHandler, useClass: SharedErrorService },
  SharedReviewsService,
  PageTaggingService,
  SharedParamsService,
  SurveyService,
  ScriptService,
  InvoiceService,
  VatNumberService,
  PhoneNumberService,
  SharedDistanceService,
  { provide: DISTANCE_SERVICE, useExisting: SharedDistanceService },
  UserActiveService,
  CurrencyService,
  PromoCodeService,
  StorageManagerService,
  PayoutService,
  SwapService,
  SwapLocationsService,
  LegacyOrderService,
  StorageTimerService,
  SearchService,
  SharedHolidayService,
];

export * from './app-version-guard.service';
export * from './apple-signin.service';
export * from './auth-guard.service';
export * from './country.service';
export * from './currency.service';
export * from './facebook.sdk';
export * from './facebook-login-service';
export * from './google-signin.service';
export * from './icon.service';
export * from './invoice.service';
export * from './ip-lookup.service';
export * from './page-tagging.service';
export * from './partners/pms/bookingsync.service';
export * from './partners/pms/guesty.service';
export * from './partners/pms/smoobu.service';
export * from './phone-number.service';
export * from './promo-code.service';
export * from './redirect-guard.service';
export * from './script.service';
export * from './shops/address';
export * from './shops/capacity';
export * from './shops/exceptional-opening-hours';
export * from './shops/holiday-opening-hours';
export * from './shops/opening-hours';
export * from './shops/place';
export * from './shops/time-interval';
export * from './storage-manager/payout.service';
export * from './storage-manager/payout-verification-status';
export * from './storage-manager/storage-location';
export * from './storage-manager/storage-manager.service';
export * from './storage-timer.service';
export * from './survey.service';
export * from './swaps';
export * from './user-active.service';
export * from './vat-number.service';
