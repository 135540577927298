import { Component, Input, OnInit } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import moment from 'moment';

import { BaseComponent } from '../../../core';
import { DateUtil } from '../../../utils/date.util';

const noop = () => {
  /**/
};

@Component({ template: '' })
export abstract class DateTimePickerBaseComponent extends BaseComponent implements OnInit, ControlValueAccessor {
  public minTime: Date = new Date();

  private _disabledDates: (date: Date) => boolean;
  private _minDate: Date = new Date(this.minTime.getFullYear(), this.minTime.getMonth(), this.minTime.getDate());
  private _maxDate: Date = DateUtil.addDays(new Date(), 10 * 365);
  private _value: Date;
  private _selectedDate: Date;
  private _selectedTime: Date;
  private _isSelectTimeEnabled = false;
  private isInitialized = false;

  // These are the localized date and time strings that will be
  // displayed to the user on mobile as options in list pickers
  private dateDisplayItems: string[] = [];
  private timeDisplayItems: string[] = [];

  // These are the internal date and time values being selected
  // when the user changes the index of the list pickers
  private dateOptions: Date[] = [];
  private timeOptions: Date[] = [];

  private onTouchedCallback: () => void = noop;
  private onChangeCallback: (_) => void = noop;

  @Input() set disabledDates(value: (date: Date) => boolean) {
    this._disabledDates = value;
    this._markForCheck();
  }
  get disabledDates(): (date: Date) => boolean {
    return this._disabledDates;
  }

  @Input() set minDate(value: Date) {
    this._minDate = value;
    this.loadMobileOptions();
    this._markForCheck();
  }
  get minDate(): Date {
    return this._minDate;
  }

  @Input() set maxDate(value: Date) {
    this._maxDate = value;
    this.loadMobileOptions();
    this._markForCheck();
  }
  get maxDate(): Date {
    return this._maxDate;
  }

  set value(value: Date) {
    if (!value) {
      return;
    }
    value = DateUtil.roundTimeHalfHour(value);
    if (this._value && value.getTime() === this._value.getTime()) {
      return;
    }
    this._value = value;
    this._selectedDate = value;
    this._selectedTime = value;
    this.isInitialized = true;

    // FIXME: This causes stack overflow (enable only on mobile?)
    // this.updateSelectedTime();

    this.onChangeCallback(value);
    this._markForCheck();
  }
  get value(): Date {
    return this._value;
  }

  set selectedDate(value: Date) {
    if (!value) {
      return;
    }
    if (this._selectedDate && value.getTime() === this._selectedDate.getTime()) {
      return;
    }
    this._selectedDate = value;

    this.updateMinTime();
    this.loadMobileTimeOptions();

    this.apply();
  }
  get selectedDate(): Date {
    return this._selectedDate;
  }

  set selectedTime(value: Date) {
    if (!value) {
      return;
    }
    value = DateUtil.roundTimeHalfHour(value);
    if (this._selectedTime && this._selectedTime.getTime() === value.getTime()) {
      return;
    }
    this._selectedTime = value;
    this._detectChanges();

    // TODO: Is this needed on mobile?
    // this.apply();
  }
  get selectedTime(): Date {
    return this._selectedTime;
  }

  get isSelectTimeEnabled(): boolean {
    return this._isSelectTimeEnabled;
  }
  set isSelectTimeEnabled(value: boolean) {
    this._isSelectTimeEnabled = value;
    this._detectChanges();
  }

  get is12HourMode(): boolean {
    return DateUtil.is12HourMode;
  }

  ngOnInit() {
    this.loadMobileOptions();
  }

  writeValue(value: Date) {
    if (value !== undefined) {
      this.value = value;
    }
  }

  registerOnChange(fn: (_) => void) {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: () => void) {
    this.onTouchedCallback = fn;
  }

  apply() {
    if (!this.isInitialized) {
      return;
    }
    this.isSelectTimeEnabled = false;

    const newDate = moment(this.selectedDate).format('YYYYMMDD');
    const previousDate = moment(this.value).format('YYYYMMDD');

    if (newDate === previousDate) {
      // No change
      return;
    }

    // Prepare the new date value
    const newValue = new Date(
      this.selectedDate.getFullYear(),
      this.selectedDate.getMonth(),
      this.selectedDate.getDate(),
      this.selectedTime.getHours(),
      this.selectedTime.getMinutes(),
    );

    if (newDate === moment().format('YYYYMMDD')) {
      // Set time to current time when date was changed to today's date
      const now = DateUtil.roundTimeHalfHour(new Date());
      newValue.setHours(now.getHours(), now.getMinutes(), 0, 0);
    } else {
      // Otherwise set time to 12 noon
      newValue.setHours(12, 0, 0, 0);
    }

    // Update date with new value
    this.value = newValue;
  }

  disableSelectTime(e: Event) {
    if (e.stopPropagation) {
      e.stopPropagation();
    }
    this.isSelectTimeEnabled = false;
  }

  enableSelectTime(_e: Event) {
    this.isSelectTimeEnabled = true;
  }

  private updateMinTime() {
    if (DateUtil.isSameDate(this.selectedDate, this.minDate)) {
      this.minTime = new Date();
    } else {
      this.minTime = moment(new Date()).startOf('day').toDate();
    }
  }

  private loadMobileOptions() {
    this.loadMobileDateOptions();
    this.loadMobileTimeOptions();
    this._markForCheck();
  }

  private loadMobileDateOptions() {
    this.dateOptions = [];
    this.dateDisplayItems = [];

    const currentDate = moment(this.minDate);
    while (currentDate.toDate() <= this.maxDate) {
      this.dateOptions.push(currentDate.toDate());
      this.dateDisplayItems.push(currentDate.format('MMM D'));
      currentDate.add(1, 'days');
    }
  }

  private loadMobileTimeOptions() {
    this.timeOptions = [];
    this.timeDisplayItems = [];

    let currentTime = moment(this.minDate);
    if (DateUtil.isAtLeastOneDayLater(this.minDate, this.selectedDate)) {
      currentTime = currentTime.startOf('day');
    }
    const _isMaxDate = DateUtil.isSameDate(this.selectedDate, this.maxDate);
    const dateOfMonth = currentTime.date();

    while (currentTime.date() === dateOfMonth && !this.reachedMaxTime(currentTime)) {
      this.timeOptions.push(currentTime.toDate());
      this.timeDisplayItems.push(currentTime.format('HH:mm'));
      currentTime.add(30, 'minutes');
    }

    // Check if selected time is still valid, otherwise reset
    if (this._selectedTime && DateUtil.getTimeIndexOf(this.selectedTime, this.timeOptions) < 0) {
      this._selectedTime = this.timeOptions[0];
    }
  }

  private reachedMaxTime(currentTime: moment.Moment): boolean {
    return (
      DateUtil.isSameDate(this.selectedDate, this.maxDate) && DateUtil.isSameTime(currentTime.toDate(), this.maxDate)
    );
  }
}
