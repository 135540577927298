import { ChangeDetectorRef, Component } from '@angular/core';
import { SharedErrorService } from '@luggagehero/shared/services/error';
import { SharedLoggingService } from '@luggagehero/shared/services/logging';
import { INewUser, SharedUserService } from '@luggagehero/shared/services/users';
import { TranslateService } from '@ngx-translate/core';

import { BaseComponent } from '../../../core';

// TODO: Delete this component as the register is part of login
@Component({ template: '' })
export abstract class RegisterBaseComponent extends BaseComponent {
  user: INewUser = {
    email: '',
    password: '',
    name: '',
    phone: '',
    appStoreReviewRequested: false,
    playStoreReviewRequested: false,
  };
  private _isLoading = false;

  constructor(
    private loginService: SharedUserService,
    private error: SharedErrorService,
    private translate: TranslateService,
    private log: SharedLoggingService,
    private cd: ChangeDetectorRef,
  ) {
    super();
  }

  get isLoading(): boolean {
    return this._isLoading;
  }
  set isLoading(value: boolean) {
    this._isLoading = value;
    this.cd.markForCheck();
  }

  registerNewUser() {
    this.isLoading = true;

    this.loginService.registerUser(this.user).then(
      (response) => {
        if (response.success) {
          // TODO: Navigate to value of redirectTo instead?
          this.loginService.getUserAndRedirect('user/account').then(
            () => {
              void this.log.debug(`Redirected registered user to: 'user/account'`);
              this.isLoading = false;
            },
            (err) => {
              this.error.handleError(
                err,
                this.translate.instant('REGISTER_USER_ERROR_MESSAGE') as string,
                this.translate.instant('REGISTER_USER_ERROR_TITLE') as string,
              );
              this.isLoading = false;
            },
          );
        } else {
          this.error.handleError(
            response.message,
            response.message,
            this.translate.instant('REGISTER_USER_ERROR_TITLE') as string,
          );
          this.isLoading = false;
        }
      },
      (err) => {
        this.error.handleError(
          err,
          isErrorWithJson(err) && err.json().message,
          this.translate.instant('REGISTER_USER_ERROR_TITLE') as string,
        );
        this.isLoading = false;
      },
    );
  }
}

function isErrorWithJson(err: unknown): err is { json(): { message: string } } {
  return typeof err === 'object' && 'json' in err && typeof (err as { json(): unknown }).json === 'function';
}
