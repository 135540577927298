// angular
import { ChangeDetectorRef, Component, Input } from '@angular/core';
// feature modules
import { Booking } from '@luggagehero/shared/interfaces';

import { BaseComponent } from '../../../core';

@Component({ template: '' })
export abstract class BookingProgressBaseComponent extends BaseComponent {
  private _booking: Booking;
  private _isLoading = false;

  private confirmedStatusList = ['CONFIRMED', 'CHECKED_IN', 'CHECKED_OUT', 'PAID'];
  private checkedInStatusList = ['CHECKED_IN', 'CHECKED_OUT', 'PAID'];
  private checkedOutStatusList = ['CHECKED_OUT', 'PAID'];
  private paymentSuccessfulStatusList = ['PAID'];
  private paymentFailedStatusList = ['CHECKED_OUT'];
  private canceledStatusList = ['CANCELLED'];

  constructor(private cd: ChangeDetectorRef) {
    super();
  }

  @Input() set booking(value: Booking) {
    this._booking = value;
  }
  get booking() {
    return this._booking;
  }

  get isLoading(): boolean {
    return this._isLoading;
  }
  set isLoading(value: boolean) {
    this._isLoading = value;
    this.cd.markForCheck();
  }

  get isConfirmed(): boolean {
    return this.confirmedStatusList.indexOf(this.booking.status) >= 0;
  }

  get isCheckedIn(): boolean {
    return this.checkedInStatusList.indexOf(this.booking.status) >= 0;
  }

  get isCheckedOut(): boolean {
    return this.checkedOutStatusList.indexOf(this.booking.status) >= 0;
  }

  get isPaymentSuccessful(): boolean {
    return this.paymentSuccessfulStatusList.indexOf(this.booking.status) >= 0;
  }

  get isPaymentFailed(): boolean {
    return this.paymentFailedStatusList.indexOf(this.booking.status) >= 0;
  }

  get isCanceled(): boolean {
    return this.canceledStatusList.indexOf(this.booking.status) >= 0;
  }
}
