import { Booking, EncryptedString, InitiatePaymentResult, LegacyOrder } from '@luggagehero/shared/interfaces';
import JsCrypto from 'jscrypto';

type BookingEvent = 'created';
type PaymentEvent = 'initiated' | 'reinitiated' | 'confirmed' | 'failed';
type OrderEvent = 'generated';

const DEFAULT_SEPARATORS = [',', ';'];

export class SharedUtilString {
  /**
   * Checks if the provided string is a valid MongoDB BSON ObjectID.
   * @param id The ID string to validate
   * @returns `true` if the provided string is a valid ObjectID; otherwise, `false`.
   */
  static isValidObjectId(id: string) {
    if (typeof id !== 'string') {
      return false;
    }
    return /^[0-9a-fA-F]{24}$/.test(id);
  }

  static getCurrencySymbol(currency: string): string {
    let currencySymbol = '';
    switch (currency.toLowerCase()) {
      case 'eur':
        currencySymbol = '€';
        break;

      case 'usd':
        currencySymbol = '$';
        break;

      case 'gbp':
        currencySymbol = '£';
        break;

      case 'dkk':
        currencySymbol = 'kr ';
        break;

      case 'nok':
        currencySymbol = 'kr ';
        break;

      case 'sek':
        currencySymbol = 'kr ';
        break;

      default:
        currencySymbol = `${currency.toUpperCase()} `;
        break;
    }
    return currencySymbol;
  }

  static split(value: string, separators = DEFAULT_SEPARATORS): string[] {
    let separator: string | undefined;
    for (let i = 0; i < separators.length; i++) {
      if (value.includes(separators[i])) {
        separator = separators[i];
        break;
      }
    }
    return separator ? value.split(separator) : [value];
  }

  static isEncryptedString(value: EncryptedString): boolean {
    let isEncrypted = false;

    try {
      isEncrypted = value && typeof value['iv'] === 'string' && typeof value['content'] === 'string';
    } catch {
      // Ignore
    }

    return isEncrypted;
  }

  static tryDecryptAndParseString<T>(value: T | EncryptedString, cryptoKey: string): T {
    if (!SharedUtilString.isEncryptedString(value as EncryptedString)) {
      return value as T;
    }
    const decryptedString = SharedUtilString.decryptString(value as EncryptedString, cryptoKey);
    return JSON.parse(decryptedString) as T;
  }

  static tryDecryptString(value: string | EncryptedString, cryptoKey: string): string {
    if (!SharedUtilString.isEncryptedString(value as EncryptedString)) {
      return value as string;
    }
    return SharedUtilString.decryptString(value as EncryptedString, cryptoKey);
  }

  static decryptString(value: string | EncryptedString, cryptoKey: string): string {
    if (value) {
      try {
        if (typeof value === 'string') {
          const encryptedChars = JSON.parse(value) as number[];

          if (typeof encryptedChars[0] === 'number') {
            const chars = encryptedChars.map((c) => String.fromCharCode(c - 1313));
            value = chars.join('');
          }
        } else {
          const decryptedValue = JsCrypto.AES.decrypt(value.content, JsCrypto.Hex.parse(cryptoKey), {
            iv: JsCrypto.Hex.parse(value.iv),
            mode: JsCrypto.mode.GCM,
          });

          value = decryptedValue.toString(JsCrypto.Utf8);
        }
      } catch (err) {
        // Do nothing; if decryption fails, we will return the value as-is
        // console.log(`Error decrypting sensitive data: ${err.message}`, { err, value });
      }
    }

    return value as string;
  }

  static toBase64String(buffer: ArrayBuffer): string {
    const binaryString = String.fromCharCode(...new Uint8Array(buffer));
    const base64String = btoa(binaryString);

    return base64String;
  }

  static toPriceString(value: number, stripZeroDecimals = true): string {
    // Use two decimals in case the price is not an even number (so we show $12.50 instead of $12.5)
    let output = value.toFixed(2);

    if (stripZeroDecimals && output.endsWith('.00')) {
      // If the decimals are both zero, remove them (e.g. if 12.001 is rounded to 12.00 then we show as $12)
      output = output.substring(0, output.length - 3);
    }

    return output;
  }

  static normalizePhoneNumber(value: string): string {
    // Removes all non-digits from the phone number string
    let output = value.replace(/[\D]/g, '');

    if (output.startsWith('00')) {
      // Change leading 00 to leading +
      output = output.replace(/^00/, '+');
    }
    if (!output.startsWith('+')) {
      // Add + in front if not already there
      output = `+${output}`;
    }
    return output;
  }

  /**
   * Compares two version strings in the format `"x.y.z"` where `x`, `y` and `z` are integers. If the version strings
   * have a different number of parts, they are not comparable and the function returns 0.
   *
   * @param version1 The first version string
   * @param version2 The second version string
   * @returns 1 if `version1` is greater than `version2`, -1 if `version1` is less than `version2`, 0 if they are equal
   */
  static compareVersions(version1: string, version2: string): number {
    if (version1 === version2) {
      // The version strings are equal
      return 0;
    }

    const parts1 = version1?.split('.');
    const parts2 = version2?.split('.');

    if (parts1?.length !== parts2?.length) {
      // If the version strings have different number of parts, they are not comparable
      return 0;
    }

    for (let i = 0; i < parts1.length; i++) {
      const part1 = Number(parts1[i]);
      const part2 = Number(parts2[i]);

      if (part1 > part2) {
        return 1;
      } else if (part1 < part2) {
        return -1;
      }
    }

    return 0;
  }

  static errorToString(err: unknown): string {
    if (!err) {
      return '';
    }

    if (typeof err === 'string') {
      return err;
    }

    if (err instanceof Error) {
      return err.message;
    }

    if (typeof err === 'object' && 'statusText' in err && err.statusText) {
      return (err as { statusText?: string }).statusText || '';
    }

    if (typeof err === 'object' && 'message' in err && typeof err.message === 'string') {
      return err.message;
    }

    if (typeof err === 'object' && 'error' in err && typeof err.error === 'string') {
      return err.error;
    }

    if (
      typeof err === 'object' &&
      'error' in err &&
      typeof err.error === 'object' &&
      err.error !== null &&
      'message' in err.error &&
      typeof err.error.message === 'string'
    ) {
      return err.error.message;
    }

    return String(err);
  }

  static isUpperCase(value: string): boolean {
    return value === value.toUpperCase();
  }

  static toPasalCase(value: string, spaces = false): string {
    return (
      value
        // Split the input string by spaces or underscores
        .split(/[\s_]+/)
        // Capitalize the first letter and make the rest lowercase
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
        // Join all the words (with spaces if specified)
        .join(spaces ? ' ' : '')
    );
  }

  static uppercaseFirstLetter(value: string, lowercaseTail = false, allWords = false): string {
    if (!value) {
      return '';
    }

    const transform = (str: string): string => {
      const firstLetter = str[0].toUpperCase();
      const tail = str.substring(1);
      return `${firstLetter}${lowercaseTail ? tail.toLowerCase() : tail}`;
    };

    if (allWords) {
      return value.split(/[_ ]+/).map(transform).join(' ');
    }

    return transform(value);
  }

  static formatPhoneNumber(phoneNumber: string): string {
    return phoneNumber.replace(/(\d)(?=(\d{2})+$)/g, '$1 ');
  }

  static formatOrderEvent(o: LegacyOrder, event: OrderEvent): string {
    switch (event) {
      case 'generated':
        return `Generated ${o.pricingModel} quote for ${o.bags} bag(s): ${o.total} ${o.currency} (${o._id})`;

      default:
        return `Order ${o._id} ${event as string}`;
    }
  }

  static formatBookingEvent(b: Booking, event: BookingEvent): string {
    const o = b?.orderDetails?.[0];

    switch (event) {
      case 'created':
        return `Booking for ${b.luggage.normal} bag(s) created at ${b.shopName}: ${o?.total} ${o?.currency} (${b._id})`;

      default:
        return `Booking ${b._id} ${event as string}`;
    }
  }

  static formatPaymentEvent(p: InitiatePaymentResult, event: PaymentEvent): string {
    const { amount, currency } = p;

    switch (event) {
      case 'initiated':
      case 'reinitiated':
      case 'confirmed':
      case 'failed': {
        return `Payment ${event}: ${amount ? `${amount} ${currency}` : 'no charge'} (${p.id})`;
      }
      default:
        return `Payment ${p.id} ${event as string}`;
    }
  }
}
