import {
  ChangeDetectorRef,
  ComponentFactoryResolver,
  ComponentRef,
  Directive,
  Input,
  OnChanges,
  OnInit,
  Type,
  ViewContainerRef,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { IFormField, IFormFieldConfig } from '@luggagehero/shared/interfaces';

import { DynamicFormButtonComponent } from '../components/dynamic-form-button/dynamic-form-button.component';
import { DynamicFormInputComponent } from '../components/dynamic-form-input/dynamic-form-input.component';
import { DynamicFormSelectComponent } from '../components/dynamic-form-select/dynamic-form-select.component';

const SUPPORTED_TYPES: { [type: string]: Type<IFormField> } = {
  button: DynamicFormButtonComponent,
  input: DynamicFormInputComponent,
  select: DynamicFormSelectComponent,
};

@Directive({
  selector: '[lhDynamicFormField]',
})
export class DynamicFormFieldDirective implements IFormField, OnInit, OnChanges {
  component: ComponentRef<IFormField>;

  private _config: IFormFieldConfig;
  private _group: FormGroup;

  constructor(
    private resolver: ComponentFactoryResolver,
    private container: ViewContainerRef,
    private cd: ChangeDetectorRef,
  ) {}

  get config(): IFormFieldConfig {
    return this._config;
  }
  @Input() set config(value: IFormFieldConfig) {
    this._config = value;
    this.cd.markForCheck();
  }

  get group(): FormGroup {
    return this._group;
  }
  @Input() set group(value: FormGroup) {
    this._group = value;
    this.cd.markForCheck();
  }

  ngOnChanges() {
    if (this.component) {
      this.component.instance.config = this.config;
      this.component.instance.group = this.getInstanceGroup();
    }
  }

  ngOnInit() {
    if (!this.config.type) {
      // Not a component that needs to be rendered
      return;
    }
    const type = SUPPORTED_TYPES[this.config.type];
    if (!type) {
      const supportedTypes = Object.keys(SUPPORTED_TYPES).join(', ');
      throw new Error(`Unsupported type (${this.config.type}). Supported types: ${supportedTypes}.`);
    }
    const factory = this.resolver.resolveComponentFactory<IFormField>(type);
    this.component = this.container.createComponent(factory);
    this.component.instance.config = this.config;
    this.component.instance.group = this.getInstanceGroup();
  }

  private getInstanceGroup(): FormGroup {
    if (!this.config.fullyQualifiedName.includes('.')) {
      return this.group;
    }
    const lastDotIndex = this.config.fullyQualifiedName.lastIndexOf('.');
    const name = this.config.fullyQualifiedName.substring(0, lastDotIndex);
    return this.group.get(name) as FormGroup;
  }
}
