import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';

import { IPricing } from './pricing.interface';
import { PricingModel } from './pricing-model.type';

export interface PricingService {
  get pricingModel$(): Observable<PricingModel>;
  get pricingModel(): PricingModel;
  changePricing(value: PricingModel): void;
  getCurrencyPrefix(currency: string): string;
  format(price: number, currency: string, decimalCount?: number, removeZeroDecimals?: boolean): string;
  formatPricingModel(pricingModel: PricingModel): string | null;
  transformPricing(
    pricing: IPricing,
    includePerBag?: boolean,
    includeDailyPrice?: boolean,
    includeHourlyPrice?: boolean,
    includeStartupFee?: boolean,
    includeFrom?: boolean,
    html?: boolean,
  ): Observable<string | null>;
}

export const PRICING_SERVICE = new InjectionToken<PricingService>('SharedPricingService');
