import { BookableStorageLocation, Booking, GoogleCalendarEvent, IHoliday } from '@luggagehero/shared/interfaces';
import moment from 'moment-timezone';

const SERIALIZED_DATE_TIME_FORMAT = 'YYYYMMDDHHmm';
const SERIALIZED_DATE_FORMAT = 'YYYYMMDD';
const EXCLUDE_HOLIDAYS_PATTERN = [
  'daylight',
  'sommertid',
  'vintertid',
  'tax day',
  "st. george's day",
  "mother's day",
  "father's day",
].join('|');

const AM_PM_PATTERN = /am|pm/i;

export type TimeFormatDetectionVersion = 1 | 2;

/** @deprecated Use `SharedUtilDate` instead */
export class DateUtil {
  public static TimeFormatDetectionVersion: TimeFormatDetectionVersion = 2;

  static get is12HourMode(): boolean {
    // TODO: Choose the best implementation
    switch (this.TimeFormatDetectionVersion) {
      case 1:
        return DateUtil._is12HourMode1;

      case 2:
        return DateUtil._is12HourMode2;

      default:
        return DateUtil._is12HourMode1;
    }
  }

  private static get _is12HourMode1(): boolean {
    // Create dummy locale time string and look for 'AM' or 'PM'
    const localeTime = new Date().toLocaleTimeString();
    const is12HourFormat = AM_PM_PATTERN.test(localeTime);

    return is12HourFormat;
  }

  private static get _is12HourMode2(): boolean {
    // Create a date object for a specific time
    const date = new Date(2024, 0, 1, 13, 0); // January 1, 2024, 1:00 PM

    // Format the time using the user's locale
    const formatter = new Intl.DateTimeFormat(undefined, { hour: 'numeric', minute: 'numeric' });

    // Get the formatted time string
    const formattedTime = formatter.format(date);

    // Check if the formatted time contains 'AM' or 'PM' using a regex
    const is12HourFormat = AM_PM_PATTERN.test(formattedTime);

    return is12HourFormat;
  }

  static get is24HourMode(): boolean {
    return !DateUtil.is12HourMode;
  }

  static getStartOfToday(): Date {
    return moment().startOf('day').toDate();
  }

  static setTime(date: Date, h = 0, m = 0, s = 0): Date {
    return moment(date).hour(h).minute(m).second(s).toDate();
  }

  static getEndOfOneYearFromToday(): Date {
    return moment().add(365, 'days').endOf('day').toDate();
  }

  static getValidTo(from: Date, to: Date, isMobile = false): Date {
    // TODO: Check that date is not in the past
    if (isMobile) {
      // When in mobile always just set to one hour ahead of from
      return DateUtil.addMinutes(from, 60);
    }
    if (to <= from) {
      return DateUtil.addMinutes(new Date(from), 60);
    }
    return to;
  }

  static getValidFrom(from: Date, to: Date): Date {
    // TODO: Check that date is not in the past
    if (from >= to) {
      return DateUtil.subtractMinutes(new Date(to), 60);
    }
    return from;
  }

  static isAtLeastOneDayLater(from: Date, to: Date): boolean {
    return (
      to > from &&
      (to.getFullYear() > from.getFullYear() || to.getMonth() > from.getMonth() || to.getDate() > from.getDate())
    );
  }

  static isToday(date: Date): boolean {
    return DateUtil.isSameDate(date, new Date());
  }

  static isSameDate(date1: Date, date2: Date): boolean {
    if (date1 === date2) {
      return true;
    }
    if (!date1 || !date2) {
      return false;
    }
    return (
      date1.getDate() === date2.getDate() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getFullYear() === date2.getFullYear()
    );
  }

  static isSameDateAsHoliday(date1: Date, holiday: GoogleCalendarEvent | IHoliday): boolean {
    const date = 'start' in holiday ? holiday.start.date : holiday.instances[0];
    return moment(date1).format('YYYY-MM-DD') === date;
  }

  static matchHoliday(date: Date, holidays: GoogleCalendarEvent[] | IHoliday[]): GoogleCalendarEvent | IHoliday {
    if (holidays) {
      for (let i = 0; i < holidays.length; i++) {
        const holiday = holidays[i];
        if (DateUtil.isSameDateAsHoliday(date, holiday) && DateUtil.isValidHoliday(holiday)) {
          return holiday;
        }
      }
    }
    return null;
  }

  static isValidHoliday(holiday: GoogleCalendarEvent | IHoliday): boolean {
    if ('summary' in holiday) {
      return !holiday.summary.toLowerCase().match(EXCLUDE_HOLIDAYS_PATTERN);
    }
    // Holidays returned from our own API are always valid
    return true;
  }

  static isPastEvent(event: GoogleCalendarEvent): boolean {
    const todayNum = Number(moment().format('YYYYMMDD'));
    const eventNum = Number(DateUtil.formatEventDate(event));
    return eventNum < todayNum;
  }

  static formatEventDate(event: GoogleCalendarEvent): string {
    return event.start.date.replace(new RegExp('-', 'g'), '');
  }

  static formatTime(hour: string, minute: string, shorten: boolean): string {
    hour = DateUtil.formatTimeComponent(hour, true);

    if (hour === '24') {
      // Moment.js expects 00 for midnight
      hour = '00';
    }

    const format = DateUtil.is12HourMode ? (shorten ? 'h:mma' : 'hh:mma') : 'HH:mm';
    const output = moment(`${hour}:${minute}`, 'HH:mm').format(format);

    // if (output === '00:00' && DateUtil.is24HourMode) {
    //   // Use 24 instead of 00 for midnight
    //   output = '24:00';
    // }

    return shorten ? output.replace(':00', '') : output;
  }

  static formatTimeComponent(value: number, isHour: boolean): string;
  static formatTimeComponent(value: string, isHour: boolean): string;
  static formatTimeComponent(timeAsNumOrStr: number | string, isHour: boolean): string {
    const value = typeof timeAsNumOrStr === 'string' ? parseInt(timeAsNumOrStr, 10) : timeAsNumOrStr;
    let output = typeof timeAsNumOrStr === 'string' ? timeAsNumOrStr : timeAsNumOrStr.toString();

    //
    // The hour component is the number of hours since midnight. If a location is open past midnight, the number will
    // be greater than 24, so in that case we need to subtract 24 to get the correct time of day for display.
    //
    if (isHour && value > 24) {
      output = DateUtil.formatTimeComponent(value - 24, true);
    } else if (value < 10) {
      // Add leading zero for single digit numbers
      output = `0${value}`;
    }

    return output;
  }

  static isSameTime(date1: Date, date2: Date): boolean {
    return date1.getMinutes() === date2.getMinutes() && date1.getHours() === date2.getHours();
  }

  static getTimeIndexOf(time: Date, times: Date[]): number {
    for (let i = 0; i < times.length; i++) {
      if (DateUtil.isSameTime(time, times[i])) {
        return i;
      }
    }
    return -1;
  }

  static getDateIndexOf(date: Date, dates: Date[]): number {
    for (let i = 0; i < dates.length; i++) {
      if (DateUtil.isSameDate(date, dates[i])) {
        return i;
      }
    }
    return -1;
  }

  static roundTimeHalfHour(time: Date): Date {
    const timeToReturn = new Date(time);
    timeToReturn.setMilliseconds(Math.round(time.getMilliseconds() / 1000) * 1000);
    timeToReturn.setSeconds(Math.round(timeToReturn.getSeconds() / 60) * 60);
    timeToReturn.setMinutes(Math.round(timeToReturn.getMinutes() / 30) * 30);
    return timeToReturn;
  }

  static hoursBetween(fromDateTime: Date | string, toDateTime: Date | string, gracePeriod = 0): number {
    if (typeof fromDateTime === 'string') {
      fromDateTime = moment(fromDateTime).toDate();
    }

    if (typeof toDateTime === 'string') {
      toDateTime = moment(toDateTime).toDate();
    }

    // Get 1 hour in milliseconds
    const oneHour = 1000 * 60 * 60;

    // Convert both dates to milliseconds
    const fromMilliseconds = fromDateTime.getTime();
    const toMilliseconds = toDateTime.getTime() - gracePeriod;

    // Calculate the difference in milliseconds
    const diffMilliseconds = toMilliseconds - fromMilliseconds;

    // Convert to hours and return
    const hoursPassed = Math.ceil(diffMilliseconds / oneHour);
    return hoursPassed;
  }

  static daysBetween(fromDateTime: Date | string, toDateTime: Date | string): number {
    if (typeof fromDateTime === 'string') {
      fromDateTime = moment(fromDateTime).toDate();
    }

    if (typeof toDateTime === 'string') {
      toDateTime = moment(toDateTime).toDate();
    }

    // Get 1 day in milliseconds
    const oneDay = 1000 * 60 * 60 * 24;

    // Convert both dates to milliseconds
    const fromMilliseconds = fromDateTime.getTime();
    const toMilliseconds = toDateTime.getTime();

    // Calculate the difference in milliseconds
    const diffMilliseconds = toMilliseconds - fromMilliseconds;

    // Convert to days and return
    const diff = diffMilliseconds / oneDay;
    const daysPassed = diff < 1 ? Math.floor(diff) : Math.ceil(diff);
    return daysPassed;
  }

  static minutesBetween(fromDateTime: Date, toDateTime: Date) {
    // Get 1 minute in milliseconds
    const oneMinute = 1000 * 60;

    // Convert both dates to milliseconds
    const fromMilliseconds = fromDateTime.getTime();
    const toMilliseconds = toDateTime.getTime();

    // Calculate the difference in milliseconds
    const diffMilliseconds = toMilliseconds - fromMilliseconds;

    // Convert to minutes and return
    const diff = diffMilliseconds / oneMinute;
    const minutesPassed = Math.floor(diff);
    return minutesPassed;
  }

  static minutesSinceCheckIn(booking: Booking): number {
    return moment().tz(booking.timezone).diff(moment(booking.period.checkIn), 'minutes');
  }

  static isBookingForToday(booking: Booking): boolean {
    // TODO: Check that this works correctly with timezones
    const nowLocal = moment.tz(booking.timezone);
    const checkInLocal =
      booking.period.checkIn.getTime() === booking.period.from.getTime()
        ? moment.tz(booking.period.checkIn, booking.timezone)
        : booking.period.checkIn;

    return nowLocal.isSame(checkInLocal, 'day');
  }

  static addDays(date: Date, days: number, endOfMonth = false): Date {
    const m = moment(date).add(days, 'days');
    if (endOfMonth) {
      m.endOf('month');
    }
    return m.toDate();
  }

  static addHours(date: Date, hours: number): Date {
    return moment(date).add(hours, 'hours').toDate();
  }

  static addMinutes(date: Date, minutes: number): Date {
    return moment(date).add(minutes, 'minutes').toDate();
  }

  static subtractMinutes(date: Date, minutes: number): Date {
    return moment(date).subtract(minutes, 'minutes').toDate();
  }

  static serializeDate(date: Date, includeTime = true): string {
    const format = includeTime ? SERIALIZED_DATE_TIME_FORMAT : SERIALIZED_DATE_FORMAT;
    return moment(date).format(format);
  }

  static serializeDateUtc(date: Date, includeTime = true): string {
    const format = includeTime ? SERIALIZED_DATE_TIME_FORMAT : SERIALIZED_DATE_FORMAT;
    return moment(date).utc().format(format);
  }

  static deserializeDate(date: string, includeTime = true, allowPast = true): Date {
    const format = includeTime ? SERIALIZED_DATE_TIME_FORMAT : SERIALIZED_DATE_FORMAT;
    const m = moment(date, format);

    const now = new Date();
    if (!allowPast && m.isBefore(now)) {
      return now;
    }
    return m.toDate();
  }

  static deserializeDateTz(date: string, timezone: string, includeTime = true, allowPast = true): Date {
    const format = includeTime ? SERIALIZED_DATE_TIME_FORMAT : SERIALIZED_DATE_FORMAT;
    const m = moment.tz(date, format, timezone);

    const now = new Date();
    if (!allowPast && m.isBefore(now)) {
      return now;
    }
    return m.toDate();
  }

  static dateToNumber(value: Date): number {
    return Number(moment(value).format('YYYYMMDD'));
  }

  static dateToTimeOfDay(date: Date): number {
    return date.getHours() * 60 + date.getMinutes();
  }

  static getStorageLocationTimeOfDay(shop: BookableStorageLocation): number {
    const localNow = moment.tz(shop.timezone);
    const localMidnight = localNow.clone().startOf('day');

    return localNow.diff(localMidnight, 'minutes');
  }

  static getStorageLocationNow(shop: BookableStorageLocation): Date {
    return moment.tz(shop.timezone).toDate();
  }

  static parseDateParam(date: string): Date {
    if (!date) {
      return null;
    }
    return DateUtil.deserializeDate(date);
  }

  static isValidPeriod(from: Date, to: Date): boolean {
    return from && to && from < to && from > new Date();
  }
}
