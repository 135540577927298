import { Component, inject, Input } from '@angular/core';
import { Config } from '@luggagehero/shared/environment';
import {
  BookableStorageLocation,
  TIME_INTERVAL_TRANSFORMER_SERVICE,
  TimeIntervalTransformerService,
} from '@luggagehero/shared/interfaces';
import { SharedShopsService } from '@luggagehero/shared/services/shops';
import { SharedStorageCriteriaService } from '@luggagehero/shared/services/storage-criteria';
import { map, Observable, of } from 'rxjs';

import { BaseComponent } from '../../../core';
import { DateUtil } from '../../../utils/date.util';

@Component({ template: '' })
export abstract class StorageLocationAvailabilityBaseComponent extends BaseComponent {
  public get isDebugging(): boolean {
    return !Config.isProduction && Config.environment.DEBUG_UI;
  }
  private storageLocationService = inject(SharedShopsService);
  private timeIntervalService = inject<TimeIntervalTransformerService>(TIME_INTERVAL_TRANSFORMER_SERVICE);
  private criteria = inject(SharedStorageCriteriaService);
  private _storageLocation: BookableStorageLocation;
  private _isFullyBooked: boolean;
  private _isOpenOnArrival: boolean;
  private _openingHours: string;
  private _closesAt: string;
  private _opensAt: string;
  private _nextOpenDay: Date;

  public get localTime(): Observable<string> {
    const now = this.storageLocation
      ? DateUtil.getStorageLocationTimeOfDay(this.storageLocation)
      : DateUtil.dateToNumber(new Date());
    return this.timeIntervalService.transform([{ from: now }], true, true, 'from');
  }

  public get storageLocation(): BookableStorageLocation {
    return this._storageLocation;
  }
  @Input() public set storageLocation(value: BookableStorageLocation) {
    this._storageLocation = value;
    this._markForCheck();
  }

  public get isFullyBooked(): boolean {
    if (!this.storageLocation) {
      return this._isFullyBooked;
    }
    // return this.isOpenOnArrival && !this.storageLocation.available;
    return this.isOpen && !this.storageLocation.available;
  }
  @Input() public set isFullyBooked(value: boolean) {
    this._isFullyBooked = value;
    this._markForCheck();
  }

  public get isOpenOnArrival(): boolean {
    if (!this.storageLocation) {
      return this._isOpenOnArrival;
    }
    return this.storageLocationService.isOpen(this.arrivingAt, this.storageLocation);
  }
  @Input() public set isOpenOnArrival(value: boolean) {
    this._isOpenOnArrival = value;
    this._markForCheck();
  }

  public get isOpenAllDay(): boolean {
    if (!this.storageLocation) {
      return false;
    }
    return this.storageLocationService.isOpenAllDay(this.arrivingAt, this.storageLocation);
  }

  public get isOpen(): boolean {
    return this.storageLocationService.isOpen(this.arrivingAt, this.storageLocation);
  }

  public get isClosed(): boolean {
    return !this.isOpen;
  }

  public get openingHours(): Observable<string> {
    if (!this.storageLocation) {
      return of(this._openingHours);
    }
    const date = this.storageLocationService.getNextOpenDay(this.arrivingAt, this.storageLocation);
    const hours = this.storageLocationService.getOpeningHoursForDate(date, this.storageLocation);
    // const hours = this.storageLocationService.getOpeningHoursFromDate(this.arrivingAt, this.storageLocation);
    return this.timeIntervalService.transform(hours, true);
  }
  @Input() public set openingHours(value: string) {
    this._openingHours = value;
    this._markForCheck();
  }

  public get nextOpenDay(): Date {
    if (!this.storageLocation) {
      return this._nextOpenDay;
    }
    const nextOpenDay = this.storageLocationService.getNextOpenDay(this.arrivingAt, this.storageLocation);
    return nextOpenDay;
  }
  @Input() public set nextOpenDay(value: Date) {
    this._nextOpenDay = value;
    this._markForCheck();
  }

  public get opensAt(): Observable<string> {
    if (!this.storageLocation) {
      return of(this._opensAt);
    }
    return this.storageLocationService.getOpensAt(this.arrivingAt, this.storageLocation);
  }
  @Input() public set opensAt(value: string) {
    this._opensAt = value;
    this._markForCheck();
  }

  public get closesAt(): Observable<string> {
    if (!this.storageLocation) {
      return of(this._closesAt);
    }
    return this.storageLocationService.getClosesAt(this.arrivingAt, this.storageLocation);
  }
  @Input() public set closesAt(value: string) {
    this._closesAt = value;
    this._markForCheck();
  }

  public get arrivingAt(): Date {
    const arrivalDate = this.criteria.currentOrDefault.period.from;
    return DateUtil.isToday(arrivalDate) ? new Date() : arrivalDate;
  }

  public get displayHours(): Observable<string> {
    if (!this.isOpen && !this.isOpeningSoon) {
      // The storage location is closed, show when it opens back up
      return this.opensAt;
    }

    if (this.isOpen && this.isArrivingToday) {
      // The time of arrival is today and the storage location is open, show when it closes
      return this.closesAt;
    }

    return this.openingHours.pipe(map((hours) => hours.toLowerCase()));
  }

  public get isArrivingToday(): boolean {
    return DateUtil.isToday(this.arrivingAt);
  }

  public get isClosingSoon(): boolean {
    if (!this.storageLocation) {
      return false;
    }
    if (!DateUtil.isToday(this.arrivingAt)) {
      return false;
    }

    if (this.isOpenAllDay) {
      return false;
    }

    return this.storageLocationService.isClosingSoon(this.storageLocation);
  }

  public get isOpeningSoon(): boolean {
    if (!this.storageLocation) {
      return false;
    }
    if (!DateUtil.isToday(this.arrivingAt)) {
      return false;
    }
    return this.storageLocationService.isOpeningSoon(this.storageLocation);
  }

  public get openClosedText(): string {
    if (this.isOpen && this.isClosingSoon) {
      return 'CLOSING_SOON';
    }
    if (this.isOpen) {
      return 'OPEN';
    }
    if (this.isOpeningSoon) {
      return 'OPENING_SOON';
    }
    return 'CLOSED';
  }
}
