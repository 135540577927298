<div class="step-headline">{{ 'WELCOME_TO_STORE' | lhTranslate: variant }} {{ storageLocation.name }}</div>

<div class="no-security-seal" *ngIf="!isSecuritySealStorageLocation">
  <p>{{ 'DROPOFF_WELCOME_STEP_INTRO_MESSAGE' | lhTranslate: variant }}</p>
  <div class="welcome-image-container" *ngIf="!showGuestOption && !isGuest">
    <img class="welcome-image" src="/assets/lugggagehero_piktogrammer_insurance3.webp" />
  </div>
</div>

<div class="guest-container" *ngIf="showGuestOption">
  <div class="guest-question">{{ guestOptionText | lhTranslate: variant }}</div>

  <div class="guest-options">
    <button
      class="btn btn-md btn-default"
      [class.active]="isGuestOptionSelected && !isGuest"
      (click)="setIsGuest(false)"
    >
      {{ 'NO' | lhTranslate: variant }}
    </button>

    <button class="btn btn-md btn-default" [class.active]="isGuestOptionSelected && isGuest" (click)="setIsGuest(true)">
      {{ 'YES' | lhTranslate: variant }}
    </button>
  </div>
</div>

<div class="guest-container" *ngIf="isGuest">
  <div class="guest-question">{{ 'GUEST_MOVEMENT_OPTION' | lhTranslate: variant }}</div>

  <div class="guest-options">
    <button
      class="btn btn-md btn-default"
      [class.active]="isGuestOptionSelected && isArrivingGuest"
      (click)="setIsArrivingGuest()"
    >
      {{ 'ARRIVING' | lhTranslate: variant }}
    </button>

    <button
      class="btn btn-md btn-default"
      [class.active]="isGuestOptionSelected && isDepartingGuest"
      (click)="setIsDepartingGuest()"
    >
      {{ 'DEPARTING' | lhTranslate: variant }}
    </button>
  </div>
</div>

<div class="security-seal" *ngIf="isSecuritySealInfoVisible">
  <p>{{ 'ATTACH_SECURITY_SEAL_BEFORE_PROCEEDING' | lhTranslate: variant }}</p>

  <div class="security-seal-image-container">
    <img class="security-seal-image" [class.compact]="showGuestOption" src="/assets/security-tag-zipper.jpg" />
  </div>

  <label
    for="doneCheckbox"
    class="form-control text-center d-flex align-items-center justify-content-center"
    *ngIf="isSecuritySealConfirmationRequired"
  >
    <input type="checkbox" name="doneCheckbox" id="doneCheckbox" [(ngModel)]="isSecuritySealConfirmed" />
    <span class="ms-2">{{ 'OK_I_HAVE_SEALS' | lhTranslate: variant }}</span>
  </label>
</div>
