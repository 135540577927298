import { ChangeDetectionStrategy, Component } from '@angular/core';
import { SimpleShopItemBaseComponent } from '@luggagehero/features/ui';

@Component({
  selector: 'lh-simple-shop-item',
  templateUrl: './simple-shop-item.component.html',
  styleUrls: ['./simple-shop-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SimpleShopItemComponent extends SimpleShopItemBaseComponent {}
