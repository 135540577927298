import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { DistanceUnit } from '@luggagehero/shared/interfaces';

import { TravelerShopsUiShopFilterComponent } from '../shop-filters/traveler-shops-ui-shop-filters.component';

@Component({
  selector: 'lh-traveler-shops-ui-main-toolbar',
  standalone: true,
  imports: [CommonModule, TravelerShopsUiShopFilterComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['traveler-shops-ui-main-toolbar.component.scss'],
  templateUrl: 'traveler-shops-ui-main-toolbar.component.html',
})
export class TravelerShopsUiMainToolbarComponent {
  private _showMap = false;

  @Input()
  set showMap(value: boolean) {
    this._showMap = value;
    this.showMapChange.emit(value);
  }

  get showMap(): boolean {
    return this._showMap;
  }

  @Input()
  public distanceUnit: DistanceUnit = 'imperial';
  @Input() isHotelFilterVisible = false;
  @Input() isOpen24HoursFilterVisible = false;
  @Input() isWifiAvailableFilterVisible = false;
  @Input() isMultiDayStorageFilterVisible = false;
  @Input() hotelsFilterEnabled = false;
  @Input() openAllDayFilterEnabled = false;
  @Input() wifiAvailableFilterEnabled = false;
  @Input() multiDayStorageFilterEnabled = false;
  @Output() showMapChange = new EventEmitter<boolean>();
  @Output() distanceUnitChangeRequest = new EventEmitter();
  @Output() toggleOpenAllDayFilter = new EventEmitter<void>();
  @Output() toggleMultiDayStorageFilter = new EventEmitter<void>();
  @Output() toggleWifiAvailableFilter = new EventEmitter<void>();
  @Output() toggleHotelsFilter = new EventEmitter<void>();
}
