import { ChangeDetectorRef, Component, Type } from '@angular/core';
import { LegacyOrder } from '@luggagehero/shared/interfaces';
import { SharedBookingService } from '@luggagehero/shared/services/bookings';
import { SharedNotificationService } from '@luggagehero/shared/services/notification';
import { SharedOrderService } from '@luggagehero/shared/services/orders';
import { SharedShopsService } from '@luggagehero/shared/services/shops';
import { SharedStorageService } from '@luggagehero/shared/services/storage';

import { BookingActionBaseComponent } from './booking-action.base-component';
import { BookingActionInfo } from './booking-action-info';
import { ModifyBookingActionBaseComponent } from './modify-booking-action.base-component';

interface CancelReason {
  key: string;
  value: string;
  hidden: boolean;
}

@Component({ template: '' })
export abstract class CancelBookingActionBaseComponent extends BookingActionBaseComponent {
  public order: LegacyOrder;

  public constructor(
    bookingService: SharedBookingService,
    shopsService: SharedShopsService,
    storageService: SharedStorageService,
    cd: ChangeDetectorRef,
    private orderService: SharedOrderService,
    private notify: SharedNotificationService,
  ) {
    super(bookingService, shopsService, storageService, cd);
  }

  errorOccurred = false;
  errorMessage = '';
  cancelReason = '';
  cancelReasonCode = '';
  selectedReason = '';
  reasonsExpanded = false;

  cancelReasons: CancelReason[] = [
    {
      key: 'FOUND_OTHER_OPTION',
      value: this._translate('CANCEL_REASON_FOUND_OTHER_OPTION_SIMPLE'),
      hidden: false,
    },
    {
      key: 'UNSUITABLE_OPENING_HOURS',
      value: this._translate('CANCEL_REASON_UNSUITABLE_OPENING_HOURS'),
      hidden: false,
    },
    { key: 'CHANGE_OF_PLANS', value: this._translate('CANCEL_REASON_CHANGE_OF_PLANS'), hidden: true },
    { key: 'DUPLICATE_BOOKING', value: this._translate('CANCEL_REASON_DUPLICATE_BOOKING'), hidden: true },
    {
      key: 'UNABLE_TO_FIND_LOCATION',
      value: this._translate('CANCEL_REASON_UNABLE_TO_FIND_LOCATION'),
      hidden: true,
    },
    {
      key: 'DONT_UNDERSTAND_HOW_THIS_WORKS',
      value: this._translate('CANCEL_REASON_DONT_UNDERSTAND_HOW_THIS_WORKS'),
      hidden: true,
    },
  ];

  get hasFreeCancellation(): boolean {
    return this.order?.total === 0;
  }

  get isCancelAllowed(): boolean {
    return this.cancelReasonCode && !this.isLoading;
  }

  public get cancelationFee(): number {
    return this.order?.total || 0;
  }

  public get isLegacyBooking(): boolean {
    return this.booking?.schemaVersion < 3;
  }

  get modifyBookingActionInfo(): BookingActionInfo {
    return new BookingActionInfo('ModifyBookingFromCancelBooking')
      .withCallToAction(this._translate('MODIFY_BOOKING_CTA'))
      .withAction({ componentToShow: this.modifyBookingActionComponentType })
      .withTitle(this._translate('MODIFY_BOOKING_CTA'));
  }

  protected abstract get modifyBookingActionComponentType(): Type<ModifyBookingActionBaseComponent>;

  public async onBaseInit(): Promise<void> {
    if (this.isLegacyBooking) {
      return;
    }

    this.order = await this.orderService.requestCancellation(this.booking._id);
    this.cd.markForCheck();
  }

  async cancelBooking() {
    this.isLoading = true;

    try {
      const res = await (this.isLegacyBooking
        ? this.bookingService.cancelBooking(this.booking._id, this.cancelReason, this.cancelReasonCode)
        : this.bookingService.confirmCancellation(
            this.booking._id,
            this.order._id,
            this.cancelReason,
            this.cancelReasonCode,
          ));

      if (res.status !== 'CANCELLED') {
        const cancelError = this._translate('CANCEL_FAILED');
        this.notify.error(cancelError);
      }

      this.updateBooking(res);
      this.close();
    } catch (err) {
      const errorMessage = typeof err === 'string' ? err : (err as { message?: string })?.message || 'Unknown error';
      this.notify.error(errorMessage);
    }

    this.isLoading = false;
  }

  selectReason(selectedReasonCode: string) {
    this.cancelReasonCode = selectedReasonCode;
    this.selectedReason = this.cancelReasons.find((r) => r.key === selectedReasonCode)?.value;
    this.cd.markForCheck();
  }

  public modifyBooking() {
    // Close this action nicely (otherwise it will be destroyed by the new action without animation etc.)
    this.close();

    // Launch modify booking action instead
    this.actionRequest.emit(this.modifyBookingActionInfo);
  }
}
