import { APP_BASE_HREF, CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule, Optional, Provider, SkipSelf } from '@angular/core';

import { throwIfAlreadyLoaded } from '../utils/angular';
import { CORE_PROVIDERS } from './services/index';

export const BASE_PROVIDERS: Provider[] = [
  ...CORE_PROVIDERS,
  {
    provide: APP_BASE_HREF,
    useValue: '/',
  },
];

@NgModule({
  imports: [CommonModule],
})
export class CoreModule {
  // configuredProviders: *required to configure WindowService and ConsoleService per platform
  static forRoot(configuredProviders: Array<Provider>): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [...BASE_PROVIDERS, ...configuredProviders],
    };
  }
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }
}
