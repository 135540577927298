import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, Input, OnDestroy, OnInit } from '@angular/core';
import { Booking } from '@luggagehero/shared/interfaces';
import { SharedUiTimerComponent } from '@luggagehero/shared/ui';
import { TranslatePipe } from '@luggagehero/shared/ui-pipes';
import { SharedBookingsTimerService } from '@luggagehero/shared-data-access-bookings-timer';
import { Subscription } from 'rxjs';

@Component({
  selector: 'lh-shared-feature-bookings-timer',
  standalone: true,
  imports: [CommonModule, SharedUiTimerComponent, TranslatePipe],
  templateUrl: './shared-feature-bookings-timer.component.html',
  styleUrl: './shared-feature-bookings-timer.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SharedFeatureBookingsTimerComponent implements OnInit, OnDestroy {
  public days: string;
  public hours: string;
  public minutes: string;
  public seconds: string;

  private _isInitialized = false;
  private _isCheckInSession = false;
  private _booking: Booking | undefined;

  private tickSubscription: Subscription | undefined;

  private bookingsTimerService = inject(SharedBookingsTimerService);
  private cd = inject(ChangeDetectorRef);

  @Input() public set booking(value: Booking) {
    this._booking = value;

    if (this.isInitialized) {
      this.bookingsTimerService.set(this.booking, this.isCheckInSession);
    }
  }
  public get booking(): Booking {
    return this._booking;
  }

  @Input() public set isCheckInSession(value: boolean) {
    this._isCheckInSession = value;

    if (this.isInitialized) {
      this.bookingsTimerService.set(this.booking, this.isCheckInSession);
    }
  }
  public get isCheckInSession(): boolean {
    return this._isCheckInSession;
  }

  public get isInitialized(): boolean {
    return this._isInitialized;
  }
  private set isInitialized(value: boolean) {
    this._isInitialized = value;
  }

  public ngOnInit(): void {
    this.bookingsTimerService.set(this.booking, this.isCheckInSession);

    this.tickSubscription = this.bookingsTimerService.tick.subscribe((value) => {
      this.days = value.days;
      this.hours = value.hours;
      this.minutes = value.minutes;
      this.seconds = value.seconds;
      this.cd.markForCheck();
    });

    this.isInitialized = true;
  }

  public ngOnDestroy(): void {
    this.tickSubscription?.unsubscribe();
  }
}
