import { Injectable, NgZone } from '@angular/core';
import { GoogleAuthResponse, GoogleSignin } from '@luggagehero/shared/interfaces';
import { SharedLoggingService } from '@luggagehero/shared/services/logging';

import { ScriptService } from './script.service';

declare const gapi: {
  load: (name: string, callback: () => void) => void;
  auth2: {
    init: (config: { client_id: string }) => {
      grantOfflineAccess: () => Promise<GoogleAuthResponse>;
    };
  };
};
declare const auth2: {
  grantOfflineAccess: () => Promise<GoogleAuthResponse>;
};

const loadScriptOnDemand = false;

@Injectable()
export class GoogleSigninService implements GoogleSignin {
  private auth2: {
    grantOfflineAccess: () => Promise<GoogleAuthResponse>;
  };

  constructor(
    protected script: ScriptService,
    protected ngZone: NgZone,
    protected log: SharedLoggingService,
  ) {}

  async signIn(): Promise<GoogleAuthResponse> {
    if (loadScriptOnDemand) {
      await this.loadAuth2();
    }
    const res: GoogleAuthResponse = await auth2.grantOfflineAccess();
    void this.log.info(`Google auth response`, res);

    return res;
  }

  private async loadAuth2(): Promise<void> {
    if (this.auth2) {
      return;
    }

    await this.script.load('google').toPromise();

    return new Promise((resolve) => {
      gapi.load('auth2', () => {
        this.auth2 = gapi.auth2.init({
          client_id: '595935798811-bkojh18ok4unniqh1okq8p0q1fbbqp4m.apps.googleusercontent.com',
          // Scopes to request in addition to 'profile' and 'email'
          // scope: 'additional_scope'
        });
        resolve();
      });
    });
  }
}
