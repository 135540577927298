import { Type } from '@angular/core';
import { Booking } from '@luggagehero/shared/interfaces';
import { SharedMomentService } from '@luggagehero/shared/services/moment';

import { BookingAction, BookingActionInfo } from '../booking-actions/booking-action-info';
import { GoToShopActionBaseComponent } from '../booking-actions/go-to-shop-action.base-component';
import { SelectPaymentMethodActionBaseComponent } from '../booking-actions/select-payment-method-action.base-component';
import { BookingTimelineBaseComponent } from '../booking-timeline.base-component';
import { BookingStep } from './booking-step';

export abstract class BookingConfirmedBaseStep extends BookingStep {
  private skipGetDirectionsAction: BookingAction = {
    functionToCall: () => {
      this.booking.state.getDirectionsDone = true;
      return this.updateBookingState();
    },
  };

  private get selectPaymentMethodActionInfo(): BookingActionInfo {
    return new BookingActionInfo('SelectPaymentMethodFromBookingConfirmedStep')
      .withCallToAction(this.translate('GET_DIRECTIONS'))
      .withAction({ componentToShow: this.selectPaymentMethodActionComponentType })
      .withSkipAction(this.skipGetDirectionsAction)
      .withSkipText(this.translate('DONE'))
      .withTitle(this.translate('PAYMENT_METHOD_MISSING'))
      .withSubtitle(this.translate('DIRECTIONS_AFTER_PAYMENT_METHOD'))
      .withBookingChangeHandler((b) => this.onPaymentMethodChange(b));
  }

  private get getDirectionsActionInfo(): BookingActionInfo {
    return new BookingActionInfo('GetDirectionsFromBookingConfirmedStep')
      .withCallToAction(this.translate('GET_DIRECTIONS'))
      .withAction({ componentToShow: this.goToShopActionComponentType })
      .withSkipAction(this.skipGetDirectionsAction)
      .withSkipText(this.translate('DONE'))
      .withTitle(this.translate('FIND_SHOP'));
  }

  constructor(
    parent: BookingTimelineBaseComponent,
    protected momentService: SharedMomentService,
  ) {
    super(parent);
  }

  //
  // We only know the base action components here, so force the derived class
  // to provide the specific types
  //
  protected abstract get goToShopActionComponentType(): Type<GoToShopActionBaseComponent>;
  protected abstract get selectPaymentMethodActionComponentType(): Type<SelectPaymentMethodActionBaseComponent>;

  getIsVisible() {
    return true;
  }

  getTitle(): string {
    const bagCount = (this.booking.metadata?.lh_number_of_bags || this.booking.luggage.normal) as number;
    const date = this.momentService.formatDate(
      ['CHECKED_IN', 'CHECKED_OUT', 'PAID'].includes(this.booking.status)
        ? this.booking.period.checkIn
        : this.booking.period.from,
      'calendar',
    );
    const bookingFor = this.translate('BOOKING_FOR');
    const bags = this.translate(bagCount === 1 ? 'BAG' : 'BAGS').toLowerCase();

    if (!this.booking.paymentMethodId && this.parent.config.paymentInfoRequiredBeforeDirections) {
      const inCity = this.translate('IN');
      const city = this.booking.address.city;
      return `${bookingFor} ${bagCount} ${bags} ${date} ${inCity} ${city}`;
    }
    const at = this.translate('AT_SHOP');
    const shopName = this.booking.shopName;
    return `${bookingFor} ${bagCount} ${bags} ${date} ${at} ${shopName}`;
  }

  getSubtitle(): string {
    if (!this.booking.paymentMethodId && this.parent.config.paymentInfoRequiredBeforeDirections) {
      return this.translate('GO_TO_STORAGE_LOCATION');
    }
    const address = this.booking.address;
    let formattedAddress = address.street;
    if (address.city) {
      formattedAddress += `, ${address.city}`;
    }
    return formattedAddress;
  }

  getIsSubtitleHidden(): boolean {
    // TODO: Use something else to determine this
    if (this.parent.strategy.useInlineTimer) {
      return true;
    }
    return false;
  }

  getIsActive(): boolean {
    return false; // !this.booking.state.getDirectionsDone;
  }

  getIsCompleted(): boolean {
    return true;
  }

  getIsFailed(): boolean {
    return false;
  }

  getActionInfo(): BookingActionInfo {
    if (!this.booking.paymentMethodId && this.parent.config.paymentInfoRequiredBeforeDirections) {
      return this.selectPaymentMethodActionInfo;
    }
    return this.getDirectionsActionInfo;
  }

  getIsActionCollapsed(): boolean {
    return true; // this.booking.state.getDirectionsDone;
  }

  private onPaymentMethodChange(booking: Booking) {
    if (!booking.paymentMethodId) {
      return;
    }
    this.triggerAction(this.getDirectionsActionInfo);
  }
}
