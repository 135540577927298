import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ModalService, RouterExtensions } from '@luggagehero/core';
import { ShopDetailsBaseComponent } from '@luggagehero/features/shops';
import { SharedDistanceService } from '@luggagehero/shared/services/distance';
import { SharedDocumentService } from '@luggagehero/shared/services/document';
import { SharedErrorService } from '@luggagehero/shared/services/error';
import { SharedGoogleMapsService } from '@luggagehero/shared/services/google-maps';
import { SharedNotificationService } from '@luggagehero/shared/services/notification';
import { SharedPageTaggingService } from '@luggagehero/shared/services/page-tagging';
import { SharedParamsService } from '@luggagehero/shared/services/params';
import { SharedReviewsService } from '@luggagehero/shared/services/reviews';
import { SharedShopsService } from '@luggagehero/shared/services/shops';
import { SharedStorageCriteriaService } from '@luggagehero/shared/services/storage-criteria';
import { SharedThemeService } from '@luggagehero/shared/services/theme';
import { SharedWindowService } from '@luggagehero/shared/services/window';
import { HowItWorksComponent, PricingComponent, PromoCodeComponent } from '@luggagehero/web/features/ui';
import { TranslateService } from '@ngx-translate/core';

import { ConfirmBookingComponent } from '../confirm-booking/confirm-booking.component';

@Component({
  selector: 'lh-shop-details',
  templateUrl: './shop-details.component.html',
  styleUrls: ['./shop-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShopDetailsComponent extends ShopDetailsBaseComponent {
  constructor(
    private modalService: ModalService,
    themeService: SharedThemeService,
    documentService: SharedDocumentService,
    router: RouterExtensions,
    route: ActivatedRoute,
    shopsService: SharedShopsService,
    criteriaService: SharedStorageCriteriaService,
    reviewsService: SharedReviewsService,
    googleMapsService: SharedGoogleMapsService,
    distanceService: SharedDistanceService,
    translate: TranslateService,
    paramsService: SharedParamsService,
    tag: SharedPageTaggingService,
    windowService: SharedWindowService,
    notification: SharedNotificationService,
    cd: ChangeDetectorRef,
    error: SharedErrorService,
  ) {
    super(
      themeService,
      documentService,
      router,
      route,
      shopsService,
      criteriaService,
      reviewsService,
      googleMapsService,
      distanceService,
      translate,
      paramsService,
      tag,
      windowService,
      notification,
      cd,
      error,
    );
  }

  public async showHowItWorks(): Promise<void> {
    await this.modalService.show({ component: HowItWorksComponent });
  }

  public async showConfirmBooking(): Promise<void> {
    await this.modalService.show({ component: ConfirmBookingComponent });
  }

  public async showPriceInfo(): Promise<void> {
    await this.modalService.show({ component: PricingComponent, componentState: { shop: this.shop } });
  }

  async showDiscountOptions() {
    await this.modalService.show({ component: PromoCodeComponent });
  }
}
