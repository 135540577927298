<div class="email-mobile-toggle" *ngIf="isMobileSignInEnabled">
  <button
    id="selectMobileSignIn"
    class="btn btn-info"
    [class.active]="signInUsingMobile"
    (click)="signInUsingMobile = true"
  >
    {{ 'SIGN_IN_TOGGLE_SMS' | translate }}
  </button>
  <button
    id="selectEmailSignIn"
    class="btn btn-info"
    [class.active]="!signInUsingMobile"
    (click)="signInUsingMobile = false"
  >
    {{ 'SIGN_IN_TOGGLE_EMAIL' | translate }}
  </button>
</div>

<p *ngIf="message">{{ message }}</p>

<div [hidden]="signInUsingMobile">
  <!-- New user form -->
  <form *ngIf="isNewUser" class="user-form" [formGroup]="newUserForm" (submit)="submitForm()">
    <div class="form-group">
      <input
        id="email"
        type="email"
        autocomplete="email username"
        class="form-control"
        name="email"
        formControlName="email"
        [(ngModel)]="user.email"
        [placeholder]="'EMAIL_ADDRESS' | translate"
        (focus)="isEmailFormVisible = false"
        #emailInput
      />
      <div *ngIf="newUserForm.get('email').dirty && !newUserForm.get('email').valid" class="alert alert-danger">
        {{ 'INVALID_EMAIL' | translate }}
      </div>
    </div>

    <div [hidden]="!isEmailFormVisible">
      <div class="form-group">
        <input
          id="name"
          type="text"
          autocomplete="name"
          class="form-control"
          name="name"
          formControlName="name"
          [(ngModel)]="user.name"
          [placeholder]="(requireName ? 'NAME' : 'NAME_OPTIONAL') | translate"
          #nameInput
        />
        <div
          *ngIf="newUserForm.get('name').errors && newUserForm.get('name').errors.required"
          class="alert alert-danger"
        >
          {{ 'NAME_IS_REQUIRED' | translate }}
        </div>
        <div
          *ngIf="newUserForm.get('name').errors && newUserForm.get('name').errors.pattern"
          class="alert alert-danger"
        >
          {{ 'INVALID_NAME' | translate }}
        </div>
      </div>

      <div class="form-group password-form-group" [hidden]="!choosePassword">
        <input
          id="password"
          [type]="showPassword ? 'text' : 'password'"
          autocomplete="new-password"
          class="form-control"
          name="password"
          formControlName="password"
          [(ngModel)]="user.password"
          [placeholder]="'CHOOSE_PASSWORD' | translate"
        />
        <a id="toggleShowPassword" class="input-link" (click)="showPassword = !showPassword">
          <span *ngIf="!showPassword">{{ 'SHOW_PASSWORD' | translate }}</span>
          <span *ngIf="showPassword">{{ 'HIDE_PASSWORD' | translate }}</span>
        </a>
        <div
          *ngIf="existingUserForm.get('password').dirty && !existingUserForm.get('password').valid"
          class="alert alert-danger"
        >
          {{ 'PASSWORD_REQUIRED' | translate }}
        </div>
      </div>
    </div>

    <input type="submit" class="hidden" />
  </form>

  <!-- Existing user form -->
  <form *ngIf="isExistingUser" class="user-form" [formGroup]="existingUserForm" (submit)="submitForm()">
    <div class="form-group">
      <input
        id="email"
        type="email"
        autocomplete="email username"
        class="form-control"
        name="email"
        formControlName="email"
        [(ngModel)]="user.email"
        [placeholder]="'EMAIL_ADDRESS' | translate"
        (focus)="isEmailFormVisible = false"
        #emailInput
      />
      <div
        *ngIf="existingUserForm.get('email').dirty && !existingUserForm.get('email').valid"
        class="alert alert-danger"
      >
        {{ 'INVALID_EMAIL' | translate }}
      </div>
    </div>

    <div [hidden]="!isEmailFormVisible">
      <div class="form-group password-form-group">
        <input
          id="password"
          [type]="showPassword ? 'text' : 'password'"
          autocomplete="current-password"
          class="form-control"
          name="password"
          formControlName="password"
          [(ngModel)]="user.password"
          [placeholder]="'PASSWORD' | translate"
          #passwordInput
        />
        <a id="toggleShowPassword" class="input-link" (click)="showPassword = !showPassword">
          <span *ngIf="!showPassword">{{ 'SHOW_PASSWORD' | translate }}</span>
          <span *ngIf="showPassword">{{ 'HIDE_PASSWORD' | translate }}</span>
        </a>
        <div
          *ngIf="existingUserForm.get('password').dirty && !existingUserForm.get('password').valid"
          class="alert alert-danger"
        >
          {{ 'PASSWORD_REQUIRED' | translate }}
        </div>
      </div>
    </div>

    <input type="submit" class="hidden" />
  </form>

  <!-- E-mail sign-in -->
  <button
    id="continueWithEmail"
    class="btn btn-block btn-lg btn-primary btn-email"
    (click)="submitForm()"
    *ngIf="!isEmailFormVisible"
    [disabled]="isContinueWithEmailButtonDisabled"
  >
    <span *ngIf="!isLoading">
      <i>
        <svg fill="#fff" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path d="M0 0h24v24H0z" fill="none" />
          <path
            d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 14H4V8l8 5 8-5v10zm-8-7L4 6h16l-8 5z"
          />
        </svg>
      </i>
      {{ 'CONTINUE_WITH_EMAIL' | translate }}
    </span>

    <!-- Dummy span to make sure the button is still inflated -->
    <span *ngIf="isLoading">&nbsp;</span>

    <div class="spinner-container">
      <lh-shared-ui-spinner [visible]="isLoading"></lh-shared-ui-spinner>
    </div>
  </button>

  <!-- E-mail login button -->
  <div class="form-group" *ngIf="isEmailFormVisible">
    <button
      id="emailSignIn"
      class="btn btn-block btn-primary btn-create"
      (click)="submitForm()"
      [disabled]="currentEmailForm.invalid || isLoading"
    >
      {{ (isLoading ? 'PLEASE_WAIT' : isExistingUser ? 'LOG_IN_WITH_EMAIL' : 'REGISTER_NEW_USER') | translate }}
    </button>
  </div>

  <!-- Password reset -->
  <div *ngIf="isExistingUser && isEmailFormVisible" class="form-group text-end forgot-password">
    {{ 'FORGOT_PASSWORD' | translate
    }}<a id="requestPasswordReset" class="reset-pass-link" (click)="resetPassword()">{{
      'RESET_PASSWORD' | translate
    }}</a>
  </div>
</div>

<div [hidden]="!signInUsingMobile">
  <form [formGroup]="phoneNumberForm" class="user-form">
    <!-- <p>{{ phoneNumberPrompt | translate }}</p> -->
    <div class="form-group">
      <lh-phone-input
        id="phone"
        formControlName="phone"
        [advancedMode]="true"
        [(ngModel)]="user.phone"
        [placeholder]="'PHONE_NUMBER' | translate"
        [countries]="phoneCountries"
        [(selectedCountry)]="user.phoneCountry"
        [requestFocus]="true"
        (focus)="isPhoneFormVisible = false"
      >
      </lh-phone-input>
      <div *ngIf="phoneInputError" class="alert alert-danger">{{ phoneInputError | translate }}</div>
    </div>

    <div [hidden]="!isPhoneFormVisible">
      <div class="form-group password-form-group">
        <input
          id="oneTimePassword"
          [type]="isLoading ? 'password' : 'text'"
          inputmode="numeric"
          autocomplete="one-time-code"
          [minlength]="otpLength"
          [maxlength]="otpLength"
          [size]="otpLength"
          class="form-control one-time-code"
          name="oneTimePassword"
          formControlName="oneTimePassword"
          [(ngModel)]="user.password"
          [placeholder]="otpPlaceholder"
          (input)="onOneTimePasswordInput($event)"
          [readonly]="isLoading"
          #oneTimePasswordInput
        />

        <div class="otp-text" *ngIf="!isLoading">
          <h6>{{ 'ENTER_ONETIME_PASSWORD' | translate }}</h6>

          <!-- Resend one-time password -->
          <span>
            {{ 'DID_NOT_RECEIVE_A_CODE' | translate }}
            <span *ngIf="!isResendOtpAllowed">
              {{ 'SEND_AGAIN_IN_REMAINING_DELAY' | translate }}
              <span class="text-primary">0:{{ secondsRemainingBeforeOtpResend | zeroPaddedNumber: 2 }}</span>
            </span>
            <span *ngIf="isResendOtpAllowed">
              <a id="requestOneTimePassword" class="reset-pass-link" (click)="sendOneTimePassword()">
                {{ 'SEND_AGAIN' | translate }}
              </a>
            </span>
          </span>
        </div>

        <div class="text-center mt-4">
          <lh-shared-ui-spinner [visible]="isLoading"></lh-shared-ui-spinner>
        </div>

        <!-- <div class="text-center mt-4">
            <lh-shared-ui-spinner [visible]="isLoading"></lh-shared-ui-spinner>
          </div> -->
      </div>
    </div>
  </form>
  <button
    id="continueWithPhone"
    class="btn btn-block btn-lg btn-primary btn-phone"
    (click)="submitForm()"
    *ngIf="!isPhoneFormVisible"
    [disabled]="isContinueWithPhoneNumberButtonDisabled"
  >
    <span *ngIf="!isLoading">
      <i>
        <!-- <svg version="1.1" xmlns="http://www.w3.org/2000/svg" fill="#fff" width="24" height="24" viewBox="0 0 24 24">
          <title>call</title>
          <path
            d="M20.016 15.375q0.422 0 0.703 0.281t0.281 0.703v3.469q0 1.172-0.984 1.172-6.984 0-12-5.016t-5.016-12q0-0.984 1.172-0.984h3.469q0.422 0 0.703 0.281t0.281 0.703q0 1.828 0.563 3.516 0.188 0.609-0.234 1.031l-1.922 1.688q2.156 4.5 6.891 6.797l1.547-1.969q0.281-0.281 0.703-0.281 0.234 0 0.328 0.047 1.688 0.563 3.516 0.563z"
          ></path>
        </svg> -->
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" fill="#fff" width="24" height="24" viewBox="0 0 24 24">
          <title>phone_iphone</title>
          <path
            d="M15.984 18v-14.016h-9v14.016h9zM11.484 21.984q0.609 0 1.055-0.445t0.445-1.055-0.445-1.055-1.055-0.445-1.055 0.445-0.445 1.055 0.445 1.055 1.055 0.445zM15.516 0.984q1.031 0 1.758 0.75t0.727 1.781v16.969q0 1.031-0.727 1.781t-1.758 0.75h-8.016q-1.031 0-1.758-0.75t-0.727-1.781v-16.969q0-1.031 0.727-1.781t1.758-0.75h8.016z"
          ></path>
        </svg>
      </i>
      {{ 'CONTINUE_WITH_PHONE' | translate }}
    </span>

    <!-- Dummy span to make sure the button is still inflated -->
    <span *ngIf="isLoading">&nbsp;</span>

    <div class="spinner-container">
      <lh-shared-ui-spinner [visible]="isLoading"></lh-shared-ui-spinner>
    </div>
  </button>
</div>

<!-- External sign-in buttons -->
<div class="text-center form-group" *ngIf="isExternalSignInAvailable">
  <!-- Divider before external sign-in buttons -->
  <div class="divider">
    <small class="divider-text">{{ 'OR_CONNECT_WITH' | translate | lowercase }}</small>
  </div>

  <!--Facebook sign-in-->
  <a
    id="facebookSignIn"
    class="btn btn-facebook btn-facebook-sm"
    (click)="logInWithFacebook()"
    *ngIf="isFacebookSignInAvailable"
  >
    <i>
      <svg viewBox="0 0 32 32" role="presentation" aria-hidden="true" focusable="false">
        <path
          fill-rule="evenodd"
          d="M8 14.408v-4.165c0-.424.35-.812.77-.812h2.519V7.347c0-4.84 2.484-7.311 7.42-7.347 1.645 0 3.219.212 4.692.636.455.141.63.424.595.883l-.56 4.062c-.035.178-.14.354-.315.531-.21.105-.42.176-.63.14-.875-.247-1.784-.352-2.799-.352-1.399 0-1.61.283-1.61 1.73v1.8H22.6c.42 0 .805.423.805.883l-.349 4.17c0 .422-.35.705-.77.705H18.08v16c0 .424-.349.812-.769.812h-5.213c-.42 0-.804-.388-.804-.812V15.185h-2.52A.781.781 0 0 1 8 14.408"
        ></path>
      </svg>
    </i>
  </a>

  <!--Google sign-in-->
  <a
    id="googleSignIn"
    class="btn btn-info btn-google btn-google-sm"
    (click)="signInWithGoogle()"
    *ngIf="isGoogleSignInAvailable"
  >
    <i>
      <svg viewBox="0 0 18 18" role="presentation" aria-hidden="true" focusable="false">
        <g fill="none" fill-rule="evenodd">
          <path
            d="M9 3.48c1.69 0 2.83.73 3.48 1.34l2.54-2.48C13.46.89 11.43 0 9 0 5.48 0 2.44 2.02.96 4.96l2.91 2.26C4.6 5.05 6.62 3.48 9 3.48z"
            fill="#EA4335"
          ></path>
          <path
            d="M17.64 9.2c0-.74-.06-1.28-.19-1.84H9v3.34h4.96c-.1.83-.64 2.08-1.84 2.92l2.84 2.2c1.7-1.57 2.68-3.88 2.68-6.62z"
            fill="#4285F4"
          ></path>
          <path
            d="M3.88 10.78A5.54 5.54 0 0 1 3.58 9c0-.62.11-1.22.29-1.78L.96 4.96A9.008 9.008 0 0 0 0 9c0 1.45.35 2.82.96 4.04l2.92-2.26z"
            fill="#FBBC05"
          ></path>
          <path
            d="M9 18c2.43 0 4.47-.8 5.96-2.18l-2.84-2.2c-.76.53-1.78.9-3.12.9-2.38 0-4.4-1.57-5.12-3.74L.97 13.04C2.45 15.98 5.48 18 9 18z"
            fill="#34A853"
          ></path>
          <path d="M0 0h18v18H0V0z"></path>
        </g>
      </svg>
    </i>
  </a>
</div>

<!-- Skip button for testing -->
<div class="text-center" *ngIf="showSkipLogin">
  <button id="skipLogin" class="btn btn-xs btn-link" (click)="skipLogin()" [disabled]="isLoading">Skip</button>
</div>

<div class="tos-acceptance" *ngIf="!isStorageManager">
  <small>
    {{ 'ACCEPT_TERMS_PART_1' | translate }}
    <a target="_blank" href="https://luggagehero.com/terms-conditions/" class="tos-link href">
      {{ 'ACCEPT_TERMS_PART_2' | translate }}</a
    >
  </small>
</div>

<div class="padding-bottom"></div>
