import { LegacyOrder } from '@luggagehero/shared/interfaces';

import { SharedUtilProduct } from './shared-util-product';

export abstract class SharedUtilOrder {
  /**
   * Calcuates the storage rate (price per bag) by accumulating the unit price for all storage order lines in the
   * provided order
   * @param value The order for which to calculate the total storage rate per bag
   * @returns The total storage rate per bag for the give order
   */
  public static perBagStorageRate(value: LegacyOrder): number {
    const storageOrderLines = value.orderLines.filter((orderLine) => SharedUtilProduct.isStorageFee(orderLine.product));
    const storageRate = storageOrderLines.reduce((acc, orderLine) => acc + orderLine.unitPrice, 0);

    console.log(`Calculated storage rate: ${storageRate}`);

    return storageRate;
  }

  /**
   * Calcuates the service fee (price per bag) by accumulating the unit price for all service order lines in the
   * provided order
   * @param value The order for which to calculate the total service fee per bag
   * @returns The total service fee per bag for the given order
   */
  public static perBagServiceFee(value: LegacyOrder): number {
    const serviceOrderLines = value.orderLines.filter((orderLine) => SharedUtilProduct.isServiceFee(orderLine.product));
    const serviceFee = serviceOrderLines.reduce((acc, orderLine) => acc + orderLine.unitPrice, 0);

    console.log(`Calculated service fee: ${serviceFee}`);

    return serviceFee;
  }
}
