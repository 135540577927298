export enum StorageLocationType {
  ApparelStore = 'Apparel Store',
  Bar = 'Bar',
  Hairdresser = 'Barbershop / Hairdresser',
  Beauty = 'Beauty Salon / Nail Studio',
  BedAndBreakfast = 'Bed and Breakfast',
  BikeShopRental = 'Bike Rental / Bike Shop',
  HighEndShop = 'Boutique / High-end Shop',
  Cafe = 'Café',
  CoffeeShop = 'Coffee Shop',
  ConvenienceStore = 'Convenience Store',
  CopyPrintShop = 'Copy / Print Shop',
  Deli = 'Deli',
  DryCleaner = 'Dry Cleaner / Laundry',
  ElectronicsStore = 'Electronics Store',
  Gym = 'Gym / Yoga Studio',
  Hostel = 'Hostel',
  Hotel = 'Hotel',
  LiquorStore = 'Liquor Store',
  LuggageStorage = 'Luggage Storage',
  OfficeSpace = 'Office Space',
  PhoneRepairShop = 'Phone Repair Shop',
  PropertyManagementOffice = 'Property Management Office',
  Restaurant = 'Restaurant',
  SegwayTours = 'Segway Tours / Rental',
  SexShop = 'Sex Shop',
  Shipping = 'Shipping / Parcels',
  ShoeRepair = 'Shoe Repair / Cobbler',
  SmokeShop = 'Smoke Shop',
  SouvenirShop = 'Souvenir Shop',
  Spa = 'Spa',
  Supermarket = 'Supermarket',
  Tailor = 'Tailor',
  TattooShop = 'Tattoo Shop',
  TouristInformation = 'Tourist Information',
  Other = 'Other',
}
