import { Type } from '@angular/core';
import { Booking, PricingService } from '@luggagehero/shared/interfaces';
import { Observable, of as observableOf } from 'rxjs';

import { ValidateBookingActionBaseComponent } from '../booking-actions';
import { BookingActionInfo, BookingChange } from '../booking-actions/booking-action-info';
import { PickUpActionBaseComponent } from '../booking-actions/pick-up-action.base-component';
import { ShowReceiptActionBaseComponent } from '../booking-actions/show-receipt-action.base-component';
import { BookingTimelineBaseComponent } from '../booking-timeline.base-component';
import { BookingStep } from './booking-step';
export abstract class PickUpBaseStep extends BookingStep {
  constructor(
    parent: BookingTimelineBaseComponent,
    protected pricingService: PricingService,
  ) {
    super(parent);
  }

  get pickUpActionInfo(): BookingActionInfo {
    return (
      new BookingActionInfo('StopStorageTimerFromPickUpStep')
        .withCallToAction(this.translate('PICK_UP'))
        .withAction({ componentToShow: this.pickUpActionComponentType })
        .withTitle(null)
        // .withTitle(this.translate('CHECK_OUT_EXPLANATION_1'))
        // .withSubtitle(this.translate('CHECK_OUT_EXPLANATION_2'))
        .withViewSize('auto')
        .withDisabled(!this.getIsActive())
        .withDisplayTimer(this.parent.strategy.useInlineTimer)
    );
  }

  get showPickupConfirmationActionInfo(): BookingActionInfo {
    return new BookingActionInfo('ShowPickUpConfirmationFromPickUpStep')
      .withCallToAction(this.translate('SHOW_PICKUP_CONFIRMATION_LONG'))
      .withAction({ componentToShow: this.validateBookingActionComponentType })
      .withSkipAction({ functionToCall: () => this.skipShowPickupConfirmation() })
      .withSkipText(this.translate('DONE'))
      .withTitle(null)
      .withDisplayTimer(this.parent.strategy.useInlineTimer)
      .withState(this.booking.paidDirectly ? 'warning' : 'success');
  }

  get retryPaymentActionInfo(): BookingActionInfo {
    let actionInfo = new BookingActionInfo('RetryPaymentFromPickUpStep')
      .withCallToAction(this.translate('RETRY_PAYMENT_BUTTON'))
      .withAction({ componentToShow: this.showReceiptActionComponentType })
      .withTitle(this.translate('PAYMENT_FAILED'))
      .withDisplayTimer(this.parent.strategy.useInlineTimer)
      .withState('error')
      .withBookingChangeHandler((b) => this.onRetryPayment(b));

    if (this.booking.paymentPostponedAt) {
      actionInfo = actionInfo
        .withSkipAction({
          functionToCall: () => {
            this.triggerAction(this.showPickupConfirmationActionInfo);
            return Promise.resolve(null);
          },
        })
        .withSkipText(this.translate('SHOW_PICKUP_CONFIRMATION_LONG'));
    }

    return actionInfo;
  }

  //
  // We only know the base action components here, so force the derived class
  // to provide the specific types
  //
  protected abstract get pickUpActionComponentType(): Type<PickUpActionBaseComponent>;
  protected abstract get validateBookingActionComponentType(): Type<ValidateBookingActionBaseComponent>;
  protected abstract get showReceiptActionComponentType(): Type<ShowReceiptActionBaseComponent>;

  getIsVisible(): boolean {
    return this.booking.status !== 'CANCELLED';
  }

  getTitle(): string {
    return this.translate('RECLAIM_YOUR_LUGGAGE');
  }

  getSubtitle(): string {
    if (this.booking.status === 'CHECKED_OUT') {
      const total = this.booking.price.final.total;
      const currency = this.booking.price.final.currency;
      const price = this.pricingService.format(total, currency, 2, true);
      const part1 = this.translate('UNABLE_TO_CHARGE_PART_1');
      const part2 = this.translate('UNABLE_TO_CHARGE_PART_2');
      return `${part1} ${price} ${part2}`;
    }
    if (this.booking.status === 'PAID') {
      if (this.booking.paidDirectly) {
        const payInShop = this.translate('PAY_IN_SHOP');
        const showAndPay = this.translate('SHOW_THE_RECEIPT_AND_PAY_IN_SHOP');
        return `${payInShop}. ${showAndPay}`;
      }
      // const paymentSucceeded = this.translate('PAYMENT_SUCCEEDED');
      const showAndCollect = this.translate('SHOW_THE_RECEIPT_TO_THE_STAFF');
      // return `${paymentSucceeded}. ${showAndCollect}`;
      return showAndCollect;
    }
    return this.translate('STOP_STORAGE_TIMER_AND_SHOW_RECEIPT');
  }

  getIsSubtitleHidden(): boolean {
    if (this.parent.strategy.useInlineTimer && this.previousStep.isActive) {
      return true;
    }
    if (this.booking.status === 'CHECKED_IN') {
      return false;
    }
    if (this.isExperiencesEnabled) {
      return this.booking.status === 'CONFIRMED';
    }
    return false;
  }

  getIsActive(): boolean {
    return this.booking.status === 'CHECKED_IN';
  }

  getIsCompleted(): boolean {
    return this.booking.status === 'PAID';
  }

  getIsFailed(): boolean {
    return this.booking.status === 'CHECKED_OUT';
  }

  getActionInfo(): BookingActionInfo {
    if (this.booking.status === 'CHECKED_OUT') {
      return this.retryPaymentActionInfo;
    }
    if (this.booking.status === 'PAID') {
      return this.showPickupConfirmationActionInfo;
    }
    if (this.isExperiencesEnabled && this.booking.status === 'CONFIRMED') {
      return null;
    }
    // TODO: Use something else to determine this
    if (this.parent.strategy.useInlineTimer && this.booking.status === 'CONFIRMED') {
      return null;
    }
    return this.pickUpActionInfo;
  }

  getIsActionCollapsed(): boolean {
    return !this.isActive && !this.isFailed;
  }

  private skipShowPickupConfirmation(): Observable<BookingChange> {
    this.booking.state.showReceiptDone = true;
    const change: BookingChange = {
      changeType: 'booking',
      changedValue: this.booking,
    };
    return observableOf(change);
  }

  private onRetryPayment(booking: Booking) {
    if (booking.status === 'PAID' || booking.paymentPostponedAt) {
      this.triggerAction(this.showPickupConfirmationActionInfo);
    }
  }
}
