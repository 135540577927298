import { Directive, HostBinding, HostListener } from '@angular/core';

@Directive({
  selector: '[lhLoadStatus]',
})
export class LoadStatusDirective {
  @HostBinding('class.load-success') success = false;
  @HostBinding('class.load-error') error = false;

  @HostListener('error') onError() {
    this.error = true;
  }

  @HostListener('load') onLoad() {
    this.success = true;
  }
}
