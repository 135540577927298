import { HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom, Observable } from 'rxjs';

import { SharedHttpClient } from './shared-http-client';

@Injectable({
  providedIn: 'root',
})
export class SharedHttpService {
  public readonly error$: Observable<HttpStatusCode>;

  constructor(private client: SharedHttpClient) {
    this.error$ = this.client.error$;
  }

  async get<T>(url: string, authorize = true): Promise<T> {
    return firstValueFrom(this.client.get<T>(url, authorize));
  }

  async postFile<T>(url: string, file: Blob, fileName: string, authorize = true): Promise<T> {
    return firstValueFrom(this.client.postFile<T>(url, file, fileName, authorize));
  }

  async post<T>(url: string, data: unknown, authorize = true): Promise<T> {
    return firstValueFrom(this.client.post<T>(url, data, authorize));
  }

  async put<T>(url: string, data: unknown, authorize = true): Promise<T> {
    return firstValueFrom(this.client.put<T>(url, data, authorize));
  }

  async delete<T>(url: string, authorize = true): Promise<T> {
    return firstValueFrom(this.client.delete<T>(url, authorize));
  }
}
