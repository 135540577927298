import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { Router } from '@angular/router';
import { ModalService } from '@luggagehero/core';
import { NavbarBaseComponent } from '@luggagehero/features/ui';
import { SharedParamsService } from '@luggagehero/shared/services/params';
import { SharedStorageService, StorageTarget } from '@luggagehero/shared/services/storage';
import { SharedThemeService } from '@luggagehero/shared/services/theme';
import { SharedUserService } from '@luggagehero/shared/services/users';
import { SharedWindowService } from '@luggagehero/shared/services/window';

import { HowItWorksComponent } from '../how-it-works/how-it-works.component';
import { PricingComponent } from '../pricing/pricing.component';
import { UniversalSearchComponent } from '../universal-search/universal-search.component';

@Component({
  selector: 'lh-navbar-storage-manager',
  templateUrl: 'navbar-storage-manager.component.html',
  styleUrls: ['navbar-storage-manager.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavbarStorageManagerComponent extends NavbarBaseComponent {
  constructor(
    private modalService: ModalService,
    paramsService: SharedParamsService,
    themeService: SharedThemeService,
    protected userService: SharedUserService,
    windowService: SharedWindowService,
    router: Router,
    cd: ChangeDetectorRef,
    private storageService: SharedStorageService,
  ) {
    super(paramsService, themeService, userService, windowService, router, cd);
  }

  public async showHowItWorks(): Promise<void> {
    await this.modalService.show({ component: HowItWorksComponent });
  }

  public async showPriceInfo(): Promise<void> {
    await this.modalService.show({ component: PricingComponent });
  }

  public async showSearch(): Promise<void> {
    await this.modalService.show({ component: UniversalSearchComponent });
  }

  public isImpersonationMode(): boolean {
    const impersonationMode = this.userService.isImpersonationMode();
    return impersonationMode;
  }

  public get isValidImpersonationMode(): boolean {
    if (this.isImpersonationMode()) {
      const storageLocationId = this.storageService.storageLocationId;

      if (storageLocationId) {
        return true;
      }
    }
    return false;
  }
  private getStorageLocationLink(suffix: string): string {
    const storageLocationId = this.storageService.get('storageLocationId', StorageTarget.Local);
    return `/storage-locations/${storageLocationId}${suffix}`;
  }

  public get storageLocationSettingsLink(): string {
    return this.getStorageLocationLink('');
  }

  public get storageLocationBookingsLink(): string {
    return this.getStorageLocationLink('/bookings');
  }

  public get storageLocationPricingLink(): string {
    return this.getStorageLocationLink('/pricing');
  }

  get homeUrl(): string {
    return '/dashboard';
  }

  public async isLoggedInAsync(): Promise<boolean> {
    const isLoggedIn = await this.userService.isLoggedInAsync();

    console.log('isLoggedInAsync', isLoggedIn);
    return isLoggedIn;
  }
}
