<div class="step-headline">{{ 'ALMOST_THERE' | translate }} 👏</div>

<div *ngIf="isLoading" class="text-center mt-4">
  <lh-shared-ui-spinner [visible]="true"></lh-shared-ui-spinner>
</div>

<div *ngIf="!isLoading">
  <p>
    {{ 'CHOOSE_PAYMENT_METHOD_TO_CONFIRM_BOOKING_FOR_BAGS' | translate }}
    {{ numberOfBags }}
    {{ (numberOfBags === 1 ? 'BAG' : 'BAGS') | translate | lowercase }}
  </p>

  <lh-payment-method-selector
    [(ngModel)]="paymentMethod"
    [paymentMethods]="paymentMethods"
    [isPaymentDue]="false"
    [isConfirmingBooking]="true"
    [isSimpleMode]="false"
    [isConfirmButtonHidden]="true"
    [isWalletPaymentAllowed]="false"
    [isPayPalPaymentAllowed]="false"
    [order]="bookingDraft.order"
    [payment]="bookingDraft.payment"
    [storageLocation]="storageLocation"
    [disabled]="isLoading"
    (confirm)="createBooking()"
    (paymentMethodAuthenticationError)="onPaymentMethodError($event)"
    #paymentMethodSelector
  ></lh-payment-method-selector>
</div>
