<div class="shop-filters">
  <!-- prettier-ignore -->
  <svg  class="filter-icon" width="19" height="13" viewBox="0 0 19 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.53503 12.6156V10.6156H11.535V12.6156H7.53503ZM3.53503 7.61563V5.61563H15.535V7.61563H3.53503ZM0.535034 2.61563V0.615631H18.535V2.61563H0.535034Z" fill="currentColor"/>
  </svg>

  <div class="desktop-filter">
    @if (isHotelFilterVisible) {
      <button
        class="filter-button toggle-button"
        [ngClass]="{ active: hotelsFilterEnabled }"
        (click)="toggleHotelsFilter.emit()"
      >
        {{ 'HOTELS_FILTER' | lhTranslate }}
      </button>
    }

    @if (isOpen24HoursFilterVisible) {
      <button
        class="filter-button toggle-button"
        [ngClass]="{ active: openAllDayFilterEnabled }"
        (click)="toggleOpenAllDayFilter.emit()"
      >
        {{ 'OPEN_24_HOURS' | lhTranslate }}
      </button>
    }

    @if (isMultiDayStorageFilterVisible) {
      <button
        class="filter-button toggle-button"
        [ngClass]="{ active: multiDayStorageFilterEnabled }"
        (click)="toggleMultiDayStorageFilter.emit()"
      >
        {{ 'MULTI_DAY_STORAGE_FILTER' | lhTranslate }}
      </button>
    }

    @if (isWifiAvailableFilterVisible) {
      <button
        class="filter-button toggle-button"
        [ngClass]="{ active: wifiAvailableFilterEnabled }"
        (click)="toggleWifiAvailableFilter.emit()"
      >
        {{ 'WIFI_FILTER' | lhTranslate }}
      </button>
    }

    <button class="filter-button toggle-button unit-button active" (click)="distanceUnitClick.emit()">
      <span [ngClass]="{ active: distanceUnit === 'metric' }" class="km">km</span>
      <span>/</span>
      <span [ngClass]="{ active: distanceUnit === 'imperial' }" class="miles">miles</span>
    </button>
  </div>

  <div class="mobile-trigger" (click)="presentFilterAlert()"></div>

  <div class="mobile-filter">
    <div class="filter-label">
      {{ filterLabel }}
    </div>

    <div class="filter-count" *ngIf="activeFiltersCount">
      {{ activeFiltersCount }}
    </div>
  </div>
</div>
