import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';

import { ITimeInterval } from './time-interval.interface';

export interface TimeIntervalTransformerService {
  transform(
    value: ITimeInterval[],
    short?: boolean,
    timeOnly?: boolean,
    ...components: ('from' | 'to')[]
  ): Observable<string>;
}
export const TIME_INTERVAL_TRANSFORMER_SERVICE = new InjectionToken<TimeIntervalTransformerService>(
  'TimeIntervalTransformerService',
);
