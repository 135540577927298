import { Injectable } from '@angular/core';
import { Config } from '@luggagehero/shared/environment';
import { IPaymentRecord, IPayout, Result } from '@luggagehero/shared/interfaces';
import { SharedHttpService } from '@luggagehero/shared/services/http';
import { SharedWindowService } from '@luggagehero/shared/services/window';
import { SharedUtilWindow } from '@luggagehero/shared/util';
import { TranslateService } from '@ngx-translate/core';

import { PayoutAccountLink } from './payout-account-link';
import { PayoutVerificationStatus } from './payout-verification-status';

type ProviderRecord = {
  provider: string;
  data: unknown;
};
interface PaymentRecordDto {
  _id: string;
  providerRecord: ProviderRecord;
}

export enum BusinessType {
  'company' = 'company',
  'individual' = 'individual',
}

@Injectable()
export class PayoutService {
  private apiEndPoint: string;

  constructor(
    private httpService: SharedHttpService,
    private windowService: SharedWindowService,
    private translate: TranslateService,
  ) {
    this.apiEndPoint = `${Config.environment.STORAGE_MANAGER_API}/payouts`;
  }

  async getAuthorizationCode(provider: string): Promise<string> {
    const url = `${this.apiEndPoint}/accounts/oauth/authorize?provider=${provider}`;

    // TODO: Add to translation files
    const title = this.translate.instant('PAYOUT_PROVIDER_CONNECT_WINDOW_TITLE') as string;

    const window = await this.windowService.openCentered(url, title, false).toPromise();
    return SharedUtilWindow.waitForValue(window, 'PAYOUT_PROVIDER_OAUTH_CODE').toPromise();
  }

  async connectStripeAccount(authorizationCode: string): Promise<IPaymentRecord> {
    const url = `${this.apiEndPoint}/accounts/oauth/connect`;
    const res = await this.httpService.post<PaymentRecordDto>(url, {
      provider: 'stripe',
      providerData: authorizationCode,
    });
    return {
      id: res._id,
      provider: res.providerRecord.provider,
      data: res.providerRecord.data,
    };
  }

  async getPayoutProviderAccounts(): Promise<IPaymentRecord[]> {
    const url = `${this.apiEndPoint}/accounts`;
    const res = await this.httpService.get<PaymentRecordDto[]>(url, true);

    if (!res) {
      return [];
    }

    const payoutProviderAccounts: IPaymentRecord[] = [];

    for (let i = 0; res && i < res.length; i++) {
      const payoutProviderAccount: IPaymentRecord = {
        id: res[i]._id,
        provider: res[i].providerRecord.provider,
        data: res[i].providerRecord.data,
      };
      payoutProviderAccounts.push(payoutProviderAccount);
    }

    return payoutProviderAccounts;
  }

  async getPayoutMethods(): Promise<IPaymentRecord[]> {
    const url = `${this.apiEndPoint}/methods`;
    const res = await this.httpService.get<PaymentRecordDto[]>(url, true);

    const payoutMethods: IPaymentRecord[] = [];
    for (let i = 0; i < res.length; i++) {
      const payoutMethod: IPaymentRecord = {
        id: res[i]._id,
        provider: res[i].providerRecord.provider,
        data: res[i].providerRecord.data,
      };
      payoutMethods.push(payoutMethod);
    }
    return payoutMethods;
  }

  async getPayoutAccountVerificationStatus(accountId: string): Promise<PayoutVerificationStatus> {
    const url = `${this.apiEndPoint}/accounts/${accountId}/verification_status`;
    const res = await this.httpService.get<PayoutVerificationStatus>(url, true);
    return res;
  }

  async getManagePayoutAccountLink(accountId: string): Promise<PayoutAccountLink> {
    const url = `${this.apiEndPoint}/accounts/${accountId}/link`;
    const res = await this.httpService.get<PayoutAccountLink>(url, true);
    return res;
  }

  async addPayoutProviderAccount(
    email: string,
    country: string,
    businessType: BusinessType,
    businessLegalName: string,
    storageLocationId: string,
    provider: string,
    providerData?: unknown,
  ): Promise<IPaymentRecord> {
    const url = `${this.apiEndPoint}/accounts`;
    const res = await this.httpService.post<PaymentRecordDto>(url, {
      email,
      country,
      businessType,
      businessLegalName,
      storageLocationId,
      provider,
      providerData,
    });
    return {
      id: res._id,
      provider: res.providerRecord.provider,
      data: res.providerRecord.data,
    };
  }

  async updatePayoutProviderAccount(payoutAccountId: string, providerData: unknown): Promise<IPaymentRecord> {
    const url = `${this.apiEndPoint}/accounts/${payoutAccountId}`;
    const res = await this.httpService.put<{
      _id: string;
      providerRecord: ProviderRecord;
    }>(url, providerData);

    return {
      id: res._id,
      provider: res.providerRecord.provider,
      data: res.providerRecord.data,
    };
  }

  async deletePayoutMethod(payoutMethodId: string): Promise<Result> {
    const url = `${this.apiEndPoint}/methods/${payoutMethodId}`;
    return this.httpService.delete<Result>(url, true);
  }

  async getPayouts(storageLocationId: string, testMode = false): Promise<IPayout[]> {
    const url = `${Config.environment.STORAGE_MANAGER_API}/storage_locations/${storageLocationId}/payouts?testMode=${testMode}`;

    const res = await this.httpService.get<IPayout[]>(url);

    return res.map((payout) => {
      payout.data.securityTagCount = payout.data.securityTagCount || 0;
      payout.data.securityTagFee = payout.data.securityTagFee || 0;
      return payout;
    });
  }
}
