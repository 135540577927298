import { inject, Pipe, PipeTransform } from '@angular/core';
import {
  IPaymentRecord,
  PAYMENT_METHOD_TRANSFORMER_SERVICE,
  PaymentMethodTransformerService,
} from '@luggagehero/shared/interfaces';

@Pipe({ name: 'paymentMethod', standalone: true })
export class PaymentMethodPipe implements PipeTransform {
  private transformer = inject<PaymentMethodTransformerService>(PAYMENT_METHOD_TRANSFORMER_SERVICE);

  transform(value: IPaymentRecord, format = 'summary', html = false): string {
    return this.transformer.transform(value, format, html);
  }
}
