import { Injectable } from '@angular/core';
import { AvailabilityContext, BookableStorageLocation } from '@luggagehero/shared/interfaces';
import { SharedShopsService } from '@luggagehero/shared/services/shops';
import { SharedStorageCriteriaService } from '@luggagehero/shared/services/storage-criteria';
import { SharedTranslateService } from '@luggagehero/shared/services/translation';
import moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class SharedStorageAvailabilityService {
  constructor(
    private criteriaService: SharedStorageCriteriaService,
    private translate: SharedTranslateService,
    private shopsService: SharedShopsService,
  ) {}

  /**
   * Get availability information for a given shop.
   * @param shop - The shop for which to retrieve availability.
   * @returns AvailabilityContext - An object with open/close times and flags for availability.
   */
  getAvailability(shop: BookableStorageLocation): AvailabilityContext {
    const criteriaDate = moment(this.criteriaService.currentOrDefault.period.from);
    const today = moment().startOf('day');
    const openingHours = this.shopsService.getOpeningHoursForDate(criteriaDate.toDate(), shop);
    const isForCurrentDay = today.isSame(criteriaDate, 'day');

    if (!openingHours || openingHours.length === 0) {
      return {
        openTime: null,
        closeTime: null,
        isOpenAllDay: false,
        isPermanentlyClosed: true,
        isFullyBooked: false,
        isForCurrentDay,
      };
    }

    const isOpen = this.shopsService.isOpen(criteriaDate.toDate(), shop);
    return {
      isForCurrentDay,
      openTime: this.formatTime(openingHours[0].from),
      closeTime: this.formatTime(openingHours[0].to),
      isOpenAllDay: this.shopsService.isOpenAllDay(this.criteriaService.period.from, shop),
      isPermanentlyClosed: false,
      isFullyBooked: isOpen && !shop.available,
    };
  }

  formatTime(time: number): string {
    const startOfDay = moment().startOf('day');
    const timeOfDay = startOfDay.add(time, 'minutes');
    return moment(timeOfDay).format('HH:mm');
  }

  /**
   * Translate weekday names based on the current language.
   * @returns An array of localized weekday names.
   */
  getLocalizedWeekdayNames(): string[] {
    return [
      this.translate.instant('SUNDAY'),
      this.translate.instant('MONDAY'),
      this.translate.instant('TUESDAY'),
      this.translate.instant('WEDNESDAY'),
      this.translate.instant('THURSDAY'),
      this.translate.instant('FRIDAY'),
      this.translate.instant('SATURDAY'),
    ];
  }
}
