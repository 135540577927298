import { CommonModule, NgFor, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, Input } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AppConfig } from '@luggagehero/shared/app-settings/data-access';
import { BookableStorageLocation, Booking, ModalRef, Optional, Review } from '@luggagehero/shared/interfaces';
import { SharedErrorService } from '@luggagehero/shared/services/error';
import { SharedReviewsService } from '@luggagehero/shared/services/reviews';
import { SharedTranslateService } from '@luggagehero/shared/services/translation';
import { SharedUiSpinnerComponent } from '@luggagehero/shared/ui';
import { BookingPipe, MomentPipe } from '@luggagehero/shared/ui-pipes';
import { PublicReviewComponent } from '@luggagehero/traveler/shops/ui';
import { TranslateModule } from '@ngx-translate/core';
import { RatingModule } from 'ngx-bootstrap/rating';

const TRIM_SNIPPET_REGEX = /^[.,;: ]|[.,;: ]+$/g;
const SNIPPET_LENGTH = 240;
const SNIPPET_CUTOFF = 250;
const DEFAULT_BATCH_SIZE = 25;
const MAX_LOCAL_REVIEWS = 100;

@Component({
  selector: 'lh-traveler-shops-feature-shop-reviews',
  standalone: true,
  imports: [
    CommonModule,
    SharedUiSpinnerComponent,
    PublicReviewComponent,
    FormsModule,
    RatingModule,
    TranslateModule,
    MomentPipe,
    BookingPipe,
    NgIf,
    NgFor,
  ],
  templateUrl: './traveler-shops-feature-shop-reviews.component.html',
  styleUrls: ['./traveler-shops-feature-shop-reviews.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TravelerShopsFeatureShopReviewsComponent {
  public modalRef?: ModalRef;

  public readonly profileBackgroundColors = AppConfig.PROFILE_BACKGROUND_COLORS;

  @Input() set shop(value: BookableStorageLocation) {
    this._shop = value;
    this._perfectRatingsCount = this._shop?.stats?.numberOfPerfectRatings ?? 0;

    clearTimeout(this._loadReviewsTimer);
    this._loadReviewsTimer = window.setTimeout(() => {
      void this.loadReviews();
    }, 1000);

    this.cd.markForCheck();
  }
  get shop(): Optional<BookableStorageLocation> {
    return this._shop;
  }

  public visibleReviews: Review[] = [];
  public isShowingRecommendations = AppConfig.IS_SHOW_RECOMMENDATIONS_WITH_REVIEWS_ENABLED;
  public indexOfFirstGlobalReview = 0;

  public get isLoading(): boolean {
    return this._isLoading;
  }

  public set isLoading(value: boolean) {
    this._isLoading = value;
    this.cd.markForCheck();
  }

  public get isReady(): boolean {
    return this._isReady;
  }

  public get perfectRatingsCount(): number {
    return this._perfectRatingsCount;
  }

  public get reviews(): Review[] {
    return this._reviews;
  }
  public set reviews(value: Review[]) {
    this._reviews = value;
    this._currentBatchIndex = 0;
    this.showNextBatch();
  }

  public get isModal(): boolean {
    return !!this.modalRef;
  }

  public async hideModal(result?: unknown): Promise<void> {
    await this.modalRef?.hide(result);
  }

  public showNextBatch(): void {
    this._currentBatchIndex++;
    const maxToShow = this._currentBatchIndex * this._batchSize;

    if (this._reviews.length > maxToShow) {
      this.visibleReviews = this._reviews.slice(0, maxToShow);
    } else {
      this.visibleReviews = [...this._reviews];
    }

    this.cd.markForCheck();
  }

  public getSnippet(review: Review): string {
    const comment = review.translation?.shopComments ?? review.shopComments;
    if (!comment) {
      return '';
    }
    if (comment.length < SNIPPET_CUTOFF) {
      return comment;
    }
    return comment.substring(0, SNIPPET_LENGTH).replace(TRIM_SNIPPET_REGEX, '');
  }

  public getLuggage(review: Review): Booking {
    return { luggage: { normal: review.bookingBags, hand: 0 } } as Booking;
  }

  public isClipped(review: Review): boolean {
    return (review.shopComments?.length ?? 0) >= SNIPPET_CUTOFF;
  }

  public getDividerText(index: number): Optional<string> {
    if (index > 0 && this.indexOfFirstGlobalReview === index) {
      return this.translate.instant('REVIEWS_OF_OTHER_LOCATIONS');
    }
    return null;
  }

  public async loadReviews(): Promise<void> {
    if (!this._shop) {
      return;
    }

    this.isLoading = true;

    try {
      const localReviews = await this.reviewsService.listReviewsByStorageLocation(this._shop._id);
      const validLocalReviews = localReviews.filter((r) => this._shouldShowReview(r));
      const allReviews = [...validLocalReviews];

      if (validLocalReviews.length < MAX_LOCAL_REVIEWS) {
        this.indexOfFirstGlobalReview = validLocalReviews.length;
        const needed = MAX_LOCAL_REVIEWS - validLocalReviews.length;

        const globalReviews = await this.reviewsService.listReviews(needed);
        const validGlobalReviews = globalReviews.filter(
          (r) => this._shouldShowReview(r) && r.shopId !== this._shop?._id,
        );
        allReviews.push(...validGlobalReviews);
      } else {
        this.indexOfFirstGlobalReview = MAX_LOCAL_REVIEWS;
      }

      this.reviews = allReviews.sort((a: Review, b: Review) => {
        const isLocalA = a.shopId === this._shop?._id;
        const isLocalB = b.shopId === this._shop?._id;

        if (isLocalA && !isLocalB) {
          return -1;
        }
        if (isLocalB && !isLocalA) {
          return 1;
        }

        const dateA = new Date(a.bookingDate ?? a.created).getTime();
        const dateB = new Date(b.bookingDate ?? b.created).getTime();
        return dateB - dateA;
      });
    } catch (err) {
      this.errorService.handleError(err);
    } finally {
      this._isReady = true;
      this.isLoading = false;
    }
  }

  private _shop?: BookableStorageLocation;
  private _loadReviewsTimer = 0;

  private _isReady = false;
  private _isLoading = false;

  private _batchSize = DEFAULT_BATCH_SIZE;
  private _currentBatchIndex = 0;

  private _reviews: Review[] = [];
  private _perfectRatingsCount = 0;

  private readonly reviewsService = inject(SharedReviewsService);
  private readonly translate = inject(SharedTranslateService);
  private readonly errorService = inject(SharedErrorService);
  private readonly cd = inject(ChangeDetectorRef);

  private _shouldShowReview(review: Review): boolean {
    if (review.rating) {
      return true;
    }

    return (
      this.isShowingRecommendations &&
      review.satisfaction >= AppConfig.MIN_SATISFACTION_TO_SHOW_WITH_REVIEWS &&
      review.shopId === this._shop?._id
    );
  }
}
