<!-- Storage locations that use security seals -->
<div *ngIf="!storageLocation.noSecuritySeals">
  <div class="step-headline">{{ 'TAKE_PHOTO_OF_SECURITY_SEALS' | lhTranslate: variant }}</div>
  <p *ngIf="storageLocation.validateBagPhoto">
    <span>{{ 'MAKE_SURE_TAG_NUMBERS_VISIBLE' | lhTranslate: variant }}</span>
    <a class="href ms-1" (click)="showExample()">{{ 'SEE_EXAMPLE' | lhTranslate: variant }}</a>
  </p>
  <p *ngIf="!storageLocation.validateBagPhoto">
    {{ 'DROPOFF_TAKE_A_PHOTO_OF_YOUR_LUGGAGE_PART_2' | lhTranslate: variant }}
  </p>
</div>

<!-- Storage locations that DO NOT use security seals -->
<div *ngIf="storageLocation.noSecuritySeals">
  <div class="step-headline">
    {{ 'DROPOFF_TAKE_A_PHOTO_OF_YOUR_LUGGAGE_PART_1' | lhTranslate: variant }}
  </div>
  <p>{{ 'DROPOFF_TAKE_A_PHOTO_OF_YOUR_LUGGAGE_PART_2' | lhTranslate: variant }}</p>
</div>

<div class="image-input-container">
  <div class="image-input-wrapper">
    <lh-shared-feature-bag-image-input
      [remoteLuggageImage]="remoteLuggageImage"
      [localLuggageImage]="localLuggageImage"
      [storageLocation]="storageLocation"
      (remoteLuggageImageChange)="onRemoteImageChange($event)"
      (completedChange)="onCompletedChange($event)"
      (allowSkipChange)="onAllowSkipChange($event)"
    ></lh-shared-feature-bag-image-input>
  </div>
</div>
