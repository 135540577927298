// taxes.reducer.ts
import { createReducer, on } from '@ngrx/store';

import * as TaxesActions from './taxes.actions';
import { initialState } from './taxes.model';

export const taxesReducer = createReducer(
  initialState,

  // Get Taxes
  on(TaxesActions.getTaxes, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(TaxesActions.getTaxesSuccess, (state, { taxes }) => ({
    ...state,
    isLoading: false,
    taxes,
  })),
  on(TaxesActions.getTaxesFailure, (state) => ({
    ...state,
    isLoading: false,
  })),

  // Add Tax
  on(TaxesActions.addTax, (state) => ({
    ...state,
    isSavingDraft: true,
  })),
  on(TaxesActions.addTaxSuccess, (state, { taxItem }) => ({
    ...state,
    isSavingDraft: false,
    taxes: [...state.taxes, taxItem],
    draft: null,
  })),
  on(TaxesActions.addTaxFailure, (state) => ({
    ...state,
    isSavingDraft: false,
  })),

  // Update Tax
  on(TaxesActions.updateTax, (state, { payload }) => ({
    ...state,
    savingStates: {
      ...state.savingStates,
      [payload.taxItemId]: true,
    },
  })),
  on(TaxesActions.updateTaxSuccess, (state, { updatedTaxItem }) => ({
    ...state,
    savingStates: {
      ...state.savingStates,
      [updatedTaxItem._id || '']: false,
    },
    taxes: state.taxes.map((tax) => (tax._id === updatedTaxItem._id ? updatedTaxItem : tax)),
  })),
  on(TaxesActions.updateTaxFailure, (state, { taxItemId }) => ({
    ...state,
    savingStates: {
      ...state.savingStates,
      [taxItemId]: false,
    },
  })),

  // Delete Tax
  on(TaxesActions.deleteTax, (state, { payload }) => ({
    ...state,
    deletingStates: {
      ...state.deletingStates,
      [payload.taxItemId]: true,
    },
  })),
  on(TaxesActions.deleteTaxSuccess, (state, { taxItemId }) => ({
    ...state,
    deletingStates: {
      ...state.deletingStates,
      [taxItemId]: false,
    },
    taxes: state.taxes.filter((tax) => tax._id !== taxItemId),
  })),
  on(TaxesActions.deleteTaxFailure, (state, { taxItemId }) => ({
    ...state,
    deletingStates: {
      ...state.deletingStates,
      [taxItemId]: false,
    },
  })),
);
