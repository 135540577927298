import { ChangeDetectorRef, Component, EventEmitter, inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { GoogleMap as AngularGoogleMap, MapInfoWindow } from '@angular/google-maps';
import { AppConfig } from '@luggagehero/shared/app-settings/data-access';
import { BookableStorageLocation } from '@luggagehero/shared/interfaces';
import { SharedThemeService } from '@luggagehero/shared/services/theme';
import { ActiveStorageLocationMarkerNotice } from '@luggagehero/traveler/shops/ui';

import { BaseComponent } from '../../../core';
import { GeoUtil } from '../../../utils/geo.util';

export interface MapMarker extends google.maps.MarkerOptions {
  storageLocation?: BookableStorageLocation;
}

export type MapCircle = google.maps.CircleOptions;
export type MapTypeStyle = google.maps.MapTypeStyle;

const STORAGE_LOCATION_MARKER_ICON_HEIGHT = 48;
const STORAGE_LOCATION_MARKER_ICON_WIDTH = 48;
const MAP_UPDATE_DEBOUNCE_MS = 200;

@Component({ template: '' })
export abstract class StorageLocationMapBaseComponent extends BaseComponent implements OnInit {
  @ViewChild(AngularGoogleMap) public angularGoogleMap?: AngularGoogleMap;
  @ViewChild(MapInfoWindow) public infoWindow?: MapInfoWindow;

  @Output() public centerChange = new EventEmitter<google.maps.LatLngLiteral>();
  @Output() public zoomChange = new EventEmitter<number>();
  @Output() public bookingRequest = new EventEmitter<BookableStorageLocation>();
  @Output() public detailsRequest = new EventEmitter<BookableStorageLocation>();
  @Output() public markerClick = new EventEmitter<BookableStorageLocation>();

  @Input() public myLocation: google.maps.LatLngLiteral;
  @Input() public latitude: number;
  @Input() public longitude: number;
  @Input() public obfuscateStorageLocations: boolean;
  @Input() public enableStorageLocationInfoWindows: boolean;
  @Input() public enableStorageLocationMarkerNotice: boolean;
  @Input() public maxZoom: number;
  @Input() public clickableIcons: boolean;
  @Input() public keyboardShortcuts: boolean;
  @Input() public fullscreenControl: boolean;
  @Input() public scaleControl: boolean;
  @Input() public streetViewControl: boolean;
  @Input() public zoomControl: boolean;
  @Input() public zoomControlOptions: google.maps.ZoomControlOptions;
  @Input() public disableDoubleClickZoom: boolean;
  @Input() public scrollwheel: boolean;
  @Input() public draggable: boolean;
  @Input() public gestureHandling: string;
  @Input() public usePanning: boolean;
  @Input() public styles: MapTypeStyle[];
  @Input() public initialOpenStorageLocationId: string;
  @Input() public largeStorageLocationMarkers: boolean;
  @Input() public largeStorageLocationCircles: boolean;
  @Input() public storageLocationObfuscation = AppConfig.IS_MAPS_LOCATION_OBFUSCATION_ENABLED;

  public openStorageLocation: BookableStorageLocation;

  private themeService = inject(SharedThemeService);
  private cd = inject(ChangeDetectorRef);

  private _storageLocationCircles: MapCircle[];
  private _storageLocationMarkers: MapMarker[];
  private _myLocationMarker: MapMarker;
  private _storageLocations: BookableStorageLocation[];
  private _highlightedStorageLocationId: string;
  private wasObfuscating = false;
  private loadStorageLocationCirclesTimeout: NodeJS.Timeout;
  private loadStorageLocationMarkersTimeout: NodeJS.Timeout;
  private _mapOptions: google.maps.MapOptions;
  private _markerNotices: ActiveStorageLocationMarkerNotice[] = [];

  private _center: google.maps.LatLngLiteral;
  private _zoom: number;

  public get mapOptions(): google.maps.MapOptions {
    return this._mapOptions;
  }

  public get myLocationMarker(): MapMarker {
    if (!this.myLocation) {
      return null;
    }
    // TODO: Do we need to store this in a variable?
    this._myLocationMarker = {
      position: this.myLocation,
      icon: {
        url: 'assets/markers/my-location-circle.svg',
        anchor: new google.maps.Point(8, 8),
      },
    };
    return this._myLocationMarker;
  }

  public get mapInfoWindowOptions(): google.maps.InfoWindowOptions {
    const isBestChoice = this.openStorageLocation != null ? this.isBestChoice(this.openStorageLocation) : false;
    const factor =
      this.largeStorageLocationMarkers || isBestChoice ? 1 : AppConfig.SHOP_MAP_MARKER_SIZE_DOWNSCALE_FACTOR;
    const markerIconHeight = STORAGE_LOCATION_MARKER_ICON_HEIGHT * factor;
    const options: google.maps.InfoWindowOptions = {
      pixelOffset: new google.maps.Size(0, -markerIconHeight / 2 - 5),
    };
    return options;
  }

  public get center(): google.maps.LatLngLiteral {
    return this._center;
  }
  @Input() public set center(value: google.maps.LatLngLiteral) {
    if (GeoUtil.isSame(value, this._center)) {
      return; // Nothing changed
    }
    this._center = value;
    this.cd.markForCheck();
  }

  public get zoom(): number {
    return this._zoom;
  }
  @Input() public set zoom(value: number) {
    if (!value || value === this._zoom) {
      return;
    }
    this._zoom = value;
    this.cd.markForCheck();
  }

  public get storageLocations(): BookableStorageLocation[] {
    return this._storageLocations;
  }
  @Input() public set storageLocations(value: BookableStorageLocation[]) {
    if (!value || (value.length === 0 && !this._storageLocations)) {
      return;
    }
    this._storageLocations = value;

    this.loadStorageLocationMarkers();

    if (this.initialOpenStorageLocationId) {
      // Delay this a bit so it catches the user's attention
      setTimeout(() => {
        this.openStorageLocationInfoWindow(this.initialOpenStorageLocationId);
        this.initialOpenStorageLocationId = null;
      }, 1000);
    }
  }

  public get highlightedStorageLocationId(): string {
    return this._highlightedStorageLocationId;
  }
  @Input() public set highlightedStorageLocationId(value: string) {
    if (this._highlightedStorageLocationId === value) {
      return; // Nothing changed
    }

    // Record previous highlighted storage location id and update with the new value
    const previousHighlightedStorageLocationId = this._highlightedStorageLocationId;
    this._highlightedStorageLocationId = value;

    // Update marker icons for the previous and new highlighted storage locations
    this.updateStorageLocationMarkerIcon(previousHighlightedStorageLocationId);
    this.updateStorageLocationMarkerIcon(this.highlightedStorageLocationId);

    // Update view
    this.cd.markForCheck();
  }

  public get storageLocationMarkers(): MapMarker[] {
    return this._storageLocationMarkers;
  }

  public get storageLocationCircles(): MapCircle[] {
    return this._storageLocationCircles;
  }

  private get isObfuscating(): boolean {
    if (!this.storageLocationObfuscation) {
      return false;
    }
    return this.zoom >= 16 || this.largeStorageLocationCircles ? true : false;
  }

  public ngOnInit() {
    this.updateMapOptions();
  }

  public onMapInitialized(map: google.maps.Map) {
    map.setCenter(this.center);
    map.setZoom(this.zoom);
  }

  public onCenterChange() {
    this.centerChange.emit(this.angularGoogleMap?.getCenter().toJSON());
  }

  public onZoomChange(e: number) {
    this.zoom = this.angularGoogleMap?.getZoom() || e;
    this.loadStorageLocationCircles();
    this.zoomChange.emit(this.zoom);
  }

  public onMapClick(_e: MouseEvent) {
    this.setOpenStorageLocation(null);
    this.closeActiveStorageLocationMarkerNotices();

    if (this.infoWindow) {
      this.infoWindow.close();
    }
  }

  public onStorageLocationClick(storageLocation: BookableStorageLocation) {
    this.detailsRequest.emit(storageLocation);
  }

  public onBookingRequest(storageLocation: BookableStorageLocation) {
    this.bookingRequest.emit(storageLocation);
  }

  public onStorageLocationMarkerClick(marker: MapMarker) {
    this.openStorageLocationInfoWindow(marker.storageLocation._id);
    this.markerClick.emit(marker.storageLocation);
  }

  public onStorageLocationMarkerMouseOver(marker: MapMarker) {
    this.highlightedStorageLocationId = marker.storageLocation._id;
  }

  public onStorageLocationMarkerMouseOut(_marker: MapMarker) {
    this.highlightedStorageLocationId = null;
  }

  public isStorageLocationOpen(value: BookableStorageLocation): boolean {
    if (!this.enableStorageLocationInfoWindows) {
      return false;
    }
    if (!this.openStorageLocation || !value) {
      return false;
    }
    return this.openStorageLocation?._id === value._id;
  }

  public openStorageLocationInfoWindow(storageLocationId: string) {
    this.closeActiveStorageLocationMarkerNotices();

    if (!storageLocationId) {
      return;
    }
    this.setOpenStorageLocation(this.storageLocations?.find((s) => s._id === storageLocationId));

    if (this.infoWindow && this.openStorageLocation) {
      this.infoWindow.position = {
        lat: this.openStorageLocation.location.coordinates[1],
        lng: this.openStorageLocation.location.coordinates[0],
      };
      this.infoWindow.open();
    }

    this.openActiveStorageLocationMarkerNotice(this.openStorageLocation);
  }

  private _markerNoticeOrNull(): string | null {
    try {
      return this._translate(AppConfig.ACTIVE_STORAGE_LOCATION_MARKER_NOTICE?.trim());
    } catch {
      return null;
    }
  }

  private closeActiveStorageLocationMarkerNotices() {
    this._markerNotices.forEach((x) => {
      x?.setMap(null);
    });
    this._markerNotices = [];
  }

  private openActiveStorageLocationMarkerNotice(storageLocation: BookableStorageLocation) {
    const notice = this._markerNoticeOrNull();

    if (notice && this.enableStorageLocationMarkerNotice) {
      const iconHeight = this.isBestChoice(storageLocation)
        ? STORAGE_LOCATION_MARKER_ICON_HEIGHT
        : STORAGE_LOCATION_MARKER_ICON_HEIGHT * AppConfig.SHOP_MAP_MARKER_SIZE_DOWNSCALE_FACTOR;

      const markerNotice = new ActiveStorageLocationMarkerNotice({
        map: this.angularGoogleMap.googleMap,
        position: new google.maps.LatLng({
          lat: storageLocation.location.coordinates[1],
          lng: storageLocation.location.coordinates[0],
        }),
        text: notice,
        offset: {
          x: 0,
          y: iconHeight + 15,
        },
        onClick: () => {
          this.bookingRequest.emit(storageLocation);
        },
      });

      this._markerNotices.push(markerNotice);
    }
  }

  private isBestChoice(storageLocation: BookableStorageLocation): boolean {
    return storageLocation?.tags?.includes('Recommended') ?? false;
  }

  // HACK: Here to ensure marker is updated visually when using @google/angular-maps
  public getMarkerOptions(marker: MapMarker): google.maps.MarkerOptions {
    const options: google.maps.MarkerOptions = {
      position: marker.position,
      icon: marker.icon,
      title: marker.storageLocation.name,
      zIndex: this.isBestChoice(marker.storageLocation) ? 99999999 : 1000000,
    };
    return options;
  }

  private setOpenStorageLocation(value: BookableStorageLocation) {
    if (value === this.openStorageLocation) {
      return;
    }

    if (!this.storageLocationMarkers) {
      return;
    }

    const previous = this.openStorageLocation;
    this.openStorageLocation = value;

    this.storageLocationMarkers.forEach((m) => {
      if ([value?._id, previous?._id].includes(m.storageLocation._id)) {
        this.updateStorageLocationMarkerIcon(m);
      }
    });
  }

  private loadStorageLocationMarkers() {
    if (this.loadStorageLocationMarkersTimeout) {
      clearTimeout(this.loadStorageLocationMarkersTimeout);
    }
    this.loadStorageLocationMarkersTimeout = setTimeout(
      () => this._loadStorageLocationMarkers(),
      MAP_UPDATE_DEBOUNCE_MS,
    );
  }

  private _loadStorageLocationMarkers() {
    this._storageLocationMarkers = this.storageLocations?.map((s) => this.createStorageLocationMarker(s));
    this._loadStorageLocationCircles(true);
  }

  private loadStorageLocationCircles(shopsDidChange = false) {
    if (this.loadStorageLocationCirclesTimeout) {
      clearTimeout(this.loadStorageLocationCirclesTimeout);
    }
    this.loadStorageLocationCirclesTimeout = setTimeout(
      () => this._loadStorageLocationCircles(shopsDidChange),
      MAP_UPDATE_DEBOUNCE_MS,
    );
  }

  private _loadStorageLocationCircles(shopsDidChange = false) {
    const isObfuscating = this.isObfuscating;
    const wasObfuscating = this.wasObfuscating;

    if (isObfuscating === wasObfuscating && !shopsDidChange) {
      return; // Nothing changed
    }

    // Update storage location circles
    this._storageLocationCircles = isObfuscating
      ? this.storageLocations?.map((s) => this.createStorageLocationCircle(s))
      : [];

    // Record new obfuscation state and update view
    this.wasObfuscating = isObfuscating;
    this.cd.markForCheck();
  }

  private createStorageLocationMarker(storageLocation: BookableStorageLocation): MapMarker {
    const marker: MapMarker = {
      storageLocation,
      position: {
        lat: storageLocation.location.coordinates[1],
        lng: storageLocation.location.coordinates[0],
      },
      icon: this.getStorageLocationMarkerIcon(storageLocation),
    };
    return marker;
  }

  private createStorageLocationCircle(storageLocation: BookableStorageLocation): MapCircle {
    const zoomToRadiusFactor = this.largeStorageLocationCircles ? 8 : 4.5;
    const currentZoom = this.zoom || this.angularGoogleMap?.zoom;
    const mapCircleRadius = currentZoom * zoomToRadiusFactor;

    const circle: MapCircle = {
      center: {
        lat: storageLocation.location.coordinates[1],
        lng: storageLocation.location.coordinates[0],
      },
      radius: mapCircleRadius,
      fillColor: this.themeService.mapCircleFillColor,
      strokeColor: this.themeService.mapCircleStrokeColor,
      strokeOpacity: 0.5,
      strokeWeight: 0,
      clickable: false,
    };
    return circle;
  }

  private updateStorageLocationMarkerIcon(storageLocationId: string): void;
  private updateStorageLocationMarkerIcon(marker: MapMarker): void;
  private updateStorageLocationMarkerIcon(storageLocationIdOrMarker: string | MapMarker): void {
    let marker: MapMarker | undefined;

    if (typeof storageLocationIdOrMarker === 'string') {
      marker = this.storageLocationMarkers?.find((m) => m.storageLocation._id === storageLocationIdOrMarker);
    } else {
      marker = storageLocationIdOrMarker;
    }

    if (!marker) {
      return;
    }

    marker.icon = this.getStorageLocationMarkerIcon(marker.storageLocation);
  }

  private isActive(storageLocation: BookableStorageLocation): boolean {
    if (!storageLocation || !storageLocation.available) {
      return false;
    }

    const isHighlighted = this.highlightedStorageLocationId === storageLocation._id;
    const isOpen = this.openStorageLocation?._id === storageLocation._id;

    return isHighlighted || isOpen;
  }

  private getStorageLocationMarkerIcon(storageLocation: BookableStorageLocation): google.maps.Icon {
    const isActive = this.largeStorageLocationMarkers || this.isActive(storageLocation);
    const isBestChoice = this.isBestChoice(storageLocation);
    const isUnavailable = !storageLocation.available;
    const markerIconUrl = this.getMarkerIconUrl(isBestChoice, isUnavailable, isActive);
    const size = this.getMarkerIconSize(isBestChoice);

    return {
      url: markerIconUrl,
      size,
      scaledSize: size,
      anchor: new google.maps.Point(size.width / 2, size.height / 2),
    };
  }

  private getBestChoiceMarkerIconUrl(isUnavailable: boolean, isActive: boolean): string {
    if (isUnavailable) {
      return this.themeService.unavailableMapMarkerBestChoiceIcon;
    }
    if (isActive) {
      return this.themeService.activeMapMarkerBestChoiceIcon;
    }
    return this.themeService.mapMarkerBestChoiceIcon;
  }

  private getRegularMarkerIconUrl(isUnavailable: boolean, isActive: boolean): string {
    if (isUnavailable) {
      return this.themeService.unavailableMapMarkerIcon;
    }
    if (isActive) {
      return this.themeService.activeMapMarkerIcon;
    }

    return this.themeService.mapMarkerIcon;
  }

  private getMarkerIconUrl(isBestChoice: boolean, isUnavailable: boolean, isActive: boolean): string {
    if (isBestChoice) {
      return this.getBestChoiceMarkerIconUrl(isUnavailable, isActive);
    }

    return this.getRegularMarkerIconUrl(isUnavailable, isActive);
  }

  private getMarkerIconSize(isBestChoice: boolean): google.maps.Size {
    const factor =
      this.largeStorageLocationMarkers || isBestChoice ? 1 : AppConfig.SHOP_MAP_MARKER_SIZE_DOWNSCALE_FACTOR;

    return new google.maps.Size(
      STORAGE_LOCATION_MARKER_ICON_WIDTH * factor,
      STORAGE_LOCATION_MARKER_ICON_HEIGHT * factor,
    );
  }

  private createIconConfig(url: string, size: google.maps.Size): google.maps.Icon {
    return {
      url,
      size,
      scaledSize: size,
      anchor: new google.maps.Point(size.width / 2, size.height / 2),
    };
  }

  private updateMapOptions() {
    this._mapOptions = {
      center: this.center,
      zoom: this.zoom,
      maxZoom: this.maxZoom,
      disableDefaultUI: true,
      clickableIcons: this.clickableIcons,
      keyboardShortcuts: this.keyboardShortcuts,
      fullscreenControl: this.fullscreenControl,
      scaleControl: this.scaleControl,
      streetViewControl: this.streetViewControl,
      zoomControl: this.zoomControl,
      zoomControlOptions: this.zoomControlOptions,
      disableDoubleClickZoom: this.disableDoubleClickZoom,
      scrollwheel: this.scrollwheel,
      draggable: this.draggable,
      gestureHandling: this.gestureHandling,
      styles: this.styles,
    };
  }
}
