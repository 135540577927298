<div
  class="slider-wrapper"
  [class.primary]="mode === 'primary'"
  [class.success]="mode === 'success'"
  [class.warning]="mode === 'warning'"
  [class.disabled]="disabled && !isLoading"
  *ngIf="isTouch"
>
  <input
    id="slider"
    type="range"
    min="0"
    max="100"
    class="slider"
    [class.confirmed]="confirmed"
    [class.loading]="isLoading"
    [(ngModel)]="value"
    (touchend)="checkValue()"
    (touchcancel)="checkValue()"
    [disabled]="disabled"
  />
  <div class="slider-cta dynamic-font-size" [attr.data-content-length]="getSliderContentLength(slideCallToAction)">
    <span *ngIf="!isLoading">{{ slideCallToAction }}</span>
    <div *ngIf="isLoading">
      <lh-shared-ui-spinner [visible]="true" [darkMode]="true"></lh-shared-ui-spinner>
    </div>
  </div>
</div>

<button
  class="btn btn-lg dynamic-font-size"
  [class.btn-primary]="mode === 'primary'"
  [class.btn-success]="mode === 'success'"
  [class.btn-warning]="mode === 'warning'"
  [attr.data-content-length]="getButtonContentLength(clickCallToAction)"
  [disabled]="disabled || isLoading"
  (click)="confirm.emit()"
  *ngIf="!isTouch"
>
  {{ clickCallToAction }}
</button>
