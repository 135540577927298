<!-- Advanced country picker -->
<div *ngIf="advancedMode" class="row">
  <div class="col-12 lh-tel-input">
    <input
      class="form-control"
      type="tel"
      [(ngModel)]="phoneNumber"
      [readonly]="readonly"
      lhIntlTelInput
      [lhAutoFocus]="requestFocus"
      [intlTelInputOptions]="advancedOptions"
      (inputChange)="onIntlTelInputChange($event)"
      (focus)="onInputFocus()"
      (blur)="onInputBlur()"
      #intlTelInput
    />
  </div>
</div>

<div *ngIf="!advancedMode">
  <!-- Simple country picker -->
  <div *ngIf="countries" class="row">
    <div class="col-3 left-col">
      <select class="form-select country" name="country" [(ngModel)]="selectedDialCode">
        <option *ngFor="let country of countries" [value]="country.dialCode">
          {{ country.countryCode }}
        </option>
      </select>
    </div>
    <div class="col-9 right-col text-end">
      <span class="dial-code">{{ selectedDialCode }}</span>
      <input
        class="form-control phone-number pull-right"
        type="tel"
        [(ngModel)]="phoneNumber"
        [placeholder]="placeholder"
        [lhAutoFocus]="requestFocus"
        (focus)="onInputFocus()"
      />
    </div>
  </div>

  <!-- Plain text input -->
  <div *ngIf="!countries">
    <input class="form-control" type="tel" [(ngModel)]="value" [placeholder]="placeholder" lhAutoFocus />
  </div>
</div>
