import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  NgZone,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { FindStorageBaseComponent } from '@luggagehero/features/shops';
import { AppConfig } from '@luggagehero/shared/app-settings/data-access';
import {
  BookableStorageLocation,
  DistanceUnit,
  GeolocationState,
  IDiscount,
  ILocation,
  ILuggage,
  IPrice,
  IPricing,
  ITimePeriod,
  LatLng,
  StorageCriteria,
  WindowSizeGroup,
} from '@luggagehero/shared/interfaces';
import { SharedDistanceService } from '@luggagehero/shared/services/distance';
import { SharedDocumentService } from '@luggagehero/shared/services/document';
import { SharedGeocodingService } from '@luggagehero/shared/services/geocoding';
import { SharedGeolocationService } from '@luggagehero/shared/services/geolocation';
import { SharedLocationService } from '@luggagehero/shared/services/locations';
import { SharedLoggingService } from '@luggagehero/shared/services/logging';
import { SharedPageTaggingService } from '@luggagehero/shared/services/page-tagging';
import { SharedParamsService } from '@luggagehero/shared/services/params';
import { SharedPaymentService } from '@luggagehero/shared/services/payments';
import { SharedPricingService } from '@luggagehero/shared/services/pricing';
import { SharedShopsService } from '@luggagehero/shared/services/shops';
import { SharedStorageService } from '@luggagehero/shared/services/storage';
import { SharedStorageCriteria, SharedStorageCriteriaService } from '@luggagehero/shared/services/storage-criteria';
import { SharedUserService } from '@luggagehero/shared/services/users';
import { SharedWindowService } from '@luggagehero/shared/services/window';
import { GeoUtil } from '@luggagehero/utils';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Observable, Subscription } from 'rxjs';

import { ShopListComponent } from '../shop-list/shop-list.component';
import { ShopMapComponent } from '../shop-map/shop-map.component';

@Component({
  selector: 'lh-find-storage',
  templateUrl: './find-storage.component.html',
  styleUrls: ['./find-storage.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FindStorageComponent extends FindStorageBaseComponent implements OnInit, OnDestroy {
  @ViewChild('filterModal') public filterModal: ModalDirective;
  @ViewChild('promoCodeModal') public promoCodeModal: ModalDirective;
  @ViewChild('shopList') public shopList: ShopListComponent;
  @ViewChild('shopMap') public shopMap: ShopMapComponent;
  @ViewChild('googlePlacesInput') public googlePlacesInput: ElementRef;
  shops: BookableStorageLocation[] = [];
  visibleShops: BookableStorageLocation[] = [];
  nearestShop: BookableStorageLocation;
  initialSelectedShopId: string;
  showList = false;
  hideCriteria = false;
  isCriteriaExpanded = false;
  isNoShopsAvailable = false;
  isPromoCodeAlertDismissed = false;
  promoCode: string;
  checkedPromoCode: string;
  isCheckingPromoCode = false;
  isCheckedPromoCodeValid: boolean;
  isSavedPromoCodeValid: boolean;
  checkedDiscount: IDiscount;
  savedDiscount: IDiscount;
  isMapInitialized = false;

  private _showMap = false;
  private _mapLocation: LatLng;
  private subscriptions: Subscription[] = [];
  private geoLocationState: GeolocationState = 'unknown';
  private isFirstParamsChange = true;
  private _loadAttempted = false;
  private _isSearchOnMoveMapEnabled = false;
  private _loadFailed = false;
  private _highlightShop = '';
  private _location: ILocation;
  private _showRedoSearchButton = false;
  private _windowSize: WindowSizeGroup;
  private loadShopsByMapLocationTimeout: NodeJS.Timeout;
  private isTouch: boolean;

  constructor(
    private paymentService: SharedPaymentService,
    private storageService: SharedStorageService,
    private documentService: SharedDocumentService,
    shopsService: SharedShopsService,
    criteriaService: SharedStorageCriteriaService,
    priceService: SharedPricingService,
    private distanceService: SharedDistanceService,
    private locationService: SharedLocationService,
    private geolocationService: SharedGeolocationService,
    private tag: SharedPageTaggingService,
    private route: ActivatedRoute,
    private paramsService: SharedParamsService,
    private userService: SharedUserService,
    cd: ChangeDetectorRef,
    private log: SharedLoggingService,
    private ngZone: NgZone,
    windowService: SharedWindowService,
    private geocodeService: SharedGeocodingService,
  ) {
    super(shopsService, criteriaService, priceService, cd);

    this.documentService.addBodyClass('find-shop');
    this.subscriptions.push(windowService.size.subscribe((s) => (this.windowSize = s.sizeGroup)));
    this.isTouch = windowService.isTouch;
  }

  get filterModalSub(): ModalDirective {
    return this.filterModal;
  }

  get simpleAddPromoCodeAlert() {
    return this.isListVisible;
  }

  get distanceUnit(): Observable<DistanceUnit> {
    return this.distanceService.distanceUnit$;
  }

  get isTabbedNavigationEnabled(): boolean {
    return this.isTouch && AppConfig.IS_TABBED_NAVIGATION_ENABLED;
  }

  get windowSize(): WindowSizeGroup {
    return this._windowSize;
  }
  set windowSize(value: WindowSizeGroup) {
    if (this._windowSize === value) {
      return; // No change
    }
    this._windowSize = value;

    switch (value) {
      case 'xs':
      case 'sm':
        this.showList = false;
        this.showMap = true;
        this.hideCriteria = true;
        break;

      case 'md':
        this.showList = true;
        this.showMap = false;
        this.hideCriteria = true;
        break;

      case 'lg':
        this.showList = true;
        this.showMap = true;
        this.hideCriteria = false;
        break;
    }
    this.cd.markForCheck();
  }

  get isSmallScreen(): boolean {
    return ['xs', 'sm'].includes(this.windowSize);
  }

  get criteria(): StorageCriteria {
    return this.criteriaService.current;
  }
  set criteria(value: StorageCriteria) {
    if (!value) {
      return;
    }
    this.criteriaService.current = value;
    // this.tag.addFindShopTags(value);
    this.cd.markForCheck();

    this.location = value ? value.location : null;
    this.showRedoSearchButton = !this.isSearchOnMoveMapEnabled && this.isMapLocationChanged;
  }

  get location(): ILocation {
    return this._location;
  }
  set location(value: ILocation) {
    this._location = value;
    this.cd.markForCheck();
  }

  get estimatedPrice(): IPrice {
    return this.shopsService.latestPrice ? this.shopsService.latestPrice.estimated : null;
  }

  get pricing(): IPricing {
    return this.shopsService.latestPrice ? this.shopsService.latestPrice.pricings[0] : null;
  }

  set highlightShop(val: string) {
    this._highlightShop = val;
    this.cd.markForCheck();

    // Fixes map pins not highlighting
    this.cd.detectChanges();
  }
  get highlightShop() {
    return this._highlightShop;
  }

  get loadAttempted(): boolean {
    return this._loadAttempted;
  }
  set loadAttempted(value: boolean) {
    this._loadAttempted = value;
    this.cd.markForCheck();
  }

  get isLoading(): boolean {
    return this._isLoading;
  }
  set isLoading(value: boolean) {
    this._isLoading = value;
    this.cd.markForCheck();
  }

  get loadFailed(): boolean {
    return this._loadFailed;
  }
  set loadFailed(value: boolean) {
    this._loadFailed = value;
    this.cd.markForCheck();
  }

  get isSearchOnMoveMapEnabled(): boolean {
    return this._isSearchOnMoveMapEnabled;
  }
  set isSearchOnMoveMapEnabled(value: boolean) {
    this._isSearchOnMoveMapEnabled = value;
    this.cd.markForCheck();

    this.showRedoSearchButton = !this.isSearchOnMoveMapEnabled && this.isMapLocationChanged;
  }

  get mapLocation(): LatLng {
    return this._mapLocation;
  }
  set mapLocation(value: LatLng) {
    this._mapLocation = value;
    this.cd.markForCheck();

    this.showRedoSearchButton = !this.isSearchOnMoveMapEnabled && this.isMapLocationChanged;
  }

  get showMap(): boolean {
    return this._showMap;
  }
  set showMap(value: boolean) {
    if (value) {
      this.isMapInitialized = true;
    }
    this._showMap = value;
    this.cd.markForCheck();
  }

  get isMapVisible(): boolean {
    return this.showMap && !this.isLoading && !this.loadFailed;
  }

  get isListVisible(): boolean {
    if (this.windowSize === 'lg') {
      return true;
    }
    return !this.showMap && !this.isLoading && !this.loadFailed;
  }

  get showRedoSearchButton(): boolean {
    return this._showRedoSearchButton;
  }
  set showRedoSearchButton(value: boolean) {
    this._showRedoSearchButton = value;
    this.cd.markForCheck();
  }

  get isMapLocationChanged(): boolean {
    if (!this.criteria) {
      return false;
    }
    const currentLocation: LatLng = {
      lat: this.criteria.location.lat,
      lng: this.criteria.location.lon,
    };
    return GeoUtil.isDistanceGreaterThan(currentLocation, this.mapLocation, this.mapIgnoreThreshold);
  }

  get mapIgnoreThreshold(): number {
    return AppConfig.MAP_MOVE_IGNORE_THRESHOLD;
  }

  get canSavePromoCode(): boolean {
    if (this.isCheckingPromoCode) {
      return false;
    }
    if (!this.promoCode) {
      return false;
    }
    if (this.promoCode === this.savedPromoCode) {
      return false;
    }
    if (this.promoCode !== this.checkedPromoCode) {
      return false;
    }
    return this.isCheckedPromoCodeValid;
  }

  get canCheckPromoCode(): boolean {
    if (!this.promoCode) {
      return false;
    }
    if (this.promoCode === this.checkedPromoCode) {
      return false;
    }
    return true;
  }

  get canRemovePromoCode(): boolean {
    if (this.isCheckingPromoCode) {
      return false;
    }
    if (!this.savedPromoCode) {
      return false;
    }
    if (this.savedPromoCode !== this.promoCode) {
      return false;
    }
    if (this.savedPromoCode !== this.checkedPromoCode) {
      return false;
    }
    return true;
  }

  get showCheckedPromoCodeInvalid(): boolean {
    if (this.isCheckingPromoCode) {
      return false;
    }
    if (this.isCheckedPromoCodeValid !== false) {
      return false;
    }
    if (this.promoCode !== this.checkedPromoCode) {
      return false;
    }
    return true;
  }

  get showCheckedPromoCodeValid(): boolean {
    if (this.isCheckingPromoCode) {
      return false;
    }
    if (!this.checkedPromoCode) {
      return false;
    }
    if (this.isCheckedPromoCodeValid !== true) {
      return false;
    }
    if (this.promoCode !== this.checkedPromoCode) {
      return false;
    }
    if (!this.checkedDiscount) {
      return false;
    }
    return true;
  }

  get showAddPromoCodeAlert(): boolean {
    if (!AppConfig.IS_PROMO_CODE_ALERT_ENABLED) {
      return false;
    }
    if (this.savedPromoCode) {
      return false;
    }
    return true;
  }

  get showValidPromoCodeAlert(): boolean {
    if (!this.savedPromoCode) {
      return false;
    }
    if (!this.checkedPromoCode) {
      return false;
    }
    if (!this.isSavedPromoCodeValid) {
      return false;
    }
    if (!this.criteria) {
      return false;
    }
    return true;
  }

  get showInvalidPromoCodeAlert(): boolean {
    if (this.isCheckingPromoCode) {
      return false;
    }
    if (!this.savedPromoCode) {
      return false;
    }
    if (!this.checkedPromoCode) {
      return false;
    }
    if (this.isSavedPromoCodeValid) {
      return false;
    }
    return true;
  }

  get savedPromoCode(): string {
    return this.storageService.promoCode;
  }
  set savedPromoCode(value: string) {
    this.storageService.promoCode = value;
    this.cd.markForCheck();
  }

  get isAlertAllowed(): boolean {
    return this.criteria && !this.isLoading && !this.isPromoCodeAlertDismissed;
  }

  get isAlertVisible(): boolean {
    if (!this.isAlertAllowed) {
      return false;
    }
    return this.showAddPromoCodeAlert || this.showValidPromoCodeAlert || this.showInvalidPromoCodeAlert;
  }

  ngOnInit() {
    this.userService.enableUserTracking();

    this.subscriptions.push(
      this.route.queryParams.subscribe((params) => void this.onQueryParamsChange(params)),
      this.route.params.subscribe((params) => void this.onParamsChange(params)),
      this.criteriaService.change.subscribe((criteria) => void this.onCriteriaChange(criteria)),
      this.criteriaService.changeRequested.subscribe((criteria) => this.onCriteriaChangeRequested(criteria)),
      // this.script.load('sumo').subscribe(),
    );

    this.tag.disableCrawling();

    // Fixes some issues with UI not updating
    this.ngZone.run(() => this.cd.detectChanges());
  }

  ngOnDestroy() {
    try {
      this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    } catch {
      // Ignore
    }

    this.documentService.removeBodyClass('find-shop');
    this.tag.clearTags();
  }

  onCriteriaChangeRequested(_criteria: StorageCriteria) {
    this.isLoading = true;
  }

  async onCriteriaChange(criteria: StorageCriteria) {
    try {
      if (!criteria) {
        return;
      }
      if (!this.locationService.isInitialized) {
        // Only get shops once official locations have been loaded
        return;
      }
      if (criteria.equals(this.currentCriteria)) {
        // Criteria is the same as was used to get the current result
        return;
      }

      this.nearestShop = null;
      this.isLoading = true;
      this.loadAttempted = true;
      try {
        const shops = await this.shopsService.listShops(this.criteria);
        this.processAndDisplayShops(shops, this.criteria);
        this.loadFailed = false;
      } catch (err) {
        this.loadFailed = true;
      }
    } finally {
      this.isLoading = false;
    }
  }

  onMapLocationChange(location: LatLng) {
    this.mapLocation = location;

    if (this.isSearchOnMoveMapEnabled) {
      this.loadShopsByMapLocation();
    }
  }

  loadShopsByMapLocation() {
    if (this.loadShopsByMapLocationTimeout) {
      clearTimeout(this.loadShopsByMapLocationTimeout);
    }
    this.loadShopsByMapLocationTimeout = setTimeout(() => this._loadShopsByMapLocation(), 1000);
  }

  _loadShopsByMapLocation() {
    if (!this.mapLocation) {
      return;
    }

    void this.geocodeService
      .requestReverseGeoCodeForLatLong(this.mapLocation.lat, this.mapLocation.lng, 'FindStorageWeb')
      .then((place) => {
        if (place) {
          const location: ILocation = {
            lat: this.mapLocation.lat,
            lon: this.mapLocation.lng,
            region: place.address.region,
            address: place.name,
            zoom: this.criteria.location.zoom,
            radius: this.criteria.location.radius,
            type: 'custom',
          };
          this.criteria = new SharedStorageCriteria(location, this.criteria.period, this.criteria.luggage);
        }
      });
  }

  // Called when the user explicitly requests geolocation
  async loadUserLocation() {
    this.isLoading = true;

    try {
      const location = await this.locationService.getUserLocation(true);
      if (location) {
        this.criteria = new SharedStorageCriteria(location, this.criteria.period, this.criteria.luggage);
      }
    } catch {
      // Ignore
    }
    this.isLoading = false;
  }

  applyFilters() {
    this.visibleShops = this.shops.filter((shop) => {
      if (this.hideUnavailable && !shop.available) {
        return false;
      }
      if (this.showOnlyShopsWithWifi && !shop.hasWifi) {
        return false;
      }
      return true;
    });
    this.isNoShopsAvailable = this.visibleShops.length === 0;
    this.filterModal.hide();
    this.cd.markForCheck();

    setTimeout(() => {
      // Select the nearest shop after a delay
      this.nearestShop = this.visibleShops.length > 0 ? this.visibleShops[0] : null;
      this.cd.markForCheck();
    }, 3000);
  }

  setHoverShopId(shopId: string) {
    this.highlightShop = shopId;
  }

  toggleCriteria() {
    this.hideCriteria = !this.hideCriteria;
    this.cd.markForCheck();
  }

  toggleDistanceUnit() {
    if (this.distanceService.distanceUnit === 'metric') {
      this.distanceService.changeDistanceUnit('imperial');
    } else {
      this.distanceService.changeDistanceUnit('metric');
    }
  }

  showView(view: string) {
    switch (view) {
      case 'map':
        this.showMap = true;
        this.showList = false;
        break;

      case 'list':
        this.showMap = false;
        this.showList = true;
        break;

      default:
        this.showMap = true;
        this.showList = true;
        break;
    }
    this.cd.markForCheck();
  }

  toggleMap() {
    this.showMap = !this.showMap;
    this.cd.markForCheck();
  }

  expandCriteria() {
    this.isCriteriaExpanded = true;
    this.cd.markForCheck();
  }

  collapseCriteria() {
    this.isCriteriaExpanded = false;
    this.cd.markForCheck();
  }

  showPromoCodeModal() {
    // Reset states
    this.promoCode = this.savedPromoCode;
    this.checkedPromoCode = this.savedPromoCode;
    this.checkedDiscount = this.savedDiscount;
    this.isCheckedPromoCodeValid = this.isSavedPromoCodeValid;

    this.promoCodeModal.show();
  }

  async checkPromoCode() {
    this.isCheckingPromoCode = true;
    this.cd.markForCheck();

    // Ensure that the spinner is shown for a meaningful amount of time
    const minimumDelay = new Promise((resolve) => setTimeout(resolve, 1000));

    if (this.promoCode) {
      this.promoCode = this.promoCode.toUpperCase();
    }
    this.checkedPromoCode = this.promoCode;
    try {
      this.checkedDiscount = await this.paymentService.getDiscount(this.promoCode);
    } catch {
      // TODO: Handle error
    }
    this.isCheckedPromoCodeValid = this.checkedDiscount && this.checkedDiscount.active ? true : false;

    await minimumDelay;

    this.isCheckingPromoCode = false;
    this.cd.markForCheck();
  }

  savePromoCode() {
    if (this.canSavePromoCode) {
      this.savedPromoCode = this.checkedPromoCode;
      this.savedDiscount = this.checkedDiscount;
      this.isSavedPromoCodeValid = this.isCheckedPromoCodeValid;
    }
    this.promoCodeModal.hide();
    this.cd.markForCheck();
  }

  cancelPromoCode() {
    this.promoCodeModal.hide();
    this.promoCode = this.savedPromoCode;
    this.cd.markForCheck();
  }

  removePromoCode() {
    this.promoCode = '';

    this.savedPromoCode = '';
    this.savedDiscount = undefined;
    this.isSavedPromoCodeValid = undefined;

    this.checkedPromoCode = '';
    this.checkedDiscount = undefined;
    this.isCheckedPromoCodeValid = undefined;

    this.cd.markForCheck();
  }

  dismissPromoCodeAlert() {
    this.isPromoCodeAlertDismissed = true;
    this.cd.markForCheck();
  }

  private async onQueryParamsChange(_params: Params) {
    if (this.savedPromoCode) {
      this.promoCode = this.savedPromoCode;

      await this.checkPromoCode();

      this.isSavedPromoCodeValid = this.isCheckedPromoCodeValid;
      this.savedDiscount = this.checkedDiscount;
    }
  }

  private async onParamsChange(params: Params) {
    if (!this.isFirstParamsChange) {
      return;
    }
    this.isFirstParamsChange = false;

    this.geoLocationState = await this.geolocationService.queryGeolocationState();

    const findShopParams = await this.paramsService.parseFindShop(params);

    if (findShopParams) {
      if (findShopParams.showMap && !this.showMap) {
        this.showView('map');
      }
      if (findShopParams.geolocate) {
        void this.loadShops(findShopParams.criteria);
      } else {
        this.criteria = findShopParams.criteria;
      }
      if (findShopParams.expand) {
        this.expandCriteria();
      }
      this.showUncertified = findShopParams.showUncertified;
      this.initialSelectedShopId = findShopParams.selectedShop;
    } else {
      // No valid params provided
      void this.loadShops(this.criteriaService.currentOrDefault);
    }
  }

  private async loadShops(criteria: StorageCriteria) {
    void this.log.debug(`Loading shops with geolocation state '${this.geoLocationState}'`);

    this.nearestShop = null;
    this.isLoading = true;

    if (this.geoLocationState === 'granted') {
      try {
        const success = await this.loadShopsByUserLocation(criteria.period, criteria.luggage);
        if (!success) {
          // No shops near the user's current location
          this.criteria = criteria;
        }
        this.isLoading = false;
      } catch (err) {
        this.criteria = criteria;
        this.isLoading = false;
      }
    } else {
      // Use what criteria we have now to guarantee a result
      this.criteria = criteria;
      this.isLoading = false;

      if (this.geoLocationState !== 'denied') {
        // Try to load shops by the user's location
        await this.loadShopsByUserLocation(criteria.period, criteria.luggage);
      }
    }
  }

  private async loadShopsByUserLocation(period: ITimePeriod, luggage: ILuggage): Promise<boolean> {
    const location = await this.locationService.getUserLocation(false);
    if (!location) {
      return false;
    }

    const criteria = new SharedStorageCriteria(location, period, luggage);
    this.loadAttempted = true;

    const shops = await this.shopsService.listShops(criteria);

    const success = this.getAvailableShopCount(shops, criteria) > 0;
    if (success) {
      this.criteria = criteria;
      this.processAndDisplayShops(shops, criteria);
      this.loadFailed = false;
    }
    return success;
  }

  private getAvailableShopCount(shops: BookableStorageLocation[], criteria: StorageCriteria): number {
    if (!shops) {
      return 0;
    }
    let availableCount = 0;
    shops.forEach((shop) => {
      if (this.shopsService.isShopAvailable(shop, criteria)) {
        availableCount++;
      }
    });
    return availableCount;
  }
}
