/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  cloneDeep as cloneDeepImpl,
  debounce as debounceImpl,
  isEqual as isEqualImpl,
  merge as mergeImpl,
} from 'lodash-es';

export function cloneDeep<T>(value: T): T {
  return cloneDeepImpl(value);
}

export interface DebouncedFunc<T extends (...args: unknown[]) => unknown> {
  (...args: Parameters<T>): ReturnType<T> | undefined;
  cancel(): void;
  flush(): ReturnType<T> | undefined;
}

export function debounce<T extends (...args: unknown[]) => unknown>(func: T, wait: number | undefined) {
  return debounceImpl(func, wait);
}

export function isEqual<T = unknown>(value: T, other: T): boolean {
  return isEqualImpl(value, other);
}

export function merge<TObject, TSource>(object: TObject, source: TSource): TObject & TSource;
export function merge<TObject, TSource1, TSource2>(
  object: TObject,
  source1: TSource1,
  source2: TSource2,
): TObject & TSource1 & TSource2;
export function merge<TObject, TSource1, TSource2, TSource3>(
  object: TObject,
  source1: TSource1,
  source2: TSource2,
  source3: TSource3,
): TObject & TSource1 & TSource2 & TSource3;
export function merge<TObject, TSource1, TSource2, TSource3, TSource4>(
  object: TObject,
  source1: TSource1,
  source2: TSource2,
  source3: TSource3,
  source4: TSource4,
): TObject & TSource1 & TSource2 & TSource3 & TSource4;
export function merge(object: any, ...otherArgs: any[]): any;
export function merge(object: any, ...otherArgs: any[]): any {
  return mergeImpl(object, otherArgs);
}
