<div class="availability-display">
  @if (isOpen) {
    <!-- Open state -->
    <div class="status">
      @if (!availability.isFullyBooked) {
        <div class="circle bg-success-darker"></div>
        <div class="text-success-darker">{{ getTranslatedText('OPEN') }}</div>
      } @else {
        <div class="circle bg-danger"></div>
        <div class="text-danger">{{ getTranslatedText('FULLY_BOOKED') }}</div>
      }
    </div>

    <!-- Open 24 hours vs. limited open hours -->
    @if (availability.isOpenAllDay && !availability.isFullyBooked) {
      <div>24/7</div>
    } @else if (!availability.isOpenAllDay && !availability.isFullyBooked) {
      <div>
        <span class="text-lighter">{{ getTranslatedText('CLOSES_AT') }}</span>
        {{ availability.closeTime }}
      </div>
    }
  }

  @if (!isOpen && availability.isForCurrentDay) {
    <!-- Closed state for current day -->
    <div class="status">
      <div class="circle bg-danger"></div>
      <div class="text-danger">{{ getTranslatedText('CLOSED') }}</div>
    </div>
    @if (!availability.isPermanentlyClosed) {
      <div>
        <span class="text-lighter">{{ getTranslatedText('OPENS_AT') }}</span>
        {{ availability.openTime }}
      </div>
    }
  }

  @if (
    !availability.isForCurrentDay &&
    !availability.isPermanentlyClosed &&
    !availability.isOpenAllDay &&
    !availability.isFullyBooked
  ) {
    <!-- For days other than the current day with specific open/close times -->
    <div class="status">
      <div>{{ getTranslatedText('OPEN') }}</div>
    </div>
    <div>{{ availability.openTime }} – {{ availability.closeTime }}</div>
  }

  @if (
    availability.isOpenAllDay &&
    !availability.isFullyBooked &&
    !availability.isForCurrentDay &&
    !availability.isPermanentlyClosed
  ) {
    <!-- For days other than current day that are open 24/7 -->
    <div class="status">
      <div>{{ getTranslatedText('OPEN') }}</div>
    </div>
    <div>24/7</div>
  }

  @if (
    !availability.isForCurrentDay &&
    (availability.isFullyBooked ||
      availability.isPermanentlyClosed ||
      (!availability.openTime && !availability.closeTime))
  ) {
    <!-- For days other than the current day where there’s either a booking or closure issue -->
    @if (availability.isFullyBooked) {
      <div class="status">
        <div class="text-danger">{{ getTranslatedText('FULLY_BOOKED') }}</div>
      </div>
    }
    @if (availability.isPermanentlyClosed) {
      <div class="status">
        <div class="text-danger">{{ getTranslatedText('CLOSED') }}</div>
      </div>
    }
  }
</div>
