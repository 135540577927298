import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';
import { SharedDirectiveBackgroundImageDirective } from '@luggagehero/shared/ui-directives';
import { TranslatePipe } from '@luggagehero/shared/ui-pipes';
import { SharedUtilFile } from '@luggagehero/shared/util';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { DOC_ORIENTATION, NgxImageCompressService } from 'ngx-image-compress';
import { WebcamComponent, WebcamImage, WebcamInitError, WebcamModule } from 'ngx-webcam';
import { Subject } from 'rxjs';

import { SharedUiSpinnerComponent } from '../spinner/shared-ui-spinner.component';
@Component({
  selector: 'lh-shared-ui-image-input',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    WebcamModule,
    SharedUiSpinnerComponent,
    SharedDirectiveBackgroundImageDirective,
    TranslatePipe,
  ],
  templateUrl: './shared-ui-image-input.component.html',
  styleUrl: './shared-ui-image-input.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SharedUiImageInputComponent implements OnInit, OnDestroy {
  public destroy$: Subject<unknown> = new Subject();

  private _cd = inject(ChangeDetectorRef);
  private _translateService = inject(TranslateService);

  get currentLang(): string {
    return this._translateService.currentLang;
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  protected _detectChanges(): void {
    this._cd.detectChanges();
  }

  protected _markForCheck(): void {
    this._cd.markForCheck();
  }

  protected _translate(key: string | string[], params?: object): string {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return this._translateService.instant(key, params);
  }

  @ViewChild('camera') public camera!: WebcamComponent;
  @Output() public mediaAccessChange = new EventEmitter<boolean>();
  @Output() public localImageChange = new EventEmitter<string | null | undefined>();
  @Output() public isTakingPhotoChange = new EventEmitter<boolean>();
  @Input() public callToAction?: string;

  private static readonly MAX_CAMERA_NOT_ALLOWED_ERROR_COUNT = 2;

  // TODO: Get from AppConfig
  public readonly allowedMimeTypes = ['image/png', 'image/jpg', 'image/jpeg', 'image/gif'];
  @Input() public isEmbeddedCameraEnabled = true;
  @Input() public isCameraSwitchingEnabled = false;
  @Input() public isCameraLoaderEnabled = false;
  @Input() public isImageCompressionEnabled = true;
  @Input() public imageCompressionQuality = 90;
  @Input() public imageCompressionRatio = 100;
  @Input() public imageCompressionMaxWidth = 1800;
  @Input() public imageCompressionMaxHeight: number | undefined;
  @Input() public baseUrl = '';

  private imageCompressService = inject(NgxImageCompressService);
  private cd = inject(ChangeDetectorRef);

  private isCompressing = false;
  private _loadingMessage?: string;
  private _localImage?: string | null | undefined;
  private _remoteImage?: string | null | undefined;
  private _imagePlaceholder?: string;
  private _isTakingPhoto = true;
  private _promoteRetake = false;
  private _isCameraInitialized = false;
  private _mediaAccess = true;
  private _isLoading = false;
  private _cameraNotAllowedErrorCount = 0;
  private fileInputClickedAt?: number;
  private fileInputCanceledAt?: number | null;
  private cameraAccessCheckTimeout?: ReturnType<typeof setTimeout>;

  public async onFileSelected(event: Event) {
    const inputElement = event.target as HTMLInputElement;

    if (inputElement.files) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
      const dataUrl: string = await SharedUtilFile.blobToDataURI(inputElement.files[0]);
      void this.onImageSelected(dataUrl);
    }
  }

  public onFileInputClick(_event: Event) {
    this.fileInputClickedAt = Date.now();
    this.fileInputCanceledAt = null;
    this.checkCameraAccess();
  }

  public onFileInputCancel(_event: Event) {
    this.fileInputCanceledAt = Date.now();
    this.checkCameraAccess();
  }

  private checkCameraAccess() {
    clearTimeout(this.cameraAccessCheckTimeout);
    this.cameraAccessCheckTimeout = setTimeout(() => this._checkCameraAccess(), 300);
  }

  private _checkCameraAccess() {
    if (typeof this.fileInputClickedAt !== 'number' || typeof this.fileInputCanceledAt !== 'number') {
      return;
    }

    const timeBetweenClickAndCancel = this.fileInputCanceledAt - this.fileInputClickedAt;
    if (timeBetweenClickAndCancel < 1000) {
      // Likely a camera access denied error if the cancel event happens almost immediately after the click event
      this.mediaAccess = false;
    }
  }

  // End of web

  // Mobile
  public async takePicture() {
    const image = await Camera.getPhoto({
      quality: this.imageCompressionQuality,
      allowEditing: true,
      resultType: CameraResultType.DataUrl,
      saveToGallery: true,
      source: CameraSource.Camera,
    });
    void this.onImageSelected(image.dataUrl);
  }
  // end of mobile

  public get isCameraInitialized(): boolean {
    return this._isCameraInitialized;
  }
  @Input() public set isCameraInitialized(value: boolean) {
    this._isCameraInitialized = value;
    this.cd.markForCheck();
  }

  public get isTakingPhoto(): boolean {
    return this._isTakingPhoto;
  }
  @Input() public set isTakingPhoto(value: boolean) {
    this._isTakingPhoto = value;
    this.isTakingPhotoChange.emit(value);

    this.isCameraInitialized = false;
  }

  public get promoteRetake(): boolean {
    return this._promoteRetake;
  }
  @Input() public set promoteRetake(value: boolean) {
    this._promoteRetake = value;
    this.cd.markForCheck();
  }

  public get loadingMessage(): string | undefined {
    if (this.isCompressing) {
      return 'PROCESSING_IMAGE_LONG';
    }
    return this._loadingMessage;
  }
  @Input() public set loadingMessage(value: string) {
    this._loadingMessage = value;
    this.cd.markForCheck();
  }

  public get localImage(): string | null | undefined {
    return this._localImage;
  }

  @Input() public set localImage(value: string | null | undefined) {
    this._localImage = value;
    this.cd.markForCheck();

    this.localImageChange.emit(value);
  }

  public get remoteImage(): string | null | undefined {
    return this._remoteImage;
  }

  @Input() public set remoteImage(value: string | null | undefined) {
    this._remoteImage = value;
    this.cd.markForCheck();
  }

  public get imagePlaceholder(): string | undefined {
    return this._imagePlaceholder;
  }
  @Input() public set imagePlaceholder(value: string) {
    this._imagePlaceholder = value;
    this.cd.markForCheck();
  }

  get isLoading(): boolean {
    return this._isLoading;
  }
  @Input() set isLoading(value: boolean) {
    this._isLoading = value;
    this.cd.markForCheck();
  }

  public get mediaAccess(): boolean {
    return this._mediaAccess;
  }
  public set mediaAccess(value: boolean) {
    this._mediaAccess = value;
    this.cd.markForCheck();

    this.mediaAccessChange.emit(this.mediaAccess);
  }

  get image(): string | null | undefined {
    if (this.isLoading) {
      return null;
    }
    if (this.localImage) {
      return this.localImage;
    }
    if (this.remoteImage) {
      return this.remoteImage;
    }
    return this.imagePlaceholder;
  }

  public ngOnInit() {
    if (this.remoteImage) {
      this.isTakingPhoto = false;
    }
  }

  public onCameraInitSuccess() {
    this._cameraNotAllowedErrorCount = 0;
    this._isCameraInitialized = true;
    this.cd.markForCheck();
  }

  public onCameraInitError(error: WebcamInitError) {
    if (error.mediaStreamError && error.mediaStreamError.name === 'NotAllowedError') {
      // The user has not given us permission to access the camera
      this._cameraNotAllowedErrorCount++;
    } else {
      // For unexpected errors, immediately disable camera
      this.isEmbeddedCameraEnabled = false;
    }

    if (
      this._cameraNotAllowedErrorCount > SharedUiImageInputComponent.MAX_CAMERA_NOT_ALLOWED_ERROR_COUNT &&
      this.isEmbeddedCameraEnabled
    ) {
      // The second time the camera initialization fails due to lack of permission, disable and fall back to file input
      this.isEmbeddedCameraEnabled = false;
    } else {
      this.mediaAccess = false;
    }
  }

  public onCameraImageCapture(image: WebcamImage) {
    this.isTakingPhoto = false;
    this.cd.markForCheck();

    const dataUrl = image.imageAsDataUrl;
    void this.onImageSelected(dataUrl);
  }

  public async onImageSelected(dataUrl: string | undefined) {
    if (this.isImageCompressionEnabled && dataUrl != null) {
      dataUrl = await this.tryCompressImage(dataUrl);
    }
    this.localImage = dataUrl;
    this.isTakingPhoto = false;
  }

  public takePhoto() {
    this.camera?.takeSnapshot();
  }

  public retakePhoto() {
    this.remoteImage = null;
    this.localImage = null;
    this.isTakingPhoto = true;
  }

  public reset() {
    this.mediaAccess = true;
    this.cd.markForCheck();
  }

  private async tryCompressImage(dataUrl: string): Promise<string> {
    this.isCompressing = true;
    this.isLoading = true;

    try {
      dataUrl = await this.imageCompressService.compressFile(
        dataUrl,
        DOC_ORIENTATION.Default,
        this.imageCompressionRatio,
        this.imageCompressionQuality,
        this.imageCompressionMaxWidth,
        this.imageCompressionMaxHeight,
      );
    } catch (err) {
      console.error(`Error compressing image`, err);
    } finally {
      this.isCompressing = false;
      this.isLoading = false;
    }
    return dataUrl;
  }
}
