import { ITimeInterval } from '@luggagehero/shared/interfaces';

export abstract class SharedUtilTimeInterval {
  static copy(value: ITimeInterval[]): ITimeInterval[] {
    if (!value) {
      return value;
    }
    const copy: ITimeInterval[] = [];
    for (const timeInterval of value) {
      copy.push({ from: timeInterval.from, to: timeInterval.to });
    }
    return copy;
  }

  static isValid(value: ITimeInterval): boolean {
    if (value === null) {
      // Indicates that opening hours will be inherited
      return true;
    }
    // TODO: Validate range of to and from
    if (value.from && value.to) {
      return value && value.from >= 0 && value.to >= 0;
    }

    return false;
  }

  static is24Hours(value: ITimeInterval): boolean {
    if (!value === null) {
      return false;
    }
    return value && value.from === 0 && value.to === 1440;
  }
}
