import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import {
  InitiatePaymentResult,
  IPaymentRecord,
  IStripePaymentIntentResult,
  IStripeSetupIntentResult,
} from '@luggagehero/shared/interfaces';
import { SharedLoadingService } from '@luggagehero/shared/services/loading';
import { SharedNotificationService } from '@luggagehero/shared/services/notification';
import { SharedPaymentService } from '@luggagehero/shared/services/payments';
import { SharedUserService } from '@luggagehero/shared/services/users';
import { SharedFeaturePaymentCardInputComponent } from '@luggagehero/shared-feature-payment-card-input';
import { SharedFeaturePaymentMethodPickerComponent } from '@luggagehero/shared-feature-payment-method-picker';
import { Subscription } from 'rxjs';

const isCardPaymentMethod = (value: IPaymentRecord): value is IPaymentRecord<{ type: 'card' }> => {
  if (!value || !value.data) {
    return false;
  }
  return typeof value.data === 'object' && 'type' in value.data && value.data.type === 'card' ? true : false;
};

const isSetupIntentResult = (
  value: IStripePaymentIntentResult | IStripeSetupIntentResult,
): value is IStripeSetupIntentResult => {
  return 'setupIntent' in value && value.setupIntent ? true : false;
};

@Component({
  selector: 'lh-shared-feature-select-payment-method',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    SharedFeaturePaymentCardInputComponent,
    SharedFeaturePaymentMethodPickerComponent,
  ],
  templateUrl: './shared-feature-select-payment-method.component.html',
  styleUrl: './shared-feature-select-payment-method.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SharedFeatureSelectPaymentMethodComponent implements OnInit, OnDestroy {
  @ViewChild('paymentCardInput') public paymentCardInput: SharedFeaturePaymentCardInputComponent;

  @Output() public readonly paymentMethodSubmit = new EventEmitter<IPaymentRecord>();
  @Output() public readonly canSubmitChange = new EventEmitter<boolean>();
  @Output() public readonly selectedPaymentMethodChange = new EventEmitter<IPaymentRecord>();

  public paymentMethods: IPaymentRecord[];
  public isPaymentCardInputVisible = false;

  private subscriptions = new Subscription();
  private _payment: IPaymentRecord<InitiatePaymentResult>;
  private _selectedPaymentMethod: IPaymentRecord;
  private _canConfirm = false;
  private _isLoggedIn = false;

  private readonly paymentService = inject(SharedPaymentService);
  private readonly userService = inject(SharedUserService);
  private readonly notificationService = inject(SharedNotificationService);
  private readonly loader = inject(SharedLoadingService);
  private readonly cd = inject(ChangeDetectorRef);

  public get payment(): IPaymentRecord<InitiatePaymentResult> {
    return this._payment;
  }
  @Input() public set payment(value: IPaymentRecord<InitiatePaymentResult>) {
    this._payment = value;
    this.cd.markForCheck();
  }

  public get selectedPaymentMethod(): IPaymentRecord {
    return this._selectedPaymentMethod;
  }
  @Input() public set selectedPaymentMethod(value: IPaymentRecord) {
    this._selectedPaymentMethod = value;
    this.cd.markForCheck();
    this.selectedPaymentMethodChange.emit(value);
  }

  public get canConfirm(): boolean {
    return this._canConfirm;
  }
  private set canConfirm(value: boolean) {
    this._canConfirm = value;
    this.cd.markForCheck();
    this.canSubmitChange.emit(value);
  }

  public get isLoggedIn(): boolean {
    return this._isLoggedIn;
  }
  private set isLoggedIn(value: boolean) {
    this._isLoggedIn = value;
    this.cd.markForCheck();
  }

  public ngOnInit(): void {
    this.subscriptions.add(
      this.userService.loginAnnounced.subscribe(() => {
        this.isLoggedIn = this.userService.isLoggedIn;
        void this.loadPaymentMethods();

        if (this.isLoggedIn) {
          this.canConfirm = true;
        } else {
          this.canConfirm = false;
        }
      }),
    );
  }

  public ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  public onSelectedPaymentMethodChange(value: IPaymentRecord): void {
    this.isPaymentCardInputVisible = value ? false : true;
    this.selectedPaymentMethod = value;
  }

  public onPaymentCardInputCanSubmitChange(value: boolean) {
    this.canConfirm = value;
  }

  public onPaymentCardResult(result: IStripeSetupIntentResult | IStripePaymentIntentResult) {
    if (result.error) {
      this.notificationService.warning(result.error.message);
      return;
    }

    const intent = isSetupIntentResult(result) ? result.setupIntent : result.paymentIntent;
    this.paymentMethodSubmit.emit({ provider: 'stripe', data: intent });
  }

  public confirm(): void {
    if (this.isPaymentCardInputVisible && this.paymentCardInput.canSubmit) {
      this.paymentCardInput.submit();
    } else {
      this.canSubmitChange.emit(false);
    }
  }

  private async loadPaymentMethods(): Promise<void> {
    if (!this.userService.isLoggedIn) {
      // Clear payment methods if the user is not logged in
      this.paymentMethods = [];
      return;
    }

    this.paymentMethods = await this.loader.load(() =>
      this.paymentService.getPaymentMethods().then((res) => res.filter((pm) => isCardPaymentMethod(pm))),
    );
  }
}
