import { Type } from '@angular/core';
import { AppConfig } from '@luggagehero/shared/app-settings/data-access';
import { Booking } from '@luggagehero/shared/interfaces';
import { SharedUserService } from '@luggagehero/shared/services/users';

import { ManageBookingBaseComponent } from '../manage-booking.base-component';
import { BookingTimelineStrategy, DefaultBookingTimelineStrategy } from './booking-timeline';
import { IManageBookingStrategy } from './manage-booking/manage-booking.strategy';

export type BookingFlow = 'default' | 'payment_first';

export abstract class BookingStrategyFactory {
  private static manageBookingStrategies: { [flow: string]: Type<IManageBookingStrategy> } = {};

  static createTimelineStrategy(booking: Booking): BookingTimelineStrategy {
    let strategy: BookingTimelineStrategy;

    // TODO: Dynamically create strategy instance by name
    switch (booking.strategy) {
      // Add additional strategies here
      // case SomeTimelineStrategy.id:
      //   strategy = new SomeTimelineStrategy();
      //   break;

      case DefaultBookingTimelineStrategy.id:
      default:
        strategy = new DefaultBookingTimelineStrategy();
        break;
    }

    return strategy;
  }

  static pickStrategy(variant?: string): string {
    switch (variant) {
      // Add additional variants here
      // case AppConfig.EXPERIMENT_VARIANTS.someVariant:
      //   return SomeTimelineStrategy.id;

      case AppConfig.EXPERIMENT_VARIANTS.original:
      default:
        return DefaultBookingTimelineStrategy.id;
    }
  }

  static registerLegacyStrategy(flow: BookingFlow, bookingStrategyType: Type<IManageBookingStrategy>) {
    this.manageBookingStrategies[flow] = bookingStrategyType;
  }

  static createLegacyStrategy(
    owner: ManageBookingBaseComponent,
    userService: SharedUserService,
  ): IManageBookingStrategy {
    const flow: BookingFlow = (owner.booking.bookingFlow as BookingFlow) || 'default';
    const strategyType = this.manageBookingStrategies[flow];
    if (!strategyType) {
      throw new Error(`Could not locate a strategy for the booking flow: ${flow}`);
    }
    return new strategyType(owner, userService);
  }
}
