<div class="dropoff-pickup-options">
  <div class="btn-container">
    <!-- Dropoff toggle button -->
    <button class="btn btn-info btn-dropoff-pickup" [disabled]="disableFrom" (click)="toggleFrom()" #fromAnchor>
      <div class="btn-pre">{{ 'DROP_OFF' | translate }}</div>
      <div class="btn-title">{{ from | lhMoment: 'calendar' | uppercaseFirstLetter }}</div>
      <div class="btn-desc">
        <div class="open-closed" [class.success]="isOpenOnDropoffDate" [class.warning]="!isOpenOnDropoffDate"></div>
        <span>{{ dropoffHours | timeInterval: false | async }}</span>
      </div>
      <div class="icon-calendar"><i></i></div>
    </button>
    <lh-shared-ui-tooltip
      [body]="'YOU_ALREADY_DROPPED_OFF_YOUR_BAGS' | translate"
      *ngIf="disableFrom"
    ></lh-shared-ui-tooltip>

    <!-- Dropoff holiday warnings -->
    <div *ngIf="showHolidayWarning(from)" class="holiday-alert text-warning">
      {{ getHolidayName(from) }} {{ 'MIGHT_AFFECT_THESE_HOURS' | translate }}
    </div>
    <div *ngIf="showConfirmedHolidayHours(from)" class="holiday-alert text-success">
      {{ 'CONFIRMED_HOURS_FOR' | translate }} {{ getHolidayName(from) }}
    </div>
  </div>

  <!-- Dropoff date picker -->
  <kendo-popup
    class="date-time-popup-wrapper"
    [anchor]="fromAnchor"
    (click)="toggleFromAndApply()"
    #fromPopup
    (anchorViewportLeave)="showFrom = false"
    *ngIf="showFrom"
  >
    <div class="popup-wrapper-content">
      <lh-date-time-picker #fromPicker [(ngModel)]="from" [disabledDates]="disabledDates"></lh-date-time-picker>
    </div>
  </kendo-popup>

  <div class="btn-container">
    <!-- Pickup toggle button -->
    <button class="btn btn-info btn-dropoff-pickup" [disabled]="isSameDayOnly" (click)="toggleTo()" #toAnchor>
      <div class="btn-pre">{{ 'PICK_UP' | translate }}</div>
      <div class="btn-title">{{ to | lhMoment: 'calendar' | uppercaseFirstLetter }}</div>
      <div class="btn-desc">
        <div class="open-closed" [class.success]="isOpenOnPickupDate" [class.warning]="!isOpenOnPickupDate"></div>
        <span>{{ pickupHours | timeInterval: false | async }}</span>
      </div>
      <div class="icon-calendar"><i></i></div>
    </button>
    <lh-shared-ui-tooltip
      [header]="'SHORT_TERM_ONLY' | translate"
      [body]="'NO_OVERNIGHT_STORAGE' | translate"
      *ngIf="isSameDayOnly"
    ></lh-shared-ui-tooltip>

    <!-- Picup holiday alerts -->
    <div *ngIf="showHolidayWarning(to)" class="holiday-alert text-warning">
      {{ getHolidayName(to) }} {{ 'MIGHT_AFFECT_THESE_HOURS' | translate }}
    </div>
    <div *ngIf="showConfirmedHolidayHours(to)" class="holiday-alert text-success">
      {{ 'CONFIRMED_HOURS_FOR' | translate }} {{ getHolidayName(to) }}
    </div>
  </div>

  <!-- Pickup date picker -->
  <kendo-popup
    class="date-time-popup-wrapper"
    [anchor]="toAnchor"
    (click)="toggleToAndApply()"
    #toPopup
    (anchorViewportLeave)="showTo = false"
    *ngIf="showTo"
  >
    <div class="popup-wrapper-content">
      <lh-date-time-picker #toPicker [(ngModel)]="to" [disabledDates]="disabledDates"></lh-date-time-picker>
    </div>
  </kendo-popup>
</div>
