<div
  id="placesSearchContainer"
  class="places-search-container dropdown"
  [class.typing]="typing"
  [class.form-control]="formControlMode"
  #placesDropdown="bs-dropdown"
  dropdown
  [autoClose]="true"
  [insideClick]="true"
>
  <input
    id="searchInput"
    name="searchInput"
    #searchInput
    type="text"
    autocomplete="off"
    [value]="displayValue"
    class="form-control places-search-field"
    [class.standalone]="!formControlMode"
    autocorrect="off"
    autocapitalize="off"
    spellcheck="off"
    [(ngModel)]="query"
    [disabled]="disabled"
    (keypress)="onSearchFieldKeyPress($event)"
    (input)="onQueryChanged()"
    (focus)="selectAllText()"
    [placeholder]="placeholder || ('WHERE' | lhTranslate) + '?'"
  />

  <ul class="dropdown-menu" *dropdownMenu role="menu" aria-labelledby="place-predictions">
    <li *ngFor="let prediction of predictions" class="prediction-item href">
      <a (click)="selectPlace(prediction)" class="prediction-item-button">
        <span class="prediction-item-title">{{ getMainText(prediction) }}</span>
        <span class="prediction-item-subtitle">{{ getSecondaryText(prediction) }}</span>
      </a>
    </li>
  </ul>
</div>
