import { Component, Input } from '@angular/core';

import { BaseComponent } from '../../../core';

export type Icon = 'check' | 'exclamation';

@Component({ template: '' })
export abstract class LiveIconBaseComponent extends BaseComponent {
  @Input() public icon: Icon = 'check';
  @Input() public tickerMessages: string[];
}
