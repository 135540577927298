import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { GlobalConfig, Toast, ToastrModule } from 'ngx-toastr';

import { SharedNotificationService } from './shared-notification.service';

const toastrConfig: GlobalConfig = {
  includeTitleDuplicates: false,
  newestOnTop: true,
  maxOpened: 1,
  autoDismiss: true,
  iconClasses: {
    error: 'toast-error',
    info: 'toast-info',
    success: 'toast-success',
    warning: 'toast-warning',
  },
  preventDuplicates: true,
  disableTimeOut: false,
  timeOut: 8000,
  closeButton: true,
  extendedTimeOut: 1000,
  progressBar: false,
  enableHtml: true,
  toastClass: '',
  positionClass: 'toast-top-center',
  titleClass: '',
  messageClass: '',
  easing: 'fade',
  easeTime: 50,
  tapToDismiss: true,
  toastComponent: Toast,
  onActivateTick: false,
  countDuplicates: false,
  progressAnimation: 'decreasing',
  resetTimeoutOnDuplicate: true,
};

@NgModule({
  imports: [BrowserAnimationsModule, ToastrModule.forRoot(toastrConfig)],
  providers: [SharedNotificationService],
  exports: [ToastrModule],
})
export class SharedNotificationModule {}
