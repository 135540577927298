<div class="criteria-bar">
  <lh-traveler-shops-ui-places-search
    [value]="query"
    (valueChange)="queryChange($event)"
    [predictions]="predictionsListViewModel"
    (predictionSelected)="onPredictionSelected($event)"
    [placeholder]="searchPlaceholder"
    class="places-search"
  ></lh-traveler-shops-ui-places-search>

  <div class="spacer"></div>
  <lh-traveler-shops-ui-date-picker (dateChange)="dateChanged($event)"></lh-traveler-shops-ui-date-picker>
  <div class="spacer"></div>
  <lh-traveler-shops-ui-luggage-picker [(value)]="bags"></lh-traveler-shops-ui-luggage-picker>
</div>
