import { Inject, Injectable, InjectionToken } from '@angular/core';
import { ILocation, LatLng } from '@luggagehero/shared/interfaces';
import { SharedGeocodingService } from '@luggagehero/shared/services/geocoding';
import { SharedGeolocationService } from '@luggagehero/shared/services/geolocation';
import { SharedHttpService } from '@luggagehero/shared/services/http';

interface OfficialLocation {
  key: string;
  name: string;
  region: string;
  coordinates: number[];
  zoom: number;
  radius: number;
}

interface ListLocationsResponse {
  locations: OfficialLocation[];
  indexOfNearest: number;
}

export const LOCATIONS_SERVICE_ENDPOINT_TOKEN = new InjectionToken<string>('LOCATIONS_SERVICE_ENDPOINT_TOKEN');

@Injectable({
  providedIn: 'root',
})
export class SharedLocationService {
  public officialLocations: ILocation[];
  private indexOfNearest: number;
  private _isInitialized = false;
  private apiEndPoint: string;

  constructor(
    private geolocationService: SharedGeolocationService,
    private httpService: SharedHttpService,
    private geocodeService: SharedGeocodingService,
    @Inject(LOCATIONS_SERVICE_ENDPOINT_TOKEN) private locationsEndpoint: string,
  ) {
    this.apiEndPoint = locationsEndpoint;
  }

  get isInitialized(): boolean {
    return this._isInitialized;
  }

  get nearestOfficialLocation(): ILocation | null {
    if (!this.isInitialized) {
      return null;
    }
    return this.officialLocations[this.indexOfNearest];
  }

  async init(): Promise<void> {
    if (this.isInitialized) {
      return;
    }
    const response = await this.httpService.get<ListLocationsResponse>(`${this.apiEndPoint}/locations`, false);

    this.officialLocations = response.locations.map((loc) => {
      const officialLocation: ILocation = {
        key: loc.key,
        address: loc.name,
        region: loc.region,
        lat: loc.coordinates[0],
        lon: loc.coordinates[1],
        zoom: loc.zoom,
        radius: loc.radius,
        type: 'official',
      };
      return officialLocation;
    });

    this.indexOfNearest = response.indexOfNearest;
    this._isInitialized = true;
  }

  async getUserLocation(isUserRequest: boolean): Promise<ILocation> {
    let position: LatLng = null;
    try {
      position = await this.geolocationService.getCurrentPosition(isUserRequest);
      const place = await this.geocodeService.requestReverseGeoCodeForLatLong(
        position.lat,
        position.lng,
        'UserLocation',
      );

      const address = place.name || place.address.city;
      const userLocation: ILocation = {
        lat: position.lat,
        lon: position.lng,
        address: address,
        region: place.address.region,
        zoom: 14,
        // TODO: Find nearest official location and use that radius
        radius: 50,
        type: 'user',
      };
      return userLocation;
    } catch (error) {
      return null;
    }
  }
}
