import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslatePipe } from '@luggagehero/shared/ui-pipes';

@Component({
  selector: 'lh-traveler-shops-ui-luggage-picker',
  standalone: true,
  imports: [CommonModule, FormsModule, TranslatePipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['traveler-shops-ui-luggage-picker.component.scss'],
  templateUrl: 'traveler-shops-ui-luggage-picker.component.html',
})
export class TravelerShopsUiLuggagePickerComponent {
  @Input() value = 1;
  @Output() valueChange = new EventEmitter<number>();

  optionCount = Array(100).fill(0);
}
