import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { ProgressBarBaseComponent } from '@luggagehero/features/ui';

@Component({
  selector: 'lh-progress-bar',
  templateUrl: 'progress-bar.component.html',
  styleUrls: ['progress-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProgressBarComponent extends ProgressBarBaseComponent {
  constructor(cd: ChangeDetectorRef) {
    super(cd);
  }
}
