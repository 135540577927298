import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  inject,
  Input,
  Output,
} from '@angular/core';

type ApplePayButtonType = 'plain' | 'book' | 'pay';
type ApplePayButtonTheme = 'dark' | 'light';
type ApplePayButtonSize = 'small' | 'medium' | 'large';

@Component({
  selector: 'lh-shared-ui-apple-pay-button',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './shared-ui-apple-pay-button.component.html',
  styleUrl: './shared-ui-apple-pay-button.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SharedUiApplePayButtonComponent {
  @Input() public type: ApplePayButtonType = 'plain';
  @Input() public theme: ApplePayButtonTheme = 'dark';
  @Input() public size: ApplePayButtonSize = 'medium';
  @Input() public rounded = false;
  @Input() public elevated = false;

  @Output() public readonly press = new EventEmitter<Event>();

  private _selected = false;
  private _disabled = false;
  private _isLoading = false;

  private readonly cd = inject(ChangeDetectorRef);

  public get isLoading(): boolean {
    return this._isLoading;
  }
  @Input() public set isLoading(value: boolean) {
    this._isLoading = value;
    this.cd.markForCheck();
  }

  public get disabled(): boolean {
    return this._disabled;
  }
  @Input() public set disabled(value: boolean) {
    this._disabled = value;
    this.cd.markForCheck();
  }

  public get selected(): boolean {
    return this._selected;
  }
  @Input() public set selected(value: boolean) {
    this._selected = value;
    this.cd.markForCheck();
  }

  public onClick(event: Event): void {
    if (this.disabled || this.isLoading) {
      event.preventDefault();
      event.stopPropagation();
      return;
    }
    this.press.emit(event);
  }
}
