<form role="form" #form="ngForm">
  <div class="form-group">
    <label (click)="printDebugInfo()">{{ 'BANK_ACCOUNT_COUNTRY' | translate }}</label>

    <div class="form-group">
      <select id="countryPicker" name="countryPicker" class="form-select" [(ngModel)]="country">
        <option *ngFor="let option of supportedCountries" [value]="option.countryCode">
          {{ option.name | translate }}
        </option>
      </select>
    </div>

    <label (click)="printDebugInfo()">{{ 'BANK_ACCOUNT_CURRENCY' | translate }}</label>
    <div class="form-group">
      <select id="currencyPicker" name="currencyPicker" class="form-select" [(ngModel)]="currency">
        <option *ngFor="let option of supportedCurrencies" [value]="option">
          {{ option | uppercase }}
        </option>
      </select>
    </div>

    <label (click)="printDebugInfo()">{{ 'BANK_ACCOUNT_DETAILS' | translate }}</label>

    <div class="form-group" *ngIf="bankCodeLabel">
      <input
        id="bankCode"
        name="bankCode"
        #bankCodeControl="ngModel"
        type="text"
        class="form-control"
        [class.missing]="isMissing(bankCodeControl)"
        [class.complete]="isComplete(bankCodeControl)"
        [class.invalid]="isInvalid(bankCodeControl)"
        [(ngModel)]="bankCode"
        [placeholder]="bankCodeLabel | translate"
        required
      />
    </div>

    <div class="form-group" *ngIf="branchCodeLabel">
      <input
        id="branchCode"
        name="branchCode"
        #branchCodeControl="ngModel"
        type="text"
        class="form-control"
        [class.missing]="isMissing(branchCodeControl)"
        [class.complete]="isComplete(branchCodeControl)"
        [class.invalid]="isInvalid(branchCodeControl)"
        [(ngModel)]="branchCode"
        [placeholder]="branchCodeLabel | translate"
        required
      />
    </div>

    <div class="form-group" *ngIf="routingNumberLabel">
      <input
        id="routingNumber"
        name="routingNumber"
        #routingNumberControl="ngModel"
        type="text"
        class="form-control"
        [class.missing]="isMissing(routingNumberControl)"
        [class.complete]="isComplete(routingNumberControl)"
        [class.invalid]="isInvalid(routingNumberControl)"
        [(ngModel)]="routingNumber"
        [placeholder]="routingNumberLabel | translate"
        required
      />
    </div>

    <div *ngIf="isInvalidRoutingNumber && !isRoutingNumberPristine" class="alert alert-danger">
      {{ invalidRoutingNumberError | translate }}
    </div>

    <div class="form-group">
      <input
        id="accountNumber"
        name="accountNumber"
        #accountNumberControl="ngModel"
        type="text"
        class="form-control"
        [class.missing]="isMissing(accountNumberControl)"
        [class.complete]="isComplete(accountNumberControl)"
        [class.invalid]="isInvalid(accountNumberControl)"
        [(ngModel)]="accountNumber"
        [placeholder]="accountNumberLabel | translate"
        required
      />
    </div>

    <div *ngIf="isInvalidAccountNumber && !isAccountNumberPristine" class="alert alert-danger">
      {{ 'INVALID_BANK_ACCOUNT_NUMBER' | translate }}
    </div>
  </div>
</form>
