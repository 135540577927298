import { Injectable } from '@angular/core';
import { DeepPartial } from '@luggagehero/shared/interfaces';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, filter, map, switchMap, withLatestFrom } from 'rxjs/operators';

import { GuestOrderService } from '../guest-order.service';
import * as OrderRequestActions from './order-requests.actions';
import { OrderRequest } from './order-requests.models';
import * as OrderRequestSelectors from './order-requests.selectors';
import * as OrderActions from './orders.actions';
import { OrdersState } from './orders.models';

@Injectable()
export class OrdersEffects {
  constructor(
    private actions$: Actions,
    private store: Store<OrdersState>,
    private ordersService: GuestOrderService,
  ) {}

  createOrder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrderActions.createOrder),
      withLatestFrom(this.store.select(OrderRequestSelectors.selectOrderRequest)),
      switchMap(([_action, orderRequest]) =>
        this.ordersService.createOrder(orderRequest as OrderRequest).pipe(
          map((order) => OrderActions.createOrderSuccess({ order })),
          catchError((error: Error) => of(OrderActions.createOrderFailure({ error: error.message }))),
        ),
      ),
    ),
  );

  // Trigger order creation when orderRequest has sufficient data
  triggerOrderCreation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrderRequestActions.updateOrderRequest),
      withLatestFrom(this.store.select(OrderRequestSelectors.selectOrderRequest)),
      filter(([_, orderRequest]) => this.isOrderRequestComplete(orderRequest)),
      map(() => OrderActions.createOrder()),
    ),
  );

  private isOrderRequestComplete(orderRequest: DeepPartial<OrderRequest>): boolean {
    // Define the conditions under which the order should be created
    const shouldCreateOrder =
      orderRequest !== undefined &&
      orderRequest.bags !== undefined &&
      orderRequest.pricingModel !== undefined &&
      orderRequest.from !== undefined &&
      orderRequest.to !== undefined &&
      orderRequest.addOns !== undefined &&
      orderRequest.addOns.insurance !== undefined;

    console.log(`Order request updated - shouldCreateOrder: ${shouldCreateOrder}`);

    return shouldCreateOrder;
  }
}
