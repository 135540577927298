export enum LogoStyle {
  Default = 'Default',
  Primary = 'Primary',
}

export interface IThemeColor {
  value: string;
  dark: string;
  light: string;
  isLight: boolean;
  isDark: boolean;
}

export interface ITheme {
  name: string;
  organizationId: string;
  images: {
    logo: string;
    cover?: string;
  };
  colors: {
    primary: string;
    accent?: string;
    header?: string;
  };
  logoStyle: LogoStyle;
  primaryColor: IThemeColor;
  accentColor: IThemeColor;
  headerColor: IThemeColor;
  link: string;
}
