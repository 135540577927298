import { ChangeDetectorRef, Component, ElementRef, ViewChild } from '@angular/core';
import { AppConfig } from '@luggagehero/shared/app-settings/data-access';
import {
  BookableStorageLocation,
  PricingModel,
  StorageCriteria,
  StorageLocationService,
  StorageLocationType,
} from '@luggagehero/shared/interfaces';
import { SharedPricingService } from '@luggagehero/shared/services/pricing';
import { SharedShopsService } from '@luggagehero/shared/services/shops';
import { SharedStorageCriteriaService } from '@luggagehero/shared/services/storage-criteria';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';

import { BaseComponent } from '../../../core';

@Component({ template: '' })
export abstract class FindStorageBaseComponent extends BaseComponent {
  @ViewChild('shopFilters') public shopFilters: ElementRef<HTMLDivElement>;

  public visibleShops: BookableStorageLocation[] = [];
  public filtersReady = false;
  public isNoShopsAvailable = false;
  public isPromoCodeAlertEnabled = AppConfig.IS_PROMO_CODE_ALERT_ENABLED;

  protected shops: BookableStorageLocation[] = [];

  protected showUncertified = false;
  protected numberOfShopsAvailable = 0;
  protected currentCriteria: StorageCriteria;
  protected _isLoading = false;

  private _hotelsPresent = false;
  private _hotelsFilterEnabled = false;
  private _openAllDayPresent = false;
  private _openAllDayFilterEnabled = false;
  private _multiDayStoragePresent = false;
  private _multiDayStorageFilterEnabled = false;
  private _wifiAvailablePresent = false;
  private _wifiAvailableFilterEnabled = false;
  private _hideUnavailable = AppConfig.IS_HIDE_UNAVAILABLE_STORAGE_LOCATIONS_ENABLED;
  private _showOnlyShopsWithWifi = false;

  constructor(
    protected shopsService: SharedShopsService,
    protected criteriaService: SharedStorageCriteriaService,
    protected priceService: SharedPricingService,
    protected cd: ChangeDetectorRef,
  ) {
    super();
  }

  get isHotelFilterVisible(): boolean {
    return AppConfig.IS_SHOP_LIST_FILTER_BY_HOTELS_ENABLED && this.hotelsPresent;
  }

  get isOpen24HoursFilterVisible(): boolean {
    return AppConfig.IS_SHOP_LIST_FILTER_BY_OPEN_24_HOURS_ENABLED && this.openAllDayPresent;
  }

  get isMultiDayStorageFilterVisible(): boolean {
    return AppConfig.IS_SHOP_LIST_FILTER_BY_MULTIDAY_STORAGE_ENABLED && this.multiDayStoragePresent;
  }

  get isWifiAvailableFilterVisible(): boolean {
    return AppConfig.IS_SHOP_LIST_FILTER_BY_WIFI_AVAILABLE_ENABLED && this.wifiAvailablePresent;
  }

  get isShopFilteringEnabled(): boolean {
    if (AppConfig.IS_SHOP_LIST_FILTER_DISABLED) {
      return false;
    }
    return true;
  }

  public get hotelsPresent(): boolean {
    return this._hotelsPresent;
  }

  public get hotelsFilterEnabled(): boolean {
    return this._hotelsFilterEnabled;
  }

  get openAllDayPresent(): boolean {
    return this._openAllDayPresent;
  }

  get openAllDayFilterEnabled(): boolean {
    return this._openAllDayFilterEnabled;
  }

  get multiDayStoragePresent(): boolean {
    return this._multiDayStoragePresent;
  }

  get multiDayStorageFilterEnabled(): boolean {
    return this._multiDayStorageFilterEnabled;
  }

  get wifiAvailablePresent(): boolean {
    return this._wifiAvailablePresent;
  }

  get wifiAvailableFilterEnabled(): boolean {
    return this._wifiAvailableFilterEnabled;
  }

  get hideUnavailable() {
    return this._hideUnavailable;
  }
  set hideUnavailable(value: boolean) {
    this._hideUnavailable = value;
    this.cd.markForCheck();
  }

  get showOnlyShopsWithWifi() {
    return this._showOnlyShopsWithWifi;
  }
  set showOnlyShopsWithWifi(value: boolean) {
    this._showOnlyShopsWithWifi = value;
    this.cd.markForCheck();
  }

  get isFilterGradientLeftVisible(): boolean {
    if (!this.shopFilters) {
      return false;
    }
    // As soon as we start scrolling, show the left side gradient
    return this.shopFilters.nativeElement.scrollLeft > 0;
  }

  get isFilterGradientRightVisible(): boolean {
    if (!this.shopFilters) {
      return false;
    }
    const container = this.shopFilters.nativeElement;

    // As long as we haven't scrolled to the end, show the right side gradient
    return container.scrollLeft + container.clientWidth < container.scrollWidth;
  }

  get pricingModel(): Observable<PricingModel> {
    return this.priceService.pricingModel$;
  }

  abstract get isLoading(): boolean;

  abstract set isLoading(value: boolean);

  onFilterScroll() {
    // Make sure we update the UI when the user scrolls the filters
    this.cd.markForCheck();
  }

  toggleHotelsFilter() {
    this._hotelsFilterEnabled = !this._hotelsFilterEnabled;
    this.applyFilters();
  }

  toggleOpenAllDayFilter() {
    this._openAllDayFilterEnabled = !this._openAllDayFilterEnabled;
    this.applyFilters();
  }

  toggleMultiDayStorageFilter() {
    this._multiDayStorageFilterEnabled = !this._multiDayStorageFilterEnabled;

    if (this.multiDayStorageFilterEnabled) {
      this.priceService.changePricing('daily');
    }

    this.applyFilters();
  }

  toggleWifiAvailableFilter() {
    this._wifiAvailableFilterEnabled = !this._wifiAvailableFilterEnabled;
    this.applyFilters();
  }

  togglePricingModel() {
    if (this.priceService.pricingModel === 'hourly') {
      this.priceService.changePricing('daily');
    } else {
      this.priceService.changePricing('hourly');
    }
  }

  abstract get criteria(): StorageCriteria;
  abstract set criteria(value: StorageCriteria);

  protected processAndDisplayShops(shops: BookableStorageLocation[], criteria: StorageCriteria) {
    this.isNoShopsAvailable = false;
    this.numberOfShopsAvailable = 0;

    // HACK: Fixes distance not displayed correctly when user approves geolocation after a delay
    if (this.criteriaService.current) {
      this.criteriaService.current.location.type = criteria.location.type;
    }

    this.shops = shops
      .map((shop) => {
        shop.available = this.shopsService.isShopAvailable(shop, criteria, this.showUncertified);
        if (shop.available) {
          this.numberOfShopsAvailable++;
        }
        return shop;
      })
      .filter((shop) => {
        if (shop.active === false) {
          return false;
        }
        if (shop.certified === false && this.showUncertified !== true) {
          return false;
        }
        return true;
      });

    this.applyFilters();
    this.currentCriteria = criteria;
    this.isLoading = false;
  }

  applyFilters() {
    this.visibleShops = this.shops.filter((shop) => {
      if (this.hideUnavailable && !shop.available) {
        return false;
      }

      if (this.showOnlyShopsWithWifi && !shop.hasWifi) {
        return false;
      }

      if (this.hotelsFilterEnabled && shop.locationType !== StorageLocationType.Hotel) {
        return false;
      }

      if (
        this.openAllDayFilterEnabled &&
        (!this.shopsService.isOpenAllDay(this.criteria.period.from, shop) ||
          !this.shopsService.isOpenAllDay(this.criteria.period.to, shop))
      ) {
        return false;
      }

      if (this.multiDayStorageFilterEnabled && !shop.services.includes(StorageLocationService.MultiDayStorage)) {
        return false;
      }

      if (this.wifiAvailableFilterEnabled && !shop.hasWifi) {
        return false;
      }

      return true;
    });

    this.isNoShopsAvailable = this.visibleShops.length === 0;

    this._hotelsPresent = this.visibleShops.some((s) => s.locationType === StorageLocationType.Hotel);
    this._openAllDayPresent = this.visibleShops.some(
      (s) =>
        this.shopsService.isOpenAllDay(this.criteria.period.from, s) &&
        this.shopsService.isOpenAllDay(this.criteria.period.to, s),
    );
    this._multiDayStoragePresent = this.visibleShops.some(
      (s) => s.services && s.services.includes(StorageLocationService.MultiDayStorage),
    );
    this._wifiAvailablePresent = this.visibleShops.some((s) => s.hasWifi);

    if (this.filterModalSub) {
      this.filterModalSub.hide();
    }

    this.ensureFilterConsistency();

    this.filtersReady = true;
    this.cd.markForCheck();
  }

  private ensureFilterConsistency() {
    let applyFilters = false;
    if (!this.openAllDayPresent && this.openAllDayFilterEnabled) {
      this._openAllDayFilterEnabled = false;
      applyFilters = true;
    }

    if (!this.multiDayStoragePresent && this.multiDayStorageFilterEnabled) {
      this._multiDayStorageFilterEnabled = false;
      applyFilters = true;
    }

    if (!this.wifiAvailablePresent && this.wifiAvailableFilterEnabled) {
      this._wifiAvailableFilterEnabled = false;
      applyFilters = true;
    }

    if (!this.hotelsPresent && this.hotelsFilterEnabled) {
      this._hotelsFilterEnabled = false;
      applyFilters = true;
    }

    if (applyFilters) {
      this.applyFilters();
    }
  }

  abstract get filterModalSub(): ModalDirective;
}
