import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { Booking } from '@luggagehero/shared/interfaces';
import { SharedWindowService } from '@luggagehero/shared/services/window';
import { Subscription } from 'rxjs';

import { BaseComponent } from '../../../../core';
import { UserActiveService } from '../../../../services/index';

@Component({ template: '' })
export abstract class UserActiveBaseComponent extends BaseComponent implements OnDestroy {
  @Output() userActiveChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  private idleCounter = 0;
  private userIdleSubscription: Subscription;
  private _booking: Booking;

  public constructor(
    private userActiveService: UserActiveService,
    private windowService: SharedWindowService,
    private cd: ChangeDetectorRef,
  ) {
    super();
  }

  @Input() set booking(value: Booking) {
    if (!value) {
      return;
    }
    this._booking = value;

    if (['CANCELLED', 'PAID'].includes(value.status)) {
      // The booking is no longer active
      this.stopListening();
      return;
    }
    if (!this.userIdleSubscription) {
      this.startListening();
    }
  }
  get booking(): Booking {
    return this._booking;
  }

  get ios(): boolean {
    return this.windowService.iOS;
  }

  ngOnDestroy() {
    this.stopListening();
  }

  abstract showRefreshPrompt(): Promise<void>;
  abstract hideRefreshPrompt(): Promise<void>;

  startListening() {
    // make sure we start fresh and doesnt have any other listeners running
    this.userActiveService.registerUserIdleTimeMonitoring();

    // subscribe to the user active service
    this.userIdleSubscription = this.userActiveService.userIdle.subscribe((isIdle) => {
      this.idleCounter++;

      if (this.idleCounter === 1) {
        return;
      }

      if (isIdle) {
        // The user is idle and past the threshold
        void this.showRefreshPrompt();
        this.cd.markForCheck();
      } else {
        // When the user becomes active again submit the active event
        this.submitActive(true);
      }
    });
  }

  stopListening() {
    if (this.userIdleSubscription) {
      this.userIdleSubscription.unsubscribe();
      this.userActiveService.unregisterUserIdleTimeMonitoring();
    }
  }

  submitActive(refresh: boolean) {
    void this.hideRefreshPrompt();
    this.cd.markForCheck();

    this.userActiveChange.next(refresh);
  }
}
