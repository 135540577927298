import { Injectable } from '@angular/core';
import { Config } from '@luggagehero/shared/environment';
import { Booking, IUser } from '@luggagehero/shared/interfaces';
import { AppEvent, AppPlatform, SharedAppEventService } from '@luggagehero/shared/services/app-events';
import { SharedStorageCriteriaService } from '@luggagehero/shared/services/storage-criteria';
import { SharedWindowService } from '@luggagehero/shared/services/window';
import { cloneDeep } from '@luggagehero/shared/util';
import { Angulartics2, Angulartics2Facebook, Angulartics2GoogleTagManager, Angulartics2Intercom } from 'angulartics2';

export interface IAnalyticsProperties {
  category?: string;
  label?: string;
  value?: number;
  currency?: string;
  orderId?: string;
  event?: string;
  booking?: Booking;
  user?: IUser;
  storageLocationId?: string;
  customProperties?: unknown;
}

export interface IAnalytics {
  track(action: string, properties: IAnalyticsProperties): void;
}

@Injectable({
  providedIn: 'root',
})
export class SharedAnalyticsService implements IAnalytics {
  constructor(
    private angulartics: Angulartics2,
    private googleTagManager: Angulartics2GoogleTagManager,
    private facebook: Angulartics2Facebook,
    private intercom: Angulartics2Intercom,
    private windowService: SharedWindowService,
    private shopCriteriaService: SharedStorageCriteriaService,
    protected appEventService: SharedAppEventService,
  ) {
    this.devMode = Config.isDevelopment;
    this.devMode = false;
  }

  public isNative(): boolean {
    return this.windowService.isNative;
  }

  /**
   * Control whether analytics are tracked
   * true: dev mode on, therefore do not track anything
   * false: dev mode off, track everything
   **/
  public get devMode(): boolean {
    return this.angulartics.settings.developerMode;
  }
  public set devMode(value: boolean) {
    this.angulartics.settings.developerMode = value;
  }

  /**
   * Track actions, events, etc.
   **/
  public track(action: string, properties: IAnalyticsProperties): void {
    if (this.devMode) {
      return;
    }

    try {
      this.intercom.eventTrack(action, this.intercomProperties(properties));
    } catch {
      // Catch any unhandled errors as we never want analytics to be able to cause issues
    }

    try {
      this.googleTagManager.eventTrack(action, this.gtmProperties(properties));
    } catch {
      // Catch any unhandled errors as we never want analytics to be able to cause issues
    }

    try {
      this.fbEventTrack(action, properties);
    } catch {
      // Catch any unhandled errors as we never want analytics to be able to cause issues
    }

    try {
      void this.appEventService.add(this.appEventProperties(action, properties));
    } catch {
      // Catch any unhandled errors as we never want analytics to be able to cause issues
    }
  }

  /**
   * Called automatically by default with Angular 2 Routing
   * However, that can be turned off and this could be used manually
   **/
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public pageTrack(path: string, location: unknown): void {
    if (this.devMode) {
      return;
    }

    try {
      this.intercom.pageTrack(path);
      this.googleTagManager.pageTrack(path);
    } catch {
      // Catch any unhanled errors as we never want analytics to be able to cause issues
    }
  }

  /**
   * Identify authenticated users
   **/
  public identify(properties: { userId: string }): void {
    if (this.devMode) {
      return;
    }

    try {
      if (properties && properties.userId) {
        this.intercom.setUserProperties({ user_id: properties.userId });
        this.googleTagManager.setUsername(properties.userId);
      }
    } catch {
      // Catch any unhandled errors as we never want analytics to be able to cause issues
    }
  }

  private gtmProperties(properties: IAnalyticsProperties): unknown {
    if (!properties.orderId && !properties.user) {
      return properties;
    }

    const gtmProperties = Object.assign({}, properties, {
      gtmCustom: {
        orderId: properties.orderId || undefined,
        currencyCode: properties.currency || undefined,
        userEmail: properties.user?.email || undefined,
        userPhone: properties.user?.phone || undefined,
      },
    });
    delete gtmProperties.orderId;
    delete gtmProperties.currency;
    delete gtmProperties.booking;
    delete gtmProperties.user;

    return gtmProperties;
  }

  private intercomProperties(properties: IAnalyticsProperties): unknown {
    if (!properties.orderId && !properties.booking) {
      return properties;
    }
    const appDomain = Config.isProduction ? 'app.luggagehero.com' : 'app.devheroo.com';
    const bookingId = properties.orderId || properties.booking._id;

    //
    // Add booking ID with booking URL and clone the provided properties so we're sure we don't affect original objects
    // when we parse and serialize the metadata for Intercom
    //
    const metadata: {
      bookingId: { value: string; url: string };
      bookingUrl: string;
      booking?: Booking;
      price?: { amount: number; currency: string };
    } = {
      ...cloneDeep(properties),
      bookingId: {
        value: bookingId,
        url: bookingId && `https://${appDomain}/bookings/${bookingId}`,
      },
      // TODO: Here for legacy reasons; remove if the above works
      bookingUrl: bookingId && `https://${appDomain}/bookings/${bookingId}`,
    };

    if (metadata.booking.price.final) {
      //
      // Add a price property according to the specification by Intercom
      // https://developers.intercom.com/intercom-api-reference/v1.4/reference/event-metadata-types
      //
      metadata.price = {
        amount: metadata.booking.price.final.total,
        currency: metadata.booking.price.final.currency,
      };
    }

    // Serialize the metadata (recursively converts properties into types that Intercom supports)
    return this.serializeIntercomMetadata(metadata);
  }

  private serializeIntercomMetadata(metadata: unknown): unknown {
    if (typeof metadata !== 'object') {
      return metadata;
    }
    for (const propertyName in metadata) {
      const propertyType = typeof metadata[propertyName];

      switch (propertyType) {
        case 'string':
          // Keep strings as-is
          break;

        case 'number':
          // Keep numbers as-is
          break;

        case 'boolean':
          // Parse booleans to strings as Intercom doesn't support booleans
          metadata[propertyName] = String(metadata[propertyName]);
          break;

        case 'object':
          // Parse the sub object recursively
          metadata[propertyName] = this.serializeIntercomMetadata(metadata[propertyName]);
          break;

        default:
          // Delete other property types
          delete metadata[propertyName];
          break;
      }
    }

    return metadata;
  }

  protected appEventProperties(action: string, properties: IAnalyticsProperties): AppEvent {
    let geoLocation: { lat: number; lon: number } = null;
    if (this.shopCriteriaService.location && this.shopCriteriaService.location.type === 'user') {
      geoLocation = { lat: this.shopCriteriaService.location.lat, lon: this.shopCriteriaService.location.lon };
    }

    let appPlatform: AppPlatform = null;
    if (this.windowService.platform === 'web') {
      appPlatform = AppPlatform.Web;
    } else if (this.windowService.platform === 'android') {
      appPlatform = AppPlatform.Android;
    } else if (this.windowService.platform === 'ios') {
      appPlatform = AppPlatform.iOS;
    }

    const appVersion = this.windowService.appVersion;
    const appName = this.windowService.appName;

    const appEventProperties = Object.assign({}, properties, {
      appPlatform,
      appVersion,
      appName,
      geoLocation,
      action,
    });
    delete appEventProperties.event;
    delete appEventProperties.booking;
    delete appEventProperties.user;

    return appEventProperties;
  }

  private fbEventTrack(action: string, properties: IAnalyticsProperties): void {
    //
    // Facebook event list:
    //
    //   'ViewContent'
    //   'Search'
    //   'AddToCart'
    //   'AddToWishlist'
    //   'InitiateCheckout'
    //   'AddPaymentInfo'
    //   'Purchase'
    //   'Lead'
    //   'CompleteRegistration'
    //
    switch (action) {
      case 'signUp':
      case 'CompleteRegistration':
        this.facebook.eventTrack('CompleteRegistration', {});
        break;

      case 'bookingCreated':
      case 'MakeBooking':
        this.facebook.eventTrack('AddToCart', { content_name: properties.label });
        break;

      case 'paymentMethodAdded':
      case 'SetPaymentMethod':
        this.facebook.eventTrack('AddPaymentInfo', {});
        break;

      case 'bagsDroppedOff':
        this.facebook.eventTrack('InitiateCheckOut', {});
        break;

      case 'bagsPickedUp':
      case 'CompleteCheckOut':
        this.facebook.eventTrack('Purchase', { currency: properties.currency, value: properties.value });
        break;

      default: {
        const facebookProperties = Object.assign({}, properties);
        delete facebookProperties.booking;
        delete facebookProperties.user;

        this.facebook.eventTrack(action, properties);

        break;
      }
    }
  }
}

/**
 * Base class
 * Standardizes tracking actions and categorization
 */
export class Analytics implements IAnalytics {
  // sub-classes should define their category
  public category: string;

  constructor(public analytics: SharedAnalyticsService) {}

  /**
   * Track actions, events, etc.
   **/
  track(action: string, properties: IAnalyticsProperties): void {
    this.analytics.track(action, Object.assign(properties, { category: this.category }));
  }
}
