import { ChangeDetectorRef, Component, inject } from '@angular/core';
import { AppConfig } from '@luggagehero/shared/app-settings/data-access';
import {
  Booking,
  BOOKING_TRANSFORMATION_SERVICE,
  BookingDto,
  BookingTransformationService,
  PRICING_SERVICE,
  PricingService,
} from '@luggagehero/shared/interfaces';
import { SharedBookingService } from '@luggagehero/shared/services/bookings';
import { SharedUserService } from '@luggagehero/shared/services/users';
import { debounce, DebouncedFunc } from '@luggagehero/shared/util';
import { TranslateService } from '@ngx-translate/core';

import { BaseModalComponent } from '../../../core';
import { RouterExtensions } from '../../../core/services/index';
import { SearchResultType, SearchService } from '../../../services/search/search.service';
import { ListGroup } from './grouped-list';
import { ListItemFactory } from './grouped-list/list-item-factory';

@Component({ template: '' })
export abstract class UniversalSearchBaseComponent extends BaseModalComponent {
  private _debouncedSearch: DebouncedFunc<(query: string) => Promise<void>>;
  private _groupedBookings: ListGroup<Booking>[];
  private _isSearchInitiatedByUser = false;
  private _isSearchQueryTooShort = false;

  private searchService = inject(SearchService);
  private userService = inject(SharedUserService);
  private bookingService = inject(SharedBookingService);
  private bookingTransformationService = inject<BookingTransformationService>(BOOKING_TRANSFORMATION_SERVICE);

  private pricingService = inject<PricingService>(PRICING_SERVICE);
  private router = inject(RouterExtensions);
  private translate = inject(TranslateService);
  private cd = inject(ChangeDetectorRef);

  constructor() {
    super();
    this._debouncedSearch = debounce(
      this.search.bind(this) as typeof this.search,
      AppConfig.UNIVERSAL_SEARCH_DEBOUNCE_TIME,
    );
  }

  public get groupedBookings(): ListGroup<Booking>[] {
    return this._groupedBookings;
  }
  public set groupedBookings(value: ListGroup<Booking>[]) {
    this._groupedBookings = value;
    this.cd.markForCheck();
  }

  public get debouncedSearch(): DebouncedFunc<(query: string) => Promise<void>> {
    return this._debouncedSearch;
  }

  public get isSerchResultAvailable(): boolean {
    return this.groupedBookings && this.groupedBookings.length > 0;
  }

  public get isSearchInitiatedByUser(): boolean {
    return this._isSearchInitiatedByUser;
  }

  public get isSearchQueryTooShort(): boolean {
    return this._isSearchQueryTooShort;
  }

  public async search(query: string): Promise<void> {
    this._isSearchInitiatedByUser = true;

    if (!query || query.length < AppConfig.UNIVERSAL_MIN_SEARCH_QUERY_LENGTH) {
      this._isSearchQueryTooShort = true;
      this.groupedBookings = [];
      return;
    }
    this._isSearchQueryTooShort = false;

    const result = await this.searchService.search(query);
    const bookings = await Promise.all(
      result
        .filter((r) => r.type === SearchResultType.Booking)
        .map((b) => this.bookingService.deserialize(b.data as BookingDto)),
    );

    this.groupedBookings = this.groupBookings(bookings);
  }

  public async navigateToBooking(selectedBooking: Booking) {
    await this.hideModal();
    await this.router.navigate(['storage-locations', selectedBooking.shopId, 'bookings', selectedBooking._id]);
  }

  private groupBookings(bookings: Booking[]): ListGroup<Booking>[] {
    const groupedBookings: ListGroup<Booking>[] = [];

    for (const booking of bookings) {
      // Get the group name of the current booking
      const isValidEmail = !this.userService.isDummyUserEmail(booking.userEmail);
      const groupName = (isValidEmail && booking.userEmail) || booking.userPhone || this._translate('NO_USER_ACCOUNT');

      // Get the current group
      const currentGroup = groupedBookings[groupedBookings.length - 1];

      if (currentGroup && currentGroup.name === groupName) {
        // Add current booking to the current group
        currentGroup.items.push(
          ListItemFactory.createBookingListItem(
            booking,
            this.translate,
            this.bookingTransformationService,
            this.pricingService,
          ),
        );
      } else {
        // Start new group with the current booking
        groupedBookings.push({
          name: groupName,
          icon: 'user',
          items: [
            ListItemFactory.createBookingListItem(
              booking,
              this.translate,
              this.bookingTransformationService,
              this.pricingService,
            ),
          ],
        });
      }
    }
    return groupedBookings;
  }
}
