import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IPricing } from '@luggagehero/shared/interfaces';
import { PricingPipe } from '@luggagehero/shared/ui-pipes';
import { TranslateModule } from '@ngx-translate/core';
import { RatingModule } from 'ngx-bootstrap/rating';

@Component({
  selector: 'lh-traveler-shops-ui-pricing-details',
  standalone: true,
  imports: [CommonModule, RatingModule, FormsModule, TranslateModule, PricingPipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './traveler-shops-ui-pricing-details.component.html',
  styleUrls: ['./traveler-shops-ui-pricing-details.component.scss'],
})
export class TravelerShopsUiPricingDetailsComponent {
  @Input() pricing!: IPricing;
  @Input() showDiscountOptions = false;
  @Input() showPriceInfoOptions = true;
  @Output() priceInfoClicked = new EventEmitter<void>();
  @Output() discountOptionsClicked = new EventEmitter<void>();

  showPriceInfoClick() {
    this.priceInfoClicked.emit();
  }

  showDiscountOptionsClick() {
    this.discountOptionsClicked.emit();
  }
}
