import { ChangeDetectorRef, Component } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { IFormField, IFormFieldConfig } from '@luggagehero/shared/interfaces';

import { BaseComponent } from '../../../core';

@Component({ template: '' })
export abstract class DynamicFormButtonBaseComponent extends BaseComponent implements IFormField {
  private _group: FormGroup;
  private _config: IFormFieldConfig;

  constructor(private cd: ChangeDetectorRef) {
    super();
  }

  get group(): FormGroup {
    return this._group;
  }
  set group(value: FormGroup) {
    this._group = value;
    this.cd.markForCheck();
  }

  get config(): IFormFieldConfig {
    return this._config;
  }
  set config(value: IFormFieldConfig) {
    this._config = value;
    this.cd.markForCheck();
  }
}
