import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { AppConfig, SharedAppSettingsService } from '@luggagehero/shared/app-settings/data-access';
import { Config } from '@luggagehero/shared/environment';
import { SharedErrorService } from '@luggagehero/shared/services/error';
import { SharedLoadingService } from '@luggagehero/shared/services/loading';
import { SharedNotificationService } from '@luggagehero/shared/services/notification';
import { SharedTranslateService } from '@luggagehero/shared/services/translation';
import { INewUser, SharedUserService } from '@luggagehero/shared/services/users';
import { SharedWindowService } from '@luggagehero/shared/services/window';
import { SharedUiButtonComponent, SharedUiInputComponent, SharedUiSpinnerComponent } from '@luggagehero/shared/ui';
import { TranslatePipe } from '@luggagehero/shared/ui-pipes';
import { SharedUtilPassword, SharedUtilString } from '@luggagehero/shared/util';
import { Subscription } from 'rxjs';

export interface UserInfo {
  email: string;
  password: string;
  name: string;
}

@Component({
  selector: 'lh-shared-feature-fluent-auth',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedUiButtonComponent,
    SharedUiInputComponent,
    SharedUiSpinnerComponent,
    TranslatePipe,
  ],
  templateUrl: './shared-feature-fluent-auth.component.html',
  styleUrl: './shared-feature-fluent-auth.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SharedFeatureFluentAuthComponent implements OnInit, OnDestroy {
  @Input() public hideButtons = false;
  @Output() public canSubmitChange = new EventEmitter<boolean>();
  @ViewChild('passwordInput') public passwordInput: SharedUiInputComponent;

  public userForm: FormGroup;

  public isPasswordFieldVisible = false;
  public isExistingUser = false;
  public isContinueWithEmailButtonClicked = false;
  public isOtpEnabled = false;

  public isTestMode = !Config.isProduction;

  private subscriptions = new Subscription();
  private _canContinue = false;
  private _isLoading = false;

  private readonly userService = inject(SharedUserService);
  private readonly translateService = inject(SharedTranslateService);
  private readonly notificationService = inject(SharedNotificationService);
  private readonly appSettings = inject(SharedAppSettingsService);
  private readonly windowService = inject(SharedWindowService);
  private readonly errorService = inject(SharedErrorService);
  private readonly loader = inject(SharedLoadingService);
  private readonly formBuilder = inject(FormBuilder);
  private readonly cd = inject(ChangeDetectorRef);

  constructor() {
    this.isOtpEnabled = this.appSettings.current?.isEmailOtpEnabled || false;
  }

  public get isLoading(): boolean {
    return this._isLoading;
  }
  public set isLoading(value: boolean) {
    this._isLoading = value;
    this.cd.markForCheck();
  }

  public get canContinue(): boolean {
    return this._canContinue;
  }
  private set canContinue(value: boolean) {
    if (this._canContinue === value) {
      return;
    }
    this._canContinue = value;
    this.cd.markForCheck();
    this.canSubmitChange.emit(value);
  }

  public get isContinueWithEmailButtonDisabled(): boolean {
    if (this.isLoading) {
      return true;
    }
    if (!this.isContinueWithEmailButtonClicked) {
      return false;
    }
    return this.emailField.invalid;
  }

  public get email(): string {
    const email = this.userForm.controls.email?.value as string;

    if (email.endsWith('.con')) {
      return email.trim().slice(0, -3) + 'com';
    }

    return email;
  }

  public get password(): string {
    return this.userForm.controls.password?.value as string;
  }

  public get emailField(): AbstractControl {
    return this.userForm.controls.email;
  }

  public get passwordField(): AbstractControl {
    return this.userForm.controls.password;
  }

  public ngOnInit(): void {
    this.userForm = this.formBuilder.group({
      // eslint-disable-next-line @typescript-eslint/unbound-method
      email: ['', [Validators.required, Validators.pattern(AppConfig.EMAIL_REGEX)]],
      // eslint-disable-next-line @typescript-eslint/unbound-method
      password: ['', [Validators.required]],
    });
    this.subscriptions.add(this.userForm.valueChanges.subscribe(() => this.checkCanContinue()));
    this.canContinue = true;
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public onEmailFocus(): void {
    this.isPasswordFieldVisible = false;
    this.cd.markForCheck();
  }

  public async continue(): Promise<void> {
    this.isContinueWithEmailButtonClicked = true;
    this.emailField.markAsDirty();

    if (this.emailField.invalid) {
      return;
    }

    await this.loader.load(
      async () => {
        if (this.isPasswordFieldVisible) {
          return this.logInEmailUser();
        }

        try {
          this.isExistingUser = await this.userService.checkIfEmailUserExists(this.email);
        } catch (error) {
          this.notificationService.error('Invalid email, please try again');
          return;
        }

        if (this.isExistingUser) {
          this.isPasswordFieldVisible = true;

          if (this.isOtpEnabled) {
            await this.userService.requestOneTimePasswordViaEmail(this.email);
          }
        } else {
          await this.registerEmailUser();
        }

        setTimeout(() => this.passwordInput?.setFocus(), 100);
      },
      (err) => this.errorService.handleError(err),
      () => this.checkCanContinue(),
    );
  }

  public async resetPassword(): Promise<void> {
    if (this.emailField.invalid) {
      this.emailField.markAsDirty();
      this.cd.markForCheck();

      this.notificationService.warning(this.translate('RESET_PASSWORD_MISSING_EMAIL'));

      return;
    }

    if (!this.confirmPasswordReset()) {
      return;
    }

    await this.loader.load(
      async () => {
        await this.userService.resetPasswordViaEmail(this.email);
        this.notificationService.success(this.translate('RESET_PASSWORD_SUCCESS_MESSAGE'));
      },
      (err) => {
        this.errorService.handleError(err, SharedUtilString.errorToString(err));
      },
    );
  }

  public async skipLoginForTest(): Promise<void> {
    this.emailField.setValue(`test_${Date.now()}@luggagehero.com`);
    await this.loader.load(() => this.registerEmailUser());
  }

  private checkCanContinue(): void {
    if (this.isLoading) {
      this.canContinue = false;
    } else if (this.isPasswordFieldVisible) {
      this.canContinue = this.userForm.valid;
    } else {
      this.canContinue = this.emailField.valid || !this.isContinueWithEmailButtonClicked;
    }
  }

  private async registerEmailUser(): Promise<void> {
    try {
      const newUser: INewUser = {
        email: this.email,
        password: SharedUtilPassword.generate(),
        name: 'Guest User',
        sendPassword: !this.isOtpEnabled,
      };

      const res = await this.userService.registerUser(newUser);

      if (res.success) {
        await this.userService.getUserAndRedirect();
      } else {
        this.errorService.handleError(res.message, res.message, this.translate('REGISTER_USER_ERROR_TITLE'));
      }
    } catch (err) {
      console.error('Error registering user', err);
    }
  }

  private async logInEmailUser(): Promise<void> {
    this.passwordField.markAsDirty();

    if (this.passwordField.invalid) {
      return;
    }

    await this.loader.load(
      async () => {
        const res = await this.userService.loginWithEmail({
          email: this.email,
          password: this.password,
          otp: this.isOtpEnabled,
        });

        if (res.success) {
          await this.userService.getUserAndRedirect();
        } else {
          this.errorService.handleError(res.message, res.message, this.translate('LOGIN_ERROR_TITLE'));
        }
      },
      (err) => {
        this.passwordField?.setValue('');
        this.userForm.markAsPristine();

        if (this.extractStatus(err) === 401 || this.extractStatus(err) === 429) {
          this.notificationService.error(`Login failed, please try again`);
        } else {
          this.errorService.handleError(err, SharedUtilString.errorToString(err) || 'Uknown error, please try again');
        }
      },
    );
  }

  private confirmPasswordReset(): boolean {
    const part1 = this.translate('CONFIRM_RESET_PASSWORD_PART_1');
    const part2 = this.translate('CONFIRM_RESET_PASSWORD_PART_2');
    return this.getUserConfirmation(`${part1} ${this.email}. ${part2}.`);
  }

  private getUserConfirmation(message: string): boolean {
    try {
      this.windowService.confirm(message);
      return true;
    } catch {
      return false; // User cancelled
    }
  }

  private extractStatus(err: unknown): number {
    return typeof err === 'object' && 'status' in err ? (err as { status: number }).status : -1;
  }

  private translate(key: string): string {
    return this.translateService.instant(key);
  }
}
