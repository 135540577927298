import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, Input } from '@angular/core';
import { SecuritySeal, Tag, TagColor, TagColorInfo } from '@luggagehero/shared/interfaces';
import { TranslatePipe } from '@luggagehero/shared/ui-pipes';
import { ColorUtil } from '@luggagehero/shared/util';

type UiStyle = 'primary' | 'success' | 'warning';
type UiSize = 'small' | 'medium' | 'large';

class TagInstance implements Tag {
  constructor(
    public text: string,
    public color?: TagColor,
  ) {}

  static fromSecuritySeal(securitySeal: SecuritySeal, palette?: TagColorInfo[]): TagInstance {
    const color = securitySeal.color && palette ? ColorUtil.normalizeColor(securitySeal.color, palette) : undefined;
    return new TagInstance(securitySeal.tag, color?.name);
  }
}

@Component({
  selector: 'lh-shared-ui-tag-list',
  standalone: true,
  imports: [CommonModule, TranslatePipe],
  templateUrl: './shared-ui-tag-list.component.html',
  styleUrl: './shared-ui-tag-list.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SharedUiTagListComponent {
  private cd = inject(ChangeDetectorRef);
  private _tags: Tag[] = [];
  private _securitySeals: SecuritySeal[] = [];
  private _style: UiStyle | undefined;
  private _size: UiSize = 'medium';
  private _noWrap = false;
  private _maxTagsCollapsed = 2;
  private _isCollapsed = false;

  public get securitySeals(): SecuritySeal[] {
    return this._securitySeals;
  }
  @Input() public set securitySeals(value: SecuritySeal[]) {
    this._securitySeals = value;

    // Create tags from the security seals
    this.tags = value.map((securitySeal) => TagInstance.fromSecuritySeal(securitySeal));
  }

  public get tags(): Tag[] {
    return this._tags;
  }
  @Input() public set tags(value: Tag[]) {
    this._tags = value;
    this.cd.markForCheck();
  }

  public get style(): UiStyle | undefined {
    return this._style;
  }
  @Input() public set style(value: UiStyle | undefined) {
    this._style = value;
    this.cd.markForCheck();
  }

  public get size(): UiSize {
    return this._size;
  }
  @Input() public set size(value: UiSize) {
    this._size = value;
    this.cd.markForCheck();
  }

  public get noWrap(): boolean {
    return this._noWrap;
  }
  @Input() public set noWrap(value: boolean) {
    this._noWrap = value;
    this.cd.markForCheck();
  }

  public get maxTagsCollapsed(): number {
    return this._maxTagsCollapsed;
  }
  @Input() public set maxTagsCollapsed(value: number) {
    this._maxTagsCollapsed = value;
    this.cd.markForCheck();
  }

  public get isCollapsed(): boolean {
    return this._isCollapsed;
  }
  @Input() public set isCollapsed(value: boolean) {
    this._isCollapsed = value;
    this.cd.markForCheck();
  }

  public get visibleTags(): Tag[] {
    return this.isCollapsed ? this.tags.slice(0, this.maxTagsCollapsed) : this.tags;
  }

  public get numberOfHiddenTags(): number {
    if (this.noWrap || !this.isCollapsed || this.tags.length <= this.maxTagsCollapsed) {
      return 0;
    }
    return this.tags.length - this.maxTagsCollapsed;
  }
}
