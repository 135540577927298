import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, Input, OnDestroy, OnInit } from '@angular/core';
import { BookableStorageLocation, ITimeInterval } from '@luggagehero/shared/interfaces';
import { SharedShopsService } from '@luggagehero/shared/services/shops';
import { SharedStorageCriteriaService } from '@luggagehero/shared/services/storage-criteria';
import { TimeIntervalPipe } from '@luggagehero/shared/ui-pipes';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { Subscription } from 'rxjs';

@Component({
  selector: 'lh-traveler-shops-feature-opening-hours',
  standalone: true,
  imports: [CommonModule, TimeIntervalPipe, TranslateModule],
  templateUrl: './traveler-shops-feature-opening-hours.component.html',
  styleUrl: './traveler-shops-feature-opening-hours.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TravelerShopsFeatureOpeningHoursComponent implements OnInit, OnDestroy {
  private readonly shopsService = inject(SharedShopsService);
  private readonly criteriaService = inject(SharedStorageCriteriaService);
  private readonly translate = inject(TranslateService);
  private readonly cd = inject(ChangeDetectorRef);

  @Input() shop!: BookableStorageLocation;

  weekDates: Date[] = [];
  weekDayNames: string[] = [];

  private subscriptions: Subscription[] = [];

  ngOnInit() {
    this.buildWeekDates();
    this.loadWeekdayNames();
    this.cd.markForCheck();

    this.subscriptions.push(
      this.criteriaService.change.subscribe(() => {
        this.buildWeekDates();
        this.cd.markForCheck();
      }),
    );

    this.subscriptions.push(
      this.translate.onLangChange.subscribe(() => {
        this.loadWeekdayNames();
        this.cd.markForCheck();
      }),
    );
  }

  ngOnDestroy() {
    try {
      this.subscriptions.forEach((s) => s.unsubscribe());
    } catch {
      /* ignore */
    }
  }

  getNameOfDay(date: Date): string {
    return this.weekDayNames[moment(date).weekday()];
  }

  getOpeningHoursFromDate(date: Date): ITimeInterval[] {
    return this.shopsService.getOpeningHoursForDate(date, this.shop);
  }

  showHolidayWarning(date: Date): boolean {
    return this.shopsService.showHolidayWarning(date, this.shop);
  }

  showConfirmedHolidayHours(date: Date): boolean {
    return this.shopsService.isHoliday(date, this.shop) && this.shopsService.hasConfirmedHours(date, this.shop);
  }

  getHolidayName(date: Date): string {
    return this.shopsService.getHolidayName(date, this.shop);
  }

  private loadWeekdayNames() {
    this.weekDayNames = [];
    this.weekDayNames.push(this.translate.instant('SUNDAY') as string);
    this.weekDayNames.push(this.translate.instant('MONDAY') as string);
    this.weekDayNames.push(this.translate.instant('TUESDAY') as string);
    this.weekDayNames.push(this.translate.instant('WEDNESDAY') as string);
    this.weekDayNames.push(this.translate.instant('THURSDAY') as string);
    this.weekDayNames.push(this.translate.instant('FRIDAY') as string);
    this.weekDayNames.push(this.translate.instant('SATURDAY') as string);
  }

  private buildWeekDates() {
    const startDate = moment(this.criteriaService?.currentOrDefault?.period?.from);
    this.weekDates = [];
    this.weekDates.push(startDate.toDate());
    this.weekDates.push(startDate.add(1, 'day').toDate());
    this.weekDates.push(startDate.add(1, 'day').toDate());
    this.weekDates.push(startDate.add(1, 'day').toDate());
    this.weekDates.push(startDate.add(1, 'day').toDate());
    this.weekDates.push(startDate.add(1, 'day').toDate());
    this.weekDates.push(startDate.add(1, 'day').toDate());
  }
}
