import { Inject, Injectable } from '@angular/core';
import { ITheme, IUser } from '@luggagehero/shared/interfaces';
import { SharedDocumentService } from '@luggagehero/shared/services/document';

import { SHARED_STORAGE_SERVICE_CONFIG } from './shared-storage.token';
import { StorageServiceConfig } from './shared-storage-service-config.interface';

const PROFILE_KEY = 'profile';
const VISITOR_ID_KEY = 'visitor_id';
const BOOKING_TOKEN_KEY = 'booking_token';
const SESSION_ID_KEY = 'session_id';
const TRACKING_DATA_KEY = 'tracking_data';
const TOKEN_KEY = 'access_token';
const PROMO_CODE_KEY = 'promo_code';
const UI_THEME_KEY = 'ui_theme';
const UI_THEME_VERSION_KEY = 'ui_theme_version';
const SVG_ICONS_KEY = 'svg_icons';
const SVG_ICONS_VERSION_KEY = 'svg_icons_version';
const VARIANT_KEY = 'variant';
const EXPERIMENT_ID_KEY = 'experiment_id';
const BOOKING_ORIGIN_VIEW_KEY = 'booking_origin_view';
const LANGUAGE_KEY = 'app_language';
const LAST_OTP_REQUEST_KEY = 'last_otp_request';
const LAST_OTP_RECIPIENT_KEY = 'last_otp_recipient';
const IS_IMPERSONATED_USER_KEY = 'is_impersonated_user';
const IS_COOKIE_CONSENT_GIVEN_KEY = 'is_cookie_consent_given';
const RELOADED_AT_KEY = 'reloaded_at';
const BOOKINGS_KEY = 'bookings';
const ABANDON_CART_RESPONSE_KEY = 'abandon_cart_response';
const ABANDON_CART_LAST_RESPONSE_AT_KEY = 'abandon_cart_last_response_at';
const ABANDON_CART_LAST_PROMPTED_AT_KEY = 'abandon_cart_last_prompted_at';
const PAYOUT_PROVIDER_ACCOUNT_ID_KEY = 'payout_provider_account_id';
const STORAGE_LOCATION_ID_KEY = 'storageLocationId';
const REGISTERED_APP_TRANSLATIONS_KEY = 'registered_app_translations';

const DEFAULT_COOOKIE_LIFETIME = 24 * 60 * 60 * 365 * 1000; // 1 year
const BOOKINGS_COOKIE_LIFETIME = 24 * 60 * 60 * 30 * 1000; // 30 days

const DUMMY_KEY = 'Hello';
const DUMMY_VALUE = 'World';

export enum StorageTarget {
  Local = 'Local',
  Session = 'Session',
  Cookie = 'Cookie',
}

@Injectable({
  providedIn: 'root',
})
export class SharedStorageService {
  public isLocalStorageSupported: boolean;
  public isSessionStorageSupported: boolean;
  public isCookieStorageSupported: boolean;

  private isInitialized = false;

  constructor(
    @Inject(SHARED_STORAGE_SERVICE_CONFIG) private config: StorageServiceConfig,
    private documentService: SharedDocumentService,
  ) {
    this.isLocalStorageSupported = this.isStorageTargetSupported(StorageTarget.Local);
    this.isSessionStorageSupported = this.isStorageTargetSupported(StorageTarget.Session);
    this.isCookieStorageSupported = this.isStorageTargetSupported(StorageTarget.Cookie);

    this.isInitialized = true;

    console.log('Storage service initialized', {
      Local: this.isLocalStorageSupported,
      Session: this.isSessionStorageSupported,
      Cookie: this.isCookieStorageSupported,
    });
  }

  get abandonCartResponse(): string {
    return this.get(ABANDON_CART_RESPONSE_KEY, StorageTarget.Local);
  }
  set abandonCartResponse(value: string) {
    if (!value) {
      this.remove(ABANDON_CART_RESPONSE_KEY, StorageTarget.Local);
      return;
    }
    this.set(ABANDON_CART_RESPONSE_KEY, value, StorageTarget.Local);
  }

  get abandonCartLastResponseAt(): Date {
    const value = this.get(ABANDON_CART_LAST_RESPONSE_AT_KEY, StorageTarget.Local);
    return value ? new Date(value) : null;
  }
  set abandonCartLastResponseAt(value: Date) {
    if (!value) {
      this.remove(ABANDON_CART_LAST_RESPONSE_AT_KEY, StorageTarget.Local);
      return;
    }
    this.set(ABANDON_CART_LAST_RESPONSE_AT_KEY, value.toISOString(), StorageTarget.Local);
  }

  get timeSinceAbandonCartLastResponse(): number {
    const lastResponseAt = this.abandonCartLastResponseAt;

    if (!lastResponseAt) {
      // We never got feedback from the user about an abandoned cart
      return Number.MAX_VALUE;
    }

    // Calculate the time since the last time we got feedback from the user
    return Date.now() - lastResponseAt.getTime();
  }

  get abandonCartLastPromptedAt(): Date {
    const value = this.get(ABANDON_CART_LAST_PROMPTED_AT_KEY, StorageTarget.Local);
    return value ? new Date(value) : null;
  }
  set abandonCartLastPromptedAt(value: Date) {
    if (!value) {
      this.remove(ABANDON_CART_LAST_PROMPTED_AT_KEY, StorageTarget.Local);
      return;
    }
    this.set(ABANDON_CART_LAST_PROMPTED_AT_KEY, value.toISOString(), StorageTarget.Local);
  }

  get timeSinceAbandonCartLastPrompted(): number {
    const lastPromptedAt = this.abandonCartLastPromptedAt;

    if (!lastPromptedAt) {
      // We never asked the user for feedback about an abandoned cart
      return Number.MAX_VALUE;
    }

    // Calculate the time since the last time we asked the user for feedback
    return Date.now() - lastPromptedAt.getTime();
  }

  get isCookieConsentGiven(): boolean {
    const valueAsString = this.get(IS_COOKIE_CONSENT_GIVEN_KEY, StorageTarget.Cookie);
    return valueAsString === 'true';
  }
  set isCookieConsentGiven(value: boolean) {
    const valueAsString = value ? 'true' : 'false';
    this.set(IS_COOKIE_CONSENT_GIVEN_KEY, valueAsString, StorageTarget.Cookie);
  }

  get visitorId(): string {
    const visitorIdFromStorage = this.get(VISITOR_ID_KEY, StorageTarget.Cookie);
    return visitorIdFromStorage;
  }
  set visitorId(value: string) {
    if (!value) {
      this.remove(VISITOR_ID_KEY, StorageTarget.Cookie);
      return;
    }
    this.set(VISITOR_ID_KEY, value, StorageTarget.Cookie);
  }

  get bookingToken(): string {
    const bookingTokenFromStorage = this.get(BOOKING_TOKEN_KEY, StorageTarget.Cookie);
    return bookingTokenFromStorage;
  }
  set bookingToken(value: string) {
    if (!value) {
      this.remove(BOOKING_TOKEN_KEY, StorageTarget.Cookie);
      return;
    }
    this.set(BOOKING_TOKEN_KEY, value, StorageTarget.Cookie);
  }

  get sessionId(): string {
    const sessionIdFromStorage = this.get(SESSION_ID_KEY, StorageTarget.Session);
    return sessionIdFromStorage;
  }
  set sessionId(value: string) {
    if (!value) {
      this.remove(SESSION_ID_KEY, StorageTarget.Session);
      return;
    }
    this.set(SESSION_ID_KEY, value, StorageTarget.Session);
  }

  get storageLocationId(): string {
    return this.get(STORAGE_LOCATION_ID_KEY, StorageTarget.Local);
  }
  set storageLocationId(value: string) {
    if (!value) {
      this.remove(STORAGE_LOCATION_ID_KEY, StorageTarget.Local);
      return;
    }
    this.set(STORAGE_LOCATION_ID_KEY, value, StorageTarget.Local);
  }

  get payoutProviderAccountId(): string {
    const payoutProviderAccountIdFromStorage = this.get(PAYOUT_PROVIDER_ACCOUNT_ID_KEY, StorageTarget.Local);
    return payoutProviderAccountIdFromStorage;
  }
  set payoutProviderAccountId(value: string) {
    if (!value) {
      this.remove(PAYOUT_PROVIDER_ACCOUNT_ID_KEY, StorageTarget.Local);
      return;
    }
    this.set(PAYOUT_PROVIDER_ACCOUNT_ID_KEY, value, StorageTarget.Local);
  }

  get token(): string {
    // Give priority to token from cookie if supported and available, otherwise try local storage
    const value = this.get(TOKEN_KEY, StorageTarget.Cookie) || this.get(TOKEN_KEY, StorageTarget.Local);

    //
    // Handles an issue where we were storing the user profile JSON instead of the token. When detected, sign the user
    // out so they can sign back in and get a valid token stored
    //
    if (value && value.startsWith('{')) {
      this.clearUserData();
      return null;
    }

    return value;
  }
  set token(value: string) {
    if (!value) {
      // Clear token from both cookie and local storage in case it exists in either or both places
      this.remove(TOKEN_KEY, StorageTarget.Cookie);
      this.remove(TOKEN_KEY, StorageTarget.Local);

      return;
    }
    this.set(TOKEN_KEY, value, StorageTarget.Cookie);
  }

  get profile(): IUser {
    const value = this.get(PROFILE_KEY, StorageTarget.Cookie) || this.get(PROFILE_KEY, StorageTarget.Local);
    if (value) {
      return JSON.parse(value) as IUser;
    }
    return null;
  }
  set profile(value: IUser) {
    if (!value) {
      // Clear user info from both cookie and local storage in case it exists in either or both places
      this.remove(PROFILE_KEY, StorageTarget.Cookie);
      this.remove(PROFILE_KEY, StorageTarget.Local);

      return;
    }
    this.set(PROFILE_KEY, JSON.stringify(value), StorageTarget.Cookie);
  }

  get bookings(): string[] {
    const storedValue = this.get(BOOKINGS_KEY, StorageTarget.Local) || this.get(BOOKINGS_KEY, StorageTarget.Cookie);
    if (storedValue) {
      return JSON.parse(storedValue) as string[];
    }
    return null;
  }
  set bookings(value: string[]) {
    if (!value) {
      this.clearBookings();
      return;
    }
    const expiresAt = new Date(Date.now() + BOOKINGS_COOKIE_LIFETIME);
    this.set(BOOKINGS_KEY, JSON.stringify(value), StorageTarget.Local, expiresAt);
  }

  get trackingData(): Record<string, unknown> {
    const storedValue = this.get(TRACKING_DATA_KEY, StorageTarget.Local);
    if (storedValue) {
      return JSON.parse(storedValue) as Record<string, unknown>;
    }
    return null;
  }
  set trackingData(value: unknown) {
    this.set(TRACKING_DATA_KEY, JSON.stringify(value), StorageTarget.Local);
  }

  get registeredAppTranslations(): Record<string, string[]> {
    const storedValue = this.get(REGISTERED_APP_TRANSLATIONS_KEY, StorageTarget.Local);
    if (storedValue) {
      return JSON.parse(storedValue) as Record<string, string[]>;
    }
    return null;
  }
  set registeredAppTranslations(value: Record<string, string[]>) {
    this.set(REGISTERED_APP_TRANSLATIONS_KEY, JSON.stringify(value), StorageTarget.Local);
  }

  get promoCode(): string {
    return this.get(PROMO_CODE_KEY, StorageTarget.Local);
  }
  set promoCode(value: string) {
    if (!value) {
      this.remove(PROMO_CODE_KEY, StorageTarget.Local);
      return;
    }
    this.set(PROMO_CODE_KEY, value.toUpperCase(), StorageTarget.Local);
  }

  get uiTheme(): ITheme {
    const storedValue = this.get(UI_THEME_KEY, StorageTarget.Local);
    if (storedValue) {
      return JSON.parse(storedValue) as ITheme;
    }
    return null;
  }
  set uiTheme(value: ITheme) {
    if (!value) {
      this.remove(UI_THEME_KEY, StorageTarget.Local);
      return;
    }
    this.set(UI_THEME_KEY, JSON.stringify(value), StorageTarget.Local);
  }

  get uiThemeVersion(): number {
    return Number(this.get(UI_THEME_VERSION_KEY, StorageTarget.Local));
  }
  set uiThemeVersion(value: number) {
    this.set(UI_THEME_VERSION_KEY, String(value), StorageTarget.Local);
  }

  get svgIcons(): string {
    return this.get(SVG_ICONS_KEY, StorageTarget.Local);
  }
  set svgIcons(value: string) {
    this.set(SVG_ICONS_KEY, value, StorageTarget.Local);
  }

  get svgIconsVersion(): number {
    return Number(this.get(SVG_ICONS_VERSION_KEY, StorageTarget.Local));
  }
  set svgIconsVersion(value: number) {
    this.set(SVG_ICONS_VERSION_KEY, String(value), StorageTarget.Local);
  }

  get variant(): string {
    return this.get(VARIANT_KEY, StorageTarget.Cookie);
  }
  set variant(value: string) {
    if (!value) {
      this.remove(VARIANT_KEY, StorageTarget.Cookie);
      return;
    }
    this.set(VARIANT_KEY, value, StorageTarget.Cookie);
  }

  get experimentId(): string {
    return this.get(EXPERIMENT_ID_KEY, StorageTarget.Cookie);
  }
  set experimentId(value: string) {
    if (!value) {
      this.remove(EXPERIMENT_ID_KEY, StorageTarget.Cookie);
      return;
    }
    this.set(EXPERIMENT_ID_KEY, value, StorageTarget.Cookie);
  }

  get bookingOriginView(): string {
    return this.get(BOOKING_ORIGIN_VIEW_KEY, StorageTarget.Session);
  }
  set bookingOriginView(value: string) {
    if (!value) {
      this.remove(BOOKING_ORIGIN_VIEW_KEY, StorageTarget.Session);
      return;
    }
    this.set(BOOKING_ORIGIN_VIEW_KEY, value, StorageTarget.Session);
  }

  get isImpersonator(): boolean {
    const valueAsString = this.get(IS_IMPERSONATED_USER_KEY, StorageTarget.Session);
    return valueAsString === 'true';
  }
  set isImpersonator(value: boolean) {
    const valueAsString = value ? 'true' : 'false';
    if (!value) {
      this.remove(IS_IMPERSONATED_USER_KEY, StorageTarget.Session);
      return;
    }
    this.set(IS_IMPERSONATED_USER_KEY, valueAsString, StorageTarget.Session);
  }

  get language(): string {
    return this.get(LANGUAGE_KEY, StorageTarget.Local);
  }
  set language(value: string) {
    if (!value) {
      this.remove(LANGUAGE_KEY, StorageTarget.Local);
      return;
    }
    this.set(LANGUAGE_KEY, value, StorageTarget.Local);
  }

  get lastOtpRequest(): number {
    const value = this.get(LAST_OTP_REQUEST_KEY, StorageTarget.Session);
    return value ? Number(value) : 0;
  }
  set lastOtpRequest(value: number) {
    if (!value) {
      this.remove(LAST_OTP_REQUEST_KEY, StorageTarget.Session);
      return;
    }
    this.set(LAST_OTP_REQUEST_KEY, String(value), StorageTarget.Session);
  }

  get lastOtpRecipient(): string {
    return this.get(LAST_OTP_RECIPIENT_KEY, StorageTarget.Session);
  }
  set lastOtpRecipient(value: string) {
    if (!value) {
      this.remove(LAST_OTP_RECIPIENT_KEY, StorageTarget.Session);
      return;
    }
    this.set(LAST_OTP_RECIPIENT_KEY, value, StorageTarget.Session);
  }

  get reloadedAt(): string {
    return this.get(RELOADED_AT_KEY, StorageTarget.Session);
  }
  set reloadedAt(value: string) {
    if (!value) {
      this.remove(RELOADED_AT_KEY, StorageTarget.Session);
      return;
    }
    this.set(RELOADED_AT_KEY, value, StorageTarget.Session);
  }

  public get(key: string, target: StorageTarget): string {
    try {
      switch (target) {
        case StorageTarget.Cookie:
          if (this.isCookieStorageSupported || !this.isInitialized) {
            return this.getCookie(key);
          } // Otherwise fall through to local storage

        case StorageTarget.Local:
          if (this.isLocalStorageSupported || !this.isInitialized) {
            return localStorage.getItem(key);
          } // Otherwise fall through to session storage

        case StorageTarget.Session:
          if (this.isSessionStorageSupported || !this.isInitialized) {
            return sessionStorage.getItem(key);
          }
      }
    } catch (e) {
      // Suppress errors in incognito mode
    }
    return null;
  }

  public set(key: string, value: string, target: StorageTarget, expiresAt?: Date) {
    try {
      switch (target) {
        case StorageTarget.Cookie:
          if (this.isCookieStorageSupported || !this.isInitialized) {
            return this.setCookie(key, value, expiresAt);
          } // Otherwise fall through to local storage

        case StorageTarget.Local:
          if (this.isLocalStorageSupported || !this.isInitialized) {
            return localStorage.setItem(key, value);
          } // Otherwise fall through to session storage

        case StorageTarget.Session:
          if (this.isSessionStorageSupported || !this.isInitialized) {
            return sessionStorage.setItem(key, value);
          }
      }
    } catch (e) {
      // Suppress errors in incognito mode
    }
  }

  public remove(key: string, target: StorageTarget) {
    try {
      switch (target) {
        case StorageTarget.Cookie:
          if (this.isCookieStorageSupported || !this.isInitialized) {
            this.removeCookie(key);
            break;
          } // Otherwise fall through to local storage

        case StorageTarget.Local:
          if (this.isLocalStorageSupported || !this.isInitialized) {
            localStorage.removeItem(key);
            break;
          } // Otherwise fall through to session storage

        case StorageTarget.Session:
          if (this.isSessionStorageSupported || !this.isInitialized) {
            sessionStorage.removeItem(key);
            break;
          }
      }
    } catch (e) {
      // Suppress errors (e.g. in incognito mode)
    }
  }

  public clear(target: StorageTarget) {
    try {
      switch (target) {
        case StorageTarget.Cookie:
          if (this.isCookieStorageSupported) {
            this.clearCookies();
          }
          break;

        case StorageTarget.Local:
          if (this.isLocalStorageSupported) {
            localStorage.clear();
          }
          break;

        case StorageTarget.Session:
          if (this.isSessionStorageSupported) {
            sessionStorage.clear();
          }
          break;
      }
    } catch {
      // Suppress errors in incognito mode
    }
  }

  public clearUserData() {
    this.remove(PROFILE_KEY, StorageTarget.Local);
    this.remove(PROFILE_KEY, StorageTarget.Cookie);

    this.remove(TOKEN_KEY, StorageTarget.Local);
    this.remove(TOKEN_KEY, StorageTarget.Cookie);

    this.remove(IS_IMPERSONATED_USER_KEY, StorageTarget.Session);
    this.remove(STORAGE_LOCATION_ID_KEY, StorageTarget.Local);

    this.remove(PAYOUT_PROVIDER_ACCOUNT_ID_KEY, StorageTarget.Local);

    this.clearPromoCode();
  }

  public clearPromoCode() {
    this.remove(PROMO_CODE_KEY, StorageTarget.Local);
  }

  public clearBookings() {
    this.remove(BOOKINGS_KEY, StorageTarget.Local);
    this.remove(BOOKINGS_KEY, StorageTarget.Cookie);
  }

  public addBooking(bookingId: string) {
    // Get the list of bookings from storage
    const bookings = this.bookings || [];
    this.clearBookings();

    // Only add the new booking if it's not already in the list
    if (bookings.indexOf(bookingId) < 0) {
      // Append the new booking to the end of the list
      bookings.push(bookingId);

      // Only keep the last 10 bookings
      if (bookings.length > 10) {
        bookings.shift();
      }
    }

    // Update the list of bookings in storage
    this.bookings = bookings;
  }

  public removeBooking(bookingId: string) {
    const bookings = this.bookings || [];
    this.clearBookings();

    const index = bookings.indexOf(bookingId);
    if (index > -1) {
      bookings.splice(index, 1);
    }

    this.bookings = bookings;
  }

  private getCookie(key: string) {
    const keyEq = `${encodeURIComponent(key)}=`;

    const decodedCookie = decodeURIComponent(this.documentService.document.cookie);
    const cookies = decodedCookie.split(';');

    for (let cookie of cookies) {
      cookie = cookie.trimStart();

      if (cookie.indexOf(keyEq) === 0) {
        return cookie.substring(keyEq.length, cookie.length);
      }
    }
    return '';
  }

  private setCookie(key: string, value: string, expiresAt?: Date) {
    if (!expiresAt) {
      expiresAt = new Date(Date.now() + DEFAULT_COOOKIE_LIFETIME);
    }

    key = encodeURIComponent(key);
    value = encodeURIComponent(value);

    const cookie = `${key}=${value}; expires=${expiresAt.toUTCString()}; domain=${this.config.domain}; path=/;`;

    this.documentService.document.cookie = cookie;
  }

  private removeCookie(key: string) {
    const cookie = `${encodeURIComponent(key)}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; domain=${this.config.domain}; path=/;`;
    this.documentService.document.cookie = cookie;
  }

  // This function was taken from here: https://stackoverflow.com/a/33366171/13734938
  private clearCookies() {
    const cookies = document.cookie.split('; ');

    for (const cookie of cookies) {
      const domainParts = this.documentService.document.location.hostname.split('.');

      while (domainParts.length > 0) {
        const key = encodeURIComponent(cookie.split(';')[0].split('=')[0]);
        const domain = domainParts.join('.');
        const cookieBase = `${key}=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=${domain}; path=`;

        const pathElements = location.pathname.split('/');
        document.cookie = cookieBase + '/';

        while (pathElements.length > 0) {
          document.cookie = cookieBase + pathElements.join('/');
          pathElements.pop();
        }
        domainParts.shift();
      }
    }
  }

  private isStorageTargetSupported(target: StorageTarget): boolean {
    let result = false;

    try {
      this.set(DUMMY_KEY, DUMMY_VALUE, target);

      // Enable support if we get the expected value back
      result = this.get(DUMMY_KEY, target) === DUMMY_VALUE;

      this.remove(DUMMY_KEY, target);
    } catch {
      // If we fail, assume lack of support
    }

    return result;
  }
}
