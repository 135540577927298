import { Injectable } from '@angular/core';
import { ApplePayEventsEnum, GooglePayEventsEnum, Stripe as CapacitorStripe } from '@capacitor-community/stripe';
import { AppConfig } from '@luggagehero/shared/app-settings/data-access';
import { InitiatePaymentResult, IPaymentRecord } from '@luggagehero/shared/interfaces';
import { SharedPaymentService } from '@luggagehero/shared/services/payments';
import { SharedWindowService } from '@luggagehero/shared/services/window';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class WalletPaymentService {
  constructor(
    private paymentService: SharedPaymentService,
    private translateService: TranslateService,
    private windowService: SharedWindowService,
  ) {}

  async chargeApplePay(
    storageLocationId?: string,
    orderId?: string,
    bookingId?: string,
    payment?: IPaymentRecord<InitiatePaymentResult>,
  ): Promise<InitiatePaymentResult> {
    if (!payment) {
      const returnUrl = this.windowService.isNative ? AppConfig.STRIPE_PAYPAL_NATIVE_RETURN_URL : null;
      payment = await this.paymentService.initiatePayment('stripe', storageLocationId, orderId, bookingId, returnUrl);
    }

    await CapacitorStripe.createApplePay({
      paymentIntentClientSecret: payment.data.client_secret,
      merchantIdentifier: AppConfig.APPLE_PAY_MERCHANT_ID,
      requiredShippingContactFields: ['emailAddress'],
      paymentSummaryItems: [
        {
          label: this.translateService.instant(AppConfig.PAYMENT_VERIFICATION_LABEL) as string,
          amount: payment.data.amount || 0,
        },
      ],
      countryCode: AppConfig.MERCHANT_COUNTRY_CODE,
      currency: payment.data.currency || AppConfig.MERCHANT_CURRENCY,
    });
    const result = await CapacitorStripe.presentApplePay();

    if (result.paymentResult !== ApplePayEventsEnum.Completed) {
      throw new Error('Apple pay failed');
    }

    return payment.data;
  }

  async chargeGooglePay(
    storageLocationId?: string,
    orderId?: string,
    bookingId?: string,
    payment?: IPaymentRecord<InitiatePaymentResult>,
  ): Promise<InitiatePaymentResult> {
    if (!payment) {
      const returnUrl = this.windowService.isNative ? AppConfig.STRIPE_PAYPAL_NATIVE_RETURN_URL : null;
      payment = await this.paymentService.initiatePayment('stripe', storageLocationId, orderId, bookingId, returnUrl);
    }

    await CapacitorStripe.createGooglePay({
      paymentIntentClientSecret: payment.data.client_secret,
      currency: payment.data.currency || AppConfig.MERCHANT_CURRENCY,
    });

    const result = await CapacitorStripe.presentGooglePay();

    if (result.paymentResult !== GooglePayEventsEnum.Completed) {
      throw new Error('Google pay failed');
    }

    return payment.data;
  }
}
