/**
 * Enum that represents the steps of the guest dropoff process.
 */
export enum GuestDropoffStep {
  /**
   * Verifies that the user is a guest staying at the hotel. Can be through a given third-party provider (property or
   * hotel management system) or just by asking for basic information like last name and arrival or room number etc.
   */
  VerifyGuest = 'VerifyGuest',
  /**
   * Used to segment the guests e.g. based on arrival and departure information and loyalty program membership.
   */
  SegmentGuest = 'SegmentGuest',
  /**
   * Allows guests to select the bags that they want to drop off.
   */
  SelectBags = 'SelectBags',
  /**
   * Allows guests to take a photo of the bags that they want to drop off.
   */
  TakePhoto = 'TakePhoto',
  /**
   * Allows the guests to choose the storage products that they want (e.g. hourly/daily storage).
   */
  SelectStorage = 'SelectStorage',
  /**
   * Allows the guests to choose the coverage that they want.
   */
  SelectCoverage = 'SelectCoverage',
  /**
   * Allows the guests to leave a tip for the service.
   */
  LeaveTip = 'LeaveTip',
  /**
   * Allows the guests to choose the payment method that they want to use.
   */
  SelectPaymentMethod = 'SelectPaymentMethod',
  /**
   * Provides a booking summary and confirmation of the completed dropoff process.
   */
  Confirmation = 'Confirmation',
}
