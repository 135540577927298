<div class="step-progress" *ngIf="steps">
  <div class="line"></div>

  <div class="container-fluid lh-container-fluid-sm">
    <div class="steps">
      <!-- HACK: Using ngStyle to set descending z-index for steps to avoid progress line covering previous steps -->
      <div
        *ngFor="let step of countableSteps; let i = index"
        class="step"
        [class.href]="allowStepNavigation && i !== currentStepIndex"
        [class.completed]="currentStepIndex >= i"
        [class.first-step]="i === 0"
        [class.last-step]="i >= steps.length - 1"
        [ngStyle]="{ 'z-index': 100 - i }"
        (click)="goToStep(i)"
      >
        <div class="line"></div>
        <div class="circle">{{ i }}</div>
      </div>
    </div>
  </div>
</div>

<div class="step-container container-fluid lh-container-fluid-sm" [class.footer-padding]="showNavigation">
  <!-- The step component will be rendered here -->
  <div #stepContainer></div>
</div>

<div class="nav-footer" *ngIf="showNavigation">
  <div class="container-fluid lh-container-fluid-sm">
    <div *ngIf="steps" class="nav-buttons">
      <button class="btn btn-lg btn-link" (click)="goBack()" [disabled]="!canGoBack">
        {{ backCallToAction || 'BACK' | translate }}
      </button>
      <button
        class="btn btn-lg btn-primary"
        (click)="goForward()"
        [disabled]="!canGoForward && !canSkip"
        [hidden]="!hasNextStep"
      >
        {{ (canSkip && !canGoForward ? 'SKIP' : forwardCallToAction) | translate }}
      </button>
    </div>
  </div>
</div>
