import { Component, EventEmitter, inject, OnInit, Output, ViewChild } from '@angular/core';
import { AppConfig } from '@luggagehero/shared/app-settings/data-access';
import { Config } from '@luggagehero/shared/environment';
import {
  Booking,
  BookingAddOns,
  GUEST_DAILY_RATE_PRODUCT_KEY,
  GUEST_HOURLY_RATE_PRODUCT_KEY,
  GUEST_INSURANCE_FEE_PRODUCT_KEY,
  GUEST_PREMIUM_INSURANCE_FEE_PRODUCT_KEY,
  ILuggage,
  INSURANCE_PRODUCT_KEY,
  IPaymentRecord,
  IPricing,
  ITimePeriod,
  LegacyOrderLine,
  PAYMENT_METHOD_TRANSFORMER_SERVICE,
  PaymentMethodTransformerService,
  ProductInfo,
  StorageCriteria,
  WALK_IN_DAILY_RATE_PRODUCT_KEY,
  WALK_IN_HOURLY_RATE_PRODUCT_KEY,
  WALK_IN_INSURANCE_FEE_PRODUCT_KEY,
} from '@luggagehero/shared/interfaces';
import { SharedOrderService } from '@luggagehero/shared/services/orders';
import { SharedPaymentService } from '@luggagehero/shared/services/payments';
import { SharedPromoCodeService } from '@luggagehero/shared/services/promo-codes';
import { SharedStorageService } from '@luggagehero/shared/services/storage';
import { SharedStripeService } from '@luggagehero/shared/services/stripe';
import { SharedWindowService } from '@luggagehero/shared/services/window';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';

import { PaymentMethodSelectorBaseComponent } from '../../../../ui/index';
import { DropoffStepBaseComponent } from './dropoff-step.base-component';

interface Footer {
  text: string;
  callToAction?: string;
  action?: () => void;
  link?: string;
  isError?: boolean;
}

@Component({ template: '' })
export abstract class OrderStepBaseComponent extends DropoffStepBaseComponent implements OnInit {
  protected paymentMethodTransformerService = inject<PaymentMethodTransformerService>(
    PAYMENT_METHOD_TRANSFORMER_SERVICE,
  );

  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() public result = new EventEmitter<Booking>();
  @ViewChild('paymentMethodSelector') paymentMethodSelector: PaymentMethodSelectorBaseComponent;

  public isAddingPaymentCard = false;
  public selectedProducts: ProductInfo[];
  public isPricingModelSelectorForGuestsEnabled = AppConfig.IS_PRICING_MODEL_SELECTOR_FOR_GUESTS_ENABLED;

  public minBagsWarning: string;

  // TODO: Translate this
  public confirmNumberOfBagsMessage =
    'You did not adjust the number of bags. Do you want to continue and drop off 1 bag?';
  public numberOfBagsChanged = false;

  private initialNumberOfBags: number;

  private _paymentMethod: IPaymentRecord;
  private _paymentMethods: IPaymentRecord[];

  private _hourlyRate: number;
  private _dailyRate: number;
  private _currency: string;

  private updateOrderTimeout: NodeJS.Timeout;
  private isInitialized = false;
  private _isUpdating = false;
  private isZeroPriceShop = false;

  constructor(
    private orderService: SharedOrderService,
    private paymentService: SharedPaymentService,
    private stripeService: SharedStripeService,
    private promoCodeService: SharedPromoCodeService,
    private windowService: SharedWindowService,
    private storage: SharedStorageService,
    private translate: TranslateService,
  ) {
    super();
  }

  get isUpdating(): boolean {
    return this._isUpdating;
  }
  set isUpdating(value: boolean) {
    this._isUpdating = value;
    this.cd.markForCheck();
  }

  public get pricing(): IPricing {
    return this.storageLocation.pricing;
  }

  public get addOns(): BookingAddOns {
    return {
      insurance: this.priceService.insuranceSelected,
      freeCancellation: this.priceService.freeCancellationFeeSelected,
      securitySeals: this.priceService.numberOfSecuritySeals,
      walkIn: this.bookingDraft.addOns.guest ? false : this.bookingDraft.addOns.walkIn,
      guestMovement: this.bookingDraft.addOns.guestMovement,
      guest: this.bookingDraft.addOns.guest,
    };
  }

  public get isGuest(): boolean {
    return this.bookingDraft.addOns?.guest || false;
  }

  public get criteria(): StorageCriteria {
    return this.criteriaService.currentOrDefault;
  }

  public get luggage(): ILuggage {
    return this.criteria.luggage;
  }

  public get numberOfBags(): number {
    return this.luggage.hand + this.luggage.normal;
  }

  public get maxBags(): number {
    return AppConfig.DROP_OFF_MAX_BAGS || this.storageLocation.capacity.normal;
  }

  public get period(): ITimePeriod {
    return this.criteria.period;
  }

  public get from(): Date {
    return this.criteria.period.from;
  }

  public get to(): Date {
    return this.criteria.period.to;
  }

  public get promoCodeCta(): string {
    return this.promoCodeService.appliedDiscount ? 'CHANGE_DISCOUNT_CODE' : 'HAVE_A_VOUCHER_OR_PROMO_CODE_MINI';
  }

  public get showNumberOfReviews(): boolean {
    return this.numberOfReviews > 1;
  }

  public get averageRating(): number {
    return this.storageLocation.stats ? this.storageLocation.stats.averageRating : AppConfig.GLOBAL_AVERAGE_RATING;
  }

  public get numberOfReviews(): number {
    if (!this.storageLocation || !this.storageLocation.stats) {
      return 0;
    }
    return this.storageLocation.stats.numberOfRatings;
  }

  public get numberOfStars(): number {
    if (AppConfig.IS_FULL_STAR_RATING_DISABLED) {
      return 1;
    }
    return 5;
  }

  public get storageLocationImage(): string {
    if (!this.storageLocation || !this.storageLocation.images || this.storageLocation.images.length === 0) {
      return 'assets/no-image.jpg';
    }
    return `${Config.environment.IMAGES_BASE_URL}/images/medium/${this.storageLocation.images[0]}`;
  }

  public get poiList() {
    if (!this.storageLocation || !this.storageLocation.poiList) {
      return [];
    }
    const pois = [...this.storageLocation.poiList];

    return pois.map((poi) => {
      // HACK: Remove POI_ prefix which is added to some POI names
      poi.poiName = poi.poiName.replace('POI_', '');

      return poi;
    });
  }

  public get isNearestPoiMostRelevant(): boolean {
    if (this.poiList.length === 0) {
      // No POIs nearby
      return false;
    }
    if (this.criteria.location.type === 'official') {
      // The center of the city is not relevant, use POI as reference point instead
      return true;
    }
    // If distance to current location is more than 500m, consider nearest POI more relevant
    return this.storageLocation.distance > 500;
  }

  public get distance(): number {
    if (!this.storageLocation) {
      return undefined;
    }
    if (this.isNearestPoiMostRelevant) {
      return this.poiList[0].distance;
    }
    return this.storageLocation.distance;
  }

  public get paymentMethod(): IPaymentRecord {
    return this._paymentMethod;
  }
  public set paymentMethod(value: IPaymentRecord) {
    if (this._paymentMethod === value) {
      return;
    }
    this._paymentMethod = value;
    this.cd.markForCheck();
  }

  public get paymentMethods(): IPaymentRecord[] {
    return this._paymentMethods;
  }
  public set paymentMethods(value: IPaymentRecord[]) {
    this._paymentMethods = value;
    this.paymentMethod = value[0];
  }

  public get hourlyRate(): number {
    return this._hourlyRate;
  }
  public set hourlyRate(value: number) {
    this._hourlyRate = value;
    this.cd.markForCheck();
  }

  public get dailyRate(): number {
    return this._dailyRate;
  }
  public set dailyRate(value: number) {
    this._dailyRate = value;
    this.cd.markForCheck();
  }

  public get currency(): string {
    return this._currency;
  }
  public set currency(value: string) {
    this._currency = value;
    this.cd.markForCheck();
  }

  public get isPaymentDue(): Observable<boolean> {
    //
    // For now considering all bookings as not paid up front as this aligns better with what we are actually doing on
    // the backend.
    //
    // return this.pricingModel.pipe(map((value) => value === 'daily'));
    return of(false);
  }

  public get maxCapacity(): number {
    if (!this.storageLocation) {
      return 0;
    }
    return this.storageLocation.capacity.normal;
  }

  public get isPaymentMethodSelectorVisible(): boolean {
    if (this.isZeroPriceShop) {
      return false;
    }
    if (!this.paymentMethod && this.stripeService.isWalletPaymentAvailable) {
      // No existing payment method and wallet payment is available
      return true;
    }
    return false;
  }

  public get isWalletPaymentVisible(): boolean {
    return this.paymentMethodSelector?.isWalletPaymentVisible;
  }

  public get isPaymentCardValidationVariant(): boolean {
    return this.storage.variant === AppConfig.EXPERIMENT_VARIANTS.paymentCardValidation;
  }

  public get isAddPaymentCardButtonVisible(): boolean {
    return this.isPaymentMethodSelectorVisible && this.isWalletPaymentVisible;
  }

  public get isLoggedIn(): boolean {
    return this.userService.isLoggedIn;
  }

  public get isPricingModelSelectorEnabled(): boolean {
    if (AppConfig.SHOPS_WITH_HOURLY_RATE_DISABLED.includes(this.storageLocation._id)) {
      return false;
    }

    if (this.isGuest && !this.isPricingModelSelectorForGuestsEnabled) {
      return false;
    }

    return true;
  }

  public get footer(): Footer {
    if (this.paymentMethod) {
      return {
        text: `${this.translate.instant('USING_PAYMENT_CARD')} ${this.paymentMethodTransformerService.transform(this.paymentMethod)}`,
        callToAction: this.translate.instant('CHANGE') as string,
        action: () => void this.goForward(true),
      };
    }

    if (!this.isLoggedIn && this.isWalletPaymentVisible) {
      return {
        text: this.translate.instant('ACCEPT_TERMS_PART_1') as string,
        callToAction: this.translate.instant('ACCEPT_TERMS_PART_2') as string,
        // TODO: Move this to AppConfig
        link: 'https://luggagehero.com/terms-conditions/',
      };
    }

    return null;
  }

  protected get useDefaultNavigation(): boolean {
    return false;
  }

  public async ngOnInit() {
    super.ngOnInit();

    // Don't reuse previous orders
    this.orderService.clear();

    this.subscriptions.push(this.priceService.pricingModel$.subscribe(() => void this.updateOrder()));
    this.subscriptions.push(this.promoCodeService.promoCode.subscribe(() => void this.updateOrder()));
    this.subscriptions.push(this.criteriaService.changeRequested.subscribe(() => void this.updateOrder()));

    if (AppConfig.SHOPS_WITH_HOURLY_RATE_DISABLED.includes(this.storageLocation._id)) {
      this.priceService.changePricing('daily');
    }

    await this.updateOrder(true);
    this.isInitialized = true;
  }

  public onOrderLineChange(orderLine: LegacyOrderLine) {
    // HACK: Using a hardcoded product id to add/remove insurance for now
    switch (orderLine.product._id) {
      case WALK_IN_INSURANCE_FEE_PRODUCT_KEY:
      case GUEST_INSURANCE_FEE_PRODUCT_KEY:
      case GUEST_PREMIUM_INSURANCE_FEE_PRODUCT_KEY:
      case INSURANCE_PRODUCT_KEY:
        this.priceService.insuranceSelected = orderLine.selected ? true : false;
        break;
    }

    void this.updateOrder(true);
  }

  public goForward(deferPaymentMethod = false) {
    if (!this.numberOfBagsChanged && AppConfig.IS_USER_CONFIRMATION_OF_NO_BAGS_CHANGE_ENABLED) {
      try {
        this.windowService.confirm(this.translate.instant(this.confirmNumberOfBagsMessage));
      } catch (err) {
        // User cancelled
        return;
      }
    }

    if (deferPaymentMethod) {
      // Payment method will be selected on the next step
      this.bookingDraft.paymentMethodId = null;
    } else if (this.paymentMethod?.id) {
      this.bookingDraft.paymentMethodId = this.paymentMethod?.id;
    }

    const bookingCategory = this.bookingDraft.addOns.guest ? 'guest' : 'walk_in';

    // TODO: Move this to the payment step where the booking is created
    this.bookingDraft.metadata = this.bookingService.compileMetaData(
      bookingCategory,
      this.storageLocation,
      this.bookingDraft.addOns,
    );

    super.goForward();
  }

  public onLoginEvent() {
    void this.loadPaymentMethods();
  }

  private async loadPaymentMethods() {
    if (!this.isLoggedIn) {
      return;
    }

    try {
      // Get existing payment methods for the user
      const paymentMethods = await this.paymentService.getPaymentMethods();
      this.paymentMethods = paymentMethods;
    } catch (err) {
      if ((err as { status?: number })?.status === 401) {
        // Token issue, user needs to log in again
        this.userService.logout();
      }
    }
  }

  private async updateOrder(immediate = false) {
    if (!this.isInitialized && !immediate) {
      return;
    }

    if (immediate) {
      await this._updateOrder();
      return;
    }

    //
    // This is an update of the order, so debounce to avoid firing multiple requests to the API when the user is making
    // multiple consecutive changes
    //
    this.isUpdating = true;

    clearTimeout(this.updateOrderTimeout);

    return new Promise<void>((resolve, reject) => {
      this.updateOrderTimeout = setTimeout(() => {
        void (async () => {
          try {
            await this._updateOrder();
            resolve();
          } catch (err) {
            reject(err);
          } finally {
            this.isUpdating = false;
          }
        })();
      }, AppConfig.ORDER_DEBOUNCE_TIME);
    });
  }

  private async _updateOrder() {
    if (!this.initialNumberOfBags) {
      // This is the first time an order is generated, so record the initial number of bags
      this.initialNumberOfBags = this.numberOfBags;
    } else if (this.initialNumberOfBags !== this.numberOfBags) {
      // Record that the user has changed the number of bags
      this.numberOfBagsChanged = true;
    }

    const minBags = this.orderService.getMinimumBags(true);
    const luggage = {
      normal: Math.max(this.luggage.normal, minBags),
      hand: this.luggage.hand,
    };

    this.bookingDraft.order = await this.orderService.generateQuote(
      this.storageLocation._id,
      this.priceService.pricingModel,
      luggage,
      this.period,
      this.addOns,
      undefined,
      this.promoCodeService.appliedDiscount?.code,
    );

    if (this.bookingDraft.order && this.bookingDraft.order.orderLines.length > 0) {
      let totalUnitPriceOfNonOptionalItems = 0;

      this.bookingDraft.order.orderLines.forEach((orderLine) => {
        if (!orderLine.optional) {
          totalUnitPriceOfNonOptionalItems += orderLine.unitPrice;
        }
      });

      if (totalUnitPriceOfNonOptionalItems === 0) {
        this.isZeroPriceShop = true;
      }
    }

    if (AppConfig.IS_PRICE_ON_PRICING_MODEL_SELECTOR_ENABLED) {
      // Set hourly and daily rates and currency for pricing model selector binding
      if (this.orderService.latestQuote.orderRequest.addOns.walkIn) {
        this.hourlyRate = this.orderService.latestProductList.products.find(
          (p) => p.key === WALK_IN_HOURLY_RATE_PRODUCT_KEY,
        )?.cost;
        this.dailyRate = this.orderService.latestProductList.products.find(
          (p) => p.key === WALK_IN_DAILY_RATE_PRODUCT_KEY,
        )?.cost;
      } else if (this.orderService.latestQuote.orderRequest.addOns.guest) {
        this.hourlyRate = this.orderService.latestProductList.products.find(
          (p) => p.key === GUEST_HOURLY_RATE_PRODUCT_KEY,
        )?.cost;
        this.dailyRate = this.orderService.latestProductList.products.find(
          (p) => p.key === GUEST_DAILY_RATE_PRODUCT_KEY,
        )?.cost;
      }
    }
    this.currency = this.orderService.latestProductList.currency;

    this.minBagsWarning = this.orderService.getMinimumBagsWarning(true, this.luggage);

    this.bookingDraft.addOns = this.addOns;
    this.bookingDraft.discountCode = this.promoCodeService.appliedDiscount?.code;

    //
    // By providing a storage location id we tell the server to validate the payment card with an up front payment if
    // necessary for the given storage location
    //
    const storageLocationId = this.storageLocation._id;
    const paymentId = this.bookingDraft.payment?.data.id;
    const orderId = this.bookingDraft.order?._id;
    const returnUrl = this.windowService.isNative ? AppConfig.STRIPE_PAYPAL_NATIVE_RETURN_URL : null;

    // Initiate payment so we know what amount, if any, will be paid or reserved up front for this order
    this.bookingDraft.payment = paymentId
      ? await this.paymentService.reinitiatePayment('stripe', paymentId, storageLocationId, orderId)
      : await this.paymentService.initiatePayment('stripe', storageLocationId, orderId, undefined, returnUrl);

    this.canGoForward = true;
  }
}
