import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { NgModel } from '@angular/forms';
import { Booking } from '@luggagehero/shared/interfaces';
import { SharedNotificationService } from '@luggagehero/shared/services/notification';
import { SharedOrderService } from '@luggagehero/shared/services/orders';
import { SharedPromoCodeService } from '@luggagehero/shared/services/promo-codes';
import { TranslateService } from '@ngx-translate/core';

import { StringUtil } from '../../../../../utils/string.util';
import { DropoffStepBaseComponent } from './dropoff-step.base-component';

interface TipOptions {
  amount: number;
  label: string;
}

@Component({ template: '' })
export abstract class TipStepBaseComponent extends DropoffStepBaseComponent implements OnInit {
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() public result = new EventEmitter<Booking>();
  @ViewChild('customTip') public customTipModel: NgModel;
  @ViewChild('customTipInput') public customTipInput: ElementRef<HTMLInputElement>;

  public showTip = true;
  public tipOptions: TipOptions[] = [];
  public isCustomTipVisible = false;
  private _tip: number;

  constructor(
    private orderService: SharedOrderService,
    private notify: SharedNotificationService,
    private promoCodeService: SharedPromoCodeService,
    private translate: TranslateService,
  ) {
    super();
  }

  get isCustomTipInvalid(): boolean {
    if (!this.isCustomTipVisible) {
      return false;
    }
    return this.customTipModel.invalid || this.tip === undefined;
  }

  get currencySymbol(): string {
    return StringUtil.getCurrencySymbol(this.bookingDraft.order.currency);
  }

  get tip(): number {
    return this._tip;
  }
  set tip(value: number) {
    if (typeof value === 'string') {
      // The binding to the input element gives us a string value, so we need to convert it to a number
      value = value && !isNaN(value) ? Number(value) : undefined;
    }
    this._tip = value;
    this.setGoForward();
    this.cd.markForCheck();
  }

  get maxTip(): number {
    if (this.bookingDraft.order?.tip?.maxTip > 0) {
      return this.bookingDraft.order.tip.maxTip;
    }
  }

  private async updateOrderIfNeeded() {
    if (this.tip === undefined) {
      return;
    }

    this.bookingDraft.addOns.tip = this.tip;
    this.bookingDraft.order = await this.orderService.generateQuote(
      this.storageLocation._id,
      this.priceService.pricingModel,
      this.criteria.luggage,
      this.criteria.period,
      this.bookingDraft.addOns,
      undefined,
      this.promoCodeService.appliedDiscount?.code,
    );
  }

  public toggleCustomTip() {
    this.isCustomTipVisible = !this.isCustomTipVisible;
    this.cd.markForCheck();

    if (this.isCustomTipVisible) {
      this.tip = undefined;
      setTimeout(() => this.customTipInput.nativeElement.focus(), 1);
    }
  }

  public get forwardCallToAction(): string {
    return 'CONTINUE';
  }

  protected get useDefaultNavigation(): boolean {
    return true;
  }

  public setTipAmount(value: number) {
    this.tip = value;
    this.isCustomTipVisible = false;
  }

  private setGoForward() {
    this.canGoForward = this.tip !== undefined && this.tip !== null;
  }

  public ngOnInit() {
    super.ngOnInit();

    const currency = this.bookingDraft.order.currency;

    if (this.bookingDraft.order.tip?.options) {
      this.tipOptions = this.bookingDraft.order.tip.options.map((amount) => {
        return { amount, label: this.priceService.format(amount, currency) };
      });
    }

    this.canGoForward = false;
  }

  get isTipTooLarge(): boolean {
    return this.tip > this.maxTip;
  }

  get maxTipFormatted(): string {
    return this.priceService.format(this.maxTip, this.bookingDraft.order.currency);
  }

  public async goForward() {
    // validate tip and update the order
    //this.canGoForward = false;
    if (this.tip === undefined) {
      return;
    }
    if (this.isTipTooLarge) {
      this.notify.error(
        `${this.translate.instant('TIP_NO_MORE_THAN_X')} ${this.bookingDraft.order.currency} ${this.maxTip}`,
      );

      return;
    }
    await this.updateOrderIfNeeded();
    super.goForward();
  }
}
