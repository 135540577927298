import { environment } from './env/environment';
import { EnvConfig } from './environment.interface';

export class Config {
  // DEBUG settings are instance variables and not static anymore
  public static debug = {
    LEVEL_1: false, // .info only
    LEVEL_2: false, // .warn only
    LEVEL_3: false, // .error only
    LEVEL_4: true, // .log + all the above
  };

  private static hasLoggedEnvironment = false;

  // Get environment config dynamically
  public static get environment(): EnvConfig {
    if (!this.hasLoggedEnvironment && environment.ENV !== 'PRODUCTION') {
      this.hasLoggedEnvironment = true;
      console.log('ENVIRONMENT', environment);
    }

    return environment;
  }

  // Supported languages
  public static get supportedLanguages() {
    return [
      { code: 'en', title: 'English' },
      { code: 'es', title: 'Español' },
      { code: 'fr', title: 'Français' },
      { code: 'it', title: 'Italiano' },
      { code: 'de', title: 'Deutsch' },
      { code: 'da', title: 'Dansk' },
      { code: 'sv', title: 'Svenska' },
      { code: 'nb', title: 'Norsk' },
      { code: 'nl', title: 'Nederlands' },
      { code: 'pl', title: 'Polskie' },
      { code: 'pt', title: 'Portuguesa' },
      { code: 'ru', title: 'Pусский' },
    ];
  }

  // Environment checks
  public static get isProduction(): boolean {
    return environment.ENV === 'PRODUCTION';
  }

  public static get isStaging(): boolean {
    return environment.ENV === 'STAGING';
  }

  public static get isDevelopment(): boolean {
    return environment.ENV === 'DEV';
  }

  // Debug mode checks
  public static get isDebugMode(): boolean {
    return Object.values(this.debug).some((level) => level);
  }
}
