<div class="find">
  <lh-traveler-shops-ui-black-navbar class="black-navbar"></lh-traveler-shops-ui-black-navbar>

  <lh-traveler-feature-navbar [(showMap)]="showMap" [(criteria)]="criteria" class="main-navbar" />
  <lh-traveler-shops-ui-main-toolbar
    class="main-toolbar"
    [(showMap)]="showMap"
    [distanceUnit]="distanceUnit"
    (distanceUnitChangeRequest)="toggleDistanceUnit()"
    [isHotelFilterVisible]="isHotelFilterVisible"
    [isOpen24HoursFilterVisible]="isOpen24HoursFilterVisible"
    [isMultiDayStorageFilterVisible]="isMultiDayStorageFilterVisible"
    [isWifiAvailableFilterVisible]="isWifiAvailableFilterVisible"
    (toggleOpenAllDayFilter)="toggleOpenAllDayFilter.emit()"
    (toggleMultiDayStorageFilter)="toggleMultiDayStorageFilter.emit()"
    (toggleWifiAvailableFilter)="toggleWifiAvailableFilter.emit()"
    (toggleHotelsFilter)="toggleHotelsFilter.emit()"
    [hotelsFilterEnabled]="hotelsFilterEnabled"
    [openAllDayFilterEnabled]="openAllDayFilterEnabled"
    [multiDayStorageFilterEnabled]="multiDayStorageFilterEnabled"
    [wifiAvailableFilterEnabled]="wifiAvailableFilterEnabled"
  />

  <div class="main-navbar-bg" [ngClass]="{ 'is-map-view': showMap }"></div>

  <div class="sidebar" [ngClass]="{ show: !showMap }">
    <!-- Shop list -->
    <div class="shop-list">
      @if (!isLoading && isNoShopsAvailable) {
        <div class="message-bar">
          <div>{{ 'NO_SHOPS_FOUND' | lhTranslate }}.</div>
        </div>
      }

      @if (isLoading) {
        <lh-shared-ui-spinner [visible]="true"></lh-shared-ui-spinner>
      } @else {
        <div class="shop-items">
          @for (shop of listShops; track shop.shopItem) {
            <lh-traveler-shops-ui-shop-card
              [isHighlighted]="highlightShop === shop.id"
              [recommendedTag]="shop.recommendedTag"
              [title]="shop.title"
              [shopRating]="shop.shopRating"
              [distance]="shop.distance"
              [pricingDaily]="shop.pricingDaily"
              [pricingHourly]="shop.pricingHourly"
              [holidayNoticeHtml]="shop.holidayNoticeHtml"
              [availability]="shop.availability"
              [badges]="shop.badges"
              (bookNow)="bookNow.emit(shop.shopItem)"
              (click)="openShopDetails.emit(shop.shopItem)"
              (mouseenter)="highlightShop = shop.id"
            />
          }
        </div>

        @if (!isLoading && numberOfShopsShown > 0 && numberOfShopsShown < numberOfShopsAvailable) {
          <lh-traveler-shops-ui-button
            (clicked)="showNextBatch()"
            class="btn-load-more"
            [style]="'text'"
            color="gray-light"
            size="sm"
            >{{ 'LOAD_MORE_SHOPS' | lhTranslate }}</lh-traveler-shops-ui-button
          >
        }
      }
    </div>
  </div>

  <!-- map -->
  <div class="map" [ngClass]="{ show: showMap }">
    <lh-traveler-shops-ui-button
      [style]="'icon'"
      class="btn-my-location"
      size="md"
      color="white"
      [class.loading]="isLoading"
      (clicked)="userLocationRequest.emit()"
    >
      <!-- prettier-ignore -->
      <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9.16662 18.4483V16.7817C7.43051 16.5872 5.94093 15.8685 4.69787 14.6254C3.45481 13.3824 2.73606 11.8928 2.54162 10.1567H0.874953V8.49002H2.54162C2.73606 6.7539 3.45481 5.26432 4.69787 4.02127C5.94093 2.77821 7.43051 2.05946 9.16662 1.86502V0.198349H10.8333V1.86502C12.5694 2.05946 14.059 2.77821 15.302 4.02127C16.5451 5.26432 17.2638 6.7539 17.4583 8.49002H19.125V10.1567H17.4583C17.2638 11.8928 16.5451 13.3824 15.302 14.6254C14.059 15.8685 12.5694 16.5872 10.8333 16.7817V18.4483H9.16662ZM9.99995 15.1567C11.6111 15.1567 12.9861 14.5872 14.125 13.4483C15.2638 12.3095 15.8333 10.9345 15.8333 9.32335C15.8333 7.71224 15.2638 6.33724 14.125 5.19835C12.9861 4.05946 11.6111 3.49002 9.99995 3.49002C8.38884 3.49002 7.01384 4.05946 5.87495 5.19835C4.73606 6.33724 4.16662 7.71224 4.16662 9.32335C4.16662 10.9345 4.73606 12.3095 5.87495 13.4483C7.01384 14.5872 8.38884 15.1567 9.99995 15.1567ZM9.99995 12.6567C9.08329 12.6567 8.29856 12.3303 7.64579 11.6775C6.99301 11.0247 6.66662 10.24 6.66662 9.32335C6.66662 8.40668 6.99301 7.62196 7.64579 6.96918C8.29856 6.3164 9.08329 5.99002 9.99995 5.99002C10.9166 5.99002 11.7013 6.3164 12.3541 6.96918C13.0069 7.62196 13.3333 8.40668 13.3333 9.32335C13.3333 10.24 13.0069 11.0247 12.3541 11.6775C11.7013 12.3303 10.9166 12.6567 9.99995 12.6567Z" fill="currentColor"/></svg>
    </lh-traveler-shops-ui-button>

    @if (showRedoSearchButton) {
      <lh-traveler-shops-ui-button
        class="btn-search-area"
        color="black"
        size="sm"
        (clicked)="loadShopsByMapLocationRequest.emit()"
        >{{ 'REDO_SEARCH_HERE' | lhTranslate }}</lh-traveler-shops-ui-button
      >
    }

    <lh-traveler-shops-ui-storage-location-map
      (detailsRequest)="openShopDetails.emit($event)"
      (bookingRequest)="bookNow.emit($event)"
      (markerClick)="setActiveShop($event)"
      (centerChange)="onCenterChange($event)"
      [center]="mapCenter"
      [myLocation]="mapCenter"
      [usePanning]="true"
      [(zoom)]="zoom"
      [maxZoom]="maxZoom"
      [clickableIcons]="mapOptions.clickableIcons"
      [keyboardShortcuts]="mapOptions.keyboardShortcuts"
      [fullscreenControl]="mapOptions.fullscreenControl"
      [scaleControl]="mapOptions.scaleControl"
      [streetViewControl]="mapOptions.streetViewControl"
      [zoomControl]="false"
      [disableDoubleClickZoom]="mapOptions.disableDoubleClickZoom"
      [scrollwheel]="mapOptions.scrollwheel"
      [draggable]="mapOptions.draggable"
      [gestureHandling]="mapOptions.gestureHandling"
      [styles]="mapStyles"
      [storageLocations]="displayShops"
      [(highlightedStorageLocationId)]="highlightShop"
      [initialOpenStorageLocationId]="initialSelectedShopId"
      [enableStorageLocationInfoWindows]="enableStorageLocationInfoWindows"
      [enableStorageLocationMarkerNotice]="true"
      [mapMarkerThemeOptions]="mapMarkerThemeOptions"
      [largeStorageLocationCircles]="true"
      [mapClickEnabled]="mapClickEnabled"
      (mapClick)="onMapClick()"
      #storageLocationMap
    ></lh-traveler-shops-ui-storage-location-map>
  </div>
</div>
