import { Inject, Pipe, PipeTransform } from '@angular/core';
import {
  ILuggage,
  ITimePeriod,
  ITranslateService,
  StorageCriteria,
  TRANSLATE_SERVICE,
} from '@luggagehero/shared/interfaces';
import { SharedUtilDate } from '@luggagehero/shared/util';
import moment from 'moment';

const bullet = '·';

@Pipe({ name: 'shopCriteria', standalone: true })
export class ShopCriteriaPipe implements PipeTransform {
  private text = {
    TODAY: 'Today',
    TOMORROW: 'Tomorrow',
    YESTERDAY: 'Yesterday',
    LAST: 'Last',
  };
  private calendarFormats = {
    sameDay: `[${this.text.TODAY}]`,
    nextDay: `[${this.text.TOMORROW}]`,
    nextWeek: 'dddd',
    lastDay: `[${this.text.YESTERDAY}]`,
    lastWeek: `[${this.text.LAST}] dddd`,
    sameElse: `MMM D`,
  };

  public constructor(@Inject(TRANSLATE_SERVICE) private translateService: ITranslateService) {
    this.translateService.stream(this.text).subscribe((translatedText) => {
      this.text = translatedText;
      this.calendarFormats = {
        sameDay: `[${this.text.TODAY}]`,
        nextDay: `[${this.text.TOMORROW}]`,
        nextWeek: 'dddd',
        lastDay: `[${this.text.YESTERDAY}]`,
        lastWeek: `[${this.text.LAST}] dddd`,
        sameElse: `MMM D`,
      };
    });
  }

  transform(criteria: StorageCriteria, type = 'summary', includePeriod = true): string {
    if (!criteria || !criteria.location || !criteria.period || !criteria.luggage) {
      return '';
    }
    switch (type.toLowerCase()) {
      case 'summary':
        return this.transformSummary(criteria, includePeriod);

      case 'bullet':
        return this.transformBullet(criteria);

      case 'location':
        return criteria.location.address;

      case 'period':
        return this.transPeriodBullet(criteria.period);

      case 'luggage':
        return this.summarizeLuggage(criteria.luggage);
    }
    return '';
  }

  private transformBullet(criteria: StorageCriteria): string {
    const luggage = this.summarizeLuggage(criteria.luggage);
    const period = this.transPeriodBullet(criteria.period);
    return `${criteria.location?.address} ${bullet} ${period} ${bullet} ${luggage}`;
  }

  private transformSummary(criteria: StorageCriteria, includePeriod = true): string {
    const luggage = this.summarizeLuggage(criteria.luggage);
    if (!includePeriod) {
      return luggage;
    }
    const period = this.summarizePeriod(criteria.period);
    const forTimePeriod = this.translateService.instant('FOR_TIME_PERIOD').toLowerCase();
    return `${luggage} ${forTimePeriod} ${period}`;
  }

  private summarizeLuggage(luggage?: ILuggage): string {
    const luggageCount = luggage ? luggage.hand + luggage.normal : 0;
    const suffix = this.translateService.instant(luggageCount > 1 ? 'BAGS' : 'BAG').toLowerCase();
    return `${luggageCount} ${suffix}`;
  }

  private summarizePeriod(period?: { from: Date; to: Date }): string {
    if (!period) {
      return '';
    }
    const from = new Date(period.from);
    const to = new Date(period.to);

    const daysBetween = SharedUtilDate.daysBetween(from, to);
    if (daysBetween > 0) {
      const daysSuffix = this.translateService.instant(daysBetween === 1 ? 'DAY' : 'DAYS').toLowerCase();
      return `${daysBetween} ${daysSuffix}`;
    }

    const hoursBetween = SharedUtilDate.hoursBetween(from, to);
    const hoursSuffix = this.translateService.instant(hoursBetween === 1 ? 'HOUR' : 'HOURS').toLowerCase();
    return `${hoursBetween} ${hoursSuffix}`;
  }

  private transPeriodBullet(value?: ITimePeriod): string {
    if (!value) {
      return '';
    }
    if (SharedUtilDate.isAtLeastOneDayLater(value.from, value.to)) {
      let output = this.formatDate(value.from);
      if (value.from.getMonth() === value.to.getMonth()) {
        output += ` – ${value.to.getDate()}`;
      } else {
        output += ` – ${this.formatDate(value.to)}`;
      }
      return output;
    }
    return moment(value.from).calendar(null, this.calendarFormats);
  }

  private formatDate(value: Date): string {
    // return moment(value).calendar(null, this.calendarFormats);
    return moment(value).format('MMM D');
  }
}
