// libs
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserBaseComponent } from '@luggagehero/features/user';
import { SharedUserService } from '@luggagehero/shared/services/users';
@Component({
  selector: 'lh-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserComponent extends UserBaseComponent implements OnInit {
  constructor(userService: SharedUserService, router: Router) {
    super(userService, router);
  }
}
