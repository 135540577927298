import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';

import { BaseComponent } from '../../../../core';

@Component({ template: '' })
export abstract class LineChartBaseComponent extends BaseComponent implements OnInit {
  public results: unknown;
  public xAxisTicks: unknown[];
  public yAxisTicks: unknown[];
  public yScaleMin: number;
  public yScaleMax: number;
  public scheme: unknown;
  public totalValue = 2732 + 314;
  public totalLabel = 'Total earnings';

  private _currency: string;

  constructor(private cd: ChangeDetectorRef) {
    super();
  }

  public get currency(): string {
    return this._currency;
  }
  @Input() public set currency(value: string) {
    this._currency = value;
    this.cd.markForCheck();
  }

  ngOnInit(): void {
    this.scheme = {
      domain: ['#384347', '#aeaeae'],
    };

    this.yScaleMin = 100;
    this.yScaleMax = 750;
    this.yAxisTicks = [100];
    this.xAxisTicks = ['Friday', 'Thursday'];

    this.results = [
      {
        // TODO: Translate this
        name: 'Last 7 days',
        series: [
          {
            name: 'Friday',
            value: 245,
          },
          {
            name: 'Saturday',
            value: 399,
          },
          {
            name: 'Sunday',
            value: 534,
          },
          {
            name: 'Monday',
            value: 488,
          },
          {
            name: 'Tuesday',
            value: 378,
          },
          {
            name: 'Wednesday',
            value: 309,
          },
          {
            name: 'Thursday',
            value: 376,
          },
        ],
      },
      {
        name: 'Previous period',
        series: [
          {
            name: 'Friday',
            value: 245 * 0.85,
          },
          {
            name: 'Saturday',
            value: 399 * 0.86,
          },
          {
            name: 'Sunday',
            value: 534 * 0.88,
          },
          {
            name: 'Monday',
            value: 488 * 0.75,
          },
          {
            name: 'Tuesday',
            value: 378 * 0.83,
          },
          {
            name: 'Wednesday',
            value: 309 * 0.8,
          },
          {
            name: 'Thursday',
            value: 376 * 0.9,
          },
        ],
      },
    ];
  }
}
