<div class="map-card-wrapper">
  <div class="google-map-container">
    <lh-storage-location-map
      *ngIf="location"
      (markerClick)="setActiveShop($event)"
      (centerChange)="onCenterChange($event)"
      [center]="mapCenter"
      [myLocation]="mapCenter"
      [usePanning]="true"
      [(zoom)]="zoom"
      [maxZoom]="maxZoom"
      [clickableIcons]="mapOptions.clickableIcons"
      [keyboardShortcuts]="mapOptions.keyboardShortcuts"
      [fullscreenControl]="mapOptions.fullscreenControl"
      [scaleControl]="mapOptions.scaleControl"
      [streetViewControl]="mapOptions.streetViewControl"
      [zoomControl]="false"
      [disableDoubleClickZoom]="mapOptions.disableDoubleClickZoom"
      [scrollwheel]="mapOptions.scrollwheel"
      [draggable]="mapOptions.draggable"
      [gestureHandling]="mapOptions.gestureHandling"
      [styles]="mapStyles"
      [storageLocations]="visibleShops"
      [highlightedStorageLocationId]="highlightShop"
      [initialOpenStorageLocationId]="initialSelectedShopId"
      [enableStorageLocationInfoWindows]="false"
      [enableStorageLocationMarkerNotice]="true"
      #storageLocationMap
    ></lh-storage-location-map>

    <div
      id="loadMyLocation"
      *ngIf="location"
      class="btn my-location"
      [class.loading]="isLoading"
      [title]="'MY_LOCATION' | translate"
      type="button"
      (click)="userLocationRequest.emit()"
    >
      <i class="icon"></i>
    </div>
  </div>

  <div class="active-shop-card" *ngIf="isInitialized">
    <div *ngIf="!activeShop || isLoading" class="no-active-shop">
      <div *ngIf="isLoading || visibleShops.length > 0" class="loading">
        <lh-shared-ui-spinner [visible]="true"></lh-shared-ui-spinner>
        <strong>{{ 'FINDING_STORAGE_LOCATIONS' | translate }}</strong>
      </div>

      <div *ngIf="!isLoading && visibleShops.length === 0" class="no-results">
        <strong>{{ 'NO_STORAGE_LOCATIONS_FOUND' | translate }}</strong>
      </div>
    </div>

    <div *ngIf="activeShop && !isLoading">
      <a [id]="'goToShopDetailsFromMap_' + activeShop._id" class="shop-list-item" (click)="viewShopDetails(activeShop)">
        <lh-simple-shop-item
          [shop]="activeShop"
          [distanceUnit]="distanceUnit | async"
          (bookingRequest)="makeBooking(activeShop)"
        ></lh-simple-shop-item>
      </a>
    </div>
  </div>
</div>
