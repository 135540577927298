import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SharedBookingService } from '@luggagehero/shared/services/bookings';
import { SharedPageTaggingService } from '@luggagehero/shared/services/page-tagging';
import { Subscription } from 'rxjs';

import { BaseComponent } from '../../../../core';

@Component({ template: '' })
export abstract class ManageBookingSelectorBaseComponent extends BaseComponent implements OnInit, OnDestroy {
  private _bookingSchemaVersion: number;
  private _isInitialized = false;
  private paramsSubscription: Subscription;

  constructor(
    private bookingService: SharedBookingService,
    private tag: SharedPageTaggingService,
    private route: ActivatedRoute,
    protected cd: ChangeDetectorRef,
  ) {
    super();
  }

  get legacyMode(): boolean {
    if (this._bookingSchemaVersion > 1) {
      return false;
    }
    return true;
  }

  get isInitialized(): boolean {
    return this._isInitialized;
  }

  ngOnInit() {
    this.paramsSubscription = this.route.params.subscribe((params: { id: string; v: number }) => {
      void (async () => {
        if (!params.id) {
          // TODO: Handle invalid params
          return;
        }
        const booking = await this.bookingService.getBooking(params.id);

        // Add meta tags to the page
        this.tag.addBookingTags(booking);

        this._bookingSchemaVersion = params.v || booking.schemaVersion;
        this._isInitialized = true;
        this.cd.markForCheck();
      })();
    });
  }

  ngOnDestroy() {
    try {
      this.paramsSubscription.unsubscribe();
    } catch {
      // Ignore
    }
  }
}
