<div *ngFor="let date of weekDates" class="row">
  <div class="col-4 opening-hours-item">
    <span>{{ getNameOfDay(date) }}</span>
  </div>
  <div class="col-8 opening-hours-item">
    <span>{{ getOpeningHoursFromDate(date) | timeInterval | async }}</span>
  </div>
  <div *ngIf="showHolidayWarning(date)" class="col-12 opening-hours-sub-item">
    <small class="holiday-warning"> {{ getHolidayName(date) }} {{ 'MIGHT_AFFECT_THESE_HOURS' | translate }} </small>
  </div>
  <div *ngIf="showConfirmedHolidayHours(date)" class="col-12 opening-hours-sub-item">
    <small class="holiday-success"> {{ 'CONFIRMED_HOURS_FOR' | translate }} {{ getHolidayName(date) }} </small>
  </div>
</div>
