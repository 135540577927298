<div
  class="tag-list"
  [class.tag-list-sm]="size === 'small'"
  [class.tag-list-md]="size === 'medium'"
  [class.tag-list-lg]="size === 'large'"
  [class.tag-list-primary]="style === 'primary'"
  [class.tag-list-success]="style === 'success'"
  [class.tag-list-warning]="style === 'warning'"
  [class.no-wrap]="noWrap"
>
  <div class="tag" [attr.data-color]="tag.color" *ngFor="let tag of visibleTags">
    <i class="tag-icon"></i>
    <div class="tag-number">
      <span *ngFor="let digit of tag.text.split('')">{{ digit }}</span>
    </div>
  </div>
  <!-- HACK: Create extra flex gap to add end padding when scrolling horizontally -->
  <div style="width: 0" *ngIf="noWrap">&nbsp;</div>
</div>

<div class="expand-tags" *ngIf="isCollapsed && numberOfHiddenTags > 0">
  <a class="expand-tags-cta" (click)="isCollapsed = false"
    ><span
      >{{ 'SHOW_X_MORE_TAGS_PRE' | lhTranslate }} {{ numberOfHiddenTags }}
      {{ 'SHOW_X_MORE_TAGS_POST' | lhTranslate }}</span
    ><i class="bi bi-caret-down-fill"></i>
  </a>
</div>
