import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  inject,
  Input,
  Output,
} from '@angular/core';
import { LocationType, Place, StorageCriteria } from '@luggagehero/shared/interfaces';
import { SharedGeocodingService } from '@luggagehero/shared/services/geocoding';
import { SharedStorageCriteriaService } from '@luggagehero/shared/services/storage-criteria';
import { SharedTranslateService } from '@luggagehero/shared/services/translation';
import {
  TravelerShopsUiDatePickerComponent,
  TravelerShopsUiLuggagePickerComponent,
  TravelerShopsUiPlacesSearchComponent,
} from '@luggagehero/traveler/shops/ui';

@Component({
  selector: 'lh-traveler-feature-criteria-bar',
  standalone: true,
  imports: [
    CommonModule,
    TravelerShopsUiPlacesSearchComponent,
    TravelerShopsUiLuggagePickerComponent,
    TravelerShopsUiDatePickerComponent,
  ],
  templateUrl: './traveler-feature-criteria-bar.component.html',
  styleUrl: './traveler-feature-criteria-bar.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TravelerFeatureCriteriaBarComponent {
  private criteriaService = inject(SharedStorageCriteriaService);
  private geocodingService = inject(SharedGeocodingService);
  private translateService = inject(SharedTranslateService);
  private cd = inject(ChangeDetectorRef);

  private _criteria!: StorageCriteria;

  get criteria(): StorageCriteria {
    return this._criteria;
  }

  @Input() set criteria(value: StorageCriteria) {
    this._criteria = value;
    this.bags = value?.luggage?.normal ?? 2;

    if (value != null && value.location != null) {
      const newLocation = value.location.address;
      this.query = newLocation;
      this.cd.markForCheck();
    }
  }

  @Output() criteriaChange = new EventEmitter<StorageCriteria>();
  @Input() businessesOnly = false;

  get searchPlaceholder(): string {
    return this.translateService.instant('WHERE') + '?';
  }

  private _predictions: google.maps.places.AutocompletePrediction[] = [];
  predictionsListViewModel: [string, string][] = [];
  private _selectedPlace!: Place;
  query = '';
  private queryTimeout?: unknown;
  showCalendar = false;
  _bags = this.criteriaService.currentOrDefault?.luggage?.normal ?? 2;

  constructor(private elementRef: ElementRef<HTMLElement>) {}

  get predictions(): google.maps.places.AutocompletePrediction[] {
    return this._predictions;
  }

  set predictions(predictions: google.maps.places.AutocompletePrediction[]) {
    this._predictions = predictions;

    if (predictions.length === 0) {
      this.predictionsListViewModel = [];
    }

    this.predictionsListViewModel = predictions.map((prediction) => [
      prediction.structured_formatting.main_text,
      prediction.structured_formatting.secondary_text,
    ]);
    this.cd.markForCheck();
  }

  get selectedPlace(): Place {
    return this._selectedPlace;
  }

  set selectedPlace(value: Place) {
    this._selectedPlace = value;
    this.updateLocation(
      value.address.location[1],
      value.address.location[0],
      value.name,
      value.address.region,
      this.criteria.location.zoom,
      this.criteria.location.radius,
      '',
      'place',
    );
  }

  get bags(): number {
    return this._bags;
  }

  set bags(value: number) {
    this._bags = value;

    if (
      this.criteriaService &&
      this.criteriaService.currentOrDefault &&
      this.criteriaService.currentOrDefault.luggage
    ) {
      this.criteriaService.luggage = {
        ...this.criteriaService.currentOrDefault.luggage,
        normal: +value,
      };
    } else {
      console.warn('CriteriaService or luggage is undefined. Skipping luggage update.');
    }

    this.cd.markForCheck();
  }

  queryChange(value: string) {
    this.query = value;
    clearTimeout(this.queryTimeout as number);
    this.queryTimeout = setTimeout(() => this.loadPredictions(), 200);
  }

  dateChanged(value: Date) {
    this.criteriaService.period = {
      from: value,
      to: value,
    };
    this.cd.markForCheck();
  }

  @HostListener('document:click', ['$event'])
  onClickOutside(event: Event) {
    if (!this.elementRef.nativeElement.contains(event.target as Node)) {
      this.showCalendar = false;
    }
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.showCalendar = false;
    }
  }

  async onPredictionSelected({ prediction, index }: { prediction: [string, string]; index: number }) {
    this.query = prediction[0];
    const placeId = this.predictions[index]?.place_id;

    if (placeId) {
      const result = await this.geocodingService.requestReverseGeoCodeForPlaceId(placeId, 'selectPlace');
      result.name = prediction[0];
      this.selectedPlace = result;
    }

    this.cd.markForCheck();
  }

  private updateLocation(
    lat: number,
    lon: number,
    address: string,
    region: string,
    zoom: number,
    radius: number,
    key: string,
    type: LocationType,
  ) {
    this.criteriaService.location = {
      lat: lat,
      lon: lon,
      address: address,
      region: region,
      zoom: zoom,
      radius: radius,
      key: key,
      type: type,
    };
    this.cd.markForCheck();
  }

  private cacheQuery: Map<string, google.maps.places.AutocompletePrediction[]> = new Map();

  loadPredictions() {
    if (this.cacheQuery.has(this.query)) {
      this.predictions = this.cacheQuery.get(this.query);
      return;
    }

    void this.getPlacePredictions(this.query).then((results: google.maps.places.AutocompletePrediction[]) => {
      this.predictions = results;
      this.cacheQuery.set(this.query, results);
    });
  }

  private async getPlacePredictions(query: string): Promise<google.maps.places.AutocompletePrediction[]> {
    if (query.length < 3) {
      return [];
    }

    const types = this.businessesOnly ? 'establishment' : undefined;
    const tag = 'getPlacePredictions';
    return this.geocodingService.autocomplete(query, types, tag);
  }
}
