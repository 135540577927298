<!-- <timepicker
  class="lh-time-picker"
  [(ngModel)]="value"
  [hourStep]="hourStep"
  [minuteStep]="minuteStep"
  [showMeridian]="is12HourMode"
  [showSpinners]="showSpinners"
  [readonlyInput]="disabled"
  [arrowkeys]="enableArrowKeys"
  [mousewheel]="enableMouseWheel"
>
</timepicker> -->

<kendo-timepicker
  class="lh-time-picker"
  [format]="is12HourMode ? 'hh:mm aa' : 'HH:mm'"
  [steps]="{ hour: 1, minute: 15 }"
  [value]="value"
  [formatPlaceholder]="{ hour: 'HH', minute: 'MM' }"
  [(ngModel)]="value"
  [disabled]="disabled"
  [cancelButton]="false"
  [nowButton]="false"
  (close)="onTimePickerClose()"
  #timePicker
>
</kendo-timepicker>

<!-- HACK: To avoid keyboard popup when time is selected put focus on this dummy button -->
<button class="focus-stealer" #focusStealer></button>
