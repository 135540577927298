import { Observable, of as observableOf } from 'rxjs';

import { RouterExtensions } from '../../../../../core/services/index';
import { BookingActionInfo, BookingChange } from '../booking-actions/booking-action-info';
import { BookingTimelineBaseComponent } from '../booking-timeline.base-component';
import { BookingStep } from './booking-step';

export abstract class BookingCanceledBaseStep extends BookingStep {
  constructor(
    parent: BookingTimelineBaseComponent,
    private router: RouterExtensions,
  ) {
    super(parent);
  }

  private get makeAnotherBookingActionInfo(): BookingActionInfo {
    return new BookingActionInfo('MakeAnotherBookingFromBookingCanceledStep')
      .withCallToAction(this.translate('MAKE_ANOTHER_BOOKING'))
      .withAction({ functionToCall: () => this.makeAnotherBooking() });
  }

  getIsVisible() {
    return this.booking.status === 'CANCELLED';
  }

  getTitle(): string {
    return this.translate('BOOKING_CANCELED');
  }

  getSubtitle(): string {
    if (this.order?.total || this.booking.price.final?.total > 0) {
      return null;
    }
    return this.translate('BOOKING_PROGRESS_CANCELED_SUBTEXT');
  }

  getIsSubtitleHidden(): boolean {
    return false;
  }

  getIsActive(): boolean {
    return false;
  }

  getIsCompleted(): boolean {
    return true;
  }

  getIsFailed(): boolean {
    return false;
  }

  getActionInfo(): BookingActionInfo {
    return this.makeAnotherBookingActionInfo;
  }

  getIsActionCollapsed(): boolean {
    return false;
  }

  getContinueTimeline(): boolean {
    return false;
  }

  private makeAnotherBooking(): Observable<BookingChange> {
    void this.router.navigate(['']);
    return observableOf(null);
  }
}
