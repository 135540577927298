import { Route } from '@angular/router';
import { AppVersionGuard } from '@luggagehero/services/app-version-guard.service';
import { RedirectGuard } from '@luggagehero/services/redirect-guard.service';
import { Config } from '@luggagehero/shared/environment';

// lazy loaded modules are handled here.
// users are handled in the module itself and then imported in the web.module
export const routes: Array<Route> = [
  {
    path: '',
    canActivate: [AppVersionGuard],
    children: [
      {
        path: 'demo',
        loadChildren: async () => (await import('@luggagehero/traveler/shops/routes')).travelerShopsRoutesRoutes,
      },
      { path: '', loadChildren: () => import('./shops/shops.module').then((m) => m.ShopsLoaderModule) },
      { path: '', loadChildren: () => import('./bookings/bookings.module').then((m) => m.BookingsLoaderModule) },
      {
        path: 'shop-manager',
        canActivate: [RedirectGuard],
        component: RedirectGuard,
        data: {
          externalUrl: () => Config.environment.STORAGE_MANAGER_UI,
        },
      },
      {
        path: 'storage-manager',
        canActivate: [RedirectGuard],
        component: RedirectGuard,
        data: {
          externalUrl: () => Config.environment.STORAGE_MANAGER_UI,
        },
      },
    ],
  },
];
