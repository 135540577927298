import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { Config } from '@luggagehero/shared/environment';
import { SharedDocumentService } from '@luggagehero/shared/services/document';
import { SharedLoggingService } from '@luggagehero/shared/services/logging';
import { Observable, Observer } from 'rxjs';

export interface Script {
  src: string;
  styles?: string[];
  properties?: { [name: string]: string };
  parent: 'head' | 'body';
  isLoaded?: boolean;
  disablePrd?: boolean;
  disableStg?: boolean;
  disableDev?: boolean;
}

@Injectable()
export class ScriptService {
  private scripts: { [name: string]: Script } = {
    wootric: {
      src: 'https://disutgh7q0ncc.cloudfront.net/beacon.js',
      parent: 'body',
      disableDev: true,
    },
    pdfmake: {
      src: 'scripts/pdfmake/pdfmake.min.js',
      parent: 'head',
    },
    vfs_fonts: {
      src: 'scripts/pdfmake/vfs_fonts.js',
      parent: 'head',
    },
    jsvat: {
      src: 'scripts/jsvat/jsvat.min.js',
      parent: 'head',
    },
    facebook: {
      src: 'https://connect.facebook.net/en_US/sdk.js',
      parent: 'head',
      isLoaded: true, // Loaded in index.html for now
    },
    google: {
      src: 'https://apis.google.com/js/client:platform.js',
      parent: 'head',
      isLoaded: true, // Loaded in index.html for now
    },
    gyg: {
      src: 'https://widget.getyourguide.com/v2/widget.js',
      parent: 'head',
      isLoaded: false,
    },
  };

  private document: Document;
  private renderer: Renderer2;

  constructor(
    rendererFactory: RendererFactory2,
    documentService: SharedDocumentService,
    private log: SharedLoggingService,
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
    this.document = documentService.document;
  }

  public load(name: string): Observable<Script> {
    return new Observable<Script>((observer: Observer<Script>) => {
      if (!this.document) {
        void this.log.error(`No document`);
        observer.error(`No document`);
        return;
      }

      const script = this.scripts[name];

      if (!script) {
        void this.log.error(`Script not found: '${name}'`);
        observer.error(`No script with the name '${name}' was found`);
        return;
      }

      if (this.isDisabled(script)) {
        void this.log.debug(`Script disabled in ${Config.environment.ENV} environment: '${name}'`);
        observer.next(script);
        observer.complete();
        return;
      }

      // Complete if already loaded
      if (script.isLoaded) {
        void this.log.debug(`Script is already loaded: '${name}'`);
        observer.next(script);
        observer.complete();
      } else {
        // Load the script
        const scriptElement = this.renderer.createElement('script') as HTMLScriptElement;
        scriptElement.type = 'text/javascript';
        scriptElement.src = script.src;

        // Load additional script properties
        if (script.properties) {
          for (const propertyName in script.properties) {
            if (!propertyName) {
              continue;
            }
            const propertyValue = script.properties[propertyName];
            this.renderer.setAttribute(scriptElement, propertyName, propertyValue);
          }
        }

        if (script.styles && script.styles.length > 0) {
          for (let i = 0; i < script.styles.length; i++) {
            const styleElement = this.renderer.createElement('link') as HTMLLinkElement;
            styleElement.rel = 'stylesheet';
            styleElement.type = 'text/css';
            styleElement.href = script.styles[i];

            this.renderer.appendChild(this.document[script.parent], styleElement);
          }
        }

        scriptElement.onload = () => {
          script.isLoaded = true;

          void this.log.debug(`Loaded script: '${name}'`);
          observer.next(script);
          observer.complete();
        };

        scriptElement.onerror = (_error) => {
          void this.log.error(`Could not load script: '${name}'`);
          observer.error(`Could not load script '${name}' from: ${script.src}`);
        };

        this.renderer.appendChild(this.document[script.parent], scriptElement);
      }
    });
  }

  private isDisabled(s: Script): boolean {
    const e = Config.environment.ENV;
    return (s.disableDev && e === 'DEV') || (s.disableStg && e === 'STAGING') || (s.disablePrd && e === 'STAGING');
  }
}
