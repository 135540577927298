import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { FormsModule, NgModel } from '@angular/forms';
import { PricePipe, TranslatePipe } from '@luggagehero/shared/ui-pipes';
import { SharedUtilString } from '@luggagehero/shared/util';

export interface TipOption {
  amount: number;
  label: string;
}

@Component({
  selector: 'lh-shared-ui-tip-options',
  standalone: true,
  imports: [CommonModule, FormsModule, PricePipe, TranslatePipe],
  templateUrl: './shared-ui-tip-options.component.html',
  styleUrl: './shared-ui-tip-options.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SharedUiTipOptionsComponent {
  @ViewChild('customTipInput') public customTipInput?: ElementRef<HTMLInputElement>;
  @ViewChild('customTip') public customTipModel?: NgModel;

  @Input() public maxTipAmount = 0;
  @Input() public currency = 'usd';
  @Input() public isCustomTipEnabled = true;

  @Output() public readonly tipChange = new EventEmitter<number | null>();

  public isCustomTipVisible = false;

  private _tip: number | null = null;
  private _tipOptions: TipOption[] = [];

  private cd = inject(ChangeDetectorRef);

  public get tip(): number | null {
    return this._tip;
  }
  @Input() public set tip(value: number | null) {
    this._tip = value;
    this.cd.markForCheck();
    this.tipChange.emit(value);

    this.updateCustomTipVisibility();
  }

  public get tipOptions(): TipOption[] {
    return this._tipOptions;
  }
  @Input() public set tipOptions(value: TipOption[]) {
    this._tipOptions = value;
    this.cd.markForCheck();

    this.updateCustomTipVisibility();
  }

  public get tipString(): string | null {
    return this.tip?.toString() || null;
  }
  public set tipString(value: string | null) {
    if (value) {
      const parsedValue = Number(value);
      this.tip = !isNaN(parsedValue) ? parsedValue : null;
    } else {
      this.tip = null;
    }
  }

  public get isCustomTipInvalid(): boolean {
    if (!this.isCustomTipVisible) {
      return false;
    }
    if (this.customTipModel?.valid) {
      return false;
    }
    return true;
  }

  public get isTipTooLarge(): boolean {
    if (!this.tip || !this.maxTipAmount || this.maxTipAmount <= 0) {
      return false;
    }
    return this.tip > this.maxTipAmount;
  }

  public get currencySymbol(): string {
    return SharedUtilString.getCurrencySymbol(this.currency);
  }

  public get isStandardTipSelected(): boolean {
    if (!this.tipOptions) {
      return false;
    }
    return this.tipOptions.find((o) => o.amount === this.tip) ? true : false;
  }

  public setTipAmount(value: number) {
    this.isCustomTipVisible = false;
    this.tip = value;
  }

  public showCustomTip() {
    if (this.isStandardTipSelected) {
      this.tipString = null;
    }

    this.isCustomTipVisible = true;
    this.cd.markForCheck();

    setTimeout(() => this.customTipInput?.nativeElement.focus(), 1);
  }

  private updateCustomTipVisibility() {
    if (!this.tipOptions || this.tip == null || this.isStandardTipSelected) {
      return;
    }
    this.showCustomTip();
  }
}
