import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  inject,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { IonicModule, ModalController } from '@ionic/angular';
import { AppConfig } from '@luggagehero/shared/app-settings/data-access';
import {
  BookableStorageLocation,
  DistanceUnit,
  ILocation,
  LatLng,
  Optional,
  StorageCriteria,
} from '@luggagehero/shared/interfaces';
import { SharedDistanceService } from '@luggagehero/shared/services/distance';
import { SharedDocumentService } from '@luggagehero/shared/services/document';
import { SharedShopsService } from '@luggagehero/shared/services/shops';
import { SharedWindowService } from '@luggagehero/shared/services/window';
import { SharedUiSpinnerComponent } from '@luggagehero/shared/ui';
import { TranslatePipe } from '@luggagehero/shared/ui-pipes';
import { TravelerShopsService } from '@luggagehero/traveler/shops/data-access';
import {
  TravelerShopsUiBlackNavbarComponent,
  TravelerShopsUiButtonComponent,
  TravelerShopsUiMainToolbarComponent,
  TravelerShopsUiShopCardComponent,
  TravelerShopsUIStorageLocationMapComponent,
} from '@luggagehero/traveler/shops/ui';
import { MapMarkerThemeOptions } from '@luggagehero/traveler/shops/util';
import { TravelerFeatureNavbarComponent } from '@luggagehero/traveler-shops-feature-navbar';
import { TravelerShopsFeatureStorageLocationDetailsSheetComponent } from '@luggagehero/traveler-shops-feature-storage-location-details-sheet';
import { ShopDisplayModel } from '@luggagehero/traveler-shops-models';
import { distinctUntilChanged, filter, firstValueFrom, Subscription } from 'rxjs';

const DEFAULT_ZOOM_LEVEL = 14;

@Component({
  selector: 'lh-traveler-shops-feature-find',
  standalone: true,
  imports: [
    CommonModule,
    IonicModule,
    TravelerShopsUiBlackNavbarComponent,
    TravelerShopsUiShopCardComponent,
    TravelerShopsUIStorageLocationMapComponent,
    TravelerShopsUiMainToolbarComponent,
    TravelerShopsUiButtonComponent,
    TravelerFeatureNavbarComponent,
    SharedUiSpinnerComponent,
    TranslatePipe,
  ],
  templateUrl: './traveler-shops-feature-find.component.html',
  styleUrls: ['./traveler-shops-feature-find.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TravelerShopsFeatureFindComponent implements OnInit, OnDestroy, OnChanges {
  /*
   * Inputs
   */
  @Input() isLoading = true;
  @Input() initialSelectedShopId: Optional<string>;
  @Input() showRedoSearchButton = false;
  @Input() isNoShopsAvailable = false;
  private _criteria!: StorageCriteria;

  @Input() set criteria(value: StorageCriteria) {
    this._criteria = value;
    if (value && value.location) {
      this.location = value.location;
    }
  }

  get criteria(): StorageCriteria {
    return this._criteria;
  }

  @Input() isHotelFilterVisible = false;
  @Input() isOpen24HoursFilterVisible = false;
  @Input() isWifiAvailableFilterVisible = false;
  @Input() isMultiDayStorageFilterVisible = false;
  @Input() hotelsFilterEnabled = false;
  @Input() openAllDayFilterEnabled = false;
  @Input() wifiAvailableFilterEnabled = false;
  @Input() multiDayStorageFilterEnabled = false;
  @Input() batchSize = 6;
  @Input() hideUnavailable = false;
  @Input() mapMarkerThemeOptions: MapMarkerThemeOptions = { markerSizeDownscaleFactor: 0.75 };

  @Input() set distanceUnit(value: DistanceUnit) {
    this._distanceUnit = value;
    void this.refreshShopDisplayModels();
  }
  get distanceUnit(): DistanceUnit {
    return this._distanceUnit;
  }

  @Input() set shops(value: BookableStorageLocation[]) {
    this._shops = value;
    this.isNoShopsAvailable = value.length === 0;
    this._numberOfBatchesShown = 1;
    this.cd.markForCheck();
  }
  get shops(): BookableStorageLocation[] {
    return this._shops;
  }

  @Input() set location(value: Optional<ILocation>) {
    this._location = value;
    this.cd.markForCheck();
  }
  get location(): Optional<ILocation> {
    return this._location;
  }

  @Input() set activeShop(value: Optional<ShopDisplayModel>) {
    this._activeShop = value;
    if (value == null && this.location) {
      this.zoom = this.location.zoom ?? DEFAULT_ZOOM_LEVEL;
    }
    this.highlightShop = value?.id;
  }
  get activeShop(): Optional<ShopDisplayModel> {
    return this._activeShop;
  }

  /**
   * This refers to the parent “Find Shop” component, which manages the display of either the “Book Now” or “Shop Details” modal.
   * It should not be confused with the docked shop card modal.
   * Understanding this distinction is essential to determine when the shop dock card modal can be shown.
   */
  @Input() set isParentShowingModal(value: boolean) {
    if (value) {
      void this.closeModal({ animated: true });
    }
    if (this._isParentShowingModal != value && !value && this.activeShop) {
      void this.openModal({ shop: this.activeShop });
    }
    this._isParentShowingModal = value;
    this.cd.markForCheck();
  }

  /**
   * This refers to the parent “Find Shop” component, which manages the display of either the “Book Now” or “Shop Details” modal.
   * It should not be confused with the docked shop card modal.
   * Understanding this distinction is essential to determine when the shop dock card modal can be shown.
   */
  get isParentShowingModal(): boolean {
    return this._isParentShowingModal;
  }

  /*
   * Outputs
   */
  @Output() criteriaChange = new EventEmitter<StorageCriteria>();
  @Output() userLocationRequest = new EventEmitter<void>();
  @Output() loadShopsByMapLocationRequest = new EventEmitter<void>();
  @Output() bookNow = new EventEmitter<BookableStorageLocation>();
  @Output() openShopDetails = new EventEmitter<BookableStorageLocation>();
  @Output() locationChange = new EventEmitter<LatLng>();
  @Output() toggleOpenAllDayFilter = new EventEmitter<void>();
  @Output() toggleMultiDayStorageFilter = new EventEmitter<void>();
  @Output() toggleWifiAvailableFilter = new EventEmitter<void>();
  @Output() toggleHotelsFilter = new EventEmitter<void>();

  onHighlightedStorageLocationIdChange(value: Optional<string>) {
    this.highlightShop = value;
  }
  /*
   * Getters/Setters
   */
  get mapClickEnabled(): boolean {
    return this.enableStorageLocationInfoWindows && !this.isParentShowingModal;
  }

  get highlightShop(): Optional<string> {
    return this._highlightShop;
  }
  set highlightShop(value: Optional<string>) {
    this._highlightShop = value;
    this.cd.markForCheck();
  }

  get zoom(): number {
    return this._zoom;
  }
  set zoom(value: number) {
    this._zoom = value;
    this.cd.markForCheck();
  }

  get maxZoom(): Optional<number> {
    if (AppConfig.IS_SHOP_MAP_MAX_ZOOM_DISABLED) {
      return undefined;
    }
    return AppConfig.SHOP_MAP_MAX_ZOOM;
  }

  get mapCenter(): google.maps.LatLngLiteral {
    if (this.location != null) {
      return { lat: this.location.lat, lng: this.location.lon };
    }
    return { lat: 0, lng: 0 };
  }

  get enableStorageLocationInfoWindows(): boolean {
    return !this.windowService.currentWindowSize.isSmallScreen;
  }

  set showMap(value: boolean) {
    if (this._showMap != value) {
      void this._onShowMapChange(value);
    }
    this._showMap = value;
  }
  get showMap(): boolean {
    return this._showMap;
  }

  get numberOfShopsAvailable(): number {
    return this.displayShops.length;
  }

  get maxShopsToShow(): number {
    return this.batchSize * this._numberOfBatchesShown;
  }

  get listShops(): ShopDisplayModel[] {
    if (!this.criteria || this.isLoading || !this.displayShops) {
      return [];
    }

    const shopsToShow = this.hideUnavailable
      ? this.displayShops.filter((shop) => shop.shopItem.available)
      : this.displayShops;

    const limitedShops =
      shopsToShow.length > this.maxShopsToShow ? shopsToShow.slice(0, this.maxShopsToShow) : shopsToShow;

    this.numberOfShopsShown = limitedShops.length;
    return limitedShops;
  }

  /*
   * Public Properties
   */
  public numberOfShopsShown = 0;
  public displayShops: ShopDisplayModel[] = [];
  public mapOptions: google.maps.MapOptions = AppConfig.GOOGLE_MAPS_OPTIONS['DEFAULT'];
  public mapStyles = AppConfig.GOOGLE_MAPS_STYLES['DROPNLOCAL'];

  /*
   * Private Properties
   */
  private readonly cd: ChangeDetectorRef;
  private readonly modalController: ModalController;
  private readonly ngZone: NgZone;
  private readonly distanceService = inject(SharedDistanceService);
  private readonly windowService = inject(SharedWindowService);
  private readonly shopsService = inject(SharedShopsService);
  private readonly travelerShopsService = inject(TravelerShopsService);
  private readonly documentService = inject(SharedDocumentService);
  private readonly router = inject(Router);

  private subscriptions: Subscription[] = [];
  private _distanceUnit: DistanceUnit = 'metric';
  private _shops: BookableStorageLocation[] = [];
  private _location: Optional<ILocation>;
  private _showMap = true;
  private _zoom = DEFAULT_ZOOM_LEVEL;
  private _highlightShop: Optional<string>;
  private _activeShop: Optional<ShopDisplayModel>;
  private _currentShopModal: Optional<HTMLIonModalElement>;
  private _isParentShowingModal = false;
  private _numberOfBatchesShown = 0;

  /*
   * Constructor
   */
  constructor(modalController: ModalController, ngZone: NgZone, cd: ChangeDetectorRef) {
    this.modalController = modalController;
    this.ngZone = ngZone;
    this.cd = cd;

    this.documentService.addBodyClass('find-shop');
    this.documentService.addBodyClass('fullscreen-map');
  }

  /*
   * Lifecycle Hooks
   */
  ngOnChanges(_changes: SimpleChanges): void {
    void this.refreshShopDisplayModels();
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.router.events.subscribe((event) => {
        if (
          event instanceof NavigationEnd &&
          this.activeShop &&
          event.urlAfterRedirects?.includes('/home') &&
          this.showMap
        ) {
          void this.openModal({ shop: this.activeShop });
        }

        /**
         * Hack to close the modal when navigating away from the home page
         */
        setTimeout(() => {
          if (!this.router.url?.includes('/home')) {
            void this.closeModal({ animated: false });
          }
        }, 500);
      }),
    );

    this.subscriptions.push(
      this.windowService.size
        .pipe(distinctUntilChanged((prev, curr) => prev.windowHeight === curr.windowHeight))
        .subscribe(() => {
          this._breakpoints = {
            mini: 80 / this.windowService.currentWindowSize.windowHeight,
            docked: 220 / this.windowService.currentWindowSize.windowHeight,
            full: 1,
          };

          if (this.showMap && this.windowService.currentWindowSize.isSmallScreen && this.activeShop != null) {
            void this.openModal({ shop: this.activeShop });
          }
        }),
    );

    this.subscriptions.push(
      this.windowService.size
        .pipe(distinctUntilChanged((prev, curr) => prev.isSmallScreen === curr.isSmallScreen))
        .subscribe(() => {
          if (this.showMap && this.windowService.currentWindowSize.isSmallScreen && this.activeShop != null) {
            void this.openModal({ shop: this.activeShop });
          }

          if (!this.windowService.currentWindowSize.isSmallScreen) {
            void this.closeModal({ animated: false });
          }

          this.cd.markForCheck();
        }),
    );

    this.subscriptions.push(
      this.distanceService.distanceUnit$.subscribe((unit) => {
        this.distanceUnit = unit;
      }),
    );

    this.subscriptions.push(
      this.shopsService.listShopsAsyncComplete.pipe(filter(() => this.router.url.includes('/home'))).subscribe(() => {
        void this.refreshShopDisplayModels();
      }),
    );

    this.ngZone.run(() => this.cd.detectChanges());
  }

  ngOnDestroy(): void {
    this.documentService.removeBodyClass('find-shop');
    this.documentService.removeBodyClass('fullscreen-map');
    this.subscriptions.forEach((sub) => sub?.unsubscribe());
  }

  /*
   * Public Methods
   */
  showNextBatch(): void {
    this._numberOfBatchesShown++;
    this.cd.markForCheck();
  }

  toggleDistanceUnit(): void {
    const newUnit = this.distanceUnit === 'metric' ? 'imperial' : 'metric';
    this.distanceService.changeDistanceUnit(newUnit);
  }

  async onMapClick(): Promise<void> {
    if (!this._currentShopModal) return;
    this._currentShopModal.animated = true;
    await this._currentShopModal.setCurrentBreakpoint(this._breakpoints.mini);
  }

  onCenterChange(latLng: LatLng): void {
    this.locationChange.emit(latLng);
  }

  setActiveShop(value: ShopDisplayModel): void {
    this.activeShop = value;
    void this.openModal({ shop: value });
  }

  private _modalLock = false;
  private _breakpoints = {
    mini: 80 / window.innerHeight,
    docked: 220 / window.innerHeight,
    full: 1,
  };

  async openModal({ shop, animated = true }: { shop: ShopDisplayModel; animated?: boolean }): Promise<void> {
    if (!this.router.url.includes('/home') || this.isParentShowingModal) {
      return;
    }

    if (this._modalLock) return;
    this._modalLock = true;
    try {
      if (!this.windowService.currentWindowSize.isSmallScreen) {
        void this.closeModal();
        return;
      }
      const dockedShopBreakpoint = this._breakpoints.docked;
      const isActiveShopInModalVisible = this._currentShopModal != null && this._activeShop != null;
      const initialBreakpoint = (await this._currentShopModal?.getCurrentBreakpoint()) ?? dockedShopBreakpoint;
      const nextModal = await this.modalController.create({
        component: TravelerShopsFeatureStorageLocationDetailsSheetComponent,
        componentProps: {
          onBookNow: () => this.bookNow.emit(shop.shopItem),
          shop,
          onCardClicked: () => {
            void this._currentShopModal?.setCurrentBreakpoint(this._breakpoints.full);
          },
        },
        breakpoints: Object.values(this._breakpoints),
        initialBreakpoint,
        backdropDismiss: false,
        backdropBreakpoint: this._breakpoints.full,
        cssClass: 'transparent-modal',
      });

      nextModal.initialBreakpoint = this._breakpoints.docked;

      const innerAnimated = !isActiveShopInModalVisible;
      nextModal.animated = innerAnimated && animated;
      await nextModal.present();

      if (isActiveShopInModalVisible && this._currentShopModal != null) {
        this._currentShopModal.animated = false;
        await this._currentShopModal.dismiss();
      }

      nextModal.animated = animated;
      await nextModal.setCurrentBreakpoint(dockedShopBreakpoint);
      this._currentShopModal = nextModal;
      this.addCustomBehavior(nextModal, dockedShopBreakpoint);
    } finally {
      this._modalLock = false;
    }
  }

  private addCustomBehavior(modal: HTMLIonModalElement, dockedBreakpoint: number) {
    let currentBreakpoint: number = dockedBreakpoint;
    let shouldStopPropagation = false;

    const modalContent = modal.querySelector<HTMLElement>('.traveler-shops-feature-storage-location-details-sheet');
    if (!modalContent) return;

    modalContent.addEventListener('touchmove', function modalContentTouchHandler(ev: TouchEvent) {
      const isInTop = modalContent.scrollTop === 0;
      if (shouldStopPropagation && !isInTop) {
        ev.stopPropagation();
      }
    });

    modalContent.setAttribute('style', 'overflow: hidden');

    modal.addEventListener('ionBreakpointDidChange', (event: CustomEvent<{ breakpoint: number }>) => {
      currentBreakpoint = event.detail.breakpoint;

      if (currentBreakpoint !== this._breakpoints.full) {
        modalContent.setAttribute('style', 'overflow: hidden');
        shouldStopPropagation = false;
      } else {
        modalContent.removeAttribute('style');
        shouldStopPropagation = true;
      }
    });
  }

  async closeModal(options: { animated?: boolean } = {}): Promise<void> {
    if (this._modalLock || !this._currentShopModal) return;
    this._modalLock = true;
    try {
      const modal = this._currentShopModal;
      this._currentShopModal = null;
      modal.animated = options.animated ?? true;
      await modal.dismiss();
    } finally {
      this._modalLock = false;
    }
  }

  private async refreshShopDisplayModels(): Promise<void> {
    const previousActiveShop = this.activeShop;
    this.displayShops = await firstValueFrom(
      this.travelerShopsService.convertToDisplayModels(this.shops, this.distanceUnit, this.criteria),
    );
    const newActiveShop = this.displayShops.find((shop) => shop.recommendedTag != null);

    if (newActiveShop) {
      this.activeShop = newActiveShop;

      if (this.showMap) {
        await this.openModal({ shop: newActiveShop, animated: newActiveShop.id !== previousActiveShop?.id });
      }
    }

    this.cd.markForCheck();
  }

  private async _onShowMapChange(showMap: boolean): Promise<void> {
    if (showMap && this.activeShop && this._currentShopModal == null) {
      await this.openModal({ shop: this.activeShop });
    } else if (!showMap) {
      await this.closeModal();
    }
  }
}
