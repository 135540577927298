import { Injectable } from '@angular/core';
import { DeepPartial } from '@luggagehero/shared/interfaces';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import * as OrderRequestActions from './+state/order-requests.actions';
import { OrderRequest } from './+state/order-requests.models';
import * as OrderRequestSelectors from './+state/order-requests.selectors';

@Injectable({ providedIn: 'root' })
export class OrderRequestsFacade {
  orderRequest$: Observable<DeepPartial<OrderRequest>>;

  constructor(private store: Store) {
    this.orderRequest$ = this.store.select(OrderRequestSelectors.selectOrderRequest);
  }

  updateOrderRequest(updates: DeepPartial<OrderRequest>) {
    this.store.dispatch(OrderRequestActions.updateOrderRequest({ updates }));
  }
}
