import { Injectable, Renderer2 } from '@angular/core';
import { AbstractControl, ValidationErrors } from '@angular/forms';
import { firstValueFrom } from 'rxjs';

import { ScriptService } from './script.service';

declare let jsvat;

interface VatNumberInfo {
  country: {
    name: string;
    isoCode: {
      long: string; // "DNK"
      numeric: number; // "208"
      short: string; // "DK"
    };
  };
  isValid: boolean;
  value: string; // "DK37611328"
}

@Injectable()
export class VatNumberService {
  static isReady = false;
  private isInitialized = false;

  constructor(private script: ScriptService) {}

  public async init(_renderer: Renderer2, _doc: unknown): Promise<void> {
    if (this.isInitialized) {
      return;
    }
    this.isInitialized = true;

    await firstValueFrom(this.script.load('jsvat'));
    VatNumberService.isReady = true;
  }

  public validate(control: AbstractControl): ValidationErrors {
    if (!control.value || !VatNumberService.isReady) {
      return null;
    }
    const result = this.checkVatNumber(control.value as string);
    if (!result.isValid) {
      return !result.value.match(/^[a-zA-Z]{2}/) ? { countryCodeRequired: true } : { invalidVatNumber: true };
    }
    return null;
  }

  private checkVatNumber(value: string): VatNumberInfo {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
    return jsvat.checkVAT(value);
  }
}
