import { ChangeDetectionStrategy, Component } from '@angular/core';
import { LangSwitcherBaseComponent } from '@luggagehero/features/ui';

@Component({
  selector: 'lh-lang-switcher',
  templateUrl: 'lang-switcher.component.html',
  styleUrls: ['lang-switcher.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LangSwitcherComponent extends LangSwitcherBaseComponent {}
