import { Injectable } from '@angular/core';
import { Config } from '@luggagehero/shared/environment';
import { Review, ReviewStats } from '@luggagehero/shared/interfaces';
import { SharedHttpService } from '@luggagehero/shared/services/http';
import { SharedStorageService } from '@luggagehero/shared/services/storage';
import { SharedUserService } from '@luggagehero/shared/services/users';
import { SharedWindowService } from '@luggagehero/shared/services/window';

@Injectable({
  providedIn: 'root',
})
export class SharedReviewsService {
  private _currentStats: ReviewStats;

  constructor(
    private httpService: SharedHttpService,
    private userService: SharedUserService,
    private storageService: SharedStorageService,
    private windowService: SharedWindowService,
  ) {}

  public get currentStats(): ReviewStats {
    return this._currentStats;
  }

  public get targetReviewLocale(): string {
    if (this.userService.isLoggedIn && this.userService.user && this.userService.user.locale) {
      return this.userService.user.locale;
    }

    if (this.storageService.language) {
      return this.storageService.language;
    }

    if (this.windowService.locale) {
      return this.windowService.locale;
    }

    return undefined;
  }

  public async getStats(refresh = false): Promise<ReviewStats> {
    if (!this.currentStats || refresh) {
      const url = `${Config.environment.TRAVELER_API}/v2/reviews/stats`;
      this._currentStats = await this.httpService.get<ReviewStats>(url);
    }

    return this.currentStats;
  }

  public listReviewsByStorageLocation(storageLocationId?: string): Promise<Review[]> {
    const url = new URL(`${Config.environment.TRAVELER_API}/v2/storage_locations/${storageLocationId}/reviews`);

    if (this.targetReviewLocale) {
      url.searchParams.append('locale', this.targetReviewLocale);
    }

    return this.httpService.get<Review[]>(url.toString());
  }

  public listReviews(count: number): Promise<Review[]> {
    const url = new URL(`${Config.environment.TRAVELER_API}/v2/reviews`);

    if (count) {
      url.searchParams.append('limit', String(count));
    }

    if (this.targetReviewLocale) {
      url.searchParams.append('locale', this.targetReviewLocale);
    }

    return this.httpService.get<Review[]>(url.toString());
  }

  public getReview(bookingId: string): Promise<Review> {
    const url = `${Config.environment.TRAVELER_API}/v2/bookings/${bookingId}/reviews`;
    return this.httpService.get<Review>(url);
  }

  public addReview(review: Review): Promise<Review> {
    const url = `${Config.environment.TRAVELER_API}/v2/bookings/${review.bookingId}/reviews`;
    return this.httpService.post<Review>(url, review);
  }

  public updateReview(review: Review): Promise<Review> {
    const url = `${Config.environment.TRAVELER_API}/v2/bookings/${review.bookingId}/reviews/${review._id}`;
    return this.httpService.put<Review>(url, review);
  }
}
