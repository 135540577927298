import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { RouterExtensions } from '@luggagehero/core';
import { SmoothLoginBaseComponent } from '@luggagehero/features/user';
import { FacebookLoginService, GoogleSigninService } from '@luggagehero/services';
import { SharedErrorService } from '@luggagehero/shared/services/error';
import { SharedLoggingService } from '@luggagehero/shared/services/logging';
import { SharedNotificationService } from '@luggagehero/shared/services/notification';
import { SharedStorageService } from '@luggagehero/shared/services/storage';
import { SharedUserService } from '@luggagehero/shared/services/users';
import { SharedWindowService } from '@luggagehero/shared/services/window';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'lh-smooth-login',
  templateUrl: './smooth-login.component.html',
  styleUrls: ['./smooth-login.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SmoothLoginComponent extends SmoothLoginBaseComponent {
  constructor(
    formBuilder: FormBuilder,
    userService: SharedUserService,
    facebookLogin: FacebookLoginService,
    googleSignin: GoogleSigninService,
    storage: SharedStorageService,
    window: SharedWindowService,
    router: RouterExtensions,
    translate: TranslateService,
    notify: SharedNotificationService,
    error: SharedErrorService,
    log: SharedLoggingService,
    cd: ChangeDetectorRef,
  ) {
    super(
      formBuilder,
      userService,
      facebookLogin,
      googleSignin,
      storage,
      window,
      router,
      translate,
      notify,
      error,
      log,
      cd,
    );
  }

  public get isAppleSignInAvailable(): boolean {
    // Not supporting Apple Sign-in on web for now
    return false;
  }

  public signInWithApple(): Promise<void> {
    throw new Error('Apple sign-in is not supported on web');
  }
}
