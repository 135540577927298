import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { ModalService } from '@luggagehero/core';
import { OrderStepBaseComponent } from '@luggagehero/features/shops';
import { PromoCodeComponent } from '@luggagehero/web/features/ui';

@Component({
  selector: 'lh-order-step',
  templateUrl: './order-step.component.html',
  styleUrls: ['./order-step.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class OrderStepComponent extends OrderStepBaseComponent {
  private modalService = inject(ModalService);

  async showPromoCodeModal(): Promise<void> {
    await this.modalService.show({ component: PromoCodeComponent });
  }
}
