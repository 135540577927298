import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { AppConfig, SharedAppSettingsService } from '@luggagehero/shared/app-settings/data-access';
import { BookableStorageLocation, StorageLocationService } from '@luggagehero/shared/interfaces';
import { SharedStorageService } from '@luggagehero/shared/services/storage';

export interface CheckVariantParams {
  storageLocation?: BookableStorageLocation;
}

@Injectable({
  providedIn: 'root',
})
export class SharedSplitTestingService {
  private initPromise: Promise<void>;
  private initResolve: () => void;

  /** Gets the current variant to apply for the clieent; or null if no variant is enabled. */
  public get variant(): string {
    if (this.storage.variant !== null && this.storage.variant !== undefined) {
      return this.storage.variant;
    }
    return this.settings.current?.variantId || null;
  }

  /** Gets the current experiment which the client is part of; or null if no experiment is enabled. */
  public get experiment(): string {
    if (this.storage.experimentId !== null && this.storage.experimentId !== undefined) {
      return this.storage.experimentId;
    }
    return this.settings.current?.experimentId || null;
  }

  constructor(
    private settings: SharedAppSettingsService,
    private storage: SharedStorageService,
  ) {
    // Initialize the initPromise and store its resolve function
    this.initPromise = new Promise<void>((resolve) => (this.initResolve = resolve));
  }

  /**
   * Initializes the split testing service by configuring experiment and variant to apply (if any). If no experiment or
   * variant is provided with the given params, the service will use values from app settings, if available.
   * @param params The query parameters from the current route
   * @returns void
   */
  public init(params: Params): void {
    const variant: string | undefined =
      (params.utm_variant as string) ?? (params.lh_variant as string) ?? (params.variant as string) ?? undefined;

    if (variant !== undefined && variant !== null) {
      // Use the variant from the query params if provided
      this.storage.experimentId = null;
      this.storage.variant = String(variant);
    } else {
      // Otherwise, use experiment and variant ids from app settings (retrieved from the server)
      this.storage.experimentId = this.settings.current?.experimentId || null;
      this.storage.variant = this.settings.current?.variantId || null;
    }

    // Resolve the initPromise to indicate that initialization is complete
    if (this.initResolve) {
      this.initResolve();
      this.initResolve = undefined;
    }
  }

  /**
   * Determines if a given variant is currently enabled. Will wait for the service to be initialized before returning.
   * @param variant The variant to check.
   * @param params Additional parameters to consider when checking the variant (optional).
   * @returns A Promise that resolves to true if the specified variant is enabled; otherwise, false.
   */
  public async isVariantEnabled(variant: string, params?: CheckVariantParams): Promise<boolean> {
    // Wait for init() to be called and completed
    await this.initPromise;

    const { storageLocation } = params || {};

    // Check variant-specific conditions
    switch (variant) {
      case AppConfig.EXPERIMENT_VARIANTS.newGuestDropoffFlow: {
        if (
          !storageLocation ||
          storageLocation?.services?.length !== 1 ||
          storageLocation?.services[0] !== StorageLocationService.GuestLuggageStorage
        ) {
          // Don't use the new guest dropoff flow if the storage location offers other services
          return false;
        }
        break;
      }
    }

    return this.variant === variant;
  }
}
