import { ChangeDetectionStrategy, Component } from '@angular/core';
import { SimpleTimePeriodPickerBaseComponent } from '@luggagehero/features/ui';

@Component({
  selector: 'lh-simple-time-period-picker',
  templateUrl: './simple-time-period-picker.component.html',
  styleUrls: ['./simple-time-period-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SimpleTimePeriodPickerComponent extends SimpleTimePeriodPickerBaseComponent {}
