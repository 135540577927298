<lh-storage-location-item
  [storageLocation]="shop"
  [locationType]="locationType"
  [title]="title"
  [averageRating]="averageRating"
  [showAverageRating]="showAverageRating"
  [numberOfReviews]="numberOfReviews"
  [showNumberOfReviews]="showNumberOfReviews"
  [isRecommended]="isRecommended"
  [isOpen]="isOpen"
  [openingHours]="openingHours | timeInterval: true | async"
  [nextOpenDay]="nextOpenDay"
  [opensAt]="opensAt | async"
  [closesAt]="closesAt | async"
  [isFullyBooked]="isFullyBooked"
  [locationInfo]="locationInfo | async"
  [holidayNoticeHtml]="holidayNoticeHtml"
  [isMultiDayStorageAllowed]="isMultiDayStorageAllowed"
  [footnotePart1Html]="hourlyRateHtml | async"
  [footnotePart2Html]="dailyRateHtml | async"
  [actions]="actions"
  [badges]="badges"
  [index]="index"
></lh-storage-location-item>
