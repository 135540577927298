<div class="order-summary" [class.no-padding]="noPadding">
  <!-- Headline -->
  <div class="header" [class.collapsed]="isCollapsed">
    <div class="headline">{{ headline }}</div>
    <a class="href" [class.long]="isCallToActionLong" *ngIf="callToAction" (click)="onCallToActionClick()">{{
      callToAction
    }}</a>
  </div>

  @if (!order || isLoading) {
    <!-- Dummy order lines shown before order is ready -->
    <div class="updating">
      <div
        *ngFor="let x of [].constructor(dummyOrderLineCount); let i = index"
        class="row item"
        [class.collapsed]="isCollapsed"
      >
        <div class="col-9 item-label">
          <label class="item-title dummy-item" [ngClass]="getDummyItemSizeClass(i)">&nbsp;</label>
        </div>
        <div class="col-3 item-price">
          <div class="dummy-item" [ngClass]="getDummyItemSizeClass(i)">&nbsp;</div>
        </div>
      </div>
    </div>
  } @else {
    <!-- Order lines -->
    <div *ngFor="let line of order.orderLines; let i = index" class="row item" [class.collapsed]="isCollapsed">
      <div class="col-9 item-label" [class.text-success]="line.unitPrice < 0">
        <label class="item-title" [class.disabled]="line.selected === false">
          <input
            type="checkbox"
            [id]="'orderLine' + i + 'Selected'"
            [name]="'orderLine' + i + 'Selected'"
            [(ngModel)]="line.selected"
            (change)="onOrderLineChange(line)"
            *ngIf="line.optional && line.unitPrice > 0 && !isReadOnly"
          />
          <span>
            {{ line.product.name | translate
            }}<span *ngIf="line.showQuantity"
              ><span *ngIf="line.showUnitPrice && line.product.unitSingular"
                >&nbsp;({{ line.unitPrice | price: order.currency : 2 }}/{{
                  line.product.unitSingular | translate | lowercase
                }})</span
              ><span *ngIf="!line.showQuantityAsUpTo"
                >&nbsp;x {{ line.quantity }}
                {{
                  (line.quantity === 1 ? line.product.unitSingular : line.product.unitPlural) | translate | lowercase
                }}</span
              ><span *ngIf="line.showQuantityAsUpTo"
                >, {{ 'UP_TO_X_BAGS' | translate | lowercase }} {{ line.quantity }}
                {{
                  (line.quantity === 1 ? line.product.unitSingular : line.product.unitPlural) | translate | lowercase
                }}
              </span>
            </span>
          </span>
          <lh-shared-ui-tooltip
            *ngIf="line.product.info && isInfoEnabled"
            callToAction="i"
            [header]="line.product.info.header | translate"
            [body]="line.product.info.body | translate"
          ></lh-shared-ui-tooltip>
        </label>
      </div>
      <div
        class="col-3 item-price"
        [class.text-success]="line.unitPrice < 0"
        [class.disabled]="line.optional && !line.selected"
      >
        <span [class.free-item]="isIncludedForFree(line)">{{ getPriceText(line) }}</span>
      </div>
    </div>
  }

  <!-- Divider -->
  <div class="row item" [class.collapsed]="isCollapsed">
    <div class="col-12">
      <hr />
    </div>
  </div>

  <div class="row item">
    @if (showCollapseToggle) {
      <div class="col-6 order-summary-toggle" (click)="toggleCollapse()">
        @if (!isCollapsed) {
          {{ 'HIDE_SUMMARY' | translate }}
        } @else {
          {{ 'SHOW_SUMMARY' | translate }}
        }
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="chevron"
          [class.up]="isCollapsed"
        >
          <path stroke-linecap="round" stroke-linejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
        </svg>
      </div>
    }

    <div
      class="total"
      [class.can-self-collapse]="showCollapseToggle"
      [class.col-6]="showCollapseToggle"
      [class.col-12]="!showCollapseToggle"
    >
      <span *ngIf="totalLabel">{{ totalLabel }}</span>
      <span *ngIf="!totalLabel" class="dummy-item updating">&nbsp;</span>

      <div class="total-amount">
        <span *ngIf="orderTotal">{{ orderTotal }}</span>
        <span *ngIf="!orderTotal" class="dummy-item updating">&nbsp;</span>
      </div>
    </div>

    <div class="col-12 total-note" *ngIf="totalNote">{{ totalNote }}</div>
  </div>
</div>
