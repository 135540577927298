import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IDiscount } from '@luggagehero/shared/interfaces';
import { SharedPromoCodeService } from '@luggagehero/shared/services/promo-codes';
import { SharedStorageCriteriaService } from '@luggagehero/shared/services/storage-criteria';

import { BaseModalComponent } from '../../../core';
@Component({ template: '' })
export class PromoCodeBaseComponent extends BaseModalComponent implements OnInit {
  @Output() public done = new EventEmitter<void>();

  private _promoCode: string;
  private _isLoading = false;
  private codeRechecked = false;

  constructor(
    private promoCodeService: SharedPromoCodeService,
    private criteriaService: SharedStorageCriteriaService,
    private cd: ChangeDetectorRef,
  ) {
    super();
  }

  get promoCode(): string {
    return this._promoCode;
  }
  set promoCode(value: string) {
    this._promoCode = value ? value.toUpperCase() : '';
    this.cd.markForCheck();
  }

  get bags(): number {
    return this.criteriaService.currentOrDefault.luggage.normal;
  }

  get isLoading(): boolean {
    return this._isLoading;
  }
  @Input() set isLoading(value: boolean) {
    this._isLoading = value;
    this.cd.markForCheck();
  }

  get canCheckPromoCode(): boolean {
    if (!this.promoCode) {
      return false;
    }

    this.promoCode = this.promoCode.trim();

    if (this.promoCode === this.promoCodeService.checkedPromoCode) {
      return false;
    }
    return true;
  }

  get canApplyPromoCode(): boolean {
    if (this.isLoading) {
      return false;
    }
    if (!this.promoCode) {
      return false;
    }
    if (this.promoCode === this.promoCodeService.appliedPromoCode && !this.codeRechecked) {
      return false;
    }
    if (this.promoCode !== this.promoCodeService.checkedPromoCode) {
      return false;
    }
    return this.promoCodeService.isCheckedPromoCodeValid;
  }

  get canRemovePromoCode(): boolean {
    if (this.isLoading) {
      return false;
    }
    if (!this.promoCodeService.appliedPromoCode) {
      return false;
    }
    if (this.promoCodeService.appliedPromoCode !== this.promoCode) {
      return false;
    }
    if (this.promoCodeService.appliedPromoCode !== this.promoCodeService.checkedPromoCode) {
      return false;
    }
    return true;
  }

  get showCheckedPromoCodeInvalid(): boolean {
    if (this.isLoading) {
      return false;
    }

    if (!this.promoCode) {
      return false;
    }

    if (this.promoCodeService.isCheckedPromoCodeValid !== false) {
      return false;
    }

    if (this.promoCode !== this.promoCodeService.checkedPromoCode) {
      return false;
    }

    return true;
  }

  get showCheckedPromoCodeValid(): boolean {
    if (this.isLoading) {
      return false;
    }
    if (!this.promoCodeService.checkedPromoCode) {
      return false;
    }
    if (this.promoCodeService.isCheckedPromoCodeValid !== true) {
      return false;
    }
    if (this.promoCode !== this.promoCodeService.checkedPromoCode) {
      return false;
    }
    if (!this.promoCodeService.checkedDiscount) {
      return false;
    }
    return true;
  }

  get checkedDiscount(): IDiscount {
    return this.promoCodeService.checkedDiscount;
  }

  get checkedPromoCode(): string {
    return this.promoCodeService.checkedPromoCode;
  }

  ngOnInit() {
    this.promoCode = this.promoCodeService.appliedPromoCode;
  }

  async checkPromoCode() {
    this.isLoading = true;
    this.codeRechecked = true;

    // Ensure that the spinner is shown for a meaningful amount of time
    const minimumDelay = new Promise((resolve) => setTimeout(resolve, 1000));

    await this.promoCodeService.checkPromoCode(this.promoCode);
    await minimumDelay;

    this.isLoading = false;
  }

  applyPromoCode() {
    if (this.canApplyPromoCode) {
      void this.promoCodeService.applyPromoCode(this.promoCode);
    }
    this.cd.markForCheck();
    this.done.emit();

    void this.hideModal();
  }

  cancelPromoCode() {
    this.promoCode = this.promoCodeService.appliedPromoCode;
    this.done.emit();
  }

  removePromoCode() {
    this.promoCodeService.clearPromoCode();
    this.promoCode = '';
    this.cd.markForCheck();
  }
}
