import { ChangeDetectorRef, Component } from '@angular/core';

import { DynamicFormControlBaseComponent } from './dynamic-form-control.base-component';

@Component({ template: '', selector: 'lh-dynamic-form-input-base' })
export abstract class DynamicFormInputBaseComponent extends DynamicFormControlBaseComponent {
  constructor(cd: ChangeDetectorRef) {
    super(cd);
  }
}
