import { ChangeDetectionStrategy, Component, forwardRef, Provider } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BagSelectorBaseComponent } from '@luggagehero/features/ui/base/bag-selector.base-component';

export const BAG_SELECTOR_CONTROL_VALUE_ACCESSOR: Provider = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => BagSelectorComponent),
  multi: true,
};

@Component({
  selector: 'lh-bag-selector',
  templateUrl: './bag-selector.component.html',
  styleUrls: ['./bag-selector.component.scss'],
  providers: [BAG_SELECTOR_CONTROL_VALUE_ACCESSOR],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BagSelectorComponent extends BagSelectorBaseComponent implements ControlValueAccessor {}
