<div class="luggage-picker">
  <div class="custom-select" [class.custom-select-criteriabar]="!inPage">
    <i class="icon-bag"></i>
    <select
      class="form-select"
      [selectedIndex]="normalSelectedIndex"
      (change)="selectNormalLuggage(normalSelect.value)"
      #normalSelect
    >
      <option *ngFor="let option of normalOptions" [value]="option - 1">{{ option }}</option>
    </select>
    <span
      >{{ normalOptions[normalSelectedIndex] }}
      {{ (normalSelectedIndex === 0 ? 'BAG' : 'BAGS') | translate | lowercase }}</span
    >
  </div>
</div>
