import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ManagePaymentMethodsBaseComponent } from '@luggagehero/features/user';
import { SharedErrorService } from '@luggagehero/shared/services/error';
import { SharedNotificationService } from '@luggagehero/shared/services/notification';
import { SharedPaymentService } from '@luggagehero/shared/services/payments';
import { SharedUserService } from '@luggagehero/shared/services/users';
import { SharedWindowService } from '@luggagehero/shared/services/window';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'lh-manage-payment-methods',
  templateUrl: 'manage-payment-methods.component.html',
  styleUrls: ['manage-payment-methods.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class ManagePaymentMethodsComponent extends ManagePaymentMethodsBaseComponent implements OnInit {
  constructor(
    paymentService: SharedPaymentService,
    windowService: SharedWindowService,
    notify: SharedNotificationService,
    error: SharedErrorService,
    translate: TranslateService,
    cd: ChangeDetectorRef,
    userService: SharedUserService,
  ) {
    super(paymentService, windowService, notify, error, translate, cd, userService);
  }
}
