import { ChangeDetectorRef, Component, inject, Input, OnDestroy, OnInit } from '@angular/core';
import { SharedUserService } from '@luggagehero/shared/services/users';
import { Subscription } from 'rxjs';

import { BaseComponent } from '../../../core';

@Component({ template: '' })
export abstract class UserAvatarBaseComponent extends BaseComponent implements OnInit, OnDestroy {
  private userService = inject(SharedUserService);
  private cd = inject(ChangeDetectorRef);

  private subscriptions: Subscription[];
  private _lightBackground: boolean;

  public get lightBackground(): boolean {
    return this._lightBackground;
  }
  @Input() public set lightBackground(value: boolean) {
    this._lightBackground = value;
    this.cd.markForCheck();
  }

  public get profilePicture(): string {
    if (!this.userService.user) {
      return null;
    }
    return this.userService.user.picture;
  }

  public get notificationCount(): number {
    return this.userService.notificationCount;
  }

  public ngOnInit() {
    this.subscriptions = [this.userService.loginAnnounced.subscribe((_e) => this.cd.markForCheck())];
  }

  public ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }
}
