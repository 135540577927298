import { Injectable } from '@angular/core';

import { BaseModalComponent } from '../base/base-modal-component';
import { ModalFactory, ModalOptions, ModalRef } from '../interfaces/modal';

@Injectable()
export abstract class ModalService implements ModalFactory {
  public presentingElement: HTMLElement;
  private _modalCount = 0;

  get modalCount(): number {
    return this._modalCount;
  }

  public incrementCount() {
    this._modalCount++;
  }

  public decrementCount() {
    if (this.modalCount > 0) {
      this._modalCount--;
    }
  }

  abstract show<T extends BaseModalComponent>(options: ModalOptions<T>): Promise<ModalRef>;
  abstract hide(modalId?: string): Promise<void>;
}
