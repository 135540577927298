import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';

import { Address } from './address.interface';
import { Place } from './place.interface';
import { BookableStorageLocation } from './storage-locations';

export interface IGoogleMapsService {
  geocode(request: google.maps.GeocoderRequest): Observable<google.maps.GeocoderResult[]>;
  autocomplete(
    request: google.maps.places.AutocompletionRequest,
  ): Observable<google.maps.places.AutocompletePrediction[]>;
  parsePlaceResult(data: google.maps.places.PlaceResult): Place;
  parseGeocoderResult(value: google.maps.GeocoderResult): Place;
  parseAddress(
    addressComponents: google.maps.GeocoderAddressComponent[],
    formattedAddress: string,
    lat: number,
    lon: number,
  ): Address;

  parseCity(addressComponents: google.maps.GeocoderAddressComponent[]): string;
  parseRegion(addressComponents: google.maps.GeocoderAddressComponent[]): string;
  getDirectionsLink(storageLocation: BookableStorageLocation): string;
  getDirectionsLink(location: google.maps.LatLng): string;
  getDirectionsLink(locationOrStorageLocation: google.maps.LatLng | BookableStorageLocation): string;
  getPlaceLink(storageLocation: BookableStorageLocation, zoom?: number): string;
}

export const GOOGLE_MAPS_SERVICE = new InjectionToken<IGoogleMapsService>('GOOGLE_MAPS_SERVICE');
