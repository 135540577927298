import {
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  Type,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AppConfig } from '@luggagehero/shared/app-settings/data-access';
import { Config } from '@luggagehero/shared/environment';
import { BookableStorageLocation, Booking, IPrice, LegacyOrder, Review } from '@luggagehero/shared/interfaces';
import { SharedAnalyticsService } from '@luggagehero/shared/services/analytics';
import { SharedBookingService } from '@luggagehero/shared/services/bookings';
import { SharedDocumentService } from '@luggagehero/shared/services/document';
import { SharedGoogleMapsService } from '@luggagehero/shared/services/google-maps';
import { SharedLocationService } from '@luggagehero/shared/services/locations';
import { SharedNotificationService } from '@luggagehero/shared/services/notification';
import { SharedReviewsService } from '@luggagehero/shared/services/reviews';
import { SharedShopsService } from '@luggagehero/shared/services/shops';
import { SharedStorageService } from '@luggagehero/shared/services/storage';
import { SharedWindowService } from '@luggagehero/shared/services/window';
import { TranslateService } from '@ngx-translate/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { isObservable, Observable, of as observableOf, Subscription } from 'rxjs';

import { BaseComponent } from '../../../../core';
import { ModalService } from '../../../../core/services/index';
import { DateUtil } from '../../../../utils/date.util';
import {
  BookingActionBaseComponent,
  BookingActionInfo,
  BookingActionState,
  BookingChange,
  DropOffActionBaseComponent,
  ExplainStorageTimerActionBaseComponent,
  ExploreExperiencesActionBaseComponent,
  GoToShopActionBaseComponent,
  LeaveFeedbackActionBaseComponent,
  ModifyBookingActionBaseComponent,
  SelectPaymentMethodActionBaseComponent,
  ShowReceiptActionBaseComponent,
  UndoDropOffActionBaseComponent,
} from './booking-actions';
import { BookingOptionsBaseComponent } from './booking-options.base-component';
import { BookingTimelineStrategy } from './booking-strategies';
import { BookingTimelineBaseComponent } from './booking-timeline.base-component';

type AlertType = 'success' | 'warning' | 'danger';

const UPDATE_ALERT_INTERVAL = 1000 * 10;
const UNDO_DROP_OFF_PERIOD = 2;

@Component({ template: '' })
export abstract class ManageBookingTimelineBaseComponent extends BaseComponent implements OnInit, OnDestroy {
  @ViewChild('modalContent', { read: ViewContainerRef }) modalContent: ViewContainerRef;
  @ViewChild('modal') modal: ModalDirective;
  @ViewChild('bookingTimeline') bookingTimeline: BookingTimelineBaseComponent;
  @ViewChild('bookingOptions') bookingOptions: BookingOptionsBaseComponent;
  @Output() loading = new EventEmitter<boolean>(false);

  modalComponentRef: ComponentRef<BookingActionBaseComponent>;
  currentAction: BookingActionInfo;
  currentActionSubscriptions: Subscription[] = [];

  isCheckInSession = false;
  isSkipAction = false;

  shareBookingOptionsVisible = false;

  private _averageRating = 0;
  private _booking: Booking;
  private _shop: BookableStorageLocation;
  private _review: Review;
  private _strategy: BookingTimelineStrategy;

  private paramsSubscription: Subscription;

  private isDirty = false;
  private _isModalReady = false;
  private _isLoading = false;

  protected promptFeedbackTimer: NodeJS.Timeout;
  protected feedbackPrompted = false;
  protected promptExperiencesTimer: NodeJS.Timeout;
  protected experiencesPrompted = false;
  protected isUndoDropOffSkipped = false;
  protected isSelectPaymentMethodSkipped = false;

  protected alertType: AlertType;
  private _alertMessage: string;
  private _alertAction: BookingActionInfo;

  private previousScrollPosition = 0;

  private shareLink: string;

  constructor(
    protected modalService: ModalService,
    protected storageService: SharedStorageService,
    protected documentService: SharedDocumentService,
    protected bookingService: SharedBookingService,
    protected shopsService: SharedShopsService,
    protected reviewsService: SharedReviewsService,
    protected locationService: SharedLocationService,
    protected googleMapsService: SharedGoogleMapsService,
    protected windowService: SharedWindowService,
    protected route: ActivatedRoute,
    protected translateService: TranslateService,
    protected notify: SharedNotificationService,
    protected analytics: SharedAnalyticsService,
    protected resolver: ComponentFactoryResolver,
    protected cd: ChangeDetectorRef,
  ) {
    super();
    this.documentService.addBodyClass('manage-booking-timeline');
  }

  get isNative(): boolean {
    return this.windowService.isNative;
  }

  get isSharingAvailable(): boolean {
    return this.windowService.isSharingAvailable;
  }

  get isClipboardAvailable(): boolean {
    return this.windowService.isClipboardAvailable;
  }

  get isTabbedNavigationEnabled(): boolean {
    return this.windowService.isTouch && AppConfig.IS_TABBED_NAVIGATION_ENABLED;
  }

  get showOrderSummary(): boolean {
    if (!this.order) {
      return false;
    }
    return AppConfig.IS_ORDER_SUMMARY_ON_BOOKING_TIMELINE_ENABLED;
  }

  get showNumberOfReviews(): boolean {
    return this.numberOfReviews > 1;
  }

  get averageRating(): number {
    return this._averageRating;
  }
  set averageRating(value: number) {
    this._averageRating = value;
    this.cd.detectChanges();
  }

  get numberOfReviews(): number {
    if (!this.shop || !this.shop.stats) {
      return 0;
    }
    return this.shop.stats.numberOfRatings;
  }

  get numberOfStars(): number {
    if (AppConfig.IS_FULL_STAR_RATING_DISABLED) {
      return 1;
    }
    return 5;
  }

  get storageLocationImage(): string {
    if (!this.shop || !this.shop.images || this.shop.images.length === 0) {
      return 'assets/no-image.jpg';
    }
    return `${Config.environment.IMAGES_BASE_URL}/images/medium/${this.shop.images[0]}`;
  }

  get inlineTimerMode(): boolean {
    if (!this.strategy) {
      return false;
    }
    return this.strategy.useInlineTimer;
  }

  get strategy(): BookingTimelineStrategy {
    return this._strategy;
  }
  set strategy(value: BookingTimelineStrategy) {
    this._strategy = value;

    // HACK: Fixes timer not showing up until something else triggers a UI update
    setTimeout(() => this.cd.markForCheck(), 0);
  }

  get alertMessage(): string {
    return this._alertMessage;
  }

  get alertAction(): BookingActionInfo {
    return this._alertAction;
  }

  get isSuccessAlert() {
    if (!this.alertType) {
      return false;
    }
    return this.alertType === 'success';
  }

  get isWarningAlert() {
    if (!this.alertType) {
      return false;
    }
    return this.alertType === 'warning';
  }

  get isDangerAlert() {
    if (!this.alertType) {
      return false;
    }
    return this.alertType === 'danger';
  }

  get modalId(): string {
    const actionName = this.currentAction ? this.currentAction.name : 'NoAction';
    return `actionModal_${actionName}`;
  }

  get modalTitle(): string {
    if (!this.currentAction) {
      return '';
    }
    if (this.isSkipAction && this.currentAction.skipTitle) {
      return this.currentAction.skipTitle;
    }
    return this.currentAction.title;
  }

  get isModalFullHeight(): boolean {
    if (this.currentAction?.viewSize === 'auto') {
      return false;
    }
    return this.modalTitle ? false : true;
  }

  get modalSubtitle(): string {
    if (!this.currentAction) {
      return '';
    }
    if (this.isSkipAction) {
      return this.currentAction.skipSubtitle;
    }
    return this.currentAction.subtitle;
  }

  get isModalReady(): boolean {
    return this._isModalReady;
  }
  set isModalReady(value: boolean) {
    this._isModalReady = value;
    this.cd.markForCheck();
  }

  get currency(): string {
    if (!this.booking) {
      return '';
    }
    return this.booking.price.final?.currency || this.booking.price.pricing.currency;
  }

  get booking(): Booking {
    return this._booking;
  }
  set booking(value: Booking) {
    this._booking = value;
    this.updateAlert();

    // FIXME: Remove these, should be updated via binding
    if (this.bookingTimeline) {
      this.bookingTimeline.booking = value;
    }
    if (this.bookingOptions) {
      this.bookingOptions.booking = value;
    }
  }

  get shop(): BookableStorageLocation {
    return this._shop;
  }
  set shop(value: BookableStorageLocation) {
    this._shop = value;
    this.cd.markForCheck();

    if (!value) {
      return;
    }
    this.averageRating = value.stats ? value.stats.averageRating : AppConfig.GLOBAL_AVERAGE_RATING;
  }

  get review(): Review {
    return this._review;
  }
  set review(value: Review) {
    this._review = value;
    this.cd.markForCheck();
  }

  get isLoading(): boolean {
    return this._isLoading;
  }
  set isLoading(value: boolean) {
    this._isLoading = value;
    this.loading.emit(value);
    this.cd.markForCheck();
  }

  get ios(): boolean {
    return this.windowService.iOS;
  }

  get modalError(): boolean {
    return this.modalTitle && this.currentAction && this.currentAction.error;
  }

  get modalWarning(): boolean {
    return this.modalTitle && this.currentAction && this.currentAction.warning;
  }

  get modalSuccess(): boolean {
    return this.modalTitle && this.currentAction && this.currentAction.success;
  }

  get order(): LegacyOrder {
    return this.booking?.orderDetails?.length > 0
      ? this.booking.orderDetails[this.booking.orderDetails.length - 1]
      : null;
  }

  get isSettled(): boolean {
    return ['PAID', 'CANCELLED'].includes(this.booking?.status);
  }

  get orderSummaryHeadline(): string {
    return this.booking?.status === 'CANCELLED' ? 'BOOKING_CANCELED' : 'BOOKING_SUMMARY';
  }

  get finalPrice(): IPrice {
    return this.booking.price.final;
  }

  get isUndoDropOffAllowed(): boolean {
    if (!AppConfig.IS_UNDO_DROPOFF_ENABLED) {
      return false;
    }
    if (this.booking.status !== 'CHECKED_IN') {
      return false;
    }
    const minutesPassed = DateUtil.minutesSinceCheckIn(this.booking);
    return minutesPassed <= UNDO_DROP_OFF_PERIOD;
  }

  get isDirectionsVisible(): boolean {
    if (
      !this.booking.paymentMethodId &&
      this.bookingTimeline &&
      this.bookingTimeline.config.paymentInfoRequiredBeforeDirections
    ) {
      return false;
    }
    return true;
  }

  protected get addLuggageImageAction(): BookingActionInfo {
    return new BookingActionInfo('PromptLuggageImageFromBookingTimeline')
      .withAction({ componentToShow: this.addLuggageImageComponentType })
      .withTitle(this.translate('TAKE_A_PHOTO_OF_YOUR_LUGGAGE'));
  }

  protected get viewStorageLocationActionInfo(): BookingActionInfo {
    if (!this.booking.paymentMethodId && this.bookingTimeline.config.paymentInfoRequiredBeforeDirections) {
      return new BookingActionInfo('ViewStorageLocationFromBookingTimeline')
        .withCallToAction(this.translate('GET_DIRECTIONS'))
        .withAction({
          functionToCall: () => {
            const message = this.translate('PAYMENT_METHOD_BEFORE_DIRECTIONS_ALERT');
            const title = this.translate('PAYMENT_METHOD_MISSING');
            this.windowService.alert(message, title);

            return Promise.resolve(null);
          },
          componentToShow: this.selectPaymentMethodActionComponentType,
        })
        .withTitle(this.translate('DIRECTIONS_AFTER_PAYMENT_METHOD'))
        .withSubtitle(this.translate('PAYMENT_METHOD_MISSING'))
        .withBookingChangeHandler((b) => this.onPaymentMethodChange(b));
    }
    return new BookingActionInfo('ViewStorageLocationFromBookingTimeline')
      .withAction({
        componentToShow: this.goToShopActionComponentType,
      })
      .withViewSize('auto');
  }

  protected get leaveFeedbackActionInfo(): BookingActionInfo {
    return new BookingActionInfo('PromptFeedbackFromBookingTimeline')
      .withAction({ componentToShow: this.leaveFeedbackActionComponentType })
      .withTitle(this.translate('FEEDBACK'))
      .withReviewChangeHandler((r) => void this.onFeedbackReceived(r));
  }

  protected get exploreExperiencesActionInfo(): BookingActionInfo {
    return new BookingActionInfo('PromptExperiencesFromBookingTimeline')
      .withAction({ componentToShow: this.exploreExperiencesActionComponentType })
      .withTitle(this.translate('YOU_ARE_FREE'))
      .withSubtitle(this.translate('GO_ENJOY_THE_CITY_WE_GOT_YOUR_STUFF'));
  }

  protected get explainStorageTimerActionInfo(): BookingActionInfo {
    return new BookingActionInfo('ExplainStorageTimerFromBookingTimeline')
      .withAction({ componentToShow: this.explainStorageTimerActionComponentType })
      .withTitle(this.translate('THE_STORAGE_TIMER'))
      .withSubtitle(this.translate('KEEPS_TRACK_OF_HOW_LONG_YOU_STORE'));
  }

  protected get selectPaymentMethodActionInfo(): BookingActionInfo {
    return new BookingActionInfo('SelectPaymentMethodFromMissingPaymentMethodAlert')
      .withCallToAction(this.translate('ADD_NOW'))
      .withAction({ componentToShow: this.selectPaymentMethodActionComponentType })
      .withSkipAction({
        functionToCall: () => {
          this.isSelectPaymentMethodSkipped = true;
          return observableOf<BookingChange>(null);
        },
      })
      .withTitle(this.translate('PAYMENT_OPTIONS'));
  }

  protected get undoDropOffActionInfo(): BookingActionInfo {
    return new BookingActionInfo('UndoDropOffFromDropOffSuccessAlert')
      .withCallToAction(this.translate('UNDO'))
      .withAction({ componentToShow: this.undoDropOffActionComponentType })
      .withSkipAction({
        functionToCall: () => {
          this.isUndoDropOffSkipped = true;
          return observableOf<BookingChange>(null);
        },
      })
      .withTitle(this.translate('DID_YOU_START_STORAGE_TIMER_BY_MISTAKE'));
  }

  protected get orderSummaryActionInfo(): BookingActionInfo {
    const isPaid =
      ['CHECKED_OUT', 'PAID'].includes(this.booking.status) ||
      (this.booking.status === 'CANCELLED' && (this.order?.total || this.booking.price.final?.total > 0))
        ? true
        : false;

    if (isPaid) {
      let titleKey: string;
      let subtitleKey: string;
      let state: BookingActionState;

      const isPaymentFailed = this.booking.status === 'CHECKED_OUT' && !this.booking.paidDirectly;
      if (isPaymentFailed) {
        titleKey = 'PAYMENT_FAILED';
        state = 'error';
      } else if (this.booking.paidDirectly) {
        titleKey = 'PAY_IN_SHOP';
        subtitleKey = 'SHOW_THE_RECEIPT_AND_PAY_IN_SHOP';
        state = 'warning';
      } else {
        titleKey = 'PAYMENT_SUCCEEDED';
        subtitleKey = this.booking.status !== 'CANCELLED' ? 'SHOW_THE_RECEIPT_TO_THE_STAFF' : null;
        state = 'success';
      }

      return new BookingActionInfo('ShowReceiptActionFromBookingOptions')
        .withCallToAction(this.translate('RECEIPT'))
        .withAction({ componentToShow: this.showReceiptActionComponentType })
        .withTitle(this.translate(titleKey))
        .withSubtitle(subtitleKey && this.translate(subtitleKey))
        .withState(state);
    }

    if (this.booking.status === 'CANCELLED') {
      return null;
    }

    return new BookingActionInfo('ModifyBookingFromInlineBookingSummary')
      .withCallToAction(this.translate('MODIFY_BOOKING_SHORT'))
      .withAction({ componentToShow: this.modifyBookingActionComponentType })
      .withTitle(this.translate('MODIFY_BOOKING_CTA'));
  }

  protected abstract get explainStorageTimerActionComponentType(): Type<ExplainStorageTimerActionBaseComponent>;
  protected abstract get leaveFeedbackActionComponentType(): Type<LeaveFeedbackActionBaseComponent>;
  protected abstract get exploreExperiencesActionComponentType(): Type<ExploreExperiencesActionBaseComponent>;
  protected abstract get selectPaymentMethodActionComponentType(): Type<SelectPaymentMethodActionBaseComponent>;
  protected abstract get undoDropOffActionComponentType(): Type<UndoDropOffActionBaseComponent>;
  protected abstract get modifyBookingActionComponentType(): Type<ModifyBookingActionBaseComponent>;
  protected abstract get showReceiptActionComponentType(): Type<ShowReceiptActionBaseComponent>;
  protected abstract get goToShopActionComponentType(): Type<GoToShopActionBaseComponent>;
  protected abstract get addLuggageImageComponentType(): Type<DropOffActionBaseComponent>;

  ngOnInit() {
    this.averageRating = this.shop && this.shop.stats ? this.shop.stats.averageRating : AppConfig.GLOBAL_AVERAGE_RATING;

    // This is to make sure the shop criteria are available
    void this.locationService.init().then(() => this.cd.markForCheck());
    this.paramsSubscription = this.route.params.subscribe(
      (params: { id: string; luggagePhotoRequired: string; linkToPlaces: string }) => {
        if (params.id) {
          if (Config.isDevelopment) {
            console.log(`Found booking id in params`, {
              params,
              luggagePhotoRequired: params.luggagePhotoRequired,
              typeOfLuggagePhotoRequired: typeof params.luggagePhotoRequired,
            });
          }
          const isLuggagePhotoRequired = params.luggagePhotoRequired === 'true';
          void this.loadBooking(params.id, isLuggagePhotoRequired);

          if (params.linkToPlaces) {
            this.googleMapsService.linkToPlaces = params.linkToPlaces === 'true';
          }
        } else {
          this.cd.markForCheck();
        }
      },
    );

    this.scheduleUpdateAlert(true);
  }

  ngOnDestroy() {
    this.documentService.removeBodyClass('manage-booking-timeline');
    this.destroyCurrentAction();
    try {
      this.paramsSubscription.unsubscribe();
    } catch {
      // Ignore
    }
  }

  async onAction(actionInfo: BookingActionInfo, skip = false, delay?: number) {
    if (delay > 0) {
      // Trigger action after the specified delay
      setTimeout(() => void this.onAction(actionInfo, skip), delay);
      return;
    }
    // Remove previous modal content and subscriptions
    this.destroyCurrentAction();

    // Set the currently selected action
    this.currentAction = actionInfo;
    this.isSkipAction = skip;

    // Determine which action to take
    const actionToTake = skip ? actionInfo.skipAction : actionInfo.action;

    // Show component and/or call function defined by the action
    await this.callActionFunction(actionToTake.functionToCall);
    this.showActionModal(actionToTake.componentToShow);
  }

  onAlertClicked() {
    if (!this.alertAction) {
      return;
    }
    void this.onAction(this.alertAction);
  }

  dismissAlert() {
    void this.onAction(this.alertAction, true);
    this.setAlert(null, null, null);
  }

  explainStorageTimer() {
    void this.onAction(this.explainStorageTimerActionInfo);
  }

  viewStorageLocation() {
    void this.onAction(this.viewStorageLocationActionInfo);
  }

  toggleShareBookingOptions() {
    this.shareBookingOptionsVisible = !this.shareBookingOptionsVisible;
  }

  async getLinkForSharing(): Promise<string> {
    // TODO: Store in local or session storage
    if (!this.shareLink) {
      this.shareLink = await this.bookingService.getShareableLink(this.booking._id);
    }
    return this.shareLink;
  }

  async shareBookingCopyToClipboard() {
    try {
      // Get shareable link for the booking and copy to the clipboard
      await this.windowService.writeToClipboard(this.shareLink || this.getLinkForSharing());

      // Notify the user that the link was copied
      this.notify.success(this.translate('LINK_COPIED'));
    } catch (err) {
      // Notify the user that something went wrong
      this.notify.warning(`Error copying link: ${(err as { message?: string }).message}`);
    }
  }

  async shareBookingVia() {
    if (!this.isSharingAvailable) {
      return;
    }

    try {
      const url = await this.getLinkForSharing();
      const text = `${this.translate('LUGGAGEHERO_BOOKING_AT_LOCATION')} ${this.shop.name}`;

      await this.windowService.shareLink(url, text);
    } catch (err) {
      this.notify.warning(`Sharing failed, please check your connection and try again`);
    }
  }

  protected showActionModal(actionComponentType: Type<BookingActionBaseComponent>) {
    // Check if there is a component to show
    if (!actionComponentType) {
      return;
    }
    if (actionComponentType === this.leaveFeedbackActionComponentType) {
      this.feedbackPrompted = true;
    } else {
      this.cancelPromptFeedback();
    }
    if (actionComponentType === this.exploreExperiencesActionComponentType) {
      this.experiencesPrompted = true;
    } else {
      this.cancelPromptExperiences();
    }
    // Dynamically create the relevant action component inside the modal
    this.modalComponentRef = this.modalContent.createComponent(actionComponentType);

    // Subscribe to booking and review changes from the action component
    this.addActionSubscription(this.modalComponentRef.instance.bookingChange, (b) => this.onBookingChange(b));
    this.addActionSubscription(this.modalComponentRef.instance.reviewChange, (r) => this.onReviewChange(r));

    // Subscribe to action requests from the action component
    this.addActionSubscription(
      this.modalComponentRef.instance.actionRequest,
      (requestedAction: BookingActionInfo) =>
        // HACK: Delay prevents issue with page scrolling not working after the action modal is closed
        void this.onAction(requestedAction, false, 600),
    );

    // Hide the modal when the action component is requested closed
    this.addActionSubscription(this.modalComponentRef.instance.closed, () => this.hideActionModal());

    // Listen to when the modal is shown and hidden
    this.addActionSubscription(this.modal.onShow, () => this.onModalShow());
    this.addActionSubscription(this.modal.onHide, () => this.onModalHide());
    this.addActionSubscription(this.modal.onHidden, () => this.onModalHidden());

    this.isModalReady = true;

    if (!this.modal.isShown) {
      // Show the modal with the action component
      this.modal.show();
      this.modalService.incrementCount();
    }
  }

  protected onModalShow() {
    this.previousScrollPosition = this.windowService.scrollY;

    if (AppConfig.IS_BOOKING_ACTION_MODAL_AUTO_SCROLL_ENABLED) {
      this.windowService.scrollToTop();
    }
  }

  protected onModalHide() {
    if (this.previousScrollPosition > 0 && AppConfig.IS_BOOKING_ACTION_MODAL_AUTO_SCROLL_ENABLED) {
      // Scroll back down to where we were on the page before the modal was shown
      this.windowService.scrollVertical(this.previousScrollPosition);
    }
  }

  protected onModalHidden() {
    this.isModalReady = false;
    if (this.modalComponentRef && this.modalComponentRef.instance) {
      this.modalComponentRef.instance.onHidden();
    }
    this.promptFeedback(1000);
    this.promptExperiences(1500);
  }

  protected hideActionModal() {
    if (this.isDirty) {
      this.windowService.scrollToBottom();
      this.previousScrollPosition = this.windowService.scrollX;
    }
    if (this.modal?.isShown) {
      this.modal.hide();
      this.modalService.decrementCount();
    }
  }

  protected promptLuggageImage() {
    if (this.shop.noSecuritySeals) {
      void this.onAction(this.addLuggageImageAction);
    }
  }

  protected promptExperiences(delay: number) {
    if (this.booking.status !== 'CHECKED_IN' || this.booking.state.exploreExperiencesDone || this.experiencesPrompted) {
      return false;
    }
    if (!AppConfig.CITIES_WITH_EXPERIENCES_AUTO_PROMPTED.includes(this.shop.officialLocationKey)) {
      return false;
    }
    this.promptExperiencesTimer = setTimeout(() => void this.onAction(this.exploreExperiencesActionInfo), delay);
  }

  protected cancelPromptExperiences() {
    if (!this.promptExperiencesTimer) {
      return;
    }
    clearTimeout(this.promptExperiencesTimer);
    this.promptExperiencesTimer = null;
  }

  protected promptFeedback(delay: number) {
    if (this.booking.status !== 'PAID' || this.booking.feedbackReceived || this.feedbackPrompted) {
      return;
    }
    this.promptFeedbackTimer = setTimeout(() => void this.onAction(this.leaveFeedbackActionInfo), delay);
  }

  protected cancelPromptFeedback() {
    if (!this.promptFeedbackTimer) {
      return;
    }
    clearTimeout(this.promptFeedbackTimer);
    this.promptFeedbackTimer = null;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  protected async onFeedbackReceived(review: Review) {
    const booking = await this.bookingService.getBooking(this.booking._id, true);
    this.onBookingChange(booking);
  }

  protected scheduleUpdateAlert(continuous = false) {
    setTimeout(() => {
      this.updateAlert();

      if (continuous) {
        this.scheduleUpdateAlert(true);
      }
    }, UPDATE_ALERT_INTERVAL);
  }

  protected updateAlert() {
    if (!this.booking) {
      return;
    }
    if (!this.booking.paymentMethodId && !this.isSelectPaymentMethodSkipped) {
      this.setAlert('danger', this.translate('PAYMENT_METHOD_MISSING'), this.selectPaymentMethodActionInfo);
      return;
    }
    if (this.isUndoDropOffAllowed && !this.isUndoDropOffSkipped) {
      const bagCount = this.booking.luggage.normal + this.booking.luggage.hand;
      const bagsText = this.translate(bagCount === 1 ? 'BAG' : 'BAGS').toLowerCase();
      const youDroppedOff = this.translate('YOU_DROPPED_OFF_X_BAGS');
      this.setAlert('success', `${youDroppedOff} ${bagCount} ${bagsText}`, this.undoDropOffActionInfo);
      return;
    }
    this.setAlert(null, null, null);
  }

  protected setAlert(type: AlertType, message: string, action?: BookingActionInfo) {
    this.alertType = type;
    this._alertMessage = message;
    this._alertAction = action;
    this.cd.markForCheck();
  }

  protected onBookingChange(value: Booking) {
    if (value.status === 'CHECKED_IN' && this.booking.status !== 'CHECKED_IN') {
      this.isCheckInSession = true;
    }
    // TODO: Check if anything actually changed
    this.booking = value;
    this.isDirty = true;

    // Notify booking change handler on current action if present
    if (this.currentAction && this.currentAction.bookingChangeHandler) {
      this.currentAction.bookingChangeHandler(value);
    }
  }

  protected onReviewChange(value: Review) {
    // TODO: Check if anything actually changed
    this.review = value;
    this.isDirty = true;

    // Notify booking change handler on current action if present
    if (this.currentAction && this.currentAction.reviewChangeHandler) {
      this.currentAction.reviewChangeHandler(value);
    }
  }

  protected async callActionFunction(
    actionFunction: () => Observable<BookingChange> | Promise<BookingChange>,
  ): Promise<void> {
    // Check if there is an function to call
    if (!actionFunction) {
      return;
    }

    // Call the function to perform the action
    const asyncAction = actionFunction();

    // Wait for the result to record any changes returned
    const change = await (isObservable(asyncAction) ? asyncAction.toPromise() : asyncAction);

    if (!change) {
      return;
    }
    switch (change.changeType) {
      case 'booking':
        this.booking = change.changedValue as Booking;
        break;

      case 'review':
        this.review = change.changedValue as Review;
        break;
    }
  }

  private onPaymentMethodChange(booking: Booking) {
    if (!booking.paymentMethodId) {
      return;
    }
    this.viewStorageLocation();
  }

  protected destroyCurrentAction() {
    this.hideActionModal();

    try {
      if (this.currentActionSubscriptions.length > 0) {
        this.currentActionSubscriptions.forEach((subscription) => subscription.unsubscribe());
        this.currentActionSubscriptions = [];
      }
    } catch {
      // Ignore
    }

    if (this.modalContent) {
      this.modalContent.clear();
    }
    if (this.modalComponentRef) {
      this.modalComponentRef.destroy();
      this.modalComponentRef = null;
    }
  }

  protected addActionSubscription<T>(observable: Observable<T>, handler: (value: T) => void) {
    this.currentActionSubscriptions.push(observable.subscribe(handler));
  }

  // Refresh mainly used by the user active component as call back
  public refreshBooking(refresh: boolean) {
    if (refresh && this.booking) {
      void this.loadBooking(this.booking._id, false, refresh);
    }
  }

  private async loadBooking(id: string, promptLuggageImage = false, refresh = false): Promise<void> {
    this.isLoading = true;

    try {
      console.log(`Loading booking with ID: ${id} (refresh: ${refresh})`);
      const booking = await this.bookingService.getBooking(id, refresh);

      console.log(`Loaded booking with ID: ${id}`, booking);

      // Record user ID for analytics purposes
      this.analytics.identify({ userId: booking.userId });

      try {
        // Fetch shop details
        this.shop = await this.shopsService.getShopDetails(
          booking.shopId,
          DateUtil.serializeDate(booking.period.from),
          DateUtil.serializeDate(booking.period.to),
          booking.luggage.normal,
          booking.luggage.hand,
          booking.isGuest || booking.isWalkIn ? 'dropoff' : 'marketplace',
        );
      } catch (err) {
        // TODO: Handle shops that are deleted or no longer active
        console.log(`Error loading shop details`, err);
      }

      this.review = await this.reviewsService.getReview(booking._id);
      this.booking = booking;

      const isLuggageImageAdded = this.booking.luggage.smartImages?.length > 0;
      if (promptLuggageImage && !isLuggageImageAdded) {
        this.promptLuggageImage();
      } else {
        this.promptFeedback(3000);
      }
    } catch (err) {
      // TODO: Handle errors
    }

    this.isLoading = false;
  }

  private translate(key: string): string {
    return this.translateService.instant(key) as string;
  }
}
