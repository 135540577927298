import { InjectionToken } from '@angular/core';

import { Booking } from './booking.interface';

export type BookingTransformType =
  | 'summary'
  | 'periodValue'
  | 'periodSuffix'
  | 'luggage'
  | 'luggageValue'
  | 'luggageSuffix'
  | 'latestAction'
  | 'latestIncident'
  | 'user'
  | 'status'
  | 'statusVerbose';

export interface BookingTransformationService {
  transformBooking(booking: Booking, type: BookingTransformType, includePeriod?: boolean, format?: string): string;
}

export const BOOKING_TRANSFORMATION_SERVICE = new InjectionToken<BookingTransformationService>(
  'SharedBookingTransformationService',
);
