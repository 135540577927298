import { ChangeDetectorRef, Component, inject, Input } from '@angular/core';
import {
  Booking,
  IPaymentRecord,
  LegacyOrder,
  PAYMENT_METHOD_TRANSFORMER_SERVICE,
} from '@luggagehero/shared/interfaces';
import { SharedTranslateService } from '@luggagehero/shared/services/translation';

import { BaseComponent } from '../../../core';

@Component({ template: '' })
export abstract class PaymentReceiptBaseComponent extends BaseComponent {
  private _booking: Booking;
  private _paymentMethod: IPaymentRecord;

  private translateService = inject(SharedTranslateService);
  private cd = inject(ChangeDetectorRef);

  protected paymentMethodTransformerService = inject(PAYMENT_METHOD_TRANSFORMER_SERVICE);

  @Input() set booking(value: Booking) {
    this._booking = value;
    this.cd.markForCheck();
  }
  get booking() {
    return this._booking;
  }

  @Input() set paymentMethod(value: IPaymentRecord) {
    this._paymentMethod = value;
    this.cd.markForCheck();
  }
  get paymentMethod() {
    return this._paymentMethod;
  }

  get order(): LegacyOrder {
    return this.booking?.orderDetails[this.booking.orderDetails.length - 1];
  }

  get totalNote(): string {
    const isPaid =
      this.booking.status === 'PAID' ||
      (this.booking.status === 'CANCELLED' && (this.order?.total || this.booking.price.final?.total > 0))
        ? true
        : false;

    if (isPaid) {
      return this.booking.paidDirectly
        ? this.translateService.instant('PAY_USING_ANY_METHOD_SUPPORTED_BY_SHOP')
        : this.paymentMethodTransformerService.transform(this.paymentMethod, 'summary');
    }

    return null;
  }
}
