import { Subscription } from 'rxjs';

import { ChangeDetectorRef, Component, Input, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormControl } from '@angular/forms';

import { Place } from '@luggagehero/services';
import { TranslateService } from '@ngx-translate/core';

import { ILocation, IPlacesSearch, LocationType } from '@luggagehero/shared/interfaces';
import { SharedUtilString } from '@luggagehero/shared/util';
import { BaseComponent } from '../../../core/base/base-component';
import { SharedStorageCriteriaService } from '@luggagehero/shared/services/storage-criteria';
import { SharedTranslateService, SharedTranslationsService } from '@luggagehero/shared/services/translation';

const noop = () => {
  /**/
};

@Component({ template: '' })
export abstract class LocationPickerBaseComponent
  extends BaseComponent
  implements ControlValueAccessor, OnInit, OnDestroy
{
  public searchControl: FormControl = new FormControl();
  public zoom: number;

  @ViewChild('placesSearch') public placesSearch?: IPlacesSearch;
  private _value: ILocation = { address: '', region: '', lat: 0, lon: 0, zoom: 14, radius: 50000, type: 'custom' };
  private _selectedPlace: Place;
  private _cities: ILocation[];
  private _showCityList = false;
  private cityNames: string[];
  private selectedCityIndex = 0;
  private _isTyping = false;
  private _displayValue: string;
  private subscriptions: Subscription[] = [];
  private onTouchedCallback: () => void = noop;
  private onChangeCallback: (_: unknown) => void = noop;

  constructor(
    private translationsService: SharedTranslationsService,
    private translate: SharedTranslateService,
    private ngZone: NgZone,
    private criteriaService: SharedStorageCriteriaService,
    private cd: ChangeDetectorRef,
  ) {
    super();
  }

  get value(): ILocation {
    return this._value;
  }
  set value(value: ILocation) {
    if (!value || value === this._value) {
      return;
    }
    this._value = value;

    this.updateSelectedCityIndex();
    this.onChangeCallback(value);
    this.cd.markForCheck();
  }

  get selectedPlace(): Place {
    return this._selectedPlace;
  }
  set selectedPlace(value: Place) {
    this._selectedPlace = value;

    this.isTyping = false;
    this.updateLocation(
      value.address.location[1],
      value.address.location[0],
      value.name,
      value.address.region,
      this.criteriaService.current.location.zoom,
      this.criteriaService.current.location.radius,
      '',
      'place',
    );
    this.setSearchFieldToCurrentLocation();
  }

  get displayValue(): string {
    return this._displayValue;
  }
  set displayValue(value: string) {
    this._displayValue = value;
    this.cd.markForCheck();
  }

  get displayAddress(): string {
    const commaIndex = this.value.address.indexOf(',');
    if (commaIndex > 0) {
      return this.value.address.substring(0, commaIndex);
    }
    return this.value.address;
  }

  get isTyping() {
    return this._isTyping;
  }
  set isTyping(value: boolean) {
    this._isTyping = value;
    this.cd.detectChanges();
  }

  get showCityList(): boolean {
    return this._showCityList;
  }
  @Input() set showCityList(value: boolean) {
    this._showCityList = value;
    this.cd.markForCheck();
  }

  get cities(): ILocation[] {
    return this._cities;
  }
  @Input() set cities(value: ILocation[]) {
    if (!value || value === this._cities) {
      return;
    }
    this._cities = value;
    this.translateCityNames();
  }

  get cityKeys(): string[] {
    if (!this.cities || this.cities.length === 0) {
      return null;
    }
    return this.cities.map((city) => city.key);
  }

  ngOnInit() {
    this.subscriptions.push(
      this.translate.onLanguageChange.subscribe(() => this.translateCityNames()),
      this.criteriaService.change.subscribe(() => this.setSearchFieldToCurrentLocation()),
      this.criteriaService.addressChange.subscribe(() => this.setSearchFieldToCurrentLocation()),
    );

    // Fixes some issues with UI not updating
    this.ngZone.run(() => this.cd.detectChanges());
  }

  ngOnDestroy() {
    try {
      this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    } catch {
      // Ignore
    }
  }

  writeValue(value: ILocation) {
    if (value !== undefined) {
      this.value = value;
    }
  }

  registerOnChange(fn: (_: unknown) => void) {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: () => void) {
    this.onTouchedCallback = fn;
  }

  startTyping() {
    this.isTyping = true;
    this.placesSearch?.startTyping();
  }

  stopTyping() {
    this.isTyping = false;
  }

  private setSearchFieldToCurrentLocation() {
    if (!this.criteriaService.current) {
      return;
    }
    const value =
      this.criteriaService.current.location.type === 'official'
        ? SharedUtilString.uppercaseFirstLetter(this.criteriaService.current.location.address, true, true)
        : this.criteriaService.current.location.address;

    this.displayValue = value;
    this.searchControl.setValue(this.displayValue);
  }

  private selectCity(index) {
    const selectedCity = this.cities[index];
    if (selectedCity) {
      const selectedAddress = this.cityNames[index];
      this.updateLocation(
        selectedCity.lat,
        selectedCity.lon,
        selectedAddress,
        selectedCity.region,
        selectedCity.zoom,
        selectedCity.radius,
        selectedCity.key,
        'official',
      );
      this.setSearchFieldToCurrentLocation();
    }
  }

  private updateLocation(
    lat: number,
    lon: number,
    address: string,
    region: string,
    zoom: number,
    radius: number,
    key: string,
    type: LocationType,
  ) {
    this.value = {
      lat: lat,
      lon: lon,
      address: address,
      region: region,
      zoom: zoom,
      radius: radius,
      key: key,
      type: type,
    };
    this.cd.markForCheck();
  }

  private translateCityNames() {
    if (!this.cityKeys) {
      return;
    }
    // Make sure we don't try to submit all the city keys as translations used by the app
    this.translationsService.excludeAppTranslationKeys(...this.cityKeys);

    this.translate.get(this.cityKeys).subscribe((translations) => {
      const translatedCityNames: string[] = [];
      for (const key in translations) {
        if (!key) {
          continue;
        }
        translatedCityNames.push(translations[key]);
        this.cities.find((city) => city.key === key).address = translations[key];
      }
      this.cityNames = translatedCityNames;
      this.cd.markForCheck();
    });
  }

  private updateSelectedCityIndex() {
    if (!this.cityNames) {
      return;
    }
    const selectedIndex = this.cityNames.indexOf(this.displayAddress);
    this.selectedCityIndex = selectedIndex >= 0 ? selectedIndex : 0;
  }
}
