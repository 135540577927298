import { ChangeDetectionStrategy, Component } from '@angular/core';
import { StorageLocationHolidayNoticeBaseComponent } from '@luggagehero/features/ui';

@Component({
  selector: 'lh-storage-location-holiday-notice',
  templateUrl: './storage-location-holiday-notice.component.html',
  styleUrls: ['./storage-location-holiday-notice.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StorageLocationHolidayNoticeComponent extends StorageLocationHolidayNoticeBaseComponent {}
