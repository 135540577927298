import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, Provider } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ShopCriteriaBaseComponent } from '@luggagehero/features/shops';
import { SharedLocationService } from '@luggagehero/shared/services/locations';
import { SharedPricingService } from '@luggagehero/shared/services/pricing';

export const SHOP_CRITERIA_CONTROL_VALUE_ACCESSOR: Provider = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => ShopCriteriaComponent),
  multi: true,
};
@Component({
  selector: 'lh-shop-criteria',
  templateUrl: './shop-criteria.component.html',
  styleUrls: ['./shop-criteria.component.scss'],
  providers: [SHOP_CRITERIA_CONTROL_VALUE_ACCESSOR],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShopCriteriaComponent extends ShopCriteriaBaseComponent implements ControlValueAccessor {
  constructor(locationService: SharedLocationService, priceService: SharedPricingService, cd: ChangeDetectorRef) {
    super(locationService, priceService, cd);
  }
}
