import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { SharedUiSpinnerComponent, SharedUiTipOptionsComponent, TipOption } from '@luggagehero/shared/ui';
import { TranslatePipe } from '@luggagehero/shared/ui-pipes';

import { GuestDropoffStepBaseComponent } from '../guest-dropoff-step-base-component';
import { TippingStepProperties } from './tipping-step-properties';

@Component({
  selector: 'lh-tipping-step',
  standalone: true,
  imports: [CommonModule, FormsModule, SharedUiSpinnerComponent, SharedUiTipOptionsComponent, TranslatePipe],
  templateUrl: './tipping-step.component.html',
  styleUrl: './tipping-step.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TippingStepComponent extends GuestDropoffStepBaseComponent<TippingStepProperties> {
  public tipOptions: TipOption[];

  public get selectedTip(): number {
    return this.bookingDraft.addOns.tip;
  }
  public set selectedTip(value: number) {
    this.bookingDraft.addOns.tip = value;
    void this.checkCanGoForward(false);
  }

  public get maxTipAmount(): number {
    return this.properties.maxTipAmount || this.bookingDraft.order.tip.maxTip || 50;
  }

  public get isRequired(): boolean {
    return this.properties?.required ? true : false;
  }

  public get defaultProperties(): TippingStepProperties {
    return {
      header: 'ADD_A_TIP',
      body: ['DO_YOU_WANT_TO_LEAVE_A_TIP'],
      enableCustomTip: true,
      required: false,
      requireOrder: true,
      createOrder: true,
    };
  }

  protected onInit(): Promise<void> {
    // Initialize tip options
    const tipAmountOptions = this.order?.tip?.options || [
      0,
      this.properties.smallTipAmount || 2,
      this.properties.mediumTipAmount || 5,
      this.properties.largeTipAmount || 10,
    ];

    this.tipOptions = tipAmountOptions.map((amount) => ({
      amount,
      label: this.pricingService.format(amount, this.order.currency),
    }));

    return Promise.resolve();
  }

  protected onCheckCanGoForward(): boolean {
    return this.selectedTip != null;
  }

  protected onCheckCanSkip(): boolean {
    return this.selectedTip != null || !this.isRequired;
  }
}
