import { Type } from '@angular/core';
import { AppConfig } from '@luggagehero/shared/app-settings/data-access';
import { Booking } from '@luggagehero/shared/interfaces';
import { SharedWindowService } from '@luggagehero/shared/services/window';

import { DateUtil } from '../../../../../utils/date.util';
import { ValidateBookingActionBaseComponent } from '../booking-actions';
import { BookingActionInfo } from '../booking-actions/booking-action-info';
import { DropOffActionBaseComponent } from '../booking-actions/drop-off-action.base-component';
import { ExplainStorageTimerActionBaseComponent } from '../booking-actions/explain-storage-timer-action.base-component';
import { SelectPaymentMethodActionBaseComponent } from '../booking-actions/select-payment-method-action.base-component';
import { BookingTimelineBaseComponent } from '../booking-timeline.base-component';
import { BookingStep } from './booking-step';

export abstract class DropOffBaseStep extends BookingStep {
  private get selectPaymentMethodActionInfo(): BookingActionInfo {
    return new BookingActionInfo('SelectPaymentMethodFromDropOffStep')
      .withCallToAction(this.translate('START_STORAGE_TIMER'))
      .withAction({
        functionToCall: () => {
          const message = this.translate('PAYMENT_METHOD_BEFORE_START_TIMER_ALERT');
          const title = this.translate('PAYMENT_METHOD_MISSING');
          this.windowService.alert(message, title);

          return Promise.resolve(null);
        },
        componentToShow: this.selectPaymentMethodActionComponentType,
      })
      .withTitle(this.translate('PAYMENT_METHOD_MISSING'))
      .withSubtitle(this.translate('STORAGE_TIMER_ENABLED_AFTER_PAYMENT_METHOD'))
      .withSkipAction({ componentToShow: this.explainStorageTimerActionComponentType })
      .withSkipText(this.translate('STORAGE_TIMER_WHY'))
      .withSkipTitle(this.translate('THE_STORAGE_TIMER'))
      .withSkipSubtitle(this.translate('KEEPS_TRACK_OF_HOW_LONG_YOU_STORE'))
      .withDisabled(false) // !this.booking.state.getDirectionsDone)
      .withDisplayTimer(this.parent.strategy.useInlineTimer)
      .withBookingChangeHandler((b) => this.onPaymentMethodChange(b));
  }

  private get dropOffActionInfo(): BookingActionInfo {
    return new BookingActionInfo('StartStorageTimerFromDropOffStep')
      .withCallToAction(this.translate('DROP_OFF'))
      .withAction({ componentToShow: this.dropOffActionComponentType })
      .withTitle(this.translate('DROP_OFF'))
      .withSkipAction({ componentToShow: this.explainStorageTimerActionComponentType })
      .withSkipText(this.translate('STORAGE_TIMER_WHY'))
      .withSkipTitle(this.translate('THE_STORAGE_TIMER'))
      .withSkipSubtitle(this.translate('KEEPS_TRACK_OF_HOW_LONG_YOU_STORE'))
      .withDisabled(false) // !this.booking.state.getDirectionsDone);
      .withDisplayTimer(this.parent.strategy.useInlineTimer);
  }

  private get showDropoffConfirmatioActionInfo(): BookingActionInfo {
    return new BookingActionInfo('ShowDropoffConfirmationFromDropOffStep')
      .withCallToAction(this.translate('SHOW_DROPOFF_CONFIRMATION_LONG'))
      .withAction({ componentToShow: this.validateBookingActionComponentType })
      .withDisplayTimer(this.parent.strategy.useInlineTimer);
  }

  private get isSelfService(): boolean {
    return this.shop?.storageRoomAccessCode || this.shop?.storageRoomAccessText ? true : false;
  }

  private get isDropoffConfirmationVisible(): boolean {
    if (!this.isSelfService && !AppConfig.IS_DROPOFF_CONFIRMATION_ACTION_ENABLED) {
      return false;
    }
    if (!this.booking) {
      return false;
    }
    if (this.booking.status !== 'CHECKED_IN') {
      return false;
    }
    if (DateUtil.minutesSinceCheckIn(this.booking) > AppConfig.HIDE_DROPOFF_CONFIRMATION_AFTER_MINUTES) {
      return false;
    }
    return true;
  }

  //
  // We only know the base action components here, so force the derived class
  // to provide the specific types
  //
  protected abstract get dropOffActionComponentType(): Type<DropOffActionBaseComponent>;
  protected abstract get selectPaymentMethodActionComponentType(): Type<SelectPaymentMethodActionBaseComponent>;
  protected abstract get explainStorageTimerActionComponentType(): Type<ExplainStorageTimerActionBaseComponent>;
  protected abstract get validateBookingActionComponentType(): Type<ValidateBookingActionBaseComponent>;

  constructor(
    parent: BookingTimelineBaseComponent,
    private windowService: SharedWindowService,
  ) {
    super(parent);
  }

  public getIsVisible(): boolean {
    return this.booking.status !== 'CANCELLED';
  }

  public getTitle(): string {
    return this.translate('HAND_OVER_YOUR_LUGGAGE');
  }

  public getSubtitle(): string {
    if (this.getIsCompleted()) {
      return this.translate('YOUR_LUGGAGE_IS_SAFE');
    }
    return this.translate('BOOKING_TIMELINE_START_TIMER_SHORT');
  }

  public getIsSubtitleHidden(): boolean {
    return false;
  }

  public getIsActive(): boolean {
    return this.booking.status === 'CONFIRMED';
  }

  public getIsCompleted(): boolean {
    return ['CHECKED_IN', 'CHECKED_OUT', 'PAID'].includes(this.booking.status);
  }

  public getIsFailed(): boolean {
    return false;
  }

  public getActionInfo(): BookingActionInfo {
    if (!this.booking.paymentMethodId) {
      return this.selectPaymentMethodActionInfo;
    }
    if (!this.isCompleted) {
      return this.dropOffActionInfo;
    }
    if (this.isDropoffConfirmationVisible) {
      return this.showDropoffConfirmatioActionInfo;
    }
    return null;
  }

  public getIsActionCollapsed(): boolean {
    return this.isCompleted;
  }

  private onPaymentMethodChange(booking: Booking) {
    if (!booking.paymentMethodId) {
      return;
    }
    this.triggerAction(this.dropOffActionInfo);
  }
}
