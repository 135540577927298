import { EventEmitter, Injectable } from '@angular/core';
import { AppConfig } from '@luggagehero/shared/app-settings/data-access';
import { Config } from '@luggagehero/shared/environment';
import { SharedDocumentService } from '@luggagehero/shared/services/document';
import { SharedLoggingService } from '@luggagehero/shared/services/logging';
import { SharedWindowService } from '@luggagehero/shared/services/window';

export interface IntercomCustomAttributes {
  clientApp?: string;
  [name: string]: boolean | number | string;
}

export interface IntercomSettings {
  email: string;
  phone: string;
  createdAt: Date;
  name: string;
  userId: string;
  customAttributes?: IntercomCustomAttributes;
}

interface NativeIntercomSettings {
  app_id: string;
  email: string;
  phone: string;
  created_at: number;
  name: string;
  user_id: string;
  custom_attributes?: IntercomCustomAttributes;
}

interface IntercomConfiguration {
  /**
   * The app_id of your Intercom app which will indicate where to store any data
   */
  app_id: string;
  /**
   * The CSS selector of an element to trigger Intercom("show") in order to activate the messenger (See docs). To
   * target an element by ID: "#id_of_element". To target elements by class ".classname_of_elements"
   */
  custom_launcher_selector?: string;
  /**
   * Dictate the alignment of the default launcher icon to be on the left/right. Possible values: "left" or "right" (any other value is treated as right). (See docs)
   */
  alignment?: string;
  /**
   * Move the default launcher icon vertically. Padding from bottom of screen. Minimum value: 20. Does not work on mobile. (See docs)
   */
  vertical_padding?: number;
  /**
   * Move the default launcher icon horizontally. Padding from right side of screen Minimum value: 20. Does not work on mobile. (See docs)
   */
  horizontal_padding?: number;
  /**
   * Hide the default launcher icon. Setting to false will forcefully show the launcher icon (See docs)
   */
  hide_default_launcher?: boolean;
  /**
   * Time in milliseconds for the Intercom session to be considered active.
   * A value of 5 * 60 * 1000 would set the expiry time to be 5 minutes
   */
  session_duration?: number;
  /**
   * Used in button links and more to highlight and emphasise
   */
  action_color?: string;
  /**
   * Used behind your team profile and other attributes
   */
  background_color?: string;
}

@Injectable({
  providedIn: 'root',
})
export class SharedIntercomService {
  public onShow = new EventEmitter();
  public onHide = new EventEmitter();

  protected _clientApp: string;
  private _isShown = false;

  private get config(): IntercomConfiguration {
    return {
      app_id: Config.environment.INTERCOM_APP_ID,
      hide_default_launcher: true,
      custom_launcher_selector: '.intercom-trigger',
    };
  }

  constructor(
    protected windowService: SharedWindowService,
    private documentService: SharedDocumentService,
    private log: SharedLoggingService,
  ) {}

  get clientApp(): string {
    return this._clientApp;
  }

  get isEnabled() {
    if (!this.windowService.Intercom) {
      return false;
    }
    return AppConfig.IS_CHAT_ENABLED && AppConfig.IS_INTERCOM_ENABLED;
  }

  get isShown(): boolean {
    return this._isShown;
  }

  init(clientApp: string): Promise<void> {
    if (this.isEnabled && !this.windowService.intercomSettings) {
      this._clientApp = clientApp;
      this.windowService.intercomSettings = this.config;

      this.windowService.Intercom('onShow', () => {
        this.documentService.fixIntercomViewport();

        this._isShown = true;
        this.onShow.emit();

        // These classes are used to fix strange Intercom behavior on some mobile devices (see /libs-legacy/scss/_chat.scss)
        this.documentService.removeBodyClass('intercom-messenger-hidden');
        this.documentService.addBodyClass('intercom-messenger-shown');
      });

      this.windowService.Intercom('onHide', () => {
        this._isShown = false;
        this.onHide.emit();

        // These classes are used to fix strange Intercom behavior on some mobile devices (see /libs-legacy/scss/_chat.scss)
        this.documentService.removeBodyClass('intercom-messenger-shown');
        this.documentService.addBodyClass('intercom-messenger-hidden');
      });
    }
    return Promise.resolve();
  }

  boot(settings: IntercomSettings): void {
    if (!this.isEnabled) {
      return;
    }
    const nativeSettings = this.parseSettings(settings);

    void this.log.info(`Booting Intercom web`, { settings: nativeSettings });

    this.windowService.Intercom('boot', nativeSettings);
  }

  update(): void {
    if (!this.isEnabled) {
      return;
    }
    this.windowService.Intercom('update');
  }

  show(): void {
    if (!this.isEnabled) {
      return;
    }
    this.windowService.Intercom('show');
  }

  hide(): void {
    if (!this.isEnabled) {
      return;
    }
    this.windowService.Intercom('hide');
  }

  trackEvent(event: string, metadata: unknown): void {
    if (!this.isEnabled) {
      return;
    }
    this.windowService.Intercom('trackEvent', event, metadata);
  }

  startTour(tourId: number): void {
    if (!this.isEnabled) {
      return;
    }
    this.windowService.Intercom('startTour', tourId);
  }

  shutdown(): void {
    this.windowService.Intercom('shutdown');
  }

  private parseSettings(value: IntercomSettings): NativeIntercomSettings {
    if (!value.customAttributes) {
      value.customAttributes = {};
    }
    value.customAttributes.clientApp = this.clientApp;

    return {
      app_id: this.config.app_id,
      user_id: value.userId,
      name: value.name,
      email: value.email,
      phone: value.phone,
      created_at: Math.round(value.createdAt.getTime() / 1000),
      custom_attributes: value.customAttributes,
    };
  }
}
