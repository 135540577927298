import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { IPaymentRecord } from '@luggagehero/shared/interfaces';
import { SharedLoggingService } from '@luggagehero/shared/services/logging';
import { SharedUserService } from '@luggagehero/shared/services/users';

import { BaseComponent } from '../../../core';
const noop = () => {
  /**/
};

@Component({ template: '' })
export abstract class StripeAccountFormBaseComponent
  extends BaseComponent
  implements ControlValueAccessor, AfterViewInit
{
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() cancel: EventEmitter<unknown> = new EventEmitter<unknown>();
  @Input() allowCancel = true;

  private _value: IPaymentRecord;
  private _country: string;
  private _email: string;
  private _isLoading = false;

  private onTouchedCallback: () => void = noop;
  private onChangeCallback: (_) => void = noop;

  constructor(
    private userService: SharedUserService,
    private log: SharedLoggingService,
    private cd: ChangeDetectorRef,
  ) {
    super();
    this.cancel = new EventEmitter();
  }

  get value(): IPaymentRecord {
    return this._value;
  }
  set value(value: IPaymentRecord) {
    if (!value || value === this._value) {
      return;
    }
    this._value = value;

    this.onChangeCallback(value);
    this.cd.markForCheck();
  }

  get isLoading(): boolean {
    return this._isLoading;
  }
  @Input() set isLoading(value: boolean) {
    this._isLoading = value;
    this.cd.markForCheck();
  }

  get country(): string {
    return this._country;
  }
  set country(value: string) {
    this._country = value;
    this.updateValue();
  }

  get email(): string {
    return this._email;
  }
  set email(value: string) {
    this._email = value;
    this.updateValue();
  }

  ngAfterViewInit() {
    this.setDefaultValues();
  }

  writeValue(value: IPaymentRecord<unknown>) {
    if (value !== undefined) {
      this.value = value;
    }
  }

  registerOnChange(fn: (_) => void) {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: () => void) {
    this.onTouchedCallback = fn;
  }

  onCancel() {
    this.cancel.emit();
  }

  private updateValue() {
    this.value = {
      provider: 'stripe',
      data: {
        country: this.country,
        email: this.email,
      },
    };
  }

  private setDefaultValues() {
    this.country = 'GB';
    this.email = this.userService.user.email;
  }
}
