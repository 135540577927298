import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, Input, OnDestroy, OnInit } from '@angular/core';
import { ColorUtil, isDummyUserName } from '@luggagehero/shared/util';
import { Subject } from 'rxjs';

@Component({
  selector: 'lh-shared-ui-user-profile-image',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './shared-ui-user-profile-image.component.html',
  styleUrl: './shared-ui-user-profile-image.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SharedUiUserProfileImageComponent implements OnInit, OnDestroy {
  @Input() public imageUrl!: string;
  @Input() public name?: string;
  @Input() public color!: string;
  @Input() public size: 'sm' | 'md' | 'lg' = 'md';
  @Input() public profileBackgroundColors!: string[];

  private destroy$: Subject<unknown> = new Subject();
  private _cd = inject(ChangeDetectorRef);

  public ngOnInit() {
    if (!this.color) {
      this.color =
        this.name && !isDummyUserName(this.name)
          ? ColorUtil.pickColorByUsername(this.name, this.profileBackgroundColors)
          : ColorUtil.pickRandomColor(this.profileBackgroundColors);
    }
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  protected _detectChanges(): void {
    this._cd.detectChanges();
  }

  protected _markForCheck(): void {
    this._cd.markForCheck();
  }
}
