<form role="form" #stripeAccountForm="ngForm">
  <div class="form-group">
    {{ 'SHOP_STRIPE_EXPLANATION_CREATE_ACCOUNT' | translate }}
  </div>

  <table class="table">
    <tr>
      <td>
        <label>{{ 'ACCOUNT_COUNTRY' | translate }}</label>
        <select class="form-select" name="country" [(ngModel)]="country" required>
          <option value="DK">{{ 'ACCOUNT_COUNTRY_DENMARK' | translate }}</option>
          <option value="GB">{{ 'ACCOUNT_COUNTRY_UNITED_KINGDOM' | translate }}</option>
          <option value="US">{{ 'ACCOUNT_COUNTRY_UNITED_STATES' | translate }}</option>
        </select>
      </td>
      <td>
        <label>{{ 'EMAIL_ADDRESS' | translate }}</label>
        <input
          type="text"
          class="form-control"
          name="email"
          [(ngModel)]="email"
          [placeholder]="'EMAIL_ADDRESS' | translate"
          required
        />
      </td>
    </tr>
  </table>

  <div class="form-group">
    <button
      id="confirmCreateStripeAccount"
      class="btn btn-warning"
      type="submit"
      [disabled]="!stripeAccountForm.form.valid || isLoading"
    >
      {{ 'CREATE_STRIPE_ACCOUNT' | translate }}
    </button>
    <button id="cancelCreateStripeAccount" class="btn btn-info lh-btn" type="button" (click)="onCancel()">
      {{ 'CANCEL' | translate }}
    </button>
  </div>
</form>
