import { AppConfig } from '@luggagehero/shared/app-settings/data-access';
import { Booking, BookingTransformationService, PricingService } from '@luggagehero/shared/interfaces';
import { TranslateService } from '@ngx-translate/core';

import { ListItem, ListItemIcon, ListItemLabel, UiStyle } from './grouped-list.base-component';

export class ListItemFactory {
  public static createBookingListItem(
    booking: Booking,
    translate: TranslateService,
    bookingTansformationService: BookingTransformationService,
    pricingService: PricingService,
  ): ListItem<Booking> {
    const listItem: ListItem<Booking> = {
      title: booking._id,
      content: {
        strong: bookingTansformationService.transformBooking(booking, 'user'),
        normal: bookingTansformationService.transformBooking(
          booking,
          booking.incidentReported ? 'latestIncident' : 'latestAction',
        ),
      },
      labels: this.getBookingLabels(booking, translate, bookingTansformationService, pricingService),
      icons: [
        new ListItemIcon('bag', String(booking.luggage.normal + booking.luggage.hand)),
        new ListItemIcon('zoom-in'),
      ],
      value: booking,
      disabled: booking.status === 'CANCELLED' && !booking.incidentReported,
    };

    if (!listItem.disabled) {
      listItem.image = booking.luggage.smartImages?.[0]?.name;
    }

    return listItem;
  }

  private static getBookingLabels(
    booking: Booking,
    translate: TranslateService,
    bookingTransformationService: BookingTransformationService,
    pricingService: PricingService,
  ): ListItemLabel[] {
    const labels: ListItemLabel[] = [];

    // Incident
    if (booking.incidentReported) {
      const text = translate.instant('INCIDENT') as string;
      const tooltip = translate.instant('BOOKING_INCIDENT_EXPLANATION') as string;
      labels.push(new ListItemLabel(text, tooltip, 'yellow'));
    }

    const storageDays = (booking.metadata?.lh_storage_days ?? undefined) as number | undefined;
    // Storage days
    if (storageDays != null && storageDays > 1) {
      const text = `${storageDays} ${translate.instant('DAYS') as string}`;
      const tooltip = translate.instant('MULTI_DAY_BOOKING_EXPLANATION') as string;
      labels.push(new ListItemLabel(text, tooltip, 'info'));
    }

    // Guest
    if (booking.isGuest) {
      const text = translate.instant('GUEST') as string;
      const tooltip = translate.instant('GUEST_EXPLANATION') as string;
      labels.push(new ListItemLabel(text, tooltip, 'info'));
    }
    // Walk-in
    else if (booking.isWalkIn) {
      const text = translate.instant('WALK_IN') as string;
      const tooltip = translate.instant('WALK_IN_EXPLANATION') as string;
      labels.push(new ListItemLabel(text, tooltip, 'info'));
    }

    // Booking amount
    if (this.isBookingAmountVisible(booking)) {
      const style: UiStyle = this.isDirectPaymentRequired(booking)
        ? booking.status === 'CHECKED_OUT'
          ? 'danger'
          : 'warning'
        : 'success';
      const text = pricingService.format(this.getBookingAmount(booking), this.getBookingCurrency(booking), 2, false);
      const tooltip = translate.instant(this.getBookingAmountTooltip(booking)) as string;
      labels.push(new ListItemLabel(text, tooltip, style, true));
    }

    // Status
    if (booking.status !== 'CANCELLED') {
      const style: UiStyle =
        (booking.status === 'CHECKED_IN' && 'primary') ||
        (booking.status === 'CHECKED_OUT' && 'danger') ||
        (booking.status === 'PAID' && (booking.paidDirectly ? 'warning' : 'success')) ||
        undefined;
      const text = bookingTransformationService.transformBooking(booking, 'status');
      const tooltip = bookingTransformationService.transformBooking(booking, 'statusVerbose');
      labels.push(new ListItemLabel(text, tooltip, style));
    }

    return labels;
  }

  private static isBookingAmountVisible(booking: Booking): boolean {
    if (!AppConfig.IS_STORAGE_MANAGER_BOOKING_AMOUNT_ENABLED) {
      return false;
    }

    if (!this.getBookingAmount(booking)) {
      return false;
    }

    return true;
  }

  private static isDirectPaymentRequired(booking: Booking): boolean {
    return booking.status === 'CHECKED_OUT' || (booking.status === 'PAID' && booking.paidDirectly);
  }

  private static getBookingAmountTooltip(booking: Booking): string {
    return this.isDirectPaymentRequired(booking)
      ? 'YOU_NEED_TO_CHARGE_THIS_AMOUNT'
      : 'YOUR_NET_EARNINGS_FOR_THIS_BOOKING';
  }

  private static getBookingAmount(booking: Booking): number {
    if (!['CHECKED_OUT', 'PAID'].includes(booking.status)) {
      return null;
    }
    return this.isDirectPaymentRequired(booking) ? booking.fullBookingAmount : booking.storageLocationBookingAmount;
  }

  private static getBookingCurrency(booking: Booking): string {
    if (booking.storageLocationRevenuePlus && !this.isDirectPaymentRequired(booking)) {
      return booking.storageLocationRevenuePlus.currency;
    }
    return booking.price.final?.currency || booking.price.pricing.currency;
  }
}
